import * as React from 'react';
import { Badge, Box, Grid, Tooltip } from '@mui/material';
import { FlexyHeaderForm, FlexyTextField } from '@europrocurement/flexy-components';
import CustomOfferCard from './CustomOfferCard';

type OfferOrigin = {
    origin: string;
    offer: {
        id: number;
        label: string;
    };
};

const OffersSettings: React.FunctionComponent = function () {
    const fakeCustomOffers: OfferOrigin[] = [
        {
            origin: 'string',
            offer: {
                id: 1,
                label: 'string',
            },
        },
    ];

    const prescriberHasCustomOffers = fakeCustomOffers.length > 0;

    const numberOfCustomOffers = 4;

    return (
        <Grid
            container
            spacing={4}
            rowSpacing={1}
        >
            <Grid
                item
                xl={8}
                lg={8}
            >
                <FlexyHeaderForm
                    label={
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            width="100%"
                        >
                            <Tooltip
                                title={`${numberOfCustomOffers} offres personalisées sont paramétrées pour ce prescripteur`}
                            >
                                <Badge
                                    badgeContent={numberOfCustomOffers}
                                    sx={{ paddingRight: '12px' }}
                                    color="primary"
                                >
                                    Offres personnalisées
                                </Badge>
                            </Tooltip>
                            <span>( + Badges de tri par domaine + Tri offre personnalisées)</span>
                        </Box>
                    }
                    outlined
                />
            </Grid>
            <Grid
                item
                xl={4}
                lg={4}
            >
                <FlexyTextField
                    variant="outlined"
                    placeholder="Rechercher origine"
                    sx={{ width: '100%' }}
                />
            </Grid>

            {!prescriberHasCustomOffers && <p>(Aucunes offres personnalisées) </p>}

            {prescriberHasCustomOffers && (
                <>
                    <Grid
                        item
                        lg={3}
                    >
                        <CustomOfferCard />
                    </Grid>
                    <Grid
                        item
                        lg={3}
                    >
                        <CustomOfferCard />
                    </Grid>
                    <Grid
                        item
                        lg={3}
                    >
                        <CustomOfferCard />
                    </Grid>
                    <Grid
                        item
                        lg={3}
                    >
                        <CustomOfferCard />
                    </Grid>
                    <Grid
                        item
                        lg={3}
                    >
                        <CustomOfferCard />
                    </Grid>
                    <Grid
                        item
                        lg={3}
                    >
                        <CustomOfferCard />
                    </Grid>
                    <Grid
                        item
                        lg={3}
                    >
                        <CustomOfferCard />
                    </Grid>
                    <Grid
                        item
                        lg={3}
                    >
                        <CustomOfferCard />
                    </Grid>
                    <Grid
                        item
                        lg={3}
                    >
                        <CustomOfferCard />
                    </Grid>
                    <Grid
                        item
                        lg={3}
                    >
                        <CustomOfferCard />
                    </Grid>

                    {/* {fakeCustomOffers.map((customOffer) => {
                            return <CustomOfferCard />;
                        })} */}
                </>
            )}
        </Grid>
    );
};

export default OffersSettings;
