// [ANNONCES-LEGALES] Actions

import { getApiConfiguration } from '@b2d/redux/utils';
import { APIS_ENUM } from '@europrocurement/l2d-domain';
import {
    AnnonceLegaleApiFactory,
    AnnoncesLegalesApi,
} from '@europrocurement/l2d-domain/openApi/ApiAnnoncesLegales';

export const apiConfiguration = getApiConfiguration(APIS_ENUM.AL);
export const legalAdvertApi = new AnnoncesLegalesApi(apiConfiguration);
export const { getPricingAnnonceLegaleItem } = AnnonceLegaleApiFactory(apiConfiguration);
