import React, { FunctionComponent } from 'react';

import { Box } from '@mui/material';
import { DataItem } from '../../atoms';

export type DataItemsProps = {
    items: Array<{
        title: string;
        content: string | number;
        contentType?: 'currency';
    }>;
};

const DataItems: FunctionComponent<DataItemsProps> = function ({ items }: DataItemsProps) {
    return (
        <Box>
            {items.map((item) => (
                <DataItem {...item} />
            ))}
        </Box>
    );
};

export default DataItems;
