import { ColumnDatatable } from '@europrocurement/flexy-datatable';
import { MediaObject, MediaObjectApiObject } from '@europrocurement/l2d-domain';
import { useMemo } from 'react';
import { useNavigate } from 'react-router';
import { getFromSpecifiedDataSourceThunks } from '@b2d/redux/RootStore';
import { DataSource } from '@europrocurement/l2d-redux-utils';
import columnItemFileName from '../components/ColumnItems/columnItemFileName';
import columnItemsTsBlame from '../components/ColumnItems/columnItemsTsBlame';
import columnItemAssignedCompany from '../components/ColumnItems/columnItemAssignedCompany';
import columnItemsStatus from '../components/ColumnItems/columnItemsStatus';
import columnItemsAssociatedPurchaseInvoice from '../components/ColumnItems/columnItemsAssociatedPurchaseInvoice';
import columnItemActions from '../components/ColumnItems/columnItemActions';

type UseUnregisteredInvoicesListColumnsProps = {
    getMediaObject: ReturnType<typeof getFromSpecifiedDataSourceThunks>;
    selectedDataSource?: MediaObjectApiObject;
    dataSource: DataSource<MediaObjectApiObject>;
};

const useUnregisteredInvoicesListColumns = ({
    getMediaObject,
    selectedDataSource,
    dataSource,
}: UseUnregisteredInvoicesListColumnsProps) => {
    const navigate = useNavigate();

    const columns = useMemo(
        (): Array<ColumnDatatable<MediaObject>> => [
            columnItemFileName,
            ...columnItemsTsBlame,
            columnItemAssignedCompany,
            columnItemsStatus,
            columnItemsAssociatedPurchaseInvoice(navigate),
            columnItemActions(getMediaObject, selectedDataSource, dataSource),
        ],
        [getMediaObject, navigate, selectedDataSource, dataSource],
    );

    return {
        columns,
    };
};

export default useUnregisteredInvoicesListColumns;
