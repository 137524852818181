import React, { useEffect } from 'react';
import {
    CenterCircularProgress,
    LazyBreadcrumb,
    useUploader,
} from '@europrocurement/flexy-components';
import { UseKeycloakService } from '@europrocurement/l2d-keycloak';

import { MainDossierSignatureSelector, selectDossierSignature } from '@b2d/redux/subReducers';
import { useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from '@b2d/redux/RootStore';
import { DataSource } from '@europrocurement/l2d-redux-utils';
import { DocumentType, DossierSignature } from '@europrocurement/l2d-domain';
import { signatureListeFullPath, signatureNewFullPath } from '../../constants/paths';
import DossierSignatureFormWrapper from './DossierSignatureFormWrapper';

async function createFile(doc: DocumentType) {
    if (!doc.contentUrl || !doc.mimeType || !doc.nom) {
        throw new Error('Bad Document');
    }
    // url: string, mimeType : string

    const response = await fetch(doc.contentUrl);
    const data = await response.blob();
    const metadata = {
        type: doc.mimeType,
    };
    const file = new File([data], doc.nom, metadata);
    return file;
}

const DossierSignatureDucplicate: React.FunctionComponent = function () {
    const kc = UseKeycloakService();

    const [uploaderInitialized, setUploaderInitialized] = React.useState<boolean>(false);

    const { idDossierSignature } = useParams();

    if (!idDossierSignature) {
        throw new Error('Pas de dossier a dupliquer erreur');
    }

    const dispatch = useAppDispatch();

    const dataSource: DataSource<DossierSignature> = useAppSelector(MainDossierSignatureSelector);

    const currentDossierSignature = dataSource.selected;

    const uploader = useUploader();

    useEffect(() => {
        if (
            idDossierSignature &&
            (!dataSource.selected || `${dataSource.selected.id}` !== idDossierSignature)
        ) {
            if (dataSource.status !== 'loading') {
                dispatch(selectDossierSignature({ id: idDossierSignature }));
            }
        }
    }, [dataSource.selected, dataSource.status, dispatch, idDossierSignature]);

    useEffect(() => {
        if (
            !uploaderInitialized &&
            idDossierSignature &&
            dataSource.selected &&
            `${dataSource.selected.id}` === idDossierSignature
        ) {
            console.log('if', uploader, dataSource.selected.documents);
            Promise.all(
                dataSource.selected.documents.map(
                    async (doc: DocumentType) => await createFile(doc),
                ),
            ).then((res) => {
                uploader.resetFiles(res);
                setUploaderInitialized(true);
            });
        }
    }, [uploaderInitialized, uploader, dataSource.selected, idDossierSignature]);

    return !currentDossierSignature ||
        !dataSource.selected ||
        `${dataSource.selected.id}` !== idDossierSignature ? (
        <CenterCircularProgress />
    ) : (
        <>
            <LazyBreadcrumb
                path={[
                    { path: 'Signature électronique', link: signatureNewFullPath },
                    { path: 'Liste des dossiers', link: signatureListeFullPath },
                    `Dupliquer le dossier`,
                ]}
            />

            <DossierSignatureFormWrapper
                defaultValues={{
                    prescripteur: kc.getName(),
                    prescripteurEmail: kc.getEmail(),
                    referenceExterne: currentDossierSignature.referenceExterne,
                    sujet: currentDossierSignature.sujet,
                    signataires: currentDossierSignature.signataires,
                    // ajouter les documents
                }}
            />
        </>
    );
};

export default DossierSignatureDucplicate;
