/**
 *
 * Notre composant affiche un composant de téléchargement de documents
 *
 */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies, import/no-cycle
import { FlexyForm } from '@europrocurement/flexy-form';
import { SubmitHandler } from 'react-hook-form';
import {
    MailApiPostMailMailCollectionRequest,
    MailMailSend,
} from '@europrocurement/l2d-domain/openApi/ApiMail';
import { UploadedFile } from '@europrocurement/flexy-components';
import { MailStructureMapType, mailStructureMap, overrideMailStructure } from './MailFormItems';

// eslint-disable-next-line import/no-cycle

export type MailDetailType = {
    subject: MailMailSend['subject'];
    body: MailMailSend['body'];
    tos: MailMailSend['tos'];
    from: MailMailSend['from'];
    fromName: MailMailSend['fromName'];
    replies: MailMailSend['replies'];
    bccs: MailMailSend['bccs'];
    ccs: MailMailSend['ccs'];
    tags: MailMailSend['tags'];
};

type MailFormObject = {
    tags: string;
    bccs: string;
    ccs: string;
    tos: string;
    replies: string;
    subject: string;
    body: string;
    from: string;
    fromName: string;
};

export type MailFormProps = {
    sendMailCallback: (mailPayload: MailApiPostMailMailCollectionRequest) => void;
    override?: Partial<MailStructureMapType>;
    attachments?: File[];
    defaultValues?: Partial<MailDetailType>;
    hideSubmitButton?: boolean;
};

// titre ?
// champs de formulaire

const MailForm: React.FunctionComponent<MailFormProps> = function ({
    sendMailCallback,
    override = {},
    attachments,
    defaultValues,
    hideSubmitButton = false,
}: MailFormProps) {
    /**
     *
     * appeler la fonction d'override pour récup une formstructure - OK
     *
     * afficher la structure - OK
     *
     * afficher les attachements deja mis
     *
     * // garder dans la spec mais on fera plus tard
     * si addAttachement = true : ajouter un uploader
     *
     *
     */

    const genericStructureOverride: Partial<MailStructureMapType> = {
        ...override,
        from: {
            ...mailStructureMap.from,
            ...override.from,
            type: 'text',
            name: 'from',
        },
        tos: {
            ...mailStructureMap.tos,
            ...override.tos,
            type: 'text',
            name: 'tos',
        },
        replies: {
            ...mailStructureMap.replies,
            ...override.replies,
            type: 'text',
            name: 'replies',
        },
        ccs: {
            ...mailStructureMap.ccs,
            ...override.ccs,
            type: 'text',
            name: 'ccs',
        },
        bccs: {
            ...mailStructureMap.bccs,
            ...override.bccs,
            type: 'text',
            name: 'bccs',
        },
        tags: {
            ...mailStructureMap.tags,
            ...override.tags,
            type: 'text',
            name: 'tags',
        },
        subject: {
            ...mailStructureMap.subject,
            ...override.subject,
            type: 'text',
            name: 'subject',
            // faire pareil avec le name
        },
    };
    // value: `back-to-digital, ${override.tags?.value}`,

    const mailFormStructure = overrideMailStructure(genericStructureOverride);
    // const mailFormObject = getFormObjectFromStructure(mailFormStructure);

    const handleSubmit: SubmitHandler<MailFormObject> = useCallback(
        (data) => {
            sendMailCallback({
                details: {
                    ...data,
                    tags: data.tags.split(',').map((s) => s.trim()),
                    bccs: data.bccs.split(',').map((s) => s.trim()),
                    ccs: data.ccs.split(',').map((s) => s.trim()),
                    tos: data.tos.split(',').map((s) => s.trim()),
                    replies: data.replies.split(',').map((s) => s.trim()),
                },
                attachments: [],
            });
        },
        [sendMailCallback],
    );

    const mailFormObject = {
        ...defaultValues,
        tags: ['back-to-digital', ...(defaultValues?.tags || [])].join(', '),
        bccs: [...(defaultValues?.bccs || [])].join(', '),
        ccs: [...(defaultValues?.ccs || [])].join(', '),
        tos: [...(defaultValues?.tos || [])].join(', '),
        replies: [...(defaultValues?.replies || [])].join(', '),
    };

    return (
        <>
            {attachments && attachments.length > 0 && (
                <UploadedFile
                    file={attachments[0]}
                    status="done"
                />
            )}
            <FlexyForm
                formObject={{ ...mailFormObject }}
                formStructure={mailFormStructure}
                onSubmit={handleSubmit}
                submitButton={{
                    displayed: !hideSubmitButton,
                }}
            />
        </>
    );
};

export default MailForm;
