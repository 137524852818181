import { NamedIconsType } from '@europrocurement/l2d-icons/types';
import { faGavel } from '@fortawesome/pro-duotone-svg-icons';
import { checkIcon, errorIcon, forbiddenIcon, listIcon, plusIcon } from './general';

export const formalityIcon: NamedIconsType = {
    name: 'formality',
    displayName: 'Formalités',
    props: {
        icon: faGavel,
    },
};

export const formalityIncompatibilitiesIcon: NamedIconsType = {
    props: {
        icon: formalityIcon.props.icon,
        option: forbiddenIcon.props.icon,
        optionProps: {
            style: {
                translate: '-10% -5%',
            },
        },
    },
    name: 'formalityIncompatibilitiesIcon',
    displayName: 'Incompatibilité formalités',
};

export const formalityListIcon: NamedIconsType = {
    props: {
        icon: formalityIcon.props.icon,
        option: listIcon.props.icon,
        optionProps: {
            style: {
                translate: '-10% -5%',
            },
        },
    },
    name: 'formalityListIcon',
    displayName: 'Liste des formalités',
};

export const formalityActiveIcon: NamedIconsType = {
    props: {
        icon: formalityIcon.props.icon,
        option: checkIcon.props.icon,
        color: 'primary.main',
        optionProps: {
            style: {
                translate: '-10% -5%',
            },
        },
    },
    name: 'formalityActiveIcon',
    displayName: 'Formalité active',
};

export const formalityInactiveIcon: NamedIconsType = {
    props: {
        icon: formalityIcon.props.icon,
        option: errorIcon.props.icon,
        color: 'error.main',
        optionProps: {
            style: {
                translate: '-10% -5%',
            },
        },
    },
    name: 'formalityInactiveIcon',
    displayName: 'Formalité inactive',
};

export const formalityAddIcon: NamedIconsType = {
    props: {
        icon: formalityIcon.props.icon,
        option: plusIcon.props.icon,
        optionProps: {
            style: {
                translate: '-10% -5%',
            },
        },
    },
    name: 'formalityAddIcon',
    displayName: 'Ajout de formalité',
};

export const formalitiesIcons: NamedIconsType[] = [
    formalityIcon,
    formalityIncompatibilitiesIcon,
    formalityListIcon,
    formalityActiveIcon,
    formalityInactiveIcon,
    formalityAddIcon,
];
