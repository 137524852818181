import React from 'react';

import 'react-json-view-lite/dist/index.css';

import { FlexyTabs } from '@europrocurement/flexy-components';

// eslint-disable-next-line import/extensions
import {
    PanelColors,
    PanelEnvironment,
    PanelFormulaires,
    PanelIcons,
    PanelKeycloak,
    PanelTests,
    type PanelEnvironmentType,
} from '../components';

export type DebugType = { panelEnvironnement: PanelEnvironmentType };

const Debug: React.FunctionComponent<DebugType> = function ({ panelEnvironnement }) {
    return (
        <FlexyTabs
            status={undefined}
            sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '15px' }}
            // activeTab={activeTab}
            // handleActiveTab={handleActiveTab}
            tabs={[
                {
                    tabName: 'env',
                    tabTitle: `Environnement`,
                    tabPanel: <PanelEnvironment {...panelEnvironnement} />,
                },
                {
                    tabName: 'icons',
                    tabTitle: `Iconographies`,
                    tabPanel: <PanelIcons />,
                },
                {
                    tabName: 'palette',
                    tabTitle: `Palette`,
                    tabPanel: <PanelColors />,
                },
                {
                    tabName: 'keycloak',
                    tabTitle: `Keycloak`,
                    tabPanel: <PanelKeycloak />,
                },
                {
                    tabName: 'formulaires',
                    tabTitle: `Tests formulaires`,
                    tabPanel: <PanelFormulaires />,
                },
                {
                    tabName: 'tests',
                    tabTitle: `Tests`,
                    tabPanel: <PanelTests />,
                },
            ]}
        />
    );
};
export default Debug;
