/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { UseFormReturn, FieldValues } from 'react-hook-form';
import { FactureAchatLigneJsonldFactureAchatRead } from '@europrocurement/l2d-domain/openApi/ApiAchats';
import type { LigneProduitRegistered, LigneProduit, TableauProduits } from '../types';

export type pricelineType = 'ht' | 'tva' | 'txtva' | 'ttc';

export type pricelineParameters = {
    ht: number;
    tva: number;
    txtva: number;
    ttc: number;
};

export type priceArray = pricelineParameters[];

/**
 * Utility function to convert a value to a number.
 * @param value - The value to convert.
 * @returns The converted number.
 */
export const convertToNumber = (value: string | number | undefined | null): number => {
    if (!value) return 0;
    if (typeof value === 'number') return value;
    return parseFloat(value);
};

/**
 * Utility function to format a number to a string with 2 decimal places.
 * @param num - The number to format.
 * @returns The formatted string.
 */
export const formatNumber = (num: number): string => num.toFixed(4);

/**
 * Utility function embedding the whole process of transformation for currency values in Portail Achat forms.
 *
 * @param value - The value to convert whatever it's type.
 * @return The converted value as a string.
 */
export const convertToNumberAndFormatToString = (
    value: string | number | undefined | null,
): string => formatNumber(convertToNumber(value));

/**
 * Utility function number to number type, rounding value.
 *
 * @param value - The value as a number.
 * @returns The rounded value as a number.
 */
export const round = (value: number | undefined | null) => {
    let valueToTreat = value;

    if (!valueToTreat) {
        valueToTreat = 0;
    }

    return parseFloat(valueToTreat.toFixed(4));
};

/**
 * Utility function number to number type, rounding value, based on places setup.
 *
 * Consider the following thread about difference between Math.round and toFixed :
 * https://stackoverflow.com/questions/566564/math-roundnum-vs-num-tofixed0-and-browser-inconsistencies
 *
 * @param value - The value as a number.
 * @param places - Degree of rounding.
 * @returns The rounded value as a number.
 */
export const roundTo = (value: number, places: number) => {
    const factor = 10 ** places;
    return Math.round(value * factor) / factor;
};

// Pour HT
export const getHT_TVA_TTC = (TVA: number, TTC: number) => TTC - TVA;
export const getHT_TxTVA_TTC = (txTVA: number, TTC: number) => (TTC * 100) / txTVA;
export const getHT_TVA_TxTVA = (TVA: number, txTVA: number) => (TVA * 100) / txTVA;

export function getHT(TVA?: number, txTVA?: number, TTC?: number): number | undefined {
    switch ((TVA && txTVA) || (txTVA && TTC) || (TVA && TTC)) {
        case TVA && txTVA:
            if (TVA !== undefined && txTVA !== undefined) {
                return getHT_TVA_TxTVA(TVA, txTVA);
            }
            break;
        case txTVA && TTC:
            if (txTVA !== undefined && TTC !== undefined) {
                return getHT_TxTVA_TTC(txTVA, TTC);
            }
            break;
        case TVA && TTC:
            if (TVA !== undefined && TTC !== undefined) {
                return getHT_TVA_TTC(TVA, TTC);
            }
            break;
        default:
            // Au minimum, deux des trois valeurs doivent être remplies
            break;
    }

    return undefined;
}

// Pour TVA
export const getTVA_HT_TxTVA = (HT: number, txTVA: number) => HT * (txTVA / 100);
export const getTVA_TxTVA_TTC = (txTVA: number, TTC: number) => TTC - TTC / (1 + txTVA / 100);
export const getTVA_HT_TTC = (HT: number, TTC: number) => TTC - HT;

export function getTVA(HT?: number, txTVA?: number, TTC?: number): number | undefined {
    switch ((HT && txTVA) || (HT && TTC) || (txTVA && TTC)) {
        case HT && txTVA:
            if (HT !== undefined && txTVA !== undefined) {
                return getTVA_HT_TxTVA(HT, txTVA);
            }
            break;
        case HT && TTC:
            if (HT !== undefined && TTC !== undefined) {
                return getTVA_HT_TTC(HT, TTC);
            }
            break;
        case txTVA && TTC:
            if (txTVA !== undefined && TTC !== undefined) {
                return getTVA_TxTVA_TTC(txTVA, TTC);
            }
            break;
        default:
            // Au minimum, deux des trois valeurs doivent être remplies
            break;
    }

    return undefined;
}

// Pour Taux TVA
export const getTxTVA_HT_TVA = (HT: number, TVA: number) => (TVA / HT) * 100;
export const getTxTVA_TVA_TTC = (TVA: number, TTC: number) => (TVA / getHT_TVA_TTC(TVA, TTC)) * 100;
export const getTxTVA_HT_TTC = (HT: number, TTC: number) => (TTC / HT - 1) * 100;

export function getTxTVA(HT?: number, TVA?: number, TTC?: number): number | undefined {
    switch ((HT && TVA) || (HT && TTC) || (TVA && TTC)) {
        case HT && TVA:
            if (HT !== undefined && TVA !== undefined) {
                return getTxTVA_HT_TVA(HT, TVA);
            }
            break;
        case HT && TTC:
            if (HT !== undefined && TTC !== undefined) {
                return getTxTVA_HT_TTC(HT, TTC);
            }
            break;
        case TVA && TTC:
            if (TVA !== undefined && TTC !== undefined) {
                return getTxTVA_TVA_TTC(TVA, TTC);
            }
            break;
        default:
            // Au minimum, deux des trois valeurs doivent être remplies
            break;
    }

    return undefined;
}

// Pour TTC
export const getTTC_HT_TVA = (HT: number, TVA: number) => HT + TVA;
export const getTTC_HT_TxTVA = (HT: number, txTVA: number) => HT * (1 + txTVA / 100);
export const getTTC_TVA_TxTVA = (TVA: number, txTVA: number) => TVA + getHT_TVA_TxTVA(TVA, txTVA);

export function getTTC(HT?: number, TVA?: number, txTVA?: number): number | undefined {
    switch ((TVA && txTVA) || (HT && txTVA) || (HT && TVA)) {
        case TVA && txTVA:
            if (TVA !== undefined && txTVA !== undefined) {
                return getTTC_TVA_TxTVA(TVA, txTVA);
            }
            break;
        case HT && txTVA:
            if (HT !== undefined && txTVA !== undefined) {
                return getTTC_HT_TxTVA(HT, txTVA);
            }
            break;
        case HT && TVA:
            if (HT !== undefined && TVA !== undefined) {
                return getTTC_HT_TVA(HT, TVA);
            }
            break;
        default:
            // Au minimum, deux des trois valeurs doivent être remplies
            break;
    }

    return undefined;
}

export function calculator(input: Partial<pricelineParameters>): pricelineParameters {
    const res: pricelineParameters = {
        ht: input.ht || 0,
        txtva: input.txtva || 0,
        tva: 0,
        ttc: 0,
    };

    res.tva = getTVA_HT_TxTVA(res.ht, res.txtva);
    res.ttc = getTTC_HT_TxTVA(res.ht, res.txtva);

    return res;
}

/**
 * Calculation as PricelineParameters format.
 *
 * @param produit
 * @returns
 */
export const calculatorProduit = function (produit: Partial<LigneProduit>) {
    return calculator({
        ht: (produit.ht || 0) * 1,
        tva: (produit.tva ? +produit.tva : 0) * 1,
        txtva: (produit.txtva?.value || 0) * 1,
        ttc: (produit.ttc ? +produit.ttc : 0) * 1,
    });
};

/**
 * Format PriceLineParameters format into ProductLine.
 *
 * @param priceLineParameters
 * @returns
 */
export const priceLineParametersToProductLine = (
    priceLineParameters: pricelineParameters,
): LigneProduitRegistered => {
    const { ht, txtva, tva, ttc } = priceLineParameters;

    return {
        ht: roundTo(ht, 4),
        txtva: {
            label: txtva,
            value: txtva,
        },
        tva: roundTo(tva, 4),
        ttc: roundTo(ttc, 4),
    };
};

/**
 * Utility function to calculate new values of a ProductLine and return it in the same format.
 *
 * (Calculation function return a PriceLineParameters which is the reason of this function.)
 *
 * @param product - ProductLine to evaluate.
 * @returns - Calculated ProductLine.
 */
export const productLineCalculation = (product: LigneProduitRegistered) => {
    const calculateProducts: LigneProduitRegistered = priceLineParametersToProductLine(
        calculatorProduit(product),
    );

    return calculateProducts;
};

export const somme = (array: priceArray) =>
    array.reduce(
        (total, line) => ({
            ht:
                typeof total.ht === 'number' && typeof line.ht === 'number'
                    ? total.ht + line.ht
                    : total.ht,
            tva:
                typeof total.tva === 'number' && typeof line.tva === 'number'
                    ? total.tva + line.tva
                    : total.tva,
            txtva:
                typeof total.txtva === 'number' && typeof line.txtva === 'number'
                    ? total.txtva + line.txtva
                    : total.txtva,
            ttc:
                typeof total.ttc === 'number' && typeof line.ttc === 'number'
                    ? total.ttc + line.ttc
                    : total.ttc,
        }),
        { ht: 0, tva: 0, txtva: 0, ttc: 0 },
    );

export const produitsToSomme: (
    produits: TableauProduits,
    inverse?: boolean,
) => {
    ht: string;
    tva: string;
    txtva: number;
    ttc: string;
} = (produits, inverse) => {
    const sommeProduits = somme(
        produits
            .map((produit) => ({ ...produit, ...calculatorProduit(produit) }))
            .map((produit) => {
                const codeRubrique = produit.rubriqueFacturation
                    ? produit.rubriqueFacturation.id
                    : 1;
                return {
                    ht: roundTo(
                        Number(codeRubrique === 99 ? -(produit.ht || 0) : produit.ht || 0),
                        4,
                    ),
                    tva: roundTo(
                        Number(codeRubrique === 99 ? -(produit.tva || 0) : produit.tva || 0),
                        4,
                    ),
                    txtva: roundTo(Number(produit.txtva || 0), 4),
                    ttc: roundTo(
                        Number(codeRubrique === 99 ? -(produit.ttc || 0) : produit.ttc || 0),
                        4,
                    ),
                } as pricelineParameters;
            }),
    );

    return {
        ht: formatNumber(roundTo(inverse === true ? -sommeProduits.ht : sommeProduits.ht, 4)),
        tva: formatNumber(roundTo(inverse === true ? -sommeProduits.tva : sommeProduits.tva, 4)),
        txtva: roundTo(sommeProduits.txtva, 4),
        ttc: formatNumber(roundTo(inverse === true ? -sommeProduits.ttc : sommeProduits.ttc, 4)),
    };
};

export const compareApiLines = function (
    a: FactureAchatLigneJsonldFactureAchatRead,
    b: FactureAchatLigneJsonldFactureAchatRead,
) {
    // Check if either product belongs to rubrique 1
    if (a.details && a.details.length > 0 && a.details[0].idRubriqueFacturation === 1) {
        return -1;
    }
    if (b.details && b.details.length > 0 && b.details[0].idRubriqueFacturation === 1) {
        return 1;
    }

    // Check if either product belongs to rubrique 99
    if (a.details && a.details.length > 0 && a.details[0].idRubriqueFacturation === 99) {
        return 1;
    }
    if (b.details && b.details.length > 0 && b.details[0].idRubriqueFacturation === 99) {
        return -1;
    }

    // For all other rubriqueFacturation, compare by id
    if (
        a.details &&
        a.details.length > 0 &&
        a.details[0].idRubriqueFacturation !== undefined &&
        b.details &&
        b.details.length > 0 &&
        b.details[0].idRubriqueFacturation !== undefined
    ) {
        return a.details[0].idRubriqueFacturation - b.details[0].idRubriqueFacturation;
    }

    return 0; // If rubriqueFacturation or id is undefined, treat them as equal
};

export const compareProduits = function (a: Partial<LigneProduit>, b: Partial<LigneProduit>) {
    // Check if either product belongs to rubrique 1
    if (a.rubriqueFacturation?.id === 1) {
        return -1;
    }
    if (b.rubriqueFacturation?.id === 1) {
        return 1;
    }

    // Check if either product belongs to rubrique 99
    if (a.rubriqueFacturation?.id === 99) {
        return 1;
    }
    if (b.rubriqueFacturation?.id === 99) {
        return -1;
    }

    // For all other rubriqueFacturation, compare by id
    if (a.rubriqueFacturation?.id !== undefined && b.rubriqueFacturation?.id !== undefined) {
        return a.rubriqueFacturation.id - b.rubriqueFacturation.id;
    }

    return 0; // If rubriqueFacturation or id is undefined, treat them as equal
};

export type UpdateFormTotauxProps = {
    formContext: UseFormReturn<FieldValues, any>;
    reasonToTriggerUpdate?: boolean;
};

export const updateFormTotaux = ({
    formContext,
    reasonToTriggerUpdate = true,
}: UpdateFormTotauxProps) => {
    if (!reasonToTriggerUpdate) return;

    const produits = formContext.getValues('produits');

    if (!produits) {
        return;
    }
    const avoir: boolean = formContext.getValues('avoir') || false;

    const totalvaleurs = produitsToSomme(produits, avoir);

    // Modif des Totaux
    formContext.setValue('total_ht', totalvaleurs.ht);
    formContext.setValue('total_tva', totalvaleurs.tva);
    formContext.setValue('total_txtva', totalvaleurs.txtva);
    formContext.setValue('total_ttc', totalvaleurs.ttc);
};

export type UpdateFormTotalsTtcProps = {
    formContext: UseFormReturn<FieldValues, any>;
};

/**
 * When the totals section is open to manual edition, total TTC should be calculated based on HT and TVA.
 */
export const updateFormTotalsTtc = ({ formContext }: UpdateFormTotalsTtcProps) => {
    // Values from form
    const rawHt = formContext.getValues('total_ht');
    const rawTva = formContext.getValues('total_tva');

    // Values prepared for calculations
    const ht: number = (rawHt ? +rawHt : 0) * 1;
    const tva: number = (rawTva ? +rawTva : 0) * 1;

    const ttc: number = getTTC_HT_TVA(ht, tva);

    formContext.setValue('total_ttc', convertToNumberAndFormatToString(ttc));
};
