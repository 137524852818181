import * as React from 'react';
import { Container } from '@mui/system';
import { TreeItem, TreeItemProps } from '../../atoms';
import TreeGroupItems, { TreeItemBaseProps } from '../TreeGroupItems';

export type TreeItemsProps = {
    items: TreeItemBaseProps[];
    onChange: (values: Array<TreeItemProps['value']>) => void;
    value?: Array<TreeItemProps['value']>;
};

function onlyUnique<T>(value: T, index: number, array: Array<T>) {
    return array.indexOf(value) === index;
}

const TreeItems: React.FunctionComponent<TreeItemsProps> = function ({
    items = [],
    onChange,
    value,
}: TreeItemsProps) {
    let innerItems: TreeItemsProps['items'];

    if (value) {
        innerItems = items.map((item) => {
            if (value.indexOf(item.value) !== -1) {
                return { ...item, isChecked: true };
            }
            return { ...item, isChecked: false };
        });
    } else {
        innerItems = items;
    }

    const allValues = innerItems.map((i) => i.value).filter(onlyUnique);
    if (allValues.length !== innerItems.length) {
        console.error('Two items or more with the same value found.');
    }

    const [values, setValues] = React.useState(
        innerItems.filter((i) => i.isChecked).map((i) => i.value),
    );

    const onChangeWrapper: TreeItemProps['onChange'] = React.useCallback(
        (newValue: TreeItemProps['value'], checked: boolean) => {
            if (checked) {
                setValues([...values, newValue]);
            } else {
                setValues([...values.filter((v) => v !== newValue)]);
            }
        },
        [values, setValues],
    );

    React.useEffect(() => {
        if (onChange) {
            onChange(values);
        }
    }, [onChange, values]);

    // on parcour tous les titles
    // si y a pas de title=> title =''
    // pour tous les items avec ce title => treeGroupItems
    // pour tous les items avec sans title => treeItem

    const itemsTitles = innerItems
        .map((item) => item.title)
        .filter(
            (valueTitle, index, self) =>
                self.findIndex((obj) => JSON.stringify(obj) === JSON.stringify(valueTitle)) ===
                index,
        );

    return (
        <Container
            maxWidth="md"
            style={{ margin: '5px' }}
        >
            <>
                {itemsTitles.map((itemtitle) =>
                    itemtitle ? (
                        <TreeGroupItems
                            items={innerItems.filter((item) => item.title === itemtitle)}
                            label={itemtitle}
                            onChange={onChange}
                        />
                    ) : (
                        innerItems
                            .filter((item) => item.title === itemtitle)
                            .map((item) => (
                                <TreeItem
                                    label={item.label}
                                    value={item.value}
                                    onChange={onChangeWrapper}
                                    isChecked={item.isChecked}
                                    isDisabled={item.isDisabled}
                                />
                            ))
                    ),
                )}
            </>
        </Container>
    );
};

export default TreeItems;
