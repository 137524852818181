/** Here are the paths which have to close the navigation drawer */

export default [
    `/achats/liste/saisir`,
    `/achats/liste/saisir/multi/entete`,
    `/achats/liste/saisir/multi/dossier`,
    `/achats/liste/saisir/multi/recap`,
    `/achats/liste/modifier`,
    `/achats/liste/recap`,
    `/achats/statement`,
];
