/* eslint-disable @typescript-eslint/no-unused-vars */

import {
    SliceFactory,
    FetchCollectionData,
    FetchItemDataType,
    ApiCollectionResponse,
} from '@europrocurement/l2d-redux-utils';
import {
    ConfigurationParameters as AchatsConfigurationParameters,
    Configuration,
    MediaObjectApi,
    MediaObjectApiListMyMediaObjectMediaObjectCollectionRequest,
    MediaObjectJsonldMediaObjectReadTimestampableBlameambleGroupTaggableGroup,
    MediaObjectNoteJsonldMediaObjectReadTimestampableBlameambleGroupTaggableGroup,
} from '../../../openApi/ApiAchats';
import { Order } from '../../../types/apiGenericTypes';
import { ACHATS_REDUCER_NAME } from '../constants';
// import { UseKeycloakService } from '@europrocurement/l2d-keycloak';

export type MediaObjectApiObject =
    MediaObjectJsonldMediaObjectReadTimestampableBlameambleGroupTaggableGroup &
        Record<string, unknown>;

export type MediaObject = MediaObjectApiObject;
export type MediaObjectNote =
    MediaObjectNoteJsonldMediaObjectReadTimestampableBlameambleGroupTaggableGroup;

export const MEDIAOBJECT_SLICE_NAME = 'mediaobject';

export const UNREGISTERED_INVOICES_DATASOURCE_NAME = 'unregisteredInvoices';
export const USER_UNREGISTERED_INVOICES_DATASOURCE_NAME = 'userUnregisteredInvoices';
export const UNASSIGNED_UNREGISTERED_INVOICES_DATASOURCE_NAME = 'unassignedUnregisteredInvoices';

export const additionalMediaObjectsDataSources: Array<string> = [
    UNREGISTERED_INVOICES_DATASOURCE_NAME,
    USER_UNREGISTERED_INVOICES_DATASOURCE_NAME,
    UNASSIGNED_UNREGISTERED_INVOICES_DATASOURCE_NAME,
];

const mapperMediaObject = function (mediaobject: MediaObjectApiObject) {
    return {
        ...mediaobject,
    } as MediaObject;
};

/**
 * Détermine la période pendant laquelle un MediaObject est en cours d'utilisation.
 * Après cette période, peu importe si quelqu'un est toujours dessus, le MediaObject est de nouveau disponible.
 */
export const dateOffsetMediaObject = 2 * 60 * 1000;

/**
 * Test si un MediaObject est en cours d'utilisation ou non.
 *
 * @param mediaObject MediaObject
 * @returns boolean
 */
export const isProcessingMediaObject = (mediaObject: MediaObject): boolean => {
    if (!mediaObject.dateDebutTraitement) {
        return false;
    }

    if (mediaObject.dateDebutTraitement) {
        if (
            new Date(mediaObject.dateDebutTraitement) >
            new Date(new Date().getTime() - dateOffsetMediaObject)
        ) {
            return true;
        }
    }

    return false;
};

export function createMediaObjectSlice(configuration: AchatsConfigurationParameters) {
    const conf = new Configuration(configuration);
    const api = new MediaObjectApi(conf);

    const fetchMediaObjectsCollection: FetchCollectionData<MediaObjectApiObject> = ({
        search,
        pagination,
        filters,
        orders,
        xIdSociete,
        rootstate,
    }) => {
        let createdBy: string | undefined;
        let startDate: string | undefined;
        let endDate: string | undefined;
        let linkedFacture: boolean | undefined;
        let available: boolean | undefined;
        let forceInterne: boolean | undefined;
        let orderId: Order;
        let orderCreatedAt: Order;

        if (filters.createdBy) {
            createdBy = filters.createdBy as string;
        }

        if (filters.linkedFacture !== undefined) {
            linkedFacture = filters.linkedFacture as boolean;
        }

        if (filters.available !== undefined) {
            available = filters.available as boolean;
        }

        if (filters.forceInterne !== undefined) {
            forceInterne = filters.forceInterne as boolean;
        }

        if (filters.processingFacture === false) {
            startDate = new Date(new Date().getTime() - dateOffsetMediaObject).toISOString();
        } else if (filters.processingFacture === true) {
            endDate = new Date(new Date().getTime() - dateOffsetMediaObject).toISOString();

            startDate = new Date(new Date().getTime() + dateOffsetMediaObject).toISOString();
        }

        orders.forEach((item) => {
            if (item.field === 'id') {
                orderId = item.value;
            }
            if (item.field === 'createdAt') {
                orderCreatedAt = item.value;
            }
        });

        const requestParameters: MediaObjectApiListMyMediaObjectMediaObjectCollectionRequest = {
            // idSociete: xIdSociete,
            page: pagination.page + 1,
            itemsPerPage: pagination.itemsPerPage,
            createdBy,
            existsFacturesAchat: linkedFacture,
            dateDebutTraitementBefore: startDate,
            dateDebutTraitementAfter: endDate,
            available,
            forceInterne,
            orderId,
            orderCreatedAt,
        };

        // processingFacture
        if (filters.createdByMe === true) {
            // Fonction spécifique pour les created by me
            return api
                .listMyMediaObjectMediaObjectCollection(requestParameters)
                .then((res) => res.data) as Promise<ApiCollectionResponse<MediaObjectApiObject>>;
        }

        return api
            .listMediaObjectMediaObjectCollection(requestParameters)
            .then((res) => res.data) as Promise<ApiCollectionResponse<MediaObjectApiObject>>;
    };

    const fetchMediaObjectsItem: FetchItemDataType<MediaObjectApiObject> = ({
        idItem,
        search,
        pagination,
        filters,
        orders,
        xIdSociete,
        rootstate,
    }) =>
        api
            .getMediaObjectItem({
                id: String(idItem),
            })
            .then((res) => res.data) as Promise<MediaObjectApiObject>;

    const { slice: mediaobjectSlice, dataSourcesThunks: mediaobjectDataSourcesThunks } =
        SliceFactory.createSlice<MediaObjectApiObject, MediaObject>(
            MEDIAOBJECT_SLICE_NAME,
            ACHATS_REDUCER_NAME,
            additionalMediaObjectsDataSources,
            fetchMediaObjectsCollection,
            fetchMediaObjectsItem,
            mapperMediaObject,
            {
                processingFacture: false,
                available: true,
            },
            undefined,
            [
                {
                    field: 'createdAt',
                    value: 'desc',
                },
            ],
        );

    return { mediaobjectSlice, mediaobjectDataSourcesThunks };
}
