/* eslint-disable no-param-reassign */
import { AnnonceLegaleAnnonceLegaleAnnonceLegaleReadItem } from '@europrocurement/l2d-domain/openApi/ApiAnnoncesLegales';
import { TypeDeDocumentJsonldTypedocumentRead } from '@europrocurement/l2d-domain/openApi/ApiOffre';
import { createSlice } from '@reduxjs/toolkit';

export type GenericCachedResult<T> = Array<T> | null;
type CacheddDocument = TypeDeDocumentJsonldTypedocumentRead['typeDocument'];
type CachedSubType = {
    label: string;
    value: number;
    category: AnnonceLegaleAnnonceLegaleAnnonceLegaleReadItem['categorie'];
};
type CachedDeparment = {
    label: string;
    value: number;
};

export type CachedStore = {
    documents: GenericCachedResult<CacheddDocument>;
    subTypes: GenericCachedResult<CachedSubType>;
    departments: GenericCachedResult<CachedDeparment>;
};

export const OFFER_CACHED_KEY = 'cached';

const initialCacheState: CachedStore = {
    documents: null,
    subTypes: null,
    departments: null,
};

const offerCacheSlice = createSlice({
    name: OFFER_CACHED_KEY,
    initialState: initialCacheState,
    reducers: {
        updateCachedDocuments(state, action) {
            state.documents = action.payload;
        },
        updateCachedSubTypes(state, action) {
            state.subTypes = action.payload;
        },
        updateCachedDepartments(state, action) {
            state.departments = action.payload;
        },
    },
});

export const { updateCachedDocuments } = offerCacheSlice.actions;

export default offerCacheSlice;
