import * as React from 'react';

import { Box, Typography, Avatar, Button, Divider } from '@mui/material';
import { KeycloakHasRole, KeycloakIsAuth, UseKeycloakService } from '@europrocurement/l2d-keycloak';
import { Link } from 'react-router-dom';
import {
    FaOptionIcon,
    codeIcon,
    companyIcon,
    mailIcon,
    preferencesIcon,
    quitIcon,
    userIcon,
    usersIcon,
} from '@europrocurement/l2d-icons';
import { useTheme } from '@mui/system';

export type ProfileDropdownProps = {
    jobName?: string;
    avatarSrc?: string;
    logout: () => void;
    navigateToPrescripteur?: () => void;
    navigateToMembers?: () => void;
    openSettings?: () => void;
};
const ProfileDropdown: React.FunctionComponent<ProfileDropdownProps> = function ({
    jobName,
    avatarSrc,
    logout,
    navigateToPrescripteur,
    navigateToMembers,
    openSettings,
}: ProfileDropdownProps) {
    const theme = useTheme();

    const keycloak = UseKeycloakService();

    return (
        <Box
            sx={{
                padding: '15px',
            }}
        >
            <Typography variant="h1">Mon Compte</Typography>
            <Box
                role="menu"
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    gap: '5px',
                }}
            >
                <Box>
                    <Avatar
                        alt="avatar"
                        src={avatarSrc}
                        sx={{ width: '90px', height: '90px' }}
                    />
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '5px',
                    }}
                >
                    {keycloak.getName() ? (
                        <Typography variant="h2">{keycloak.getName()}</Typography>
                    ) : null}
                    {jobName ? <Typography variant="body1">{jobName}</Typography> : null}
                    {keycloak.getEmail() ? (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '10px',
                            }}
                        >
                            <FaOptionIcon {...mailIcon.props} />
                            <Typography variant="body1">{keycloak.getEmail()}</Typography>
                        </Box>
                    ) : null}
                </Box>
            </Box>
            <Typography
                variant="body1"
                sx={{ margin: '10px', color: theme.palette.text.secondary }}
            >
                Mon profil
            </Typography>
            <Box
                sx={{
                    margin: '8px',
                }}
            >
                <Link
                    to={keycloak.getKc().createAccountUrl()}
                    target="_blank"
                    style={{
                        textDecoration: 'none',
                        color: theme.palette.text.secondary,
                        padding: '6px 8px', // style pour aligner avec les <Button>
                    }}
                >
                    <FaOptionIcon
                        {...userIcon.props}
                        sx={{ marginRight: '8px' }}
                    />
                    Mon compte
                </Link>
            </Box>
            {openSettings ? (
                <Box
                    sx={{
                        margin: '8px',
                    }}
                >
                    <Button
                        variant="text"
                        onClick={openSettings}
                        sx={{ color: 'text.secondary' }}
                    >
                        <FaOptionIcon
                            {...preferencesIcon.props}
                            sx={{ marginRight: '10px' }}
                        />
                        Mes préférences
                    </Button>
                </Box>
            ) : null}
            {navigateToPrescripteur || navigateToMembers ? (
                <Typography
                    variant="body1"
                    sx={{ margin: '10px', color: theme.palette.text.secondary }}
                >
                    Paramètres de la société
                </Typography>
            ) : null}
            {navigateToPrescripteur ? (
                <Box
                    sx={{
                        margin: '8px',
                    }}
                >
                    <Button
                        onClick={navigateToPrescripteur}
                        variant="text"
                        sx={{ color: 'text.secondary' }}
                    >
                        <FaOptionIcon
                            {...companyIcon.props}
                            sx={{ marginRight: '10px' }}
                        />
                        Ma structure
                    </Button>
                </Box>
            ) : null}
            {navigateToMembers ? (
                <Box
                    sx={{
                        margin: '8px',
                    }}
                >
                    <Button
                        onClick={navigateToMembers}
                        variant="text"
                        sx={{ color: 'text.secondary' }}
                    >
                        <FaOptionIcon
                            {...usersIcon.props}
                            sx={{ marginRight: '10px' }}
                        />
                        Membres
                    </Button>
                </Box>
            ) : null}
            <KeycloakIsAuth>
                <>
                    <KeycloakHasRole kcRole="realm:debug">
                        <Typography
                            variant="body1"
                            sx={{ margin: '10px', color: theme.palette.text.secondary }}
                        >
                            Administration
                        </Typography>

                        <Box
                            sx={{
                                margin: '8px',
                            }}
                        >
                            <Button
                                color="primary"
                                variant="text"
                                component={Link}
                                to="/debug"
                                disableElevation
                                sx={{ color: 'text.secondary' }}
                            >
                                <FaOptionIcon
                                    {...codeIcon.props}
                                    sx={{ marginRight: '10px' }}
                                />
                                Outils de développement
                            </Button>
                        </Box>
                    </KeycloakHasRole>
                    <Divider />
                    <Box
                        sx={{
                            margin: '8px',
                        }}
                    >
                        <Button
                            onClick={logout}
                            variant="text"
                            title="Déconnexion"
                            sx={{ color: 'text.secondary' }}
                        >
                            <FaOptionIcon
                                {...quitIcon.props}
                                sx={{ marginRight: '10px' }}
                            />
                            Se déconnecter
                        </Button>
                    </Box>
                </>
            </KeycloakIsAuth>
        </Box>
    );
};

export default ProfileDropdown;
