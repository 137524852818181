import React, { type FunctionComponent } from 'react';
import { DisplayOpts, SearchOpts } from '../DatatableHeader';
import FlexyFiltersList from '../FlexyFiltersList/FlexyFiltersList';
import FlexySearch from '../FlexySearch/FlexySearch';

export type FlexyFiltersProps = {
    searchOpts?: SearchOpts;
    displayOpts?: DisplayOpts;
    // handleExport?: DatatableHeaderProps['handleExport'];
    toggleRightMenu?: () => void;
    isSearch?: boolean;
    isExport?: boolean;
    isFilters?: boolean;
};

const FlexyFilters: FunctionComponent<FlexyFiltersProps> = function ({
    searchOpts,
    displayOpts,
    // handleExport,
    toggleRightMenu,
    isSearch = true,
    isExport = false,
    isFilters = true,
}: FlexyFiltersProps) {
    return searchOpts || displayOpts ? (
        // || handleExport
        <>
            {isSearch ? (
                <FlexySearch
                    searchOpts={searchOpts}
                    toggleRightMenu={toggleRightMenu}
                />
            ) : null}
            {isExport ? null /* TODO */ : null}
            {isFilters ? <FlexyFiltersList filtersOpts={searchOpts?.filtersOpts} /> : null}
        </>
    ) : null;
};

export default FlexyFilters;
