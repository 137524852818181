import * as React from 'react';
import { prescribersApi } from '@b2d/redux/RootStore';
import { Box, Grid, Typography } from '@mui/material';
import { FlexyFormLabel, FlexyHeaderForm, FlexySelect } from '@europrocurement/flexy-components';
import useApiRequest from '@b2d/hooks/useApiRequest';
import { RelationSocieteTiersJsonldTiersRead } from '@europrocurement/l2d-domain/openApi/ApiTiers';
import { usePrescriberUtils } from '@b2d/hooks/prescriberHooks';

type BillingOptions = {
    paymentTerm: unknown;
    paymentMethod: unknown;
};

const AccountingSettings: React.FunctionComponent = function () {
    const {
        companyRelation,
        companyId,
        prescriber,
        methods: { reloadPrescriber },
    } = usePrescriberUtils();

    const { request, requestState } = useApiRequest();
    const { isProcessing } = requestState;

    const paymentTerms = [
        { value: 1, label: 'Comptant' },
        { value: 2, label: 'A Réception de Facture' },
        { value: 3, label: 'Paiement à 30 Jours' },
        { value: 4, label: 'Paiement à 45 Jours' },
        { value: 5, label: 'Paiement à 60 Jours' },
    ];

    // Those values came from TBL_Def_Mode_Paiement => load those options as config option in option store instead of hard coded values
    const paymentMethods = [
        { value: 1, code: 'CHE', label: 'Chèque' },
        { value: 2, code: 'MPR', label: 'Prelèvement Mensuel' },
        { value: 3, code: 'TPR', label: 'Prelèvement Trimestriel' },
        { value: 4, code: 'EPR', label: 'Prelèvement Echeancier' },
        { value: 5, code: 'CB', label: 'Carte' },
        { value: 6, code: 'VIR', label: 'Virement' },
        { value: 7, code: 'ESP', label: 'Espèce' },
    ];

    const getPaymentTermOption = (paymentTermId: number) =>
        paymentTerms.find(({ value }: { value: number }) => value === paymentTermId);

    const getPaymentMethodOption = (paymentMethodId: number) =>
        paymentMethods.find(({ value }: { value: number }) => value === paymentMethodId);

    const defaultOptions =
        companyRelation?.delaiPaiement && companyRelation?.modePaiement?.id
            ? {
                  paymentTerm: getPaymentTermOption(companyRelation.delaiPaiement),
                  paymentMethod: getPaymentMethodOption(companyRelation.modePaiement.id),
              }
            : {
                  paymentTerm: null,
                  paymentMethod: null,
              };

    const [optionsState, setOptionsState] = React.useState<BillingOptions>(defaultOptions);

    const formLabelStyle = { margin: '15px', width: '15%' };

    const updateCommercialConditionsRequest = function (
        updatedCompanyRelation: RelationSocieteTiersJsonldTiersRead,
    ) {
        if (!prescriber.id) {
            throw new Error('missing prescriber id');
        }

        return prescribersApi.updateRelationSocietePrescripteurRelationSocieteTiersItem({
            tiers: prescriber.id,
            xIdSociete: companyId,
            relationSocieteTiersRelationSocieteTiersJsonldPrescripteurWriteRelationSociete:
                updatedCompanyRelation,
            societe: companyId.toString(),
        });
    };

    const updateCompanyRelation = (field: string, value: unknown) => {
        if (!companyRelation) {
            return;
        }
        const updatedCompanyRelation = {
            ...companyRelation,
            ...(field === 'delaiPaiement' && { [field]: value }),
            ...(field === 'modePaiement' && {
                [field]: {
                    ...companyRelation.modePaiement,
                    id: value,
                },
            }),
        };

        const updateRequest = updateCommercialConditionsRequest(
            updatedCompanyRelation as RelationSocieteTiersJsonldTiersRead,
        );

        if (updateRequest) {
            request(updateRequest, { successCallback: () => reloadPrescriber() });
        }
    };

    return (
        <Grid
            container
            spacing={4}
            rowSpacing={1}
        >
            <Grid
                item
                xl={12}
                lg={12}
            >
                <FlexyHeaderForm
                    label="Paiement"
                    outlined
                />

                <Box
                    display="flex"
                    alignItems="center"
                    sx={{ marginTop: '15px' }}
                >
                    <FlexyFormLabel sx={formLabelStyle}>
                        <Typography component="span">Délai de paiement</Typography>
                    </FlexyFormLabel>
                    <Box sx={{ width: '50%' }}>
                        <FlexySelect
                            options={paymentTerms}
                            isDisabled={isProcessing}
                            value={optionsState.paymentTerm}
                            onChange={({ value }: { value: number }) => {
                                setOptionsState({
                                    ...optionsState,
                                    paymentTerm: getPaymentTermOption(value),
                                });
                                updateCompanyRelation('delaiPaiement', value);
                            }}
                        />
                    </Box>
                </Box>
                <Box
                    display="flex"
                    alignItems="center"
                >
                    <FlexyFormLabel sx={formLabelStyle}>
                        <Typography component="span">Mode de paiement</Typography>
                    </FlexyFormLabel>
                    <Box sx={{ width: '50%' }}>
                        <FlexySelect
                            options={paymentMethods}
                            value={optionsState.paymentMethod}
                            isDisabled={isProcessing}
                            onChange={({ value }: { value: number }) => {
                                setOptionsState({
                                    ...optionsState,
                                    paymentMethod: getPaymentMethodOption(value),
                                });
                                updateCompanyRelation('modePaiement', value);
                            }}
                        />
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
};

export default AccountingSettings;
