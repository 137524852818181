import { L2D_THEME } from '@europrocurement/l2d-domain';
import { createTheme, type Theme } from '@mui/material/styles';
import { frFR } from '@mui/material/locale';
import { themesOptions } from './Theme-variable';

const findTheme = themesOptions.find((theme) => theme.name === L2D_THEME);

const createdTheme = createTheme(findTheme, frFR);

export const DefaultTheme: Theme = {
    ...createdTheme,
    palette: {
        ...createdTheme.palette,
        primary: { ...createdTheme.palette.primary, main: createdTheme.palette.primary.main },
        background: {
            default: '#fafbfb',
            dark: '#ffffff',
            paper: '#ffffff',
        },
        text: {
            primary: 'rgba(0, 0, 0, 0.87)',
            secondary: '#777e89',
            disabled: 'rgba(0, 0, 0, 0.50)',
            light: 'rgba(0, 0, 0, 0.25)',
        },
    },
};

export default DefaultTheme;
