import {
    SliceFactory,
    FetchCollectionData,
    FetchItemDataType,
    ApiCollectionResponse,
} from '@europrocurement/l2d-redux-utils';
import {
    ConfigurationParameters as OffreConfigurationParameters,
    Configuration,
    OffresApi,
    OffresOffreRead,
} from '../../../openApi/ApiOffre';
import { OFFRE_REDUCER_NAME } from '../constants';

export type OfferApiObject = OffresOffreRead & Record<string, unknown>;

export type Offers = OfferApiObject;

export const OFFERS_SLICE_NAME = 'offers';

const mapperOffers = function (offers: OfferApiObject) {
    return {
        ...offers,
    } as Offers;
};

export function createOffersSlice(configuration: OffreConfigurationParameters) {
    const conf = new Configuration(configuration);
    const api = new OffresApi(conf);

    const fetchOffersCollection: FetchCollectionData<OfferApiObject> = ({
        pagination: { page, itemsPerPage },
        xIdSociete,
    }) =>
        api
            .apiOffresGetCollection({
                xIdSociete,
                page: page + 1,
                itemsPerPage,
            })
            .then((res) => res.data)
            .catch((error) => {
                console.error(error);
            }) as Promise<ApiCollectionResponse<OfferApiObject>>;

    const fetchOffersItem: FetchItemDataType<OfferApiObject> = ({
        idItem,
        // pagination,
        xIdSociete,
    }) =>
        api
            .apiOffresIdGet({ id: String(idItem), xIdSociete })
            .then((res) => res.data) as Promise<OfferApiObject>;

    const { slice: offersSlice, dataSourcesThunks: offersDataSourcesThunks } =
        SliceFactory.createSlice<OfferApiObject, Offers>(
            OFFERS_SLICE_NAME,
            OFFRE_REDUCER_NAME,
            [],
            fetchOffersCollection,
            fetchOffersItem,
            mapperOffers,
        );

    return { offersSlice, offersDataSourcesThunks };
}
