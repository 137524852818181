/**
 * Remove non allowed characters / replace comma with dot / allow one dot
 * @param string
 */
export const sanitizePriceString = (string: string) => {
    // Trim starting / ending dots or 0
    let value = string.replace(/^[0|.]*|[0|.]*$/gm, '');
    // Replace comas
    value = value.replaceAll(/,/g, '.');
    // Remove extra dots
    value = value.replace(/\./g, (char, index, text) => (text.indexOf(char) === index ? char : ''));
    // Remove non allowed chars
    value = value.replaceAll(/[^\d|.]/g, '');
    // Retrim potential starting / ending 0
    value = value.replace(/^[0|.]*|[0|.]*$/gm, '');
    return value;
};

const formatDecimalValues = (decimalValues: string) => {
    let value = decimalValues;
    const maxDecimalNumbers = 4;
    // Limit decimal length
    if (decimalValues.length > maxDecimalNumbers) {
        value = decimalValues.substring(0, maxDecimalNumbers);
    }
    // Or pad it with 0
    if (decimalValues.length < maxDecimalNumbers) {
        value = value.padEnd(4, '0');
    }
    // Apply decimal numbers specific rules
    if (value.charAt(3) !== '0') {
        return value;
    }

    if (value.charAt(2) !== '0') {
        return value.substring(0, 3);
    }

    // Exclude 0 only decimal part
    if (/^[0]+$/.test(decimalValues)) {
        return null;
    }

    return value.substring(0, 2);
};

/**
 * Can take a decimal 19,4 as input. Output format is based on those rules :
 *
 * - Only display used decimals formats
 * - 13.0000 -> 13
 * - 13.0010 -> 13.001
 * - 13.3700 -> 13.37
 * - 13.3000 -> 13.30
 *
 * - Dot is use as separator
 *
 * @param price
 * @returns
 */
const displayPrice = (price: string, appendUnit?: string) => {
    let value = price;

    const allowedFormat = /^[1-9]\d*(\.\d+)?$/;
    if (!allowedFormat.test(value)) {
        value = sanitizePriceString(price);
    }

    const isDecimal = /[.]/.test(value);

    if (!isDecimal) {
        return value;
    }

    const [integerPart, decimalPart] = value.split('.');
    const decimals = formatDecimalValues(decimalPart);

    if (decimals) {
        return `${integerPart}.${decimals}`;
    }

    return appendUnit ? `${integerPart} ${appendUnit}` : integerPart;
};

export const formatPrice = (price: number | string | null | undefined, appendUnit?: string) => {
    let value = price;

    if (!price) {
        return '';
    }

    if (typeof price === 'number') {
        value = price.toString();
    }

    return displayPrice(value as string, appendUnit);
};

/** Price displaying rules for article */
export const displayArticlePriceLabel = (price: number | null): string => {
    if (!price) {
        return 'Aucun tarif défini';
    }

    if (price === 0) {
        return 'Article offert';
    }

    return `Prix unitaire : ${formatPrice(price)} €`;
};
