import React, { ClipboardEvent, useCallback, useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { useDebounce } from 'use-debounce';
import {
    Autocomplete,
    AutocompleteChangeDetails,
    AutocompleteChangeReason,
    AutocompleteInputChangeReason,
    AutocompleteRenderOptionState,
    CircularProgress,
    SxProps,
    Tooltip,
    Typography,
} from '@mui/material';
import {
    FlexyFormLabel,
    FlexyTextField,
    FlexyTextFieldProps,
} from '@europrocurement/flexy-components';
import { getDataThunkType, type DataSource } from '@europrocurement/l2d-redux-utils';
import { AppDispatch } from '@europrocurement/flexy-components/redux/storeConfig/store';

export type AutocompleteOnChange<T> = (
    event: React.SyntheticEvent<Element, Event>,
    value: T,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<unknown>,
) => void;

export type AutocompleteOnInputChange<T> = (
    event: React.SyntheticEvent<Element, Event>,
    value: T,
    reason: AutocompleteInputChangeReason,
) => void;

const defaultNoOptionTextFn = (reason: 'noinput' | 'unsearchable' | 'nooptions') => {
    switch (reason) {
        case 'noinput':
            return 'Merci de compléter le champ';
        case 'unsearchable':
            return 'La saisie est invalide';
        case 'nooptions':
        default:
            return 'Aucune options disponible pour cette saisie';
    }
};

export type AutocompleteEventType<T> = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: T,
    reason: AutocompleteChangeReason,
    details: AutocompleteChangeDetails<unknown> | undefined,
) => void;

export type AutocompleteStoreEvents<T> = {
    onBlur?: AutocompleteEventType<T>;
    onSelectOption?: AutocompleteEventType<T>;
    onClear?: AutocompleteEventType<T>;
};

export type AutocompleteStoreProps<T extends Record<string, unknown>> = {
    // Source de donnée ou récupérer les options
    dataSource: DataSource<T>;
    // Fonction a appeler pour fetch les données
    fetchData: getDataThunkType<T>;
    // Champ a rendre dans l'input
    renderField: keyof T;
    // Label de l'input
    inputlabel: string;
    // propriétée idantifiant l'objet ( pour verifier l'équité entre deux objets )
    idProperty: Extract<keyof T, string>;
    // booléen qui définis si le champ est requis
    required?: boolean;
    // Fonction qui permet de rendre une option, il faut rendre un element <li> pour un affichage correcte. par défaut item[renderField]
    renderOption?: (
        props: React.HTMLAttributes<HTMLLIElement>,
        item: T,
        state: AutocompleteRenderOptionState,
    ) => React.ReactNode;
    // Fonction simplifiée de renderOption, se charge de faire l'élément <li>
    simpleRenderOption?: (item: T) => React.ReactNode;
    // Fonction qui rend l'item dans l'inpunt, par défaut item[renderField]
    getOptionLabel?: (item: T) => string;
    // callback appelé au changement
    onChange?: AutocompleteOnChange<T>;
    // callback appelé au changement de l'input
    onInputChange?: AutocompleteOnInputChange<T>;
    // valeur par défaut a l'initalisation
    defaultValue?: T; // Defined like 'any' in MUI. It's not me sorry...
    defaultInputValue?: string; // Defined like 'any' in MUI. It's not me sorry...
    // Propriétés sx
    sx?: SxProps;
    // Props fournies a l'input
    inputProps?: Omit<FlexyTextFieldProps, 'defaultValue' | 'variant'>;
    // Props fournies a l'input
    // preload?: boolean;
    searchability?: (terms: string) => boolean | string;
    noOptionsTextFn?: (reason: 'noinput' | 'unsearchable' | 'nooptions') => React.ReactNode;
    reset?: boolean;
    events?: AutocompleteStoreEvents<T>;
    debounce?: number;
    disabled?: boolean;
};

export const AutocompleteStore = function <T extends Record<string, unknown>>({
    dataSource,
    fetchData,
    renderField,
    inputlabel,
    idProperty,
    required = false,
    renderOption,
    simpleRenderOption,
    getOptionLabel,
    onChange,
    defaultValue,
    defaultInputValue,
    sx = {
        width: '100%',
    },
    inputProps = {
        fullWidth: true,
    },
    searchability,
    noOptionsTextFn = defaultNoOptionTextFn,
    reset = false,
    events = {},
    disabled = false,
    debounce = 300,
}: AutocompleteStoreProps<T>) {
    const dispatch = useDispatch<AppDispatch>();

    const [inputValue, setInputValue] = useState<string>(defaultInputValue || '');
    const [value, setValue] = useState<T | null>(defaultValue || null);
    const [noOptions, setNoOptions] = useState<React.ReactNode>('merci de completer le champ');
    const [debouncedSearchTerm, controls] = useDebounce(inputValue, debounce);

    const [lastSearch, setLastSearch] = useState<string>('');

    /**
     * Efface totalement les données du champs lors
     * du passage à true de reset.
     * (bien s'assurrer que le changement d'état soit temporaire)
     */
    useEffect(() => {
        if (reset) {
            setInputValue('');
            setValue(null);
            dispatch({
                type: `${dataSource.slicename}/clear${dataSource.name}Data`,
            });
        }
    }, [dataSource.name, dataSource.slicename, dispatch, reset]);

    useEffect(() => {
        if (inputValue === '') {
            dispatch({
                type: `${dataSource.slicename}/clear${dataSource.name}Data`,
            });
        }
    }, [dataSource.name, dataSource.slicename, dispatch, inputValue]);

    useEffect(() => {
        setInputValue(defaultInputValue || '');
    }, [defaultInputValue]);

    useEffect(() => {
        if (
            dataSource.data.length === 0 &&
            (searchability ? searchability(debouncedSearchTerm) : true) &&
            debouncedSearchTerm !== ''
        ) {
            setNoOptions(noOptionsTextFn('nooptions'));
        }
    }, [
        dataSource.data.length,
        dataSource.status,
        debouncedSearchTerm,
        noOptionsTextFn,
        searchability,
    ]);

    useEffect(() => {
        if (debouncedSearchTerm !== '') {
            // on ne change qu'en cas de clear ou de saisie user
            const searchable = searchability ? searchability(debouncedSearchTerm) : true;
            if (!searchability || searchable === true) {
                if (debouncedSearchTerm !== lastSearch && dataSource.status !== 'loading') {
                    setLastSearch(debouncedSearchTerm);
                    dispatch({
                        type: `${dataSource.slicename}/set${dataSource.name}Search`,
                        payload: { search: debouncedSearchTerm },
                    });
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    dispatch(fetchData({}) as any);
                }
            } else if (searchable === false) {
                setNoOptions(noOptionsTextFn('unsearchable'));
            } else {
                setNoOptions(searchable);
            }
        } else {
            setNoOptions(noOptionsTextFn('noinput'));
        }
    }, [
        dataSource.name,
        dataSource.slicename,
        dataSource.status,
        debouncedSearchTerm,
        dispatch,
        fetchData,
        lastSearch,
        noOptionsTextFn,
        searchability,
    ]);

    const handleInputChange = useCallback(
        (e: React.SyntheticEvent, _value: string, reason: AutocompleteInputChangeReason) => {
            if (reason !== 'reset') controls.cancel(); // reset du debounce
            if (reason === 'reset' && !_value && inputValue) {
                return;
            } // on est entrain de saisir empêche de supprimer le premier caractère de l'input

            // on a une valeur dans le text input et une valeur séléctionée ( le champ )
            if (_value && _value !== '' && value !== null) {
                if (dataSource.selected) {
                    dispatch({
                        type: `${dataSource.slicename}/delete${dataSource.name}Selected`,
                    });
                }
                setValue(defaultValue || null);
                setTimeout(() => setInputValue(defaultInputValue || ''), 100);
            } else {
                setInputValue(_value);
            }
        },
        [
            controls,
            inputValue,
            value,
            dataSource.name,
            dataSource.selected,
            dataSource.slicename,
            defaultValue,
            dispatch,
            defaultInputValue,
        ],
    );

    // Conditionne l'input après selection
    const getOptionLablFn = (option: T) => {
        if (!option) {
            return '';
        }
        if (getOptionLabel) {
            return getOptionLabel(option);
        }

        return option[renderField] as string;
    };

    const renderOptionFn: (
        props: React.HTMLAttributes<HTMLLIElement>,
        item: T,
        state: AutocompleteRenderOptionState,
    ) => React.ReactNode = (props, item, state) => {
        if (renderOption) {
            return renderOption(props, item, state);
        }
        if (simpleRenderOption) {
            return (
                <li
                    {...props}
                    key={`acstore-item-${item[idProperty]}`}
                >
                    <Typography>{simpleRenderOption(item)}</Typography>
                </li>
            );
        }
        return (
            <li
                {...props}
                key={`acstore-item-${item[idProperty]}`}
            >
                <Typography>{getOptionLablFn(item)}</Typography>
            </li>
        );
    };

    const handleValueChange: AutocompleteOnChange<T> = (event, newValue, reason, details) => {
        switch (reason) {
            case 'blur':
                // Si j'ai une recherche ( inputValue ) et que j'ai de la data dans mon state et j'ai une seul donnée
                if (inputValue === '' || dataSource.data.length !== 1) {
                    dispatch({
                        type: `${dataSource.slicename}/delete${dataSource.name}Selected`,
                    });
                    setValue(null);
                } else {
                    dispatch({
                        type: `${dataSource.slicename}/set${dataSource.name}Selected`,
                        payload: dataSource.data[0],
                    });
                    setValue(dataSource.data[0]);
                }
                if (events?.onBlur) {
                    events?.onBlur(event, newValue, reason, details);
                }
                break;
            case 'selectOption':
                dispatch({
                    type: `${dataSource.slicename}/set${dataSource.name}Selected`,
                    payload: newValue,
                });
                setValue(newValue);
                // event.stopPropagation(); marche pas
                if (events?.onSelectOption) {
                    events?.onSelectOption(event, newValue, reason, details);
                }
                break;
            case 'clear':
                dispatch({
                    type: `${dataSource.slicename}/delete${dataSource.name}Selected`,
                });
                setInputValue('');
                setValue(null);
                if (events?.onClear) {
                    events?.onClear(event, newValue, reason, details);
                }
                break;
            default:
                break;
        }

        if (onChange) {
            onChange(event, newValue, reason, details);
        }
    };

    const onPasteNewValue = useCallback(
        (event: ClipboardEvent<HTMLInputElement>) => {
            if (event.clipboardData && event.clipboardData.getData('text').trim() !== inputValue) {
                const newValue = event.clipboardData.getData('text').trim();
                if (value !== null) {
                    dispatch({
                        type: `${dataSource.slicename}/delete${dataSource.name}Selected`,
                    });
                    setValue(null);
                }

                setTimeout(() => {
                    setInputValue(newValue);
                }, 100);
                event.preventDefault();
            }
        },
        [dataSource.name, dataSource.slicename, dispatch, inputValue, value],
    );

    /**
     * Nouvel autocomplete :
     *  ajout de :
     *      clearOnEscape
     *      clearOnBlur
     *      autoSelect
     *      autoHighlight
     *
     *  a tester :
     *      - le bon fonctionnement des event finaux
     *      - le select a la main
     *      - le 'tab'
     *      - le 'enter'
     *      - le clear a la main
     *      - le je m'envais sans avoir selectionné
     *
     *  les plus :
     *
     *  les moins :
     */

    return (
        <>
            <FlexyFormLabel data-testid="test-id-FlexyInput-label">
                <Typography component="span">
                    {inputlabel}
                    {required ? (
                        <Tooltip title="Le champ est requis">
                            <Typography
                                component="span"
                                color="danger.main"
                            >
                                &nbsp;&nbsp;*
                            </Typography>
                        </Tooltip>
                    ) : null}
                </Typography>
            </FlexyFormLabel>
            <Autocomplete
                clearOnEscape
                // blurOnSelect // Provoque un bug A utiliser avec parcimonie
                clearOnBlur
                autoSelect
                autoHighlight
                value={value}
                inputValue={inputValue}
                onChange={handleValueChange}
                onInputChange={handleInputChange}
                options={dataSource.data}
                getOptionLabel={getOptionLablFn}
                sx={{ ...sx }}
                noOptionsText={noOptions}
                loadingText={<CircularProgress sx={{ margin: 'auto' }} />}
                loading={dataSource.status === 'loading'}
                renderOption={renderOptionFn}
                renderInput={(params) => (
                    <FlexyTextField
                        variant="outlined"
                        {...params}
                        {...inputProps}
                    />
                )}
                filterOptions={(x) => x}
                isOptionEqualToValue={(option: T, valueToCompare: T) =>
                    option[idProperty] === valueToCompare[idProperty]
                }
                onPaste={onPasteNewValue}
                disabled={disabled}
            />
        </>
    );
};
