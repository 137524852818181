import * as React from 'react';

import { Tooltip, IconButton, type IconButtonProps } from '@mui/material';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export type ClearButtonProps = {
    tooltip?: string;
    size?: IconButtonProps['size'];
    color?: IconButtonProps['color'];
    onClick?: IconButtonProps['onClick'];
    disabled?: IconButtonProps['disabled'];
};

const ClearButton: React.FunctionComponent<ClearButtonProps> = function ({
    size = 'medium',
    color = 'error',
    tooltip = 'Supprimer',
    onClick = undefined,
    disabled = false,
}: ClearButtonProps) {
    return (
        <Tooltip
            sx={{
                position: 'absolute',
                top: '11px',
                right: '0px',
            }}
            title={tooltip}
        >
            <IconButton {...{ size, color, onClick, disabled }}>
                <FontAwesomeIcon icon={faXmark} />
            </IconButton>
        </Tooltip>
    );
};

export default ClearButton;
