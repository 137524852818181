import { useNavigate } from 'react-router';

export const NavigateNotFound = function () {
    // const location = useLocation();
    const navigate = useNavigate();

    return () => {
        navigate('/errors/404');
    };

    // return <Navigate to="/errors/404" state={{ from: location, notFoundError: true }} />;
};

export default NavigateNotFound;
