import {
    SliceFactory,
    FetchCollectionData,
    ApiCollectionResponse,
    FetchItemDataType,
} from '@europrocurement/l2d-redux-utils';
import {
    ConfigurationParameters as DossiersConfigurationParameters,
    Configuration,
    FormaliteApiApiFormalitesIdGetRequest,
    FormaliteApiApiFormalitesGetCollectionRequest,
    CategorieCategorieRead,
    CategorieApi,
} from '../../../openApi/ApiFormalite';
import { FORMALITES_REDUCER_NAME } from '../constants';

export type CategoriesFormaliteApiObject = CategorieCategorieRead & Record<string, unknown>;

export type CategoriesFormalite = CategoriesFormaliteApiObject;

export const CATEGORY_SLICE_NAME = 'categories_formalites';

export const mapperCategoriesFormalite = function (formalities: CategoriesFormalite) {
    return {
        ...formalities,
    } as CategoriesFormalite;
};

export function createCategoriesFormaliteSlice(configuration: DossiersConfigurationParameters) {
    const conf = new Configuration(configuration);
    const api = new CategorieApi(conf);

    const fetchCategoryFormaliteCollection: FetchCollectionData<CategoriesFormalite> = () => {
        const options = undefined;

        const requestParameters: FormaliteApiApiFormalitesGetCollectionRequest = {
            itemsPerPage: 20,
        };

        return api
            .apiCategoriesGetCollection(requestParameters, options)
            .then((res) => res.data) as Promise<ApiCollectionResponse<CategoriesFormalite>>;
    };

    const fetchCategoryFormaliteItem: FetchItemDataType<CategoriesFormalite> = ({ idItem }) => {
        const requestParameters: FormaliteApiApiFormalitesIdGetRequest = {
            id: String(idItem),
        };

        return api
            .apiCategoriesIdGet(requestParameters)
            .then((res) => res.data) as Promise<CategoriesFormalite>;
    };

    const { slice: categoryFormaliteSlice, dataSourcesThunks: categoryFormaliteDataSourcesThunks } =
        SliceFactory.createSlice<CategoriesFormalite, CategoriesFormalite>(
            CATEGORY_SLICE_NAME,
            FORMALITES_REDUCER_NAME,
            [],
            fetchCategoryFormaliteCollection,
            fetchCategoryFormaliteItem,
            mapperCategoriesFormalite,
            {},
        );

    return { categoryFormaliteSlice, categoryFormaliteDataSourcesThunks };
}
