import { formulairesFormaliteSelector } from '@b2d/redux/subReducers/FormulaireReducer';
import { FormulaireFormalite } from '@europrocurement/l2d-domain';
import { DataSource } from '@europrocurement/l2d-redux-utils';
import { useSelector } from 'react-redux';

const useFormulaire = () => {
    const formulaires: DataSource<FormulaireFormalite> = useSelector(formulairesFormaliteSelector);

    const listFormulaires = () => formulaires.data;
    const findFormulaire: (id: number) => FormulaireFormalite | undefined = (id: number) =>
        formulaires.data.find((formulaire: FormulaireFormalite) => id === formulaire.id);

    return { listFormulaires, findFormulaire };
};

export default useFormulaire;
