/* tslint:disable */
/* eslint-disable */
/**
 * Europrocurement Api Dossiers
 * API de gestion des dossiers
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface CommandeCommandeRead
 */
export interface CommandeCommandeRead {
    /**
     * 
     * @type {number}
     * @memberof CommandeCommandeRead
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof CommandeCommandeRead
     */
    'societe'?: number;
    /**
     * 
     * @type {number}
     * @memberof CommandeCommandeRead
     */
    'idDomaine'?: number;
    /**
     * 
     * @type {number}
     * @memberof CommandeCommandeRead
     */
    'prescripteur'?: number;
    /**
     * 
     * @type {string}
     * @memberof CommandeCommandeRead
     */
    'raisonSocialePrescripteur'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CommandeCommandeRead
     */
    'client'?: number;
    /**
     * 
     * @type {number}
     * @memberof CommandeCommandeRead
     */
    'idContact'?: number;
    /**
     * 
     * @type {string}
     * @memberof CommandeCommandeRead
     */
    'contact'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommandeCommandeRead
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommandeCommandeRead
     */
    'telephone'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CommandeCommandeRead
     */
    'numeroCommande'?: number;
    /**
     * 
     * @type {number}
     * @memberof CommandeCommandeRead
     */
    'htCommande'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommandeCommandeRead
     */
    'tvaCommande'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommandeCommandeRead
     */
    'ttcCommande'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommandeCommandeRead
     */
    'idCommercial'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CommandeCommandeRead
     */
    'newBiz'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CommandeCommandeRead
     */
    'idOrigine'?: number;
    /**
     * 
     * @type {number}
     * @memberof CommandeCommandeRead
     */
    'delaiPaiement'?: number;
    /**
     * 
     * @type {number}
     * @memberof CommandeCommandeRead
     */
    'idStatutCommande'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CommandeCommandeRead
     */
    'venteIndirecte'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CommandeCommandeRead
     */
    'cegidRegimeTva'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CommandeCommandeRead
     */
    'idFacture'?: number;
    /**
     * 
     * @type {string}
     * @memberof CommandeCommandeRead
     */
    'annonceur'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommandeCommandeRead
     */
    'refInterne'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommandeCommandeRead
     */
    'refClient'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommandeCommandeRead
     */
    'dateCreation'?: string;
    /**
     * 
     * @type {number}
     * @memberof CommandeCommandeRead
     */
    'userCreation'?: number;
    /**
     * 
     * @type {string}
     * @memberof CommandeCommandeRead
     */
    'dateMaj'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CommandeCommandeRead
     */
    'userMaj'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CommandeCommandeRead
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CommandeCommandeRead
     */
    'dateDelete'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CommandeCommandeRead
     */
    'userDelete'?: number;
    /**
     * 
     * @type {string}
     * @memberof CommandeCommandeRead
     */
    'portable'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommandeCommandeRead
     */
    'comFacture'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommandeCommandeRead
     */
    'notes'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CommandeCommandeRead
     */
    'idMarque'?: number;
    /**
     * 
     * @type {string}
     * @memberof CommandeCommandeRead
     */
    'numEngagement'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CommandeCommandeRead
     */
    'dossiers'?: Array<string>;
}
/**
 * 
 * @export
 * @interface CommandeJsonldCommandeRead
 */
export interface CommandeJsonldCommandeRead {
    /**
     * 
     * @type {string}
     * @memberof CommandeJsonldCommandeRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommandeJsonldCommandeRead
     */
    '@type'?: string;
    /**
     * 
     * @type {CommandeJsonldCommandeReadContext}
     * @memberof CommandeJsonldCommandeRead
     */
    '@context'?: CommandeJsonldCommandeReadContext;
    /**
     * 
     * @type {number}
     * @memberof CommandeJsonldCommandeRead
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof CommandeJsonldCommandeRead
     */
    'societe'?: number;
    /**
     * 
     * @type {number}
     * @memberof CommandeJsonldCommandeRead
     */
    'idDomaine'?: number;
    /**
     * 
     * @type {number}
     * @memberof CommandeJsonldCommandeRead
     */
    'prescripteur'?: number;
    /**
     * 
     * @type {string}
     * @memberof CommandeJsonldCommandeRead
     */
    'raisonSocialePrescripteur'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CommandeJsonldCommandeRead
     */
    'client'?: number;
    /**
     * 
     * @type {number}
     * @memberof CommandeJsonldCommandeRead
     */
    'idContact'?: number;
    /**
     * 
     * @type {string}
     * @memberof CommandeJsonldCommandeRead
     */
    'contact'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommandeJsonldCommandeRead
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommandeJsonldCommandeRead
     */
    'telephone'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CommandeJsonldCommandeRead
     */
    'numeroCommande'?: number;
    /**
     * 
     * @type {number}
     * @memberof CommandeJsonldCommandeRead
     */
    'htCommande'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommandeJsonldCommandeRead
     */
    'tvaCommande'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommandeJsonldCommandeRead
     */
    'ttcCommande'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommandeJsonldCommandeRead
     */
    'idCommercial'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CommandeJsonldCommandeRead
     */
    'newBiz'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CommandeJsonldCommandeRead
     */
    'idOrigine'?: number;
    /**
     * 
     * @type {number}
     * @memberof CommandeJsonldCommandeRead
     */
    'delaiPaiement'?: number;
    /**
     * 
     * @type {number}
     * @memberof CommandeJsonldCommandeRead
     */
    'idStatutCommande'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CommandeJsonldCommandeRead
     */
    'venteIndirecte'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CommandeJsonldCommandeRead
     */
    'cegidRegimeTva'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CommandeJsonldCommandeRead
     */
    'idFacture'?: number;
    /**
     * 
     * @type {string}
     * @memberof CommandeJsonldCommandeRead
     */
    'annonceur'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommandeJsonldCommandeRead
     */
    'refInterne'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommandeJsonldCommandeRead
     */
    'refClient'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommandeJsonldCommandeRead
     */
    'dateCreation'?: string;
    /**
     * 
     * @type {number}
     * @memberof CommandeJsonldCommandeRead
     */
    'userCreation'?: number;
    /**
     * 
     * @type {string}
     * @memberof CommandeJsonldCommandeRead
     */
    'dateMaj'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CommandeJsonldCommandeRead
     */
    'userMaj'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CommandeJsonldCommandeRead
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CommandeJsonldCommandeRead
     */
    'dateDelete'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CommandeJsonldCommandeRead
     */
    'userDelete'?: number;
    /**
     * 
     * @type {string}
     * @memberof CommandeJsonldCommandeRead
     */
    'portable'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommandeJsonldCommandeRead
     */
    'comFacture'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommandeJsonldCommandeRead
     */
    'notes'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CommandeJsonldCommandeRead
     */
    'idMarque'?: number;
    /**
     * 
     * @type {string}
     * @memberof CommandeJsonldCommandeRead
     */
    'numEngagement'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CommandeJsonldCommandeRead
     */
    'dossiers'?: Array<string>;
}
/**
 * @type CommandeJsonldCommandeReadContext
 * @export
 */
export type CommandeJsonldCommandeReadContext = CommandeJsonldCommandeReadContextOneOf | string;

/**
 * 
 * @export
 * @interface CommandeJsonldCommandeReadContextOneOf
 */
export interface CommandeJsonldCommandeReadContextOneOf {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof CommandeJsonldCommandeReadContextOneOf
     */
    '@vocab': string;
    /**
     * 
     * @type {string}
     * @memberof CommandeJsonldCommandeReadContextOneOf
     */
    'hydra': CommandeJsonldCommandeReadContextOneOfHydraEnum;
}

export const CommandeJsonldCommandeReadContextOneOfHydraEnum = {
    HttpWwwW3OrgNsHydraCore: 'http://www.w3.org/ns/hydra/core#'
} as const;

export type CommandeJsonldCommandeReadContextOneOfHydraEnum = typeof CommandeJsonldCommandeReadContextOneOfHydraEnum[keyof typeof CommandeJsonldCommandeReadContextOneOfHydraEnum];

/**
 * 
 * @export
 * @interface DossierDossierRead
 */
export interface DossierDossierRead {
    /**
     * 
     * @type {number}
     * @memberof DossierDossierRead
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof DossierDossierRead
     */
    'societe'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DossierDossierRead
     */
    'prescripteur'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DossierDossierRead
     */
    'contact'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DossierDossierRead
     */
    'client'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DossierDossierRead
     */
    'numero'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierRead
     */
    'notes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierRead
     */
    'refCommandeClient'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DossierDossierRead
     */
    'idOrigine'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DossierDossierRead
     */
    'idMoyenPaiement'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierRead
     */
    'doafMail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierRead
     */
    'statut'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DossierDossierRead
     */
    'mailCommande'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof DossierDossierRead
     */
    'gratuit'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof DossierDossierRead
     */
    'transformeCommande'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierRead
     */
    'dateCommande'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierRead
     */
    'facture'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierRead
     */
    'infoBloquage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierRead
     */
    'infoGratuit'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DossierDossierRead
     */
    'idCommercial'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierRead
     */
    'annonceur'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierRead
     */
    'totalHt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierRead
     */
    'totalTva'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierRead
     */
    'attendu'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DossierDossierRead
     */
    'idAdresseClient'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DossierDossierRead
     */
    'tiersCorrespFacture'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DossierDossierRead
     */
    'tiersCorrespJustif'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DossierDossierRead
     */
    'adresseCorrespFacture'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DossierDossierRead
     */
    'adresseCorrespJustif'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DossierDossierRead
     */
    'delaiPaiement'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DossierDossierRead
     */
    'idStatut'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof DossierDossierRead
     */
    'ouvert'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof DossierDossierRead
     */
    'regle'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof DossierDossierRead
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof DossierDossierRead
     */
    'userMaj'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierRead
     */
    'dateMaj'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DossierDossierRead
     */
    'userCreation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierRead
     */
    'dateCreation'?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierRead
     */
    'etat'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierRead
     */
    'paye'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierRead
     */
    'cClient'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierRead
     */
    'cContact'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierRead
     */
    'cTel'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierRead
     */
    'cEmail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierRead
     */
    'cCFacture'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierRead
     */
    'adresse1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierRead
     */
    'adresse2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierRead
     */
    'adresse3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierRead
     */
    'adresse4'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierRead
     */
    'codePostal'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierRead
     */
    'ville'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierRead
     */
    'rsc'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierRead
     */
    'contactC'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierRead
     */
    'adresse1C'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierRead
     */
    'adresse2C'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierRead
     */
    'adresse3C'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierRead
     */
    'adresse4C'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierRead
     */
    'codePostalC'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierRead
     */
    'villeC'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DossierDossierRead
     */
    'incident'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DossierDossierRead
     */
    'idOrigineLastModif'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DossierDossierRead
     */
    'idContactLastModif'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof DossierDossierRead
     */
    'venteIndirecte'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof DossierDossierRead
     */
    'idDossierOrigine'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierRead
     */
    'cegidRegimeTva'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierRead
     */
    'dssDateLastMdfG3'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DossierDossierRead
     */
    'wsIdDossierFournisseur'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierRead
     */
    'prixCommercial'?: string;
    /**
     * 
     * @type {number}
     * @memberof DossierDossierRead
     */
    'remiseAvisNatReelle'?: number;
    /**
     * 
     * @type {number}
     * @memberof DossierDossierRead
     */
    'prixAchatCmrcHt'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DossierDossierRead
     */
    'prixCmrcForce'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierRead
     */
    'ipCommande'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierRead
     */
    'commande'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DossierDossierRead
     */
    'idStatutBat'?: number;
    /**
     * 
     * @type {number}
     * @memberof DossierDossierRead
     */
    'wsIdDossierClient'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierRead
     */
    'wsBddOrigine'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DossierDossierRead
     */
    'dldDnidId'?: number;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierRead
     */
    'wsBddCible'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierRead
     */
    'noteAchats'?: string;
    /**
     * 
     * @type {number}
     * @memberof DossierDossierRead
     */
    'idStatutAchat'?: number;
    /**
     * 
     * @type {number}
     * @memberof DossierDossierRead
     */
    'idUserStatutAchat'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DossierDossierRead
     */
    'relanceFnp'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DossierDossierRead
     */
    'idDnidGrpDossiers'?: number | null;
    /**
     * 
     * @type {Array<TblPrestaDossierRead>}
     * @memberof DossierDossierRead
     */
    'prestations'?: Array<TblPrestaDossierRead>;
}
/**
 * 
 * @export
 * @interface DossierDossierReadCollection
 */
export interface DossierDossierReadCollection {
    /**
     * 
     * @type {number}
     * @memberof DossierDossierReadCollection
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof DossierDossierReadCollection
     */
    'societe'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DossierDossierReadCollection
     */
    'prescripteur'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DossierDossierReadCollection
     */
    'numero'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierReadCollection
     */
    'notes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierReadCollection
     */
    'refCommandeClient'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DossierDossierReadCollection
     */
    'idOrigine'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierReadCollection
     */
    'statut'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DossierDossierReadCollection
     */
    'mailCommande'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof DossierDossierReadCollection
     */
    'gratuit'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof DossierDossierReadCollection
     */
    'transformeCommande'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierReadCollection
     */
    'dateCommande'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierReadCollection
     */
    'facture'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierReadCollection
     */
    'infoBloquage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierReadCollection
     */
    'infoGratuit'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DossierDossierReadCollection
     */
    'idCommercial'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierReadCollection
     */
    'annonceur'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierReadCollection
     */
    'totalHt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierReadCollection
     */
    'totalTva'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierReadCollection
     */
    'attendu'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DossierDossierReadCollection
     */
    'idAdresseClient'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DossierDossierReadCollection
     */
    'tiersCorrespFacture'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DossierDossierReadCollection
     */
    'tiersCorrespJustif'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DossierDossierReadCollection
     */
    'adresseCorrespFacture'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DossierDossierReadCollection
     */
    'adresseCorrespJustif'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DossierDossierReadCollection
     */
    'delaiPaiement'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DossierDossierReadCollection
     */
    'idStatut'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof DossierDossierReadCollection
     */
    'ouvert'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof DossierDossierReadCollection
     */
    'regle'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof DossierDossierReadCollection
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof DossierDossierReadCollection
     */
    'userMaj'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierReadCollection
     */
    'dateMaj'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DossierDossierReadCollection
     */
    'userCreation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierReadCollection
     */
    'dateCreation'?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierReadCollection
     */
    'etat'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierReadCollection
     */
    'paye'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierReadCollection
     */
    'cClient'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierReadCollection
     */
    'cContact'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierReadCollection
     */
    'cTel'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierReadCollection
     */
    'cEmail'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DossierDossierReadCollection
     */
    'incident'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DossierDossierReadCollection
     */
    'idOrigineLastModif'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DossierDossierReadCollection
     */
    'idContactLastModif'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof DossierDossierReadCollection
     */
    'venteIndirecte'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof DossierDossierReadCollection
     */
    'idDossierOrigine'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierReadCollection
     */
    'prixCommercial'?: string;
    /**
     * 
     * @type {number}
     * @memberof DossierDossierReadCollection
     */
    'remiseAvisNatReelle'?: number;
    /**
     * 
     * @type {number}
     * @memberof DossierDossierReadCollection
     */
    'prixAchatCmrcHt'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DossierDossierReadCollection
     */
    'prixCmrcForce'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierReadCollection
     */
    'commande'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DossierDossierReadCollection
     */
    'idStatutBat'?: number;
    /**
     * 
     * @type {number}
     * @memberof DossierDossierReadCollection
     */
    'wsIdDossierClient'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierReadCollection
     */
    'noteAchats'?: string;
    /**
     * 
     * @type {number}
     * @memberof DossierDossierReadCollection
     */
    'idStatutAchat'?: number;
    /**
     * 
     * @type {number}
     * @memberof DossierDossierReadCollection
     */
    'idUserStatutAchat'?: number;
    /**
     * 
     * @type {Array<TblPrestaDossierReadCollection>}
     * @memberof DossierDossierReadCollection
     */
    'prestations'?: Array<TblPrestaDossierReadCollection>;
}
/**
 * 
 * @export
 * @interface DossierJsonldDossierRead
 */
export interface DossierJsonldDossierRead {
    /**
     * 
     * @type {CommandeJsonldCommandeReadContext}
     * @memberof DossierJsonldDossierRead
     */
    '@context'?: CommandeJsonldCommandeReadContext;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof DossierJsonldDossierRead
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof DossierJsonldDossierRead
     */
    'societe'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DossierJsonldDossierRead
     */
    'prescripteur'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DossierJsonldDossierRead
     */
    'contact'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DossierJsonldDossierRead
     */
    'client'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DossierJsonldDossierRead
     */
    'numero'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    'notes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    'refCommandeClient'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DossierJsonldDossierRead
     */
    'idOrigine'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DossierJsonldDossierRead
     */
    'idMoyenPaiement'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    'doafMail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    'statut'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DossierJsonldDossierRead
     */
    'mailCommande'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof DossierJsonldDossierRead
     */
    'gratuit'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof DossierJsonldDossierRead
     */
    'transformeCommande'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    'dateCommande'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    'facture'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    'infoBloquage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    'infoGratuit'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DossierJsonldDossierRead
     */
    'idCommercial'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    'annonceur'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    'totalHt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    'totalTva'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    'attendu'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DossierJsonldDossierRead
     */
    'idAdresseClient'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DossierJsonldDossierRead
     */
    'tiersCorrespFacture'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DossierJsonldDossierRead
     */
    'tiersCorrespJustif'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DossierJsonldDossierRead
     */
    'adresseCorrespFacture'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DossierJsonldDossierRead
     */
    'adresseCorrespJustif'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DossierJsonldDossierRead
     */
    'delaiPaiement'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DossierJsonldDossierRead
     */
    'idStatut'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof DossierJsonldDossierRead
     */
    'ouvert'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof DossierJsonldDossierRead
     */
    'regle'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof DossierJsonldDossierRead
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof DossierJsonldDossierRead
     */
    'userMaj'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    'dateMaj'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DossierJsonldDossierRead
     */
    'userCreation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    'dateCreation'?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    'etat'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    'paye'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    'cClient'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    'cContact'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    'cTel'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    'cEmail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    'cCFacture'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    'adresse1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    'adresse2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    'adresse3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    'adresse4'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    'codePostal'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    'ville'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    'rsc'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    'contactC'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    'adresse1C'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    'adresse2C'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    'adresse3C'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    'adresse4C'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    'codePostalC'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    'villeC'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DossierJsonldDossierRead
     */
    'incident'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DossierJsonldDossierRead
     */
    'idOrigineLastModif'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DossierJsonldDossierRead
     */
    'idContactLastModif'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof DossierJsonldDossierRead
     */
    'venteIndirecte'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof DossierJsonldDossierRead
     */
    'idDossierOrigine'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    'cegidRegimeTva'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    'dssDateLastMdfG3'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DossierJsonldDossierRead
     */
    'wsIdDossierFournisseur'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    'prixCommercial'?: string;
    /**
     * 
     * @type {number}
     * @memberof DossierJsonldDossierRead
     */
    'remiseAvisNatReelle'?: number;
    /**
     * 
     * @type {number}
     * @memberof DossierJsonldDossierRead
     */
    'prixAchatCmrcHt'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DossierJsonldDossierRead
     */
    'prixCmrcForce'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    'ipCommande'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    'commande'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DossierJsonldDossierRead
     */
    'idStatutBat'?: number;
    /**
     * 
     * @type {number}
     * @memberof DossierJsonldDossierRead
     */
    'wsIdDossierClient'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    'wsBddOrigine'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DossierJsonldDossierRead
     */
    'dldDnidId'?: number;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    'wsBddCible'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    'noteAchats'?: string;
    /**
     * 
     * @type {number}
     * @memberof DossierJsonldDossierRead
     */
    'idStatutAchat'?: number;
    /**
     * 
     * @type {number}
     * @memberof DossierJsonldDossierRead
     */
    'idUserStatutAchat'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DossierJsonldDossierRead
     */
    'relanceFnp'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DossierJsonldDossierRead
     */
    'idDnidGrpDossiers'?: number | null;
    /**
     * 
     * @type {Array<TblPrestaJsonldDossierRead>}
     * @memberof DossierJsonldDossierRead
     */
    'prestations'?: Array<TblPrestaJsonldDossierRead>;
}
/**
 * 
 * @export
 * @interface DossierJsonldDossierReadCollection
 */
export interface DossierJsonldDossierReadCollection {
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierReadCollection
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierReadCollection
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof DossierJsonldDossierReadCollection
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof DossierJsonldDossierReadCollection
     */
    'societe'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DossierJsonldDossierReadCollection
     */
    'prescripteur'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DossierJsonldDossierReadCollection
     */
    'numero'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierReadCollection
     */
    'notes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierReadCollection
     */
    'refCommandeClient'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DossierJsonldDossierReadCollection
     */
    'idOrigine'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierReadCollection
     */
    'statut'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DossierJsonldDossierReadCollection
     */
    'mailCommande'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof DossierJsonldDossierReadCollection
     */
    'gratuit'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof DossierJsonldDossierReadCollection
     */
    'transformeCommande'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierReadCollection
     */
    'dateCommande'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierReadCollection
     */
    'facture'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierReadCollection
     */
    'infoBloquage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierReadCollection
     */
    'infoGratuit'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DossierJsonldDossierReadCollection
     */
    'idCommercial'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierReadCollection
     */
    'annonceur'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierReadCollection
     */
    'totalHt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierReadCollection
     */
    'totalTva'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierReadCollection
     */
    'attendu'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DossierJsonldDossierReadCollection
     */
    'idAdresseClient'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DossierJsonldDossierReadCollection
     */
    'tiersCorrespFacture'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DossierJsonldDossierReadCollection
     */
    'tiersCorrespJustif'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DossierJsonldDossierReadCollection
     */
    'adresseCorrespFacture'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DossierJsonldDossierReadCollection
     */
    'adresseCorrespJustif'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DossierJsonldDossierReadCollection
     */
    'delaiPaiement'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DossierJsonldDossierReadCollection
     */
    'idStatut'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof DossierJsonldDossierReadCollection
     */
    'ouvert'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof DossierJsonldDossierReadCollection
     */
    'regle'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof DossierJsonldDossierReadCollection
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof DossierJsonldDossierReadCollection
     */
    'userMaj'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierReadCollection
     */
    'dateMaj'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DossierJsonldDossierReadCollection
     */
    'userCreation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierReadCollection
     */
    'dateCreation'?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierReadCollection
     */
    'etat'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierReadCollection
     */
    'paye'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierReadCollection
     */
    'cClient'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierReadCollection
     */
    'cContact'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierReadCollection
     */
    'cTel'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierReadCollection
     */
    'cEmail'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DossierJsonldDossierReadCollection
     */
    'incident'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DossierJsonldDossierReadCollection
     */
    'idOrigineLastModif'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DossierJsonldDossierReadCollection
     */
    'idContactLastModif'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof DossierJsonldDossierReadCollection
     */
    'venteIndirecte'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof DossierJsonldDossierReadCollection
     */
    'idDossierOrigine'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierReadCollection
     */
    'prixCommercial'?: string;
    /**
     * 
     * @type {number}
     * @memberof DossierJsonldDossierReadCollection
     */
    'remiseAvisNatReelle'?: number;
    /**
     * 
     * @type {number}
     * @memberof DossierJsonldDossierReadCollection
     */
    'prixAchatCmrcHt'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DossierJsonldDossierReadCollection
     */
    'prixCmrcForce'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierReadCollection
     */
    'commande'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DossierJsonldDossierReadCollection
     */
    'idStatutBat'?: number;
    /**
     * 
     * @type {number}
     * @memberof DossierJsonldDossierReadCollection
     */
    'wsIdDossierClient'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierReadCollection
     */
    'noteAchats'?: string;
    /**
     * 
     * @type {number}
     * @memberof DossierJsonldDossierReadCollection
     */
    'idStatutAchat'?: number;
    /**
     * 
     * @type {number}
     * @memberof DossierJsonldDossierReadCollection
     */
    'idUserStatutAchat'?: number;
    /**
     * 
     * @type {Array<TblPrestaJsonldDossierReadCollection>}
     * @memberof DossierJsonldDossierReadCollection
     */
    'prestations'?: Array<TblPrestaJsonldDossierReadCollection>;
}
/**
 * 
 * @export
 * @interface FactureVenteFactureVenteRead
 */
export interface FactureVenteFactureVenteRead {
    /**
     * 
     * @type {number}
     * @memberof FactureVenteFactureVenteRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteFactureVenteRead
     */
    'numero'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteFactureVenteRead
     */
    'dossier'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteFactureVenteRead
     */
    'numeroDossier'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteFactureVenteRead
     */
    'dateFacture'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteFactureVenteRead
     */
    'prescripteur'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteFactureVenteRead
     */
    'client'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteFactureVenteRead
     */
    'raisonSociale'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteFactureVenteRead
     */
    'adresse1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteFactureVenteRead
     */
    'adresse2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteFactureVenteRead
     */
    'adresse3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteFactureVenteRead
     */
    'adresse4'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteFactureVenteRead
     */
    'codePostal'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteFactureVenteRead
     */
    'ville'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteFactureVenteRead
     */
    'tiersCorrespondance'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteFactureVenteRead
     */
    'rsc'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteFactureVenteRead
     */
    'adresse1C'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteFactureVenteRead
     */
    'adresse2C'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteFactureVenteRead
     */
    'adresse3C'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteFactureVenteRead
     */
    'adresse4C'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteFactureVenteRead
     */
    'codePostalC'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteFactureVenteRead
     */
    'villeC'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteFactureVenteRead
     */
    'reference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteFactureVenteRead
     */
    'cliCode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteFactureVenteRead
     */
    'totalHt'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteFactureVenteRead
     */
    'totalTva'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteFactureVenteRead
     */
    'totalTtc'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureVenteFactureVenteRead
     */
    'paye'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteFactureVenteRead
     */
    'dateExport'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteFactureVenteRead
     */
    'societe'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteFactureVenteRead
     */
    'contact'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureVenteFactureVenteRead
     */
    'gratuit'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteFactureVenteRead
     */
    'remise'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureVenteFactureVenteRead
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteFactureVenteRead
     */
    'delaiPaiement'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteFactureVenteRead
     */
    'userCreation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteFactureVenteRead
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteFactureVenteRead
     */
    'contactClient'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteFactureVenteRead
     */
    'tvaBase1'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteFactureVenteRead
     */
    'tvaBase2'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteFactureVenteRead
     */
    'tvaBase3'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteFactureVenteRead
     */
    'tvaBase4'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteFactureVenteRead
     */
    'tvaTx1'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteFactureVenteRead
     */
    'tvaTx2'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteFactureVenteRead
     */
    'tvaTx3'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteFactureVenteRead
     */
    'tvaTx4'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteFactureVenteRead
     */
    'tvaMontant1'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteFactureVenteRead
     */
    'tvaMontant2'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteFactureVenteRead
     */
    'tvaMontant3'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteFactureVenteRead
     */
    'tvaMontant4'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteFactureVenteRead
     */
    'montantPaiementRecu'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteFactureVenteRead
     */
    'userMaj'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteFactureVenteRead
     */
    'dateMaj'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureVenteFactureVenteRead
     */
    'compta'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteFactureVenteRead
     */
    'contactFacture'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteFactureVenteRead
     */
    'contactCorrespondance'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureVenteFactureVenteRead
     */
    'rrrPaye'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteFactureVenteRead
     */
    'datePaiementRrr'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureVenteFactureVenteRead
     */
    'rrrRefuse'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureVenteFactureVenteRead
     */
    'formalite'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteFactureVenteRead
     */
    'idDossierFormalite'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureVenteFactureVenteRead
     */
    'factureEnPreparation'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteFactureVenteRead
     */
    'societeFacture'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureVenteFactureVenteRead
     */
    'facturationDiverse'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteFactureVenteRead
     */
    'idDossierFactureDiverse'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureVenteFactureVenteRead
     */
    'comptaCegid'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteFactureVenteRead
     */
    'dateExportCegid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteFactureVenteRead
     */
    'cegidTvaEncaissement'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteFactureVenteRead
     */
    'cegidRegimeTva'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteFactureVenteRead
     */
    'cegidLibelle'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureVenteFactureVenteRead
     */
    'statFacture'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureVenteFactureVenteRead
     */
    'factureAvoir'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureVenteFactureVenteRead
     */
    'factureAvoirRemise'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteFactureVenteRead
     */
    'cegidRejetExportCompta'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteFactureVenteRead
     */
    'cegidRejetDateExport'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureVenteFactureVenteRead
     */
    'formalite2'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteFactureVenteRead
     */
    'idFdos'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteFactureVenteRead
     */
    'adjNomTgi'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteFactureVenteRead
     */
    'adjDateVente'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteFactureVenteRead
     */
    'adjAffaire'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteFactureVenteRead
     */
    'adjDetailVente'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteFactureVenteRead
     */
    'dateEcheanceFacture'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureVenteFactureVenteRead
     */
    'factureAdju'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteFactureVenteRead
     */
    'adjDossier'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureVenteFactureVenteRead
     */
    'presentationAdju'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteFactureVenteRead
     */
    'dateLettrageReglement'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureVenteFactureVenteRead
     */
    'editionReleve'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureVenteFactureVenteRead
     */
    'nonRelancable'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteFactureVenteRead
     */
    'idUserNonRelancable'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteFactureVenteRead
     */
    'dateNonRelancable'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureVenteFactureVenteRead
     */
    'rbtFraisAchats'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteFactureVenteRead
     */
    'idFactureRefAvoir'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteFactureVenteRead
     */
    'idfactureReleveAvoir'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureVenteFactureVenteRead
     */
    'venteIndirecte'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureVenteFactureVenteRead
     */
    'horsParrainage'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteFactureVenteRead
     */
    'tiersParrain'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteFactureVenteRead
     */
    'tauxParrainG'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteFactureVenteRead
     */
    'tauxParrainHg'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureVenteFactureVenteRead
     */
    'traAchat'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteFactureVenteRead
     */
    'dateTraAchat'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteFactureVenteRead
     */
    'factureMultiple'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteFactureVenteRead
     */
    'comNonRelancable'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureVenteFactureVenteRead
     */
    'exportG3'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteFactureVenteRead
     */
    'referenceInterne'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureVenteFactureVenteRead
     */
    'parrainageEdition'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureVenteFactureVenteRead
     */
    'retourNpai'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteFactureVenteRead
     */
    'nImpNeotouch'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteFactureVenteRead
     */
    'brouillonMultiple'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteFactureVenteRead
     */
    'commande'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteFactureVenteRead
     */
    'idDdm'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof FactureVenteFactureVenteRead
     */
    'intercoTraitee'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteFactureVenteRead
     */
    'dateTraitementInterco'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteFactureVenteRead
     */
    'numeroFactureInterco'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteFactureVenteRead
     */
    'coef'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureVenteFactureVenteRead
     */
    'comRegie'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteFactureVenteRead
     */
    'dateComRegie'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteFactureVenteRead
     */
    'fournisseurComRegie'?: number;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteFactureVenteRead
     */
    'idTcf'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteFactureVenteRead
     */
    'releveRrrConfrereFile'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureVenteFactureVenteRead
     */
    'fnpExclus'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteFactureVenteRead
     */
    'cegidFile'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureVenteFactureVenteRead
     */
    'justifsDetailsInterdit'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteFactureVenteRead
     */
    'nJustifsTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteFactureVenteRead
     */
    'nJustifsPayants'?: number;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteFactureVenteRead
     */
    'nJustifsGratuits'?: number;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteFactureVenteRead
     */
    'idPfj'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteFactureVenteRead
     */
    'numEngagement'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureVenteFactureVenteRead
     */
    'kdoTraite'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteFactureVenteRead
     */
    'dateTraitementKdo'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteFactureVenteRead
     */
    'idUserKdoTraite'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof FactureVenteFactureVenteRead
     */
    'dnidTraite'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteFactureVenteRead
     */
    'dateTraitementDnid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteFactureVenteRead
     */
    'numAchatRrr'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteFactureVenteRead
     */
    'dateAchatRrr'?: string | null;
    /**
     * 
     * @type {Array<TblFligneFactureVenteRead>}
     * @memberof FactureVenteFactureVenteRead
     */
    'lignes'?: Array<TblFligneFactureVenteRead>;
    /**
     * 
     * @type {boolean}
     * @memberof FactureVenteFactureVenteRead
     */
    'isFactureCommande'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureVenteFactureVenteRead
     */
    'isFactureAl'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureVenteFactureVenteRead
     */
    'isFactureFpro'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureVenteFactureVenteRead
     */
    'isFactureDiverse'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureVenteFactureVenteRead
     */
    'isFactureMultiple'?: boolean;
}
/**
 * 
 * @export
 * @interface FactureVenteJsonldFactureVenteRead
 */
export interface FactureVenteJsonldFactureVenteRead {
    /**
     * 
     * @type {string}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    '@type'?: string;
    /**
     * 
     * @type {CommandeJsonldCommandeReadContext}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    '@context'?: CommandeJsonldCommandeReadContext;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'numero'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'dossier'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'numeroDossier'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'dateFacture'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'prescripteur'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'client'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'raisonSociale'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'adresse1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'adresse2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'adresse3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'adresse4'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'codePostal'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'ville'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'tiersCorrespondance'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'rsc'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'adresse1C'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'adresse2C'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'adresse3C'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'adresse4C'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'codePostalC'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'villeC'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'reference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'cliCode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'totalHt'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'totalTva'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'totalTtc'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'paye'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'dateExport'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'societe'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'contact'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'gratuit'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'remise'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'delaiPaiement'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'userCreation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'contactClient'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'tvaBase1'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'tvaBase2'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'tvaBase3'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'tvaBase4'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'tvaTx1'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'tvaTx2'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'tvaTx3'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'tvaTx4'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'tvaMontant1'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'tvaMontant2'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'tvaMontant3'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'tvaMontant4'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'montantPaiementRecu'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'userMaj'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'dateMaj'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'compta'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'contactFacture'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'contactCorrespondance'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'rrrPaye'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'datePaiementRrr'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'rrrRefuse'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'formalite'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'idDossierFormalite'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'factureEnPreparation'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'societeFacture'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'facturationDiverse'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'idDossierFactureDiverse'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'comptaCegid'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'dateExportCegid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'cegidTvaEncaissement'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'cegidRegimeTva'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'cegidLibelle'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'statFacture'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'factureAvoir'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'factureAvoirRemise'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'cegidRejetExportCompta'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'cegidRejetDateExport'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'formalite2'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'idFdos'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'adjNomTgi'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'adjDateVente'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'adjAffaire'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'adjDetailVente'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'dateEcheanceFacture'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'factureAdju'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'adjDossier'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'presentationAdju'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'dateLettrageReglement'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'editionReleve'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'nonRelancable'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'idUserNonRelancable'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'dateNonRelancable'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'rbtFraisAchats'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'idFactureRefAvoir'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'idfactureReleveAvoir'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'venteIndirecte'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'horsParrainage'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'tiersParrain'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'tauxParrainG'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'tauxParrainHg'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'traAchat'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'dateTraAchat'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'factureMultiple'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'comNonRelancable'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'exportG3'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'referenceInterne'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'parrainageEdition'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'retourNpai'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'nImpNeotouch'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'brouillonMultiple'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'commande'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'idDdm'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'intercoTraitee'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'dateTraitementInterco'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'numeroFactureInterco'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'coef'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'comRegie'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'dateComRegie'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'fournisseurComRegie'?: number;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'idTcf'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'releveRrrConfrereFile'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'fnpExclus'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'cegidFile'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'justifsDetailsInterdit'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'nJustifsTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'nJustifsPayants'?: number;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'nJustifsGratuits'?: number;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'idPfj'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'numEngagement'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'kdoTraite'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'dateTraitementKdo'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'idUserKdoTraite'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'dnidTraite'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'dateTraitementDnid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'numAchatRrr'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'dateAchatRrr'?: string | null;
    /**
     * 
     * @type {Array<TblFligneJsonldFactureVenteRead>}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'lignes'?: Array<TblFligneJsonldFactureVenteRead>;
    /**
     * 
     * @type {boolean}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'isFactureCommande'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'isFactureAl'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'isFactureFpro'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'isFactureDiverse'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureVenteJsonldFactureVenteRead
     */
    'isFactureMultiple'?: boolean;
}
/**
 * 
 * @export
 * @interface GetCommandeCollection200Response
 */
export interface GetCommandeCollection200Response {
    /**
     * 
     * @type {Array<CommandeJsonldCommandeRead>}
     * @memberof GetCommandeCollection200Response
     */
    'hydra:member': Array<CommandeJsonldCommandeRead>;
    /**
     * 
     * @type {number}
     * @memberof GetCommandeCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {GetCommandeCollection200ResponseHydraView}
     * @memberof GetCommandeCollection200Response
     */
    'hydra:view'?: GetCommandeCollection200ResponseHydraView;
    /**
     * 
     * @type {GetCommandeCollection200ResponseHydraSearch}
     * @memberof GetCommandeCollection200Response
     */
    'hydra:search'?: GetCommandeCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface GetCommandeCollection200ResponseHydraSearch
 */
export interface GetCommandeCollection200ResponseHydraSearch {
    /**
     * 
     * @type {string}
     * @memberof GetCommandeCollection200ResponseHydraSearch
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCommandeCollection200ResponseHydraSearch
     */
    'hydra:template'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCommandeCollection200ResponseHydraSearch
     */
    'hydra:variableRepresentation'?: string;
    /**
     * 
     * @type {Array<GetCommandeCollection200ResponseHydraSearchHydraMappingInner>}
     * @memberof GetCommandeCollection200ResponseHydraSearch
     */
    'hydra:mapping'?: Array<GetCommandeCollection200ResponseHydraSearchHydraMappingInner>;
}
/**
 * 
 * @export
 * @interface GetCommandeCollection200ResponseHydraSearchHydraMappingInner
 */
export interface GetCommandeCollection200ResponseHydraSearchHydraMappingInner {
    /**
     * 
     * @type {string}
     * @memberof GetCommandeCollection200ResponseHydraSearchHydraMappingInner
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCommandeCollection200ResponseHydraSearchHydraMappingInner
     */
    'variable'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCommandeCollection200ResponseHydraSearchHydraMappingInner
     */
    'property'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetCommandeCollection200ResponseHydraSearchHydraMappingInner
     */
    'required'?: boolean;
}
/**
 * 
 * @export
 * @interface GetCommandeCollection200ResponseHydraView
 */
export interface GetCommandeCollection200ResponseHydraView {
    /**
     * 
     * @type {string}
     * @memberof GetCommandeCollection200ResponseHydraView
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCommandeCollection200ResponseHydraView
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCommandeCollection200ResponseHydraView
     */
    'hydra:first'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCommandeCollection200ResponseHydraView
     */
    'hydra:last'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCommandeCollection200ResponseHydraView
     */
    'hydra:previous'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCommandeCollection200ResponseHydraView
     */
    'hydra:next'?: string;
}
/**
 * 
 * @export
 * @interface GetCommandeCollection401Response
 */
export interface GetCommandeCollection401Response {
    /**
     * 
     * @type {string}
     * @memberof GetCommandeCollection401Response
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface GetCommandeCollection403Response
 */
export interface GetCommandeCollection403Response {
    /**
     * 
     * @type {string}
     * @memberof GetCommandeCollection403Response
     */
    'error'?: string;
    /**
     * 
     * @type {GetCommandeCollection403ResponseException}
     * @memberof GetCommandeCollection403Response
     */
    'exception'?: GetCommandeCollection403ResponseException;
}
/**
 * 
 * @export
 * @interface GetCommandeCollection403ResponseException
 */
export interface GetCommandeCollection403ResponseException {
    /**
     * 
     * @type {string}
     * @memberof GetCommandeCollection403ResponseException
     */
    'message'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetCommandeCollection403ResponseException
     */
    'code'?: number;
}
/**
 * 
 * @export
 * @interface GetDossierCollection200Response
 */
export interface GetDossierCollection200Response {
    /**
     * 
     * @type {Array<DossierJsonldDossierReadCollection>}
     * @memberof GetDossierCollection200Response
     */
    'hydra:member': Array<DossierJsonldDossierReadCollection>;
    /**
     * 
     * @type {number}
     * @memberof GetDossierCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {GetCommandeCollection200ResponseHydraView}
     * @memberof GetDossierCollection200Response
     */
    'hydra:view'?: GetCommandeCollection200ResponseHydraView;
    /**
     * 
     * @type {GetCommandeCollection200ResponseHydraSearch}
     * @memberof GetDossierCollection200Response
     */
    'hydra:search'?: GetCommandeCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface GetFactureVenteCollection200Response
 */
export interface GetFactureVenteCollection200Response {
    /**
     * 
     * @type {Array<FactureVenteJsonldFactureVenteRead>}
     * @memberof GetFactureVenteCollection200Response
     */
    'hydra:member': Array<FactureVenteJsonldFactureVenteRead>;
    /**
     * 
     * @type {number}
     * @memberof GetFactureVenteCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {GetCommandeCollection200ResponseHydraView}
     * @memberof GetFactureVenteCollection200Response
     */
    'hydra:view'?: GetCommandeCollection200ResponseHydraView;
    /**
     * 
     * @type {GetCommandeCollection200ResponseHydraSearch}
     * @memberof GetFactureVenteCollection200Response
     */
    'hydra:search'?: GetCommandeCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface GetJournalAnnoncesLegalesCollection200Response
 */
export interface GetJournalAnnoncesLegalesCollection200Response {
    /**
     * 
     * @type {Array<JournalAnnoncesLegalesJsonldJalRead>}
     * @memberof GetJournalAnnoncesLegalesCollection200Response
     */
    'hydra:member': Array<JournalAnnoncesLegalesJsonldJalRead>;
    /**
     * 
     * @type {number}
     * @memberof GetJournalAnnoncesLegalesCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {GetCommandeCollection200ResponseHydraView}
     * @memberof GetJournalAnnoncesLegalesCollection200Response
     */
    'hydra:view'?: GetCommandeCollection200ResponseHydraView;
    /**
     * 
     * @type {GetCommandeCollection200ResponseHydraSearch}
     * @memberof GetJournalAnnoncesLegalesCollection200Response
     */
    'hydra:search'?: GetCommandeCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface GetRubriqueFacturationCollection200Response
 */
export interface GetRubriqueFacturationCollection200Response {
    /**
     * 
     * @type {Array<RubriqueFacturationJsonldRubriqueFactureRead>}
     * @memberof GetRubriqueFacturationCollection200Response
     */
    'hydra:member': Array<RubriqueFacturationJsonldRubriqueFactureRead>;
    /**
     * 
     * @type {number}
     * @memberof GetRubriqueFacturationCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {GetCommandeCollection200ResponseHydraView}
     * @memberof GetRubriqueFacturationCollection200Response
     */
    'hydra:view'?: GetCommandeCollection200ResponseHydraView;
    /**
     * 
     * @type {GetCommandeCollection200ResponseHydraSearch}
     * @memberof GetRubriqueFacturationCollection200Response
     */
    'hydra:search'?: GetCommandeCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface GetTauxTvaCollection200Response
 */
export interface GetTauxTvaCollection200Response {
    /**
     * 
     * @type {Array<TauxTvaJsonldTvaFactureRead>}
     * @memberof GetTauxTvaCollection200Response
     */
    'hydra:member': Array<TauxTvaJsonldTvaFactureRead>;
    /**
     * 
     * @type {number}
     * @memberof GetTauxTvaCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {GetCommandeCollection200ResponseHydraView}
     * @memberof GetTauxTvaCollection200Response
     */
    'hydra:view'?: GetCommandeCollection200ResponseHydraView;
    /**
     * 
     * @type {GetCommandeCollection200ResponseHydraSearch}
     * @memberof GetTauxTvaCollection200Response
     */
    'hydra:search'?: GetCommandeCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface JournalAnnoncesLegalesDossierRead
 */
export interface JournalAnnoncesLegalesDossierRead {
    /**
     * 
     * @type {number}
     * @memberof JournalAnnoncesLegalesDossierRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof JournalAnnoncesLegalesDossierRead
     */
    'nom'?: string;
}
/**
 * 
 * @export
 * @interface JournalAnnoncesLegalesDossierReadCollection
 */
export interface JournalAnnoncesLegalesDossierReadCollection {
    /**
     * 
     * @type {number}
     * @memberof JournalAnnoncesLegalesDossierReadCollection
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof JournalAnnoncesLegalesDossierReadCollection
     */
    'nom'?: string;
}
/**
 * 
 * @export
 * @interface JournalAnnoncesLegalesJalRead
 */
export interface JournalAnnoncesLegalesJalRead {
    /**
     * 
     * @type {number}
     * @memberof JournalAnnoncesLegalesJalRead
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof JournalAnnoncesLegalesJalRead
     */
    'jalCal'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof JournalAnnoncesLegalesJalRead
     */
    'nom'?: string;
    /**
     * 
     * @type {string}
     * @memberof JournalAnnoncesLegalesJalRead
     */
    'note'?: string | null;
    /**
     * 
     * @type {JournalAnnoncesLegalesJalReadTypePresse}
     * @memberof JournalAnnoncesLegalesJalRead
     */
    'typePresse'?: JournalAnnoncesLegalesJalReadTypePresse | null;
    /**
     * 
     * @type {string}
     * @memberof JournalAnnoncesLegalesJalRead
     */
    'concentrateur'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof JournalAnnoncesLegalesJalRead
     */
    'operateurFax'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof JournalAnnoncesLegalesJalRead
     */
    'operateurEmail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof JournalAnnoncesLegalesJalRead
     */
    'compoEmail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof JournalAnnoncesLegalesJalRead
     */
    'telephone'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof JournalAnnoncesLegalesJalRead
     */
    'groupe'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof JournalAnnoncesLegalesJalRead
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof JournalAnnoncesLegalesJalRead
     */
    'quotidien'?: boolean | null;
    /**
     * 
     * @type {JournalAnnoncesLegalesJalReadUserCreation}
     * @memberof JournalAnnoncesLegalesJalRead
     */
    'userCreation'?: JournalAnnoncesLegalesJalReadUserCreation | null;
    /**
     * 
     * @type {string}
     * @memberof JournalAnnoncesLegalesJalRead
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {JournalAnnoncesLegalesJalReadUserCreation}
     * @memberof JournalAnnoncesLegalesJalRead
     */
    'userMaj'?: JournalAnnoncesLegalesJalReadUserCreation | null;
    /**
     * 
     * @type {string}
     * @memberof JournalAnnoncesLegalesJalRead
     */
    'dateMaj'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof JournalAnnoncesLegalesJalRead
     */
    'fournisseur'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof JournalAnnoncesLegalesJalRead
     */
    'jalG3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof JournalAnnoncesLegalesJalRead
     */
    'operateurEmail2'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof JournalAnnoncesLegalesJalRead
     */
    'logoAutorise'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof JournalAnnoncesLegalesJalRead
     */
    'logoGratuit'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof JournalAnnoncesLegalesJalRead
     */
    'groupe2'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof JournalAnnoncesLegalesJalRead
     */
    'cegid'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof JournalAnnoncesLegalesJalRead
     */
    'operateurEmailDnid'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof JournalAnnoncesLegalesJalRead
     */
    'fournisseurDnid'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof JournalAnnoncesLegalesJalRead
     */
    'societe'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof JournalAnnoncesLegalesJalRead
     */
    'national'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof JournalAnnoncesLegalesJalRead
     */
    'societeGroupe'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof JournalAnnoncesLegalesJalRead
     */
    'diffusionSpel'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof JournalAnnoncesLegalesJalRead
     */
    'operateurEmailForfait'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof JournalAnnoncesLegalesJalRead
     */
    'telephoneForfait'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof JournalAnnoncesLegalesJalRead
     */
    'telephoneDnid'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof JournalAnnoncesLegalesJalRead
     */
    'fournisseurForfait'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof JournalAnnoncesLegalesJalRead
     */
    'urlPlateforme'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof JournalAnnoncesLegalesJalRead
     */
    'compo'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof JournalAnnoncesLegalesJalRead
     */
    'codeJal'?: string | null;
}
/**
 * 
 * @export
 * @interface JournalAnnoncesLegalesJalReadTypePresse
 */
export interface JournalAnnoncesLegalesJalReadTypePresse {
    /**
     * 
     * @type {number}
     * @memberof JournalAnnoncesLegalesJalReadTypePresse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof JournalAnnoncesLegalesJalReadTypePresse
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof JournalAnnoncesLegalesJalReadTypePresse
     */
    'info'?: string | null;
}
/**
 * 
 * @export
 * @interface JournalAnnoncesLegalesJalReadUserCreation
 */
export interface JournalAnnoncesLegalesJalReadUserCreation {
    /**
     * 
     * @type {number}
     * @memberof JournalAnnoncesLegalesJalReadUserCreation
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof JournalAnnoncesLegalesJalReadUserCreation
     */
    'nom'?: string;
    /**
     * 
     * @type {string}
     * @memberof JournalAnnoncesLegalesJalReadUserCreation
     */
    'prenom'?: string;
}
/**
 * 
 * @export
 * @interface JournalAnnoncesLegalesJsonldDossierRead
 */
export interface JournalAnnoncesLegalesJsonldDossierRead {
    /**
     * 
     * @type {CommandeJsonldCommandeReadContext}
     * @memberof JournalAnnoncesLegalesJsonldDossierRead
     */
    '@context'?: CommandeJsonldCommandeReadContext;
    /**
     * 
     * @type {string}
     * @memberof JournalAnnoncesLegalesJsonldDossierRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof JournalAnnoncesLegalesJsonldDossierRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof JournalAnnoncesLegalesJsonldDossierRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof JournalAnnoncesLegalesJsonldDossierRead
     */
    'nom'?: string;
}
/**
 * 
 * @export
 * @interface JournalAnnoncesLegalesJsonldDossierReadCollection
 */
export interface JournalAnnoncesLegalesJsonldDossierReadCollection {
    /**
     * 
     * @type {CommandeJsonldCommandeReadContext}
     * @memberof JournalAnnoncesLegalesJsonldDossierReadCollection
     */
    '@context'?: CommandeJsonldCommandeReadContext;
    /**
     * 
     * @type {string}
     * @memberof JournalAnnoncesLegalesJsonldDossierReadCollection
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof JournalAnnoncesLegalesJsonldDossierReadCollection
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof JournalAnnoncesLegalesJsonldDossierReadCollection
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof JournalAnnoncesLegalesJsonldDossierReadCollection
     */
    'nom'?: string;
}
/**
 * 
 * @export
 * @interface JournalAnnoncesLegalesJsonldJalRead
 */
export interface JournalAnnoncesLegalesJsonldJalRead {
    /**
     * 
     * @type {string}
     * @memberof JournalAnnoncesLegalesJsonldJalRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof JournalAnnoncesLegalesJsonldJalRead
     */
    '@type'?: string;
    /**
     * 
     * @type {CommandeJsonldCommandeReadContext}
     * @memberof JournalAnnoncesLegalesJsonldJalRead
     */
    '@context'?: CommandeJsonldCommandeReadContext;
    /**
     * 
     * @type {number}
     * @memberof JournalAnnoncesLegalesJsonldJalRead
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof JournalAnnoncesLegalesJsonldJalRead
     */
    'jalCal'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof JournalAnnoncesLegalesJsonldJalRead
     */
    'nom'?: string;
    /**
     * 
     * @type {string}
     * @memberof JournalAnnoncesLegalesJsonldJalRead
     */
    'note'?: string | null;
    /**
     * 
     * @type {JournalAnnoncesLegalesJsonldJalReadTypePresse}
     * @memberof JournalAnnoncesLegalesJsonldJalRead
     */
    'typePresse'?: JournalAnnoncesLegalesJsonldJalReadTypePresse | null;
    /**
     * 
     * @type {string}
     * @memberof JournalAnnoncesLegalesJsonldJalRead
     */
    'concentrateur'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof JournalAnnoncesLegalesJsonldJalRead
     */
    'operateurFax'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof JournalAnnoncesLegalesJsonldJalRead
     */
    'operateurEmail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof JournalAnnoncesLegalesJsonldJalRead
     */
    'compoEmail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof JournalAnnoncesLegalesJsonldJalRead
     */
    'telephone'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof JournalAnnoncesLegalesJsonldJalRead
     */
    'groupe'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof JournalAnnoncesLegalesJsonldJalRead
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof JournalAnnoncesLegalesJsonldJalRead
     */
    'quotidien'?: boolean | null;
    /**
     * 
     * @type {JournalAnnoncesLegalesJsonldJalReadUserCreation}
     * @memberof JournalAnnoncesLegalesJsonldJalRead
     */
    'userCreation'?: JournalAnnoncesLegalesJsonldJalReadUserCreation | null;
    /**
     * 
     * @type {string}
     * @memberof JournalAnnoncesLegalesJsonldJalRead
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {JournalAnnoncesLegalesJsonldJalReadUserCreation}
     * @memberof JournalAnnoncesLegalesJsonldJalRead
     */
    'userMaj'?: JournalAnnoncesLegalesJsonldJalReadUserCreation | null;
    /**
     * 
     * @type {string}
     * @memberof JournalAnnoncesLegalesJsonldJalRead
     */
    'dateMaj'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof JournalAnnoncesLegalesJsonldJalRead
     */
    'fournisseur'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof JournalAnnoncesLegalesJsonldJalRead
     */
    'jalG3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof JournalAnnoncesLegalesJsonldJalRead
     */
    'operateurEmail2'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof JournalAnnoncesLegalesJsonldJalRead
     */
    'logoAutorise'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof JournalAnnoncesLegalesJsonldJalRead
     */
    'logoGratuit'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof JournalAnnoncesLegalesJsonldJalRead
     */
    'groupe2'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof JournalAnnoncesLegalesJsonldJalRead
     */
    'cegid'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof JournalAnnoncesLegalesJsonldJalRead
     */
    'operateurEmailDnid'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof JournalAnnoncesLegalesJsonldJalRead
     */
    'fournisseurDnid'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof JournalAnnoncesLegalesJsonldJalRead
     */
    'societe'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof JournalAnnoncesLegalesJsonldJalRead
     */
    'national'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof JournalAnnoncesLegalesJsonldJalRead
     */
    'societeGroupe'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof JournalAnnoncesLegalesJsonldJalRead
     */
    'diffusionSpel'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof JournalAnnoncesLegalesJsonldJalRead
     */
    'operateurEmailForfait'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof JournalAnnoncesLegalesJsonldJalRead
     */
    'telephoneForfait'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof JournalAnnoncesLegalesJsonldJalRead
     */
    'telephoneDnid'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof JournalAnnoncesLegalesJsonldJalRead
     */
    'fournisseurForfait'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof JournalAnnoncesLegalesJsonldJalRead
     */
    'urlPlateforme'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof JournalAnnoncesLegalesJsonldJalRead
     */
    'compo'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof JournalAnnoncesLegalesJsonldJalRead
     */
    'codeJal'?: string | null;
}
/**
 * 
 * @export
 * @interface JournalAnnoncesLegalesJsonldJalReadTypePresse
 */
export interface JournalAnnoncesLegalesJsonldJalReadTypePresse {
    /**
     * 
     * @type {CommandeJsonldCommandeReadContext}
     * @memberof JournalAnnoncesLegalesJsonldJalReadTypePresse
     */
    '@context'?: CommandeJsonldCommandeReadContext;
    /**
     * 
     * @type {string}
     * @memberof JournalAnnoncesLegalesJsonldJalReadTypePresse
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof JournalAnnoncesLegalesJsonldJalReadTypePresse
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof JournalAnnoncesLegalesJsonldJalReadTypePresse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof JournalAnnoncesLegalesJsonldJalReadTypePresse
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof JournalAnnoncesLegalesJsonldJalReadTypePresse
     */
    'info'?: string | null;
}
/**
 * 
 * @export
 * @interface JournalAnnoncesLegalesJsonldJalReadUserCreation
 */
export interface JournalAnnoncesLegalesJsonldJalReadUserCreation {
    /**
     * 
     * @type {CommandeJsonldCommandeReadContext}
     * @memberof JournalAnnoncesLegalesJsonldJalReadUserCreation
     */
    '@context'?: CommandeJsonldCommandeReadContext;
    /**
     * 
     * @type {string}
     * @memberof JournalAnnoncesLegalesJsonldJalReadUserCreation
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof JournalAnnoncesLegalesJsonldJalReadUserCreation
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof JournalAnnoncesLegalesJsonldJalReadUserCreation
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof JournalAnnoncesLegalesJsonldJalReadUserCreation
     */
    'nom'?: string;
    /**
     * 
     * @type {string}
     * @memberof JournalAnnoncesLegalesJsonldJalReadUserCreation
     */
    'prenom'?: string;
}
/**
 * 
 * @export
 * @interface RubriqueFacturationJsonldRubriqueFactureRead
 */
export interface RubriqueFacturationJsonldRubriqueFactureRead {
    /**
     * 
     * @type {string}
     * @memberof RubriqueFacturationJsonldRubriqueFactureRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RubriqueFacturationJsonldRubriqueFactureRead
     */
    '@type'?: string;
    /**
     * 
     * @type {CommandeJsonldCommandeReadContext}
     * @memberof RubriqueFacturationJsonldRubriqueFactureRead
     */
    '@context'?: CommandeJsonldCommandeReadContext;
    /**
     * 
     * @type {number}
     * @memberof RubriqueFacturationJsonldRubriqueFactureRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof RubriqueFacturationJsonldRubriqueFactureRead
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RubriqueFacturationJsonldRubriqueFactureRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RubriqueFacturationJsonldRubriqueFactureRead
     */
    'codeTvaJalSociete'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RubriqueFacturationJsonldRubriqueFactureRead
     */
    'codeTvaJalAutre'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RubriqueFacturationJsonldRubriqueFactureRead
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {Array<TblRubfacDomaineAchatsJsonldRubriqueFactureRead>}
     * @memberof RubriqueFacturationJsonldRubriqueFactureRead
     */
    'domainesAchats'?: Array<TblRubfacDomaineAchatsJsonldRubriqueFactureRead>;
}
/**
 * 
 * @export
 * @interface RubriqueFacturationRubriqueFactureRead
 */
export interface RubriqueFacturationRubriqueFactureRead {
    /**
     * 
     * @type {number}
     * @memberof RubriqueFacturationRubriqueFactureRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof RubriqueFacturationRubriqueFactureRead
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RubriqueFacturationRubriqueFactureRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RubriqueFacturationRubriqueFactureRead
     */
    'codeTvaJalSociete'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RubriqueFacturationRubriqueFactureRead
     */
    'codeTvaJalAutre'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RubriqueFacturationRubriqueFactureRead
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {Array<TblRubfacDomaineAchatsRubriqueFactureRead>}
     * @memberof RubriqueFacturationRubriqueFactureRead
     */
    'domainesAchats'?: Array<TblRubfacDomaineAchatsRubriqueFactureRead>;
}
/**
 * 
 * @export
 * @interface TauxTvaJsonldTvaFactureRead
 */
export interface TauxTvaJsonldTvaFactureRead {
    /**
     * 
     * @type {string}
     * @memberof TauxTvaJsonldTvaFactureRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TauxTvaJsonldTvaFactureRead
     */
    '@type'?: string;
    /**
     * 
     * @type {CommandeJsonldCommandeReadContext}
     * @memberof TauxTvaJsonldTvaFactureRead
     */
    '@context'?: CommandeJsonldCommandeReadContext;
    /**
     * 
     * @type {number}
     * @memberof TauxTvaJsonldTvaFactureRead
     */
    'idtva'?: number;
    /**
     * 
     * @type {string}
     * @memberof TauxTvaJsonldTvaFactureRead
     */
    'regimetva'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TauxTvaJsonldTvaFactureRead
     */
    'tauxAchat'?: number | null;
}
/**
 * 
 * @export
 * @interface TauxTvaTvaFactureRead
 */
export interface TauxTvaTvaFactureRead {
    /**
     * 
     * @type {number}
     * @memberof TauxTvaTvaFactureRead
     */
    'idtva'?: number;
    /**
     * 
     * @type {string}
     * @memberof TauxTvaTvaFactureRead
     */
    'regimetva'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TauxTvaTvaFactureRead
     */
    'tauxAchat'?: number | null;
}
/**
 * 
 * @export
 * @interface TblAnnonceSTypeDossierRead
 */
export interface TblAnnonceSTypeDossierRead {
    /**
     * 
     * @type {number}
     * @memberof TblAnnonceSTypeDossierRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblAnnonceSTypeDossierRead
     */
    'libelle'?: string | null;
}
/**
 * 
 * @export
 * @interface TblAnnonceSTypeJsonldDossierRead
 */
export interface TblAnnonceSTypeJsonldDossierRead {
    /**
     * 
     * @type {CommandeJsonldCommandeReadContext}
     * @memberof TblAnnonceSTypeJsonldDossierRead
     */
    '@context'?: CommandeJsonldCommandeReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblAnnonceSTypeJsonldDossierRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblAnnonceSTypeJsonldDossierRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblAnnonceSTypeJsonldDossierRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblAnnonceSTypeJsonldDossierRead
     */
    'libelle'?: string | null;
}
/**
 * 
 * @export
 * @interface TblAnnonceTypeDossierRead
 */
export interface TblAnnonceTypeDossierRead {
    /**
     * 
     * @type {number}
     * @memberof TblAnnonceTypeDossierRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblAnnonceTypeDossierRead
     */
    'libelle'?: string | null;
}
/**
 * 
 * @export
 * @interface TblAnnonceTypeJsonldDossierRead
 */
export interface TblAnnonceTypeJsonldDossierRead {
    /**
     * 
     * @type {CommandeJsonldCommandeReadContext}
     * @memberof TblAnnonceTypeJsonldDossierRead
     */
    '@context'?: CommandeJsonldCommandeReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblAnnonceTypeJsonldDossierRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblAnnonceTypeJsonldDossierRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblAnnonceTypeJsonldDossierRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblAnnonceTypeJsonldDossierRead
     */
    'libelle'?: string | null;
}
/**
 * 
 * @export
 * @interface TblCategorieDossierRead
 */
export interface TblCategorieDossierRead {
    /**
     * 
     * @type {number}
     * @memberof TblCategorieDossierRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblCategorieDossierRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {TblDefDomaineDossierRead}
     * @memberof TblCategorieDossierRead
     */
    'domaine'?: TblDefDomaineDossierRead;
}
/**
 * 
 * @export
 * @interface TblCategorieJsonldDossierRead
 */
export interface TblCategorieJsonldDossierRead {
    /**
     * 
     * @type {CommandeJsonldCommandeReadContext}
     * @memberof TblCategorieJsonldDossierRead
     */
    '@context'?: CommandeJsonldCommandeReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblCategorieJsonldDossierRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblCategorieJsonldDossierRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblCategorieJsonldDossierRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblCategorieJsonldDossierRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {TblDefDomaineJsonldDossierRead}
     * @memberof TblCategorieJsonldDossierRead
     */
    'domaine'?: TblDefDomaineJsonldDossierRead;
}
/**
 * 
 * @export
 * @interface TblDefDomaineDossierRead
 */
export interface TblDefDomaineDossierRead {
    /**
     * 
     * @type {number}
     * @memberof TblDefDomaineDossierRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblDefDomaineDossierRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblDefDomaineDossierRead
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblDefDomaineDossierRead
     */
    'code'?: string | null;
}
/**
 * 
 * @export
 * @interface TblDefDomaineJsonldDossierRead
 */
export interface TblDefDomaineJsonldDossierRead {
    /**
     * 
     * @type {CommandeJsonldCommandeReadContext}
     * @memberof TblDefDomaineJsonldDossierRead
     */
    '@context'?: CommandeJsonldCommandeReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblDefDomaineJsonldDossierRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDefDomaineJsonldDossierRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblDefDomaineJsonldDossierRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblDefDomaineJsonldDossierRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblDefDomaineJsonldDossierRead
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblDefDomaineJsonldDossierRead
     */
    'code'?: string | null;
}
/**
 * 
 * @export
 * @interface TblDefDomaineJsonldRubriqueFactureRead
 */
export interface TblDefDomaineJsonldRubriqueFactureRead {
    /**
     * 
     * @type {CommandeJsonldCommandeReadContext}
     * @memberof TblDefDomaineJsonldRubriqueFactureRead
     */
    '@context'?: CommandeJsonldCommandeReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblDefDomaineJsonldRubriqueFactureRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDefDomaineJsonldRubriqueFactureRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblDefDomaineJsonldRubriqueFactureRead
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface TblDefDomaineRubriqueFactureRead
 */
export interface TblDefDomaineRubriqueFactureRead {
    /**
     * 
     * @type {number}
     * @memberof TblDefDomaineRubriqueFactureRead
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface TblFligneFactureVenteRead
 */
export interface TblFligneFactureVenteRead {
    /**
     * 
     * @type {number}
     * @memberof TblFligneFactureVenteRead
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblFligneFactureVenteRead
     */
    'numeroLigne'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFligneFactureVenteRead
     */
    'jal'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneFactureVenteRead
     */
    'rubriqueFacturation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneFactureVenteRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneFactureVenteRead
     */
    'unite'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFligneFactureVenteRead
     */
    'quantite'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFligneFactureVenteRead
     */
    'prixBase'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFligneFactureVenteRead
     */
    'tauxTva'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFligneFactureVenteRead
     */
    'montant'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFligneFactureVenteRead
     */
    'debours'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneFactureVenteRead
     */
    'compte'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFligneFactureVenteRead
     */
    'affiche'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneFactureVenteRead
     */
    'flux'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFligneFactureVenteRead
     */
    'fomalite'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof TblFligneFactureVenteRead
     */
    'idAnnonce'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFligneFactureVenteRead
     */
    'factureDiverse'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneFactureVenteRead
     */
    'cegidCompte'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneFactureVenteRead
     */
    'cegidSp1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneFactureVenteRead
     */
    'cegidSp2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneFactureVenteRead
     */
    'cegidTl1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneFactureVenteRead
     */
    'cegidTl2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneFactureVenteRead
     */
    'cegidTl3'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFligneFactureVenteRead
     */
    'idFdos'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFligneFactureVenteRead
     */
    'dprTypeTarif'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblFligneFactureVenteRead
     */
    'idTat'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblFligneFactureVenteRead
     */
    'idArticle'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TblFligneFactureVenteRead
     */
    'rrrAvoInterdit'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TblFligneFactureVenteRead
     */
    'htReference'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblFligneFactureVenteRead
     */
    'dnidVersionPresta'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblFligneFactureVenteRead
     */
    'idDrt'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TblFligneFactureVenteRead
     */
    'rrrPaye'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TblFligneFactureVenteRead
     */
    'datePaiementRrr'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFligneFactureVenteRead
     */
    'rrrRefuse'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TblFligneFactureVenteRead
     */
    'rrrAvoTraite'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TblFligneFactureVenteRead
     */
    'dateTraitementRrrAvo'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFligneFactureVenteRead
     */
    'tarifLogoEnteteV3'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TblFligneFactureVenteRead
     */
    'rrrAvoReferenceTheorique'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TblFligneFactureVenteRead
     */
    'rrrAvoReferenceReel'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TblFligneFactureVenteRead
     */
    'tauxRrrAvoReel'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblFligneFactureVenteRead
     */
    'dateRrrAvoReel'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFligneFactureVenteRead
     */
    'idUserRrrAvoReel'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TblFligneFactureVenteRead
     */
    'cmdDrtIdDetails'?: boolean;
}
/**
 * 
 * @export
 * @interface TblFligneJsonldFactureVenteRead
 */
export interface TblFligneJsonldFactureVenteRead {
    /**
     * 
     * @type {CommandeJsonldCommandeReadContext}
     * @memberof TblFligneJsonldFactureVenteRead
     */
    '@context'?: CommandeJsonldCommandeReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureVenteRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureVenteRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblFligneJsonldFactureVenteRead
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblFligneJsonldFactureVenteRead
     */
    'numeroLigne'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFligneJsonldFactureVenteRead
     */
    'jal'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureVenteRead
     */
    'rubriqueFacturation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureVenteRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureVenteRead
     */
    'unite'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFligneJsonldFactureVenteRead
     */
    'quantite'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFligneJsonldFactureVenteRead
     */
    'prixBase'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFligneJsonldFactureVenteRead
     */
    'tauxTva'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFligneJsonldFactureVenteRead
     */
    'montant'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFligneJsonldFactureVenteRead
     */
    'debours'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureVenteRead
     */
    'compte'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFligneJsonldFactureVenteRead
     */
    'affiche'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureVenteRead
     */
    'flux'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFligneJsonldFactureVenteRead
     */
    'fomalite'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof TblFligneJsonldFactureVenteRead
     */
    'idAnnonce'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFligneJsonldFactureVenteRead
     */
    'factureDiverse'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureVenteRead
     */
    'cegidCompte'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureVenteRead
     */
    'cegidSp1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureVenteRead
     */
    'cegidSp2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureVenteRead
     */
    'cegidTl1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureVenteRead
     */
    'cegidTl2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureVenteRead
     */
    'cegidTl3'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFligneJsonldFactureVenteRead
     */
    'idFdos'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFligneJsonldFactureVenteRead
     */
    'dprTypeTarif'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblFligneJsonldFactureVenteRead
     */
    'idTat'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblFligneJsonldFactureVenteRead
     */
    'idArticle'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TblFligneJsonldFactureVenteRead
     */
    'rrrAvoInterdit'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TblFligneJsonldFactureVenteRead
     */
    'htReference'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblFligneJsonldFactureVenteRead
     */
    'dnidVersionPresta'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblFligneJsonldFactureVenteRead
     */
    'idDrt'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TblFligneJsonldFactureVenteRead
     */
    'rrrPaye'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureVenteRead
     */
    'datePaiementRrr'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFligneJsonldFactureVenteRead
     */
    'rrrRefuse'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TblFligneJsonldFactureVenteRead
     */
    'rrrAvoTraite'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureVenteRead
     */
    'dateTraitementRrrAvo'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFligneJsonldFactureVenteRead
     */
    'tarifLogoEnteteV3'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TblFligneJsonldFactureVenteRead
     */
    'rrrAvoReferenceTheorique'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TblFligneJsonldFactureVenteRead
     */
    'rrrAvoReferenceReel'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureVenteRead
     */
    'tauxRrrAvoReel'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureVenteRead
     */
    'dateRrrAvoReel'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFligneJsonldFactureVenteRead
     */
    'idUserRrrAvoReel'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TblFligneJsonldFactureVenteRead
     */
    'cmdDrtIdDetails'?: boolean;
}
/**
 * 
 * @export
 * @interface TblJalDefTypepresseJalRead
 */
export interface TblJalDefTypepresseJalRead {
    /**
     * 
     * @type {number}
     * @memberof TblJalDefTypepresseJalRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblJalDefTypepresseJalRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblJalDefTypepresseJalRead
     */
    'info'?: string | null;
}
/**
 * 
 * @export
 * @interface TblJalDefTypepresseJsonldJalRead
 */
export interface TblJalDefTypepresseJsonldJalRead {
    /**
     * 
     * @type {CommandeJsonldCommandeReadContext}
     * @memberof TblJalDefTypepresseJsonldJalRead
     */
    '@context'?: CommandeJsonldCommandeReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblJalDefTypepresseJsonldJalRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblJalDefTypepresseJsonldJalRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblJalDefTypepresseJsonldJalRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblJalDefTypepresseJsonldJalRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblJalDefTypepresseJsonldJalRead
     */
    'info'?: string | null;
}
/**
 * 
 * @export
 * @interface TblPrestaDossierRead
 */
export interface TblPrestaDossierRead {
    /**
     * 
     * @type {number}
     * @memberof TblPrestaDossierRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'numero'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'titre'?: string | null;
    /**
     * 
     * @type {TblPrestaDossierReadType}
     * @memberof TblPrestaDossierRead
     */
    'type'?: TblPrestaDossierReadType | null;
    /**
     * 
     * @type {TblPrestaDossierReadSousType}
     * @memberof TblPrestaDossierRead
     */
    'sousType'?: TblPrestaDossierReadSousType | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'entete'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'annonceBrute'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'annonceFormate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'statut'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaDossierRead
     */
    'idJalEdition'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaDossierRead
     */
    'coef'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'codeDepartement'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'codePostal'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'codeInsee'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'prixLigne'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaDossierRead
     */
    'nLignesEntete'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaDossierRead
     */
    'nLignesIntercalaire'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaDossierRead
     */
    'nLignesAnnonce'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaDossierRead
     */
    'nLignesAnnonceCorrige'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaDossierRead
     */
    'nLignesRefaction'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaDossierRead
     */
    'nLignesDevis'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'exemplairePrix'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaDossierRead
     */
    'exemplairesNb'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'exemplairePort'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'devisAnnonce'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'devisJustif'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'devisLogo'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaDossierRead
     */
    'logo'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'devisTva'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaDossierRead
     */
    'compositionEconomique'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaDossierRead
     */
    'idFormulaire'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'dateParutionDemandee'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'dateParutionEffective'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaDossierRead
     */
    'tiersCoef'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaDossierRead
     */
    'tiersRefracttion'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'jalNumParution'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaDossierRead
     */
    'devis'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaDossierRead
     */
    'commande'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaDossierRead
     */
    'paiementRequis'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaDossierRead
     */
    'annonceSecurise'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaDossierRead
     */
    'annonceSecuriseRelue'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaDossierRead
     */
    'ordrePublicationEnvoye'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaDossierRead
     */
    'attestationClientTransmise'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaDossierRead
     */
    'clientFacture'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaDossierRead
     */
    'attestationJalRecue'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaDossierRead
     */
    'justificatifJalRecu'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaDossierRead
     */
    'factureJalRecu'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaDossierRead
     */
    'factureJalPayee'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaDossierRead
     */
    'jalGroupe'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaDossierRead
     */
    'enteteImage'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaDossierRead
     */
    'enteteTgGroupe'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaDossierRead
     */
    'enteteIgGroupe'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaDossierRead
     */
    'bodac'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'lien'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'annonceur'?: string | null;
    /**
     * 
     * @type {TblPrestaDossierReadJal}
     * @memberof TblPrestaDossierRead
     */
    'jal'?: TblPrestaDossierReadJal | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'dateCommande'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaDossierRead
     */
    'nLignes'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaDossierRead
     */
    'nLignesForce'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'bouclage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'bouclagePrecedent'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'annonceSecuriseeDh'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaDossierRead
     */
    'idAnnonceBodac'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaDossierRead
     */
    'idClient'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaDossierRead
     */
    'idStatut'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaDossierRead
     */
    'idTiersEnvoiFacture'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaDossierRead
     */
    'idTiersEnvoiJustif'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'paiementAttendu'?: string | null;
    /**
     * 
     * @type {TblPrestaDossierReadCategorie}
     * @memberof TblPrestaDossierRead
     */
    'categorie'?: TblPrestaDossierReadCategorie | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaDossierRead
     */
    'idOrigine'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaDossierRead
     */
    'idDevis'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaDossierRead
     */
    'cpm'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaDossierRead
     */
    'regle'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaDossierRead
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'devis2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'relecture'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'commande2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'ordrePublicationEnvoye2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'attestationAp'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'attestationJal'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'justifRecu'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'justifEnvoye'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'factureJal'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'reglementJal'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'dateDevis'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'dateRelecture'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'dateCommande2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'dateOrdrePublication'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'dateAttestationAp'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'dateAttestationJal'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'dateReceptionJustif'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'dateEnvoiJustif'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'dateFactureJal'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'dateReglementJal'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'dateMaj'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaDossierRead
     */
    'userMaj'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaDossierRead
     */
    'userCreation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'dateParution'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'export'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaDossierRead
     */
    'justifCertifie'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaDossierRead
     */
    'idSocieteFacture'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'codeRegroupement'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaDossierRead
     */
    'justifPdf'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'urlJustifPage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'urlJustifSource'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaDossierRead
     */
    'justifVerif'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'justifEtat'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'justifLienPublic'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaDossierRead
     */
    'justifNumeroPage'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaDossierRead
     */
    'justifPdfEnvoye'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'dateEnvoiJustifPdf'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'dateReceptionJustifPdf'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaDossierRead
     */
    'enteteTghGroupe'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaDossierRead
     */
    'justifEtatCode'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaDossierRead
     */
    'retourNpai'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'dateRetourNpai'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'justifNumerosPage'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaDossierRead
     */
    'justifEtatEngine'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaDossierRead
     */
    'idEntiteFacturante'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'emailOpReel'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'justifCoordPageXy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'justifCoordPageUseradd'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'sha1MainDoss'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaDossierRead
     */
    'nJustifdemandeReel'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaDossierRead
     */
    'dossierAttenteDevis'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaDossierRead
     */
    'idOrigineLastModif'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'appsMdc'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaDossierRead
     */
    'appsMdcIdUserModif'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'appsMdcDateModif'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaDossierRead
     */
    'verrouPrix'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaDossierRead
     */
    'tauxRemiseJal'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'prsDateCmbLastMdf'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaDossierRead
     */
    'idContactLastMdf'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaDossierRead
     */
    'idContactCrea'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'prsDateLastMdfG3'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaDossierRead
     */
    'wsIdPrestaFournisseur'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaDossierRead
     */
    'floId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'devisPromo'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'devisAnnonceApresReiHt'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaDossierRead
     */
    'spel'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaDossierRead
     */
    'spelEtatModeration'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'spelLibelleModeration'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'spelTexteModere'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'spelUrlDiffusion'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'dateDebutDiffusionWeb'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'dateFinDiffusionWeb'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaDossierRead
     */
    'spelIdModeration'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'numeroLiaisonG3Ltl'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaDossierRead
     */
    'coefReel'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaDossierRead
     */
    'idActulegales'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaDossierRead
     */
    'xmlCompo'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaDossierRead
     */
    'wsIdPrestaClient'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'wsBddOrigine'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaDossierRead
     */
    'dematisUserDelete'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaDossierRead
     */
    'diffusionSpel'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaDossierRead
     */
    'idTcf'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaDossierRead
     */
    'tarifForfait'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaDossierRead
     */
    'idStDetail'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaDossierRead
     */
    'logoGratuit'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'mntFraisTechnique'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaDossierRead
     */
    'checkForfaitDesk'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaDossierRead
     */
    'fraisTechGratuit'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaDossierRead
     */
    'justifsDetailsInterdit'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaDossierRead
     */
    'nJustifsPayants'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaDossierRead
     */
    'nJustifsGratuits'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaDossierRead
     */
    'extractMotClef'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaDossierRead
     */
    'idPfj'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'actulegalesQrCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'actulegalesShortId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaDossierRead
     */
    'actulegalesPostOk'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'actulegalesRetryData'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'actulegalesError'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaDossierRead
     */
    'dprTypeTarif'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaDossierRead
     */
    'nCaractTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaDossierRead
     */
    'nCaractDeduits'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaDossierRead
     */
    'nCaractReel'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaDossierRead
     */
    'idTat'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaDossierRead
     */
    'fraisTechLogoGratuit'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'mntFraisTechLogo'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaDossierRead
     */
    'forcageNCaract'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaDossierRead
     */
    'nCaractForce'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'mntTarifUnitaireCaractReel'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaDossierRead
     */
    'rrrAvoInterdit'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaDossierRead
     */
    'dnidVersionPresta'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaDossierRead
     */
    'nCaractReelEntete'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'devisEntete'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaDossierRead
     */
    'tarifLogoEnteteV3'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaDossierRead
     */
    'dprTypeTarifEntete'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierRead
     */
    'mntFraisTechEntete'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaDossierRead
     */
    'fraisTechEnteteGratuit'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaDossierRead
     */
    'cmdDrtIdDetails'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaDossierRead
     */
    'idTypeRemiseJal'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaDossierRead
     */
    'idContactMbCrea'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaDossierRead
     */
    'idContactMbLastModif'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaDossierRead
     */
    'sapIdStAttAp'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaDossierRead
     */
    'sdvIdStDevis'?: number | null;
}
/**
 * 
 * @export
 * @interface TblPrestaDossierReadCategorie
 */
export interface TblPrestaDossierReadCategorie {
    /**
     * 
     * @type {number}
     * @memberof TblPrestaDossierReadCategorie
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierReadCategorie
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {TblDefDomaineDossierRead}
     * @memberof TblPrestaDossierReadCategorie
     */
    'domaine'?: TblDefDomaineDossierRead;
}
/**
 * 
 * @export
 * @interface TblPrestaDossierReadCollection
 */
export interface TblPrestaDossierReadCollection {
    /**
     * 
     * @type {number}
     * @memberof TblPrestaDossierReadCollection
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierReadCollection
     */
    'numero'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierReadCollection
     */
    'titre'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierReadCollection
     */
    'statut'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaDossierReadCollection
     */
    'idJalEdition'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierReadCollection
     */
    'codeDepartement'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierReadCollection
     */
    'dateParutionDemandee'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierReadCollection
     */
    'dateParutionEffective'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaDossierReadCollection
     */
    'jalGroupe'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierReadCollection
     */
    'lien'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierReadCollection
     */
    'annonceur'?: string | null;
    /**
     * 
     * @type {TblPrestaDossierReadCollectionJal}
     * @memberof TblPrestaDossierReadCollection
     */
    'jal'?: TblPrestaDossierReadCollectionJal | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierReadCollection
     */
    'bouclage'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaDossierReadCollection
     */
    'idClient'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaDossierReadCollection
     */
    'idStatut'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaDossierReadCollection
     */
    'idTiersEnvoiFacture'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaDossierReadCollection
     */
    'idTiersEnvoiJustif'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaDossierReadCollection
     */
    'idOrigine'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaDossierReadCollection
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierReadCollection
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierReadCollection
     */
    'dateParution'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaDossierReadCollection
     */
    'idSocieteFacture'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaDossierReadCollection
     */
    'idEntiteFacturante'?: number | null;
}
/**
 * 
 * @export
 * @interface TblPrestaDossierReadCollectionJal
 */
export interface TblPrestaDossierReadCollectionJal {
    /**
     * 
     * @type {number}
     * @memberof TblPrestaDossierReadCollectionJal
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierReadCollectionJal
     */
    'nom'?: string;
}
/**
 * 
 * @export
 * @interface TblPrestaDossierReadJal
 */
export interface TblPrestaDossierReadJal {
    /**
     * 
     * @type {number}
     * @memberof TblPrestaDossierReadJal
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierReadJal
     */
    'nom'?: string;
}
/**
 * 
 * @export
 * @interface TblPrestaDossierReadSousType
 */
export interface TblPrestaDossierReadSousType {
    /**
     * 
     * @type {number}
     * @memberof TblPrestaDossierReadSousType
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierReadSousType
     */
    'libelle'?: string | null;
}
/**
 * 
 * @export
 * @interface TblPrestaDossierReadType
 */
export interface TblPrestaDossierReadType {
    /**
     * 
     * @type {number}
     * @memberof TblPrestaDossierReadType
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaDossierReadType
     */
    'libelle'?: string | null;
}
/**
 * 
 * @export
 * @interface TblPrestaJsonldDossierRead
 */
export interface TblPrestaJsonldDossierRead {
    /**
     * 
     * @type {CommandeJsonldCommandeReadContext}
     * @memberof TblPrestaJsonldDossierRead
     */
    '@context'?: CommandeJsonldCommandeReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaJsonldDossierRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'numero'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'titre'?: string | null;
    /**
     * 
     * @type {TblPrestaJsonldDossierReadType}
     * @memberof TblPrestaJsonldDossierRead
     */
    'type'?: TblPrestaJsonldDossierReadType | null;
    /**
     * 
     * @type {TblPrestaJsonldDossierReadSousType}
     * @memberof TblPrestaJsonldDossierRead
     */
    'sousType'?: TblPrestaJsonldDossierReadSousType | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'entete'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'annonceBrute'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'annonceFormate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'statut'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaJsonldDossierRead
     */
    'idJalEdition'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaJsonldDossierRead
     */
    'coef'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'codeDepartement'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'codePostal'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'codeInsee'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'prixLigne'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaJsonldDossierRead
     */
    'nLignesEntete'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaJsonldDossierRead
     */
    'nLignesIntercalaire'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaJsonldDossierRead
     */
    'nLignesAnnonce'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaJsonldDossierRead
     */
    'nLignesAnnonceCorrige'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaJsonldDossierRead
     */
    'nLignesRefaction'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaJsonldDossierRead
     */
    'nLignesDevis'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'exemplairePrix'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaJsonldDossierRead
     */
    'exemplairesNb'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'exemplairePort'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'devisAnnonce'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'devisJustif'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'devisLogo'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaJsonldDossierRead
     */
    'logo'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'devisTva'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaJsonldDossierRead
     */
    'compositionEconomique'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaJsonldDossierRead
     */
    'idFormulaire'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'dateParutionDemandee'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'dateParutionEffective'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaJsonldDossierRead
     */
    'tiersCoef'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaJsonldDossierRead
     */
    'tiersRefracttion'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'jalNumParution'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaJsonldDossierRead
     */
    'devis'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaJsonldDossierRead
     */
    'commande'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaJsonldDossierRead
     */
    'paiementRequis'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaJsonldDossierRead
     */
    'annonceSecurise'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaJsonldDossierRead
     */
    'annonceSecuriseRelue'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaJsonldDossierRead
     */
    'ordrePublicationEnvoye'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaJsonldDossierRead
     */
    'attestationClientTransmise'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaJsonldDossierRead
     */
    'clientFacture'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaJsonldDossierRead
     */
    'attestationJalRecue'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaJsonldDossierRead
     */
    'justificatifJalRecu'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaJsonldDossierRead
     */
    'factureJalRecu'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaJsonldDossierRead
     */
    'factureJalPayee'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaJsonldDossierRead
     */
    'jalGroupe'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaJsonldDossierRead
     */
    'enteteImage'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaJsonldDossierRead
     */
    'enteteTgGroupe'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaJsonldDossierRead
     */
    'enteteIgGroupe'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaJsonldDossierRead
     */
    'bodac'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'lien'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'annonceur'?: string | null;
    /**
     * 
     * @type {TblPrestaJsonldDossierReadJal}
     * @memberof TblPrestaJsonldDossierRead
     */
    'jal'?: TblPrestaJsonldDossierReadJal | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'dateCommande'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaJsonldDossierRead
     */
    'nLignes'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaJsonldDossierRead
     */
    'nLignesForce'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'bouclage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'bouclagePrecedent'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'annonceSecuriseeDh'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaJsonldDossierRead
     */
    'idAnnonceBodac'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaJsonldDossierRead
     */
    'idClient'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaJsonldDossierRead
     */
    'idStatut'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaJsonldDossierRead
     */
    'idTiersEnvoiFacture'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaJsonldDossierRead
     */
    'idTiersEnvoiJustif'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'paiementAttendu'?: string | null;
    /**
     * 
     * @type {TblPrestaJsonldDossierReadCategorie}
     * @memberof TblPrestaJsonldDossierRead
     */
    'categorie'?: TblPrestaJsonldDossierReadCategorie | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaJsonldDossierRead
     */
    'idOrigine'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaJsonldDossierRead
     */
    'idDevis'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaJsonldDossierRead
     */
    'cpm'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaJsonldDossierRead
     */
    'regle'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaJsonldDossierRead
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'devis2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'relecture'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'commande2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'ordrePublicationEnvoye2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'attestationAp'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'attestationJal'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'justifRecu'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'justifEnvoye'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'factureJal'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'reglementJal'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'dateDevis'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'dateRelecture'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'dateCommande2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'dateOrdrePublication'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'dateAttestationAp'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'dateAttestationJal'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'dateReceptionJustif'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'dateEnvoiJustif'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'dateFactureJal'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'dateReglementJal'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'dateMaj'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaJsonldDossierRead
     */
    'userMaj'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaJsonldDossierRead
     */
    'userCreation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'dateParution'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'export'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaJsonldDossierRead
     */
    'justifCertifie'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaJsonldDossierRead
     */
    'idSocieteFacture'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'codeRegroupement'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaJsonldDossierRead
     */
    'justifPdf'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'urlJustifPage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'urlJustifSource'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaJsonldDossierRead
     */
    'justifVerif'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'justifEtat'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'justifLienPublic'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaJsonldDossierRead
     */
    'justifNumeroPage'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaJsonldDossierRead
     */
    'justifPdfEnvoye'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'dateEnvoiJustifPdf'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'dateReceptionJustifPdf'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaJsonldDossierRead
     */
    'enteteTghGroupe'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaJsonldDossierRead
     */
    'justifEtatCode'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaJsonldDossierRead
     */
    'retourNpai'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'dateRetourNpai'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'justifNumerosPage'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaJsonldDossierRead
     */
    'justifEtatEngine'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaJsonldDossierRead
     */
    'idEntiteFacturante'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'emailOpReel'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'justifCoordPageXy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'justifCoordPageUseradd'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'sha1MainDoss'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaJsonldDossierRead
     */
    'nJustifdemandeReel'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaJsonldDossierRead
     */
    'dossierAttenteDevis'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaJsonldDossierRead
     */
    'idOrigineLastModif'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'appsMdc'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaJsonldDossierRead
     */
    'appsMdcIdUserModif'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'appsMdcDateModif'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaJsonldDossierRead
     */
    'verrouPrix'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaJsonldDossierRead
     */
    'tauxRemiseJal'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'prsDateCmbLastMdf'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaJsonldDossierRead
     */
    'idContactLastMdf'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaJsonldDossierRead
     */
    'idContactCrea'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'prsDateLastMdfG3'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaJsonldDossierRead
     */
    'wsIdPrestaFournisseur'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaJsonldDossierRead
     */
    'floId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'devisPromo'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'devisAnnonceApresReiHt'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaJsonldDossierRead
     */
    'spel'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaJsonldDossierRead
     */
    'spelEtatModeration'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'spelLibelleModeration'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'spelTexteModere'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'spelUrlDiffusion'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'dateDebutDiffusionWeb'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'dateFinDiffusionWeb'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaJsonldDossierRead
     */
    'spelIdModeration'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'numeroLiaisonG3Ltl'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaJsonldDossierRead
     */
    'coefReel'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaJsonldDossierRead
     */
    'idActulegales'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaJsonldDossierRead
     */
    'xmlCompo'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaJsonldDossierRead
     */
    'wsIdPrestaClient'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'wsBddOrigine'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaJsonldDossierRead
     */
    'dematisUserDelete'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaJsonldDossierRead
     */
    'diffusionSpel'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaJsonldDossierRead
     */
    'idTcf'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaJsonldDossierRead
     */
    'tarifForfait'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaJsonldDossierRead
     */
    'idStDetail'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaJsonldDossierRead
     */
    'logoGratuit'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'mntFraisTechnique'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaJsonldDossierRead
     */
    'checkForfaitDesk'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaJsonldDossierRead
     */
    'fraisTechGratuit'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaJsonldDossierRead
     */
    'justifsDetailsInterdit'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaJsonldDossierRead
     */
    'nJustifsPayants'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaJsonldDossierRead
     */
    'nJustifsGratuits'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaJsonldDossierRead
     */
    'extractMotClef'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaJsonldDossierRead
     */
    'idPfj'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'actulegalesQrCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'actulegalesShortId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaJsonldDossierRead
     */
    'actulegalesPostOk'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'actulegalesRetryData'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'actulegalesError'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaJsonldDossierRead
     */
    'dprTypeTarif'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaJsonldDossierRead
     */
    'nCaractTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaJsonldDossierRead
     */
    'nCaractDeduits'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaJsonldDossierRead
     */
    'nCaractReel'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaJsonldDossierRead
     */
    'idTat'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaJsonldDossierRead
     */
    'fraisTechLogoGratuit'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'mntFraisTechLogo'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaJsonldDossierRead
     */
    'forcageNCaract'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaJsonldDossierRead
     */
    'nCaractForce'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'mntTarifUnitaireCaractReel'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaJsonldDossierRead
     */
    'rrrAvoInterdit'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaJsonldDossierRead
     */
    'dnidVersionPresta'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaJsonldDossierRead
     */
    'nCaractReelEntete'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'devisEntete'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaJsonldDossierRead
     */
    'tarifLogoEnteteV3'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaJsonldDossierRead
     */
    'dprTypeTarifEntete'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierRead
     */
    'mntFraisTechEntete'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaJsonldDossierRead
     */
    'fraisTechEnteteGratuit'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaJsonldDossierRead
     */
    'cmdDrtIdDetails'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaJsonldDossierRead
     */
    'idTypeRemiseJal'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaJsonldDossierRead
     */
    'idContactMbCrea'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaJsonldDossierRead
     */
    'idContactMbLastModif'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaJsonldDossierRead
     */
    'sapIdStAttAp'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaJsonldDossierRead
     */
    'sdvIdStDevis'?: number | null;
}
/**
 * 
 * @export
 * @interface TblPrestaJsonldDossierReadCategorie
 */
export interface TblPrestaJsonldDossierReadCategorie {
    /**
     * 
     * @type {CommandeJsonldCommandeReadContext}
     * @memberof TblPrestaJsonldDossierReadCategorie
     */
    '@context'?: CommandeJsonldCommandeReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierReadCategorie
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierReadCategorie
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaJsonldDossierReadCategorie
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierReadCategorie
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {TblDefDomaineJsonldDossierRead}
     * @memberof TblPrestaJsonldDossierReadCategorie
     */
    'domaine'?: TblDefDomaineJsonldDossierRead;
}
/**
 * 
 * @export
 * @interface TblPrestaJsonldDossierReadCollection
 */
export interface TblPrestaJsonldDossierReadCollection {
    /**
     * 
     * @type {CommandeJsonldCommandeReadContext}
     * @memberof TblPrestaJsonldDossierReadCollection
     */
    '@context'?: CommandeJsonldCommandeReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierReadCollection
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierReadCollection
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaJsonldDossierReadCollection
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierReadCollection
     */
    'numero'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierReadCollection
     */
    'titre'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierReadCollection
     */
    'statut'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaJsonldDossierReadCollection
     */
    'idJalEdition'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierReadCollection
     */
    'codeDepartement'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierReadCollection
     */
    'dateParutionDemandee'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierReadCollection
     */
    'dateParutionEffective'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaJsonldDossierReadCollection
     */
    'jalGroupe'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierReadCollection
     */
    'lien'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierReadCollection
     */
    'annonceur'?: string | null;
    /**
     * 
     * @type {TblPrestaJsonldDossierReadCollectionJal}
     * @memberof TblPrestaJsonldDossierReadCollection
     */
    'jal'?: TblPrestaJsonldDossierReadCollectionJal | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierReadCollection
     */
    'bouclage'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaJsonldDossierReadCollection
     */
    'idClient'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaJsonldDossierReadCollection
     */
    'idStatut'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaJsonldDossierReadCollection
     */
    'idTiersEnvoiFacture'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaJsonldDossierReadCollection
     */
    'idTiersEnvoiJustif'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaJsonldDossierReadCollection
     */
    'idOrigine'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblPrestaJsonldDossierReadCollection
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierReadCollection
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierReadCollection
     */
    'dateParution'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaJsonldDossierReadCollection
     */
    'idSocieteFacture'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaJsonldDossierReadCollection
     */
    'idEntiteFacturante'?: number | null;
}
/**
 * 
 * @export
 * @interface TblPrestaJsonldDossierReadCollectionJal
 */
export interface TblPrestaJsonldDossierReadCollectionJal {
    /**
     * 
     * @type {CommandeJsonldCommandeReadContext}
     * @memberof TblPrestaJsonldDossierReadCollectionJal
     */
    '@context'?: CommandeJsonldCommandeReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierReadCollectionJal
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierReadCollectionJal
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaJsonldDossierReadCollectionJal
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierReadCollectionJal
     */
    'nom'?: string;
}
/**
 * 
 * @export
 * @interface TblPrestaJsonldDossierReadJal
 */
export interface TblPrestaJsonldDossierReadJal {
    /**
     * 
     * @type {CommandeJsonldCommandeReadContext}
     * @memberof TblPrestaJsonldDossierReadJal
     */
    '@context'?: CommandeJsonldCommandeReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierReadJal
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierReadJal
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaJsonldDossierReadJal
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierReadJal
     */
    'nom'?: string;
}
/**
 * 
 * @export
 * @interface TblPrestaJsonldDossierReadSousType
 */
export interface TblPrestaJsonldDossierReadSousType {
    /**
     * 
     * @type {CommandeJsonldCommandeReadContext}
     * @memberof TblPrestaJsonldDossierReadSousType
     */
    '@context'?: CommandeJsonldCommandeReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierReadSousType
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierReadSousType
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaJsonldDossierReadSousType
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierReadSousType
     */
    'libelle'?: string | null;
}
/**
 * 
 * @export
 * @interface TblPrestaJsonldDossierReadType
 */
export interface TblPrestaJsonldDossierReadType {
    /**
     * 
     * @type {CommandeJsonldCommandeReadContext}
     * @memberof TblPrestaJsonldDossierReadType
     */
    '@context'?: CommandeJsonldCommandeReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierReadType
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierReadType
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblPrestaJsonldDossierReadType
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblPrestaJsonldDossierReadType
     */
    'libelle'?: string | null;
}
/**
 * 
 * @export
 * @interface TblRubfacDomaineAchatsJsonldRubriqueFactureRead
 */
export interface TblRubfacDomaineAchatsJsonldRubriqueFactureRead {
    /**
     * 
     * @type {CommandeJsonldCommandeReadContext}
     * @memberof TblRubfacDomaineAchatsJsonldRubriqueFactureRead
     */
    '@context'?: CommandeJsonldCommandeReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblRubfacDomaineAchatsJsonldRubriqueFactureRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblRubfacDomaineAchatsJsonldRubriqueFactureRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblRubfacDomaineAchatsJsonldRubriqueFactureRead
     */
    'id'?: number;
    /**
     * 
     * @type {TblDefDomaineJsonldRubriqueFactureRead}
     * @memberof TblRubfacDomaineAchatsJsonldRubriqueFactureRead
     */
    'domaine'?: TblDefDomaineJsonldRubriqueFactureRead;
    /**
     * 
     * @type {boolean}
     * @memberof TblRubfacDomaineAchatsJsonldRubriqueFactureRead
     */
    'defaut'?: boolean;
}
/**
 * 
 * @export
 * @interface TblRubfacDomaineAchatsRubriqueFactureRead
 */
export interface TblRubfacDomaineAchatsRubriqueFactureRead {
    /**
     * 
     * @type {number}
     * @memberof TblRubfacDomaineAchatsRubriqueFactureRead
     */
    'id'?: number;
    /**
     * 
     * @type {TblDefDomaineRubriqueFactureRead}
     * @memberof TblRubfacDomaineAchatsRubriqueFactureRead
     */
    'domaine'?: TblDefDomaineRubriqueFactureRead;
    /**
     * 
     * @type {boolean}
     * @memberof TblRubfacDomaineAchatsRubriqueFactureRead
     */
    'defaut'?: boolean;
}
/**
 * 
 * @export
 * @interface TblUtilisateurJalRead
 */
export interface TblUtilisateurJalRead {
    /**
     * 
     * @type {number}
     * @memberof TblUtilisateurJalRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblUtilisateurJalRead
     */
    'nom'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblUtilisateurJalRead
     */
    'prenom'?: string;
}
/**
 * 
 * @export
 * @interface TblUtilisateurJsonldJalRead
 */
export interface TblUtilisateurJsonldJalRead {
    /**
     * 
     * @type {CommandeJsonldCommandeReadContext}
     * @memberof TblUtilisateurJsonldJalRead
     */
    '@context'?: CommandeJsonldCommandeReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblUtilisateurJsonldJalRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblUtilisateurJsonldJalRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblUtilisateurJsonldJalRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblUtilisateurJsonldJalRead
     */
    'nom'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblUtilisateurJsonldJalRead
     */
    'prenom'?: string;
}

/**
 * CommandeApi - axios parameter creator
 * @export
 */
export const CommandeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Récupérer la liste des commandes
         * @summary Récupérer la liste des commandes
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommandeCollection: async (xIdSociete?: number, page?: number, itemsPerPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/dossiers/commandes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'une commande
         * @summary Récupérer les informations d\'une commande
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommandeItem: async (id: string, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCommandeItem', 'id', id)
            const localVarPath = `/dossiers/commandes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommandeApi - functional programming interface
 * @export
 */
export const CommandeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CommandeApiAxiosParamCreator(configuration)
    return {
        /**
         * Récupérer la liste des commandes
         * @summary Récupérer la liste des commandes
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCommandeCollection(xIdSociete?: number, page?: number, itemsPerPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCommandeCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCommandeCollection(xIdSociete, page, itemsPerPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Récupérer les informations d\'une commande
         * @summary Récupérer les informations d\'une commande
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCommandeItem(id: string, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommandeJsonldCommandeRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCommandeItem(id, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CommandeApi - factory interface
 * @export
 */
export const CommandeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CommandeApiFp(configuration)
    return {
        /**
         * Récupérer la liste des commandes
         * @summary Récupérer la liste des commandes
         * @param {CommandeApiGetCommandeCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommandeCollection(requestParameters: CommandeApiGetCommandeCollectionRequest = {}, options?: AxiosRequestConfig): AxiosPromise<GetCommandeCollection200Response> {
            return localVarFp.getCommandeCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'une commande
         * @summary Récupérer les informations d\'une commande
         * @param {CommandeApiGetCommandeItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommandeItem(requestParameters: CommandeApiGetCommandeItemRequest, options?: AxiosRequestConfig): AxiosPromise<CommandeJsonldCommandeRead> {
            return localVarFp.getCommandeItem(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getCommandeCollection operation in CommandeApi.
 * @export
 * @interface CommandeApiGetCommandeCollectionRequest
 */
export interface CommandeApiGetCommandeCollectionRequest {
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof CommandeApiGetCommandeCollection
     */
    readonly xIdSociete?: number

    /**
     * The collection page number
     * @type {number}
     * @memberof CommandeApiGetCommandeCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof CommandeApiGetCommandeCollection
     */
    readonly itemsPerPage?: number
}

/**
 * Request parameters for getCommandeItem operation in CommandeApi.
 * @export
 * @interface CommandeApiGetCommandeItemRequest
 */
export interface CommandeApiGetCommandeItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof CommandeApiGetCommandeItem
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof CommandeApiGetCommandeItem
     */
    readonly xIdSociete?: number
}

/**
 * CommandeApi - object-oriented interface
 * @export
 * @class CommandeApi
 * @extends {BaseAPI}
 */
export class CommandeApi extends BaseAPI {
    /**
     * Récupérer la liste des commandes
     * @summary Récupérer la liste des commandes
     * @param {CommandeApiGetCommandeCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommandeApi
     */
    public getCommandeCollection(requestParameters: CommandeApiGetCommandeCollectionRequest = {}, options?: AxiosRequestConfig) {
        return CommandeApiFp(this.configuration).getCommandeCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'une commande
     * @summary Récupérer les informations d\'une commande
     * @param {CommandeApiGetCommandeItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommandeApi
     */
    public getCommandeItem(requestParameters: CommandeApiGetCommandeItemRequest, options?: AxiosRequestConfig) {
        return CommandeApiFp(this.configuration).getCommandeItem(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DossierApi - axios parameter creator
 * @export
 */
export const DossierApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Obtenir la liste des dossiers
         * @summary Obtenir la liste des dossiers
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {number} [numero] 
         * @param {Array<number>} [numero2] 
         * @param {string} [prestationsNumero] 
         * @param {string} [prestationsJalNom] 
         * @param {string} [prestationsAnnonceur] 
         * @param {number} [societe] 
         * @param {Array<number>} [societe2] 
         * @param {string} [prestationsDateParutionDemandeeBefore] 
         * @param {string} [prestationsDateParutionDemandeeStrictlyBefore] 
         * @param {string} [prestationsDateParutionDemandeeAfter] 
         * @param {string} [prestationsDateParutionDemandeeStrictlyAfter] 
         * @param {boolean} [deleted] 
         * @param {'asc' | 'desc'} [orderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDossierCollection: async (xIdSociete?: number, page?: number, itemsPerPage?: number, numero?: number, numero2?: Array<number>, prestationsNumero?: string, prestationsJalNom?: string, prestationsAnnonceur?: string, societe?: number, societe2?: Array<number>, prestationsDateParutionDemandeeBefore?: string, prestationsDateParutionDemandeeStrictlyBefore?: string, prestationsDateParutionDemandeeAfter?: string, prestationsDateParutionDemandeeStrictlyAfter?: string, deleted?: boolean, orderId?: 'asc' | 'desc', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/dossiers/dossiers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (numero !== undefined) {
                localVarQueryParameter['numero'] = numero;
            }

            if (numero2) {
                localVarQueryParameter['numero[]'] = numero2;
            }

            if (prestationsNumero !== undefined) {
                localVarQueryParameter['prestations.numero'] = prestationsNumero;
            }

            if (prestationsJalNom !== undefined) {
                localVarQueryParameter['prestations.jal.nom'] = prestationsJalNom;
            }

            if (prestationsAnnonceur !== undefined) {
                localVarQueryParameter['prestations.annonceur'] = prestationsAnnonceur;
            }

            if (societe !== undefined) {
                localVarQueryParameter['societe'] = societe;
            }

            if (societe2) {
                localVarQueryParameter['societe[]'] = societe2;
            }

            if (prestationsDateParutionDemandeeBefore !== undefined) {
                localVarQueryParameter['prestations.dateParutionDemandee[before]'] = prestationsDateParutionDemandeeBefore;
            }

            if (prestationsDateParutionDemandeeStrictlyBefore !== undefined) {
                localVarQueryParameter['prestations.dateParutionDemandee[strictly_before]'] = prestationsDateParutionDemandeeStrictlyBefore;
            }

            if (prestationsDateParutionDemandeeAfter !== undefined) {
                localVarQueryParameter['prestations.dateParutionDemandee[after]'] = prestationsDateParutionDemandeeAfter;
            }

            if (prestationsDateParutionDemandeeStrictlyAfter !== undefined) {
                localVarQueryParameter['prestations.dateParutionDemandee[strictly_after]'] = prestationsDateParutionDemandeeStrictlyAfter;
            }

            if (deleted !== undefined) {
                localVarQueryParameter['deleted'] = deleted;
            }

            if (orderId !== undefined) {
                localVarQueryParameter['order[id]'] = orderId;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'un dossier
         * @summary Récupérer les informations d\'un dossier
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDossierItem: async (id: string, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDossierItem', 'id', id)
            const localVarPath = `/dossiers/dossiers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DossierApi - functional programming interface
 * @export
 */
export const DossierApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DossierApiAxiosParamCreator(configuration)
    return {
        /**
         * Obtenir la liste des dossiers
         * @summary Obtenir la liste des dossiers
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {number} [numero] 
         * @param {Array<number>} [numero2] 
         * @param {string} [prestationsNumero] 
         * @param {string} [prestationsJalNom] 
         * @param {string} [prestationsAnnonceur] 
         * @param {number} [societe] 
         * @param {Array<number>} [societe2] 
         * @param {string} [prestationsDateParutionDemandeeBefore] 
         * @param {string} [prestationsDateParutionDemandeeStrictlyBefore] 
         * @param {string} [prestationsDateParutionDemandeeAfter] 
         * @param {string} [prestationsDateParutionDemandeeStrictlyAfter] 
         * @param {boolean} [deleted] 
         * @param {'asc' | 'desc'} [orderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDossierCollection(xIdSociete?: number, page?: number, itemsPerPage?: number, numero?: number, numero2?: Array<number>, prestationsNumero?: string, prestationsJalNom?: string, prestationsAnnonceur?: string, societe?: number, societe2?: Array<number>, prestationsDateParutionDemandeeBefore?: string, prestationsDateParutionDemandeeStrictlyBefore?: string, prestationsDateParutionDemandeeAfter?: string, prestationsDateParutionDemandeeStrictlyAfter?: string, deleted?: boolean, orderId?: 'asc' | 'desc', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDossierCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDossierCollection(xIdSociete, page, itemsPerPage, numero, numero2, prestationsNumero, prestationsJalNom, prestationsAnnonceur, societe, societe2, prestationsDateParutionDemandeeBefore, prestationsDateParutionDemandeeStrictlyBefore, prestationsDateParutionDemandeeAfter, prestationsDateParutionDemandeeStrictlyAfter, deleted, orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Récupérer les informations d\'un dossier
         * @summary Récupérer les informations d\'un dossier
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDossierItem(id: string, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DossierJsonldDossierRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDossierItem(id, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DossierApi - factory interface
 * @export
 */
export const DossierApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DossierApiFp(configuration)
    return {
        /**
         * Obtenir la liste des dossiers
         * @summary Obtenir la liste des dossiers
         * @param {DossierApiGetDossierCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDossierCollection(requestParameters: DossierApiGetDossierCollectionRequest = {}, options?: AxiosRequestConfig): AxiosPromise<GetDossierCollection200Response> {
            return localVarFp.getDossierCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, requestParameters.numero, requestParameters.numero2, requestParameters.prestationsNumero, requestParameters.prestationsJalNom, requestParameters.prestationsAnnonceur, requestParameters.societe, requestParameters.societe2, requestParameters.prestationsDateParutionDemandeeBefore, requestParameters.prestationsDateParutionDemandeeStrictlyBefore, requestParameters.prestationsDateParutionDemandeeAfter, requestParameters.prestationsDateParutionDemandeeStrictlyAfter, requestParameters.deleted, requestParameters.orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'un dossier
         * @summary Récupérer les informations d\'un dossier
         * @param {DossierApiGetDossierItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDossierItem(requestParameters: DossierApiGetDossierItemRequest, options?: AxiosRequestConfig): AxiosPromise<DossierJsonldDossierRead> {
            return localVarFp.getDossierItem(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getDossierCollection operation in DossierApi.
 * @export
 * @interface DossierApiGetDossierCollectionRequest
 */
export interface DossierApiGetDossierCollectionRequest {
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof DossierApiGetDossierCollection
     */
    readonly xIdSociete?: number

    /**
     * The collection page number
     * @type {number}
     * @memberof DossierApiGetDossierCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof DossierApiGetDossierCollection
     */
    readonly itemsPerPage?: number

    /**
     * 
     * @type {number}
     * @memberof DossierApiGetDossierCollection
     */
    readonly numero?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof DossierApiGetDossierCollection
     */
    readonly numero2?: Array<number>

    /**
     * 
     * @type {string}
     * @memberof DossierApiGetDossierCollection
     */
    readonly prestationsNumero?: string

    /**
     * 
     * @type {string}
     * @memberof DossierApiGetDossierCollection
     */
    readonly prestationsJalNom?: string

    /**
     * 
     * @type {string}
     * @memberof DossierApiGetDossierCollection
     */
    readonly prestationsAnnonceur?: string

    /**
     * 
     * @type {number}
     * @memberof DossierApiGetDossierCollection
     */
    readonly societe?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof DossierApiGetDossierCollection
     */
    readonly societe2?: Array<number>

    /**
     * 
     * @type {string}
     * @memberof DossierApiGetDossierCollection
     */
    readonly prestationsDateParutionDemandeeBefore?: string

    /**
     * 
     * @type {string}
     * @memberof DossierApiGetDossierCollection
     */
    readonly prestationsDateParutionDemandeeStrictlyBefore?: string

    /**
     * 
     * @type {string}
     * @memberof DossierApiGetDossierCollection
     */
    readonly prestationsDateParutionDemandeeAfter?: string

    /**
     * 
     * @type {string}
     * @memberof DossierApiGetDossierCollection
     */
    readonly prestationsDateParutionDemandeeStrictlyAfter?: string

    /**
     * 
     * @type {boolean}
     * @memberof DossierApiGetDossierCollection
     */
    readonly deleted?: boolean

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof DossierApiGetDossierCollection
     */
    readonly orderId?: 'asc' | 'desc'
}

/**
 * Request parameters for getDossierItem operation in DossierApi.
 * @export
 * @interface DossierApiGetDossierItemRequest
 */
export interface DossierApiGetDossierItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof DossierApiGetDossierItem
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof DossierApiGetDossierItem
     */
    readonly xIdSociete?: number
}

/**
 * DossierApi - object-oriented interface
 * @export
 * @class DossierApi
 * @extends {BaseAPI}
 */
export class DossierApi extends BaseAPI {
    /**
     * Obtenir la liste des dossiers
     * @summary Obtenir la liste des dossiers
     * @param {DossierApiGetDossierCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DossierApi
     */
    public getDossierCollection(requestParameters: DossierApiGetDossierCollectionRequest = {}, options?: AxiosRequestConfig) {
        return DossierApiFp(this.configuration).getDossierCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, requestParameters.numero, requestParameters.numero2, requestParameters.prestationsNumero, requestParameters.prestationsJalNom, requestParameters.prestationsAnnonceur, requestParameters.societe, requestParameters.societe2, requestParameters.prestationsDateParutionDemandeeBefore, requestParameters.prestationsDateParutionDemandeeStrictlyBefore, requestParameters.prestationsDateParutionDemandeeAfter, requestParameters.prestationsDateParutionDemandeeStrictlyAfter, requestParameters.deleted, requestParameters.orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'un dossier
     * @summary Récupérer les informations d\'un dossier
     * @param {DossierApiGetDossierItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DossierApi
     */
    public getDossierItem(requestParameters: DossierApiGetDossierItemRequest, options?: AxiosRequestConfig) {
        return DossierApiFp(this.configuration).getDossierItem(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FactureVenteApi - axios parameter creator
 * @export
 */
export const FactureVenteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Récupérer les informations d\'une facture de vente
         * @summary Récupérer les informations d\'une facture de vente
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFactureVenteCollection: async (xIdSociete?: number, page?: number, itemsPerPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/dossiers/factures_vente`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtenir la liste des factures de ventes
         * @summary Obtenir la liste des factures de ventes
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFactureVenteItem: async (id: string, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFactureVenteItem', 'id', id)
            const localVarPath = `/dossiers/factures_vente/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FactureVenteApi - functional programming interface
 * @export
 */
export const FactureVenteApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FactureVenteApiAxiosParamCreator(configuration)
    return {
        /**
         * Récupérer les informations d\'une facture de vente
         * @summary Récupérer les informations d\'une facture de vente
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFactureVenteCollection(xIdSociete?: number, page?: number, itemsPerPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetFactureVenteCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFactureVenteCollection(xIdSociete, page, itemsPerPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtenir la liste des factures de ventes
         * @summary Obtenir la liste des factures de ventes
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFactureVenteItem(id: string, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FactureVenteJsonldFactureVenteRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFactureVenteItem(id, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FactureVenteApi - factory interface
 * @export
 */
export const FactureVenteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FactureVenteApiFp(configuration)
    return {
        /**
         * Récupérer les informations d\'une facture de vente
         * @summary Récupérer les informations d\'une facture de vente
         * @param {FactureVenteApiGetFactureVenteCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFactureVenteCollection(requestParameters: FactureVenteApiGetFactureVenteCollectionRequest = {}, options?: AxiosRequestConfig): AxiosPromise<GetFactureVenteCollection200Response> {
            return localVarFp.getFactureVenteCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtenir la liste des factures de ventes
         * @summary Obtenir la liste des factures de ventes
         * @param {FactureVenteApiGetFactureVenteItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFactureVenteItem(requestParameters: FactureVenteApiGetFactureVenteItemRequest, options?: AxiosRequestConfig): AxiosPromise<FactureVenteJsonldFactureVenteRead> {
            return localVarFp.getFactureVenteItem(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getFactureVenteCollection operation in FactureVenteApi.
 * @export
 * @interface FactureVenteApiGetFactureVenteCollectionRequest
 */
export interface FactureVenteApiGetFactureVenteCollectionRequest {
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof FactureVenteApiGetFactureVenteCollection
     */
    readonly xIdSociete?: number

    /**
     * The collection page number
     * @type {number}
     * @memberof FactureVenteApiGetFactureVenteCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof FactureVenteApiGetFactureVenteCollection
     */
    readonly itemsPerPage?: number
}

/**
 * Request parameters for getFactureVenteItem operation in FactureVenteApi.
 * @export
 * @interface FactureVenteApiGetFactureVenteItemRequest
 */
export interface FactureVenteApiGetFactureVenteItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof FactureVenteApiGetFactureVenteItem
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof FactureVenteApiGetFactureVenteItem
     */
    readonly xIdSociete?: number
}

/**
 * FactureVenteApi - object-oriented interface
 * @export
 * @class FactureVenteApi
 * @extends {BaseAPI}
 */
export class FactureVenteApi extends BaseAPI {
    /**
     * Récupérer les informations d\'une facture de vente
     * @summary Récupérer les informations d\'une facture de vente
     * @param {FactureVenteApiGetFactureVenteCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FactureVenteApi
     */
    public getFactureVenteCollection(requestParameters: FactureVenteApiGetFactureVenteCollectionRequest = {}, options?: AxiosRequestConfig) {
        return FactureVenteApiFp(this.configuration).getFactureVenteCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtenir la liste des factures de ventes
     * @summary Obtenir la liste des factures de ventes
     * @param {FactureVenteApiGetFactureVenteItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FactureVenteApi
     */
    public getFactureVenteItem(requestParameters: FactureVenteApiGetFactureVenteItemRequest, options?: AxiosRequestConfig) {
        return FactureVenteApiFp(this.configuration).getFactureVenteItem(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * JournalAnnoncesLegalesApi - axios parameter creator
 * @export
 */
export const JournalAnnoncesLegalesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Obtenir la liste des jals
         * @summary Obtenir la liste des jals
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [recherche] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJournalAnnoncesLegalesCollection: async (xIdSociete?: number, page?: number, itemsPerPage?: number, recherche?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/dossiers/jals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (recherche !== undefined) {
                localVarQueryParameter['recherche'] = recherche;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'un jal
         * @summary Récupérer les informations d\'un jal
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJournalAnnoncesLegalesItem: async (id: string, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getJournalAnnoncesLegalesItem', 'id', id)
            const localVarPath = `/dossiers/jals/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * JournalAnnoncesLegalesApi - functional programming interface
 * @export
 */
export const JournalAnnoncesLegalesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = JournalAnnoncesLegalesApiAxiosParamCreator(configuration)
    return {
        /**
         * Obtenir la liste des jals
         * @summary Obtenir la liste des jals
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [recherche] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getJournalAnnoncesLegalesCollection(xIdSociete?: number, page?: number, itemsPerPage?: number, recherche?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetJournalAnnoncesLegalesCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getJournalAnnoncesLegalesCollection(xIdSociete, page, itemsPerPage, recherche, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Récupérer les informations d\'un jal
         * @summary Récupérer les informations d\'un jal
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getJournalAnnoncesLegalesItem(id: string, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JournalAnnoncesLegalesJsonldJalRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getJournalAnnoncesLegalesItem(id, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * JournalAnnoncesLegalesApi - factory interface
 * @export
 */
export const JournalAnnoncesLegalesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = JournalAnnoncesLegalesApiFp(configuration)
    return {
        /**
         * Obtenir la liste des jals
         * @summary Obtenir la liste des jals
         * @param {JournalAnnoncesLegalesApiGetJournalAnnoncesLegalesCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJournalAnnoncesLegalesCollection(requestParameters: JournalAnnoncesLegalesApiGetJournalAnnoncesLegalesCollectionRequest = {}, options?: AxiosRequestConfig): AxiosPromise<GetJournalAnnoncesLegalesCollection200Response> {
            return localVarFp.getJournalAnnoncesLegalesCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, requestParameters.recherche, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'un jal
         * @summary Récupérer les informations d\'un jal
         * @param {JournalAnnoncesLegalesApiGetJournalAnnoncesLegalesItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJournalAnnoncesLegalesItem(requestParameters: JournalAnnoncesLegalesApiGetJournalAnnoncesLegalesItemRequest, options?: AxiosRequestConfig): AxiosPromise<JournalAnnoncesLegalesJsonldJalRead> {
            return localVarFp.getJournalAnnoncesLegalesItem(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getJournalAnnoncesLegalesCollection operation in JournalAnnoncesLegalesApi.
 * @export
 * @interface JournalAnnoncesLegalesApiGetJournalAnnoncesLegalesCollectionRequest
 */
export interface JournalAnnoncesLegalesApiGetJournalAnnoncesLegalesCollectionRequest {
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof JournalAnnoncesLegalesApiGetJournalAnnoncesLegalesCollection
     */
    readonly xIdSociete?: number

    /**
     * The collection page number
     * @type {number}
     * @memberof JournalAnnoncesLegalesApiGetJournalAnnoncesLegalesCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof JournalAnnoncesLegalesApiGetJournalAnnoncesLegalesCollection
     */
    readonly itemsPerPage?: number

    /**
     * 
     * @type {string}
     * @memberof JournalAnnoncesLegalesApiGetJournalAnnoncesLegalesCollection
     */
    readonly recherche?: string
}

/**
 * Request parameters for getJournalAnnoncesLegalesItem operation in JournalAnnoncesLegalesApi.
 * @export
 * @interface JournalAnnoncesLegalesApiGetJournalAnnoncesLegalesItemRequest
 */
export interface JournalAnnoncesLegalesApiGetJournalAnnoncesLegalesItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof JournalAnnoncesLegalesApiGetJournalAnnoncesLegalesItem
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof JournalAnnoncesLegalesApiGetJournalAnnoncesLegalesItem
     */
    readonly xIdSociete?: number
}

/**
 * JournalAnnoncesLegalesApi - object-oriented interface
 * @export
 * @class JournalAnnoncesLegalesApi
 * @extends {BaseAPI}
 */
export class JournalAnnoncesLegalesApi extends BaseAPI {
    /**
     * Obtenir la liste des jals
     * @summary Obtenir la liste des jals
     * @param {JournalAnnoncesLegalesApiGetJournalAnnoncesLegalesCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JournalAnnoncesLegalesApi
     */
    public getJournalAnnoncesLegalesCollection(requestParameters: JournalAnnoncesLegalesApiGetJournalAnnoncesLegalesCollectionRequest = {}, options?: AxiosRequestConfig) {
        return JournalAnnoncesLegalesApiFp(this.configuration).getJournalAnnoncesLegalesCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, requestParameters.recherche, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'un jal
     * @summary Récupérer les informations d\'un jal
     * @param {JournalAnnoncesLegalesApiGetJournalAnnoncesLegalesItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JournalAnnoncesLegalesApi
     */
    public getJournalAnnoncesLegalesItem(requestParameters: JournalAnnoncesLegalesApiGetJournalAnnoncesLegalesItemRequest, options?: AxiosRequestConfig) {
        return JournalAnnoncesLegalesApiFp(this.configuration).getJournalAnnoncesLegalesItem(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RubriqueFacturationApi - axios parameter creator
 * @export
 */
export const RubriqueFacturationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Obtenir la liste des rubrique de facturation
         * @summary Obtenir la liste des rubrique de facturation
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRubriqueFacturationCollection: async (xIdSociete?: number, page?: number, itemsPerPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/dossiers/rubrique_facturations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'une rubrique de facturation
         * @summary Récupérer les informations d\'une rubrique de facturation
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRubriqueFacturationItem: async (id: string, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getRubriqueFacturationItem', 'id', id)
            const localVarPath = `/dossiers/rubrique_facturations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RubriqueFacturationApi - functional programming interface
 * @export
 */
export const RubriqueFacturationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RubriqueFacturationApiAxiosParamCreator(configuration)
    return {
        /**
         * Obtenir la liste des rubrique de facturation
         * @summary Obtenir la liste des rubrique de facturation
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRubriqueFacturationCollection(xIdSociete?: number, page?: number, itemsPerPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRubriqueFacturationCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRubriqueFacturationCollection(xIdSociete, page, itemsPerPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Récupérer les informations d\'une rubrique de facturation
         * @summary Récupérer les informations d\'une rubrique de facturation
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRubriqueFacturationItem(id: string, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RubriqueFacturationJsonldRubriqueFactureRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRubriqueFacturationItem(id, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RubriqueFacturationApi - factory interface
 * @export
 */
export const RubriqueFacturationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RubriqueFacturationApiFp(configuration)
    return {
        /**
         * Obtenir la liste des rubrique de facturation
         * @summary Obtenir la liste des rubrique de facturation
         * @param {RubriqueFacturationApiGetRubriqueFacturationCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRubriqueFacturationCollection(requestParameters: RubriqueFacturationApiGetRubriqueFacturationCollectionRequest = {}, options?: AxiosRequestConfig): AxiosPromise<GetRubriqueFacturationCollection200Response> {
            return localVarFp.getRubriqueFacturationCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'une rubrique de facturation
         * @summary Récupérer les informations d\'une rubrique de facturation
         * @param {RubriqueFacturationApiGetRubriqueFacturationItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRubriqueFacturationItem(requestParameters: RubriqueFacturationApiGetRubriqueFacturationItemRequest, options?: AxiosRequestConfig): AxiosPromise<RubriqueFacturationJsonldRubriqueFactureRead> {
            return localVarFp.getRubriqueFacturationItem(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getRubriqueFacturationCollection operation in RubriqueFacturationApi.
 * @export
 * @interface RubriqueFacturationApiGetRubriqueFacturationCollectionRequest
 */
export interface RubriqueFacturationApiGetRubriqueFacturationCollectionRequest {
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof RubriqueFacturationApiGetRubriqueFacturationCollection
     */
    readonly xIdSociete?: number

    /**
     * The collection page number
     * @type {number}
     * @memberof RubriqueFacturationApiGetRubriqueFacturationCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof RubriqueFacturationApiGetRubriqueFacturationCollection
     */
    readonly itemsPerPage?: number
}

/**
 * Request parameters for getRubriqueFacturationItem operation in RubriqueFacturationApi.
 * @export
 * @interface RubriqueFacturationApiGetRubriqueFacturationItemRequest
 */
export interface RubriqueFacturationApiGetRubriqueFacturationItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof RubriqueFacturationApiGetRubriqueFacturationItem
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof RubriqueFacturationApiGetRubriqueFacturationItem
     */
    readonly xIdSociete?: number
}

/**
 * RubriqueFacturationApi - object-oriented interface
 * @export
 * @class RubriqueFacturationApi
 * @extends {BaseAPI}
 */
export class RubriqueFacturationApi extends BaseAPI {
    /**
     * Obtenir la liste des rubrique de facturation
     * @summary Obtenir la liste des rubrique de facturation
     * @param {RubriqueFacturationApiGetRubriqueFacturationCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RubriqueFacturationApi
     */
    public getRubriqueFacturationCollection(requestParameters: RubriqueFacturationApiGetRubriqueFacturationCollectionRequest = {}, options?: AxiosRequestConfig) {
        return RubriqueFacturationApiFp(this.configuration).getRubriqueFacturationCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'une rubrique de facturation
     * @summary Récupérer les informations d\'une rubrique de facturation
     * @param {RubriqueFacturationApiGetRubriqueFacturationItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RubriqueFacturationApi
     */
    public getRubriqueFacturationItem(requestParameters: RubriqueFacturationApiGetRubriqueFacturationItemRequest, options?: AxiosRequestConfig) {
        return RubriqueFacturationApiFp(this.configuration).getRubriqueFacturationItem(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TauxTvaApi - axios parameter creator
 * @export
 */
export const TauxTvaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Obtenir la liste des taux de TVA
         * @summary Obtenir la liste des taux de TVA
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTauxTvaCollection: async (xIdSociete?: number, page?: number, itemsPerPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/dossiers/taux_tvas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'un taux de TVA
         * @summary Récupérer les informations d\'un taux de TVA
         * @param {string} idtva Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTauxTvaItem: async (idtva: string, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idtva' is not null or undefined
            assertParamExists('getTauxTvaItem', 'idtva', idtva)
            const localVarPath = `/dossiers/taux_tvas/{idtva}`
                .replace(`{${"idtva"}}`, encodeURIComponent(String(idtva)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TauxTvaApi - functional programming interface
 * @export
 */
export const TauxTvaApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TauxTvaApiAxiosParamCreator(configuration)
    return {
        /**
         * Obtenir la liste des taux de TVA
         * @summary Obtenir la liste des taux de TVA
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTauxTvaCollection(xIdSociete?: number, page?: number, itemsPerPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTauxTvaCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTauxTvaCollection(xIdSociete, page, itemsPerPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Récupérer les informations d\'un taux de TVA
         * @summary Récupérer les informations d\'un taux de TVA
         * @param {string} idtva Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTauxTvaItem(idtva: string, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TauxTvaJsonldTvaFactureRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTauxTvaItem(idtva, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TauxTvaApi - factory interface
 * @export
 */
export const TauxTvaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TauxTvaApiFp(configuration)
    return {
        /**
         * Obtenir la liste des taux de TVA
         * @summary Obtenir la liste des taux de TVA
         * @param {TauxTvaApiGetTauxTvaCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTauxTvaCollection(requestParameters: TauxTvaApiGetTauxTvaCollectionRequest = {}, options?: AxiosRequestConfig): AxiosPromise<GetTauxTvaCollection200Response> {
            return localVarFp.getTauxTvaCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'un taux de TVA
         * @summary Récupérer les informations d\'un taux de TVA
         * @param {TauxTvaApiGetTauxTvaItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTauxTvaItem(requestParameters: TauxTvaApiGetTauxTvaItemRequest, options?: AxiosRequestConfig): AxiosPromise<TauxTvaJsonldTvaFactureRead> {
            return localVarFp.getTauxTvaItem(requestParameters.idtva, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getTauxTvaCollection operation in TauxTvaApi.
 * @export
 * @interface TauxTvaApiGetTauxTvaCollectionRequest
 */
export interface TauxTvaApiGetTauxTvaCollectionRequest {
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof TauxTvaApiGetTauxTvaCollection
     */
    readonly xIdSociete?: number

    /**
     * The collection page number
     * @type {number}
     * @memberof TauxTvaApiGetTauxTvaCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof TauxTvaApiGetTauxTvaCollection
     */
    readonly itemsPerPage?: number
}

/**
 * Request parameters for getTauxTvaItem operation in TauxTvaApi.
 * @export
 * @interface TauxTvaApiGetTauxTvaItemRequest
 */
export interface TauxTvaApiGetTauxTvaItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof TauxTvaApiGetTauxTvaItem
     */
    readonly idtva: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof TauxTvaApiGetTauxTvaItem
     */
    readonly xIdSociete?: number
}

/**
 * TauxTvaApi - object-oriented interface
 * @export
 * @class TauxTvaApi
 * @extends {BaseAPI}
 */
export class TauxTvaApi extends BaseAPI {
    /**
     * Obtenir la liste des taux de TVA
     * @summary Obtenir la liste des taux de TVA
     * @param {TauxTvaApiGetTauxTvaCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TauxTvaApi
     */
    public getTauxTvaCollection(requestParameters: TauxTvaApiGetTauxTvaCollectionRequest = {}, options?: AxiosRequestConfig) {
        return TauxTvaApiFp(this.configuration).getTauxTvaCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'un taux de TVA
     * @summary Récupérer les informations d\'un taux de TVA
     * @param {TauxTvaApiGetTauxTvaItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TauxTvaApi
     */
    public getTauxTvaItem(requestParameters: TauxTvaApiGetTauxTvaItemRequest, options?: AxiosRequestConfig) {
        return TauxTvaApiFp(this.configuration).getTauxTvaItem(requestParameters.idtva, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }
}


