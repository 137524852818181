/* istanbul ignore file */

import React from 'react';

import { useRoutes } from 'react-router-dom';
import { ThemeProvider, CssBaseline } from '@mui/material';
import {
    CenterCircularProgress,
    ModalProvider,
    VerboseProvider,
} from '@europrocurement/flexy-components';
import { UseKeycloakService } from '@europrocurement/l2d-keycloak';
import useLoadingStatus from './hooks/useLoading';
import Router from './router/router';
import L2DTheme from './Theme';

const App = function () {
    const routing = useRoutes(Router);
    const kc = UseKeycloakService();
    const theme = L2DTheme();

    const { loading } = useLoadingStatus({ checkReady: () => kc.isInitialized() });

    return (
        <VerboseProvider>
            <ModalProvider>
                <ThemeProvider theme={theme}>
                    {!loading ? routing : <CenterCircularProgress />}
                    <CssBaseline />
                </ThemeProvider>
            </ModalProvider>
        </VerboseProvider>
    );
};

export default App;
