import React from 'react';
import { Formalite } from '@europrocurement/l2d-domain/reducers/formalites/slices/formalite';
import { TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import { FaOptionIcon } from '@europrocurement/l2d-icons';
import useIncompatibility from '../../hooks/useIncompatibility';

export type TableHeadingProps = {
    formalities: Formalite[];
};

const TableHeading: React.FunctionComponent<TableHeadingProps> = function (props) {
    const { formalities } = props;
    const { getDistinctCategories } = useIncompatibility();
    const theme = useTheme();
    const borderColor = theme.palette.grey[300];

    const celStyle = {
        width: '120px',
        height: '120px',
        maxHeight: '120px',
        maxWidth: '250px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        textAlign: 'center',
        verticalAlign: 'middle',
        border: `solid 0px ${borderColor}`,
        borderBottomWidth: '1px',
        backgroundColor: theme.palette.background.paper,
    };
    const categoryCelStyle = {
        height: '40px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
        textAlign: 'center',
        verticalAlign: 'middle',
        border: `solid 0px ${borderColor}`,
        borderBottomWidth: '1px',
    };

    let currentCategory = 0;
    const distinctCategories = getDistinctCategories(formalities);

    const categoriesItems = distinctCategories.map((item, index: number) => {
        const rightBorderWidth = index === distinctCategories.length - 1 ? '1px' : '0px';
        console.log({ item });
        return (
            <TableCell
                key={`table-category-head-${item.id}`}
                colSpan={item.count}
                sx={{
                    ...categoryCelStyle,
                    borderLeftWidth: '1px',
                    borderTopWidth: '1px',
                    borderRightWidth: rightBorderWidth,
                }}
            >
                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                >
                    <Typography
                        sx={{
                            fontSize: '18px',
                        }}
                    >
                        <FaOptionIcon icon={['fad', item.iconeName]} />
                    </Typography>{' '}
                    {item.label}
                </Box>
            </TableCell>
        );
    });
    const items = formalities.map((formality: Formalite, index: number) => {
        const leftBorderWidth = currentCategory !== formality?.categorie?.id ? '1px' : '0px';
        const rightBorderWidth = index === formalities.length - 1 ? '1px' : '0px';
        currentCategory = formality?.categorie?.id || 0;

        return (
            <TableCell
                sx={{
                    ...celStyle,
                    borderLeftWidth: leftBorderWidth,
                    borderRightWidth: rightBorderWidth,
                    backgroundColor: theme.palette.background.default,
                }}
                key={`table-head-${formality.id}`}
            >
                {formality.libelle}
            </TableCell>
        );
    });

    return (
        <TableHead sx={{ position: 'sticky', top: '0', zIndex: '1' }}>
            <TableRow>
                <TableCell sx={{ ...celStyle, height: '40px', borderBottomWidth: '0px' }} />
                <TableCell
                    sx={{ ...categoryCelStyle, borderBottomWidth: '0xp' }}
                    key="table-head-category-base"
                />
                {categoriesItems}
            </TableRow>
            <TableRow>
                <TableCell sx={{ ...celStyle, borderBottomWidth: '1px' }} />
                <TableCell
                    sx={celStyle}
                    key="table-head-0"
                />
                {items}
            </TableRow>
        </TableHead>
    );
};

export default TableHeading;
