import useApiRequest from '@b2d/hooks/useApiRequest';
import ConfirmationModalContent from '@b2d/pages/Achats/components/modals/ConfirmationModalContent';
import { swapSocieteMediaObjectModalMessages } from '@b2d/pages/Achats/constants/wording/modals';
import {
    AppDispatch,
    getFromSpecifiedDataSourceThunks,
    mediaObjectApi,
} from '@b2d/redux/RootStore';
import { ListActionButton, ModalContext } from '@europrocurement/flexy-components';
import { MediaObject } from '@europrocurement/l2d-domain';
import { MediaObjectApiPatchMediaObjectItemRequest } from '@europrocurement/l2d-domain/openApi/ApiAchats';
import { changeCompanyIcon, FaOptionIcon } from '@europrocurement/l2d-icons';
import { KeycloakHasRole } from '@europrocurement/l2d-keycloak';
import { Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { FunctionComponent, useCallback, useContext } from 'react';
import { useDispatch } from 'react-redux';

type SwitchAssignedCompanyButtonProps = {
    mediaObject: MediaObject;
    getMediaObject: ReturnType<typeof getFromSpecifiedDataSourceThunks>;
};

const SwitchAssignedCompanyButton: FunctionComponent<SwitchAssignedCompanyButtonProps> = function ({
    mediaObject,
    getMediaObject,
}: SwitchAssignedCompanyButtonProps) {
    const dispatch = useDispatch<AppDispatch>();
    const { enqueueSnackbar } = useSnackbar();
    const { modalActions } = useContext(ModalContext);
    const { request } = useApiRequest();

    const switchSociete = useCallback(
        async (mediaObjectToSwitchAssignedCompany: MediaObject) => {
            modalActions.call(
                <ConfirmationModalContent
                    messages={swapSocieteMediaObjectModalMessages}
                    actionOnValidation={() => {
                        if (
                            !mediaObjectToSwitchAssignedCompany ||
                            !mediaObjectToSwitchAssignedCompany.id
                        ) {
                            enqueueSnackbar(
                                <Typography>Il n&apos;y a aucun PDF sélectionné !</Typography>,
                                {
                                    variant: 'error',
                                },
                            );
                            return;
                        }

                        // Suppression physique actuellement impossible à cause de :
                        // 1. Contrainte SQL (même si aucune facture liée n'est active, elle reste liée en base)
                        if (
                            mediaObjectToSwitchAssignedCompany &&
                            mediaObjectToSwitchAssignedCompany.facturesAchat
                        ) {
                            const isOneFactureInUse =
                                mediaObjectToSwitchAssignedCompany.facturesAchat.find(
                                    (facture) => !facture.deleted,
                                );

                            if (isOneFactureInUse) {
                                enqueueSnackbar(
                                    <Typography>
                                        Ce PDF ne peut pas être supprimé car il est lié à une ou
                                        plusieurs facture(s) d&apos;achat en cours de traitement(s),
                                        ou traitée(s).
                                    </Typography>,
                                    {
                                        variant: 'error',
                                    },
                                );

                                return;
                            }
                        }

                        const successCallback = () => {
                            dispatch(getMediaObject({}));
                        };

                        const requestParameters: MediaObjectApiPatchMediaObjectItemRequest = {
                            id: mediaObjectToSwitchAssignedCompany.id.toString(),
                            mediaObject: {
                                idSociete:
                                    mediaObjectToSwitchAssignedCompany.idSociete === 12 ? 2 : 12,
                            },
                        };

                        // Suppression logique
                        request(mediaObjectApi.patchMediaObjectItem(requestParameters), {
                            withToaster: true,
                            successCallback,
                        });
                    }}
                />,
            );
        },
        [dispatch, enqueueSnackbar, getMediaObject, modalActions, request],
    );
    return (
        <KeycloakHasRole kcRole="realm:interne">
            <ListActionButton
                tooltip="Modifier la société"
                onClick={() => {
                    switchSociete(mediaObject);
                }}
                iconComponent={<FaOptionIcon {...changeCompanyIcon.props} />}
            />
        </KeycloakHasRole>
    );
};

export default SwitchAssignedCompanyButton;
