import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { FieldValues, SubmitHandler, UseFormReturn } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { Box, Button, InputAdornment, Typography } from '@mui/material';
import { FlexyForm, FormStructure } from '@europrocurement/flexy-form';
import {
    ajouterLigneRSF,
    fillProduits,
    useActionOnChangeTauxRemise,
} from '@b2d/pages/Achats/components/forms/functions/produitFunctions';
import { LigneProduit, TableauProduits } from '@b2d/pages/Achats/components/forms/types';
import { round } from '@b2d/pages/Achats/components/forms/functions/calculsProduits';
import { Fournisseur } from '@europrocurement/l2d-domain';
import { useModal } from '@europrocurement/flexy-components';
import { UseSwitchLockValuesProps } from './useSwitchLockValues';

export type UpdateDiscountRate = {
    formContext: UseFormReturn<FieldValues, unknown>;
    stateSwitchLockValues: UseSwitchLockValuesProps['stateSwitchLockValues'];
    fournisseur?: Fournisseur;
    overwriteAfterUpdate?: () => void;
};

/**
 * Custom hook for updating the discount rate.
 *
 * This hook manages the logic for updating the discount rate in a form.
 * It handles user interactions, modal display, and updating the form values based on the discount rate.
 *
 * @param {UpdateDiscountRate} params - The parameters for the hook.
 * @param {UseFormReturn<FieldValues, unknown>} params.formContext - The react-hook-form context used for form operations.
 * @param {Fournisseur} [params.fournisseur] - The supplier information, if available.
 * @param {Function} [params.overwriteAfterUpdate] - Optional callback to execute after updating the discount rate.
 *
 * @returns {Object} - An object containing functions and state for managing the discount rate update.
 */
const useUpdateDiscountRate = ({
    formContext,
    stateSwitchLockValues,
    fournisseur,
    overwriteAfterUpdate,
}: UpdateDiscountRate) => {
    const actionOnChangeTauxRemise = useActionOnChangeTauxRemise();
    const { enqueueSnackbar } = useSnackbar();
    const { modalActions } = useModal();

    // Tell when a RSF have been set manually by the user.
    const [hasSetRsf, setHasSetRsf] = useState<boolean>(false);
    // Define when user blur the RSF field.
    const [hasBlured, setHasBlured] = useState<boolean>(false);
    // Can't translate it in english, I don't understand the purpose at 100%.
    const [rsfInclusTxremise, setRsfInclusTxremise] = useState<string>();

    /**
     * Generates the form structure for the discount rate modal.
     *
     * @returns {FormStructure[]} - The form structure array for the discount rate modal.
     */
    const modalFactureFormStructure: FormStructure[] = useMemo(
        () => [
            {
                type: 'number',
                name: 'facture-form-modal-txremise',
                inputlabel: 'Taux de remise',
                autoFocus: true,
                placeholder: '0',
                InputProps: {
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                },
                defaultValue: '20',
                inputProps: {
                    step: 1,
                    min: 0,
                },
                onChangeInput: (event, input, changeInputFormContext) => {
                    const value: string = changeInputFormContext.getValue(input.path);
                    setRsfInclusTxremise(value);
                },
                onPaste: (event: React.ClipboardEvent) => {
                    if (event.clipboardData.getData('text').match(/\D/g)) event.preventDefault();
                },
                onKeyDown: (event: React.KeyboardEvent) => {
                    if (['e', 'E', '+', '-'].includes(event.key)) event.preventDefault();
                },
                rules: {
                    required: 'Taux de remise manquant',
                },
                xs: 10,
                sm: 10,
                md: 10,
                lg: 10,
            },
        ],
        [],
    );

    /**
     * Executes actions based on the presence of a RSF product in the product table.
     *
     * @param {TableauProduits} tableauProduits - The product table.
     * @param {Function} [actionIfRSF] - The action to execute if the product is found.
     * @param {Function} [actionNoRSF] - The action to execute if the product is not found.
     */
    const actionOnRSF = (
        tableauProduits: TableauProduits,
        actionIfRSF?: (produitRSF?: Partial<LigneProduit> | undefined) => void,
        actionNoRSF?: () => void,
    ) => {
        const produits = tableauProduits;
        const produitRSF = produits.find((item) => item.rubriqueFacturation?.id === 99);

        if (produitRSF && actionIfRSF !== undefined) {
            actionIfRSF(produitRSF);
        }

        if (!produitRSF && actionNoRSF !== undefined) {
            actionNoRSF();
        }
    };

    /**
     * Updates the product values in the form based on the discount rate.
     *
     * @param {string} txRemise - The discount rate.
     * @param {number} pubLineNumber - The line number of the product in the form.
     */
    const updateProduitsOnRemise = useCallback(
        async (txRemise: string, pubLineNumber: number) => {
            if (!fournisseur) throw new Error('Fournisseur non trouvé');

            // Sert pour relever les fournisseurs dont la RSF a été modifiée manuellement.
            setHasSetRsf(true);
            await ajouterLigneRSF(formContext, fournisseur);

            const produits = formContext.getValues(`produits`) as TableauProduits;

            if (fournisseur && parseFloat(txRemise) > 0 && pubLineNumber !== undefined) {
                // Calcul de la ligne de PUB
                const pubHt = formContext.getValues(`produits.${pubLineNumber}.ht`);

                if (pubHt !== undefined) {
                    const newPubHt = round(pubHt / (1 - parseFloat(txRemise) / 100)) as number;
                    const newRsfHt = round(newPubHt - pubHt);

                    const txtva = formContext.getValues(`produits.${pubLineNumber}.txtva`);

                    formContext.setValue(`produits.${pubLineNumber}.ht`, newPubHt);

                    fillProduits(
                        formContext,
                        {
                            idLigneVente: formContext.getValues(
                                `produits.${pubLineNumber}.idLigneVente`,
                            ),
                            ht: newPubHt,
                            txtva: formContext.getValues(`produits.${pubLineNumber}.txtva`),
                        },
                        pubLineNumber,
                    );

                    // On cherche la ligne de rsf
                    actionOnRSF(
                        produits,
                        (produitRSF: Partial<LigneProduit>) => {
                            const index = produits.indexOf(produitRSF);

                            produits[index].ht = newRsfHt;
                            produits[index].txtva = txtva;

                            formContext.setValue('produits', produits);
                        },
                        undefined,
                    );

                    const produitsAfterAddRsf = formContext.getValues(
                        `produits`,
                    ) as TableauProduits;

                    const RSF = produitsAfterAddRsf.find(
                        (item) => item.rubriqueFacturation?.id === 99,
                    );

                    if (RSF) {
                        fillProduits(formContext, RSF, produitsAfterAddRsf.indexOf(RSF));
                        setTimeout(() => {
                            formContext.trigger(`produits.${produitsAfterAddRsf.indexOf(RSF)}.ht`);
                        }, 1);
                    }

                    if (overwriteAfterUpdate) {
                        overwriteAfterUpdate();
                    }
                }
            }
        },
        [formContext, fournisseur, overwriteAfterUpdate],
    );

    /**
     * Handles the form submission for the discount rate modal.
     *
     * @param {Object} data - The form data containing the discount rate.
     */
    const handleModalSubmit: SubmitHandler<{
        'facture-form-modal-txremise': string;
    }> = useCallback(
        (data: { 'facture-form-modal-txremise': string }) => {
            actionOnChangeTauxRemise({
                formContext,
                stateSwitchLockValues,
                produitConcernedByRsfExist: (produitConcernedByRsf) => {
                    const txRemise = data['facture-form-modal-txremise'];
                    updateProduitsOnRemise(txRemise, produitConcernedByRsf);
                    setHasBlured(true);
                    modalActions.close();
                },
                produitConcernedByRsfDoNotExist: (message) => {
                    enqueueSnackbar(<Typography>{message}</Typography>, { variant: 'error' });
                },
            });
        },
        [
            actionOnChangeTauxRemise,
            enqueueSnackbar,
            formContext,
            stateSwitchLockValues,
            modalActions,
            updateProduitsOnRemise,
        ],
    );

    /**
     * Opens the discount rate modal.
     */
    const modalDiscountRate = useCallback(() => {
        modalActions.call(
            <Box>
                <FlexyForm
                    formObject={{
                        'facture-form-modal-txremise': rsfInclusTxremise,
                    }}
                    formStructure={modalFactureFormStructure}
                    onSubmit={handleModalSubmit}
                    submitButton={{
                        render: ({ isSubmitSuccessful, isSubmitting, isLoading, isValidating }) => (
                            <Box
                                display="flex"
                                flexDirection="row"
                                justifyContent="end"
                            >
                                <Button
                                    type="submit"
                                    disabled={
                                        isSubmitSuccessful ||
                                        isSubmitting ||
                                        isLoading ||
                                        isValidating
                                    }
                                    variant="contained"
                                    style={{ marginTop: '16px', marginBottom: '16px' }}
                                >
                                    Appliquer la remise
                                </Button>
                            </Box>
                        ),
                    }}
                />
            </Box>,
        );
    }, [handleModalSubmit, modalActions, modalFactureFormStructure, rsfInclusTxremise]);

    useEffect(() => {
        formContext.setValue('rsf_inclus_txremise', rsfInclusTxremise);
    }, [formContext, rsfInclusTxremise]);

    return {
        modalDiscountRate,
        stateDiscountRate: {
            hasBlured: {
                value: hasBlured,
                set: setHasBlured,
            },
            rsfInclusTxremise: {
                value: rsfInclusTxremise,
                set: setRsfInclusTxremise,
            },
            hasSetRsf: {
                value: hasSetRsf,
                set: setHasSetRsf,
            },
        },
    };
};

export default useUpdateDiscountRate;
