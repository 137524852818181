import { useMemo } from 'react';

import { FormStructure } from '@europrocurement/flexy-form';
import { UseTotalsSectionStructureProps } from '@b2d/pages/Achats/components/forms/formElements/types';
import { totalsStructure } from '@b2d/pages/Achats/components/forms/formElements/fragments/totalsSection';
import { ExtractArrayType } from '@b2d/utils/types';

type HeaderChildrenArrayType = ExtractArrayType<UseTotalsSectionStructureProps['headerChildren']>;

const useTotalsSectionStructure = ({
    mode,
    headerSwitches,
    headerChildren,
    isMulti,
}: UseTotalsSectionStructureProps) => {
    let overwriteHeaderChildren = useMemo<HeaderChildrenArrayType>(() => {
        const temporaryHeaderChildren: HeaderChildrenArrayType = [];

        if (headerChildren) {
            if (Array.isArray(headerChildren)) {
                overwriteHeaderChildren = [...overwriteHeaderChildren, ...headerChildren];
            } else {
                overwriteHeaderChildren.push(headerChildren);
            }
        }

        return temporaryHeaderChildren;
    }, [headerChildren]);

    const totalsSectionStructure = useMemo<Array<FormStructure>>(
        () =>
            totalsStructure({
                mode,
                isMulti,
                headerSwitches,
                headerChildren: overwriteHeaderChildren,
            }),
        [mode, isMulti, headerSwitches, overwriteHeaderChildren],
    );

    return {
        totalsSectionStructure,
    };
};

export default useTotalsSectionStructure;
