import useKeyboard from '@b2d/hooks/useKeyboard';
import { customizerSelector, useAppSelector } from '@b2d/redux/RootStore';
import {
    ColumnDatatable,
    FiltersDatatableList,
    StoreDatatable,
} from '@europrocurement/flexy-datatable';
import { EuroprocApiResponseStatus, getDataThunkType } from '@europrocurement/l2d-redux-utils';
import { Box } from '@mui/material';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import {
    FlexyIconButton,
    modalActionsType,
    ModalContext,
    Scrollbar,
} from '@europrocurement/flexy-components';

import PreviewCard from '@b2d/pages/Offres/components/PreviewCard';
import ModalFormTitle from '@b2d/pages/Offres/components/form/ModalFormTitle';
import ActionButton from '@b2d/pages/Offres/components/ActionButton';
import { OfferModelItem } from '@b2d/pages/Offres/models/types';
import ListingTitle from '@b2d/pages/Offres/components/ListingTitle';
import { editIcon, NamedIconsType } from '@europrocurement/l2d-icons';
import { AbstractFormType } from '../forms/types';

type ListingWithPreviewProps = {
    listingTitle: string;
    listingButton: {
        action: () => void;
        icon: NamedIconsType;
        label: string;
    };
    listingColumns: ColumnDatatable<unknown>[];
    filters?: FiltersDatatableList;
    form?: React.FC<AbstractFormType>;
    model: OfferModelItem<unknown>;
    filtersControl: boolean;
};

const ListingWithPreview: React.FC<ListingWithPreviewProps> = function (props) {
    const {
        listingTitle,
        listingButton,
        listingColumns,
        filters,
        form: Form,
        model,
        filtersControl = false,
    } = props;

    const [formId, setFormId] = React.useState<string | null>(null);

    const { datasourcesThunks, selector, sliceName } = model;

    const dispatch = useDispatch();
    const fetchAction = model.datasourcesThunks.main.getData as getDataThunkType<never>;
    const deleteSelectedItem = () => {
        dispatch({
            type: `${sliceName}/deletemainSelected`,
        });
    };

    const onSelectItem = (item: { id: string | number }) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        dispatch(datasourcesThunks.main.getSelected(item) as any);
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const modelSelector = useAppSelector(selector) as any;
    const datasource = modelSelector.main;
    const selectedEntity = datasource.selected;
    const selectedEntityResponseStatus: EuroprocApiResponseStatus = datasource.selectedStatus;

    /** --- Initial state / global methods / hooks / accessors --- */

    const { modalActions }: { modalActions: modalActionsType } = React.useContext(ModalContext);
    const { xIdSociete } = useAppSelector(customizerSelector);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const reloadList = () => dispatch(datasourcesThunks.main.getData({}) as any);
    const refreshSelectedEntity = () => onSelectItem(selectedEntity);

    const afterUpdate = () => {
        reloadList();
        refreshSelectedEntity();
    };

    const onEdit = () => {
        if (!selectedEntity || !Form) {
            return;
        }

        const nameMap = {
            additional_field: 'champ complémentaire',
            action: 'action',
            article: 'article',
            document: 'document',
            offer_package: 'package',
            catalog: 'catalogue',
            offer: 'offre',
            offer_option: 'option',
            offer_group: 'groupement',
        } as const;

        const articleMap = ['offer', 'offer_option'];
        const getArticle = () => (articleMap.includes(model.key) ? 'une' : 'un');
        const getModelTranslation = () => nameMap[model.key as keyof typeof nameMap];

        const getEditionTitle = () => `Édition d'${getArticle()} ${getModelTranslation()}`;

        modalActions.call(
            <>
                <ModalFormTitle
                    icon={model.icon}
                    title={getEditionTitle()}
                />
                <Form
                    entity={selectedEntity}
                    afterSubmit={afterUpdate}
                />
            </>,
        );
    };
    React.useEffect(() => {
        const selectedId = selectedEntity?.id;
        if (selectedId === formId) {
            onEdit();
            setFormId(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedEntity, formId]);

    const columns = React.useMemo(
        () => [
            ...listingColumns,

            {
                label: 'Actions',
                displayLabel: true,
                render: (item: { id: string }) =>
                    item.id && (
                        <FlexyIconButton
                            displayTooltip
                            icon={editIcon}
                            onClick={() => setFormId(item.id)}
                        />
                    ),
                isDisplayed: true,
            },
        ],

        [listingColumns],
    );

    const memoizedListingColumns = React.useMemo(() => columns, [columns]);

    /** ---------------------------------------------------------- */

    /** --- KEYBOARD CONTROLS --- */
    const keyboardActions = [
        {
            key: 'Escape',
            action: () => deleteSelectedItem(),
        },
    ];
    useKeyboard(keyboardActions);
    /** ------------------------- */

    const canSelect = (item: { id: string }) => {
        const isAlreadySelected: boolean = selectedEntity && selectedEntity.id === item.id;
        const selectionIsPending: boolean = selectedEntityResponseStatus === 'loading';

        if (isAlreadySelected || selectionIsPending) {
            return false;
        }
        return true;
    };

    const onClickRow = (_: React.MouseEvent<HTMLElement>, item: { id: string }) => {
        if (canSelect(item)) {
            onSelectItem(item);
        }
    };

    return (
        <Box>
            <Box display="flex">
                <ListingTitle
                    title={listingTitle}
                    modelIcon={model.icon}
                />
                <ActionButton {...listingButton} />
            </Box>
            <Box sx={{ display: 'flex' }}>
                {/* Listing */}
                {datasource ? (
                    <Box sx={{ width: '69%', maxHeight: '65vh' }}>
                        <Scrollbar>
                            <StoreDatatable
                                dataSource={datasource}
                                columns={memoizedListingColumns}
                                fetchData={fetchAction}
                                filters={filters ?? []}
                                filtersControl={filtersControl}
                                hideColumnOptions
                                selectedId={selectedEntity?.id}
                                onClickRow={onClickRow}
                                observers={[xIdSociete]}
                            />
                        </Scrollbar>
                    </Box>
                ) : null}

                <Box sx={{ width: '28%', maxHeight: '65vh' }}>
                    <Scrollbar>
                        <PreviewCard
                            isPreviewEmpty={!selectedEntity}
                            isPreviewLoading={selectedEntityResponseStatus === 'loading'}
                            selectedEntity={selectedEntity}
                            onEdit={onEdit}
                        />
                    </Scrollbar>
                </Box>
            </Box>
        </Box>
    );
};

export default ListingWithPreview;
