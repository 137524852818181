import { Box } from '@mui/material';
import React, { FunctionComponent } from 'react';
import InvoiceFormFragmentSkeleton from '../views/forms/simple/InvoiceFormFragmentSkeleton';

export type FormOverlayProps = {
    sectionsCount?: number;
};

const FormOverlay: FunctionComponent = function ({ sectionsCount = 5 }: FormOverlayProps) {
    return (
        <Box
            sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(255, 255, 255, 1)',
                zIndex: 10,
                display: 'flex',
                justifyContent: 'space-between',
                pointerEvents: 'none', // Prevent interactions
                padding: 2, // Fine-tune padding if needed
                boxSizing: 'border-box', // Ensure padding doesn’t affect dimensions
                borderRadius: '15px',
                flexDirection: 'column',
            }}
        >
            {Array.from({ length: sectionsCount }).map((_, index) => (
                <Box
                    key={`sections-count-${sectionsCount}-skeleton-${index.toString()}`}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        height: `${100 / sectionsCount}vh`,
                    }}
                >
                    <InvoiceFormFragmentSkeleton sectionsCount={sectionsCount} />
                </Box>
            ))}
        </Box>
    );
};

export default FormOverlay;
