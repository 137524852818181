import { AppDispatch, RootStateType, selectPrescripteur } from '@b2d/redux/RootStore';
import { Prescripteur } from '@europrocurement/l2d-domain';
import { AxiosRequestConfig } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { DataSource, EuroprocApiResponseStatus } from '@europrocurement/l2d-redux-utils';
import { useSociete } from './societeHooks';

/**
 * Hooks that regroup prescriber actions / methods / accessors
 */

export const SelectedPrescripteursSelector = (s: RootStateType) =>
    s.tiers.prescripteur.main.selected;

export const PrescripteursDatasourceSelector = (s: RootStateType) => s.tiers.prescripteur.main;

export const PrescripteursDatasourceStatusSelector = (s: RootStateType) =>
    s.tiers.prescripteur.main.status;

export const useCurrentPrescriber = () => {
    const prescriber: Prescripteur | null = useSelector(SelectedPrescripteursSelector);

    return prescriber;
};

export const usePrescriberDatasource = () => {
    const datasource: DataSource<Prescripteur> = useSelector(PrescripteursDatasourceSelector);

    return datasource;
};

export const usePrescriberStatus = () => {
    const status: EuroprocApiResponseStatus = useSelector(PrescripteursDatasourceStatusSelector);

    return status;
};

export const useSelectPrescriber = () => {
    const prescriber = useCurrentPrescriber();
    const prescriberStatus = usePrescriberStatus();
    const dispatch = useDispatch<AppDispatch>();

    return (prescriberId: number, options?: AxiosRequestConfig, relaod: boolean = false) => {
        if (relaod) {
            dispatch(selectPrescripteur({ id: prescriberId, options }));
        } else if (
            (!prescriber || prescriber.id !== prescriberId) &&
            prescriberStatus !== 'loading'
        ) {
            dispatch(selectPrescripteur({ id: prescriberId, options }));
        }
    };
};

export const useUnSelectPrescriber = () => {
    const dispatch = useDispatch<AppDispatch>();

    return () => {
        dispatch({ type: 'prescripteur/deletemainSelected' });
    };
};

export const useReloadPrescriber = () => {
    const prescriber = useCurrentPrescriber();

    const unselect = useUnSelectPrescriber();
    const select = useSelectPrescriber();
    return (options?: AxiosRequestConfig) => {
        if (!prescriber || !prescriber.id) {
            console.error('cant relaod prescriber, prescriber is not selected');
            return;
        }
        unselect();
        select(prescriber.id, options, true);
    };
};

export const getRelationSocietePrescriber = (
    prescriber: Prescripteur | null,
    companyId: number,
) => {
    if (!prescriber) {
        return null;
    }

    const defaultCompany = prescriber.relationsSocietes[0] ?? null;

    if (!companyId) {
        return defaultCompany;
    }

    const relatedCompany = prescriber.relationsSocietes.find(
        (relation) => relation.societe?.id === companyId,
    );

    return relatedCompany ?? defaultCompany;
};

export const useCompanyRelationCurrentPrescriber = () => {
    const prescriber = useCurrentPrescriber();
    const companyId = useSociete();

    return getRelationSocietePrescriber(prescriber, companyId);
};

export const usePrescriberUtils = () => {
    const prescriber = useCurrentPrescriber();

    if (!prescriber) {
        throw new Error('missing selected presciber');
    }

    const companyId = useSociete();
    const companyRelation = useCompanyRelationCurrentPrescriber();

    if (!companyRelation) {
        throw new Error('missing compagny relation for this presciber');
    }

    const unselectPrescriber = useUnSelectPrescriber();
    const selectPrescriber = useSelectPrescriber();
    const reloadPrescriber = useReloadPrescriber();

    return {
        prescriber,
        companyId,
        companyRelation,
        methods: {
            unselectPrescriber,
            selectPrescriber,
            reloadPrescriber,
        },
    };
};
