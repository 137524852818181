import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { FaOptionIcon, moreIcon } from '@europrocurement/l2d-icons';
import { IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { Formalite } from '@europrocurement/l2d-domain';
import ModalConfirm from '@europrocurement/flexy-components/components/organisms/ModalConfirm';
import useApiRequest from '@b2d/hooks/useApiRequest';
import { formaliteApi } from '@b2d/redux/subReducers/FormalityReducer';
import { useModal } from '@europrocurement/flexy-components';

export type FormalityListItemActionsProps = {
    item: Formalite;
    onItemUpdate: () => void;
};

const FormalityListItemActions: FunctionComponent<FormalityListItemActionsProps> = function (
    props,
) {
    const { item, onItemUpdate } = props;
    const { id, libelle, deleted } = item;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const { request } = useApiRequest();
    const { modalActions } = useModal();

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onModalConfirm = () =>
        request(
            formaliteApi.apiFormalitesIdPut({
                id: id as unknown as string,
                formaliteJsonldFormaliteUpdate: { libelle, deleted: !deleted },
            }),
            {
                successCallback: onItemUpdate,
            },
        );

    const onOpenModal = () => {
        handleClose();

        modalActions.call(
            <ModalConfirm
                onConfirm={onModalConfirm}
                message={
                    deleted
                        ? 'Êtes vous sur de vouloir activer cette formalité ?'
                        : 'Êtes vous sur de vouloir désactiver cette formalité ?'
                }
            />,
        );
    };

    return (
        <div
            onClick={(e) => {
                e.stopPropagation();
            }}
            onKeyDown={() => {}}
            role="button"
            tabIndex={0}
        >
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <FaOptionIcon
                    {...moreIcon.props}
                    iconProps={{ style: { fontSize: '16px' } }}
                />
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={handleClose}>
                    <Link
                        to={`${id}`}
                        target="_blank"
                        style={{ textDecoration: 'none' }}
                    >
                        <Typography sx={{ color: 'text.primary' }}>
                            Ouvrir dans un nouvel onglet
                        </Typography>
                    </Link>
                </MenuItem>
                <MenuItem onClick={onOpenModal}>
                    {deleted ? 'Activer cette formalité' : 'Désactiver cette formalité'}
                </MenuItem>
            </Menu>
        </div>
    );
};

export default FormalityListItemActions;
