import React from 'react';
import { Controller, UseControllerProps, useFormContext } from 'react-hook-form';

import { useResolvePath } from '@europrocurement/flexy-components';
import {
    AutocompleteEventType,
    AutocompleteStore,
    AutocompleteStoreEvents,
    AutocompleteStoreProps,
} from './AutocompleteStore';
import { type ControledAutocompleteStoreEvents, type ExtandedFormContext } from '../FlexyForm';

export type ControlledAutocompleteProps = UseControllerProps & {
    events: ControledAutocompleteStoreEvents<Record<string, unknown>>;
} & Omit<AutocompleteStoreProps<Record<string, unknown>>, 'events'>;

export const ControlledAutocomplete: React.FunctionComponent<ControlledAutocompleteProps> =
    function (props) {
        const { resolvePath } = useResolvePath();

        const formContext = useFormContext();

        const extandedFormContext: ExtandedFormContext = {
            ...formContext,
            getValue: (path: string) => resolvePath(path, formContext.getValues()),
        };

        const { name, rules, shouldUnregister, defaultValue, control, events, ...acstoreprops } =
            props;

        const controllerProps: UseControllerProps = {
            name,
            rules,
            shouldUnregister,
            defaultValue,
            control,
        };

        return (
            <Controller
                render={({ field: { onChange, onBlur, ref } }) => {
                    if (!acstoreprops.inputProps) {
                        acstoreprops.inputProps = {
                            variant: 'outlined',
                        };
                    }
                    acstoreprops.inputProps.onBlur = () => {
                        onBlur();
                    };
                    acstoreprops.inputProps.inputRef = ref;

                    const acEvents: AutocompleteStoreEvents<Record<string, unknown>> = {};
                    if (events) {
                        const acOnBlur: AutocompleteEventType<Record<string, unknown>> = (
                            event,
                            newValue,
                            reason,
                            details,
                        ) => {
                            if (events.onBlur) {
                                events.onBlur(
                                    event,
                                    newValue,
                                    reason,
                                    details,
                                    extandedFormContext,
                                );
                            }
                        };

                        const acOnSelectOption: AutocompleteEventType<Record<string, unknown>> = (
                            event,
                            newValue,
                            reason,
                            details,
                        ) => {
                            if (events.onSelectOption) {
                                events.onSelectOption(
                                    event,
                                    newValue,
                                    reason,
                                    details,
                                    extandedFormContext,
                                );
                            }
                        };

                        const acOnClear: AutocompleteEventType<Record<string, unknown>> = (
                            event,
                            newValue,
                            reason,
                            details,
                        ) => {
                            if (events.onClear) {
                                events.onClear(
                                    event,
                                    newValue,
                                    reason,
                                    details,
                                    extandedFormContext,
                                );
                            }
                        };

                        acEvents.onBlur = acOnBlur;
                        acEvents.onSelectOption = acOnSelectOption;
                        acEvents.onClear = acOnClear;
                    }

                    return (
                        <AutocompleteStore
                            {...acstoreprops}
                            onChange={(e, data) => {
                                onChange(data);
                            }}
                            required={rules?.required ? true : undefined}
                            events={acEvents}
                        />
                    );
                }}
                {...controllerProps}
            />
        );
    };
