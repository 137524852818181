import { useState, useEffect } from 'react';

interface UseLoadingStatusProps {
    checkReady: () => boolean | Promise<boolean>;
}

const useLoadingStatus = ({ checkReady }: UseLoadingStatusProps) => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        let isMounted = true;

        const check = async () => {
            try {
                const result = await checkReady();
                if (isMounted) {
                    setLoading(!result);
                }
            } catch (error) {
                console.error('Error in checkReady:', error);
                if (isMounted) {
                    setLoading(true);
                }
            }
        };

        check();

        return () => {
            isMounted = false;
        };
    }, [checkReady]);

    return { loading };
};

export default useLoadingStatus;
