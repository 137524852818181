import * as React from 'react';

import { InformationCard, InformationCardProps } from '@europrocurement/flexy-components';
import { GroupementGroupementRead } from '@europrocurement/l2d-domain/openApi/ApiOffre';
import { Box } from '@mui/system';
import { FaOptionIcon } from '@europrocurement/l2d-icons';
import { IconName } from '@fortawesome/pro-duotone-svg-icons';
import { JsonLdData } from '../forms/types';
import { EntityPreview } from '../components/types';

type EntityType = JsonLdData<JsonLdData<GroupementGroupementRead>>;

const PreviewCard: React.FC<EntityPreview<EntityType>> = function (props) {
    const { entity, onEdit } = props;

    const { iconeName } = entity;

    const cardTitle = entity.libelle;
    const cardItems: InformationCardProps['items'] = [
        {
            label: 'Domaine',
            value: entity.domaine?.libelle ?? null,
        },
    ];

    return (
        <>
            {iconeName ? (
                <Box
                    sx={{
                        fontSize: '40px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: '15px',
                    }}
                >
                    <FaOptionIcon
                        icon={['fad', iconeName as IconName]}
                        withShape
                        shapeSize={60}
                    />
                </Box>
            ) : null}
            <InformationCard
                title={cardTitle}
                items={cardItems}
                button={{
                    label: 'Éditer',
                    onClick: onEdit,
                }}
            />
        </>
    );
};

export default PreviewCard;
