// eslint-disable-next-line import/no-cycle
import { RootStateType } from '../../RootStore';

export const formalitiesSelector = (s: RootStateType) => s.formalites.formalities.main;

export const categoriesFormalitySelector = (s: RootStateType) =>
    s.formalites.categories_formalites.main;

export const DossierFormaliteSelector = (s: RootStateType) => s.formalites.dosform.main;

export const legalStatusSelector = (s: RootStateType) => s.formalites.legalStatuses.main;
export const formalitiesDocumentSelector = (s: RootStateType) =>
    s.formalites.formaliteDocuments.main;
