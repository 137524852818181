import { checkConsole, contactSupport } from '@b2d/utils/wording';

import type {
    DetailsWrite,
    LigneProduit,
    LigneWrite,
} from '@b2d/pages/Achats/components/forms/types';
import { Dossier, DossierFormalite } from '@europrocurement/l2d-domain';

export type SetApiProductLinePublicationFieldsProps = {
    folioPublication: Dossier;
};

export type SetApiProductLineFormalityFieldsProps = {
    folioFormality: DossierFormalite;
};

/**
 * Define fields of api product line as publication
 *
 * @param formInvoicePurchase
 * @returns
 */
export const setApiProductLinePublicationFields = function ({
    folioPublication,
}: SetApiProductLinePublicationFieldsProps): Partial<LigneWrite> {
    const ligneData: Partial<LigneWrite> = {
        idDossier: null,
        idDossierFpro: null,
        numeroDossier: null,
        idPrestation: null,
        numeroPrestation: null,
        libelleAnnonceur: null,
    };

    if (folioPublication.id) {
        ligneData.idDossier = folioPublication.id;
    }

    if (folioPublication.numero) {
        ligneData.numeroDossier = String(folioPublication.numero);
    }

    if (folioPublication.prestations && folioPublication.prestations.length > 0) {
        ligneData.idPrestation = folioPublication?.prestations[0]?.id;
        ligneData.numeroPrestation = folioPublication?.prestations[0]?.numero;
    }

    if (folioPublication.annonceur) {
        ligneData.libelleAnnonceur = folioPublication.annonceur;
    }

    return ligneData;
};

/**
 * Define fields of api product line as formality
 *
 * @param formInvoicePurchase
 * @returns
 */
export const setApiProductLineFormalityFields = function ({
    folioFormality,
}: SetApiProductLineFormalityFieldsProps): Partial<LigneWrite> {
    const ligneData: Partial<LigneWrite> = {
        idDossier: null,
        idDossierFpro: null,
        numeroDossier: null,
        idPrestation: null,
        numeroPrestation: null,
        libelleAnnonceur: null,
    };

    if (folioFormality.id) {
        ligneData.idDossierFpro = folioFormality.id;
    }

    if (folioFormality.numero) {
        ligneData.numeroDossier = String(folioFormality.numero);
    }

    if (folioFormality.societeDenomination) {
        ligneData.libelleAnnonceur = folioFormality.societeDenomination;
    }

    return ligneData;
};

/**
 * Define details fields of api product line
 *
 * RG-1 - VAT Rate Multiplication
 *
 * @param formProductLine
 * @returns
 */
export const setApiProductDetails = function (
    formProductLine: Partial<LigneProduit>,
): DetailsWrite {
    return {
        idRubriqueFacturation: formProductLine.rubriqueFacturation?.id || 0,
        ht: formProductLine.ht ? `${formProductLine.ht}` : '0',
        tauxTva: formProductLine.txtva ? Number(formProductLine.txtva.value) * 100 : 2000,
        tva: formProductLine.tva ? `${formProductLine.tva}` : '0',
    };
};

export type FormProductLineToApiProductLineProps = {
    formProductLine: Partial<LigneProduit>;
    invoiceIri?: string;
    folioPublication?: Dossier;
    folioFormality?: DossierFormalite;
};

/**
 * Convert one product line from form to api format.
 *
 * @param formInvoicePurchase
 * @param formProductLine
 * @param invoiceIri - Needed only in case the line must be associated to existing invoice.
 * @returns
 */
export default ({
    formProductLine,
    invoiceIri,
    folioPublication,
    folioFormality,
}: FormProductLineToApiProductLineProps): LigneWrite => {
    let apiProductLine: LigneWrite = {
        idDossier: folioPublication?.id,
        details: [setApiProductDetails(formProductLine)],
        facture: invoiceIri,
    };

    if (folioPublication && !folioFormality) {
        apiProductLine = {
            ...apiProductLine,
            ...setApiProductLinePublicationFields({ folioPublication }),
        };
    } else if (folioFormality && !folioPublication) {
        apiProductLine = {
            ...apiProductLine,
            ...setApiProductLineFormalityFields({ folioFormality }),
        };
    } else {
        console.error(
            'Dossier de publication : ',
            folioPublication,
            'Dossier de formalité : ',
            folioFormality,
        );
        throw new Error(
            `La facture est associée à deux ou aucun des types de dossier possibles. ${contactSupport} ${checkConsole}`,
        );
    }

    if (formProductLine.idLigneVente) {
        apiProductLine.idFLigneVente = formProductLine.idLigneVente;
    }

    return apiProductLine;
};
