import useLazyDispatch from '@b2d/hooks/useLazyDispatch';
import usePrevious from '@b2d/hooks/usePrevious';
import { filterOptionsProps } from '@b2d/pages/Achats/components/forms/types';
import { DataSource } from '@europrocurement/l2d-redux-utils';
import { useCallback, useMemo } from 'react';

type UseClearProps<T> = {
    dataSource: DataSource<T>;
    filterOptions: filterOptionsProps;
};

/**
 * useClear
 *
 * Provides functionality to clear data and associated criteria for a given data source within the application.
 * This hook is designed to reset the state of data sources effectively, ensuring that no stale or irrelevant data
 * persists after a view or component using the data source has been unmounted or needs to be reinitialized.
 *
 * @param {UseClearProps<T>} {
 *     dataSource,       // Current state of the data source to be cleared
 *     filterOptions     // Options necessary to configure dispatch actions correctly
 * }
 * @returns {object} {
 *     clear            // Function to execute the clearing of the data source and its criteria
 * }
 */
const useClear = <T>({ dataSource, filterOptions }: UseClearProps<T>) => {
    const lazyDispatch = useLazyDispatch(filterOptions);

    const prevDataSource = usePrevious(dataSource);

    const isDataSourceHasChanged: boolean = useMemo(
        () => JSON.stringify(prevDataSource) !== JSON.stringify(dataSource),
        [dataSource, prevDataSource],
    );

    const clearData = useCallback(() => {
        lazyDispatch({ target: 'Data', action: 'clear' })();
    }, [lazyDispatch]);

    /**
     * Clear all criteria.
     *
     * Where a criteria is one of :
     * - A search
     * - A filter
     * - An order.
     */
    const clearAllCriteria = useCallback(() => {
        lazyDispatch({ target: 'Order', action: 'reset' })();
        lazyDispatch({ target: 'Search', action: 'reset' })();
        lazyDispatch({ target: 'Filter', action: 'reset' })();
    }, [lazyDispatch]);

    /**
     * Clear both data and all criteria.
     */
    const clear = useCallback(() => {
        if (isDataSourceHasChanged) return;

        clearAllCriteria();
        clearData();
    }, [clearAllCriteria, clearData, isDataSourceHasChanged]);

    return { clear };
};

export default useClear;
