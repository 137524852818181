import React from 'react';
import { Button, Grid } from '@mui/material';
import { Formalite } from '@europrocurement/l2d-domain/reducers/formalites/slices/formalite';
import { Modalizer, FlexyHeaderForm } from '@europrocurement/flexy-components';
import { FlexyForm, FormStructure } from '@europrocurement/flexy-form';
import { FieldValues, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
    categoriesFormalitySelector,
    selectFormality,
} from '@b2d/redux/subReducers/FormalityReducer';
import { Box } from '@mui/system';
import { IconLookup, IconName } from '@fortawesome/pro-duotone-svg-icons';
import { findIconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FormaliteFormaliteReadCategorie } from '@europrocurement/l2d-domain/openApi/ApiFormalite';
import { AnyAction } from '@reduxjs/toolkit';
import { FaOptionIcon } from '@europrocurement/l2d-icons';
import { FormulaireFormalite } from '@europrocurement/l2d-domain';
import { AppDispatch } from '@b2d/redux/RootStore';
import useFormalityFormHandler from '../../hooks/useFormalityFormHandler';
import useFormulaire from '../../hooks/useFormulaire';

type FormalityEditModalProps = {
    formality: Formalite;
    isOpen: boolean;
    onClose: () => void;
};

type FormData = {
    categorie: FormaliteFormaliteReadCategorie;
    informationsSupplementaires?: string | null;
    libelle?: string | null;
};

const FormalityEditModal: React.FunctionComponent<FormalityEditModalProps> = function (props) {
    const { formality, isOpen, onClose } = props;
    const { handleEditForm, requestState } = useFormalityFormHandler();
    const categoriesDatasource = useSelector(categoriesFormalitySelector);
    const { listFormulaires } = useFormulaire();
    const formulairesOptions = listFormulaires();
    const dispatch = useDispatch<AppDispatch>();
    const onSubmit = (formData: FormData) => {
        handleEditForm(formality, formData).then(() => {
            dispatch(selectFormality({ id: formality.id as number }) as unknown as AnyAction);
            onClose();
        });
    };

    const formStructure: FormStructure[] = [
        {
            type: 'text',
            name: 'libelle',
            inputlabel: 'Libellé',
            rules: {
                required: 'Vous devez renseigner un libellé',
            },
            placeholder: 'Le libellé de ma formalité',
        },
        {
            type: 'text',
            name: 'informationsSupplementaires',
            inputlabel: 'Informations supplémentaires',
            placeholder: 'Informations supplémentaires',
        },
        {
            type: 'select',
            name: 'categorie',
            inputlabel: 'Catégorie',
            getOptionLabel: ({ libelle }: { libelle: string }) => libelle,
            getOptionValue: ({ id }: { id: number }) => id.toString(),
            options: categoriesDatasource.data,
            placeholder: 'Veuillez sélectionner une catégorie',
            rules: {
                required: 'Vous devez sélectionner une catégorie',
            },
        },
        {
            type: 'text',
            name: 'iconeName',
            inputlabel: 'Icône (https://fontawesome.com/search)',
            placeholder: 'Icône',
            rules: {
                validate: {
                    isAnIcon: (value) => {
                        if (!value || value === '') {
                            return true;
                        }
                        const icon = findIconDefinition({ iconName: value } as IconLookup);
                        if (icon) {
                            return true;
                        }
                        return "L'icône est invalide";
                    },
                },
            },
            xs: 12,
            sm: 12,
            md: 9,
            lg: 9,
        },
        {
            type: 'customItem',
            name: 'renderIcone',
            xs: 12,
            sm: 12,
            md: 3,
            lg: 3,
            renderField: (formContext) => {
                let iconeRender: string | null = formContext.getValues('iconeName');
                if (!iconeRender || !findIconDefinition({ iconName: iconeRender } as IconLookup)) {
                    iconeRender = 'image-slash';
                }
                console.log(iconeRender);
                return (
                    <Box
                        sx={{
                            fontSize: '40px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: '15px',
                        }}
                    >
                        <FaOptionIcon icon={['fad', iconeRender as IconName]} />
                    </Box>
                );
            },
        },
        {
            type: 'select',
            name: 'formulaire',
            inputlabel: 'Formulaire',
            options: formulairesOptions,
            getOptionLabel: ({ libelle }: { libelle: string }) => libelle,
            getOptionValue: ({ id }: { id: string }) => id.toString(),
            placeholder: 'Veuillez sélectionner un formulaire',
        },
    ];

    const formContext = useForm<FieldValues>({
        mode: 'onTouched',
        defaultValues: {
            libelle: formality?.libelle,
            informationsSupplementaires: formality?.informationsSupplementaires,
            iconeName: formality?.iconeName,
            categorie: categoriesDatasource.data.find(
                (categorie: FormaliteFormaliteReadCategorie) =>
                    categorie.id === formality?.categorie?.id,
            ),
            formulaire: formulairesOptions.find(
                (formulaire: FormulaireFormalite) => formulaire.id === formality?.formulaire,
            ),
        },
    });

    const formProps = {
        formContext,
        formObject: {},
        onSubmit,
        submitButton: {
            displayed: false,
        },
    };

    formContext.watch(['iconeName']);

    const submitBtn = (
        <Button
            key="formality-edit-submit_button"
            color="primary"
            variant="contained"
            onClick={formContext.handleSubmit(onSubmit)}
            style={{ marginTop: '8px', marginBottom: '8px' }}
            disabled={requestState.isProcessing}
        >
            Enregistrer
        </Button>
    );
    const cancelBtn = (
        <Button
            key="formality-edit-cancel_button"
            color="primary"
            variant="outlined"
            onClick={onClose}
            style={{ marginTop: '8px', marginBottom: '8px' }}
            disabled={requestState.isProcessing}
        >
            Annuler
        </Button>
    );

    return (
        <Modalizer
            open={isOpen}
            onClose={onClose}
            maxWidth="md"
            fullWidth
            modalActions={[cancelBtn, submitBtn]}
        >
            <Grid>
                <FlexyHeaderForm
                    outlined
                    label="Modifier une formalité"
                />
                <FlexyForm
                    formStructure={formStructure}
                    {...formProps}
                />
            </Grid>
        </Modalizer>
    );
};

export default FormalityEditModal;
