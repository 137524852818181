import React, { FunctionComponent, useState } from 'react';

import achatsViewPathResolver from '@b2d/pages/Achats/achatsViewPathResolver';
import { mediaObjectApi } from '@b2d/redux/RootStore';
import { DataCard } from '@europrocurement/flexy-components';
import { FaOptionIcon } from '@europrocurement/l2d-icons';
import { faFileInvoice } from '@fortawesome/pro-duotone-svg-icons';
import { Button, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import { faUser } from '@fortawesome/pro-solid-svg-icons';

// interne

const FacturesInternesNonAttribuees: FunctionComponent = function () {
    const navigate = useNavigate();

    const [countOfUnassignedUnregisteredInvoices, setCountOfUnassignedUnregisteredInvoices] =
        useState<string>('...');

    mediaObjectApi
        .listMediaObjectMediaObjectCollection({
            page: 1,
            itemsPerPage: 0,
            createdBy: 'service-account-api-formalite',
            forceInterne: true,
            dateDebutTraitementBefore: new Date(new Date().getTime() - 2 * 60 * 1000).toISOString(),
            available: true,
        })
        .then((res) => {
            if (
                res.data['hydra:totalItems'] !== null ||
                res.data['hydra:totalItems'] !== undefined
            ) {
                setCountOfUnassignedUnregisteredInvoices(`${res.data['hydra:totalItems']}`);
            }
        });

    return (
        <Grid
            sx={{
                flex: 1,
            }}
            item
            lg={1}
            md={1}
            xs={12}
        >
            <DataCard
                title="Factures internes non attribuées"
                color="purple"
                iconBgColor="transparent"
                iconColor="transparent"
                icon={
                    <FaOptionIcon
                        icon={faFileInvoice}
                        option={faUser}
                        size="2x"
                        color="purple.main"
                        optionColor="purple.dark"
                    />
                }
                tools={
                    <Button
                        color="purple"
                        variant="outlined"
                        onClick={() => {
                            navigate(
                                `${achatsViewPathResolver(
                                    'listmediaobjectinternally',
                                )}?tab=Factures+non+attribuées`,
                            );
                        }}
                    >
                        Traiter
                    </Button>
                }
            >
                <Typography
                    sx={{
                        fontSize: '18px',
                        textAlign: 'center',
                    }}
                >
                    Factures int. non attribuées :
                </Typography>
                <Typography
                    color="purple.main"
                    component="h2"
                    sx={{
                        fontSize: '32px',
                        textAlign: 'center',
                    }}
                >
                    <b>{countOfUnassignedUnregisteredInvoices}</b>
                </Typography>
            </DataCard>
        </Grid>
    );
};

export default FacturesInternesNonAttribuees;
