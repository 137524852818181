type ObjectMapFn<T, U> = (value: T, key: string, index: number) => U;

/**
 * Applies a mapping function to each value in an object and returns a new object with the same keys and transformed values.
 *
 * @template T - The type of the values in the input object.
 * @template U - The type of the values in the output object.
 * @param {Record<string, T>} obj - The input object whose values are to be transformed.
 * @param {ObjectMapFn<T, U>} fn - The mapping function that transforms each value. It receives the value, key, and index as arguments.
 * @returns {Record<string, U>} A new object with the same keys as the input object but with transformed values.
 *
 * @example
 * // Example usage to double the values in an object
 * const prices = { apple: 1, banana: 0.5, cherry: 0.75 };
 * const updatedPrices = objectMap(prices, (value) => value * 2);
 * console.log(updatedPrices); // { apple: 2, banana: 1, cherry: 1.5 }
 *
 * @example
 * // Example usage to add additional information to each value
 * const users = { user1: 'Alice', user2: 'Bob' };
 * const usersWithIds = objectMap(users, (value, key, index) => ({ id: key, name: value, index }));
 * console.log(usersWithIds); // { user1: { id: 'user1', name: 'Alice', index: 0 }, user2: { id: 'user2', name: 'Bob', index: 1 } }
 */
function objectMap<T, U>(obj: Record<string, T>, fn: ObjectMapFn<T, U>): Record<string, U> {
    return Object.fromEntries(Object.entries(obj).map(([k, v], i) => [k, fn(v, k, i)]));
}

export default objectMap;
