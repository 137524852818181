import React, { ReactNode, useCallback, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Button } from '@mui/material';

import { MediaObject, MediaObjectNote } from '@europrocurement/l2d-domain';
import {
    MediaObjectNoteApiCreateMediaObjectNoteMediaObjectNoteCollectionRequest,
    MediaObjectNoteApiDeleteMediaObjectNoteMediaObjectNoteItemRequest,
    MediaObjectNoteApiUpdateMediaObjectNoteMediaObjectNoteItemRequest,
    MediaObjectNoteJsonldMediaObjectNoteCreate,
    MediaObjectNoteJsonldMediaObjectNoteWrite,
} from '@europrocurement/l2d-domain/openApi/ApiAchats';
import { customizerSelector, mediaObjectApi, mediaObjectNoteApi } from '@b2d/redux/RootStore';

import useToaster from '@b2d/hooks/useToaster';

import {
    addNoteMessages,
    editNoteMessages,
    removeNoteMessages,
} from '@b2d/pages/Achats/constants/wording/toasts';
import ListNotesObject from '@b2d/pages/Achats/components/notes/ListNotesObject';

export type NotesMediaObjectType = {
    title?: ReactNode;
};

export const NotesMediaObject = function ({ title = 'Notes générales' }: NotesMediaObjectType) {
    const { mediaobjectid } = useParams();
    const { xIdSociete } = useSelector(customizerSelector);
    const { handleApiResponse } = useToaster();
    const [addNoteDisabled, setAddNoteDisabled] = useState<boolean>(false);

    const [mediaObjectSelected, setMediaObjectSelected] = useState<MediaObject | null>(null);

    const refreshMediaObject = useCallback(() => {
        if (mediaobjectid) {
            mediaObjectApi.getMediaObjectItem({ id: mediaobjectid }).then((res) => {
                setMediaObjectSelected(res.data as MediaObject);
            });
        }
    }, [mediaobjectid]);

    useEffect(() => {
        if (!mediaObjectSelected) {
            refreshMediaObject();
        }
    }, [mediaObjectSelected, refreshMediaObject]);

    const selectNote = useCallback(() => {
        if (!mediaObjectSelected?.notes) {
            return [];
        }

        return mediaObjectSelected.notes;
    }, [mediaObjectSelected]);

    const handleEditItem = async (note: MediaObjectNote) => {
        const noteToSend = { ...note };
        if (noteToSend.note === undefined) {
            noteToSend.note = '';
        }

        const requestParameters: MediaObjectNoteApiUpdateMediaObjectNoteMediaObjectNoteItemRequest =
            {
                xIdSociete,
                id: `${noteToSend.id}`,
                mediaObjectNoteJsonldMediaObjectNoteWrite:
                    note as MediaObjectNoteJsonldMediaObjectNoteWrite,
            };

        await handleApiResponse(
            mediaObjectNoteApi.updateMediaObjectNoteMediaObjectNoteItem(requestParameters),
            undefined,
            editNoteMessages,
        );

        refreshMediaObject();
    };

    const handleRemoveItem = async (note: MediaObjectNote) => {
        const requestParameters: MediaObjectNoteApiDeleteMediaObjectNoteMediaObjectNoteItemRequest =
            {
                xIdSociete,
                id: `${note.id}`,
            };

        await handleApiResponse(
            mediaObjectNoteApi.deleteMediaObjectNoteMediaObjectNoteItem(requestParameters),
            undefined,
            removeNoteMessages,
        );
        refreshMediaObject();
    };

    const handleAddItem = async () => {
        if (!mediaObjectSelected) {
            throw new Error('No media object');
        }

        const noteToCreate: MediaObjectNoteJsonldMediaObjectNoteCreate = {
            note: ' ',
            mediaObject: mediaObjectSelected['@id'],
        };

        const requestParameters: MediaObjectNoteApiCreateMediaObjectNoteMediaObjectNoteCollectionRequest =
            {
                xIdSociete,
                mediaObjectNoteJsonldMediaObjectNoteCreate: noteToCreate,
            };

        await handleApiResponse(
            mediaObjectNoteApi.createMediaObjectNoteMediaObjectNoteCollection(requestParameters),
            undefined,
            addNoteMessages,
        );
        refreshMediaObject();
    };

    /**
     * Verrouille ou déverrouille l'ajout de note si une note est en édition.
     * @param editionMode
     */
    const handleAddNoteButtonState = (editionMode: boolean) => {
        setAddNoteDisabled(editionMode);
    };

    /**
     * Force à déverrouiller l'ajout de note s'il n'y a pas de notes
     */
    useEffect(() => {
        if (!addNoteDisabled || !mediaObjectSelected) return;
        if (mediaObjectSelected.notes && mediaObjectSelected.notes.length === 0) {
            setAddNoteDisabled(false);
        }
    }, [addNoteDisabled, mediaObjectSelected]);

    return (
        <>
            <ListNotesObject
                title={title}
                notes={selectNote()}
                editItem={handleEditItem}
                removeItem={handleRemoveItem}
                onEditModeChange={handleAddNoteButtonState}
            />
            <Button
                onClick={handleAddItem}
                disabled={addNoteDisabled}
                variant="contained"
                style={{ marginTop: '16px', marginBottom: '16px' }}
            >
                Ajouter une note
            </Button>
        </>
    );
};

export default NotesMediaObject;
