import { CategorieCategorieRead } from '@europrocurement/l2d-domain/openApi/ApiFormalite';
import { FormaliteFormaliteRead } from '@europrocurement/l2d-domain/openApi/ApiOffre';
import { CircularProgress, Tab, Tabs } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { Box } from '@mui/system';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { CategoriesFormaliteApiObject } from '@europrocurement/l2d-domain/reducers/formalites/slices/categoriesFormalite';
import { categoriesFormalitySelector } from '@b2d/redux/subReducers/FormalityReducer';
import formalityModel from '@b2d/pages/Formalities/models/formality';
import FormalityFilterTab from './FormalityFilterTab';
import useFetchCollection from '../../Package/Form/useFetchCollection';

type FormalityFiltersProps = {
    filtersSetterCallback: (ids: number[]) => void;
};

const ALL_FORMALITIES_INDEX = 0;

const FormalityFilters: React.FC<FormalityFiltersProps> = function ({ filtersSetterCallback }) {
    const [activeTabIndex, setActiveTabIndex] = React.useState(0);
    const [formalityIds, setFormalityIds] = React.useState<number[]>([]); // Filter content

    const fetchedCategoriesFormalityOptions = useSelector(categoriesFormalitySelector)
        ?.data as CategorieCategorieRead[];

    const formalitiesPromise = () =>
        formalityModel.list({
            page: 1,
            itemsPerPage: 1000,
            deleted: false,
        });

    const {
        fetch: fetchFormalities,
        result: formalitiesResult,
        isLoaded,
        isFetching,
    } = useFetchCollection<FormaliteFormaliteRead[]>(formalitiesPromise);

    const getIds = (formalities: FormaliteFormaliteRead[]) => {
        const ids = formalities.map(({ id }) => id);
        // Todo remove this useless sanitize step by correctly typing formality ids on API SIDE / regenerate API.ts formalité
        const sanitizedIds = ids.filter((id) => typeof id !== 'undefined');
        return sanitizedIds;
    };
    const allIds = getIds(formalitiesResult ?? []);
    const allFormalitiesAreSelected = allIds.every((id) => formalityIds.includes(id));

    // OnMount
    React.useEffect(() => {
        fetchFormalities();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Listen fetched formalities event
    React.useEffect(() => {
        if (isLoaded && !isFetching) {
            setFormalityIds(allIds);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoaded, isFetching]);

    // On filter update / update parent
    React.useEffect(() => {
        filtersSetterCallback(formalityIds);
    }, [filtersSetterCallback, formalityIds]);

    const getFormalitiesByCategoryId = (id?: number) => {
        if (!id) {
            return [];
        }
        return formalitiesResult.filter(
            (formalite) => (formalite as { categorie: { id: number } }).categorie.id === id, // Todo correct / generate -> API Formalité types
        );
    };

    const getCategoryIds = (categoryId?: CategoriesFormaliteApiObject['id']) => {
        if (!categoryId) {
            return [];
        }

        const formalities = getFormalitiesByCategoryId(categoryId);
        return getIds(formalities);
    };

    const isWholeCategorySelected = (categoryId?: CategoriesFormaliteApiObject['id']) => {
        if (!categoryId) {
            return false;
        }
        const categoryIds = getCategoryIds(categoryId);
        return categoryIds.every((id) => formalityIds.includes(id));
    };

    const toggleAllCategories = () => {
        if (allFormalitiesAreSelected) {
            setFormalityIds([]);
        } else {
            const sanitizedIds = allIds.filter((id) => typeof id !== 'undefined');
            setFormalityIds(sanitizedIds);
        }
    };

    const getCategorySelectionStatus = (categoryId?: CategoriesFormaliteApiObject['id']) => {
        const categoryIds = getCategoryIds(categoryId);
        const every = categoryIds.every((id) => formalityIds.includes(id));
        const some = categoryIds.some((id) => formalityIds.includes(id));
        if (every) {
            return 'every';
        }
        if (some) {
            return 'some';
        }
        return 'none';
    };

    const toggleCategory = (categoryId?: CategoriesFormaliteApiObject['id']) => {
        if (!categoryId) {
            return;
        }
        const categoryIds = getCategoryIds(categoryId);
        const selectedIdsExceptCategory = formalityIds.filter((id) => !categoryIds.includes(id));

        if (isWholeCategorySelected(categoryId)) {
            setFormalityIds(selectedIdsExceptCategory);
        } else {
            setFormalityIds([
                ...selectedIdsExceptCategory,
                ...categoryIds, // whole category
            ]);
        }
    };

    const toggleFormality = (formalityId: number) => {
        if (formalityIds.includes(formalityId)) {
            const updatedIds = formalityIds.filter((id) => id !== formalityId);
            setFormalityIds(updatedIds);
        } else {
            setFormalityIds([...formalityIds, formalityId]);
        }
    };

    const onTabChange = (event: React.SyntheticEvent, newTabIndex: number) => {
        setActiveTabIndex(newTabIndex);
    };

    const getCheckedColor = (categoryId?: CategoriesFormaliteApiObject['id']) => {
        const selectionStatus = getCategorySelectionStatus(categoryId);
        if (selectionStatus === 'some') {
            return 'lightgray';
        }
        return 'default';
    };

    if (!fetchedCategoriesFormalityOptions || !formalitiesResult) {
        return <CircularProgress />;
    }

    return (
        <Box>
            <Box
                display="flex"
                justifyContent="space-between"
            >
                <Tabs
                    value={activeTabIndex}
                    onChange={onTabChange}
                    aria-label="basic tabs example"
                >
                    <Tab
                        label={
                            <Box display="flex">
                                <Checkbox
                                    checked={allFormalitiesAreSelected}
                                    onChange={() => toggleAllCategories()}
                                />
                                <p>Toutes les formalités</p>
                            </Box>
                        }
                    />
                    {fetchedCategoriesFormalityOptions.map(({ id, libelle }) => (
                        <Tab
                            label={
                                <Box display="flex">
                                    <Checkbox
                                        checked={getCategorySelectionStatus(id) !== 'none'}
                                        onChange={() => toggleCategory(id)}
                                        sx={{
                                            color: 'gray',
                                            '&.Mui-checked': {
                                                color: getCheckedColor(id),
                                            },
                                        }}
                                    />
                                    <p>{libelle ?? ''}</p>
                                </Box>
                            }
                        />
                    ))}
                </Tabs>
            </Box>

            <FormalityFilterTab
                activeIndex={activeTabIndex}
                index={ALL_FORMALITIES_INDEX}
                formalities={formalitiesResult}
                toggleId={toggleFormality}
                formalityIds={formalityIds}
            />
            {fetchedCategoriesFormalityOptions.map(({ id }, index) => (
                <FormalityFilterTab
                    activeIndex={activeTabIndex}
                    index={index + 1}
                    formalities={getFormalitiesByCategoryId(id)}
                    toggleId={toggleFormality}
                    formalityIds={formalityIds}
                />
            ))}
        </Box>
    );
};

export default FormalityFilters;
