import React, { FunctionComponent } from 'react';

import { Box } from '@mui/material';
import { SkeletonArray, SkeletonQuickSearch } from '@europrocurement/flexy-components';

const InvoiceListSkeleton: FunctionComponent = function () {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 5,
                height: '95%',
                width: '95%',
            }}
        >
            <SkeletonQuickSearch />
            <SkeletonArray
                columns={4}
                rows={8}
            />
        </Box>
    );
};

export default InvoiceListSkeleton;
