/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useMemo, useState } from 'react';
import { AxiosPromise, AxiosResponse } from 'axios';
import { useSnackbar } from 'notistack';
import useHandleError from './useHandleError'; // Import the error handling hook

type DefaultParameters = {
    withToaster: boolean;
};

export type AdditionalParameters = {
    withToaster?: boolean;
    successCallback?: (result: AxiosResponse<any, any>) => unknown;
    failureCallback?: (error: unknown) => unknown;
    callback?: (...args: unknown[]) => unknown;
};

type RequestState = {
    error: boolean;
    success: boolean;
    isProcessing: boolean;
};

type Parameters = DefaultParameters & AdditionalParameters;

const useApiRequest = () => {
    const initialState = {
        error: false,
        success: false,
        response: null,
        isProcessing: false,
    };

    const [requestState, _setRequestState] = useState<RequestState>(initialState);

    const { enqueueSnackbar } = useSnackbar();
    const { handleFailure } = useHandleError();

    const setRequestState = useCallback(
        (key: string, value: unknown) =>
            _setRequestState((prevState) => ({
                ...prevState,
                [key]: value,
            })),
        [],
    );

    const defaultParameters = useMemo(
        () => ({
            withToaster: true,
        }),
        [],
    );

    const DEFAULT_SUCCESS_MESSAGE = 'effectuée avec succès';

    const successStatuses = useMemo(() => [200, 201, 204], []);

    const getDefaultSuccessMessage = (statusCode: number) => {
        let prefix = '';
        switch (statusCode) {
            case 200:
                prefix = 'Mise à jour';
                break;
            case 201:
                prefix = 'Création';
                break;
            case 204:
                prefix = 'Suppression';
                break;
            default:
                prefix = '(Non défini)';
        }

        return `${prefix} ${DEFAULT_SUCCESS_MESSAGE}`;
    };

    const handleSuccess = useCallback(
        (result: AxiosResponse<any, any>, parameters: Parameters) => {
            const { withToaster, successCallback } = parameters;

            setRequestState('success', true);

            if (successCallback) {
                successCallback(result);
            }

            if (withToaster) {
                enqueueSnackbar(getDefaultSuccessMessage(result.status));
            }
        },
        [enqueueSnackbar, setRequestState],
    );

    const request = useCallback(
        async (req: AxiosPromise, additionalParameters: AdditionalParameters) => {
            const parameters = { ...defaultParameters, ...additionalParameters };

            setRequestState('isProcessing', true);

            const response = await req
                .then((result) => {
                    if (successStatuses.includes(result.status)) {
                        handleSuccess(result, parameters);
                    } else {
                        console.error('CODE RESPONSE NOT SUPPORTED');
                    }
                })
                .catch((error) => {
                    handleFailure({
                        error,
                        withToaster: parameters.withToaster,
                        failureCallback: parameters.failureCallback,
                    });
                });

            const { callback } = parameters;

            if (callback) {
                callback();
            }

            setRequestState('isProcessing', false);

            return response;
        },
        [defaultParameters, handleFailure, handleSuccess, setRequestState, successStatuses],
    );

    return { request, requestState };
};

export default useApiRequest;
