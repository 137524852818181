import React, { ReactNode, useCallback, useContext, useMemo } from 'react';

import { useSelector } from 'react-redux';
import {
    Box,
    Divider,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';

import { FactureAchatLigneJsonldFactureAchatRead } from '@europrocurement/l2d-domain/openApi/ApiAchats';
import { FlexyAccordeon, FlexyIconButton, ModalContext } from '@europrocurement/flexy-components';

import { FactureAchat, MediaObject, RubriqueFacturation } from '@europrocurement/l2d-domain';
import { DataSource } from '@europrocurement/l2d-redux-utils';

import { addNoteIcon, editIcon } from '@europrocurement/l2d-icons';
import generateB2DPath from '@b2d/utils/generateB2DPath';
import { useNavigate } from 'react-router';
import { useToaster } from '@b2d/hooks';
import { RubriqueFacturationSelector } from '../../../../redux/RootStore';
import { findRubFactById } from '../forms/functions/produitFunctions';
import { RSFRubFac } from '../forms/types';
import { CodeRejetsChipList, CodeRejetsChipType } from '../widgets/CodeRejetsChipList';
import { DisplayLigneDossier } from './DisplayLigneDossier';
import NoteModalForm from './NoteModalForm';
import { convertToNumberAndFormatToString } from '../forms/functions/calculsProduits';

export type DossierFproLinesAccordeonType = {
    mediaObject: MediaObject;
    facture: FactureAchat;
    displayName: string;
    lines: FactureAchatLigneJsonldFactureAchatRead[];
    rejets?: CodeRejetsChipType[];
};

export const reduceLines = function (lines: FactureAchatLigneJsonldFactureAchatRead[]) {
    return lines.reduce(
        (
            acc: FactureAchatLigneJsonldFactureAchatRead[],
            curr: FactureAchatLigneJsonldFactureAchatRead,
        ) => {
            if (
                curr.details &&
                curr.details[0] &&
                curr.details[0].idRubriqueFacturation === 99 &&
                acc.length >= 1
            ) {
                acc.splice(1, 0, curr);
            } else if (
                acc.length === 1 &&
                acc[0].details &&
                acc[0].details[0] &&
                acc[0].details[0].idRubriqueFacturation === 99
            ) {
                acc.splice(0, 0, curr);
            } else {
                acc.push(curr);
            }
            return acc;
        },
        [],
    );
};

export const DossierFproLinesAccordeon = function ({
    mediaObject,
    facture,
    displayName,
    lines,
    rejets = [],
}: DossierFproLinesAccordeonType) {
    const rubFactsDataSource: DataSource<RubriqueFacturation> = useSelector(
        RubriqueFacturationSelector,
    ).main;

    const { modalActions } = useContext(ModalContext);

    const renderRejetByDossier = function (
        iddossier?: number | null,
        rejetsToRender?: FactureAchatLigneJsonldFactureAchatRead[],
    ) {
        if (!rejetsToRender || !iddossier) return null;

        return (
            <CodeRejetsChipList
                rejets={rejetsToRender.filter((rejet) => rejet.idDossier === iddossier)}
                full
            />
        );
    };

    let accordeonText: ReactNode = '';

    if (lines.length === 1 && lines[0].details && lines[0].details.length > 0) {
        accordeonText = (
            <Typography
                variant="subtitle2"
                sx={{ fontWeight: '600' }}
            >
                Dossier {displayName} : &nbsp;
                <Typography
                    variant="caption"
                    component="span"
                >{`
        ${
            findRubFactById(rubFactsDataSource.data, lines[0].details[0]?.idRubriqueFacturation)
                ?.code
        } - HT
        ${
            lines[0].details[0] && convertToNumberAndFormatToString(lines[0].details[0].ht)
        } €`}</Typography>
            </Typography>
        );
    } else if (lines[0].details) {
        accordeonText = (
            <Typography
                variant="subtitle2"
                sx={{ fontWeight: '600' }}
            >
                Dossier {displayName} : &nbsp;
                <Typography
                    variant="caption"
                    component="span"
                >{`${lines.length} lignes`}</Typography>
            </Typography>
        );
    }

    const resetModal = useCallback(() => {
        if (!lines[0].idDossier || !facture.id) {
            console.error('missing id dossier or id facture on line');
            return;
        }
        modalActions.call(
            <NoteModalForm
                idMediaObject={Number(mediaObject.id)}
                idFacture={facture.id}
                idDossier={lines[0].idDossier}
            />,
        );
    }, [mediaObject.id, facture.id, lines, modalActions]);

    const isExistNotesOnThisFolio = useMemo(
        () =>
            facture.notes &&
            lines[0].idDossier &&
            facture.notes.filter((note) => note.idDossier === lines[0].idDossier).length > 0,
        [facture.notes, lines],
    );

    const { handleRouterResponse } = useToaster();
    const navigate = useNavigate();

    const editFolio = useCallback(() => {
        if (!lines[0].idDossierFpro) throw new Error('Pas de numéro de dossier trouvé !');

        const folioId = lines[0].idDossierFpro.toString();

        const res = generateB2DPath('formmultieditfprodossier', {
            factureAchat: facture,
            dossiernum: folioId,
        });

        handleRouterResponse(res, () => {
            if (res.status === 'OK') {
                navigate(res.path);
            }
        });
    }, [facture, handleRouterResponse, lines, navigate]);

    return (
        <>
            <FlexyAccordeon
                variante="section"
                accordionSummary={
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%',
                        }}
                    >
                        <Stack
                            direction="row"
                            alignItems="center"
                            gap={1}
                        >
                            {accordeonText}
                        </Stack>
                        <Stack
                            direction="row"
                            alignItems="center"
                            gap={1}
                        >
                            <FlexyIconButton
                                displayTooltip
                                icon={addNoteIcon}
                                onClick={(event) => {
                                    if (lines[0].idDossier) {
                                        resetModal();
                                    }
                                    event.stopPropagation();
                                }}
                                tooltipOverwriteProps={{
                                    title: 'Notes sur ce dossier',
                                }}
                                iconOverwriteProps={{
                                    color: isExistNotesOnThisFolio ? 'primary' : 'grey.400',
                                }}
                            />

                            {!facture.syncCegid ? (
                                <FlexyIconButton
                                    displayTooltip
                                    icon={editIcon}
                                    onClick={editFolio}
                                    tooltipOverwriteProps={{
                                        title: 'Modifier cette saisie',
                                    }}
                                    iconOverwriteProps={{
                                        color: 'primary',
                                    }}
                                />
                            ) : null}
                        </Stack>
                    </Box>
                }
                defaultExpanded
                accordionDetails={
                    <>
                        {renderRejetByDossier(lines[0].idDossierFpro, rejets)}
                        <TableContainer component={Paper}>
                            <Table
                                sx={{ minWidth: 650 }}
                                aria-label="lignes de la facture d'achat"
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <strong>Rubrique</strong>
                                        </TableCell>
                                        <TableCell align="right">
                                            <strong>HT (€)</strong>
                                        </TableCell>
                                        <TableCell align="right">
                                            <strong>TVA (€)</strong>
                                        </TableCell>
                                        <TableCell align="right">
                                            <strong>TTC (€)</strong>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {reduceLines(lines).map((ligne) => (
                                        <DisplayLigneDossier
                                            key={`${ligne.numeroDossier}-${ligne.id}-${ligne.libelleAnnonceur}`}
                                            ligne={ligne}
                                            rubriques={[...rubFactsDataSource.data, RSFRubFac]}
                                        />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                }
            />
            <Divider />
        </>
    );
};

export default DossierFproLinesAccordeon;
