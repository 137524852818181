import useApiRequest from '@b2d/hooks/useApiRequest';
import { customizerSelector, domainSelector } from '@b2d/redux/RootStore';
import { maximumLengthRule } from '@b2d/validation/rules/length';
import { FlexyForm, FormObject, FormStructure } from '@europrocurement/flexy-form';
import { Box } from '@mui/material';
import * as React from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { ModalContext } from '@europrocurement/flexy-components';
import {
    GroupementGroupementRead,
    GroupementJsonldGroupementCreate,
    GroupementJsonldGroupementRead,
} from '@europrocurement/l2d-domain/openApi/ApiOffre';
import { FaOptionIcon } from '@europrocurement/l2d-icons';
import { IconName } from '@fortawesome/pro-duotone-svg-icons';
import model from '../models/offerGroup';
import { formatPayload } from '../forms/format';
import { ModelForm } from '../forms/types';
import FontAwesomeDocLink from '../components/FontAwesomeDocLink';

type OfferGroupFormObject = {
    id?: number;
    libelle: string;
    domaine: number;
};

const Form: React.FC<ModelForm<GroupementGroupementRead>> = function (props) {
    const { afterSubmit, entity = {}, isCreate = false } = props;
    const { libelle: label, iconeName } = entity as GroupementJsonldGroupementRead;
    const { modalActions } = React.useContext(ModalContext);
    const initialLabel = isCreate ? '' : (label ?? '');
    const initialIcone = isCreate ? '' : (iconeName ?? '');

    const [stateLabel, setLabel] = React.useState({
        initialLabel,
        value: initialLabel,
    });
    const [stateIcone, setIcone] = React.useState({
        initialIcone,
        value: initialIcone,
    });

    const successCallback = () => {
        modalActions.reset();
        afterSubmit();
    };

    // Prefetched options
    const domainsOptions = useSelector(domainSelector).main.data;

    // FormContext
    const formContext = useForm<FieldValues, unknown, undefined>({
        defaultValues: isCreate ? {} : entity,
        mode: 'onTouched',
    });

    const { xIdSociete: companyId } = useSelector(customizerSelector);

    const { request } = useApiRequest();

    const formatOutputSchema = {
        domaine: (domaineOption: { id: string }) => domaineOption.id,
    };

    // FormStructure
    const OfferGroupFormStructure: FormStructure[] = [
        {
            isDisplayable: isCreate, // No domain edition allowed
            type: 'select',
            name: 'domaine',
            inputlabel: 'Domaine',
            options: domainsOptions,
            getOptionLabel: (option: { libelle: string }) => option.libelle,
            getOptionValue: (option: { id: number }) => option.id.toString(),
            rules: {
                required: "La sélection d'un domaine est obligatoire",
            },
            xs: 12,
            placeholder: 'Sélectionnez un domaine',
        },
        {
            type: 'text',
            name: 'libelle',
            inputlabel: 'Libellé',
            rules: {
                required: 'Le libellé est obligatoire',
                validate: {
                    ...maximumLengthRule(255),
                },
            },
            onChangeInput: (event: React.FormEvent<HTMLInputElement>) => {
                const { value } = event.currentTarget;
                setLabel({
                    ...stateLabel,
                    value,
                });
            },
            xs: 12,
            placeholder: 'Libellé',
        },
        {
            type: 'text',
            name: 'iconeName',
            inputlabel: 'Icône (https://fontawesome.com/search)',
            placeholder: 'Icône',
            xs: 12,
            sm: 12,
            md: 9,
            lg: 9,
            onChangeInput: (event: React.FormEvent<HTMLInputElement>) => {
                const { value } = event.currentTarget;
                setIcone({
                    ...stateIcone,
                    value,
                });
            },
        },
        {
            type: 'customItem',
            name: 'renderIcone',
            xs: 12,
            sm: 12,
            md: 3,
            lg: 3,
            renderField: () => {
                let iconeRender: string | null = formContext.getValues('iconeName');
                if (!iconeRender) {
                    iconeRender = 'image-slash';
                }
                return (
                    <Box
                        sx={{
                            fontSize: '40px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: '35px',
                        }}
                    >
                        <FaOptionIcon icon={['fad', iconeRender as IconName]} />
                    </Box>
                );
            },
        },
        {
            type: 'customItem',
            name: 'iconsLink',
            xs: 12,
            sm: 12,
            md: 12,
            lg: 12,
            renderField: () => (
                <Box sx={{ marginTop: '20px' }}>
                    <FontAwesomeDocLink />
                </Box>
            ),
        },
    ];

    const createOfferGroup: SubmitHandler<FormObject> = async (
        formValues: OfferGroupFormObject,
    ) => {
        const offerGroup = formatPayload(formValues, formatOutputSchema);
        const createRequest = model.create({
            groupementJsonldGroupementCreate: offerGroup as GroupementJsonldGroupementCreate,
            xIdSociete: companyId,
        });
        await request(createRequest, { successCallback });
    };

    const updateOfferGroup: SubmitHandler<FormObject> = async (
        offerGroup: OfferGroupFormObject,
    ) => {
        const { id: offerGroupId } = offerGroup;
        if (offerGroupId) {
            const updateRequest = model.update({
                id: offerGroupId.toString(),
                groupementJsonldGroupementUpdate: offerGroup,
                xIdSociete: companyId,
            });
            await request(updateRequest, { successCallback });
        }
    };

    const dataHasChanged =
        stateLabel.value !== stateLabel.initialLabel ||
        stateIcone.value !== stateIcone.initialIcone;

    return (
        <Box>
            <FlexyForm
                formContext={formContext}
                formObject={isCreate ? {} : (entity as FormObject)}
                formStructure={OfferGroupFormStructure}
                onSubmit={isCreate ? createOfferGroup : updateOfferGroup}
                isDisabled={!dataHasChanged}
            />
        </Box>
    );
};

export default Form;
