import React, { FunctionComponent, useEffect } from 'react';

import { MainDemandeurSignatureSelector, getDemandeurSignature } from '@b2d/redux/subReducers';
import { useAppDispatch, useAppSelector } from '@b2d/redux/RootStore';
import { CenterCircularProgress, LazyBreadcrumb } from '@europrocurement/flexy-components';
import { DemandeurSignature } from '@europrocurement/l2d-domain';
import { DataSource } from '@europrocurement/l2d-redux-utils';
import { Grid } from '@mui/material';
import { UseKeycloakCheckRole } from '@europrocurement/l2d-keycloak';
import { signatureListeDemandeurFullPath } from '../../constants/paths';
import { DemandeurCardProps } from '../../constants/types';

import DemandeurCard from '../../components/DemandeurCard';

const DemandeurSignatureListe: FunctionComponent = function () {
    const dataSource: DataSource<DemandeurSignature> = useAppSelector(
        MainDemandeurSignatureSelector,
    ); // MainDemandeurSignatureSelector

    const dispatch = useAppDispatch();

    const actualizeDemandeur = () => {
        dispatch(getDemandeurSignature({}));
    };

    useEffect(() => {
        dispatch(getDemandeurSignature({}));
    }, [dispatch]);

    const roleChecker = UseKeycloakCheckRole();

    return (
        <>
            <LazyBreadcrumb
                path={[
                    { path: 'Signature électronique', link: signatureListeDemandeurFullPath },
                    'Référentiels',
                ]}
            />
            {dataSource ? (
                <Grid container>
                    <Grid
                        item
                        xs={12}
                        md={6}
                    >
                        {dataSource.data
                            .filter((item, index) => index % 2 === 0)
                            .map((demandeur: DemandeurCardProps) => (
                                <DemandeurCard
                                    callbackUrl={demandeur?.callbackUrl}
                                    key={demandeur?.id}
                                    logo={demandeur?.logo}
                                    nom={demandeur?.nom}
                                    id={demandeur?.id}
                                    actif={demandeur?.actif}
                                    afterSubmit={actualizeDemandeur}
                                    userIdentifier={demandeur?.userIdentifier}
                                    editable={roleChecker('api-sign:demandeur-write')}
                                />
                            ))}
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={6}
                    >
                        {dataSource.data
                            .filter((item, index) => index % 2 === 1)
                            .map((demandeur: DemandeurCardProps) => (
                                <DemandeurCard
                                    callbackUrl={demandeur?.callbackUrl}
                                    key={demandeur?.id}
                                    logo={demandeur?.logo}
                                    nom={demandeur?.nom}
                                    id={demandeur?.id}
                                    actif={demandeur?.actif}
                                    afterSubmit={actualizeDemandeur}
                                    userIdentifier={demandeur?.userIdentifier}
                                    editable={roleChecker('api-sign:demandeur-write')}
                                />
                            ))}
                    </Grid>
                </Grid>
            ) : (
                <CenterCircularProgress sx={{ height: '500px' }} />
            )}
        </>
    );
};

export default DemandeurSignatureListe;
