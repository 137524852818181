import React, { ReactNode, useMemo, useState } from 'react';

import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, Tooltip, Typography } from '@mui/material';

export type ListActionButtonProps = {
    onClick: () => void;
    icon?: IconDefinition;
    iconComponent?: ReactNode;
    tooltip?: string;
};
/**
 * ListActionButton permet de sélectionner une valeur pré-défini dans une liste déroulante.
 */
const ListActionButton: React.FunctionComponent<ListActionButtonProps> = function ({
    onClick,
    icon,
    iconComponent,
    tooltip,
}: ListActionButtonProps) {
    const [hover, setHover] = useState(false);

    const buttonColor = useMemo(() => (hover ? 'secondaryLight' : 'secondary'), [hover]);

    return (
        <Tooltip title={tooltip}>
            <IconButton
                data-testid="list-action-button"
                onClick={onClick}
                style={{ textDecoration: 'none' }}
                onMouseOver={() => setHover(true)}
                onMouseOut={() => setHover(false)}
            >
                <Typography color={buttonColor}>
                    {icon ? <FontAwesomeIcon icon={icon} /> : null}
                    {iconComponent || null}
                </Typography>
            </IconButton>
        </Tooltip>
    );
};

export default ListActionButton;
