import Keycloak from 'keycloak-js';
import React, { useEffect, useMemo } from 'react';
import { KeycloakContext } from './KeycloakContext';

export type KeycloakProviderProps = {
    authClient: Keycloak;
    initOptions: Keycloak.KeycloakInitOptions;
    children: React.ReactNode;
};

export const KeycloakProvider: React.FunctionComponent<KeycloakProviderProps> = function ({
    authClient,
    initOptions,
    children,
}) {
    const [keycloakInstance, setKeycloakInstance] = React.useState<Keycloak>(authClient);
    const [initialized, setInitialized] = React.useState<boolean>(
        authClient.authenticated || false,
    );

    useEffect(() => {
        if (!initialized) {
            // throw new Error('KeycloakProvider: keycloak instance not initialized');
            keycloakInstance.init(initOptions).then((authenticated) => {
                setInitialized(authenticated);
            });
        }
    }, [initOptions, initialized, keycloakInstance]);

    useEffect(() => {
        setInitialized(keycloakInstance.authenticated || false);
    }, [keycloakInstance.authenticated]);

    const contextValue = useMemo(
        () => ({
            keycloakInstance,
            setKeycloakInstance,
            initialized,
            setInitialized,
        }),
        [initialized, keycloakInstance],
    );

    return <KeycloakContext.Provider value={contextValue}>{children}</KeycloakContext.Provider>;
};
