import * as React from 'react';
import { InformationCard, InformationCardProps } from '@europrocurement/flexy-components';
import { OptionsJsonldOptionRead } from '@europrocurement/l2d-domain/openApi/ApiOffre';
import { JsonLdData } from '../forms/types';
import { getHtmlFieldTypeLabel, HTMLFieldsPreviewType } from '../forms/htmlFields';
import { EntityPreview } from '../components/types';

type EntityType = JsonLdData<OptionsJsonldOptionRead>;

const PreviewCard: React.FC<EntityPreview<EntityType>> = function (props) {
    const { entity, onEdit } = props;

    const cardTitle = entity.libelle;
    const cardItems: InformationCardProps['items'] = [
        {
            label: 'ID',
            value: entity.id?.toString() ?? null,
        },
        {
            label: 'Description',
            value: entity.description ?? null,
        },
        {
            label: 'Type de champ HTML',
            value: getHtmlFieldTypeLabel(entity.htmlFieldType as HTMLFieldsPreviewType) ?? null, // Type Cast needed / Api is not accurate here
        },
        {
            label: 'Obligatoire',
            value: entity.obligatoire ? 'oui' : 'non',
        },
        {
            label: 'Obligatoire',
            value: entity.obligatoire ? 'oui' : 'non',
        },
        {
            label: 'Visible',
            value: entity.visibleFront ? 'oui' : 'non',
        },
    ];

    const optionValuesItems = () => {
        if (!entity.values) {
            return [];
        }

        return entity.values.map((optionValue) => ({
            label: optionValue.libelle ?? '',
            value: optionValue.description ?? null,
        }));
    };

    return (
        <>
            <InformationCard
                title={cardTitle}
                items={cardItems}
                button={{
                    label: 'Éditer',
                    onClick: onEdit,
                }}
            />

            <InformationCard
                title="Valeur(s) possible(s)"
                subtitle=""
                items={optionValuesItems()}
            />
        </>
    );
};

export default PreviewCard;
