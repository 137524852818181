import * as React from 'react';
import { ModalContext } from '@europrocurement/flexy-components';
import { ColumnDatatable } from '@europrocurement/flexy-datatable';
import { OffresJsonldOffreRead } from '@europrocurement/l2d-domain/openApi/ApiOffre';
import { useDispatch } from 'react-redux';
import ListingWithPreview from '@b2d/pages/Offres/components/ListingWithPreview';
import OfferForm from '@b2d/pages/Offres/Offer/Form';
import { FaOptionIcon, addItem } from '@europrocurement/l2d-icons';
import { AnyAction } from '@reduxjs/toolkit';
import { IconName } from '@fortawesome/pro-duotone-svg-icons';
import model from '../models/offer';
import ModalFormTitle from '../components/form/ModalFormTitle';
import { getStringColumn } from '../listings';

const Panel: React.FC = function () {
    const { modalActions } = React.useContext(ModalContext);

    const dispatch = useDispatch();

    const reloadList = () =>
        dispatch(model.datasourcesThunks.main.getData({}) as unknown as AnyAction);

    const listingButton = {
        label: 'Créer une nouvelle offre',
        icon: addItem,
        action: () =>
            modalActions.call(
                <>
                    <ModalFormTitle
                        icon={model.icon}
                        title="Création d'une offre"
                    />
                    <OfferForm
                        isCreate
                        afterSubmit={reloadList}
                    />
                </>,
            ),
    };

    const columns: ColumnDatatable<OffresJsonldOffreRead>[] = [
        getStringColumn('libelle', 'Libellé'),
        {
            label: 'Icône',
            displayLabel: true,
            render: ({ iconeName }) => {
                if (iconeName === null) return null;

                return <FaOptionIcon icon={['fad', iconeName as IconName]} />;
            },
            isDisplayed: true,
        },
    ];

    return (
        <ListingWithPreview
            listingTitle="Liste des offres"
            listingButton={listingButton}
            listingColumns={columns}
            form={OfferForm}
            model={model}
        />
    );
};

export default Panel;
