import * as React from 'react';
import Business from '@mui/icons-material/Business';
import PersonIcon from '@mui/icons-material/Person';
import { Link, useNavigate } from 'react-router-dom';
// import useContextActions from '@b2d/hooks/useContextActions';
import {
    ContextActionType,
    LazyBreadcrumb,
    useDumbActionsContext,
} from '@europrocurement/flexy-components';

import {
    ColumnDatatable,
    FiltersDatatableList,
    StoreDatatable,
} from '@europrocurement/flexy-datatable';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { Prescripteur } from '@europrocurement/l2d-domain';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Typography } from '@mui/material';
import {
    customizerSelector,
    getPrescripteurs,
    PrescripteursSelector,
    useAppSelector,
} from '../../../../redux/RootStore';
import { PRESCRIBER_NEW } from '../../constants/paths';

const ActionMenu = function ({ itemId }: { itemId: number | string }) {
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const contextActions: ContextActionType[] = [
        {
            name: 'Ajouter prescripteur',
            icon: <PersonAddIcon />,
            action: () => navigate(PRESCRIBER_NEW),
        },
    ];

    useDumbActionsContext(contextActions);

    return (
        <div
            onClick={(e) => {
                e.stopPropagation();
            }}
            onKeyDown={() => {}}
            role="button"
            tabIndex={0}
        >
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={handleClose}>
                    <Link
                        to={`${itemId}`}
                        target="_blank"
                        style={{ textDecoration: 'none' }}
                    >
                        <Typography sx={{ color: 'text.primary' }}>
                            Ouvrir dans un nouvel onglet
                        </Typography>
                    </Link>
                </MenuItem>
                <MenuItem onClick={handleClose}>Consulter factures</MenuItem>
                <MenuItem onClick={handleClose}>Saisir un dossier</MenuItem>
            </Menu>
        </div>
    );
};

const PrescripteursListing = function () {
    const datasource = useAppSelector(PrescripteursSelector).main;
    const { xIdSociete } = useAppSelector(customizerSelector);
    const navigate = useNavigate();

    const columns: ColumnDatatable<Prescripteur>[] = React.useMemo(
        () => [
            {
                label: 'Statut',
                displayLabel: false,
                render: ({ personnePhysique, demo }) => (
                    <div>
                        {personnePhysique ? (
                            <PersonIcon color={demo ? 'error' : 'primary'} />
                        ) : (
                            <Business color={demo ? 'error' : 'primary'} />
                        )}
                    </div>
                ),
                isDisplayed: true,
                sortable: true,
                field: 'raisonSociale',
            },
            {
                label: 'Code Client',
                render: ({ relationsSocietes }) =>
                    relationsSocietes.find((item) => item.societe?.id === xIdSociete)?.codeClient ||
                    '',
                isDisplayed: true,
            },
            {
                label: 'Raison sociale',
                render: ({ raisonSociale }) => raisonSociale,
                isDisplayed: true,
                sortable: true,
                field: 'raisonSociale',
            },
            {
                label: 'CP Ville',
                render: ({ adresses }) => {
                    if (!adresses || !adresses.length) return null;
                    const { ville, codePostal } = adresses[0];
                    return `${codePostal} ${ville.toUpperCase()}`;
                },
                isDisplayed: true,
            },
            {
                label: 'Siret',
                render: 'siret',
                isDisplayed: false,
            },
            {
                label: 'Contact',
                render: ({ contacts }: Prescripteur) => {
                    if (!contacts || !contacts.length) return null;
                    return `${contacts[0].nomContact.toUpperCase()} ${contacts[0].prenomContact}`;
                },
                isDisplayed: true,
            },
            {
                label: 'Actions',
                displayLabel: false,
                render: (item) => item.id && <ActionMenu itemId={item.id} />,
                isDisplayed: true,
            },
        ],
        [xIdSociete],
    );

    const filters: FiltersDatatableList = [
        {
            label: 'Demo',
            field: 'demo',
            type: 'boolean',
        },
        {
            label: 'supprimé',
            field: 'deleted',
            type: 'boolean',
        },
        {
            label: 'Code client',
            field: 'relationsSocietes.codeClient',
            type: 'text',
        },
        {
            label: 'Raison sociale',
            field: 'raisonSociale',
            type: 'text',
        },
        {
            label: 'Siret',
            field: 'siret',
            type: 'text',
        },
        {
            label: 'Siren',
            field: 'siren',
            type: 'text',
        },
        {
            label: 'Email contacts',
            field: 'contacts.emailsContact.email',
            type: 'text',
        },
        {
            label: 'Telephone contacts',
            field: 'contacts.telephonesContact.telephone',
            type: 'text',
        },
        {
            label: 'Nom contact',
            field: 'contacts.nomContact',
            type: 'text',
        },
        {
            label: 'Prénom contact',
            field: 'contacts.prenomContact',
            type: 'text',
        },
        {
            label: 'Nom Commercial',
            field: 'commerciaux.utilisateur.nom',
            type: 'text',
        },
        {
            label: 'Prénom Commercial',
            field: 'commerciaux.utilisateur.prenom',
            type: 'text',
        },
        {
            label: 'Formaliste',
            field: 'formaliste.nom',
            type: 'text',
        },
        {
            label: 'adresse',
            field: 'adresses.codePostal',
            type: 'text',
        },
    ];

    return datasource ? (
        <>
            <LazyBreadcrumb
                path={[
                    { path: 'Prescripteurs', link: '/prescripteurs/liste' },
                    "Recherche d'un prescripteur",
                    'Liste des résultats',
                ]}
            />
            <StoreDatatable
                dataSource={datasource}
                columns={columns}
                fetchData={getPrescripteurs}
                filters={filters}
                localStorageKey="FlexyStoreDatatable"
                onClickRow={(_: React.MouseEvent<HTMLElement>, { id }: Prescripteur) =>
                    navigate(`${id}`)
                }
                observers={[xIdSociete]}
            />
        </>
    ) : null;
};

export default PrescripteursListing;
