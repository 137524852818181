import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { Box, Button, SxProps, TextField, Typography } from '@mui/material';
import { BorderedBox } from '@europrocurement/flexy-components';
import { FactureAchatNote } from '@europrocurement/l2d-domain';

import { removeNoteModalMessages } from '@b2d/pages/Achats/constants/wording/modals';
import ConfirmationModal from '@b2d/pages/Achats/components/modals/ConfirmationModal';
import { NoteFactureForm } from '@b2d/pages/Achats/components/forms/types';

export type NoteObjectProps = {
    note: NoteFactureForm;
    sx?: SxProps;
    onEdit?: (noteContent: string) => void;
    onRemove?: () => void;
    onEditModeChange?: (isEditing: boolean) => void;
    isEditable?: boolean;
};

const NoteObject: React.FunctionComponent<NoteObjectProps> = function ({
    note,
    sx,
    onEdit,
    onRemove,
    onEditModeChange,
    isEditable = true,
}) {
    const { register, getValues } = useForm({
        mode: 'onTouched',
        defaultValues: { note: note.note },
    });

    const noteField = register('note');

    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [canEdit, setCanEdit] = useState<boolean>(true);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false);

    const { enqueueSnackbar } = useSnackbar();
    const textFieldRef = useRef<HTMLTextAreaElement>(null);

    useEffect(() => {
        if (onEditModeChange) {
            onEditModeChange(isEditing);
        }
    }, [isEditing, onEditModeChange]);

    const handleToggleEditMode = () => {
        if (!canEdit) return;
        setIsEditing(!isEditing);
    };

    const handleUpdateNote = () => {
        if (!canEdit) {
            enqueueSnackbar("Impossible d'enregistrer une note vide !", { variant: 'error' });
            return;
        }
        setIsSubmitting(true);
        const noteContent = getValues('note');
        if (onEdit) {
            onEdit(noteContent ?? '');
        }
        setIsSubmitting(false);
        setIsEditing(false);
    };

    const handleRemoveNote = () => {
        setIsConfirmationModalOpen(true);
    };

    const getNoteTitle = (noteData: FactureAchatNote) => {
        let title = '';
        if (noteData.createdAt) {
            title += `Le ${new Date(noteData.createdAt).toLocaleDateString()} `;
        }
        if (noteData.createdBy) {
            title += `Par ${noteData.createdBy} `;
        }
        return title;
    };

    useEffect(() => {
        if (note.note === ' ') {
            setIsEditing(true);
        }
    }, [note.note]);

    useEffect(() => {
        if (isEditing) {
            textFieldRef.current?.focus();
        }
    }, [isEditing]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleNoteFieldChange = (event: { target: any; type?: any }) => {
        const value = event.target.value.trim();
        setCanEdit(value !== '');
        noteField.onChange(event);
    };

    return (
        <BorderedBox
            boxTitle={getNoteTitle(note)}
            actionBarProps={
                isEditable
                    ? {
                          editAction: handleToggleEditMode,
                          deleteAction: handleRemoveNote,
                      }
                    : undefined
            }
            borderWidth={3}
            sx={sx}
        >
            <ConfirmationModal
                isModalOpen={isConfirmationModalOpen}
                closeModal={() => setIsConfirmationModalOpen(false)}
                messages={removeNoteModalMessages}
                actionOnValidation={onRemove}
                actionOnCancellation={() => setIsConfirmationModalOpen(false)}
            />
            <Typography
                component="div"
                variant="body1"
                style={{ whiteSpace: 'break-spaces' }}
            >
                {isEditing ? (
                    <>
                        <TextField
                            inputRef={textFieldRef}
                            id="note_prescripteur_textarea"
                            sx={{ marginTop: 2 }}
                            variant="outlined"
                            fullWidth
                            multiline
                            minRows={5}
                            placeholder="NoteObject"
                            {...noteField}
                            onChange={handleNoteFieldChange}
                        />
                        <Box
                            display="flex"
                            justifyContent="flex-end"
                            alignItems="flex-end"
                        >
                            <Button
                                type="submit"
                                onClick={handleUpdateNote}
                                disabled={isSubmitting || !canEdit}
                                variant="contained"
                                style={{ marginTop: '2rem', marginBottom: '16px' }}
                            >
                                Mettre à jour la note
                            </Button>
                        </Box>
                    </>
                ) : (
                    note.note
                )}
            </Typography>
        </BorderedBox>
    );
};

export default NoteObject;
