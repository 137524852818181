import useSectionHeader from './useSectionHeader';
import useSectionFolio from './useSectionFolio';
import useSectionTotals from './useSectionTotals';
import useSectionPublisher from './useSectionPublisher';
import useSectionProducts from './useSectionProducts';

/**
 * Includes all the custom hooks used to update the API with form data, by section.
 */
const useSectionDataManager = () => ({
    header: useSectionHeader,
    folio: useSectionFolio,
    publisher: useSectionPublisher,
    products: useSectionProducts,
    totals: useSectionTotals,
});

export default useSectionDataManager;
