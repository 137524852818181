// React import
import * as React from 'react';
// Data / utils / custom hooks
import AccordionCard from '@b2d/components/AccordionCard';

import { ModalContext } from '@europrocurement/flexy-components';
import { usePrescriberUtils } from '@b2d/hooks/prescriberHooks';
import PrescriberAddresses from './PrescriberAddresses';
import PrescriberContacts from './PrescriberContacts';
import PrescriberInformations from './PrescriberInformations';

const MainInformations: React.FunctionComponent = function () {
    const {
        prescriber,
        methods: { reloadPrescriber },
    } = usePrescriberUtils();

    const { modalActions } = React.useContext(ModalContext);

    const afterSubmit = () => {
        reloadPrescriber();
        modalActions.reset();
    };

    const prescriberAccordionProps = {
        prescriber,
        afterSubmit,
    };

    type AccordionItem = {
        title: string;
        component: React.ReactNode;
        addAction?: () => void;
        addTooltip?: string;
    };

    const accordions: Array<AccordionItem> = [
        {
            title: 'Renseignements Société',
            component: <PrescriberInformations {...prescriberAccordionProps} />,
        },
        {
            title: 'Contacts',
            component: <PrescriberContacts {...prescriberAccordionProps} />,
        },
        {
            title: 'Adresses',
            component: <PrescriberAddresses {...prescriberAccordionProps} />,
        },
    ];

    return (
        <>
            {accordions.map(({ title, component }) => (
                <AccordionCard
                    key={title}
                    title={title}
                    component={component}
                />
            ))}
        </>
    );
};

export default MainInformations;
