import {
    SliceFactory,
    FetchCollectionData,
    FetchItemDataType,
    ApiCollectionResponse,
} from '@europrocurement/l2d-redux-utils';
import {
    ConfigurationParameters as TiersConfigurationParameters,
    Configuration,
    CivilitesApi,
} from '../../../openApi/ApiTiers';
import { TIERS_REDUCER_NAME } from '../constants';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type CivilityApiObject = any & Record<string, unknown>;

export type Civilities = CivilityApiObject;

export const CIVILITIES_SLICE_NAME = 'civilities';

const mapperCivilities = function (civilities: CivilityApiObject) {
    return {
        ...civilities,
    } as Civilities;
};

export function createCivilitiesSlice(configuration: TiersConfigurationParameters) {
    const conf = new Configuration(configuration);
    const api = new CivilitesApi(conf);

    const fetchCivilitiesCollection: FetchCollectionData<CivilityApiObject> = ({
        pagination,
        xIdSociete,
    }) =>
        api
            .getListeCivilitesTblParametreReferencesCollection({
                xIdSociete,
                page: pagination.page + 1,
                itemsPerPage: 500,
            })
            .then((res) => res.data)
            .catch((error) => {
                console.error(error);
            }) as Promise<ApiCollectionResponse<CivilityApiObject>>;

    const fetchCivilitiesItem: FetchItemDataType<CivilityApiObject> = () =>
        new Promise(() => {
            console.error('fetchCivilitiesItem called but should not');
        });

    const { slice: civilitiesSlice, dataSourcesThunks: civilitiesDataSourcesThunks } =
        SliceFactory.createSlice<CivilityApiObject, Civilities>(
            CIVILITIES_SLICE_NAME,
            TIERS_REDUCER_NAME,
            [],
            fetchCivilitiesCollection,
            fetchCivilitiesItem,
            mapperCivilities,
        );

    return { civilitiesSlice, civilitiesDataSourcesThunks };
}
