import {
    SliceFactory,
    FetchCollectionData,
    FetchItemDataType,
    ApiCollectionResponse,
} from '@europrocurement/l2d-redux-utils';
import {
    ConfigurationParameters as TiersConfigurationParameters,
    Configuration,
    PortefeuilleApi,
    PortefeuilleTiersRead,
} from '../../../openApi/ApiTiers';
import { TIERS_REDUCER_NAME } from '../constants';

export { type TiersConfigurationParameters };

export type WalletApiObject = PortefeuilleTiersRead & Record<string, unknown>;

export type WalletOptions = WalletApiObject;

export const WALLET_OPTIONS_SLICE_NAME = 'walletOptions';

const mapperWalletOptions = function (walletOptions: WalletApiObject) {
    return {
        ...walletOptions,
    } as WalletOptions;
};

export function createWalletOptionsSlice(configuration: TiersConfigurationParameters) {
    const conf = new Configuration(configuration);
    const api = new PortefeuilleApi(conf);

    const fetchWalletOptionsCollection: FetchCollectionData<WalletApiObject> = ({
        pagination,
        xIdSociete,
    }) =>
        api
            .getListePortefeuilleCategorieSocioProfessionnelleCollection({
                xIdSociete,
                page: pagination.page + 1,
            })
            .then((res) => res.data)
            .catch((error) => {
                console.error(error);
            }) as Promise<ApiCollectionResponse<WalletApiObject>>;

    const fetchWalletOptionsItem: FetchItemDataType<WalletApiObject> = ({ idItem, xIdSociete }) =>
        api
            .getPortefeuilleCategorieSocioProfessionnelleItem({
                id: String(idItem),
                xIdSociete,
            })
            .then((res) => res.data) as Promise<WalletApiObject>;

    const { slice: walletOptionsSlice, dataSourcesThunks: walletOptionsDataSourcesThunks } =
        SliceFactory.createSlice<WalletApiObject, WalletOptions>(
            WALLET_OPTIONS_SLICE_NAME,
            TIERS_REDUCER_NAME,
            [],
            fetchWalletOptionsCollection,
            fetchWalletOptionsItem,
            mapperWalletOptions,
        );

    return { walletOptionsSlice, walletOptionsDataSourcesThunks };
}
