/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { Prescripteur } from '@europrocurement/l2d-domain';
import { Grid } from '@mui/material';
import { AddressCard, ModalContext } from '@europrocurement/flexy-components';
import { AdresseJsonldTiersRead } from '@europrocurement/l2d-domain/openApi/ApiTiers';
import { useSelector } from 'react-redux';
import { customizerSelector } from '@europrocurement/flexy-components/redux/storeConfig/selectors';
import { prescribersApi } from '../../../../../../redux/RootStore';
import ActionModal from '../../forms/ActionModal';
import AddressForm from '../../../../forms/AddressForm';

type PrescriberAddressesProps = {
    prescriber: Prescripteur;
    afterSubmit: () => void;
};

const PrescriberAddresses: React.FunctionComponent<PrescriberAddressesProps> = function (props) {
    const { prescriber, afterSubmit } = props;
    const { modalActions } = React.useContext(ModalContext);

    const { xIdSociete } = useSelector(customizerSelector);

    const deleteAddress = async (addressId: number) => {
        const prescriberAddresses = prescriber.adresses.filter(
            (prescriberAddress) => prescriberAddress.id !== addressId,
        );
        if (prescriber && prescriber.id) {
            await prescribersApi.updateAdressesPrescripteurTiersItem({
                xIdSociete,
                id: prescriber.id.toString(),
                tiersPrescripteurJsonldPrescripteurWriteAdresses: {
                    adresses: prescriberAddresses,
                },
            });
            afterSubmit();
        }
    };

    const handleDeleteAddress = (address: any) => {
        // Todo type params
        if (prescriber.id) {
            modalActions.call(
                <ActionModal
                    onClose={modalActions.close}
                    asyncAction={() => deleteAddress(address.id)}
                    title="Suppression d'une adresse"
                    actionText="Voulez-vous supprimer cette adresse ?"
                    actionLabel="Confirmer suppression"
                />,
            );
        }
    };

    const handleEditAddress = (address: any) => {
        // Todo type params
        if (prescriber.id) {
            modalActions.call(
                <AddressForm
                    entity={address}
                    prescriber={prescriber}
                    afterSubmit={afterSubmit}
                />,
            );
        }
    };

    // Compare function to determine default order of address
    const defaultAddressSort = (
        elementA: AdresseJsonldTiersRead,
        elementB: AdresseJsonldTiersRead,
    ) => {
        const aDefault = elementA.defaut;
        const bDefault = elementB.defaut;
        const aBilling = elementA.facturation;

        if (aDefault) {
            return -1;
        }

        if (aBilling && !bDefault) {
            return -1;
        }

        return 1;
    };

    const sortedAddresses = [...prescriber.adresses].sort(defaultAddressSort);
    const hasAddress = sortedAddresses.length > 0;

    return (
        <Grid container>
            {hasAddress ? (
                <>
                    {sortedAddresses.map((address, _, array) => (
                        <Grid
                            key={`address_card_${address.id}`}
                            item
                            xs={12}
                            md={6}
                            lg={array.length > 2 ? 4 : 6}
                        >
                            <AddressCard
                                isBordered
                                address={address}
                                onDelete={() => handleDeleteAddress(address)}
                                onEdit={() => handleEditAddress(address)}
                                isDeletable={
                                    address.defaut === false && address.facturation === false
                                }
                            />
                        </Grid>
                    ))}
                </>
            ) : (
                <p>Aucune adresse</p>
            )}
        </Grid>
    );
};

export default PrescriberAddresses;
