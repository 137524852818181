import React, { useCallback, useState } from 'react';

import { Card, CardContent, Grid, Divider, Stack, Typography } from '@mui/material';
import { LazyBreadcrumb, Scrollbar, TogglesSociete } from '@europrocurement/flexy-components';

import { FileUploadItemProps } from '../../ImportToast';
import ImportToaster from '../../ImportToaster';
import Uploader from '../../Uploader';

const MediaObjectImportView = function () {
    const [uploaded, setUploaded] = useState<Array<FileUploadItemProps>>([]);
    const [uploading, setUploading] = useState<Array<FileUploadItemProps>>([]);

    const handleUploaded = useCallback((newItem: FileUploadItemProps) => {
        setUploaded((oldUploaded) => [...oldUploaded, newItem]);
        setUploading((oldUploading) => [
            ...oldUploading.filter((item) => item.file !== newItem.file),
        ]);
    }, []);

    const handlePending = useCallback((newItem: FileUploadItemProps) => {
        setUploading((oldUploading) => [...oldUploading, newItem]);
    }, []);

    return (
        <>
            <LazyBreadcrumb
                path={[
                    { path: 'Portail achats', link: '/achats/liste' },
                    'Ajout manuel de facture',
                ]}
            />
            <Card>
                <CardContent>
                    <Stack gap={1}>
                        <Typography variant="body1">Importer un fichier vers :</Typography>
                        <TogglesSociete />
                    </Stack>
                </CardContent>
            </Card>
            <Card>
                <CardContent>
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                        >
                            <Uploader
                                onUploaded={handleUploaded}
                                onPending={handlePending}
                            />
                        </Grid>
                        <Grid
                            item
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            xs={12}
                            sm={12}
                            md={1}
                        >
                            <Divider orientation="vertical" />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={5}
                        >
                            <Scrollbar style={{ width: '30vw' }}>
                                <ImportToaster filesUploading={[...uploading, ...uploaded]} />
                            </Scrollbar>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>
    );
};

export default MediaObjectImportView;
