import React, { FunctionComponent } from 'react';

import { Box } from '@mui/material';
import InvoiceFormFragmentSkeleton from './InvoiceFormFragmentSkeleton';

const InvoiceFormViewSkeleton: FunctionComponent = function () {
    const arrayOfSections = ['header', 'folio', 'publisher', 'products', 'totals'];

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                width: '100%',
                height: '100%',
            }}
        >
            {arrayOfSections.map((sectionName) => (
                <Box key={sectionName}>
                    <InvoiceFormFragmentSkeleton
                        fragmentOnlyRender={false}
                        name={sectionName}
                        sectionsCount={arrayOfSections.length}
                    />
                </Box>
            ))}
        </Box>
    );
};

export default InvoiceFormViewSkeleton;
