import { LazyBreadcrumb } from '@europrocurement/flexy-components';
import React from 'react';
import PrescriberForm from './form/PrescriberForm';

const PrescripteurAdd: React.FunctionComponent = function () {
    return (
        <>
            <LazyBreadcrumb
                path={[
                    { path: 'Prescripteurs', link: '/prescripteurs/new' },
                    'nouveau prescripteur',
                    'Création de prescripteur',
                ]}
            />

            <PrescriberForm />
        </>
    );
};

export default React.memo(PrescripteurAdd);
