import {
    SliceFactory,
    FetchCollectionData,
    FetchItemDataType,
    ApiCollectionResponse,
} from '@europrocurement/l2d-redux-utils';
import {
    ConfigurationParameters as OffreConfigurationParameters,
    Configuration,
    ChampComplmentaireApi,
    ChampComplMentaireJsonldChampcomplementaireRead,
} from '../../../openApi/ApiOffre';
import { AUTOCOMPLETE_KEY, OFFRE_REDUCER_NAME } from '../constants';

export type OfferAdditionalFieldApiObject = ChampComplMentaireJsonldChampcomplementaireRead &
    Record<string, unknown>;

export type OfferAdditionalFields = OfferAdditionalFieldApiObject;

export const OFFER_ADDITIONAL_FIELDS_SLICE_NAME = 'offer_additional_fields';

const mapperOfferAdditionalFields = function (offers: OfferAdditionalFieldApiObject) {
    return {
        ...offers,
    } as OfferAdditionalFields;
};

export function createOfferAdditionalFieldsSlice(configuration: OffreConfigurationParameters) {
    const conf = new Configuration(configuration);
    const api = new ChampComplmentaireApi(conf);

    const fetchOfferAdditionalFieldsCollection: FetchCollectionData<
        OfferAdditionalFieldApiObject
    > = ({ pagination: { page, itemsPerPage }, xIdSociete, search }) => {
        let libelle: string | undefined;

        if (search && search !== '') {
            libelle = search;
        }
        return api
            .apiChampsComplementairesGetCollection({
                xIdSociete,
                page: page + 1,
                itemsPerPage,
                libelle,
            })
            .then((res) => res.data)
            .catch((error) => {
                console.error(error);
            }) as Promise<ApiCollectionResponse<OfferAdditionalFieldApiObject>>;
    };

    const fetchOfferAdditionalFieldsItem: FetchItemDataType<OfferAdditionalFieldApiObject> = ({
        idItem,
        xIdSociete,
    }) =>
        api
            .apiChampsComplementairesIdGet({ id: String(idItem), xIdSociete })
            .then((res) => res.data) as Promise<OfferAdditionalFieldApiObject>;

    const {
        slice: offerAdditionalFieldsSlice,
        dataSourcesThunks: offerAdditionalFieldsDataSourcesThunks,
    } = SliceFactory.createSlice<OfferAdditionalFieldApiObject, OfferAdditionalFields>(
        OFFER_ADDITIONAL_FIELDS_SLICE_NAME,
        OFFRE_REDUCER_NAME,
        [AUTOCOMPLETE_KEY],
        fetchOfferAdditionalFieldsCollection,
        fetchOfferAdditionalFieldsItem,
        mapperOfferAdditionalFields,
    );

    return { offerAdditionalFieldsSlice, offerAdditionalFieldsDataSourcesThunks };
}
