import * as React from 'react';
import { FlexyForm, FormStructure } from '@europrocurement/flexy-form';
import Person from '@mui/icons-material/Person';
import useToaster from '@b2d/hooks/useToaster';
import { demandeurSignatureApi } from '@b2d/redux/subReducers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/pro-duotone-svg-icons';
import { B2DLogoIcon } from '@europrocurement/l2d-icons';
import { URL_CALLBACK, URL_LOGO } from '@b2d/validation/regex';
import { DemandeurCardProps } from '../constants/types';

export type DemandeurFormProps = {
    demandeur?: DemandeurCardProps;
    afterSubmit?: (demandeur: DemandeurCardProps) => void;
};

const DemandeurForm: React.FunctionComponent<DemandeurFormProps> = function (props) {
    const { demandeur = {}, afterSubmit } = props;

    const { handleApiResponse } = useToaster();

    const updateDemandeur = async (formDemandeur: DemandeurCardProps) => {
        console.log(formDemandeur);
        console.log('here');

        if (!formDemandeur.id) {
            throw new Error('Pas de référentiel id, cas non gerré');
        }

        const request = demandeurSignatureApi
            .putDemandeurItem({
                id: `${formDemandeur.id}`,
                demandeurJsonldWrite: formDemandeur,
            })
            .then((value) => {
                if (afterSubmit) {
                    afterSubmit(value.data as DemandeurCardProps);
                }
            });

        await handleApiResponse(request, undefined, {
            error: 'Erreur lors de la modification du référentiel',
            success: 'Référentiel mis a jour !',
        });
    };

    const DemandeurFormStructure: FormStructure[] = [
        {
            type: 'header',
            label: (
                <>
                    <Person sx={{ marginRight: '5px' }} />
                    Informations référentiel
                </>
            ),
            sx: {
                marginTop: '0px',
            },
            name: 'info',
        },
        {
            type: 'boolean',
            name: 'actif',
            inputlabel: 'État',
            verticalSwitch: true,
            xs: 12,
            sm: 12,
            md: 12,
            lg: 3,
        },
        {
            type: 'text',
            name: 'nom',
            inputlabel: 'Nom',
            xs: 12,
            rules: {
                required: 'Vous devez renseigner un nom',
            },
            placeholder: 'MonSite.com',
        },
        {
            type: 'text',
            name: 'callbackUrl',
            inputlabel: 'Url de Callback json automatisé',
            xs: 12,
            placeholder: 'http://example.com/mycallback.php',

            rules: {
                pattern: {
                    value: URL_CALLBACK,
                    message: 'Merci de renseigner une url de Callback correcte',
                },
            },
        },
        {
            type: 'header',
            label: (
                <>
                    <FontAwesomeIcon
                        icon={faImage}
                        style={{ marginRight: '5px ' }}
                    />
                    Logo
                </>
            ),
            sx: {
                marginTop: '0px',
            },
            name: 'info',
        },
        {
            type: 'text',
            name: 'logo',
            inputlabel: 'URL logo des mails',
            xs: 12,
            sm: 12,
            md: 9,
            lg: 9,
            placeholder:
                'https://www.legal2digital.fr/wp-content/uploads/2022/12/logo-baseline.jpg',

            rules: {
                required: 'Vous devez renseigner une URL de logo (au format jpg, png ou svg)',
                pattern: {
                    value: URL_LOGO,
                    message:
                        'Merci de renseigner une URL de logo correcte (au format jpg, png ou svg)',
                },
            },
        },
        {
            type: 'customItem',
            name: 'renderLogo',
            xs: 12,
            sm: 12,
            md: 3,
            lg: 3,
            renderField: (formContext) => {
                const logoUrl: string | null = formContext.getValues('logo');

                if (logoUrl && logoUrl !== '') {
                    return (
                        <img
                            style={{
                                margin: '20px',
                                minHeight: '80px',
                                minWidth: '80px',
                                maxHeight: '80px',
                                maxWidth: '80px',
                            }}
                            src={logoUrl}
                            alt="Logo du référentiel"
                        />
                    );
                }

                return <B2DLogoIcon style={{ maxWidth: '200px', marginTop: '15px' }} />;
            },
        },
    ];

    return (
        <FlexyForm
            formObject={demandeur}
            formStructure={DemandeurFormStructure}
            onSubmit={updateDemandeur}
        />
    );
};

export default DemandeurForm;
