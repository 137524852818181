/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { domainSelector } from '@b2d/redux/RootStore';
import { zodResolver } from '@hookform/resolvers/zod';
import { useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { Box } from '@mui/system';
import { FlexyHeaderForm } from '@europrocurement/flexy-components';
import { Alert } from '@mui/material';
import { formatOptionsByKeys } from '../../forms/options';
import SelectField from '../../components/form/SelectField';
import TextField from '../../components/form/TextField';
import { configurationStepSchema } from './validation';
import FormalitySelect from './FormalitySelect';
import { ArticleFormItem } from '../Components/types';
import useStepForm from '../../components/form/MultiStepForm/useStepForm';
import { MultiStepContextProps } from '../../components/form/MultiStepForm/types';
import useReadOnlyDocuments from '../../components/DocumentsList/useReadOnlyDocuments';
import { domainIds } from '../../constants';

const FORMALITY_DOMAIN_ID = 3;

/** Form first step : Main informations of package (label / domain) */
const ConfigurationStep: React.FC<MultiStepContextProps> = function (props) {
    const { multiStepForm } = props;
    const { setCurrentFormContext, formValues } = multiStepForm;

    /** Api fetched options for select inputs */
    const fetchedDomainsOptions = useSelector(domainSelector).main.data;
    const domainsOptions = formatOptionsByKeys(fetchedDomainsOptions, 'id', 'libelle');

    /** React hook form */

    // Form configuration

    const formContext = useForm({
        mode: 'onBlur',
        defaultValues: formValues,
        resolver: zodResolver(configurationStepSchema),
    });

    React.useEffect(() => {
        setCurrentFormContext(formContext);
    }, [formContext, setCurrentFormContext]);

    useStepForm(formContext, multiStepForm);

    const {
        control,
        formState: { errors },
        watch,
    } = formContext;

    const { formalityId } = formContext.getValues();
    const { actions, onFormalityUpdate } = useReadOnlyDocuments(formContext);

    watch('formalityId');

    // Fetch formalities documents on formality update
    React.useEffect(() => {
        onFormalityUpdate(formalityId);
    }, [formalityId]);

    const { domainId } = formContext.getValues();
    const isFormalityDomain = domainId === domainIds.formality;

    // Store them as read only documents when response
    React.useEffect(() => {
        if (!isFormalityDomain) {
            actions.clearDocumentsInCategory('formalities');
        }
    }, [domainId]);

    const isFormalitieDomain = formContext.getValues().domainId === FORMALITY_DOMAIN_ID;
    const packageArticles = formContext
        .getValues()
        .articles.filter((article: ArticleFormItem) => article.invoicingCategory !== 'LIB');
    const packageContainsArticles = packageArticles.length > 0;

    return (
        <Box sx={{ marginTop: '15px' }}>
            <Box sx={{ marginBottom: '10px' }}>
                <FlexyHeaderForm
                    sx={{ marginTop: 0 }}
                    label="Informations générales"
                    outlined
                />
            </Box>
            <form noValidate>
                <Box
                    display="flex"
                    justifyContent="space-between"
                >
                    <Box width="48%">
                        <Controller
                            name="label"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    sx={{ width: '100%' }}
                                    {...field}
                                    name={field.name}
                                    label="Libellé"
                                    errors={errors}
                                    required
                                />
                            )}
                        />
                    </Box>
                    <Box width="48%">
                        <Controller
                            name="domainId"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <SelectField
                                        {...field}
                                        value={watch(field.name)}
                                        placeholder="Sélectionnez un domaine"
                                        name={field.name}
                                        label="Domaine"
                                        options={domainsOptions}
                                        errors={errors}
                                        isDisabled={packageContainsArticles}
                                    />
                                    {packageContainsArticles && (
                                        <Alert
                                            sx={{ marginTop: '10px' }}
                                            severity="info"
                                        >
                                            Vous ne pouvez pas modifier le domaine d&apos;un package
                                            contenant des articles
                                        </Alert>
                                    )}
                                </>
                            )}
                        />
                    </Box>
                </Box>

                <Controller
                    name="commercialName"
                    control={control}
                    render={({ field }) => (
                        <TextField
                            sx={{ width: '100%' }}
                            {...field}
                            name={field.name}
                            label="Nom commercial"
                            errors={errors}
                            required
                        />
                    )}
                />

                <Box sx={{ marginBottom: '10px', opacity: isFormalitieDomain ? 1 : 0.3 }}>
                    <FlexyHeaderForm
                        label="Informations complémentaires"
                        outlined
                    />
                </Box>

                {isFormalitieDomain ? (
                    <FormalitySelect formContext={formContext} />
                ) : (
                    <Alert
                        sx={{ marginTop: '10px' }}
                        severity="success"
                    >
                        Aucune information complémentaire requise pour ce domaine
                    </Alert>
                )}
            </form>
        </Box>
    );
};

export default ConfigurationStep;
