import { RootStateType, facturationConfiguration } from '@b2d/redux/RootStore';
import {
    Configuration,
    DEVIS_LIGNE_SLICE_NAME,
    DevisLigne,
    FACTURATION_REDUCER_NAME,
} from '@europrocurement/l2d-domain';
import ICONS from '@b2d/icons';
import { ModelConfiguration, ModelItem, ModelMethods } from '@b2d/pages/Achats/models/types';
import { DevisLignesApi } from '@europrocurement/l2d-domain/openApi/ApiFacturation';
import { devisLigneDataSourcesThunks } from '@b2d/redux/subReducers';

const modelKey = 'quote_line';
const modelName = 'QuoteLine';
const sliceName = DEVIS_LIGNE_SLICE_NAME;
const apiConfiguration = new Configuration(facturationConfiguration);
const api = new DevisLignesApi(apiConfiguration);
const baseSelector = (state: RootStateType) => state[FACTURATION_REDUCER_NAME][sliceName];
const datasourcesThunks = devisLigneDataSourcesThunks;

type ModelStructure = DevisLigne & Record<string, unknown>;

const configuration: ModelConfiguration = {
    key: modelKey,
    name: modelName,
    type: 'QuoteLine',
    selector: baseSelector,
    sliceName,
    api,
    datasourcesThunks,
    icon: ICONS.models[modelKey],
    color: 'blue',
};

const methods: ModelMethods<ModelStructure> = {
    getItemDefaultLabel: (model: ModelStructure) =>
        `Devis: ${model.devis} - Numéro: ${model.numero}`,
    getItemSecondaryLabel: (model: ModelStructure) =>
        `Libelle: ${model.libelle} - Rubrique: ${model.rubfac}`,
};

const endpoints = {
    list: (...args: Parameters<typeof api.apiDevisLignesGetDevisCollection>) =>
        api.apiDevisLignesGetDevisCollection(...args),
    read: (...args: Parameters<typeof api.apiDevisLignesGetItem>) =>
        api.apiDevisLignesGetItem(...args),
};
type Endpoints = typeof endpoints;

const model: ModelItem<ModelStructure> & Endpoints = {
    ...configuration,
    ...methods,
    ...endpoints,
};

export default model;
