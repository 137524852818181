import { UseFormReturn } from 'react-hook-form';
import { FormObject } from '@europrocurement/flexy-form';
import { Fournisseur } from '@europrocurement/l2d-domain';
import { TypeRemiseFournisseurRead } from '@europrocurement/l2d-domain/openApi/ApiTiers';

/**
 * Met à jour le champs "Recherche par siren / nom" de la section "Fournisseur"
 *
 * @param formContext
 * @param siren
 */
export const updateSirenFournisseur = function (
    formContext: UseFormReturn<FormObject>,
    siren: string,
) {
    formContext.setValue('siren_fournisseur', siren);
};

/**
 * Met à jour le champs "Raison sociale" de la section "Fournisseur"
 *
 * @param formContext
 * @param raisonSociale
 */
export const updateRaisonSocialeFournisseur = function (
    formContext: UseFormReturn<FormObject>,
    raisonSociale: string,
) {
    formContext.setValue('raison_sociale_fournisseur', raisonSociale);
};

/**
 * Met à jour le champs "Type remise" de la section "Fournisseur"
 *
 * @param formContext
 * @param typeRemise
 */
export const updateRemiseFournisseur = function (
    formContext: UseFormReturn<FormObject>,
    typeRemise: string,
) {
    formContext.setValue('type_remise_fournisseur', typeRemise);
};

/**
 * Vide les champs de la section "Fournisseur"
 *
 * @param formContext
 */
export const resetFournisseursFields = function (formContext: UseFormReturn<FormObject>) {
    updateRaisonSocialeFournisseur(formContext, '');
    updateSirenFournisseur(formContext, '');
    updateRemiseFournisseur(formContext, '');
};

/**
 * Complète le formulaire grâce au fournisseur.
 *
 * @param formContext
 * @param fournisseur
 * @param callbacks appelés en fonction de certains champs
 *      raisonSociale => Si le fournisseur est présent
 *      remise => Si le fournisseur est présent
 *      siren => Si le fournisseur est présent
 *      typeRemise => Si le fournisseur a un type de remise
 * @returns
 */
export const updateFormWithFournisseur = function (
    formContext: UseFormReturn<FormObject>,
    fournisseur?: Fournisseur,
    callbacks?: {
        raisonSociale?: (value: string) => void;
        remise?: (value: string) => void;
        siren?: (value: string) => void;
        typeRemise?: (value: TypeRemiseFournisseurRead) => void;
    },
) {
    if (!fournisseur) {
        /**
         * Empêche de saisir le fournisseur en premier !
         */
        // resetFournisseursFields(formContext);
        return;
    }

    if (callbacks?.raisonSociale) {
        callbacks?.raisonSociale(fournisseur.libelle || 'Pas de raison sociale');
    }
    updateRaisonSocialeFournisseur(formContext, fournisseur.libelle || 'Pas de raison sociale');

    if (callbacks?.siren) {
        callbacks?.siren(fournisseur.siren || 'Pas de SIREN');
    }
    updateSirenFournisseur(formContext, fournisseur.siren || 'Pas de SIREN');

    if (fournisseur.typeRemise) {
        let codeTypeRemise = fournisseur.typeRemise.code;

        if (fournisseur.rsfInclus) {
            codeTypeRemise = 'RSF Inclus';
        }

        const remiseString = `${codeTypeRemise} (${fournisseur.typeRemise.libelle})`;

        if (callbacks?.remise) {
            callbacks?.remise(remiseString);
        }
        if (callbacks?.typeRemise) {
            callbacks?.typeRemise(fournisseur.typeRemise);
        }
        updateRemiseFournisseur(formContext, remiseString);
    } else {
        updateRemiseFournisseur(formContext, `Pas de remise`);
    }
};

/**
 *
 * @param societeReference
 * @returns
 */
export const isSocieteGroupe: (societeReference?: number) => boolean | string = function (
    societeReference?: number,
) {
    return !!(societeReference && societeReference > 0);
};
