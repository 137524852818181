/* tslint:disable */
/* eslint-disable */
/**
 * Europrocurement API Utilisateur
 * API de gestion des utilisateurs
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ApiDroitGetCollection200Response
 */
export interface ApiDroitGetCollection200Response {
    /**
     * 
     * @type {any}
     * @memberof ApiDroitGetCollection200Response
     */
    'hydra:member': any;
    /**
     * 
     * @type {any}
     * @memberof ApiDroitGetCollection200Response
     */
    'hydra:totalItems'?: any;
    /**
     * 
     * @type {ApiDroitGetCollection200ResponseHydraView}
     * @memberof ApiDroitGetCollection200Response
     */
    'hydra:view'?: ApiDroitGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiDroitGetCollection200ResponseHydraSearch}
     * @memberof ApiDroitGetCollection200Response
     */
    'hydra:search'?: ApiDroitGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiDroitGetCollection200ResponseHydraSearch
 */
export interface ApiDroitGetCollection200ResponseHydraSearch {
    /**
     * 
     * @type {any}
     * @memberof ApiDroitGetCollection200ResponseHydraSearch
     */
    '@type'?: any;
    /**
     * 
     * @type {any}
     * @memberof ApiDroitGetCollection200ResponseHydraSearch
     */
    'hydra:template'?: any;
    /**
     * 
     * @type {any}
     * @memberof ApiDroitGetCollection200ResponseHydraSearch
     */
    'hydra:variableRepresentation'?: any;
    /**
     * 
     * @type {any}
     * @memberof ApiDroitGetCollection200ResponseHydraSearch
     */
    'hydra:mapping'?: any;
}
/**
 * 
 * @export
 * @interface ApiDroitGetCollection200ResponseHydraView
 */
export interface ApiDroitGetCollection200ResponseHydraView {
    /**
     * 
     * @type {any}
     * @memberof ApiDroitGetCollection200ResponseHydraView
     */
    '@id'?: any;
    /**
     * 
     * @type {any}
     * @memberof ApiDroitGetCollection200ResponseHydraView
     */
    '@type'?: any;
    /**
     * 
     * @type {any}
     * @memberof ApiDroitGetCollection200ResponseHydraView
     */
    'hydra:first'?: any;
    /**
     * 
     * @type {any}
     * @memberof ApiDroitGetCollection200ResponseHydraView
     */
    'hydra:last'?: any;
    /**
     * 
     * @type {any}
     * @memberof ApiDroitGetCollection200ResponseHydraView
     */
    'hydra:previous'?: any;
    /**
     * 
     * @type {any}
     * @memberof ApiDroitGetCollection200ResponseHydraView
     */
    'hydra:next'?: any;
}
/**
 * 
 * @export
 * @interface ApiDroitGetCollection401Response
 */
export interface ApiDroitGetCollection401Response {
    /**
     * 
     * @type {any}
     * @memberof ApiDroitGetCollection401Response
     */
    'message'?: any;
}
/**
 * 
 * @export
 * @interface ApiDroitGetCollection403Response
 */
export interface ApiDroitGetCollection403Response {
    /**
     * 
     * @type {any}
     * @memberof ApiDroitGetCollection403Response
     */
    'error'?: any;
    /**
     * 
     * @type {ApiDroitGetCollection403ResponseException}
     * @memberof ApiDroitGetCollection403Response
     */
    'exception'?: ApiDroitGetCollection403ResponseException;
}
/**
 * 
 * @export
 * @interface ApiDroitGetCollection403ResponseException
 */
export interface ApiDroitGetCollection403ResponseException {
    /**
     * 
     * @type {any}
     * @memberof ApiDroitGetCollection403ResponseException
     */
    'message'?: any;
    /**
     * 
     * @type {any}
     * @memberof ApiDroitGetCollection403ResponseException
     */
    'code'?: any;
}
/**
 * 
 * @export
 * @interface ApiDroitGetCollection404Response
 */
export interface ApiDroitGetCollection404Response {
    /**
     * 
     * @type {any}
     * @memberof ApiDroitGetCollection404Response
     */
    'error'?: any;
    /**
     * 
     * @type {ApiDroitGetCollection404ResponseException}
     * @memberof ApiDroitGetCollection404Response
     */
    'exception'?: ApiDroitGetCollection404ResponseException;
}
/**
 * 
 * @export
 * @interface ApiDroitGetCollection404ResponseException
 */
export interface ApiDroitGetCollection404ResponseException {
    /**
     * 
     * @type {any}
     * @memberof ApiDroitGetCollection404ResponseException
     */
    'message'?: any;
    /**
     * 
     * @type {any}
     * @memberof ApiDroitGetCollection404ResponseException
     */
    'code'?: any;
}
/**
 * 
 * @export
 * @interface ApiGroupesGetCollection200Response
 */
export interface ApiGroupesGetCollection200Response {
    /**
     * 
     * @type {any}
     * @memberof ApiGroupesGetCollection200Response
     */
    'hydra:member': any;
    /**
     * 
     * @type {any}
     * @memberof ApiGroupesGetCollection200Response
     */
    'hydra:totalItems'?: any;
    /**
     * 
     * @type {ApiDroitGetCollection200ResponseHydraView}
     * @memberof ApiGroupesGetCollection200Response
     */
    'hydra:view'?: ApiDroitGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiDroitGetCollection200ResponseHydraSearch}
     * @memberof ApiGroupesGetCollection200Response
     */
    'hydra:search'?: ApiDroitGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiJournauxGetCollection200Response
 */
export interface ApiJournauxGetCollection200Response {
    /**
     * 
     * @type {any}
     * @memberof ApiJournauxGetCollection200Response
     */
    'hydra:member': any;
    /**
     * 
     * @type {any}
     * @memberof ApiJournauxGetCollection200Response
     */
    'hydra:totalItems'?: any;
    /**
     * 
     * @type {ApiDroitGetCollection200ResponseHydraView}
     * @memberof ApiJournauxGetCollection200Response
     */
    'hydra:view'?: ApiDroitGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiDroitGetCollection200ResponseHydraSearch}
     * @memberof ApiJournauxGetCollection200Response
     */
    'hydra:search'?: ApiDroitGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiKeycloakUtilisateurPost400Response
 */
export interface ApiKeycloakUtilisateurPost400Response {
    /**
     * 
     * @type {any}
     * @memberof ApiKeycloakUtilisateurPost400Response
     */
    'error'?: any;
    /**
     * 
     * @type {ApiKeycloakUtilisateurPost400ResponseException}
     * @memberof ApiKeycloakUtilisateurPost400Response
     */
    'exception'?: ApiKeycloakUtilisateurPost400ResponseException;
}
/**
 * 
 * @export
 * @interface ApiKeycloakUtilisateurPost400ResponseException
 */
export interface ApiKeycloakUtilisateurPost400ResponseException {
    /**
     * 
     * @type {any}
     * @memberof ApiKeycloakUtilisateurPost400ResponseException
     */
    'message'?: any;
    /**
     * 
     * @type {any}
     * @memberof ApiKeycloakUtilisateurPost400ResponseException
     */
    'code'?: any;
}
/**
 * 
 * @export
 * @interface ApiKeycloakUtilisateursPrescripteurIdGetCollection200Response
 */
export interface ApiKeycloakUtilisateursPrescripteurIdGetCollection200Response {
    /**
     * 
     * @type {any}
     * @memberof ApiKeycloakUtilisateursPrescripteurIdGetCollection200Response
     */
    'hydra:member': any;
    /**
     * 
     * @type {any}
     * @memberof ApiKeycloakUtilisateursPrescripteurIdGetCollection200Response
     */
    'hydra:totalItems'?: any;
    /**
     * 
     * @type {ApiDroitGetCollection200ResponseHydraView}
     * @memberof ApiKeycloakUtilisateursPrescripteurIdGetCollection200Response
     */
    'hydra:view'?: ApiDroitGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiDroitGetCollection200ResponseHydraSearch}
     * @memberof ApiKeycloakUtilisateursPrescripteurIdGetCollection200Response
     */
    'hydra:search'?: ApiDroitGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiMarquesGetCollection200Response
 */
export interface ApiMarquesGetCollection200Response {
    /**
     * 
     * @type {any}
     * @memberof ApiMarquesGetCollection200Response
     */
    'hydra:member': any;
    /**
     * 
     * @type {any}
     * @memberof ApiMarquesGetCollection200Response
     */
    'hydra:totalItems'?: any;
    /**
     * 
     * @type {ApiDroitGetCollection200ResponseHydraView}
     * @memberof ApiMarquesGetCollection200Response
     */
    'hydra:view'?: ApiDroitGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiDroitGetCollection200ResponseHydraSearch}
     * @memberof ApiMarquesGetCollection200Response
     */
    'hydra:search'?: ApiDroitGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiPolesGetCollection200Response
 */
export interface ApiPolesGetCollection200Response {
    /**
     * 
     * @type {any}
     * @memberof ApiPolesGetCollection200Response
     */
    'hydra:member': any;
    /**
     * 
     * @type {any}
     * @memberof ApiPolesGetCollection200Response
     */
    'hydra:totalItems'?: any;
    /**
     * 
     * @type {ApiDroitGetCollection200ResponseHydraView}
     * @memberof ApiPolesGetCollection200Response
     */
    'hydra:view'?: ApiDroitGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiDroitGetCollection200ResponseHydraSearch}
     * @memberof ApiPolesGetCollection200Response
     */
    'hydra:search'?: ApiDroitGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiServicesGetCollection200Response
 */
export interface ApiServicesGetCollection200Response {
    /**
     * 
     * @type {any}
     * @memberof ApiServicesGetCollection200Response
     */
    'hydra:member': any;
    /**
     * 
     * @type {any}
     * @memberof ApiServicesGetCollection200Response
     */
    'hydra:totalItems'?: any;
    /**
     * 
     * @type {ApiDroitGetCollection200ResponseHydraView}
     * @memberof ApiServicesGetCollection200Response
     */
    'hydra:view'?: ApiDroitGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiDroitGetCollection200ResponseHydraSearch}
     * @memberof ApiServicesGetCollection200Response
     */
    'hydra:search'?: ApiDroitGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiUtilisateursGetCollection200Response
 */
export interface ApiUtilisateursGetCollection200Response {
    /**
     * 
     * @type {any}
     * @memberof ApiUtilisateursGetCollection200Response
     */
    'hydra:member': any;
    /**
     * 
     * @type {any}
     * @memberof ApiUtilisateursGetCollection200Response
     */
    'hydra:totalItems'?: any;
    /**
     * 
     * @type {ApiDroitGetCollection200ResponseHydraView}
     * @memberof ApiUtilisateursGetCollection200Response
     */
    'hydra:view'?: ApiDroitGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiDroitGetCollection200ResponseHydraSearch}
     * @memberof ApiUtilisateursGetCollection200Response
     */
    'hydra:search'?: ApiDroitGetCollection200ResponseHydraSearch;
}
/**
 * Returns all resources from Tbl_Utilisateur_Droit
 * @export
 * @interface DroitsDroitReadCollection
 */
export interface DroitsDroitReadCollection {
    /**
     * 
     * @type {any}
     * @memberof DroitsDroitReadCollection
     */
    'usdId'?: any;
    /**
     * 
     * @type {any}
     * @memberof DroitsDroitReadCollection
     */
    'iduser'?: any;
    /**
     * 
     * @type {any}
     * @memberof DroitsDroitReadCollection
     */
    'usdAppsJalgest'?: any;
    /**
     * 
     * @type {any}
     * @memberof DroitsDroitReadCollection
     */
    'usdAppsJalgestOption'?: any;
    /**
     * 
     * @type {any}
     * @memberof DroitsDroitReadCollection
     */
    'usdAppsXy'?: any;
    /**
     * 
     * @type {any}
     * @memberof DroitsDroitReadCollection
     */
    'usdAppsXyOption'?: any;
    /**
     * 
     * @type {any}
     * @memberof DroitsDroitReadCollection
     */
    'usdAppsMdc'?: any;
    /**
     * 
     * @type {any}
     * @memberof DroitsDroitReadCollection
     */
    'usdAppsMdcOption'?: any;
    /**
     * 
     * @type {any}
     * @memberof DroitsDroitReadCollection
     */
    'usdAppsMail'?: any;
    /**
     * 
     * @type {any}
     * @memberof DroitsDroitReadCollection
     */
    'usdAppsMailOption'?: any;
    /**
     * 
     * @type {any}
     * @memberof DroitsDroitReadCollection
     */
    'usdAppsAnalytics'?: any;
    /**
     * 
     * @type {any}
     * @memberof DroitsDroitReadCollection
     */
    'usdAppsAnalyticsOption'?: any;
    /**
     * 
     * @type {any}
     * @memberof DroitsDroitReadCollection
     */
    'usdAppsAnnuaire'?: any;
    /**
     * 
     * @type {any}
     * @memberof DroitsDroitReadCollection
     */
    'usdAppsAnnuaireOption'?: any;
    /**
     * 
     * @type {any}
     * @memberof DroitsDroitReadCollection
     */
    'usdAppsSupport'?: any;
    /**
     * 
     * @type {any}
     * @memberof DroitsDroitReadCollection
     */
    'usdAppsSupportOption'?: any;
    /**
     * 
     * @type {any}
     * @memberof DroitsDroitReadCollection
     */
    'usdAppsMdcSpel'?: any;
    /**
     * 
     * @type {any}
     * @memberof DroitsDroitReadCollection
     */
    'usdAppsMdcSpelOption'?: any;
    /**
     * 
     * @type {any}
     * @memberof DroitsDroitReadCollection
     */
    'usdAppsDocutech'?: any;
    /**
     * 
     * @type {any}
     * @memberof DroitsDroitReadCollection
     */
    'usdAppsDocutechOption'?: any;
    /**
     * 
     * @type {any}
     * @memberof DroitsDroitReadCollection
     */
    'usdAppsDocutechBackoffice'?: any;
    /**
     * 
     * @type {any}
     * @memberof DroitsDroitReadCollection
     */
    'usdAppsGestAbonnes'?: any;
}
/**
 * Returns all resources from Tbl_Utilisateur_Droit
 * @export
 * @interface DroitsJsonldDroitReadCollection
 */
export interface DroitsJsonldDroitReadCollection {
    /**
     * 
     * @type {any}
     * @memberof DroitsJsonldDroitReadCollection
     */
    '@id'?: any;
    /**
     * 
     * @type {any}
     * @memberof DroitsJsonldDroitReadCollection
     */
    '@type'?: any;
    /**
     * 
     * @type {any}
     * @memberof DroitsJsonldDroitReadCollection
     */
    'usdId'?: any;
    /**
     * 
     * @type {any}
     * @memberof DroitsJsonldDroitReadCollection
     */
    'iduser'?: any;
    /**
     * 
     * @type {any}
     * @memberof DroitsJsonldDroitReadCollection
     */
    'usdAppsJalgest'?: any;
    /**
     * 
     * @type {any}
     * @memberof DroitsJsonldDroitReadCollection
     */
    'usdAppsJalgestOption'?: any;
    /**
     * 
     * @type {any}
     * @memberof DroitsJsonldDroitReadCollection
     */
    'usdAppsXy'?: any;
    /**
     * 
     * @type {any}
     * @memberof DroitsJsonldDroitReadCollection
     */
    'usdAppsXyOption'?: any;
    /**
     * 
     * @type {any}
     * @memberof DroitsJsonldDroitReadCollection
     */
    'usdAppsMdc'?: any;
    /**
     * 
     * @type {any}
     * @memberof DroitsJsonldDroitReadCollection
     */
    'usdAppsMdcOption'?: any;
    /**
     * 
     * @type {any}
     * @memberof DroitsJsonldDroitReadCollection
     */
    'usdAppsMail'?: any;
    /**
     * 
     * @type {any}
     * @memberof DroitsJsonldDroitReadCollection
     */
    'usdAppsMailOption'?: any;
    /**
     * 
     * @type {any}
     * @memberof DroitsJsonldDroitReadCollection
     */
    'usdAppsAnalytics'?: any;
    /**
     * 
     * @type {any}
     * @memberof DroitsJsonldDroitReadCollection
     */
    'usdAppsAnalyticsOption'?: any;
    /**
     * 
     * @type {any}
     * @memberof DroitsJsonldDroitReadCollection
     */
    'usdAppsAnnuaire'?: any;
    /**
     * 
     * @type {any}
     * @memberof DroitsJsonldDroitReadCollection
     */
    'usdAppsAnnuaireOption'?: any;
    /**
     * 
     * @type {any}
     * @memberof DroitsJsonldDroitReadCollection
     */
    'usdAppsSupport'?: any;
    /**
     * 
     * @type {any}
     * @memberof DroitsJsonldDroitReadCollection
     */
    'usdAppsSupportOption'?: any;
    /**
     * 
     * @type {any}
     * @memberof DroitsJsonldDroitReadCollection
     */
    'usdAppsMdcSpel'?: any;
    /**
     * 
     * @type {any}
     * @memberof DroitsJsonldDroitReadCollection
     */
    'usdAppsMdcSpelOption'?: any;
    /**
     * 
     * @type {any}
     * @memberof DroitsJsonldDroitReadCollection
     */
    'usdAppsDocutech'?: any;
    /**
     * 
     * @type {any}
     * @memberof DroitsJsonldDroitReadCollection
     */
    'usdAppsDocutechOption'?: any;
    /**
     * 
     * @type {any}
     * @memberof DroitsJsonldDroitReadCollection
     */
    'usdAppsDocutechBackoffice'?: any;
    /**
     * 
     * @type {any}
     * @memberof DroitsJsonldDroitReadCollection
     */
    'usdAppsGestAbonnes'?: any;
}
/**
 * Returns the groups collection
 * @export
 * @interface GroupesGroupReadCollection
 */
export interface GroupesGroupReadCollection {
    /**
     * 
     * @type {any}
     * @memberof GroupesGroupReadCollection
     */
    'id'?: any;
    /**
     * 
     * @type {any}
     * @memberof GroupesGroupReadCollection
     */
    'groupName'?: any;
    /**
     * 
     * @type {any}
     * @memberof GroupesGroupReadCollection
     */
    'note'?: any;
    /**
     * 
     * @type {any}
     * @memberof GroupesGroupReadCollection
     */
    'flagDelete'?: any;
    /**
     * 
     * @type {any}
     * @memberof GroupesGroupReadCollection
     */
    'userMaj'?: any;
    /**
     * 
     * @type {any}
     * @memberof GroupesGroupReadCollection
     */
    'dateMaj'?: any;
    /**
     * 
     * @type {any}
     * @memberof GroupesGroupReadCollection
     */
    'group'?: any;
}
/**
 * Returns the groups collection
 * @export
 * @interface GroupesJsonldGroupReadCollection
 */
export interface GroupesJsonldGroupReadCollection {
    /**
     * 
     * @type {any}
     * @memberof GroupesJsonldGroupReadCollection
     */
    '@id'?: any;
    /**
     * 
     * @type {any}
     * @memberof GroupesJsonldGroupReadCollection
     */
    '@type'?: any;
    /**
     * 
     * @type {any}
     * @memberof GroupesJsonldGroupReadCollection
     */
    'id'?: any;
    /**
     * 
     * @type {any}
     * @memberof GroupesJsonldGroupReadCollection
     */
    'groupName'?: any;
    /**
     * 
     * @type {any}
     * @memberof GroupesJsonldGroupReadCollection
     */
    'note'?: any;
    /**
     * 
     * @type {any}
     * @memberof GroupesJsonldGroupReadCollection
     */
    'flagDelete'?: any;
    /**
     * 
     * @type {any}
     * @memberof GroupesJsonldGroupReadCollection
     */
    'userMaj'?: any;
    /**
     * 
     * @type {any}
     * @memberof GroupesJsonldGroupReadCollection
     */
    'dateMaj'?: any;
    /**
     * 
     * @type {any}
     * @memberof GroupesJsonldGroupReadCollection
     */
    'group'?: any;
}
/**
 * Returns all resources from Tbl_Utilisateur_Journaux
 * @export
 * @interface JournauxJournauxReadCollection
 */
export interface JournauxJournauxReadCollection {
    /**
     * 
     * @type {any}
     * @memberof JournauxJournauxReadCollection
     */
    'id'?: any;
    /**
     * 
     * @type {any}
     * @memberof JournauxJournauxReadCollection
     */
    'name'?: any;
}
/**
 * Returns all resources from Tbl_Utilisateur_Journaux
 * @export
 * @interface JournauxJsonldJournauxReadCollection
 */
export interface JournauxJsonldJournauxReadCollection {
    /**
     * 
     * @type {any}
     * @memberof JournauxJsonldJournauxReadCollection
     */
    '@id'?: any;
    /**
     * 
     * @type {any}
     * @memberof JournauxJsonldJournauxReadCollection
     */
    '@type'?: any;
    /**
     * 
     * @type {any}
     * @memberof JournauxJsonldJournauxReadCollection
     */
    'id'?: any;
    /**
     * 
     * @type {any}
     * @memberof JournauxJsonldJournauxReadCollection
     */
    'name'?: any;
}
/**
 * Returns the society\'s brands list
 * @export
 * @interface MarquesJsonldSocieteMarqueReadCollection
 */
export interface MarquesJsonldSocieteMarqueReadCollection {
    /**
     * 
     * @type {any}
     * @memberof MarquesJsonldSocieteMarqueReadCollection
     */
    '@id'?: any;
    /**
     * 
     * @type {any}
     * @memberof MarquesJsonldSocieteMarqueReadCollection
     */
    '@type'?: any;
    /**
     * 
     * @type {any}
     * @memberof MarquesJsonldSocieteMarqueReadCollection
     */
    'idMarque'?: any;
    /**
     * 
     * @type {any}
     * @memberof MarquesJsonldSocieteMarqueReadCollection
     */
    'idSociete'?: any;
    /**
     * 
     * @type {any}
     * @memberof MarquesJsonldSocieteMarqueReadCollection
     */
    'libelle'?: any;
    /**
     * 
     * @type {any}
     * @memberof MarquesJsonldSocieteMarqueReadCollection
     */
    'codeMarque'?: any;
    /**
     * 
     * @type {any}
     * @memberof MarquesJsonldSocieteMarqueReadCollection
     */
    'userCreation'?: any;
    /**
     * 
     * @type {any}
     * @memberof MarquesJsonldSocieteMarqueReadCollection
     */
    'dateCreation'?: any;
    /**
     * 
     * @type {any}
     * @memberof MarquesJsonldSocieteMarqueReadCollection
     */
    'flagDelete'?: any;
    /**
     * 
     * @type {any}
     * @memberof MarquesJsonldSocieteMarqueReadCollection
     */
    'dateDelete'?: any;
    /**
     * 
     * @type {any}
     * @memberof MarquesJsonldSocieteMarqueReadCollection
     */
    'userDelete'?: any;
    /**
     * 
     * @type {any}
     * @memberof MarquesJsonldSocieteMarqueReadCollection
     */
    'piedDePage'?: any;
}
/**
 * Returns the society\'s brands list
 * @export
 * @interface MarquesSocieteMarqueReadCollection
 */
export interface MarquesSocieteMarqueReadCollection {
    /**
     * 
     * @type {any}
     * @memberof MarquesSocieteMarqueReadCollection
     */
    'idMarque'?: any;
    /**
     * 
     * @type {any}
     * @memberof MarquesSocieteMarqueReadCollection
     */
    'idSociete'?: any;
    /**
     * 
     * @type {any}
     * @memberof MarquesSocieteMarqueReadCollection
     */
    'libelle'?: any;
    /**
     * 
     * @type {any}
     * @memberof MarquesSocieteMarqueReadCollection
     */
    'codeMarque'?: any;
    /**
     * 
     * @type {any}
     * @memberof MarquesSocieteMarqueReadCollection
     */
    'userCreation'?: any;
    /**
     * 
     * @type {any}
     * @memberof MarquesSocieteMarqueReadCollection
     */
    'dateCreation'?: any;
    /**
     * 
     * @type {any}
     * @memberof MarquesSocieteMarqueReadCollection
     */
    'flagDelete'?: any;
    /**
     * 
     * @type {any}
     * @memberof MarquesSocieteMarqueReadCollection
     */
    'dateDelete'?: any;
    /**
     * 
     * @type {any}
     * @memberof MarquesSocieteMarqueReadCollection
     */
    'userDelete'?: any;
    /**
     * 
     * @type {any}
     * @memberof MarquesSocieteMarqueReadCollection
     */
    'piedDePage'?: any;
}
/**
 * Returns the TBL poles collection
 * @export
 * @interface PolesDefPoleReadCollection
 */
export interface PolesDefPoleReadCollection {
    /**
     * 
     * @type {any}
     * @memberof PolesDefPoleReadCollection
     */
    'id'?: any;
    /**
     * 
     * @type {any}
     * @memberof PolesDefPoleReadCollection
     */
    'libelle'?: any;
    /**
     * 
     * @type {any}
     * @memberof PolesDefPoleReadCollection
     */
    'info'?: any;
}
/**
 * Returns the TBL poles collection
 * @export
 * @interface PolesJsonldDefPoleReadCollection
 */
export interface PolesJsonldDefPoleReadCollection {
    /**
     * 
     * @type {any}
     * @memberof PolesJsonldDefPoleReadCollection
     */
    '@id'?: any;
    /**
     * 
     * @type {any}
     * @memberof PolesJsonldDefPoleReadCollection
     */
    '@type'?: any;
    /**
     * 
     * @type {any}
     * @memberof PolesJsonldDefPoleReadCollection
     */
    'id'?: any;
    /**
     * 
     * @type {any}
     * @memberof PolesJsonldDefPoleReadCollection
     */
    'libelle'?: any;
    /**
     * 
     * @type {any}
     * @memberof PolesJsonldDefPoleReadCollection
     */
    'info'?: any;
}
/**
 * Returns all resources from Tbl_Def_Service
 * @export
 * @interface ServicesJsonldServiceReadCollection
 */
export interface ServicesJsonldServiceReadCollection {
    /**
     * 
     * @type {any}
     * @memberof ServicesJsonldServiceReadCollection
     */
    '@id'?: any;
    /**
     * 
     * @type {any}
     * @memberof ServicesJsonldServiceReadCollection
     */
    '@type'?: any;
    /**
     * 
     * @type {any}
     * @memberof ServicesJsonldServiceReadCollection
     */
    'id'?: any;
    /**
     * 
     * @type {any}
     * @memberof ServicesJsonldServiceReadCollection
     */
    'libelle'?: any;
    /**
     * 
     * @type {any}
     * @memberof ServicesJsonldServiceReadCollection
     */
    'info'?: any;
}
/**
 * Returns all resources from Tbl_Def_Service
 * @export
 * @interface ServicesServiceReadCollection
 */
export interface ServicesServiceReadCollection {
    /**
     * 
     * @type {any}
     * @memberof ServicesServiceReadCollection
     */
    'id'?: any;
    /**
     * 
     * @type {any}
     * @memberof ServicesServiceReadCollection
     */
    'libelle'?: any;
    /**
     * 
     * @type {any}
     * @memberof ServicesServiceReadCollection
     */
    'info'?: any;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {any}
     * @memberof User
     */
    'id'?: any;
    /**
     * 
     * @type {any}
     * @memberof User
     */
    'username'?: any;
    /**
     * 
     * @type {any}
     * @memberof User
     */
    'enabled'?: any;
    /**
     * 
     * @type {any}
     * @memberof User
     */
    'emailVerified'?: any;
    /**
     * 
     * @type {any}
     * @memberof User
     */
    'firstName'?: any;
    /**
     * 
     * @type {any}
     * @memberof User
     */
    'lastName'?: any;
    /**
     * 
     * @type {any}
     * @memberof User
     */
    'email'?: any;
    /**
     * 
     * @type {any}
     * @memberof User
     */
    'idPrescripteur'?: any;
}
/**
 * 
 * @export
 * @interface UserCreateUser
 */
export interface UserCreateUser {
    /**
     * 
     * @type {any}
     * @memberof UserCreateUser
     */
    'username': any;
    /**
     * 
     * @type {any}
     * @memberof UserCreateUser
     */
    'enabled': any;
    /**
     * 
     * @type {any}
     * @memberof UserCreateUser
     */
    'emailVerified': any;
    /**
     * 
     * @type {any}
     * @memberof UserCreateUser
     */
    'firstName'?: any;
    /**
     * 
     * @type {any}
     * @memberof UserCreateUser
     */
    'lastName'?: any;
    /**
     * 
     * @type {any}
     * @memberof UserCreateUser
     */
    'email': any;
    /**
     * 
     * @type {any}
     * @memberof UserCreateUser
     */
    'password': any;
    /**
     * 
     * @type {any}
     * @memberof UserCreateUser
     */
    'idPrescripteur': any;
}
/**
 * 
 * @export
 * @interface UserCreateUserJsonld
 */
export interface UserCreateUserJsonld {
    /**
     * 
     * @type {any}
     * @memberof UserCreateUserJsonld
     */
    'username': any;
    /**
     * 
     * @type {any}
     * @memberof UserCreateUserJsonld
     */
    'enabled': any;
    /**
     * 
     * @type {any}
     * @memberof UserCreateUserJsonld
     */
    'emailVerified': any;
    /**
     * 
     * @type {any}
     * @memberof UserCreateUserJsonld
     */
    'firstName'?: any;
    /**
     * 
     * @type {any}
     * @memberof UserCreateUserJsonld
     */
    'lastName'?: any;
    /**
     * 
     * @type {any}
     * @memberof UserCreateUserJsonld
     */
    'email': any;
    /**
     * 
     * @type {any}
     * @memberof UserCreateUserJsonld
     */
    'password': any;
    /**
     * 
     * @type {any}
     * @memberof UserCreateUserJsonld
     */
    'idPrescripteur': any;
}
/**
 * 
 * @export
 * @interface UserJsonld
 */
export interface UserJsonld {
    /**
     * 
     * @type {any}
     * @memberof UserJsonld
     */
    '@context'?: any;
    /**
     * 
     * @type {any}
     * @memberof UserJsonld
     */
    '@id'?: any;
    /**
     * 
     * @type {any}
     * @memberof UserJsonld
     */
    '@type'?: any;
    /**
     * 
     * @type {any}
     * @memberof UserJsonld
     */
    'id'?: any;
    /**
     * 
     * @type {any}
     * @memberof UserJsonld
     */
    'username'?: any;
    /**
     * 
     * @type {any}
     * @memberof UserJsonld
     */
    'enabled'?: any;
    /**
     * 
     * @type {any}
     * @memberof UserJsonld
     */
    'emailVerified'?: any;
    /**
     * 
     * @type {any}
     * @memberof UserJsonld
     */
    'firstName'?: any;
    /**
     * 
     * @type {any}
     * @memberof UserJsonld
     */
    'lastName'?: any;
    /**
     * 
     * @type {any}
     * @memberof UserJsonld
     */
    'email'?: any;
    /**
     * 
     * @type {any}
     * @memberof UserJsonld
     */
    'idPrescripteur'?: any;
}
/**
 * 
 * @export
 * @interface UserUpdateUser
 */
export interface UserUpdateUser {
    /**
     * 
     * @type {any}
     * @memberof UserUpdateUser
     */
    'enabled'?: any;
    /**
     * 
     * @type {any}
     * @memberof UserUpdateUser
     */
    'emailVerified'?: any;
    /**
     * 
     * @type {any}
     * @memberof UserUpdateUser
     */
    'firstName'?: any;
    /**
     * 
     * @type {any}
     * @memberof UserUpdateUser
     */
    'lastName'?: any;
    /**
     * 
     * @type {any}
     * @memberof UserUpdateUser
     */
    'email'?: any;
    /**
     * 
     * @type {any}
     * @memberof UserUpdateUser
     */
    'idPrescripteur'?: any;
}
/**
 * Returns users collection
 * @export
 * @interface UtilisateursJsonldUtilisateurReadCollection
 */
export interface UtilisateursJsonldUtilisateurReadCollection {
    /**
     * 
     * @type {any}
     * @memberof UtilisateursJsonldUtilisateurReadCollection
     */
    '@id'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursJsonldUtilisateurReadCollection
     */
    '@type'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursJsonldUtilisateurReadCollection
     */
    'id'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursJsonldUtilisateurReadCollection
     */
    'nom'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursJsonldUtilisateurReadCollection
     */
    'prenom'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursJsonldUtilisateurReadCollection
     */
    'login'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursJsonldUtilisateurReadCollection
     */
    'idUserGroupe'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursJsonldUtilisateurReadCollection
     */
    'initiales'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursJsonldUtilisateurReadCollection
     */
    'isDeleted'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursJsonldUtilisateurReadCollection
     */
    'user'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursJsonldUtilisateurReadCollection
     */
    'userMaj'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursJsonldUtilisateurReadCollection
     */
    'dateMaj'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursJsonldUtilisateurReadCollection
     */
    'poste'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursJsonldUtilisateurReadCollection
     */
    'ligneDirecte'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursJsonldUtilisateurReadCollection
     */
    'mail'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursJsonldUtilisateurReadCollection
     */
    'pc'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursJsonldUtilisateurReadCollection
     */
    'eleveur'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursJsonldUtilisateurReadCollection
     */
    'hasG3Access'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursJsonldUtilisateurReadCollection
     */
    'formalistDemo'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursJsonldUtilisateurReadCollection
     */
    'formalistAdmin'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursJsonldUtilisateurReadCollection
     */
    'formalistDroitModifDossier'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursJsonldUtilisateurReadCollection
     */
    'formalistDroitLectureDossier'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursJsonldUtilisateurReadCollection
     */
    'formalistDroitModifGestion'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursJsonldUtilisateurReadCollection
     */
    'formalistDroitLectureGestion'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursJsonldUtilisateurReadCollection
     */
    'formalistDroitSupprDossier'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursJsonldUtilisateurReadCollection
     */
    'isFormalist'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursJsonldUtilisateurReadCollection
     */
    'idSociete'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursJsonldUtilisateurReadCollection
     */
    'poleId'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursJsonldUtilisateurReadCollection
     */
    'serviceId'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursJsonldUtilisateurReadCollection
     */
    'raccourciG3Id'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursJsonldUtilisateurReadCollection
     */
    'portable'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursJsonldUtilisateurReadCollection
     */
    'fonction'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursJsonldUtilisateurReadCollection
     */
    'hasVpn'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursJsonldUtilisateurReadCollection
     */
    'isG3Recetteur'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursJsonldUtilisateurReadCollection
     */
    'formalistDroitMultiSociete'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursJsonldUtilisateurReadCollection
     */
    'idUserG3'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursJsonldUtilisateurReadCollection
     */
    'hasComptaSoftware'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursJsonldUtilisateurReadCollection
     */
    'dematFacile'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursJsonldUtilisateurReadCollection
     */
    'idUserDematfacile'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursJsonldUtilisateurReadCollection
     */
    'idMarque'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursJsonldUtilisateurReadCollection
     */
    'hasAccessL2DFormalite'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursJsonldUtilisateurReadCollection
     */
    'hasAnnuaire'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursJsonldUtilisateurReadCollection
     */
    'isAdminApps'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursJsonldUtilisateurReadCollection
     */
    'idGroupeGie'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursJsonldUtilisateurReadCollection
     */
    'binome'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursJsonldUtilisateurReadCollection
     */
    'idSocieteEmployeur'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursJsonldUtilisateurReadCollection
     */
    'idAntenne'?: any;
}
/**
 * Returns users collection
 * @export
 * @interface UtilisateursUtilisateurReadCollection
 */
export interface UtilisateursUtilisateurReadCollection {
    /**
     * 
     * @type {any}
     * @memberof UtilisateursUtilisateurReadCollection
     */
    'id'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursUtilisateurReadCollection
     */
    'nom'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursUtilisateurReadCollection
     */
    'prenom'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursUtilisateurReadCollection
     */
    'login'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursUtilisateurReadCollection
     */
    'idUserGroupe'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursUtilisateurReadCollection
     */
    'initiales'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursUtilisateurReadCollection
     */
    'isDeleted'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursUtilisateurReadCollection
     */
    'user'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursUtilisateurReadCollection
     */
    'userMaj'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursUtilisateurReadCollection
     */
    'dateMaj'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursUtilisateurReadCollection
     */
    'poste'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursUtilisateurReadCollection
     */
    'ligneDirecte'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursUtilisateurReadCollection
     */
    'mail'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursUtilisateurReadCollection
     */
    'pc'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursUtilisateurReadCollection
     */
    'eleveur'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursUtilisateurReadCollection
     */
    'hasG3Access'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursUtilisateurReadCollection
     */
    'formalistDemo'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursUtilisateurReadCollection
     */
    'formalistAdmin'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursUtilisateurReadCollection
     */
    'formalistDroitModifDossier'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursUtilisateurReadCollection
     */
    'formalistDroitLectureDossier'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursUtilisateurReadCollection
     */
    'formalistDroitModifGestion'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursUtilisateurReadCollection
     */
    'formalistDroitLectureGestion'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursUtilisateurReadCollection
     */
    'formalistDroitSupprDossier'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursUtilisateurReadCollection
     */
    'isFormalist'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursUtilisateurReadCollection
     */
    'idSociete'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursUtilisateurReadCollection
     */
    'poleId'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursUtilisateurReadCollection
     */
    'serviceId'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursUtilisateurReadCollection
     */
    'raccourciG3Id'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursUtilisateurReadCollection
     */
    'portable'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursUtilisateurReadCollection
     */
    'fonction'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursUtilisateurReadCollection
     */
    'hasVpn'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursUtilisateurReadCollection
     */
    'isG3Recetteur'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursUtilisateurReadCollection
     */
    'formalistDroitMultiSociete'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursUtilisateurReadCollection
     */
    'idUserG3'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursUtilisateurReadCollection
     */
    'hasComptaSoftware'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursUtilisateurReadCollection
     */
    'dematFacile'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursUtilisateurReadCollection
     */
    'idUserDematfacile'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursUtilisateurReadCollection
     */
    'idMarque'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursUtilisateurReadCollection
     */
    'hasAccessL2DFormalite'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursUtilisateurReadCollection
     */
    'hasAnnuaire'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursUtilisateurReadCollection
     */
    'isAdminApps'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursUtilisateurReadCollection
     */
    'idGroupeGie'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursUtilisateurReadCollection
     */
    'binome'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursUtilisateurReadCollection
     */
    'idSocieteEmployeur'?: any;
    /**
     * 
     * @type {any}
     * @memberof UtilisateursUtilisateurReadCollection
     */
    'idAntenne'?: any;
}

/**
 * DroitsApi - axios parameter creator
 * @export
 */
export const DroitsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns all resources from Tbl_Utilisateur_Droit
         * @summary Returns all resources from Tbl_Utilisateur_Droit
         * @param {any} [page] The collection page number
         * @param {any} [itemsPerPage] The number of items per page
         * @param {any} [pagination] Enable or disable pagination
         * @param {any} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDroitGetCollection: async (page?: any, itemsPerPage?: any, pagination?: any, xIdSociete?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/utilisateur/droit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (pagination !== undefined) {
                localVarQueryParameter['pagination'] = pagination;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DroitsApi - functional programming interface
 * @export
 */
export const DroitsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DroitsApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns all resources from Tbl_Utilisateur_Droit
         * @summary Returns all resources from Tbl_Utilisateur_Droit
         * @param {any} [page] The collection page number
         * @param {any} [itemsPerPage] The number of items per page
         * @param {any} [pagination] Enable or disable pagination
         * @param {any} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDroitGetCollection(page?: any, itemsPerPage?: any, pagination?: any, xIdSociete?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiDroitGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDroitGetCollection(page, itemsPerPage, pagination, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DroitsApi - factory interface
 * @export
 */
export const DroitsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DroitsApiFp(configuration)
    return {
        /**
         * Returns all resources from Tbl_Utilisateur_Droit
         * @summary Returns all resources from Tbl_Utilisateur_Droit
         * @param {DroitsApiApiDroitGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDroitGetCollection(requestParameters: DroitsApiApiDroitGetCollectionRequest = {}, options?: AxiosRequestConfig): AxiosPromise<ApiDroitGetCollection200Response> {
            return localVarFp.apiDroitGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.pagination, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiDroitGetCollection operation in DroitsApi.
 * @export
 * @interface DroitsApiApiDroitGetCollectionRequest
 */
export interface DroitsApiApiDroitGetCollectionRequest {
    /**
     * The collection page number
     * @type {any}
     * @memberof DroitsApiApiDroitGetCollection
     */
    readonly page?: any

    /**
     * The number of items per page
     * @type {any}
     * @memberof DroitsApiApiDroitGetCollection
     */
    readonly itemsPerPage?: any

    /**
     * Enable or disable pagination
     * @type {any}
     * @memberof DroitsApiApiDroitGetCollection
     */
    readonly pagination?: any

    /**
     * Identifiant de la société
     * @type {any}
     * @memberof DroitsApiApiDroitGetCollection
     */
    readonly xIdSociete?: any
}

/**
 * DroitsApi - object-oriented interface
 * @export
 * @class DroitsApi
 * @extends {BaseAPI}
 */
export class DroitsApi extends BaseAPI {
    /**
     * Returns all resources from Tbl_Utilisateur_Droit
     * @summary Returns all resources from Tbl_Utilisateur_Droit
     * @param {DroitsApiApiDroitGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DroitsApi
     */
    public apiDroitGetCollection(requestParameters: DroitsApiApiDroitGetCollectionRequest = {}, options?: AxiosRequestConfig) {
        return DroitsApiFp(this.configuration).apiDroitGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.pagination, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GroupesApi - axios parameter creator
 * @export
 */
export const GroupesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns the groups collection.
         * @summary Returns the groups collection.
         * @param {any} [page] The collection page number
         * @param {any} [itemsPerPage] The number of items per page
         * @param {any} [flagDelete] 
         * @param {any} [gieGroup] Filter by group id
         * @param {any} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGroupesGetCollection: async (page?: any, itemsPerPage?: any, flagDelete?: any, gieGroup?: any, xIdSociete?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/utilisateur/groupes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (flagDelete !== undefined) {
                localVarQueryParameter['flagDelete'] = flagDelete;
            }

            if (gieGroup !== undefined) {
                localVarQueryParameter['gieGroup'] = gieGroup;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GroupesApi - functional programming interface
 * @export
 */
export const GroupesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GroupesApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns the groups collection.
         * @summary Returns the groups collection.
         * @param {any} [page] The collection page number
         * @param {any} [itemsPerPage] The number of items per page
         * @param {any} [flagDelete] 
         * @param {any} [gieGroup] Filter by group id
         * @param {any} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGroupesGetCollection(page?: any, itemsPerPage?: any, flagDelete?: any, gieGroup?: any, xIdSociete?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGroupesGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGroupesGetCollection(page, itemsPerPage, flagDelete, gieGroup, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GroupesApi - factory interface
 * @export
 */
export const GroupesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GroupesApiFp(configuration)
    return {
        /**
         * Returns the groups collection.
         * @summary Returns the groups collection.
         * @param {GroupesApiApiGroupesGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGroupesGetCollection(requestParameters: GroupesApiApiGroupesGetCollectionRequest = {}, options?: AxiosRequestConfig): AxiosPromise<ApiGroupesGetCollection200Response> {
            return localVarFp.apiGroupesGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.flagDelete, requestParameters.gieGroup, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiGroupesGetCollection operation in GroupesApi.
 * @export
 * @interface GroupesApiApiGroupesGetCollectionRequest
 */
export interface GroupesApiApiGroupesGetCollectionRequest {
    /**
     * The collection page number
     * @type {any}
     * @memberof GroupesApiApiGroupesGetCollection
     */
    readonly page?: any

    /**
     * The number of items per page
     * @type {any}
     * @memberof GroupesApiApiGroupesGetCollection
     */
    readonly itemsPerPage?: any

    /**
     * 
     * @type {any}
     * @memberof GroupesApiApiGroupesGetCollection
     */
    readonly flagDelete?: any

    /**
     * Filter by group id
     * @type {any}
     * @memberof GroupesApiApiGroupesGetCollection
     */
    readonly gieGroup?: any

    /**
     * Identifiant de la société
     * @type {any}
     * @memberof GroupesApiApiGroupesGetCollection
     */
    readonly xIdSociete?: any
}

/**
 * GroupesApi - object-oriented interface
 * @export
 * @class GroupesApi
 * @extends {BaseAPI}
 */
export class GroupesApi extends BaseAPI {
    /**
     * Returns the groups collection.
     * @summary Returns the groups collection.
     * @param {GroupesApiApiGroupesGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupesApi
     */
    public apiGroupesGetCollection(requestParameters: GroupesApiApiGroupesGetCollectionRequest = {}, options?: AxiosRequestConfig) {
        return GroupesApiFp(this.configuration).apiGroupesGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.flagDelete, requestParameters.gieGroup, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * JournauxApi - axios parameter creator
 * @export
 */
export const JournauxApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns all resources from Tbl_Utilisateur_Journaux
         * @summary Returns all resources from Tbl_Utilisateur_Journaux
         * @param {any} [page] The collection page number
         * @param {any} [itemsPerPage] The number of items per page
         * @param {any} [pagination] Enable or disable pagination
         * @param {any} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiJournauxGetCollection: async (page?: any, itemsPerPage?: any, pagination?: any, xIdSociete?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/utilisateur/journaux`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (pagination !== undefined) {
                localVarQueryParameter['pagination'] = pagination;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * JournauxApi - functional programming interface
 * @export
 */
export const JournauxApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = JournauxApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns all resources from Tbl_Utilisateur_Journaux
         * @summary Returns all resources from Tbl_Utilisateur_Journaux
         * @param {any} [page] The collection page number
         * @param {any} [itemsPerPage] The number of items per page
         * @param {any} [pagination] Enable or disable pagination
         * @param {any} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiJournauxGetCollection(page?: any, itemsPerPage?: any, pagination?: any, xIdSociete?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiJournauxGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiJournauxGetCollection(page, itemsPerPage, pagination, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * JournauxApi - factory interface
 * @export
 */
export const JournauxApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = JournauxApiFp(configuration)
    return {
        /**
         * Returns all resources from Tbl_Utilisateur_Journaux
         * @summary Returns all resources from Tbl_Utilisateur_Journaux
         * @param {JournauxApiApiJournauxGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiJournauxGetCollection(requestParameters: JournauxApiApiJournauxGetCollectionRequest = {}, options?: AxiosRequestConfig): AxiosPromise<ApiJournauxGetCollection200Response> {
            return localVarFp.apiJournauxGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.pagination, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiJournauxGetCollection operation in JournauxApi.
 * @export
 * @interface JournauxApiApiJournauxGetCollectionRequest
 */
export interface JournauxApiApiJournauxGetCollectionRequest {
    /**
     * The collection page number
     * @type {any}
     * @memberof JournauxApiApiJournauxGetCollection
     */
    readonly page?: any

    /**
     * The number of items per page
     * @type {any}
     * @memberof JournauxApiApiJournauxGetCollection
     */
    readonly itemsPerPage?: any

    /**
     * Enable or disable pagination
     * @type {any}
     * @memberof JournauxApiApiJournauxGetCollection
     */
    readonly pagination?: any

    /**
     * Identifiant de la société
     * @type {any}
     * @memberof JournauxApiApiJournauxGetCollection
     */
    readonly xIdSociete?: any
}

/**
 * JournauxApi - object-oriented interface
 * @export
 * @class JournauxApi
 * @extends {BaseAPI}
 */
export class JournauxApi extends BaseAPI {
    /**
     * Returns all resources from Tbl_Utilisateur_Journaux
     * @summary Returns all resources from Tbl_Utilisateur_Journaux
     * @param {JournauxApiApiJournauxGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JournauxApi
     */
    public apiJournauxGetCollection(requestParameters: JournauxApiApiJournauxGetCollectionRequest = {}, options?: AxiosRequestConfig) {
        return JournauxApiFp(this.configuration).apiJournauxGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.pagination, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MarquesApi - axios parameter creator
 * @export
 */
export const MarquesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns the society\'s brands list.
         * @summary Returns the society\'s brands list.
         * @param {any} [page] The collection page number
         * @param {any} [itemsPerPage] The number of items per page
         * @param {any} [flagDelete] 
         * @param {any} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMarquesGetCollection: async (page?: any, itemsPerPage?: any, flagDelete?: any, xIdSociete?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/utilisateur/marques`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (flagDelete !== undefined) {
                localVarQueryParameter['flagDelete'] = flagDelete;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MarquesApi - functional programming interface
 * @export
 */
export const MarquesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MarquesApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns the society\'s brands list.
         * @summary Returns the society\'s brands list.
         * @param {any} [page] The collection page number
         * @param {any} [itemsPerPage] The number of items per page
         * @param {any} [flagDelete] 
         * @param {any} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMarquesGetCollection(page?: any, itemsPerPage?: any, flagDelete?: any, xIdSociete?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiMarquesGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMarquesGetCollection(page, itemsPerPage, flagDelete, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MarquesApi - factory interface
 * @export
 */
export const MarquesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MarquesApiFp(configuration)
    return {
        /**
         * Returns the society\'s brands list.
         * @summary Returns the society\'s brands list.
         * @param {MarquesApiApiMarquesGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMarquesGetCollection(requestParameters: MarquesApiApiMarquesGetCollectionRequest = {}, options?: AxiosRequestConfig): AxiosPromise<ApiMarquesGetCollection200Response> {
            return localVarFp.apiMarquesGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.flagDelete, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiMarquesGetCollection operation in MarquesApi.
 * @export
 * @interface MarquesApiApiMarquesGetCollectionRequest
 */
export interface MarquesApiApiMarquesGetCollectionRequest {
    /**
     * The collection page number
     * @type {any}
     * @memberof MarquesApiApiMarquesGetCollection
     */
    readonly page?: any

    /**
     * The number of items per page
     * @type {any}
     * @memberof MarquesApiApiMarquesGetCollection
     */
    readonly itemsPerPage?: any

    /**
     * 
     * @type {any}
     * @memberof MarquesApiApiMarquesGetCollection
     */
    readonly flagDelete?: any

    /**
     * Identifiant de la société
     * @type {any}
     * @memberof MarquesApiApiMarquesGetCollection
     */
    readonly xIdSociete?: any
}

/**
 * MarquesApi - object-oriented interface
 * @export
 * @class MarquesApi
 * @extends {BaseAPI}
 */
export class MarquesApi extends BaseAPI {
    /**
     * Returns the society\'s brands list.
     * @summary Returns the society\'s brands list.
     * @param {MarquesApiApiMarquesGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarquesApi
     */
    public apiMarquesGetCollection(requestParameters: MarquesApiApiMarquesGetCollectionRequest = {}, options?: AxiosRequestConfig) {
        return MarquesApiFp(this.configuration).apiMarquesGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.flagDelete, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PolesApi - axios parameter creator
 * @export
 */
export const PolesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns the TBL poles collection.
         * @summary Returns the TBL poles collection.
         * @param {any} [page] The collection page number
         * @param {any} [itemsPerPage] The number of items per page
         * @param {any} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPolesGetCollection: async (page?: any, itemsPerPage?: any, xIdSociete?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/utilisateur/poles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PolesApi - functional programming interface
 * @export
 */
export const PolesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PolesApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns the TBL poles collection.
         * @summary Returns the TBL poles collection.
         * @param {any} [page] The collection page number
         * @param {any} [itemsPerPage] The number of items per page
         * @param {any} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPolesGetCollection(page?: any, itemsPerPage?: any, xIdSociete?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiPolesGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPolesGetCollection(page, itemsPerPage, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PolesApi - factory interface
 * @export
 */
export const PolesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PolesApiFp(configuration)
    return {
        /**
         * Returns the TBL poles collection.
         * @summary Returns the TBL poles collection.
         * @param {PolesApiApiPolesGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPolesGetCollection(requestParameters: PolesApiApiPolesGetCollectionRequest = {}, options?: AxiosRequestConfig): AxiosPromise<ApiPolesGetCollection200Response> {
            return localVarFp.apiPolesGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiPolesGetCollection operation in PolesApi.
 * @export
 * @interface PolesApiApiPolesGetCollectionRequest
 */
export interface PolesApiApiPolesGetCollectionRequest {
    /**
     * The collection page number
     * @type {any}
     * @memberof PolesApiApiPolesGetCollection
     */
    readonly page?: any

    /**
     * The number of items per page
     * @type {any}
     * @memberof PolesApiApiPolesGetCollection
     */
    readonly itemsPerPage?: any

    /**
     * Identifiant de la société
     * @type {any}
     * @memberof PolesApiApiPolesGetCollection
     */
    readonly xIdSociete?: any
}

/**
 * PolesApi - object-oriented interface
 * @export
 * @class PolesApi
 * @extends {BaseAPI}
 */
export class PolesApi extends BaseAPI {
    /**
     * Returns the TBL poles collection.
     * @summary Returns the TBL poles collection.
     * @param {PolesApiApiPolesGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PolesApi
     */
    public apiPolesGetCollection(requestParameters: PolesApiApiPolesGetCollectionRequest = {}, options?: AxiosRequestConfig) {
        return PolesApiFp(this.configuration).apiPolesGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ServicesApi - axios parameter creator
 * @export
 */
export const ServicesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns all resources from Tbl_Def_Service
         * @summary Returns all resources from Tbl_Def_Service
         * @param {any} [page] The collection page number
         * @param {any} [itemsPerPage] The number of items per page
         * @param {any} [pagination] Enable or disable pagination
         * @param {any} [assignable] dsv_id 0 and 999 filter
         * @param {any} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesGetCollection: async (page?: any, itemsPerPage?: any, pagination?: any, assignable?: any, xIdSociete?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/utilisateur/services`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (pagination !== undefined) {
                localVarQueryParameter['pagination'] = pagination;
            }

            if (assignable !== undefined) {
                localVarQueryParameter['assignable'] = assignable;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ServicesApi - functional programming interface
 * @export
 */
export const ServicesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ServicesApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns all resources from Tbl_Def_Service
         * @summary Returns all resources from Tbl_Def_Service
         * @param {any} [page] The collection page number
         * @param {any} [itemsPerPage] The number of items per page
         * @param {any} [pagination] Enable or disable pagination
         * @param {any} [assignable] dsv_id 0 and 999 filter
         * @param {any} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiServicesGetCollection(page?: any, itemsPerPage?: any, pagination?: any, assignable?: any, xIdSociete?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiServicesGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiServicesGetCollection(page, itemsPerPage, pagination, assignable, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ServicesApi - factory interface
 * @export
 */
export const ServicesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ServicesApiFp(configuration)
    return {
        /**
         * Returns all resources from Tbl_Def_Service
         * @summary Returns all resources from Tbl_Def_Service
         * @param {ServicesApiApiServicesGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesGetCollection(requestParameters: ServicesApiApiServicesGetCollectionRequest = {}, options?: AxiosRequestConfig): AxiosPromise<ApiServicesGetCollection200Response> {
            return localVarFp.apiServicesGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.pagination, requestParameters.assignable, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiServicesGetCollection operation in ServicesApi.
 * @export
 * @interface ServicesApiApiServicesGetCollectionRequest
 */
export interface ServicesApiApiServicesGetCollectionRequest {
    /**
     * The collection page number
     * @type {any}
     * @memberof ServicesApiApiServicesGetCollection
     */
    readonly page?: any

    /**
     * The number of items per page
     * @type {any}
     * @memberof ServicesApiApiServicesGetCollection
     */
    readonly itemsPerPage?: any

    /**
     * Enable or disable pagination
     * @type {any}
     * @memberof ServicesApiApiServicesGetCollection
     */
    readonly pagination?: any

    /**
     * dsv_id 0 and 999 filter
     * @type {any}
     * @memberof ServicesApiApiServicesGetCollection
     */
    readonly assignable?: any

    /**
     * Identifiant de la société
     * @type {any}
     * @memberof ServicesApiApiServicesGetCollection
     */
    readonly xIdSociete?: any
}

/**
 * ServicesApi - object-oriented interface
 * @export
 * @class ServicesApi
 * @extends {BaseAPI}
 */
export class ServicesApi extends BaseAPI {
    /**
     * Returns all resources from Tbl_Def_Service
     * @summary Returns all resources from Tbl_Def_Service
     * @param {ServicesApiApiServicesGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServicesApi
     */
    public apiServicesGetCollection(requestParameters: ServicesApiApiServicesGetCollectionRequest = {}, options?: AxiosRequestConfig) {
        return ServicesApiFp(this.configuration).apiServicesGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.pagination, requestParameters.assignable, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Updates the User resource.
         * @summary Updates the User resource.
         * @param {any} id User identifier
         * @param {UserUpdateUser} userUpdateUser The updated User resource
         * @param {any} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKeycloakUtilisateurIdPatch: async (id: any, userUpdateUser: UserUpdateUser, xIdSociete?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiKeycloakUtilisateurIdPatch', 'id', id)
            // verify required parameter 'userUpdateUser' is not null or undefined
            assertParamExists('apiKeycloakUtilisateurIdPatch', 'userUpdateUser', userUpdateUser)
            const localVarPath = `/utilisateur/keycloak-utilisateur/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userUpdateUser, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a User resource.
         * @summary Creates a User resource.
         * @param {UserCreateUserJsonld} userCreateUserJsonld The new User resource
         * @param {any} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKeycloakUtilisateurPost: async (userCreateUserJsonld: UserCreateUserJsonld, xIdSociete?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userCreateUserJsonld' is not null or undefined
            assertParamExists('apiKeycloakUtilisateurPost', 'userCreateUserJsonld', userCreateUserJsonld)
            const localVarPath = `/utilisateur/keycloak-utilisateur`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userCreateUserJsonld, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the collection of User resources.
         * @summary Retrieves the collection of User resources.
         * @param {any} prescripteurId User identifier
         * @param {any} [page] The collection page number
         * @param {any} [itemsPerPage] The number of items per page
         * @param {any} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKeycloakUtilisateursPrescripteurIdGetCollection: async (prescripteurId: any, page?: any, itemsPerPage?: any, xIdSociete?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'prescripteurId' is not null or undefined
            assertParamExists('apiKeycloakUtilisateursPrescripteurIdGetCollection', 'prescripteurId', prescripteurId)
            const localVarPath = `/utilisateur/keycloak-utilisateurs/{prescripteurId}`
                .replace(`{${"prescripteurId"}}`, encodeURIComponent(String(prescripteurId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * Updates the User resource.
         * @summary Updates the User resource.
         * @param {any} id User identifier
         * @param {UserUpdateUser} userUpdateUser The updated User resource
         * @param {any} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKeycloakUtilisateurIdPatch(id: any, userUpdateUser: UserUpdateUser, xIdSociete?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKeycloakUtilisateurIdPatch(id, userUpdateUser, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a User resource.
         * @summary Creates a User resource.
         * @param {UserCreateUserJsonld} userCreateUserJsonld The new User resource
         * @param {any} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKeycloakUtilisateurPost(userCreateUserJsonld: UserCreateUserJsonld, xIdSociete?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKeycloakUtilisateurPost(userCreateUserJsonld, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the collection of User resources.
         * @summary Retrieves the collection of User resources.
         * @param {any} prescripteurId User identifier
         * @param {any} [page] The collection page number
         * @param {any} [itemsPerPage] The number of items per page
         * @param {any} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKeycloakUtilisateursPrescripteurIdGetCollection(prescripteurId: any, page?: any, itemsPerPage?: any, xIdSociete?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiKeycloakUtilisateursPrescripteurIdGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKeycloakUtilisateursPrescripteurIdGetCollection(prescripteurId, page, itemsPerPage, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * Updates the User resource.
         * @summary Updates the User resource.
         * @param {UserApiApiKeycloakUtilisateurIdPatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKeycloakUtilisateurIdPatch(requestParameters: UserApiApiKeycloakUtilisateurIdPatchRequest, options?: AxiosRequestConfig): AxiosPromise<UserJsonld> {
            return localVarFp.apiKeycloakUtilisateurIdPatch(requestParameters.id, requestParameters.userUpdateUser, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a User resource.
         * @summary Creates a User resource.
         * @param {UserApiApiKeycloakUtilisateurPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKeycloakUtilisateurPost(requestParameters: UserApiApiKeycloakUtilisateurPostRequest, options?: AxiosRequestConfig): AxiosPromise<UserJsonld> {
            return localVarFp.apiKeycloakUtilisateurPost(requestParameters.userCreateUserJsonld, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the collection of User resources.
         * @summary Retrieves the collection of User resources.
         * @param {UserApiApiKeycloakUtilisateursPrescripteurIdGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKeycloakUtilisateursPrescripteurIdGetCollection(requestParameters: UserApiApiKeycloakUtilisateursPrescripteurIdGetCollectionRequest, options?: AxiosRequestConfig): AxiosPromise<ApiKeycloakUtilisateursPrescripteurIdGetCollection200Response> {
            return localVarFp.apiKeycloakUtilisateursPrescripteurIdGetCollection(requestParameters.prescripteurId, requestParameters.page, requestParameters.itemsPerPage, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiKeycloakUtilisateurIdPatch operation in UserApi.
 * @export
 * @interface UserApiApiKeycloakUtilisateurIdPatchRequest
 */
export interface UserApiApiKeycloakUtilisateurIdPatchRequest {
    /**
     * User identifier
     * @type {any}
     * @memberof UserApiApiKeycloakUtilisateurIdPatch
     */
    readonly id: any

    /**
     * The updated User resource
     * @type {UserUpdateUser}
     * @memberof UserApiApiKeycloakUtilisateurIdPatch
     */
    readonly userUpdateUser: UserUpdateUser

    /**
     * Identifiant de la société
     * @type {any}
     * @memberof UserApiApiKeycloakUtilisateurIdPatch
     */
    readonly xIdSociete?: any
}

/**
 * Request parameters for apiKeycloakUtilisateurPost operation in UserApi.
 * @export
 * @interface UserApiApiKeycloakUtilisateurPostRequest
 */
export interface UserApiApiKeycloakUtilisateurPostRequest {
    /**
     * The new User resource
     * @type {UserCreateUserJsonld}
     * @memberof UserApiApiKeycloakUtilisateurPost
     */
    readonly userCreateUserJsonld: UserCreateUserJsonld

    /**
     * Identifiant de la société
     * @type {any}
     * @memberof UserApiApiKeycloakUtilisateurPost
     */
    readonly xIdSociete?: any
}

/**
 * Request parameters for apiKeycloakUtilisateursPrescripteurIdGetCollection operation in UserApi.
 * @export
 * @interface UserApiApiKeycloakUtilisateursPrescripteurIdGetCollectionRequest
 */
export interface UserApiApiKeycloakUtilisateursPrescripteurIdGetCollectionRequest {
    /**
     * User identifier
     * @type {any}
     * @memberof UserApiApiKeycloakUtilisateursPrescripteurIdGetCollection
     */
    readonly prescripteurId: any

    /**
     * The collection page number
     * @type {any}
     * @memberof UserApiApiKeycloakUtilisateursPrescripteurIdGetCollection
     */
    readonly page?: any

    /**
     * The number of items per page
     * @type {any}
     * @memberof UserApiApiKeycloakUtilisateursPrescripteurIdGetCollection
     */
    readonly itemsPerPage?: any

    /**
     * Identifiant de la société
     * @type {any}
     * @memberof UserApiApiKeycloakUtilisateursPrescripteurIdGetCollection
     */
    readonly xIdSociete?: any
}

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * Updates the User resource.
     * @summary Updates the User resource.
     * @param {UserApiApiKeycloakUtilisateurIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiKeycloakUtilisateurIdPatch(requestParameters: UserApiApiKeycloakUtilisateurIdPatchRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiKeycloakUtilisateurIdPatch(requestParameters.id, requestParameters.userUpdateUser, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a User resource.
     * @summary Creates a User resource.
     * @param {UserApiApiKeycloakUtilisateurPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiKeycloakUtilisateurPost(requestParameters: UserApiApiKeycloakUtilisateurPostRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiKeycloakUtilisateurPost(requestParameters.userCreateUserJsonld, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the collection of User resources.
     * @summary Retrieves the collection of User resources.
     * @param {UserApiApiKeycloakUtilisateursPrescripteurIdGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiKeycloakUtilisateursPrescripteurIdGetCollection(requestParameters: UserApiApiKeycloakUtilisateursPrescripteurIdGetCollectionRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiKeycloakUtilisateursPrescripteurIdGetCollection(requestParameters.prescripteurId, requestParameters.page, requestParameters.itemsPerPage, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UtilisateursApi - axios parameter creator
 * @export
 */
export const UtilisateursApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns users collection
         * @summary Returns users collection
         * @param {any} [page] The collection page number
         * @param {any} [itemsPerPage] The number of items per page
         * @param {any} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUtilisateursGetCollection: async (page?: any, itemsPerPage?: any, xIdSociete?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/utilisateur/utilisateurs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UtilisateursApi - functional programming interface
 * @export
 */
export const UtilisateursApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UtilisateursApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns users collection
         * @summary Returns users collection
         * @param {any} [page] The collection page number
         * @param {any} [itemsPerPage] The number of items per page
         * @param {any} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUtilisateursGetCollection(page?: any, itemsPerPage?: any, xIdSociete?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiUtilisateursGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUtilisateursGetCollection(page, itemsPerPage, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UtilisateursApi - factory interface
 * @export
 */
export const UtilisateursApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UtilisateursApiFp(configuration)
    return {
        /**
         * Returns users collection
         * @summary Returns users collection
         * @param {UtilisateursApiApiUtilisateursGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUtilisateursGetCollection(requestParameters: UtilisateursApiApiUtilisateursGetCollectionRequest = {}, options?: AxiosRequestConfig): AxiosPromise<ApiUtilisateursGetCollection200Response> {
            return localVarFp.apiUtilisateursGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiUtilisateursGetCollection operation in UtilisateursApi.
 * @export
 * @interface UtilisateursApiApiUtilisateursGetCollectionRequest
 */
export interface UtilisateursApiApiUtilisateursGetCollectionRequest {
    /**
     * The collection page number
     * @type {any}
     * @memberof UtilisateursApiApiUtilisateursGetCollection
     */
    readonly page?: any

    /**
     * The number of items per page
     * @type {any}
     * @memberof UtilisateursApiApiUtilisateursGetCollection
     */
    readonly itemsPerPage?: any

    /**
     * Identifiant de la société
     * @type {any}
     * @memberof UtilisateursApiApiUtilisateursGetCollection
     */
    readonly xIdSociete?: any
}

/**
 * UtilisateursApi - object-oriented interface
 * @export
 * @class UtilisateursApi
 * @extends {BaseAPI}
 */
export class UtilisateursApi extends BaseAPI {
    /**
     * Returns users collection
     * @summary Returns users collection
     * @param {UtilisateursApiApiUtilisateursGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilisateursApi
     */
    public apiUtilisateursGetCollection(requestParameters: UtilisateursApiApiUtilisateursGetCollectionRequest = {}, options?: AxiosRequestConfig) {
        return UtilisateursApiFp(this.configuration).apiUtilisateursGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }
}


