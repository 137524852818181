import * as React from 'react';
import { Button } from '@mui/material';
import { FaOptionIcon, NamedIconsType } from '@europrocurement/l2d-icons';

type ActionButtonProps = {
    action: () => void;
    icon: NamedIconsType;
    label: string;
};

/** Action button used in Listing & preview layout */
const ActionButton: React.FC<ActionButtonProps> = function (props) {
    const { action, icon, label } = props;

    const listingActionButtonStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0 0 15px 15px',
        maxHeight: '60px',
        width: '16%',
        borderRadius: '15px',
    };

    const iconProps = {
        ...icon?.props,
        sx: {
            ...icon?.props.sx,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '0px 10px',
        },
    };

    return (
        <Button
            variant="contained"
            sx={listingActionButtonStyle}
            onClick={() => {
                action();
            }}
        >
            {icon && <FaOptionIcon {...iconProps} />}
            {label}
        </Button>
    );
};

export default ActionButton;
