import React from 'react';
import { Formalite } from '@europrocurement/l2d-domain/reducers/formalites/slices/formalite';
import { Alert, Table, TableContainer } from '@mui/material';
import Paper from '@mui/material/Paper';
import TableBody from './TableBody';
import TableHeading from './TableHeading';

export type IncompatibilitiesTableProps = {
    formalities: Formalite[];
    filteredFormalities: Formalite[];
    onToggleIncompatibility: (formality: Formalite, incompatibility: Formalite) => void;
};

const IncompatibilitiesTable: React.FunctionComponent<IncompatibilitiesTableProps> = function (
    props,
) {
    const { formalities, filteredFormalities, onToggleIncompatibility } = props;

    if (!filteredFormalities.length) {
        return <Alert severity="warning">Votre recherche n&apos;a donné aucun résultat</Alert>;
    }

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: 800 }}>
                <Table
                    stickyHeader
                    aria-label="sticky table"
                >
                    <TableHeading formalities={filteredFormalities} />
                    <TableBody
                        formalities={formalities}
                        incompatibilities={filteredFormalities}
                        onChange={onToggleIncompatibility}
                    />
                </Table>
            </TableContainer>
        </Paper>
    );
};

export default IncompatibilitiesTable;
