/* eslint-disable @typescript-eslint/no-unused-vars */

import {
    SliceFactory,
    FetchCollectionData,
    FetchItemDataType,
    ApiCollectionResponse,
} from '@europrocurement/l2d-redux-utils';
import {
    ConfigurationParameters as DossiersConfigurationParameters,
    Configuration,
    JournalAnnoncesLegalesApi,
    JournalAnnoncesLegalesJalRead,
} from '../../../openApi/ApiDossiers';
import { DOSSIERS_REDUCER_NAME } from '../constants';

export const additionalJournalsDataSources: Array<string> = [];

export type JalLegalesApi = JournalAnnoncesLegalesJalRead & Record<string, unknown>;

export type Journal = JalLegalesApi;

export const JOURNAL_SLICE_NAME = 'jal';

const mapperJournal = function (jal: JalLegalesApi) {
    return {
        ...jal,
    } as Journal;
};

export function createJournalSlice(configuration: DossiersConfigurationParameters) {
    const conf = new Configuration(configuration);
    const api = new JournalAnnoncesLegalesApi(conf);

    const fetchJournalsCollection: FetchCollectionData<JalLegalesApi> = ({
        search,
        pagination,
        filters,
        orders,
        xIdSociete,
        rootstate,
    }) =>
        api
            .getJournalAnnoncesLegalesCollection({
                xIdSociete,
                page: pagination.page + 1,
                itemsPerPage: pagination.itemsPerPage,
                recherche: search,
            })
            .then((res) => res.data) as Promise<ApiCollectionResponse<JalLegalesApi>>;

    const fetchJournalsItem: FetchItemDataType<JalLegalesApi> = ({
        idItem,
        search,
        pagination,
        filters,
        orders,
        xIdSociete,
        rootstate,
    }) =>
        api
            .getJournalAnnoncesLegalesItem({ id: String(idItem), xIdSociete })
            .then((res) => res.data) as Promise<JalLegalesApi>;

    const { slice: journalSlice, dataSourcesThunks: journalDataSourcesThunks } =
        SliceFactory.createSlice<JalLegalesApi, Journal>(
            JOURNAL_SLICE_NAME,
            DOSSIERS_REDUCER_NAME,
            additionalJournalsDataSources,
            fetchJournalsCollection,
            fetchJournalsItem,
            mapperJournal,
        );

    return { journalSlice, journalDataSourcesThunks };
}
