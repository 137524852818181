import React from 'react';

import { Box, Chip, Tooltip } from '@mui/material';

export type CodeRejetsChipType = {
    id?: number;
    etat?: string;
    codeRejet?: {
        code?: string;
        libelle?: string;
    };
};

export type CodeRejetsChipListType = {
    rejets: CodeRejetsChipType[];
    full?: boolean;
    max?: number;
    count?: number;
};

export const CODEREJET_ETAT_TODO = 'todo';
export const CODEREJET_ETAT_DONE = 'done';
export const CODEREJET_ETAT_ERROR = 'error';
export const CODEREJET_ETAT_EXCEPT = 'except';

export const getRejetColor = function (etat: string | undefined) {
    switch (etat) {
        case CODEREJET_ETAT_TODO:
            return 'warning';
        case CODEREJET_ETAT_ERROR:
            return 'danger';
        case CODEREJET_ETAT_DONE:
            return 'success';
        case CODEREJET_ETAT_EXCEPT:
        default:
            return 'primary';
    }
};

export const getRejetLabelSmall = function (etat: string | undefined) {
    switch (etat) {
        case CODEREJET_ETAT_TODO:
            return 'Litige';
        case CODEREJET_ETAT_ERROR:
            return 'Erreur';
        case CODEREJET_ETAT_DONE:
            return 'Traité';
        case CODEREJET_ETAT_EXCEPT:
        default:
            return 'Tolérance';
    }
};

export const getRejetLabel = function (etat: string | undefined) {
    switch (etat) {
        case CODEREJET_ETAT_TODO:
            return 'Litige';
        case CODEREJET_ETAT_ERROR:
            return 'Erreur technique';
        case CODEREJET_ETAT_DONE:
            return 'Traité';
        case CODEREJET_ETAT_EXCEPT:
        default:
            return 'Dans la tolérance';
    }
};

export const CodeRejetsChipList = function ({ rejets, full, max, count }: CodeRejetsChipListType) {
    return (
        <Box
            display="box"
            sx={{ maxWidth: '100%', marginTop: '-5px' }}
        >
            {rejets.map((rejet, index, array) => {
                if (max) {
                    if (index > max - 1) return null;
                    if (index === max - 1 && (count || array.length) > max) {
                        return (
                            <Chip
                                key={`${rejet.id} - ${rejet.codeRejet?.code} - ${rejet.etat}`}
                                color="warning"
                                label={`${(count || array.length) - index} de plus`}
                                size="small"
                                variant="outlined"
                                sx={{
                                    margin: '5px 5px 0px 0px',
                                    color: '#3e3e3e', // Nécessaire pour l'accessibilité
                                }}
                            />
                        );
                    }
                }

                if (rejet.etat === 'error') {
                    return (
                        <Tooltip
                            key={`${rejet.id} - ${rejet.codeRejet?.code} - ${rejet.etat}`}
                            title="Erreur technique lors de la validation de la règle "
                        >
                            {full ? (
                                <Chip
                                    color={getRejetColor(rejet.etat)}
                                    label={`${rejet.codeRejet?.code} - Erreur technique`}
                                    size="small"
                                    sx={{
                                        margin: '5px 5px 0px 0px',
                                    }}
                                />
                            ) : (
                                <Chip
                                    color={getRejetColor(rejet.etat)}
                                    label={`${rejet.codeRejet?.code} - Erreur technique`}
                                    size="small"
                                    sx={{
                                        margin: '5px 5px 0px 0px',
                                    }}
                                />
                            )}
                        </Tooltip>
                    );
                }

                return (
                    <Tooltip
                        title={rejet.codeRejet?.code}
                        key={`${rejet.id} - ${rejet.codeRejet?.code} - ${rejet.etat}`}
                    >
                        {full ? (
                            <Chip
                                color={getRejetColor(rejet.etat)}
                                label={
                                    <>
                                        <b>{rejet.codeRejet?.code}</b>
                                        {` - ${rejet.codeRejet?.libelle} - ${getRejetLabel(
                                            rejet.etat,
                                        )}`}
                                    </>
                                }
                                size="small"
                                sx={{
                                    margin: '5px 5px 0px 0px',
                                    color: '#3e3e3e', // Nécessaire pour l'accessibilité
                                }}
                            />
                        ) : (
                            <Chip
                                color={getRejetColor(rejet.etat)}
                                label={`${rejet.codeRejet?.code} - ${getRejetLabelSmall(
                                    rejet.etat,
                                )}`}
                                size="small"
                                sx={{
                                    margin: '5px 5px 0px 0px',
                                    color: '#3e3e3e', // Nécessaire pour l'accessibilité
                                }}
                            />
                        )}
                    </Tooltip>
                );
            })}
        </Box>
    );
};

export default CodeRejetsChipList;
