import { RootStateType } from '@b2d/redux/RootStore';
import {
    OFFER_ADDITIONAL_FIELDS_SLICE_NAME,
    OFFRE_REDUCER_NAME,
} from '@europrocurement/l2d-domain';
import { ChampComplMentaireChampcomplementaireCreate } from '@europrocurement/l2d-domain/openApi/ApiOffre';
import { additionalFieldIcon } from '@europrocurement/l2d-icons';
import { additionalFieldApi } from '@b2d/redux/subReducers/Offers/api';
import { offerAdditionalFieldsDataSourcesThunks } from '@b2d/redux/subReducers/Offers/reducers';
import { ModelConfiguration, OfferModelItem, ModelMethods } from './types';

const modelKey = 'additional_field';
const modelName = 'AdditionalField';
const sliceName = OFFER_ADDITIONAL_FIELDS_SLICE_NAME;
const api = additionalFieldApi;
const baseSelector = (state: RootStateType) => state[OFFRE_REDUCER_NAME][sliceName];
const datasourcesThunks = offerAdditionalFieldsDataSourcesThunks;

type ModelStructure = ChampComplMentaireChampcomplementaireCreate;

const configuration: ModelConfiguration = {
    key: modelKey,
    name: modelName,
    type: 'Champ Complémentaire',
    selector: baseSelector,
    sliceName,
    api,
    datasourcesThunks,
    icon: additionalFieldIcon,
};

const methods: ModelMethods<ModelStructure> = {
    getItemDefaultLabel: (model: ModelStructure) => `${model.libelle}`,
    getItemSecondaryLabel: (model: ModelStructure) => `${model.description}`,
};

const endpoints = {
    create: (...args: Parameters<typeof api.apiChampsComplementairesPost>) =>
        api.apiChampsComplementairesPost(...args),
    read: (...args: Parameters<typeof api.apiChampsComplementairesIdGet>) =>
        api.apiChampsComplementairesIdGet(...args),
    update: (...args: Parameters<typeof api.apiChampsComplementairesIdPut>) =>
        api.apiChampsComplementairesIdPut(...args),
    delete: (...args: Parameters<typeof api.apiChampsComplementairesIdDelete>) =>
        api.apiChampsComplementairesIdDelete(...args),
};

type ModelType = OfferModelItem<ModelStructure> & typeof endpoints;

const model: ModelType = {
    ...configuration,
    ...methods,
    ...endpoints,
};

export type AdditionalFieldModel = ModelType;
export default model;
