import React, { useCallback, useMemo, useState } from 'react';

import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Stack, Typography } from '@mui/material';

import {
    COMMANDES_SLICE_NAME,
    DOSSIERS_SLICE_NAME,
    FACTURES_ACHATS_SLICE_NAME,
    FACTURE_VENTE_SLICE_NAME,
    FOURNISSEUR_SLICE_NAME,
    FactureAchat,
    FactureAchatApiObject,
    BDD_SWITCH_SOCIETES_FULLNAME_FROM_ID,
    BDD_SWITCH_SOCIETES_IDS_TYPE,
} from '@europrocurement/l2d-domain';
import {
    ColumnDatatable,
    FilterDatatable,
    FiltersDatatableList,
    StoreDatatable,
} from '@europrocurement/flexy-datatable';
import { UseKeycloakService } from '@europrocurement/l2d-keycloak';
import { DataSource, getDataThunkType } from '@europrocurement/l2d-redux-utils';
import { AppDispatch } from '@europrocurement/flexy-components/redux/storeConfig/store';
import { FactureAchatApiSyncG3FactureAchatFactureAchatItemRequest } from '@europrocurement/l2d-domain/openApi/ApiAchats';

import generateB2DPath, {
    AccessibleDirections,
    PossibleParams,
} from '../../../../utils/generateB2DPath';
import { repriseMultiAnnonceModalMessages } from '../../constants/wording/modals';
import { ACTIONS } from '../../../../redux/FactureFormReducer';
import { factureAchatApi } from '../../../../redux/RootStore';
import {
    CodeRejetsChipList,
    CODEREJET_ETAT_DONE,
    CODEREJET_ETAT_ERROR,
    CODEREJET_ETAT_EXCEPT,
    CODEREJET_ETAT_TODO,
    getRejetLabel,
} from '../widgets/CodeRejetsChipList';
import ConfirmationModal from '../modals/ConfirmationModal';
import FactureAchatsActionMenu from './FactureAchatsActionMenu';
import StatusList from '../fragments/StatusList';
import { convertToNumberAndFormatToString } from '../forms/functions/calculsProduits';

export type FactureAchatListProps = {
    dataSource: DataSource<FactureAchat>;
    fetchData: getDataThunkType<FactureAchatApiObject>;
    filtersControl?: boolean;
    filterFilters?: (
        filtre: FilterDatatable,
        index: number,
        array: FiltersDatatableList,
    ) => unknown;
    statement?: string;
};

type NavigateToFactureProps = {
    factureAchat: FactureAchat;
    navigate: (path: string) => void;
    onError: (message: string) => void;
    openNewTab?: boolean;
    statement?: string;
};

export const navigateToFacture = ({
    factureAchat,
    navigate,
    onError,
    openNewTab = false,
    statement,
}: NavigateToFactureProps) => {
    let direction: AccessibleDirections = 'formfactureachat';
    let params: PossibleParams = { factureAchat };

    if (statement && factureAchat.pdfFacture?.id) {
        direction = 'statementUpdate';

        params = {
            ...params,
            statementId: +statement,
            mediaObjectId: factureAchat.pdfFacture?.id,
        };
    }

    if ((factureAchat.countDossier || 1) > 1) {
        direction = 'consulterMultiFacture';
    }

    const response = generateB2DPath(direction, params);

    if (response.status === 'OK') {
        if (openNewTab) {
            window.open(response.path, '_blank', 'rel=noopener noreferrer');
        } else {
            navigate(response.path);
        }
    } else if (response.status === 'KO') {
        onError(response.message);
    }
};

const FactureAchatList: React.FunctionComponent<FactureAchatListProps> = function ({
    dataSource,
    fetchData,
    filtersControl = true,
    filterFilters = () => true,
    statement,
}) {
    const keycloak = UseKeycloakService();

    const [repriseFactureModalOpen, setRepriseFactureModalOpen] = useState<boolean>(false);
    const [selectedFactureAchat, setSelectedFactureAchat] = useState<FactureAchat | undefined>(
        undefined,
    );

    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const resetStates = useCallback(() => {
        // clean du state du formulaire
        dispatch({
            type: ACTIONS.RESET,
        });
    }, [dispatch]);

    type NavigateToFactureCbProps = {
        factureAchat: FactureAchat;
        openNewTab?: boolean;
        statementId?: string;
    };

    const navigateToFactureCb = useCallback(
        ({ factureAchat, openNewTab = false, statementId }: NavigateToFactureCbProps) => {
            dispatch({
                type: `${DOSSIERS_SLICE_NAME}/deletemainSelected`,
            });
            dispatch({
                type: `${FACTURE_VENTE_SLICE_NAME}/deletemainSelected`,
            });
            dispatch({
                type: `${COMMANDES_SLICE_NAME}/deletemainSelected`,
            });
            dispatch({
                type: `${FACTURES_ACHATS_SLICE_NAME}/deletemainSelected`,
            });
            dispatch({
                type: `${FOURNISSEUR_SLICE_NAME}/deletemainSelected`,
            });

            if (factureAchat.cloture === false) {
                setRepriseFactureModalOpen(true);
                setSelectedFactureAchat(factureAchat);
            } else {
                navigateToFacture({
                    factureAchat,
                    navigate,
                    onError: (message) => {
                        enqueueSnackbar(<Typography>{message}</Typography>, { variant: 'warning' });
                    },
                    openNewTab,
                    statement: statementId,
                });
            }
        },
        [dispatch, enqueueSnackbar, navigate],
    );

    const reprendreFacture = useCallback(() => {
        if (!selectedFactureAchat) return;
        resetStates();

        const fallback: AccessibleDirections = 'formmultiadddossier';

        const response = generateB2DPath(fallback, { factureAchat: selectedFactureAchat });
        if (response.status === 'OK') {
            navigate(response.path);
        }
    }, [selectedFactureAchat, resetStates, navigate]);

    const synchroG3Facture = useCallback(
        async (factureAchat: FactureAchat, refresh: boolean = true) => {
            if (!factureAchat.id) {
                enqueueSnackbar(
                    <Typography>
                        Erreur technique la facture n&apos;a pas d&apos;identifiant
                    </Typography>,
                    { variant: 'warning' },
                );
                return;
            }
            try {
                if (typeof factureAchat.idSociete !== 'number') {
                    enqueueSnackbar(
                        <Typography>
                            Erreur technique la facture n&apos;est pas liée a une societe
                        </Typography>,
                        { variant: 'error' },
                    );
                    return;
                }

                const requestParameters: FactureAchatApiSyncG3FactureAchatFactureAchatItemRequest =
                    {
                        xIdSociete: factureAchat.idSociete,
                        id: `${factureAchat.id}`,
                        factureAchatJsonldFactureAchatSyncG3: { syncG3: true, forced: true },
                    };

                const res =
                    await factureAchatApi.syncG3FactureAchatFactureAchatItem(requestParameters);

                if (res.status >= 200 && res.status < 300) {
                    enqueueSnackbar(<Typography>Synchronisation effectuée</Typography>, {
                        variant: 'success',
                    });

                    // todo
                    if (refresh) {
                        dispatch(fetchData({}));
                    }
                } else {
                    throw new Error(`Erreur ${res.status}`);
                }
            } catch (error) {
                if (
                    error instanceof AxiosError &&
                    error.response &&
                    error.response.data.exception &&
                    error.response.data.exception.message
                ) {
                    enqueueSnackbar(
                        <Typography>{error.response.data.exception.message}</Typography>,
                        {
                            variant: 'error',
                        },
                    );
                } else if (error instanceof Error) {
                    enqueueSnackbar(<Typography>{error.message}</Typography>, {
                        variant: 'error',
                    });
                }
            }
        },
        [dispatch, enqueueSnackbar, fetchData],
    );

    // const syncAll = useCallback(async () => {
    //     for (let index = 0; index < dataSource.data.length; index++) {
    //         const facture = dataSource.data[index];
    //         if (facture.cloture && facture.syncG3 === false) {
    //             await synchroG3Facture(facture, false);
    //         }
    //     }
    //     dispatch(fetchData({}));
    // }, [dataSource.data, dispatch, fetchData, synchroG3Facture]);

    // const contextActions: ContextActionType[] = useMemo(
    //     () => [
    //         {
    //             name: 'Synchroniser les factures',
    //             icon: (
    //                 <FontAwesomeIcon
    //                     icon={faRotate}
    //                     size="xl"
    //                 />
    //             ),
    //             action: async () => {
    //                 await syncAll();
    //             },
    //         },
    //     ],
    //     [syncAll],
    // );

    // useContextActions(contextActions);

    const columns: ColumnDatatable<FactureAchat>[] = useMemo(
        () => [
            {
                label: 'N° de facture',
                isDisplayed: true,
                render: (factureAchat: FactureAchat) => (
                    <Stack
                        direction="row"
                        alignItems="center"
                        gap={1}
                    >
                        <Typography>{factureAchat.numeroFacture}</Typography>
                        <StatusList itemToWatch={factureAchat} />
                    </Stack>
                ),
            },
            {
                label: 'Créé le',
                render: (factureAchat: FactureAchat) => {
                    let faCreationDate = null;

                    if (factureAchat.createdAt) {
                        faCreationDate = new Date(
                            Date.parse(factureAchat.createdAt),
                        ).toLocaleDateString();
                    }

                    return <Typography>{faCreationDate}</Typography>;
                },
                isDisplayed: true,
            },
            {
                label: 'Créé par',
                render: 'createdBy',
                isDisplayed: false,
            },
            {
                label: 'Modifié le',
                render: (factureAchat: FactureAchat) => {
                    let faCreationDate = null;

                    if (factureAchat.updatedAt) {
                        faCreationDate = new Date(
                            Date.parse(factureAchat.updatedAt),
                        ).toLocaleDateString();
                    }

                    return <Typography>{faCreationDate}</Typography>;
                },
                field: 'updatedAt',
                sortable: true,
                isDisplayed: true,
            },
            {
                label: 'Modifié par',
                render: 'updatedBy',
                isDisplayed: true,
            },
            {
                label: 'Numéro Dossier',
                render: (factureAchat: FactureAchat) => {
                    if (factureAchat.countDossier && factureAchat.countDossier > 1) {
                        return `Facture multiple`;
                    }
                    if (factureAchat.firstDossier && factureAchat.firstDossier.numeroDossier) {
                        return factureAchat.firstDossier.numeroDossier;
                    }
                    return '';
                },
                isDisplayed: true,
            },
            {
                label: 'Numéro Annonce',
                render: (factureAchat: FactureAchat) => {
                    if (factureAchat.countDossier && factureAchat.countDossier > 1) {
                        return `(${factureAchat.countDossier} dossiers)`;
                    }
                    if (factureAchat.firstDossier) {
                        return factureAchat.firstDossier.numeroPrestation;
                    }
                    return '';
                },
                isDisplayed: true,
            },
            {
                label: 'Id Dossier G3',
                render: (factureAchat: FactureAchat) => {
                    if (factureAchat.countDossier && factureAchat.countDossier > 1) {
                        return `Facture multiple`;
                    }
                    if (factureAchat.firstDossier) {
                        return factureAchat.firstDossier.idDossier;
                    }
                    return null;
                },
                isDisplayed: false,
            },
            {
                label: 'Id Prestation G3',
                render: (factureAchat: FactureAchat) => {
                    if (factureAchat.countDossier && factureAchat.countDossier > 1) {
                        return `Facture multiple`;
                    }
                    if (factureAchat.firstDossier) {
                        return factureAchat.firstDossier.idPrestation;
                    }
                    return null;
                },
                isDisplayed: false,
            },
            {
                label: 'Fournisseur',
                render: 'libelleFacturante',
                isDisplayed: true,
            },
            {
                label: 'Annonceur',
                render: 'libelleAnnonceur',
                isDisplayed: true,
            },
            {
                label: 'ID Fournisseur',
                render: 'idEntiteFacturante',
                isDisplayed: false,
            },
            {
                label: 'Société',
                render: (factureAchat: FactureAchat) => {
                    let factureSociete = null;

                    if (factureAchat.idSociete) {
                        factureSociete =
                            BDD_SWITCH_SOCIETES_FULLNAME_FROM_ID[
                                factureAchat.idSociete as BDD_SWITCH_SOCIETES_IDS_TYPE
                            ];
                    }

                    return <Typography>{factureSociete}</Typography>;
                },
                isDisplayed: true,
            },
            {
                label: 'Code rejets',
                render: (factureAchat) => {
                    if (
                        factureAchat.firstCodesRejets &&
                        factureAchat.firstCodesRejets?.length > 0
                    ) {
                        return (
                            <CodeRejetsChipList
                                rejets={factureAchat.firstCodesRejets}
                                full={false}
                                max={3}
                                count={
                                    factureAchat.countCodesRejets ||
                                    factureAchat.firstCodesRejets.length
                                }
                            />
                        );
                    }
                    return '';
                },
                isDisplayed: true,
            },
            {
                label: 'Date de facturation',
                render: (factureAchat: FactureAchat) => {
                    if (factureAchat.dateFacture) {
                        return new Date(factureAchat.dateFacture).toLocaleDateString();
                    }
                    return '';
                },
                isDisplayed: false,
            },
            {
                label: 'Montant total',
                render: (factureAchat: FactureAchat) => {
                    if (factureAchat.ttc) {
                        const mnt = convertToNumberAndFormatToString(factureAchat.ttc || '0');
                        return factureAchat.avoir ? `- ${mnt}` : mnt;
                    }
                    return '';
                },
                isDisplayed: false,
            },
            {
                label: 'Actions',
                onClickCell: () => {},
                render: (item) => (
                    <FactureAchatsActionMenu
                        factureAchat={item}
                        onNavigate={navigateToFactureCb}
                        onSync={synchroG3Facture}
                        afterDelete={() => {
                            dispatch(fetchData({}));
                        }}
                    />
                ),
                isDisplayed: true,
            },
        ],
        [dispatch, fetchData, navigateToFactureCb, synchroG3Facture],
    );

    const filters: FiltersDatatableList = [
        {
            field: 'linkedCodeRejets',
            label: 'Avec code rejet',
            type: 'boolean',
        },
        {
            field: 'cloture',
            label: 'Factures clôturées',
            type: 'boolean',
        },
        {
            field: 'syncG3',
            label: 'Factures synchronisées G3',
            type: 'boolean',
        },
        {
            field: 'libelleFacturante',
            label: 'Fournisseur',
            type: 'text',
        },
        {
            field: 'ligneNumeroDossier',
            label: 'Numéro Dossier',
            type: 'number',
        },
        {
            field: 'ligneNumeroPresta',
            label: 'Numéro Annonce',
            type: 'text',
        },
        {
            field: 'updatedBy',
            label: 'Mes saisies',
            type: 'boolean',
            valueMap: (value: boolean) => {
                if (value === true) {
                    return keycloak.getTokenParsed().email || undefined;
                }
                return undefined;
            },
        },
        {
            field: 'multiAnnonces',
            label: 'Facture multi-annonces',
            type: 'boolean',
        },
        {
            field: 'codesRejetsEtat',
            label: 'Etat rejet',
            type: 'staticSelect',
            options: [
                {
                    value: CODEREJET_ETAT_DONE,
                    label: getRejetLabel(CODEREJET_ETAT_DONE),
                },
                {
                    value: CODEREJET_ETAT_ERROR,
                    label: getRejetLabel(CODEREJET_ETAT_ERROR),
                },
                {
                    value: CODEREJET_ETAT_TODO,
                    label: getRejetLabel(CODEREJET_ETAT_TODO),
                },
                {
                    value: CODEREJET_ETAT_EXCEPT,
                    label: getRejetLabel(CODEREJET_ETAT_EXCEPT),
                },
            ],
            renderFilterList: (value) => getRejetLabel(value),
        },
        {
            field: 'idSociete',
            label: 'Société',
            type: 'staticSelect',
            options: Object.entries(BDD_SWITCH_SOCIETES_FULLNAME_FROM_ID).map(([key, value]) => ({
                value: key,
                label: value as string,
            })),
            renderFilterList: (idSociete: number) => {
                const libelleSociete =
                    BDD_SWITCH_SOCIETES_FULLNAME_FROM_ID[idSociete as BDD_SWITCH_SOCIETES_IDS_TYPE];

                return libelleSociete;
            },
        },
    ];

    return (
        <>
            <ConfirmationModal
                isModalOpen={repriseFactureModalOpen}
                closeModal={() => {
                    setRepriseFactureModalOpen(false);
                }}
                messages={repriseMultiAnnonceModalMessages}
                actionOnValidation={() => {
                    reprendreFacture();
                }}
                actionOnCancellation={() => {
                    setRepriseFactureModalOpen(false);
                }}
            />
            <StoreDatatable
                dataSource={dataSource}
                // activeFilters={activeFilters}
                // replaceActiveFilters={replaceActiveFilters}
                columns={columns}
                fetchData={fetchData}
                filters={filterFilters ? filters.filter(filterFilters) : filters}
                localStorageKey="FlexyStoreDatatableFactureAchat"
                localStorageRefreshDate={new Date('2023-06-23T09:42:00.000Z')}
                onClickRow={(e: React.MouseEvent<HTMLElement>, item: FactureAchat) =>
                    navigateToFactureCb({ factureAchat: item, statementId: statement })
                }
                onWheelClickRow={(e: React.MouseEvent<HTMLElement>, item: FactureAchat) =>
                    navigateToFactureCb({
                        factureAchat: item,
                        openNewTab: true,
                        statementId: statement,
                    })
                }
                filtersControl={filtersControl}
            />
        </>
    );
};

export default FactureAchatList;
