import { FlexyFormLabel } from '@europrocurement/flexy-components';
import { Typography } from '@mui/material';
import * as React from 'react';
import AsyncEditableList, { AsyncEditableListProps } from './AsyncEditableList/AsyncEditableList';

type ComponentProps = {
    name: string;
    label: string;
    placeholder: string;
} & AsyncEditableListProps;

const AutocompleteField = React.forwardRef<HTMLElement, ComponentProps>(
    ({ name, label, formContext, model, placeholder, defaultList }: ComponentProps) => (
        <>
            <FlexyFormLabel>
                <Typography component="span">{label}</Typography>
            </FlexyFormLabel>
            <AsyncEditableList
                inputLabel=""
                formContext={formContext}
                formKey={name}
                defaultList={defaultList}
                formatItem={(item) => (!item ? null : item.id)}
                placeholder={placeholder}
                model={model}
            />
        </>
    ),
);

export default AutocompleteField;
