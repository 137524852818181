import invoiceStatement from './invoiceStatement';
import invoicePurchase from './invoicePurchase';
import mediaObject from './mediaObject';
import quote from './quote';
import quoteLine from './quoteLine';

const models = {
    invoiceStatement,
    invoicePurchase,
    mediaObject,
    quote,
    quoteLine,
};

export default models;
