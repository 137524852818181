import * as React from 'react';
import { UseKeycloakService } from '../../KeycloakProvider';

export type KeycloakIsAuthProps = {
    children: React.ReactNode;
    elseChildren?: React.ReactNode;
};

/**
 * Agit comme un provider.
 * Il permet de vérifier auprès du service keycloak que l'utilisateur est connecté.
 *
 * ## Utilisation
 *
 * ### props
 * children: Composant enfant à afficher SI l'utilisateur est connecté.
 * elseChildren: Composant enfant à afficher si l'utilisateur n'est pas connecté.
 */
export const KeycloakIsAuth = function ({
    children,
    elseChildren = undefined,
}: KeycloakIsAuthProps) {
    const KeycloakService = UseKeycloakService();
    const show = function (): boolean {
        return KeycloakService.isAutenticated();
    };

    if (show()) {
        return children;
    }
    if (elseChildren === undefined) {
        return null;
    }
    return elseChildren;
};

export default KeycloakIsAuth;
