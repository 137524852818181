/* eslint-disable @typescript-eslint/no-unused-vars */

import {
    SliceFactory,
    FetchCollectionData,
    FetchItemDataType,
    ApiCollectionResponse,
} from '@europrocurement/l2d-redux-utils';
import {
    ConfigurationParameters as DossiersConfigurationParameters,
    Configuration,
    RubriqueFacturationApi,
    RubriqueFacturationJsonldRubriqueFactureRead,
} from '../../../openApi/ApiDossiers';
import { DOSSIERS_REDUCER_NAME } from '../constants';

export type RubriqueFacturationApiObject = RubriqueFacturationJsonldRubriqueFactureRead &
    Record<string, unknown>;

export type RubriqueFacturation = RubriqueFacturationApiObject;

export const RUBFACT_SLICE_NAME = 'rubfac';

const mapperRubriqueFacturation = function (rubFac: RubriqueFacturationApiObject) {
    return {
        ...rubFac,
    } as RubriqueFacturation;
};

export function createRubriqueFacturationSlice(configuration: DossiersConfigurationParameters) {
    const conf = new Configuration(configuration);
    const api = new RubriqueFacturationApi(conf);

    const fetchRubriqueFacturationsCollection: FetchCollectionData<
        RubriqueFacturationApiObject
    > = ({ search, pagination, filters, orders, xIdSociete, rootstate }) =>
        api
            .getRubriqueFacturationCollection({ xIdSociete, page: pagination.page + 1 })
            .then((res) => res.data) as Promise<
            ApiCollectionResponse<RubriqueFacturationApiObject>
        >;

    const fetchRubriqueFacturationsItem: FetchItemDataType<RubriqueFacturationApiObject> = ({
        idItem,
        search,
        pagination,
        filters,
        orders,
        xIdSociete,
        rootstate,
    }) =>
        api
            .getRubriqueFacturationItem({ id: String(idItem), xIdSociete })
            .then((res) => res.data) as Promise<RubriqueFacturationApiObject>;

    const {
        slice: rubriqueFacturationSlice,
        dataSourcesThunks: rubriqueFacturationDataSourcesThunks,
    } = SliceFactory.createSlice<RubriqueFacturationApiObject, RubriqueFacturation>(
        RUBFACT_SLICE_NAME,
        DOSSIERS_REDUCER_NAME,
        [],
        fetchRubriqueFacturationsCollection,
        fetchRubriqueFacturationsItem,
        mapperRubriqueFacturation,
    );

    return { rubriqueFacturationSlice, rubriqueFacturationDataSourcesThunks };
}
