import { Box, ListItem } from '@mui/material';
import * as React from 'react';

const EmptyLine: React.FC = function () {
    return (
        <Box
            width="100%"
            sx={{
                opacity: '0.4',
            }}
        >
            <ListItem
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    transition: 'background 0.3s',
                    margin: '5px',
                    border: '2px dashed gray',
                }}
            >
                <p>Recherchez un article pour l&apos;ajouter a la liste</p>
            </ListItem>
        </Box>
    );
};

export default EmptyLine;
