/**
 *
 * Notre composant affiche une timeline comme
 *
 * https://gitlab.europroc.net/devl2d/librairies/flexy/-/blob/master/package/main/src/views/timeline/CustomTimeline.js
 *
 * prends en parametre :
 *
 * une liste de LogType
 *
 * une objet de mapping d'icone    event => reactNode
 *
 * exemple : {
 *      "DOSSIER_CREATE" => <PlusIcon>,
 *      "SIGNATAIRE_SIGN" => <SignIcon>,
 * }
 *
 * une objet de mapping de couleur event => TypographyProps['color']
 *
 * exemple : {
 *      "DOSSIER_CREATE" => 'blueGrey.main',
 *      "SIGNATAIRE_SIGN" => 'success.main',
 * }
 *
 * position="alternate" https://mui.com/material-ui/react-timeline/
 *
 */
import React from 'react';

import { Timeline } from '@mui/lab';

import LogTimelineItem, {
    TimelineItemProps,
    TimelineLogType,
} from '../../molecules/LogTimelineItem';

export type LogTimelineProps = {
    logs: Array<TimelineLogType>;
    eventToColor: TimelineItemProps['eventToColor'];
    eventToIcone: TimelineItemProps['eventToIcone'];
    eventToTitle: TimelineItemProps['eventToTitle'];
    filterEvents?: (log: TimelineLogType, index: number, logs: Array<TimelineLogType>) => boolean;
};

const LogTimeline: React.FunctionComponent<LogTimelineProps> = function (props) {
    const { logs, eventToColor, eventToIcone, eventToTitle, filterEvents = () => true } = props;
    return (
        <Timeline color="secondary">
            {logs.filter(filterEvents).map((log) => (
                <LogTimelineItem
                    key={`${log.event}-${log.date}-${log.message}`}
                    log={log}
                    eventToColor={eventToColor}
                    eventToIcone={eventToIcone}
                    eventToTitle={eventToTitle}
                />
            ))}
        </Timeline>
    );
};

export default LogTimeline;
