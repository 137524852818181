import * as React from 'react';
import { Box, Button, List, useTheme } from '@mui/material';
import InformationCardItem, { InformationCardItemProps } from '../../molecules/InformationCardItem';
import './InformationCard.css';

export type InformationCardProps = {
    title?: string;
    subtitle?: React.ReactNode;
    button?: {
        label: string;
        onClick: () => void;
    };
    items: Array<InformationCardItemProps>;
};
const InformationCard: React.FunctionComponent<InformationCardProps> = function (
    props: InformationCardProps,
) {
    const { title, button, items, subtitle } = props;
    const [completedCount, setCompletedCount] = React.useState(0);
    const [totalCount, setTotalCount] = React.useState(0);
    const theme = useTheme();
    const itemComponents = items.map((itemProps: InformationCardItemProps, index: number) => {
        const keyGenerate = () => `informationCard-${title}_${index}`;
        return (
            <InformationCardItem
                key={keyGenerate()}
                {...itemProps}
            />
        );
    });
    let buttonComponent = null;

    React.useEffect(() => {
        const completed = items.reduce(
            (counter, currentItem: InformationCardItemProps) =>
                currentItem.value !== null ? counter + 1 : counter,
            0,
        );

        setCompletedCount(completed);
        setTotalCount(items.length);
    }, [items]);

    if (button?.label) {
        buttonComponent = (
            <Button
                className="action"
                onClick={button?.onClick}
                data-testid="information-card-action-button"
                sx={{
                    color: theme.palette.primary.dark,
                }}
            >
                {button?.label}
            </Button>
        );
    }

    const hasSubtitle = subtitle !== null && subtitle !== undefined;
    const countInformationRender = (
        <>
            {completedCount}/{totalCount}{' '}
            {completedCount > 1 ? 'Informations complétées' : 'Information complétée'}
        </>
    );
    const renderSubtitleOrCount = hasSubtitle ? subtitle : countInformationRender;

    return (
        <Box
            className="information-card"
            sx={{
                backgroundColor: theme.palette.background.dark,
            }}
        >
            <Box
                className="heading"
                sx={{
                    borderLeftColor: theme.palette.primary.main,
                }}
            >
                <Box
                    className="title"
                    sx={{
                        color: theme.palette.text.primary,
                    }}
                >
                    {title}
                </Box>
                {buttonComponent}

                <Box
                    className="completion-feedback"
                    sx={{
                        color: theme.palette.text.primary,
                    }}
                >
                    {renderSubtitleOrCount}
                </Box>
            </Box>
            <List className="body">{itemComponents}</List>
        </Box>
    );
};

export default InformationCard;
