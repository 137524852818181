/* eslint-disable @typescript-eslint/no-unused-vars */

import {
    SliceFactory,
    FetchCollectionData,
    FetchItemDataType,
    ApiCollectionResponse,
} from '@europrocurement/l2d-redux-utils';
import { Order } from '@europrocurement/l2d-domain/types/apiGenericTypes';
import {
    DossierJsonldReadCollectionTimestampableBlameambleGroup,
    DossierApiGetDossierCollectionRequest,
    DossierApi as DossierSignatureApi,
    LogJsonldReadCollectionTimestampableBlameambleGroup as LogType,
} from '../../../openApi/ApiSign';
import { SIGNATURE_REDUCER_NAME } from '../constants';
import {
    SignataireType,
    DossierSignatureApiObject,
    DossierSignature,
    DossierSignatureConfigurationParameters,
    DossierSignatureConfiguration,
} from '../types';

export const DOSSIER_SIGNATURE_SLICE_NAME = 'dossierSignature';

const mapperDossierSignature = function (dossierSignature: DossierSignatureApiObject) {
    return {
        ...dossierSignature,
    } as DossierSignature;
};

export function createDossierSignatureSlice(
    configuration: DossierSignatureConfigurationParameters,
) {
    const conf = new DossierSignatureConfiguration(configuration);
    const api = new DossierSignatureApi(conf);

    const fetchDossierSignaturesCollection: FetchCollectionData<DossierSignatureApiObject> = ({
        search,
        pagination,
        filters,
        orders,
        xIdSociete,
        rootstate,
    }) => {
        const page: number = pagination.page + 1;
        let signatairesSignature: boolean | undefined;
        let referenceExterne: string | undefined;
        const referenceExterne2: Array<string> | undefined = undefined;
        let sujet: string | undefined;
        const sujet2: Array<string> | undefined = undefined;
        let prescripteur: string | undefined;
        const prescripteur2: Array<string> | undefined = undefined;
        let prescripteurEmail: string | undefined;
        const prescripteurEmail2: Array<string> | undefined = undefined;
        const groups: Array<string> | undefined = undefined;
        let recherche: string | undefined;
        let demandeurNom: string | undefined;
        let termine: boolean | undefined;
        let refuse: boolean | undefined;
        let orderId: Order;
        let orderSujet: Order;
        let orderPrescripteur: Order;
        let orderDemandeurNom: Order;
        let orderCreatedAt: Order;
        let orderUpdatedAt: Order;

        if (filters.referenceExterne) {
            referenceExterne = filters?.referenceExterne as string;
        }
        if (filters.sujet) {
            sujet = filters?.sujet as string;
        }
        if (filters.prescripteur) {
            prescripteur = filters?.prescripteur as string;
        }
        if (filters.prescripteurEmail) {
            prescripteurEmail = filters?.prescripteurEmail as string;
        }
        if (filters.demandeurNom) {
            demandeurNom = filters?.demandeurNom as string;
        }

        if (filters.termine === true) {
            termine = true;
        } else if (filters.termine === false) {
            termine = false;
        }

        if (filters.refuse === true) {
            refuse = true;
        } else if (filters.refuse === false) {
            refuse = false;
        }

        if (search && search !== '') {
            recherche = search;
        }

        orders.forEach((item) => {
            if (item.field === 'id') {
                orderId = item.value;
            }
            if (item.field === 'sujet') {
                orderSujet = item.value;
            }
            if (item.field === 'prescripteur') {
                orderPrescripteur = item.value;
            }
            if (item.field === 'demandeur') {
                orderDemandeurNom = item.value;
            }
            if (item.field === 'createdAt') {
                orderCreatedAt = item.value;
            }
            if (item.field === 'updatedAt') {
                orderUpdatedAt = item.value;
            }
        });

        // RG: le filtre par défaut est id, mais si il y a au moins un filtre,
        // on ne filtre plus par id

        const requestParameters: DossierApiGetDossierCollectionRequest = {
            page: pagination.page + 1,
            itemsPerPage: pagination.itemsPerPage,
            referenceExterne,
            sujet,
            prescripteur,
            prescripteurEmail,
            recherche,
            demandeurNom,
            termine,
            refuse,
            orderCreatedAt,
            orderId,
            orderSujet,
            orderPrescripteur,
            orderDemandeurNom,
            orderUpdatedAt,
        };

        /**
         *
         * Filtre multiple serach
         *
         * pagination complete
         *
         * isoler les vrais filtre chaine des recheche dans un champ
         *
         * order
         *
         */

        return api.getDossierCollection(requestParameters).then((res) => res.data) as Promise<
            ApiCollectionResponse<DossierSignatureApiObject>
        >;
    };

    const fetchDossierSignaturesItem: FetchItemDataType<DossierSignatureApiObject> = ({
        idItem,
        search,
        pagination,
        filters,
        orders,
        xIdSociete,
        rootstate,
    }) =>
        api
            .getDossierItem({ id: String(idItem) })
            .then((res) => res.data) as Promise<DossierSignatureApiObject>;

    const { slice: dossierSignatureSlice, dataSourcesThunks: dossierSignatureDataSourcesThunks } =
        SliceFactory.createSlice<DossierSignatureApiObject, DossierSignature>(
            DOSSIER_SIGNATURE_SLICE_NAME,
            SIGNATURE_REDUCER_NAME,
            [],
            fetchDossierSignaturesCollection,
            fetchDossierSignaturesItem,
            mapperDossierSignature,
            {
                // termine: false,
            },
            undefined,
            [
                {
                    field: 'createdAt',
                    value: 'desc',
                },
            ],
        );

    return { dossierSignatureSlice, dossierSignatureDataSourcesThunks };
}
