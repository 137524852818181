import React, { ReactNode } from 'react';

import { useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import { B2DLogoIconProps } from '../B2DLogoIcon';

const L2DFLogoSmallIcon: React.FunctionComponent<B2DLogoIconProps> = function ({
    style,
    redirect,
}: B2DLogoIconProps) {
    const theme = useTheme();

    const logoIcon: ReactNode = (
        <svg
            role="img"
            aria-describedby="logo LégalToDigital - formalités"
            id="L2DFLogoIcon"
            style={style}
            width="120"
            height="120"
            viewBox="0 0 120 120"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M28.5247 10H90.9154V23.6146H28.5247V10ZM59.707 108.24C39.332 108.24 26.1081 102.354 25.5664 102.099L31.4622 89.0417C31.5716 89.0885 42.6602 93.9115 59.707 93.9115C76.7591 93.9115 87.8633 89.1146 87.9727 89.0469L93.8789 102.104C93.3268 102.354 80.0872 108.24 59.707 108.24ZM40.0039 37.9219H53.6185V79.2917H40.0039V37.9219ZM65.5768 37.9219H79.1966V79.2917H65.5768V37.9219Z"
                style={{ fill: theme.palette.primary.main }}
            />
        </svg>
    );

    return redirect ? <Link to={redirect || '/'}>{logoIcon}</Link> : logoIcon;
};

export default L2DFLogoSmallIcon;
