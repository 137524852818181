/** Convert hexadecimal color to the related RGBa value by applying an opacity filter
 * @param hex color input (Hexadecimal format)
 * @param alpha opacity filter
 */
const hexToRgba = (hex: string, alpha: string | number): string => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
        ? `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(
              result[3],
              16,
          )}, ${alpha})`
        : hex;
};

export default hexToRgba;
