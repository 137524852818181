import React, { FunctionComponent } from 'react';

import { GridIndicator } from '@europrocurement/flexy-components';
import { Box } from '@mui/system';
import { Grid } from '@mui/material';
import { ReadOnlyField, defaultGridProps } from '@europrocurement/flexy-form';

export type ViewPublisherSectionProps = {
    siren: string;
    companyName: string;
    discountType: string;
};

const ViewPublisherSection: FunctionComponent<ViewPublisherSectionProps> = function ({
    siren,
    companyName,
    discountType,
}) {
    return (
        <Box sx={{ marginTop: '10px' }}>
            <GridIndicator sx={{ display: 'none' }} />
            <Grid
                container
                {...defaultGridProps}
            >
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                >
                    <ReadOnlyField
                        inputLabel="Siren"
                        value={siren || 'N/A'}
                    />
                </Grid>

                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                >
                    <ReadOnlyField
                        inputLabel="Raison sociale"
                        value={companyName || 'N/A'}
                    />
                </Grid>

                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                >
                    <ReadOnlyField
                        inputLabel="Type de remise"
                        value={discountType || 'N/A'}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export default ViewPublisherSection;
