import { ReactNode, useMemo } from 'react';
import { FormStructure } from '@europrocurement/flexy-form';
import { DataSource, getDataThunkType } from '@europrocurement/l2d-redux-utils';
import { Fournisseur } from '@europrocurement/l2d-domain';
import { HeaderSwitch } from '@europrocurement/flexy-components';
import { ModeProps, SectionHeadControlProps } from '../types';
import { publisherSectionStructure } from '../fragments/publisherSection';

export type UsePublisherSectionStructureProps = {
    dataSource: DataSource<Fournisseur>;
    fetchData: getDataThunkType<Fournisseur>;
    mode: ModeProps;
    headerSwitches?: Array<HeaderSwitch>;
    headerChildren?: Array<SectionHeadControlProps> | SectionHeadControlProps;
    defaultInputValue?: string;
    adornment?: ReactNode;
};

const usePublisherSectionStructure = ({
    dataSource,
    fetchData,
    defaultInputValue,
    adornment,
    mode,
    headerSwitches,
    headerChildren,
}: UsePublisherSectionStructureProps) => {
    const overwriteHeaderSwitches = useMemo<Array<HeaderSwitch>>(() => {
        let temporaryHeaderSwitches: Array<HeaderSwitch> = [];

        if (headerSwitches && headerSwitches.length > 0) {
            temporaryHeaderSwitches = [...temporaryHeaderSwitches, ...headerSwitches];
        }

        return temporaryHeaderSwitches;
    }, [headerSwitches]);

    const overwriteHeaderChildren = useMemo<Array<SectionHeadControlProps>>(() => {
        let temporaryHeaderChildren: Array<SectionHeadControlProps> = [];

        if (Array.isArray(headerChildren)) {
            temporaryHeaderChildren = [...temporaryHeaderChildren, ...headerChildren];
        } else {
            temporaryHeaderChildren.push(headerChildren);
        }

        return temporaryHeaderChildren;
    }, [headerChildren]);

    const autocompleteProps = useMemo(
        () => ({
            publisher: {
                dataSource,
                fetchData,
                defaultValue: defaultInputValue,
            },
        }),
        [dataSource, defaultInputValue, fetchData],
    );

    const publisherStructure = useMemo<Array<FormStructure>>(
        () =>
            publisherSectionStructure({
                mode,
                adornment,
                autocompleteProps,
                headerChildren: overwriteHeaderChildren,
                headerSwitches: overwriteHeaderSwitches,
            }),
        [adornment, autocompleteProps, mode, overwriteHeaderChildren, overwriteHeaderSwitches],
    );

    return { publisherStructure };
};

export default usePublisherSectionStructure;
