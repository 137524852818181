import React from 'react';
import { List, ListItem } from '@mui/material';
import './MenuItems.css';
import { MenuItem, MenuItemProps } from '../../molecules';

export type MenuItemsProps = {
    items: MenuItemProps[];
    folded?: boolean;
};

const MenuItems: React.FunctionComponent<MenuItemsProps> = function (props) {
    const { items, folded = false } = props;

    return (
        <List
            className="menu-items"
            data-testid="menu-items"
        >
            {items.map((item) => (
                <ListItem
                    key={`menu-item-${item.identifier}`}
                    sx={
                        folded
                            ? {
                                  borderTop: '1px dashed',
                                  borderColor: 'grey.300',
                              }
                            : {}
                    }
                >
                    <MenuItem
                        {...item}
                        folded={folded}
                    />
                </ListItem>
            ))}
        </List>
    );
};

export default MenuItems;
