import { FlexyHeaderForm, Modalizer } from '@europrocurement/flexy-components';
import { Box, Button, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Formalite } from '@europrocurement/l2d-domain';
import FormalitySelect from '../../components/FormalitySelect';

type IncompatibilitiesModalProps = {
    isOpen: boolean;
    onModalClose: () => void;
    onSubmitModal: (selectedItems: number[]) => void;
    itemsSelected: number[];
    formality: Formalite;
};

const IncompatibilitiesModal: React.FunctionComponent<IncompatibilitiesModalProps> = function (
    props,
) {
    const { isOpen, onModalClose, onSubmitModal, itemsSelected, formality } = props;
    const [selectedItems, setSelectedItems] = useState(itemsSelected);

    const cancelModal = () => {
        onModalClose();
    };
    const submitModal = () => {
        setSelectedItems(selectedItems);
        onSubmitModal(selectedItems);
    };

    const onUpdateSelectedItems = (items: number[]) => {
        setSelectedItems(items);
    };

    useEffect(() => {
        setSelectedItems(itemsSelected);
    }, [isOpen, itemsSelected]);

    const submitBtn = (
        <Button
            key="incompatibility-submit_button"
            color="primary"
            variant="contained"
            onClick={submitModal}
            style={{ marginTop: '8px', marginBottom: '8px' }}
        >
            Confirmer
        </Button>
    );
    const cancelBtn = (
        <Button
            key="incompatibility-cancelbutton"
            color="primary"
            variant="outlined"
            onClick={cancelModal}
            style={{ marginTop: '8px', marginBottom: '8px' }}
        >
            Annuler
        </Button>
    );

    return (
        <Modalizer
            open={isOpen}
            onClose={cancelModal}
            maxWidth="lg"
            fullWidth
            modalActions={[cancelBtn, submitBtn]}
        >
            <Box>
                <FlexyHeaderForm
                    outlined
                    label="Modifier les incompatibilités"
                />
                <Box sx={{ marginTop: '25px', maxHeight: '60vh', overflowY: 'scroll' }}>
                    <Grid
                        container
                        gap="10px 0"
                    >
                        <FormalitySelect
                            selectedFormalityIds={itemsSelected}
                            onUpdate={onUpdateSelectedItems}
                            excludeItems={formality?.id ? [formality.id] : []}
                        />
                    </Grid>
                </Box>
            </Box>
        </Modalizer>
    );
};

export default IncompatibilitiesModal;
