import React, { useContext } from 'react';

import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { FlexyHeaderForm, ModalContext } from '@europrocurement/flexy-components';

import ICONS from '@b2d/icons';
import NoteObject from '../notes/NoteObject';

export type NoteModalFormFournisseurType = {
    noteFournisseur: string;
};

const NoteModalFormFournisseur: React.FunctionComponent<NoteModalFormFournisseurType> = function ({
    noteFournisseur,
}) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const theme: any = useTheme();
    const { modalActions } = useContext(ModalContext);

    return (
        <IconButton
            sx={{ margin: '0', padding: '0' }}
            aria-label="editable-notes-libres"
            size="small"
            onClick={(event) => {
                modalActions.call(
                    <Box sx={{ minWidth: '40vw' }}>
                        <FlexyHeaderForm
                            label={
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        width: '100%',
                                    }}
                                >
                                    <Typography
                                        variant="h2"
                                        color="secondary"
                                    >
                                        Note du fournisseur
                                    </Typography>
                                </Box>
                            }
                        />
                        <NoteObject
                            note={{
                                '@id': _.uniqueId(),
                                note: noteFournisseur,
                            }}
                            isEditable={false}
                        />
                    </Box>,
                );
                event.stopPropagation();
            }}
        >
            <FontAwesomeIcon
                icon={ICONS.actions.help}
                color={theme.palette.pink.main}
                beatFade
                style={{
                    animationIterationCount: 5,
                }}
            />
        </IconButton>
    );
};

export default NoteModalFormFournisseur;
