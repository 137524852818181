import React from 'react';
import { FlexyInput } from '@europrocurement/flexy-components';
import { inputDefaultProps } from '../FlexyForm';

export type ReadOnlyFieldProps = {
    inputLabel: string;
    value: string;
};

const ReadOnlyField: React.FunctionComponent<ReadOnlyFieldProps> = function ({
    inputLabel,
    value,
}) {
    return (
        <FlexyInput
            {...inputDefaultProps}
            variant="outlined"
            name="readOnlyField"
            type="displayData"
            inputlabel={inputLabel}
            value={value}
            readOnly
        />
    );
};

export default ReadOnlyField;
