import { useState, useCallback } from 'react';

/**
 * @typedef {Object} GetSwitchLockValuesProps
 * @property {'header' | 'folio' | 'publisher' | 'products' | 'totals'} sectionToBlock - The section to be toggled.
 * @property {() => void} [overwriteAction] - An optional action to execute when toggling.
 * @property {boolean} [isDependent=true] - Flag to determine if toggling should be dependent on other sections. This functionality is currently in development and may not work as expected.
 */
export type GetSwitchLockValuesProps = {
    sectionToBlock: 'header' | 'folio' | 'publisher' | 'products' | 'totals';
    overwriteAction?: () => void;
    isDependent?: boolean;
};

/**
 * @typedef {Object} HeaderSwitch
 * @property {string} label - The label for the switch.
 * @property {boolean} checked - The checked state of the switch.
 * @property {() => void} onChange - The function to call when the switch is toggled.
 */
export type HeaderSwitch = {
    label: string;
    checked: boolean;
    onChange: () => void;
};

/**
 * Custom hook to manage the state of switch locks for different sections.
 *
 * @returns {{
 *   getSwitchLockValues: (props: GetSwitchLockValuesProps) => HeaderSwitch;
 *   stateSwitchLockValues: {
 *     header: { value: boolean; set: (value: boolean) => void; };
 *     folio: { value: boolean; set: (value: boolean) => void; };
 *     publisher: { value: boolean; set: (value: boolean) => void; };
 *     products: { value: boolean; set: (value: boolean) => void; };
 *     totals: { value: boolean; set: (value: boolean) => void; };
 *   };
 * }}
 *
 * @description This hook provides functionalities to get switch lock values for specified sections and manage their states. The `getSwitchLockValues` function returns the switch state and a toggle function for a given section. The `stateSwitchLockValues` object contains the current value and setter function for each section.
 *
 * @note The `isDependent` functionality, which determines if the toggling should be dependent on other sections, is currently in development and may not work as expected.
 */
const useSwitchLockValues = () => {
    const [blockState, setBlockState] = useState({
        header: true,
        folio: true,
        publisher: true,
        products: true,
        totals: true,
    });

    const getSwitchLockValues = useCallback(
        ({
            sectionToBlock,
            overwriteAction,
            isDependent = true,
        }: GetSwitchLockValuesProps): HeaderSwitch => {
            const onChange = () => {
                if (isDependent) {
                    setBlockState((prev) => ({
                        header: sectionToBlock === 'header' ? !prev.header : true,
                        folio: sectionToBlock === 'folio' ? !prev.folio : true,
                        publisher: sectionToBlock === 'publisher' ? !prev.publisher : true,
                        products: sectionToBlock === 'products' ? !prev.products : true,
                        totals: sectionToBlock === 'totals' ? !prev.totals : true,
                    }));
                } else {
                    setBlockState((prev) => ({
                        ...prev,
                        [sectionToBlock]: !prev[sectionToBlock],
                    }));
                }

                if (overwriteAction) {
                    overwriteAction();
                }
            };

            return {
                label: 'Débloquer',
                checked: !blockState[sectionToBlock],
                onChange,
            };
        },
        [blockState],
    );

    return {
        getSwitchLockValues,
        stateSwitchLockValues: {
            header: {
                value: blockState.header,
                set: (value: boolean) => setBlockState((prev) => ({ ...prev, header: value })),
            },
            folio: {
                value: blockState.folio,
                set: (value: boolean) => setBlockState((prev) => ({ ...prev, folio: value })),
            },
            publisher: {
                value: blockState.publisher,
                set: (value: boolean) => setBlockState((prev) => ({ ...prev, publisher: value })),
            },
            products: {
                value: blockState.products,
                set: (value: boolean) => setBlockState((prev) => ({ ...prev, products: value })),
            },
            totals: {
                value: blockState.totals,
                set: (value: boolean) => setBlockState((prev) => ({ ...prev, totals: value })),
            },
        },
    };
};

export type UseSwitchLockValuesProps = ReturnType<typeof useSwitchLockValues>;
export default useSwitchLockValues;
