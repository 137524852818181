import React, { useCallback, useState, useEffect, MouseEvent, useRef } from 'react';

import md5 from 'md5';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/pro-regular-svg-icons';
import { faChevronDown } from '@fortawesome/pro-duotone-svg-icons';

import {
    AppBar,
    IconButton,
    Box,
    Toolbar,
    Button,
    Menu,
    Avatar,
    SxProps,
    Typography,
    Stack,
} from '@mui/material';

import {
    CustomizerReducerType,
    BDD_SWITCH_SOCIETES_FULLNAME_FROM_ID,
} from '@europrocurement/l2d-domain';
import { UseKeycloakService } from '@europrocurement/l2d-keycloak';

import ProfileDropdown from '../../molecules/ProfileDropdown';
import { FlexyLayoutState } from '../../../redux/storeConfig/store';
import { DropdownSociete } from '../../atoms';
import { useFullLayout } from '../../templates/FullLayout/FullLayoutContext';
import ExitButton from '../../atoms/ExitButton';

export type HeaderProps = {
    toggleSidebar?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    sx?: SxProps;
    drawerAvailability?: boolean;
    handleClickExitButton?: (event: MouseEvent<HTMLButtonElement>) => void;
};

const Header: React.FunctionComponent<HeaderProps> = function ({
    toggleSidebar,
    sx,
    drawerAvailability,
    handleClickExitButton,
}: HeaderProps) {
    const kc = UseKeycloakService();
    const xIdSociete = useSelector((state: FlexyLayoutState) => state.customizer.xIdSociete);

    // const kc = {
    //     isAutenticated : ()=>{return false;}
    // }

    // Action de redirection utilisée lorsque
    // le bouton menu est remplacé par la flèche de retour

    const userimg = useCallback(
        () => `https://www.gravatar.com/avatar/${md5(kc.getEmail())}`,
        [kc],
    );

    // userimg = "https://www.gravatar.com/avatar/"

    // const login = kc.getKc().login();
    const logout = useCallback(() => kc.getKc().logout(), [kc]);

    const [profilDropdownOpened, setProfilDropdwnOpened] = useState<Element | null>(null);

    const handleClose4 = () => setProfilDropdwnOpened(null);
    const openProfilDropdown = (event: MouseEvent<HTMLButtonElement>) => {
        if (event.target instanceof Element) setProfilDropdwnOpened(event.currentTarget);
    };
    const { enqueueSnackbar } = useSnackbar();

    const prevXIdSocieteRef = useRef<CustomizerReducerType['xIdSociete']>();

    const { toggleCustomizerDrawer } = useFullLayout();

    const oppenCustomizeFromProfile = () => {
        setProfilDropdwnOpened(null);
        toggleCustomizerDrawer();
    };

    useEffect(() => {
        const prevXIdSociete = prevXIdSocieteRef.current;

        if (prevXIdSociete !== undefined && prevXIdSociete !== xIdSociete) {
            enqueueSnackbar(
                <Stack
                    display="flex"
                    flexDirection="row"
                    alignItems="baseline"
                    gap={1}
                >
                    <Typography variant="caption">Nouvelle société sélectionnée :</Typography>
                    <Typography
                        variant="caption"
                        fontWeight="bold"
                    >
                        {BDD_SWITCH_SOCIETES_FULLNAME_FROM_ID[xIdSociete]}
                    </Typography>
                </Stack>,
                {
                    variant: 'info',
                    autoHideDuration: 5000,
                },
            );
        }

        prevXIdSocieteRef.current = xIdSociete;
    }, [xIdSociete, enqueueSnackbar]);

    return (
        <AppBar
            sx={{ backgroundColor: 'background.default', ...sx }}
            elevation={0}
        >
            <Toolbar>
                {drawerAvailability ? (
                    <>
                        {/* Pour le mode Desktop */}
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            onClick={toggleSidebar}
                            size="small"
                            sx={{
                                display: {
                                    lg: 'flex',
                                    xs: 'none',
                                },
                            }}
                        >
                            <FontAwesomeIcon icon={faBars} />
                        </IconButton>

                        {/* Pour le Mobile */}
                        <IconButton
                            size="small"
                            color="inherit"
                            aria-label="menu"
                            onClick={toggleSidebar}
                            sx={{
                                display: {
                                    lg: 'none',
                                    xs: 'flex',
                                },
                            }}
                        >
                            <FontAwesomeIcon
                                icon={faBars}
                                width="20"
                                height="20"
                            />
                        </IconButton>
                    </>
                ) : (
                    <ExitButton handleClickExitButton={handleClickExitButton} />
                )}
                {/* ------------ End Menu icon ------------- */}

                <Box flexGrow={1} />

                <DropdownSociete />

                <Box
                    sx={{
                        width: '1px',
                        backgroundColor: 'rgba(0,0,0,0.1)',
                        height: '25px',
                        ml: 1,
                        mr: 1,
                    }}
                />

                {/* Profile Dropdown */}
                <Button
                    aria-label="menu"
                    color="inherit"
                    aria-controls="profile-menu"
                    aria-haspopup="true"
                    onClick={openProfilDropdown}
                >
                    <Stack
                        alignItems="center"
                        display="flex"
                        flexDirection="row"
                        gap={1}
                    >
                        <Avatar
                            src={userimg()}
                            alt={userimg()}
                            sx={{
                                width: '30px',
                                height: '30px',
                            }}
                        />
                        <Typography variant="button">Bonjour, {kc.getName()}</Typography>
                        <FontAwesomeIcon
                            icon={faChevronDown}
                            flip={profilDropdownOpened ? 'vertical' : undefined}
                        />
                    </Stack>
                </Button>

                <Menu
                    id="profile-menu"
                    anchorEl={profilDropdownOpened}
                    open={!!profilDropdownOpened}
                    onClose={handleClose4}
                >
                    <ProfileDropdown
                        avatarSrc={userimg()}
                        logout={logout}
                        openSettings={oppenCustomizeFromProfile}
                    />
                </Menu>
            </Toolbar>
        </AppBar>
    );
};

export default Header;
