import { FlexySelect } from '@europrocurement/flexy-components';
import { Card, Theme, lighten } from '@mui/material';
import * as React from 'react';
import { useTheme } from '@mui/system';
import OriginHeader from './OriginHeader';

type CustomOfferCardProps = {
    options?: { id: number; label: string }[];
};

const CustomOfferCard: React.FunctionComponent<CustomOfferCardProps> = function (props) {
    const {
        options = [
            { id: 1, label: 'Offre 1 (défaut)' },
            { id: 2, label: 'Offre Perso' },
        ],
    } = props;

    const theme: Theme = useTheme();

    const isDefault = Math.random() > 0.3;

    const wrapperCardStyle = {
        boxShadow: 'none',
        border: '1px solid',
        backgroundColor: isDefault ? 'inherit' : lighten(theme.palette.primary.main, 0.97),
        borderColor: isDefault
            ? lighten(theme.palette.secondary.main, 0.8)
            : theme.palette.secondary.main,
        width: '100%',
    };

    return (
        <Card sx={wrapperCardStyle}>
            <OriginHeader />

            <hr />
            <FlexySelect
                options={options}
                value={options.find(({ id }: { id: number }) => id === (isDefault ? 1 : 2))}
            />
        </Card>
    );
};

export default CustomOfferCard;
