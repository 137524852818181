import * as React from 'react';

import { AddressCard, ModalContext } from '@europrocurement/flexy-components';

import AddressForm from '@b2d/pages/Prescripteurs/forms/AddressForm';

import { Address, Prescripteur } from '@europrocurement/l2d-domain';

import { Grid } from '@mui/material';
import { AdresseJsonldTiersRead } from '@europrocurement/l2d-domain/openApi/ApiTiers';
import CompanyInformationsCard from './CompanyInformationsCard';

type PrescriberInformationsProps = {
    prescriber: Prescripteur;
    afterSubmit: () => void;
};

const PrescriberInformations: React.FunctionComponent<PrescriberInformationsProps> = function (
    props,
) {
    const { prescriber, afterSubmit } = props;

    const { modalActions } = React.useContext(ModalContext);

    const { adresses } = prescriber;

    const mainAddress = adresses.find((address) => address.defaut);

    const handleEditAddress = (address: AdresseJsonldTiersRead) => {
        if (prescriber.id) {
            modalActions.call(
                <AddressForm
                    entity={address}
                    prescriber={prescriber}
                    afterSubmit={afterSubmit}
                />,
            );
        }
    };

    return (
        <Grid
            container
            spacing={2}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
            <Grid
                item
                xs={12}
                lg={6}
            >
                <CompanyInformationsCard afterSubmit={afterSubmit} />
            </Grid>

            <Grid
                item
                xs={12}
                lg={6}
            >
                <AddressCard
                    isBordered
                    address={mainAddress as Address}
                    onEdit={() => handleEditAddress(mainAddress as Address)}
                />
            </Grid>
        </Grid>
    );
};

export default PrescriberInformations;
