import { SelectInputOptions } from './types';

/** Specify the callbacks to determine either value and label format for a given option list */
export const formatOptionList = (
    unknownOptionList: object[],
    transformCallbacks: {
        value: (option: object) => unknown;
        label: (option: object) => string;
    },
): SelectInputOptions => {
    const { value, label } = transformCallbacks;

    const formatedOptions = unknownOptionList.map((option) => ({
        value: value(option),
        label: label(option),
    }));

    return formatedOptions;
};

/** Specify the value key and label key to format a given option list */
export function formatOptionsByKeys<T extends object[]>(
    unknownOptionList: T,
    valueKey: keyof T[0],
    labelKey: keyof T[0],
): SelectInputOptions {
    const formatedOptions = unknownOptionList.map((option: T[0]) => {
        if (!(valueKey in option)) {
            throw new Error('formatOptionsByKeys: The value key is not present in given object');
        }

        if (!(valueKey in option)) {
            throw new Error('formatOptionsByKeys: The value key is not present in given object');
        }

        if (!(labelKey in option)) {
            throw new Error('formatOptionsByKeys: The label key is not present in given object');
        }

        if (typeof option[labelKey] !== 'string') {
            throw new Error('formatOptionsByKeys: The label key must be a string');
        }

        return {
            value: option[valueKey],
            label: option[labelKey] as string,
        };
    });

    return formatedOptions;
}
