export const taxRates = {
    TN: 'Taux Normal',
    TI: 'Taux Intermédiaire',
    TR: 'Taux Réduit',
    TP: 'Taux Particulier',
    NS: 'Taux exonéré',
    default: 'Pas de taux',
};

export const articleUnits = {
    F: 'Forfait',
    C: 'Caractère',
    U: 'Unité',
    L: 'Ligne',
    default: "Pas d'unité pour cet article",
};

export const specificsArticleLabels = {
    section: 'section-title',
    lineBreak: 'line-break',
};

export const domainIds = {
    formality: 3,
};
