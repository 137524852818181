import React, { ChangeEvent, DragEvent, FunctionComponent, useState } from 'react';

import { Box, Button, Grid, Typography } from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowUp } from '@fortawesome/pro-duotone-svg-icons';

export type FileUploadProps = {
    accept: string;
    width?: string;
    height?: string;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    onDrop: (event: DragEvent<HTMLElement>) => void;
};

export const FileUpload: FunctionComponent<FileUploadProps> = function ({
    accept,
    width = '100%',
    height = '200px',
    onChange,
    onDrop,
}) {
    const [isHover, setIsHover] = useState<boolean>(false);

    const stopDefaults = (e: DragEvent) => {
        e.stopPropagation();
        e.preventDefault();
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        onChange(event);
    };

    const handleMouseEnter = () => setIsHover(true);
    const handleMouseLeave = () => setIsHover(false);

    const handleDrop = (event: DragEvent<HTMLElement>) => {
        stopDefaults(event);
        onDrop(event);
    };

    const dragEvents = {
        onMouseEnter: handleMouseEnter,
        onMouseLeave: handleMouseLeave,
        onDragEnter: (e: DragEvent) => {
            stopDefaults(e);
        },
        onDragLeave: (e: DragEvent) => {
            stopDefaults(e);
        },
        onDragOver: stopDefaults,
        onDrop: (e: DragEvent<HTMLElement>) => {
            handleDrop(e);
        },
    };

    return (
        <Grid
            container
            spacing={2}
        >
            <Grid
                item
                xs={12}
            >
                <label
                    htmlFor="file-upload"
                    {...dragEvents}
                >
                    <Box
                        width={width}
                        height={height}
                        sx={{
                            border: 3,
                            borderStyle: 'dashed',
                            borderColor: 'primary.main',
                            borderRadius: '30px',
                        }}
                    >
                        <Box
                            height="100%"
                            width="100%"
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Button
                                id="basic-button"
                                color="inherit"
                                component="label"
                            >
                                <Typography color="GrayText">
                                    <FontAwesomeIcon
                                        icon={faCloudArrowUp}
                                        bounce={isHover}
                                        size="2x"
                                    />
                                </Typography>
                                <input
                                    type="file"
                                    onChange={handleChange}
                                    accept={accept}
                                    multiple
                                    hidden
                                />
                            </Button>

                            <Typography
                                variant="caption"
                                color="GrayText"
                                sx={{
                                    opacity: 0.5,
                                }}
                            >
                                Glisser ou cliquer pour importer un fichier
                            </Typography>
                            <Typography
                                variant="caption"
                                color="GrayText"
                                sx={{
                                    opacity: 0.5,
                                }}
                            >
                                ({accept})
                            </Typography>
                        </Box>
                    </Box>
                </label>
            </Grid>
        </Grid>
    );
};
