import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { Route } from '../../../entities/route/route';

type RoutingSliceStateProps = {
    historySize: number;
    routes: Array<Route>;
    previousRoute?: Route;
};

const initialState: RoutingSliceStateProps = {
    historySize: 5,
    routes: [],
    previousRoute: undefined,
};

const setPreviousRoute = (state: RoutingSliceStateProps) => {
    const { routes } = state;
    let newPreviousRoute;

    if (routes.length > 1) {
        const lastElement = routes[routes.length - 1];
        const beforeLastElement = routes[routes.length - 2];

        if (lastElement !== beforeLastElement) {
            newPreviousRoute = beforeLastElement;
        }
    }

    return newPreviousRoute;
};

const removeOlderRouteAction = (state: RoutingSliceStateProps) => {
    const newRoutes = state.routes.slice(1); // Remove the oldest route

    const newState = {
        ...state,
        routes: newRoutes,
    };

    return {
        ...newState,
        previousRoute: setPreviousRoute(newState),
    };
};

const removeNewerRouteAction = (state: RoutingSliceStateProps) => {
    const newRoutes = state.routes.slice(0, -1);

    return {
        ...state,
        routes: newRoutes,
    };
};

const areRoutesEqual = (route1: Route, route2: Route): boolean =>
    route1.path.pathname === route2.path.pathname;

const pruneConsecutiveDuplicateRoutes = (state: RoutingSliceStateProps, newRoute: Route) => {
    const { routes } = state;
    const newRoutes = [...routes];

    // Remove duplicates from the end of the array
    while (newRoutes.length > 0 && areRoutesEqual(newRoutes[newRoutes.length - 1], newRoute)) {
        newRoutes.pop();
    }

    return newRoutes;
};

const addRouteAction = (state: RoutingSliceStateProps, action: PayloadAction<Route>) => {
    const newRoute = action.payload;

    // Remove duplicates from the end before adding newRoute
    const newRoutes = pruneConsecutiveDuplicateRoutes(state, newRoute);

    // Add newRoute to the routes array
    newRoutes.push(newRoute);

    if (newRoutes.length > state.historySize) {
        newRoutes.shift();
    }

    const newState = {
        ...state,
        routes: newRoutes,
    };

    return {
        ...newState,
        previousRoute: setPreviousRoute(newState),
    };
};

const routingSlice = createSlice({
    name: 'routing',
    initialState,
    reducers: {
        addRoute: addRouteAction,
        removeOlderRoute: removeOlderRouteAction,
        removeNewerRoute: removeNewerRouteAction,
    },
});

export const { addRoute, removeOlderRoute, removeNewerRoute } = routingSlice.actions;

export default routingSlice.reducer;
