import React, { useCallback, useState } from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { useSnackbar } from 'notistack';
import { Typography } from '@mui/material';
import {
    isProcessingMediaObject,
    MediaObject,
    MediaObjectApiObject,
} from '@europrocurement/l2d-domain';
import { MediaObjectApiGetMediaObjectItemRequest } from '@europrocurement/l2d-domain/openApi/ApiAchats';
import {
    AppDispatch,
    getFromSpecifiedDataSourceThunks,
    mediaObjectApi,
} from '@b2d/redux/RootStore';
import generateB2DPath from '@b2d/utils/generateB2DPath';

import { ACTIONS } from '@b2d/redux/FactureFormReducer';
import { inUseFactureModalMessages } from '@b2d/pages/Achats/constants/wording/modals';
import useHandleListParam from '@b2d/pages/Achats/views/lists/UnregisteredInvoicesViews/hooks/useHandleListParam';
import { getViewNameFromValue } from '@b2d/pages/Achats/views/lists/UnregisteredInvoicesViews/hooks/types';
import ConfirmationModalContent from '../../../modals/ConfirmationModalContent';

type UseCheckInTreatmentInvoiceProps = {
    getMediaObject: ReturnType<typeof getFromSpecifiedDataSourceThunks>;
    selectedDataSource?: MediaObjectApiObject;
};

const useCheckInTreatmentInvoice = ({
    getMediaObject,
    selectedDataSource,
}: UseCheckInTreatmentInvoiceProps) => {
    const dispatch = useDispatch<AppDispatch>();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const [selectedMediaObject, setSelectedMediaObject] = useState<MediaObject | undefined>(
        undefined,
    );

    const resetStates = useCallback(() => {
        // clean du state du formulaire
        dispatch({
            type: ACTIONS.RESET,
        });
    }, [dispatch]);

    type NavigateToMediaObjectProps = {
        mediaObjectId: number;
        openNewTab?: boolean;
        dataSourceName?: string;
    };

    const { applyListUrlParameter } = useHandleListParam();

    /**
     * Génère le chemin d'accès vers le formulaire de saisie d'une facture,
     * puis s'y dirige.
     *
     * @param mediaObject MediaObject
     * @returns void
     */
    const navigateToMediaObject = useCallback(
        ({
            mediaObjectId,
            openNewTab = false,
            dataSourceName = undefined,
        }: NavigateToMediaObjectProps) => {
            if (selectedDataSource) {
                resetStates();
            }

            const response = generateB2DPath('formmediaobject', { mediaObjectId });
            let fromUrlParam: string | undefined;

            if (response.status === 'STAY') {
                return;
            }

            if (response.status === 'KO') {
                enqueueSnackbar(<Typography>{response.message}</Typography>, {
                    variant: 'warning',
                });
                return;
            }

            /**
             * This is used to define the ThunkAction to use for "Facture Suivante" button
             */
            if (dataSourceName) {
                const listName = getViewNameFromValue(dataSourceName);

                if (listName) {
                    const listNameUrlParams = applyListUrlParameter(listName);
                    fromUrlParam =
                        listNameUrlParams && listNameUrlParams.has('from')
                            ? new URLSearchParams({
                                  from: listNameUrlParams.get('from') as string,
                              }).toString()
                            : undefined;
                }
            }

            if (openNewTab) {
                // Open in a new tab and include the search parameters if they exist
                const urlWithParams = fromUrlParam
                    ? `${response.path}?${fromUrlParam}`
                    : response.path;
                window.open(urlWithParams, '_blank', 'rel=noopener noreferrer');
                return;
            }

            navigate({ pathname: response.path, search: fromUrlParam });
        },
        [applyListUrlParameter, enqueueSnackbar, navigate, resetStates, selectedDataSource],
    );

    type DebutTraitementCheckModalProps = {
        mediaObjectToRegister: MediaObject;
        openNewTab?: boolean;
        dataSourceName?: string;
    };

    /**
     * Test si le MediaObject sélectionné est en cours d'utilisation.
     * Si oui, affiche une modal d'avertissement à l'utilisateur.
     * Sinon, se dirige vers le formulaire de saisie.
     *
     * @param mediaObject MediaObject
     * @returns void
     */
    const debutTraitementCheckModal = useCallback(
        async ({
            mediaObjectToRegister,
            openNewTab = false,
            dataSourceName = undefined,
        }: DebutTraitementCheckModalProps) => {
            if (!mediaObjectToRegister.id) {
                enqueueSnackbar(<Typography>L&apos;ID du PDF est introuvable !</Typography>, {
                    variant: 'error',
                });
                return;
            }

            const requestParameters: MediaObjectApiGetMediaObjectItemRequest = {
                id: `${mediaObjectToRegister.id}`,
            };

            const res = await mediaObjectApi.getMediaObjectItem(requestParameters);

            if (res.data) {
                setSelectedMediaObject(res.data as MediaObject);

                if (isProcessingMediaObject(res.data as MediaObject)) {
                    <ConfirmationModalContent
                        messages={inUseFactureModalMessages}
                        actionOnValidation={() => {
                            if (!selectedMediaObject) {
                                enqueueSnackbar(
                                    <Typography>Il n&apos;y a aucun PDF sélectionné !</Typography>,
                                    {
                                        variant: 'error',
                                    },
                                );
                                return;
                            }

                            if (selectedMediaObject.id) {
                                navigateToMediaObject({
                                    mediaObjectId: selectedMediaObject.id,
                                    openNewTab,
                                });
                            }
                        }}
                        actionOnCancellation={() => {
                            dispatch(getMediaObject({}));
                        }}
                    />;
                } else if (res.data.id) {
                    navigateToMediaObject({
                        mediaObjectId: res.data.id,
                        openNewTab,
                        dataSourceName,
                    });
                }
            }
        },
        [dispatch, enqueueSnackbar, getMediaObject, navigateToMediaObject, selectedMediaObject],
    );

    return {
        debutTraitementCheckModal,
    };
};

export default useCheckInTreatmentInvoice;
