import * as React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { FlexyHeaderForm, FlexySelect } from '@europrocurement/flexy-components';
import PreviewTool from './PreviewTool';

type OffersPreviewToolProps = {
    originId?: number;
};

/**
 * Ecran de previsualisation des Packages offres.
 *
 * - Selecteur d'origine
 *
 * -> Permet de prévisualiser l'offre d'une origine tel que paramétré pour le prescripteur
 */

type OfferSelection = {
    origin: number | null;
    offer: {
        packages: {
            title: string;
        }[];
    } | null;
};
const OffersPreviewTool: React.FunctionComponent<OffersPreviewToolProps> = function (props) {
    const { originId } = props;

    console.log(originId);

    const [selection] = React.useState<OfferSelection>({
        origin: originId || null,
        offer: null,
    });

    const fakeOffer = {
        packages: [
            {
                title: 'title',
            },
            {
                title: 'title',
            },
            {
                title: 'title',
            },
        ],
    };
    const selectedOrigin: number | null = selection.origin;
    const selectedOffer = selection.offer ?? fakeOffer;

    return (
        <Grid
            container
            spacing={4}
            rowSpacing={1}
        >
            <Grid
                item
                xl={8}
                lg={8}
            >
                <FlexyHeaderForm
                    label="Outil de prévisualisation"
                    outlined
                />
            </Grid>
            <Grid
                item
                xl={4}
                lg={4}
                sx={{ marginTop: '25px' }}
            >
                <FlexySelect
                    options={[{ id: 1, label: 'origine' }]}
                    placeholder="Selecteur d'origine"
                />
            </Grid>

            {!selectedOrigin &&
                false && ( // TODO Reactivate condition
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        sx={{ height: '50vh', width: '100%' }}
                    >
                        <Typography sx={{ opacity: '0.5' }}>
                            ( Veuillez selectionner une origine )
                        </Typography>
                    </Box>
                )}

            {selectedOffer && <PreviewTool offer={selectedOffer} />}
        </Grid>
    );
};

export default OffersPreviewTool;
