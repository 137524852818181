export const downloadFromUrl = function (url: string, name: string) {
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    // link.type = 'application/octet-stream';
    link.download = name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};
