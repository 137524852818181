import React, { FunctionComponent } from 'react';

import { GridIndicator } from '@europrocurement/flexy-components';
import { Box } from '@mui/system';
import { Grid } from '@mui/material';
import { ReadOnlyField, defaultGridProps } from '@europrocurement/flexy-form';

export type ViewFolioPublicationSectionProps = {
    numeroDossier: string;
    numeroPrestation: string;
    journal: string;
    dateParution: string;
    libelleAnnonceur: string;
};

const ViewFolioPublicationSection: FunctionComponent<ViewFolioPublicationSectionProps> = function ({
    numeroDossier,
    numeroPrestation,
    journal,
    dateParution,
    libelleAnnonceur,
}) {
    return (
        <Box sx={{ marginTop: '10px' }}>
            <GridIndicator sx={{ display: 'none' }} />
            <Grid
                container
                {...defaultGridProps}
            >
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                >
                    <ReadOnlyField
                        inputLabel="Numéro de dossier"
                        value={numeroDossier || 'N/A'}
                    />
                </Grid>

                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                >
                    <ReadOnlyField
                        inputLabel="Numéro de prestation"
                        value={numeroPrestation || 'N/A'}
                    />
                </Grid>

                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    lg={4}
                >
                    <ReadOnlyField
                        inputLabel="Journal"
                        value={journal || 'N/A'}
                    />
                </Grid>

                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    lg={4}
                >
                    <ReadOnlyField
                        inputLabel="Date de parution"
                        value={dateParution || 'N/A'}
                    />
                </Grid>

                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    lg={4}
                >
                    <ReadOnlyField
                        inputLabel="Annonceur"
                        value={libelleAnnonceur || 'N/A'}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export default ViewFolioPublicationSection;
