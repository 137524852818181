/* eslint-disable @typescript-eslint/no-unused-vars */

import {
    SliceFactory,
    FetchCollectionData,
    FetchItemDataType,
    ApiCollectionResponse,
} from '@europrocurement/l2d-redux-utils';
import {
    ConfigurationParameters as DossiersConfigurationParameters,
    Configuration,
    DossierApi,
    DossierDossierRead,
    DossierApiGetDossierCollectionRequest,
    DossierApiGetDossierItemRequest,
} from '../../../openApi/ApiDossiers';
import { DOSSIERS_REDUCER_NAME } from '../constants';

export type DossierApiObject = DossierDossierRead & Record<string, unknown>;

export type Dossier = DossierApiObject;

export const DOSSIERS_SLICE_NAME = 'dos';

export const DOSSIER_AUTOCOMPLETE_DATASOURCE_NAME = 'autocompleteDossiers';

export const additionalDossiersDataSources: Array<string> = [DOSSIER_AUTOCOMPLETE_DATASOURCE_NAME];

export const mapperDossier = function (dos: DossierApiObject) {
    return {
        ...dos,
    } as Dossier;
};

export function createDossierSlice(configuration: DossiersConfigurationParameters) {
    const conf = new Configuration(configuration);
    const api = new DossierApi(conf);

    const fetchDossiersCollection: FetchCollectionData<DossierApiObject> = ({
        search,
        pagination,
        filters,
        orders,
        xIdSociete,
        rootstate,
    }) => {
        const page = pagination.page + 1;
        const { itemsPerPage } = pagination;
        let numero;
        const numero2 = undefined;
        let societe = xIdSociete;
        const societe2 = undefined;
        let prestationsNumero;
        let prestationsJalNom;
        let prestationsAnnonceur;
        let prestationsDateParutionDemandeeBefore;
        const prestationsDateParutionDemandeeStrictlyBefore = undefined;
        let prestationsDateParutionDemandeeAfter;
        const prestationsDateParutionDemandeeStrictlyAfter = undefined;
        const options = undefined;
        const deleted = undefined;
        const orderId = undefined;

        if (filters.numero) {
            numero = filters.numero as number;
        }

        if (filters.societe) {
            societe = filters.societe as number;
        }

        if (filters.prestationsNumero) {
            prestationsNumero = filters.prestationsNumero as string;
        }
        if (filters.prestationsJalNom) {
            prestationsJalNom = filters.prestationsJalNom as string;
        }
        if (filters.prestationsAnnonceur) {
            prestationsAnnonceur = filters.prestationsAnnonceur as string;
        }

        if (
            filters.prestationsDateParutionDemandeeBefore ||
            filters.prestationsDateParutionDemandeeAfter
        ) {
            if (filters.prestationsDateParutionDemandeeBefore) {
                prestationsDateParutionDemandeeBefore =
                    filters.prestationsDateParutionDemandeeBefore as string;
            }

            if (filters.prestationsDateParutionDemandeeAfter) {
                prestationsDateParutionDemandeeAfter =
                    filters.prestationsDateParutionDemandeeAfter as string;
            }
        } else if (filters.prestationsDateParution) {
            if (filters.prestationsDateParution) {
                prestationsDateParutionDemandeeAfter = filters.prestationsDateParution as string;
                const dateP1 = new Date(filters.prestationsDateParution as string).getDate() + 1;
                prestationsDateParutionDemandeeBefore = (
                    new Date(
                        new Date(filters.prestationsDateParution as string).setDate(dateP1),
                    ) as Date
                ).toISOString() as string;
            }
        }

        if (search && search !== '') {
            numero = parseFloat(search);
        }

        const requestParameters: DossierApiGetDossierCollectionRequest = {
            xIdSociete,
            page,
            itemsPerPage,
            numero,
            numero2,
            prestationsNumero,
            prestationsJalNom,
            prestationsAnnonceur,
            societe,
            societe2,
            prestationsDateParutionDemandeeBefore,
            prestationsDateParutionDemandeeStrictlyBefore,
            prestationsDateParutionDemandeeAfter,
            prestationsDateParutionDemandeeStrictlyAfter,
            deleted,
            orderId,
        };

        return api
            .getDossierCollection(requestParameters, options)
            .then((res) => res.data) as Promise<ApiCollectionResponse<DossierApiObject>>;
    };

    const searchByNumeroPresta: FetchCollectionData<DossierApiObject> = ({
        search,
        pagination,
        filters,
        orders,
        xIdSociete,
        rootstate,
    }) => {
        const requestParameters: DossierApiGetDossierCollectionRequest = {
            xIdSociete,
            page: pagination.page + 1,
            itemsPerPage: pagination.itemsPerPage,
            prestationsNumero: search,
        };

        return api.getDossierCollection(requestParameters).then((res) => res.data) as Promise<
            ApiCollectionResponse<DossierApiObject>
        >;
    };

    const fetchDossiersItem: FetchItemDataType<DossierApiObject> = ({
        idItem,
        search,
        pagination,
        filters,
        orders,
        xIdSociete,
        rootstate,
    }) => {
        const requestParameters: DossierApiGetDossierItemRequest = {
            id: String(idItem),
            xIdSociete,
        };

        return api
            .getDossierItem(requestParameters)
            .then((res) => res.data) as Promise<DossierApiObject>;
    };

    const { slice: dossierSlice, dataSourcesThunks: dossierDataSourcesThunks } =
        SliceFactory.createSlice<DossierApiObject, Dossier>(
            DOSSIERS_SLICE_NAME,
            DOSSIERS_REDUCER_NAME,
            additionalDossiersDataSources,
            fetchDossiersCollection,
            fetchDossiersItem,
            mapperDossier,
            {},
            {
                data: {
                    searchByNumeroPresta,
                },
            },
        );

    return { dossierSlice, dossierDataSourcesThunks };
}
