/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {
    FlexyForm,
    FormStructure,
    FormObject,
    ExtandedFormContext,
} from '@europrocurement/flexy-form';

import Person from '@mui/icons-material/Person';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import { contactsApi, prescribersApi } from '@b2d/redux/RootStore';

import { SubmitHandler, useForm } from 'react-hook-form';
import { UseKeycloakService } from '@europrocurement/l2d-keycloak';
import { Box, Grid, TextField, Typography } from '@mui/material';
import { FlexyFormLabel, FlexyHeaderForm, Switch } from '@europrocurement/flexy-components';
import { useSnackbar } from 'notistack';

import { AxiosResponse } from 'axios';
import { ContactsContactJsonldContactRead } from '@europrocurement/l2d-domain/openApi/ApiTiers';

// Contact Icons
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import PhoneIcon from '@mui/icons-material/Phone';
import MailIcon from '@mui/icons-material/MailOutline';

// Contact validation Regex
import {
    EMAIL_VALIDATION_REGEX,
    LANDLINE_PHONE_NUMBER_REGEX,
    MOBILE_PHONE_NUMBER_REGEX,
} from '@b2d/validation/regex';
import { FormInputInformations } from '@europrocurement/flexy-form/components/FormStructureRenderer/FormItemRenderer';
import { useSociete } from '@b2d/hooks/societeHooks';
import { Prescripteur } from '@europrocurement/l2d-domain';
import { civilitiesOptions } from './utils';
import { PrescriberFormProps } from './common';

export type ContactFormProps = PrescriberFormProps & {
    entity?: any; // todo
};

type FormStructureGenerator = (input: unknown) => FormStructure;

type ContactType = 'landline' | 'mobile' | 'email';
type ContactFieldValues = {
    telephone?: string;
    mobile?: string;
    email?: string;
};

type ContactTypeConfiguration = {
    icon: React.ElementType;
    inputKey: string;
    key: string;
    formKey: string;
    validationFeedback: string;
    headerLabel: string;
    regex: RegExp;
    requiredFeedback: string;
    invalidPatternFeedBack: string;
};

type ContactTypeConfigurations = {
    email: ContactTypeConfiguration;
    landline: ContactTypeConfiguration;
    mobile: ContactTypeConfiguration;
};

/**
 * Contact type configuration :
 *
 * Usage :
 * - FormStructure generation
 * - Data validation
 * - User feedback configuration
 * - Related Icon
 */
const contactTypeConfiguration: ContactTypeConfigurations = {
    email: {
        icon: MailIcon,
        inputKey: 'Email',
        key: 'email',
        formKey: 'emailsContact',
        validationFeedback: 'Cet email est déja utilisé',
        headerLabel: 'Email(s)',
        regex: EMAIL_VALIDATION_REGEX,
        requiredFeedback: "Vous devez renseigner l'email",
        invalidPatternFeedBack: "L'Email est invalide",
    },
    landline: {
        icon: PhoneIcon,
        inputKey: 'Téléphone fixe',
        key: 'telephone',
        formKey: 'telephonesContact',
        validationFeedback: 'Ce numéro de téléphone est déja utilisé',
        headerLabel: 'Téléphone(s) fixe',
        regex: LANDLINE_PHONE_NUMBER_REGEX,
        requiredFeedback: 'Vous devez renseigner un numero de téléphone fixe',
        invalidPatternFeedBack: 'Le numero de téléphone fixe est invalide',
    },
    mobile: {
        icon: PhoneAndroidIcon,
        inputKey: 'Mobile',
        key: 'mobile',
        formKey: 'mobilesContact',
        validationFeedback: 'Ce numéro de téléphone est déja utilisé',
        headerLabel: 'Téléphone(s) mobile',
        regex: MOBILE_PHONE_NUMBER_REGEX,
        requiredFeedback: 'Vous devez renseigner un numero de téléphone mobile',
        invalidPatternFeedBack: 'Le numéro de mobile est invalide',
    },
};

const ContactForm: React.FunctionComponent<ContactFormProps> = function (props) {
    const { afterSubmit, entity = {}, isCreate = false, prescriber } = props;

    const xIdSociete = useSociete();

    type ContactForm = {
        IsExit?: boolean;
        administrateur: boolean;
        civilite: number; // old ContactJsonldTiersReadCiviliteEnum
        comptabilite: boolean;
        emailsContact: { email: string; defaut: boolean; factureEmail: boolean }[];
        fonction: string;
        nomContact: string;
        note: string;
        prenomContact: string;
        principal: boolean;
        telephonesContact: { telephone: string; defaut: boolean }[];
        mobilesContact: { mobile: string; defaut: boolean }[];
        faxsContact: never[]; // old []
        factureParMail: boolean;
    };

    const defaultFormValues = {
        administrateur: false,
        civilite: null,
        comptabilite: false,
        emailsContact: [{ email: '', defaut: true, factureEmail: false }],
        fonction: '',
        nomContact: '',
        note: isCreate ? '' : entity.note,
        prenomContact: '',
        principal: false,
        telephonesContact: isCreate
            ? [{ telephone: '', defaut: true }]
            : entity.telephonesContact.concat(
                  entity.mobilesContact.map((mobileContact: any) => ({
                      type: 'telephone',
                      telephone: mobileContact.mobile,
                  })),
              ),
        faxsContact: [],
    };

    const getEntity = () => {
        const { civilite } = entity;

        const entityCivilityOption = civilitiesOptions.find(({ value }) => value === civilite);

        return {
            ...entity,
            civilite: entityCivilityOption,
        };
    };

    // FormContext (Access formState from parent)
    const formContext = useForm({
        mode: 'onTouched',
        defaultValues: isCreate ? defaultFormValues : getEntity(),
    });

    const { enqueueSnackbar } = useSnackbar();
    // FormState
    const [formContactObject] = React.useState<Partial<ContactForm>>(isCreate ? {} : entity);
    const [isMainContactEditable] = React.useState<boolean>(
        isCreate ? true : entity.principal === false,
    );
    const [isBillingContactEditable] = React.useState<boolean>(
        isCreate ? true : entity.comptabilite === false,
    );
    const [isMainContact, setIsMainContact] = React.useState<boolean>(
        isCreate ? false : entity.principal,
    );
    const [isBillingContact, setIsBillingContact] = React.useState<boolean>(
        isCreate ? false : entity.comptabilite,
    );
    const [noteHasAlreadyBeenFocused, setNoteHasAlreadyBeenFocused] =
        React.useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

    const { id: prescriberId } = prescriber;

    const keycloakService = UseKeycloakService();
    const currentUserName = keycloakService.getName();
    const todayDate = new Date().toLocaleDateString();

    const noteField = formContext.register('note');

    const setCaretPosition = (introLength: number) => {
        const textAreas = document.getElementsByTagName('textarea');
        const noteTextArea = textAreas.namedItem('note_textarea');

        if (noteTextArea) {
            noteTextArea.focus();
            noteTextArea.setSelectionRange(introLength - 1, introLength); // Place caret at new line break
        }
    };

    const setNoteIntroduction = (introduction: string) => {
        formContext.setValue('note', introduction + (formContactObject.note ?? ''));
    };

    const onNoteFocus = () => {
        const noteIntroduction = `${todayDate} - ${currentUserName} : \n\n`;

        if (!noteHasAlreadyBeenFocused) {
            setNoteIntroduction(noteIntroduction);
            setCaretPosition(noteIntroduction.length - 1);
            setNoteHasAlreadyBeenFocused(true);
        }
    };

    const reformatedContactData = (contact: any) => {
        const { note } = formContext.getValues();

        return {
            ...contact,
            note,
            civilite: contact.civilite.value,
        };
    };

    const createContact: SubmitHandler<FormObject> = async (contact: unknown) => {
        // Todo type param

        if (prescriber && prescriberId) {
            await prescribersApi.createPrescripteurContactContactsCollection({
                xIdSociete,
                tiers: prescriberId,
                contactsContactJsonldContactCreate: reformatedContactData(contact),
            });
            afterSubmit();
            formContext.reset();
        }
    };

    /**
     * Unique contact constraint. Check duplicate entries in form for a given contact type
     * @param phoneValue
     * @param contactType
     * @returns
     */
    const uniqueContactValidation = (phoneValue: string, contactType: ContactType) => {
        const {
            key: accessorKey,
            formKey,
            validationFeedback,
        } = contactTypeConfiguration[contactType];
        const formValues = formContext.getValues(formKey) as ContactFieldValues[];
        const formFields = formValues.map(
            (formValue: { [index: string]: string }) => formValue[accessorKey],
        );

        return (
            formFields.filter((value: string) => value === phoneValue).length === 1 ||
            validationFeedback
        );
    };

    /**
     * Method that sanitize id key :
     * - remove id if not present
     * - parse id if present
     * @param contacts
     * @returns
     */
    const sanitizeContactId = (contacts: any) => {
        const contactHasId = (contactId: any) => contactId !== undefined && contactId !== '';

        return contacts.map((contact: any) => {
            if (contactHasId(contact.id) === false) {
                // eslint-disable-next-line no-param-reassign
                delete contact.id;
            }

            return {
                ...contact,
                ...(contactHasId(contact.id) && { id: parseInt(contact.id, 10) }),
            };
        });
    };

    const toasterFeedBack = (
        results: PromiseSettledResult<AxiosResponse<ContactsContactJsonldContactRead, any>>[],
    ) => {
        const numberOfPromises = results.length;

        const errorsIndexes = results.reduce((accumulator: number[], currentValue, index) => {
            if (currentValue.status === 'rejected') {
                accumulator.push(index);
            }
            return accumulator;
        }, []);

        const hasError = errorsIndexes.length > 0;
        const totallyRejected = errorsIndexes.length === numberOfPromises;

        const getErrorLabel = (index: number) => {
            switch (index) {
                case 0:
                    return 'des informations de contact';
                case 1:
                    return 'du téléphone';
                case 2:
                    return "de l'email";
                default:
                    return '';
            }
        };

        if (totallyRejected) {
            enqueueSnackbar('Une erreur est survenue', { variant: 'error' });
        } else if (hasError) {
            const errorToasts = errorsIndexes.map(
                (errorIndex) => `Echec de la mise à jour ${getErrorLabel(errorIndex)}`,
            );

            errorToasts.forEach((errorToast) => {
                enqueueSnackbar(errorToast, { variant: 'error' });
            });
        } else {
            enqueueSnackbar('Modifications effectuées avec succès', { variant: 'success' });
        }
    };

    /**
     * Method that reformat contact payload for api update (sanitize id key)
     * @param contact
     */
    const getFormatedContacts = (contact: any) => {
        const { telephonesContact, emailsContact, mobilesContact } = contact;

        return {
            telephonesContact: sanitizeContactId(telephonesContact),
            emailsContact: sanitizeContactId(emailsContact),
            mobilesContact: sanitizeContactId(mobilesContact),
        };
    };

    const proceedUpdate = async (prescriberToEdit: Prescripteur, contact: any) => {
        if (!prescriberToEdit.id) {
            throw new Error('missing prescriber id');
        }

        // Prepare requests
        const { telephonesContact, emailsContact, mobilesContact } = getFormatedContacts(contact);

        const updatePromises = [
            // Contact main informations (base model without relations)
            contactsApi.updateContactContactsItem({
                xIdSociete,
                id: contact.id,
                contactsContactJsonldContactUpdate: reformatedContactData(contact),
            }),
            // Relations - Conta    ct phones (phone & mobile)
            contactsApi.updateTelephonesContactPrescripteurContactsItem({
                xIdSociete,
                id: contact.id,
                tiers: prescriberToEdit.id.toString(),
                contactsContactJsonldContactWriteTelsPrescripteur: { telephonesContact },
            }),
            contactsApi.updateMobilesContactPrescripteurContactsItem({
                xIdSociete,
                id: contact.id,
                tiers: prescriberToEdit.id.toString(),
                contactsContactJsonldContactWriteMobilesPrescripteur: { mobilesContact },
            }),
            // Contact email
            contactsApi.updateEmailsContactPrescripteurContactsItem({
                xIdSociete,
                id: contact.id,
                tiers: prescriberToEdit.id.toString(),
                contactsContactJsonldContactWriteEmailsPrescripteur: { emailsContact },
            }),
        ];

        // // // Proceed update

        setIsSubmitting(true);
        Promise.allSettled(updatePromises).then((results) => {
            toasterFeedBack(results);
            setIsSubmitting(false);
            afterSubmit();
            formContext.reset();
        });
    };

    const updateContact: SubmitHandler<FormObject> = async (contact: any) => {
        // Todo type param
        if (prescriber && prescriber.id) {
            proceedUpdate(prescriber, contact);
        }
    };

    /**
     * Function that handle default flag change :
     * It passes default to true for the triggered input / all others defaults contacts values are set to false
     * @param contactType
     * @param inputInformations
     * @param extandedFormContext
     */
    const handleContactDefault = (
        contactType: ContactType,
        inputInformations: FormInputInformations,
        extandedFormContext: ExtandedFormContext,
    ) => {
        const { formKey, key } = contactTypeConfiguration[contactType];
        const { objectPath } = inputInformations;
        const newContactObject = extandedFormContext.getValue(objectPath);

        const contactValues = formContext.getValues(formKey);

        const updatedDefaultContact = contactValues.map((contactValue: any) => {
            const isUpdatedContact = contactValue[key] === newContactObject[key];

            if (isUpdatedContact) {
                return {
                    ...contactValue,
                    defaut: true,
                };
            }
            return {
                ...contactValue,
                defaut: false,
            };
        });

        formContext.setValue(formKey, updatedDefaultContact);
    };

    /**
     * Render subform header based on contact type
     * @param contactType
     * @returns
     */
    const subFormContactHeader = (contactType: ContactType) => {
        const { icon: Icon, headerLabel } = contactTypeConfiguration[contactType];
        return (
            <Box
                display="flex"
                alignItems="center"
            >
                <Icon sx={{ marginRight: '10px' }} />
                <p>{headerLabel}</p>
            </Box>
        );
    };

    const getContactTypeHeader: FormStructureGenerator = (contactType: ContactType) => {
        const name = `${contactType}_header`;
        return {
            xs: 12,
            lg: 4,
            type: 'customItem',
            renderField: () => subFormContactHeader(contactType),
            name,
        };
    };

    /**
     * Generate contact formStructure based on contactType
     * @param contactType
     * @returns
     */
    const getContactFormStructure: FormStructureGenerator = (contactType: ContactType) => {
        const { inputKey, key, formKey, requiredFeedback, regex, invalidPatternFeedBack } =
            contactTypeConfiguration[contactType];

        return {
            xs: 12,
            lg: 4,
            type: 'subformarray',
            name: formKey,
            defaultLength: 1,
            minLength: contactType === 'email' ? 1 : 0,
            maxLength: 5,
            structure: [
                {
                    type: 'text',
                    name: key,
                    inputlabel: inputKey,
                    rules: {
                        required: requiredFeedback,
                        pattern: {
                            value: regex,
                            message: invalidPatternFeedBack,
                        },
                        validate: {
                            unique: (fieldValue: string) =>
                                uniqueContactValidation(fieldValue, contactType),
                        },
                    },
                },
                {
                    type: 'boolean',
                    name: 'defaut',
                    inputlabel: 'Contact par défaut',
                    defaultChecked: false,
                    xs: 12,
                    onChangeInput: (
                        event: React.ChangeEvent<HTMLInputElement>,
                        base: FormInputInformations,
                        extandedFormContext: ExtandedFormContext,
                    ) => {
                        handleContactDefault(contactType, base, extandedFormContext);
                    },
                },
                {
                    type: 'hidden',
                    name: 'id',
                },
            ],
        };
    };

    const contactFormStructure: FormStructure[] = [
        {
            type: 'header',
            label: (
                <>
                    <Person sx={{ marginRight: '5px' }} />
                    Informations contact
                </>
            ),
            sx: {
                marginTop: '0px',
            },
            name: 'info',
        },
        {
            type: 'customItem',
            name: 'principal',
            xs: 12,
            sm: 12,
            md: 6,
            lg: 6,
            renderField: (currentFormContext) => {
                const onChange = (event: React.ChangeEvent<HTMLInputElement>, value: boolean) => {
                    currentFormContext.setValue('principal', value);
                    setIsMainContact(value);
                };

                return (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'baseline',
                        }}
                    >
                        <FlexyFormLabel data-testid="test-id-FlexyInput-label">
                            <Typography component="span">Contact principal</Typography>
                        </FlexyFormLabel>
                        <Switch
                            type="checkbox"
                            value={isMainContact}
                            onChange={onChange}
                            disabled={isMainContactEditable === false}
                        />
                    </Box>
                );
            },
        },
        {
            type: 'customItem',
            name: 'comptabilite',
            xs: 12,
            sm: 12,
            md: 6,
            lg: 6,
            renderField: (currentFormContext) => {
                const onChange = (event: React.ChangeEvent<HTMLInputElement>, value: boolean) => {
                    currentFormContext.setValue('comptabilite', value);
                    setIsBillingContact(value);
                };

                return (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'baseline',
                        }}
                    >
                        <FlexyFormLabel data-testid="test-id-FlexyInput-label">
                            <Typography component="span">Comptabilité</Typography>
                        </FlexyFormLabel>
                        <Switch
                            type="checkbox"
                            value={isBillingContact}
                            onChange={onChange}
                            disabled={isBillingContactEditable === false}
                        />
                    </Box>
                );
            },
        },
        {
            type: 'boolean',
            defaultValue: false,
            name: 'IsExit',
            inputlabel: 'A Quitté',
            xs: 12,
            sm: 12,
            md: 6,
            lg: 6,
        },
        {
            type: 'boolean',
            name: 'administrateur',
            defaultValue: false,
            inputlabel: 'Administrateur',
            xs: 12,
            sm: 12,
            md: 6,
            lg: 6,
        },
        {
            type: 'select',
            inputlabel: 'Civilité',
            name: 'civilite',
            rules: {
                required: 'Vous devez renseigner la civilité',
            },
            xs: 12,
            sm: 12,
            md: 6,
            lg: 6,
            options: civilitiesOptions,
        },
        {
            type: 'text',
            name: 'nomContact',
            inputlabel: 'Nom',
            xs: 12,
            sm: 12,
            md: 6,
            lg: 6,
            rules: {
                required: 'Vous devez renseigner le nom',
            },
        },
        {
            type: 'text',
            name: 'prenomContact',
            inputlabel: 'Prénom',
            xs: 12,
            sm: 12,
            md: 6,
            lg: 6,
            rules: {
                required: 'Vous devez renseigner le prénom',
            },
        },
        {
            type: 'text',
            name: 'fonction',
            inputlabel: 'Fonction',
            xs: 12,
            sm: 12,
            md: 6,
            lg: 6,
        },
        {
            type: 'header',
            label: (
                <>
                    <ContactMailIcon sx={{ marginRight: '5px' }} />
                    Coordonnées
                </>
            ),
            name: 'coord',
        },
        getContactTypeHeader('landline'),
        getContactTypeHeader('mobile'),
        getContactTypeHeader('email'),
        getContactFormStructure('landline'),
        getContactFormStructure('mobile'),
        getContactFormStructure('email'),
    ];

    return (
        <Grid
            container
            spacing={3}
        >
            {/* contact form */}
            <Grid
                item
                lg={12}
            >
                <FlexyForm
                    formContext={formContext}
                    formObject={isCreate ? defaultFormValues : getEntity()}
                    formStructure={contactFormStructure}
                    onSubmit={isCreate ? createContact : updateContact}
                    isSubmitting={isSubmitting}
                />
            </Grid>
            {/* note form */}
            <Grid
                item
                lg={12}
            >
                <FlexyHeaderForm
                    label="Notes"
                    outlined
                    sx={{ margin: 0 }}
                />
                <TextField
                    id="note_textarea"
                    sx={{ marginTop: 2 }}
                    variant="outlined"
                    onFocus={() => onNoteFocus()}
                    fullWidth
                    multiline
                    minRows={15}
                    maxRows={25}
                    placeholder="Notes"
                    {...noteField}
                />
            </Grid>
        </Grid>
    );
};

export default ContactForm;
