import { formaliteApi } from '@b2d/redux/subReducers/FormalityReducer';

const endpoints = {
    list: (...args: Parameters<typeof formaliteApi.apiFormalitesGetCollection>) =>
        formaliteApi.apiFormalitesGetCollection(...args),
    update: (...args: Parameters<typeof formaliteApi.apiFormalitesIdPut>) =>
        formaliteApi.apiFormalitesIdPut(...args),
};

const model = {
    ...endpoints,
};

export default model;
