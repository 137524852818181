import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { ReleveAchatJsonldReleveAchatReadReleveAchatReadItemTimestampableBlameambleGroup } from '@europrocurement/l2d-domain/openApi/ApiAchats';
import {
    AppDispatch,
    FactureachatSelector,
    MediaObjectSelector,
    customizerSelector,
    getFactureAchatFromStatement,
    selectMediaObject,
} from '@b2d/redux/RootStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import {
    CenterCircularProgress,
    DataItems,
    TopbarHeight,
    footerHeight,
} from '@europrocurement/flexy-components';
import { Box, Button, Card, Grid } from '@mui/material';
import { DataSource } from '@europrocurement/l2d-redux-utils';
import {
    FACTURES_ACHATS_FROM_STATEMENT_DATASOURCE_NAME,
    FactureAchat,
    MediaObject,
} from '@europrocurement/l2d-domain';
import { jsonLdIdScraper } from '@europrocurement/l2d-utils';
import { FilterDatatable } from '@europrocurement/flexy-datatable';
import { faCheck, faLeftFromLine } from '@fortawesome/pro-duotone-svg-icons';
import { navigateToNewFacture } from '@b2d/utils/navigationHelper';
import { MediaObjectReader } from '@b2d/pages/Achats/components/widgets/MediaObjectReader';
import FactureAchatList from '@b2d/pages/Achats/components/lists/FactureAchatList';
import { useNavigateToStatementList } from '@b2d/pages/Achats/achatRouterHooks';
import models from '@b2d/pages/Achats/models';
import useShowMessage from '@b2d/hooks/useShowMessage';

type StatementInvoicesListProps = {
    displayActions: boolean;
};

const StatementInvoicesList: FunctionComponent<StatementInvoicesListProps> = function ({
    displayActions = false,
}: StatementInvoicesListProps) {
    const { statementid } = useParams();
    const { xIdSociete } = useSelector(customizerSelector);
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const showMessage = useShowMessage();

    const mediaObjectDataSource: DataSource<MediaObject> = useSelector(MediaObjectSelector).main;
    const mediaObjectSelected: MediaObject = useSelector(MediaObjectSelector).main.selected;

    const facturesAchatsFromStatementDataSource: DataSource<FactureAchat> =
        useSelector(FactureachatSelector)[FACTURES_ACHATS_FROM_STATEMENT_DATASOURCE_NAME];

    const [currentStatement, setCurrentStatement] =
        useState<ReleveAchatJsonldReleveAchatReadReleveAchatReadItemTimestampableBlameambleGroup>();
    const [sum, setSum] = useState<number>(0);

    const navigateToList = useNavigateToStatementList();

    /**
     * Fetch invoices associated with the statement.
     */
    const updateFilter = useCallback(
        (key: string, value: unknown) => {
            dispatch({
                type: `${facturesAchatsFromStatementDataSource.slicename}/set${facturesAchatsFromStatementDataSource.name}Filter`,
                payload: { key, value },
            });
        },
        [
            dispatch,
            facturesAchatsFromStatementDataSource.slicename,
            facturesAchatsFromStatementDataSource.name,
        ],
    );

    const isFilterCorrect = useCallback(
        (filterKey: string, expectedValue: unknown) => {
            const filters = facturesAchatsFromStatementDataSource.filters || {};
            return filters[filterKey] === expectedValue;
        },
        [facturesAchatsFromStatementDataSource.filters],
    );

    const shouldUpdateFilters = useCallback(() => {
        const isClotureFilterCorrect = isFilterCorrect('cloture', undefined);
        const isDeletedFilterCorrect = isFilterCorrect('deleted', false);
        const isIdReleveAchatFilterCorrect =
            currentStatement?.id && isFilterCorrect('idReleveAchat', currentStatement?.id);

        return !isClotureFilterCorrect || !isDeletedFilterCorrect || !isIdReleveAchatFilterCorrect;
    }, [isFilterCorrect, currentStatement?.id]);

    const applyFiltersIfNecessary = useCallback(() => {
        if (!isFilterCorrect('cloture', undefined)) {
            updateFilter('cloture', undefined);
        }

        if (!isFilterCorrect('deleted', false)) {
            updateFilter('deleted', false);
        }

        if (!isFilterCorrect('idReleveAchat', currentStatement?.id)) {
            updateFilter('idReleveAchat', currentStatement?.id);
        }
    }, [isFilterCorrect, updateFilter, currentStatement?.id]);

    useEffect(() => {
        if (shouldUpdateFilters()) {
            applyFiltersIfNecessary();
            return () => {};
        }

        const fetchTimeout = setTimeout(() => {
            dispatch(getFactureAchatFromStatement({}));
        }, 1000);

        return () => clearTimeout(fetchTimeout);
    }, [
        currentStatement?.id,
        dispatch,
        facturesAchatsFromStatementDataSource.name,
        facturesAchatsFromStatementDataSource.slicename,
        facturesAchatsFromStatementDataSource.filters,
        shouldUpdateFilters,
        applyFiltersIfNecessary,
    ]);

    /** Sélectionne le relevé. */
    useEffect(() => {
        if (statementid) {
            models.invoiceStatement
                .read({
                    id: statementid,
                    xIdSociete,
                })
                .then((result) => {
                    setCurrentStatement(result.data);
                })
                .catch((err) => {
                    console.error(err);
                });

            /** Récupère la somme des factures du relevé. */
            models.invoiceStatement
                .sum({
                    id: statementid,
                    xIdSociete,
                })
                .then((result) => {
                    setSum(result.data.totalTtc || 0);
                })
                .catch((err) => {
                    console.error(err);
                });
        }
    }, [statementid, xIdSociete]);

    /**
     * Sélectionne le MediaObject du relevé.
     */
    useEffect(() => {
        if (currentStatement && currentStatement.pdfFacture?.['@id']) {
            dispatch(
                selectMediaObject({ id: jsonLdIdScraper(currentStatement.pdfFacture?.['@id']) }),
            );
        }
    }, [currentStatement, dispatch]);

    return !mediaObjectSelected || mediaObjectDataSource.selectedStatus === 'loading' ? (
        <CenterCircularProgress sx={{ height: '500px' }} />
    ) : (
        <Grid container>
            <Grid
                item
                lg={6}
                sm={12}
            >
                <MediaObjectReader
                    mediaObject={mediaObjectSelected}
                    sx={{
                        height: `calc(100vh -${footerHeight} - ${TopbarHeight})`,
                    }}
                />
            </Grid>
            <Grid
                item
                lg={6}
                sm={12}
            >
                <Card
                    sx={{
                        borderColor: 'transparent',
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                        padding: 0,
                    }}
                >
                    <FactureAchatList
                        filtersControl
                        dataSource={facturesAchatsFromStatementDataSource}
                        fetchData={getFactureAchatFromStatement}
                        filterFilters={(filtre: FilterDatatable) =>
                            filtre.field !== 'cloture' &&
                            filtre.field !== 'deleted' &&
                            filtre.field !== 'idReleveAchat'
                        }
                        statement={statementid}
                    />
                    <Card
                        sx={{
                            marginLeft: 0,
                            marginRight: 0,
                        }}
                    >
                        <DataItems
                            items={[{ title: 'Total', content: sum, contentType: 'currency' }]}
                        />
                    </Card>
                    <Card
                        sx={{
                            marginLeft: 0,
                            marginRight: 0,
                        }}
                    >
                        {displayActions ? (
                            <Box
                                display="flex"
                                flexDirection="row"
                                justifyContent="space-between"
                            >
                                <Button
                                    key={`reprendre-saisie-${faLeftFromLine}`}
                                    color="primary"
                                    variant="outlined"
                                    endIcon={<FontAwesomeIcon icon={faLeftFromLine} />}
                                    onClick={() => {
                                        navigateToNewFacture({
                                            showMessage,
                                            dispatch,
                                            navigate,
                                            navigateToList,
                                            idCurrentMediaObject:
                                                currentStatement?.pdfFacture?.['@id'],
                                            idStatement: currentStatement?.id,
                                        });
                                    }}
                                    style={{
                                        marginTop: '16px',
                                        marginBottom: '16px',
                                    }}
                                >
                                    Reprendre la saisie
                                </Button>
                                <Button
                                    key={`close-statement-${faCheck}`}
                                    color="primary"
                                    variant="contained"
                                    endIcon={<FontAwesomeIcon icon={faCheck} />}
                                    onClick={() => {
                                        models.invoiceStatement.patch({
                                            id: `${currentStatement?.id}`,
                                            xIdSociete,
                                            releveAchatReleveAchatWrite: {
                                                cloture: true,
                                            },
                                        });
                                        navigateToList();
                                    }}
                                    style={{
                                        marginTop: '16px',
                                        marginBottom: '16px',
                                    }}
                                >
                                    Clôturer le relevé
                                </Button>
                            </Box>
                        ) : null}
                    </Card>
                </Card>
            </Grid>
        </Grid>
    );
};

export default StatementInvoicesList;
