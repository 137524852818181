import React from 'react';
import { TreeItems, TreeItemsProps } from '@europrocurement/flexy-components';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import { FormHelperText } from '@mui/material';

export type ControlledTreeItemsProps = {
    name: string;
    rules: RegisterOptions;
    label?: string;
} & Omit<TreeItemsProps, 'onChange'>;

const ControlledTreeItems: React.FunctionComponent<ControlledTreeItemsProps> = function ({
    name,
    rules,
    items,
    ...inputProps
}) {
    const { control } = useFormContext();

    return (
        <Controller
            control={control}
            name={name}
            rules={rules}
            render={({
                field: { onChange, value },
                fieldState: { error },
                // formState,
            }) => (
                <>
                    <TreeItems
                        onChange={onChange}
                        items={items}
                        {...inputProps}
                        value={value}
                    />
                    {error ? (
                        <FormHelperText
                            sx={{
                                margin: '4px 14px 0px 14px',
                            }}
                            error
                        >
                            {error?.message}
                        </FormHelperText>
                    ) : null}
                </>
            )}
        />
    );
};

export default ControlledTreeItems;
