import * as React from 'react';
import { InformationCard, InformationCardProps } from '@europrocurement/flexy-components';

import {
    PackagePackageRead,
    TblRelArticlePackageRead,
} from '@europrocurement/l2d-domain/openApi/ApiOffre';
import { formatPrice } from '@europrocurement/l2d-utils';
import { JsonLdData } from '../forms/types';
import { EntityPreview } from '../components/types';

type Items = InformationCardProps['items'];

type EntityType = JsonLdData<JsonLdData<PackagePackageRead>>;

const PreviewCard: React.FC<EntityPreview<EntityType>> = function (props) {
    const { entity, onEdit } = props;

    const { articles, libelle, nomCommercial, domaine, formalite } = entity;

    const formalityItem = [
        ...(formalite
            ? [
                  {
                      label: 'Formalite',
                      value: formalite.libelle,
                  },
              ]
            : []),
    ];

    const cardTitle = libelle;
    const cardItems: InformationCardProps['items'] = [
        {
            label: 'Nom commercial',
            value: nomCommercial,
        },
        {
            label: 'Domaine',
            value: domaine?.libelle ?? null,
        },
        ...formalityItem,
    ];

    const articleLabel = (article: TblRelArticlePackageRead) =>
        `${article.libelle} (${article.quantite})`;
    const articleItems: () => Items = () => {
        if (!articles || articles.length === 0) {
            return [];
        }
        return articles?.map((article) => ({
            label: articleLabel(article) ?? '',
            value: formatPrice(article.tarif, '€'),
        }));
    };

    const articleSectionTitle = (number: number) => `Article${number > 1 ? 's' : ''}`;
    const articleSectionSubtitle = (number: number) =>
        `${number} article${number > 1 ? 's' : ''} - Label (quantité) - Tarif`;

    return (
        <>
            <InformationCard
                title={cardTitle}
                items={cardItems}
                button={{
                    label: 'Éditer',
                    onClick: onEdit,
                }}
            />

            {articles && articles.length > 0 && (
                <InformationCard
                    title={articleSectionTitle(articles.length)}
                    subtitle={articleSectionSubtitle(articles.length)}
                    items={articleItems()}
                />
            )}
        </>
    );
};

export default PreviewCard;
