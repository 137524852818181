import React, { useEffect, useRef } from 'react';
import { Box, IconButton, InputAdornment } from '@mui/material';
import EventIcon from '@mui/icons-material/Event';
import {
    addDays,
    startOfWeek,
    endOfWeek,
    addWeeks,
    startOfMonth,
    endOfMonth,
    addMonths,
    startOfYear,
    endOfYear,
} from 'date-fns';
import { fr } from 'date-fns/locale';
import { DateRangePickerWrapperProps } from '../../molecules/DateRangePicker/DateRangePickerWrapper';
import FlexyTextField, { FlexyTextFieldProps } from '../FlexyTextField';
import { DateRange, DefinedRange } from '../../molecules/DateRangePicker';
import DateRangePicker from '../../molecules/DateRangePicker/DateRangePicker';

const date = new Date();

// Utilisé pour déterminer la taille du picker
export const MonthWidth = 290;
export const RangesWidth = 120;
const maxPickerWidth = 1 + 2 * MonthWidth + RangesWidth;

export const defaultsRange: DefinedRange[] = [
    {
        label: "Aujourd'hui",
        startDate: date,
        endDate: date,
    },
    {
        label: 'Hier',
        startDate: addDays(date, -1),
        endDate: addDays(date, -1),
    },
    {
        label: 'Cette semaine',
        startDate: startOfWeek(date, { locale: fr }),
        endDate: endOfWeek(date, { locale: fr }),
    },
    {
        label: 'Semaine derniere',
        startDate: startOfWeek(addWeeks(date, -1), { locale: fr }),
        endDate: endOfWeek(addWeeks(date, -1), { locale: fr }),
    },
    {
        label: 'Ce mois-ci',
        startDate: startOfMonth(date),
        endDate: endOfMonth(date),
    },
    {
        label: 'Mois dernier',
        startDate: startOfMonth(addMonths(date, -1)),
        endDate: endOfMonth(addMonths(date, -1)),
    },
    {
        label: 'Cette année',
        startDate: startOfYear(date),
        endDate: endOfYear(date),
    },
];

type FlexyInputType = 'text';

export type FlexyDateRangeProps = Omit<DateRangePickerWrapperProps, 'toogle'> &
    FlexyTextFieldProps & {
        inputlabel?: string;
        type: FlexyInputType;
        name: string;
        required?: boolean;
        inputRef?: React.Ref<HTMLInputElement>;
        reset?: boolean;
    };

const FlexyDateRange: React.FunctionComponent<FlexyDateRangeProps> = function ({
    onChange,
    reset,
    ...props
}: FlexyDateRangeProps) {
    // const [textValue, setTextValue] = React.useState<string>('');
    const [dateValue, setDateValue] = React.useState<DateRange | DefinedRange>({});
    const [inputValue, setInputValue] = React.useState<string>('');
    const [open, setOpen] = React.useState(false);
    const [out, setOut] = React.useState(false);
    const pickerBox = useRef<HTMLDivElement>(null);
    const toggle = () => setOpen(!open);

    /**
     *  Au clic pour ouvrir/fermer le date picker :
     *    - Ouvre/Ferme le picker
     *    - Vérifie s'il est en dehors du viewport
     */
    const handleOpenPicker = () => {
        setOut(false);
        toggle();
        if (!pickerBox.current) return;
        setOut(pickerBox.current.getBoundingClientRect().left + maxPickerWidth > window.innerWidth);
    };

    useEffect(() => {
        if (dateValue.startDate && dateValue.endDate) {
            setInputValue(
                `${dateValue.startDate?.toLocaleDateString(
                    'FR-fr',
                )} au ${dateValue.endDate?.toLocaleDateString('FR-fr')}`,
            );
        }
    }, [dateValue]);

    useEffect(() => {
        if (reset) {
            setInputValue('');
        }
    }, [reset]);

    return (
        <Box
            data-testid="FlexyDateRange"
            ref={pickerBox}
            sx={{
                position: 'relative',
            }}
        >
            <FlexyTextField
                fullWidth
                placeholder={!inputValue ? props.placeholder : undefined}
                onFocus={handleOpenPicker}
                size={props.size}
                error={props.error}
                helperText={props.helperText}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="date-range-selector"
                                id="date-range-selector"
                                onClick={handleOpenPicker}
                                sx={{ margin: 0, padding: 0 }}
                            >
                                <EventIcon />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                value={inputValue}
                {...props}
            />
            <Box
                data-testid="FlexyDateRange"
                sx={{
                    display: 'block',
                    width: 'fit-content',
                    position: 'absolute',
                    left: out ? undefined : 0,
                    right: out ? 0 : undefined,
                    zIndex: 10000,
                }}
            >
                <DateRangePicker
                    open={open}
                    // toggle={toggle}
                    definedRanges={defaultsRange}
                    onChange={(dateRange: DateRange) => {
                        setDateValue(dateRange); // Display dateRange in FlexyTextField
                        onChange(dateRange);
                        toggle();
                    }}
                    locale={fr}
                />
            </Box>
        </Box>
    );
};

export default FlexyDateRange;
