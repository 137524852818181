/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { FieldValues, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { customizerSelector, domainSelector } from '@b2d/redux/RootStore';
import {
    DomainesDomaineRead,
    PackagePackageRead,
    TblRelArticleJsonldPackageRead,
    TblRelArticlePackageRead,
} from '@europrocurement/l2d-domain/openApi/ApiOffre';
import { MultiStepContextProps } from '../../components/form/MultiStepForm/types';
import useStepForm from '../../components/form/MultiStepForm/useStepForm';
import { contentStepSchema } from './validation';
import ContentForm from './ContentForm';
import { SelectOptions } from '../../forms/types';
import { formatOptionsByKeys } from '../../forms/options';
import useDomainRelatedValues from './useDomainRelatedValues';
import LoadingScreen from '../../forms/LoadingScreen';
import useReadOnlyDocuments from '../../components/DocumentsList/useReadOnlyDocuments';
import { domainIds } from '../../constants';
import { ReadOnlyDocumentItem } from '../../components/DocumentsList/types';

/** Form first step : Content of catalog (Domain / Name / Package / Group)
 *
 * 1) Init form / default values
 * 2) Form specific logic
 *
 * 3) Form Render
 *
 * Specific rules : If domain is 'Formality domain' a filter is available to filter package available options
 * On Domain change :
 *  - Reset formvalues
 *  - Refetch and store domain related options
 *
 * On package change :
 *  - Store / replace step 3 ('Overrides') package articles
 *
 */
const ContentStep: FC<MultiStepContextProps> = function (props) {
    // 1 - Init form / default values
    const { multiStepForm, isCreate } = props;
    const { setCurrentFormContext, formValues, setIsStepDisabled } = multiStepForm;

    const { xIdSociete: companyId } = useSelector(customizerSelector);
    const stepForm = useForm<FieldValues>({
        mode: 'onChange',
        defaultValues: { ...formValues, companyId },
        resolver: zodResolver(contentStepSchema),
    });

    useStepForm(stepForm, multiStepForm);
    useEffect(() => {
        setCurrentFormContext(stepForm);
    }, [stepForm, setCurrentFormContext]);

    const { actions, onFormalityUpdate, formatUtils } = useReadOnlyDocuments(stepForm);

    // 2 - Form logic

    const { domainRelatedInformations, packageId, domainId } = stepForm.getValues();
    const { packages, groups } = domainRelatedInformations || {};

    const hasSelectedDomain = typeof domainId === 'number';
    const hasSelectedPackage = typeof packageId === 'number';

    const getPackage = (id: number): PackagePackageRead =>
        packages?.values.find((packageItem: PackagePackageRead) => packageItem.id === id);

    const loadNewPackageContent = (articles: TblRelArticlePackageRead[]) => {
        const formatedArticles = articles?.map((article: TblRelArticleJsonldPackageRead) => ({
            originalLabel: article.libelle,
            originalPrice: article.tarif,
            originalQuantity: article.quantite,
            articleRelationId: article.id as number, // Wrong api type / ID should be defined every time .
            label: null,
            price: null,
            quantity: null,
        }));
        stepForm.setValue('catalogArticlesOverrides', formatedArticles);
    };

    const {
        hasFetched,
        isFetching,
        values,
        fetch: fetchDomainRelatedValues,
    } = useDomainRelatedValues(domainId);

    const packagesLoaded = packages?.options;
    const groupsLoaded = groups?.options;
    const optionsLoaded = packagesLoaded?.length > 0 && groupsLoaded?.length > 0;
    const catalogOverrideLoaded = stepForm.getValues()?.catalogArticlesOverrides?.length > 0;

    const loadReadOnlyDocuments = (selectedPackage: PackagePackageRead) => {
        // Clear then fill package articles related documents with package articles documents
        actions.clearDocumentsInCategory('articles');
        const packageArticles = selectedPackage?.articles ?? [];

        const articleDocuments: ReadOnlyDocumentItem[] = [];
        packageArticles.forEach((articleRelationship) => {
            const documentItems = formatUtils.formatArticleDocumentItems(
                articleRelationship.article?.documents, // Article related document
                articleRelationship.article, // Reference article
            );
            articleDocuments.push(...documentItems);
        });

        actions.addDocumentsInCategory('articles', articleDocuments);

        // Formality related documents
        const isDomainFormality = selectedPackage.domaine?.id === domainIds.formality;
        const formalityId = selectedPackage.formalite?.id;
        if (isDomainFormality && formalityId) {
            onFormalityUpdate(formalityId.toString());
        }
    };

    // RG On package update / prefill
    const prefillCommercialName = () => {
        const { libelle: packageName } = getPackage(packageId) || {};
        stepForm.setValue('commercialName', packageName);
    };

    const afterPackageSelect = (
        articlesCallback?: (articles: TblRelArticlePackageRead[]) => void,
    ) => {
        const selectedPackage = getPackage(packageId);
        stepForm.setValue('selectedPackage', selectedPackage);

        if (!selectedPackage) {
            return;
        }
        prefillCommercialName();
        const packageArticles = selectedPackage?.articles;

        if (!packageArticles) {
            return;
        }
        loadReadOnlyDocuments(selectedPackage);

        if (packageArticles && typeof articlesCallback === 'function') {
            articlesCallback(packageArticles);
        }
    };

    /** Function trigerred on every domain value update */
    const afterDomainSelect = () => {
        fetchDomainRelatedValues();
    };

    const updateFormIsLoaded =
        !isCreate && hasSelectedPackage && optionsLoaded && catalogOverrideLoaded;
    const formIsReady = isCreate || updateFormIsLoaded;

    /** Load form values / options / articles on update mount (Update form case / first render) */
    React.useEffect(() => {
        const formNeedValues = !isCreate && !updateFormIsLoaded && !isFetching;
        if (formNeedValues) {
            fetchDomainRelatedValues();
        }
    }, []);

    React.useEffect(() => {
        if (hasSelectedDomain) {
            afterDomainSelect();
        }
    }, [domainId]);

    React.useEffect(() => {
        if (hasSelectedPackage && packages) {
            afterPackageSelect();
        }
    }, [packageId, packages]);

    React.useEffect(() => {
        if (hasFetched && !isFetching) {
            stepForm.setValue('domainRelatedInformations', values);
        }
    }, [hasFetched, isFetching]);

    React.useEffect(() => {
        if (packages && !catalogOverrideLoaded) {
            afterPackageSelect(loadNewPackageContent);
        }
    }, [catalogOverrideLoaded, packages]);

    React.useEffect(() => {
        setIsStepDisabled(!formIsReady);
    }, [formIsReady]);

    // 3 - Form options
    const reduxStoredDomains = useSelector(domainSelector).main.data as DomainesDomaineRead[];
    const domainsOptions: SelectOptions = formatOptionsByKeys(reduxStoredDomains, 'id', 'libelle');
    const packagesOptions: SelectOptions = packagesLoaded ?? [];
    const groupsOptions: SelectOptions = groupsLoaded ?? [];

    const selectOptions = {
        domains: domainsOptions,
        packages: packagesOptions,
        groups: groupsOptions,
    };

    if (!formIsReady) {
        return <LoadingScreen />;
    }

    return (
        <ContentForm
            isFetching={isFetching}
            formObject={stepForm}
            isCreate={isCreate}
            selectOptions={selectOptions}
        />
    );
};

export default ContentStep;
