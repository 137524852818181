import React, { FunctionComponent, useState } from 'react';

import achatsViewPathResolver from '@b2d/pages/Achats/achatsViewPathResolver';
import models from '@b2d/pages/Achats/models';
import { DataCard } from '@europrocurement/flexy-components';
import { FaOptionIcon, pausedStatementIcon } from '@europrocurement/l2d-icons';
import { customizerSelector } from '@europrocurement/l2d-modules';
import { Button, Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

// interne

const RelevesNonClotures: FunctionComponent = function () {
    const navigate = useNavigate();

    const { xIdSociete } = useSelector(customizerSelector);

    const [countOfUnclosedStatements, setCountOfUnclosedStatements] = useState<string>('...');

    models.invoiceStatement
        .list({
            xIdSociete,
            page: 1,
            itemsPerPage: 0,
            cloture: false,
            deleted: false,
        })
        .then((res) => {
            if (
                res.data['hydra:totalItems'] !== null ||
                res.data['hydra:totalItems'] !== undefined
            ) {
                setCountOfUnclosedStatements(`${res.data['hydra:totalItems']}`);
            }
        });

    return (
        <Grid
            sx={{
                flex: 1,
            }}
            item
            lg={1}
            md={1}
            xs={12}
        >
            <DataCard
                title="Relevés non clôturés"
                color={models.invoiceStatement.color}
                iconBgColor="transparent"
                iconColor="transparent"
                icon={<FaOptionIcon {...pausedStatementIcon.props} />}
                tools={
                    <Button
                        color={models.invoiceStatement.color}
                        variant="outlined"
                        onClick={() => {
                            navigate(achatsViewPathResolver('listInvoiceStatement'));
                        }}
                    >
                        Traiter
                    </Button>
                }
            >
                <Typography
                    sx={{
                        fontSize: '18px',
                        textAlign: 'center',
                    }}
                >
                    Relevés non clôturés :
                </Typography>
                <Typography
                    color={`${models.invoiceStatement.color}.main`}
                    component="h2"
                    sx={{
                        fontSize: '32px',
                        textAlign: 'center',
                    }}
                >
                    <b>{countOfUnclosedStatements}</b>
                </Typography>
            </DataCard>
        </Grid>
    );
};

export default RelevesNonClotures;
