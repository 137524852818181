import {
    SliceFactory,
    FetchCollectionData,
    ApiCollectionResponse,
    FetchItemDataType,
} from '@europrocurement/l2d-redux-utils';
import {
    ConfigurationParameters as FormalityApiConfigurationParameters,
    Configuration,
    FormeJuridiqueFormejuridiqueRead,
    FormeJuridiqueApi,
    FormeJuridiqueApiApiFormesJuridiquesIdGetRequest,
    FormeJuridiqueApiApiFormesJuridiquesGetCollectionRequest,
} from '../../../openApi/ApiFormalite';
import { FORMALITES_REDUCER_NAME } from '../constants';

export type LegalStatusApiObject = FormeJuridiqueFormejuridiqueRead & Record<string, unknown>;

export type LegalStatus = LegalStatusApiObject;

export const LEGAL_STATUS_SLICE_NAME = 'legalStatuses';

export const mapperLegalStatuses = function (legalStatuses: LegalStatus) {
    return {
        ...legalStatuses,
    } as LegalStatus;
};

export function createLegalStatusSlice(configuration: FormalityApiConfigurationParameters) {
    const conf = new Configuration(configuration);
    const api = new FormeJuridiqueApi(conf);

    const fetchLegalStatusesCollection: FetchCollectionData<LegalStatus> = ({ pagination }) => {
        const options = undefined;
        const requestParameters: FormeJuridiqueApiApiFormesJuridiquesGetCollectionRequest = {
            page: pagination.page + 1,
            itemsPerPage: 100,
        };

        return api
            .apiFormesJuridiquesGetCollection(requestParameters, options)
            .then((res) => res.data) as Promise<ApiCollectionResponse<LegalStatus>>;
    };

    const fetchLegalStatusItem: FetchItemDataType<LegalStatus> = ({ idItem }) => {
        const requestParameters: FormeJuridiqueApiApiFormesJuridiquesIdGetRequest = {
            id: String(idItem),
        };

        return api
            .apiFormesJuridiquesIdGet(requestParameters)
            .then((res) => res.data) as Promise<LegalStatus>;
    };

    const { slice: legalStatusSlice, dataSourcesThunks: legalStatusDataSourcesThunks } =
        SliceFactory.createSlice<LegalStatus, LegalStatus>(
            LEGAL_STATUS_SLICE_NAME,
            FORMALITES_REDUCER_NAME,
            [],
            fetchLegalStatusesCollection,
            fetchLegalStatusItem,
            mapperLegalStatuses,
            {},
        );

    return { legalStatusSlice, legalStatusDataSourcesThunks };
}
