import * as React from 'react';

import { useFieldArray, useFormContext } from 'react-hook-form';
import { ClearButton, useResolvePath } from '@europrocurement/flexy-components';
import { Box } from '@mui/system';
import { Button, Grid, GridProps, Tooltip, Typography } from '@mui/material';

// eslint-disable-next-line import/no-cycle
import { FormStructureRenderer, FormStructureRendererProps } from './FormStructureRenderer';
import type {
    FlexyFormInputProps,
    FlexySubFormProps,
    FormStructure,
    SubFormArrayEvents,
} from '../FlexyForm';

export type SubformArrayProps = {
    input: FormStructure & FlexySubFormProps;
    basePath: string;
    displayAddButton?: boolean;
    displayClearButton?: boolean;
    inputsProps?: FlexyFormInputProps;
    gridProps?: GridProps;
    events?: SubFormArrayEvents;
};

export const SubformArray: React.FunctionComponent<SubformArrayProps> = function ({
    input,
    basePath,
    displayAddButton = true,
    displayClearButton = true,
    inputsProps,
    gridProps,
    events,
}) {
    const { resolvePath } = useResolvePath();
    const formContext = useFormContext();

    const extandedFormContext = {
        ...formContext,
        getValue: (path: string) => resolvePath(path, formContext.getValues()),
    };

    const fieldArray = useFieldArray({
        name: basePath,
        shouldUnregister: true,
    });

    const { fields, append, remove } = fieldArray;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onAppendItem = (item: any) => {
        if (events && events.beforeAppend) {
            const res = events.beforeAppend(item, extandedFormContext, fieldArray);
            if (res && res.item) {
                // eslint-disable-next-line no-param-reassign
                item = res.item;
            }
        }
        append(item);
        if (events && events.onAppend) {
            events.onAppend(item, extandedFormContext, fieldArray);
        }
    };

    const minLength = input.minLength || 0;
    const maxLength = input.maxLength || Infinity;

    const onRemoveItem = (index: number) => {
        const item = extandedFormContext.getValue(`${basePath}.${index}`);
        if (events && events.beforeRemove) {
            events.beforeRemove(index, item, extandedFormContext, fieldArray);
        }
        remove(index);
        if (events && events.onRemove) {
            events.onRemove(index, item, extandedFormContext, fieldArray);
        }
    };

    return (
        <>
            {fields.map(({ id }, index) => {
                const props = {
                    ...input,
                    shouldUnregister: true,
                    basePath: `${basePath}.${index}.`,
                    inputsProps,
                    gridProps,
                } as FormStructureRendererProps;

                return (
                    <React.Fragment key={id}>
                        {input.subFormHeader
                            ? input.subFormHeader(input, formContext.getValues(), index)
                            : null}

                        <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="end"
                            justifyContent="space-between"
                            position="relative"
                            sx={{ width: '100%' }}
                        >
                            <FormStructureRenderer {...props} />
                            {displayClearButton && fields.length > minLength ? (
                                <ClearButton
                                    size={
                                        inputsProps?.size !== undefined
                                            ? inputsProps.size
                                            : 'medium'
                                    }
                                    tooltip={input.removeBtnTooltip}
                                    onClick={() => {
                                        onRemoveItem(index);
                                    }}
                                />
                            ) : null}
                        </Box>
                    </React.Fragment>
                );
            })}

            {fields.length < maxLength && displayAddButton ? (
                <Grid
                    container
                    spacing={2}
                >
                    <Grid
                        item
                        xs={12}
                    />
                    <Grid
                        item
                        xs={12}
                        sx={{ textAlign: 'right' }}
                    >
                        <Grid
                            item
                            xs={12}
                            sx={{ textAlign: 'right' }}
                        >
                            {/* Outlined button */}
                            <Tooltip title={input.addBtnTooltip || 'Ajouter'}>
                                <Button
                                    color="secondary"
                                    aria-label="ajout"
                                    variant="outlined"
                                    sx={{
                                        height: 'fit-content',
                                    }}
                                    size={
                                        inputsProps?.size !== undefined
                                            ? inputsProps.size
                                            : 'medium'
                                    }
                                    onClick={() => onAppendItem({})}
                                >
                                    <Typography>{input.addBtnLabel || 'Ajouter'}</Typography>
                                </Button>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Grid>
            ) : null}
        </>
    );
};

export default SubformArray;
