// Enum to map view names to corresponding camelCase function names
export enum ViewFunctionMap {
    unregistered = 'unregisteredInvoices',
    user_unregistered = 'userUnregisteredInvoices',
    unassigned_unregistered = 'unassignedUnregisteredInvoices',
}

export type ViewName = keyof typeof ViewFunctionMap;

// Utility function to get ViewName key from a ViewFunctionMap value
export function getViewNameFromValue(value: string): ViewName | undefined {
    return (Object.keys(ViewFunctionMap) as Array<ViewName>).find(
        (key) => ViewFunctionMap[key] === value,
    );
}
