import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { FC } from 'react';

type Props = {
    startDate: Date;
    endDate: Date;
};
const CalendarInterval: FC<Props> = function ({ startDate, endDate }) {
    const arrowColor = '#00000040';
    const getRangeInterval = () => {
        const invalidCases = [!startDate || !endDate, startDate >= endDate];
        if (invalidCases.includes(true)) {
            return null;
        }
        const startTime = new Date(startDate).getTime();
        const endTime = new Date(endDate).getTime();

        const timeDifference = endTime - startTime;
        const intervalInDays = Math.round(timeDifference / (1000 * 3600 * 24));

        return intervalInDays;
    };

    const getInterval = () => {
        const intervalInDays = getRangeInterval();
        if (!intervalInDays) {
            return '';
        }
        return `( ${intervalInDays} jour${intervalInDays > 1 ? 's' : ''} )`;
    };

    const dash = (
        <Box
            margin="7px"
            minHeight="4px"
            maxHeight="4px"
            minWidth="12px"
            borderRadius="5px"
            style={{ backgroundColor: arrowColor }}
        />
    );

    const arrow = (
        <Box
            display="flex"
            alignItems="center"
            paddingTop="25px"
        >
            {dash}
            {dash}
            {dash}
            {dash}
            {dash}
            <Box margin="2px" />
            <FontAwesomeIcon
                icon={faArrowRight}
                color={arrowColor}
                size="2xl"
            />
        </Box>
    );
    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            sx={{ opacity: 0.5 }}
        >
            <Typography
                component="span"
                sx={{ minHeight: '20px' }}
            >
                {getInterval()}
            </Typography>
            {arrow}
        </Box>
    );
};

export default CalendarInterval;
