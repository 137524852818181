/* eslint-disable @typescript-eslint/no-unused-vars */

import {
    SliceFactory,
    FetchCollectionData,
    FetchItemDataType,
    ApiCollectionResponse,
} from '@europrocurement/l2d-redux-utils';
import {
    ConfigurationParameters as FacturationConfigurationParameters,
    Configuration,
    FactureApi,
    DevisLignesJsonld,
    DevisLignesApi,
    DevisApi,
    DevisJsonldDevisRead,
    DevisLignesApiApiDevisLignesGetDevisCollectionRequest,
    DevisApiApiDevisGetDevisCollectionRequest,
} from '../../../openApi/ApiFacturation';
import { FACTURATION_REDUCER_NAME } from '../constants';

export type DevisApiObject = DevisJsonldDevisRead & Record<string, unknown>;

export type Devis = DevisApiObject;

export const DEVIS_SLICE_NAME = 'devis';

const mapperDevis = function (devis: Devis) {
    return {
        ...devis,
    } as Devis;
};

export function createDevisSlice(configuration: FacturationConfigurationParameters) {
    const conf = new Configuration(configuration);
    const api = new DevisApi(conf);

    const fetchDevisCollection: FetchCollectionData<Devis> = ({
        search,
        pagination,
        filters,
        orders,
        xIdSociete,
        rootstate,
    }) => {
        let dossierId: number | undefined;
        let dossierId2: Array<number> | undefined;
        let lignesIdAnnonce: number | undefined;
        let lignesIdAnnonce2: Array<number> | undefined;

        if (filters.dossierId !== undefined) {
            dossierId = filters.dossierId as number;
        }

        if (
            filters.dossierId2 &&
            Array.isArray(filters.dossierId2) &&
            filters.dossierId2.length > 0
        ) {
            dossierId2 = filters.dossierId2;
        }

        if (filters.lignesIdAnnonce !== undefined) {
            lignesIdAnnonce = filters.lignesIdAnnonce as number;
        }

        if (
            filters.lignesIdAnnonce2 &&
            Array.isArray(filters.lignesIdAnnonce2) &&
            filters.lignesIdAnnonce2.length > 0
        ) {
            lignesIdAnnonce2 = filters.lignesIdAnnonce2;
        }

        const requestParameters: DevisApiApiDevisGetDevisCollectionRequest = {
            xIdSociete,
            page: pagination.page + 1,
            dossierId,
            dossierId2,
            lignesIdAnnonce,
            lignesIdAnnonce2,
        };

        return api.apiDevisGetDevisCollection(requestParameters).then((res) => res.data) as Promise<
            ApiCollectionResponse<DevisApiObject>
        >;
    };

    const fetchDevisItem: FetchItemDataType<DevisApiObject> = ({
        idItem,
        search,
        pagination,
        filters,
        orders,
        xIdSociete,
        rootstate,
    }) =>
        api
            .apiDevisGetDevisByIdItem({ id: String(idItem), xIdSociete })
            .then((res) => res.data) as Promise<DevisApiObject>;

    const { slice: devisSlice, dataSourcesThunks: devisDataSourcesThunks } =
        SliceFactory.createSlice<DevisApiObject, Devis>(
            DEVIS_SLICE_NAME,
            FACTURATION_REDUCER_NAME,
            [],
            fetchDevisCollection,
            fetchDevisItem,
            mapperDevis,
        );

    return { devisSlice, devisDataSourcesThunks };
}
