import React, { CSSProperties, ChangeEvent, DragEvent, FunctionComponent, useState } from 'react';

import { Box, Button, Grid, Typography, useTheme } from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowDown } from '@fortawesome/pro-duotone-svg-icons';
import { useSnackbar } from 'notistack';

export type FileUploadProps = {
    accept: string;
    formatInfo: string;
    width?: string;
    height?: string;
    maxMbSize?: number;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    onDrop: (event: DragEvent<HTMLElement>) => void;
};

const FileUpload: FunctionComponent<FileUploadProps> = function ({
    accept,
    formatInfo,
    width = '100%',
    height = '300px',
    onChange,
    onDrop,
    maxMbSize,
}) {
    const [isHover, setIsHover] = useState<boolean>(false);

    const theme = useTheme();

    let borderRadius: CSSProperties['borderRadius'] = '50px';

    if (
        theme.components?.MuiCard?.styleOverrides?.root &&
        typeof theme.components?.MuiCard?.styleOverrides?.root === 'object'
    ) {
        borderRadius = (theme.components.MuiCard.styleOverrides.root as CSSProperties).borderRadius;
    }

    const stopDefaults = (e: DragEvent) => {
        e.stopPropagation();
        e.preventDefault();
    };

    const { enqueueSnackbar } = useSnackbar();

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        Array.from(event.target.files).forEach((file) => {
            // console.log(file.size);
            if (maxMbSize !== undefined && file.size > maxMbSize * 1000000) {
                // console.log(maxMbSize);
                enqueueSnackbar(<Typography>Fichier trop lourd</Typography>, {
                    variant: 'error',
                });
                throw new Error('Fichier trop lourd');
            }
            // console.log(file.type);
            if (accept !== undefined && !accept.split(',').includes(file.type)) {
                // console.log(accept);
                enqueueSnackbar(<Typography>Format de fichier non compatible</Typography>, {
                    variant: 'error',
                });
                throw new Error('Format de fichier non compatible');
            }
        });
        onChange(event);
    };

    const handleMouseEnter = () => setIsHover(true);
    const handleMouseLeave = () => setIsHover(false);

    const handleDrop = (event: DragEvent<HTMLElement>) => {
        stopDefaults(event);
        Array.from(event.dataTransfer.files).forEach((file) => {
            // console.log(file.size);
            if (maxMbSize !== undefined && file.size > maxMbSize * 1000000) {
                // console.log(maxMbSize);
                enqueueSnackbar(<Typography>Fichier trop lourd</Typography>, {
                    variant: 'error',
                });
                throw new Error('Fichier trop lourd');
            }
            // console.log(file.type);
            if (accept !== undefined && !accept.split(',').includes(file.type)) {
                // console.log(accept);
                enqueueSnackbar(<Typography>Format de fichier non compatible</Typography>, {
                    variant: 'error',
                });
                throw new Error('Format de fichier non compatible');
            }
        });
        onDrop(event);
    };

    const dragEvents = {
        onMouseEnter: handleMouseEnter,
        onMouseLeave: handleMouseLeave,
        onDragEnter: (e: DragEvent) => {
            stopDefaults(e);
        },
        onDragLeave: (e: DragEvent) => {
            stopDefaults(e);
        },
        onDragOver: stopDefaults,
        onDrop: (e: DragEvent<HTMLElement>) => {
            handleDrop(e);
        },
    };

    return (
        <Grid
            container
            spacing={2}
        >
            <Grid
                item
                xs={12}
            >
                <label
                    htmlFor="file-upload"
                    {...dragEvents}
                >
                    <Box
                        width={width}
                        height={height}
                        sx={{
                            border: 2,
                            borderStyle: 'dashed',
                            backgroundColor: 'rgba(0,0,0,0.06)',
                            borderColor: 'primary.main',
                            borderRadius,
                        }}
                    >
                        <Box
                            height="100%"
                            width="100%"
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Typography color="GrayText">
                                <FontAwesomeIcon
                                    icon={faCloudArrowDown}
                                    bounce={isHover}
                                    size="3x"
                                />
                            </Typography>
                            <Box
                                display="flex"
                                flexDirection="row"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Button
                                    id="basic-button"
                                    color="inherit"
                                    component="label"
                                >
                                    <Typography
                                        variant="body1"
                                        color="primary.main"
                                    >
                                        Cliquez ici
                                    </Typography>
                                    {maxMbSize !== undefined ? (
                                        <input
                                            type="file"
                                            onChange={handleChange}
                                            accept={accept}
                                            size={maxMbSize * 1000000}
                                            multiple
                                            hidden
                                        />
                                    ) : (
                                        <input
                                            type="file"
                                            onChange={handleChange}
                                            accept={accept}
                                            multiple
                                            hidden
                                        />
                                    )}
                                </Button>
                                <Typography variant="body1">ou déposez vos fichiers</Typography>
                            </Box>
                            <Typography
                                variant="caption"
                                color="GrayText"
                            >
                                Formats: {formatInfo}{' '}
                                {maxMbSize ? `- Poids maximum :${maxMbSize}Mo` : ''}
                            </Typography>
                        </Box>
                    </Box>
                </label>
            </Grid>
        </Grid>
    );
};

export default FileUpload;
