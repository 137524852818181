import { useTheme } from '@mui/material';
import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';

export type B2DLogoIconProps = {
    /**
     * Custom style for the icon
     */
    style?: React.CSSProperties;
    /**
     * Redirect URL ( default to '/')
     */
    redirect?: string;
};

const B2DLogoIcon: React.FunctionComponent<B2DLogoIconProps> = function ({
    style,
    redirect,
}: B2DLogoIconProps) {
    const theme = useTheme();

    const cls1Style = { fill: theme.palette.secondary.main };
    const cls2Style = { fill: theme.palette.primary.main };

    const logoIcon: ReactNode = (
        <svg
            role="img"
            aria-describedby="Logo backToDigital"
            id="B2DLogoIcon"
            style={style}
            width="579.04999"
            height="94.040001"
            viewBox="0 0 579.04998 94.039998"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g
                id="LOGO"
                transform="translate(-107.33,-222.25)"
            >
                <g id="BACK">
                    <path
                        id="B"
                        style={cls1Style}
                        d="m 141.76,292.98 h -34.43 v -70.69 h 32.6 c 14.16,0 21.29,7.33 21.29,19.15 0,5.91 -2.55,10.7 -6.93,13.85 6.01,3.16 9.68,8.86 9.68,16.91 0,12.63 -8.56,20.78 -22.21,20.78 z m -20.98,-58.47 v 15.59 h 19.15 c 4.58,0 8.25,-3.16 8.25,-7.84 0,-4.68 -3.16,-7.74 -8.25,-7.74 h -19.15 z m 18.85,27.91 h -18.85 v 18.23 h 19.15 c 5.19,0 10.29,-2.04 10.29,-8.96 0,-5.91 -3.77,-9.27 -10.59,-9.27 z"
                    />
                    <path
                        id="A"
                        style={cls1Style}
                        d="m 197.98,282.5 c -2.67,0 -5.18,-0.59 -7.46,-1.74 -2.26,-1.15 -4.02,-2.81 -5.37,-5.08 -1.34,-2.25 -2.02,-5.05 -2.02,-8.33 0,-3.28 0.69,-6.25 2.06,-8.5 1.37,-2.26 3.19,-3.99 5.4,-5.15 2.25,-1.18 4.73,-1.78 7.39,-1.78 2.66,0 5.02,0.61 7.22,1.81 2.19,1.19 3.98,2.94 5.32,5.2 1.34,2.26 2.02,5.09 2.02,8.42 0,3.33 -0.68,6.07 -2.02,8.33 -1.35,2.28 -3.09,3.94 -5.29,5.09 -2.21,1.15 -4.66,1.73 -7.26,1.73 m 14.67,-38.29 c -0.31,-0.18 -0.63,-0.35 -0.95,-0.51 -4.11,-2.1 -8.73,-3.17 -13.72,-3.17 -4.99,0 -9.64,1.07 -13.81,3.17 -4.21,2.13 -7.6,5.23 -10.07,9.24 -2.47,4.01 -3.72,8.86 -3.72,14.42 0,5.56 1.25,10.3 3.72,14.28 2.47,3.98 5.85,7.07 10.07,9.19 4.17,2.1 8.82,3.17 13.81,3.17 4.99,0 9.61,-1.07 13.72,-3.17 0.32,-0.16 0.63,-0.33 0.95,-0.51 v 2.67 h 12.73 v -52.4 h -12.73 v 3.64 z"
                    />
                    <path
                        id="C"
                        style={cls1Style}
                        d="m 258.94,293.99 c -15.37,0 -26.37,-11.9 -26.37,-26.67 0,-14.77 11.01,-26.87 26.37,-26.87 12.29,0 21.61,6.94 24.59,18.44 h -13.98 c -1.78,-3.47 -5.85,-6.44 -10.61,-6.44 -7.24,0 -13.09,6.74 -13.09,14.97 0,8.23 5.85,14.67 13.09,14.67 4.86,0 9.02,-2.97 10.71,-6.54 h 13.98 c -2.97,11.6 -12.29,18.44 -24.69,18.44 z"
                    />
                    <path
                        id="K"
                        style={cls1Style}
                        d="m 303.84,278.3 v 14.67 h -13.09 v -70.69 h 13.09 v 38.17 l 17.15,-18.92 h 18.04 l -19.43,20.31 20.13,31.13 h -16.66 l -12.69,-21.52 -6.54,6.84 z"
                    />
                </g>
                <g id="II">
                    <rect
                        style={cls2Style}
                        x="353.32999"
                        y="222.25"
                        width="47.849998"
                        height="10.44"
                        id="rect1"
                    />
                    <path
                        style={cls2Style}
                        d="m 377.24,299.44 c -15.63,0 -25.76,-4.52 -26.19,-4.71 l 4.52,-10.02 c 0.08,0.04 8.57,3.74 21.67,3.74 13.1,0 21.59,-3.69 21.67,-3.73 l 4.53,10.01 c -0.42,0.19 -10.58,4.71 -26.2,4.71"
                        id="path1"
                    />
                    <rect
                        style={cls2Style}
                        x="362.23001"
                        y="243.66"
                        width="10.44"
                        height="32.119999"
                        id="rect2"
                    />
                    <rect
                        style={cls2Style}
                        x="381.84"
                        y="243.66"
                        width="10.44"
                        height="32.119999"
                        id="rect3"
                    />
                </g>
                <g id="DIGITAL">
                    <path
                        id="D"
                        style={cls1Style}
                        d="m 442.36,282.55 c -2.67,0 -5.18,-0.59 -7.46,-1.74 -2.26,-1.15 -4.02,-2.81 -5.37,-5.08 -1.34,-2.25 -2.02,-5.05 -2.02,-8.32 0,-3.27 0.69,-6.25 2.06,-8.5 1.37,-2.26 3.19,-3.99 5.4,-5.15 2.25,-1.18 4.73,-1.78 7.39,-1.78 2.66,0 5.02,0.61 7.23,1.81 2.19,1.19 3.98,2.94 5.32,5.2 1.34,2.26 2.02,5.09 2.02,8.42 0,3.33 -0.68,6.07 -2.02,8.33 -1.35,2.28 -3.09,3.94 -5.29,5.09 -2.21,1.15 -4.66,1.73 -7.26,1.73 m 14.67,-38.29 c -0.31,-0.18 -0.63,-0.35 -0.94,-0.51 -4.11,-2.11 -8.73,-3.17 -13.72,-3.17 -4.99,0 -9.64,1.07 -13.81,3.17 -4.21,2.12 -7.6,5.23 -10.07,9.24 -2.47,4.01 -3.72,8.86 -3.72,14.42 0,5.56 1.25,10.3 3.72,14.28 2.47,3.98 5.85,7.07 10.07,9.19 4.17,2.1 8.82,3.17 13.81,3.17 4.99,0 9.61,-1.07 13.72,-3.17 0.32,-0.16 0.63,-0.33 0.94,-0.51 V 293 h 12.74 v -70.69 h -12.74 v 21.97 z"
                    />
                    <g id="I">
                        <rect
                            style={cls1Style}
                            x="479.31"
                            y="241.53999"
                            width="12.64"
                            height="51.439999"
                            id="rect4"
                        />
                        <rect
                            style={cls1Style}
                            x="479.31"
                            y="222.28"
                            width="12.64"
                            height="12.64"
                            id="rect5"
                        />
                    </g>
                    <path
                        id="G"
                        style={cls1Style}
                        d="m 512.14,276 c -1.14,-2.24 -1.71,-4.71 -1.71,-7.34 0,-2.63 0.58,-5.09 1.71,-7.34 1.13,-2.23 2.76,-3.95 5,-5.28 2.22,-1.32 4.97,-1.99 8.18,-1.99 3.21,0 6.14,0.68 8.36,2.03 2.22,1.35 3.92,3.13 5.07,5.31 1.16,2.21 1.75,4.65 1.75,7.27 0,2.62 -0.59,5.06 -1.75,7.27 -1.15,2.18 -2.85,3.96 -5.07,5.31 -2.22,1.35 -5.03,2.03 -8.36,2.03 -3.33,0 -5.97,-0.67 -8.18,-1.99 -2.24,-1.33 -3.87,-3.05 -5,-5.28 m 33.73,10.48 c 1.03,-1.3 1.93,-2.72 2.7,-4.25 2.07,-4.1 3.11,-8.67 3.11,-13.58 0,-4.91 -1.05,-9.47 -3.11,-13.58 -0.18,-0.35 -0.37,-0.69 -0.56,-1.03 h 4.53 v -12.52 h -27.31 c -5.36,0.02 -10.05,1.25 -13.94,3.66 -3.91,2.43 -6.95,5.75 -9.03,9.89 -2.07,4.1 -3.11,8.67 -3.11,13.57 0,4.9 1.05,9.47 3.11,13.57 2.09,4.14 5.12,7.47 9.03,9.89 3.88,2.41 8.55,3.63 13.9,3.65 v 0.03 h 12.21 c 2.41,0 4.38,1.96 4.38,4.38 0,2.42 -1.96,4.38 -4.38,4.38 h -30.94 v 11.75 h 30.94 c 8.89,0 16.13,-7.24 16.13,-16.13 0,-5.78 -3.06,-10.86 -7.65,-13.71"
                    />
                    <g id="I-2">
                        <rect
                            style={cls1Style}
                            x="561.23999"
                            y="222.28"
                            width="12.64"
                            height="12.64"
                            id="rect6"
                        />
                        <rect
                            style={cls1Style}
                            x="561.23999"
                            y="241.53999"
                            width="12.64"
                            height="51.439999"
                            id="rect7"
                        />
                    </g>
                    <g id="T">
                        <path
                            style={cls1Style}
                            d="m 595.66,253.07 v 20.74 c 0,2.79 0.6,4.7 1.82,5.86 1.2,1.13 3.08,1.59 5.76,1.44 l 0.82,-0.05 v 11.7 l -0.69,0.07 c -0.98,0.1 -1.94,0.16 -2.87,0.16 -4.81,0 -8.88,-1.39 -11.87,-4.08 -3.74,-3.37 -5.72,-8.61 -5.72,-15.17 V 222.4 h 12.74 v 19.15 h 8.46 v 11.54 h -8.46 z"
                            id="path7"
                        />
                    </g>
                    <path
                        id="A-2"
                        style={cls1Style}
                        d="m 636.9,282.5 c -2.67,0 -5.18,-0.59 -7.46,-1.74 -2.26,-1.15 -4.02,-2.81 -5.37,-5.08 -1.34,-2.25 -2.02,-5.05 -2.02,-8.33 0,-3.28 0.69,-6.25 2.06,-8.5 1.37,-2.26 3.19,-3.99 5.4,-5.15 2.25,-1.18 4.73,-1.78 7.39,-1.78 2.66,0 5.02,0.61 7.22,1.81 2.19,1.19 3.98,2.94 5.32,5.2 1.34,2.26 2.02,5.09 2.02,8.42 0,3.33 -0.68,6.07 -2.02,8.33 -1.35,2.28 -3.09,3.94 -5.29,5.09 -2.21,1.15 -4.66,1.73 -7.26,1.73 m 14.67,-38.29 c -0.31,-0.18 -0.63,-0.35 -0.95,-0.51 -4.11,-2.1 -8.73,-3.17 -13.72,-3.17 -4.99,0 -9.64,1.07 -13.81,3.17 -4.21,2.13 -7.6,5.23 -10.07,9.24 -2.47,4.01 -3.72,8.86 -3.72,14.42 0,5.56 1.25,10.3 3.72,14.28 2.47,3.98 5.85,7.07 10.07,9.19 4.17,2.1 8.82,3.17 13.81,3.17 4.99,0 9.61,-1.07 13.72,-3.17 0.32,-0.16 0.63,-0.33 0.95,-0.51 v 2.67 h 12.73 v -52.4 h -12.73 v 3.64 z"
                    />
                    <rect
                        id="L"
                        style={cls1Style}
                        x="673.64001"
                        y="222.28"
                        width="12.74"
                        height="70.690002"
                    />
                </g>
            </g>
        </svg>
    );

    return redirect ? <Link to={redirect || '/'}>{logoIcon}</Link> : logoIcon;
};

export default B2DLogoIcon;
