import * as React from 'react';
import { Box } from '@mui/system';
import { Button } from '@mui/material';
import { ModalContext } from '@europrocurement/flexy-components';
import { MultiStepContextProps } from './types';

type FooterProps = MultiStepContextProps;

const MultiStepFormFooter: React.FC<FooterProps> = function (props) {
    const { multiStepForm } = props;
    const {
        currentStep: { formContext },
        stepState: { isFirstStep, isLastStep, isStepValid, isStepDisabled },
        onPrevious,
        onNext,
        isFormSubmitting,
    } = multiStepForm;
    const { modalActions } = React.useContext(ModalContext ?? null);

    if (!formContext) {
        return null;
    }

    const defaultStyle = { marginLeft: '20px', minWidth: '137px' };
    const disabledStyle = {
        ...defaultStyle,
        cursor: 'not-allowed',
        opacity: '0.4',
    };
    return (
        <Box
            marginTop="auto"
            sx={{ width: '100%' }}
        >
            <Box
                alignSelf="end"
                display="flex"
                marginTop="20px"
            >
                <Button
                    sx={{ marginRight: '20px', minWidth: '137px' }}
                    onClick={modalActions.reset}
                    variant="outlined"
                >
                    Annuler
                </Button>

                <Box sx={{ marginLeft: 'auto' }}>
                    {!isFirstStep && (
                        <Button
                            onClick={onPrevious}
                            variant="outlined"
                        >
                            Étape précédente
                        </Button>
                    )}

                    <Button
                        type="submit"
                        sx={!isStepValid ? disabledStyle : defaultStyle}
                        onClick={onNext}
                        variant="contained"
                        disabled={isFormSubmitting || isStepDisabled}
                    >
                        {isLastStep ? 'Envoyer' : 'Étape suivante'}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default MultiStepFormFooter;
