/* eslint-disable @typescript-eslint/no-explicit-any */
import { DossierFormalite, Fournisseur, RubriqueFacturation } from '@europrocurement/l2d-domain';
import {
    FactureAchatJsonldFactureAchatCreateFactureAchatWrite,
    FactureAchatJsonldFactureAchatWrite,
    FactureAchatLigneDetailJsonldFactureAchatLigneWriteFactureAchatLigneCreate,
    FactureAchatLigneJsonldFactureAchatLigneWriteFactureAchatLigneCreate,
    FactureAchatLigneJsonldFactureAchatRead,
} from '@europrocurement/l2d-domain/openApi/ApiAchats';
import { UseSwitchLockValuesProps } from '../../hooks/useSwitchLockValues';

export type ToggableSection = {
    switchLockValues: UseSwitchLockValuesProps;
};

export type LigneProduit = {
    ordre?: number;
    idLigne?: number | '';
    idLigneVente?: number;
    rubriqueFacturation?: {
        id: number;
        code?: string;
    };
    libelle?: string;
    ht?: number;
    tva?: number | string;
    txtva?: {
        label: number;
        value: number;
    };
    ttc?: number | string;
};

export type LigneProduitRegistered = Partial<LigneProduit> & { id?: string };

export type TableauProduits = Array<Partial<LigneProduit>>;

export interface NoteFactureForm {
    '@id': string;
    note: string;
    // idDossier?: number;
}

export type DispatchValue = {
    type: string;
    payload: any;
};

export type EnteteFactureForm = {
    numero_facture: string;
    date_facture: Date;
    avoir?: boolean;
    fournisseur: Fournisseur;
    siren_fournisseur?: Fournisseur | string;
    raison_sociale_fournisseur: string;
    type_remise_fournisseur: string;
    id_statement?: number;
};

export type FactureFormObject = EnteteFactureForm & {
    numero_annonce: string;
    numero_dossier: string;
    numero_dossier_formalite?: DossierFormalite | string;
    dossier: any;
    dossier_formalite: any;
    facture_formalite: boolean;
    notes: NoteFactureForm[];
    produits: TableauProduits;
    total_ht: number | string;
    total_tva: number | string;
    total_txtva: number;
    total_ttc: number | string;
    nom_journal: string;
    date_parution: string;
    nom_annonceur: string;
    denomination_societe_formalite: string;
    rsf_inclus_txremise: string;
    goToNext?: boolean;
    id_statement?: number;
};

export type LigneAchat = FactureAchatLigneJsonldFactureAchatRead;

export type LigneWrite = FactureAchatLigneJsonldFactureAchatLigneWriteFactureAchatLigneCreate;

export type FactureAchatCreate = FactureAchatJsonldFactureAchatCreateFactureAchatWrite;

export type FactureAchatUpdate = FactureAchatJsonldFactureAchatWrite;

export type TableauAchat = Array<Partial<LigneAchat>>;

export type TableauWrite = Array<Partial<LigneWrite>>;

export type DetailsAchat = {
    idRubriqueFacturation: number;
    ht: string;
    tauxTva: number;
    tva: string;
};

export type DetailsWrite =
    FactureAchatLigneDetailJsonldFactureAchatLigneWriteFactureAchatLigneCreate;

export type TotauxFactureAchatType = {
    ht: number;
    tva: number;
    ttc: number;
};

export const RSFRubFac: RubriqueFacturation = {
    id: 99,
    code: 'RSF',
    domainesAchats: [
        {
            id: 53,
            domaine: { id: 1 },
            defaut: true,
        },
        {
            id: 66,
            domaine: { id: 3 },
            defaut: false,
        },
        {
            id: 74,
            domaine: { id: 4 },
            defaut: false,
        },
        {
            id: 79,
            domaine: { id: 6 },
            defaut: false,
        },
        {
            id: 84,
            domaine: { id: 7 },
            defaut: false,
        },
        {
            id: 102,
            domaine: { id: 8 },
            defaut: true,
        },
        {
            id: 105,
            domaine: { id: 10 },
            defaut: true,
        },
    ],
};

export type filterOptionsProps = {
    sliceName: string;
    dataSourceName: string;
};
