import * as React from 'react';
import { Badge, Box, SxProps, Tooltip } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';

type ExistingPrescriberBadgeProps = {
    numberOfPrescribers: number;
    sx?: SxProps;
    onClick?: () => void;
};

const ExistingPrescriberBadge: React.FunctionComponent<ExistingPrescriberBadgeProps> = function (
    props,
) {
    const { numberOfPrescribers, onClick, sx } = props;

    const getExistingPrescribersLabel = () => {
        const plural = numberOfPrescribers > 1;
        const pluralSuffix = plural ? 's' : '';

        return `Il existe ${numberOfPrescribers} autre${pluralSuffix} prescripteur${pluralSuffix} pour ce numéro de
Siret :`;
    };

    return (
        <Tooltip title={getExistingPrescribersLabel()}>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="end"
                sx={{
                    height: '100%',
                    cursor: onClick ? 'pointer' : '',
                    ...sx,
                }}
                onClick={onClick}
            >
                <Badge
                    badgeContent={numberOfPrescribers}
                    color="primary"
                >
                    <PersonIcon
                        fontSize="large"
                        color="action"
                    />
                </Badge>
            </Box>
        </Tooltip>
    );
};

export default ExistingPrescriberBadge;
