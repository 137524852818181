import * as React from 'react';
import { AxiosError } from 'axios';
import { Box, Card, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { BooleanBadge } from '../../atoms';
import CenterCircularProgress from '../CenterCircularProgress/CenterCircularProgress';

export type relSociete = {
    plateformeB2BAutorise?: boolean;
    clientSuivi?: boolean;
    clientXl?: boolean;
    devisDetaille?: boolean;
};

export type ConfigurationCardProps = {
    companyRelation: relSociete;
    changeConfiguration: (field: keyof relSociete, newValue: boolean) => Promise<unknown>;
};

export type BadgeLoadingItem = {
    field: string;
    isLoading: boolean;
};

export type BadgeLoadingCollection = BadgeLoadingItem[];

const ConfigurationCard: React.FunctionComponent<ConfigurationCardProps> = function ({
    companyRelation,
    changeConfiguration,
}: ConfigurationCardProps) {
    const { clientXl, clientSuivi, plateformeB2BAutorise, devisDetaille } = companyRelation;

    type BooleanBadgeProps = {
        label: string;
        logo?: string;
        field: keyof relSociete;
        value?: boolean;
    };

    const booleanBadges: Array<BooleanBadgeProps> = [
        {
            label: 'Accès B2B',
            field: 'plateformeB2BAutorise',
            value: plateformeB2BAutorise,
        },
        {
            label: 'Devis formalité détaillé',
            field: 'devisDetaille',
            value: devisDetaille,
        },
        {
            label: 'Client suivi',
            field: 'clientSuivi',
            value: clientSuivi,
        },
        // {
        //     label: 'Facturation DOD',
        //     field: '', //définir donnée relation
        //     value: false
        // },
        {
            label: 'Client XL',
            field: 'clientXl',
            value: clientXl,
        },
    ];

    const [isConfigurationUpdating, setIsConfigurationUpdating] = React.useState<boolean>(false);

    const { enqueueSnackbar } = useSnackbar();
    const toggleValue = (field: keyof relSociete, value?: boolean) => {
        setIsConfigurationUpdating(true);

        changeConfiguration(field, !value)
            .then(() => {
                setIsConfigurationUpdating(false);
            })
            .catch((error) => {
                console.error('error');
                console.error(error);
                setIsConfigurationUpdating(false);
                if (
                    error instanceof AxiosError &&
                    error.response &&
                    error.response.data.exception &&
                    error.response.data.exception.message
                ) {
                    enqueueSnackbar(
                        <Typography>{error.response.data.exception.message}</Typography>,
                        {
                            variant: 'error',
                        },
                    );
                } else if (error instanceof Error) {
                    enqueueSnackbar(<Typography>{error.message}</Typography>, {
                        variant: 'error',
                    });
                }
            });
    };

    return (
        <Card sx={{ gap: '15px', padding: '15px', display: 'flex', flexDirection: 'column' }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '15px',
                    padding: '0px',
                    alignItems: 'center',
                }}
            >
                <Typography
                    variant="h5"
                    fontWeight="bold"
                >
                    Configuration{' '}
                </Typography>

                {booleanBadges.map(({ label, logo, field, value }) => (
                    <BooleanBadge
                        key={label}
                        label={label}
                        icon
                        logo={logo}
                        value={value}
                        toggleAction={() => toggleValue(field, value)}
                        disabled={isConfigurationUpdating}
                    />
                ))}

                {isConfigurationUpdating && (
                    <CenterCircularProgress sx={{ width: 'inherit', maxHeight: '20px' }} />
                )}
            </Box>
        </Card>
    );
};

export default ConfigurationCard;
