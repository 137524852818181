/* eslint-disable @typescript-eslint/no-explicit-any */
import md5 from 'md5';

export const filtersHasher: (filtersToHash: any) => string | undefined = (filtersToHash) => {
    if (!filtersToHash) return undefined;

    return md5(
        Object.keys(filtersToHash).reduce((acc, key) => {
            const filterValue = filtersToHash[key as keyof typeof filtersToHash];
            return acc + JSON.stringify(filterValue);
        }, ''),
    );
};

export default filtersHasher;
