/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import {
    Button,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';

type ActionModalProps = {
    onClose: () => void;
    action?: any;
    title: string;
    actionText: string;
    actionLabel: string;
    cancelLabel?: string;
    asyncAction?: any;
    callback?: () => void;
};
const ActionModal: React.FunctionComponent<ActionModalProps> = function (props) {
    const { onClose, action, title, actionText, actionLabel, cancelLabel, asyncAction, callback } =
        props;

    const [isActionPending, setIsActionPending] = React.useState<boolean>(false);

    const onAsyncConfirm = async () => {
        setIsActionPending(true);
        await asyncAction();
        setIsActionPending(false);
    };

    const onConfirm = () => {
        if (asyncAction) {
            onAsyncConfirm();
        } else {
            action();
        }

        if (callback) {
            callback();
        }
    };

    return (
        <>
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {actionText ?? 'Êtes vous sur de vouloir effectuer cette action'}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onConfirm}
                    disabled={isActionPending}
                >
                    {actionLabel ?? 'Confirmer'}
                </Button>
                <Button onClick={onClose}>{cancelLabel ?? 'Annuler'}</Button>
            </DialogActions>
        </>
    );
};

export default ActionModal;
