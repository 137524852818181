import React, { FunctionComponent } from 'react';

import {
    SxProps,
    Tooltip,
    TooltipProps,
    Typography,
    TypographyProps,
    useTheme,
} from '@mui/material';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/system';

export type FaOptionIconProps = {
    size?: FontAwesomeIconProps['size'];
    icon: FontAwesomeIconProps['icon'];
    option?: FontAwesomeIconProps['icon'];
    iconProps?: Omit<FontAwesomeIconProps, 'icon' | 'size'>;
    optionProps?: Omit<FontAwesomeIconProps, 'icon' | 'size'>;
    color?: TypographyProps['color'];
    optionColor?: TypographyProps['color'];
    tooltip?: TooltipProps['title'];
    withShape?: boolean;
    shapeSize?: number;
    shapeColor?: TypographyProps['color'];
    sx?: SxProps;
};

const FaOptionIcon: FunctionComponent<FaOptionIconProps> = function ({
    size = '1x',
    icon,
    iconProps = {},
    optionProps = {},
    option = undefined,
    color = undefined,
    optionColor = undefined,
    tooltip = undefined,
    withShape = false,
    shapeSize = 48,
    shapeColor = undefined,
    sx = undefined,
}: FaOptionIconProps) {
    const theme = useTheme();

    type CSS = React.CSSProperties;

    const shape = (
        <svg
            width={shapeSize}
            height={shapeSize}
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M16.3647 45.5366C8.59052 41.1825 4.01469 36.2267 1.37052 26.7742C-1.27365 17.3217 2.32052 12.7933 9.77802 8.16999C17.8688 3.10332 25.0572 1.51999 30.963 0.316656C36.8688 -0.886677 41.508 1.34582 44.9438 6.99832C48.3797 12.635 48.9022 18.2717 46.5905 23.845C44.2788 29.4183 42.6005 33.1708 37.7713 39.9C32.9422 46.6291 24.123 49.8591 16.3647 45.5366Z"
                fill={shapeColor || theme.palette.primary.light}
            />
        </svg>
    );

    const centeredContent: CSS = {
        display: 'flex',
        // justifyContent: 'center',
        // alignItems: 'center',
    };

    let containerStyle: CSS = {};
    let itemStyle: CSS = {};
    let iconStyle: CSS = {};
    let shapeStyle: CSS = {};
    if (withShape) {
        containerStyle = {
            position: 'relative',
            marginBottom: '15px',
            ...centeredContent,
        };
        itemStyle = {
            // width: '100%',
            // height: '100%',

            // top: '15px',
            left: '0px',
            ...centeredContent,
        };
        iconStyle = { ...itemStyle, zIndex: '10' };
        shapeStyle = { ...itemStyle, position: 'absolute' };
    }

    return (
        <Box>
            <Box sx={containerStyle}>
                <Tooltip title={tooltip}>
                    <Typography
                        sx={{
                            fontSize: 'inherit',
                            ...sx,
                        }}
                        component="span"
                        color={color || undefined}
                    >
                        <Box
                            sx={iconStyle}
                            className="fa-layers fa-fw fa-lg"
                        >
                            <FontAwesomeIcon
                                icon={icon}
                                size={size}
                                {...iconProps}
                            />
                            {option ? (
                                <Typography
                                    sx={{
                                        fontSize: 'inherit',
                                    }}
                                    component="span"
                                    color={optionColor || undefined}
                                >
                                    <FontAwesomeIcon
                                        icon={option}
                                        size={size}
                                        transform="shrink-8 right-6 down-8"
                                        {...optionProps}
                                    />
                                </Typography>
                            ) : null}
                        </Box>
                    </Typography>
                </Tooltip>
                {withShape ? <Box sx={shapeStyle}>{shape}</Box> : null}
            </Box>
        </Box>
    );
};

export default FaOptionIcon;
