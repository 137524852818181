import { RubriqueFacturation } from '@europrocurement/l2d-domain';
import React from 'react';
import { TableCell, TableRow } from '@mui/material';
import { findRubFactById } from '../forms/functions/produitFunctions';
import { type LigneAchat } from '../forms/types';
import { convertToNumberAndFormatToString } from '../forms/functions/calculsProduits';

export type DisplayLigneDossierType = {
    ligne: LigneAchat;
    rubriques: RubriqueFacturation[];
};

export const DisplayLigneDossier = function ({ ligne, rubriques }: DisplayLigneDossierType) {
    return (
        <TableRow
            key={ligne.id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
            {ligne.details && ligne.details.length > 0 && ligne.details[0].idRubriqueFacturation ? (
                <TableCell
                    component="th"
                    scope="ligne"
                >
                    {findRubFactById(rubriques, ligne.details[0].idRubriqueFacturation)?.code}
                </TableCell>
            ) : (
                <TableCell
                    component="th"
                    scope="ligne"
                >
                    --
                </TableCell>
            )}
            {ligne.details && ligne.details.length > 0 && ligne.details[0].ht ? (
                <TableCell align="right">
                    {convertToNumberAndFormatToString(ligne.details[0].ht)}
                </TableCell>
            ) : (
                <TableCell align="right">--</TableCell>
            )}
            {ligne.details && ligne.details.length > 0 && ligne.details[0].tva ? (
                <TableCell align="right">
                    {convertToNumberAndFormatToString(ligne.details[0].tva)}
                </TableCell>
            ) : (
                <TableCell align="right">--</TableCell>
            )}
            {ligne.details &&
            ligne.details.length > 0 &&
            ligne.details[0].tva &&
            ligne.details[0].ht ? (
                <TableCell align="right">
                    {convertToNumberAndFormatToString(
                        parseFloat(ligne.details[0].ht) + parseFloat(ligne.details[0].tva),
                    )}
                </TableCell>
            ) : (
                <TableCell align="right">--</TableCell>
            )}
        </TableRow>
    );
};

export default DisplayLigneDossier;
