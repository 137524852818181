import * as React from 'react';
import {
    CatalogueCatalogueRead,
    CatalogueJsonldCatalogueCreate,
    CatalogueJsonldCatalogueUpdate,
} from '@europrocurement/l2d-domain/openApi/ApiOffre';
import { useSelector } from 'react-redux';
import { customizerSelector } from '@b2d/redux/RootStore';
import { SubmitHandler } from 'react-hook-form';
import { FormObject } from '@europrocurement/flexy-form';
import { ModalContext } from '@europrocurement/flexy-components';
import useApiRequest from '@b2d/hooks/useApiRequest';
import ContentStep from './ContentStep';
import VisibilityStep from './VisibilityStep';
import OverrideStep from './OverrideStep';
import { ModelForm } from '../../forms/types';
import MultiStepForm from '../../components/form/MultiStepForm/MultiStepForm';
import OptionsStep from './OptionsStep';
import { CatalogFormData } from './validation';
import { formValuesToPayload, ressourceToFormValues } from '../../PayloadAdapter/catalog';
import model from '../../models/catalog';

/** Catalog Form : Splitted form in 4 steps
 *
 * Steps :
 * 1) Content : Catalog main informations
 * 2) Visibility :  Offer / Origin / Dates
 * 3) Overrides : List of package articles
 * 4) Options : Documents / Additional fields
 *
 * Available form actions : Create / Update
 */
const Form: React.FC<ModelForm<CatalogueCatalogueRead>> = function (props) {
    const { entity = {}, isCreate = false, afterSubmit } = props;
    const { modalActions } = React.useContext(ModalContext);
    const { request } = useApiRequest();
    const { xIdSociete: companyId } = useSelector(customizerSelector);
    const successCallback = () => {
        modalActions.reset();
        afterSubmit();
    };

    /** Form steps definitions */

    const formSteps = [
        {
            title: 'Contenu du catalogue',
            component: ContentStep,
        },
        {
            title: 'Visibilité',
            component: VisibilityStep,
        },
        {
            title: 'Surcharges',
            component: OverrideStep,
        },
        {
            title: 'Éléments optionnels',
            component: OptionsStep,
        },
    ];

    /** Form action | Creation & Update */

    /** Form input formatting */
    const getDefaultValues = () => ({
        companyId,
        formOptions: { packages: [], groups: [], offers: [], origins: [] },
        readOnlyDocuments: {
            articles: [],
            options: [],
            formalities: [],
        }, // ReadOnly related documents (Articles documents / Formalities ...)
    });

    const initiaValues = isCreate ? getDefaultValues() : ressourceToFormValues(entity);

    /** Form output formatting */
    const createModel: SubmitHandler<FormObject> = async (formValues) => {
        const payload = formValuesToPayload(formValues as CatalogFormData);
        const createRequest = model.create({
            catalogueJsonldCatalogueCreate: payload as unknown as CatalogueJsonldCatalogueCreate,
            xIdSociete: companyId,
        });
        await request(createRequest, { successCallback });
    };

    const updateModel: SubmitHandler<FormObject> = async (formValues) => {
        if (entity.id) {
            const payload = formValuesToPayload(formValues as CatalogFormData);
            const updateRequest = model.update({
                id: entity.id.toString(),
                catalogueJsonldCatalogueUpdate:
                    payload as unknown as CatalogueJsonldCatalogueUpdate,
                xIdSociete: companyId,
            });
            await request(updateRequest, { successCallback });
        }
    };

    const formAction = isCreate ? createModel : updateModel;

    return (
        <MultiStepForm
            steps={formSteps}
            initiaValues={initiaValues}
            submitAction={formAction}
            afterSubmit={afterSubmit}
            isCreate={isCreate}
        />
    );
};

export default Form;
