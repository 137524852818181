import React from 'react';

import { ColumnDatatable } from '@europrocurement/flexy-datatable';
import { MediaObject } from '@europrocurement/l2d-domain';
import { Stack, Typography } from '@mui/material';

import StatusList from '../../../../fragments/StatusList';

const columnItemFileName: ColumnDatatable<MediaObject> = {
    label: 'Nom du fichier',
    render: (mediaObject: MediaObject) => (
        <Stack
            direction="row"
            alignItems="center"
            gap={1}
        >
            <Typography>{mediaObject.originalName}</Typography>
            <StatusList itemToWatch={mediaObject} />
        </Stack>
    ),
    isDisplayed: true,
};

export default columnItemFileName;
