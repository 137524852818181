import { Card, Theme } from '@mui/material';
import { useTheme } from '@mui/system';
import * as React from 'react';

const PackageCard: React.FunctionComponent = function () {
    const theme: Theme = useTheme();

    const wrapperCardStyle = {
        boxShadow: 'none',
        border: '1px solid',
        backgroundColor: 'inherit',
        borderColor: theme.palette.secondary.main,
        height: '400px',
        width: '100%',
    };

    return (
        <Card sx={wrapperCardStyle}>
            <p>TITLE</p>
            <hr />
            <p>Liste des articles</p>
            <p>Liste des options</p>
            <p>Liste des champs complémentaires</p>
        </Card>
    );
};

export default PackageCard;
