import { RootStateType } from '@b2d/redux/RootStore';
import { OFFRE_REDUCER_NAME, PACKAGES_SLICE_NAME } from '@europrocurement/l2d-domain';
import { PackageJsonldPackageRead } from '@europrocurement/l2d-domain/openApi/ApiOffre';
import { offerPackageIcon } from '@europrocurement/l2d-icons';
import { packageApi } from '@b2d/redux/subReducers/Offers';
import { packagesDataSourcesThunks } from '@b2d/redux/subReducers/Offers/reducers';
import { ModelConfiguration, OfferModelItem, ModelMethods } from './types';

const modelKey = 'offer_package';
const modelName = 'Package';
const sliceName = PACKAGES_SLICE_NAME;
const api = packageApi;
const baseSelector = (state: RootStateType) => state[OFFRE_REDUCER_NAME][sliceName];
const datasourcesThunks = packagesDataSourcesThunks;

type ModelStructure = PackageJsonldPackageRead;

const configuration: ModelConfiguration = {
    key: modelKey,
    name: modelName,
    type: 'Package',
    selector: baseSelector,
    sliceName,
    api,
    datasourcesThunks,
    icon: offerPackageIcon,
};

const methods: ModelMethods<ModelStructure> = {
    getItemDefaultLabel: (model: ModelStructure) => `${model.nomCommercial}`,
};

const endpoints = {
    list: (...args: Parameters<typeof api.apiPackagesGetCollection>) =>
        api.apiPackagesGetCollection(...args),
    delete: (...args: Parameters<typeof api.apiPackagesIdDelete>) =>
        api.apiPackagesIdDelete(...args),
    dependencies: (...args: Parameters<typeof api.apiPackagesIdDependenciesGet>) =>
        api.apiPackagesIdDependenciesGet(...args),
    read: (...args: Parameters<typeof api.apiPackagesIdGet>) => api.apiPackagesIdGet(...args),
    update: (...args: Parameters<typeof api.apiPackagesIdPut>) => api.apiPackagesIdPut(...args),
    create: (...args: Parameters<typeof api.apiPackagesPost>) => api.apiPackagesPost(...args),
};

type ModelType = OfferModelItem<ModelStructure> & typeof endpoints;

const model: ModelType = {
    ...configuration,
    ...methods,
    ...endpoints,
};

export type OfferPackageModel = ModelType;

export default model;
