import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { customizerSelector, selectFactureAchat } from '@b2d/redux/RootStore'; // Adjust the import as per your project structure
import models from '@b2d/pages/Achats/models';
import { AppDispatch } from '@europrocurement/flexy-components/redux/storeConfig/store';
import { FactureFormObject } from '@b2d/pages/Achats/components/forms/types';
import { useSynchroG3Facture } from '@b2d/pages/Achats/components/forms/functions/dataHooks';
import { produitsToSomme } from '@b2d/pages/Achats/components/forms/functions/calculsProduits';

export type UpdateTotalsProps = {
    newTotals: Pick<FactureFormObject, 'total_ht' | 'total_tva' | 'total_ttc'>;
    invoiceId?: number | string;
};

export type CalculateLinesTotalsProps = {
    invoiceId?: number | string;
    newProducts: Pick<FactureFormObject, 'produits'>;
};

/**
 * Custom hook to calculate and update invoice totals.
 */
const useSectionTotals = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { enqueueSnackbar } = useSnackbar();
    const { xIdSociete } = useSelector(customizerSelector);
    const synchroG3Facture = useSynchroG3Facture();

    /**
     * Updates the totals on the server.
     * @param newTotals - The totals to update.
     */
    const updateTotals = useCallback(
        async ({ invoiceId, newTotals }: UpdateTotalsProps) => {
            const invoiceRequest = () =>
                models.invoicePurchase.patch({
                    id: invoiceId?.toString() || '-1',
                    factureAchatFactureAchatWrite: {
                        ht: newTotals.total_ht.toString(),
                        tva: newTotals.total_tva.toString(),
                        ttc: newTotals.total_ttc.toString(),
                    },
                    xIdSociete,
                });

            const syncRequest = () => synchroG3Facture(invoiceId?.toString() || '-1');

            const requestPromiseList: Array<typeof invoiceRequest> = [];

            requestPromiseList.push(invoiceRequest);

            try {
                await Promise.all(requestPromiseList.map((req) => req()));

                syncRequest();

                if (invoiceId) {
                    dispatch(selectFactureAchat({ id: invoiceId }));
                }

                enqueueSnackbar(`Les totaux de la facture ont été mis à jour avec succès`);
            } catch (error) {
                console.error('Error updating totals fields:', error);

                enqueueSnackbar(
                    `Une erreur est survenue lors de la mise à jour des totaux de la facture`,
                    {
                        variant: 'error',
                    },
                );
            }
        },
        [dispatch, enqueueSnackbar, synchroG3Facture, xIdSociete],
    );

    /**
     * Calculates and updates the line totals.
     */
    const calculateLinesTotals = useCallback(
        ({ invoiceId, newProducts }: CalculateLinesTotalsProps) => {
            const totalsCalculation = produitsToSomme(newProducts.produits);

            const formattedTotals: UpdateTotalsProps['newTotals'] = {
                total_ht: totalsCalculation.ht,
                total_tva: totalsCalculation.tva,
                total_ttc: totalsCalculation.ttc,
            };

            updateTotals({ invoiceId, newTotals: formattedTotals });
        },
        [updateTotals],
    );

    return {
        updateTotals,
        calculateLinesTotals,
    };
};

export default useSectionTotals;
