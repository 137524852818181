import { faPaperPlane, faPlus, faQuestion } from '@fortawesome/pro-solid-svg-icons';
import { NamedIconsType } from '../../types/types';

export const undefinedLogIcon: NamedIconsType = {
    props: {
        icon: faQuestion,
    },
    name: 'undefinedLog',
    displayName: 'Log inconnu',
};

export const newDosIcon: NamedIconsType = {
    props: { icon: faPlus },
    name: 'newSign',
    displayName: 'Création dossier signature',
};

export const raiseSignIcon: NamedIconsType = {
    props: { icon: faPaperPlane },
    name: 'raiseSign',
    displayName: 'Relance dossier signature',
};

export const logtimelineNamedIcons: NamedIconsType[] = [
    undefinedLogIcon,
    newDosIcon,
    raiseSignIcon,
];
