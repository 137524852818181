import * as React from 'react';
import { InformationCard, InformationCardProps } from '@europrocurement/flexy-components';
import { TblTypeDocumentTypedocumentRead } from '@europrocurement/l2d-domain/openApi/ApiOffre';
import { documentTypes } from '@europrocurement/l2d-domain';
import { JsonLdData } from '../forms/types';
import { documentStatusOptions } from './options';
import { EntityPreview } from '../components/types';

type EntityType = JsonLdData<JsonLdData<TblTypeDocumentTypedocumentRead>>;

const PreviewCard: React.FC<EntityPreview<EntityType>> = function (props) {
    const { entity, onEdit } = props;

    const cardTitle = entity.libelle ?? '';

    const getStatusLabel = () => {
        const status = documentStatusOptions.find((option) => option.value === entity.statut);
        if (!status) {
            return 'Aucun statut';
        }

        return status.label;
    };

    const getTypeLabel = () => {
        const status = documentTypes.find((option) => option.value === entity.type);
        if (!status) {
            return 'Aucun statut';
        }

        return status.label;
    };

    const cardItems: InformationCardProps['items'] = [
        {
            label: 'ID',
            value: entity.id?.toString() ?? null,
        },
        {
            label: "Libellé Demat' Facile",
            value: entity.libelleDF ?? null,
        },
        {
            label: 'Signable',
            value: entity.signable ? 'oui' : 'non',
        },
        {
            label: 'Domaine',
            value: entity.domaine?.libelle ?? null,
        },
        {
            label: 'Statut',
            value: getStatusLabel() ?? null,
        },
        {
            label: 'Type',
            value: getTypeLabel() ?? null,
        },
    ];

    return (
        <InformationCard
            title={cardTitle}
            items={cardItems}
            button={{
                label: 'Éditer',
                onClick: onEdit,
            }}
        />
    );
};

export default PreviewCard;
