/* eslint-disable @typescript-eslint/no-unused-vars */

import {
    SliceFactory,
    FetchCollectionData,
    FetchItemDataType,
    ApiCollectionResponse,
} from '@europrocurement/l2d-redux-utils';
import {
    ConfigurationParameters as AchatsConfigurationParameters,
    Configuration,
    FactureAchatApi,
    FactureAchatApiGetCoderejetFaStatsFactureAchatCollectionRequest,
    GetCoderejetFaStatsFactureAchatCollection200ResponseHydraMemberInner,
} from '../../../openApi/ApiAchats';
import { ACHATS_REDUCER_NAME } from '../constants';

export type CodeRejetStatsApiObject =
    GetCoderejetFaStatsFactureAchatCollection200ResponseHydraMemberInner & Record<string, unknown>;

export type CodeRejetStats = CodeRejetStatsApiObject;

export const CODE_REJET_STATS_SLICE_NAME = 'coderejetstats';

const mapperCodeRejetStats = function (codeRejetStats: CodeRejetStats) {
    return {
        ...codeRejetStats,
    } as CodeRejetStats;
};

export function createCodeRejetStatsSlice(configuration: AchatsConfigurationParameters) {
    const conf = new Configuration(configuration);
    const api = new FactureAchatApi(conf);

    const fetchCodeRejetStatsCollection: FetchCollectionData<CodeRejetStatsApiObject> = ({
        search,
        pagination,
        filters,
        orders,
        xIdSociete,
        rootstate,
    }) => {
        let dateFactureBefore: string | undefined;
        let dateFactureAfter: string | undefined;
        let createdAtBefore: string | undefined;
        let createdAtAfter: string | undefined;
        let categories: string | undefined;
        let codesRejets: string | undefined;
        let idEntiteFacturante: number | undefined;
        let idDossier: number | undefined;
        let idJal: number | undefined;
        let idSociete: number | undefined;

        // La gestion de la range jusqu'à aujourd'hui est géré côté API
        // pour dateFacture et createdAt

        if (filters.dateFacture) {
            [dateFactureBefore, dateFactureAfter] = filters.dateFacture as string[];
        }

        if (filters.createdAt) {
            [createdAtBefore, createdAtAfter] = filters.createdAt as string[];
        }

        if (
            filters.categories &&
            Array.isArray(filters.categories) &&
            filters.categories.length > 0
        ) {
            categories = JSON.stringify(filters.categories);
        }

        if (
            filters.codesRejets &&
            Array.isArray(filters.codesRejets) &&
            filters.codesRejets.length > 0
        ) {
            codesRejets = JSON.stringify(filters.codesRejets);
        }

        if (filters.idEntiteFacturante) {
            idEntiteFacturante = filters.idEntiteFacturante as number;
        }

        if (filters.idDossier) {
            idDossier = filters.idDossier as number;
        }

        if (filters.idJal) {
            idJal = filters.idJal as number;
        }

        if (filters.idSociete) {
            idSociete = filters.idSociete as number;
        }

        const requestParameters: FactureAchatApiGetCoderejetFaStatsFactureAchatCollectionRequest = {
            xIdSociete,
            dateFactureBefore,
            dateFactureAfter,
            createdAtBefore,
            createdAtAfter,
            idEntiteFacturante,
            lignesIdDossier: idDossier,
            idJal,
            idSociete,
            categories,
            codesRejets,
        };

        return api
            .getCoderejetFaStatsFactureAchatCollection(requestParameters)
            .then((res) => res.data as CodeRejetStatsApiObject) as Promise<
            ApiCollectionResponse<CodeRejetStatsApiObject>
        >;
    };

    const fetchCodeRejetStatsItem: FetchItemDataType<CodeRejetStatsApiObject> = ({
        idItem,
        search,
        pagination,
        filters,
        orders,
        xIdSociete,
        rootstate,
    }) =>
        api
            .getFactureAchatFactureAchatItem({
                id: String(idItem),
                xIdSociete,
            }) // NO ITEM FOR CODES REJETS
            .then((res) => res.data) as Promise<CodeRejetStatsApiObject>;

    const { slice: codeRejetStatsSlice, dataSourcesThunks: codeRejetStatsDataSourcesThunks } =
        SliceFactory.createSlice<CodeRejetStatsApiObject, CodeRejetStats>(
            CODE_REJET_STATS_SLICE_NAME,
            ACHATS_REDUCER_NAME,
            [],
            fetchCodeRejetStatsCollection,
            fetchCodeRejetStatsItem,
            mapperCodeRejetStats,
            {
                linkedCodeRejets: true,
            },
        );

    return { codeRejetStatsSlice, codeRejetStatsDataSourcesThunks };
}
