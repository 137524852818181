import { toISOString } from '@europrocurement/l2d-utils';

import type { MediaObject } from '@europrocurement/l2d-domain';
import type { FactureAchatJsonldFactureAchatWrite } from '@europrocurement/l2d-domain/openApi/ApiAchats';
import type { EnteteFactureForm } from '@b2d/pages/Achats/components/forms/types';

export default (
    formdata: EnteteFactureForm,
    idSociete: number,
    mediaObject: MediaObject,
): FactureAchatJsonldFactureAchatWrite => {
    const facture: FactureAchatJsonldFactureAchatWrite = {
        avoir: formdata.avoir || false,
        hasSetRsf: false,
        cloture: false,
        dateFacture: toISOString(formdata.date_facture),
        numeroFacture: formdata.numero_facture,
        idEntiteFacturante: formdata.fournisseur.id ? +formdata.fournisseur.id : undefined,
        libelleFacturante: formdata.fournisseur.libelle,
        idReleveAchat: formdata.id_statement,
        idSociete,
        pdfFacture: mediaObject['@id'],
        idJal: undefined,
    };

    return facture;
};
