import React from 'react';

export type ContextActionType = {
    icon: React.ReactNode;
    name: string;
    resetHash?: string;
    action: () => unknown;
};

export type ActionsContextType = {
    defaultActions: ContextActionType[];
    contextActions: ContextActionType[];
    defineContextActions: (actions: ContextActionType[]) => void;
    resetContextActions: () => void;
};

const ActionsContext = React.createContext<ActionsContextType>({
    defaultActions: [],
    contextActions: [],
    defineContextActions: () => {},
    resetContextActions: () => {},
});

// const ActionsContext = React.createContext<ActionsContextType>({
//     defaultActions: [],
//     contextActions: [],
// });

export default ActionsContext;
