import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { Box, Card, Grid, Typography } from '@mui/material';
import {
    CustomizerReducerType,
    DOSSIERS_SLICE_NAME,
    Dossier,
    DossierFormalite,
    FACTURES_ACHATS_SLICE_NAME,
    FactureAchat,
    Fournisseur,
    MediaObject,
    setXIdSociete,
} from '@europrocurement/l2d-domain';
import {
    PdfViewer,
    footerHeight,
    TopbarHeight,
    FlexyTabs,
    EmptyBox,
    FlexyHeaderForm,
} from '@europrocurement/flexy-components';
import type { FactureAchatLigneJsonldFactureAchatRead } from '@europrocurement/l2d-domain/openApi/ApiAchats';
import {
    FactureVente,
    FACTURE_VENTE_SLICE_NAME,
} from '@europrocurement/l2d-domain/reducers/dossiers/slices/factureVenteSlice';
import { DataSource, EuroprocApiResponseStatus } from '@europrocurement/l2d-redux-utils';
import { UseKeycloakCheckRole } from '@europrocurement/l2d-keycloak';
import useAddCustomContextActions from '@b2d/pages/Achats/hooks/contextActions/useAddCustomContextActions';
import useDeleteInvoice from '@b2d/pages/Achats/hooks/contextActions/useDeleteInvoice';
import useSyncG3 from '@b2d/pages/Achats/hooks/contextActions/useSyncG3';
import {
    selectMediaObject,
    selectFactureAchat,
    selectFactureVente,
    AppDispatch,
    RootStateType,
    selectFournisseur,
    selectDossier,
} from '@b2d/redux/RootStore';
import { CodeRejetsChipType } from '@b2d/pages/Achats/components/widgets/CodeRejetsChipList';
import { useGetLignes, useGetRejets } from '@b2d/pages/Achats/components/forms/functions/dataHooks';
import {
    AttachmentsWidget,
    AttachmentType,
} from '@b2d/pages/Achats/components/widgets/AttachmentsWidget';
import { NotesFacture } from '@b2d/pages/Achats/components/widgets/NotesFacture';
import NoteObject from '@b2d/pages/Achats/components/notes/NoteObject';
import SkeletonLoader from '@b2d/pages/Achats/components/SkeletonLoader';
import useLoadingStatus from '@b2d/hooks/useLoading';
import InvoiceDataCard from '@b2d/pages/Achats/components/fragments/InvoiceDataCard/InvoiceDataCard';
import { jsonLdIdScraper } from '@europrocurement/l2d-utils';
import { selectDossierFormalite } from '@b2d/redux/subReducers/FormalityReducer';
import { contactSupport, refreshPage } from '@b2d/utils/wording';
import { useSnackbar } from 'notistack';
import useHandleCleanIOInvoiceForm from '@b2d/hooks/useHandleCleanIOInvoiceForm';
import UpdateSimpleInvoiceForm from '@b2d/pages/Achats/components/forms/simple/UpdateSimpleInvoiceForm';

const UpdateSimpleInvoiceView: React.FunctionComponent = function () {
    const { mediaobjectid, factureachatid } = useParams();
    useHandleCleanIOInvoiceForm({});

    /** ********** */
    /* DataSources */
    /** ********** */
    const foliosDatasource: DataSource<Dossier> = useSelector(
        (s: RootStateType) => s.dossiers.dos.main,
        _.isEqual,
    );

    const foliosFormalityDatasource: DataSource<DossierFormalite> = useSelector(
        (s: RootStateType) => s.formalites.dosform.main,
        _.isEqual,
    );

    const publishersDatasource: DataSource<Fournisseur> = useSelector(
        (s: RootStateType) => s.tiers.fournisseur.main,
        _.isEqual,
    );

    const sellsDatasource: DataSource<FactureVente> = useSelector(
        (s: RootStateType) => s.dossiers.facturevente.main,
        _.isEqual,
    );

    /** ********** */
    /* DataSources */
    /** ********** */

    /** ************* */
    /* Selected items */
    /** ************* */
    const mediaObject: MediaObject = useSelector(
        (s: RootStateType) => s.achats.mediaobject.main.selected,
        _.isEqual,
    );

    const factureAchatSelected: FactureAchat = useSelector(
        (s: RootStateType) => s.achats.factureachat.main.selected,
        _.isEqual,
    );

    const factureAchatSelectedStatus: EuroprocApiResponseStatus = useSelector(
        (s: RootStateType) => s.achats.factureachat.main.selectedStatus,
        _.isEqual,
    );

    const fournisseurSelected: Fournisseur | undefined = useSelector(
        (s: RootStateType) => s.tiers.fournisseur.main.selected,
        _.isEqual,
    );

    const dossierSelected: Dossier | undefined = useSelector(
        (s: RootStateType) => s.dossiers.dos.main.selected,
        _.isEqual,
    );

    const dossierFormaliteSelected: DossierFormalite | undefined = useSelector(
        (s: RootStateType) => s.formalites.dosform.main.selected,
        _.isEqual,
    );
    /** ************* */
    /* Selected items */
    /** ************* */

    const [lignes, setLignes] = useState<FactureAchatLigneJsonldFactureAchatRead[]>([]);
    const [rejets, setRejets] = useState<CodeRejetsChipType[]>([]);

    const [lignesStatus, setLignesStatus] = useState<EuroprocApiResponseStatus>('idle');
    const [rejetsStatus, setRejetsStatus] = useState<EuroprocApiResponseStatus>('idle');

    const [isFormalite, setIsFormalite] = useState<boolean>();

    const dispatch = useDispatch<AppDispatch>();

    const { enqueueSnackbar } = useSnackbar();

    const roleChecker = UseKeycloakCheckRole();
    const isInterne = roleChecker('realm:interne');

    useEffect(() => {
        dispatch({
            type: `${DOSSIERS_SLICE_NAME}/deletemainSelected`,
        });
    }, [dispatch]);

    if (!factureachatid) {
        throw new Error('FactureAchatId is undefined !');
    }

    /** Si c'est la mauvaise facture, on la delete */
    useEffect(() => {
        if (factureAchatSelected && factureachatid !== factureAchatSelected?.id?.toString()) {
            dispatch({
                type: `${FACTURES_ACHATS_SLICE_NAME}/deletemainSelected`,
            });
        }
    }, [dispatch, factureAchatSelected, factureachatid]);

    /** va chercher la facture */
    useEffect(() => {
        if (!factureAchatSelected && factureAchatSelectedStatus !== 'loading') {
            dispatch(selectFactureAchat({ id: +factureachatid }));
        }
    }, [dispatch, factureAchatSelected, factureAchatSelectedStatus, factureachatid]);

    /**
     * Select MediaObject corresponding url param mediaobjectid.
     *
     * Should select MediaObject on mount.
     */
    useEffect(() => {
        if (mediaobjectid) {
            dispatch(selectMediaObject({ id: +mediaobjectid }));
        } else {
            enqueueSnackbar(
                `Le PDF n'est peut-être pas le bon, car son identifiant n'a pas été trouvé dans l'url. ${refreshPage} ${contactSupport}`,
                {
                    variant: 'warning',
                },
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Si le PDF a un idSociete,
     * alors le définir comme xIdSociete de l'utilisateur
     */
    useEffect(() => {
        if (factureAchatSelected?.idSociete) {
            dispatch(
                setXIdSociete(
                    factureAchatSelected?.idSociete as CustomizerReducerType['xIdSociete'],
                ),
            );
        }
    }, [dispatch, factureAchatSelected?.idSociete]);

    /**
     * Définir le fournisseur sélectionné
     */
    useEffect(() => {
        if (!factureAchatSelected || !factureAchatSelected.idEntiteFacturante) return;

        if (
            !fournisseurSelected ||
            fournisseurSelected.id !== factureAchatSelected.idEntiteFacturante
        ) {
            if (factureAchatSelected.idEntiteFacturante) {
                if (publishersDatasource.selectedStatus !== 'loading') {
                    dispatch(selectFournisseur({ id: factureAchatSelected.idEntiteFacturante }));
                }
            }
        }
    }, [dispatch, factureAchatSelected, publishersDatasource.selectedStatus, fournisseurSelected]);

    /**
     * A partir du fournisseur sélectionné,
     * déterminer le domaine.
     */
    useEffect(() => {
        if (fournisseurSelected?.ddmId !== undefined && fournisseurSelected?.ddmId >= 0) {
            setIsFormalite(fournisseurSelected?.ddmId === 3);
        }
    }, [fournisseurSelected]);

    /**
     * Définir le dossier.
     * Dépends du domaine définit à la sélection du fournisseur.
     */
    useEffect(() => {
        if (isFormalite === undefined || isFormalite) return;

        if (foliosDatasource.selectedStatus !== 'loading' && lignes && lignes[0]) {
            if (!dossierSelected) {
                if (lignes[0].idDossier === null) {
                    setIsFormalite(true);
                    return;
                }
                dispatch(
                    selectDossier({
                        id: `${lignes[0].idDossier}`,
                    }),
                );
            }
        }
    }, [dispatch, dossierSelected, foliosDatasource.selectedStatus, isFormalite, lignes]);

    /**
     * Définir la facture de vente.
     * Dépends du domaine définit à la sélection du fournisseur.
     */
    useEffect(() => {
        if (isFormalite === undefined || isFormalite) return;

        let idFactureVente: number;

        if (dossierSelected && dossierSelected.facture) {
            idFactureVente = jsonLdIdScraper(dossierSelected.facture);
            if (sellsDatasource.selectedStatus !== 'loading') {
                if (!sellsDatasource.selected || sellsDatasource.selected.id !== idFactureVente) {
                    dispatch(selectFactureVente({ id: idFactureVente }));
                }
            }
        } else {
            dispatch({
                type: `${FACTURE_VENTE_SLICE_NAME}/deletemainSelected`,
            });
        }
    }, [
        dispatch,
        dossierSelected,
        isFormalite,
        sellsDatasource.selected,
        sellsDatasource.selectedStatus,
    ]);

    /**
     * Définir le dossier de formalité.
     * Dépends du domaine définit à la sélection du fournisseur.
     */
    useEffect(() => {
        if (isFormalite === undefined || !isFormalite) return;

        if (foliosFormalityDatasource.selectedStatus !== 'loading' && lignes && lignes[0]) {
            if (
                !dossierFormaliteSelected ||
                dossierFormaliteSelected.id !== lignes[0].idDossierFpro
            ) {
                if (lignes[0].idDossierFpro === null) {
                    setIsFormalite(false);
                }

                dispatch(
                    selectDossierFormalite({
                        id: `${lignes[0].idDossierFpro}`,
                    }),
                );
            }
        }
    }, [
        dispatch,
        dossierFormaliteSelected,
        foliosFormalityDatasource.selectedStatus,
        isFormalite,
        lignes,
    ]);

    const getRejets = useGetRejets();
    const getLignes = useGetLignes();

    const fetchLines = useCallback(() => {
        if (factureAchatSelected?.id && lignesStatus !== 'loading') {
            setLignesStatus('loading');
            getLignes(factureAchatSelected.id, [])
                .then((data) => {
                    setLignesStatus('succeeded');
                    setLignes(data);
                })
                .catch((error) => {
                    setLignesStatus('failed');
                    console.error(error);
                });
        }
    }, [factureAchatSelected?.id, getLignes, lignesStatus]);

    useEffect(() => {
        if (factureAchatSelected && lignesStatus !== 'succeeded') {
            fetchLines();
        }
    }, [factureAchatSelected, fetchLines, lignesStatus]);

    useEffect(() => {
        if (
            factureAchatSelected &&
            factureAchatSelected.id &&
            rejetsStatus !== 'loading' &&
            rejetsStatus !== 'succeeded'
        ) {
            setRejetsStatus('loading');
            getRejets(factureAchatSelected.id, []).then((data) => {
                setRejetsStatus('succeeded');
                setRejets(data);
            });
        }
    }, [factureAchatSelected, getRejets, rejetsStatus]);

    const syncG3 = useSyncG3({
        invoice: factureAchatSelected,
    });

    const deleteInvoice = useDeleteInvoice({
        invoice: factureAchatSelected,
    });

    useAddCustomContextActions([...(isInterne ? [syncG3, deleteInvoice] : [])]);

    const readyToDisplayForm = useMemo<boolean>(() => {
        if (
            lignesStatus !== 'succeeded' ||
            rejetsStatus !== 'succeeded' ||
            factureAchatSelectedStatus !== 'succeeded'
        ) {
            // console.log('not ready loading status');
            return false;
        }

        if (!factureAchatSelected) {
            // console.log('not ready no facture');
            return false;
        }

        if (!fournisseurSelected) {
            // console.log('not ready no fournisseur');
            return false;
        }

        if (!dossierSelected && !dossierFormaliteSelected) {
            // console.log('not ready no dossier');
            return false;
        }

        if (
            factureAchatSelected.idEntiteFacturante &&
            factureAchatSelected.idEntiteFacturante !== 0
        ) {
            if (fournisseurSelected.id !== factureAchatSelected.idEntiteFacturante) {
                // console.log('not ready no idEntiteFacturante');
                return false;
            }
        }

        if (dossierSelected && lignes && lignes.length > 0 && lignes[0].idDossierFpro === null) {
            let idFactureVente: number;
            if (dossierSelected?.facture && dossierSelected.facture) {
                idFactureVente = jsonLdIdScraper(dossierSelected.facture);
                if (!sellsDatasource.selected) {
                    // console.log('not ready dossier has not facture');
                    return false;
                }
                if (sellsDatasource.selected.id !== idFactureVente) {
                    // console.log('not ready no vente');
                    return false;
                }
            }
        } else if (
            dossierFormaliteSelected &&
            lignes &&
            lignes.length > 0 &&
            lignes[0].idDossier === null
        ) {
            const dossiersFProToLoad = _.uniq(lignes.map((ligne) => ligne.idDossierFpro));

            if (dossiersFProToLoad.length > 1) {
                console.error('Multi dossier à implémenter');
            } else if (dossiersFProToLoad.length === 1) {
                if (dossierFormaliteSelected.id !== dossiersFProToLoad[0]) {
                    // console.log('not ready no dossier fpro');
                    return false;
                }
            }
        } else {
            // console.log('no lines');
            return false;
        }

        return true;
    }, [
        lignesStatus,
        rejetsStatus,
        factureAchatSelectedStatus,
        factureAchatSelected,
        fournisseurSelected,
        dossierSelected,
        dossierFormaliteSelected,
        lignes,
        sellsDatasource.selected,
    ]);

    const readyToDisplayDataCard = useMemo<boolean>(
        () => !!(factureAchatSelected && rejets && rejetsStatus === 'succeeded'),
        [factureAchatSelected, rejets, rejetsStatus],
    );

    const waitForValues = useMemo<boolean>(
        () =>
            !!(
                mediaObject &&
                factureAchatSelected &&
                fournisseurSelected &&
                (dossierSelected || dossierFormaliteSelected)
            ),
        [
            mediaObject,
            factureAchatSelected,
            dossierSelected,
            dossierFormaliteSelected,
            fournisseurSelected,
        ],
    );

    const { loading: globalLoading } = useLoadingStatus({ checkReady: () => waitForValues });
    const { loading: dataCardLoading } = useLoadingStatus({
        checkReady: () => readyToDisplayDataCard,
    });
    const { loading: formLoading } = useLoadingStatus({ checkReady: () => readyToDisplayForm });

    return (
        <SkeletonLoader
            isLoading={globalLoading}
            type="SplitView"
        >
            <Grid container>
                <Grid
                    item
                    lg={6}
                    sm={12}
                >
                    <Card
                        id="cardPdfReader"
                        sx={{
                            height: `calc(100vh -${footerHeight} - ${TopbarHeight}  )`,
                        }}
                    >
                        <PdfViewer
                            title={mediaObject?.originalName}
                            pdfUrl={mediaObject?.contentUrl ?? undefined}
                        />
                    </Card>
                </Grid>
                <Grid
                    item
                    lg={6}
                    sm={12}
                >
                    <Card
                        sx={{
                            gap: '15px',
                            padding: '15px',
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <SkeletonLoader
                            isLoading={dataCardLoading}
                            type="DataList"
                        >
                            <InvoiceDataCard
                                invoice={factureAchatSelected}
                                rejectCodes={rejets}
                            />
                        </SkeletonLoader>
                    </Card>
                    <Card>
                        <SkeletonLoader
                            isLoading={formLoading}
                            type="Form"
                        >
                            <FlexyTabs
                                scrollbar={false}
                                tabs={[
                                    {
                                        tabName: 'facture',
                                        tabTitle: 'Facture',
                                        tabPanel: (
                                            <UpdateSimpleInvoiceForm
                                                mediaObject={mediaObject}
                                                invoice={factureAchatSelected}
                                                lines={lignes}
                                                fetchLines={fetchLines}
                                                publisher={fournisseurSelected}
                                                folioPublication={dossierSelected}
                                                folioFormality={dossierFormaliteSelected}
                                            />
                                        ),
                                    },
                                    {
                                        tabName: 'notes',
                                        tabTitle: 'Notes',
                                        tabPanel: (
                                            <>
                                                <FlexyHeaderForm
                                                    label={
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                alignItems: 'center',
                                                                justifyContent: 'space-between',
                                                                width: '100%',
                                                            }}
                                                        >
                                                            <Typography
                                                                variant="h2"
                                                                color="secondary"
                                                            >
                                                                Note du dossier
                                                            </Typography>
                                                        </Box>
                                                    }
                                                />
                                                {dossierSelected && dossierSelected.notes ? (
                                                    <NoteObject
                                                        note={{
                                                            '@id': _.uniqueId(),
                                                            note: dossierSelected.notes,
                                                        }}
                                                        isEditable={false}
                                                    />
                                                ) : (
                                                    <EmptyBox
                                                        text="Pas de notes"
                                                        sx={{
                                                            marginTop: '10px',
                                                        }}
                                                    />
                                                )}
                                                <NotesFacture
                                                    title="Notes libres"
                                                    facture={factureAchatSelected}
                                                    idDossier={
                                                        dossierSelected?.id ||
                                                        dossierFormaliteSelected?.id ||
                                                        undefined
                                                    }
                                                />
                                            </>
                                        ),
                                    },
                                    {
                                        tabName: 'attachments',
                                        tabTitle: 'Fichiers joints',
                                        tabPanel: (
                                            <>
                                                <FlexyHeaderForm
                                                    label={
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                alignItems: 'center',
                                                                justifyContent: 'space-between',
                                                                width: '100%',
                                                            }}
                                                        >
                                                            <Typography
                                                                variant="h2"
                                                                color="secondary"
                                                            >
                                                                Fichiers joints
                                                            </Typography>
                                                        </Box>
                                                    }
                                                />
                                                <AttachmentsWidget
                                                    attachments={
                                                        factureAchatSelected?.attachments as AttachmentType[]
                                                    }
                                                />
                                            </>
                                        ),
                                    },
                                ]}
                                routerParameterName="factureTab"
                            />
                        </SkeletonLoader>
                    </Card>
                </Grid>
            </Grid>
        </SkeletonLoader>
    );
};

export default UpdateSimpleInvoiceView;
