import * as React from 'react';
import { Grid } from '@mui/material';
import {
    AdresseJsonldTiersRead,
    ContactJsonldTiersRead,
} from '@europrocurement/l2d-domain/openApi/ApiTiers';
import AddressForm from '@b2d/pages/Prescripteurs/forms/AddressForm';
import ContactForm from '@b2d/pages/Prescripteurs/forms/ContactForm';

import { ModalContext } from '@europrocurement/flexy-components';
import { usePrescriberUtils } from '@b2d/hooks/prescriberHooks';
import NoteRow from './NoteRow';
import AccordionsSideBar from './AccordionsSideBar';
import ContactAndAddressRow from './ContactAndAddressRow';
import ConfigurationRow from './ConfigurationRow';

const ActivityAndTracking: React.FunctionComponent = function () {
    const {
        prescriber,
        methods: { reloadPrescriber },
    } = usePrescriberUtils();

    const { modalActions } = React.useContext(ModalContext);
    const { contacts, adresses: addresses } = prescriber;

    const afterSubmit = () => {
        reloadPrescriber();
        modalActions.reset();
    };

    const handleEditAddress = (address: AdresseJsonldTiersRead) => {
        if (prescriber.id) {
            modalActions.call(
                <AddressForm
                    entity={address}
                    prescriber={prescriber}
                    afterSubmit={afterSubmit}
                />,
            );
        }
    };

    const handleEditContact = (contact: ContactJsonldTiersRead) => {
        if (prescriber.id) {
            modalActions.call(
                <ContactForm
                    entity={contact}
                    prescriber={prescriber}
                    afterSubmit={afterSubmit}
                />,
            );
        }
    };

    return (
        <Grid
            container
            spacing={2}
        >
            {/* Left side */}
            <Grid
                item
                xs={9}
            >
                <Grid container>
                    <ConfigurationRow />
                    <ContactAndAddressRow
                        handleEditContact={handleEditContact}
                        handleEditAddress={handleEditAddress}
                        contacts={contacts}
                        addresses={addresses}
                    />
                    <NoteRow />
                </Grid>
            </Grid>

            {/* Right side */}
            <Grid
                item
                xs={3}
            >
                <Grid
                    container
                    paddingY={2}
                    direction="column"
                    spacing={3}
                >
                    <AccordionsSideBar />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ActivityAndTracking;
