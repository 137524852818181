import React, { FC } from 'react';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { hexToRgba } from '@europrocurement/l2d-utils';
import { documentIcon, FaOptionIcon } from '@europrocurement/l2d-icons';
import { Box } from '@mui/system';
import { ReadOnlyDocumentItem } from './types';
import useOnHover from '../../Package/Components/useOnHover';

type ComponentProps = {
    item: ReadOnlyDocumentItem;
};

const DocumentItem: FC<ComponentProps> = function ({ item }) {
    const { label, subTitle, originEntityName, originName, originIcon } = item;
    const { onHoverProps, onHover } = useOnHover();

    const getBackgroundItem = () => {
        const opacityFilter = onHover ? 0.05 : 0.01;
        const hexColor = '#000000';
        return hexToRgba(hexColor, opacityFilter);
    };
    return (
        <ListItem
            style={{
                background: getBackgroundItem(),
                transition: 'background 0.3s',
                margin: '5px',
            }}
            {...onHoverProps}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    minWidth: '65%',
                    maxWidth: '65%',
                    marginRight: '5%',
                }}
            >
                <ListItemIcon>
                    <FaOptionIcon
                        {...documentIcon.props}
                        size="xl"
                    />
                </ListItemIcon>

                <ListItemText
                    primary={label}
                    secondary={subTitle}
                />
            </Box>
            <Box sx={{ display: 'flex', width: '50%', alignItems: 'center' }}>
                {originIcon && (
                    <ListItemIcon>
                        <FaOptionIcon
                            {...originIcon.props}
                            size="xl"
                            sx={{ marginRight: '10px ' }}
                        />
                    </ListItemIcon>
                )}
                <ListItemText
                    primary={originEntityName}
                    secondary={originName}
                />
            </Box>
        </ListItem>
    );
};

export default DocumentItem;
