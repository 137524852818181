/* eslint-disable @typescript-eslint/no-explicit-any */

import type { FactureAchat } from '@europrocurement/l2d-domain';
import type { FactureAchatJsonldFactureAchatWrite } from '@europrocurement/l2d-domain/openApi/ApiAchats';

/**
 * Convert API format Partial<FactureAchat> into another API format FactureAchatJsonldFactureAchatWrite.
 *
 * @TODO need in deep investigation to clarify this function utility.
 *
 * @param Partial<FactureAchat>
 * @returns FactureAchatJsonldFactureAchatWrite
 */
export default (clone: Partial<FactureAchat>) => {
    // clone.codesRejets = [];
    // eslint-disable-next-line no-param-reassign
    delete clone.codesRejets;

    let notesAtId: Array<string> = [];
    if (clone.notes) {
        notesAtId = clone.notes
            .map((note: { [x: string]: any }) => note['@id'])
            .filter((atid: undefined) => atid !== undefined) as Array<string>;
    }

    const res: FactureAchatJsonldFactureAchatWrite = {
        ...clone,
        notes: notesAtId,
        codesRejets: [],
        pdfFacture: '',
    };
    if (clone.pdfFacture && clone.pdfFacture.id) {
        res.pdfFacture = clone.pdfFacture['@id'];
    }

    // clone.lignes = clone.lignes?.map((ligne) => {
    //     ligne.details = ligne.details?.map((detail) => {
    //         return cleanJsonLd(detail);
    //     })
    //     return cleanJsonLd(ligne);
    // })
    return res;
};
