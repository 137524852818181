import React, { FunctionComponent, useCallback, useState } from 'react';

import {
    AppDispatch,
    CategorieStatsSelector,
    customizerSelector,
    factureAchatApi,
    getCategorieStats,
    getCodeRejetStats,
    getFactureAchatStats,
} from '@b2d/redux/RootStore';
import { CenterCircularProgress, DataCard } from '@europrocurement/flexy-components';
import { FaOptionIcon } from '@europrocurement/l2d-icons';
import { faFileInvoice } from '@fortawesome/pro-duotone-svg-icons';
import { faPenToSquare } from '@fortawesome/pro-solid-svg-icons';
import { Button, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { DataSource } from '@europrocurement/l2d-redux-utils';
import { CategorieStats } from '@europrocurement/l2d-domain';
import useLazyDispatch from '@b2d/hooks/useLazyDispatch';

// All

const FacturesATraiterManuellement: FunctionComponent = function () {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(false);

    const { xIdSociete } = useSelector(customizerSelector);

    const categoriesDataSource: DataSource<CategorieStats> =
        useSelector(CategorieStatsSelector).main;

    const [
        countOfUnregisteredInvoicesToTreatManually,
        setCountOfUnregisteredInvoicesToTreatManually,
    ] = useState<string>('...');

    factureAchatApi
        .getFacturesAchatsFactureAchatCollection({
            xIdSociete,
            page: 1,
            itemsPerPage: 0,
            cloture: true,
            deleted: false,
            codesRejetsCodeRejetCategorie: 'VE',
            codesRejetsEtat: 'todo',
        })
        .then((res) => {
            if (
                res.data['hydra:totalItems'] !== null ||
                res.data['hydra:totalItems'] !== undefined
            ) {
                setCountOfUnregisteredInvoicesToTreatManually(`${res.data['hydra:totalItems']}`);
            }
        });

    const lazyDispatch = useLazyDispatch({
        sliceName: categoriesDataSource.slicename,
        dataSourceName: categoriesDataSource.name,
    });

    const clearPreviousFilters = useCallback(() => {
        lazyDispatch({
            target: 'Filter',
            action: 'reset',
        })();
    }, [lazyDispatch]);

    const applyFilters = useCallback(() => {
        lazyDispatch({
            target: 'Filter',
            action: 'set',
            payload: {
                key: 'codesRejetsEtat',
                value: 'todo',
            },
        })();
        lazyDispatch({
            target: 'Filter',
            action: 'set',
            payload: {
                key: 'categories',
                value: ['VE'],
            },
        })();
    }, [lazyDispatch]);

    const requestLists = useCallback(async () => {
        setLoading(true);
        dispatch(getCategorieStats({}));
        dispatch(getCodeRejetStats({}));
        await dispatch(getFactureAchatStats({}));
    }, [dispatch]);

    return (
        <Grid
            sx={{
                flex: 1,
            }}
            item
            lg={1}
            md={1}
            xs={12}
        >
            <DataCard
                title="Factures à traiter manuellement"
                color="VE"
                iconBgColor="transparent"
                iconColor="transparent"
                icon={
                    <FaOptionIcon
                        icon={faFileInvoice}
                        option={faPenToSquare}
                        size="2x"
                        color="VE.main"
                        optionColor="VE.dark"
                        iconProps={{
                            radius: 100,
                        }}
                    />
                }
                tools={
                    <Button
                        color="VE"
                        variant="outlined"
                        disabled={loading}
                        onClick={() => {
                            clearPreviousFilters();
                            applyFilters();

                            setTimeout(() => {
                                requestLists()
                                    .then(() => {
                                        navigate('/achats/stats?tab=detail');
                                    })
                                    .finally(() => {
                                        setLoading(false);
                                    });
                            }, 250);
                        }}
                    >
                        {loading ? (
                            <CenterCircularProgress
                                color="VE"
                                sx={{
                                    scale: '0.5',
                                    margin: 0,
                                    padding: 0,
                                }}
                            />
                        ) : (
                            'Traiter'
                        )}
                    </Button>
                }
            >
                {loading ? (
                    <Typography
                        sx={{
                            fontSize: '18px',
                            textAlign: 'center',
                        }}
                    >
                        Chargement de la page...
                    </Typography>
                ) : (
                    <>
                        <Typography
                            sx={{
                                fontSize: '18px',
                                textAlign: 'center',
                            }}
                        >
                            Factures en VEAA, VEADJ, VEMP :
                        </Typography>
                        <Typography
                            color="VE.main"
                            component="h2"
                            sx={{
                                fontSize: '32px',
                                textAlign: 'center',
                            }}
                        >
                            <b>{countOfUnregisteredInvoicesToTreatManually}</b>
                        </Typography>
                    </>
                )}
            </DataCard>
        </Grid>
    );
};

export default FacturesATraiterManuellement;
