/* tslint:disable */
/* eslint-disable */
/**
 * Europrocurement Api Achats
 * API de gestion des achats
 *
 * The version of the OpenAPI document: 1.9.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ActionActionRead
 */
export interface ActionActionRead {
    /**
     * 
     * @type {string}
     * @memberof ActionActionRead
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionActionRead
     */
    'libelle'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ActionActionRead
     */
    'manuel'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ActionActionRead
     */
    'bloquant'?: boolean;
}
/**
 * 
 * @export
 * @interface ActionCodeRejetRead
 */
export interface ActionCodeRejetRead {
    /**
     * 
     * @type {string}
     * @memberof ActionCodeRejetRead
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionCodeRejetRead
     */
    'libelle'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ActionCodeRejetRead
     */
    'manuel'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ActionCodeRejetRead
     */
    'bloquant'?: boolean;
}
/**
 * 
 * @export
 * @interface ActionFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
 */
export interface ActionFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup {
    /**
     * 
     * @type {string}
     * @memberof ActionFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'libelle'?: string;
}
/**
 * 
 * @export
 * @interface ActionFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
 */
export interface ActionFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup {
    /**
     * 
     * @type {string}
     * @memberof ActionFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'libelle'?: string;
}
/**
 * 
 * @export
 * @interface ActionFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
 */
export interface ActionFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup {
    /**
     * 
     * @type {string}
     * @memberof ActionFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'libelle'?: string;
}
/**
 * 
 * @export
 * @interface ActionJsonldActionRead
 */
export interface ActionJsonldActionRead {
    /**
     * 
     * @type {string}
     * @memberof ActionJsonldActionRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionJsonldActionRead
     */
    '@type'?: string;
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof ActionJsonldActionRead
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof ActionJsonldActionRead
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionJsonldActionRead
     */
    'libelle'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ActionJsonldActionRead
     */
    'manuel'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ActionJsonldActionRead
     */
    'bloquant'?: boolean;
}
/**
 * @type ActionJsonldActionReadContext
 * @export
 */
export type ActionJsonldActionReadContext = ActionJsonldActionReadContextOneOf | string;

/**
 * 
 * @export
 * @interface ActionJsonldActionReadContextOneOf
 */
export interface ActionJsonldActionReadContextOneOf {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ActionJsonldActionReadContextOneOf
     */
    '@vocab': string;
    /**
     * 
     * @type {string}
     * @memberof ActionJsonldActionReadContextOneOf
     */
    'hydra': ActionJsonldActionReadContextOneOfHydraEnum;
}

export const ActionJsonldActionReadContextOneOfHydraEnum = {
    HttpWwwW3OrgNsHydraCore: 'http://www.w3.org/ns/hydra/core#'
} as const;

export type ActionJsonldActionReadContextOneOfHydraEnum = typeof ActionJsonldActionReadContextOneOfHydraEnum[keyof typeof ActionJsonldActionReadContextOneOfHydraEnum];

/**
 * 
 * @export
 * @interface ActionJsonldCodeRejetRead
 */
export interface ActionJsonldCodeRejetRead {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof ActionJsonldCodeRejetRead
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof ActionJsonldCodeRejetRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionJsonldCodeRejetRead
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionJsonldCodeRejetRead
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionJsonldCodeRejetRead
     */
    'libelle'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ActionJsonldCodeRejetRead
     */
    'manuel'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ActionJsonldCodeRejetRead
     */
    'bloquant'?: boolean;
}
/**
 * 
 * @export
 * @interface ActionJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
 */
export interface ActionJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof ActionJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof ActionJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'libelle'?: string;
}
/**
 * 
 * @export
 * @interface ActionJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
 */
export interface ActionJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof ActionJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof ActionJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'libelle'?: string;
}
/**
 * 
 * @export
 * @interface ActionJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
 */
export interface ActionJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof ActionJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof ActionJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'libelle'?: string;
}
/**
 * 
 * @export
 * @interface AddAttachmentFactureAchatItemRequest
 */
export interface AddAttachmentFactureAchatItemRequest {
    /**
     * 
     * @type {number}
     * @memberof AddAttachmentFactureAchatItemRequest
     */
    'attachmentId'?: number;
}
/**
 * 
 * @export
 * @interface Attachment
 */
export interface Attachment {
    /**
     * 
     * @type {number}
     * @memberof Attachment
     */
    'id'?: number;
    /**
     * 
     * @type {File}
     * @memberof Attachment
     */
    'file'?: File | null;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    'contentUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    'urlExpires'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof Attachment
     */
    'size'?: number;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    'originalName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    'mimeType'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Attachment
     */
    'deleted'?: boolean;
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof Attachment
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof Attachment
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof Attachment
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof Attachment
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface AttachmentAttachmentGroupTimestampableBlameambleGroup
 */
export interface AttachmentAttachmentGroupTimestampableBlameambleGroup {
    /**
     * 
     * @type {number}
     * @memberof AttachmentAttachmentGroupTimestampableBlameambleGroup
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AttachmentAttachmentGroupTimestampableBlameambleGroup
     */
    'contentUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AttachmentAttachmentGroupTimestampableBlameambleGroup
     */
    'urlExpires'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AttachmentAttachmentGroupTimestampableBlameambleGroup
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof AttachmentAttachmentGroupTimestampableBlameambleGroup
     */
    'size'?: number;
    /**
     * 
     * @type {string}
     * @memberof AttachmentAttachmentGroupTimestampableBlameambleGroup
     */
    'originalName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AttachmentAttachmentGroupTimestampableBlameambleGroup
     */
    'mimeType'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AttachmentAttachmentGroupTimestampableBlameambleGroup
     */
    'deleted'?: boolean;
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof AttachmentAttachmentGroupTimestampableBlameambleGroup
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof AttachmentAttachmentGroupTimestampableBlameambleGroup
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof AttachmentAttachmentGroupTimestampableBlameambleGroup
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof AttachmentAttachmentGroupTimestampableBlameambleGroup
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface AttachmentFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
 */
export interface AttachmentFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup {
    /**
     * 
     * @type {number}
     * @memberof AttachmentFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AttachmentFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'contentUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AttachmentFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'urlExpires'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AttachmentFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof AttachmentFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'size'?: number;
    /**
     * 
     * @type {string}
     * @memberof AttachmentFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'originalName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AttachmentFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'mimeType'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AttachmentFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'deleted'?: boolean;
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof AttachmentFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof AttachmentFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof AttachmentFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof AttachmentFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface AttachmentFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
 */
export interface AttachmentFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup {
    /**
     * 
     * @type {number}
     * @memberof AttachmentFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AttachmentFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'contentUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AttachmentFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'urlExpires'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AttachmentFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof AttachmentFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'size'?: number;
    /**
     * 
     * @type {string}
     * @memberof AttachmentFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'originalName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AttachmentFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'mimeType'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AttachmentFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'deleted'?: boolean;
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof AttachmentFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof AttachmentFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof AttachmentFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof AttachmentFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface AttachmentFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
 */
export interface AttachmentFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup {
    /**
     * 
     * @type {number}
     * @memberof AttachmentFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AttachmentFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'contentUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AttachmentFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'urlExpires'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AttachmentFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof AttachmentFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'size'?: number;
    /**
     * 
     * @type {string}
     * @memberof AttachmentFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'originalName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AttachmentFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'mimeType'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AttachmentFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'deleted'?: boolean;
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof AttachmentFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof AttachmentFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof AttachmentFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof AttachmentFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface AttachmentJsonld
 */
export interface AttachmentJsonld {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof AttachmentJsonld
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof AttachmentJsonld
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AttachmentJsonld
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof AttachmentJsonld
     */
    'id'?: number;
    /**
     * 
     * @type {File}
     * @memberof AttachmentJsonld
     */
    'file'?: File | null;
    /**
     * 
     * @type {string}
     * @memberof AttachmentJsonld
     */
    'contentUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AttachmentJsonld
     */
    'urlExpires'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AttachmentJsonld
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof AttachmentJsonld
     */
    'size'?: number;
    /**
     * 
     * @type {string}
     * @memberof AttachmentJsonld
     */
    'originalName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AttachmentJsonld
     */
    'mimeType'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AttachmentJsonld
     */
    'deleted'?: boolean;
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof AttachmentJsonld
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof AttachmentJsonld
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof AttachmentJsonld
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof AttachmentJsonld
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface AttachmentJsonldAttachmentGroupTimestampableBlameambleGroup
 */
export interface AttachmentJsonldAttachmentGroupTimestampableBlameambleGroup {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof AttachmentJsonldAttachmentGroupTimestampableBlameambleGroup
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof AttachmentJsonldAttachmentGroupTimestampableBlameambleGroup
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AttachmentJsonldAttachmentGroupTimestampableBlameambleGroup
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof AttachmentJsonldAttachmentGroupTimestampableBlameambleGroup
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AttachmentJsonldAttachmentGroupTimestampableBlameambleGroup
     */
    'contentUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AttachmentJsonldAttachmentGroupTimestampableBlameambleGroup
     */
    'urlExpires'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AttachmentJsonldAttachmentGroupTimestampableBlameambleGroup
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof AttachmentJsonldAttachmentGroupTimestampableBlameambleGroup
     */
    'size'?: number;
    /**
     * 
     * @type {string}
     * @memberof AttachmentJsonldAttachmentGroupTimestampableBlameambleGroup
     */
    'originalName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AttachmentJsonldAttachmentGroupTimestampableBlameambleGroup
     */
    'mimeType'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AttachmentJsonldAttachmentGroupTimestampableBlameambleGroup
     */
    'deleted'?: boolean;
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof AttachmentJsonldAttachmentGroupTimestampableBlameambleGroup
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof AttachmentJsonldAttachmentGroupTimestampableBlameambleGroup
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof AttachmentJsonldAttachmentGroupTimestampableBlameambleGroup
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof AttachmentJsonldAttachmentGroupTimestampableBlameambleGroup
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface AttachmentJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
 */
export interface AttachmentJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof AttachmentJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof AttachmentJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AttachmentJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof AttachmentJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AttachmentJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'contentUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AttachmentJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'urlExpires'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AttachmentJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof AttachmentJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'size'?: number;
    /**
     * 
     * @type {string}
     * @memberof AttachmentJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'originalName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AttachmentJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'mimeType'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AttachmentJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'deleted'?: boolean;
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof AttachmentJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof AttachmentJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof AttachmentJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof AttachmentJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface AttachmentJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
 */
export interface AttachmentJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof AttachmentJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof AttachmentJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AttachmentJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof AttachmentJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AttachmentJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'contentUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AttachmentJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'urlExpires'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AttachmentJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof AttachmentJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'size'?: number;
    /**
     * 
     * @type {string}
     * @memberof AttachmentJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'originalName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AttachmentJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'mimeType'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AttachmentJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'deleted'?: boolean;
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof AttachmentJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof AttachmentJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof AttachmentJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof AttachmentJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface AttachmentJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
 */
export interface AttachmentJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof AttachmentJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof AttachmentJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AttachmentJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof AttachmentJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AttachmentJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'contentUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AttachmentJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'urlExpires'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AttachmentJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof AttachmentJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'size'?: number;
    /**
     * 
     * @type {string}
     * @memberof AttachmentJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'originalName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AttachmentJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'mimeType'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AttachmentJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'deleted'?: boolean;
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof AttachmentJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof AttachmentJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof AttachmentJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof AttachmentJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface CategorieRejetCategorieRejetRead
 */
export interface CategorieRejetCategorieRejetRead {
    /**
     * 
     * @type {string}
     * @memberof CategorieRejetCategorieRejetRead
     */
    'categorie'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategorieRejetCategorieRejetRead
     */
    'libelle'?: string;
}
/**
 * 
 * @export
 * @interface CategorieRejetFactureAchatCodeRejetReadTimestampableBlameambleGroup
 */
export interface CategorieRejetFactureAchatCodeRejetReadTimestampableBlameambleGroup {
    /**
     * 
     * @type {string}
     * @memberof CategorieRejetFactureAchatCodeRejetReadTimestampableBlameambleGroup
     */
    'categorie'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategorieRejetFactureAchatCodeRejetReadTimestampableBlameambleGroup
     */
    'libelle'?: string;
}
/**
 * 
 * @export
 * @interface CategorieRejetJsonldCategorieRejetRead
 */
export interface CategorieRejetJsonldCategorieRejetRead {
    /**
     * 
     * @type {string}
     * @memberof CategorieRejetJsonldCategorieRejetRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategorieRejetJsonldCategorieRejetRead
     */
    '@type'?: string;
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof CategorieRejetJsonldCategorieRejetRead
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof CategorieRejetJsonldCategorieRejetRead
     */
    'categorie'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategorieRejetJsonldCategorieRejetRead
     */
    'libelle'?: string;
}
/**
 * 
 * @export
 * @interface CategorieRejetJsonldFactureAchatCodeRejetReadTimestampableBlameambleGroup
 */
export interface CategorieRejetJsonldFactureAchatCodeRejetReadTimestampableBlameambleGroup {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof CategorieRejetJsonldFactureAchatCodeRejetReadTimestampableBlameambleGroup
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof CategorieRejetJsonldFactureAchatCodeRejetReadTimestampableBlameambleGroup
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategorieRejetJsonldFactureAchatCodeRejetReadTimestampableBlameambleGroup
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategorieRejetJsonldFactureAchatCodeRejetReadTimestampableBlameambleGroup
     */
    'categorie'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategorieRejetJsonldFactureAchatCodeRejetReadTimestampableBlameambleGroup
     */
    'libelle'?: string;
}
/**
 * 
 * @export
 * @interface CodeRejetCodeRejetRead
 */
export interface CodeRejetCodeRejetRead {
    /**
     * 
     * @type {string}
     * @memberof CodeRejetCodeRejetRead
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CodeRejetCodeRejetRead
     */
    'libelle'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CodeRejetCodeRejetRead
     */
    'manuel'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CodeRejetCodeRejetRead
     */
    'bloquant'?: boolean;
    /**
     * 
     * @type {Array<ActionCodeRejetRead>}
     * @memberof CodeRejetCodeRejetRead
     */
    'actions'?: Array<ActionCodeRejetRead>;
}
/**
 * 
 * @export
 * @interface CodeRejetFactureAchatCodeRejetReadTimestampableBlameambleGroup
 */
export interface CodeRejetFactureAchatCodeRejetReadTimestampableBlameambleGroup {
    /**
     * 
     * @type {string}
     * @memberof CodeRejetFactureAchatCodeRejetReadTimestampableBlameambleGroup
     */
    'code'?: string;
    /**
     * 
     * @type {CodeRejetFactureAchatCodeRejetReadTimestampableBlameambleGroupCategorie}
     * @memberof CodeRejetFactureAchatCodeRejetReadTimestampableBlameambleGroup
     */
    'categorie'?: CodeRejetFactureAchatCodeRejetReadTimestampableBlameambleGroupCategorie | null;
    /**
     * 
     * @type {string}
     * @memberof CodeRejetFactureAchatCodeRejetReadTimestampableBlameambleGroup
     */
    'libelle'?: string;
}
/**
 * 
 * @export
 * @interface CodeRejetFactureAchatCodeRejetReadTimestampableBlameambleGroupCategorie
 */
export interface CodeRejetFactureAchatCodeRejetReadTimestampableBlameambleGroupCategorie {
    /**
     * 
     * @type {string}
     * @memberof CodeRejetFactureAchatCodeRejetReadTimestampableBlameambleGroupCategorie
     */
    'categorie'?: string;
    /**
     * 
     * @type {string}
     * @memberof CodeRejetFactureAchatCodeRejetReadTimestampableBlameambleGroupCategorie
     */
    'libelle'?: string;
}
/**
 * 
 * @export
 * @interface CodeRejetFactureAchatCreateFactureAchatWrite
 */
export interface CodeRejetFactureAchatCreateFactureAchatWrite {
    /**
     * 
     * @type {string}
     * @memberof CodeRejetFactureAchatCreateFactureAchatWrite
     */
    'categorie'?: string | null;
}
/**
 * 
 * @export
 * @interface CodeRejetFactureAchatWrite
 */
export interface CodeRejetFactureAchatWrite {
    /**
     * 
     * @type {string}
     * @memberof CodeRejetFactureAchatWrite
     */
    'categorie'?: string | null;
}
/**
 * 
 * @export
 * @interface CodeRejetJsonldCodeRejetRead
 */
export interface CodeRejetJsonldCodeRejetRead {
    /**
     * 
     * @type {string}
     * @memberof CodeRejetJsonldCodeRejetRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CodeRejetJsonldCodeRejetRead
     */
    '@type'?: string;
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof CodeRejetJsonldCodeRejetRead
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof CodeRejetJsonldCodeRejetRead
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CodeRejetJsonldCodeRejetRead
     */
    'libelle'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CodeRejetJsonldCodeRejetRead
     */
    'manuel'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CodeRejetJsonldCodeRejetRead
     */
    'bloquant'?: boolean;
    /**
     * 
     * @type {Array<ActionJsonldCodeRejetRead>}
     * @memberof CodeRejetJsonldCodeRejetRead
     */
    'actions'?: Array<ActionJsonldCodeRejetRead>;
}
/**
 * 
 * @export
 * @interface CodeRejetJsonldFactureAchatCodeRejetReadTimestampableBlameambleGroup
 */
export interface CodeRejetJsonldFactureAchatCodeRejetReadTimestampableBlameambleGroup {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof CodeRejetJsonldFactureAchatCodeRejetReadTimestampableBlameambleGroup
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof CodeRejetJsonldFactureAchatCodeRejetReadTimestampableBlameambleGroup
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CodeRejetJsonldFactureAchatCodeRejetReadTimestampableBlameambleGroup
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof CodeRejetJsonldFactureAchatCodeRejetReadTimestampableBlameambleGroup
     */
    'code'?: string;
    /**
     * 
     * @type {CodeRejetJsonldFactureAchatCodeRejetReadTimestampableBlameambleGroupCategorie}
     * @memberof CodeRejetJsonldFactureAchatCodeRejetReadTimestampableBlameambleGroup
     */
    'categorie'?: CodeRejetJsonldFactureAchatCodeRejetReadTimestampableBlameambleGroupCategorie | null;
    /**
     * 
     * @type {string}
     * @memberof CodeRejetJsonldFactureAchatCodeRejetReadTimestampableBlameambleGroup
     */
    'libelle'?: string;
}
/**
 * 
 * @export
 * @interface CodeRejetJsonldFactureAchatCodeRejetReadTimestampableBlameambleGroupCategorie
 */
export interface CodeRejetJsonldFactureAchatCodeRejetReadTimestampableBlameambleGroupCategorie {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof CodeRejetJsonldFactureAchatCodeRejetReadTimestampableBlameambleGroupCategorie
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof CodeRejetJsonldFactureAchatCodeRejetReadTimestampableBlameambleGroupCategorie
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CodeRejetJsonldFactureAchatCodeRejetReadTimestampableBlameambleGroupCategorie
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof CodeRejetJsonldFactureAchatCodeRejetReadTimestampableBlameambleGroupCategorie
     */
    'categorie'?: string;
    /**
     * 
     * @type {string}
     * @memberof CodeRejetJsonldFactureAchatCodeRejetReadTimestampableBlameambleGroupCategorie
     */
    'libelle'?: string;
}
/**
 * 
 * @export
 * @interface CodeRejetJsonldFactureAchatCreateFactureAchatWrite
 */
export interface CodeRejetJsonldFactureAchatCreateFactureAchatWrite {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof CodeRejetJsonldFactureAchatCreateFactureAchatWrite
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof CodeRejetJsonldFactureAchatCreateFactureAchatWrite
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CodeRejetJsonldFactureAchatCreateFactureAchatWrite
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof CodeRejetJsonldFactureAchatCreateFactureAchatWrite
     */
    'categorie'?: string | null;
}
/**
 * 
 * @export
 * @interface CodeRejetJsonldFactureAchatWrite
 */
export interface CodeRejetJsonldFactureAchatWrite {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof CodeRejetJsonldFactureAchatWrite
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof CodeRejetJsonldFactureAchatWrite
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CodeRejetJsonldFactureAchatWrite
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof CodeRejetJsonldFactureAchatWrite
     */
    'categorie'?: string | null;
}
/**
 * 
 * @export
 * @interface ExceptionRejetExceptionRejetRead
 */
export interface ExceptionRejetExceptionRejetRead {
    /**
     * 
     * @type {number}
     * @memberof ExceptionRejetExceptionRejetRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ExceptionRejetExceptionRejetRead
     */
    'categorie'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExceptionRejetExceptionRejetRead
     */
    'codeRejet'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ExceptionRejetExceptionRejetRead
     */
    'idJal'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ExceptionRejetExceptionRejetRead
     */
    'idFacturante'?: number | null;
}
/**
 * 
 * @export
 * @interface ExceptionRejetJsonldExceptionRejetRead
 */
export interface ExceptionRejetJsonldExceptionRejetRead {
    /**
     * 
     * @type {string}
     * @memberof ExceptionRejetJsonldExceptionRejetRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExceptionRejetJsonldExceptionRejetRead
     */
    '@type'?: string;
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof ExceptionRejetJsonldExceptionRejetRead
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {number}
     * @memberof ExceptionRejetJsonldExceptionRejetRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ExceptionRejetJsonldExceptionRejetRead
     */
    'categorie'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExceptionRejetJsonldExceptionRejetRead
     */
    'codeRejet'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ExceptionRejetJsonldExceptionRejetRead
     */
    'idJal'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ExceptionRejetJsonldExceptionRejetRead
     */
    'idFacturante'?: number | null;
}
/**
 * 
 * @export
 * @interface ExportCodesRejetsDashboardFactureAchatCollection200Response
 */
export interface ExportCodesRejetsDashboardFactureAchatCollection200Response {
    /**
     * 
     * @type {string}
     * @memberof ExportCodesRejetsDashboardFactureAchatCollection200Response
     */
    'numeroFacture'?: string;
    /**
     * 
     * @type {ExportCodesRejetsDashboardFactureAchatCollection200ResponseDateFacture}
     * @memberof ExportCodesRejetsDashboardFactureAchatCollection200Response
     */
    'dateFacture'?: ExportCodesRejetsDashboardFactureAchatCollection200ResponseDateFacture;
    /**
     * 
     * @type {ExportCodesRejetsDashboardFactureAchatCollection200ResponseDateFacture}
     * @memberof ExportCodesRejetsDashboardFactureAchatCollection200Response
     */
    'dateSaisie'?: ExportCodesRejetsDashboardFactureAchatCollection200ResponseDateFacture;
    /**
     * 
     * @type {number}
     * @memberof ExportCodesRejetsDashboardFactureAchatCollection200Response
     */
    'idFacturePortailAchat'?: number;
    /**
     * 
     * @type {number}
     * @memberof ExportCodesRejetsDashboardFactureAchatCollection200Response
     */
    'idFactureG3'?: number;
    /**
     * 
     * @type {string}
     * @memberof ExportCodesRejetsDashboardFactureAchatCollection200Response
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExportCodesRejetsDashboardFactureAchatCollection200Response
     */
    'libelle'?: string;
    /**
     * 
     * @type {number}
     * @memberof ExportCodesRejetsDashboardFactureAchatCollection200Response
     */
    'idEntiteFacturante'?: number;
    /**
     * 
     * @type {number}
     * @memberof ExportCodesRejetsDashboardFactureAchatCollection200Response
     */
    'idJal'?: number;
    /**
     * 
     * @type {string}
     * @memberof ExportCodesRejetsDashboardFactureAchatCollection200Response
     */
    'typeRejet'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExportCodesRejetsDashboardFactureAchatCollection200Response
     */
    'multiplicite'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExportCodesRejetsDashboardFactureAchatCollection200Response
     */
    'lien'?: string;
}
/**
 * 
 * @export
 * @interface ExportCodesRejetsDashboardFactureAchatCollection200ResponseDateFacture
 */
export interface ExportCodesRejetsDashboardFactureAchatCollection200ResponseDateFacture {
    /**
     * 
     * @type {Datetime}
     * @memberof ExportCodesRejetsDashboardFactureAchatCollection200ResponseDateFacture
     */
    'date'?: Datetime;
    /**
     * 
     * @type {number}
     * @memberof ExportCodesRejetsDashboardFactureAchatCollection200ResponseDateFacture
     */
    'timezone_type'?: number;
    /**
     * 
     * @type {string}
     * @memberof ExportCodesRejetsDashboardFactureAchatCollection200ResponseDateFacture
     */
    'timezone'?: string;
}
/**
 * 
 * @export
 * @interface ExportCodesRejetsRecapFactureAchatCollection200Response
 */
export interface ExportCodesRejetsRecapFactureAchatCollection200Response {
    /**
     * 
     * @type {string}
     * @memberof ExportCodesRejetsRecapFactureAchatCollection200Response
     */
    'numeroFacture'?: string;
    /**
     * 
     * @type {number}
     * @memberof ExportCodesRejetsRecapFactureAchatCollection200Response
     */
    'idDossier'?: number;
    /**
     * 
     * @type {string}
     * @memberof ExportCodesRejetsRecapFactureAchatCollection200Response
     */
    'numeroDossier'?: string;
    /**
     * 
     * @type {ExportCodesRejetsDashboardFactureAchatCollection200ResponseDateFacture}
     * @memberof ExportCodesRejetsRecapFactureAchatCollection200Response
     */
    'dateFacture'?: ExportCodesRejetsDashboardFactureAchatCollection200ResponseDateFacture;
    /**
     * 
     * @type {ExportCodesRejetsDashboardFactureAchatCollection200ResponseDateFacture}
     * @memberof ExportCodesRejetsRecapFactureAchatCollection200Response
     */
    'dateSaisie'?: ExportCodesRejetsDashboardFactureAchatCollection200ResponseDateFacture;
    /**
     * 
     * @type {number}
     * @memberof ExportCodesRejetsRecapFactureAchatCollection200Response
     */
    'idFacturePortailAchat'?: number;
    /**
     * 
     * @type {number}
     * @memberof ExportCodesRejetsRecapFactureAchatCollection200Response
     */
    'idFactureG3'?: number;
    /**
     * 
     * @type {string}
     * @memberof ExportCodesRejetsRecapFactureAchatCollection200Response
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExportCodesRejetsRecapFactureAchatCollection200Response
     */
    'libelle'?: string;
    /**
     * 
     * @type {number}
     * @memberof ExportCodesRejetsRecapFactureAchatCollection200Response
     */
    'idEntiteFacturante'?: number;
    /**
     * 
     * @type {string}
     * @memberof ExportCodesRejetsRecapFactureAchatCollection200Response
     */
    'typeRejet'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExportCodesRejetsRecapFactureAchatCollection200Response
     */
    'multiplicite'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExportCodesRejetsRecapFactureAchatCollection200Response
     */
    'lien'?: string;
}
/**
 * 
 * @export
 * @interface FactureAchatActionFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
 */
export interface FactureAchatActionFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup {
    /**
     * 
     * @type {number}
     * @memberof FactureAchatActionFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'id'?: number;
    /**
     * 
     * @type {ActionFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup}
     * @memberof FactureAchatActionFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'action'?: ActionFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatActionFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatActionFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'dateMaj'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatActionFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'userCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatActionFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'userMaj'?: string | null;
}
/**
 * 
 * @export
 * @interface FactureAchatActionFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
 */
export interface FactureAchatActionFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup {
    /**
     * 
     * @type {number}
     * @memberof FactureAchatActionFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'id'?: number;
    /**
     * 
     * @type {ActionFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup}
     * @memberof FactureAchatActionFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'action'?: ActionFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatActionFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatActionFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'dateMaj'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatActionFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'userCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatActionFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'userMaj'?: string | null;
}
/**
 * 
 * @export
 * @interface FactureAchatActionFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
 */
export interface FactureAchatActionFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup {
    /**
     * 
     * @type {number}
     * @memberof FactureAchatActionFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'id'?: number;
    /**
     * 
     * @type {ActionFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup}
     * @memberof FactureAchatActionFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'action'?: ActionFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatActionFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatActionFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'dateMaj'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatActionFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'userCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatActionFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'userMaj'?: string | null;
}
/**
 * 
 * @export
 * @interface FactureAchatActionJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
 */
export interface FactureAchatActionJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof FactureAchatActionJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatActionJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatActionJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatActionJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'id'?: number;
    /**
     * 
     * @type {ActionJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup}
     * @memberof FactureAchatActionJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'action'?: ActionJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatActionJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatActionJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'dateMaj'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatActionJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'userCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatActionJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'userMaj'?: string | null;
}
/**
 * 
 * @export
 * @interface FactureAchatActionJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
 */
export interface FactureAchatActionJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof FactureAchatActionJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatActionJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatActionJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatActionJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'id'?: number;
    /**
     * 
     * @type {ActionJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup}
     * @memberof FactureAchatActionJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'action'?: ActionJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatActionJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatActionJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'dateMaj'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatActionJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'userCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatActionJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'userMaj'?: string | null;
}
/**
 * 
 * @export
 * @interface FactureAchatActionJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
 */
export interface FactureAchatActionJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof FactureAchatActionJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatActionJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatActionJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatActionJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'id'?: number;
    /**
     * 
     * @type {ActionJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup}
     * @memberof FactureAchatActionJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'action'?: ActionJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatActionJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatActionJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'dateMaj'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatActionJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'userCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatActionJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'userMaj'?: string | null;
}
/**
 * 
 * @export
 * @interface FactureAchatCodeRejetFactureAchatCodeRejetReadTimestampableBlameambleGroup
 */
export interface FactureAchatCodeRejetFactureAchatCodeRejetReadTimestampableBlameambleGroup {
    /**
     * 
     * @type {number}
     * @memberof FactureAchatCodeRejetFactureAchatCodeRejetReadTimestampableBlameambleGroup
     */
    'id'?: number;
    /**
     * 
     * @type {CodeRejetFactureAchatCodeRejetReadTimestampableBlameambleGroup}
     * @memberof FactureAchatCodeRejetFactureAchatCodeRejetReadTimestampableBlameambleGroup
     */
    'codeRejet'?: CodeRejetFactureAchatCodeRejetReadTimestampableBlameambleGroup;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatCodeRejetFactureAchatCodeRejetReadTimestampableBlameambleGroup
     */
    'idDossier'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatCodeRejetFactureAchatCodeRejetReadTimestampableBlameambleGroup
     */
    'etat'?: string;
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof FactureAchatCodeRejetFactureAchatCodeRejetReadTimestampableBlameambleGroup
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof FactureAchatCodeRejetFactureAchatCodeRejetReadTimestampableBlameambleGroup
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof FactureAchatCodeRejetFactureAchatCodeRejetReadTimestampableBlameambleGroup
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof FactureAchatCodeRejetFactureAchatCodeRejetReadTimestampableBlameambleGroup
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface FactureAchatCodeRejetFactureAchatCodeRejetUpdateEtat
 */
export interface FactureAchatCodeRejetFactureAchatCodeRejetUpdateEtat {
    /**
     * 
     * @type {string}
     * @memberof FactureAchatCodeRejetFactureAchatCodeRejetUpdateEtat
     */
    'etat'?: string;
}
/**
 * 
 * @export
 * @interface FactureAchatCodeRejetFactureAchatCreateFactureAchatWrite
 */
export interface FactureAchatCodeRejetFactureAchatCreateFactureAchatWrite {
    /**
     * 
     * @type {CodeRejetFactureAchatCreateFactureAchatWrite}
     * @memberof FactureAchatCodeRejetFactureAchatCreateFactureAchatWrite
     */
    'codeRejet'?: CodeRejetFactureAchatCreateFactureAchatWrite;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatCodeRejetFactureAchatCreateFactureAchatWrite
     */
    'idDossier'?: number;
}
/**
 * 
 * @export
 * @interface FactureAchatCodeRejetFactureAchatWrite
 */
export interface FactureAchatCodeRejetFactureAchatWrite {
    /**
     * 
     * @type {CodeRejetFactureAchatWrite}
     * @memberof FactureAchatCodeRejetFactureAchatWrite
     */
    'codeRejet'?: CodeRejetFactureAchatWrite;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatCodeRejetFactureAchatWrite
     */
    'idDossier'?: number;
}
/**
 * 
 * @export
 * @interface FactureAchatCodeRejetJsonldFactureAchatCodeRejetReadTimestampableBlameambleGroup
 */
export interface FactureAchatCodeRejetJsonldFactureAchatCodeRejetReadTimestampableBlameambleGroup {
    /**
     * 
     * @type {string}
     * @memberof FactureAchatCodeRejetJsonldFactureAchatCodeRejetReadTimestampableBlameambleGroup
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatCodeRejetJsonldFactureAchatCodeRejetReadTimestampableBlameambleGroup
     */
    '@type'?: string;
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof FactureAchatCodeRejetJsonldFactureAchatCodeRejetReadTimestampableBlameambleGroup
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatCodeRejetJsonldFactureAchatCodeRejetReadTimestampableBlameambleGroup
     */
    'id'?: number;
    /**
     * 
     * @type {CodeRejetJsonldFactureAchatCodeRejetReadTimestampableBlameambleGroup}
     * @memberof FactureAchatCodeRejetJsonldFactureAchatCodeRejetReadTimestampableBlameambleGroup
     */
    'codeRejet'?: CodeRejetJsonldFactureAchatCodeRejetReadTimestampableBlameambleGroup;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatCodeRejetJsonldFactureAchatCodeRejetReadTimestampableBlameambleGroup
     */
    'idDossier'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatCodeRejetJsonldFactureAchatCodeRejetReadTimestampableBlameambleGroup
     */
    'etat'?: string;
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof FactureAchatCodeRejetJsonldFactureAchatCodeRejetReadTimestampableBlameambleGroup
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof FactureAchatCodeRejetJsonldFactureAchatCodeRejetReadTimestampableBlameambleGroup
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof FactureAchatCodeRejetJsonldFactureAchatCodeRejetReadTimestampableBlameambleGroup
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof FactureAchatCodeRejetJsonldFactureAchatCodeRejetReadTimestampableBlameambleGroup
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface FactureAchatCodeRejetJsonldFactureAchatCodeRejetUpdateEtat
 */
export interface FactureAchatCodeRejetJsonldFactureAchatCodeRejetUpdateEtat {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof FactureAchatCodeRejetJsonldFactureAchatCodeRejetUpdateEtat
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatCodeRejetJsonldFactureAchatCodeRejetUpdateEtat
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatCodeRejetJsonldFactureAchatCodeRejetUpdateEtat
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatCodeRejetJsonldFactureAchatCodeRejetUpdateEtat
     */
    'etat'?: string;
}
/**
 * 
 * @export
 * @interface FactureAchatCodeRejetJsonldFactureAchatCreateFactureAchatWrite
 */
export interface FactureAchatCodeRejetJsonldFactureAchatCreateFactureAchatWrite {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof FactureAchatCodeRejetJsonldFactureAchatCreateFactureAchatWrite
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatCodeRejetJsonldFactureAchatCreateFactureAchatWrite
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatCodeRejetJsonldFactureAchatCreateFactureAchatWrite
     */
    '@type'?: string;
    /**
     * 
     * @type {CodeRejetJsonldFactureAchatCreateFactureAchatWrite}
     * @memberof FactureAchatCodeRejetJsonldFactureAchatCreateFactureAchatWrite
     */
    'codeRejet'?: CodeRejetJsonldFactureAchatCreateFactureAchatWrite;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatCodeRejetJsonldFactureAchatCreateFactureAchatWrite
     */
    'idDossier'?: number;
}
/**
 * 
 * @export
 * @interface FactureAchatCodeRejetJsonldFactureAchatWrite
 */
export interface FactureAchatCodeRejetJsonldFactureAchatWrite {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof FactureAchatCodeRejetJsonldFactureAchatWrite
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatCodeRejetJsonldFactureAchatWrite
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatCodeRejetJsonldFactureAchatWrite
     */
    '@type'?: string;
    /**
     * 
     * @type {CodeRejetJsonldFactureAchatWrite}
     * @memberof FactureAchatCodeRejetJsonldFactureAchatWrite
     */
    'codeRejet'?: CodeRejetJsonldFactureAchatWrite;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatCodeRejetJsonldFactureAchatWrite
     */
    'idDossier'?: number;
}
/**
 * 
 * @export
 * @interface FactureAchatDossierJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
 */
export interface FactureAchatDossierJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof FactureAchatDossierJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatDossierJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatDossierJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    '@type'?: string;
}
/**
 * 
 * @export
 * @interface FactureAchatDossierJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
 */
export interface FactureAchatDossierJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof FactureAchatDossierJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatDossierJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatDossierJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    '@type'?: string;
}
/**
 * 
 * @export
 * @interface FactureAchatFactureAchatCreateFactureAchatWrite
 */
export interface FactureAchatFactureAchatCreateFactureAchatWrite {
    /**
     * 
     * @type {number}
     * @memberof FactureAchatFactureAchatCreateFactureAchatWrite
     */
    'idJal'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatFactureAchatCreateFactureAchatWrite
     */
    'idReleveAchat'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatCreateFactureAchatWrite
     */
    'dateFacture'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatCreateFactureAchatWrite
     */
    'numeroFacture'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatFactureAchatCreateFactureAchatWrite
     */
    'idEntiteFacturante'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatCreateFactureAchatWrite
     */
    'libelleFacturante'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatCreateFactureAchatWrite
     */
    'libelleAnnonceur'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatCreateFactureAchatWrite
     */
    'rcsEntiteFacturante'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatFactureAchatCreateFactureAchatWrite
     */
    'idTypeTarif'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatFactureAchatCreateFactureAchatWrite
     */
    'idSociete'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatFactureAchatCreateFactureAchatWrite
     */
    'forced'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatFactureAchatCreateFactureAchatWrite
     */
    'cloture'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatFactureAchatCreateFactureAchatWrite
     */
    'avoir'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatFactureAchatCreateFactureAchatWrite
     */
    'hasSetRsf'?: boolean;
    /**
     * 
     * @type {Array<FactureAchatCodeRejetFactureAchatCreateFactureAchatWrite>}
     * @memberof FactureAchatFactureAchatCreateFactureAchatWrite
     */
    'codesRejets'?: Array<FactureAchatCodeRejetFactureAchatCreateFactureAchatWrite>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FactureAchatFactureAchatCreateFactureAchatWrite
     */
    'notes'?: Array<string>;
    /**
     * 
     * @type {Array<FactureAchatLigneFactureAchatCreateFactureAchatWrite>}
     * @memberof FactureAchatFactureAchatCreateFactureAchatWrite
     */
    'lignes'?: Array<FactureAchatLigneFactureAchatCreateFactureAchatWrite>;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatCreateFactureAchatWrite
     */
    'pdfFacture'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatCreateFactureAchatWrite
     */
    'ht'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatCreateFactureAchatWrite
     */
    'tva'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatCreateFactureAchatWrite
     */
    'ttc'?: string | null;
}
/**
 * 
 * @export
 * @interface FactureAchatFactureAchatReadDossier
 */
export interface FactureAchatFactureAchatReadDossier {
    /**
     * 
     * @type {number}
     * @memberof FactureAchatFactureAchatReadDossier
     */
    'id'?: number;
    /**
     * dossiers de la facture
     * @type {Array<FactureAchatFactureAchatReadDossierDossiersInner>}
     * @memberof FactureAchatFactureAchatReadDossier
     */
    'dossiers'?: Array<FactureAchatFactureAchatReadDossierDossiersInner>;
}
/**
 * 
 * @export
 * @interface FactureAchatFactureAchatReadDossierDossiersInner
 */
export interface FactureAchatFactureAchatReadDossierDossiersInner {
    /**
     * 
     * @type {number}
     * @memberof FactureAchatFactureAchatReadDossierDossiersInner
     */
    'idDossier'?: number;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatFactureAchatReadDossierDossiersInner
     */
    'idDossierFpro'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatReadDossierDossiersInner
     */
    'numeroDossier'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatFactureAchatReadDossierDossiersInner
     */
    'idPrestation'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatReadDossierDossiersInner
     */
    'libelleAnnonceur'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatReadDossierDossiersInner
     */
    'numeroPrestation'?: string;
}
/**
 * 
 * @export
 * @interface FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
 */
export interface FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup {
    /**
     * 
     * @type {number}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'idJal'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'idReleveAchat'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'idFactureAchatJal'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'dateFacture'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'numeroFacture'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'idEntiteFacturante'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'libelleFacturante'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'libelleAnnonceur'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'rcsEntiteFacturante'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'idTypeTarif'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'idSociete'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'forced'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'syncG3'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'cloture'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'avoir'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'hasSetRsf'?: boolean;
    /**
     * 
     * @type {Array<FactureAchatActionFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup>}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'actions'?: Array<FactureAchatActionFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup>;
    /**
     * 
     * @type {Array<FactureAchatNoteFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup>}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'notes'?: Array<FactureAchatNoteFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup>;
    /**
     * 
     * @type {FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroupPdfFacture}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'pdfFacture'?: FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroupPdfFacture | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'ht'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'tva'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'ttc'?: string | null;
    /**
     * Get the value of forcedttc
     * @type {boolean}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'forcedttc'?: boolean | null;
    /**
     * Get the value of forcedht
     * @type {boolean}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'forcedht'?: boolean | null;
    /**
     * Get the value of forcedtva
     * @type {boolean}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'forcedtva'?: boolean | null;
    /**
     * 
     * @type {FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroupFirstDossier}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'firstDossier'?: FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroupFirstDossier | null;
    /**
     * nombre de dossiers
     * @type {number}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'countDossier'?: number | null;
    /**
     * facture multi-annonces
     * @type {boolean}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'multiAnnonces'?: boolean | null;
    /**
     * Deux premiers codes rejets de la facture
     * @type {Array<FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroupFirstCodesRejetsInner>}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'firstCodesRejets'?: Array<FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroupFirstCodesRejetsInner>;
    /**
     * nombre de codes rejets
     * @type {number}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'countCodesRejets'?: number | null;
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {Array<AttachmentFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup>}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'attachments'?: Array<AttachmentFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup>;
    /**
     * Tags de la facture d\'achat
     * @type {Array<FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroupTagsInner>}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'tags'?: Array<FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroupTagsInner>;
}
/**
 * 
 * @export
 * @interface FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroupPdfFacture
 */
export interface FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroupPdfFacture {
    /**
     * 
     * @type {number}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroupPdfFacture
     */
    'id'?: number;
    /**
     * 
     * @type {File}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroupPdfFacture
     */
    'file'?: File | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroupPdfFacture
     */
    'contentUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroupPdfFacture
     */
    'urlExpires'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroupPdfFacture
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroupPdfFacture
     */
    'size'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroupPdfFacture
     */
    'originalName'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroupPdfFacture
     */
    'mimeType'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroupPdfFacture
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {Array<object>}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroupPdfFacture
     */
    'mediaObjectTags'?: Array<object>;
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroupPdfFacture
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroupPdfFacture
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroupPdfFacture
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroupPdfFacture
     */
    'updatedBy'?: string | null;
    /**
     * Tags du pdf
     * @type {Array<FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroupTagsInner>}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroupPdfFacture
     */
    'tags'?: Array<FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroupTagsInner> | null;
}
/**
 * 
 * @export
 * @interface FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
 */
export interface FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup {
    /**
     * 
     * @type {number}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'idJal'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'idReleveAchat'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'idFactureAchatJal'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'dateFacture'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'numeroFacture'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'idEntiteFacturante'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'libelleFacturante'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'libelleAnnonceur'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'rcsEntiteFacturante'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'idTypeTarif'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'idSociete'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'forced'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'syncG3'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'cloture'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'avoir'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'hasSetRsf'?: boolean;
    /**
     * 
     * @type {Array<FactureAchatActionFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup>}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'actions'?: Array<FactureAchatActionFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup>;
    /**
     * 
     * @type {Array<FactureAchatNoteFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup>}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'notes'?: Array<FactureAchatNoteFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup>;
    /**
     * 
     * @type {FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroupPdfFacture}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'pdfFacture'?: FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroupPdfFacture | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'ht'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'tva'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'ttc'?: string | null;
    /**
     * Get the value of forcedttc
     * @type {boolean}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'forcedttc'?: boolean | null;
    /**
     * Get the value of forcedht
     * @type {boolean}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'forcedht'?: boolean | null;
    /**
     * Get the value of forcedtva
     * @type {boolean}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'forcedtva'?: boolean | null;
    /**
     * Syncro cegid de la facture
     * @type {boolean}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'syncCegid'?: boolean;
    /**
     * 
     * @type {FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroupFirstDossier}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'firstDossier'?: FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroupFirstDossier | null;
    /**
     * nombre de dossiers
     * @type {number}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'countDossier'?: number | null;
    /**
     * facture multi-annonces
     * @type {boolean}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'multiAnnonces'?: boolean | null;
    /**
     * Deux premiers codes rejets de la facture
     * @type {Array<FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroupFirstCodesRejetsInner>}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'firstCodesRejets'?: Array<FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroupFirstCodesRejetsInner>;
    /**
     * nombre de codes rejets
     * @type {number}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'countCodesRejets'?: number | null;
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {Array<AttachmentFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup>}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'attachments'?: Array<AttachmentFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup>;
}
/**
 * 
 * @export
 * @interface FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroupFirstCodesRejetsInner
 */
export interface FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroupFirstCodesRejetsInner {
    /**
     * 
     * @type {number}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroupFirstCodesRejetsInner
     */
    'id'?: number;
    /**
     * 
     * @type {FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroupFirstCodesRejetsInnerCodeRejet}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroupFirstCodesRejetsInner
     */
    'codeRejet'?: FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroupFirstCodesRejetsInnerCodeRejet;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroupFirstCodesRejetsInner
     */
    'idDossier'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroupFirstCodesRejetsInner
     */
    'etat'?: string;
}
/**
 * 
 * @export
 * @interface FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroupFirstCodesRejetsInnerCodeRejet
 */
export interface FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroupFirstCodesRejetsInnerCodeRejet {
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroupFirstCodesRejetsInnerCodeRejet
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroupFirstCodesRejetsInnerCodeRejet
     */
    'categorie'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroupFirstCodesRejetsInnerCodeRejet
     */
    'libelle'?: string;
}
/**
 * Premier dossier de la facture
 * @export
 * @interface FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroupFirstDossier
 */
export interface FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroupFirstDossier {
    /**
     * 
     * @type {number}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroupFirstDossier
     */
    'idDossier'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroupFirstDossier
     */
    'numeroDossier'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroupFirstDossier
     */
    'idPrestation'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroupFirstDossier
     */
    'libelleAnnonceur'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroupFirstDossier
     */
    'numeroPrestation'?: string;
}
/**
 * 
 * @export
 * @interface FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroupPdfFacture
 */
export interface FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroupPdfFacture {
    /**
     * 
     * @type {number}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroupPdfFacture
     */
    'id'?: number;
    /**
     * 
     * @type {File}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroupPdfFacture
     */
    'file'?: File | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroupPdfFacture
     */
    'contentUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroupPdfFacture
     */
    'urlExpires'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroupPdfFacture
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroupPdfFacture
     */
    'size'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroupPdfFacture
     */
    'originalName'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroupPdfFacture
     */
    'mimeType'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroupPdfFacture
     */
    'deleted'?: boolean;
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroupPdfFacture
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroupPdfFacture
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroupPdfFacture
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroupPdfFacture
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
 */
export interface FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup {
    /**
     * 
     * @type {number}
     * @memberof FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'idJal'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'idReleveAchat'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'idFactureAchatJal'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'dateFacture'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'numeroFacture'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'idEntiteFacturante'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'libelleFacturante'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'libelleAnnonceur'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'rcsEntiteFacturante'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'idTypeTarif'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'idSociete'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'forced'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'syncG3'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'cloture'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'avoir'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'hasSetRsf'?: boolean;
    /**
     * 
     * @type {Array<FactureAchatActionFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup>}
     * @memberof FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'actions'?: Array<FactureAchatActionFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup>;
    /**
     * 
     * @type {Array<FactureAchatNoteFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup>}
     * @memberof FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'notes'?: Array<FactureAchatNoteFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup>;
    /**
     * 
     * @type {FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroupPdfFacture}
     * @memberof FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'pdfFacture'?: FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroupPdfFacture | null;
    /**
     * facture multi-annonces
     * @type {boolean}
     * @memberof FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'multiAnnonces'?: boolean | null;
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {Array<AttachmentFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup>}
     * @memberof FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'attachments'?: Array<AttachmentFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup>;
    /**
     * Tags de la facture d\'achat
     * @type {Array<FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroupTagsInner>}
     * @memberof FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'tags'?: Array<FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroupTagsInner>;
}
/**
 * 
 * @export
 * @interface FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroupPdfFacture
 */
export interface FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroupPdfFacture {
    /**
     * 
     * @type {number}
     * @memberof FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroupPdfFacture
     */
    'id'?: number;
    /**
     * 
     * @type {File}
     * @memberof FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroupPdfFacture
     */
    'file'?: File | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroupPdfFacture
     */
    'contentUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroupPdfFacture
     */
    'urlExpires'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroupPdfFacture
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroupPdfFacture
     */
    'size'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroupPdfFacture
     */
    'originalName'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroupPdfFacture
     */
    'mimeType'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroupPdfFacture
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {Array<object>}
     * @memberof FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroupPdfFacture
     */
    'mediaObjectTags'?: Array<object>;
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroupPdfFacture
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroupPdfFacture
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroupPdfFacture
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroupPdfFacture
     */
    'updatedBy'?: string | null;
    /**
     * Tags du pdf
     * @type {Array<FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroupTagsInner>}
     * @memberof FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroupPdfFacture
     */
    'tags'?: Array<FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroupTagsInner> | null;
}
/**
 * 
 * @export
 * @interface FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroupTagsInner
 */
export interface FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroupTagsInner {
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroupTagsInner
     */
    'label'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroupTagsInner
     */
    'value'?: string;
}
/**
 * 
 * @export
 * @interface FactureAchatFactureAchatSyncG3
 */
export interface FactureAchatFactureAchatSyncG3 {
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatFactureAchatSyncG3
     */
    'forced'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatFactureAchatSyncG3
     */
    'syncG3'?: boolean;
}
/**
 * 
 * @export
 * @interface FactureAchatFactureAchatWrite
 */
export interface FactureAchatFactureAchatWrite {
    /**
     * 
     * @type {number}
     * @memberof FactureAchatFactureAchatWrite
     */
    'idJal'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatFactureAchatWrite
     */
    'idReleveAchat'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatWrite
     */
    'dateFacture'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatWrite
     */
    'numeroFacture'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatFactureAchatWrite
     */
    'idEntiteFacturante'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatWrite
     */
    'libelleFacturante'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatWrite
     */
    'libelleAnnonceur'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatWrite
     */
    'rcsEntiteFacturante'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatFactureAchatWrite
     */
    'idTypeTarif'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatFactureAchatWrite
     */
    'idSociete'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatFactureAchatWrite
     */
    'forced'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatFactureAchatWrite
     */
    'cloture'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatFactureAchatWrite
     */
    'avoir'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatFactureAchatWrite
     */
    'hasSetRsf'?: boolean;
    /**
     * 
     * @type {Array<FactureAchatCodeRejetFactureAchatWrite>}
     * @memberof FactureAchatFactureAchatWrite
     */
    'codesRejets'?: Array<FactureAchatCodeRejetFactureAchatWrite>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FactureAchatFactureAchatWrite
     */
    'notes'?: Array<string>;
    /**
     * 
     * @type {Array<FactureAchatLigneFactureAchatWrite>}
     * @memberof FactureAchatFactureAchatWrite
     */
    'lignes'?: Array<FactureAchatLigneFactureAchatWrite>;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatWrite
     */
    'pdfFacture'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatWrite
     */
    'ht'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatWrite
     */
    'tva'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatWrite
     */
    'ttc'?: string | null;
}
/**
 * 
 * @export
 * @interface FactureAchatJalFactureAchatJalRead
 */
export interface FactureAchatJalFactureAchatJalRead {
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'idJal'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'dateFacture'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'numeroFacture'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'idDossier'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'idDossierFpro'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'idPrestation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'numeroPiece'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'totalTtc'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'exportCompta'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'dateExportCompta'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'quiCree'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'quiModif'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'dateCree'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'dateModif'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'libelleCompta'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'vuEnCompta'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'factureMultiAnnonce'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'factureAValider'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'factureImportee'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'lienPdfFactureImportee'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'idEntiteFacturante'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'dateimport'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'comptaCegid'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'dateExportCegid'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'idSociete'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidRejetExportCompta'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidRejetDateExport'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'rrrFacture'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'dateRrrFacture'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'vuCegid'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'dateVuCegid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'fichierPdfFacture'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidDateComptable'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'userNote'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'montantRrrFacture'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'tauxRemiseJalReel'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'idFactureRbtFraisAchats'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'comptaControle'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'logMajRemiseTaux'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidFile'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'achatRrr'?: boolean;
    /**
     * 
     * @type {FactureAchatJalFactureAchatJalReadTypeRemiseJal}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'typeRemiseJal'?: FactureAchatJalFactureAchatJalReadTypeRemiseJal | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidCompteFrais'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidSp1Frais'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidSp2Frais'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidTl1Frais'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidTl2Frais'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidTl3Frais'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'htAutre'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'tvaAutre'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'tauxTvaAutre'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'htPublicite'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'tvaPublicite'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'tauxTvaPublicite'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidComptePublicite'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidSp1Publicite'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidSp2Publicite'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidTl1Publicite'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidTl2Publicite'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidTl3Publicite'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'htPubliciteNs'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'tvaPubliciteNs'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'tauxTvaPubliciteNs'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidComptePubliciteNs'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidSp1PubliciteNs'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidSp2PubliciteNs'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidTl1PubliciteNs'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidTl2PubliciteNs'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidTl3PubliciteNs'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'htJustif'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'tvaJustif'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'tauxTvaJustif'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidCompteJustif'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidSp1Justif'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidSp2Justif'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidTl1Justif'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidTl2Justif'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidTl3Justif'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'htRcsSoumis'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'tvaRcsSoumis'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'tauxTvaRcsSoumis'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidCompteRcsSoumis'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidSp1RcsSoumis'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidSp2RcsSoumis'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidTl1RcsSoumis'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidTl2RcsSoumis'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidTl3RcsSoumis'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'htRcsNonSoumis'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'tvaRcsNonSoumis'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'tauxTvaRcsNonSoumis'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidCompteRcsNonSoumis'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidSp1RcsNonSoumis'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidSp2RcsNonSoumis'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidTl1RcsNonSoumis'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidTl2RcsNonSoumis'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidTl3RcsNonSoumis'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'htAdju'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'tvaAdju'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'tauxTvaAdju'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidCompteAdju'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidSp1Adju'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidSp2Adju'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidTl1Adju'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidTl2Adju'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidTl3Adju'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'htHonorairesFormalite'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'tvaHonorairesFormalite'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'tauxTvaHonorairesFormalite'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidCompteHonorairesFormalite'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidSp1HonorairesFormalite'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidSp2HonorairesFormalite'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidTl1HonorairesFormalite'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidTl2HonorairesFormalite'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidTl3HonorairesFormalite'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'htVenteRegistre'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'tvaVenteRegistre'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'tauxTvaVenteRegistre'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidCompteVenteRegistre'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidSp1VenteRegistre'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidSp2VenteRegistre'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidTl1VenteRegistre'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidTl2VenteRegistre'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidTl3VenteRegistre'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'htMarchePublic'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'tvaMarchePublic'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'tauxTvaMarchePublic'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidCompteMarchePublic'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidSp1MarchePublic'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidSp2MarchePublic'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidTl1MarchePublic'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidTl2MarchePublic'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidTl3MarchePublic'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'litige'?: boolean;
    /**
     * 
     * @type {Array<TblFactureAchatJalLigneFactureAchatJalRead>}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'lignes'?: Array<TblFactureAchatJalLigneFactureAchatJalRead>;
}
/**
 * 
 * @export
 * @interface FactureAchatJalFactureAchatJalReadTypeRemiseJal
 */
export interface FactureAchatJalFactureAchatJalReadTypeRemiseJal {
}
/**
 * 
 * @export
 * @interface FactureAchatJalFactureAchatJalUpdate
 */
export interface FactureAchatJalFactureAchatJalUpdate {
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalFactureAchatJalUpdate
     */
    'idJal'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalUpdate
     */
    'dateFacture'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalUpdate
     */
    'numeroFacture'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalFactureAchatJalUpdate
     */
    'idDossier'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalFactureAchatJalUpdate
     */
    'idDossierFpro'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalFactureAchatJalUpdate
     */
    'idPrestation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalUpdate
     */
    'totalTtc'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalUpdate
     */
    'lienPdfFactureImportee'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalFactureAchatJalUpdate
     */
    'idEntiteFacturante'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalFactureAchatJalUpdate
     */
    'idSociete'?: number | null;
    /**
     * 
     * @type {Array<TblFactureAchatJalLigneFactureAchatJalUpdate>}
     * @memberof FactureAchatJalFactureAchatJalUpdate
     */
    'lignes'?: Array<TblFactureAchatJalLigneFactureAchatJalUpdate>;
}
/**
 * 
 * @export
 * @interface FactureAchatJalFactureAchatJalWrite
 */
export interface FactureAchatJalFactureAchatJalWrite {
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalFactureAchatJalWrite
     */
    'idJal'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalWrite
     */
    'dateFacture'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalWrite
     */
    'numeroFacture'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalFactureAchatJalWrite
     */
    'idDossier'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalFactureAchatJalWrite
     */
    'idDossierFpro'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalFactureAchatJalWrite
     */
    'idPrestation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalWrite
     */
    'totalTtc'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalWrite
     */
    'lienPdfFactureImportee'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalFactureAchatJalWrite
     */
    'idEntiteFacturante'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalFactureAchatJalWrite
     */
    'idSociete'?: number | null;
    /**
     * 
     * @type {Array<TblFactureAchatJalLigneFactureAchatJalWrite>}
     * @memberof FactureAchatJalFactureAchatJalWrite
     */
    'lignes'?: Array<TblFactureAchatJalLigneFactureAchatJalWrite>;
}
/**
 * 
 * @export
 * @interface FactureAchatJalJsonldFactureAchatJalRead
 */
export interface FactureAchatJalJsonldFactureAchatJalRead {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'idJal'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'dateFacture'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'numeroFacture'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'idDossier'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'idDossierFpro'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'idPrestation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'numeroPiece'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'totalTtc'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'exportCompta'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'dateExportCompta'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'quiCree'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'quiModif'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'dateCree'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'dateModif'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'libelleCompta'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'vuEnCompta'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'factureMultiAnnonce'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'factureAValider'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'factureImportee'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'lienPdfFactureImportee'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'idEntiteFacturante'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'dateimport'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'comptaCegid'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'dateExportCegid'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'idSociete'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidRejetExportCompta'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidRejetDateExport'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'rrrFacture'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'dateRrrFacture'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'vuCegid'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'dateVuCegid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'fichierPdfFacture'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidDateComptable'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'userNote'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'montantRrrFacture'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'tauxRemiseJalReel'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'idFactureRbtFraisAchats'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'comptaControle'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'logMajRemiseTaux'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidFile'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'achatRrr'?: boolean;
    /**
     * 
     * @type {FactureAchatJalJsonldFactureAchatJalReadTypeRemiseJal}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'typeRemiseJal'?: FactureAchatJalJsonldFactureAchatJalReadTypeRemiseJal | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidCompteFrais'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidSp1Frais'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidSp2Frais'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidTl1Frais'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidTl2Frais'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidTl3Frais'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'htAutre'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'tvaAutre'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'tauxTvaAutre'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'htPublicite'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'tvaPublicite'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'tauxTvaPublicite'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidComptePublicite'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidSp1Publicite'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidSp2Publicite'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidTl1Publicite'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidTl2Publicite'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidTl3Publicite'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'htPubliciteNs'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'tvaPubliciteNs'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'tauxTvaPubliciteNs'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidComptePubliciteNs'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidSp1PubliciteNs'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidSp2PubliciteNs'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidTl1PubliciteNs'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidTl2PubliciteNs'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidTl3PubliciteNs'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'htJustif'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'tvaJustif'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'tauxTvaJustif'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidCompteJustif'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidSp1Justif'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidSp2Justif'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidTl1Justif'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidTl2Justif'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidTl3Justif'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'htRcsSoumis'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'tvaRcsSoumis'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'tauxTvaRcsSoumis'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidCompteRcsSoumis'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidSp1RcsSoumis'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidSp2RcsSoumis'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidTl1RcsSoumis'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidTl2RcsSoumis'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidTl3RcsSoumis'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'htRcsNonSoumis'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'tvaRcsNonSoumis'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'tauxTvaRcsNonSoumis'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidCompteRcsNonSoumis'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidSp1RcsNonSoumis'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidSp2RcsNonSoumis'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidTl1RcsNonSoumis'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidTl2RcsNonSoumis'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidTl3RcsNonSoumis'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'htAdju'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'tvaAdju'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'tauxTvaAdju'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidCompteAdju'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidSp1Adju'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidSp2Adju'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidTl1Adju'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidTl2Adju'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidTl3Adju'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'htHonorairesFormalite'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'tvaHonorairesFormalite'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'tauxTvaHonorairesFormalite'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidCompteHonorairesFormalite'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidSp1HonorairesFormalite'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidSp2HonorairesFormalite'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidTl1HonorairesFormalite'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidTl2HonorairesFormalite'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidTl3HonorairesFormalite'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'htVenteRegistre'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'tvaVenteRegistre'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'tauxTvaVenteRegistre'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidCompteVenteRegistre'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidSp1VenteRegistre'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidSp2VenteRegistre'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidTl1VenteRegistre'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidTl2VenteRegistre'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidTl3VenteRegistre'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'htMarchePublic'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'tvaMarchePublic'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'tauxTvaMarchePublic'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidCompteMarchePublic'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidSp1MarchePublic'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidSp2MarchePublic'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidTl1MarchePublic'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidTl2MarchePublic'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidTl3MarchePublic'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'litige'?: boolean;
    /**
     * 
     * @type {Array<TblFactureAchatJalLigneJsonldFactureAchatJalRead>}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'lignes'?: Array<TblFactureAchatJalLigneJsonldFactureAchatJalRead>;
}
/**
 * 
 * @export
 * @interface FactureAchatJalJsonldFactureAchatJalReadTypeRemiseJal
 */
export interface FactureAchatJalJsonldFactureAchatJalReadTypeRemiseJal {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof FactureAchatJalJsonldFactureAchatJalReadTypeRemiseJal
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalReadTypeRemiseJal
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalReadTypeRemiseJal
     */
    '@type'?: string;
}
/**
 * 
 * @export
 * @interface FactureAchatJalJsonldFactureAchatJalUpdate
 */
export interface FactureAchatJalJsonldFactureAchatJalUpdate {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof FactureAchatJalJsonldFactureAchatJalUpdate
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalUpdate
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalJsonldFactureAchatJalUpdate
     */
    'idJal'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalUpdate
     */
    'dateFacture'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalUpdate
     */
    'numeroFacture'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalJsonldFactureAchatJalUpdate
     */
    'idDossier'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalJsonldFactureAchatJalUpdate
     */
    'idDossierFpro'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalJsonldFactureAchatJalUpdate
     */
    'idPrestation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalUpdate
     */
    'totalTtc'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalUpdate
     */
    'lienPdfFactureImportee'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalJsonldFactureAchatJalUpdate
     */
    'idEntiteFacturante'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalJsonldFactureAchatJalUpdate
     */
    'idSociete'?: number | null;
    /**
     * 
     * @type {Array<TblFactureAchatJalLigneJsonldFactureAchatJalUpdate>}
     * @memberof FactureAchatJalJsonldFactureAchatJalUpdate
     */
    'lignes'?: Array<TblFactureAchatJalLigneJsonldFactureAchatJalUpdate>;
}
/**
 * 
 * @export
 * @interface FactureAchatJalJsonldFactureAchatJalWrite
 */
export interface FactureAchatJalJsonldFactureAchatJalWrite {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof FactureAchatJalJsonldFactureAchatJalWrite
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalWrite
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalWrite
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalJsonldFactureAchatJalWrite
     */
    'idJal'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalWrite
     */
    'dateFacture'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalWrite
     */
    'numeroFacture'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalJsonldFactureAchatJalWrite
     */
    'idDossier'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalJsonldFactureAchatJalWrite
     */
    'idDossierFpro'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalJsonldFactureAchatJalWrite
     */
    'idPrestation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalWrite
     */
    'totalTtc'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalWrite
     */
    'lienPdfFactureImportee'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalJsonldFactureAchatJalWrite
     */
    'idEntiteFacturante'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalJsonldFactureAchatJalWrite
     */
    'idSociete'?: number | null;
    /**
     * 
     * @type {Array<TblFactureAchatJalLigneJsonldFactureAchatJalWrite>}
     * @memberof FactureAchatJalJsonldFactureAchatJalWrite
     */
    'lignes'?: Array<TblFactureAchatJalLigneJsonldFactureAchatJalWrite>;
}
/**
 * 
 * @export
 * @interface FactureAchatJsonldFactureAchatCreateFactureAchatWrite
 */
export interface FactureAchatJsonldFactureAchatCreateFactureAchatWrite {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof FactureAchatJsonldFactureAchatCreateFactureAchatWrite
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatCreateFactureAchatWrite
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatCreateFactureAchatWrite
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJsonldFactureAchatCreateFactureAchatWrite
     */
    'idJal'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJsonldFactureAchatCreateFactureAchatWrite
     */
    'idReleveAchat'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatCreateFactureAchatWrite
     */
    'dateFacture'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatCreateFactureAchatWrite
     */
    'numeroFacture'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJsonldFactureAchatCreateFactureAchatWrite
     */
    'idEntiteFacturante'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatCreateFactureAchatWrite
     */
    'libelleFacturante'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatCreateFactureAchatWrite
     */
    'libelleAnnonceur'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatCreateFactureAchatWrite
     */
    'rcsEntiteFacturante'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJsonldFactureAchatCreateFactureAchatWrite
     */
    'idTypeTarif'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJsonldFactureAchatCreateFactureAchatWrite
     */
    'idSociete'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJsonldFactureAchatCreateFactureAchatWrite
     */
    'forced'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJsonldFactureAchatCreateFactureAchatWrite
     */
    'cloture'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJsonldFactureAchatCreateFactureAchatWrite
     */
    'avoir'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJsonldFactureAchatCreateFactureAchatWrite
     */
    'hasSetRsf'?: boolean;
    /**
     * 
     * @type {Array<FactureAchatCodeRejetJsonldFactureAchatCreateFactureAchatWrite>}
     * @memberof FactureAchatJsonldFactureAchatCreateFactureAchatWrite
     */
    'codesRejets'?: Array<FactureAchatCodeRejetJsonldFactureAchatCreateFactureAchatWrite>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FactureAchatJsonldFactureAchatCreateFactureAchatWrite
     */
    'notes'?: Array<string>;
    /**
     * 
     * @type {Array<FactureAchatLigneJsonldFactureAchatCreateFactureAchatWrite>}
     * @memberof FactureAchatJsonldFactureAchatCreateFactureAchatWrite
     */
    'lignes'?: Array<FactureAchatLigneJsonldFactureAchatCreateFactureAchatWrite>;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatCreateFactureAchatWrite
     */
    'pdfFacture'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatCreateFactureAchatWrite
     */
    'ht'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatCreateFactureAchatWrite
     */
    'tva'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatCreateFactureAchatWrite
     */
    'ttc'?: string | null;
}
/**
 * 
 * @export
 * @interface FactureAchatJsonldFactureAchatReadDossier
 */
export interface FactureAchatJsonldFactureAchatReadDossier {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof FactureAchatJsonldFactureAchatReadDossier
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadDossier
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadDossier
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJsonldFactureAchatReadDossier
     */
    'id'?: number;
    /**
     * dossiers de la facture
     * @type {Array<FactureAchatFactureAchatReadDossierDossiersInner>}
     * @memberof FactureAchatJsonldFactureAchatReadDossier
     */
    'dossiers'?: Array<FactureAchatFactureAchatReadDossierDossiersInner>;
}
/**
 * 
 * @export
 * @interface FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
 */
export interface FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup {
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'idJal'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'idReleveAchat'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'idFactureAchatJal'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'dateFacture'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'numeroFacture'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'idEntiteFacturante'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'libelleFacturante'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'libelleAnnonceur'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'rcsEntiteFacturante'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'idTypeTarif'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'idSociete'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'forced'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'syncG3'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'cloture'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'avoir'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'hasSetRsf'?: boolean;
    /**
     * 
     * @type {Array<FactureAchatActionJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup>}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'actions'?: Array<FactureAchatActionJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup>;
    /**
     * 
     * @type {Array<FactureAchatNoteJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup>}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'notes'?: Array<FactureAchatNoteJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup>;
    /**
     * 
     * @type {FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroupPdfFacture}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'pdfFacture'?: FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroupPdfFacture | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'ht'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'tva'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'ttc'?: string | null;
    /**
     * Get the value of forcedttc
     * @type {boolean}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'forcedttc'?: boolean | null;
    /**
     * Get the value of forcedht
     * @type {boolean}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'forcedht'?: boolean | null;
    /**
     * Get the value of forcedtva
     * @type {boolean}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'forcedtva'?: boolean | null;
    /**
     * 
     * @type {FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroupFirstDossier}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'firstDossier'?: FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroupFirstDossier | null;
    /**
     * nombre de dossiers
     * @type {number}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'countDossier'?: number | null;
    /**
     * facture multi-annonces
     * @type {boolean}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'multiAnnonces'?: boolean | null;
    /**
     * Deux premiers codes rejets de la facture
     * @type {Array<FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroupFirstCodesRejetsInner>}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'firstCodesRejets'?: Array<FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroupFirstCodesRejetsInner>;
    /**
     * nombre de codes rejets
     * @type {number}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'countCodesRejets'?: number | null;
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {Array<AttachmentJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup>}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'attachments'?: Array<AttachmentJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup>;
    /**
     * Tags de la facture d\'achat
     * @type {Array<FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroupTagsInner>}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'tags'?: Array<FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroupTagsInner>;
}
/**
 * 
 * @export
 * @interface FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroupPdfFacture
 */
export interface FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroupPdfFacture {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroupPdfFacture
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroupPdfFacture
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroupPdfFacture
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroupPdfFacture
     */
    'id'?: number;
    /**
     * 
     * @type {File}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroupPdfFacture
     */
    'file'?: File | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroupPdfFacture
     */
    'contentUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroupPdfFacture
     */
    'urlExpires'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroupPdfFacture
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroupPdfFacture
     */
    'size'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroupPdfFacture
     */
    'originalName'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroupPdfFacture
     */
    'mimeType'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroupPdfFacture
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {Array<MediaObjectTagJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup>}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroupPdfFacture
     */
    'mediaObjectTags'?: Array<MediaObjectTagJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup>;
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroupPdfFacture
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroupPdfFacture
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroupPdfFacture
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroupPdfFacture
     */
    'updatedBy'?: string | null;
    /**
     * Tags du pdf
     * @type {Array<FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroupTagsInner>}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroupPdfFacture
     */
    'tags'?: Array<FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroupTagsInner> | null;
}
/**
 * 
 * @export
 * @interface FactureAchatJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
 */
export interface FactureAchatJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'idJal'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'idReleveAchat'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'idFactureAchatJal'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'dateFacture'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'numeroFacture'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'idEntiteFacturante'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'libelleFacturante'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'libelleAnnonceur'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'rcsEntiteFacturante'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'idTypeTarif'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'idSociete'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'forced'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'syncG3'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'cloture'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'avoir'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'hasSetRsf'?: boolean;
    /**
     * 
     * @type {Array<FactureAchatActionJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup>}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'actions'?: Array<FactureAchatActionJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup>;
    /**
     * 
     * @type {Array<FactureAchatNoteJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup>}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'notes'?: Array<FactureAchatNoteJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup>;
    /**
     * 
     * @type {FactureAchatJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroupPdfFacture}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'pdfFacture'?: FactureAchatJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroupPdfFacture | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'ht'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'tva'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'ttc'?: string | null;
    /**
     * Get the value of forcedttc
     * @type {boolean}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'forcedttc'?: boolean | null;
    /**
     * Get the value of forcedht
     * @type {boolean}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'forcedht'?: boolean | null;
    /**
     * Get the value of forcedtva
     * @type {boolean}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'forcedtva'?: boolean | null;
    /**
     * Syncro cegid de la facture
     * @type {boolean}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'syncCegid'?: boolean;
    /**
     * 
     * @type {FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroupFirstDossier}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'firstDossier'?: FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroupFirstDossier | null;
    /**
     * nombre de dossiers
     * @type {number}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'countDossier'?: number | null;
    /**
     * facture multi-annonces
     * @type {boolean}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'multiAnnonces'?: boolean | null;
    /**
     * Deux premiers codes rejets de la facture
     * @type {Array<FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroupFirstCodesRejetsInner>}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'firstCodesRejets'?: Array<FactureAchatFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroupFirstCodesRejetsInner>;
    /**
     * nombre de codes rejets
     * @type {number}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'countCodesRejets'?: number | null;
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {Array<AttachmentJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup>}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'attachments'?: Array<AttachmentJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup>;
}
/**
 * 
 * @export
 * @interface FactureAchatJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroupPdfFacture
 */
export interface FactureAchatJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroupPdfFacture {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroupPdfFacture
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroupPdfFacture
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroupPdfFacture
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroupPdfFacture
     */
    'id'?: number;
    /**
     * 
     * @type {File}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroupPdfFacture
     */
    'file'?: File | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroupPdfFacture
     */
    'contentUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroupPdfFacture
     */
    'urlExpires'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroupPdfFacture
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroupPdfFacture
     */
    'size'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroupPdfFacture
     */
    'originalName'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroupPdfFacture
     */
    'mimeType'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroupPdfFacture
     */
    'deleted'?: boolean;
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroupPdfFacture
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroupPdfFacture
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroupPdfFacture
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroupPdfFacture
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
 */
export interface FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup {
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    '@type'?: string;
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'idJal'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'idReleveAchat'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'idFactureAchatJal'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'dateFacture'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'numeroFacture'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'idEntiteFacturante'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'libelleFacturante'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'libelleAnnonceur'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'rcsEntiteFacturante'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'idTypeTarif'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'idSociete'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'forced'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'syncG3'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'cloture'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'avoir'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'hasSetRsf'?: boolean;
    /**
     * 
     * @type {Array<FactureAchatActionJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup>}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'actions'?: Array<FactureAchatActionJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup>;
    /**
     * 
     * @type {Array<FactureAchatNoteJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup>}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'notes'?: Array<FactureAchatNoteJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup>;
    /**
     * 
     * @type {FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroupPdfFacture}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'pdfFacture'?: FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroupPdfFacture | null;
    /**
     * facture multi-annonces
     * @type {boolean}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'multiAnnonces'?: boolean | null;
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {Array<AttachmentJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup>}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'attachments'?: Array<AttachmentJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup>;
    /**
     * Tags de la facture d\'achat
     * @type {Array<FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroupTagsInner>}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'tags'?: Array<FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroupTagsInner>;
}
/**
 * 
 * @export
 * @interface FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroupPdfFacture
 */
export interface FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroupPdfFacture {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroupPdfFacture
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroupPdfFacture
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroupPdfFacture
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroupPdfFacture
     */
    'id'?: number;
    /**
     * 
     * @type {File}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroupPdfFacture
     */
    'file'?: File | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroupPdfFacture
     */
    'contentUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroupPdfFacture
     */
    'urlExpires'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroupPdfFacture
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroupPdfFacture
     */
    'size'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroupPdfFacture
     */
    'originalName'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroupPdfFacture
     */
    'mimeType'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroupPdfFacture
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {Array<MediaObjectTagJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup>}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroupPdfFacture
     */
    'mediaObjectTags'?: Array<MediaObjectTagJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup>;
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroupPdfFacture
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroupPdfFacture
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroupPdfFacture
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroupPdfFacture
     */
    'updatedBy'?: string | null;
    /**
     * Tags du pdf
     * @type {Array<FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroupTagsInner>}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroupPdfFacture
     */
    'tags'?: Array<FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroupTagsInner> | null;
}
/**
 * 
 * @export
 * @interface FactureAchatJsonldFactureAchatSyncG3
 */
export interface FactureAchatJsonldFactureAchatSyncG3 {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof FactureAchatJsonldFactureAchatSyncG3
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatSyncG3
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatSyncG3
     */
    '@type'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJsonldFactureAchatSyncG3
     */
    'forced'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJsonldFactureAchatSyncG3
     */
    'syncG3'?: boolean;
}
/**
 * 
 * @export
 * @interface FactureAchatJsonldFactureAchatWrite
 */
export interface FactureAchatJsonldFactureAchatWrite {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof FactureAchatJsonldFactureAchatWrite
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatWrite
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatWrite
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJsonldFactureAchatWrite
     */
    'idJal'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJsonldFactureAchatWrite
     */
    'idReleveAchat'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatWrite
     */
    'dateFacture'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatWrite
     */
    'numeroFacture'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJsonldFactureAchatWrite
     */
    'idEntiteFacturante'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatWrite
     */
    'libelleFacturante'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatWrite
     */
    'libelleAnnonceur'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatWrite
     */
    'rcsEntiteFacturante'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJsonldFactureAchatWrite
     */
    'idTypeTarif'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJsonldFactureAchatWrite
     */
    'idSociete'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJsonldFactureAchatWrite
     */
    'forced'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJsonldFactureAchatWrite
     */
    'cloture'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJsonldFactureAchatWrite
     */
    'avoir'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJsonldFactureAchatWrite
     */
    'hasSetRsf'?: boolean;
    /**
     * 
     * @type {Array<FactureAchatCodeRejetJsonldFactureAchatWrite>}
     * @memberof FactureAchatJsonldFactureAchatWrite
     */
    'codesRejets'?: Array<FactureAchatCodeRejetJsonldFactureAchatWrite>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FactureAchatJsonldFactureAchatWrite
     */
    'notes'?: Array<string>;
    /**
     * 
     * @type {Array<FactureAchatLigneJsonldFactureAchatWrite>}
     * @memberof FactureAchatJsonldFactureAchatWrite
     */
    'lignes'?: Array<FactureAchatLigneJsonldFactureAchatWrite>;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatWrite
     */
    'pdfFacture'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatWrite
     */
    'ht'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatWrite
     */
    'tva'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatWrite
     */
    'ttc'?: string | null;
}
/**
 * 
 * @export
 * @interface FactureAchatJsonldMediaObjectReadTimestampableBlameambleGroupTaggableGroup
 */
export interface FactureAchatJsonldMediaObjectReadTimestampableBlameambleGroupTaggableGroup {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof FactureAchatJsonldMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJsonldMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    'numeroFacture'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJsonldMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJsonldMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    'forced'?: boolean;
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof FactureAchatJsonldMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof FactureAchatJsonldMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof FactureAchatJsonldMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof FactureAchatJsonldMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    'updatedBy'?: string | null;
    /**
     * Tags de la facture d\'achat
     * @type {Array<FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroupTagsInner>}
     * @memberof FactureAchatJsonldMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    'tags'?: Array<FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroupTagsInner>;
}
/**
 * 
 * @export
 * @interface FactureAchatJsonldStatsRead
 */
export interface FactureAchatJsonldStatsRead {
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldStatsRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldStatsRead
     */
    '@type'?: string;
}
/**
 * 
 * @export
 * @interface FactureAchatLigneDetailFactureAchatCreateFactureAchatWrite
 */
export interface FactureAchatLigneDetailFactureAchatCreateFactureAchatWrite {
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneDetailFactureAchatCreateFactureAchatWrite
     */
    'idRubriqueFacturation'?: number;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneDetailFactureAchatCreateFactureAchatWrite
     */
    'quantite'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailFactureAchatCreateFactureAchatWrite
     */
    'ht'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailFactureAchatCreateFactureAchatWrite
     */
    'tva'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneDetailFactureAchatCreateFactureAchatWrite
     */
    'tauxTva'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailFactureAchatCreateFactureAchatWrite
     */
    'cegidCompte'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailFactureAchatCreateFactureAchatWrite
     */
    'cegidSp1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailFactureAchatCreateFactureAchatWrite
     */
    'cegidSp2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailFactureAchatCreateFactureAchatWrite
     */
    'cegidTl1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailFactureAchatCreateFactureAchatWrite
     */
    'cegidTl2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailFactureAchatCreateFactureAchatWrite
     */
    'cegidTl3'?: string | null;
}
/**
 * 
 * @export
 * @interface FactureAchatLigneDetailFactureAchatLigneWrite
 */
export interface FactureAchatLigneDetailFactureAchatLigneWrite {
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneDetailFactureAchatLigneWrite
     */
    'idRubriqueFacturation'?: number;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneDetailFactureAchatLigneWrite
     */
    'quantite'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailFactureAchatLigneWrite
     */
    'ht'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailFactureAchatLigneWrite
     */
    'tva'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneDetailFactureAchatLigneWrite
     */
    'tauxTva'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailFactureAchatLigneWrite
     */
    'cegidCompte'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailFactureAchatLigneWrite
     */
    'cegidSp1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailFactureAchatLigneWrite
     */
    'cegidSp2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailFactureAchatLigneWrite
     */
    'cegidTl1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailFactureAchatLigneWrite
     */
    'cegidTl2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailFactureAchatLigneWrite
     */
    'cegidTl3'?: string | null;
}
/**
 * 
 * @export
 * @interface FactureAchatLigneDetailFactureAchatLigneWriteFactureAchatLigneCreate
 */
export interface FactureAchatLigneDetailFactureAchatLigneWriteFactureAchatLigneCreate {
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneDetailFactureAchatLigneWriteFactureAchatLigneCreate
     */
    'idRubriqueFacturation'?: number;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneDetailFactureAchatLigneWriteFactureAchatLigneCreate
     */
    'quantite'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailFactureAchatLigneWriteFactureAchatLigneCreate
     */
    'ht'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailFactureAchatLigneWriteFactureAchatLigneCreate
     */
    'tva'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneDetailFactureAchatLigneWriteFactureAchatLigneCreate
     */
    'tauxTva'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailFactureAchatLigneWriteFactureAchatLigneCreate
     */
    'cegidCompte'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailFactureAchatLigneWriteFactureAchatLigneCreate
     */
    'cegidSp1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailFactureAchatLigneWriteFactureAchatLigneCreate
     */
    'cegidSp2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailFactureAchatLigneWriteFactureAchatLigneCreate
     */
    'cegidTl1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailFactureAchatLigneWriteFactureAchatLigneCreate
     */
    'cegidTl2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailFactureAchatLigneWriteFactureAchatLigneCreate
     */
    'cegidTl3'?: string | null;
}
/**
 * 
 * @export
 * @interface FactureAchatLigneDetailFactureAchatRead
 */
export interface FactureAchatLigneDetailFactureAchatRead {
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneDetailFactureAchatRead
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneDetailFactureAchatRead
     */
    'idRubriqueFacturation'?: number;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneDetailFactureAchatRead
     */
    'quantite'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailFactureAchatRead
     */
    'ht'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailFactureAchatRead
     */
    'tva'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneDetailFactureAchatRead
     */
    'tauxTva'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailFactureAchatRead
     */
    'cegidCompte'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailFactureAchatRead
     */
    'cegidSp1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailFactureAchatRead
     */
    'cegidSp2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailFactureAchatRead
     */
    'cegidTl1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailFactureAchatRead
     */
    'cegidTl2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailFactureAchatRead
     */
    'cegidTl3'?: string | null;
}
/**
 * 
 * @export
 * @interface FactureAchatLigneDetailFactureAchatWrite
 */
export interface FactureAchatLigneDetailFactureAchatWrite {
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneDetailFactureAchatWrite
     */
    'idRubriqueFacturation'?: number;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneDetailFactureAchatWrite
     */
    'quantite'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailFactureAchatWrite
     */
    'ht'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailFactureAchatWrite
     */
    'tva'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneDetailFactureAchatWrite
     */
    'tauxTva'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailFactureAchatWrite
     */
    'cegidCompte'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailFactureAchatWrite
     */
    'cegidSp1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailFactureAchatWrite
     */
    'cegidSp2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailFactureAchatWrite
     */
    'cegidTl1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailFactureAchatWrite
     */
    'cegidTl2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailFactureAchatWrite
     */
    'cegidTl3'?: string | null;
}
/**
 * 
 * @export
 * @interface FactureAchatLigneDetailJsonldFactureAchatCreateFactureAchatWrite
 */
export interface FactureAchatLigneDetailJsonldFactureAchatCreateFactureAchatWrite {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatCreateFactureAchatWrite
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatCreateFactureAchatWrite
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatCreateFactureAchatWrite
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatCreateFactureAchatWrite
     */
    'idRubriqueFacturation'?: number;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatCreateFactureAchatWrite
     */
    'quantite'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatCreateFactureAchatWrite
     */
    'ht'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatCreateFactureAchatWrite
     */
    'tva'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatCreateFactureAchatWrite
     */
    'tauxTva'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatCreateFactureAchatWrite
     */
    'cegidCompte'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatCreateFactureAchatWrite
     */
    'cegidSp1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatCreateFactureAchatWrite
     */
    'cegidSp2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatCreateFactureAchatWrite
     */
    'cegidTl1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatCreateFactureAchatWrite
     */
    'cegidTl2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatCreateFactureAchatWrite
     */
    'cegidTl3'?: string | null;
}
/**
 * 
 * @export
 * @interface FactureAchatLigneDetailJsonldFactureAchatLigneWrite
 */
export interface FactureAchatLigneDetailJsonldFactureAchatLigneWrite {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatLigneWrite
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatLigneWrite
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatLigneWrite
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatLigneWrite
     */
    'idRubriqueFacturation'?: number;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatLigneWrite
     */
    'quantite'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatLigneWrite
     */
    'ht'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatLigneWrite
     */
    'tva'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatLigneWrite
     */
    'tauxTva'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatLigneWrite
     */
    'cegidCompte'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatLigneWrite
     */
    'cegidSp1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatLigneWrite
     */
    'cegidSp2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatLigneWrite
     */
    'cegidTl1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatLigneWrite
     */
    'cegidTl2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatLigneWrite
     */
    'cegidTl3'?: string | null;
}
/**
 * 
 * @export
 * @interface FactureAchatLigneDetailJsonldFactureAchatLigneWriteFactureAchatLigneCreate
 */
export interface FactureAchatLigneDetailJsonldFactureAchatLigneWriteFactureAchatLigneCreate {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatLigneWriteFactureAchatLigneCreate
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatLigneWriteFactureAchatLigneCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatLigneWriteFactureAchatLigneCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatLigneWriteFactureAchatLigneCreate
     */
    'idRubriqueFacturation'?: number;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatLigneWriteFactureAchatLigneCreate
     */
    'quantite'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatLigneWriteFactureAchatLigneCreate
     */
    'ht'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatLigneWriteFactureAchatLigneCreate
     */
    'tva'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatLigneWriteFactureAchatLigneCreate
     */
    'tauxTva'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatLigneWriteFactureAchatLigneCreate
     */
    'cegidCompte'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatLigneWriteFactureAchatLigneCreate
     */
    'cegidSp1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatLigneWriteFactureAchatLigneCreate
     */
    'cegidSp2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatLigneWriteFactureAchatLigneCreate
     */
    'cegidTl1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatLigneWriteFactureAchatLigneCreate
     */
    'cegidTl2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatLigneWriteFactureAchatLigneCreate
     */
    'cegidTl3'?: string | null;
}
/**
 * 
 * @export
 * @interface FactureAchatLigneDetailJsonldFactureAchatRead
 */
export interface FactureAchatLigneDetailJsonldFactureAchatRead {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatRead
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatRead
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatRead
     */
    'idRubriqueFacturation'?: number;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatRead
     */
    'quantite'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatRead
     */
    'ht'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatRead
     */
    'tva'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatRead
     */
    'tauxTva'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatRead
     */
    'cegidCompte'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatRead
     */
    'cegidSp1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatRead
     */
    'cegidSp2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatRead
     */
    'cegidTl1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatRead
     */
    'cegidTl2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatRead
     */
    'cegidTl3'?: string | null;
}
/**
 * 
 * @export
 * @interface FactureAchatLigneDetailJsonldFactureAchatWrite
 */
export interface FactureAchatLigneDetailJsonldFactureAchatWrite {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatWrite
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatWrite
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatWrite
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatWrite
     */
    'idRubriqueFacturation'?: number;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatWrite
     */
    'quantite'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatWrite
     */
    'ht'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatWrite
     */
    'tva'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatWrite
     */
    'tauxTva'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatWrite
     */
    'cegidCompte'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatWrite
     */
    'cegidSp1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatWrite
     */
    'cegidSp2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatWrite
     */
    'cegidTl1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatWrite
     */
    'cegidTl2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatWrite
     */
    'cegidTl3'?: string | null;
}
/**
 * 
 * @export
 * @interface FactureAchatLigneFactureAchatCreateFactureAchatWrite
 */
export interface FactureAchatLigneFactureAchatCreateFactureAchatWrite {
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneFactureAchatCreateFactureAchatWrite
     */
    'idDossier'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneFactureAchatCreateFactureAchatWrite
     */
    'idDossierFpro'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneFactureAchatCreateFactureAchatWrite
     */
    'numeroDossier'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneFactureAchatCreateFactureAchatWrite
     */
    'idPrestation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneFactureAchatCreateFactureAchatWrite
     */
    'libelleAnnonceur'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneFactureAchatCreateFactureAchatWrite
     */
    'idFLigneVente'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneFactureAchatCreateFactureAchatWrite
     */
    'numeroPrestation'?: string | null;
    /**
     * 
     * @type {Array<FactureAchatLigneDetailFactureAchatCreateFactureAchatWrite>}
     * @memberof FactureAchatLigneFactureAchatCreateFactureAchatWrite
     */
    'details'?: Array<FactureAchatLigneDetailFactureAchatCreateFactureAchatWrite>;
}
/**
 * 
 * @export
 * @interface FactureAchatLigneFactureAchatLigneWrite
 */
export interface FactureAchatLigneFactureAchatLigneWrite {
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneFactureAchatLigneWrite
     */
    'idDossier'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneFactureAchatLigneWrite
     */
    'idDossierFpro'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneFactureAchatLigneWrite
     */
    'numeroDossier'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneFactureAchatLigneWrite
     */
    'idPrestation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneFactureAchatLigneWrite
     */
    'libelleAnnonceur'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneFactureAchatLigneWrite
     */
    'idFLigneVente'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneFactureAchatLigneWrite
     */
    'numeroPrestation'?: string | null;
    /**
     * 
     * @type {Array<FactureAchatLigneDetailFactureAchatLigneWrite>}
     * @memberof FactureAchatLigneFactureAchatLigneWrite
     */
    'details'?: Array<FactureAchatLigneDetailFactureAchatLigneWrite>;
}
/**
 * 
 * @export
 * @interface FactureAchatLigneFactureAchatLigneWriteFactureAchatLigneCreate
 */
export interface FactureAchatLigneFactureAchatLigneWriteFactureAchatLigneCreate {
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneFactureAchatLigneWriteFactureAchatLigneCreate
     */
    'facture'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneFactureAchatLigneWriteFactureAchatLigneCreate
     */
    'idDossier'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneFactureAchatLigneWriteFactureAchatLigneCreate
     */
    'idDossierFpro'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneFactureAchatLigneWriteFactureAchatLigneCreate
     */
    'numeroDossier'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneFactureAchatLigneWriteFactureAchatLigneCreate
     */
    'idPrestation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneFactureAchatLigneWriteFactureAchatLigneCreate
     */
    'libelleAnnonceur'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneFactureAchatLigneWriteFactureAchatLigneCreate
     */
    'idFLigneVente'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneFactureAchatLigneWriteFactureAchatLigneCreate
     */
    'numeroPrestation'?: string | null;
    /**
     * 
     * @type {Array<FactureAchatLigneDetailFactureAchatLigneWriteFactureAchatLigneCreate>}
     * @memberof FactureAchatLigneFactureAchatLigneWriteFactureAchatLigneCreate
     */
    'details'?: Array<FactureAchatLigneDetailFactureAchatLigneWriteFactureAchatLigneCreate>;
}
/**
 * 
 * @export
 * @interface FactureAchatLigneFactureAchatRead
 */
export interface FactureAchatLigneFactureAchatRead {
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneFactureAchatRead
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneFactureAchatRead
     */
    'idDossier'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneFactureAchatRead
     */
    'idDossierFpro'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneFactureAchatRead
     */
    'numeroDossier'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneFactureAchatRead
     */
    'idPrestation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneFactureAchatRead
     */
    'libelleAnnonceur'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneFactureAchatRead
     */
    'idFLigneVente'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneFactureAchatRead
     */
    'numeroPrestation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneFactureAchatRead
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneFactureAchatRead
     */
    'dateMaj'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneFactureAchatRead
     */
    'userCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneFactureAchatRead
     */
    'userMaj'?: string | null;
    /**
     * 
     * @type {Array<FactureAchatLigneDetailFactureAchatRead>}
     * @memberof FactureAchatLigneFactureAchatRead
     */
    'details'?: Array<FactureAchatLigneDetailFactureAchatRead>;
}
/**
 * 
 * @export
 * @interface FactureAchatLigneFactureAchatWrite
 */
export interface FactureAchatLigneFactureAchatWrite {
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneFactureAchatWrite
     */
    'idDossier'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneFactureAchatWrite
     */
    'idDossierFpro'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneFactureAchatWrite
     */
    'numeroDossier'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneFactureAchatWrite
     */
    'idPrestation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneFactureAchatWrite
     */
    'libelleAnnonceur'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneFactureAchatWrite
     */
    'idFLigneVente'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneFactureAchatWrite
     */
    'numeroPrestation'?: string | null;
    /**
     * 
     * @type {Array<FactureAchatLigneDetailFactureAchatWrite>}
     * @memberof FactureAchatLigneFactureAchatWrite
     */
    'details'?: Array<FactureAchatLigneDetailFactureAchatWrite>;
}
/**
 * 
 * @export
 * @interface FactureAchatLigneJsonldFactureAchatCreateFactureAchatWrite
 */
export interface FactureAchatLigneJsonldFactureAchatCreateFactureAchatWrite {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof FactureAchatLigneJsonldFactureAchatCreateFactureAchatWrite
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneJsonldFactureAchatCreateFactureAchatWrite
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneJsonldFactureAchatCreateFactureAchatWrite
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneJsonldFactureAchatCreateFactureAchatWrite
     */
    'idDossier'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneJsonldFactureAchatCreateFactureAchatWrite
     */
    'idDossierFpro'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneJsonldFactureAchatCreateFactureAchatWrite
     */
    'numeroDossier'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneJsonldFactureAchatCreateFactureAchatWrite
     */
    'idPrestation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneJsonldFactureAchatCreateFactureAchatWrite
     */
    'libelleAnnonceur'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneJsonldFactureAchatCreateFactureAchatWrite
     */
    'idFLigneVente'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneJsonldFactureAchatCreateFactureAchatWrite
     */
    'numeroPrestation'?: string | null;
    /**
     * 
     * @type {Array<FactureAchatLigneDetailJsonldFactureAchatCreateFactureAchatWrite>}
     * @memberof FactureAchatLigneJsonldFactureAchatCreateFactureAchatWrite
     */
    'details'?: Array<FactureAchatLigneDetailJsonldFactureAchatCreateFactureAchatWrite>;
}
/**
 * 
 * @export
 * @interface FactureAchatLigneJsonldFactureAchatLigneWrite
 */
export interface FactureAchatLigneJsonldFactureAchatLigneWrite {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof FactureAchatLigneJsonldFactureAchatLigneWrite
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneJsonldFactureAchatLigneWrite
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneJsonldFactureAchatLigneWrite
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneJsonldFactureAchatLigneWrite
     */
    'idDossier'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneJsonldFactureAchatLigneWrite
     */
    'idDossierFpro'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneJsonldFactureAchatLigneWrite
     */
    'numeroDossier'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneJsonldFactureAchatLigneWrite
     */
    'idPrestation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneJsonldFactureAchatLigneWrite
     */
    'libelleAnnonceur'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneJsonldFactureAchatLigneWrite
     */
    'idFLigneVente'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneJsonldFactureAchatLigneWrite
     */
    'numeroPrestation'?: string | null;
    /**
     * 
     * @type {Array<FactureAchatLigneDetailJsonldFactureAchatLigneWrite>}
     * @memberof FactureAchatLigneJsonldFactureAchatLigneWrite
     */
    'details'?: Array<FactureAchatLigneDetailJsonldFactureAchatLigneWrite>;
}
/**
 * 
 * @export
 * @interface FactureAchatLigneJsonldFactureAchatLigneWriteFactureAchatLigneCreate
 */
export interface FactureAchatLigneJsonldFactureAchatLigneWriteFactureAchatLigneCreate {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof FactureAchatLigneJsonldFactureAchatLigneWriteFactureAchatLigneCreate
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneJsonldFactureAchatLigneWriteFactureAchatLigneCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneJsonldFactureAchatLigneWriteFactureAchatLigneCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneJsonldFactureAchatLigneWriteFactureAchatLigneCreate
     */
    'facture'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneJsonldFactureAchatLigneWriteFactureAchatLigneCreate
     */
    'idDossier'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneJsonldFactureAchatLigneWriteFactureAchatLigneCreate
     */
    'idDossierFpro'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneJsonldFactureAchatLigneWriteFactureAchatLigneCreate
     */
    'numeroDossier'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneJsonldFactureAchatLigneWriteFactureAchatLigneCreate
     */
    'idPrestation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneJsonldFactureAchatLigneWriteFactureAchatLigneCreate
     */
    'libelleAnnonceur'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneJsonldFactureAchatLigneWriteFactureAchatLigneCreate
     */
    'idFLigneVente'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneJsonldFactureAchatLigneWriteFactureAchatLigneCreate
     */
    'numeroPrestation'?: string | null;
    /**
     * 
     * @type {Array<FactureAchatLigneDetailJsonldFactureAchatLigneWriteFactureAchatLigneCreate>}
     * @memberof FactureAchatLigneJsonldFactureAchatLigneWriteFactureAchatLigneCreate
     */
    'details'?: Array<FactureAchatLigneDetailJsonldFactureAchatLigneWriteFactureAchatLigneCreate>;
}
/**
 * 
 * @export
 * @interface FactureAchatLigneJsonldFactureAchatRead
 */
export interface FactureAchatLigneJsonldFactureAchatRead {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof FactureAchatLigneJsonldFactureAchatRead
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneJsonldFactureAchatRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneJsonldFactureAchatRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneJsonldFactureAchatRead
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneJsonldFactureAchatRead
     */
    'idDossier'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneJsonldFactureAchatRead
     */
    'idDossierFpro'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneJsonldFactureAchatRead
     */
    'numeroDossier'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneJsonldFactureAchatRead
     */
    'idPrestation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneJsonldFactureAchatRead
     */
    'libelleAnnonceur'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneJsonldFactureAchatRead
     */
    'idFLigneVente'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneJsonldFactureAchatRead
     */
    'numeroPrestation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneJsonldFactureAchatRead
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneJsonldFactureAchatRead
     */
    'dateMaj'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneJsonldFactureAchatRead
     */
    'userCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneJsonldFactureAchatRead
     */
    'userMaj'?: string | null;
    /**
     * 
     * @type {Array<FactureAchatLigneDetailJsonldFactureAchatRead>}
     * @memberof FactureAchatLigneJsonldFactureAchatRead
     */
    'details'?: Array<FactureAchatLigneDetailJsonldFactureAchatRead>;
}
/**
 * 
 * @export
 * @interface FactureAchatLigneJsonldFactureAchatWrite
 */
export interface FactureAchatLigneJsonldFactureAchatWrite {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof FactureAchatLigneJsonldFactureAchatWrite
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneJsonldFactureAchatWrite
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneJsonldFactureAchatWrite
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneJsonldFactureAchatWrite
     */
    'idDossier'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneJsonldFactureAchatWrite
     */
    'idDossierFpro'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneJsonldFactureAchatWrite
     */
    'numeroDossier'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneJsonldFactureAchatWrite
     */
    'idPrestation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneJsonldFactureAchatWrite
     */
    'libelleAnnonceur'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneJsonldFactureAchatWrite
     */
    'idFLigneVente'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneJsonldFactureAchatWrite
     */
    'numeroPrestation'?: string | null;
    /**
     * 
     * @type {Array<FactureAchatLigneDetailJsonldFactureAchatWrite>}
     * @memberof FactureAchatLigneJsonldFactureAchatWrite
     */
    'details'?: Array<FactureAchatLigneDetailJsonldFactureAchatWrite>;
}
/**
 * 
 * @export
 * @interface FactureAchatMediaObjectReadTimestampableBlameambleGroupTaggableGroup
 */
export interface FactureAchatMediaObjectReadTimestampableBlameambleGroupTaggableGroup {
    /**
     * 
     * @type {number}
     * @memberof FactureAchatMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    'numeroFacture'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    'forced'?: boolean;
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof FactureAchatMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof FactureAchatMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof FactureAchatMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof FactureAchatMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    'updatedBy'?: string | null;
    /**
     * Tags de la facture d\'achat
     * @type {Array<FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroupTagsInner>}
     * @memberof FactureAchatMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    'tags'?: Array<FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroupTagsInner>;
}
/**
 * 
 * @export
 * @interface FactureAchatNoteFactureAchatNoteCreate
 */
export interface FactureAchatNoteFactureAchatNoteCreate {
    /**
     * 
     * @type {number}
     * @memberof FactureAchatNoteFactureAchatNoteCreate
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteFactureAchatNoteCreate
     */
    'facture'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatNoteFactureAchatNoteCreate
     */
    'idDossier'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteFactureAchatNoteCreate
     */
    'note': string;
}
/**
 * 
 * @export
 * @interface FactureAchatNoteFactureAchatNoteWrite
 */
export interface FactureAchatNoteFactureAchatNoteWrite {
    /**
     * 
     * @type {number}
     * @memberof FactureAchatNoteFactureAchatNoteWrite
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteFactureAchatNoteWrite
     */
    'facture'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatNoteFactureAchatNoteWrite
     */
    'idDossier'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteFactureAchatNoteWrite
     */
    'note': string;
}
/**
 * 
 * @export
 * @interface FactureAchatNoteFactureAchatRead
 */
export interface FactureAchatNoteFactureAchatRead {
    /**
     * 
     * @type {number}
     * @memberof FactureAchatNoteFactureAchatRead
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatNoteFactureAchatRead
     */
    'idDossier'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteFactureAchatRead
     */
    'note'?: string;
}
/**
 * 
 * @export
 * @interface FactureAchatNoteFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
 */
export interface FactureAchatNoteFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup {
    /**
     * 
     * @type {number}
     * @memberof FactureAchatNoteFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatNoteFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'idDossier'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'note'?: string;
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof FactureAchatNoteFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof FactureAchatNoteFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof FactureAchatNoteFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof FactureAchatNoteFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface FactureAchatNoteFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
 */
export interface FactureAchatNoteFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup {
    /**
     * 
     * @type {number}
     * @memberof FactureAchatNoteFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatNoteFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'idDossier'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'note'?: string;
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof FactureAchatNoteFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof FactureAchatNoteFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof FactureAchatNoteFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof FactureAchatNoteFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface FactureAchatNoteFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
 */
export interface FactureAchatNoteFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup {
    /**
     * 
     * @type {number}
     * @memberof FactureAchatNoteFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatNoteFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'idDossier'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'note'?: string;
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof FactureAchatNoteFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof FactureAchatNoteFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof FactureAchatNoteFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof FactureAchatNoteFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface FactureAchatNoteJsonldFactureAchatNoteCreate
 */
export interface FactureAchatNoteJsonldFactureAchatNoteCreate {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof FactureAchatNoteJsonldFactureAchatNoteCreate
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteJsonldFactureAchatNoteCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteJsonldFactureAchatNoteCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatNoteJsonldFactureAchatNoteCreate
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteJsonldFactureAchatNoteCreate
     */
    'facture'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatNoteJsonldFactureAchatNoteCreate
     */
    'idDossier'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteJsonldFactureAchatNoteCreate
     */
    'note': string;
}
/**
 * 
 * @export
 * @interface FactureAchatNoteJsonldFactureAchatNoteWrite
 */
export interface FactureAchatNoteJsonldFactureAchatNoteWrite {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof FactureAchatNoteJsonldFactureAchatNoteWrite
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteJsonldFactureAchatNoteWrite
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteJsonldFactureAchatNoteWrite
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatNoteJsonldFactureAchatNoteWrite
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteJsonldFactureAchatNoteWrite
     */
    'facture'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatNoteJsonldFactureAchatNoteWrite
     */
    'idDossier'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteJsonldFactureAchatNoteWrite
     */
    'note': string;
}
/**
 * 
 * @export
 * @interface FactureAchatNoteJsonldFactureAchatRead
 */
export interface FactureAchatNoteJsonldFactureAchatRead {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof FactureAchatNoteJsonldFactureAchatRead
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteJsonldFactureAchatRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteJsonldFactureAchatRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatNoteJsonldFactureAchatRead
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatNoteJsonldFactureAchatRead
     */
    'idDossier'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteJsonldFactureAchatRead
     */
    'note'?: string;
}
/**
 * 
 * @export
 * @interface FactureAchatNoteJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
 */
export interface FactureAchatNoteJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof FactureAchatNoteJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatNoteJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatNoteJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'idDossier'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'note'?: string;
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof FactureAchatNoteJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof FactureAchatNoteJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof FactureAchatNoteJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof FactureAchatNoteJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface FactureAchatNoteJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
 */
export interface FactureAchatNoteJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof FactureAchatNoteJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatNoteJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatNoteJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'idDossier'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'note'?: string;
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof FactureAchatNoteJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof FactureAchatNoteJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof FactureAchatNoteJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof FactureAchatNoteJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface FactureAchatNoteJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
 */
export interface FactureAchatNoteJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof FactureAchatNoteJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatNoteJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatNoteJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'idDossier'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'note'?: string;
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof FactureAchatNoteJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof FactureAchatNoteJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof FactureAchatNoteJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof FactureAchatNoteJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface FactureAchatStatsJsonldStatsRead
 */
export interface FactureAchatStatsJsonldStatsRead {
    /**
     * 
     * @type {string}
     * @memberof FactureAchatStatsJsonldStatsRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatStatsJsonldStatsRead
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatStatsJsonldStatsRead
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatStatsJsonldStatsRead
     */
    'libelle'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatStatsJsonldStatsRead
     */
    'count'?: number;
}
/**
 * 
 * @export
 * @interface FactureAchatStatsStatsRead
 */
export interface FactureAchatStatsStatsRead {
    /**
     * 
     * @type {string}
     * @memberof FactureAchatStatsStatsRead
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatStatsStatsRead
     */
    'libelle'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatStatsStatsRead
     */
    'count'?: number;
}
/**
 * 
 * @export
 * @interface GetActionCollection200Response
 */
export interface GetActionCollection200Response {
    /**
     * 
     * @type {Array<ActionJsonldActionRead>}
     * @memberof GetActionCollection200Response
     */
    'hydra:member': Array<ActionJsonldActionRead>;
    /**
     * 
     * @type {number}
     * @memberof GetActionCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {GetActionCollection200ResponseHydraView}
     * @memberof GetActionCollection200Response
     */
    'hydra:view'?: GetActionCollection200ResponseHydraView;
    /**
     * 
     * @type {GetActionCollection200ResponseHydraSearch}
     * @memberof GetActionCollection200Response
     */
    'hydra:search'?: GetActionCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface GetActionCollection200ResponseHydraSearch
 */
export interface GetActionCollection200ResponseHydraSearch {
    /**
     * 
     * @type {string}
     * @memberof GetActionCollection200ResponseHydraSearch
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetActionCollection200ResponseHydraSearch
     */
    'hydra:template'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetActionCollection200ResponseHydraSearch
     */
    'hydra:variableRepresentation'?: string;
    /**
     * 
     * @type {Array<GetActionCollection200ResponseHydraSearchHydraMappingInner>}
     * @memberof GetActionCollection200ResponseHydraSearch
     */
    'hydra:mapping'?: Array<GetActionCollection200ResponseHydraSearchHydraMappingInner>;
}
/**
 * 
 * @export
 * @interface GetActionCollection200ResponseHydraSearchHydraMappingInner
 */
export interface GetActionCollection200ResponseHydraSearchHydraMappingInner {
    /**
     * 
     * @type {string}
     * @memberof GetActionCollection200ResponseHydraSearchHydraMappingInner
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetActionCollection200ResponseHydraSearchHydraMappingInner
     */
    'variable'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetActionCollection200ResponseHydraSearchHydraMappingInner
     */
    'property'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetActionCollection200ResponseHydraSearchHydraMappingInner
     */
    'required'?: boolean;
}
/**
 * 
 * @export
 * @interface GetActionCollection200ResponseHydraView
 */
export interface GetActionCollection200ResponseHydraView {
    /**
     * 
     * @type {string}
     * @memberof GetActionCollection200ResponseHydraView
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetActionCollection200ResponseHydraView
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetActionCollection200ResponseHydraView
     */
    'hydra:first'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetActionCollection200ResponseHydraView
     */
    'hydra:last'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetActionCollection200ResponseHydraView
     */
    'hydra:previous'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetActionCollection200ResponseHydraView
     */
    'hydra:next'?: string;
}
/**
 * 
 * @export
 * @interface GetActionCollection401Response
 */
export interface GetActionCollection401Response {
    /**
     * 
     * @type {string}
     * @memberof GetActionCollection401Response
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface GetActionCollection403Response
 */
export interface GetActionCollection403Response {
    /**
     * 
     * @type {string}
     * @memberof GetActionCollection403Response
     */
    'error'?: string;
    /**
     * 
     * @type {GetActionCollection403ResponseException}
     * @memberof GetActionCollection403Response
     */
    'exception'?: GetActionCollection403ResponseException;
}
/**
 * 
 * @export
 * @interface GetActionCollection403ResponseException
 */
export interface GetActionCollection403ResponseException {
    /**
     * 
     * @type {string}
     * @memberof GetActionCollection403ResponseException
     */
    'message'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetActionCollection403ResponseException
     */
    'code'?: number;
}
/**
 * 
 * @export
 * @interface GetCategorieFaStatsFactureAchatCollection200Response
 */
export interface GetCategorieFaStatsFactureAchatCollection200Response {
    /**
     * 
     * @type {Array<FactureAchatStatsJsonldStatsRead>}
     * @memberof GetCategorieFaStatsFactureAchatCollection200Response
     */
    'hydra:member': Array<FactureAchatStatsJsonldStatsRead>;
    /**
     * 
     * @type {number}
     * @memberof GetCategorieFaStatsFactureAchatCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {GetActionCollection200ResponseHydraView}
     * @memberof GetCategorieFaStatsFactureAchatCollection200Response
     */
    'hydra:view'?: GetActionCollection200ResponseHydraView;
    /**
     * 
     * @type {GetActionCollection200ResponseHydraSearch}
     * @memberof GetCategorieFaStatsFactureAchatCollection200Response
     */
    'hydra:search'?: GetActionCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface GetCategorieRejetCollection200Response
 */
export interface GetCategorieRejetCollection200Response {
    /**
     * 
     * @type {Array<CategorieRejetJsonldCategorieRejetRead>}
     * @memberof GetCategorieRejetCollection200Response
     */
    'hydra:member': Array<CategorieRejetJsonldCategorieRejetRead>;
    /**
     * 
     * @type {number}
     * @memberof GetCategorieRejetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {GetActionCollection200ResponseHydraView}
     * @memberof GetCategorieRejetCollection200Response
     */
    'hydra:view'?: GetActionCollection200ResponseHydraView;
    /**
     * 
     * @type {GetActionCollection200ResponseHydraSearch}
     * @memberof GetCategorieRejetCollection200Response
     */
    'hydra:search'?: GetActionCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface GetCodeRejetCollection200Response
 */
export interface GetCodeRejetCollection200Response {
    /**
     * 
     * @type {Array<CodeRejetJsonldCodeRejetRead>}
     * @memberof GetCodeRejetCollection200Response
     */
    'hydra:member': Array<CodeRejetJsonldCodeRejetRead>;
    /**
     * 
     * @type {number}
     * @memberof GetCodeRejetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {GetActionCollection200ResponseHydraView}
     * @memberof GetCodeRejetCollection200Response
     */
    'hydra:view'?: GetActionCollection200ResponseHydraView;
    /**
     * 
     * @type {GetActionCollection200ResponseHydraSearch}
     * @memberof GetCodeRejetCollection200Response
     */
    'hydra:search'?: GetActionCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface GetCoderejetFaStatsFactureAchatCollection200Response
 */
export interface GetCoderejetFaStatsFactureAchatCollection200Response {
    /**
     * 
     * @type {Array<GetCoderejetFaStatsFactureAchatCollection200ResponseHydraMemberInner>}
     * @memberof GetCoderejetFaStatsFactureAchatCollection200Response
     */
    'hydra:member'?: Array<GetCoderejetFaStatsFactureAchatCollection200ResponseHydraMemberInner>;
    /**
     * 
     * @type {number}
     * @memberof GetCoderejetFaStatsFactureAchatCollection200Response
     */
    'hydra:totalItems'?: number;
}
/**
 * 
 * @export
 * @interface GetCoderejetFaStatsFactureAchatCollection200ResponseHydraMemberInner
 */
export interface GetCoderejetFaStatsFactureAchatCollection200ResponseHydraMemberInner {
    /**
     * 
     * @type {string}
     * @memberof GetCoderejetFaStatsFactureAchatCollection200ResponseHydraMemberInner
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCoderejetFaStatsFactureAchatCollection200ResponseHydraMemberInner
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetCoderejetFaStatsFactureAchatCollection200ResponseHydraMemberInner
     */
    'count'?: number;
}
/**
 * 
 * @export
 * @interface GetExceptionRejetCollection200Response
 */
export interface GetExceptionRejetCollection200Response {
    /**
     * 
     * @type {Array<ExceptionRejetJsonldExceptionRejetRead>}
     * @memberof GetExceptionRejetCollection200Response
     */
    'hydra:member': Array<ExceptionRejetJsonldExceptionRejetRead>;
    /**
     * 
     * @type {number}
     * @memberof GetExceptionRejetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {GetActionCollection200ResponseHydraView}
     * @memberof GetExceptionRejetCollection200Response
     */
    'hydra:view'?: GetActionCollection200ResponseHydraView;
    /**
     * 
     * @type {GetActionCollection200ResponseHydraSearch}
     * @memberof GetExceptionRejetCollection200Response
     */
    'hydra:search'?: GetActionCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface GetFactureAchatCodesRejetsFactureAchatCodeRejetCollection200Response
 */
export interface GetFactureAchatCodesRejetsFactureAchatCodeRejetCollection200Response {
    /**
     * 
     * @type {Array<FactureAchatCodeRejetJsonldFactureAchatCodeRejetReadTimestampableBlameambleGroup>}
     * @memberof GetFactureAchatCodesRejetsFactureAchatCodeRejetCollection200Response
     */
    'hydra:member': Array<FactureAchatCodeRejetJsonldFactureAchatCodeRejetReadTimestampableBlameambleGroup>;
    /**
     * 
     * @type {number}
     * @memberof GetFactureAchatCodesRejetsFactureAchatCodeRejetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {GetActionCollection200ResponseHydraView}
     * @memberof GetFactureAchatCodesRejetsFactureAchatCodeRejetCollection200Response
     */
    'hydra:view'?: GetActionCollection200ResponseHydraView;
    /**
     * 
     * @type {GetActionCollection200ResponseHydraSearch}
     * @memberof GetFactureAchatCodesRejetsFactureAchatCodeRejetCollection200Response
     */
    'hydra:search'?: GetActionCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface GetFacturesAchatsFactureAchatCollection200Response
 */
export interface GetFacturesAchatsFactureAchatCollection200Response {
    /**
     * 
     * @type {Array<FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup>}
     * @memberof GetFacturesAchatsFactureAchatCollection200Response
     */
    'hydra:member': Array<FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup>;
    /**
     * 
     * @type {number}
     * @memberof GetFacturesAchatsFactureAchatCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {GetActionCollection200ResponseHydraView}
     * @memberof GetFacturesAchatsFactureAchatCollection200Response
     */
    'hydra:view'?: GetActionCollection200ResponseHydraView;
    /**
     * 
     * @type {GetActionCollection200ResponseHydraSearch}
     * @memberof GetFacturesAchatsFactureAchatCollection200Response
     */
    'hydra:search'?: GetActionCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface GetFacturesAchatsJalsFactureAchatJalCollection200Response
 */
export interface GetFacturesAchatsJalsFactureAchatJalCollection200Response {
    /**
     * 
     * @type {Array<FactureAchatJalJsonldFactureAchatJalRead>}
     * @memberof GetFacturesAchatsJalsFactureAchatJalCollection200Response
     */
    'hydra:member': Array<FactureAchatJalJsonldFactureAchatJalRead>;
    /**
     * 
     * @type {number}
     * @memberof GetFacturesAchatsJalsFactureAchatJalCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {GetActionCollection200ResponseHydraView}
     * @memberof GetFacturesAchatsJalsFactureAchatJalCollection200Response
     */
    'hydra:view'?: GetActionCollection200ResponseHydraView;
    /**
     * 
     * @type {GetActionCollection200ResponseHydraSearch}
     * @memberof GetFacturesAchatsJalsFactureAchatJalCollection200Response
     */
    'hydra:search'?: GetActionCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface GetLignesFactureAchatFactureAchatLigneCollection200Response
 */
export interface GetLignesFactureAchatFactureAchatLigneCollection200Response {
    /**
     * 
     * @type {Array<FactureAchatLigneJsonldFactureAchatRead>}
     * @memberof GetLignesFactureAchatFactureAchatLigneCollection200Response
     */
    'hydra:member': Array<FactureAchatLigneJsonldFactureAchatRead>;
    /**
     * 
     * @type {number}
     * @memberof GetLignesFactureAchatFactureAchatLigneCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {GetActionCollection200ResponseHydraView}
     * @memberof GetLignesFactureAchatFactureAchatLigneCollection200Response
     */
    'hydra:view'?: GetActionCollection200ResponseHydraView;
    /**
     * 
     * @type {GetActionCollection200ResponseHydraSearch}
     * @memberof GetLignesFactureAchatFactureAchatLigneCollection200Response
     */
    'hydra:search'?: GetActionCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface GetReleveAchatSumReleveAchatItem200Response
 */
export interface GetReleveAchatSumReleveAchatItem200Response {
    /**
     * 
     * @type {number}
     * @memberof GetReleveAchatSumReleveAchatItem200Response
     */
    'totalTtc'?: number;
}
/**
 * 
 * @export
 * @interface GetRelevesAchatsReleveAchatCollection200Response
 */
export interface GetRelevesAchatsReleveAchatCollection200Response {
    /**
     * 
     * @type {Array<ReleveAchatJsonldReleveAchatReadReleveAchatReadCalculatedItemsTimestampableBlameambleGroup>}
     * @memberof GetRelevesAchatsReleveAchatCollection200Response
     */
    'hydra:member': Array<ReleveAchatJsonldReleveAchatReadReleveAchatReadCalculatedItemsTimestampableBlameambleGroup>;
    /**
     * 
     * @type {number}
     * @memberof GetRelevesAchatsReleveAchatCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {GetActionCollection200ResponseHydraView}
     * @memberof GetRelevesAchatsReleveAchatCollection200Response
     */
    'hydra:view'?: GetActionCollection200ResponseHydraView;
    /**
     * 
     * @type {GetActionCollection200ResponseHydraSearch}
     * @memberof GetRelevesAchatsReleveAchatCollection200Response
     */
    'hydra:search'?: GetActionCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface GetWorkflowMailsWorkflowMailCollection200Response
 */
export interface GetWorkflowMailsWorkflowMailCollection200Response {
    /**
     * 
     * @type {Array<WorkflowMailJsonldWorkflowMailSend>}
     * @memberof GetWorkflowMailsWorkflowMailCollection200Response
     */
    'hydra:member': Array<WorkflowMailJsonldWorkflowMailSend>;
    /**
     * 
     * @type {number}
     * @memberof GetWorkflowMailsWorkflowMailCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {GetActionCollection200ResponseHydraView}
     * @memberof GetWorkflowMailsWorkflowMailCollection200Response
     */
    'hydra:view'?: GetActionCollection200ResponseHydraView;
    /**
     * 
     * @type {GetActionCollection200ResponseHydraSearch}
     * @memberof GetWorkflowMailsWorkflowMailCollection200Response
     */
    'hydra:search'?: GetActionCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ListMediaObjectMediaObjectCollection200Response
 */
export interface ListMediaObjectMediaObjectCollection200Response {
    /**
     * 
     * @type {Array<MediaObjectJsonldMediaObjectReadTimestampableBlameambleGroupTaggableGroup>}
     * @memberof ListMediaObjectMediaObjectCollection200Response
     */
    'hydra:member': Array<MediaObjectJsonldMediaObjectReadTimestampableBlameambleGroupTaggableGroup>;
    /**
     * 
     * @type {number}
     * @memberof ListMediaObjectMediaObjectCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {GetActionCollection200ResponseHydraView}
     * @memberof ListMediaObjectMediaObjectCollection200Response
     */
    'hydra:view'?: GetActionCollection200ResponseHydraView;
    /**
     * 
     * @type {GetActionCollection200ResponseHydraSearch}
     * @memberof ListMediaObjectMediaObjectCollection200Response
     */
    'hydra:search'?: GetActionCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ListTypeMailWorkflowMailCollection200Response
 */
export interface ListTypeMailWorkflowMailCollection200Response {
    /**
     * 
     * @type {Array<ListTypeMailWorkflowMailCollection200ResponseHydraMemberInner>}
     * @memberof ListTypeMailWorkflowMailCollection200Response
     */
    'hydra:member'?: Array<ListTypeMailWorkflowMailCollection200ResponseHydraMemberInner>;
    /**
     * 
     * @type {number}
     * @memberof ListTypeMailWorkflowMailCollection200Response
     */
    'hydra:totalItems'?: number;
}
/**
 * 
 * @export
 * @interface ListTypeMailWorkflowMailCollection200ResponseHydraMemberInner
 */
export interface ListTypeMailWorkflowMailCollection200ResponseHydraMemberInner {
    /**
     * 
     * @type {string}
     * @memberof ListTypeMailWorkflowMailCollection200ResponseHydraMemberInner
     */
    'name'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ListTypeMailWorkflowMailCollection200ResponseHydraMemberInner
     */
    'tos'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ListTypeMailWorkflowMailCollection200ResponseHydraMemberInner
     */
    'ccs'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ListTypeMailWorkflowMailCollection200ResponseHydraMemberInner
     */
    'bccs'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ListTypeMailWorkflowMailCollection200ResponseHydraMemberInner
     */
    'replies'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ListTypeMailWorkflowMailCollection200ResponseHydraMemberInner
     */
    'tags'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ListTypeMailWorkflowMailCollection200ResponseHydraMemberInner
     */
    'subject'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListTypeMailWorkflowMailCollection200ResponseHydraMemberInner
     */
    'body'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ListTypeMailWorkflowMailCollection200ResponseHydraMemberInner
     */
    'attachments'?: Array<string>;
}
/**
 * 
 * @export
 * @interface MailAttachmentJsonldWorkflowMailRead
 */
export interface MailAttachmentJsonldWorkflowMailRead {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof MailAttachmentJsonldWorkflowMailRead
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof MailAttachmentJsonldWorkflowMailRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MailAttachmentJsonldWorkflowMailRead
     */
    '@type'?: string;
}
/**
 * 
 * @export
 * @interface MediaObject
 */
export interface MediaObject {
    /**
     * 
     * @type {File}
     * @memberof MediaObject
     */
    'file'?: File | null;
    /**
     * 
     * @type {string}
     * @memberof MediaObject
     */
    'contentUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MediaObject
     */
    'urlExpires'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MediaObject
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof MediaObject
     */
    'size'?: number;
    /**
     * 
     * @type {string}
     * @memberof MediaObject
     */
    'originalName'?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaObject
     */
    'mimeType'?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaObject
     */
    'dateDebutTraitement'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MediaObject
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MediaObject
     */
    'idSociete'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof MediaObject
     */
    'forceInterne'?: boolean;
    /**
     * 
     * @type {Array<MediaObjectTag>}
     * @memberof MediaObject
     */
    'mediaObjectTags'?: Array<MediaObjectTag>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MediaObject
     */
    'notes'?: Array<string>;
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof MediaObject
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof MediaObject
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof MediaObject
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof MediaObject
     */
    'updatedBy'?: string | null;
    /**
     * Tags du pdf
     * @type {Array<FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroupTagsInner>}
     * @memberof MediaObject
     */
    'tags'?: Array<FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroupTagsInner> | null;
}
/**
 * 
 * @export
 * @interface MediaObjectDateDebutTraitementTaggableGroup
 */
export interface MediaObjectDateDebutTraitementTaggableGroup {
    /**
     * 
     * @type {string}
     * @memberof MediaObjectDateDebutTraitementTaggableGroup
     */
    'dateDebutTraitement'?: string | null;
    /**
     * 
     * @type {Array<object>}
     * @memberof MediaObjectDateDebutTraitementTaggableGroup
     */
    'mediaObjectTags'?: Array<object>;
    /**
     * Tags du pdf
     * @type {Array<FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroupTagsInner>}
     * @memberof MediaObjectDateDebutTraitementTaggableGroup
     */
    'tags'?: Array<FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroupTagsInner> | null;
}
/**
 * 
 * @export
 * @interface MediaObjectFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
 */
export interface MediaObjectFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup {
    /**
     * 
     * @type {number}
     * @memberof MediaObjectFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'id'?: number;
    /**
     * 
     * @type {File}
     * @memberof MediaObjectFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'file'?: File | null;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'contentUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'urlExpires'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof MediaObjectFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'size'?: number;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'originalName'?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'mimeType'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MediaObjectFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {Array<object>}
     * @memberof MediaObjectFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'mediaObjectTags'?: Array<object>;
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof MediaObjectFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof MediaObjectFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof MediaObjectFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof MediaObjectFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'updatedBy'?: string | null;
    /**
     * Tags du pdf
     * @type {Array<FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroupTagsInner>}
     * @memberof MediaObjectFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'tags'?: Array<FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroupTagsInner> | null;
}
/**
 * 
 * @export
 * @interface MediaObjectFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
 */
export interface MediaObjectFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup {
    /**
     * 
     * @type {number}
     * @memberof MediaObjectFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'id'?: number;
    /**
     * 
     * @type {File}
     * @memberof MediaObjectFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'file'?: File | null;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'contentUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'urlExpires'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof MediaObjectFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'size'?: number;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'originalName'?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'mimeType'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MediaObjectFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'deleted'?: boolean;
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof MediaObjectFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof MediaObjectFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof MediaObjectFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof MediaObjectFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface MediaObjectFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
 */
export interface MediaObjectFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup {
    /**
     * 
     * @type {number}
     * @memberof MediaObjectFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'id'?: number;
    /**
     * 
     * @type {File}
     * @memberof MediaObjectFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'file'?: File | null;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'contentUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'urlExpires'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof MediaObjectFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'size'?: number;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'originalName'?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'mimeType'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MediaObjectFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {Array<object>}
     * @memberof MediaObjectFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'mediaObjectTags'?: Array<object>;
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof MediaObjectFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof MediaObjectFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof MediaObjectFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof MediaObjectFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'updatedBy'?: string | null;
    /**
     * Tags du pdf
     * @type {Array<FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroupTagsInner>}
     * @memberof MediaObjectFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'tags'?: Array<FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroupTagsInner> | null;
}
/**
 * 
 * @export
 * @interface MediaObjectJsonldDateDebutTraitementTaggableGroup
 */
export interface MediaObjectJsonldDateDebutTraitementTaggableGroup {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof MediaObjectJsonldDateDebutTraitementTaggableGroup
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectJsonldDateDebutTraitementTaggableGroup
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectJsonldDateDebutTraitementTaggableGroup
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectJsonldDateDebutTraitementTaggableGroup
     */
    'dateDebutTraitement'?: string | null;
    /**
     * 
     * @type {Array<MediaObjectTagJsonldDateDebutTraitementTaggableGroup>}
     * @memberof MediaObjectJsonldDateDebutTraitementTaggableGroup
     */
    'mediaObjectTags'?: Array<MediaObjectTagJsonldDateDebutTraitementTaggableGroup>;
    /**
     * Tags du pdf
     * @type {Array<FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroupTagsInner>}
     * @memberof MediaObjectJsonldDateDebutTraitementTaggableGroup
     */
    'tags'?: Array<FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroupTagsInner> | null;
}
/**
 * 
 * @export
 * @interface MediaObjectJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
 */
export interface MediaObjectJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof MediaObjectJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof MediaObjectJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'id'?: number;
    /**
     * 
     * @type {File}
     * @memberof MediaObjectJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'file'?: File | null;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'contentUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'urlExpires'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof MediaObjectJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'size'?: number;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'originalName'?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'mimeType'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MediaObjectJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {Array<MediaObjectTagJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup>}
     * @memberof MediaObjectJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'mediaObjectTags'?: Array<MediaObjectTagJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup>;
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof MediaObjectJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof MediaObjectJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof MediaObjectJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof MediaObjectJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'updatedBy'?: string | null;
    /**
     * Tags du pdf
     * @type {Array<FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroupTagsInner>}
     * @memberof MediaObjectJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'tags'?: Array<FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroupTagsInner> | null;
}
/**
 * 
 * @export
 * @interface MediaObjectJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
 */
export interface MediaObjectJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof MediaObjectJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof MediaObjectJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'id'?: number;
    /**
     * 
     * @type {File}
     * @memberof MediaObjectJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'file'?: File | null;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'contentUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'urlExpires'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof MediaObjectJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'size'?: number;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'originalName'?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'mimeType'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MediaObjectJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'deleted'?: boolean;
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof MediaObjectJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof MediaObjectJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof MediaObjectJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof MediaObjectJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface MediaObjectJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
 */
export interface MediaObjectJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof MediaObjectJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof MediaObjectJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'id'?: number;
    /**
     * 
     * @type {File}
     * @memberof MediaObjectJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'file'?: File | null;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'contentUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'urlExpires'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof MediaObjectJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'size'?: number;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'originalName'?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'mimeType'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MediaObjectJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {Array<MediaObjectTagJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup>}
     * @memberof MediaObjectJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'mediaObjectTags'?: Array<MediaObjectTagJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup>;
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof MediaObjectJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof MediaObjectJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof MediaObjectJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof MediaObjectJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'updatedBy'?: string | null;
    /**
     * Tags du pdf
     * @type {Array<FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroupTagsInner>}
     * @memberof MediaObjectJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    'tags'?: Array<FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroupTagsInner> | null;
}
/**
 * 
 * @export
 * @interface MediaObjectJsonldMediaObjectReadTimestampableBlameambleGroupTaggableGroup
 */
export interface MediaObjectJsonldMediaObjectReadTimestampableBlameambleGroupTaggableGroup {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof MediaObjectJsonldMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectJsonldMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectJsonldMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof MediaObjectJsonldMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectJsonldMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    'contentUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectJsonldMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    'urlExpires'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectJsonldMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof MediaObjectJsonldMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    'size'?: number;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectJsonldMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    'originalName'?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectJsonldMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    'mimeType'?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectJsonldMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    'dateDebutTraitement'?: string | null;
    /**
     * 
     * @type {Array<FactureAchatJsonldMediaObjectReadTimestampableBlameambleGroupTaggableGroup>}
     * @memberof MediaObjectJsonldMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    'facturesAchat'?: Array<FactureAchatJsonldMediaObjectReadTimestampableBlameambleGroupTaggableGroup>;
    /**
     * 
     * @type {boolean}
     * @memberof MediaObjectJsonldMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MediaObjectJsonldMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    'idSociete'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof MediaObjectJsonldMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    'forceInterne'?: boolean;
    /**
     * 
     * @type {Array<MediaObjectTagJsonldMediaObjectReadTimestampableBlameambleGroupTaggableGroup>}
     * @memberof MediaObjectJsonldMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    'mediaObjectTags'?: Array<MediaObjectTagJsonldMediaObjectReadTimestampableBlameambleGroupTaggableGroup>;
    /**
     * 
     * @type {Array<MediaObjectNoteJsonldMediaObjectReadTimestampableBlameambleGroupTaggableGroup>}
     * @memberof MediaObjectJsonldMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    'notes'?: Array<MediaObjectNoteJsonldMediaObjectReadTimestampableBlameambleGroupTaggableGroup>;
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof MediaObjectJsonldMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof MediaObjectJsonldMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof MediaObjectJsonldMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof MediaObjectJsonldMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    'updatedBy'?: string | null;
    /**
     * Tags du pdf
     * @type {Array<FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroupTagsInner>}
     * @memberof MediaObjectJsonldMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    'tags'?: Array<FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroupTagsInner> | null;
}
/**
 * 
 * @export
 * @interface MediaObjectJsonldReleveAchatReadReleveAchatReadCalculatedItemsTimestampableBlameambleGroup
 */
export interface MediaObjectJsonldReleveAchatReadReleveAchatReadCalculatedItemsTimestampableBlameambleGroup {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof MediaObjectJsonldReleveAchatReadReleveAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectJsonldReleveAchatReadReleveAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectJsonldReleveAchatReadReleveAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    '@type'?: string;
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof MediaObjectJsonldReleveAchatReadReleveAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof MediaObjectJsonldReleveAchatReadReleveAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof MediaObjectJsonldReleveAchatReadReleveAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof MediaObjectJsonldReleveAchatReadReleveAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface MediaObjectJsonldReleveAchatReadReleveAchatReadItemTimestampableBlameambleGroup
 */
export interface MediaObjectJsonldReleveAchatReadReleveAchatReadItemTimestampableBlameambleGroup {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof MediaObjectJsonldReleveAchatReadReleveAchatReadItemTimestampableBlameambleGroup
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectJsonldReleveAchatReadReleveAchatReadItemTimestampableBlameambleGroup
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectJsonldReleveAchatReadReleveAchatReadItemTimestampableBlameambleGroup
     */
    '@type'?: string;
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof MediaObjectJsonldReleveAchatReadReleveAchatReadItemTimestampableBlameambleGroup
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof MediaObjectJsonldReleveAchatReadReleveAchatReadItemTimestampableBlameambleGroup
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof MediaObjectJsonldReleveAchatReadReleveAchatReadItemTimestampableBlameambleGroup
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof MediaObjectJsonldReleveAchatReadReleveAchatReadItemTimestampableBlameambleGroup
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface MediaObjectJsonldReleveAchatReadTimestampableBlameambleGroup
 */
export interface MediaObjectJsonldReleveAchatReadTimestampableBlameambleGroup {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof MediaObjectJsonldReleveAchatReadTimestampableBlameambleGroup
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectJsonldReleveAchatReadTimestampableBlameambleGroup
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectJsonldReleveAchatReadTimestampableBlameambleGroup
     */
    '@type'?: string;
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof MediaObjectJsonldReleveAchatReadTimestampableBlameambleGroup
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof MediaObjectJsonldReleveAchatReadTimestampableBlameambleGroup
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof MediaObjectJsonldReleveAchatReadTimestampableBlameambleGroup
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof MediaObjectJsonldReleveAchatReadTimestampableBlameambleGroup
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface MediaObjectMediaObjectReadTimestampableBlameambleGroupTaggableGroup
 */
export interface MediaObjectMediaObjectReadTimestampableBlameambleGroupTaggableGroup {
    /**
     * 
     * @type {number}
     * @memberof MediaObjectMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    'contentUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    'urlExpires'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof MediaObjectMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    'size'?: number;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    'originalName'?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    'mimeType'?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    'dateDebutTraitement'?: string | null;
    /**
     * 
     * @type {Array<FactureAchatMediaObjectReadTimestampableBlameambleGroupTaggableGroup>}
     * @memberof MediaObjectMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    'facturesAchat'?: Array<FactureAchatMediaObjectReadTimestampableBlameambleGroupTaggableGroup>;
    /**
     * 
     * @type {boolean}
     * @memberof MediaObjectMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MediaObjectMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    'idSociete'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof MediaObjectMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    'forceInterne'?: boolean;
    /**
     * 
     * @type {Array<MediaObjectTagMediaObjectReadTimestampableBlameambleGroupTaggableGroup>}
     * @memberof MediaObjectMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    'mediaObjectTags'?: Array<MediaObjectTagMediaObjectReadTimestampableBlameambleGroupTaggableGroup>;
    /**
     * 
     * @type {Array<MediaObjectNoteMediaObjectReadTimestampableBlameambleGroupTaggableGroup>}
     * @memberof MediaObjectMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    'notes'?: Array<MediaObjectNoteMediaObjectReadTimestampableBlameambleGroupTaggableGroup>;
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof MediaObjectMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof MediaObjectMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof MediaObjectMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof MediaObjectMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    'updatedBy'?: string | null;
    /**
     * Tags du pdf
     * @type {Array<FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroupTagsInner>}
     * @memberof MediaObjectMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    'tags'?: Array<FactureAchatFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroupTagsInner> | null;
}
/**
 * 
 * @export
 * @interface MediaObjectNoteJsonldMediaObjectNoteCreate
 */
export interface MediaObjectNoteJsonldMediaObjectNoteCreate {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof MediaObjectNoteJsonldMediaObjectNoteCreate
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectNoteJsonldMediaObjectNoteCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectNoteJsonldMediaObjectNoteCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof MediaObjectNoteJsonldMediaObjectNoteCreate
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectNoteJsonldMediaObjectNoteCreate
     */
    'mediaObject'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectNoteJsonldMediaObjectNoteCreate
     */
    'note': string;
}
/**
 * 
 * @export
 * @interface MediaObjectNoteJsonldMediaObjectNoteWrite
 */
export interface MediaObjectNoteJsonldMediaObjectNoteWrite {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof MediaObjectNoteJsonldMediaObjectNoteWrite
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectNoteJsonldMediaObjectNoteWrite
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectNoteJsonldMediaObjectNoteWrite
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof MediaObjectNoteJsonldMediaObjectNoteWrite
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectNoteJsonldMediaObjectNoteWrite
     */
    'mediaObject'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectNoteJsonldMediaObjectNoteWrite
     */
    'note': string;
}
/**
 * 
 * @export
 * @interface MediaObjectNoteJsonldMediaObjectRead
 */
export interface MediaObjectNoteJsonldMediaObjectRead {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof MediaObjectNoteJsonldMediaObjectRead
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectNoteJsonldMediaObjectRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectNoteJsonldMediaObjectRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof MediaObjectNoteJsonldMediaObjectRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectNoteJsonldMediaObjectRead
     */
    'note'?: string;
}
/**
 * 
 * @export
 * @interface MediaObjectNoteJsonldMediaObjectReadTimestampableBlameambleGroupTaggableGroup
 */
export interface MediaObjectNoteJsonldMediaObjectReadTimestampableBlameambleGroupTaggableGroup {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof MediaObjectNoteJsonldMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectNoteJsonldMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectNoteJsonldMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof MediaObjectNoteJsonldMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectNoteJsonldMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    'note'?: string;
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof MediaObjectNoteJsonldMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof MediaObjectNoteJsonldMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof MediaObjectNoteJsonldMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof MediaObjectNoteJsonldMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface MediaObjectNoteMediaObjectNoteCreate
 */
export interface MediaObjectNoteMediaObjectNoteCreate {
    /**
     * 
     * @type {number}
     * @memberof MediaObjectNoteMediaObjectNoteCreate
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectNoteMediaObjectNoteCreate
     */
    'mediaObject'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectNoteMediaObjectNoteCreate
     */
    'note': string;
}
/**
 * 
 * @export
 * @interface MediaObjectNoteMediaObjectNoteWrite
 */
export interface MediaObjectNoteMediaObjectNoteWrite {
    /**
     * 
     * @type {number}
     * @memberof MediaObjectNoteMediaObjectNoteWrite
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectNoteMediaObjectNoteWrite
     */
    'mediaObject'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectNoteMediaObjectNoteWrite
     */
    'note': string;
}
/**
 * 
 * @export
 * @interface MediaObjectNoteMediaObjectRead
 */
export interface MediaObjectNoteMediaObjectRead {
    /**
     * 
     * @type {number}
     * @memberof MediaObjectNoteMediaObjectRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectNoteMediaObjectRead
     */
    'note'?: string;
}
/**
 * 
 * @export
 * @interface MediaObjectNoteMediaObjectReadTimestampableBlameambleGroupTaggableGroup
 */
export interface MediaObjectNoteMediaObjectReadTimestampableBlameambleGroupTaggableGroup {
    /**
     * 
     * @type {number}
     * @memberof MediaObjectNoteMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectNoteMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    'note'?: string;
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof MediaObjectNoteMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof MediaObjectNoteMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof MediaObjectNoteMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof MediaObjectNoteMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface MediaObjectReleveAchatReadReleveAchatReadCalculatedItemsTimestampableBlameambleGroup
 */
export interface MediaObjectReleveAchatReadReleveAchatReadCalculatedItemsTimestampableBlameambleGroup {
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof MediaObjectReleveAchatReadReleveAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof MediaObjectReleveAchatReadReleveAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof MediaObjectReleveAchatReadReleveAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof MediaObjectReleveAchatReadReleveAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface MediaObjectReleveAchatReadReleveAchatReadItemTimestampableBlameambleGroup
 */
export interface MediaObjectReleveAchatReadReleveAchatReadItemTimestampableBlameambleGroup {
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof MediaObjectReleveAchatReadReleveAchatReadItemTimestampableBlameambleGroup
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof MediaObjectReleveAchatReadReleveAchatReadItemTimestampableBlameambleGroup
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof MediaObjectReleveAchatReadReleveAchatReadItemTimestampableBlameambleGroup
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof MediaObjectReleveAchatReadReleveAchatReadItemTimestampableBlameambleGroup
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface MediaObjectReleveAchatReadTimestampableBlameambleGroup
 */
export interface MediaObjectReleveAchatReadTimestampableBlameambleGroup {
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof MediaObjectReleveAchatReadTimestampableBlameambleGroup
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof MediaObjectReleveAchatReadTimestampableBlameambleGroup
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof MediaObjectReleveAchatReadTimestampableBlameambleGroup
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof MediaObjectReleveAchatReadTimestampableBlameambleGroup
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface MediaObjectTag
 */
export interface MediaObjectTag {
    /**
     * 
     * @type {number}
     * @memberof MediaObjectTag
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectTag
     */
    'mediaObject'?: string | null;
    /**
     * 
     * @type {MediaObjectTagTag}
     * @memberof MediaObjectTag
     */
    'tag'?: MediaObjectTagTag | null;
}
/**
 * 
 * @export
 * @interface MediaObjectTagJsonldDateDebutTraitementTaggableGroup
 */
export interface MediaObjectTagJsonldDateDebutTraitementTaggableGroup {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof MediaObjectTagJsonldDateDebutTraitementTaggableGroup
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectTagJsonldDateDebutTraitementTaggableGroup
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectTagJsonldDateDebutTraitementTaggableGroup
     */
    '@type'?: string;
}
/**
 * 
 * @export
 * @interface MediaObjectTagJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
 */
export interface MediaObjectTagJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof MediaObjectTagJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectTagJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectTagJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    '@type'?: string;
}
/**
 * 
 * @export
 * @interface MediaObjectTagJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
 */
export interface MediaObjectTagJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof MediaObjectTagJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectTagJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectTagJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    '@type'?: string;
}
/**
 * 
 * @export
 * @interface MediaObjectTagJsonldMediaObjectReadTimestampableBlameambleGroupTaggableGroup
 */
export interface MediaObjectTagJsonldMediaObjectReadTimestampableBlameambleGroupTaggableGroup {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof MediaObjectTagJsonldMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectTagJsonldMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectTagJsonldMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof MediaObjectTagJsonldMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface MediaObjectTagMediaObjectReadTimestampableBlameambleGroupTaggableGroup
 */
export interface MediaObjectTagMediaObjectReadTimestampableBlameambleGroupTaggableGroup {
    /**
     * 
     * @type {number}
     * @memberof MediaObjectTagMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface MediaObjectTagTag
 */
export interface MediaObjectTagTag {
    /**
     * 
     * @type {number}
     * @memberof MediaObjectTagTag
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectTagTag
     */
    'label'?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectTagTag
     */
    'value'?: string;
}
/**
 * 
 * @export
 * @interface ReleveAchatJsonldReleveAchatCreateReleveAchatWrite
 */
export interface ReleveAchatJsonldReleveAchatCreateReleveAchatWrite {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof ReleveAchatJsonldReleveAchatCreateReleveAchatWrite
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof ReleveAchatJsonldReleveAchatCreateReleveAchatWrite
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReleveAchatJsonldReleveAchatCreateReleveAchatWrite
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReleveAchatJsonldReleveAchatCreateReleveAchatWrite
     */
    'pdfFacture'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ReleveAchatJsonldReleveAchatCreateReleveAchatWrite
     */
    'cloture'?: boolean;
}
/**
 * 
 * @export
 * @interface ReleveAchatJsonldReleveAchatReadCalculatedItems
 */
export interface ReleveAchatJsonldReleveAchatReadCalculatedItems {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof ReleveAchatJsonldReleveAchatReadCalculatedItems
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof ReleveAchatJsonldReleveAchatReadCalculatedItems
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReleveAchatJsonldReleveAchatReadCalculatedItems
     */
    '@type'?: string;
}
/**
 * 
 * @export
 * @interface ReleveAchatJsonldReleveAchatReadReleveAchatReadCalculatedItemsTimestampableBlameambleGroup
 */
export interface ReleveAchatJsonldReleveAchatReadReleveAchatReadCalculatedItemsTimestampableBlameambleGroup {
    /**
     * 
     * @type {string}
     * @memberof ReleveAchatJsonldReleveAchatReadReleveAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReleveAchatJsonldReleveAchatReadReleveAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReleveAchatJsonldReleveAchatReadReleveAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'id'?: number;
    /**
     * 
     * @type {ReleveAchatJsonldReleveAchatReadReleveAchatReadCalculatedItemsTimestampableBlameambleGroupPdfFacture}
     * @memberof ReleveAchatJsonldReleveAchatReadReleveAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'pdfFacture'?: ReleveAchatJsonldReleveAchatReadReleveAchatReadCalculatedItemsTimestampableBlameambleGroupPdfFacture | null;
    /**
     * 
     * @type {boolean}
     * @memberof ReleveAchatJsonldReleveAchatReadReleveAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'cloture'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReleveAchatJsonldReleveAchatReadReleveAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'deleted'?: boolean;
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof ReleveAchatJsonldReleveAchatReadReleveAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof ReleveAchatJsonldReleveAchatReadReleveAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof ReleveAchatJsonldReleveAchatReadReleveAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof ReleveAchatJsonldReleveAchatReadReleveAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface ReleveAchatJsonldReleveAchatReadReleveAchatReadCalculatedItemsTimestampableBlameambleGroupPdfFacture
 */
export interface ReleveAchatJsonldReleveAchatReadReleveAchatReadCalculatedItemsTimestampableBlameambleGroupPdfFacture {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof ReleveAchatJsonldReleveAchatReadReleveAchatReadCalculatedItemsTimestampableBlameambleGroupPdfFacture
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof ReleveAchatJsonldReleveAchatReadReleveAchatReadCalculatedItemsTimestampableBlameambleGroupPdfFacture
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReleveAchatJsonldReleveAchatReadReleveAchatReadCalculatedItemsTimestampableBlameambleGroupPdfFacture
     */
    '@type'?: string;
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof ReleveAchatJsonldReleveAchatReadReleveAchatReadCalculatedItemsTimestampableBlameambleGroupPdfFacture
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof ReleveAchatJsonldReleveAchatReadReleveAchatReadCalculatedItemsTimestampableBlameambleGroupPdfFacture
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof ReleveAchatJsonldReleveAchatReadReleveAchatReadCalculatedItemsTimestampableBlameambleGroupPdfFacture
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof ReleveAchatJsonldReleveAchatReadReleveAchatReadCalculatedItemsTimestampableBlameambleGroupPdfFacture
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface ReleveAchatJsonldReleveAchatReadReleveAchatReadItemTimestampableBlameambleGroup
 */
export interface ReleveAchatJsonldReleveAchatReadReleveAchatReadItemTimestampableBlameambleGroup {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof ReleveAchatJsonldReleveAchatReadReleveAchatReadItemTimestampableBlameambleGroup
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof ReleveAchatJsonldReleveAchatReadReleveAchatReadItemTimestampableBlameambleGroup
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReleveAchatJsonldReleveAchatReadReleveAchatReadItemTimestampableBlameambleGroup
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReleveAchatJsonldReleveAchatReadReleveAchatReadItemTimestampableBlameambleGroup
     */
    'id'?: number;
    /**
     * 
     * @type {ReleveAchatJsonldReleveAchatReadReleveAchatReadItemTimestampableBlameambleGroupPdfFacture}
     * @memberof ReleveAchatJsonldReleveAchatReadReleveAchatReadItemTimestampableBlameambleGroup
     */
    'pdfFacture'?: ReleveAchatJsonldReleveAchatReadReleveAchatReadItemTimestampableBlameambleGroupPdfFacture | null;
    /**
     * 
     * @type {boolean}
     * @memberof ReleveAchatJsonldReleveAchatReadReleveAchatReadItemTimestampableBlameambleGroup
     */
    'cloture'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReleveAchatJsonldReleveAchatReadReleveAchatReadItemTimestampableBlameambleGroup
     */
    'deleted'?: boolean;
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof ReleveAchatJsonldReleveAchatReadReleveAchatReadItemTimestampableBlameambleGroup
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof ReleveAchatJsonldReleveAchatReadReleveAchatReadItemTimestampableBlameambleGroup
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof ReleveAchatJsonldReleveAchatReadReleveAchatReadItemTimestampableBlameambleGroup
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof ReleveAchatJsonldReleveAchatReadReleveAchatReadItemTimestampableBlameambleGroup
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface ReleveAchatJsonldReleveAchatReadReleveAchatReadItemTimestampableBlameambleGroupPdfFacture
 */
export interface ReleveAchatJsonldReleveAchatReadReleveAchatReadItemTimestampableBlameambleGroupPdfFacture {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof ReleveAchatJsonldReleveAchatReadReleveAchatReadItemTimestampableBlameambleGroupPdfFacture
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof ReleveAchatJsonldReleveAchatReadReleveAchatReadItemTimestampableBlameambleGroupPdfFacture
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReleveAchatJsonldReleveAchatReadReleveAchatReadItemTimestampableBlameambleGroupPdfFacture
     */
    '@type'?: string;
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof ReleveAchatJsonldReleveAchatReadReleveAchatReadItemTimestampableBlameambleGroupPdfFacture
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof ReleveAchatJsonldReleveAchatReadReleveAchatReadItemTimestampableBlameambleGroupPdfFacture
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof ReleveAchatJsonldReleveAchatReadReleveAchatReadItemTimestampableBlameambleGroupPdfFacture
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof ReleveAchatJsonldReleveAchatReadReleveAchatReadItemTimestampableBlameambleGroupPdfFacture
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface ReleveAchatJsonldReleveAchatReadTimestampableBlameambleGroup
 */
export interface ReleveAchatJsonldReleveAchatReadTimestampableBlameambleGroup {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof ReleveAchatJsonldReleveAchatReadTimestampableBlameambleGroup
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof ReleveAchatJsonldReleveAchatReadTimestampableBlameambleGroup
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReleveAchatJsonldReleveAchatReadTimestampableBlameambleGroup
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReleveAchatJsonldReleveAchatReadTimestampableBlameambleGroup
     */
    'id'?: number;
    /**
     * 
     * @type {ReleveAchatJsonldReleveAchatReadTimestampableBlameambleGroupPdfFacture}
     * @memberof ReleveAchatJsonldReleveAchatReadTimestampableBlameambleGroup
     */
    'pdfFacture'?: ReleveAchatJsonldReleveAchatReadTimestampableBlameambleGroupPdfFacture | null;
    /**
     * 
     * @type {boolean}
     * @memberof ReleveAchatJsonldReleveAchatReadTimestampableBlameambleGroup
     */
    'cloture'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReleveAchatJsonldReleveAchatReadTimestampableBlameambleGroup
     */
    'deleted'?: boolean;
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof ReleveAchatJsonldReleveAchatReadTimestampableBlameambleGroup
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof ReleveAchatJsonldReleveAchatReadTimestampableBlameambleGroup
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof ReleveAchatJsonldReleveAchatReadTimestampableBlameambleGroup
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof ReleveAchatJsonldReleveAchatReadTimestampableBlameambleGroup
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface ReleveAchatJsonldReleveAchatReadTimestampableBlameambleGroupPdfFacture
 */
export interface ReleveAchatJsonldReleveAchatReadTimestampableBlameambleGroupPdfFacture {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof ReleveAchatJsonldReleveAchatReadTimestampableBlameambleGroupPdfFacture
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof ReleveAchatJsonldReleveAchatReadTimestampableBlameambleGroupPdfFacture
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReleveAchatJsonldReleveAchatReadTimestampableBlameambleGroupPdfFacture
     */
    '@type'?: string;
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof ReleveAchatJsonldReleveAchatReadTimestampableBlameambleGroupPdfFacture
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof ReleveAchatJsonldReleveAchatReadTimestampableBlameambleGroupPdfFacture
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof ReleveAchatJsonldReleveAchatReadTimestampableBlameambleGroupPdfFacture
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof ReleveAchatJsonldReleveAchatReadTimestampableBlameambleGroupPdfFacture
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface ReleveAchatJsonldReleveAchatWrite
 */
export interface ReleveAchatJsonldReleveAchatWrite {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof ReleveAchatJsonldReleveAchatWrite
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof ReleveAchatJsonldReleveAchatWrite
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReleveAchatJsonldReleveAchatWrite
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReleveAchatJsonldReleveAchatWrite
     */
    'pdfFacture'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ReleveAchatJsonldReleveAchatWrite
     */
    'cloture'?: boolean;
}
/**
 * 
 * @export
 * @interface ReleveAchatReleveAchatCreateReleveAchatWrite
 */
export interface ReleveAchatReleveAchatCreateReleveAchatWrite {
    /**
     * 
     * @type {string}
     * @memberof ReleveAchatReleveAchatCreateReleveAchatWrite
     */
    'pdfFacture'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ReleveAchatReleveAchatCreateReleveAchatWrite
     */
    'cloture'?: boolean;
}
/**
 * 
 * @export
 * @interface ReleveAchatReleveAchatReadReleveAchatReadCalculatedItemsTimestampableBlameambleGroup
 */
export interface ReleveAchatReleveAchatReadReleveAchatReadCalculatedItemsTimestampableBlameambleGroup {
    /**
     * 
     * @type {number}
     * @memberof ReleveAchatReleveAchatReadReleveAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'id'?: number;
    /**
     * 
     * @type {ReleveAchatReleveAchatReadReleveAchatReadCalculatedItemsTimestampableBlameambleGroupPdfFacture}
     * @memberof ReleveAchatReleveAchatReadReleveAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'pdfFacture'?: ReleveAchatReleveAchatReadReleveAchatReadCalculatedItemsTimestampableBlameambleGroupPdfFacture | null;
    /**
     * 
     * @type {boolean}
     * @memberof ReleveAchatReleveAchatReadReleveAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'cloture'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReleveAchatReleveAchatReadReleveAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'deleted'?: boolean;
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof ReleveAchatReleveAchatReadReleveAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof ReleveAchatReleveAchatReadReleveAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof ReleveAchatReleveAchatReadReleveAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof ReleveAchatReleveAchatReadReleveAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface ReleveAchatReleveAchatReadReleveAchatReadCalculatedItemsTimestampableBlameambleGroupPdfFacture
 */
export interface ReleveAchatReleveAchatReadReleveAchatReadCalculatedItemsTimestampableBlameambleGroupPdfFacture {
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof ReleveAchatReleveAchatReadReleveAchatReadCalculatedItemsTimestampableBlameambleGroupPdfFacture
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof ReleveAchatReleveAchatReadReleveAchatReadCalculatedItemsTimestampableBlameambleGroupPdfFacture
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof ReleveAchatReleveAchatReadReleveAchatReadCalculatedItemsTimestampableBlameambleGroupPdfFacture
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof ReleveAchatReleveAchatReadReleveAchatReadCalculatedItemsTimestampableBlameambleGroupPdfFacture
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface ReleveAchatReleveAchatReadReleveAchatReadItemTimestampableBlameambleGroup
 */
export interface ReleveAchatReleveAchatReadReleveAchatReadItemTimestampableBlameambleGroup {
    /**
     * 
     * @type {number}
     * @memberof ReleveAchatReleveAchatReadReleveAchatReadItemTimestampableBlameambleGroup
     */
    'id'?: number;
    /**
     * 
     * @type {ReleveAchatReleveAchatReadReleveAchatReadItemTimestampableBlameambleGroupPdfFacture}
     * @memberof ReleveAchatReleveAchatReadReleveAchatReadItemTimestampableBlameambleGroup
     */
    'pdfFacture'?: ReleveAchatReleveAchatReadReleveAchatReadItemTimestampableBlameambleGroupPdfFacture | null;
    /**
     * 
     * @type {boolean}
     * @memberof ReleveAchatReleveAchatReadReleveAchatReadItemTimestampableBlameambleGroup
     */
    'cloture'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReleveAchatReleveAchatReadReleveAchatReadItemTimestampableBlameambleGroup
     */
    'deleted'?: boolean;
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof ReleveAchatReleveAchatReadReleveAchatReadItemTimestampableBlameambleGroup
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof ReleveAchatReleveAchatReadReleveAchatReadItemTimestampableBlameambleGroup
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof ReleveAchatReleveAchatReadReleveAchatReadItemTimestampableBlameambleGroup
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof ReleveAchatReleveAchatReadReleveAchatReadItemTimestampableBlameambleGroup
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface ReleveAchatReleveAchatReadReleveAchatReadItemTimestampableBlameambleGroupPdfFacture
 */
export interface ReleveAchatReleveAchatReadReleveAchatReadItemTimestampableBlameambleGroupPdfFacture {
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof ReleveAchatReleveAchatReadReleveAchatReadItemTimestampableBlameambleGroupPdfFacture
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof ReleveAchatReleveAchatReadReleveAchatReadItemTimestampableBlameambleGroupPdfFacture
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof ReleveAchatReleveAchatReadReleveAchatReadItemTimestampableBlameambleGroupPdfFacture
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof ReleveAchatReleveAchatReadReleveAchatReadItemTimestampableBlameambleGroupPdfFacture
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface ReleveAchatReleveAchatReadTimestampableBlameambleGroup
 */
export interface ReleveAchatReleveAchatReadTimestampableBlameambleGroup {
    /**
     * 
     * @type {number}
     * @memberof ReleveAchatReleveAchatReadTimestampableBlameambleGroup
     */
    'id'?: number;
    /**
     * 
     * @type {ReleveAchatReleveAchatReadTimestampableBlameambleGroupPdfFacture}
     * @memberof ReleveAchatReleveAchatReadTimestampableBlameambleGroup
     */
    'pdfFacture'?: ReleveAchatReleveAchatReadTimestampableBlameambleGroupPdfFacture | null;
    /**
     * 
     * @type {boolean}
     * @memberof ReleveAchatReleveAchatReadTimestampableBlameambleGroup
     */
    'cloture'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReleveAchatReleveAchatReadTimestampableBlameambleGroup
     */
    'deleted'?: boolean;
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof ReleveAchatReleveAchatReadTimestampableBlameambleGroup
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof ReleveAchatReleveAchatReadTimestampableBlameambleGroup
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof ReleveAchatReleveAchatReadTimestampableBlameambleGroup
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof ReleveAchatReleveAchatReadTimestampableBlameambleGroup
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface ReleveAchatReleveAchatReadTimestampableBlameambleGroupPdfFacture
 */
export interface ReleveAchatReleveAchatReadTimestampableBlameambleGroupPdfFacture {
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof ReleveAchatReleveAchatReadTimestampableBlameambleGroupPdfFacture
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof ReleveAchatReleveAchatReadTimestampableBlameambleGroupPdfFacture
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof ReleveAchatReleveAchatReadTimestampableBlameambleGroupPdfFacture
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof ReleveAchatReleveAchatReadTimestampableBlameambleGroupPdfFacture
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface ReleveAchatReleveAchatWrite
 */
export interface ReleveAchatReleveAchatWrite {
    /**
     * 
     * @type {string}
     * @memberof ReleveAchatReleveAchatWrite
     */
    'pdfFacture'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ReleveAchatReleveAchatWrite
     */
    'cloture'?: boolean;
}
/**
 * 
 * @export
 * @interface Tag
 */
export interface Tag {
    /**
     * 
     * @type {number}
     * @memberof Tag
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    'label'?: string;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    'value'?: string;
}
/**
 * 
 * @export
 * @interface TagInterfaceJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
 */
export interface TagInterfaceJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof TagInterfaceJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof TagInterfaceJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TagInterfaceJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    '@type'?: string;
}
/**
 * 
 * @export
 * @interface TagInterfaceJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
 */
export interface TagInterfaceJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof TagInterfaceJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof TagInterfaceJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TagInterfaceJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup
     */
    '@type'?: string;
}
/**
 * 
 * @export
 * @interface TagInterfaceJsonldMediaObjectReadTimestampableBlameambleGroupTaggableGroup
 */
export interface TagInterfaceJsonldMediaObjectReadTimestampableBlameambleGroupTaggableGroup {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof TagInterfaceJsonldMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof TagInterfaceJsonldMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TagInterfaceJsonldMediaObjectReadTimestampableBlameambleGroupTaggableGroup
     */
    '@type'?: string;
}
/**
 * 
 * @export
 * @interface TblFactureAchatJalDetailFactureAchatJalRead
 */
export interface TblFactureAchatJalDetailFactureAchatJalRead {
    /**
     * 
     * @type {number}
     * @memberof TblFactureAchatJalDetailFactureAchatJalRead
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblFactureAchatJalDetailFactureAchatJalRead
     */
    'idFLigneVente'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFactureAchatJalDetailFactureAchatJalRead
     */
    'idRubriqueFacturation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailFactureAchatJalRead
     */
    'ht'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailFactureAchatJalRead
     */
    'tva'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFactureAchatJalDetailFactureAchatJalRead
     */
    'tauxTva'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailFactureAchatJalRead
     */
    'cegidCompte'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailFactureAchatJalRead
     */
    'cegidSp1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailFactureAchatJalRead
     */
    'cegidSp2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailFactureAchatJalRead
     */
    'cegidTl1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailFactureAchatJalRead
     */
    'cegidTl2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailFactureAchatJalRead
     */
    'cegidTl3'?: string | null;
}
/**
 * 
 * @export
 * @interface TblFactureAchatJalDetailFactureAchatJalUpdate
 */
export interface TblFactureAchatJalDetailFactureAchatJalUpdate {
    /**
     * 
     * @type {number}
     * @memberof TblFactureAchatJalDetailFactureAchatJalUpdate
     */
    'idFLigneVente'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFactureAchatJalDetailFactureAchatJalUpdate
     */
    'idRubriqueFacturation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailFactureAchatJalUpdate
     */
    'ht'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailFactureAchatJalUpdate
     */
    'tva'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFactureAchatJalDetailFactureAchatJalUpdate
     */
    'tauxTva'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailFactureAchatJalUpdate
     */
    'cegidCompte'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailFactureAchatJalUpdate
     */
    'cegidSp1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailFactureAchatJalUpdate
     */
    'cegidSp2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailFactureAchatJalUpdate
     */
    'cegidTl1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailFactureAchatJalUpdate
     */
    'cegidTl2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailFactureAchatJalUpdate
     */
    'cegidTl3'?: string | null;
}
/**
 * 
 * @export
 * @interface TblFactureAchatJalDetailFactureAchatJalWrite
 */
export interface TblFactureAchatJalDetailFactureAchatJalWrite {
    /**
     * 
     * @type {number}
     * @memberof TblFactureAchatJalDetailFactureAchatJalWrite
     */
    'idFLigneVente'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFactureAchatJalDetailFactureAchatJalWrite
     */
    'idRubriqueFacturation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailFactureAchatJalWrite
     */
    'ht'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailFactureAchatJalWrite
     */
    'tva'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFactureAchatJalDetailFactureAchatJalWrite
     */
    'tauxTva'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailFactureAchatJalWrite
     */
    'cegidCompte'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailFactureAchatJalWrite
     */
    'cegidSp1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailFactureAchatJalWrite
     */
    'cegidSp2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailFactureAchatJalWrite
     */
    'cegidTl1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailFactureAchatJalWrite
     */
    'cegidTl2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailFactureAchatJalWrite
     */
    'cegidTl3'?: string | null;
}
/**
 * 
 * @export
 * @interface TblFactureAchatJalDetailJsonldFactureAchatJalRead
 */
export interface TblFactureAchatJalDetailJsonldFactureAchatJalRead {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof TblFactureAchatJalDetailJsonldFactureAchatJalRead
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailJsonldFactureAchatJalRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailJsonldFactureAchatJalRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblFactureAchatJalDetailJsonldFactureAchatJalRead
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblFactureAchatJalDetailJsonldFactureAchatJalRead
     */
    'idFLigneVente'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFactureAchatJalDetailJsonldFactureAchatJalRead
     */
    'idRubriqueFacturation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailJsonldFactureAchatJalRead
     */
    'ht'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailJsonldFactureAchatJalRead
     */
    'tva'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFactureAchatJalDetailJsonldFactureAchatJalRead
     */
    'tauxTva'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailJsonldFactureAchatJalRead
     */
    'cegidCompte'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailJsonldFactureAchatJalRead
     */
    'cegidSp1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailJsonldFactureAchatJalRead
     */
    'cegidSp2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailJsonldFactureAchatJalRead
     */
    'cegidTl1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailJsonldFactureAchatJalRead
     */
    'cegidTl2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailJsonldFactureAchatJalRead
     */
    'cegidTl3'?: string | null;
}
/**
 * 
 * @export
 * @interface TblFactureAchatJalDetailJsonldFactureAchatJalUpdate
 */
export interface TblFactureAchatJalDetailJsonldFactureAchatJalUpdate {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof TblFactureAchatJalDetailJsonldFactureAchatJalUpdate
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailJsonldFactureAchatJalUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailJsonldFactureAchatJalUpdate
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblFactureAchatJalDetailJsonldFactureAchatJalUpdate
     */
    'idFLigneVente'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFactureAchatJalDetailJsonldFactureAchatJalUpdate
     */
    'idRubriqueFacturation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailJsonldFactureAchatJalUpdate
     */
    'ht'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailJsonldFactureAchatJalUpdate
     */
    'tva'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFactureAchatJalDetailJsonldFactureAchatJalUpdate
     */
    'tauxTva'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailJsonldFactureAchatJalUpdate
     */
    'cegidCompte'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailJsonldFactureAchatJalUpdate
     */
    'cegidSp1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailJsonldFactureAchatJalUpdate
     */
    'cegidSp2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailJsonldFactureAchatJalUpdate
     */
    'cegidTl1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailJsonldFactureAchatJalUpdate
     */
    'cegidTl2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailJsonldFactureAchatJalUpdate
     */
    'cegidTl3'?: string | null;
}
/**
 * 
 * @export
 * @interface TblFactureAchatJalDetailJsonldFactureAchatJalWrite
 */
export interface TblFactureAchatJalDetailJsonldFactureAchatJalWrite {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof TblFactureAchatJalDetailJsonldFactureAchatJalWrite
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailJsonldFactureAchatJalWrite
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailJsonldFactureAchatJalWrite
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblFactureAchatJalDetailJsonldFactureAchatJalWrite
     */
    'idFLigneVente'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFactureAchatJalDetailJsonldFactureAchatJalWrite
     */
    'idRubriqueFacturation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailJsonldFactureAchatJalWrite
     */
    'ht'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailJsonldFactureAchatJalWrite
     */
    'tva'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFactureAchatJalDetailJsonldFactureAchatJalWrite
     */
    'tauxTva'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailJsonldFactureAchatJalWrite
     */
    'cegidCompte'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailJsonldFactureAchatJalWrite
     */
    'cegidSp1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailJsonldFactureAchatJalWrite
     */
    'cegidSp2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailJsonldFactureAchatJalWrite
     */
    'cegidTl1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailJsonldFactureAchatJalWrite
     */
    'cegidTl2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailJsonldFactureAchatJalWrite
     */
    'cegidTl3'?: string | null;
}
/**
 * 
 * @export
 * @interface TblFactureAchatJalLigneFactureAchatJalRead
 */
export interface TblFactureAchatJalLigneFactureAchatJalRead {
    /**
     * 
     * @type {number}
     * @memberof TblFactureAchatJalLigneFactureAchatJalRead
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblFactureAchatJalLigneFactureAchatJalRead
     */
    'idPrestation'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFactureAchatJalLigneFactureAchatJalRead
     */
    'idDossierFpro'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneFactureAchatJalRead
     */
    'quiCree'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneFactureAchatJalRead
     */
    'quiModif'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneFactureAchatJalRead
     */
    'dateCree'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneFactureAchatJalRead
     */
    'dateModif'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneFactureAchatJalRead
     */
    'htPubDetail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneFactureAchatJalRead
     */
    'htJustifDetail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneFactureAchatJalRead
     */
    'htAutreDetail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneFactureAchatJalRead
     */
    'tvaPubDetail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneFactureAchatJalRead
     */
    'tvaJustifDetail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneFactureAchatJalRead
     */
    'tvaAutreDetail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneFactureAchatJalRead
     */
    'totalTtcDetail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneFactureAchatJalRead
     */
    'htAdj'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneFactureAchatJalRead
     */
    'tvaAdjDetail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneFactureAchatJalRead
     */
    'htMp'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneFactureAchatJalRead
     */
    'tvaMpDetail'?: string | null;
    /**
     * 
     * @type {Array<TblFactureAchatJalDetailFactureAchatJalRead>}
     * @memberof TblFactureAchatJalLigneFactureAchatJalRead
     */
    'details'?: Array<TblFactureAchatJalDetailFactureAchatJalRead>;
}
/**
 * 
 * @export
 * @interface TblFactureAchatJalLigneFactureAchatJalUpdate
 */
export interface TblFactureAchatJalLigneFactureAchatJalUpdate {
    /**
     * 
     * @type {number}
     * @memberof TblFactureAchatJalLigneFactureAchatJalUpdate
     */
    'idPrestation'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFactureAchatJalLigneFactureAchatJalUpdate
     */
    'idDossierFpro'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneFactureAchatJalUpdate
     */
    'htPubDetail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneFactureAchatJalUpdate
     */
    'htJustifDetail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneFactureAchatJalUpdate
     */
    'htAutreDetail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneFactureAchatJalUpdate
     */
    'tvaPubDetail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneFactureAchatJalUpdate
     */
    'tvaJustifDetail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneFactureAchatJalUpdate
     */
    'tvaAutreDetail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneFactureAchatJalUpdate
     */
    'totalTtcDetail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneFactureAchatJalUpdate
     */
    'htAdj'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneFactureAchatJalUpdate
     */
    'tvaAdjDetail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneFactureAchatJalUpdate
     */
    'htMp'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneFactureAchatJalUpdate
     */
    'tvaMpDetail'?: string | null;
    /**
     * 
     * @type {Array<TblFactureAchatJalDetailFactureAchatJalUpdate>}
     * @memberof TblFactureAchatJalLigneFactureAchatJalUpdate
     */
    'details'?: Array<TblFactureAchatJalDetailFactureAchatJalUpdate>;
}
/**
 * 
 * @export
 * @interface TblFactureAchatJalLigneFactureAchatJalWrite
 */
export interface TblFactureAchatJalLigneFactureAchatJalWrite {
    /**
     * 
     * @type {number}
     * @memberof TblFactureAchatJalLigneFactureAchatJalWrite
     */
    'idPrestation'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFactureAchatJalLigneFactureAchatJalWrite
     */
    'idDossierFpro'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneFactureAchatJalWrite
     */
    'htPubDetail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneFactureAchatJalWrite
     */
    'htJustifDetail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneFactureAchatJalWrite
     */
    'htAutreDetail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneFactureAchatJalWrite
     */
    'tvaPubDetail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneFactureAchatJalWrite
     */
    'tvaJustifDetail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneFactureAchatJalWrite
     */
    'tvaAutreDetail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneFactureAchatJalWrite
     */
    'totalTtcDetail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneFactureAchatJalWrite
     */
    'htAdj'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneFactureAchatJalWrite
     */
    'tvaAdjDetail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneFactureAchatJalWrite
     */
    'htMp'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneFactureAchatJalWrite
     */
    'tvaMpDetail'?: string | null;
    /**
     * 
     * @type {Array<TblFactureAchatJalDetailFactureAchatJalWrite>}
     * @memberof TblFactureAchatJalLigneFactureAchatJalWrite
     */
    'details'?: Array<TblFactureAchatJalDetailFactureAchatJalWrite>;
}
/**
 * 
 * @export
 * @interface TblFactureAchatJalLigneJsonldFactureAchatJalRead
 */
export interface TblFactureAchatJalLigneJsonldFactureAchatJalRead {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof TblFactureAchatJalLigneJsonldFactureAchatJalRead
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneJsonldFactureAchatJalRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneJsonldFactureAchatJalRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblFactureAchatJalLigneJsonldFactureAchatJalRead
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblFactureAchatJalLigneJsonldFactureAchatJalRead
     */
    'idPrestation'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFactureAchatJalLigneJsonldFactureAchatJalRead
     */
    'idDossierFpro'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneJsonldFactureAchatJalRead
     */
    'quiCree'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneJsonldFactureAchatJalRead
     */
    'quiModif'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneJsonldFactureAchatJalRead
     */
    'dateCree'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneJsonldFactureAchatJalRead
     */
    'dateModif'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneJsonldFactureAchatJalRead
     */
    'htPubDetail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneJsonldFactureAchatJalRead
     */
    'htJustifDetail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneJsonldFactureAchatJalRead
     */
    'htAutreDetail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneJsonldFactureAchatJalRead
     */
    'tvaPubDetail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneJsonldFactureAchatJalRead
     */
    'tvaJustifDetail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneJsonldFactureAchatJalRead
     */
    'tvaAutreDetail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneJsonldFactureAchatJalRead
     */
    'totalTtcDetail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneJsonldFactureAchatJalRead
     */
    'htAdj'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneJsonldFactureAchatJalRead
     */
    'tvaAdjDetail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneJsonldFactureAchatJalRead
     */
    'htMp'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneJsonldFactureAchatJalRead
     */
    'tvaMpDetail'?: string | null;
    /**
     * 
     * @type {Array<TblFactureAchatJalDetailJsonldFactureAchatJalRead>}
     * @memberof TblFactureAchatJalLigneJsonldFactureAchatJalRead
     */
    'details'?: Array<TblFactureAchatJalDetailJsonldFactureAchatJalRead>;
}
/**
 * 
 * @export
 * @interface TblFactureAchatJalLigneJsonldFactureAchatJalUpdate
 */
export interface TblFactureAchatJalLigneJsonldFactureAchatJalUpdate {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof TblFactureAchatJalLigneJsonldFactureAchatJalUpdate
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneJsonldFactureAchatJalUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneJsonldFactureAchatJalUpdate
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblFactureAchatJalLigneJsonldFactureAchatJalUpdate
     */
    'idPrestation'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFactureAchatJalLigneJsonldFactureAchatJalUpdate
     */
    'idDossierFpro'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneJsonldFactureAchatJalUpdate
     */
    'htPubDetail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneJsonldFactureAchatJalUpdate
     */
    'htJustifDetail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneJsonldFactureAchatJalUpdate
     */
    'htAutreDetail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneJsonldFactureAchatJalUpdate
     */
    'tvaPubDetail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneJsonldFactureAchatJalUpdate
     */
    'tvaJustifDetail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneJsonldFactureAchatJalUpdate
     */
    'tvaAutreDetail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneJsonldFactureAchatJalUpdate
     */
    'totalTtcDetail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneJsonldFactureAchatJalUpdate
     */
    'htAdj'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneJsonldFactureAchatJalUpdate
     */
    'tvaAdjDetail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneJsonldFactureAchatJalUpdate
     */
    'htMp'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneJsonldFactureAchatJalUpdate
     */
    'tvaMpDetail'?: string | null;
    /**
     * 
     * @type {Array<TblFactureAchatJalDetailJsonldFactureAchatJalUpdate>}
     * @memberof TblFactureAchatJalLigneJsonldFactureAchatJalUpdate
     */
    'details'?: Array<TblFactureAchatJalDetailJsonldFactureAchatJalUpdate>;
}
/**
 * 
 * @export
 * @interface TblFactureAchatJalLigneJsonldFactureAchatJalWrite
 */
export interface TblFactureAchatJalLigneJsonldFactureAchatJalWrite {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof TblFactureAchatJalLigneJsonldFactureAchatJalWrite
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneJsonldFactureAchatJalWrite
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneJsonldFactureAchatJalWrite
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblFactureAchatJalLigneJsonldFactureAchatJalWrite
     */
    'idPrestation'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFactureAchatJalLigneJsonldFactureAchatJalWrite
     */
    'idDossierFpro'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneJsonldFactureAchatJalWrite
     */
    'htPubDetail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneJsonldFactureAchatJalWrite
     */
    'htJustifDetail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneJsonldFactureAchatJalWrite
     */
    'htAutreDetail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneJsonldFactureAchatJalWrite
     */
    'tvaPubDetail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneJsonldFactureAchatJalWrite
     */
    'tvaJustifDetail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneJsonldFactureAchatJalWrite
     */
    'tvaAutreDetail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneJsonldFactureAchatJalWrite
     */
    'totalTtcDetail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneJsonldFactureAchatJalWrite
     */
    'htAdj'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneJsonldFactureAchatJalWrite
     */
    'tvaAdjDetail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneJsonldFactureAchatJalWrite
     */
    'htMp'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneJsonldFactureAchatJalWrite
     */
    'tvaMpDetail'?: string | null;
    /**
     * 
     * @type {Array<TblFactureAchatJalDetailJsonldFactureAchatJalWrite>}
     * @memberof TblFactureAchatJalLigneJsonldFactureAchatJalWrite
     */
    'details'?: Array<TblFactureAchatJalDetailJsonldFactureAchatJalWrite>;
}
/**
 * 
 * @export
 * @interface TblJalTypeRemiseJsonldFactureAchatJalRead
 */
export interface TblJalTypeRemiseJsonldFactureAchatJalRead {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof TblJalTypeRemiseJsonldFactureAchatJalRead
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblJalTypeRemiseJsonldFactureAchatJalRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblJalTypeRemiseJsonldFactureAchatJalRead
     */
    '@type'?: string;
}
/**
 * 
 * @export
 * @interface WorkflowMailJsonldWorkflowMailList
 */
export interface WorkflowMailJsonldWorkflowMailList {
    /**
     * 
     * @type {string}
     * @memberof WorkflowMailJsonldWorkflowMailList
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowMailJsonldWorkflowMailList
     */
    '@type'?: string;
}
/**
 * 
 * @export
 * @interface WorkflowMailJsonldWorkflowMailRead
 */
export interface WorkflowMailJsonldWorkflowMailRead {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof WorkflowMailJsonldWorkflowMailRead
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof WorkflowMailJsonldWorkflowMailRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowMailJsonldWorkflowMailRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof WorkflowMailJsonldWorkflowMailRead
     */
    'id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof WorkflowMailJsonldWorkflowMailRead
     */
    'typeMail': string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowMailJsonldWorkflowMailRead
     */
    'mediaObjectId': string;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof WorkflowMailJsonldWorkflowMailRead
     */
    'additionalInfo': { [key: string]: any; };
    /**
     * 
     * @type {Array<MailAttachmentJsonldWorkflowMailRead>}
     * @memberof WorkflowMailJsonldWorkflowMailRead
     */
    'attachments': Array<MailAttachmentJsonldWorkflowMailRead>;
}
/**
 * 
 * @export
 * @interface WorkflowMailJsonldWorkflowMailSend
 */
export interface WorkflowMailJsonldWorkflowMailSend {
    /**
     * 
     * @type {string}
     * @memberof WorkflowMailJsonldWorkflowMailSend
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowMailJsonldWorkflowMailSend
     */
    '@type'?: string;
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof WorkflowMailJsonldWorkflowMailSend
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof WorkflowMailJsonldWorkflowMailSend
     */
    'typeMail': string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowMailJsonldWorkflowMailSend
     */
    'mediaObjectId': string;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof WorkflowMailJsonldWorkflowMailSend
     */
    'additionalInfo': { [key: string]: any; };
}
/**
 * 
 * @export
 * @interface WorkflowMailWorkflowMailRead
 */
export interface WorkflowMailWorkflowMailRead {
    /**
     * 
     * @type {number}
     * @memberof WorkflowMailWorkflowMailRead
     */
    'id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof WorkflowMailWorkflowMailRead
     */
    'typeMail': string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowMailWorkflowMailRead
     */
    'mediaObjectId': string;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof WorkflowMailWorkflowMailRead
     */
    'additionalInfo': { [key: string]: any; };
    /**
     * 
     * @type {Array<object>}
     * @memberof WorkflowMailWorkflowMailRead
     */
    'attachments': Array<object>;
}
/**
 * 
 * @export
 * @interface WorkflowMailWorkflowMailSend
 */
export interface WorkflowMailWorkflowMailSend {
    /**
     * 
     * @type {string}
     * @memberof WorkflowMailWorkflowMailSend
     */
    'typeMail': string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowMailWorkflowMailSend
     */
    'mediaObjectId': string;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof WorkflowMailWorkflowMailSend
     */
    'additionalInfo': { [key: string]: any; };
}

/**
 * ActionApi - axios parameter creator
 * @export
 */
export const ActionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Récupérer la liste des actions
         * @summary Récupérer la liste des actions
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActionCollection: async (xIdSociete?: number, page?: number, itemsPerPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/achats/actions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'une action
         * @summary Récupérer les informations d\'une action
         * @param {string} code Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActionItem: async (code: string, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('getActionItem', 'code', code)
            const localVarPath = `/achats/actions/{code}`
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActionApi - functional programming interface
 * @export
 */
export const ActionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ActionApiAxiosParamCreator(configuration)
    return {
        /**
         * Récupérer la liste des actions
         * @summary Récupérer la liste des actions
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActionCollection(xIdSociete?: number, page?: number, itemsPerPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetActionCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActionCollection(xIdSociete, page, itemsPerPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Récupérer les informations d\'une action
         * @summary Récupérer les informations d\'une action
         * @param {string} code Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActionItem(code: string, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActionJsonldActionRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActionItem(code, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ActionApi - factory interface
 * @export
 */
export const ActionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ActionApiFp(configuration)
    return {
        /**
         * Récupérer la liste des actions
         * @summary Récupérer la liste des actions
         * @param {ActionApiGetActionCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActionCollection(requestParameters: ActionApiGetActionCollectionRequest = {}, options?: AxiosRequestConfig): AxiosPromise<GetActionCollection200Response> {
            return localVarFp.getActionCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'une action
         * @summary Récupérer les informations d\'une action
         * @param {ActionApiGetActionItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActionItem(requestParameters: ActionApiGetActionItemRequest, options?: AxiosRequestConfig): AxiosPromise<ActionJsonldActionRead> {
            return localVarFp.getActionItem(requestParameters.code, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getActionCollection operation in ActionApi.
 * @export
 * @interface ActionApiGetActionCollectionRequest
 */
export interface ActionApiGetActionCollectionRequest {
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof ActionApiGetActionCollection
     */
    readonly xIdSociete?: number

    /**
     * The collection page number
     * @type {number}
     * @memberof ActionApiGetActionCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof ActionApiGetActionCollection
     */
    readonly itemsPerPage?: number
}

/**
 * Request parameters for getActionItem operation in ActionApi.
 * @export
 * @interface ActionApiGetActionItemRequest
 */
export interface ActionApiGetActionItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof ActionApiGetActionItem
     */
    readonly code: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof ActionApiGetActionItem
     */
    readonly xIdSociete?: number
}

/**
 * ActionApi - object-oriented interface
 * @export
 * @class ActionApi
 * @extends {BaseAPI}
 */
export class ActionApi extends BaseAPI {
    /**
     * Récupérer la liste des actions
     * @summary Récupérer la liste des actions
     * @param {ActionApiGetActionCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionApi
     */
    public getActionCollection(requestParameters: ActionApiGetActionCollectionRequest = {}, options?: AxiosRequestConfig) {
        return ActionApiFp(this.configuration).getActionCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'une action
     * @summary Récupérer les informations d\'une action
     * @param {ActionApiGetActionItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionApi
     */
    public getActionItem(requestParameters: ActionApiGetActionItemRequest, options?: AxiosRequestConfig) {
        return ActionApiFp(this.configuration).getActionItem(requestParameters.code, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AttachmentApi - axios parameter creator
 * @export
 */
export const AttachmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Removes the Attachment resource.
         * @summary Removes the Attachment resource.
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAttachmentItem: async (id: string, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteAttachmentItem', 'id', id)
            const localVarPath = `/achats/attachments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Attachment resource.
         * @summary Retrieves a Attachment resource.
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAttachmentItem: async (id: string, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAttachmentItem', 'id', id)
            const localVarPath = `/achats/attachments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the Attachment resource.
         * @summary Updates the Attachment resource.
         * @param {string} id Resource identifier
         * @param {Attachment} attachment The updated Attachment resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchAttachmentItem: async (id: string, attachment: Attachment, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchAttachmentItem', 'id', id)
            // verify required parameter 'attachment' is not null or undefined
            assertParamExists('patchAttachmentItem', 'attachment', attachment)
            const localVarPath = `/achats/attachments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(attachment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Stocker une pièce jointe
         * @summary Stocker une pièce jointe
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAttachmentCollection: async (xIdSociete?: number, file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/achats/attachments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces the Attachment resource.
         * @summary Replaces the Attachment resource.
         * @param {string} id Resource identifier
         * @param {AttachmentJsonld} attachmentJsonld The updated Attachment resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAttachmentItem: async (id: string, attachmentJsonld: AttachmentJsonld, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putAttachmentItem', 'id', id)
            // verify required parameter 'attachmentJsonld' is not null or undefined
            assertParamExists('putAttachmentItem', 'attachmentJsonld', attachmentJsonld)
            const localVarPath = `/achats/attachments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(attachmentJsonld, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AttachmentApi - functional programming interface
 * @export
 */
export const AttachmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AttachmentApiAxiosParamCreator(configuration)
    return {
        /**
         * Removes the Attachment resource.
         * @summary Removes the Attachment resource.
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAttachmentItem(id: string, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAttachmentItem(id, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Attachment resource.
         * @summary Retrieves a Attachment resource.
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAttachmentItem(id: string, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AttachmentJsonldAttachmentGroupTimestampableBlameambleGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAttachmentItem(id, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the Attachment resource.
         * @summary Updates the Attachment resource.
         * @param {string} id Resource identifier
         * @param {Attachment} attachment The updated Attachment resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchAttachmentItem(id: string, attachment: Attachment, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AttachmentJsonldAttachmentGroupTimestampableBlameambleGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchAttachmentItem(id, attachment, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Stocker une pièce jointe
         * @summary Stocker une pièce jointe
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAttachmentCollection(xIdSociete?: number, file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AttachmentJsonldAttachmentGroupTimestampableBlameambleGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAttachmentCollection(xIdSociete, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces the Attachment resource.
         * @summary Replaces the Attachment resource.
         * @param {string} id Resource identifier
         * @param {AttachmentJsonld} attachmentJsonld The updated Attachment resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putAttachmentItem(id: string, attachmentJsonld: AttachmentJsonld, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AttachmentJsonldAttachmentGroupTimestampableBlameambleGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putAttachmentItem(id, attachmentJsonld, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AttachmentApi - factory interface
 * @export
 */
export const AttachmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AttachmentApiFp(configuration)
    return {
        /**
         * Removes the Attachment resource.
         * @summary Removes the Attachment resource.
         * @param {AttachmentApiDeleteAttachmentItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAttachmentItem(requestParameters: AttachmentApiDeleteAttachmentItemRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteAttachmentItem(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Attachment resource.
         * @summary Retrieves a Attachment resource.
         * @param {AttachmentApiGetAttachmentItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAttachmentItem(requestParameters: AttachmentApiGetAttachmentItemRequest, options?: AxiosRequestConfig): AxiosPromise<AttachmentJsonldAttachmentGroupTimestampableBlameambleGroup> {
            return localVarFp.getAttachmentItem(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the Attachment resource.
         * @summary Updates the Attachment resource.
         * @param {AttachmentApiPatchAttachmentItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchAttachmentItem(requestParameters: AttachmentApiPatchAttachmentItemRequest, options?: AxiosRequestConfig): AxiosPromise<AttachmentJsonldAttachmentGroupTimestampableBlameambleGroup> {
            return localVarFp.patchAttachmentItem(requestParameters.id, requestParameters.attachment, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Stocker une pièce jointe
         * @summary Stocker une pièce jointe
         * @param {AttachmentApiPostAttachmentCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAttachmentCollection(requestParameters: AttachmentApiPostAttachmentCollectionRequest = {}, options?: AxiosRequestConfig): AxiosPromise<AttachmentJsonldAttachmentGroupTimestampableBlameambleGroup> {
            return localVarFp.postAttachmentCollection(requestParameters.xIdSociete, requestParameters.file, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces the Attachment resource.
         * @summary Replaces the Attachment resource.
         * @param {AttachmentApiPutAttachmentItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAttachmentItem(requestParameters: AttachmentApiPutAttachmentItemRequest, options?: AxiosRequestConfig): AxiosPromise<AttachmentJsonldAttachmentGroupTimestampableBlameambleGroup> {
            return localVarFp.putAttachmentItem(requestParameters.id, requestParameters.attachmentJsonld, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for deleteAttachmentItem operation in AttachmentApi.
 * @export
 * @interface AttachmentApiDeleteAttachmentItemRequest
 */
export interface AttachmentApiDeleteAttachmentItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof AttachmentApiDeleteAttachmentItem
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof AttachmentApiDeleteAttachmentItem
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for getAttachmentItem operation in AttachmentApi.
 * @export
 * @interface AttachmentApiGetAttachmentItemRequest
 */
export interface AttachmentApiGetAttachmentItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof AttachmentApiGetAttachmentItem
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof AttachmentApiGetAttachmentItem
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for patchAttachmentItem operation in AttachmentApi.
 * @export
 * @interface AttachmentApiPatchAttachmentItemRequest
 */
export interface AttachmentApiPatchAttachmentItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof AttachmentApiPatchAttachmentItem
     */
    readonly id: string

    /**
     * The updated Attachment resource
     * @type {Attachment}
     * @memberof AttachmentApiPatchAttachmentItem
     */
    readonly attachment: Attachment

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof AttachmentApiPatchAttachmentItem
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for postAttachmentCollection operation in AttachmentApi.
 * @export
 * @interface AttachmentApiPostAttachmentCollectionRequest
 */
export interface AttachmentApiPostAttachmentCollectionRequest {
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof AttachmentApiPostAttachmentCollection
     */
    readonly xIdSociete?: number

    /**
     * 
     * @type {File}
     * @memberof AttachmentApiPostAttachmentCollection
     */
    readonly file?: File
}

/**
 * Request parameters for putAttachmentItem operation in AttachmentApi.
 * @export
 * @interface AttachmentApiPutAttachmentItemRequest
 */
export interface AttachmentApiPutAttachmentItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof AttachmentApiPutAttachmentItem
     */
    readonly id: string

    /**
     * The updated Attachment resource
     * @type {AttachmentJsonld}
     * @memberof AttachmentApiPutAttachmentItem
     */
    readonly attachmentJsonld: AttachmentJsonld

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof AttachmentApiPutAttachmentItem
     */
    readonly xIdSociete?: number
}

/**
 * AttachmentApi - object-oriented interface
 * @export
 * @class AttachmentApi
 * @extends {BaseAPI}
 */
export class AttachmentApi extends BaseAPI {
    /**
     * Removes the Attachment resource.
     * @summary Removes the Attachment resource.
     * @param {AttachmentApiDeleteAttachmentItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttachmentApi
     */
    public deleteAttachmentItem(requestParameters: AttachmentApiDeleteAttachmentItemRequest, options?: AxiosRequestConfig) {
        return AttachmentApiFp(this.configuration).deleteAttachmentItem(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Attachment resource.
     * @summary Retrieves a Attachment resource.
     * @param {AttachmentApiGetAttachmentItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttachmentApi
     */
    public getAttachmentItem(requestParameters: AttachmentApiGetAttachmentItemRequest, options?: AxiosRequestConfig) {
        return AttachmentApiFp(this.configuration).getAttachmentItem(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the Attachment resource.
     * @summary Updates the Attachment resource.
     * @param {AttachmentApiPatchAttachmentItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttachmentApi
     */
    public patchAttachmentItem(requestParameters: AttachmentApiPatchAttachmentItemRequest, options?: AxiosRequestConfig) {
        return AttachmentApiFp(this.configuration).patchAttachmentItem(requestParameters.id, requestParameters.attachment, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Stocker une pièce jointe
     * @summary Stocker une pièce jointe
     * @param {AttachmentApiPostAttachmentCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttachmentApi
     */
    public postAttachmentCollection(requestParameters: AttachmentApiPostAttachmentCollectionRequest = {}, options?: AxiosRequestConfig) {
        return AttachmentApiFp(this.configuration).postAttachmentCollection(requestParameters.xIdSociete, requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces the Attachment resource.
     * @summary Replaces the Attachment resource.
     * @param {AttachmentApiPutAttachmentItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttachmentApi
     */
    public putAttachmentItem(requestParameters: AttachmentApiPutAttachmentItemRequest, options?: AxiosRequestConfig) {
        return AttachmentApiFp(this.configuration).putAttachmentItem(requestParameters.id, requestParameters.attachmentJsonld, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CategorieRejetApi - axios parameter creator
 * @export
 */
export const CategorieRejetApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Récupérer la liste des catégories de codes rejets
         * @summary Récupérer la liste des catégories de codes rejets
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategorieRejetCollection: async (xIdSociete?: number, page?: number, itemsPerPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/achats/categorie_rejets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'une catégorie de code rejet
         * @summary Récupérer les informations d\'une catégorie de code rejet
         * @param {string} categorie Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategorieRejetItem: async (categorie: string, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'categorie' is not null or undefined
            assertParamExists('getCategorieRejetItem', 'categorie', categorie)
            const localVarPath = `/achats/categorie_rejets/{categorie}`
                .replace(`{${"categorie"}}`, encodeURIComponent(String(categorie)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CategorieRejetApi - functional programming interface
 * @export
 */
export const CategorieRejetApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CategorieRejetApiAxiosParamCreator(configuration)
    return {
        /**
         * Récupérer la liste des catégories de codes rejets
         * @summary Récupérer la liste des catégories de codes rejets
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCategorieRejetCollection(xIdSociete?: number, page?: number, itemsPerPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCategorieRejetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCategorieRejetCollection(xIdSociete, page, itemsPerPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Récupérer les informations d\'une catégorie de code rejet
         * @summary Récupérer les informations d\'une catégorie de code rejet
         * @param {string} categorie Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCategorieRejetItem(categorie: string, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategorieRejetJsonldCategorieRejetRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCategorieRejetItem(categorie, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CategorieRejetApi - factory interface
 * @export
 */
export const CategorieRejetApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CategorieRejetApiFp(configuration)
    return {
        /**
         * Récupérer la liste des catégories de codes rejets
         * @summary Récupérer la liste des catégories de codes rejets
         * @param {CategorieRejetApiGetCategorieRejetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategorieRejetCollection(requestParameters: CategorieRejetApiGetCategorieRejetCollectionRequest = {}, options?: AxiosRequestConfig): AxiosPromise<GetCategorieRejetCollection200Response> {
            return localVarFp.getCategorieRejetCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'une catégorie de code rejet
         * @summary Récupérer les informations d\'une catégorie de code rejet
         * @param {CategorieRejetApiGetCategorieRejetItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategorieRejetItem(requestParameters: CategorieRejetApiGetCategorieRejetItemRequest, options?: AxiosRequestConfig): AxiosPromise<CategorieRejetJsonldCategorieRejetRead> {
            return localVarFp.getCategorieRejetItem(requestParameters.categorie, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getCategorieRejetCollection operation in CategorieRejetApi.
 * @export
 * @interface CategorieRejetApiGetCategorieRejetCollectionRequest
 */
export interface CategorieRejetApiGetCategorieRejetCollectionRequest {
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof CategorieRejetApiGetCategorieRejetCollection
     */
    readonly xIdSociete?: number

    /**
     * The collection page number
     * @type {number}
     * @memberof CategorieRejetApiGetCategorieRejetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof CategorieRejetApiGetCategorieRejetCollection
     */
    readonly itemsPerPage?: number
}

/**
 * Request parameters for getCategorieRejetItem operation in CategorieRejetApi.
 * @export
 * @interface CategorieRejetApiGetCategorieRejetItemRequest
 */
export interface CategorieRejetApiGetCategorieRejetItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof CategorieRejetApiGetCategorieRejetItem
     */
    readonly categorie: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof CategorieRejetApiGetCategorieRejetItem
     */
    readonly xIdSociete?: number
}

/**
 * CategorieRejetApi - object-oriented interface
 * @export
 * @class CategorieRejetApi
 * @extends {BaseAPI}
 */
export class CategorieRejetApi extends BaseAPI {
    /**
     * Récupérer la liste des catégories de codes rejets
     * @summary Récupérer la liste des catégories de codes rejets
     * @param {CategorieRejetApiGetCategorieRejetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategorieRejetApi
     */
    public getCategorieRejetCollection(requestParameters: CategorieRejetApiGetCategorieRejetCollectionRequest = {}, options?: AxiosRequestConfig) {
        return CategorieRejetApiFp(this.configuration).getCategorieRejetCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'une catégorie de code rejet
     * @summary Récupérer les informations d\'une catégorie de code rejet
     * @param {CategorieRejetApiGetCategorieRejetItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategorieRejetApi
     */
    public getCategorieRejetItem(requestParameters: CategorieRejetApiGetCategorieRejetItemRequest, options?: AxiosRequestConfig) {
        return CategorieRejetApiFp(this.configuration).getCategorieRejetItem(requestParameters.categorie, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CodeRejetApi - axios parameter creator
 * @export
 */
export const CodeRejetApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Récupérer la liste des codes rejets
         * @summary Récupérer la liste des codes rejets
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCodeRejetCollection: async (xIdSociete?: number, page?: number, itemsPerPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/achats/code_rejets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'un code rejet
         * @summary Récupérer les informations d\'un code rejet
         * @param {string} code Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCodeRejetItem: async (code: string, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('getCodeRejetItem', 'code', code)
            const localVarPath = `/achats/code_rejets/{code}`
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CodeRejetApi - functional programming interface
 * @export
 */
export const CodeRejetApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CodeRejetApiAxiosParamCreator(configuration)
    return {
        /**
         * Récupérer la liste des codes rejets
         * @summary Récupérer la liste des codes rejets
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCodeRejetCollection(xIdSociete?: number, page?: number, itemsPerPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCodeRejetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCodeRejetCollection(xIdSociete, page, itemsPerPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Récupérer les informations d\'un code rejet
         * @summary Récupérer les informations d\'un code rejet
         * @param {string} code Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCodeRejetItem(code: string, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CodeRejetJsonldCodeRejetRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCodeRejetItem(code, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CodeRejetApi - factory interface
 * @export
 */
export const CodeRejetApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CodeRejetApiFp(configuration)
    return {
        /**
         * Récupérer la liste des codes rejets
         * @summary Récupérer la liste des codes rejets
         * @param {CodeRejetApiGetCodeRejetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCodeRejetCollection(requestParameters: CodeRejetApiGetCodeRejetCollectionRequest = {}, options?: AxiosRequestConfig): AxiosPromise<GetCodeRejetCollection200Response> {
            return localVarFp.getCodeRejetCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'un code rejet
         * @summary Récupérer les informations d\'un code rejet
         * @param {CodeRejetApiGetCodeRejetItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCodeRejetItem(requestParameters: CodeRejetApiGetCodeRejetItemRequest, options?: AxiosRequestConfig): AxiosPromise<CodeRejetJsonldCodeRejetRead> {
            return localVarFp.getCodeRejetItem(requestParameters.code, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getCodeRejetCollection operation in CodeRejetApi.
 * @export
 * @interface CodeRejetApiGetCodeRejetCollectionRequest
 */
export interface CodeRejetApiGetCodeRejetCollectionRequest {
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof CodeRejetApiGetCodeRejetCollection
     */
    readonly xIdSociete?: number

    /**
     * The collection page number
     * @type {number}
     * @memberof CodeRejetApiGetCodeRejetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof CodeRejetApiGetCodeRejetCollection
     */
    readonly itemsPerPage?: number
}

/**
 * Request parameters for getCodeRejetItem operation in CodeRejetApi.
 * @export
 * @interface CodeRejetApiGetCodeRejetItemRequest
 */
export interface CodeRejetApiGetCodeRejetItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof CodeRejetApiGetCodeRejetItem
     */
    readonly code: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof CodeRejetApiGetCodeRejetItem
     */
    readonly xIdSociete?: number
}

/**
 * CodeRejetApi - object-oriented interface
 * @export
 * @class CodeRejetApi
 * @extends {BaseAPI}
 */
export class CodeRejetApi extends BaseAPI {
    /**
     * Récupérer la liste des codes rejets
     * @summary Récupérer la liste des codes rejets
     * @param {CodeRejetApiGetCodeRejetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodeRejetApi
     */
    public getCodeRejetCollection(requestParameters: CodeRejetApiGetCodeRejetCollectionRequest = {}, options?: AxiosRequestConfig) {
        return CodeRejetApiFp(this.configuration).getCodeRejetCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'un code rejet
     * @summary Récupérer les informations d\'un code rejet
     * @param {CodeRejetApiGetCodeRejetItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodeRejetApi
     */
    public getCodeRejetItem(requestParameters: CodeRejetApiGetCodeRejetItemRequest, options?: AxiosRequestConfig) {
        return CodeRejetApiFp(this.configuration).getCodeRejetItem(requestParameters.code, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ExceptionRejetApi - axios parameter creator
 * @export
 */
export const ExceptionRejetApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Récupérer la liste des exceptions
         * @summary Récupérer la liste des exceptions
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExceptionRejetCollection: async (xIdSociete?: number, page?: number, itemsPerPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/achats/exception_rejets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'une exception
         * @summary Récupérer les informations d\'une exception
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExceptionRejetItem: async (id: string, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getExceptionRejetItem', 'id', id)
            const localVarPath = `/achats/exception_rejets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExceptionRejetApi - functional programming interface
 * @export
 */
export const ExceptionRejetApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExceptionRejetApiAxiosParamCreator(configuration)
    return {
        /**
         * Récupérer la liste des exceptions
         * @summary Récupérer la liste des exceptions
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExceptionRejetCollection(xIdSociete?: number, page?: number, itemsPerPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetExceptionRejetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExceptionRejetCollection(xIdSociete, page, itemsPerPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Récupérer les informations d\'une exception
         * @summary Récupérer les informations d\'une exception
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExceptionRejetItem(id: string, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExceptionRejetJsonldExceptionRejetRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExceptionRejetItem(id, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ExceptionRejetApi - factory interface
 * @export
 */
export const ExceptionRejetApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExceptionRejetApiFp(configuration)
    return {
        /**
         * Récupérer la liste des exceptions
         * @summary Récupérer la liste des exceptions
         * @param {ExceptionRejetApiGetExceptionRejetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExceptionRejetCollection(requestParameters: ExceptionRejetApiGetExceptionRejetCollectionRequest = {}, options?: AxiosRequestConfig): AxiosPromise<GetExceptionRejetCollection200Response> {
            return localVarFp.getExceptionRejetCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'une exception
         * @summary Récupérer les informations d\'une exception
         * @param {ExceptionRejetApiGetExceptionRejetItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExceptionRejetItem(requestParameters: ExceptionRejetApiGetExceptionRejetItemRequest, options?: AxiosRequestConfig): AxiosPromise<ExceptionRejetJsonldExceptionRejetRead> {
            return localVarFp.getExceptionRejetItem(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getExceptionRejetCollection operation in ExceptionRejetApi.
 * @export
 * @interface ExceptionRejetApiGetExceptionRejetCollectionRequest
 */
export interface ExceptionRejetApiGetExceptionRejetCollectionRequest {
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof ExceptionRejetApiGetExceptionRejetCollection
     */
    readonly xIdSociete?: number

    /**
     * The collection page number
     * @type {number}
     * @memberof ExceptionRejetApiGetExceptionRejetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof ExceptionRejetApiGetExceptionRejetCollection
     */
    readonly itemsPerPage?: number
}

/**
 * Request parameters for getExceptionRejetItem operation in ExceptionRejetApi.
 * @export
 * @interface ExceptionRejetApiGetExceptionRejetItemRequest
 */
export interface ExceptionRejetApiGetExceptionRejetItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof ExceptionRejetApiGetExceptionRejetItem
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof ExceptionRejetApiGetExceptionRejetItem
     */
    readonly xIdSociete?: number
}

/**
 * ExceptionRejetApi - object-oriented interface
 * @export
 * @class ExceptionRejetApi
 * @extends {BaseAPI}
 */
export class ExceptionRejetApi extends BaseAPI {
    /**
     * Récupérer la liste des exceptions
     * @summary Récupérer la liste des exceptions
     * @param {ExceptionRejetApiGetExceptionRejetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExceptionRejetApi
     */
    public getExceptionRejetCollection(requestParameters: ExceptionRejetApiGetExceptionRejetCollectionRequest = {}, options?: AxiosRequestConfig) {
        return ExceptionRejetApiFp(this.configuration).getExceptionRejetCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'une exception
     * @summary Récupérer les informations d\'une exception
     * @param {ExceptionRejetApiGetExceptionRejetItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExceptionRejetApi
     */
    public getExceptionRejetItem(requestParameters: ExceptionRejetApiGetExceptionRejetItemRequest, options?: AxiosRequestConfig) {
        return ExceptionRejetApiFp(this.configuration).getExceptionRejetItem(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FactureAchatApi - axios parameter creator
 * @export
 */
export const FactureAchatApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Associe un document à la facture
         * @summary Associe un document à la facture
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {AddAttachmentFactureAchatItemRequest} [addAttachmentFactureAchatItemRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAttachmentFactureAchatItem: async (id: string, xIdSociete?: number, addAttachmentFactureAchatItemRequest?: AddAttachmentFactureAchatItemRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addAttachmentFactureAchatItem', 'id', id)
            const localVarPath = `/achats/facture_achats/{id}/attachments`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addAttachmentFactureAchatItemRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Créer un facture d\'achat
         * @summary Créer un facture d\'achat
         * @param {FactureAchatJsonldFactureAchatCreateFactureAchatWrite} factureAchatJsonldFactureAchatCreateFactureAchatWrite The new FactureAchat resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFactureAchatFactureAchatCollection: async (factureAchatJsonldFactureAchatCreateFactureAchatWrite: FactureAchatJsonldFactureAchatCreateFactureAchatWrite, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'factureAchatJsonldFactureAchatCreateFactureAchatWrite' is not null or undefined
            assertParamExists('createFactureAchatFactureAchatCollection', 'factureAchatJsonldFactureAchatCreateFactureAchatWrite', factureAchatJsonldFactureAchatCreateFactureAchatWrite)
            const localVarPath = `/achats/facture_achats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(factureAchatJsonldFactureAchatCreateFactureAchatWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Supprimer une facture d\'achat
         * @summary Supprimer une facture d\'achat
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFactureAchatFactureAchatItem: async (id: string, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteFactureAchatFactureAchatItem', 'id', id)
            const localVarPath = `/achats/facture_achats/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Exporter les codes rejets du dashboard
         * @summary Exporter les codes rejets du dashboard
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {string} [dateFactureBefore] 
         * @param {string} [dateFactureStrictlyBefore] 
         * @param {string} [dateFactureAfter] 
         * @param {string} [dateFactureStrictlyAfter] 
         * @param {string} [createdAtBefore] 
         * @param {string} [createdAtStrictlyBefore] 
         * @param {string} [createdAtAfter] 
         * @param {string} [createdAtStrictlyAfter] 
         * @param {number} [idEntiteFacturante] 
         * @param {Array<number>} [idEntiteFacturante2] 
         * @param {number} [lignesIdDossier] 
         * @param {Array<number>} [lignesIdDossier2] 
         * @param {number} [idJal] 
         * @param {Array<number>} [idJal2] 
         * @param {string} [categories] 
         * @param {string} [codesRejets] 
         * @param {boolean} [cloture] 
         * @param {boolean} [deleted] 
         * @param {number} [idSociete] 
         * @param {Array<number>} [idSociete2] 
         * @param {string} [etats] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportCodesRejetsDashboardFactureAchatCollection: async (xIdSociete?: number, dateFactureBefore?: string, dateFactureStrictlyBefore?: string, dateFactureAfter?: string, dateFactureStrictlyAfter?: string, createdAtBefore?: string, createdAtStrictlyBefore?: string, createdAtAfter?: string, createdAtStrictlyAfter?: string, idEntiteFacturante?: number, idEntiteFacturante2?: Array<number>, lignesIdDossier?: number, lignesIdDossier2?: Array<number>, idJal?: number, idJal2?: Array<number>, categories?: string, codesRejets?: string, cloture?: boolean, deleted?: boolean, idSociete?: number, idSociete2?: Array<number>, etats?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/achats/facture_achat/export_codes_rejets_dashboard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (dateFactureBefore !== undefined) {
                localVarQueryParameter['dateFacture[before]'] = dateFactureBefore;
            }

            if (dateFactureStrictlyBefore !== undefined) {
                localVarQueryParameter['dateFacture[strictly_before]'] = dateFactureStrictlyBefore;
            }

            if (dateFactureAfter !== undefined) {
                localVarQueryParameter['dateFacture[after]'] = dateFactureAfter;
            }

            if (dateFactureStrictlyAfter !== undefined) {
                localVarQueryParameter['dateFacture[strictly_after]'] = dateFactureStrictlyAfter;
            }

            if (createdAtBefore !== undefined) {
                localVarQueryParameter['createdAt[before]'] = createdAtBefore;
            }

            if (createdAtStrictlyBefore !== undefined) {
                localVarQueryParameter['createdAt[strictly_before]'] = createdAtStrictlyBefore;
            }

            if (createdAtAfter !== undefined) {
                localVarQueryParameter['createdAt[after]'] = createdAtAfter;
            }

            if (createdAtStrictlyAfter !== undefined) {
                localVarQueryParameter['createdAt[strictly_after]'] = createdAtStrictlyAfter;
            }

            if (idEntiteFacturante !== undefined) {
                localVarQueryParameter['idEntiteFacturante'] = idEntiteFacturante;
            }

            if (idEntiteFacturante2) {
                localVarQueryParameter['idEntiteFacturante[]'] = idEntiteFacturante2;
            }

            if (lignesIdDossier !== undefined) {
                localVarQueryParameter['lignes.idDossier'] = lignesIdDossier;
            }

            if (lignesIdDossier2) {
                localVarQueryParameter['lignes.idDossier[]'] = lignesIdDossier2;
            }

            if (idJal !== undefined) {
                localVarQueryParameter['idJal'] = idJal;
            }

            if (idJal2) {
                localVarQueryParameter['idJal[]'] = idJal2;
            }

            if (categories !== undefined) {
                localVarQueryParameter['categories'] = categories;
            }

            if (codesRejets !== undefined) {
                localVarQueryParameter['codesRejets'] = codesRejets;
            }

            if (cloture !== undefined) {
                localVarQueryParameter['cloture'] = cloture;
            }

            if (deleted !== undefined) {
                localVarQueryParameter['deleted'] = deleted;
            }

            if (idSociete !== undefined) {
                localVarQueryParameter['idSociete'] = idSociete;
            }

            if (idSociete2) {
                localVarQueryParameter['idSociete[]'] = idSociete2;
            }

            if (etats !== undefined) {
                localVarQueryParameter['etats'] = etats;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Exporter les codes rejets d\'une facture multi-annonces
         * @summary Exporter les codes rejets d\'une facture multi-annonces
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {string} [numeroFacture] 
         * @param {Array<string>} [numeroFacture2] 
         * @param {number} [idSociete] 
         * @param {Array<number>} [idSociete2] 
         * @param {string} [etats] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportCodesRejetsRecapFactureAchatCollection: async (xIdSociete?: number, numeroFacture?: string, numeroFacture2?: Array<string>, idSociete?: number, idSociete2?: Array<number>, etats?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/achats/facture_achat/export_codes_rejets_recap`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (numeroFacture !== undefined) {
                localVarQueryParameter['numeroFacture'] = numeroFacture;
            }

            if (numeroFacture2) {
                localVarQueryParameter['numeroFacture[]'] = numeroFacture2;
            }

            if (idSociete !== undefined) {
                localVarQueryParameter['idSociete'] = idSociete;
            }

            if (idSociete2) {
                localVarQueryParameter['idSociete[]'] = idSociete2;
            }

            if (etats !== undefined) {
                localVarQueryParameter['etats'] = etats;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer la liste des stats des categories des factures d\'achats
         * @summary Récupérer la liste des stats des categories des factures d\'achats
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {string} [dateFactureBefore] 
         * @param {string} [dateFactureStrictlyBefore] 
         * @param {string} [dateFactureAfter] 
         * @param {string} [dateFactureStrictlyAfter] 
         * @param {string} [createdAtBefore] 
         * @param {string} [createdAtStrictlyBefore] 
         * @param {string} [createdAtAfter] 
         * @param {string} [createdAtStrictlyAfter] 
         * @param {number} [idEntiteFacturante] 
         * @param {Array<number>} [idEntiteFacturante2] 
         * @param {number} [lignesIdDossier] 
         * @param {Array<number>} [lignesIdDossier2] 
         * @param {number} [idJal] 
         * @param {Array<number>} [idJal2] 
         * @param {number} [idSociete] 
         * @param {Array<number>} [idSociete2] 
         * @param {string} [categories] 
         * @param {string} [codesRejets] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategorieFaStatsFactureAchatCollection: async (xIdSociete?: number, dateFactureBefore?: string, dateFactureStrictlyBefore?: string, dateFactureAfter?: string, dateFactureStrictlyAfter?: string, createdAtBefore?: string, createdAtStrictlyBefore?: string, createdAtAfter?: string, createdAtStrictlyAfter?: string, idEntiteFacturante?: number, idEntiteFacturante2?: Array<number>, lignesIdDossier?: number, lignesIdDossier2?: Array<number>, idJal?: number, idJal2?: Array<number>, idSociete?: number, idSociete2?: Array<number>, categories?: string, codesRejets?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/achats/facture_achat/categorie_stats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (dateFactureBefore !== undefined) {
                localVarQueryParameter['dateFacture[before]'] = dateFactureBefore;
            }

            if (dateFactureStrictlyBefore !== undefined) {
                localVarQueryParameter['dateFacture[strictly_before]'] = dateFactureStrictlyBefore;
            }

            if (dateFactureAfter !== undefined) {
                localVarQueryParameter['dateFacture[after]'] = dateFactureAfter;
            }

            if (dateFactureStrictlyAfter !== undefined) {
                localVarQueryParameter['dateFacture[strictly_after]'] = dateFactureStrictlyAfter;
            }

            if (createdAtBefore !== undefined) {
                localVarQueryParameter['createdAt[before]'] = createdAtBefore;
            }

            if (createdAtStrictlyBefore !== undefined) {
                localVarQueryParameter['createdAt[strictly_before]'] = createdAtStrictlyBefore;
            }

            if (createdAtAfter !== undefined) {
                localVarQueryParameter['createdAt[after]'] = createdAtAfter;
            }

            if (createdAtStrictlyAfter !== undefined) {
                localVarQueryParameter['createdAt[strictly_after]'] = createdAtStrictlyAfter;
            }

            if (idEntiteFacturante !== undefined) {
                localVarQueryParameter['idEntiteFacturante'] = idEntiteFacturante;
            }

            if (idEntiteFacturante2) {
                localVarQueryParameter['idEntiteFacturante[]'] = idEntiteFacturante2;
            }

            if (lignesIdDossier !== undefined) {
                localVarQueryParameter['lignes.idDossier'] = lignesIdDossier;
            }

            if (lignesIdDossier2) {
                localVarQueryParameter['lignes.idDossier[]'] = lignesIdDossier2;
            }

            if (idJal !== undefined) {
                localVarQueryParameter['idJal'] = idJal;
            }

            if (idJal2) {
                localVarQueryParameter['idJal[]'] = idJal2;
            }

            if (idSociete !== undefined) {
                localVarQueryParameter['idSociete'] = idSociete;
            }

            if (idSociete2) {
                localVarQueryParameter['idSociete[]'] = idSociete2;
            }

            if (categories !== undefined) {
                localVarQueryParameter['categories'] = categories;
            }

            if (codesRejets !== undefined) {
                localVarQueryParameter['codesRejets'] = codesRejets;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer la liste des stats des codes rejets des factures d\'achats
         * @summary Récupérer la liste des stats des codes rejets des factures d\'achats
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {string} [dateFactureBefore] 
         * @param {string} [dateFactureStrictlyBefore] 
         * @param {string} [dateFactureAfter] 
         * @param {string} [dateFactureStrictlyAfter] 
         * @param {string} [createdAtBefore] 
         * @param {string} [createdAtStrictlyBefore] 
         * @param {string} [createdAtAfter] 
         * @param {string} [createdAtStrictlyAfter] 
         * @param {number} [idEntiteFacturante] 
         * @param {Array<number>} [idEntiteFacturante2] 
         * @param {number} [lignesIdDossier] 
         * @param {Array<number>} [lignesIdDossier2] 
         * @param {number} [idJal] 
         * @param {Array<number>} [idJal2] 
         * @param {number} [idSociete] 
         * @param {Array<number>} [idSociete2] 
         * @param {string} [categories] 
         * @param {string} [codesRejets] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCoderejetFaStatsFactureAchatCollection: async (xIdSociete?: number, dateFactureBefore?: string, dateFactureStrictlyBefore?: string, dateFactureAfter?: string, dateFactureStrictlyAfter?: string, createdAtBefore?: string, createdAtStrictlyBefore?: string, createdAtAfter?: string, createdAtStrictlyAfter?: string, idEntiteFacturante?: number, idEntiteFacturante2?: Array<number>, lignesIdDossier?: number, lignesIdDossier2?: Array<number>, idJal?: number, idJal2?: Array<number>, idSociete?: number, idSociete2?: Array<number>, categories?: string, codesRejets?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/achats/facture_achat/coderejet_stats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (dateFactureBefore !== undefined) {
                localVarQueryParameter['dateFacture[before]'] = dateFactureBefore;
            }

            if (dateFactureStrictlyBefore !== undefined) {
                localVarQueryParameter['dateFacture[strictly_before]'] = dateFactureStrictlyBefore;
            }

            if (dateFactureAfter !== undefined) {
                localVarQueryParameter['dateFacture[after]'] = dateFactureAfter;
            }

            if (dateFactureStrictlyAfter !== undefined) {
                localVarQueryParameter['dateFacture[strictly_after]'] = dateFactureStrictlyAfter;
            }

            if (createdAtBefore !== undefined) {
                localVarQueryParameter['createdAt[before]'] = createdAtBefore;
            }

            if (createdAtStrictlyBefore !== undefined) {
                localVarQueryParameter['createdAt[strictly_before]'] = createdAtStrictlyBefore;
            }

            if (createdAtAfter !== undefined) {
                localVarQueryParameter['createdAt[after]'] = createdAtAfter;
            }

            if (createdAtStrictlyAfter !== undefined) {
                localVarQueryParameter['createdAt[strictly_after]'] = createdAtStrictlyAfter;
            }

            if (idEntiteFacturante !== undefined) {
                localVarQueryParameter['idEntiteFacturante'] = idEntiteFacturante;
            }

            if (idEntiteFacturante2) {
                localVarQueryParameter['idEntiteFacturante[]'] = idEntiteFacturante2;
            }

            if (lignesIdDossier !== undefined) {
                localVarQueryParameter['lignes.idDossier'] = lignesIdDossier;
            }

            if (lignesIdDossier2) {
                localVarQueryParameter['lignes.idDossier[]'] = lignesIdDossier2;
            }

            if (idJal !== undefined) {
                localVarQueryParameter['idJal'] = idJal;
            }

            if (idJal2) {
                localVarQueryParameter['idJal[]'] = idJal2;
            }

            if (idSociete !== undefined) {
                localVarQueryParameter['idSociete'] = idSociete;
            }

            if (idSociete2) {
                localVarQueryParameter['idSociete[]'] = idSociete2;
            }

            if (categories !== undefined) {
                localVarQueryParameter['categories'] = categories;
            }

            if (codesRejets !== undefined) {
                localVarQueryParameter['codesRejets'] = codesRejets;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer la liste des dossiers associés à une facture d\'achat
         * @summary Récupérer la liste des dossiers associés à une facture d\'achat
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDossiersFactureAchatItem: async (id: string, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDossiersFactureAchatItem', 'id', id)
            const localVarPath = `/achats/facture_achats/{id}/dossiers`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'un code rejet associé à une facture d\'achat
         * @summary Récupérer les informations d\'un code rejet associé à une facture d\'achat
         * @param {number} facture Identifiant de la facture d\&#39;achat
         * @param {string} codeRejet Identifiant du code rejet
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFactureAchatCodeRejetFactureAchatCodeRejetItem: async (facture: number, codeRejet: string, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'facture' is not null or undefined
            assertParamExists('getFactureAchatCodeRejetFactureAchatCodeRejetItem', 'facture', facture)
            // verify required parameter 'codeRejet' is not null or undefined
            assertParamExists('getFactureAchatCodeRejetFactureAchatCodeRejetItem', 'codeRejet', codeRejet)
            const localVarPath = `/achats/facture_achats/{facture}/code_rejets/{codeRejet}/etat`
                .replace(`{${"facture"}}`, encodeURIComponent(String(facture)))
                .replace(`{${"codeRejet"}}`, encodeURIComponent(String(codeRejet)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'un code rejet associé à une facture d\'achat
         * @summary Récupérer les informations d\'un code rejet associé à une facture d\'achat
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {number} [factureId] 
         * @param {Array<number>} [factureId2] 
         * @param {number} [idDossier] 
         * @param {Array<number>} [idDossier2] 
         * @param {number} [factureIdSociete] 
         * @param {Array<number>} [factureIdSociete2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFactureAchatCodesRejetsFactureAchatCodeRejetCollection: async (xIdSociete?: number, page?: number, itemsPerPage?: number, factureId?: number, factureId2?: Array<number>, idDossier?: number, idDossier2?: Array<number>, factureIdSociete?: number, factureIdSociete2?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/achats/facture_achat_code_rejets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (factureId !== undefined) {
                localVarQueryParameter['facture.id'] = factureId;
            }

            if (factureId2) {
                localVarQueryParameter['facture.id[]'] = factureId2;
            }

            if (idDossier !== undefined) {
                localVarQueryParameter['idDossier'] = idDossier;
            }

            if (idDossier2) {
                localVarQueryParameter['idDossier[]'] = idDossier2;
            }

            if (factureIdSociete !== undefined) {
                localVarQueryParameter['facture.idSociete'] = factureIdSociete;
            }

            if (factureIdSociete2) {
                localVarQueryParameter['facture.idSociete[]'] = factureIdSociete2;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'une facture d\'achat
         * @summary Récupérer les informations d\'une facture d\'achat
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFactureAchatFactureAchatItem: async (id: string, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFactureAchatFactureAchatItem', 'id', id)
            const localVarPath = `/achats/facture_achats/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer la liste des factures d\'achats
         * @summary Récupérer la liste des factures d\'achats
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {number} [idSociete] 
         * @param {Array<number>} [idSociete2] 
         * @param {number} [idReleveAchat] 
         * @param {Array<number>} [idReleveAchat2] 
         * @param {boolean} [existsCodesRejets] 
         * @param {boolean} [cloture] 
         * @param {boolean} [syncG3] 
         * @param {boolean} [deleted] 
         * @param {boolean} [multiAnnonces] 
         * @param {string} [dateFactureBefore] 
         * @param {string} [dateFactureStrictlyBefore] 
         * @param {string} [dateFactureAfter] 
         * @param {string} [dateFactureStrictlyAfter] 
         * @param {string} [createdAtBefore] 
         * @param {string} [createdAtStrictlyBefore] 
         * @param {string} [createdAtAfter] 
         * @param {string} [createdAtStrictlyAfter] 
         * @param {string} [libelleFacturante] 
         * @param {string} [lignesNumeroDossier] 
         * @param {string} [lignesNumeroPrestation] 
         * @param {string} [updatedBy] 
         * @param {Array<string>} [updatedBy2] 
         * @param {number} [idEntiteFacturante] 
         * @param {Array<number>} [idEntiteFacturante2] 
         * @param {number} [lignesIdDossier] 
         * @param {Array<number>} [lignesIdDossier2] 
         * @param {number} [lignesIdPrestation] 
         * @param {Array<number>} [lignesIdPrestation2] 
         * @param {number} [idJal] 
         * @param {Array<number>} [idJal2] 
         * @param {string} [numeroFacture] 
         * @param {string} [codesRejetsCodeRejetCategorie] 
         * @param {Array<string>} [codesRejetsCodeRejetCategorie2] 
         * @param {string} [codesRejetsCodeRejetCode] 
         * @param {Array<string>} [codesRejetsCodeRejetCode2] 
         * @param {string} [codesRejetsEtat] 
         * @param {Array<string>} [codesRejetsEtat2] 
         * @param {string} [recherche] 
         * @param {'asc' | 'desc'} [orderId] 
         * @param {'asc' | 'desc'} [orderUpdatedAt] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFacturesAchatsFactureAchatCollection: async (xIdSociete?: number, page?: number, itemsPerPage?: number, idSociete?: number, idSociete2?: Array<number>, idReleveAchat?: number, idReleveAchat2?: Array<number>, existsCodesRejets?: boolean, cloture?: boolean, syncG3?: boolean, deleted?: boolean, multiAnnonces?: boolean, dateFactureBefore?: string, dateFactureStrictlyBefore?: string, dateFactureAfter?: string, dateFactureStrictlyAfter?: string, createdAtBefore?: string, createdAtStrictlyBefore?: string, createdAtAfter?: string, createdAtStrictlyAfter?: string, libelleFacturante?: string, lignesNumeroDossier?: string, lignesNumeroPrestation?: string, updatedBy?: string, updatedBy2?: Array<string>, idEntiteFacturante?: number, idEntiteFacturante2?: Array<number>, lignesIdDossier?: number, lignesIdDossier2?: Array<number>, lignesIdPrestation?: number, lignesIdPrestation2?: Array<number>, idJal?: number, idJal2?: Array<number>, numeroFacture?: string, codesRejetsCodeRejetCategorie?: string, codesRejetsCodeRejetCategorie2?: Array<string>, codesRejetsCodeRejetCode?: string, codesRejetsCodeRejetCode2?: Array<string>, codesRejetsEtat?: string, codesRejetsEtat2?: Array<string>, recherche?: string, orderId?: 'asc' | 'desc', orderUpdatedAt?: 'asc' | 'desc', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/achats/facture_achats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (idSociete !== undefined) {
                localVarQueryParameter['idSociete'] = idSociete;
            }

            if (idSociete2) {
                localVarQueryParameter['idSociete[]'] = idSociete2;
            }

            if (idReleveAchat !== undefined) {
                localVarQueryParameter['idReleveAchat'] = idReleveAchat;
            }

            if (idReleveAchat2) {
                localVarQueryParameter['idReleveAchat[]'] = idReleveAchat2;
            }

            if (existsCodesRejets !== undefined) {
                localVarQueryParameter['exists[codesRejets]'] = existsCodesRejets;
            }

            if (cloture !== undefined) {
                localVarQueryParameter['cloture'] = cloture;
            }

            if (syncG3 !== undefined) {
                localVarQueryParameter['syncG3'] = syncG3;
            }

            if (deleted !== undefined) {
                localVarQueryParameter['deleted'] = deleted;
            }

            if (multiAnnonces !== undefined) {
                localVarQueryParameter['multiAnnonces'] = multiAnnonces;
            }

            if (dateFactureBefore !== undefined) {
                localVarQueryParameter['dateFacture[before]'] = dateFactureBefore;
            }

            if (dateFactureStrictlyBefore !== undefined) {
                localVarQueryParameter['dateFacture[strictly_before]'] = dateFactureStrictlyBefore;
            }

            if (dateFactureAfter !== undefined) {
                localVarQueryParameter['dateFacture[after]'] = dateFactureAfter;
            }

            if (dateFactureStrictlyAfter !== undefined) {
                localVarQueryParameter['dateFacture[strictly_after]'] = dateFactureStrictlyAfter;
            }

            if (createdAtBefore !== undefined) {
                localVarQueryParameter['createdAt[before]'] = createdAtBefore;
            }

            if (createdAtStrictlyBefore !== undefined) {
                localVarQueryParameter['createdAt[strictly_before]'] = createdAtStrictlyBefore;
            }

            if (createdAtAfter !== undefined) {
                localVarQueryParameter['createdAt[after]'] = createdAtAfter;
            }

            if (createdAtStrictlyAfter !== undefined) {
                localVarQueryParameter['createdAt[strictly_after]'] = createdAtStrictlyAfter;
            }

            if (libelleFacturante !== undefined) {
                localVarQueryParameter['libelleFacturante'] = libelleFacturante;
            }

            if (lignesNumeroDossier !== undefined) {
                localVarQueryParameter['lignes.numeroDossier'] = lignesNumeroDossier;
            }

            if (lignesNumeroPrestation !== undefined) {
                localVarQueryParameter['lignes.numeroPrestation'] = lignesNumeroPrestation;
            }

            if (updatedBy !== undefined) {
                localVarQueryParameter['updatedBy'] = updatedBy;
            }

            if (updatedBy2) {
                localVarQueryParameter['updatedBy[]'] = updatedBy2;
            }

            if (idEntiteFacturante !== undefined) {
                localVarQueryParameter['idEntiteFacturante'] = idEntiteFacturante;
            }

            if (idEntiteFacturante2) {
                localVarQueryParameter['idEntiteFacturante[]'] = idEntiteFacturante2;
            }

            if (lignesIdDossier !== undefined) {
                localVarQueryParameter['lignes.idDossier'] = lignesIdDossier;
            }

            if (lignesIdDossier2) {
                localVarQueryParameter['lignes.idDossier[]'] = lignesIdDossier2;
            }

            if (lignesIdPrestation !== undefined) {
                localVarQueryParameter['lignes.idPrestation'] = lignesIdPrestation;
            }

            if (lignesIdPrestation2) {
                localVarQueryParameter['lignes.idPrestation[]'] = lignesIdPrestation2;
            }

            if (idJal !== undefined) {
                localVarQueryParameter['idJal'] = idJal;
            }

            if (idJal2) {
                localVarQueryParameter['idJal[]'] = idJal2;
            }

            if (numeroFacture !== undefined) {
                localVarQueryParameter['numeroFacture'] = numeroFacture;
            }

            if (codesRejetsCodeRejetCategorie !== undefined) {
                localVarQueryParameter['codesRejets.codeRejet.categorie'] = codesRejetsCodeRejetCategorie;
            }

            if (codesRejetsCodeRejetCategorie2) {
                localVarQueryParameter['codesRejets.codeRejet.categorie[]'] = codesRejetsCodeRejetCategorie2;
            }

            if (codesRejetsCodeRejetCode !== undefined) {
                localVarQueryParameter['codesRejets.codeRejet.code'] = codesRejetsCodeRejetCode;
            }

            if (codesRejetsCodeRejetCode2) {
                localVarQueryParameter['codesRejets.codeRejet.code[]'] = codesRejetsCodeRejetCode2;
            }

            if (codesRejetsEtat !== undefined) {
                localVarQueryParameter['codesRejets.etat'] = codesRejetsEtat;
            }

            if (codesRejetsEtat2) {
                localVarQueryParameter['codesRejets.etat[]'] = codesRejetsEtat2;
            }

            if (recherche !== undefined) {
                localVarQueryParameter['recherche'] = recherche;
            }

            if (orderId !== undefined) {
                localVarQueryParameter['order[id]'] = orderId;
            }

            if (orderUpdatedAt !== undefined) {
                localVarQueryParameter['order[updatedAt]'] = orderUpdatedAt;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Ajouter une information à une facture d\'achat
         * @summary Ajouter une information à une facture d\'achat
         * @param {string} id Resource identifier
         * @param {FactureAchatFactureAchatWrite} factureAchatFactureAchatWrite The updated FactureAchat resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchFactureAchatFactureAchatItem: async (id: string, factureAchatFactureAchatWrite: FactureAchatFactureAchatWrite, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchFactureAchatFactureAchatItem', 'id', id)
            // verify required parameter 'factureAchatFactureAchatWrite' is not null or undefined
            assertParamExists('patchFactureAchatFactureAchatItem', 'factureAchatFactureAchatWrite', factureAchatFactureAchatWrite)
            const localVarPath = `/achats/facture_achats/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(factureAchatFactureAchatWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Insérer une facture d\'achat dans G3
         * @summary Insérer une facture d\'achat dans G3
         * @param {string} id Resource identifier
         * @param {FactureAchatJsonldFactureAchatSyncG3} factureAchatJsonldFactureAchatSyncG3 The updated FactureAchat resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncG3FactureAchatFactureAchatItem: async (id: string, factureAchatJsonldFactureAchatSyncG3: FactureAchatJsonldFactureAchatSyncG3, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('syncG3FactureAchatFactureAchatItem', 'id', id)
            // verify required parameter 'factureAchatJsonldFactureAchatSyncG3' is not null or undefined
            assertParamExists('syncG3FactureAchatFactureAchatItem', 'factureAchatJsonldFactureAchatSyncG3', factureAchatJsonldFactureAchatSyncG3)
            const localVarPath = `/achats/facture_achats/{id}/sync_g3`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(factureAchatJsonldFactureAchatSyncG3, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Mettre à jour l\'état d\'un code rejet associé à une facture d\'achat
         * @summary Mettre à jour l\'état d\'un code rejet associé à une facture d\'achat
         * @param {number} facture Identifiant de la facture d\&#39;achat
         * @param {string} codeRejet Identifiant du code rejet
         * @param {FactureAchatCodeRejetJsonldFactureAchatCodeRejetUpdateEtat} factureAchatCodeRejetJsonldFactureAchatCodeRejetUpdateEtat The updated FactureAchatCodeRejet resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEtatFactureAchatCodeRejetFactureAchatCodeRejetItem: async (facture: number, codeRejet: string, factureAchatCodeRejetJsonldFactureAchatCodeRejetUpdateEtat: FactureAchatCodeRejetJsonldFactureAchatCodeRejetUpdateEtat, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'facture' is not null or undefined
            assertParamExists('updateEtatFactureAchatCodeRejetFactureAchatCodeRejetItem', 'facture', facture)
            // verify required parameter 'codeRejet' is not null or undefined
            assertParamExists('updateEtatFactureAchatCodeRejetFactureAchatCodeRejetItem', 'codeRejet', codeRejet)
            // verify required parameter 'factureAchatCodeRejetJsonldFactureAchatCodeRejetUpdateEtat' is not null or undefined
            assertParamExists('updateEtatFactureAchatCodeRejetFactureAchatCodeRejetItem', 'factureAchatCodeRejetJsonldFactureAchatCodeRejetUpdateEtat', factureAchatCodeRejetJsonldFactureAchatCodeRejetUpdateEtat)
            const localVarPath = `/achats/facture_achats/{facture}/code_rejets/{codeRejet}/etat`
                .replace(`{${"facture"}}`, encodeURIComponent(String(facture)))
                .replace(`{${"codeRejet"}}`, encodeURIComponent(String(codeRejet)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(factureAchatCodeRejetJsonldFactureAchatCodeRejetUpdateEtat, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Mettre à jour une facture d\'achat
         * @summary Mettre à jour une facture d\'achat
         * @param {string} id Resource identifier
         * @param {FactureAchatJsonldFactureAchatWrite} factureAchatJsonldFactureAchatWrite The updated FactureAchat resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFactureAchatFactureAchatItem: async (id: string, factureAchatJsonldFactureAchatWrite: FactureAchatJsonldFactureAchatWrite, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateFactureAchatFactureAchatItem', 'id', id)
            // verify required parameter 'factureAchatJsonldFactureAchatWrite' is not null or undefined
            assertParamExists('updateFactureAchatFactureAchatItem', 'factureAchatJsonldFactureAchatWrite', factureAchatJsonldFactureAchatWrite)
            const localVarPath = `/achats/facture_achats/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(factureAchatJsonldFactureAchatWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FactureAchatApi - functional programming interface
 * @export
 */
export const FactureAchatApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FactureAchatApiAxiosParamCreator(configuration)
    return {
        /**
         * Associe un document à la facture
         * @summary Associe un document à la facture
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {AddAttachmentFactureAchatItemRequest} [addAttachmentFactureAchatItemRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addAttachmentFactureAchatItem(id: string, xIdSociete?: number, addAttachmentFactureAchatItemRequest?: AddAttachmentFactureAchatItemRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addAttachmentFactureAchatItem(id, xIdSociete, addAttachmentFactureAchatItemRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Créer un facture d\'achat
         * @summary Créer un facture d\'achat
         * @param {FactureAchatJsonldFactureAchatCreateFactureAchatWrite} factureAchatJsonldFactureAchatCreateFactureAchatWrite The new FactureAchat resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFactureAchatFactureAchatCollection(factureAchatJsonldFactureAchatCreateFactureAchatWrite: FactureAchatJsonldFactureAchatCreateFactureAchatWrite, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFactureAchatFactureAchatCollection(factureAchatJsonldFactureAchatCreateFactureAchatWrite, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Supprimer une facture d\'achat
         * @summary Supprimer une facture d\'achat
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFactureAchatFactureAchatItem(id: string, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFactureAchatFactureAchatItem(id, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Exporter les codes rejets du dashboard
         * @summary Exporter les codes rejets du dashboard
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {string} [dateFactureBefore] 
         * @param {string} [dateFactureStrictlyBefore] 
         * @param {string} [dateFactureAfter] 
         * @param {string} [dateFactureStrictlyAfter] 
         * @param {string} [createdAtBefore] 
         * @param {string} [createdAtStrictlyBefore] 
         * @param {string} [createdAtAfter] 
         * @param {string} [createdAtStrictlyAfter] 
         * @param {number} [idEntiteFacturante] 
         * @param {Array<number>} [idEntiteFacturante2] 
         * @param {number} [lignesIdDossier] 
         * @param {Array<number>} [lignesIdDossier2] 
         * @param {number} [idJal] 
         * @param {Array<number>} [idJal2] 
         * @param {string} [categories] 
         * @param {string} [codesRejets] 
         * @param {boolean} [cloture] 
         * @param {boolean} [deleted] 
         * @param {number} [idSociete] 
         * @param {Array<number>} [idSociete2] 
         * @param {string} [etats] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportCodesRejetsDashboardFactureAchatCollection(xIdSociete?: number, dateFactureBefore?: string, dateFactureStrictlyBefore?: string, dateFactureAfter?: string, dateFactureStrictlyAfter?: string, createdAtBefore?: string, createdAtStrictlyBefore?: string, createdAtAfter?: string, createdAtStrictlyAfter?: string, idEntiteFacturante?: number, idEntiteFacturante2?: Array<number>, lignesIdDossier?: number, lignesIdDossier2?: Array<number>, idJal?: number, idJal2?: Array<number>, categories?: string, codesRejets?: string, cloture?: boolean, deleted?: boolean, idSociete?: number, idSociete2?: Array<number>, etats?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExportCodesRejetsDashboardFactureAchatCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportCodesRejetsDashboardFactureAchatCollection(xIdSociete, dateFactureBefore, dateFactureStrictlyBefore, dateFactureAfter, dateFactureStrictlyAfter, createdAtBefore, createdAtStrictlyBefore, createdAtAfter, createdAtStrictlyAfter, idEntiteFacturante, idEntiteFacturante2, lignesIdDossier, lignesIdDossier2, idJal, idJal2, categories, codesRejets, cloture, deleted, idSociete, idSociete2, etats, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Exporter les codes rejets d\'une facture multi-annonces
         * @summary Exporter les codes rejets d\'une facture multi-annonces
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {string} [numeroFacture] 
         * @param {Array<string>} [numeroFacture2] 
         * @param {number} [idSociete] 
         * @param {Array<number>} [idSociete2] 
         * @param {string} [etats] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportCodesRejetsRecapFactureAchatCollection(xIdSociete?: number, numeroFacture?: string, numeroFacture2?: Array<string>, idSociete?: number, idSociete2?: Array<number>, etats?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExportCodesRejetsRecapFactureAchatCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportCodesRejetsRecapFactureAchatCollection(xIdSociete, numeroFacture, numeroFacture2, idSociete, idSociete2, etats, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Récupérer la liste des stats des categories des factures d\'achats
         * @summary Récupérer la liste des stats des categories des factures d\'achats
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {string} [dateFactureBefore] 
         * @param {string} [dateFactureStrictlyBefore] 
         * @param {string} [dateFactureAfter] 
         * @param {string} [dateFactureStrictlyAfter] 
         * @param {string} [createdAtBefore] 
         * @param {string} [createdAtStrictlyBefore] 
         * @param {string} [createdAtAfter] 
         * @param {string} [createdAtStrictlyAfter] 
         * @param {number} [idEntiteFacturante] 
         * @param {Array<number>} [idEntiteFacturante2] 
         * @param {number} [lignesIdDossier] 
         * @param {Array<number>} [lignesIdDossier2] 
         * @param {number} [idJal] 
         * @param {Array<number>} [idJal2] 
         * @param {number} [idSociete] 
         * @param {Array<number>} [idSociete2] 
         * @param {string} [categories] 
         * @param {string} [codesRejets] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCategorieFaStatsFactureAchatCollection(xIdSociete?: number, dateFactureBefore?: string, dateFactureStrictlyBefore?: string, dateFactureAfter?: string, dateFactureStrictlyAfter?: string, createdAtBefore?: string, createdAtStrictlyBefore?: string, createdAtAfter?: string, createdAtStrictlyAfter?: string, idEntiteFacturante?: number, idEntiteFacturante2?: Array<number>, lignesIdDossier?: number, lignesIdDossier2?: Array<number>, idJal?: number, idJal2?: Array<number>, idSociete?: number, idSociete2?: Array<number>, categories?: string, codesRejets?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCategorieFaStatsFactureAchatCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCategorieFaStatsFactureAchatCollection(xIdSociete, dateFactureBefore, dateFactureStrictlyBefore, dateFactureAfter, dateFactureStrictlyAfter, createdAtBefore, createdAtStrictlyBefore, createdAtAfter, createdAtStrictlyAfter, idEntiteFacturante, idEntiteFacturante2, lignesIdDossier, lignesIdDossier2, idJal, idJal2, idSociete, idSociete2, categories, codesRejets, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Récupérer la liste des stats des codes rejets des factures d\'achats
         * @summary Récupérer la liste des stats des codes rejets des factures d\'achats
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {string} [dateFactureBefore] 
         * @param {string} [dateFactureStrictlyBefore] 
         * @param {string} [dateFactureAfter] 
         * @param {string} [dateFactureStrictlyAfter] 
         * @param {string} [createdAtBefore] 
         * @param {string} [createdAtStrictlyBefore] 
         * @param {string} [createdAtAfter] 
         * @param {string} [createdAtStrictlyAfter] 
         * @param {number} [idEntiteFacturante] 
         * @param {Array<number>} [idEntiteFacturante2] 
         * @param {number} [lignesIdDossier] 
         * @param {Array<number>} [lignesIdDossier2] 
         * @param {number} [idJal] 
         * @param {Array<number>} [idJal2] 
         * @param {number} [idSociete] 
         * @param {Array<number>} [idSociete2] 
         * @param {string} [categories] 
         * @param {string} [codesRejets] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCoderejetFaStatsFactureAchatCollection(xIdSociete?: number, dateFactureBefore?: string, dateFactureStrictlyBefore?: string, dateFactureAfter?: string, dateFactureStrictlyAfter?: string, createdAtBefore?: string, createdAtStrictlyBefore?: string, createdAtAfter?: string, createdAtStrictlyAfter?: string, idEntiteFacturante?: number, idEntiteFacturante2?: Array<number>, lignesIdDossier?: number, lignesIdDossier2?: Array<number>, idJal?: number, idJal2?: Array<number>, idSociete?: number, idSociete2?: Array<number>, categories?: string, codesRejets?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCoderejetFaStatsFactureAchatCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCoderejetFaStatsFactureAchatCollection(xIdSociete, dateFactureBefore, dateFactureStrictlyBefore, dateFactureAfter, dateFactureStrictlyAfter, createdAtBefore, createdAtStrictlyBefore, createdAtAfter, createdAtStrictlyAfter, idEntiteFacturante, idEntiteFacturante2, lignesIdDossier, lignesIdDossier2, idJal, idJal2, idSociete, idSociete2, categories, codesRejets, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Récupérer la liste des dossiers associés à une facture d\'achat
         * @summary Récupérer la liste des dossiers associés à une facture d\'achat
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDossiersFactureAchatItem(id: string, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FactureAchatJsonldFactureAchatReadDossier>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDossiersFactureAchatItem(id, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Récupérer les informations d\'un code rejet associé à une facture d\'achat
         * @summary Récupérer les informations d\'un code rejet associé à une facture d\'achat
         * @param {number} facture Identifiant de la facture d\&#39;achat
         * @param {string} codeRejet Identifiant du code rejet
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFactureAchatCodeRejetFactureAchatCodeRejetItem(facture: number, codeRejet: string, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FactureAchatCodeRejetJsonldFactureAchatCodeRejetReadTimestampableBlameambleGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFactureAchatCodeRejetFactureAchatCodeRejetItem(facture, codeRejet, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Récupérer les informations d\'un code rejet associé à une facture d\'achat
         * @summary Récupérer les informations d\'un code rejet associé à une facture d\'achat
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {number} [factureId] 
         * @param {Array<number>} [factureId2] 
         * @param {number} [idDossier] 
         * @param {Array<number>} [idDossier2] 
         * @param {number} [factureIdSociete] 
         * @param {Array<number>} [factureIdSociete2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFactureAchatCodesRejetsFactureAchatCodeRejetCollection(xIdSociete?: number, page?: number, itemsPerPage?: number, factureId?: number, factureId2?: Array<number>, idDossier?: number, idDossier2?: Array<number>, factureIdSociete?: number, factureIdSociete2?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetFactureAchatCodesRejetsFactureAchatCodeRejetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFactureAchatCodesRejetsFactureAchatCodeRejetCollection(xIdSociete, page, itemsPerPage, factureId, factureId2, idDossier, idDossier2, factureIdSociete, factureIdSociete2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Récupérer les informations d\'une facture d\'achat
         * @summary Récupérer les informations d\'une facture d\'achat
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFactureAchatFactureAchatItem(id: string, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FactureAchatJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFactureAchatFactureAchatItem(id, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Récupérer la liste des factures d\'achats
         * @summary Récupérer la liste des factures d\'achats
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {number} [idSociete] 
         * @param {Array<number>} [idSociete2] 
         * @param {number} [idReleveAchat] 
         * @param {Array<number>} [idReleveAchat2] 
         * @param {boolean} [existsCodesRejets] 
         * @param {boolean} [cloture] 
         * @param {boolean} [syncG3] 
         * @param {boolean} [deleted] 
         * @param {boolean} [multiAnnonces] 
         * @param {string} [dateFactureBefore] 
         * @param {string} [dateFactureStrictlyBefore] 
         * @param {string} [dateFactureAfter] 
         * @param {string} [dateFactureStrictlyAfter] 
         * @param {string} [createdAtBefore] 
         * @param {string} [createdAtStrictlyBefore] 
         * @param {string} [createdAtAfter] 
         * @param {string} [createdAtStrictlyAfter] 
         * @param {string} [libelleFacturante] 
         * @param {string} [lignesNumeroDossier] 
         * @param {string} [lignesNumeroPrestation] 
         * @param {string} [updatedBy] 
         * @param {Array<string>} [updatedBy2] 
         * @param {number} [idEntiteFacturante] 
         * @param {Array<number>} [idEntiteFacturante2] 
         * @param {number} [lignesIdDossier] 
         * @param {Array<number>} [lignesIdDossier2] 
         * @param {number} [lignesIdPrestation] 
         * @param {Array<number>} [lignesIdPrestation2] 
         * @param {number} [idJal] 
         * @param {Array<number>} [idJal2] 
         * @param {string} [numeroFacture] 
         * @param {string} [codesRejetsCodeRejetCategorie] 
         * @param {Array<string>} [codesRejetsCodeRejetCategorie2] 
         * @param {string} [codesRejetsCodeRejetCode] 
         * @param {Array<string>} [codesRejetsCodeRejetCode2] 
         * @param {string} [codesRejetsEtat] 
         * @param {Array<string>} [codesRejetsEtat2] 
         * @param {string} [recherche] 
         * @param {'asc' | 'desc'} [orderId] 
         * @param {'asc' | 'desc'} [orderUpdatedAt] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFacturesAchatsFactureAchatCollection(xIdSociete?: number, page?: number, itemsPerPage?: number, idSociete?: number, idSociete2?: Array<number>, idReleveAchat?: number, idReleveAchat2?: Array<number>, existsCodesRejets?: boolean, cloture?: boolean, syncG3?: boolean, deleted?: boolean, multiAnnonces?: boolean, dateFactureBefore?: string, dateFactureStrictlyBefore?: string, dateFactureAfter?: string, dateFactureStrictlyAfter?: string, createdAtBefore?: string, createdAtStrictlyBefore?: string, createdAtAfter?: string, createdAtStrictlyAfter?: string, libelleFacturante?: string, lignesNumeroDossier?: string, lignesNumeroPrestation?: string, updatedBy?: string, updatedBy2?: Array<string>, idEntiteFacturante?: number, idEntiteFacturante2?: Array<number>, lignesIdDossier?: number, lignesIdDossier2?: Array<number>, lignesIdPrestation?: number, lignesIdPrestation2?: Array<number>, idJal?: number, idJal2?: Array<number>, numeroFacture?: string, codesRejetsCodeRejetCategorie?: string, codesRejetsCodeRejetCategorie2?: Array<string>, codesRejetsCodeRejetCode?: string, codesRejetsCodeRejetCode2?: Array<string>, codesRejetsEtat?: string, codesRejetsEtat2?: Array<string>, recherche?: string, orderId?: 'asc' | 'desc', orderUpdatedAt?: 'asc' | 'desc', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetFacturesAchatsFactureAchatCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFacturesAchatsFactureAchatCollection(xIdSociete, page, itemsPerPage, idSociete, idSociete2, idReleveAchat, idReleveAchat2, existsCodesRejets, cloture, syncG3, deleted, multiAnnonces, dateFactureBefore, dateFactureStrictlyBefore, dateFactureAfter, dateFactureStrictlyAfter, createdAtBefore, createdAtStrictlyBefore, createdAtAfter, createdAtStrictlyAfter, libelleFacturante, lignesNumeroDossier, lignesNumeroPrestation, updatedBy, updatedBy2, idEntiteFacturante, idEntiteFacturante2, lignesIdDossier, lignesIdDossier2, lignesIdPrestation, lignesIdPrestation2, idJal, idJal2, numeroFacture, codesRejetsCodeRejetCategorie, codesRejetsCodeRejetCategorie2, codesRejetsCodeRejetCode, codesRejetsCodeRejetCode2, codesRejetsEtat, codesRejetsEtat2, recherche, orderId, orderUpdatedAt, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Ajouter une information à une facture d\'achat
         * @summary Ajouter une information à une facture d\'achat
         * @param {string} id Resource identifier
         * @param {FactureAchatFactureAchatWrite} factureAchatFactureAchatWrite The updated FactureAchat resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchFactureAchatFactureAchatItem(id: string, factureAchatFactureAchatWrite: FactureAchatFactureAchatWrite, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchFactureAchatFactureAchatItem(id, factureAchatFactureAchatWrite, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Insérer une facture d\'achat dans G3
         * @summary Insérer une facture d\'achat dans G3
         * @param {string} id Resource identifier
         * @param {FactureAchatJsonldFactureAchatSyncG3} factureAchatJsonldFactureAchatSyncG3 The updated FactureAchat resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncG3FactureAchatFactureAchatItem(id: string, factureAchatJsonldFactureAchatSyncG3: FactureAchatJsonldFactureAchatSyncG3, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncG3FactureAchatFactureAchatItem(id, factureAchatJsonldFactureAchatSyncG3, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Mettre à jour l\'état d\'un code rejet associé à une facture d\'achat
         * @summary Mettre à jour l\'état d\'un code rejet associé à une facture d\'achat
         * @param {number} facture Identifiant de la facture d\&#39;achat
         * @param {string} codeRejet Identifiant du code rejet
         * @param {FactureAchatCodeRejetJsonldFactureAchatCodeRejetUpdateEtat} factureAchatCodeRejetJsonldFactureAchatCodeRejetUpdateEtat The updated FactureAchatCodeRejet resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEtatFactureAchatCodeRejetFactureAchatCodeRejetItem(facture: number, codeRejet: string, factureAchatCodeRejetJsonldFactureAchatCodeRejetUpdateEtat: FactureAchatCodeRejetJsonldFactureAchatCodeRejetUpdateEtat, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FactureAchatCodeRejetJsonldFactureAchatCodeRejetReadTimestampableBlameambleGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEtatFactureAchatCodeRejetFactureAchatCodeRejetItem(facture, codeRejet, factureAchatCodeRejetJsonldFactureAchatCodeRejetUpdateEtat, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Mettre à jour une facture d\'achat
         * @summary Mettre à jour une facture d\'achat
         * @param {string} id Resource identifier
         * @param {FactureAchatJsonldFactureAchatWrite} factureAchatJsonldFactureAchatWrite The updated FactureAchat resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFactureAchatFactureAchatItem(id: string, factureAchatJsonldFactureAchatWrite: FactureAchatJsonldFactureAchatWrite, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFactureAchatFactureAchatItem(id, factureAchatJsonldFactureAchatWrite, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FactureAchatApi - factory interface
 * @export
 */
export const FactureAchatApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FactureAchatApiFp(configuration)
    return {
        /**
         * Associe un document à la facture
         * @summary Associe un document à la facture
         * @param {FactureAchatApiAddAttachmentFactureAchatItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAttachmentFactureAchatItem(requestParameters: FactureAchatApiAddAttachmentFactureAchatItemRequest, options?: AxiosRequestConfig): AxiosPromise<FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup> {
            return localVarFp.addAttachmentFactureAchatItem(requestParameters.id, requestParameters.xIdSociete, requestParameters.addAttachmentFactureAchatItemRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Créer un facture d\'achat
         * @summary Créer un facture d\'achat
         * @param {FactureAchatApiCreateFactureAchatFactureAchatCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFactureAchatFactureAchatCollection(requestParameters: FactureAchatApiCreateFactureAchatFactureAchatCollectionRequest, options?: AxiosRequestConfig): AxiosPromise<FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup> {
            return localVarFp.createFactureAchatFactureAchatCollection(requestParameters.factureAchatJsonldFactureAchatCreateFactureAchatWrite, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Supprimer une facture d\'achat
         * @summary Supprimer une facture d\'achat
         * @param {FactureAchatApiDeleteFactureAchatFactureAchatItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFactureAchatFactureAchatItem(requestParameters: FactureAchatApiDeleteFactureAchatFactureAchatItemRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteFactureAchatFactureAchatItem(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Exporter les codes rejets du dashboard
         * @summary Exporter les codes rejets du dashboard
         * @param {FactureAchatApiExportCodesRejetsDashboardFactureAchatCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportCodesRejetsDashboardFactureAchatCollection(requestParameters: FactureAchatApiExportCodesRejetsDashboardFactureAchatCollectionRequest = {}, options?: AxiosRequestConfig): AxiosPromise<ExportCodesRejetsDashboardFactureAchatCollection200Response> {
            return localVarFp.exportCodesRejetsDashboardFactureAchatCollection(requestParameters.xIdSociete, requestParameters.dateFactureBefore, requestParameters.dateFactureStrictlyBefore, requestParameters.dateFactureAfter, requestParameters.dateFactureStrictlyAfter, requestParameters.createdAtBefore, requestParameters.createdAtStrictlyBefore, requestParameters.createdAtAfter, requestParameters.createdAtStrictlyAfter, requestParameters.idEntiteFacturante, requestParameters.idEntiteFacturante2, requestParameters.lignesIdDossier, requestParameters.lignesIdDossier2, requestParameters.idJal, requestParameters.idJal2, requestParameters.categories, requestParameters.codesRejets, requestParameters.cloture, requestParameters.deleted, requestParameters.idSociete, requestParameters.idSociete2, requestParameters.etats, options).then((request) => request(axios, basePath));
        },
        /**
         * Exporter les codes rejets d\'une facture multi-annonces
         * @summary Exporter les codes rejets d\'une facture multi-annonces
         * @param {FactureAchatApiExportCodesRejetsRecapFactureAchatCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportCodesRejetsRecapFactureAchatCollection(requestParameters: FactureAchatApiExportCodesRejetsRecapFactureAchatCollectionRequest = {}, options?: AxiosRequestConfig): AxiosPromise<ExportCodesRejetsRecapFactureAchatCollection200Response> {
            return localVarFp.exportCodesRejetsRecapFactureAchatCollection(requestParameters.xIdSociete, requestParameters.numeroFacture, requestParameters.numeroFacture2, requestParameters.idSociete, requestParameters.idSociete2, requestParameters.etats, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer la liste des stats des categories des factures d\'achats
         * @summary Récupérer la liste des stats des categories des factures d\'achats
         * @param {FactureAchatApiGetCategorieFaStatsFactureAchatCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategorieFaStatsFactureAchatCollection(requestParameters: FactureAchatApiGetCategorieFaStatsFactureAchatCollectionRequest = {}, options?: AxiosRequestConfig): AxiosPromise<GetCategorieFaStatsFactureAchatCollection200Response> {
            return localVarFp.getCategorieFaStatsFactureAchatCollection(requestParameters.xIdSociete, requestParameters.dateFactureBefore, requestParameters.dateFactureStrictlyBefore, requestParameters.dateFactureAfter, requestParameters.dateFactureStrictlyAfter, requestParameters.createdAtBefore, requestParameters.createdAtStrictlyBefore, requestParameters.createdAtAfter, requestParameters.createdAtStrictlyAfter, requestParameters.idEntiteFacturante, requestParameters.idEntiteFacturante2, requestParameters.lignesIdDossier, requestParameters.lignesIdDossier2, requestParameters.idJal, requestParameters.idJal2, requestParameters.idSociete, requestParameters.idSociete2, requestParameters.categories, requestParameters.codesRejets, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer la liste des stats des codes rejets des factures d\'achats
         * @summary Récupérer la liste des stats des codes rejets des factures d\'achats
         * @param {FactureAchatApiGetCoderejetFaStatsFactureAchatCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCoderejetFaStatsFactureAchatCollection(requestParameters: FactureAchatApiGetCoderejetFaStatsFactureAchatCollectionRequest = {}, options?: AxiosRequestConfig): AxiosPromise<GetCoderejetFaStatsFactureAchatCollection200Response> {
            return localVarFp.getCoderejetFaStatsFactureAchatCollection(requestParameters.xIdSociete, requestParameters.dateFactureBefore, requestParameters.dateFactureStrictlyBefore, requestParameters.dateFactureAfter, requestParameters.dateFactureStrictlyAfter, requestParameters.createdAtBefore, requestParameters.createdAtStrictlyBefore, requestParameters.createdAtAfter, requestParameters.createdAtStrictlyAfter, requestParameters.idEntiteFacturante, requestParameters.idEntiteFacturante2, requestParameters.lignesIdDossier, requestParameters.lignesIdDossier2, requestParameters.idJal, requestParameters.idJal2, requestParameters.idSociete, requestParameters.idSociete2, requestParameters.categories, requestParameters.codesRejets, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer la liste des dossiers associés à une facture d\'achat
         * @summary Récupérer la liste des dossiers associés à une facture d\'achat
         * @param {FactureAchatApiGetDossiersFactureAchatItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDossiersFactureAchatItem(requestParameters: FactureAchatApiGetDossiersFactureAchatItemRequest, options?: AxiosRequestConfig): AxiosPromise<FactureAchatJsonldFactureAchatReadDossier> {
            return localVarFp.getDossiersFactureAchatItem(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'un code rejet associé à une facture d\'achat
         * @summary Récupérer les informations d\'un code rejet associé à une facture d\'achat
         * @param {FactureAchatApiGetFactureAchatCodeRejetFactureAchatCodeRejetItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFactureAchatCodeRejetFactureAchatCodeRejetItem(requestParameters: FactureAchatApiGetFactureAchatCodeRejetFactureAchatCodeRejetItemRequest, options?: AxiosRequestConfig): AxiosPromise<FactureAchatCodeRejetJsonldFactureAchatCodeRejetReadTimestampableBlameambleGroup> {
            return localVarFp.getFactureAchatCodeRejetFactureAchatCodeRejetItem(requestParameters.facture, requestParameters.codeRejet, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'un code rejet associé à une facture d\'achat
         * @summary Récupérer les informations d\'un code rejet associé à une facture d\'achat
         * @param {FactureAchatApiGetFactureAchatCodesRejetsFactureAchatCodeRejetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFactureAchatCodesRejetsFactureAchatCodeRejetCollection(requestParameters: FactureAchatApiGetFactureAchatCodesRejetsFactureAchatCodeRejetCollectionRequest = {}, options?: AxiosRequestConfig): AxiosPromise<GetFactureAchatCodesRejetsFactureAchatCodeRejetCollection200Response> {
            return localVarFp.getFactureAchatCodesRejetsFactureAchatCodeRejetCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, requestParameters.factureId, requestParameters.factureId2, requestParameters.idDossier, requestParameters.idDossier2, requestParameters.factureIdSociete, requestParameters.factureIdSociete2, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'une facture d\'achat
         * @summary Récupérer les informations d\'une facture d\'achat
         * @param {FactureAchatApiGetFactureAchatFactureAchatItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFactureAchatFactureAchatItem(requestParameters: FactureAchatApiGetFactureAchatFactureAchatItemRequest, options?: AxiosRequestConfig): AxiosPromise<FactureAchatJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup> {
            return localVarFp.getFactureAchatFactureAchatItem(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer la liste des factures d\'achats
         * @summary Récupérer la liste des factures d\'achats
         * @param {FactureAchatApiGetFacturesAchatsFactureAchatCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFacturesAchatsFactureAchatCollection(requestParameters: FactureAchatApiGetFacturesAchatsFactureAchatCollectionRequest = {}, options?: AxiosRequestConfig): AxiosPromise<GetFacturesAchatsFactureAchatCollection200Response> {
            return localVarFp.getFacturesAchatsFactureAchatCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, requestParameters.idSociete, requestParameters.idSociete2, requestParameters.idReleveAchat, requestParameters.idReleveAchat2, requestParameters.existsCodesRejets, requestParameters.cloture, requestParameters.syncG3, requestParameters.deleted, requestParameters.multiAnnonces, requestParameters.dateFactureBefore, requestParameters.dateFactureStrictlyBefore, requestParameters.dateFactureAfter, requestParameters.dateFactureStrictlyAfter, requestParameters.createdAtBefore, requestParameters.createdAtStrictlyBefore, requestParameters.createdAtAfter, requestParameters.createdAtStrictlyAfter, requestParameters.libelleFacturante, requestParameters.lignesNumeroDossier, requestParameters.lignesNumeroPrestation, requestParameters.updatedBy, requestParameters.updatedBy2, requestParameters.idEntiteFacturante, requestParameters.idEntiteFacturante2, requestParameters.lignesIdDossier, requestParameters.lignesIdDossier2, requestParameters.lignesIdPrestation, requestParameters.lignesIdPrestation2, requestParameters.idJal, requestParameters.idJal2, requestParameters.numeroFacture, requestParameters.codesRejetsCodeRejetCategorie, requestParameters.codesRejetsCodeRejetCategorie2, requestParameters.codesRejetsCodeRejetCode, requestParameters.codesRejetsCodeRejetCode2, requestParameters.codesRejetsEtat, requestParameters.codesRejetsEtat2, requestParameters.recherche, requestParameters.orderId, requestParameters.orderUpdatedAt, options).then((request) => request(axios, basePath));
        },
        /**
         * Ajouter une information à une facture d\'achat
         * @summary Ajouter une information à une facture d\'achat
         * @param {FactureAchatApiPatchFactureAchatFactureAchatItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchFactureAchatFactureAchatItem(requestParameters: FactureAchatApiPatchFactureAchatFactureAchatItemRequest, options?: AxiosRequestConfig): AxiosPromise<FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup> {
            return localVarFp.patchFactureAchatFactureAchatItem(requestParameters.id, requestParameters.factureAchatFactureAchatWrite, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Insérer une facture d\'achat dans G3
         * @summary Insérer une facture d\'achat dans G3
         * @param {FactureAchatApiSyncG3FactureAchatFactureAchatItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncG3FactureAchatFactureAchatItem(requestParameters: FactureAchatApiSyncG3FactureAchatFactureAchatItemRequest, options?: AxiosRequestConfig): AxiosPromise<FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup> {
            return localVarFp.syncG3FactureAchatFactureAchatItem(requestParameters.id, requestParameters.factureAchatJsonldFactureAchatSyncG3, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Mettre à jour l\'état d\'un code rejet associé à une facture d\'achat
         * @summary Mettre à jour l\'état d\'un code rejet associé à une facture d\'achat
         * @param {FactureAchatApiUpdateEtatFactureAchatCodeRejetFactureAchatCodeRejetItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEtatFactureAchatCodeRejetFactureAchatCodeRejetItem(requestParameters: FactureAchatApiUpdateEtatFactureAchatCodeRejetFactureAchatCodeRejetItemRequest, options?: AxiosRequestConfig): AxiosPromise<FactureAchatCodeRejetJsonldFactureAchatCodeRejetReadTimestampableBlameambleGroup> {
            return localVarFp.updateEtatFactureAchatCodeRejetFactureAchatCodeRejetItem(requestParameters.facture, requestParameters.codeRejet, requestParameters.factureAchatCodeRejetJsonldFactureAchatCodeRejetUpdateEtat, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Mettre à jour une facture d\'achat
         * @summary Mettre à jour une facture d\'achat
         * @param {FactureAchatApiUpdateFactureAchatFactureAchatItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFactureAchatFactureAchatItem(requestParameters: FactureAchatApiUpdateFactureAchatFactureAchatItemRequest, options?: AxiosRequestConfig): AxiosPromise<FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroupAttachmentGroupTaggableGroup> {
            return localVarFp.updateFactureAchatFactureAchatItem(requestParameters.id, requestParameters.factureAchatJsonldFactureAchatWrite, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for addAttachmentFactureAchatItem operation in FactureAchatApi.
 * @export
 * @interface FactureAchatApiAddAttachmentFactureAchatItemRequest
 */
export interface FactureAchatApiAddAttachmentFactureAchatItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof FactureAchatApiAddAttachmentFactureAchatItem
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof FactureAchatApiAddAttachmentFactureAchatItem
     */
    readonly xIdSociete?: number

    /**
     * 
     * @type {AddAttachmentFactureAchatItemRequest}
     * @memberof FactureAchatApiAddAttachmentFactureAchatItem
     */
    readonly addAttachmentFactureAchatItemRequest?: AddAttachmentFactureAchatItemRequest
}

/**
 * Request parameters for createFactureAchatFactureAchatCollection operation in FactureAchatApi.
 * @export
 * @interface FactureAchatApiCreateFactureAchatFactureAchatCollectionRequest
 */
export interface FactureAchatApiCreateFactureAchatFactureAchatCollectionRequest {
    /**
     * The new FactureAchat resource
     * @type {FactureAchatJsonldFactureAchatCreateFactureAchatWrite}
     * @memberof FactureAchatApiCreateFactureAchatFactureAchatCollection
     */
    readonly factureAchatJsonldFactureAchatCreateFactureAchatWrite: FactureAchatJsonldFactureAchatCreateFactureAchatWrite

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof FactureAchatApiCreateFactureAchatFactureAchatCollection
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for deleteFactureAchatFactureAchatItem operation in FactureAchatApi.
 * @export
 * @interface FactureAchatApiDeleteFactureAchatFactureAchatItemRequest
 */
export interface FactureAchatApiDeleteFactureAchatFactureAchatItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof FactureAchatApiDeleteFactureAchatFactureAchatItem
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof FactureAchatApiDeleteFactureAchatFactureAchatItem
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for exportCodesRejetsDashboardFactureAchatCollection operation in FactureAchatApi.
 * @export
 * @interface FactureAchatApiExportCodesRejetsDashboardFactureAchatCollectionRequest
 */
export interface FactureAchatApiExportCodesRejetsDashboardFactureAchatCollectionRequest {
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof FactureAchatApiExportCodesRejetsDashboardFactureAchatCollection
     */
    readonly xIdSociete?: number

    /**
     * 
     * @type {string}
     * @memberof FactureAchatApiExportCodesRejetsDashboardFactureAchatCollection
     */
    readonly dateFactureBefore?: string

    /**
     * 
     * @type {string}
     * @memberof FactureAchatApiExportCodesRejetsDashboardFactureAchatCollection
     */
    readonly dateFactureStrictlyBefore?: string

    /**
     * 
     * @type {string}
     * @memberof FactureAchatApiExportCodesRejetsDashboardFactureAchatCollection
     */
    readonly dateFactureAfter?: string

    /**
     * 
     * @type {string}
     * @memberof FactureAchatApiExportCodesRejetsDashboardFactureAchatCollection
     */
    readonly dateFactureStrictlyAfter?: string

    /**
     * 
     * @type {string}
     * @memberof FactureAchatApiExportCodesRejetsDashboardFactureAchatCollection
     */
    readonly createdAtBefore?: string

    /**
     * 
     * @type {string}
     * @memberof FactureAchatApiExportCodesRejetsDashboardFactureAchatCollection
     */
    readonly createdAtStrictlyBefore?: string

    /**
     * 
     * @type {string}
     * @memberof FactureAchatApiExportCodesRejetsDashboardFactureAchatCollection
     */
    readonly createdAtAfter?: string

    /**
     * 
     * @type {string}
     * @memberof FactureAchatApiExportCodesRejetsDashboardFactureAchatCollection
     */
    readonly createdAtStrictlyAfter?: string

    /**
     * 
     * @type {number}
     * @memberof FactureAchatApiExportCodesRejetsDashboardFactureAchatCollection
     */
    readonly idEntiteFacturante?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof FactureAchatApiExportCodesRejetsDashboardFactureAchatCollection
     */
    readonly idEntiteFacturante2?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof FactureAchatApiExportCodesRejetsDashboardFactureAchatCollection
     */
    readonly lignesIdDossier?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof FactureAchatApiExportCodesRejetsDashboardFactureAchatCollection
     */
    readonly lignesIdDossier2?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof FactureAchatApiExportCodesRejetsDashboardFactureAchatCollection
     */
    readonly idJal?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof FactureAchatApiExportCodesRejetsDashboardFactureAchatCollection
     */
    readonly idJal2?: Array<number>

    /**
     * 
     * @type {string}
     * @memberof FactureAchatApiExportCodesRejetsDashboardFactureAchatCollection
     */
    readonly categories?: string

    /**
     * 
     * @type {string}
     * @memberof FactureAchatApiExportCodesRejetsDashboardFactureAchatCollection
     */
    readonly codesRejets?: string

    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatApiExportCodesRejetsDashboardFactureAchatCollection
     */
    readonly cloture?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatApiExportCodesRejetsDashboardFactureAchatCollection
     */
    readonly deleted?: boolean

    /**
     * 
     * @type {number}
     * @memberof FactureAchatApiExportCodesRejetsDashboardFactureAchatCollection
     */
    readonly idSociete?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof FactureAchatApiExportCodesRejetsDashboardFactureAchatCollection
     */
    readonly idSociete2?: Array<number>

    /**
     * 
     * @type {string}
     * @memberof FactureAchatApiExportCodesRejetsDashboardFactureAchatCollection
     */
    readonly etats?: string
}

/**
 * Request parameters for exportCodesRejetsRecapFactureAchatCollection operation in FactureAchatApi.
 * @export
 * @interface FactureAchatApiExportCodesRejetsRecapFactureAchatCollectionRequest
 */
export interface FactureAchatApiExportCodesRejetsRecapFactureAchatCollectionRequest {
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof FactureAchatApiExportCodesRejetsRecapFactureAchatCollection
     */
    readonly xIdSociete?: number

    /**
     * 
     * @type {string}
     * @memberof FactureAchatApiExportCodesRejetsRecapFactureAchatCollection
     */
    readonly numeroFacture?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof FactureAchatApiExportCodesRejetsRecapFactureAchatCollection
     */
    readonly numeroFacture2?: Array<string>

    /**
     * 
     * @type {number}
     * @memberof FactureAchatApiExportCodesRejetsRecapFactureAchatCollection
     */
    readonly idSociete?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof FactureAchatApiExportCodesRejetsRecapFactureAchatCollection
     */
    readonly idSociete2?: Array<number>

    /**
     * 
     * @type {string}
     * @memberof FactureAchatApiExportCodesRejetsRecapFactureAchatCollection
     */
    readonly etats?: string
}

/**
 * Request parameters for getCategorieFaStatsFactureAchatCollection operation in FactureAchatApi.
 * @export
 * @interface FactureAchatApiGetCategorieFaStatsFactureAchatCollectionRequest
 */
export interface FactureAchatApiGetCategorieFaStatsFactureAchatCollectionRequest {
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof FactureAchatApiGetCategorieFaStatsFactureAchatCollection
     */
    readonly xIdSociete?: number

    /**
     * 
     * @type {string}
     * @memberof FactureAchatApiGetCategorieFaStatsFactureAchatCollection
     */
    readonly dateFactureBefore?: string

    /**
     * 
     * @type {string}
     * @memberof FactureAchatApiGetCategorieFaStatsFactureAchatCollection
     */
    readonly dateFactureStrictlyBefore?: string

    /**
     * 
     * @type {string}
     * @memberof FactureAchatApiGetCategorieFaStatsFactureAchatCollection
     */
    readonly dateFactureAfter?: string

    /**
     * 
     * @type {string}
     * @memberof FactureAchatApiGetCategorieFaStatsFactureAchatCollection
     */
    readonly dateFactureStrictlyAfter?: string

    /**
     * 
     * @type {string}
     * @memberof FactureAchatApiGetCategorieFaStatsFactureAchatCollection
     */
    readonly createdAtBefore?: string

    /**
     * 
     * @type {string}
     * @memberof FactureAchatApiGetCategorieFaStatsFactureAchatCollection
     */
    readonly createdAtStrictlyBefore?: string

    /**
     * 
     * @type {string}
     * @memberof FactureAchatApiGetCategorieFaStatsFactureAchatCollection
     */
    readonly createdAtAfter?: string

    /**
     * 
     * @type {string}
     * @memberof FactureAchatApiGetCategorieFaStatsFactureAchatCollection
     */
    readonly createdAtStrictlyAfter?: string

    /**
     * 
     * @type {number}
     * @memberof FactureAchatApiGetCategorieFaStatsFactureAchatCollection
     */
    readonly idEntiteFacturante?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof FactureAchatApiGetCategorieFaStatsFactureAchatCollection
     */
    readonly idEntiteFacturante2?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof FactureAchatApiGetCategorieFaStatsFactureAchatCollection
     */
    readonly lignesIdDossier?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof FactureAchatApiGetCategorieFaStatsFactureAchatCollection
     */
    readonly lignesIdDossier2?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof FactureAchatApiGetCategorieFaStatsFactureAchatCollection
     */
    readonly idJal?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof FactureAchatApiGetCategorieFaStatsFactureAchatCollection
     */
    readonly idJal2?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof FactureAchatApiGetCategorieFaStatsFactureAchatCollection
     */
    readonly idSociete?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof FactureAchatApiGetCategorieFaStatsFactureAchatCollection
     */
    readonly idSociete2?: Array<number>

    /**
     * 
     * @type {string}
     * @memberof FactureAchatApiGetCategorieFaStatsFactureAchatCollection
     */
    readonly categories?: string

    /**
     * 
     * @type {string}
     * @memberof FactureAchatApiGetCategorieFaStatsFactureAchatCollection
     */
    readonly codesRejets?: string
}

/**
 * Request parameters for getCoderejetFaStatsFactureAchatCollection operation in FactureAchatApi.
 * @export
 * @interface FactureAchatApiGetCoderejetFaStatsFactureAchatCollectionRequest
 */
export interface FactureAchatApiGetCoderejetFaStatsFactureAchatCollectionRequest {
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof FactureAchatApiGetCoderejetFaStatsFactureAchatCollection
     */
    readonly xIdSociete?: number

    /**
     * 
     * @type {string}
     * @memberof FactureAchatApiGetCoderejetFaStatsFactureAchatCollection
     */
    readonly dateFactureBefore?: string

    /**
     * 
     * @type {string}
     * @memberof FactureAchatApiGetCoderejetFaStatsFactureAchatCollection
     */
    readonly dateFactureStrictlyBefore?: string

    /**
     * 
     * @type {string}
     * @memberof FactureAchatApiGetCoderejetFaStatsFactureAchatCollection
     */
    readonly dateFactureAfter?: string

    /**
     * 
     * @type {string}
     * @memberof FactureAchatApiGetCoderejetFaStatsFactureAchatCollection
     */
    readonly dateFactureStrictlyAfter?: string

    /**
     * 
     * @type {string}
     * @memberof FactureAchatApiGetCoderejetFaStatsFactureAchatCollection
     */
    readonly createdAtBefore?: string

    /**
     * 
     * @type {string}
     * @memberof FactureAchatApiGetCoderejetFaStatsFactureAchatCollection
     */
    readonly createdAtStrictlyBefore?: string

    /**
     * 
     * @type {string}
     * @memberof FactureAchatApiGetCoderejetFaStatsFactureAchatCollection
     */
    readonly createdAtAfter?: string

    /**
     * 
     * @type {string}
     * @memberof FactureAchatApiGetCoderejetFaStatsFactureAchatCollection
     */
    readonly createdAtStrictlyAfter?: string

    /**
     * 
     * @type {number}
     * @memberof FactureAchatApiGetCoderejetFaStatsFactureAchatCollection
     */
    readonly idEntiteFacturante?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof FactureAchatApiGetCoderejetFaStatsFactureAchatCollection
     */
    readonly idEntiteFacturante2?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof FactureAchatApiGetCoderejetFaStatsFactureAchatCollection
     */
    readonly lignesIdDossier?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof FactureAchatApiGetCoderejetFaStatsFactureAchatCollection
     */
    readonly lignesIdDossier2?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof FactureAchatApiGetCoderejetFaStatsFactureAchatCollection
     */
    readonly idJal?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof FactureAchatApiGetCoderejetFaStatsFactureAchatCollection
     */
    readonly idJal2?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof FactureAchatApiGetCoderejetFaStatsFactureAchatCollection
     */
    readonly idSociete?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof FactureAchatApiGetCoderejetFaStatsFactureAchatCollection
     */
    readonly idSociete2?: Array<number>

    /**
     * 
     * @type {string}
     * @memberof FactureAchatApiGetCoderejetFaStatsFactureAchatCollection
     */
    readonly categories?: string

    /**
     * 
     * @type {string}
     * @memberof FactureAchatApiGetCoderejetFaStatsFactureAchatCollection
     */
    readonly codesRejets?: string
}

/**
 * Request parameters for getDossiersFactureAchatItem operation in FactureAchatApi.
 * @export
 * @interface FactureAchatApiGetDossiersFactureAchatItemRequest
 */
export interface FactureAchatApiGetDossiersFactureAchatItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof FactureAchatApiGetDossiersFactureAchatItem
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof FactureAchatApiGetDossiersFactureAchatItem
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for getFactureAchatCodeRejetFactureAchatCodeRejetItem operation in FactureAchatApi.
 * @export
 * @interface FactureAchatApiGetFactureAchatCodeRejetFactureAchatCodeRejetItemRequest
 */
export interface FactureAchatApiGetFactureAchatCodeRejetFactureAchatCodeRejetItemRequest {
    /**
     * Identifiant de la facture d\&#39;achat
     * @type {number}
     * @memberof FactureAchatApiGetFactureAchatCodeRejetFactureAchatCodeRejetItem
     */
    readonly facture: number

    /**
     * Identifiant du code rejet
     * @type {string}
     * @memberof FactureAchatApiGetFactureAchatCodeRejetFactureAchatCodeRejetItem
     */
    readonly codeRejet: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof FactureAchatApiGetFactureAchatCodeRejetFactureAchatCodeRejetItem
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for getFactureAchatCodesRejetsFactureAchatCodeRejetCollection operation in FactureAchatApi.
 * @export
 * @interface FactureAchatApiGetFactureAchatCodesRejetsFactureAchatCodeRejetCollectionRequest
 */
export interface FactureAchatApiGetFactureAchatCodesRejetsFactureAchatCodeRejetCollectionRequest {
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof FactureAchatApiGetFactureAchatCodesRejetsFactureAchatCodeRejetCollection
     */
    readonly xIdSociete?: number

    /**
     * The collection page number
     * @type {number}
     * @memberof FactureAchatApiGetFactureAchatCodesRejetsFactureAchatCodeRejetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof FactureAchatApiGetFactureAchatCodesRejetsFactureAchatCodeRejetCollection
     */
    readonly itemsPerPage?: number

    /**
     * 
     * @type {number}
     * @memberof FactureAchatApiGetFactureAchatCodesRejetsFactureAchatCodeRejetCollection
     */
    readonly factureId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof FactureAchatApiGetFactureAchatCodesRejetsFactureAchatCodeRejetCollection
     */
    readonly factureId2?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof FactureAchatApiGetFactureAchatCodesRejetsFactureAchatCodeRejetCollection
     */
    readonly idDossier?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof FactureAchatApiGetFactureAchatCodesRejetsFactureAchatCodeRejetCollection
     */
    readonly idDossier2?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof FactureAchatApiGetFactureAchatCodesRejetsFactureAchatCodeRejetCollection
     */
    readonly factureIdSociete?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof FactureAchatApiGetFactureAchatCodesRejetsFactureAchatCodeRejetCollection
     */
    readonly factureIdSociete2?: Array<number>
}

/**
 * Request parameters for getFactureAchatFactureAchatItem operation in FactureAchatApi.
 * @export
 * @interface FactureAchatApiGetFactureAchatFactureAchatItemRequest
 */
export interface FactureAchatApiGetFactureAchatFactureAchatItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof FactureAchatApiGetFactureAchatFactureAchatItem
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof FactureAchatApiGetFactureAchatFactureAchatItem
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for getFacturesAchatsFactureAchatCollection operation in FactureAchatApi.
 * @export
 * @interface FactureAchatApiGetFacturesAchatsFactureAchatCollectionRequest
 */
export interface FactureAchatApiGetFacturesAchatsFactureAchatCollectionRequest {
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof FactureAchatApiGetFacturesAchatsFactureAchatCollection
     */
    readonly xIdSociete?: number

    /**
     * The collection page number
     * @type {number}
     * @memberof FactureAchatApiGetFacturesAchatsFactureAchatCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof FactureAchatApiGetFacturesAchatsFactureAchatCollection
     */
    readonly itemsPerPage?: number

    /**
     * 
     * @type {number}
     * @memberof FactureAchatApiGetFacturesAchatsFactureAchatCollection
     */
    readonly idSociete?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof FactureAchatApiGetFacturesAchatsFactureAchatCollection
     */
    readonly idSociete2?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof FactureAchatApiGetFacturesAchatsFactureAchatCollection
     */
    readonly idReleveAchat?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof FactureAchatApiGetFacturesAchatsFactureAchatCollection
     */
    readonly idReleveAchat2?: Array<number>

    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatApiGetFacturesAchatsFactureAchatCollection
     */
    readonly existsCodesRejets?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatApiGetFacturesAchatsFactureAchatCollection
     */
    readonly cloture?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatApiGetFacturesAchatsFactureAchatCollection
     */
    readonly syncG3?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatApiGetFacturesAchatsFactureAchatCollection
     */
    readonly deleted?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatApiGetFacturesAchatsFactureAchatCollection
     */
    readonly multiAnnonces?: boolean

    /**
     * 
     * @type {string}
     * @memberof FactureAchatApiGetFacturesAchatsFactureAchatCollection
     */
    readonly dateFactureBefore?: string

    /**
     * 
     * @type {string}
     * @memberof FactureAchatApiGetFacturesAchatsFactureAchatCollection
     */
    readonly dateFactureStrictlyBefore?: string

    /**
     * 
     * @type {string}
     * @memberof FactureAchatApiGetFacturesAchatsFactureAchatCollection
     */
    readonly dateFactureAfter?: string

    /**
     * 
     * @type {string}
     * @memberof FactureAchatApiGetFacturesAchatsFactureAchatCollection
     */
    readonly dateFactureStrictlyAfter?: string

    /**
     * 
     * @type {string}
     * @memberof FactureAchatApiGetFacturesAchatsFactureAchatCollection
     */
    readonly createdAtBefore?: string

    /**
     * 
     * @type {string}
     * @memberof FactureAchatApiGetFacturesAchatsFactureAchatCollection
     */
    readonly createdAtStrictlyBefore?: string

    /**
     * 
     * @type {string}
     * @memberof FactureAchatApiGetFacturesAchatsFactureAchatCollection
     */
    readonly createdAtAfter?: string

    /**
     * 
     * @type {string}
     * @memberof FactureAchatApiGetFacturesAchatsFactureAchatCollection
     */
    readonly createdAtStrictlyAfter?: string

    /**
     * 
     * @type {string}
     * @memberof FactureAchatApiGetFacturesAchatsFactureAchatCollection
     */
    readonly libelleFacturante?: string

    /**
     * 
     * @type {string}
     * @memberof FactureAchatApiGetFacturesAchatsFactureAchatCollection
     */
    readonly lignesNumeroDossier?: string

    /**
     * 
     * @type {string}
     * @memberof FactureAchatApiGetFacturesAchatsFactureAchatCollection
     */
    readonly lignesNumeroPrestation?: string

    /**
     * 
     * @type {string}
     * @memberof FactureAchatApiGetFacturesAchatsFactureAchatCollection
     */
    readonly updatedBy?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof FactureAchatApiGetFacturesAchatsFactureAchatCollection
     */
    readonly updatedBy2?: Array<string>

    /**
     * 
     * @type {number}
     * @memberof FactureAchatApiGetFacturesAchatsFactureAchatCollection
     */
    readonly idEntiteFacturante?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof FactureAchatApiGetFacturesAchatsFactureAchatCollection
     */
    readonly idEntiteFacturante2?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof FactureAchatApiGetFacturesAchatsFactureAchatCollection
     */
    readonly lignesIdDossier?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof FactureAchatApiGetFacturesAchatsFactureAchatCollection
     */
    readonly lignesIdDossier2?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof FactureAchatApiGetFacturesAchatsFactureAchatCollection
     */
    readonly lignesIdPrestation?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof FactureAchatApiGetFacturesAchatsFactureAchatCollection
     */
    readonly lignesIdPrestation2?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof FactureAchatApiGetFacturesAchatsFactureAchatCollection
     */
    readonly idJal?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof FactureAchatApiGetFacturesAchatsFactureAchatCollection
     */
    readonly idJal2?: Array<number>

    /**
     * 
     * @type {string}
     * @memberof FactureAchatApiGetFacturesAchatsFactureAchatCollection
     */
    readonly numeroFacture?: string

    /**
     * 
     * @type {string}
     * @memberof FactureAchatApiGetFacturesAchatsFactureAchatCollection
     */
    readonly codesRejetsCodeRejetCategorie?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof FactureAchatApiGetFacturesAchatsFactureAchatCollection
     */
    readonly codesRejetsCodeRejetCategorie2?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof FactureAchatApiGetFacturesAchatsFactureAchatCollection
     */
    readonly codesRejetsCodeRejetCode?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof FactureAchatApiGetFacturesAchatsFactureAchatCollection
     */
    readonly codesRejetsCodeRejetCode2?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof FactureAchatApiGetFacturesAchatsFactureAchatCollection
     */
    readonly codesRejetsEtat?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof FactureAchatApiGetFacturesAchatsFactureAchatCollection
     */
    readonly codesRejetsEtat2?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof FactureAchatApiGetFacturesAchatsFactureAchatCollection
     */
    readonly recherche?: string

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof FactureAchatApiGetFacturesAchatsFactureAchatCollection
     */
    readonly orderId?: 'asc' | 'desc'

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof FactureAchatApiGetFacturesAchatsFactureAchatCollection
     */
    readonly orderUpdatedAt?: 'asc' | 'desc'
}

/**
 * Request parameters for patchFactureAchatFactureAchatItem operation in FactureAchatApi.
 * @export
 * @interface FactureAchatApiPatchFactureAchatFactureAchatItemRequest
 */
export interface FactureAchatApiPatchFactureAchatFactureAchatItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof FactureAchatApiPatchFactureAchatFactureAchatItem
     */
    readonly id: string

    /**
     * The updated FactureAchat resource
     * @type {FactureAchatFactureAchatWrite}
     * @memberof FactureAchatApiPatchFactureAchatFactureAchatItem
     */
    readonly factureAchatFactureAchatWrite: FactureAchatFactureAchatWrite

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof FactureAchatApiPatchFactureAchatFactureAchatItem
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for syncG3FactureAchatFactureAchatItem operation in FactureAchatApi.
 * @export
 * @interface FactureAchatApiSyncG3FactureAchatFactureAchatItemRequest
 */
export interface FactureAchatApiSyncG3FactureAchatFactureAchatItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof FactureAchatApiSyncG3FactureAchatFactureAchatItem
     */
    readonly id: string

    /**
     * The updated FactureAchat resource
     * @type {FactureAchatJsonldFactureAchatSyncG3}
     * @memberof FactureAchatApiSyncG3FactureAchatFactureAchatItem
     */
    readonly factureAchatJsonldFactureAchatSyncG3: FactureAchatJsonldFactureAchatSyncG3

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof FactureAchatApiSyncG3FactureAchatFactureAchatItem
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for updateEtatFactureAchatCodeRejetFactureAchatCodeRejetItem operation in FactureAchatApi.
 * @export
 * @interface FactureAchatApiUpdateEtatFactureAchatCodeRejetFactureAchatCodeRejetItemRequest
 */
export interface FactureAchatApiUpdateEtatFactureAchatCodeRejetFactureAchatCodeRejetItemRequest {
    /**
     * Identifiant de la facture d\&#39;achat
     * @type {number}
     * @memberof FactureAchatApiUpdateEtatFactureAchatCodeRejetFactureAchatCodeRejetItem
     */
    readonly facture: number

    /**
     * Identifiant du code rejet
     * @type {string}
     * @memberof FactureAchatApiUpdateEtatFactureAchatCodeRejetFactureAchatCodeRejetItem
     */
    readonly codeRejet: string

    /**
     * The updated FactureAchatCodeRejet resource
     * @type {FactureAchatCodeRejetJsonldFactureAchatCodeRejetUpdateEtat}
     * @memberof FactureAchatApiUpdateEtatFactureAchatCodeRejetFactureAchatCodeRejetItem
     */
    readonly factureAchatCodeRejetJsonldFactureAchatCodeRejetUpdateEtat: FactureAchatCodeRejetJsonldFactureAchatCodeRejetUpdateEtat

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof FactureAchatApiUpdateEtatFactureAchatCodeRejetFactureAchatCodeRejetItem
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for updateFactureAchatFactureAchatItem operation in FactureAchatApi.
 * @export
 * @interface FactureAchatApiUpdateFactureAchatFactureAchatItemRequest
 */
export interface FactureAchatApiUpdateFactureAchatFactureAchatItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof FactureAchatApiUpdateFactureAchatFactureAchatItem
     */
    readonly id: string

    /**
     * The updated FactureAchat resource
     * @type {FactureAchatJsonldFactureAchatWrite}
     * @memberof FactureAchatApiUpdateFactureAchatFactureAchatItem
     */
    readonly factureAchatJsonldFactureAchatWrite: FactureAchatJsonldFactureAchatWrite

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof FactureAchatApiUpdateFactureAchatFactureAchatItem
     */
    readonly xIdSociete?: number
}

/**
 * FactureAchatApi - object-oriented interface
 * @export
 * @class FactureAchatApi
 * @extends {BaseAPI}
 */
export class FactureAchatApi extends BaseAPI {
    /**
     * Associe un document à la facture
     * @summary Associe un document à la facture
     * @param {FactureAchatApiAddAttachmentFactureAchatItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FactureAchatApi
     */
    public addAttachmentFactureAchatItem(requestParameters: FactureAchatApiAddAttachmentFactureAchatItemRequest, options?: AxiosRequestConfig) {
        return FactureAchatApiFp(this.configuration).addAttachmentFactureAchatItem(requestParameters.id, requestParameters.xIdSociete, requestParameters.addAttachmentFactureAchatItemRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Créer un facture d\'achat
     * @summary Créer un facture d\'achat
     * @param {FactureAchatApiCreateFactureAchatFactureAchatCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FactureAchatApi
     */
    public createFactureAchatFactureAchatCollection(requestParameters: FactureAchatApiCreateFactureAchatFactureAchatCollectionRequest, options?: AxiosRequestConfig) {
        return FactureAchatApiFp(this.configuration).createFactureAchatFactureAchatCollection(requestParameters.factureAchatJsonldFactureAchatCreateFactureAchatWrite, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Supprimer une facture d\'achat
     * @summary Supprimer une facture d\'achat
     * @param {FactureAchatApiDeleteFactureAchatFactureAchatItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FactureAchatApi
     */
    public deleteFactureAchatFactureAchatItem(requestParameters: FactureAchatApiDeleteFactureAchatFactureAchatItemRequest, options?: AxiosRequestConfig) {
        return FactureAchatApiFp(this.configuration).deleteFactureAchatFactureAchatItem(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Exporter les codes rejets du dashboard
     * @summary Exporter les codes rejets du dashboard
     * @param {FactureAchatApiExportCodesRejetsDashboardFactureAchatCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FactureAchatApi
     */
    public exportCodesRejetsDashboardFactureAchatCollection(requestParameters: FactureAchatApiExportCodesRejetsDashboardFactureAchatCollectionRequest = {}, options?: AxiosRequestConfig) {
        return FactureAchatApiFp(this.configuration).exportCodesRejetsDashboardFactureAchatCollection(requestParameters.xIdSociete, requestParameters.dateFactureBefore, requestParameters.dateFactureStrictlyBefore, requestParameters.dateFactureAfter, requestParameters.dateFactureStrictlyAfter, requestParameters.createdAtBefore, requestParameters.createdAtStrictlyBefore, requestParameters.createdAtAfter, requestParameters.createdAtStrictlyAfter, requestParameters.idEntiteFacturante, requestParameters.idEntiteFacturante2, requestParameters.lignesIdDossier, requestParameters.lignesIdDossier2, requestParameters.idJal, requestParameters.idJal2, requestParameters.categories, requestParameters.codesRejets, requestParameters.cloture, requestParameters.deleted, requestParameters.idSociete, requestParameters.idSociete2, requestParameters.etats, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Exporter les codes rejets d\'une facture multi-annonces
     * @summary Exporter les codes rejets d\'une facture multi-annonces
     * @param {FactureAchatApiExportCodesRejetsRecapFactureAchatCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FactureAchatApi
     */
    public exportCodesRejetsRecapFactureAchatCollection(requestParameters: FactureAchatApiExportCodesRejetsRecapFactureAchatCollectionRequest = {}, options?: AxiosRequestConfig) {
        return FactureAchatApiFp(this.configuration).exportCodesRejetsRecapFactureAchatCollection(requestParameters.xIdSociete, requestParameters.numeroFacture, requestParameters.numeroFacture2, requestParameters.idSociete, requestParameters.idSociete2, requestParameters.etats, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer la liste des stats des categories des factures d\'achats
     * @summary Récupérer la liste des stats des categories des factures d\'achats
     * @param {FactureAchatApiGetCategorieFaStatsFactureAchatCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FactureAchatApi
     */
    public getCategorieFaStatsFactureAchatCollection(requestParameters: FactureAchatApiGetCategorieFaStatsFactureAchatCollectionRequest = {}, options?: AxiosRequestConfig) {
        return FactureAchatApiFp(this.configuration).getCategorieFaStatsFactureAchatCollection(requestParameters.xIdSociete, requestParameters.dateFactureBefore, requestParameters.dateFactureStrictlyBefore, requestParameters.dateFactureAfter, requestParameters.dateFactureStrictlyAfter, requestParameters.createdAtBefore, requestParameters.createdAtStrictlyBefore, requestParameters.createdAtAfter, requestParameters.createdAtStrictlyAfter, requestParameters.idEntiteFacturante, requestParameters.idEntiteFacturante2, requestParameters.lignesIdDossier, requestParameters.lignesIdDossier2, requestParameters.idJal, requestParameters.idJal2, requestParameters.idSociete, requestParameters.idSociete2, requestParameters.categories, requestParameters.codesRejets, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer la liste des stats des codes rejets des factures d\'achats
     * @summary Récupérer la liste des stats des codes rejets des factures d\'achats
     * @param {FactureAchatApiGetCoderejetFaStatsFactureAchatCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FactureAchatApi
     */
    public getCoderejetFaStatsFactureAchatCollection(requestParameters: FactureAchatApiGetCoderejetFaStatsFactureAchatCollectionRequest = {}, options?: AxiosRequestConfig) {
        return FactureAchatApiFp(this.configuration).getCoderejetFaStatsFactureAchatCollection(requestParameters.xIdSociete, requestParameters.dateFactureBefore, requestParameters.dateFactureStrictlyBefore, requestParameters.dateFactureAfter, requestParameters.dateFactureStrictlyAfter, requestParameters.createdAtBefore, requestParameters.createdAtStrictlyBefore, requestParameters.createdAtAfter, requestParameters.createdAtStrictlyAfter, requestParameters.idEntiteFacturante, requestParameters.idEntiteFacturante2, requestParameters.lignesIdDossier, requestParameters.lignesIdDossier2, requestParameters.idJal, requestParameters.idJal2, requestParameters.idSociete, requestParameters.idSociete2, requestParameters.categories, requestParameters.codesRejets, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer la liste des dossiers associés à une facture d\'achat
     * @summary Récupérer la liste des dossiers associés à une facture d\'achat
     * @param {FactureAchatApiGetDossiersFactureAchatItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FactureAchatApi
     */
    public getDossiersFactureAchatItem(requestParameters: FactureAchatApiGetDossiersFactureAchatItemRequest, options?: AxiosRequestConfig) {
        return FactureAchatApiFp(this.configuration).getDossiersFactureAchatItem(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'un code rejet associé à une facture d\'achat
     * @summary Récupérer les informations d\'un code rejet associé à une facture d\'achat
     * @param {FactureAchatApiGetFactureAchatCodeRejetFactureAchatCodeRejetItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FactureAchatApi
     */
    public getFactureAchatCodeRejetFactureAchatCodeRejetItem(requestParameters: FactureAchatApiGetFactureAchatCodeRejetFactureAchatCodeRejetItemRequest, options?: AxiosRequestConfig) {
        return FactureAchatApiFp(this.configuration).getFactureAchatCodeRejetFactureAchatCodeRejetItem(requestParameters.facture, requestParameters.codeRejet, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'un code rejet associé à une facture d\'achat
     * @summary Récupérer les informations d\'un code rejet associé à une facture d\'achat
     * @param {FactureAchatApiGetFactureAchatCodesRejetsFactureAchatCodeRejetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FactureAchatApi
     */
    public getFactureAchatCodesRejetsFactureAchatCodeRejetCollection(requestParameters: FactureAchatApiGetFactureAchatCodesRejetsFactureAchatCodeRejetCollectionRequest = {}, options?: AxiosRequestConfig) {
        return FactureAchatApiFp(this.configuration).getFactureAchatCodesRejetsFactureAchatCodeRejetCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, requestParameters.factureId, requestParameters.factureId2, requestParameters.idDossier, requestParameters.idDossier2, requestParameters.factureIdSociete, requestParameters.factureIdSociete2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'une facture d\'achat
     * @summary Récupérer les informations d\'une facture d\'achat
     * @param {FactureAchatApiGetFactureAchatFactureAchatItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FactureAchatApi
     */
    public getFactureAchatFactureAchatItem(requestParameters: FactureAchatApiGetFactureAchatFactureAchatItemRequest, options?: AxiosRequestConfig) {
        return FactureAchatApiFp(this.configuration).getFactureAchatFactureAchatItem(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer la liste des factures d\'achats
     * @summary Récupérer la liste des factures d\'achats
     * @param {FactureAchatApiGetFacturesAchatsFactureAchatCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FactureAchatApi
     */
    public getFacturesAchatsFactureAchatCollection(requestParameters: FactureAchatApiGetFacturesAchatsFactureAchatCollectionRequest = {}, options?: AxiosRequestConfig) {
        return FactureAchatApiFp(this.configuration).getFacturesAchatsFactureAchatCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, requestParameters.idSociete, requestParameters.idSociete2, requestParameters.idReleveAchat, requestParameters.idReleveAchat2, requestParameters.existsCodesRejets, requestParameters.cloture, requestParameters.syncG3, requestParameters.deleted, requestParameters.multiAnnonces, requestParameters.dateFactureBefore, requestParameters.dateFactureStrictlyBefore, requestParameters.dateFactureAfter, requestParameters.dateFactureStrictlyAfter, requestParameters.createdAtBefore, requestParameters.createdAtStrictlyBefore, requestParameters.createdAtAfter, requestParameters.createdAtStrictlyAfter, requestParameters.libelleFacturante, requestParameters.lignesNumeroDossier, requestParameters.lignesNumeroPrestation, requestParameters.updatedBy, requestParameters.updatedBy2, requestParameters.idEntiteFacturante, requestParameters.idEntiteFacturante2, requestParameters.lignesIdDossier, requestParameters.lignesIdDossier2, requestParameters.lignesIdPrestation, requestParameters.lignesIdPrestation2, requestParameters.idJal, requestParameters.idJal2, requestParameters.numeroFacture, requestParameters.codesRejetsCodeRejetCategorie, requestParameters.codesRejetsCodeRejetCategorie2, requestParameters.codesRejetsCodeRejetCode, requestParameters.codesRejetsCodeRejetCode2, requestParameters.codesRejetsEtat, requestParameters.codesRejetsEtat2, requestParameters.recherche, requestParameters.orderId, requestParameters.orderUpdatedAt, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Ajouter une information à une facture d\'achat
     * @summary Ajouter une information à une facture d\'achat
     * @param {FactureAchatApiPatchFactureAchatFactureAchatItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FactureAchatApi
     */
    public patchFactureAchatFactureAchatItem(requestParameters: FactureAchatApiPatchFactureAchatFactureAchatItemRequest, options?: AxiosRequestConfig) {
        return FactureAchatApiFp(this.configuration).patchFactureAchatFactureAchatItem(requestParameters.id, requestParameters.factureAchatFactureAchatWrite, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Insérer une facture d\'achat dans G3
     * @summary Insérer une facture d\'achat dans G3
     * @param {FactureAchatApiSyncG3FactureAchatFactureAchatItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FactureAchatApi
     */
    public syncG3FactureAchatFactureAchatItem(requestParameters: FactureAchatApiSyncG3FactureAchatFactureAchatItemRequest, options?: AxiosRequestConfig) {
        return FactureAchatApiFp(this.configuration).syncG3FactureAchatFactureAchatItem(requestParameters.id, requestParameters.factureAchatJsonldFactureAchatSyncG3, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Mettre à jour l\'état d\'un code rejet associé à une facture d\'achat
     * @summary Mettre à jour l\'état d\'un code rejet associé à une facture d\'achat
     * @param {FactureAchatApiUpdateEtatFactureAchatCodeRejetFactureAchatCodeRejetItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FactureAchatApi
     */
    public updateEtatFactureAchatCodeRejetFactureAchatCodeRejetItem(requestParameters: FactureAchatApiUpdateEtatFactureAchatCodeRejetFactureAchatCodeRejetItemRequest, options?: AxiosRequestConfig) {
        return FactureAchatApiFp(this.configuration).updateEtatFactureAchatCodeRejetFactureAchatCodeRejetItem(requestParameters.facture, requestParameters.codeRejet, requestParameters.factureAchatCodeRejetJsonldFactureAchatCodeRejetUpdateEtat, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Mettre à jour une facture d\'achat
     * @summary Mettre à jour une facture d\'achat
     * @param {FactureAchatApiUpdateFactureAchatFactureAchatItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FactureAchatApi
     */
    public updateFactureAchatFactureAchatItem(requestParameters: FactureAchatApiUpdateFactureAchatFactureAchatItemRequest, options?: AxiosRequestConfig) {
        return FactureAchatApiFp(this.configuration).updateFactureAchatFactureAchatItem(requestParameters.id, requestParameters.factureAchatJsonldFactureAchatWrite, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FactureAchatCodeRejetApi - axios parameter creator
 * @export
 */
export const FactureAchatCodeRejetApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves a FactureAchatCodeRejet resource.
         * @summary Retrieves a FactureAchatCodeRejet resource.
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFactureAchatCodeRejetItem: async (id: string, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFactureAchatCodeRejetItem', 'id', id)
            const localVarPath = `/achats/facture_achat_code_rejets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FactureAchatCodeRejetApi - functional programming interface
 * @export
 */
export const FactureAchatCodeRejetApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FactureAchatCodeRejetApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves a FactureAchatCodeRejet resource.
         * @summary Retrieves a FactureAchatCodeRejet resource.
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFactureAchatCodeRejetItem(id: string, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FactureAchatCodeRejetJsonldFactureAchatCodeRejetReadTimestampableBlameambleGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFactureAchatCodeRejetItem(id, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FactureAchatCodeRejetApi - factory interface
 * @export
 */
export const FactureAchatCodeRejetApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FactureAchatCodeRejetApiFp(configuration)
    return {
        /**
         * Retrieves a FactureAchatCodeRejet resource.
         * @summary Retrieves a FactureAchatCodeRejet resource.
         * @param {FactureAchatCodeRejetApiGetFactureAchatCodeRejetItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFactureAchatCodeRejetItem(requestParameters: FactureAchatCodeRejetApiGetFactureAchatCodeRejetItemRequest, options?: AxiosRequestConfig): AxiosPromise<FactureAchatCodeRejetJsonldFactureAchatCodeRejetReadTimestampableBlameambleGroup> {
            return localVarFp.getFactureAchatCodeRejetItem(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getFactureAchatCodeRejetItem operation in FactureAchatCodeRejetApi.
 * @export
 * @interface FactureAchatCodeRejetApiGetFactureAchatCodeRejetItemRequest
 */
export interface FactureAchatCodeRejetApiGetFactureAchatCodeRejetItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof FactureAchatCodeRejetApiGetFactureAchatCodeRejetItem
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof FactureAchatCodeRejetApiGetFactureAchatCodeRejetItem
     */
    readonly xIdSociete?: number
}

/**
 * FactureAchatCodeRejetApi - object-oriented interface
 * @export
 * @class FactureAchatCodeRejetApi
 * @extends {BaseAPI}
 */
export class FactureAchatCodeRejetApi extends BaseAPI {
    /**
     * Retrieves a FactureAchatCodeRejet resource.
     * @summary Retrieves a FactureAchatCodeRejet resource.
     * @param {FactureAchatCodeRejetApiGetFactureAchatCodeRejetItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FactureAchatCodeRejetApi
     */
    public getFactureAchatCodeRejetItem(requestParameters: FactureAchatCodeRejetApiGetFactureAchatCodeRejetItemRequest, options?: AxiosRequestConfig) {
        return FactureAchatCodeRejetApiFp(this.configuration).getFactureAchatCodeRejetItem(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FactureAchatJalApi - axios parameter creator
 * @export
 */
export const FactureAchatJalApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Créer une facture d\'achat jal
         * @summary Créer une facture d\'achat jal
         * @param {FactureAchatJalJsonldFactureAchatJalWrite} factureAchatJalJsonldFactureAchatJalWrite The new FactureAchatJal resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFactureAchatJalFactureAchatJalCollection: async (factureAchatJalJsonldFactureAchatJalWrite: FactureAchatJalJsonldFactureAchatJalWrite, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'factureAchatJalJsonldFactureAchatJalWrite' is not null or undefined
            assertParamExists('createFactureAchatJalFactureAchatJalCollection', 'factureAchatJalJsonldFactureAchatJalWrite', factureAchatJalJsonldFactureAchatJalWrite)
            const localVarPath = `/achats/facture_achat_jals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(factureAchatJalJsonldFactureAchatJalWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'une facture d\'achat jal
         * @summary Récupérer les informations d\'une facture d\'achat jal
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFactureAchatJalFactureAchatJalItem: async (id: string, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFactureAchatJalFactureAchatJalItem', 'id', id)
            const localVarPath = `/achats/facture_achat_jals/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer la liste des factures d\'achats jal
         * @summary Récupérer la liste des factures d\'achats jal
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFacturesAchatsJalsFactureAchatJalCollection: async (xIdSociete?: number, page?: number, itemsPerPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/achats/facture_achat_jals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Modifier une facture d\'achat jal
         * @summary Modifier une facture d\'achat jal
         * @param {string} id Resource identifier
         * @param {FactureAchatJalJsonldFactureAchatJalUpdate} factureAchatJalJsonldFactureAchatJalUpdate The updated FactureAchatJal resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFactureAchatJalFactureAchatJalItem: async (id: string, factureAchatJalJsonldFactureAchatJalUpdate: FactureAchatJalJsonldFactureAchatJalUpdate, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateFactureAchatJalFactureAchatJalItem', 'id', id)
            // verify required parameter 'factureAchatJalJsonldFactureAchatJalUpdate' is not null or undefined
            assertParamExists('updateFactureAchatJalFactureAchatJalItem', 'factureAchatJalJsonldFactureAchatJalUpdate', factureAchatJalJsonldFactureAchatJalUpdate)
            const localVarPath = `/achats/facture_achat_jals/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(factureAchatJalJsonldFactureAchatJalUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FactureAchatJalApi - functional programming interface
 * @export
 */
export const FactureAchatJalApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FactureAchatJalApiAxiosParamCreator(configuration)
    return {
        /**
         * Créer une facture d\'achat jal
         * @summary Créer une facture d\'achat jal
         * @param {FactureAchatJalJsonldFactureAchatJalWrite} factureAchatJalJsonldFactureAchatJalWrite The new FactureAchatJal resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFactureAchatJalFactureAchatJalCollection(factureAchatJalJsonldFactureAchatJalWrite: FactureAchatJalJsonldFactureAchatJalWrite, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FactureAchatJalJsonldFactureAchatJalRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFactureAchatJalFactureAchatJalCollection(factureAchatJalJsonldFactureAchatJalWrite, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Récupérer les informations d\'une facture d\'achat jal
         * @summary Récupérer les informations d\'une facture d\'achat jal
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFactureAchatJalFactureAchatJalItem(id: string, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FactureAchatJalJsonldFactureAchatJalRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFactureAchatJalFactureAchatJalItem(id, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Récupérer la liste des factures d\'achats jal
         * @summary Récupérer la liste des factures d\'achats jal
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFacturesAchatsJalsFactureAchatJalCollection(xIdSociete?: number, page?: number, itemsPerPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetFacturesAchatsJalsFactureAchatJalCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFacturesAchatsJalsFactureAchatJalCollection(xIdSociete, page, itemsPerPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Modifier une facture d\'achat jal
         * @summary Modifier une facture d\'achat jal
         * @param {string} id Resource identifier
         * @param {FactureAchatJalJsonldFactureAchatJalUpdate} factureAchatJalJsonldFactureAchatJalUpdate The updated FactureAchatJal resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFactureAchatJalFactureAchatJalItem(id: string, factureAchatJalJsonldFactureAchatJalUpdate: FactureAchatJalJsonldFactureAchatJalUpdate, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FactureAchatJalJsonldFactureAchatJalRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFactureAchatJalFactureAchatJalItem(id, factureAchatJalJsonldFactureAchatJalUpdate, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FactureAchatJalApi - factory interface
 * @export
 */
export const FactureAchatJalApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FactureAchatJalApiFp(configuration)
    return {
        /**
         * Créer une facture d\'achat jal
         * @summary Créer une facture d\'achat jal
         * @param {FactureAchatJalApiCreateFactureAchatJalFactureAchatJalCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFactureAchatJalFactureAchatJalCollection(requestParameters: FactureAchatJalApiCreateFactureAchatJalFactureAchatJalCollectionRequest, options?: AxiosRequestConfig): AxiosPromise<FactureAchatJalJsonldFactureAchatJalRead> {
            return localVarFp.createFactureAchatJalFactureAchatJalCollection(requestParameters.factureAchatJalJsonldFactureAchatJalWrite, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'une facture d\'achat jal
         * @summary Récupérer les informations d\'une facture d\'achat jal
         * @param {FactureAchatJalApiGetFactureAchatJalFactureAchatJalItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFactureAchatJalFactureAchatJalItem(requestParameters: FactureAchatJalApiGetFactureAchatJalFactureAchatJalItemRequest, options?: AxiosRequestConfig): AxiosPromise<FactureAchatJalJsonldFactureAchatJalRead> {
            return localVarFp.getFactureAchatJalFactureAchatJalItem(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer la liste des factures d\'achats jal
         * @summary Récupérer la liste des factures d\'achats jal
         * @param {FactureAchatJalApiGetFacturesAchatsJalsFactureAchatJalCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFacturesAchatsJalsFactureAchatJalCollection(requestParameters: FactureAchatJalApiGetFacturesAchatsJalsFactureAchatJalCollectionRequest = {}, options?: AxiosRequestConfig): AxiosPromise<GetFacturesAchatsJalsFactureAchatJalCollection200Response> {
            return localVarFp.getFacturesAchatsJalsFactureAchatJalCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Modifier une facture d\'achat jal
         * @summary Modifier une facture d\'achat jal
         * @param {FactureAchatJalApiUpdateFactureAchatJalFactureAchatJalItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFactureAchatJalFactureAchatJalItem(requestParameters: FactureAchatJalApiUpdateFactureAchatJalFactureAchatJalItemRequest, options?: AxiosRequestConfig): AxiosPromise<FactureAchatJalJsonldFactureAchatJalRead> {
            return localVarFp.updateFactureAchatJalFactureAchatJalItem(requestParameters.id, requestParameters.factureAchatJalJsonldFactureAchatJalUpdate, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createFactureAchatJalFactureAchatJalCollection operation in FactureAchatJalApi.
 * @export
 * @interface FactureAchatJalApiCreateFactureAchatJalFactureAchatJalCollectionRequest
 */
export interface FactureAchatJalApiCreateFactureAchatJalFactureAchatJalCollectionRequest {
    /**
     * The new FactureAchatJal resource
     * @type {FactureAchatJalJsonldFactureAchatJalWrite}
     * @memberof FactureAchatJalApiCreateFactureAchatJalFactureAchatJalCollection
     */
    readonly factureAchatJalJsonldFactureAchatJalWrite: FactureAchatJalJsonldFactureAchatJalWrite

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof FactureAchatJalApiCreateFactureAchatJalFactureAchatJalCollection
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for getFactureAchatJalFactureAchatJalItem operation in FactureAchatJalApi.
 * @export
 * @interface FactureAchatJalApiGetFactureAchatJalFactureAchatJalItemRequest
 */
export interface FactureAchatJalApiGetFactureAchatJalFactureAchatJalItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof FactureAchatJalApiGetFactureAchatJalFactureAchatJalItem
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof FactureAchatJalApiGetFactureAchatJalFactureAchatJalItem
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for getFacturesAchatsJalsFactureAchatJalCollection operation in FactureAchatJalApi.
 * @export
 * @interface FactureAchatJalApiGetFacturesAchatsJalsFactureAchatJalCollectionRequest
 */
export interface FactureAchatJalApiGetFacturesAchatsJalsFactureAchatJalCollectionRequest {
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof FactureAchatJalApiGetFacturesAchatsJalsFactureAchatJalCollection
     */
    readonly xIdSociete?: number

    /**
     * The collection page number
     * @type {number}
     * @memberof FactureAchatJalApiGetFacturesAchatsJalsFactureAchatJalCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof FactureAchatJalApiGetFacturesAchatsJalsFactureAchatJalCollection
     */
    readonly itemsPerPage?: number
}

/**
 * Request parameters for updateFactureAchatJalFactureAchatJalItem operation in FactureAchatJalApi.
 * @export
 * @interface FactureAchatJalApiUpdateFactureAchatJalFactureAchatJalItemRequest
 */
export interface FactureAchatJalApiUpdateFactureAchatJalFactureAchatJalItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof FactureAchatJalApiUpdateFactureAchatJalFactureAchatJalItem
     */
    readonly id: string

    /**
     * The updated FactureAchatJal resource
     * @type {FactureAchatJalJsonldFactureAchatJalUpdate}
     * @memberof FactureAchatJalApiUpdateFactureAchatJalFactureAchatJalItem
     */
    readonly factureAchatJalJsonldFactureAchatJalUpdate: FactureAchatJalJsonldFactureAchatJalUpdate

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof FactureAchatJalApiUpdateFactureAchatJalFactureAchatJalItem
     */
    readonly xIdSociete?: number
}

/**
 * FactureAchatJalApi - object-oriented interface
 * @export
 * @class FactureAchatJalApi
 * @extends {BaseAPI}
 */
export class FactureAchatJalApi extends BaseAPI {
    /**
     * Créer une facture d\'achat jal
     * @summary Créer une facture d\'achat jal
     * @param {FactureAchatJalApiCreateFactureAchatJalFactureAchatJalCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FactureAchatJalApi
     */
    public createFactureAchatJalFactureAchatJalCollection(requestParameters: FactureAchatJalApiCreateFactureAchatJalFactureAchatJalCollectionRequest, options?: AxiosRequestConfig) {
        return FactureAchatJalApiFp(this.configuration).createFactureAchatJalFactureAchatJalCollection(requestParameters.factureAchatJalJsonldFactureAchatJalWrite, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'une facture d\'achat jal
     * @summary Récupérer les informations d\'une facture d\'achat jal
     * @param {FactureAchatJalApiGetFactureAchatJalFactureAchatJalItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FactureAchatJalApi
     */
    public getFactureAchatJalFactureAchatJalItem(requestParameters: FactureAchatJalApiGetFactureAchatJalFactureAchatJalItemRequest, options?: AxiosRequestConfig) {
        return FactureAchatJalApiFp(this.configuration).getFactureAchatJalFactureAchatJalItem(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer la liste des factures d\'achats jal
     * @summary Récupérer la liste des factures d\'achats jal
     * @param {FactureAchatJalApiGetFacturesAchatsJalsFactureAchatJalCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FactureAchatJalApi
     */
    public getFacturesAchatsJalsFactureAchatJalCollection(requestParameters: FactureAchatJalApiGetFacturesAchatsJalsFactureAchatJalCollectionRequest = {}, options?: AxiosRequestConfig) {
        return FactureAchatJalApiFp(this.configuration).getFacturesAchatsJalsFactureAchatJalCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Modifier une facture d\'achat jal
     * @summary Modifier une facture d\'achat jal
     * @param {FactureAchatJalApiUpdateFactureAchatJalFactureAchatJalItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FactureAchatJalApi
     */
    public updateFactureAchatJalFactureAchatJalItem(requestParameters: FactureAchatJalApiUpdateFactureAchatJalFactureAchatJalItemRequest, options?: AxiosRequestConfig) {
        return FactureAchatJalApiFp(this.configuration).updateFactureAchatJalFactureAchatJalItem(requestParameters.id, requestParameters.factureAchatJalJsonldFactureAchatJalUpdate, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FactureAchatLigneApi - axios parameter creator
 * @export
 */
export const FactureAchatLigneApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Créer une ligne de facture d\'achat
         * @summary Créer une ligne de facture d\'achat
         * @param {FactureAchatLigneJsonldFactureAchatLigneWriteFactureAchatLigneCreate} factureAchatLigneJsonldFactureAchatLigneWriteFactureAchatLigneCreate The new FactureAchatLigne resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFactureAchatLigneFactureAchatLigneCollection: async (factureAchatLigneJsonldFactureAchatLigneWriteFactureAchatLigneCreate: FactureAchatLigneJsonldFactureAchatLigneWriteFactureAchatLigneCreate, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'factureAchatLigneJsonldFactureAchatLigneWriteFactureAchatLigneCreate' is not null or undefined
            assertParamExists('createFactureAchatLigneFactureAchatLigneCollection', 'factureAchatLigneJsonldFactureAchatLigneWriteFactureAchatLigneCreate', factureAchatLigneJsonldFactureAchatLigneWriteFactureAchatLigneCreate)
            const localVarPath = `/achats/facture_achat_lignes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(factureAchatLigneJsonldFactureAchatLigneWriteFactureAchatLigneCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Supprimer une ligne facture d\'achat
         * @summary Supprimer une ligne facture d\'achat
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFactureAchatLigneFactureAchatLigneItem: async (id: string, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteFactureAchatLigneFactureAchatLigneItem', 'id', id)
            const localVarPath = `/achats/facture_achat_lignes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer une ligne de facture d\'achat
         * @summary Récupérer une ligne de facture d\'achat
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFactureAchatLigneFactureAchatLigneItem: async (id: string, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFactureAchatLigneFactureAchatLigneItem', 'id', id)
            const localVarPath = `/achats/facture_achat_lignes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les lignes d\'une facture d\'achat
         * @summary Récupérer les lignes d\'une facture d\'achat
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {number} [factureId] 
         * @param {Array<number>} [factureId2] 
         * @param {number} [idDossier] 
         * @param {Array<number>} [idDossier2] 
         * @param {number} [factureIdSociete] 
         * @param {Array<number>} [factureIdSociete2] 
         * @param {number} [idDossierFpro] 
         * @param {Array<number>} [idDossierFpro2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLignesFactureAchatFactureAchatLigneCollection: async (xIdSociete?: number, page?: number, itemsPerPage?: number, factureId?: number, factureId2?: Array<number>, idDossier?: number, idDossier2?: Array<number>, factureIdSociete?: number, factureIdSociete2?: Array<number>, idDossierFpro?: number, idDossierFpro2?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/achats/facture_achat_lignes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (factureId !== undefined) {
                localVarQueryParameter['facture.id'] = factureId;
            }

            if (factureId2) {
                localVarQueryParameter['facture.id[]'] = factureId2;
            }

            if (idDossier !== undefined) {
                localVarQueryParameter['idDossier'] = idDossier;
            }

            if (idDossier2) {
                localVarQueryParameter['idDossier[]'] = idDossier2;
            }

            if (factureIdSociete !== undefined) {
                localVarQueryParameter['facture.idSociete'] = factureIdSociete;
            }

            if (factureIdSociete2) {
                localVarQueryParameter['facture.idSociete[]'] = factureIdSociete2;
            }

            if (idDossierFpro !== undefined) {
                localVarQueryParameter['idDossierFpro'] = idDossierFpro;
            }

            if (idDossierFpro2) {
                localVarQueryParameter['idDossierFpro[]'] = idDossierFpro2;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Mettre à jour une ligne de facture d\'achat
         * @summary Mettre à jour une ligne de facture d\'achat
         * @param {string} id Resource identifier
         * @param {FactureAchatLigneJsonldFactureAchatLigneWrite} factureAchatLigneJsonldFactureAchatLigneWrite The updated FactureAchatLigne resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFactureAchatLigneFactureAchatLigneItem: async (id: string, factureAchatLigneJsonldFactureAchatLigneWrite: FactureAchatLigneJsonldFactureAchatLigneWrite, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateFactureAchatLigneFactureAchatLigneItem', 'id', id)
            // verify required parameter 'factureAchatLigneJsonldFactureAchatLigneWrite' is not null or undefined
            assertParamExists('updateFactureAchatLigneFactureAchatLigneItem', 'factureAchatLigneJsonldFactureAchatLigneWrite', factureAchatLigneJsonldFactureAchatLigneWrite)
            const localVarPath = `/achats/facture_achat_lignes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(factureAchatLigneJsonldFactureAchatLigneWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FactureAchatLigneApi - functional programming interface
 * @export
 */
export const FactureAchatLigneApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FactureAchatLigneApiAxiosParamCreator(configuration)
    return {
        /**
         * Créer une ligne de facture d\'achat
         * @summary Créer une ligne de facture d\'achat
         * @param {FactureAchatLigneJsonldFactureAchatLigneWriteFactureAchatLigneCreate} factureAchatLigneJsonldFactureAchatLigneWriteFactureAchatLigneCreate The new FactureAchatLigne resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFactureAchatLigneFactureAchatLigneCollection(factureAchatLigneJsonldFactureAchatLigneWriteFactureAchatLigneCreate: FactureAchatLigneJsonldFactureAchatLigneWriteFactureAchatLigneCreate, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FactureAchatLigneJsonldFactureAchatRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFactureAchatLigneFactureAchatLigneCollection(factureAchatLigneJsonldFactureAchatLigneWriteFactureAchatLigneCreate, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Supprimer une ligne facture d\'achat
         * @summary Supprimer une ligne facture d\'achat
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFactureAchatLigneFactureAchatLigneItem(id: string, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFactureAchatLigneFactureAchatLigneItem(id, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Récupérer une ligne de facture d\'achat
         * @summary Récupérer une ligne de facture d\'achat
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFactureAchatLigneFactureAchatLigneItem(id: string, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FactureAchatLigneJsonldFactureAchatRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFactureAchatLigneFactureAchatLigneItem(id, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Récupérer les lignes d\'une facture d\'achat
         * @summary Récupérer les lignes d\'une facture d\'achat
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {number} [factureId] 
         * @param {Array<number>} [factureId2] 
         * @param {number} [idDossier] 
         * @param {Array<number>} [idDossier2] 
         * @param {number} [factureIdSociete] 
         * @param {Array<number>} [factureIdSociete2] 
         * @param {number} [idDossierFpro] 
         * @param {Array<number>} [idDossierFpro2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLignesFactureAchatFactureAchatLigneCollection(xIdSociete?: number, page?: number, itemsPerPage?: number, factureId?: number, factureId2?: Array<number>, idDossier?: number, idDossier2?: Array<number>, factureIdSociete?: number, factureIdSociete2?: Array<number>, idDossierFpro?: number, idDossierFpro2?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetLignesFactureAchatFactureAchatLigneCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLignesFactureAchatFactureAchatLigneCollection(xIdSociete, page, itemsPerPage, factureId, factureId2, idDossier, idDossier2, factureIdSociete, factureIdSociete2, idDossierFpro, idDossierFpro2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Mettre à jour une ligne de facture d\'achat
         * @summary Mettre à jour une ligne de facture d\'achat
         * @param {string} id Resource identifier
         * @param {FactureAchatLigneJsonldFactureAchatLigneWrite} factureAchatLigneJsonldFactureAchatLigneWrite The updated FactureAchatLigne resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFactureAchatLigneFactureAchatLigneItem(id: string, factureAchatLigneJsonldFactureAchatLigneWrite: FactureAchatLigneJsonldFactureAchatLigneWrite, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FactureAchatLigneJsonldFactureAchatRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFactureAchatLigneFactureAchatLigneItem(id, factureAchatLigneJsonldFactureAchatLigneWrite, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FactureAchatLigneApi - factory interface
 * @export
 */
export const FactureAchatLigneApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FactureAchatLigneApiFp(configuration)
    return {
        /**
         * Créer une ligne de facture d\'achat
         * @summary Créer une ligne de facture d\'achat
         * @param {FactureAchatLigneApiCreateFactureAchatLigneFactureAchatLigneCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFactureAchatLigneFactureAchatLigneCollection(requestParameters: FactureAchatLigneApiCreateFactureAchatLigneFactureAchatLigneCollectionRequest, options?: AxiosRequestConfig): AxiosPromise<FactureAchatLigneJsonldFactureAchatRead> {
            return localVarFp.createFactureAchatLigneFactureAchatLigneCollection(requestParameters.factureAchatLigneJsonldFactureAchatLigneWriteFactureAchatLigneCreate, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Supprimer une ligne facture d\'achat
         * @summary Supprimer une ligne facture d\'achat
         * @param {FactureAchatLigneApiDeleteFactureAchatLigneFactureAchatLigneItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFactureAchatLigneFactureAchatLigneItem(requestParameters: FactureAchatLigneApiDeleteFactureAchatLigneFactureAchatLigneItemRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteFactureAchatLigneFactureAchatLigneItem(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer une ligne de facture d\'achat
         * @summary Récupérer une ligne de facture d\'achat
         * @param {FactureAchatLigneApiGetFactureAchatLigneFactureAchatLigneItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFactureAchatLigneFactureAchatLigneItem(requestParameters: FactureAchatLigneApiGetFactureAchatLigneFactureAchatLigneItemRequest, options?: AxiosRequestConfig): AxiosPromise<FactureAchatLigneJsonldFactureAchatRead> {
            return localVarFp.getFactureAchatLigneFactureAchatLigneItem(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les lignes d\'une facture d\'achat
         * @summary Récupérer les lignes d\'une facture d\'achat
         * @param {FactureAchatLigneApiGetLignesFactureAchatFactureAchatLigneCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLignesFactureAchatFactureAchatLigneCollection(requestParameters: FactureAchatLigneApiGetLignesFactureAchatFactureAchatLigneCollectionRequest = {}, options?: AxiosRequestConfig): AxiosPromise<GetLignesFactureAchatFactureAchatLigneCollection200Response> {
            return localVarFp.getLignesFactureAchatFactureAchatLigneCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, requestParameters.factureId, requestParameters.factureId2, requestParameters.idDossier, requestParameters.idDossier2, requestParameters.factureIdSociete, requestParameters.factureIdSociete2, requestParameters.idDossierFpro, requestParameters.idDossierFpro2, options).then((request) => request(axios, basePath));
        },
        /**
         * Mettre à jour une ligne de facture d\'achat
         * @summary Mettre à jour une ligne de facture d\'achat
         * @param {FactureAchatLigneApiUpdateFactureAchatLigneFactureAchatLigneItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFactureAchatLigneFactureAchatLigneItem(requestParameters: FactureAchatLigneApiUpdateFactureAchatLigneFactureAchatLigneItemRequest, options?: AxiosRequestConfig): AxiosPromise<FactureAchatLigneJsonldFactureAchatRead> {
            return localVarFp.updateFactureAchatLigneFactureAchatLigneItem(requestParameters.id, requestParameters.factureAchatLigneJsonldFactureAchatLigneWrite, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createFactureAchatLigneFactureAchatLigneCollection operation in FactureAchatLigneApi.
 * @export
 * @interface FactureAchatLigneApiCreateFactureAchatLigneFactureAchatLigneCollectionRequest
 */
export interface FactureAchatLigneApiCreateFactureAchatLigneFactureAchatLigneCollectionRequest {
    /**
     * The new FactureAchatLigne resource
     * @type {FactureAchatLigneJsonldFactureAchatLigneWriteFactureAchatLigneCreate}
     * @memberof FactureAchatLigneApiCreateFactureAchatLigneFactureAchatLigneCollection
     */
    readonly factureAchatLigneJsonldFactureAchatLigneWriteFactureAchatLigneCreate: FactureAchatLigneJsonldFactureAchatLigneWriteFactureAchatLigneCreate

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof FactureAchatLigneApiCreateFactureAchatLigneFactureAchatLigneCollection
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for deleteFactureAchatLigneFactureAchatLigneItem operation in FactureAchatLigneApi.
 * @export
 * @interface FactureAchatLigneApiDeleteFactureAchatLigneFactureAchatLigneItemRequest
 */
export interface FactureAchatLigneApiDeleteFactureAchatLigneFactureAchatLigneItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof FactureAchatLigneApiDeleteFactureAchatLigneFactureAchatLigneItem
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof FactureAchatLigneApiDeleteFactureAchatLigneFactureAchatLigneItem
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for getFactureAchatLigneFactureAchatLigneItem operation in FactureAchatLigneApi.
 * @export
 * @interface FactureAchatLigneApiGetFactureAchatLigneFactureAchatLigneItemRequest
 */
export interface FactureAchatLigneApiGetFactureAchatLigneFactureAchatLigneItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof FactureAchatLigneApiGetFactureAchatLigneFactureAchatLigneItem
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof FactureAchatLigneApiGetFactureAchatLigneFactureAchatLigneItem
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for getLignesFactureAchatFactureAchatLigneCollection operation in FactureAchatLigneApi.
 * @export
 * @interface FactureAchatLigneApiGetLignesFactureAchatFactureAchatLigneCollectionRequest
 */
export interface FactureAchatLigneApiGetLignesFactureAchatFactureAchatLigneCollectionRequest {
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof FactureAchatLigneApiGetLignesFactureAchatFactureAchatLigneCollection
     */
    readonly xIdSociete?: number

    /**
     * The collection page number
     * @type {number}
     * @memberof FactureAchatLigneApiGetLignesFactureAchatFactureAchatLigneCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof FactureAchatLigneApiGetLignesFactureAchatFactureAchatLigneCollection
     */
    readonly itemsPerPage?: number

    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneApiGetLignesFactureAchatFactureAchatLigneCollection
     */
    readonly factureId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof FactureAchatLigneApiGetLignesFactureAchatFactureAchatLigneCollection
     */
    readonly factureId2?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneApiGetLignesFactureAchatFactureAchatLigneCollection
     */
    readonly idDossier?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof FactureAchatLigneApiGetLignesFactureAchatFactureAchatLigneCollection
     */
    readonly idDossier2?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneApiGetLignesFactureAchatFactureAchatLigneCollection
     */
    readonly factureIdSociete?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof FactureAchatLigneApiGetLignesFactureAchatFactureAchatLigneCollection
     */
    readonly factureIdSociete2?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneApiGetLignesFactureAchatFactureAchatLigneCollection
     */
    readonly idDossierFpro?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof FactureAchatLigneApiGetLignesFactureAchatFactureAchatLigneCollection
     */
    readonly idDossierFpro2?: Array<number>
}

/**
 * Request parameters for updateFactureAchatLigneFactureAchatLigneItem operation in FactureAchatLigneApi.
 * @export
 * @interface FactureAchatLigneApiUpdateFactureAchatLigneFactureAchatLigneItemRequest
 */
export interface FactureAchatLigneApiUpdateFactureAchatLigneFactureAchatLigneItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof FactureAchatLigneApiUpdateFactureAchatLigneFactureAchatLigneItem
     */
    readonly id: string

    /**
     * The updated FactureAchatLigne resource
     * @type {FactureAchatLigneJsonldFactureAchatLigneWrite}
     * @memberof FactureAchatLigneApiUpdateFactureAchatLigneFactureAchatLigneItem
     */
    readonly factureAchatLigneJsonldFactureAchatLigneWrite: FactureAchatLigneJsonldFactureAchatLigneWrite

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof FactureAchatLigneApiUpdateFactureAchatLigneFactureAchatLigneItem
     */
    readonly xIdSociete?: number
}

/**
 * FactureAchatLigneApi - object-oriented interface
 * @export
 * @class FactureAchatLigneApi
 * @extends {BaseAPI}
 */
export class FactureAchatLigneApi extends BaseAPI {
    /**
     * Créer une ligne de facture d\'achat
     * @summary Créer une ligne de facture d\'achat
     * @param {FactureAchatLigneApiCreateFactureAchatLigneFactureAchatLigneCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FactureAchatLigneApi
     */
    public createFactureAchatLigneFactureAchatLigneCollection(requestParameters: FactureAchatLigneApiCreateFactureAchatLigneFactureAchatLigneCollectionRequest, options?: AxiosRequestConfig) {
        return FactureAchatLigneApiFp(this.configuration).createFactureAchatLigneFactureAchatLigneCollection(requestParameters.factureAchatLigneJsonldFactureAchatLigneWriteFactureAchatLigneCreate, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Supprimer une ligne facture d\'achat
     * @summary Supprimer une ligne facture d\'achat
     * @param {FactureAchatLigneApiDeleteFactureAchatLigneFactureAchatLigneItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FactureAchatLigneApi
     */
    public deleteFactureAchatLigneFactureAchatLigneItem(requestParameters: FactureAchatLigneApiDeleteFactureAchatLigneFactureAchatLigneItemRequest, options?: AxiosRequestConfig) {
        return FactureAchatLigneApiFp(this.configuration).deleteFactureAchatLigneFactureAchatLigneItem(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer une ligne de facture d\'achat
     * @summary Récupérer une ligne de facture d\'achat
     * @param {FactureAchatLigneApiGetFactureAchatLigneFactureAchatLigneItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FactureAchatLigneApi
     */
    public getFactureAchatLigneFactureAchatLigneItem(requestParameters: FactureAchatLigneApiGetFactureAchatLigneFactureAchatLigneItemRequest, options?: AxiosRequestConfig) {
        return FactureAchatLigneApiFp(this.configuration).getFactureAchatLigneFactureAchatLigneItem(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les lignes d\'une facture d\'achat
     * @summary Récupérer les lignes d\'une facture d\'achat
     * @param {FactureAchatLigneApiGetLignesFactureAchatFactureAchatLigneCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FactureAchatLigneApi
     */
    public getLignesFactureAchatFactureAchatLigneCollection(requestParameters: FactureAchatLigneApiGetLignesFactureAchatFactureAchatLigneCollectionRequest = {}, options?: AxiosRequestConfig) {
        return FactureAchatLigneApiFp(this.configuration).getLignesFactureAchatFactureAchatLigneCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, requestParameters.factureId, requestParameters.factureId2, requestParameters.idDossier, requestParameters.idDossier2, requestParameters.factureIdSociete, requestParameters.factureIdSociete2, requestParameters.idDossierFpro, requestParameters.idDossierFpro2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Mettre à jour une ligne de facture d\'achat
     * @summary Mettre à jour une ligne de facture d\'achat
     * @param {FactureAchatLigneApiUpdateFactureAchatLigneFactureAchatLigneItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FactureAchatLigneApi
     */
    public updateFactureAchatLigneFactureAchatLigneItem(requestParameters: FactureAchatLigneApiUpdateFactureAchatLigneFactureAchatLigneItemRequest, options?: AxiosRequestConfig) {
        return FactureAchatLigneApiFp(this.configuration).updateFactureAchatLigneFactureAchatLigneItem(requestParameters.id, requestParameters.factureAchatLigneJsonldFactureAchatLigneWrite, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FactureAchatNoteApi - axios parameter creator
 * @export
 */
export const FactureAchatNoteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Créer une note de facture d\'achat
         * @summary Créer une note de facture d\'achat
         * @param {FactureAchatNoteJsonldFactureAchatNoteCreate} factureAchatNoteJsonldFactureAchatNoteCreate The new FactureAchatNote resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFactureAchatNoteFactureAchatNoteCollection: async (factureAchatNoteJsonldFactureAchatNoteCreate: FactureAchatNoteJsonldFactureAchatNoteCreate, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'factureAchatNoteJsonldFactureAchatNoteCreate' is not null or undefined
            assertParamExists('createFactureAchatNoteFactureAchatNoteCollection', 'factureAchatNoteJsonldFactureAchatNoteCreate', factureAchatNoteJsonldFactureAchatNoteCreate)
            const localVarPath = `/achats/facture_achat_notes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(factureAchatNoteJsonldFactureAchatNoteCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Supprimer une note facture d\'achat
         * @summary Supprimer une note facture d\'achat
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFactureAchatNoteFactureAchatNoteItem: async (id: string, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteFactureAchatNoteFactureAchatNoteItem', 'id', id)
            const localVarPath = `/achats/facture_achat_notes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer une note de facture d\'achat
         * @summary Récupérer une note de facture d\'achat
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFactureAchatNoteFactureAchatNoteItem: async (id: string, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFactureAchatNoteFactureAchatNoteItem', 'id', id)
            const localVarPath = `/achats/facture_achat_notes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Mettre à jour une note de facture d\'achat
         * @summary Mettre à jour une note de facture d\'achat
         * @param {string} id Resource identifier
         * @param {FactureAchatNoteJsonldFactureAchatNoteWrite} factureAchatNoteJsonldFactureAchatNoteWrite The updated FactureAchatNote resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFactureAchatNoteFactureAchatNoteItem: async (id: string, factureAchatNoteJsonldFactureAchatNoteWrite: FactureAchatNoteJsonldFactureAchatNoteWrite, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateFactureAchatNoteFactureAchatNoteItem', 'id', id)
            // verify required parameter 'factureAchatNoteJsonldFactureAchatNoteWrite' is not null or undefined
            assertParamExists('updateFactureAchatNoteFactureAchatNoteItem', 'factureAchatNoteJsonldFactureAchatNoteWrite', factureAchatNoteJsonldFactureAchatNoteWrite)
            const localVarPath = `/achats/facture_achat_notes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(factureAchatNoteJsonldFactureAchatNoteWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FactureAchatNoteApi - functional programming interface
 * @export
 */
export const FactureAchatNoteApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FactureAchatNoteApiAxiosParamCreator(configuration)
    return {
        /**
         * Créer une note de facture d\'achat
         * @summary Créer une note de facture d\'achat
         * @param {FactureAchatNoteJsonldFactureAchatNoteCreate} factureAchatNoteJsonldFactureAchatNoteCreate The new FactureAchatNote resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFactureAchatNoteFactureAchatNoteCollection(factureAchatNoteJsonldFactureAchatNoteCreate: FactureAchatNoteJsonldFactureAchatNoteCreate, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FactureAchatNoteJsonldFactureAchatRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFactureAchatNoteFactureAchatNoteCollection(factureAchatNoteJsonldFactureAchatNoteCreate, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Supprimer une note facture d\'achat
         * @summary Supprimer une note facture d\'achat
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFactureAchatNoteFactureAchatNoteItem(id: string, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFactureAchatNoteFactureAchatNoteItem(id, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Récupérer une note de facture d\'achat
         * @summary Récupérer une note de facture d\'achat
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFactureAchatNoteFactureAchatNoteItem(id: string, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FactureAchatNoteJsonldFactureAchatRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFactureAchatNoteFactureAchatNoteItem(id, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Mettre à jour une note de facture d\'achat
         * @summary Mettre à jour une note de facture d\'achat
         * @param {string} id Resource identifier
         * @param {FactureAchatNoteJsonldFactureAchatNoteWrite} factureAchatNoteJsonldFactureAchatNoteWrite The updated FactureAchatNote resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFactureAchatNoteFactureAchatNoteItem(id: string, factureAchatNoteJsonldFactureAchatNoteWrite: FactureAchatNoteJsonldFactureAchatNoteWrite, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FactureAchatNoteJsonldFactureAchatRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFactureAchatNoteFactureAchatNoteItem(id, factureAchatNoteJsonldFactureAchatNoteWrite, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FactureAchatNoteApi - factory interface
 * @export
 */
export const FactureAchatNoteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FactureAchatNoteApiFp(configuration)
    return {
        /**
         * Créer une note de facture d\'achat
         * @summary Créer une note de facture d\'achat
         * @param {FactureAchatNoteApiCreateFactureAchatNoteFactureAchatNoteCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFactureAchatNoteFactureAchatNoteCollection(requestParameters: FactureAchatNoteApiCreateFactureAchatNoteFactureAchatNoteCollectionRequest, options?: AxiosRequestConfig): AxiosPromise<FactureAchatNoteJsonldFactureAchatRead> {
            return localVarFp.createFactureAchatNoteFactureAchatNoteCollection(requestParameters.factureAchatNoteJsonldFactureAchatNoteCreate, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Supprimer une note facture d\'achat
         * @summary Supprimer une note facture d\'achat
         * @param {FactureAchatNoteApiDeleteFactureAchatNoteFactureAchatNoteItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFactureAchatNoteFactureAchatNoteItem(requestParameters: FactureAchatNoteApiDeleteFactureAchatNoteFactureAchatNoteItemRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteFactureAchatNoteFactureAchatNoteItem(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer une note de facture d\'achat
         * @summary Récupérer une note de facture d\'achat
         * @param {FactureAchatNoteApiGetFactureAchatNoteFactureAchatNoteItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFactureAchatNoteFactureAchatNoteItem(requestParameters: FactureAchatNoteApiGetFactureAchatNoteFactureAchatNoteItemRequest, options?: AxiosRequestConfig): AxiosPromise<FactureAchatNoteJsonldFactureAchatRead> {
            return localVarFp.getFactureAchatNoteFactureAchatNoteItem(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Mettre à jour une note de facture d\'achat
         * @summary Mettre à jour une note de facture d\'achat
         * @param {FactureAchatNoteApiUpdateFactureAchatNoteFactureAchatNoteItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFactureAchatNoteFactureAchatNoteItem(requestParameters: FactureAchatNoteApiUpdateFactureAchatNoteFactureAchatNoteItemRequest, options?: AxiosRequestConfig): AxiosPromise<FactureAchatNoteJsonldFactureAchatRead> {
            return localVarFp.updateFactureAchatNoteFactureAchatNoteItem(requestParameters.id, requestParameters.factureAchatNoteJsonldFactureAchatNoteWrite, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createFactureAchatNoteFactureAchatNoteCollection operation in FactureAchatNoteApi.
 * @export
 * @interface FactureAchatNoteApiCreateFactureAchatNoteFactureAchatNoteCollectionRequest
 */
export interface FactureAchatNoteApiCreateFactureAchatNoteFactureAchatNoteCollectionRequest {
    /**
     * The new FactureAchatNote resource
     * @type {FactureAchatNoteJsonldFactureAchatNoteCreate}
     * @memberof FactureAchatNoteApiCreateFactureAchatNoteFactureAchatNoteCollection
     */
    readonly factureAchatNoteJsonldFactureAchatNoteCreate: FactureAchatNoteJsonldFactureAchatNoteCreate

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof FactureAchatNoteApiCreateFactureAchatNoteFactureAchatNoteCollection
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for deleteFactureAchatNoteFactureAchatNoteItem operation in FactureAchatNoteApi.
 * @export
 * @interface FactureAchatNoteApiDeleteFactureAchatNoteFactureAchatNoteItemRequest
 */
export interface FactureAchatNoteApiDeleteFactureAchatNoteFactureAchatNoteItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof FactureAchatNoteApiDeleteFactureAchatNoteFactureAchatNoteItem
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof FactureAchatNoteApiDeleteFactureAchatNoteFactureAchatNoteItem
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for getFactureAchatNoteFactureAchatNoteItem operation in FactureAchatNoteApi.
 * @export
 * @interface FactureAchatNoteApiGetFactureAchatNoteFactureAchatNoteItemRequest
 */
export interface FactureAchatNoteApiGetFactureAchatNoteFactureAchatNoteItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof FactureAchatNoteApiGetFactureAchatNoteFactureAchatNoteItem
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof FactureAchatNoteApiGetFactureAchatNoteFactureAchatNoteItem
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for updateFactureAchatNoteFactureAchatNoteItem operation in FactureAchatNoteApi.
 * @export
 * @interface FactureAchatNoteApiUpdateFactureAchatNoteFactureAchatNoteItemRequest
 */
export interface FactureAchatNoteApiUpdateFactureAchatNoteFactureAchatNoteItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof FactureAchatNoteApiUpdateFactureAchatNoteFactureAchatNoteItem
     */
    readonly id: string

    /**
     * The updated FactureAchatNote resource
     * @type {FactureAchatNoteJsonldFactureAchatNoteWrite}
     * @memberof FactureAchatNoteApiUpdateFactureAchatNoteFactureAchatNoteItem
     */
    readonly factureAchatNoteJsonldFactureAchatNoteWrite: FactureAchatNoteJsonldFactureAchatNoteWrite

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof FactureAchatNoteApiUpdateFactureAchatNoteFactureAchatNoteItem
     */
    readonly xIdSociete?: number
}

/**
 * FactureAchatNoteApi - object-oriented interface
 * @export
 * @class FactureAchatNoteApi
 * @extends {BaseAPI}
 */
export class FactureAchatNoteApi extends BaseAPI {
    /**
     * Créer une note de facture d\'achat
     * @summary Créer une note de facture d\'achat
     * @param {FactureAchatNoteApiCreateFactureAchatNoteFactureAchatNoteCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FactureAchatNoteApi
     */
    public createFactureAchatNoteFactureAchatNoteCollection(requestParameters: FactureAchatNoteApiCreateFactureAchatNoteFactureAchatNoteCollectionRequest, options?: AxiosRequestConfig) {
        return FactureAchatNoteApiFp(this.configuration).createFactureAchatNoteFactureAchatNoteCollection(requestParameters.factureAchatNoteJsonldFactureAchatNoteCreate, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Supprimer une note facture d\'achat
     * @summary Supprimer une note facture d\'achat
     * @param {FactureAchatNoteApiDeleteFactureAchatNoteFactureAchatNoteItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FactureAchatNoteApi
     */
    public deleteFactureAchatNoteFactureAchatNoteItem(requestParameters: FactureAchatNoteApiDeleteFactureAchatNoteFactureAchatNoteItemRequest, options?: AxiosRequestConfig) {
        return FactureAchatNoteApiFp(this.configuration).deleteFactureAchatNoteFactureAchatNoteItem(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer une note de facture d\'achat
     * @summary Récupérer une note de facture d\'achat
     * @param {FactureAchatNoteApiGetFactureAchatNoteFactureAchatNoteItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FactureAchatNoteApi
     */
    public getFactureAchatNoteFactureAchatNoteItem(requestParameters: FactureAchatNoteApiGetFactureAchatNoteFactureAchatNoteItemRequest, options?: AxiosRequestConfig) {
        return FactureAchatNoteApiFp(this.configuration).getFactureAchatNoteFactureAchatNoteItem(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Mettre à jour une note de facture d\'achat
     * @summary Mettre à jour une note de facture d\'achat
     * @param {FactureAchatNoteApiUpdateFactureAchatNoteFactureAchatNoteItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FactureAchatNoteApi
     */
    public updateFactureAchatNoteFactureAchatNoteItem(requestParameters: FactureAchatNoteApiUpdateFactureAchatNoteFactureAchatNoteItemRequest, options?: AxiosRequestConfig) {
        return FactureAchatNoteApiFp(this.configuration).updateFactureAchatNoteFactureAchatNoteItem(requestParameters.id, requestParameters.factureAchatNoteJsonldFactureAchatNoteWrite, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MediaObjectApi - axios parameter creator
 * @export
 */
export const MediaObjectApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Supprimer un document
         * @summary Supprimer un document
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMediaObjectItem: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteMediaObjectItem', 'id', id)
            const localVarPath = `/achats/media_objects/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer un document
         * @summary Récupérer un document
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMediaObjectItem: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getMediaObjectItem', 'id', id)
            const localVarPath = `/achats/media_objects/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the collection of MediaObject resources.
         * @summary Retrieves the collection of MediaObject resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [createdBy] 
         * @param {Array<string>} [createdBy2] 
         * @param {number} [idSociete] 
         * @param {Array<number>} [idSociete2] 
         * @param {boolean} [existsFacturesAchat] 
         * @param {boolean} [forceInterne] 
         * @param {string} [dateDebutTraitementBefore] 
         * @param {string} [dateDebutTraitementStrictlyBefore] 
         * @param {string} [dateDebutTraitementAfter] 
         * @param {string} [dateDebutTraitementStrictlyAfter] 
         * @param {boolean} [available] 
         * @param {'asc' | 'desc'} [orderId] 
         * @param {'asc' | 'desc'} [orderCreatedAt] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMediaObjectMediaObjectCollection: async (page?: number, itemsPerPage?: number, createdBy?: string, createdBy2?: Array<string>, idSociete?: number, idSociete2?: Array<number>, existsFacturesAchat?: boolean, forceInterne?: boolean, dateDebutTraitementBefore?: string, dateDebutTraitementStrictlyBefore?: string, dateDebutTraitementAfter?: string, dateDebutTraitementStrictlyAfter?: string, available?: boolean, orderId?: 'asc' | 'desc', orderCreatedAt?: 'asc' | 'desc', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/achats/media_objects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (createdBy !== undefined) {
                localVarQueryParameter['createdBy'] = createdBy;
            }

            if (createdBy2) {
                localVarQueryParameter['createdBy[]'] = createdBy2;
            }

            if (idSociete !== undefined) {
                localVarQueryParameter['idSociete'] = idSociete;
            }

            if (idSociete2) {
                localVarQueryParameter['idSociete[]'] = idSociete2;
            }

            if (existsFacturesAchat !== undefined) {
                localVarQueryParameter['exists[facturesAchat]'] = existsFacturesAchat;
            }

            if (forceInterne !== undefined) {
                localVarQueryParameter['forceInterne'] = forceInterne;
            }

            if (dateDebutTraitementBefore !== undefined) {
                localVarQueryParameter['dateDebutTraitement[before]'] = dateDebutTraitementBefore;
            }

            if (dateDebutTraitementStrictlyBefore !== undefined) {
                localVarQueryParameter['dateDebutTraitement[strictly_before]'] = dateDebutTraitementStrictlyBefore;
            }

            if (dateDebutTraitementAfter !== undefined) {
                localVarQueryParameter['dateDebutTraitement[after]'] = dateDebutTraitementAfter;
            }

            if (dateDebutTraitementStrictlyAfter !== undefined) {
                localVarQueryParameter['dateDebutTraitement[strictly_after]'] = dateDebutTraitementStrictlyAfter;
            }

            if (available !== undefined) {
                localVarQueryParameter['available'] = available;
            }

            if (orderId !== undefined) {
                localVarQueryParameter['order[id]'] = orderId;
            }

            if (orderCreatedAt !== undefined) {
                localVarQueryParameter['order[createdAt]'] = orderCreatedAt;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the collection of MediaObject resources.
         * @summary Retrieves the collection of MediaObject resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [createdBy] 
         * @param {Array<string>} [createdBy2] 
         * @param {number} [idSociete] 
         * @param {Array<number>} [idSociete2] 
         * @param {boolean} [existsFacturesAchat] 
         * @param {boolean} [forceInterne] 
         * @param {string} [dateDebutTraitementBefore] 
         * @param {string} [dateDebutTraitementStrictlyBefore] 
         * @param {string} [dateDebutTraitementAfter] 
         * @param {string} [dateDebutTraitementStrictlyAfter] 
         * @param {boolean} [available] 
         * @param {'asc' | 'desc'} [orderId] 
         * @param {'asc' | 'desc'} [orderCreatedAt] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMyMediaObjectMediaObjectCollection: async (page?: number, itemsPerPage?: number, createdBy?: string, createdBy2?: Array<string>, idSociete?: number, idSociete2?: Array<number>, existsFacturesAchat?: boolean, forceInterne?: boolean, dateDebutTraitementBefore?: string, dateDebutTraitementStrictlyBefore?: string, dateDebutTraitementAfter?: string, dateDebutTraitementStrictlyAfter?: string, available?: boolean, orderId?: 'asc' | 'desc', orderCreatedAt?: 'asc' | 'desc', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/achats/media_objects/my`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (createdBy !== undefined) {
                localVarQueryParameter['createdBy'] = createdBy;
            }

            if (createdBy2) {
                localVarQueryParameter['createdBy[]'] = createdBy2;
            }

            if (idSociete !== undefined) {
                localVarQueryParameter['idSociete'] = idSociete;
            }

            if (idSociete2) {
                localVarQueryParameter['idSociete[]'] = idSociete2;
            }

            if (existsFacturesAchat !== undefined) {
                localVarQueryParameter['exists[facturesAchat]'] = existsFacturesAchat;
            }

            if (forceInterne !== undefined) {
                localVarQueryParameter['forceInterne'] = forceInterne;
            }

            if (dateDebutTraitementBefore !== undefined) {
                localVarQueryParameter['dateDebutTraitement[before]'] = dateDebutTraitementBefore;
            }

            if (dateDebutTraitementStrictlyBefore !== undefined) {
                localVarQueryParameter['dateDebutTraitement[strictly_before]'] = dateDebutTraitementStrictlyBefore;
            }

            if (dateDebutTraitementAfter !== undefined) {
                localVarQueryParameter['dateDebutTraitement[after]'] = dateDebutTraitementAfter;
            }

            if (dateDebutTraitementStrictlyAfter !== undefined) {
                localVarQueryParameter['dateDebutTraitement[strictly_after]'] = dateDebutTraitementStrictlyAfter;
            }

            if (available !== undefined) {
                localVarQueryParameter['available'] = available;
            }

            if (orderId !== undefined) {
                localVarQueryParameter['order[id]'] = orderId;
            }

            if (orderCreatedAt !== undefined) {
                localVarQueryParameter['order[createdAt]'] = orderCreatedAt;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Modifier un document
         * @summary Modifier un document
         * @param {string} id Resource identifier
         * @param {MediaObject} mediaObject The updated MediaObject resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchMediaObjectItem: async (id: string, mediaObject: MediaObject, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchMediaObjectItem', 'id', id)
            // verify required parameter 'mediaObject' is not null or undefined
            assertParamExists('patchMediaObjectItem', 'mediaObject', mediaObject)
            const localVarPath = `/achats/media_objects/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mediaObject, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Stocker un document
         * @summary Stocker un document
         * @param {2 | 8 | 9 | 10 | 3 | 4 | 5 | 6 | 12} idSociete 
         * @param {boolean} [forceInterne] 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postMediaObjectCollection: async (idSociete: 2 | 8 | 9 | 10 | 3 | 4 | 5 | 6 | 12, forceInterne?: boolean, file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idSociete' is not null or undefined
            assertParamExists('postMediaObjectCollection', 'idSociete', idSociete)
            const localVarPath = `/achats/media_objects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (idSociete !== undefined) {
                localVarQueryParameter['idSociete'] = idSociete;
            }

            if (forceInterne !== undefined) {
                localVarQueryParameter['forceInterne'] = forceInterne;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MediaObjectApi - functional programming interface
 * @export
 */
export const MediaObjectApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MediaObjectApiAxiosParamCreator(configuration)
    return {
        /**
         * Supprimer un document
         * @summary Supprimer un document
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMediaObjectItem(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMediaObjectItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Récupérer un document
         * @summary Récupérer un document
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMediaObjectItem(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MediaObjectJsonldMediaObjectReadTimestampableBlameambleGroupTaggableGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMediaObjectItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the collection of MediaObject resources.
         * @summary Retrieves the collection of MediaObject resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [createdBy] 
         * @param {Array<string>} [createdBy2] 
         * @param {number} [idSociete] 
         * @param {Array<number>} [idSociete2] 
         * @param {boolean} [existsFacturesAchat] 
         * @param {boolean} [forceInterne] 
         * @param {string} [dateDebutTraitementBefore] 
         * @param {string} [dateDebutTraitementStrictlyBefore] 
         * @param {string} [dateDebutTraitementAfter] 
         * @param {string} [dateDebutTraitementStrictlyAfter] 
         * @param {boolean} [available] 
         * @param {'asc' | 'desc'} [orderId] 
         * @param {'asc' | 'desc'} [orderCreatedAt] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMediaObjectMediaObjectCollection(page?: number, itemsPerPage?: number, createdBy?: string, createdBy2?: Array<string>, idSociete?: number, idSociete2?: Array<number>, existsFacturesAchat?: boolean, forceInterne?: boolean, dateDebutTraitementBefore?: string, dateDebutTraitementStrictlyBefore?: string, dateDebutTraitementAfter?: string, dateDebutTraitementStrictlyAfter?: string, available?: boolean, orderId?: 'asc' | 'desc', orderCreatedAt?: 'asc' | 'desc', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListMediaObjectMediaObjectCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMediaObjectMediaObjectCollection(page, itemsPerPage, createdBy, createdBy2, idSociete, idSociete2, existsFacturesAchat, forceInterne, dateDebutTraitementBefore, dateDebutTraitementStrictlyBefore, dateDebutTraitementAfter, dateDebutTraitementStrictlyAfter, available, orderId, orderCreatedAt, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the collection of MediaObject resources.
         * @summary Retrieves the collection of MediaObject resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [createdBy] 
         * @param {Array<string>} [createdBy2] 
         * @param {number} [idSociete] 
         * @param {Array<number>} [idSociete2] 
         * @param {boolean} [existsFacturesAchat] 
         * @param {boolean} [forceInterne] 
         * @param {string} [dateDebutTraitementBefore] 
         * @param {string} [dateDebutTraitementStrictlyBefore] 
         * @param {string} [dateDebutTraitementAfter] 
         * @param {string} [dateDebutTraitementStrictlyAfter] 
         * @param {boolean} [available] 
         * @param {'asc' | 'desc'} [orderId] 
         * @param {'asc' | 'desc'} [orderCreatedAt] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMyMediaObjectMediaObjectCollection(page?: number, itemsPerPage?: number, createdBy?: string, createdBy2?: Array<string>, idSociete?: number, idSociete2?: Array<number>, existsFacturesAchat?: boolean, forceInterne?: boolean, dateDebutTraitementBefore?: string, dateDebutTraitementStrictlyBefore?: string, dateDebutTraitementAfter?: string, dateDebutTraitementStrictlyAfter?: string, available?: boolean, orderId?: 'asc' | 'desc', orderCreatedAt?: 'asc' | 'desc', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListMediaObjectMediaObjectCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMyMediaObjectMediaObjectCollection(page, itemsPerPage, createdBy, createdBy2, idSociete, idSociete2, existsFacturesAchat, forceInterne, dateDebutTraitementBefore, dateDebutTraitementStrictlyBefore, dateDebutTraitementAfter, dateDebutTraitementStrictlyAfter, available, orderId, orderCreatedAt, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Modifier un document
         * @summary Modifier un document
         * @param {string} id Resource identifier
         * @param {MediaObject} mediaObject The updated MediaObject resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchMediaObjectItem(id: string, mediaObject: MediaObject, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MediaObjectJsonldDateDebutTraitementTaggableGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchMediaObjectItem(id, mediaObject, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Stocker un document
         * @summary Stocker un document
         * @param {2 | 8 | 9 | 10 | 3 | 4 | 5 | 6 | 12} idSociete 
         * @param {boolean} [forceInterne] 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postMediaObjectCollection(idSociete: 2 | 8 | 9 | 10 | 3 | 4 | 5 | 6 | 12, forceInterne?: boolean, file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MediaObjectJsonldMediaObjectReadTimestampableBlameambleGroupTaggableGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postMediaObjectCollection(idSociete, forceInterne, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MediaObjectApi - factory interface
 * @export
 */
export const MediaObjectApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MediaObjectApiFp(configuration)
    return {
        /**
         * Supprimer un document
         * @summary Supprimer un document
         * @param {MediaObjectApiDeleteMediaObjectItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMediaObjectItem(requestParameters: MediaObjectApiDeleteMediaObjectItemRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteMediaObjectItem(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer un document
         * @summary Récupérer un document
         * @param {MediaObjectApiGetMediaObjectItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMediaObjectItem(requestParameters: MediaObjectApiGetMediaObjectItemRequest, options?: AxiosRequestConfig): AxiosPromise<MediaObjectJsonldMediaObjectReadTimestampableBlameambleGroupTaggableGroup> {
            return localVarFp.getMediaObjectItem(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the collection of MediaObject resources.
         * @summary Retrieves the collection of MediaObject resources.
         * @param {MediaObjectApiListMediaObjectMediaObjectCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMediaObjectMediaObjectCollection(requestParameters: MediaObjectApiListMediaObjectMediaObjectCollectionRequest = {}, options?: AxiosRequestConfig): AxiosPromise<ListMediaObjectMediaObjectCollection200Response> {
            return localVarFp.listMediaObjectMediaObjectCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.createdBy, requestParameters.createdBy2, requestParameters.idSociete, requestParameters.idSociete2, requestParameters.existsFacturesAchat, requestParameters.forceInterne, requestParameters.dateDebutTraitementBefore, requestParameters.dateDebutTraitementStrictlyBefore, requestParameters.dateDebutTraitementAfter, requestParameters.dateDebutTraitementStrictlyAfter, requestParameters.available, requestParameters.orderId, requestParameters.orderCreatedAt, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the collection of MediaObject resources.
         * @summary Retrieves the collection of MediaObject resources.
         * @param {MediaObjectApiListMyMediaObjectMediaObjectCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMyMediaObjectMediaObjectCollection(requestParameters: MediaObjectApiListMyMediaObjectMediaObjectCollectionRequest = {}, options?: AxiosRequestConfig): AxiosPromise<ListMediaObjectMediaObjectCollection200Response> {
            return localVarFp.listMyMediaObjectMediaObjectCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.createdBy, requestParameters.createdBy2, requestParameters.idSociete, requestParameters.idSociete2, requestParameters.existsFacturesAchat, requestParameters.forceInterne, requestParameters.dateDebutTraitementBefore, requestParameters.dateDebutTraitementStrictlyBefore, requestParameters.dateDebutTraitementAfter, requestParameters.dateDebutTraitementStrictlyAfter, requestParameters.available, requestParameters.orderId, requestParameters.orderCreatedAt, options).then((request) => request(axios, basePath));
        },
        /**
         * Modifier un document
         * @summary Modifier un document
         * @param {MediaObjectApiPatchMediaObjectItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchMediaObjectItem(requestParameters: MediaObjectApiPatchMediaObjectItemRequest, options?: AxiosRequestConfig): AxiosPromise<MediaObjectJsonldDateDebutTraitementTaggableGroup> {
            return localVarFp.patchMediaObjectItem(requestParameters.id, requestParameters.mediaObject, options).then((request) => request(axios, basePath));
        },
        /**
         * Stocker un document
         * @summary Stocker un document
         * @param {MediaObjectApiPostMediaObjectCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postMediaObjectCollection(requestParameters: MediaObjectApiPostMediaObjectCollectionRequest, options?: AxiosRequestConfig): AxiosPromise<MediaObjectJsonldMediaObjectReadTimestampableBlameambleGroupTaggableGroup> {
            return localVarFp.postMediaObjectCollection(requestParameters.idSociete, requestParameters.forceInterne, requestParameters.file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for deleteMediaObjectItem operation in MediaObjectApi.
 * @export
 * @interface MediaObjectApiDeleteMediaObjectItemRequest
 */
export interface MediaObjectApiDeleteMediaObjectItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof MediaObjectApiDeleteMediaObjectItem
     */
    readonly id: string
}

/**
 * Request parameters for getMediaObjectItem operation in MediaObjectApi.
 * @export
 * @interface MediaObjectApiGetMediaObjectItemRequest
 */
export interface MediaObjectApiGetMediaObjectItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof MediaObjectApiGetMediaObjectItem
     */
    readonly id: string
}

/**
 * Request parameters for listMediaObjectMediaObjectCollection operation in MediaObjectApi.
 * @export
 * @interface MediaObjectApiListMediaObjectMediaObjectCollectionRequest
 */
export interface MediaObjectApiListMediaObjectMediaObjectCollectionRequest {
    /**
     * The collection page number
     * @type {number}
     * @memberof MediaObjectApiListMediaObjectMediaObjectCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof MediaObjectApiListMediaObjectMediaObjectCollection
     */
    readonly itemsPerPage?: number

    /**
     * 
     * @type {string}
     * @memberof MediaObjectApiListMediaObjectMediaObjectCollection
     */
    readonly createdBy?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof MediaObjectApiListMediaObjectMediaObjectCollection
     */
    readonly createdBy2?: Array<string>

    /**
     * 
     * @type {number}
     * @memberof MediaObjectApiListMediaObjectMediaObjectCollection
     */
    readonly idSociete?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof MediaObjectApiListMediaObjectMediaObjectCollection
     */
    readonly idSociete2?: Array<number>

    /**
     * 
     * @type {boolean}
     * @memberof MediaObjectApiListMediaObjectMediaObjectCollection
     */
    readonly existsFacturesAchat?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof MediaObjectApiListMediaObjectMediaObjectCollection
     */
    readonly forceInterne?: boolean

    /**
     * 
     * @type {string}
     * @memberof MediaObjectApiListMediaObjectMediaObjectCollection
     */
    readonly dateDebutTraitementBefore?: string

    /**
     * 
     * @type {string}
     * @memberof MediaObjectApiListMediaObjectMediaObjectCollection
     */
    readonly dateDebutTraitementStrictlyBefore?: string

    /**
     * 
     * @type {string}
     * @memberof MediaObjectApiListMediaObjectMediaObjectCollection
     */
    readonly dateDebutTraitementAfter?: string

    /**
     * 
     * @type {string}
     * @memberof MediaObjectApiListMediaObjectMediaObjectCollection
     */
    readonly dateDebutTraitementStrictlyAfter?: string

    /**
     * 
     * @type {boolean}
     * @memberof MediaObjectApiListMediaObjectMediaObjectCollection
     */
    readonly available?: boolean

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof MediaObjectApiListMediaObjectMediaObjectCollection
     */
    readonly orderId?: 'asc' | 'desc'

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof MediaObjectApiListMediaObjectMediaObjectCollection
     */
    readonly orderCreatedAt?: 'asc' | 'desc'
}

/**
 * Request parameters for listMyMediaObjectMediaObjectCollection operation in MediaObjectApi.
 * @export
 * @interface MediaObjectApiListMyMediaObjectMediaObjectCollectionRequest
 */
export interface MediaObjectApiListMyMediaObjectMediaObjectCollectionRequest {
    /**
     * The collection page number
     * @type {number}
     * @memberof MediaObjectApiListMyMediaObjectMediaObjectCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof MediaObjectApiListMyMediaObjectMediaObjectCollection
     */
    readonly itemsPerPage?: number

    /**
     * 
     * @type {string}
     * @memberof MediaObjectApiListMyMediaObjectMediaObjectCollection
     */
    readonly createdBy?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof MediaObjectApiListMyMediaObjectMediaObjectCollection
     */
    readonly createdBy2?: Array<string>

    /**
     * 
     * @type {number}
     * @memberof MediaObjectApiListMyMediaObjectMediaObjectCollection
     */
    readonly idSociete?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof MediaObjectApiListMyMediaObjectMediaObjectCollection
     */
    readonly idSociete2?: Array<number>

    /**
     * 
     * @type {boolean}
     * @memberof MediaObjectApiListMyMediaObjectMediaObjectCollection
     */
    readonly existsFacturesAchat?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof MediaObjectApiListMyMediaObjectMediaObjectCollection
     */
    readonly forceInterne?: boolean

    /**
     * 
     * @type {string}
     * @memberof MediaObjectApiListMyMediaObjectMediaObjectCollection
     */
    readonly dateDebutTraitementBefore?: string

    /**
     * 
     * @type {string}
     * @memberof MediaObjectApiListMyMediaObjectMediaObjectCollection
     */
    readonly dateDebutTraitementStrictlyBefore?: string

    /**
     * 
     * @type {string}
     * @memberof MediaObjectApiListMyMediaObjectMediaObjectCollection
     */
    readonly dateDebutTraitementAfter?: string

    /**
     * 
     * @type {string}
     * @memberof MediaObjectApiListMyMediaObjectMediaObjectCollection
     */
    readonly dateDebutTraitementStrictlyAfter?: string

    /**
     * 
     * @type {boolean}
     * @memberof MediaObjectApiListMyMediaObjectMediaObjectCollection
     */
    readonly available?: boolean

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof MediaObjectApiListMyMediaObjectMediaObjectCollection
     */
    readonly orderId?: 'asc' | 'desc'

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof MediaObjectApiListMyMediaObjectMediaObjectCollection
     */
    readonly orderCreatedAt?: 'asc' | 'desc'
}

/**
 * Request parameters for patchMediaObjectItem operation in MediaObjectApi.
 * @export
 * @interface MediaObjectApiPatchMediaObjectItemRequest
 */
export interface MediaObjectApiPatchMediaObjectItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof MediaObjectApiPatchMediaObjectItem
     */
    readonly id: string

    /**
     * The updated MediaObject resource
     * @type {MediaObject}
     * @memberof MediaObjectApiPatchMediaObjectItem
     */
    readonly mediaObject: MediaObject
}

/**
 * Request parameters for postMediaObjectCollection operation in MediaObjectApi.
 * @export
 * @interface MediaObjectApiPostMediaObjectCollectionRequest
 */
export interface MediaObjectApiPostMediaObjectCollectionRequest {
    /**
     * 
     * @type {2 | 8 | 9 | 10 | 3 | 4 | 5 | 6 | 12}
     * @memberof MediaObjectApiPostMediaObjectCollection
     */
    readonly idSociete: 2 | 8 | 9 | 10 | 3 | 4 | 5 | 6 | 12

    /**
     * 
     * @type {boolean}
     * @memberof MediaObjectApiPostMediaObjectCollection
     */
    readonly forceInterne?: boolean

    /**
     * 
     * @type {File}
     * @memberof MediaObjectApiPostMediaObjectCollection
     */
    readonly file?: File
}

/**
 * MediaObjectApi - object-oriented interface
 * @export
 * @class MediaObjectApi
 * @extends {BaseAPI}
 */
export class MediaObjectApi extends BaseAPI {
    /**
     * Supprimer un document
     * @summary Supprimer un document
     * @param {MediaObjectApiDeleteMediaObjectItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaObjectApi
     */
    public deleteMediaObjectItem(requestParameters: MediaObjectApiDeleteMediaObjectItemRequest, options?: AxiosRequestConfig) {
        return MediaObjectApiFp(this.configuration).deleteMediaObjectItem(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer un document
     * @summary Récupérer un document
     * @param {MediaObjectApiGetMediaObjectItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaObjectApi
     */
    public getMediaObjectItem(requestParameters: MediaObjectApiGetMediaObjectItemRequest, options?: AxiosRequestConfig) {
        return MediaObjectApiFp(this.configuration).getMediaObjectItem(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the collection of MediaObject resources.
     * @summary Retrieves the collection of MediaObject resources.
     * @param {MediaObjectApiListMediaObjectMediaObjectCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaObjectApi
     */
    public listMediaObjectMediaObjectCollection(requestParameters: MediaObjectApiListMediaObjectMediaObjectCollectionRequest = {}, options?: AxiosRequestConfig) {
        return MediaObjectApiFp(this.configuration).listMediaObjectMediaObjectCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.createdBy, requestParameters.createdBy2, requestParameters.idSociete, requestParameters.idSociete2, requestParameters.existsFacturesAchat, requestParameters.forceInterne, requestParameters.dateDebutTraitementBefore, requestParameters.dateDebutTraitementStrictlyBefore, requestParameters.dateDebutTraitementAfter, requestParameters.dateDebutTraitementStrictlyAfter, requestParameters.available, requestParameters.orderId, requestParameters.orderCreatedAt, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the collection of MediaObject resources.
     * @summary Retrieves the collection of MediaObject resources.
     * @param {MediaObjectApiListMyMediaObjectMediaObjectCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaObjectApi
     */
    public listMyMediaObjectMediaObjectCollection(requestParameters: MediaObjectApiListMyMediaObjectMediaObjectCollectionRequest = {}, options?: AxiosRequestConfig) {
        return MediaObjectApiFp(this.configuration).listMyMediaObjectMediaObjectCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.createdBy, requestParameters.createdBy2, requestParameters.idSociete, requestParameters.idSociete2, requestParameters.existsFacturesAchat, requestParameters.forceInterne, requestParameters.dateDebutTraitementBefore, requestParameters.dateDebutTraitementStrictlyBefore, requestParameters.dateDebutTraitementAfter, requestParameters.dateDebutTraitementStrictlyAfter, requestParameters.available, requestParameters.orderId, requestParameters.orderCreatedAt, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Modifier un document
     * @summary Modifier un document
     * @param {MediaObjectApiPatchMediaObjectItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaObjectApi
     */
    public patchMediaObjectItem(requestParameters: MediaObjectApiPatchMediaObjectItemRequest, options?: AxiosRequestConfig) {
        return MediaObjectApiFp(this.configuration).patchMediaObjectItem(requestParameters.id, requestParameters.mediaObject, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Stocker un document
     * @summary Stocker un document
     * @param {MediaObjectApiPostMediaObjectCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaObjectApi
     */
    public postMediaObjectCollection(requestParameters: MediaObjectApiPostMediaObjectCollectionRequest, options?: AxiosRequestConfig) {
        return MediaObjectApiFp(this.configuration).postMediaObjectCollection(requestParameters.idSociete, requestParameters.forceInterne, requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MediaObjectNoteApi - axios parameter creator
 * @export
 */
export const MediaObjectNoteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Créer une note de media object
         * @summary Créer une note de media object
         * @param {MediaObjectNoteJsonldMediaObjectNoteCreate} mediaObjectNoteJsonldMediaObjectNoteCreate The new MediaObjectNote resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMediaObjectNoteMediaObjectNoteCollection: async (mediaObjectNoteJsonldMediaObjectNoteCreate: MediaObjectNoteJsonldMediaObjectNoteCreate, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mediaObjectNoteJsonldMediaObjectNoteCreate' is not null or undefined
            assertParamExists('createMediaObjectNoteMediaObjectNoteCollection', 'mediaObjectNoteJsonldMediaObjectNoteCreate', mediaObjectNoteJsonldMediaObjectNoteCreate)
            const localVarPath = `/achats/media_object_notes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mediaObjectNoteJsonldMediaObjectNoteCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Supprimer une note media object
         * @summary Supprimer une note media object
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMediaObjectNoteMediaObjectNoteItem: async (id: string, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteMediaObjectNoteMediaObjectNoteItem', 'id', id)
            const localVarPath = `/achats/media_object_notes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer une note de media object
         * @summary Récupérer une note de media object
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMediaObjectNoteMediaObjectNoteItem: async (id: string, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getMediaObjectNoteMediaObjectNoteItem', 'id', id)
            const localVarPath = `/achats/media_object_notes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Mettre à jour une note de media object
         * @summary Mettre à jour une note de media object
         * @param {string} id Resource identifier
         * @param {MediaObjectNoteJsonldMediaObjectNoteWrite} mediaObjectNoteJsonldMediaObjectNoteWrite The updated MediaObjectNote resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMediaObjectNoteMediaObjectNoteItem: async (id: string, mediaObjectNoteJsonldMediaObjectNoteWrite: MediaObjectNoteJsonldMediaObjectNoteWrite, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateMediaObjectNoteMediaObjectNoteItem', 'id', id)
            // verify required parameter 'mediaObjectNoteJsonldMediaObjectNoteWrite' is not null or undefined
            assertParamExists('updateMediaObjectNoteMediaObjectNoteItem', 'mediaObjectNoteJsonldMediaObjectNoteWrite', mediaObjectNoteJsonldMediaObjectNoteWrite)
            const localVarPath = `/achats/media_object_notes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mediaObjectNoteJsonldMediaObjectNoteWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MediaObjectNoteApi - functional programming interface
 * @export
 */
export const MediaObjectNoteApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MediaObjectNoteApiAxiosParamCreator(configuration)
    return {
        /**
         * Créer une note de media object
         * @summary Créer une note de media object
         * @param {MediaObjectNoteJsonldMediaObjectNoteCreate} mediaObjectNoteJsonldMediaObjectNoteCreate The new MediaObjectNote resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMediaObjectNoteMediaObjectNoteCollection(mediaObjectNoteJsonldMediaObjectNoteCreate: MediaObjectNoteJsonldMediaObjectNoteCreate, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MediaObjectNoteJsonldMediaObjectRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMediaObjectNoteMediaObjectNoteCollection(mediaObjectNoteJsonldMediaObjectNoteCreate, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Supprimer une note media object
         * @summary Supprimer une note media object
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMediaObjectNoteMediaObjectNoteItem(id: string, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMediaObjectNoteMediaObjectNoteItem(id, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Récupérer une note de media object
         * @summary Récupérer une note de media object
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMediaObjectNoteMediaObjectNoteItem(id: string, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MediaObjectNoteJsonldMediaObjectRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMediaObjectNoteMediaObjectNoteItem(id, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Mettre à jour une note de media object
         * @summary Mettre à jour une note de media object
         * @param {string} id Resource identifier
         * @param {MediaObjectNoteJsonldMediaObjectNoteWrite} mediaObjectNoteJsonldMediaObjectNoteWrite The updated MediaObjectNote resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMediaObjectNoteMediaObjectNoteItem(id: string, mediaObjectNoteJsonldMediaObjectNoteWrite: MediaObjectNoteJsonldMediaObjectNoteWrite, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MediaObjectNoteJsonldMediaObjectRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMediaObjectNoteMediaObjectNoteItem(id, mediaObjectNoteJsonldMediaObjectNoteWrite, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MediaObjectNoteApi - factory interface
 * @export
 */
export const MediaObjectNoteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MediaObjectNoteApiFp(configuration)
    return {
        /**
         * Créer une note de media object
         * @summary Créer une note de media object
         * @param {MediaObjectNoteApiCreateMediaObjectNoteMediaObjectNoteCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMediaObjectNoteMediaObjectNoteCollection(requestParameters: MediaObjectNoteApiCreateMediaObjectNoteMediaObjectNoteCollectionRequest, options?: AxiosRequestConfig): AxiosPromise<MediaObjectNoteJsonldMediaObjectRead> {
            return localVarFp.createMediaObjectNoteMediaObjectNoteCollection(requestParameters.mediaObjectNoteJsonldMediaObjectNoteCreate, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Supprimer une note media object
         * @summary Supprimer une note media object
         * @param {MediaObjectNoteApiDeleteMediaObjectNoteMediaObjectNoteItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMediaObjectNoteMediaObjectNoteItem(requestParameters: MediaObjectNoteApiDeleteMediaObjectNoteMediaObjectNoteItemRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteMediaObjectNoteMediaObjectNoteItem(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer une note de media object
         * @summary Récupérer une note de media object
         * @param {MediaObjectNoteApiGetMediaObjectNoteMediaObjectNoteItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMediaObjectNoteMediaObjectNoteItem(requestParameters: MediaObjectNoteApiGetMediaObjectNoteMediaObjectNoteItemRequest, options?: AxiosRequestConfig): AxiosPromise<MediaObjectNoteJsonldMediaObjectRead> {
            return localVarFp.getMediaObjectNoteMediaObjectNoteItem(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Mettre à jour une note de media object
         * @summary Mettre à jour une note de media object
         * @param {MediaObjectNoteApiUpdateMediaObjectNoteMediaObjectNoteItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMediaObjectNoteMediaObjectNoteItem(requestParameters: MediaObjectNoteApiUpdateMediaObjectNoteMediaObjectNoteItemRequest, options?: AxiosRequestConfig): AxiosPromise<MediaObjectNoteJsonldMediaObjectRead> {
            return localVarFp.updateMediaObjectNoteMediaObjectNoteItem(requestParameters.id, requestParameters.mediaObjectNoteJsonldMediaObjectNoteWrite, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createMediaObjectNoteMediaObjectNoteCollection operation in MediaObjectNoteApi.
 * @export
 * @interface MediaObjectNoteApiCreateMediaObjectNoteMediaObjectNoteCollectionRequest
 */
export interface MediaObjectNoteApiCreateMediaObjectNoteMediaObjectNoteCollectionRequest {
    /**
     * The new MediaObjectNote resource
     * @type {MediaObjectNoteJsonldMediaObjectNoteCreate}
     * @memberof MediaObjectNoteApiCreateMediaObjectNoteMediaObjectNoteCollection
     */
    readonly mediaObjectNoteJsonldMediaObjectNoteCreate: MediaObjectNoteJsonldMediaObjectNoteCreate

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof MediaObjectNoteApiCreateMediaObjectNoteMediaObjectNoteCollection
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for deleteMediaObjectNoteMediaObjectNoteItem operation in MediaObjectNoteApi.
 * @export
 * @interface MediaObjectNoteApiDeleteMediaObjectNoteMediaObjectNoteItemRequest
 */
export interface MediaObjectNoteApiDeleteMediaObjectNoteMediaObjectNoteItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof MediaObjectNoteApiDeleteMediaObjectNoteMediaObjectNoteItem
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof MediaObjectNoteApiDeleteMediaObjectNoteMediaObjectNoteItem
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for getMediaObjectNoteMediaObjectNoteItem operation in MediaObjectNoteApi.
 * @export
 * @interface MediaObjectNoteApiGetMediaObjectNoteMediaObjectNoteItemRequest
 */
export interface MediaObjectNoteApiGetMediaObjectNoteMediaObjectNoteItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof MediaObjectNoteApiGetMediaObjectNoteMediaObjectNoteItem
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof MediaObjectNoteApiGetMediaObjectNoteMediaObjectNoteItem
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for updateMediaObjectNoteMediaObjectNoteItem operation in MediaObjectNoteApi.
 * @export
 * @interface MediaObjectNoteApiUpdateMediaObjectNoteMediaObjectNoteItemRequest
 */
export interface MediaObjectNoteApiUpdateMediaObjectNoteMediaObjectNoteItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof MediaObjectNoteApiUpdateMediaObjectNoteMediaObjectNoteItem
     */
    readonly id: string

    /**
     * The updated MediaObjectNote resource
     * @type {MediaObjectNoteJsonldMediaObjectNoteWrite}
     * @memberof MediaObjectNoteApiUpdateMediaObjectNoteMediaObjectNoteItem
     */
    readonly mediaObjectNoteJsonldMediaObjectNoteWrite: MediaObjectNoteJsonldMediaObjectNoteWrite

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof MediaObjectNoteApiUpdateMediaObjectNoteMediaObjectNoteItem
     */
    readonly xIdSociete?: number
}

/**
 * MediaObjectNoteApi - object-oriented interface
 * @export
 * @class MediaObjectNoteApi
 * @extends {BaseAPI}
 */
export class MediaObjectNoteApi extends BaseAPI {
    /**
     * Créer une note de media object
     * @summary Créer une note de media object
     * @param {MediaObjectNoteApiCreateMediaObjectNoteMediaObjectNoteCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaObjectNoteApi
     */
    public createMediaObjectNoteMediaObjectNoteCollection(requestParameters: MediaObjectNoteApiCreateMediaObjectNoteMediaObjectNoteCollectionRequest, options?: AxiosRequestConfig) {
        return MediaObjectNoteApiFp(this.configuration).createMediaObjectNoteMediaObjectNoteCollection(requestParameters.mediaObjectNoteJsonldMediaObjectNoteCreate, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Supprimer une note media object
     * @summary Supprimer une note media object
     * @param {MediaObjectNoteApiDeleteMediaObjectNoteMediaObjectNoteItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaObjectNoteApi
     */
    public deleteMediaObjectNoteMediaObjectNoteItem(requestParameters: MediaObjectNoteApiDeleteMediaObjectNoteMediaObjectNoteItemRequest, options?: AxiosRequestConfig) {
        return MediaObjectNoteApiFp(this.configuration).deleteMediaObjectNoteMediaObjectNoteItem(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer une note de media object
     * @summary Récupérer une note de media object
     * @param {MediaObjectNoteApiGetMediaObjectNoteMediaObjectNoteItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaObjectNoteApi
     */
    public getMediaObjectNoteMediaObjectNoteItem(requestParameters: MediaObjectNoteApiGetMediaObjectNoteMediaObjectNoteItemRequest, options?: AxiosRequestConfig) {
        return MediaObjectNoteApiFp(this.configuration).getMediaObjectNoteMediaObjectNoteItem(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Mettre à jour une note de media object
     * @summary Mettre à jour une note de media object
     * @param {MediaObjectNoteApiUpdateMediaObjectNoteMediaObjectNoteItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaObjectNoteApi
     */
    public updateMediaObjectNoteMediaObjectNoteItem(requestParameters: MediaObjectNoteApiUpdateMediaObjectNoteMediaObjectNoteItemRequest, options?: AxiosRequestConfig) {
        return MediaObjectNoteApiFp(this.configuration).updateMediaObjectNoteMediaObjectNoteItem(requestParameters.id, requestParameters.mediaObjectNoteJsonldMediaObjectNoteWrite, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReleveAchatApi - axios parameter creator
 * @export
 */
export const ReleveAchatApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Créer un relevé d\'achat
         * @summary Créer un relevé d\'achat
         * @param {ReleveAchatJsonldReleveAchatCreateReleveAchatWrite} releveAchatJsonldReleveAchatCreateReleveAchatWrite The new ReleveAchat resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReleveAchatReleveAchatCollection: async (releveAchatJsonldReleveAchatCreateReleveAchatWrite: ReleveAchatJsonldReleveAchatCreateReleveAchatWrite, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'releveAchatJsonldReleveAchatCreateReleveAchatWrite' is not null or undefined
            assertParamExists('createReleveAchatReleveAchatCollection', 'releveAchatJsonldReleveAchatCreateReleveAchatWrite', releveAchatJsonldReleveAchatCreateReleveAchatWrite)
            const localVarPath = `/achats/releve_achats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(releveAchatJsonldReleveAchatCreateReleveAchatWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'un relevé d\'achat (minimum d\'infos par facture : idFacture, date facturation)
         * @summary Récupérer les informations d\'un relevé d\'achat
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReleveAchatReleveAchatItem: async (id: string, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getReleveAchatReleveAchatItem', 'id', id)
            const localVarPath = `/achats/releve_achats/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sommer les totaux des factures d\'achats du relevé
         * @summary Sommer les totaux des factures d\'achats du relevé
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReleveAchatSumReleveAchatItem: async (id: string, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getReleveAchatSumReleveAchatItem', 'id', id)
            const localVarPath = `/achats/releve_achats/{id}/sum`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer la liste des relevés d\'achats
         * @summary Récupérer la liste des relevés d\'achats
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {boolean} [cloture] 
         * @param {boolean} [deleted] 
         * @param {string} [createdAtBefore] 
         * @param {string} [createdAtStrictlyBefore] 
         * @param {string} [createdAtAfter] 
         * @param {string} [createdAtStrictlyAfter] 
         * @param {string} [updatedBy] 
         * @param {Array<string>} [updatedBy2] 
         * @param {'asc' | 'desc'} [orderId] 
         * @param {'asc' | 'desc'} [orderUpdatedAt] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRelevesAchatsReleveAchatCollection: async (xIdSociete?: number, page?: number, itemsPerPage?: number, cloture?: boolean, deleted?: boolean, createdAtBefore?: string, createdAtStrictlyBefore?: string, createdAtAfter?: string, createdAtStrictlyAfter?: string, updatedBy?: string, updatedBy2?: Array<string>, orderId?: 'asc' | 'desc', orderUpdatedAt?: 'asc' | 'desc', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/achats/releve_achats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (cloture !== undefined) {
                localVarQueryParameter['cloture'] = cloture;
            }

            if (deleted !== undefined) {
                localVarQueryParameter['deleted'] = deleted;
            }

            if (createdAtBefore !== undefined) {
                localVarQueryParameter['createdAt[before]'] = createdAtBefore;
            }

            if (createdAtStrictlyBefore !== undefined) {
                localVarQueryParameter['createdAt[strictly_before]'] = createdAtStrictlyBefore;
            }

            if (createdAtAfter !== undefined) {
                localVarQueryParameter['createdAt[after]'] = createdAtAfter;
            }

            if (createdAtStrictlyAfter !== undefined) {
                localVarQueryParameter['createdAt[strictly_after]'] = createdAtStrictlyAfter;
            }

            if (updatedBy !== undefined) {
                localVarQueryParameter['updatedBy'] = updatedBy;
            }

            if (updatedBy2) {
                localVarQueryParameter['updatedBy[]'] = updatedBy2;
            }

            if (orderId !== undefined) {
                localVarQueryParameter['order[id]'] = orderId;
            }

            if (orderUpdatedAt !== undefined) {
                localVarQueryParameter['order[updatedAt]'] = orderUpdatedAt;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Ajouter une information à un relevé d\'achat
         * @summary Ajouter une information à un relevé d\'achat
         * @param {string} id Resource identifier
         * @param {ReleveAchatReleveAchatWrite} releveAchatReleveAchatWrite The updated ReleveAchat resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchReleveAchatReleveAchatItem: async (id: string, releveAchatReleveAchatWrite: ReleveAchatReleveAchatWrite, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchReleveAchatReleveAchatItem', 'id', id)
            // verify required parameter 'releveAchatReleveAchatWrite' is not null or undefined
            assertParamExists('patchReleveAchatReleveAchatItem', 'releveAchatReleveAchatWrite', releveAchatReleveAchatWrite)
            const localVarPath = `/achats/releve_achats/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(releveAchatReleveAchatWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Mettre à jour un relevé d\'achat
         * @summary Mettre à jour un relevé d\'achat
         * @param {string} id Resource identifier
         * @param {ReleveAchatJsonldReleveAchatWrite} releveAchatJsonldReleveAchatWrite The updated ReleveAchat resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReleveAchatReleveAchatItem: async (id: string, releveAchatJsonldReleveAchatWrite: ReleveAchatJsonldReleveAchatWrite, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateReleveAchatReleveAchatItem', 'id', id)
            // verify required parameter 'releveAchatJsonldReleveAchatWrite' is not null or undefined
            assertParamExists('updateReleveAchatReleveAchatItem', 'releveAchatJsonldReleveAchatWrite', releveAchatJsonldReleveAchatWrite)
            const localVarPath = `/achats/releve_achats/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(releveAchatJsonldReleveAchatWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReleveAchatApi - functional programming interface
 * @export
 */
export const ReleveAchatApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReleveAchatApiAxiosParamCreator(configuration)
    return {
        /**
         * Créer un relevé d\'achat
         * @summary Créer un relevé d\'achat
         * @param {ReleveAchatJsonldReleveAchatCreateReleveAchatWrite} releveAchatJsonldReleveAchatCreateReleveAchatWrite The new ReleveAchat resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createReleveAchatReleveAchatCollection(releveAchatJsonldReleveAchatCreateReleveAchatWrite: ReleveAchatJsonldReleveAchatCreateReleveAchatWrite, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReleveAchatJsonldReleveAchatReadTimestampableBlameambleGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createReleveAchatReleveAchatCollection(releveAchatJsonldReleveAchatCreateReleveAchatWrite, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Récupérer les informations d\'un relevé d\'achat (minimum d\'infos par facture : idFacture, date facturation)
         * @summary Récupérer les informations d\'un relevé d\'achat
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReleveAchatReleveAchatItem(id: string, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReleveAchatJsonldReleveAchatReadReleveAchatReadItemTimestampableBlameambleGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReleveAchatReleveAchatItem(id, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sommer les totaux des factures d\'achats du relevé
         * @summary Sommer les totaux des factures d\'achats du relevé
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReleveAchatSumReleveAchatItem(id: string, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetReleveAchatSumReleveAchatItem200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReleveAchatSumReleveAchatItem(id, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Récupérer la liste des relevés d\'achats
         * @summary Récupérer la liste des relevés d\'achats
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {boolean} [cloture] 
         * @param {boolean} [deleted] 
         * @param {string} [createdAtBefore] 
         * @param {string} [createdAtStrictlyBefore] 
         * @param {string} [createdAtAfter] 
         * @param {string} [createdAtStrictlyAfter] 
         * @param {string} [updatedBy] 
         * @param {Array<string>} [updatedBy2] 
         * @param {'asc' | 'desc'} [orderId] 
         * @param {'asc' | 'desc'} [orderUpdatedAt] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRelevesAchatsReleveAchatCollection(xIdSociete?: number, page?: number, itemsPerPage?: number, cloture?: boolean, deleted?: boolean, createdAtBefore?: string, createdAtStrictlyBefore?: string, createdAtAfter?: string, createdAtStrictlyAfter?: string, updatedBy?: string, updatedBy2?: Array<string>, orderId?: 'asc' | 'desc', orderUpdatedAt?: 'asc' | 'desc', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRelevesAchatsReleveAchatCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRelevesAchatsReleveAchatCollection(xIdSociete, page, itemsPerPage, cloture, deleted, createdAtBefore, createdAtStrictlyBefore, createdAtAfter, createdAtStrictlyAfter, updatedBy, updatedBy2, orderId, orderUpdatedAt, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Ajouter une information à un relevé d\'achat
         * @summary Ajouter une information à un relevé d\'achat
         * @param {string} id Resource identifier
         * @param {ReleveAchatReleveAchatWrite} releveAchatReleveAchatWrite The updated ReleveAchat resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchReleveAchatReleveAchatItem(id: string, releveAchatReleveAchatWrite: ReleveAchatReleveAchatWrite, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReleveAchatJsonldReleveAchatReadTimestampableBlameambleGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchReleveAchatReleveAchatItem(id, releveAchatReleveAchatWrite, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Mettre à jour un relevé d\'achat
         * @summary Mettre à jour un relevé d\'achat
         * @param {string} id Resource identifier
         * @param {ReleveAchatJsonldReleveAchatWrite} releveAchatJsonldReleveAchatWrite The updated ReleveAchat resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateReleveAchatReleveAchatItem(id: string, releveAchatJsonldReleveAchatWrite: ReleveAchatJsonldReleveAchatWrite, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReleveAchatJsonldReleveAchatReadTimestampableBlameambleGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateReleveAchatReleveAchatItem(id, releveAchatJsonldReleveAchatWrite, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReleveAchatApi - factory interface
 * @export
 */
export const ReleveAchatApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReleveAchatApiFp(configuration)
    return {
        /**
         * Créer un relevé d\'achat
         * @summary Créer un relevé d\'achat
         * @param {ReleveAchatApiCreateReleveAchatReleveAchatCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReleveAchatReleveAchatCollection(requestParameters: ReleveAchatApiCreateReleveAchatReleveAchatCollectionRequest, options?: AxiosRequestConfig): AxiosPromise<ReleveAchatJsonldReleveAchatReadTimestampableBlameambleGroup> {
            return localVarFp.createReleveAchatReleveAchatCollection(requestParameters.releveAchatJsonldReleveAchatCreateReleveAchatWrite, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'un relevé d\'achat (minimum d\'infos par facture : idFacture, date facturation)
         * @summary Récupérer les informations d\'un relevé d\'achat
         * @param {ReleveAchatApiGetReleveAchatReleveAchatItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReleveAchatReleveAchatItem(requestParameters: ReleveAchatApiGetReleveAchatReleveAchatItemRequest, options?: AxiosRequestConfig): AxiosPromise<ReleveAchatJsonldReleveAchatReadReleveAchatReadItemTimestampableBlameambleGroup> {
            return localVarFp.getReleveAchatReleveAchatItem(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Sommer les totaux des factures d\'achats du relevé
         * @summary Sommer les totaux des factures d\'achats du relevé
         * @param {ReleveAchatApiGetReleveAchatSumReleveAchatItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReleveAchatSumReleveAchatItem(requestParameters: ReleveAchatApiGetReleveAchatSumReleveAchatItemRequest, options?: AxiosRequestConfig): AxiosPromise<GetReleveAchatSumReleveAchatItem200Response> {
            return localVarFp.getReleveAchatSumReleveAchatItem(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer la liste des relevés d\'achats
         * @summary Récupérer la liste des relevés d\'achats
         * @param {ReleveAchatApiGetRelevesAchatsReleveAchatCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRelevesAchatsReleveAchatCollection(requestParameters: ReleveAchatApiGetRelevesAchatsReleveAchatCollectionRequest = {}, options?: AxiosRequestConfig): AxiosPromise<GetRelevesAchatsReleveAchatCollection200Response> {
            return localVarFp.getRelevesAchatsReleveAchatCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, requestParameters.cloture, requestParameters.deleted, requestParameters.createdAtBefore, requestParameters.createdAtStrictlyBefore, requestParameters.createdAtAfter, requestParameters.createdAtStrictlyAfter, requestParameters.updatedBy, requestParameters.updatedBy2, requestParameters.orderId, requestParameters.orderUpdatedAt, options).then((request) => request(axios, basePath));
        },
        /**
         * Ajouter une information à un relevé d\'achat
         * @summary Ajouter une information à un relevé d\'achat
         * @param {ReleveAchatApiPatchReleveAchatReleveAchatItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchReleveAchatReleveAchatItem(requestParameters: ReleveAchatApiPatchReleveAchatReleveAchatItemRequest, options?: AxiosRequestConfig): AxiosPromise<ReleveAchatJsonldReleveAchatReadTimestampableBlameambleGroup> {
            return localVarFp.patchReleveAchatReleveAchatItem(requestParameters.id, requestParameters.releveAchatReleveAchatWrite, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Mettre à jour un relevé d\'achat
         * @summary Mettre à jour un relevé d\'achat
         * @param {ReleveAchatApiUpdateReleveAchatReleveAchatItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReleveAchatReleveAchatItem(requestParameters: ReleveAchatApiUpdateReleveAchatReleveAchatItemRequest, options?: AxiosRequestConfig): AxiosPromise<ReleveAchatJsonldReleveAchatReadTimestampableBlameambleGroup> {
            return localVarFp.updateReleveAchatReleveAchatItem(requestParameters.id, requestParameters.releveAchatJsonldReleveAchatWrite, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createReleveAchatReleveAchatCollection operation in ReleveAchatApi.
 * @export
 * @interface ReleveAchatApiCreateReleveAchatReleveAchatCollectionRequest
 */
export interface ReleveAchatApiCreateReleveAchatReleveAchatCollectionRequest {
    /**
     * The new ReleveAchat resource
     * @type {ReleveAchatJsonldReleveAchatCreateReleveAchatWrite}
     * @memberof ReleveAchatApiCreateReleveAchatReleveAchatCollection
     */
    readonly releveAchatJsonldReleveAchatCreateReleveAchatWrite: ReleveAchatJsonldReleveAchatCreateReleveAchatWrite

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof ReleveAchatApiCreateReleveAchatReleveAchatCollection
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for getReleveAchatReleveAchatItem operation in ReleveAchatApi.
 * @export
 * @interface ReleveAchatApiGetReleveAchatReleveAchatItemRequest
 */
export interface ReleveAchatApiGetReleveAchatReleveAchatItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof ReleveAchatApiGetReleveAchatReleveAchatItem
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof ReleveAchatApiGetReleveAchatReleveAchatItem
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for getReleveAchatSumReleveAchatItem operation in ReleveAchatApi.
 * @export
 * @interface ReleveAchatApiGetReleveAchatSumReleveAchatItemRequest
 */
export interface ReleveAchatApiGetReleveAchatSumReleveAchatItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof ReleveAchatApiGetReleveAchatSumReleveAchatItem
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof ReleveAchatApiGetReleveAchatSumReleveAchatItem
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for getRelevesAchatsReleveAchatCollection operation in ReleveAchatApi.
 * @export
 * @interface ReleveAchatApiGetRelevesAchatsReleveAchatCollectionRequest
 */
export interface ReleveAchatApiGetRelevesAchatsReleveAchatCollectionRequest {
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof ReleveAchatApiGetRelevesAchatsReleveAchatCollection
     */
    readonly xIdSociete?: number

    /**
     * The collection page number
     * @type {number}
     * @memberof ReleveAchatApiGetRelevesAchatsReleveAchatCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof ReleveAchatApiGetRelevesAchatsReleveAchatCollection
     */
    readonly itemsPerPage?: number

    /**
     * 
     * @type {boolean}
     * @memberof ReleveAchatApiGetRelevesAchatsReleveAchatCollection
     */
    readonly cloture?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ReleveAchatApiGetRelevesAchatsReleveAchatCollection
     */
    readonly deleted?: boolean

    /**
     * 
     * @type {string}
     * @memberof ReleveAchatApiGetRelevesAchatsReleveAchatCollection
     */
    readonly createdAtBefore?: string

    /**
     * 
     * @type {string}
     * @memberof ReleveAchatApiGetRelevesAchatsReleveAchatCollection
     */
    readonly createdAtStrictlyBefore?: string

    /**
     * 
     * @type {string}
     * @memberof ReleveAchatApiGetRelevesAchatsReleveAchatCollection
     */
    readonly createdAtAfter?: string

    /**
     * 
     * @type {string}
     * @memberof ReleveAchatApiGetRelevesAchatsReleveAchatCollection
     */
    readonly createdAtStrictlyAfter?: string

    /**
     * 
     * @type {string}
     * @memberof ReleveAchatApiGetRelevesAchatsReleveAchatCollection
     */
    readonly updatedBy?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof ReleveAchatApiGetRelevesAchatsReleveAchatCollection
     */
    readonly updatedBy2?: Array<string>

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof ReleveAchatApiGetRelevesAchatsReleveAchatCollection
     */
    readonly orderId?: 'asc' | 'desc'

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof ReleveAchatApiGetRelevesAchatsReleveAchatCollection
     */
    readonly orderUpdatedAt?: 'asc' | 'desc'
}

/**
 * Request parameters for patchReleveAchatReleveAchatItem operation in ReleveAchatApi.
 * @export
 * @interface ReleveAchatApiPatchReleveAchatReleveAchatItemRequest
 */
export interface ReleveAchatApiPatchReleveAchatReleveAchatItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof ReleveAchatApiPatchReleveAchatReleveAchatItem
     */
    readonly id: string

    /**
     * The updated ReleveAchat resource
     * @type {ReleveAchatReleveAchatWrite}
     * @memberof ReleveAchatApiPatchReleveAchatReleveAchatItem
     */
    readonly releveAchatReleveAchatWrite: ReleveAchatReleveAchatWrite

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof ReleveAchatApiPatchReleveAchatReleveAchatItem
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for updateReleveAchatReleveAchatItem operation in ReleveAchatApi.
 * @export
 * @interface ReleveAchatApiUpdateReleveAchatReleveAchatItemRequest
 */
export interface ReleveAchatApiUpdateReleveAchatReleveAchatItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof ReleveAchatApiUpdateReleveAchatReleveAchatItem
     */
    readonly id: string

    /**
     * The updated ReleveAchat resource
     * @type {ReleveAchatJsonldReleveAchatWrite}
     * @memberof ReleveAchatApiUpdateReleveAchatReleveAchatItem
     */
    readonly releveAchatJsonldReleveAchatWrite: ReleveAchatJsonldReleveAchatWrite

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof ReleveAchatApiUpdateReleveAchatReleveAchatItem
     */
    readonly xIdSociete?: number
}

/**
 * ReleveAchatApi - object-oriented interface
 * @export
 * @class ReleveAchatApi
 * @extends {BaseAPI}
 */
export class ReleveAchatApi extends BaseAPI {
    /**
     * Créer un relevé d\'achat
     * @summary Créer un relevé d\'achat
     * @param {ReleveAchatApiCreateReleveAchatReleveAchatCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReleveAchatApi
     */
    public createReleveAchatReleveAchatCollection(requestParameters: ReleveAchatApiCreateReleveAchatReleveAchatCollectionRequest, options?: AxiosRequestConfig) {
        return ReleveAchatApiFp(this.configuration).createReleveAchatReleveAchatCollection(requestParameters.releveAchatJsonldReleveAchatCreateReleveAchatWrite, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'un relevé d\'achat (minimum d\'infos par facture : idFacture, date facturation)
     * @summary Récupérer les informations d\'un relevé d\'achat
     * @param {ReleveAchatApiGetReleveAchatReleveAchatItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReleveAchatApi
     */
    public getReleveAchatReleveAchatItem(requestParameters: ReleveAchatApiGetReleveAchatReleveAchatItemRequest, options?: AxiosRequestConfig) {
        return ReleveAchatApiFp(this.configuration).getReleveAchatReleveAchatItem(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sommer les totaux des factures d\'achats du relevé
     * @summary Sommer les totaux des factures d\'achats du relevé
     * @param {ReleveAchatApiGetReleveAchatSumReleveAchatItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReleveAchatApi
     */
    public getReleveAchatSumReleveAchatItem(requestParameters: ReleveAchatApiGetReleveAchatSumReleveAchatItemRequest, options?: AxiosRequestConfig) {
        return ReleveAchatApiFp(this.configuration).getReleveAchatSumReleveAchatItem(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer la liste des relevés d\'achats
     * @summary Récupérer la liste des relevés d\'achats
     * @param {ReleveAchatApiGetRelevesAchatsReleveAchatCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReleveAchatApi
     */
    public getRelevesAchatsReleveAchatCollection(requestParameters: ReleveAchatApiGetRelevesAchatsReleveAchatCollectionRequest = {}, options?: AxiosRequestConfig) {
        return ReleveAchatApiFp(this.configuration).getRelevesAchatsReleveAchatCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, requestParameters.cloture, requestParameters.deleted, requestParameters.createdAtBefore, requestParameters.createdAtStrictlyBefore, requestParameters.createdAtAfter, requestParameters.createdAtStrictlyAfter, requestParameters.updatedBy, requestParameters.updatedBy2, requestParameters.orderId, requestParameters.orderUpdatedAt, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Ajouter une information à un relevé d\'achat
     * @summary Ajouter une information à un relevé d\'achat
     * @param {ReleveAchatApiPatchReleveAchatReleveAchatItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReleveAchatApi
     */
    public patchReleveAchatReleveAchatItem(requestParameters: ReleveAchatApiPatchReleveAchatReleveAchatItemRequest, options?: AxiosRequestConfig) {
        return ReleveAchatApiFp(this.configuration).patchReleveAchatReleveAchatItem(requestParameters.id, requestParameters.releveAchatReleveAchatWrite, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Mettre à jour un relevé d\'achat
     * @summary Mettre à jour un relevé d\'achat
     * @param {ReleveAchatApiUpdateReleveAchatReleveAchatItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReleveAchatApi
     */
    public updateReleveAchatReleveAchatItem(requestParameters: ReleveAchatApiUpdateReleveAchatReleveAchatItemRequest, options?: AxiosRequestConfig) {
        return ReleveAchatApiFp(this.configuration).updateReleveAchatReleveAchatItem(requestParameters.id, requestParameters.releveAchatJsonldReleveAchatWrite, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WorkflowMailApi - axios parameter creator
 * @export
 */
export const WorkflowMailApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Removes the WorkflowMail resource.
         * @summary Removes the WorkflowMail resource.
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkflowMailItem: async (id: string, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteWorkflowMailItem', 'id', id)
            const localVarPath = `/achats/workflow_mails/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a WorkflowMail resource.
         * @summary Retrieves a WorkflowMail resource.
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflowMailItem: async (id: string, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getWorkflowMailItem', 'id', id)
            const localVarPath = `/achats/workflow_mails/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lister les emails envoyés
         * @summary Lister les emails envoyés
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflowMailsWorkflowMailCollection: async (xIdSociete?: number, page?: number, itemsPerPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/achats/workflow_mails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Liste les données par défaut par type de mail
         * @summary Liste les données par défaut par type de mail
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {'InvalidDocument' | 'MissingInformation' | 'NoncompliantCompany' | 'NotFoundSupplier' | 'UnreadableDocument'} [typeMail] 
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTypeMailWorkflowMailCollection: async (xIdSociete?: number, typeMail?: 'InvalidDocument' | 'MissingInformation' | 'NoncompliantCompany' | 'NotFoundSupplier' | 'UnreadableDocument', page?: number, itemsPerPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/achats/mail-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (typeMail !== undefined) {
                localVarQueryParameter['typeMail'] = typeMail;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the WorkflowMail resource.
         * @summary Updates the WorkflowMail resource.
         * @param {string} id Resource identifier
         * @param {WorkflowMailWorkflowMailSend} workflowMailWorkflowMailSend The updated WorkflowMail resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchWorkflowMailItem: async (id: string, workflowMailWorkflowMailSend: WorkflowMailWorkflowMailSend, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchWorkflowMailItem', 'id', id)
            // verify required parameter 'workflowMailWorkflowMailSend' is not null or undefined
            assertParamExists('patchWorkflowMailItem', 'workflowMailWorkflowMailSend', workflowMailWorkflowMailSend)
            const localVarPath = `/achats/workflow_mails/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workflowMailWorkflowMailSend, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces the WorkflowMail resource.
         * @summary Replaces the WorkflowMail resource.
         * @param {string} id Resource identifier
         * @param {WorkflowMailJsonldWorkflowMailSend} workflowMailJsonldWorkflowMailSend The updated WorkflowMail resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putWorkflowMailItem: async (id: string, workflowMailJsonldWorkflowMailSend: WorkflowMailJsonldWorkflowMailSend, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putWorkflowMailItem', 'id', id)
            // verify required parameter 'workflowMailJsonldWorkflowMailSend' is not null or undefined
            assertParamExists('putWorkflowMailItem', 'workflowMailJsonldWorkflowMailSend', workflowMailJsonldWorkflowMailSend)
            const localVarPath = `/achats/workflow_mails/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workflowMailJsonldWorkflowMailSend, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Envoyer un email
         * @summary Envoyer un email
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {WorkflowMailWorkflowMailSend} [details] 
         * @param {Array<File>} [attachments] Liste des pièces jointes, il faut les nommer attachments0, attachments1, etc.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendWorkflowMailWorkflowMailCollection: async (xIdSociete?: number, details?: WorkflowMailWorkflowMailSend, attachments?: Array<File>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/achats/send-workflow-mail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


            if (details !== undefined) { 
                localVarFormParams.append('details', new Blob([JSON.stringify(details)], { type: "application/json", }));
            }
                if (attachments) {
                attachments.forEach((element) => {
                    localVarFormParams.append('attachments', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkflowMailApi - functional programming interface
 * @export
 */
export const WorkflowMailApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WorkflowMailApiAxiosParamCreator(configuration)
    return {
        /**
         * Removes the WorkflowMail resource.
         * @summary Removes the WorkflowMail resource.
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteWorkflowMailItem(id: string, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteWorkflowMailItem(id, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a WorkflowMail resource.
         * @summary Retrieves a WorkflowMail resource.
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkflowMailItem(id: string, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkflowMailJsonldWorkflowMailRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkflowMailItem(id, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Lister les emails envoyés
         * @summary Lister les emails envoyés
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkflowMailsWorkflowMailCollection(xIdSociete?: number, page?: number, itemsPerPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetWorkflowMailsWorkflowMailCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkflowMailsWorkflowMailCollection(xIdSociete, page, itemsPerPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Liste les données par défaut par type de mail
         * @summary Liste les données par défaut par type de mail
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {'InvalidDocument' | 'MissingInformation' | 'NoncompliantCompany' | 'NotFoundSupplier' | 'UnreadableDocument'} [typeMail] 
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listTypeMailWorkflowMailCollection(xIdSociete?: number, typeMail?: 'InvalidDocument' | 'MissingInformation' | 'NoncompliantCompany' | 'NotFoundSupplier' | 'UnreadableDocument', page?: number, itemsPerPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListTypeMailWorkflowMailCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listTypeMailWorkflowMailCollection(xIdSociete, typeMail, page, itemsPerPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the WorkflowMail resource.
         * @summary Updates the WorkflowMail resource.
         * @param {string} id Resource identifier
         * @param {WorkflowMailWorkflowMailSend} workflowMailWorkflowMailSend The updated WorkflowMail resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchWorkflowMailItem(id: string, workflowMailWorkflowMailSend: WorkflowMailWorkflowMailSend, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkflowMailJsonldWorkflowMailRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchWorkflowMailItem(id, workflowMailWorkflowMailSend, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces the WorkflowMail resource.
         * @summary Replaces the WorkflowMail resource.
         * @param {string} id Resource identifier
         * @param {WorkflowMailJsonldWorkflowMailSend} workflowMailJsonldWorkflowMailSend The updated WorkflowMail resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putWorkflowMailItem(id: string, workflowMailJsonldWorkflowMailSend: WorkflowMailJsonldWorkflowMailSend, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkflowMailJsonldWorkflowMailRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putWorkflowMailItem(id, workflowMailJsonldWorkflowMailSend, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Envoyer un email
         * @summary Envoyer un email
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {WorkflowMailWorkflowMailSend} [details] 
         * @param {Array<File>} [attachments] Liste des pièces jointes, il faut les nommer attachments0, attachments1, etc.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendWorkflowMailWorkflowMailCollection(xIdSociete?: number, details?: WorkflowMailWorkflowMailSend, attachments?: Array<File>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkflowMailJsonldWorkflowMailRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendWorkflowMailWorkflowMailCollection(xIdSociete, details, attachments, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WorkflowMailApi - factory interface
 * @export
 */
export const WorkflowMailApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WorkflowMailApiFp(configuration)
    return {
        /**
         * Removes the WorkflowMail resource.
         * @summary Removes the WorkflowMail resource.
         * @param {WorkflowMailApiDeleteWorkflowMailItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkflowMailItem(requestParameters: WorkflowMailApiDeleteWorkflowMailItemRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteWorkflowMailItem(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a WorkflowMail resource.
         * @summary Retrieves a WorkflowMail resource.
         * @param {WorkflowMailApiGetWorkflowMailItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflowMailItem(requestParameters: WorkflowMailApiGetWorkflowMailItemRequest, options?: AxiosRequestConfig): AxiosPromise<WorkflowMailJsonldWorkflowMailRead> {
            return localVarFp.getWorkflowMailItem(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Lister les emails envoyés
         * @summary Lister les emails envoyés
         * @param {WorkflowMailApiGetWorkflowMailsWorkflowMailCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflowMailsWorkflowMailCollection(requestParameters: WorkflowMailApiGetWorkflowMailsWorkflowMailCollectionRequest = {}, options?: AxiosRequestConfig): AxiosPromise<GetWorkflowMailsWorkflowMailCollection200Response> {
            return localVarFp.getWorkflowMailsWorkflowMailCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Liste les données par défaut par type de mail
         * @summary Liste les données par défaut par type de mail
         * @param {WorkflowMailApiListTypeMailWorkflowMailCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTypeMailWorkflowMailCollection(requestParameters: WorkflowMailApiListTypeMailWorkflowMailCollectionRequest = {}, options?: AxiosRequestConfig): AxiosPromise<ListTypeMailWorkflowMailCollection200Response> {
            return localVarFp.listTypeMailWorkflowMailCollection(requestParameters.xIdSociete, requestParameters.typeMail, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the WorkflowMail resource.
         * @summary Updates the WorkflowMail resource.
         * @param {WorkflowMailApiPatchWorkflowMailItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchWorkflowMailItem(requestParameters: WorkflowMailApiPatchWorkflowMailItemRequest, options?: AxiosRequestConfig): AxiosPromise<WorkflowMailJsonldWorkflowMailRead> {
            return localVarFp.patchWorkflowMailItem(requestParameters.id, requestParameters.workflowMailWorkflowMailSend, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces the WorkflowMail resource.
         * @summary Replaces the WorkflowMail resource.
         * @param {WorkflowMailApiPutWorkflowMailItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putWorkflowMailItem(requestParameters: WorkflowMailApiPutWorkflowMailItemRequest, options?: AxiosRequestConfig): AxiosPromise<WorkflowMailJsonldWorkflowMailRead> {
            return localVarFp.putWorkflowMailItem(requestParameters.id, requestParameters.workflowMailJsonldWorkflowMailSend, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Envoyer un email
         * @summary Envoyer un email
         * @param {WorkflowMailApiSendWorkflowMailWorkflowMailCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendWorkflowMailWorkflowMailCollection(requestParameters: WorkflowMailApiSendWorkflowMailWorkflowMailCollectionRequest = {}, options?: AxiosRequestConfig): AxiosPromise<WorkflowMailJsonldWorkflowMailRead> {
            return localVarFp.sendWorkflowMailWorkflowMailCollection(requestParameters.xIdSociete, requestParameters.details, requestParameters.attachments, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for deleteWorkflowMailItem operation in WorkflowMailApi.
 * @export
 * @interface WorkflowMailApiDeleteWorkflowMailItemRequest
 */
export interface WorkflowMailApiDeleteWorkflowMailItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof WorkflowMailApiDeleteWorkflowMailItem
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof WorkflowMailApiDeleteWorkflowMailItem
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for getWorkflowMailItem operation in WorkflowMailApi.
 * @export
 * @interface WorkflowMailApiGetWorkflowMailItemRequest
 */
export interface WorkflowMailApiGetWorkflowMailItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof WorkflowMailApiGetWorkflowMailItem
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof WorkflowMailApiGetWorkflowMailItem
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for getWorkflowMailsWorkflowMailCollection operation in WorkflowMailApi.
 * @export
 * @interface WorkflowMailApiGetWorkflowMailsWorkflowMailCollectionRequest
 */
export interface WorkflowMailApiGetWorkflowMailsWorkflowMailCollectionRequest {
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof WorkflowMailApiGetWorkflowMailsWorkflowMailCollection
     */
    readonly xIdSociete?: number

    /**
     * The collection page number
     * @type {number}
     * @memberof WorkflowMailApiGetWorkflowMailsWorkflowMailCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof WorkflowMailApiGetWorkflowMailsWorkflowMailCollection
     */
    readonly itemsPerPage?: number
}

/**
 * Request parameters for listTypeMailWorkflowMailCollection operation in WorkflowMailApi.
 * @export
 * @interface WorkflowMailApiListTypeMailWorkflowMailCollectionRequest
 */
export interface WorkflowMailApiListTypeMailWorkflowMailCollectionRequest {
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof WorkflowMailApiListTypeMailWorkflowMailCollection
     */
    readonly xIdSociete?: number

    /**
     * 
     * @type {'InvalidDocument' | 'MissingInformation' | 'NoncompliantCompany' | 'NotFoundSupplier' | 'UnreadableDocument'}
     * @memberof WorkflowMailApiListTypeMailWorkflowMailCollection
     */
    readonly typeMail?: 'InvalidDocument' | 'MissingInformation' | 'NoncompliantCompany' | 'NotFoundSupplier' | 'UnreadableDocument'

    /**
     * The collection page number
     * @type {number}
     * @memberof WorkflowMailApiListTypeMailWorkflowMailCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof WorkflowMailApiListTypeMailWorkflowMailCollection
     */
    readonly itemsPerPage?: number
}

/**
 * Request parameters for patchWorkflowMailItem operation in WorkflowMailApi.
 * @export
 * @interface WorkflowMailApiPatchWorkflowMailItemRequest
 */
export interface WorkflowMailApiPatchWorkflowMailItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof WorkflowMailApiPatchWorkflowMailItem
     */
    readonly id: string

    /**
     * The updated WorkflowMail resource
     * @type {WorkflowMailWorkflowMailSend}
     * @memberof WorkflowMailApiPatchWorkflowMailItem
     */
    readonly workflowMailWorkflowMailSend: WorkflowMailWorkflowMailSend

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof WorkflowMailApiPatchWorkflowMailItem
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for putWorkflowMailItem operation in WorkflowMailApi.
 * @export
 * @interface WorkflowMailApiPutWorkflowMailItemRequest
 */
export interface WorkflowMailApiPutWorkflowMailItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof WorkflowMailApiPutWorkflowMailItem
     */
    readonly id: string

    /**
     * The updated WorkflowMail resource
     * @type {WorkflowMailJsonldWorkflowMailSend}
     * @memberof WorkflowMailApiPutWorkflowMailItem
     */
    readonly workflowMailJsonldWorkflowMailSend: WorkflowMailJsonldWorkflowMailSend

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof WorkflowMailApiPutWorkflowMailItem
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for sendWorkflowMailWorkflowMailCollection operation in WorkflowMailApi.
 * @export
 * @interface WorkflowMailApiSendWorkflowMailWorkflowMailCollectionRequest
 */
export interface WorkflowMailApiSendWorkflowMailWorkflowMailCollectionRequest {
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof WorkflowMailApiSendWorkflowMailWorkflowMailCollection
     */
    readonly xIdSociete?: number

    /**
     * 
     * @type {WorkflowMailWorkflowMailSend}
     * @memberof WorkflowMailApiSendWorkflowMailWorkflowMailCollection
     */
    readonly details?: WorkflowMailWorkflowMailSend

    /**
     * Liste des pièces jointes, il faut les nommer attachments0, attachments1, etc.
     * @type {Array<File>}
     * @memberof WorkflowMailApiSendWorkflowMailWorkflowMailCollection
     */
    readonly attachments?: Array<File>
}

/**
 * WorkflowMailApi - object-oriented interface
 * @export
 * @class WorkflowMailApi
 * @extends {BaseAPI}
 */
export class WorkflowMailApi extends BaseAPI {
    /**
     * Removes the WorkflowMail resource.
     * @summary Removes the WorkflowMail resource.
     * @param {WorkflowMailApiDeleteWorkflowMailItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowMailApi
     */
    public deleteWorkflowMailItem(requestParameters: WorkflowMailApiDeleteWorkflowMailItemRequest, options?: AxiosRequestConfig) {
        return WorkflowMailApiFp(this.configuration).deleteWorkflowMailItem(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a WorkflowMail resource.
     * @summary Retrieves a WorkflowMail resource.
     * @param {WorkflowMailApiGetWorkflowMailItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowMailApi
     */
    public getWorkflowMailItem(requestParameters: WorkflowMailApiGetWorkflowMailItemRequest, options?: AxiosRequestConfig) {
        return WorkflowMailApiFp(this.configuration).getWorkflowMailItem(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lister les emails envoyés
     * @summary Lister les emails envoyés
     * @param {WorkflowMailApiGetWorkflowMailsWorkflowMailCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowMailApi
     */
    public getWorkflowMailsWorkflowMailCollection(requestParameters: WorkflowMailApiGetWorkflowMailsWorkflowMailCollectionRequest = {}, options?: AxiosRequestConfig) {
        return WorkflowMailApiFp(this.configuration).getWorkflowMailsWorkflowMailCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Liste les données par défaut par type de mail
     * @summary Liste les données par défaut par type de mail
     * @param {WorkflowMailApiListTypeMailWorkflowMailCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowMailApi
     */
    public listTypeMailWorkflowMailCollection(requestParameters: WorkflowMailApiListTypeMailWorkflowMailCollectionRequest = {}, options?: AxiosRequestConfig) {
        return WorkflowMailApiFp(this.configuration).listTypeMailWorkflowMailCollection(requestParameters.xIdSociete, requestParameters.typeMail, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the WorkflowMail resource.
     * @summary Updates the WorkflowMail resource.
     * @param {WorkflowMailApiPatchWorkflowMailItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowMailApi
     */
    public patchWorkflowMailItem(requestParameters: WorkflowMailApiPatchWorkflowMailItemRequest, options?: AxiosRequestConfig) {
        return WorkflowMailApiFp(this.configuration).patchWorkflowMailItem(requestParameters.id, requestParameters.workflowMailWorkflowMailSend, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces the WorkflowMail resource.
     * @summary Replaces the WorkflowMail resource.
     * @param {WorkflowMailApiPutWorkflowMailItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowMailApi
     */
    public putWorkflowMailItem(requestParameters: WorkflowMailApiPutWorkflowMailItemRequest, options?: AxiosRequestConfig) {
        return WorkflowMailApiFp(this.configuration).putWorkflowMailItem(requestParameters.id, requestParameters.workflowMailJsonldWorkflowMailSend, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Envoyer un email
     * @summary Envoyer un email
     * @param {WorkflowMailApiSendWorkflowMailWorkflowMailCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowMailApi
     */
    public sendWorkflowMailWorkflowMailCollection(requestParameters: WorkflowMailApiSendWorkflowMailWorkflowMailCollectionRequest = {}, options?: AxiosRequestConfig) {
        return WorkflowMailApiFp(this.configuration).sendWorkflowMailWorkflowMailCollection(requestParameters.xIdSociete, requestParameters.details, requestParameters.attachments, options).then((request) => request(this.axios, this.basePath));
    }
}


