import React, { useCallback } from 'react';

import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { Grid, Typography, Box, Button } from '@mui/material';

import { BorderedBox } from '@europrocurement/flexy-components';
import generateB2DPath from '../../utils/generateB2DPath';
import { ACTIONS } from '../../redux/FactureFormReducer';
import { AppDispatch } from '../../redux/RootStore';

export type FileUploadItemProps = {
    file: File;
    status: 'pending' | 'success' | 'error';
    mediaObjectId?: number;
};

type ImportToastProps = {
    fileUploading: FileUploadItemProps;
};

const ImportToast = function ({ fileUploading }: ImportToastProps) {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch<AppDispatch>();

    const navigateToMediaObject = useCallback(
        (mediaObjectId: number) => {
            if (!mediaObjectId) return;

            dispatch({
                type: ACTIONS.RESET,
            });

            const response = generateB2DPath('formmediaobject', { mediaObjectId });

            if (response.status === 'OK') {
                navigate(response.path);
            } else if (response.status === 'KO') {
                enqueueSnackbar(<Typography>{response.message}</Typography>, {
                    variant: 'warning',
                });
            }
        },
        [dispatch, enqueueSnackbar, navigate],
    );

    const getItemStatusColor = (status: 'pending' | 'success' | 'error') => {
        switch (status) {
            case 'pending':
                return 'warning.main';
            case 'error':
                return 'error.main';
            case 'success':
            default:
                return 'success.main';
        }
    };

    return (
        <Grid
            item
            key={`${fileUploading.mediaObjectId || fileUploading.file.name}-${
                fileUploading.status
            }`}
            xs={12}
        >
            <BorderedBox
                sx={{
                    display: 'flex',
                    width: '100%',
                    height: '100%',
                    padding: 0,
                    margin: 0,
                    borderColor: getItemStatusColor(fileUploading.status),
                }}
            >
                <Box
                    sx={{
                        paddingTop: '15px',
                    }}
                >
                    <Typography
                        variant="caption"
                        fontWeight="bold"
                    >
                        {fileUploading.file.name}
                    </Typography>
                    {fileUploading.status === 'success' &&
                    fileUploading.mediaObjectId !== undefined ? (
                        <Button
                            sx={{ marginLeft: '30px' }}
                            variant="contained"
                            onClick={() =>
                                navigateToMediaObject(fileUploading.mediaObjectId as number)
                            }
                        >
                            Traiter
                        </Button>
                    ) : null}
                </Box>
            </BorderedBox>
        </Grid>
    );
};

export default ImportToast;
