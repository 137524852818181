import { OptionValueJsonldOptionValueRead } from '@europrocurement/l2d-domain/openApi/ApiOffre';
import {
    Badge,
    Button,
    ListItem,
    ListItemIcon,
    ListItemText,
    Tooltip,
    Typography,
} from '@mui/material';
import { Box, useTheme } from '@mui/system';
import * as React from 'react';
import { hexToRgba, formatPrice } from '@europrocurement/l2d-utils';

import {
    deleteIcon,
    FaOptionIcon,
    leftIcon,
    NamedIconsType,
    updateIcon,
} from '@europrocurement/l2d-icons';
import { ModelItem } from '@b2d/pages/Achats/models/types';
import actionModel from '../../models/action';
import additionalFieldModel from '../../models/additionalField';
import articleModel from '../../models/article';
import documentModel from '../../models/document';

type OptionValueRowProps = {
    selectedId?: number;
    optionValue: OptionValueJsonldOptionValueRead;
    onRollback: (optionValue: OptionValueJsonldOptionValueRead) => void;
    onEdit: (optionValue: OptionValueJsonldOptionValueRead) => void;
    onDelete: (optionValue: OptionValueJsonldOptionValueRead) => void;
    rank: number;
};

const OptionValueRow: React.FC<OptionValueRowProps> = function (props) {
    const theme = useTheme();
    const { optionValue, onEdit, onDelete, onRollback, rank, selectedId } = props;
    const { libelle, description, tarif, actions, articles, champsComplementaires, documents } =
        optionValue;

    const optionValueRelatedModels = [actions, articles, champsComplementaires, documents];
    const [onHover, setHover] = React.useState<boolean>(false);

    const opacityFilter = onHover ? 0.05 : 0.01;

    const isSelected = selectedId === optionValue.id;
    const getBackground = () => {
        const hexColor = isSelected ? theme.palette.primary.main : '#000000';
        return hexToRgba(hexColor, isSelected ? 0.4 : opacityFilter);
    };

    const getModel = (apiModelType: string) => {
        if (apiModelType === 'Relation Champ Complementaires') {
            return additionalFieldModel;
        }
        if (apiModelType === documentModel.type) {
            return documentModel;
        }
        if (apiModelType === actionModel.type) {
            return actionModel;
        }
        if (['Relation Articles', articleModel.type].includes(apiModelType)) {
            return articleModel;
        }

        return undefined;
    };

    const getTooltipContent = (model: ModelItem<unknown>, items: Record<string, unknown>[]) => (
        <Box padding="10px">
            <Typography fontSize={16}>{model.type} :</Typography>
            {items.map((item) => {
                let subkey = '';
                if (model.key === 'additional_field') {
                    subkey = 'champComplementaire';
                }
                if (model.key === 'article') {
                    subkey = 'article';
                }
                if (model.key === 'document') {
                    subkey = 'typeDocument';
                }
                if (model.key === 'action') {
                    subkey = 'action';
                }

                if (!item[subkey]) {
                    return null;
                }

                return (
                    <Typography
                        fontSize={13}
                        padding={1}
                    >
                        - {model.getItemDefaultLabel(item[subkey])}
                    </Typography>
                );
            })}
        </Box>
    );

    const toolTipButton = (
        tooltipContent: string,
        clickAction: () => void,
        icon: NamedIconsType,
    ) => (
        <Tooltip title={tooltipContent}>
            <Button
                color="secondary"
                aria-label="ajout"
                size="small"
                sx={{
                    height: 'fit-content',
                }}
                onClick={clickAction}
            >
                <FaOptionIcon
                    {...icon.props}
                    size="lg"
                />
            </Button>
        </Tooltip>
    );

    return (
        <ListItem
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            style={{
                background: getBackground(),
                transition: 'background 0.3s',
                margin: '5px',
            }}
        >
            <ListItemIcon>{rank}</ListItemIcon>
            <ListItemText
                primary={libelle}
                secondary={`${description} - ${formatPrice(tarif)} €`}
            />
            {optionValueRelatedModels.map((relatedModels) => {
                const hasModel = relatedModels && relatedModels.length > 0;

                if (!relatedModels) {
                    return null;
                }

                if (hasModel) {
                    // Investigate typing
                    const modelType = (relatedModels[0] as { ['@type']: string })['@type'];
                    const model = getModel(modelType);
                    if (!model) {
                        return null;
                    }
                    const modelIcon = model.icon;
                    // Investigate typing
                    const tooltipContent = getTooltipContent(
                        model,
                        relatedModels as Record<string, unknown>[],
                    );

                    return (
                        <Box margin="0px 15px">
                            <Tooltip title={tooltipContent}>
                                <Badge
                                    badgeContent={relatedModels.length}
                                    color="primary"
                                >
                                    <FaOptionIcon
                                        {...modelIcon.props}
                                        size="lg"
                                    />
                                </Badge>
                            </Tooltip>
                        </Box>
                    );
                }
                return null;
            })}

            {isSelected
                ? toolTipButton('Annuler édition', () => onRollback(optionValue), leftIcon)
                : toolTipButton("Éditer l'élément", () => onEdit(optionValue), updateIcon)}

            <Tooltip title="Supprimer l'élément">
                <Button
                    color="secondary"
                    aria-label="ajout"
                    size="small"
                    sx={{
                        height: 'fit-content',
                    }}
                    onClick={() => onDelete(optionValue)}
                >
                    <FaOptionIcon
                        {...deleteIcon.props}
                        size="lg"
                    />
                </Button>
            </Tooltip>
        </ListItem>
    );
};

export default OptionValueRow;
