import {
    SliceFactory,
    FetchCollectionData,
    FetchItemDataType,
    ApiCollectionResponse,
    filtersToElasticQuery,
} from '@europrocurement/l2d-redux-utils';
import {
    ConfigurationParameters,
    Configuration,
    TiersApi,
    TiersTiersJsonldTiersRead,
} from '../../../openApi/ApiTiers';
import { TIERS_REDUCER_NAME } from '../constants';

// import keycloak from '../../../../keycloak';

// export { interface ConfigurationParameters} from '../../../openApi/ApiTiers';

export const additionalTiersFournisseursDataSources: Array<string> = [];

export type TiersFournisseurApi = TiersTiersJsonldTiersRead & Record<string, unknown>;

export type TiersFournisseur = TiersFournisseurApi;

export const TIERS_FOURNISSEUR_SLICE_NAME = 'tiersFournisseur';

const mapperTiersFournisseur = function (fournisseur: TiersFournisseurApi) {
    return {
        nomDentreprise: fournisseur.raisonSociale,
        ...fournisseur,
    } as TiersFournisseur;
};

export function createTiersFournisseurSlice(configuration: ConfigurationParameters) {
    const conf = new Configuration(configuration);
    const api = new TiersApi(conf);

    const fetchTiersFournisseursCollection: FetchCollectionData<TiersFournisseurApi> = ({
        search,
        pagination,
        filters,
        // orders,
        xIdSociete,
        // rootstate,
        // options,
    }) => {
        let aditionalMatch;

        if (search && search !== '') {
            aditionalMatch = [
                {
                    query_string: {
                        query: `**${search}**`,
                        fields: ['raisonSociale', 'siren', 'siret'],
                    },
                },
            ];
        }

        const mesdatas = api
            .getListTiersTiersCollection({
                xIdSociete,
                page: pagination.page + 1,
                itemsPerPage: pagination.itemsPerPage,
                search: filtersToElasticQuery(
                    {
                        'relationsSocietes.societe.id': xIdSociete,
                        ...filters,
                    },
                    aditionalMatch,
                ),
            })
            .then((res) => res.data) as Promise<ApiCollectionResponse<TiersFournisseurApi>>;

        return mesdatas;
    };

    const fetchTiersFournisseursItem: FetchItemDataType<TiersFournisseurApi> = ({
        idItem,
        // search,
        // pagination,
        // filters,
        // orders,
        xIdSociete,
        // rootstate,
        options,
    }) =>
        api
            .getTiersTiersItem(
                {
                    id: String(idItem),
                    xIdSociete,
                },
                options,
            )
            .then((res) => res.data) as Promise<TiersFournisseurApi>;

    const { slice: tiersFournisseurSlice, dataSourcesThunks: tiersFournisseurDataSourcesThunks } =
        SliceFactory.createSlice<TiersFournisseurApi, TiersFournisseur>(
            TIERS_FOURNISSEUR_SLICE_NAME,
            TIERS_REDUCER_NAME,
            additionalTiersFournisseursDataSources,
            fetchTiersFournisseursCollection,
            fetchTiersFournisseursItem,
            mapperTiersFournisseur,
            {
                isFournisseur: true,
                demo: false,
            },
        );

    return { tiersFournisseurSlice, tiersFournisseurDataSourcesThunks };
}
