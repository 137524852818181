import { useTheme } from '@mui/material';
import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';

export type B2DSymboleIconProps = {
    style?: React.CSSProperties;
    redirect?: string;
};

const B2DSymboleIcon: React.FunctionComponent<B2DSymboleIconProps> = function ({
    style,
    redirect,
}: B2DSymboleIconProps) {
    const theme = useTheme();

    const cls2Style = { fill: theme.palette.primary.main };

    const symboleIcon: ReactNode = (
        <svg
            role="img"
            aria-describedby="Logo reduit backToDigital"
            id="B2DSymboleIcon"
            style={style}
            width="52.389999"
            height="77.190002"
            viewBox="0 0 52.389999 77.19"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g
                id="LOGO"
                transform="translate(-351.05,-222.25)"
            >
                <g id="II">
                    <rect
                        style={cls2Style}
                        x="353.32999"
                        y="222.25"
                        width="47.849998"
                        height="10.44"
                        id="rect1"
                    />
                    <path
                        style={cls2Style}
                        d="m 377.24,299.44 c -15.63,0 -25.76,-4.52 -26.19,-4.71 l 4.52,-10.02 c 0.08,0.04 8.57,3.74 21.67,3.74 13.1,0 21.59,-3.69 21.67,-3.73 l 4.53,10.01 c -0.42,0.19 -10.58,4.71 -26.2,4.71"
                        id="path1"
                    />
                    <rect
                        style={cls2Style}
                        x="362.23001"
                        y="243.66"
                        width="10.44"
                        height="32.119999"
                        id="rect2"
                    />
                    <rect
                        style={cls2Style}
                        x="381.84"
                        y="243.66"
                        width="10.44"
                        height="32.119999"
                        id="rect3"
                    />
                </g>
            </g>
        </svg>
    );

    return redirect ? <Link to={redirect || '/'}>{symboleIcon}</Link> : symboleIcon;
};

export default B2DSymboleIcon;
