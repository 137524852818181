import * as React from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router';

import { UseKeycloakService } from '@europrocurement/l2d-keycloak';

import { ErrorPage } from '../ErrorPage';

export const Unauthorized = function () {
    const kc = UseKeycloakService();
    const navigate = useNavigate();

    React.useEffect(() => {
        if (kc.isAutenticated()) {
            navigate('/');
        }
    }, [kc, navigate]);

    return (
        <ErrorPage
            statusCode={401}
            message="Vous devez etre authentifié pour acceder a cette page"
        >
            <Button
                variant="contained"
                color="primary"
                onClick={() => kc.getKc().login()}
            >
                Login
            </Button>
        </ErrorPage>
    );
};
export default Unauthorized;
