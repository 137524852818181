import React, { useState } from 'react';
import { Formalite } from '@europrocurement/l2d-domain/reducers/formalites/slices/formalite';
import { Grid } from '@mui/material';
import { useDispatch } from 'react-redux';
import { selectFormality } from '@b2d/redux/subReducers/FormalityReducer';
import { todoIcon } from '@europrocurement/l2d-icons';
import { AnyAction } from '@reduxjs/toolkit';
import { FormaliteJsonldFormaliteUpdate } from '@europrocurement/l2d-domain/openApi/ApiFormalite';
import FormalityIncompatibilitiesCard from './FormalityIncompatibilitiesCard';
import CardTitle from '../../components/CardTitle';
import ActionButton from '../../components/ActionButton';
import IncompatibilitiesModal from './IncompatibilitiesModal';
import useFormalityFormHandler from '../../hooks/useFormalityFormHandler';

type FormalityIncompatbilitiesTabProps = {
    formality: Formalite;
};

const FormalityIncompatbilitiesTab: React.FunctionComponent<FormalityIncompatbilitiesTabProps> =
    function (props) {
        const { formality } = props;
        const dispatch = useDispatch();
        const [editModalOpen, setEditModalOpen] = useState(false);
        const { updateFormality } = useFormalityFormHandler();

        const onOpenIncompatibilitiesModal = () => {
            setEditModalOpen(true);
        };
        const onCloseIncompatibilitiesModal = () => {
            setEditModalOpen(false);
        };
        const onEditIncompatibilities = (selectedItems: (string | number)[]) => {
            updateFormality(formality, {
                incompatibilities: selectedItems,
            } as FormaliteJsonldFormaliteUpdate).then(() => {
                dispatch(selectFormality({ id: formality.id as number }) as unknown as AnyAction);
            });
        };
        const onRemoveIncompatibility = (incompatibilityId: string | number) => {
            const tmp = formality.incompatibilities ? formality.incompatibilities : [];
            const incompatibilities = tmp.filter(
                (item: string | number) => item !== incompatibilityId,
            );
            onEditIncompatibilities(incompatibilities);
        };

        return (
            <>
                <Grid container>
                    <Grid
                        item
                        xs={12}
                        md={10}
                    >
                        <CardTitle title="Liste des formalités incompatibles" />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={2}
                    >
                        <ActionButton
                            label="Modifier la liste"
                            onClick={onOpenIncompatibilitiesModal}
                            icon={todoIcon}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                    >
                        <FormalityIncompatibilitiesCard
                            formalityId={formality.id as number}
                            totalItemsCount={formality?.incompatibilities?.length as number}
                            onRemoveIncompatibility={onRemoveIncompatibility}
                        />
                    </Grid>
                </Grid>
                <IncompatibilitiesModal
                    isOpen={editModalOpen}
                    onModalClose={onCloseIncompatibilitiesModal}
                    onSubmitModal={onEditIncompatibilities}
                    itemsSelected={formality?.incompatibilities as unknown as number[]}
                    formality={formality}
                />
            </>
        );
    };

export default FormalityIncompatbilitiesTab;
