import React, { FunctionComponent, useState } from 'react';

import achatsViewPathResolver from '@b2d/pages/Achats/achatsViewPathResolver';
import { customizerSelector, factureAchatApi } from '@b2d/redux/RootStore';
import { DataCard } from '@europrocurement/flexy-components';
import { FaOptionIcon, invoiceStatementIcon } from '@europrocurement/l2d-icons';
import { Button, Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

// All

const FacturesEnPause: FunctionComponent = function () {
    const navigate = useNavigate();

    const { xIdSociete } = useSelector(customizerSelector);

    const [countOfInvoicesResumed, setCountOfInvoicesResumed] = useState<string>('...');

    factureAchatApi
        .getFacturesAchatsFactureAchatCollection({
            xIdSociete,
            page: 1,
            itemsPerPage: 0,
            cloture: false,
            deleted: false,
        })
        .then((res) => {
            if (
                res.data['hydra:totalItems'] !== null ||
                res.data['hydra:totalItems'] !== undefined
            ) {
                setCountOfInvoicesResumed(`${res.data['hydra:totalItems']}`);
            }
        });

    return (
        <Grid
            sx={{
                flex: 1,
            }}
            item
            lg={1}
            md={1}
            xs={12}
        >
            <DataCard
                title="Factures en pause"
                color="warning"
                iconBgColor="transparent"
                iconColor="transparent"
                icon={<FaOptionIcon {...invoiceStatementIcon.props} />}
                tools={
                    <Button
                        color="warning"
                        variant="outlined"
                        onClick={() => {
                            navigate(achatsViewPathResolver('listfacturepause'));
                        }}
                    >
                        Traiter
                    </Button>
                }
            >
                <Typography
                    sx={{
                        fontSize: '18px',
                        textAlign: 'center',
                    }}
                >
                    Factures en pause :
                </Typography>
                <Typography
                    color="warning.main"
                    component="h2"
                    sx={{
                        fontSize: '32px',
                        textAlign: 'center',
                    }}
                >
                    <b>{countOfInvoicesResumed}</b>
                </Typography>
            </DataCard>
        </Grid>
    );
};

export default FacturesEnPause;
