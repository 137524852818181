/* tslint:disable */
/* eslint-disable */
/**
 * EuroprocSign API [devgie]
 * API de l\'outil EuroprocSign, outil qui permet de créer des dossiers de signatures a l\'aide de VitalSign
 *
 * The version of the OpenAPI document: 2.0.8
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * Demandeur
 * @export
 * @interface DemandeurJsonldReadCollectionTimestampableBlameambleGroup
 */
export interface DemandeurJsonldReadCollectionTimestampableBlameambleGroup {
    /**
     * 
     * @type {DemandeurJsonldReadTimestampableBlameambleGroupContext}
     * @memberof DemandeurJsonldReadCollectionTimestampableBlameambleGroup
     */
    '@context'?: DemandeurJsonldReadTimestampableBlameambleGroupContext;
    /**
     * 
     * @type {string}
     * @memberof DemandeurJsonldReadCollectionTimestampableBlameambleGroup
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DemandeurJsonldReadCollectionTimestampableBlameambleGroup
     */
    '@type'?: string;
    /**
     * id
     * @type {number}
     * @memberof DemandeurJsonldReadCollectionTimestampableBlameambleGroup
     */
    'id'?: number;
    /**
     * nom
     * @type {string}
     * @memberof DemandeurJsonldReadCollectionTimestampableBlameambleGroup
     */
    'nom'?: string;
    /**
     * Identifiant user du demandeur
     * @type {string}
     * @memberof DemandeurJsonldReadCollectionTimestampableBlameambleGroup
     */
    'userIdentifier'?: string;
    /**
     * actif
     * @type {boolean}
     * @memberof DemandeurJsonldReadCollectionTimestampableBlameambleGroup
     */
    'actif'?: boolean;
    /**
     * Url de callback
     * @type {string}
     * @memberof DemandeurJsonldReadCollectionTimestampableBlameambleGroup
     */
    'callbackUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DemandeurJsonldReadCollectionTimestampableBlameambleGroup
     */
    'logo'?: string | null;
}
/**
 * Demandeur
 * @export
 * @interface DemandeurJsonldReadReadCollection
 */
export interface DemandeurJsonldReadReadCollection {
    /**
     * 
     * @type {string}
     * @memberof DemandeurJsonldReadReadCollection
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DemandeurJsonldReadReadCollection
     */
    '@type'?: string;
    /**
     * 
     * @type {DemandeurJsonldReadTimestampableBlameambleGroupContext}
     * @memberof DemandeurJsonldReadReadCollection
     */
    '@context'?: DemandeurJsonldReadTimestampableBlameambleGroupContext;
    /**
     * id
     * @type {number}
     * @memberof DemandeurJsonldReadReadCollection
     */
    'id'?: number;
    /**
     * nom
     * @type {string}
     * @memberof DemandeurJsonldReadReadCollection
     */
    'nom'?: string;
    /**
     * Identifiant user du demandeur
     * @type {string}
     * @memberof DemandeurJsonldReadReadCollection
     */
    'userIdentifier'?: string;
    /**
     * actif
     * @type {boolean}
     * @memberof DemandeurJsonldReadReadCollection
     */
    'actif'?: boolean;
    /**
     * Url de callback
     * @type {string}
     * @memberof DemandeurJsonldReadReadCollection
     */
    'callbackUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DemandeurJsonldReadReadCollection
     */
    'logo'?: string | null;
}
/**
 * Demandeur
 * @export
 * @interface DemandeurJsonldReadTimestampableBlameambleGroup
 */
export interface DemandeurJsonldReadTimestampableBlameambleGroup {
    /**
     * 
     * @type {DemandeurJsonldReadTimestampableBlameambleGroupContext}
     * @memberof DemandeurJsonldReadTimestampableBlameambleGroup
     */
    '@context'?: DemandeurJsonldReadTimestampableBlameambleGroupContext;
    /**
     * 
     * @type {string}
     * @memberof DemandeurJsonldReadTimestampableBlameambleGroup
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DemandeurJsonldReadTimestampableBlameambleGroup
     */
    '@type'?: string;
    /**
     * id
     * @type {number}
     * @memberof DemandeurJsonldReadTimestampableBlameambleGroup
     */
    'id'?: number;
    /**
     * nom
     * @type {string}
     * @memberof DemandeurJsonldReadTimestampableBlameambleGroup
     */
    'nom'?: string;
    /**
     * Identifiant user du demandeur
     * @type {string}
     * @memberof DemandeurJsonldReadTimestampableBlameambleGroup
     */
    'userIdentifier'?: string;
    /**
     * actif
     * @type {boolean}
     * @memberof DemandeurJsonldReadTimestampableBlameambleGroup
     */
    'actif'?: boolean;
    /**
     * Url de callback
     * @type {string}
     * @memberof DemandeurJsonldReadTimestampableBlameambleGroup
     */
    'callbackUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DemandeurJsonldReadTimestampableBlameambleGroup
     */
    'logo'?: string | null;
}
/**
 * @type DemandeurJsonldReadTimestampableBlameambleGroupContext
 * @export
 */
export type DemandeurJsonldReadTimestampableBlameambleGroupContext = DemandeurJsonldReadTimestampableBlameambleGroupContextOneOf | string;

/**
 * 
 * @export
 * @interface DemandeurJsonldReadTimestampableBlameambleGroupContextOneOf
 */
export interface DemandeurJsonldReadTimestampableBlameambleGroupContextOneOf {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof DemandeurJsonldReadTimestampableBlameambleGroupContextOneOf
     */
    '@vocab': string;
    /**
     * 
     * @type {string}
     * @memberof DemandeurJsonldReadTimestampableBlameambleGroupContextOneOf
     */
    'hydra': DemandeurJsonldReadTimestampableBlameambleGroupContextOneOfHydraEnum;
}

export const DemandeurJsonldReadTimestampableBlameambleGroupContextOneOfHydraEnum = {
    HttpWwwW3OrgNsHydraCore: 'http://www.w3.org/ns/hydra/core#'
} as const;

export type DemandeurJsonldReadTimestampableBlameambleGroupContextOneOfHydraEnum = typeof DemandeurJsonldReadTimestampableBlameambleGroupContextOneOfHydraEnum[keyof typeof DemandeurJsonldReadTimestampableBlameambleGroupContextOneOfHydraEnum];

/**
 * Demandeur
 * @export
 * @interface DemandeurJsonldWrite
 */
export interface DemandeurJsonldWrite {
    /**
     * 
     * @type {DemandeurJsonldReadTimestampableBlameambleGroupContext}
     * @memberof DemandeurJsonldWrite
     */
    '@context'?: DemandeurJsonldReadTimestampableBlameambleGroupContext;
    /**
     * 
     * @type {string}
     * @memberof DemandeurJsonldWrite
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DemandeurJsonldWrite
     */
    '@type'?: string;
    /**
     * nom
     * @type {string}
     * @memberof DemandeurJsonldWrite
     */
    'nom'?: string;
    /**
     * actif
     * @type {boolean}
     * @memberof DemandeurJsonldWrite
     */
    'actif'?: boolean;
    /**
     * Url de callback
     * @type {string}
     * @memberof DemandeurJsonldWrite
     */
    'callbackUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DemandeurJsonldWrite
     */
    'logo'?: string | null;
}
/**
 * Demandeur
 * @export
 * @interface DemandeurReadCollectionTimestampableBlameambleGroup
 */
export interface DemandeurReadCollectionTimestampableBlameambleGroup {
    /**
     * id
     * @type {number}
     * @memberof DemandeurReadCollectionTimestampableBlameambleGroup
     */
    'id'?: number;
    /**
     * nom
     * @type {string}
     * @memberof DemandeurReadCollectionTimestampableBlameambleGroup
     */
    'nom'?: string;
    /**
     * Identifiant user du demandeur
     * @type {string}
     * @memberof DemandeurReadCollectionTimestampableBlameambleGroup
     */
    'userIdentifier'?: string;
    /**
     * actif
     * @type {boolean}
     * @memberof DemandeurReadCollectionTimestampableBlameambleGroup
     */
    'actif'?: boolean;
    /**
     * Url de callback
     * @type {string}
     * @memberof DemandeurReadCollectionTimestampableBlameambleGroup
     */
    'callbackUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DemandeurReadCollectionTimestampableBlameambleGroup
     */
    'logo'?: string | null;
}
/**
 * Demandeur
 * @export
 * @interface DemandeurReadReadCollection
 */
export interface DemandeurReadReadCollection {
    /**
     * id
     * @type {number}
     * @memberof DemandeurReadReadCollection
     */
    'id'?: number;
    /**
     * nom
     * @type {string}
     * @memberof DemandeurReadReadCollection
     */
    'nom'?: string;
    /**
     * Identifiant user du demandeur
     * @type {string}
     * @memberof DemandeurReadReadCollection
     */
    'userIdentifier'?: string;
    /**
     * actif
     * @type {boolean}
     * @memberof DemandeurReadReadCollection
     */
    'actif'?: boolean;
    /**
     * Url de callback
     * @type {string}
     * @memberof DemandeurReadReadCollection
     */
    'callbackUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DemandeurReadReadCollection
     */
    'logo'?: string | null;
}
/**
 * Demandeur
 * @export
 * @interface DemandeurReadTimestampableBlameambleGroup
 */
export interface DemandeurReadTimestampableBlameambleGroup {
    /**
     * id
     * @type {number}
     * @memberof DemandeurReadTimestampableBlameambleGroup
     */
    'id'?: number;
    /**
     * nom
     * @type {string}
     * @memberof DemandeurReadTimestampableBlameambleGroup
     */
    'nom'?: string;
    /**
     * Identifiant user du demandeur
     * @type {string}
     * @memberof DemandeurReadTimestampableBlameambleGroup
     */
    'userIdentifier'?: string;
    /**
     * actif
     * @type {boolean}
     * @memberof DemandeurReadTimestampableBlameambleGroup
     */
    'actif'?: boolean;
    /**
     * Url de callback
     * @type {string}
     * @memberof DemandeurReadTimestampableBlameambleGroup
     */
    'callbackUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DemandeurReadTimestampableBlameambleGroup
     */
    'logo'?: string | null;
}
/**
 * Demandeur
 * @export
 * @interface DemandeurWrite
 */
export interface DemandeurWrite {
    /**
     * nom
     * @type {string}
     * @memberof DemandeurWrite
     */
    'nom'?: string;
    /**
     * actif
     * @type {boolean}
     * @memberof DemandeurWrite
     */
    'actif'?: boolean;
    /**
     * Url de callback
     * @type {string}
     * @memberof DemandeurWrite
     */
    'callbackUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DemandeurWrite
     */
    'logo'?: string | null;
}
/**
 * 
 * @export
 * @interface DocumentJsonldReadCollectionTimestampableBlameambleGroup
 */
export interface DocumentJsonldReadCollectionTimestampableBlameambleGroup {
    /**
     * 
     * @type {DemandeurJsonldReadTimestampableBlameambleGroupContext}
     * @memberof DocumentJsonldReadCollectionTimestampableBlameambleGroup
     */
    '@context'?: DemandeurJsonldReadTimestampableBlameambleGroupContext;
    /**
     * 
     * @type {string}
     * @memberof DocumentJsonldReadCollectionTimestampableBlameambleGroup
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentJsonldReadCollectionTimestampableBlameambleGroup
     */
    '@type'?: string;
    /**
     * id
     * @type {number}
     * @memberof DocumentJsonldReadCollectionTimestampableBlameambleGroup
     */
    'id'?: number;
    /**
     * nom
     * @type {string}
     * @memberof DocumentJsonldReadCollectionTimestampableBlameambleGroup
     */
    'nom': string;
    /**
     * mimeType
     * @type {string}
     * @memberof DocumentJsonldReadCollectionTimestampableBlameambleGroup
     */
    'mimeType'?: string | null;
    /**
     * Get the value of contentUrl
     * @type {any}
     * @memberof DocumentJsonldReadCollectionTimestampableBlameambleGroup
     */
    'contentUrl'?: any;
    /**
     * Get the value of urlExpires
     * @type {any}
     * @memberof DocumentJsonldReadCollectionTimestampableBlameambleGroup
     */
    'urlExpires'?: any;
    /**
     * Get the value of size
     * @type {number}
     * @memberof DocumentJsonldReadCollectionTimestampableBlameambleGroup
     */
    'size'?: number;
    /**
     * Get the value of signedContentUrl
     * @type {any}
     * @memberof DocumentJsonldReadCollectionTimestampableBlameambleGroup
     */
    'signedContentUrl'?: any;
    /**
     * Get the value of signedUrlExpires
     * @type {any}
     * @memberof DocumentJsonldReadCollectionTimestampableBlameambleGroup
     */
    'signedUrlExpires'?: any;
    /**
     * Get the value of signedSize
     * @type {number}
     * @memberof DocumentJsonldReadCollectionTimestampableBlameambleGroup
     */
    'signedSize'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentJsonldReadCollectionTimestampableBlameambleGroup
     */
    'urlSigned'?: string | null;
}
/**
 * 
 * @export
 * @interface DocumentJsonldReadTimestampableBlameambleGroup
 */
export interface DocumentJsonldReadTimestampableBlameambleGroup {
    /**
     * 
     * @type {DemandeurJsonldReadTimestampableBlameambleGroupContext}
     * @memberof DocumentJsonldReadTimestampableBlameambleGroup
     */
    '@context'?: DemandeurJsonldReadTimestampableBlameambleGroupContext;
    /**
     * 
     * @type {string}
     * @memberof DocumentJsonldReadTimestampableBlameambleGroup
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentJsonldReadTimestampableBlameambleGroup
     */
    '@type'?: string;
    /**
     * id
     * @type {number}
     * @memberof DocumentJsonldReadTimestampableBlameambleGroup
     */
    'id'?: number;
    /**
     * nom
     * @type {string}
     * @memberof DocumentJsonldReadTimestampableBlameambleGroup
     */
    'nom': string;
    /**
     * mimeType
     * @type {string}
     * @memberof DocumentJsonldReadTimestampableBlameambleGroup
     */
    'mimeType'?: string | null;
    /**
     * Get the value of contentUrl
     * @type {any}
     * @memberof DocumentJsonldReadTimestampableBlameambleGroup
     */
    'contentUrl'?: any;
    /**
     * Get the value of urlExpires
     * @type {any}
     * @memberof DocumentJsonldReadTimestampableBlameambleGroup
     */
    'urlExpires'?: any;
    /**
     * Get the value of size
     * @type {number}
     * @memberof DocumentJsonldReadTimestampableBlameambleGroup
     */
    'size'?: number;
    /**
     * Get the value of signedContentUrl
     * @type {any}
     * @memberof DocumentJsonldReadTimestampableBlameambleGroup
     */
    'signedContentUrl'?: any;
    /**
     * Get the value of signedUrlExpires
     * @type {any}
     * @memberof DocumentJsonldReadTimestampableBlameambleGroup
     */
    'signedUrlExpires'?: any;
    /**
     * Get the value of signedSize
     * @type {number}
     * @memberof DocumentJsonldReadTimestampableBlameambleGroup
     */
    'signedSize'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentJsonldReadTimestampableBlameambleGroup
     */
    'urlSigned'?: string | null;
}
/**
 * 
 * @export
 * @interface DocumentJsonldWrite
 */
export interface DocumentJsonldWrite {
    /**
     * 
     * @type {DemandeurJsonldReadTimestampableBlameambleGroupContext}
     * @memberof DocumentJsonldWrite
     */
    '@context'?: DemandeurJsonldReadTimestampableBlameambleGroupContext;
    /**
     * 
     * @type {string}
     * @memberof DocumentJsonldWrite
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentJsonldWrite
     */
    '@type'?: string;
    /**
     * nom
     * @type {string}
     * @memberof DocumentJsonldWrite
     */
    'nom': string;
    /**
     * 
     * @type {string}
     * @memberof DocumentJsonldWrite
     */
    'base64': string;
}
/**
 * 
 * @export
 * @interface DocumentReadCollectionTimestampableBlameambleGroup
 */
export interface DocumentReadCollectionTimestampableBlameambleGroup {
    /**
     * id
     * @type {number}
     * @memberof DocumentReadCollectionTimestampableBlameambleGroup
     */
    'id'?: number;
    /**
     * nom
     * @type {string}
     * @memberof DocumentReadCollectionTimestampableBlameambleGroup
     */
    'nom': string;
    /**
     * mimeType
     * @type {string}
     * @memberof DocumentReadCollectionTimestampableBlameambleGroup
     */
    'mimeType'?: string | null;
    /**
     * Get the value of contentUrl
     * @type {any}
     * @memberof DocumentReadCollectionTimestampableBlameambleGroup
     */
    'contentUrl'?: any;
    /**
     * Get the value of urlExpires
     * @type {any}
     * @memberof DocumentReadCollectionTimestampableBlameambleGroup
     */
    'urlExpires'?: any;
    /**
     * Get the value of size
     * @type {number}
     * @memberof DocumentReadCollectionTimestampableBlameambleGroup
     */
    'size'?: number;
    /**
     * Get the value of signedContentUrl
     * @type {any}
     * @memberof DocumentReadCollectionTimestampableBlameambleGroup
     */
    'signedContentUrl'?: any;
    /**
     * Get the value of signedUrlExpires
     * @type {any}
     * @memberof DocumentReadCollectionTimestampableBlameambleGroup
     */
    'signedUrlExpires'?: any;
    /**
     * Get the value of signedSize
     * @type {number}
     * @memberof DocumentReadCollectionTimestampableBlameambleGroup
     */
    'signedSize'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentReadCollectionTimestampableBlameambleGroup
     */
    'urlSigned'?: string | null;
}
/**
 * 
 * @export
 * @interface DocumentReadTimestampableBlameambleGroup
 */
export interface DocumentReadTimestampableBlameambleGroup {
    /**
     * id
     * @type {number}
     * @memberof DocumentReadTimestampableBlameambleGroup
     */
    'id'?: number;
    /**
     * nom
     * @type {string}
     * @memberof DocumentReadTimestampableBlameambleGroup
     */
    'nom': string;
    /**
     * mimeType
     * @type {string}
     * @memberof DocumentReadTimestampableBlameambleGroup
     */
    'mimeType'?: string | null;
    /**
     * Get the value of contentUrl
     * @type {any}
     * @memberof DocumentReadTimestampableBlameambleGroup
     */
    'contentUrl'?: any;
    /**
     * Get the value of urlExpires
     * @type {any}
     * @memberof DocumentReadTimestampableBlameambleGroup
     */
    'urlExpires'?: any;
    /**
     * Get the value of size
     * @type {number}
     * @memberof DocumentReadTimestampableBlameambleGroup
     */
    'size'?: number;
    /**
     * Get the value of signedContentUrl
     * @type {any}
     * @memberof DocumentReadTimestampableBlameambleGroup
     */
    'signedContentUrl'?: any;
    /**
     * Get the value of signedUrlExpires
     * @type {any}
     * @memberof DocumentReadTimestampableBlameambleGroup
     */
    'signedUrlExpires'?: any;
    /**
     * Get the value of signedSize
     * @type {number}
     * @memberof DocumentReadTimestampableBlameambleGroup
     */
    'signedSize'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentReadTimestampableBlameambleGroup
     */
    'urlSigned'?: string | null;
}
/**
 * 
 * @export
 * @interface DocumentWrite
 */
export interface DocumentWrite {
    /**
     * nom
     * @type {string}
     * @memberof DocumentWrite
     */
    'nom': string;
    /**
     * 
     * @type {string}
     * @memberof DocumentWrite
     */
    'base64': string;
}
/**
 * Dossier
 * @export
 * @interface DossierJsonldReadCollectionTimestampableBlameambleGroup
 */
export interface DossierJsonldReadCollectionTimestampableBlameambleGroup {
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldReadCollectionTimestampableBlameambleGroup
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldReadCollectionTimestampableBlameambleGroup
     */
    '@type'?: string;
    /**
     * id
     * @type {number}
     * @memberof DossierJsonldReadCollectionTimestampableBlameambleGroup
     */
    'id'?: number;
    /**
     * sujet
     * @type {string}
     * @memberof DossierJsonldReadCollectionTimestampableBlameambleGroup
     */
    'sujet': string;
    /**
     * referenceExterne
     * @type {string}
     * @memberof DossierJsonldReadCollectionTimestampableBlameambleGroup
     */
    'referenceExterne'?: string | null;
    /**
     * prescripteur
     * @type {string}
     * @memberof DossierJsonldReadCollectionTimestampableBlameambleGroup
     */
    'prescripteur': string;
    /**
     * prescripteurEmail
     * @type {string}
     * @memberof DossierJsonldReadCollectionTimestampableBlameambleGroup
     */
    'prescripteurEmail': string;
    /**
     * 
     * @type {DemandeurJsonldReadCollectionTimestampableBlameambleGroup}
     * @memberof DossierJsonldReadCollectionTimestampableBlameambleGroup
     */
    'demandeur'?: DemandeurJsonldReadCollectionTimestampableBlameambleGroup;
    /**
     * documents
     * @type {Array<DocumentJsonldReadCollectionTimestampableBlameambleGroup>}
     * @memberof DossierJsonldReadCollectionTimestampableBlameambleGroup
     */
    'documents': Array<DocumentJsonldReadCollectionTimestampableBlameambleGroup>;
    /**
     * signataires
     * @type {Array<SignataireJsonldReadCollectionTimestampableBlameambleGroup>}
     * @memberof DossierJsonldReadCollectionTimestampableBlameambleGroup
     */
    'signataires': Array<SignataireJsonldReadCollectionTimestampableBlameambleGroup>;
    /**
     * logs
     * @type {Array<LogJsonldReadCollectionTimestampableBlameambleGroup>}
     * @memberof DossierJsonldReadCollectionTimestampableBlameambleGroup
     */
    'logs'?: Array<LogJsonldReadCollectionTimestampableBlameambleGroup>;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldReadCollectionTimestampableBlameambleGroup
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldReadCollectionTimestampableBlameambleGroup
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldReadCollectionTimestampableBlameambleGroup
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldReadCollectionTimestampableBlameambleGroup
     */
    'updatedAt'?: string;
}
/**
 * Dossier
 * @export
 * @interface DossierJsonldReadTimestampableBlameambleGroup
 */
export interface DossierJsonldReadTimestampableBlameambleGroup {
    /**
     * 
     * @type {DemandeurJsonldReadTimestampableBlameambleGroupContext}
     * @memberof DossierJsonldReadTimestampableBlameambleGroup
     */
    '@context'?: DemandeurJsonldReadTimestampableBlameambleGroupContext;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldReadTimestampableBlameambleGroup
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldReadTimestampableBlameambleGroup
     */
    '@type'?: string;
    /**
     * id
     * @type {number}
     * @memberof DossierJsonldReadTimestampableBlameambleGroup
     */
    'id'?: number;
    /**
     * sujet
     * @type {string}
     * @memberof DossierJsonldReadTimestampableBlameambleGroup
     */
    'sujet': string;
    /**
     * referenceExterne
     * @type {string}
     * @memberof DossierJsonldReadTimestampableBlameambleGroup
     */
    'referenceExterne'?: string | null;
    /**
     * prescripteur
     * @type {string}
     * @memberof DossierJsonldReadTimestampableBlameambleGroup
     */
    'prescripteur': string;
    /**
     * prescripteurEmail
     * @type {string}
     * @memberof DossierJsonldReadTimestampableBlameambleGroup
     */
    'prescripteurEmail': string;
    /**
     * 
     * @type {DemandeurJsonldReadTimestampableBlameambleGroup}
     * @memberof DossierJsonldReadTimestampableBlameambleGroup
     */
    'demandeur'?: DemandeurJsonldReadTimestampableBlameambleGroup;
    /**
     * documents
     * @type {Array<DocumentJsonldReadTimestampableBlameambleGroup>}
     * @memberof DossierJsonldReadTimestampableBlameambleGroup
     */
    'documents': Array<DocumentJsonldReadTimestampableBlameambleGroup>;
    /**
     * signataires
     * @type {Array<SignataireJsonldReadTimestampableBlameambleGroup>}
     * @memberof DossierJsonldReadTimestampableBlameambleGroup
     */
    'signataires': Array<SignataireJsonldReadTimestampableBlameambleGroup>;
    /**
     * logs
     * @type {Array<LogJsonldReadTimestampableBlameambleGroup>}
     * @memberof DossierJsonldReadTimestampableBlameambleGroup
     */
    'logs'?: Array<LogJsonldReadTimestampableBlameambleGroup>;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldReadTimestampableBlameambleGroup
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldReadTimestampableBlameambleGroup
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldReadTimestampableBlameambleGroup
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldReadTimestampableBlameambleGroup
     */
    'updatedAt'?: string;
}
/**
 * Dossier
 * @export
 * @interface DossierJsonldWrite
 */
export interface DossierJsonldWrite {
    /**
     * 
     * @type {DemandeurJsonldReadTimestampableBlameambleGroupContext}
     * @memberof DossierJsonldWrite
     */
    '@context'?: DemandeurJsonldReadTimestampableBlameambleGroupContext;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldWrite
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldWrite
     */
    '@type'?: string;
    /**
     * sujet
     * @type {string}
     * @memberof DossierJsonldWrite
     */
    'sujet': string;
    /**
     * referenceExterne
     * @type {string}
     * @memberof DossierJsonldWrite
     */
    'referenceExterne'?: string | null;
    /**
     * prescripteur
     * @type {string}
     * @memberof DossierJsonldWrite
     */
    'prescripteur': string;
    /**
     * prescripteurEmail
     * @type {string}
     * @memberof DossierJsonldWrite
     */
    'prescripteurEmail': string;
    /**
     * documents
     * @type {Array<DocumentJsonldWrite>}
     * @memberof DossierJsonldWrite
     */
    'documents': Array<DocumentJsonldWrite>;
    /**
     * signataires
     * @type {Array<SignataireJsonldWrite>}
     * @memberof DossierJsonldWrite
     */
    'signataires': Array<SignataireJsonldWrite>;
}
/**
 * Dossier
 * @export
 * @interface DossierReadCollectionTimestampableBlameambleGroup
 */
export interface DossierReadCollectionTimestampableBlameambleGroup {
    /**
     * id
     * @type {number}
     * @memberof DossierReadCollectionTimestampableBlameambleGroup
     */
    'id'?: number;
    /**
     * sujet
     * @type {string}
     * @memberof DossierReadCollectionTimestampableBlameambleGroup
     */
    'sujet': string;
    /**
     * referenceExterne
     * @type {string}
     * @memberof DossierReadCollectionTimestampableBlameambleGroup
     */
    'referenceExterne'?: string | null;
    /**
     * prescripteur
     * @type {string}
     * @memberof DossierReadCollectionTimestampableBlameambleGroup
     */
    'prescripteur': string;
    /**
     * prescripteurEmail
     * @type {string}
     * @memberof DossierReadCollectionTimestampableBlameambleGroup
     */
    'prescripteurEmail': string;
    /**
     * 
     * @type {DemandeurReadCollectionTimestampableBlameambleGroup}
     * @memberof DossierReadCollectionTimestampableBlameambleGroup
     */
    'demandeur'?: DemandeurReadCollectionTimestampableBlameambleGroup;
    /**
     * documents
     * @type {Array<DocumentReadCollectionTimestampableBlameambleGroup>}
     * @memberof DossierReadCollectionTimestampableBlameambleGroup
     */
    'documents': Array<DocumentReadCollectionTimestampableBlameambleGroup>;
    /**
     * signataires
     * @type {Array<SignataireReadCollectionTimestampableBlameambleGroup>}
     * @memberof DossierReadCollectionTimestampableBlameambleGroup
     */
    'signataires': Array<SignataireReadCollectionTimestampableBlameambleGroup>;
    /**
     * logs
     * @type {Array<LogReadCollectionTimestampableBlameambleGroup>}
     * @memberof DossierReadCollectionTimestampableBlameambleGroup
     */
    'logs'?: Array<LogReadCollectionTimestampableBlameambleGroup>;
    /**
     * 
     * @type {string}
     * @memberof DossierReadCollectionTimestampableBlameambleGroup
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierReadCollectionTimestampableBlameambleGroup
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierReadCollectionTimestampableBlameambleGroup
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierReadCollectionTimestampableBlameambleGroup
     */
    'updatedAt'?: string;
}
/**
 * Dossier
 * @export
 * @interface DossierReadTimestampableBlameambleGroup
 */
export interface DossierReadTimestampableBlameambleGroup {
    /**
     * id
     * @type {number}
     * @memberof DossierReadTimestampableBlameambleGroup
     */
    'id'?: number;
    /**
     * sujet
     * @type {string}
     * @memberof DossierReadTimestampableBlameambleGroup
     */
    'sujet': string;
    /**
     * referenceExterne
     * @type {string}
     * @memberof DossierReadTimestampableBlameambleGroup
     */
    'referenceExterne'?: string | null;
    /**
     * prescripteur
     * @type {string}
     * @memberof DossierReadTimestampableBlameambleGroup
     */
    'prescripteur': string;
    /**
     * prescripteurEmail
     * @type {string}
     * @memberof DossierReadTimestampableBlameambleGroup
     */
    'prescripteurEmail': string;
    /**
     * 
     * @type {DemandeurReadTimestampableBlameambleGroup}
     * @memberof DossierReadTimestampableBlameambleGroup
     */
    'demandeur'?: DemandeurReadTimestampableBlameambleGroup;
    /**
     * documents
     * @type {Array<DocumentReadTimestampableBlameambleGroup>}
     * @memberof DossierReadTimestampableBlameambleGroup
     */
    'documents': Array<DocumentReadTimestampableBlameambleGroup>;
    /**
     * signataires
     * @type {Array<SignataireReadTimestampableBlameambleGroup>}
     * @memberof DossierReadTimestampableBlameambleGroup
     */
    'signataires': Array<SignataireReadTimestampableBlameambleGroup>;
    /**
     * logs
     * @type {Array<LogReadTimestampableBlameambleGroup>}
     * @memberof DossierReadTimestampableBlameambleGroup
     */
    'logs'?: Array<LogReadTimestampableBlameambleGroup>;
    /**
     * 
     * @type {string}
     * @memberof DossierReadTimestampableBlameambleGroup
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierReadTimestampableBlameambleGroup
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierReadTimestampableBlameambleGroup
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierReadTimestampableBlameambleGroup
     */
    'updatedAt'?: string;
}
/**
 * Dossier
 * @export
 * @interface DossierWrite
 */
export interface DossierWrite {
    /**
     * sujet
     * @type {string}
     * @memberof DossierWrite
     */
    'sujet': string;
    /**
     * referenceExterne
     * @type {string}
     * @memberof DossierWrite
     */
    'referenceExterne'?: string | null;
    /**
     * prescripteur
     * @type {string}
     * @memberof DossierWrite
     */
    'prescripteur': string;
    /**
     * prescripteurEmail
     * @type {string}
     * @memberof DossierWrite
     */
    'prescripteurEmail': string;
    /**
     * documents
     * @type {Array<DocumentWrite>}
     * @memberof DossierWrite
     */
    'documents': Array<DocumentWrite>;
    /**
     * signataires
     * @type {Array<SignataireWrite>}
     * @memberof DossierWrite
     */
    'signataires': Array<SignataireWrite>;
}
/**
 * 
 * @export
 * @interface GetDemandeurCollection200Response
 */
export interface GetDemandeurCollection200Response {
    /**
     * 
     * @type {Array<DemandeurJsonldReadReadCollection>}
     * @memberof GetDemandeurCollection200Response
     */
    'hydra:member': Array<DemandeurJsonldReadReadCollection>;
    /**
     * 
     * @type {number}
     * @memberof GetDemandeurCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {GetDemandeurCollection200ResponseHydraView}
     * @memberof GetDemandeurCollection200Response
     */
    'hydra:view'?: GetDemandeurCollection200ResponseHydraView;
    /**
     * 
     * @type {GetDemandeurCollection200ResponseHydraSearch}
     * @memberof GetDemandeurCollection200Response
     */
    'hydra:search'?: GetDemandeurCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface GetDemandeurCollection200ResponseHydraSearch
 */
export interface GetDemandeurCollection200ResponseHydraSearch {
    /**
     * 
     * @type {string}
     * @memberof GetDemandeurCollection200ResponseHydraSearch
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDemandeurCollection200ResponseHydraSearch
     */
    'hydra:template'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDemandeurCollection200ResponseHydraSearch
     */
    'hydra:variableRepresentation'?: string;
    /**
     * 
     * @type {Array<GetDemandeurCollection200ResponseHydraSearchHydraMappingInner>}
     * @memberof GetDemandeurCollection200ResponseHydraSearch
     */
    'hydra:mapping'?: Array<GetDemandeurCollection200ResponseHydraSearchHydraMappingInner>;
}
/**
 * 
 * @export
 * @interface GetDemandeurCollection200ResponseHydraSearchHydraMappingInner
 */
export interface GetDemandeurCollection200ResponseHydraSearchHydraMappingInner {
    /**
     * 
     * @type {string}
     * @memberof GetDemandeurCollection200ResponseHydraSearchHydraMappingInner
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDemandeurCollection200ResponseHydraSearchHydraMappingInner
     */
    'variable'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDemandeurCollection200ResponseHydraSearchHydraMappingInner
     */
    'property'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetDemandeurCollection200ResponseHydraSearchHydraMappingInner
     */
    'required'?: boolean;
}
/**
 * 
 * @export
 * @interface GetDemandeurCollection200ResponseHydraView
 */
export interface GetDemandeurCollection200ResponseHydraView {
    /**
     * 
     * @type {string}
     * @memberof GetDemandeurCollection200ResponseHydraView
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDemandeurCollection200ResponseHydraView
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDemandeurCollection200ResponseHydraView
     */
    'hydra:first'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDemandeurCollection200ResponseHydraView
     */
    'hydra:last'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDemandeurCollection200ResponseHydraView
     */
    'hydra:previous'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDemandeurCollection200ResponseHydraView
     */
    'hydra:next'?: string;
}
/**
 * 
 * @export
 * @interface GetDemandeurCollection401Response
 */
export interface GetDemandeurCollection401Response {
    /**
     * 
     * @type {string}
     * @memberof GetDemandeurCollection401Response
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface GetDemandeurCollection403Response
 */
export interface GetDemandeurCollection403Response {
    /**
     * 
     * @type {string}
     * @memberof GetDemandeurCollection403Response
     */
    'error'?: string;
    /**
     * 
     * @type {GetDemandeurCollection403ResponseException}
     * @memberof GetDemandeurCollection403Response
     */
    'exception'?: GetDemandeurCollection403ResponseException;
}
/**
 * 
 * @export
 * @interface GetDemandeurCollection403ResponseException
 */
export interface GetDemandeurCollection403ResponseException {
    /**
     * 
     * @type {string}
     * @memberof GetDemandeurCollection403ResponseException
     */
    'message'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetDemandeurCollection403ResponseException
     */
    'code'?: number;
}
/**
 * 
 * @export
 * @interface GetDossierCollection200Response
 */
export interface GetDossierCollection200Response {
    /**
     * 
     * @type {Array<DossierJsonldReadCollectionTimestampableBlameambleGroup>}
     * @memberof GetDossierCollection200Response
     */
    'hydra:member': Array<DossierJsonldReadCollectionTimestampableBlameambleGroup>;
    /**
     * 
     * @type {number}
     * @memberof GetDossierCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {GetDemandeurCollection200ResponseHydraView}
     * @memberof GetDossierCollection200Response
     */
    'hydra:view'?: GetDemandeurCollection200ResponseHydraView;
    /**
     * 
     * @type {GetDemandeurCollection200ResponseHydraSearch}
     * @memberof GetDossierCollection200Response
     */
    'hydra:search'?: GetDemandeurCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface LogJsonldReadCollectionTimestampableBlameambleGroup
 */
export interface LogJsonldReadCollectionTimestampableBlameambleGroup {
    /**
     * 
     * @type {DemandeurJsonldReadTimestampableBlameambleGroupContext}
     * @memberof LogJsonldReadCollectionTimestampableBlameambleGroup
     */
    '@context'?: DemandeurJsonldReadTimestampableBlameambleGroupContext;
    /**
     * 
     * @type {string}
     * @memberof LogJsonldReadCollectionTimestampableBlameambleGroup
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof LogJsonldReadCollectionTimestampableBlameambleGroup
     */
    '@type'?: string;
    /**
     * Get the value of date
     * @type {string}
     * @memberof LogJsonldReadCollectionTimestampableBlameambleGroup
     */
    'date'?: string;
    /**
     * Get the value of message
     * @type {string}
     * @memberof LogJsonldReadCollectionTimestampableBlameambleGroup
     */
    'message'?: string;
    /**
     * Get the value of event
     * @type {string}
     * @memberof LogJsonldReadCollectionTimestampableBlameambleGroup
     */
    'event'?: string;
    /**
     * Get the value of user
     * @type {string}
     * @memberof LogJsonldReadCollectionTimestampableBlameambleGroup
     */
    'user'?: string | null;
}
/**
 * 
 * @export
 * @interface LogJsonldReadTimestampableBlameambleGroup
 */
export interface LogJsonldReadTimestampableBlameambleGroup {
    /**
     * 
     * @type {DemandeurJsonldReadTimestampableBlameambleGroupContext}
     * @memberof LogJsonldReadTimestampableBlameambleGroup
     */
    '@context'?: DemandeurJsonldReadTimestampableBlameambleGroupContext;
    /**
     * 
     * @type {string}
     * @memberof LogJsonldReadTimestampableBlameambleGroup
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof LogJsonldReadTimestampableBlameambleGroup
     */
    '@type'?: string;
    /**
     * Get the value of date
     * @type {string}
     * @memberof LogJsonldReadTimestampableBlameambleGroup
     */
    'date'?: string;
    /**
     * Get the value of message
     * @type {string}
     * @memberof LogJsonldReadTimestampableBlameambleGroup
     */
    'message'?: string;
    /**
     * Get the value of event
     * @type {string}
     * @memberof LogJsonldReadTimestampableBlameambleGroup
     */
    'event'?: string;
    /**
     * Get the value of user
     * @type {string}
     * @memberof LogJsonldReadTimestampableBlameambleGroup
     */
    'user'?: string | null;
}
/**
 * 
 * @export
 * @interface LogReadCollectionTimestampableBlameambleGroup
 */
export interface LogReadCollectionTimestampableBlameambleGroup {
    /**
     * Get the value of date
     * @type {string}
     * @memberof LogReadCollectionTimestampableBlameambleGroup
     */
    'date'?: string;
    /**
     * Get the value of message
     * @type {string}
     * @memberof LogReadCollectionTimestampableBlameambleGroup
     */
    'message'?: string;
    /**
     * Get the value of event
     * @type {string}
     * @memberof LogReadCollectionTimestampableBlameambleGroup
     */
    'event'?: string;
    /**
     * Get the value of user
     * @type {string}
     * @memberof LogReadCollectionTimestampableBlameambleGroup
     */
    'user'?: string | null;
}
/**
 * 
 * @export
 * @interface LogReadTimestampableBlameambleGroup
 */
export interface LogReadTimestampableBlameambleGroup {
    /**
     * Get the value of date
     * @type {string}
     * @memberof LogReadTimestampableBlameambleGroup
     */
    'date'?: string;
    /**
     * Get the value of message
     * @type {string}
     * @memberof LogReadTimestampableBlameambleGroup
     */
    'message'?: string;
    /**
     * Get the value of event
     * @type {string}
     * @memberof LogReadTimestampableBlameambleGroup
     */
    'event'?: string;
    /**
     * Get the value of user
     * @type {string}
     * @memberof LogReadTimestampableBlameambleGroup
     */
    'user'?: string | null;
}
/**
 * 
 * @export
 * @interface RaiseDossierItem200Response
 */
export interface RaiseDossierItem200Response {
    /**
     * 
     * @type {string}
     * @memberof RaiseDossierItem200Response
     */
    'code'?: RaiseDossierItem200ResponseCodeEnum;
    /**
     * 
     * @type {string}
     * @memberof RaiseDossierItem200Response
     */
    'message'?: RaiseDossierItem200ResponseMessageEnum;
}

export const RaiseDossierItem200ResponseCodeEnum = {
    NothingToDo: 'NOTHING_TO_DO'
} as const;

export type RaiseDossierItem200ResponseCodeEnum = typeof RaiseDossierItem200ResponseCodeEnum[keyof typeof RaiseDossierItem200ResponseCodeEnum];
export const RaiseDossierItem200ResponseMessageEnum = {
    LeDossierEstDejaSign: 'le dossier est deja signé'
} as const;

export type RaiseDossierItem200ResponseMessageEnum = typeof RaiseDossierItem200ResponseMessageEnum[keyof typeof RaiseDossierItem200ResponseMessageEnum];

/**
 * 
 * @export
 * @interface RaiseDossierItem201Response
 */
export interface RaiseDossierItem201Response {
    /**
     * 
     * @type {string}
     * @memberof RaiseDossierItem201Response
     */
    'code'?: RaiseDossierItem201ResponseCodeEnum;
    /**
     * 
     * @type {string}
     * @memberof RaiseDossierItem201Response
     */
    'message'?: RaiseDossierItem201ResponseMessageEnum;
}

export const RaiseDossierItem201ResponseCodeEnum = {
    VitalsignReplay: 'VITALSIGN_REPLAY'
} as const;

export type RaiseDossierItem201ResponseCodeEnum = typeof RaiseDossierItem201ResponseCodeEnum[keyof typeof RaiseDossierItem201ResponseCodeEnum];
export const RaiseDossierItem201ResponseMessageEnum = {
    LeDossierNonSyncronisAvecVitalsignTRejou: 'le dossier non syncronisé avec vitalsign à été rejoué'
} as const;

export type RaiseDossierItem201ResponseMessageEnum = typeof RaiseDossierItem201ResponseMessageEnum[keyof typeof RaiseDossierItem201ResponseMessageEnum];

/**
 * 
 * @export
 * @interface RaiseDossierItem202Response
 */
export interface RaiseDossierItem202Response {
    /**
     * 
     * @type {string}
     * @memberof RaiseDossierItem202Response
     */
    'code'?: RaiseDossierItem202ResponseCodeEnum;
    /**
     * 
     * @type {string}
     * @memberof RaiseDossierItem202Response
     */
    'message'?: RaiseDossierItem202ResponseMessageEnum;
}

export const RaiseDossierItem202ResponseCodeEnum = {
    Raised: 'RAISED'
} as const;

export type RaiseDossierItem202ResponseCodeEnum = typeof RaiseDossierItem202ResponseCodeEnum[keyof typeof RaiseDossierItem202ResponseCodeEnum];
export const RaiseDossierItem202ResponseMessageEnum = {
    LeDossierATRelanc: 'le dossier a été relancé'
} as const;

export type RaiseDossierItem202ResponseMessageEnum = typeof RaiseDossierItem202ResponseMessageEnum[keyof typeof RaiseDossierItem202ResponseMessageEnum];

/**
 * 
 * @export
 * @interface RaiseDossierItem500Response
 */
export interface RaiseDossierItem500Response {
    /**
     * 
     * @type {string}
     * @memberof RaiseDossierItem500Response
     */
    'code'?: RaiseDossierItem500ResponseCodeEnum;
    /**
     * 
     * @type {string}
     * @memberof RaiseDossierItem500Response
     */
    'message'?: RaiseDossierItem500ResponseMessageEnum;
}

export const RaiseDossierItem500ResponseCodeEnum = {
    VitalsignReplayError: 'VITALSIGN_REPLAY_ERROR'
} as const;

export type RaiseDossierItem500ResponseCodeEnum = typeof RaiseDossierItem500ResponseCodeEnum[keyof typeof RaiseDossierItem500ResponseCodeEnum];
export const RaiseDossierItem500ResponseMessageEnum = {
    LeDossierNonSyncronisAvecVitalsignNaPasPuEtreRejou: 'le dossier non syncronisé avec vitalsign n\'a pas pu etre rejoué'
} as const;

export type RaiseDossierItem500ResponseMessageEnum = typeof RaiseDossierItem500ResponseMessageEnum[keyof typeof RaiseDossierItem500ResponseMessageEnum];

/**
 * 
 * @export
 * @interface RelanceJsonldReadCollectionTimestampableBlameambleGroup
 */
export interface RelanceJsonldReadCollectionTimestampableBlameambleGroup {
    /**
     * 
     * @type {DemandeurJsonldReadTimestampableBlameambleGroupContext}
     * @memberof RelanceJsonldReadCollectionTimestampableBlameambleGroup
     */
    '@context'?: DemandeurJsonldReadTimestampableBlameambleGroupContext;
    /**
     * 
     * @type {string}
     * @memberof RelanceJsonldReadCollectionTimestampableBlameambleGroup
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RelanceJsonldReadCollectionTimestampableBlameambleGroup
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof RelanceJsonldReadCollectionTimestampableBlameambleGroup
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RelanceJsonldReadCollectionTimestampableBlameambleGroup
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RelanceJsonldReadCollectionTimestampableBlameambleGroup
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof RelanceJsonldReadCollectionTimestampableBlameambleGroup
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface RelanceJsonldReadTimestampableBlameambleGroup
 */
export interface RelanceJsonldReadTimestampableBlameambleGroup {
    /**
     * 
     * @type {DemandeurJsonldReadTimestampableBlameambleGroupContext}
     * @memberof RelanceJsonldReadTimestampableBlameambleGroup
     */
    '@context'?: DemandeurJsonldReadTimestampableBlameambleGroupContext;
    /**
     * 
     * @type {string}
     * @memberof RelanceJsonldReadTimestampableBlameambleGroup
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RelanceJsonldReadTimestampableBlameambleGroup
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof RelanceJsonldReadTimestampableBlameambleGroup
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RelanceJsonldReadTimestampableBlameambleGroup
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RelanceJsonldReadTimestampableBlameambleGroup
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof RelanceJsonldReadTimestampableBlameambleGroup
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface RelanceReadCollectionTimestampableBlameambleGroup
 */
export interface RelanceReadCollectionTimestampableBlameambleGroup {
    /**
     * 
     * @type {string}
     * @memberof RelanceReadCollectionTimestampableBlameambleGroup
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RelanceReadCollectionTimestampableBlameambleGroup
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RelanceReadCollectionTimestampableBlameambleGroup
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof RelanceReadCollectionTimestampableBlameambleGroup
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface RelanceReadTimestampableBlameambleGroup
 */
export interface RelanceReadTimestampableBlameambleGroup {
    /**
     * 
     * @type {string}
     * @memberof RelanceReadTimestampableBlameambleGroup
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RelanceReadTimestampableBlameambleGroup
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RelanceReadTimestampableBlameambleGroup
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof RelanceReadTimestampableBlameambleGroup
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface SignDossiersPostRequest
 */
export interface SignDossiersPostRequest {
    /**
     * 
     * @type {Sujet}
     * @memberof SignDossiersPostRequest
     */
    'sujet'?: Sujet;
    /**
     * 
     * @type {Id}
     * @memberof SignDossiersPostRequest
     */
    'id'?: Id;
    /**
     * 
     * @type {ReferenceExterne}
     * @memberof SignDossiersPostRequest
     */
    'referenceExterne'?: ReferenceExterne;
    /**
     * 
     * @type {Array<SignDossiersPostRequestDocumentsInner>}
     * @memberof SignDossiersPostRequest
     */
    'documents'?: Array<SignDossiersPostRequestDocumentsInner>;
    /**
     * 
     * @type {DossierReadTimestampableBlameambleGroup}
     * @memberof SignDossiersPostRequest
     */
    'dossier'?: DossierReadTimestampableBlameambleGroup;
}
/**
 * 
 * @export
 * @interface SignDossiersPostRequestDocumentsInner
 */
export interface SignDossiersPostRequestDocumentsInner {
    /**
     * 
     * @type {Nom}
     * @memberof SignDossiersPostRequestDocumentsInner
     */
    'nom'?: Nom;
    /**
     * 
     * @type {string}
     * @memberof SignDossiersPostRequestDocumentsInner
     */
    'base64'?: string;
}
/**
 * 
 * @export
 * @interface SignataireJsonldReadCollectionTimestampableBlameambleGroup
 */
export interface SignataireJsonldReadCollectionTimestampableBlameambleGroup {
    /**
     * 
     * @type {DemandeurJsonldReadTimestampableBlameambleGroupContext}
     * @memberof SignataireJsonldReadCollectionTimestampableBlameambleGroup
     */
    '@context'?: DemandeurJsonldReadTimestampableBlameambleGroupContext;
    /**
     * 
     * @type {string}
     * @memberof SignataireJsonldReadCollectionTimestampableBlameambleGroup
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SignataireJsonldReadCollectionTimestampableBlameambleGroup
     */
    '@type'?: string;
    /**
     * id
     * @type {number}
     * @memberof SignataireJsonldReadCollectionTimestampableBlameambleGroup
     */
    'id'?: number;
    /**
     * nom
     * @type {string}
     * @memberof SignataireJsonldReadCollectionTimestampableBlameambleGroup
     */
    'nom': string;
    /**
     * prenom
     * @type {string}
     * @memberof SignataireJsonldReadCollectionTimestampableBlameambleGroup
     */
    'prenom': string;
    /**
     * email
     * @type {string}
     * @memberof SignataireJsonldReadCollectionTimestampableBlameambleGroup
     */
    'email': string;
    /**
     * mobile
     * @type {string}
     * @memberof SignataireJsonldReadCollectionTimestampableBlameambleGroup
     */
    'mobile': string;
    /**
     * signature
     * @type {boolean}
     * @memberof SignataireJsonldReadCollectionTimestampableBlameambleGroup
     */
    'signature'?: boolean;
    /**
     * lien
     * @type {string}
     * @memberof SignataireJsonldReadCollectionTimestampableBlameambleGroup
     */
    'lien'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SignataireJsonldReadCollectionTimestampableBlameambleGroup
     */
    'refus'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof SignataireJsonldReadCollectionTimestampableBlameambleGroup
     */
    'commentaire'?: string | null;
    /**
     * Get the value of idContract
     * @type {string}
     * @memberof SignataireJsonldReadCollectionTimestampableBlameambleGroup
     */
    'idContract'?: string | null;
    /**
     * 
     * @type {Array<RelanceJsonldReadCollectionTimestampableBlameambleGroup>}
     * @memberof SignataireJsonldReadCollectionTimestampableBlameambleGroup
     */
    'relances'?: Array<RelanceJsonldReadCollectionTimestampableBlameambleGroup>;
    /**
     * 
     * @type {string}
     * @memberof SignataireJsonldReadCollectionTimestampableBlameambleGroup
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SignataireJsonldReadCollectionTimestampableBlameambleGroup
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SignataireJsonldReadCollectionTimestampableBlameambleGroup
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof SignataireJsonldReadCollectionTimestampableBlameambleGroup
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface SignataireJsonldReadTimestampableBlameambleGroup
 */
export interface SignataireJsonldReadTimestampableBlameambleGroup {
    /**
     * 
     * @type {DemandeurJsonldReadTimestampableBlameambleGroupContext}
     * @memberof SignataireJsonldReadTimestampableBlameambleGroup
     */
    '@context'?: DemandeurJsonldReadTimestampableBlameambleGroupContext;
    /**
     * 
     * @type {string}
     * @memberof SignataireJsonldReadTimestampableBlameambleGroup
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SignataireJsonldReadTimestampableBlameambleGroup
     */
    '@type'?: string;
    /**
     * id
     * @type {number}
     * @memberof SignataireJsonldReadTimestampableBlameambleGroup
     */
    'id'?: number;
    /**
     * nom
     * @type {string}
     * @memberof SignataireJsonldReadTimestampableBlameambleGroup
     */
    'nom': string;
    /**
     * prenom
     * @type {string}
     * @memberof SignataireJsonldReadTimestampableBlameambleGroup
     */
    'prenom': string;
    /**
     * email
     * @type {string}
     * @memberof SignataireJsonldReadTimestampableBlameambleGroup
     */
    'email': string;
    /**
     * mobile
     * @type {string}
     * @memberof SignataireJsonldReadTimestampableBlameambleGroup
     */
    'mobile': string;
    /**
     * signature
     * @type {boolean}
     * @memberof SignataireJsonldReadTimestampableBlameambleGroup
     */
    'signature'?: boolean;
    /**
     * lien
     * @type {string}
     * @memberof SignataireJsonldReadTimestampableBlameambleGroup
     */
    'lien'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SignataireJsonldReadTimestampableBlameambleGroup
     */
    'refus'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof SignataireJsonldReadTimestampableBlameambleGroup
     */
    'commentaire'?: string | null;
    /**
     * Get the value of idContract
     * @type {string}
     * @memberof SignataireJsonldReadTimestampableBlameambleGroup
     */
    'idContract'?: string | null;
    /**
     * 
     * @type {Array<RelanceJsonldReadTimestampableBlameambleGroup>}
     * @memberof SignataireJsonldReadTimestampableBlameambleGroup
     */
    'relances'?: Array<RelanceJsonldReadTimestampableBlameambleGroup>;
    /**
     * 
     * @type {string}
     * @memberof SignataireJsonldReadTimestampableBlameambleGroup
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SignataireJsonldReadTimestampableBlameambleGroup
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SignataireJsonldReadTimestampableBlameambleGroup
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof SignataireJsonldReadTimestampableBlameambleGroup
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface SignataireJsonldWrite
 */
export interface SignataireJsonldWrite {
    /**
     * 
     * @type {DemandeurJsonldReadTimestampableBlameambleGroupContext}
     * @memberof SignataireJsonldWrite
     */
    '@context'?: DemandeurJsonldReadTimestampableBlameambleGroupContext;
    /**
     * 
     * @type {string}
     * @memberof SignataireJsonldWrite
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SignataireJsonldWrite
     */
    '@type'?: string;
    /**
     * nom
     * @type {string}
     * @memberof SignataireJsonldWrite
     */
    'nom': string;
    /**
     * prenom
     * @type {string}
     * @memberof SignataireJsonldWrite
     */
    'prenom': string;
    /**
     * email
     * @type {string}
     * @memberof SignataireJsonldWrite
     */
    'email': string;
    /**
     * mobile
     * @type {string}
     * @memberof SignataireJsonldWrite
     */
    'mobile': string;
}
/**
 * 
 * @export
 * @interface SignataireReadCollectionTimestampableBlameambleGroup
 */
export interface SignataireReadCollectionTimestampableBlameambleGroup {
    /**
     * id
     * @type {number}
     * @memberof SignataireReadCollectionTimestampableBlameambleGroup
     */
    'id'?: number;
    /**
     * nom
     * @type {string}
     * @memberof SignataireReadCollectionTimestampableBlameambleGroup
     */
    'nom': string;
    /**
     * prenom
     * @type {string}
     * @memberof SignataireReadCollectionTimestampableBlameambleGroup
     */
    'prenom': string;
    /**
     * email
     * @type {string}
     * @memberof SignataireReadCollectionTimestampableBlameambleGroup
     */
    'email': string;
    /**
     * mobile
     * @type {string}
     * @memberof SignataireReadCollectionTimestampableBlameambleGroup
     */
    'mobile': string;
    /**
     * signature
     * @type {boolean}
     * @memberof SignataireReadCollectionTimestampableBlameambleGroup
     */
    'signature'?: boolean;
    /**
     * lien
     * @type {string}
     * @memberof SignataireReadCollectionTimestampableBlameambleGroup
     */
    'lien'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SignataireReadCollectionTimestampableBlameambleGroup
     */
    'refus'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof SignataireReadCollectionTimestampableBlameambleGroup
     */
    'commentaire'?: string | null;
    /**
     * Get the value of idContract
     * @type {string}
     * @memberof SignataireReadCollectionTimestampableBlameambleGroup
     */
    'idContract'?: string | null;
    /**
     * 
     * @type {Array<RelanceReadCollectionTimestampableBlameambleGroup>}
     * @memberof SignataireReadCollectionTimestampableBlameambleGroup
     */
    'relances'?: Array<RelanceReadCollectionTimestampableBlameambleGroup>;
    /**
     * 
     * @type {string}
     * @memberof SignataireReadCollectionTimestampableBlameambleGroup
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SignataireReadCollectionTimestampableBlameambleGroup
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SignataireReadCollectionTimestampableBlameambleGroup
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof SignataireReadCollectionTimestampableBlameambleGroup
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface SignataireReadTimestampableBlameambleGroup
 */
export interface SignataireReadTimestampableBlameambleGroup {
    /**
     * id
     * @type {number}
     * @memberof SignataireReadTimestampableBlameambleGroup
     */
    'id'?: number;
    /**
     * nom
     * @type {string}
     * @memberof SignataireReadTimestampableBlameambleGroup
     */
    'nom': string;
    /**
     * prenom
     * @type {string}
     * @memberof SignataireReadTimestampableBlameambleGroup
     */
    'prenom': string;
    /**
     * email
     * @type {string}
     * @memberof SignataireReadTimestampableBlameambleGroup
     */
    'email': string;
    /**
     * mobile
     * @type {string}
     * @memberof SignataireReadTimestampableBlameambleGroup
     */
    'mobile': string;
    /**
     * signature
     * @type {boolean}
     * @memberof SignataireReadTimestampableBlameambleGroup
     */
    'signature'?: boolean;
    /**
     * lien
     * @type {string}
     * @memberof SignataireReadTimestampableBlameambleGroup
     */
    'lien'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SignataireReadTimestampableBlameambleGroup
     */
    'refus'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof SignataireReadTimestampableBlameambleGroup
     */
    'commentaire'?: string | null;
    /**
     * Get the value of idContract
     * @type {string}
     * @memberof SignataireReadTimestampableBlameambleGroup
     */
    'idContract'?: string | null;
    /**
     * 
     * @type {Array<RelanceReadTimestampableBlameambleGroup>}
     * @memberof SignataireReadTimestampableBlameambleGroup
     */
    'relances'?: Array<RelanceReadTimestampableBlameambleGroup>;
    /**
     * 
     * @type {string}
     * @memberof SignataireReadTimestampableBlameambleGroup
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SignataireReadTimestampableBlameambleGroup
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SignataireReadTimestampableBlameambleGroup
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof SignataireReadTimestampableBlameambleGroup
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface SignataireWrite
 */
export interface SignataireWrite {
    /**
     * nom
     * @type {string}
     * @memberof SignataireWrite
     */
    'nom': string;
    /**
     * prenom
     * @type {string}
     * @memberof SignataireWrite
     */
    'prenom': string;
    /**
     * email
     * @type {string}
     * @memberof SignataireWrite
     */
    'email': string;
    /**
     * mobile
     * @type {string}
     * @memberof SignataireWrite
     */
    'mobile': string;
}

/**
 * DemandeurApi - axios parameter creator
 * @export
 */
export const DemandeurApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Permet de remonter les demandeurs
         * @summary Récupération des demandeurs
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDemandeurCollection: async (page?: number, itemsPerPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sign/demandeurs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Permet de remonter un demandeur
         * @summary Remonté d\'un demandeur
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDemandeurItem: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDemandeurItem', 'id', id)
            const localVarPath = `/sign/demandeurs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Permet de modifier les demandeurs
         * @summary Modification demandeur
         * @param {string} id Resource identifier
         * @param {DemandeurJsonldWrite} demandeurJsonldWrite The updated Demandeur resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putDemandeurItem: async (id: string, demandeurJsonldWrite: DemandeurJsonldWrite, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putDemandeurItem', 'id', id)
            // verify required parameter 'demandeurJsonldWrite' is not null or undefined
            assertParamExists('putDemandeurItem', 'demandeurJsonldWrite', demandeurJsonldWrite)
            const localVarPath = `/sign/demandeurs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(demandeurJsonldWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DemandeurApi - functional programming interface
 * @export
 */
export const DemandeurApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DemandeurApiAxiosParamCreator(configuration)
    return {
        /**
         * Permet de remonter les demandeurs
         * @summary Récupération des demandeurs
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDemandeurCollection(page?: number, itemsPerPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDemandeurCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDemandeurCollection(page, itemsPerPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Permet de remonter un demandeur
         * @summary Remonté d\'un demandeur
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDemandeurItem(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DemandeurJsonldReadReadCollection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDemandeurItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Permet de modifier les demandeurs
         * @summary Modification demandeur
         * @param {string} id Resource identifier
         * @param {DemandeurJsonldWrite} demandeurJsonldWrite The updated Demandeur resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putDemandeurItem(id: string, demandeurJsonldWrite: DemandeurJsonldWrite, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DemandeurJsonldReadReadCollection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putDemandeurItem(id, demandeurJsonldWrite, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DemandeurApi - factory interface
 * @export
 */
export const DemandeurApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DemandeurApiFp(configuration)
    return {
        /**
         * Permet de remonter les demandeurs
         * @summary Récupération des demandeurs
         * @param {DemandeurApiGetDemandeurCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDemandeurCollection(requestParameters: DemandeurApiGetDemandeurCollectionRequest = {}, options?: AxiosRequestConfig): AxiosPromise<GetDemandeurCollection200Response> {
            return localVarFp.getDemandeurCollection(requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Permet de remonter un demandeur
         * @summary Remonté d\'un demandeur
         * @param {DemandeurApiGetDemandeurItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDemandeurItem(requestParameters: DemandeurApiGetDemandeurItemRequest, options?: AxiosRequestConfig): AxiosPromise<DemandeurJsonldReadReadCollection> {
            return localVarFp.getDemandeurItem(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Permet de modifier les demandeurs
         * @summary Modification demandeur
         * @param {DemandeurApiPutDemandeurItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putDemandeurItem(requestParameters: DemandeurApiPutDemandeurItemRequest, options?: AxiosRequestConfig): AxiosPromise<DemandeurJsonldReadReadCollection> {
            return localVarFp.putDemandeurItem(requestParameters.id, requestParameters.demandeurJsonldWrite, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getDemandeurCollection operation in DemandeurApi.
 * @export
 * @interface DemandeurApiGetDemandeurCollectionRequest
 */
export interface DemandeurApiGetDemandeurCollectionRequest {
    /**
     * The collection page number
     * @type {number}
     * @memberof DemandeurApiGetDemandeurCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof DemandeurApiGetDemandeurCollection
     */
    readonly itemsPerPage?: number
}

/**
 * Request parameters for getDemandeurItem operation in DemandeurApi.
 * @export
 * @interface DemandeurApiGetDemandeurItemRequest
 */
export interface DemandeurApiGetDemandeurItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof DemandeurApiGetDemandeurItem
     */
    readonly id: string
}

/**
 * Request parameters for putDemandeurItem operation in DemandeurApi.
 * @export
 * @interface DemandeurApiPutDemandeurItemRequest
 */
export interface DemandeurApiPutDemandeurItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof DemandeurApiPutDemandeurItem
     */
    readonly id: string

    /**
     * The updated Demandeur resource
     * @type {DemandeurJsonldWrite}
     * @memberof DemandeurApiPutDemandeurItem
     */
    readonly demandeurJsonldWrite: DemandeurJsonldWrite
}

/**
 * DemandeurApi - object-oriented interface
 * @export
 * @class DemandeurApi
 * @extends {BaseAPI}
 */
export class DemandeurApi extends BaseAPI {
    /**
     * Permet de remonter les demandeurs
     * @summary Récupération des demandeurs
     * @param {DemandeurApiGetDemandeurCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemandeurApi
     */
    public getDemandeurCollection(requestParameters: DemandeurApiGetDemandeurCollectionRequest = {}, options?: AxiosRequestConfig) {
        return DemandeurApiFp(this.configuration).getDemandeurCollection(requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Permet de remonter un demandeur
     * @summary Remonté d\'un demandeur
     * @param {DemandeurApiGetDemandeurItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemandeurApi
     */
    public getDemandeurItem(requestParameters: DemandeurApiGetDemandeurItemRequest, options?: AxiosRequestConfig) {
        return DemandeurApiFp(this.configuration).getDemandeurItem(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Permet de modifier les demandeurs
     * @summary Modification demandeur
     * @param {DemandeurApiPutDemandeurItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemandeurApi
     */
    public putDemandeurItem(requestParameters: DemandeurApiPutDemandeurItemRequest, options?: AxiosRequestConfig) {
        return DemandeurApiFp(this.configuration).putDemandeurItem(requestParameters.id, requestParameters.demandeurJsonldWrite, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DossierApi - axios parameter creator
 * @export
 */
export const DossierApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Permet de remonter les dossiers et leurs contenus
         * @summary Récupération des dossiers
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {boolean} [signatairesSignature] 
         * @param {string} [referenceExterne] 
         * @param {Array<string>} [referenceExterne2] 
         * @param {string} [sujet] 
         * @param {string} [prescripteur] 
         * @param {string} [prescripteurEmail] 
         * @param {Array<string>} [prescripteurEmail2] 
         * @param {string} [demandeurNom] 
         * @param {string} [demandeurUserIdentifier] 
         * @param {Array<string>} [demandeurUserIdentifier2] 
         * @param {Array<string>} [groups] 
         * @param {string} [recherche] 
         * @param {boolean} [termine] 
         * @param {boolean} [refuse] 
         * @param {'asc' | 'desc'} [orderId] 
         * @param {'asc' | 'desc'} [orderSujet] 
         * @param {'asc' | 'desc'} [orderPrescripteur] 
         * @param {'asc' | 'desc'} [orderDemandeurNom] 
         * @param {'asc' | 'desc'} [orderCreatedAt] 
         * @param {'asc' | 'desc'} [orderUpdatedAt] 
         * @param {string} [createdAtBefore] 
         * @param {string} [createdAtStrictlyBefore] 
         * @param {string} [createdAtAfter] 
         * @param {string} [createdAtStrictlyAfter] 
         * @param {string} [updatedAtBefore] 
         * @param {string} [updatedAtStrictlyBefore] 
         * @param {string} [updatedAtAfter] 
         * @param {string} [updatedAtStrictlyAfter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDossierCollection: async (page?: number, itemsPerPage?: number, signatairesSignature?: boolean, referenceExterne?: string, referenceExterne2?: Array<string>, sujet?: string, prescripteur?: string, prescripteurEmail?: string, prescripteurEmail2?: Array<string>, demandeurNom?: string, demandeurUserIdentifier?: string, demandeurUserIdentifier2?: Array<string>, groups?: Array<string>, recherche?: string, termine?: boolean, refuse?: boolean, orderId?: 'asc' | 'desc', orderSujet?: 'asc' | 'desc', orderPrescripteur?: 'asc' | 'desc', orderDemandeurNom?: 'asc' | 'desc', orderCreatedAt?: 'asc' | 'desc', orderUpdatedAt?: 'asc' | 'desc', createdAtBefore?: string, createdAtStrictlyBefore?: string, createdAtAfter?: string, createdAtStrictlyAfter?: string, updatedAtBefore?: string, updatedAtStrictlyBefore?: string, updatedAtAfter?: string, updatedAtStrictlyAfter?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sign/dossiers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (signatairesSignature !== undefined) {
                localVarQueryParameter['signataires.signature'] = signatairesSignature;
            }

            if (referenceExterne !== undefined) {
                localVarQueryParameter['referenceExterne'] = referenceExterne;
            }

            if (referenceExterne2) {
                localVarQueryParameter['referenceExterne[]'] = referenceExterne2;
            }

            if (sujet !== undefined) {
                localVarQueryParameter['sujet'] = sujet;
            }

            if (prescripteur !== undefined) {
                localVarQueryParameter['prescripteur'] = prescripteur;
            }

            if (prescripteurEmail !== undefined) {
                localVarQueryParameter['prescripteurEmail'] = prescripteurEmail;
            }

            if (prescripteurEmail2) {
                localVarQueryParameter['prescripteurEmail[]'] = prescripteurEmail2;
            }

            if (demandeurNom !== undefined) {
                localVarQueryParameter['demandeur.nom'] = demandeurNom;
            }

            if (demandeurUserIdentifier !== undefined) {
                localVarQueryParameter['demandeur.userIdentifier'] = demandeurUserIdentifier;
            }

            if (demandeurUserIdentifier2) {
                localVarQueryParameter['demandeur.userIdentifier[]'] = demandeurUserIdentifier2;
            }

            if (groups) {
                localVarQueryParameter['groups[]'] = groups;
            }

            if (recherche !== undefined) {
                localVarQueryParameter['recherche'] = recherche;
            }

            if (termine !== undefined) {
                localVarQueryParameter['termine'] = termine;
            }

            if (refuse !== undefined) {
                localVarQueryParameter['refuse'] = refuse;
            }

            if (orderId !== undefined) {
                localVarQueryParameter['order[id]'] = orderId;
            }

            if (orderSujet !== undefined) {
                localVarQueryParameter['order[sujet]'] = orderSujet;
            }

            if (orderPrescripteur !== undefined) {
                localVarQueryParameter['order[prescripteur]'] = orderPrescripteur;
            }

            if (orderDemandeurNom !== undefined) {
                localVarQueryParameter['order[demandeur.nom]'] = orderDemandeurNom;
            }

            if (orderCreatedAt !== undefined) {
                localVarQueryParameter['order[createdAt]'] = orderCreatedAt;
            }

            if (orderUpdatedAt !== undefined) {
                localVarQueryParameter['order[updatedAt]'] = orderUpdatedAt;
            }

            if (createdAtBefore !== undefined) {
                localVarQueryParameter['createdAt[before]'] = createdAtBefore;
            }

            if (createdAtStrictlyBefore !== undefined) {
                localVarQueryParameter['createdAt[strictly_before]'] = createdAtStrictlyBefore;
            }

            if (createdAtAfter !== undefined) {
                localVarQueryParameter['createdAt[after]'] = createdAtAfter;
            }

            if (createdAtStrictlyAfter !== undefined) {
                localVarQueryParameter['createdAt[strictly_after]'] = createdAtStrictlyAfter;
            }

            if (updatedAtBefore !== undefined) {
                localVarQueryParameter['updatedAt[before]'] = updatedAtBefore;
            }

            if (updatedAtStrictlyBefore !== undefined) {
                localVarQueryParameter['updatedAt[strictly_before]'] = updatedAtStrictlyBefore;
            }

            if (updatedAtAfter !== undefined) {
                localVarQueryParameter['updatedAt[after]'] = updatedAtAfter;
            }

            if (updatedAtStrictlyAfter !== undefined) {
                localVarQueryParameter['updatedAt[strictly_after]'] = updatedAtStrictlyAfter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Permet de remonter un dossiers et ses contenus
         * @summary Récupération de dossier
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDossierItem: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDossierItem', 'id', id)
            const localVarPath = `/sign/dossiers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Créer un dossier
         * @summary Création de dossiers
         * @param {DossierJsonldWrite} dossierJsonldWrite The new Dossier resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDossierCollection: async (dossierJsonldWrite: DossierJsonldWrite, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dossierJsonldWrite' is not null or undefined
            assertParamExists('postDossierCollection', 'dossierJsonldWrite', dossierJsonldWrite)
            const localVarPath = `/sign/dossiers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dossierJsonldWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Permet de relancer un dossiers
         * @summary Relance d\'un dossier de signature
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        raiseDossierItem: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('raiseDossierItem', 'id', id)
            const localVarPath = `/sign/dossiers/{id}/raise`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Permet de remonter les dossiers et leurs contenus
         * @summary Récupération des dossiers
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {boolean} [signatairesSignature] 
         * @param {string} [referenceExterne] 
         * @param {Array<string>} [referenceExterne2] 
         * @param {string} [sujet] 
         * @param {string} [prescripteur] 
         * @param {string} [prescripteurEmail] 
         * @param {Array<string>} [prescripteurEmail2] 
         * @param {string} [demandeurNom] 
         * @param {string} [demandeurUserIdentifier] 
         * @param {Array<string>} [demandeurUserIdentifier2] 
         * @param {Array<string>} [groups] 
         * @param {string} [recherche] 
         * @param {boolean} [termine] 
         * @param {boolean} [refuse] 
         * @param {'asc' | 'desc'} [orderId] 
         * @param {'asc' | 'desc'} [orderSujet] 
         * @param {'asc' | 'desc'} [orderPrescripteur] 
         * @param {'asc' | 'desc'} [orderDemandeurNom] 
         * @param {'asc' | 'desc'} [orderCreatedAt] 
         * @param {'asc' | 'desc'} [orderUpdatedAt] 
         * @param {string} [createdAtBefore] 
         * @param {string} [createdAtStrictlyBefore] 
         * @param {string} [createdAtAfter] 
         * @param {string} [createdAtStrictlyAfter] 
         * @param {string} [updatedAtBefore] 
         * @param {string} [updatedAtStrictlyBefore] 
         * @param {string} [updatedAtAfter] 
         * @param {string} [updatedAtStrictlyAfter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        toRaiseDossierCollection: async (page?: number, itemsPerPage?: number, signatairesSignature?: boolean, referenceExterne?: string, referenceExterne2?: Array<string>, sujet?: string, prescripteur?: string, prescripteurEmail?: string, prescripteurEmail2?: Array<string>, demandeurNom?: string, demandeurUserIdentifier?: string, demandeurUserIdentifier2?: Array<string>, groups?: Array<string>, recherche?: string, termine?: boolean, refuse?: boolean, orderId?: 'asc' | 'desc', orderSujet?: 'asc' | 'desc', orderPrescripteur?: 'asc' | 'desc', orderDemandeurNom?: 'asc' | 'desc', orderCreatedAt?: 'asc' | 'desc', orderUpdatedAt?: 'asc' | 'desc', createdAtBefore?: string, createdAtStrictlyBefore?: string, createdAtAfter?: string, createdAtStrictlyAfter?: string, updatedAtBefore?: string, updatedAtStrictlyBefore?: string, updatedAtAfter?: string, updatedAtStrictlyAfter?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sign/dossiers/to_raise`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (signatairesSignature !== undefined) {
                localVarQueryParameter['signataires.signature'] = signatairesSignature;
            }

            if (referenceExterne !== undefined) {
                localVarQueryParameter['referenceExterne'] = referenceExterne;
            }

            if (referenceExterne2) {
                localVarQueryParameter['referenceExterne[]'] = referenceExterne2;
            }

            if (sujet !== undefined) {
                localVarQueryParameter['sujet'] = sujet;
            }

            if (prescripteur !== undefined) {
                localVarQueryParameter['prescripteur'] = prescripteur;
            }

            if (prescripteurEmail !== undefined) {
                localVarQueryParameter['prescripteurEmail'] = prescripteurEmail;
            }

            if (prescripteurEmail2) {
                localVarQueryParameter['prescripteurEmail[]'] = prescripteurEmail2;
            }

            if (demandeurNom !== undefined) {
                localVarQueryParameter['demandeur.nom'] = demandeurNom;
            }

            if (demandeurUserIdentifier !== undefined) {
                localVarQueryParameter['demandeur.userIdentifier'] = demandeurUserIdentifier;
            }

            if (demandeurUserIdentifier2) {
                localVarQueryParameter['demandeur.userIdentifier[]'] = demandeurUserIdentifier2;
            }

            if (groups) {
                localVarQueryParameter['groups[]'] = groups;
            }

            if (recherche !== undefined) {
                localVarQueryParameter['recherche'] = recherche;
            }

            if (termine !== undefined) {
                localVarQueryParameter['termine'] = termine;
            }

            if (refuse !== undefined) {
                localVarQueryParameter['refuse'] = refuse;
            }

            if (orderId !== undefined) {
                localVarQueryParameter['order[id]'] = orderId;
            }

            if (orderSujet !== undefined) {
                localVarQueryParameter['order[sujet]'] = orderSujet;
            }

            if (orderPrescripteur !== undefined) {
                localVarQueryParameter['order[prescripteur]'] = orderPrescripteur;
            }

            if (orderDemandeurNom !== undefined) {
                localVarQueryParameter['order[demandeur.nom]'] = orderDemandeurNom;
            }

            if (orderCreatedAt !== undefined) {
                localVarQueryParameter['order[createdAt]'] = orderCreatedAt;
            }

            if (orderUpdatedAt !== undefined) {
                localVarQueryParameter['order[updatedAt]'] = orderUpdatedAt;
            }

            if (createdAtBefore !== undefined) {
                localVarQueryParameter['createdAt[before]'] = createdAtBefore;
            }

            if (createdAtStrictlyBefore !== undefined) {
                localVarQueryParameter['createdAt[strictly_before]'] = createdAtStrictlyBefore;
            }

            if (createdAtAfter !== undefined) {
                localVarQueryParameter['createdAt[after]'] = createdAtAfter;
            }

            if (createdAtStrictlyAfter !== undefined) {
                localVarQueryParameter['createdAt[strictly_after]'] = createdAtStrictlyAfter;
            }

            if (updatedAtBefore !== undefined) {
                localVarQueryParameter['updatedAt[before]'] = updatedAtBefore;
            }

            if (updatedAtStrictlyBefore !== undefined) {
                localVarQueryParameter['updatedAt[strictly_before]'] = updatedAtStrictlyBefore;
            }

            if (updatedAtAfter !== undefined) {
                localVarQueryParameter['updatedAt[after]'] = updatedAtAfter;
            }

            if (updatedAtStrictlyAfter !== undefined) {
                localVarQueryParameter['updatedAt[strictly_after]'] = updatedAtStrictlyAfter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DossierApi - functional programming interface
 * @export
 */
export const DossierApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DossierApiAxiosParamCreator(configuration)
    return {
        /**
         * Permet de remonter les dossiers et leurs contenus
         * @summary Récupération des dossiers
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {boolean} [signatairesSignature] 
         * @param {string} [referenceExterne] 
         * @param {Array<string>} [referenceExterne2] 
         * @param {string} [sujet] 
         * @param {string} [prescripteur] 
         * @param {string} [prescripteurEmail] 
         * @param {Array<string>} [prescripteurEmail2] 
         * @param {string} [demandeurNom] 
         * @param {string} [demandeurUserIdentifier] 
         * @param {Array<string>} [demandeurUserIdentifier2] 
         * @param {Array<string>} [groups] 
         * @param {string} [recherche] 
         * @param {boolean} [termine] 
         * @param {boolean} [refuse] 
         * @param {'asc' | 'desc'} [orderId] 
         * @param {'asc' | 'desc'} [orderSujet] 
         * @param {'asc' | 'desc'} [orderPrescripteur] 
         * @param {'asc' | 'desc'} [orderDemandeurNom] 
         * @param {'asc' | 'desc'} [orderCreatedAt] 
         * @param {'asc' | 'desc'} [orderUpdatedAt] 
         * @param {string} [createdAtBefore] 
         * @param {string} [createdAtStrictlyBefore] 
         * @param {string} [createdAtAfter] 
         * @param {string} [createdAtStrictlyAfter] 
         * @param {string} [updatedAtBefore] 
         * @param {string} [updatedAtStrictlyBefore] 
         * @param {string} [updatedAtAfter] 
         * @param {string} [updatedAtStrictlyAfter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDossierCollection(page?: number, itemsPerPage?: number, signatairesSignature?: boolean, referenceExterne?: string, referenceExterne2?: Array<string>, sujet?: string, prescripteur?: string, prescripteurEmail?: string, prescripteurEmail2?: Array<string>, demandeurNom?: string, demandeurUserIdentifier?: string, demandeurUserIdentifier2?: Array<string>, groups?: Array<string>, recherche?: string, termine?: boolean, refuse?: boolean, orderId?: 'asc' | 'desc', orderSujet?: 'asc' | 'desc', orderPrescripteur?: 'asc' | 'desc', orderDemandeurNom?: 'asc' | 'desc', orderCreatedAt?: 'asc' | 'desc', orderUpdatedAt?: 'asc' | 'desc', createdAtBefore?: string, createdAtStrictlyBefore?: string, createdAtAfter?: string, createdAtStrictlyAfter?: string, updatedAtBefore?: string, updatedAtStrictlyBefore?: string, updatedAtAfter?: string, updatedAtStrictlyAfter?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDossierCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDossierCollection(page, itemsPerPage, signatairesSignature, referenceExterne, referenceExterne2, sujet, prescripteur, prescripteurEmail, prescripteurEmail2, demandeurNom, demandeurUserIdentifier, demandeurUserIdentifier2, groups, recherche, termine, refuse, orderId, orderSujet, orderPrescripteur, orderDemandeurNom, orderCreatedAt, orderUpdatedAt, createdAtBefore, createdAtStrictlyBefore, createdAtAfter, createdAtStrictlyAfter, updatedAtBefore, updatedAtStrictlyBefore, updatedAtAfter, updatedAtStrictlyAfter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Permet de remonter un dossiers et ses contenus
         * @summary Récupération de dossier
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDossierItem(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DossierJsonldReadTimestampableBlameambleGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDossierItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Créer un dossier
         * @summary Création de dossiers
         * @param {DossierJsonldWrite} dossierJsonldWrite The new Dossier resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postDossierCollection(dossierJsonldWrite: DossierJsonldWrite, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DossierJsonldReadTimestampableBlameambleGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postDossierCollection(dossierJsonldWrite, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Permet de relancer un dossiers
         * @summary Relance d\'un dossier de signature
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async raiseDossierItem(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RaiseDossierItem200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.raiseDossierItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Permet de remonter les dossiers et leurs contenus
         * @summary Récupération des dossiers
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {boolean} [signatairesSignature] 
         * @param {string} [referenceExterne] 
         * @param {Array<string>} [referenceExterne2] 
         * @param {string} [sujet] 
         * @param {string} [prescripteur] 
         * @param {string} [prescripteurEmail] 
         * @param {Array<string>} [prescripteurEmail2] 
         * @param {string} [demandeurNom] 
         * @param {string} [demandeurUserIdentifier] 
         * @param {Array<string>} [demandeurUserIdentifier2] 
         * @param {Array<string>} [groups] 
         * @param {string} [recherche] 
         * @param {boolean} [termine] 
         * @param {boolean} [refuse] 
         * @param {'asc' | 'desc'} [orderId] 
         * @param {'asc' | 'desc'} [orderSujet] 
         * @param {'asc' | 'desc'} [orderPrescripteur] 
         * @param {'asc' | 'desc'} [orderDemandeurNom] 
         * @param {'asc' | 'desc'} [orderCreatedAt] 
         * @param {'asc' | 'desc'} [orderUpdatedAt] 
         * @param {string} [createdAtBefore] 
         * @param {string} [createdAtStrictlyBefore] 
         * @param {string} [createdAtAfter] 
         * @param {string} [createdAtStrictlyAfter] 
         * @param {string} [updatedAtBefore] 
         * @param {string} [updatedAtStrictlyBefore] 
         * @param {string} [updatedAtAfter] 
         * @param {string} [updatedAtStrictlyAfter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async toRaiseDossierCollection(page?: number, itemsPerPage?: number, signatairesSignature?: boolean, referenceExterne?: string, referenceExterne2?: Array<string>, sujet?: string, prescripteur?: string, prescripteurEmail?: string, prescripteurEmail2?: Array<string>, demandeurNom?: string, demandeurUserIdentifier?: string, demandeurUserIdentifier2?: Array<string>, groups?: Array<string>, recherche?: string, termine?: boolean, refuse?: boolean, orderId?: 'asc' | 'desc', orderSujet?: 'asc' | 'desc', orderPrescripteur?: 'asc' | 'desc', orderDemandeurNom?: 'asc' | 'desc', orderCreatedAt?: 'asc' | 'desc', orderUpdatedAt?: 'asc' | 'desc', createdAtBefore?: string, createdAtStrictlyBefore?: string, createdAtAfter?: string, createdAtStrictlyAfter?: string, updatedAtBefore?: string, updatedAtStrictlyBefore?: string, updatedAtAfter?: string, updatedAtStrictlyAfter?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDossierCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.toRaiseDossierCollection(page, itemsPerPage, signatairesSignature, referenceExterne, referenceExterne2, sujet, prescripteur, prescripteurEmail, prescripteurEmail2, demandeurNom, demandeurUserIdentifier, demandeurUserIdentifier2, groups, recherche, termine, refuse, orderId, orderSujet, orderPrescripteur, orderDemandeurNom, orderCreatedAt, orderUpdatedAt, createdAtBefore, createdAtStrictlyBefore, createdAtAfter, createdAtStrictlyAfter, updatedAtBefore, updatedAtStrictlyBefore, updatedAtAfter, updatedAtStrictlyAfter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DossierApi - factory interface
 * @export
 */
export const DossierApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DossierApiFp(configuration)
    return {
        /**
         * Permet de remonter les dossiers et leurs contenus
         * @summary Récupération des dossiers
         * @param {DossierApiGetDossierCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDossierCollection(requestParameters: DossierApiGetDossierCollectionRequest = {}, options?: AxiosRequestConfig): AxiosPromise<GetDossierCollection200Response> {
            return localVarFp.getDossierCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.signatairesSignature, requestParameters.referenceExterne, requestParameters.referenceExterne2, requestParameters.sujet, requestParameters.prescripteur, requestParameters.prescripteurEmail, requestParameters.prescripteurEmail2, requestParameters.demandeurNom, requestParameters.demandeurUserIdentifier, requestParameters.demandeurUserIdentifier2, requestParameters.groups, requestParameters.recherche, requestParameters.termine, requestParameters.refuse, requestParameters.orderId, requestParameters.orderSujet, requestParameters.orderPrescripteur, requestParameters.orderDemandeurNom, requestParameters.orderCreatedAt, requestParameters.orderUpdatedAt, requestParameters.createdAtBefore, requestParameters.createdAtStrictlyBefore, requestParameters.createdAtAfter, requestParameters.createdAtStrictlyAfter, requestParameters.updatedAtBefore, requestParameters.updatedAtStrictlyBefore, requestParameters.updatedAtAfter, requestParameters.updatedAtStrictlyAfter, options).then((request) => request(axios, basePath));
        },
        /**
         * Permet de remonter un dossiers et ses contenus
         * @summary Récupération de dossier
         * @param {DossierApiGetDossierItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDossierItem(requestParameters: DossierApiGetDossierItemRequest, options?: AxiosRequestConfig): AxiosPromise<DossierJsonldReadTimestampableBlameambleGroup> {
            return localVarFp.getDossierItem(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Créer un dossier
         * @summary Création de dossiers
         * @param {DossierApiPostDossierCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDossierCollection(requestParameters: DossierApiPostDossierCollectionRequest, options?: AxiosRequestConfig): AxiosPromise<DossierJsonldReadTimestampableBlameambleGroup> {
            return localVarFp.postDossierCollection(requestParameters.dossierJsonldWrite, options).then((request) => request(axios, basePath));
        },
        /**
         * Permet de relancer un dossiers
         * @summary Relance d\'un dossier de signature
         * @param {DossierApiRaiseDossierItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        raiseDossierItem(requestParameters: DossierApiRaiseDossierItemRequest, options?: AxiosRequestConfig): AxiosPromise<RaiseDossierItem200Response> {
            return localVarFp.raiseDossierItem(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Permet de remonter les dossiers et leurs contenus
         * @summary Récupération des dossiers
         * @param {DossierApiToRaiseDossierCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        toRaiseDossierCollection(requestParameters: DossierApiToRaiseDossierCollectionRequest = {}, options?: AxiosRequestConfig): AxiosPromise<GetDossierCollection200Response> {
            return localVarFp.toRaiseDossierCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.signatairesSignature, requestParameters.referenceExterne, requestParameters.referenceExterne2, requestParameters.sujet, requestParameters.prescripteur, requestParameters.prescripteurEmail, requestParameters.prescripteurEmail2, requestParameters.demandeurNom, requestParameters.demandeurUserIdentifier, requestParameters.demandeurUserIdentifier2, requestParameters.groups, requestParameters.recherche, requestParameters.termine, requestParameters.refuse, requestParameters.orderId, requestParameters.orderSujet, requestParameters.orderPrescripteur, requestParameters.orderDemandeurNom, requestParameters.orderCreatedAt, requestParameters.orderUpdatedAt, requestParameters.createdAtBefore, requestParameters.createdAtStrictlyBefore, requestParameters.createdAtAfter, requestParameters.createdAtStrictlyAfter, requestParameters.updatedAtBefore, requestParameters.updatedAtStrictlyBefore, requestParameters.updatedAtAfter, requestParameters.updatedAtStrictlyAfter, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getDossierCollection operation in DossierApi.
 * @export
 * @interface DossierApiGetDossierCollectionRequest
 */
export interface DossierApiGetDossierCollectionRequest {
    /**
     * The collection page number
     * @type {number}
     * @memberof DossierApiGetDossierCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof DossierApiGetDossierCollection
     */
    readonly itemsPerPage?: number

    /**
     * 
     * @type {boolean}
     * @memberof DossierApiGetDossierCollection
     */
    readonly signatairesSignature?: boolean

    /**
     * 
     * @type {string}
     * @memberof DossierApiGetDossierCollection
     */
    readonly referenceExterne?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof DossierApiGetDossierCollection
     */
    readonly referenceExterne2?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof DossierApiGetDossierCollection
     */
    readonly sujet?: string

    /**
     * 
     * @type {string}
     * @memberof DossierApiGetDossierCollection
     */
    readonly prescripteur?: string

    /**
     * 
     * @type {string}
     * @memberof DossierApiGetDossierCollection
     */
    readonly prescripteurEmail?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof DossierApiGetDossierCollection
     */
    readonly prescripteurEmail2?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof DossierApiGetDossierCollection
     */
    readonly demandeurNom?: string

    /**
     * 
     * @type {string}
     * @memberof DossierApiGetDossierCollection
     */
    readonly demandeurUserIdentifier?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof DossierApiGetDossierCollection
     */
    readonly demandeurUserIdentifier2?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof DossierApiGetDossierCollection
     */
    readonly groups?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof DossierApiGetDossierCollection
     */
    readonly recherche?: string

    /**
     * 
     * @type {boolean}
     * @memberof DossierApiGetDossierCollection
     */
    readonly termine?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof DossierApiGetDossierCollection
     */
    readonly refuse?: boolean

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof DossierApiGetDossierCollection
     */
    readonly orderId?: 'asc' | 'desc'

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof DossierApiGetDossierCollection
     */
    readonly orderSujet?: 'asc' | 'desc'

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof DossierApiGetDossierCollection
     */
    readonly orderPrescripteur?: 'asc' | 'desc'

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof DossierApiGetDossierCollection
     */
    readonly orderDemandeurNom?: 'asc' | 'desc'

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof DossierApiGetDossierCollection
     */
    readonly orderCreatedAt?: 'asc' | 'desc'

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof DossierApiGetDossierCollection
     */
    readonly orderUpdatedAt?: 'asc' | 'desc'

    /**
     * 
     * @type {string}
     * @memberof DossierApiGetDossierCollection
     */
    readonly createdAtBefore?: string

    /**
     * 
     * @type {string}
     * @memberof DossierApiGetDossierCollection
     */
    readonly createdAtStrictlyBefore?: string

    /**
     * 
     * @type {string}
     * @memberof DossierApiGetDossierCollection
     */
    readonly createdAtAfter?: string

    /**
     * 
     * @type {string}
     * @memberof DossierApiGetDossierCollection
     */
    readonly createdAtStrictlyAfter?: string

    /**
     * 
     * @type {string}
     * @memberof DossierApiGetDossierCollection
     */
    readonly updatedAtBefore?: string

    /**
     * 
     * @type {string}
     * @memberof DossierApiGetDossierCollection
     */
    readonly updatedAtStrictlyBefore?: string

    /**
     * 
     * @type {string}
     * @memberof DossierApiGetDossierCollection
     */
    readonly updatedAtAfter?: string

    /**
     * 
     * @type {string}
     * @memberof DossierApiGetDossierCollection
     */
    readonly updatedAtStrictlyAfter?: string
}

/**
 * Request parameters for getDossierItem operation in DossierApi.
 * @export
 * @interface DossierApiGetDossierItemRequest
 */
export interface DossierApiGetDossierItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof DossierApiGetDossierItem
     */
    readonly id: string
}

/**
 * Request parameters for postDossierCollection operation in DossierApi.
 * @export
 * @interface DossierApiPostDossierCollectionRequest
 */
export interface DossierApiPostDossierCollectionRequest {
    /**
     * The new Dossier resource
     * @type {DossierJsonldWrite}
     * @memberof DossierApiPostDossierCollection
     */
    readonly dossierJsonldWrite: DossierJsonldWrite
}

/**
 * Request parameters for raiseDossierItem operation in DossierApi.
 * @export
 * @interface DossierApiRaiseDossierItemRequest
 */
export interface DossierApiRaiseDossierItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof DossierApiRaiseDossierItem
     */
    readonly id: string
}

/**
 * Request parameters for toRaiseDossierCollection operation in DossierApi.
 * @export
 * @interface DossierApiToRaiseDossierCollectionRequest
 */
export interface DossierApiToRaiseDossierCollectionRequest {
    /**
     * The collection page number
     * @type {number}
     * @memberof DossierApiToRaiseDossierCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof DossierApiToRaiseDossierCollection
     */
    readonly itemsPerPage?: number

    /**
     * 
     * @type {boolean}
     * @memberof DossierApiToRaiseDossierCollection
     */
    readonly signatairesSignature?: boolean

    /**
     * 
     * @type {string}
     * @memberof DossierApiToRaiseDossierCollection
     */
    readonly referenceExterne?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof DossierApiToRaiseDossierCollection
     */
    readonly referenceExterne2?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof DossierApiToRaiseDossierCollection
     */
    readonly sujet?: string

    /**
     * 
     * @type {string}
     * @memberof DossierApiToRaiseDossierCollection
     */
    readonly prescripteur?: string

    /**
     * 
     * @type {string}
     * @memberof DossierApiToRaiseDossierCollection
     */
    readonly prescripteurEmail?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof DossierApiToRaiseDossierCollection
     */
    readonly prescripteurEmail2?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof DossierApiToRaiseDossierCollection
     */
    readonly demandeurNom?: string

    /**
     * 
     * @type {string}
     * @memberof DossierApiToRaiseDossierCollection
     */
    readonly demandeurUserIdentifier?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof DossierApiToRaiseDossierCollection
     */
    readonly demandeurUserIdentifier2?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof DossierApiToRaiseDossierCollection
     */
    readonly groups?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof DossierApiToRaiseDossierCollection
     */
    readonly recherche?: string

    /**
     * 
     * @type {boolean}
     * @memberof DossierApiToRaiseDossierCollection
     */
    readonly termine?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof DossierApiToRaiseDossierCollection
     */
    readonly refuse?: boolean

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof DossierApiToRaiseDossierCollection
     */
    readonly orderId?: 'asc' | 'desc'

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof DossierApiToRaiseDossierCollection
     */
    readonly orderSujet?: 'asc' | 'desc'

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof DossierApiToRaiseDossierCollection
     */
    readonly orderPrescripteur?: 'asc' | 'desc'

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof DossierApiToRaiseDossierCollection
     */
    readonly orderDemandeurNom?: 'asc' | 'desc'

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof DossierApiToRaiseDossierCollection
     */
    readonly orderCreatedAt?: 'asc' | 'desc'

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof DossierApiToRaiseDossierCollection
     */
    readonly orderUpdatedAt?: 'asc' | 'desc'

    /**
     * 
     * @type {string}
     * @memberof DossierApiToRaiseDossierCollection
     */
    readonly createdAtBefore?: string

    /**
     * 
     * @type {string}
     * @memberof DossierApiToRaiseDossierCollection
     */
    readonly createdAtStrictlyBefore?: string

    /**
     * 
     * @type {string}
     * @memberof DossierApiToRaiseDossierCollection
     */
    readonly createdAtAfter?: string

    /**
     * 
     * @type {string}
     * @memberof DossierApiToRaiseDossierCollection
     */
    readonly createdAtStrictlyAfter?: string

    /**
     * 
     * @type {string}
     * @memberof DossierApiToRaiseDossierCollection
     */
    readonly updatedAtBefore?: string

    /**
     * 
     * @type {string}
     * @memberof DossierApiToRaiseDossierCollection
     */
    readonly updatedAtStrictlyBefore?: string

    /**
     * 
     * @type {string}
     * @memberof DossierApiToRaiseDossierCollection
     */
    readonly updatedAtAfter?: string

    /**
     * 
     * @type {string}
     * @memberof DossierApiToRaiseDossierCollection
     */
    readonly updatedAtStrictlyAfter?: string
}

/**
 * DossierApi - object-oriented interface
 * @export
 * @class DossierApi
 * @extends {BaseAPI}
 */
export class DossierApi extends BaseAPI {
    /**
     * Permet de remonter les dossiers et leurs contenus
     * @summary Récupération des dossiers
     * @param {DossierApiGetDossierCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DossierApi
     */
    public getDossierCollection(requestParameters: DossierApiGetDossierCollectionRequest = {}, options?: AxiosRequestConfig) {
        return DossierApiFp(this.configuration).getDossierCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.signatairesSignature, requestParameters.referenceExterne, requestParameters.referenceExterne2, requestParameters.sujet, requestParameters.prescripteur, requestParameters.prescripteurEmail, requestParameters.prescripteurEmail2, requestParameters.demandeurNom, requestParameters.demandeurUserIdentifier, requestParameters.demandeurUserIdentifier2, requestParameters.groups, requestParameters.recherche, requestParameters.termine, requestParameters.refuse, requestParameters.orderId, requestParameters.orderSujet, requestParameters.orderPrescripteur, requestParameters.orderDemandeurNom, requestParameters.orderCreatedAt, requestParameters.orderUpdatedAt, requestParameters.createdAtBefore, requestParameters.createdAtStrictlyBefore, requestParameters.createdAtAfter, requestParameters.createdAtStrictlyAfter, requestParameters.updatedAtBefore, requestParameters.updatedAtStrictlyBefore, requestParameters.updatedAtAfter, requestParameters.updatedAtStrictlyAfter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Permet de remonter un dossiers et ses contenus
     * @summary Récupération de dossier
     * @param {DossierApiGetDossierItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DossierApi
     */
    public getDossierItem(requestParameters: DossierApiGetDossierItemRequest, options?: AxiosRequestConfig) {
        return DossierApiFp(this.configuration).getDossierItem(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Créer un dossier
     * @summary Création de dossiers
     * @param {DossierApiPostDossierCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DossierApi
     */
    public postDossierCollection(requestParameters: DossierApiPostDossierCollectionRequest, options?: AxiosRequestConfig) {
        return DossierApiFp(this.configuration).postDossierCollection(requestParameters.dossierJsonldWrite, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Permet de relancer un dossiers
     * @summary Relance d\'un dossier de signature
     * @param {DossierApiRaiseDossierItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DossierApi
     */
    public raiseDossierItem(requestParameters: DossierApiRaiseDossierItemRequest, options?: AxiosRequestConfig) {
        return DossierApiFp(this.configuration).raiseDossierItem(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Permet de remonter les dossiers et leurs contenus
     * @summary Récupération des dossiers
     * @param {DossierApiToRaiseDossierCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DossierApi
     */
    public toRaiseDossierCollection(requestParameters: DossierApiToRaiseDossierCollectionRequest = {}, options?: AxiosRequestConfig) {
        return DossierApiFp(this.configuration).toRaiseDossierCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.signatairesSignature, requestParameters.referenceExterne, requestParameters.referenceExterne2, requestParameters.sujet, requestParameters.prescripteur, requestParameters.prescripteurEmail, requestParameters.prescripteurEmail2, requestParameters.demandeurNom, requestParameters.demandeurUserIdentifier, requestParameters.demandeurUserIdentifier2, requestParameters.groups, requestParameters.recherche, requestParameters.termine, requestParameters.refuse, requestParameters.orderId, requestParameters.orderSujet, requestParameters.orderPrescripteur, requestParameters.orderDemandeurNom, requestParameters.orderCreatedAt, requestParameters.orderUpdatedAt, requestParameters.createdAtBefore, requestParameters.createdAtStrictlyBefore, requestParameters.createdAtAfter, requestParameters.createdAtStrictlyAfter, requestParameters.updatedAtBefore, requestParameters.updatedAtStrictlyBefore, requestParameters.updatedAtAfter, requestParameters.updatedAtStrictlyAfter, options).then((request) => request(this.axios, this.basePath));
    }
}


