import * as React from 'react';

import { Box, CircularProgress, Typography } from '@mui/material';

import { useSnackbar } from 'notistack';
import { ModalContext } from '@europrocurement/flexy-components';
import ImageSpecifications, { ImageSpecificationsType } from './ImageSpecifications';
import ActionModal from '../../forms/ActionModal';
import ImageActions from './ImageActions';

type PartnerLogoProps = {
    isActive: boolean;
    imageSpecifications: ImageSpecificationsType;
    base64StringLogo: string;
    updateLogo: (value: string | ArrayBuffer) => void;
};

const PartnerLogo: React.FunctionComponent<PartnerLogoProps> = function (props) {
    const { modalActions } = React.useContext(ModalContext);

    const { isActive, base64StringLogo, updateLogo, imageSpecifications } = props;

    const { allowedTypes } = imageSpecifications;
    const { enqueueSnackbar } = useSnackbar();

    const fileInput = React.useRef<HTMLInputElement>(null);

    const triggerFileInput = () => {
        if (!fileInput.current) {
            return;
        }
        fileInput.current.value = '';
        fileInput.current?.click();
    };

    const precheckFile = (mimeType: string) => {
        const fileType = mimeType.split('/')[0] ?? null;
        const fileExtension = mimeType.split('/')[1] ?? null;

        if (fileType !== 'image') {
            return 'Le fichier doit être une image';
        }

        if (allowedTypes.includes(fileExtension) === false) {
            return `Seul les fichiers images (${allowedTypes.join('/')}) sont supportés`;
        }

        return '';
    };

    const convertFiletoBase64: (file: File) => Promise<string | ArrayBuffer | null> = (
        file: File,
    ) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = reject;
        });

    const handleFileInput = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files === null) {
            return;
        }

        const file = event.target.files[0];
        const incorrectFileError = precheckFile(file.type);

        if (incorrectFileError !== '') {
            enqueueSnackbar(incorrectFileError, { variant: 'error' });
        } else {
            const base64File = await convertFiletoBase64(file);
            if (base64File) {
                updateLogo(base64File);
            } else {
                updateLogo('');
            }
        }
    };

    const imageSize = '220px';

    const imageStyle = {
        margin: '20px',
        minHeight: imageSize,
        minWidth: imageSize,
        maxHeight: imageSize,
        maxWidth: imageSize,
    };

    const customGrey = '#8b8b8b';

    const removeLogo = () => {
        modalActions.call(
            <ActionModal
                onClose={modalActions.close}
                asyncAction={() => {
                    updateLogo('');
                }}
                title="Suppression d'un logo"
                actionText="Voulez-vous supprimer ce logo ?"
                actionLabel="Confirmer suppression"
                callback={modalActions.close}
            />,
        );
    };

    const getAllowedExtensions = () => {
        const extensions = allowedTypes.map((extension) => `.${extension}`);
        return extensions.join(',');
    };

    if (!imageSpecifications) {
        return <CircularProgress />;
    }

    return (
        <Box
            display="flex"
            justifyContent="center"
            height="100%"
            marginTop="20px"
        >
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                width="100%"
            >
                {isActive && base64StringLogo ? (
                    <Box>
                        <img
                            src={base64StringLogo}
                            alt="Logo formalité"
                            style={{
                                ...imageStyle,
                                objectFit: 'contain',
                            }}
                        />
                    </Box>
                ) : (
                    <Box
                        style={{
                            ...imageStyle,
                            backgroundColor: 'lightgrey',
                            opacity: '0.1',
                            border: `5px solid ${customGrey}`,
                            borderRadius: '300px',
                        }}
                    >
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            height="100%"
                        >
                            <Typography
                                fontWeight="bold"
                                fontSize={`${parseInt(imageSize, 10) / 5}px`}
                                color={customGrey}
                                letterSpacing="6px"
                            >
                                LOGO
                            </Typography>
                        </Box>
                    </Box>
                )}

                <ImageActions
                    isActive={isActive}
                    base64StringLogo={base64StringLogo}
                    triggerFileInput={triggerFileInput}
                    removeLogo={removeLogo}
                />

                <Box
                    display="flex"
                    margin="20px 10px 10px"
                    justifyContent="space-between"
                >
                    <ImageSpecifications
                        isActive={isActive}
                        imageSpecifications={imageSpecifications}
                    />
                </Box>
            </Box>
            <input
                type="file"
                accept={getAllowedExtensions()}
                ref={fileInput}
                onChange={handleFileInput}
                hidden
            />
        </Box>
    );
};

export default PartnerLogo;
