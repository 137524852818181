import type { DevisLigne, RubriqueFacturation } from '@europrocurement/l2d-domain';
import type { LigneProduit } from '@b2d/pages/Achats/components/forms/types';

export default (quoteProduct: DevisLigne, rubFacts: RubriqueFacturation[]) => {
    const res: LigneProduit = {
        idLigneVente: quoteProduct.id,
        libelle: quoteProduct.libelle ? quoteProduct.libelle : undefined,
        ht: undefined,
        tva: undefined,
        ttc: undefined,
    };

    if (quoteProduct.rubfac && quoteProduct.rubfac !== '') {
        res.rubriqueFacturation = {
            code: quoteProduct.rubfac,
            id:
                rubFacts.find(
                    (rubFact: RubriqueFacturation) => rubFact.code === quoteProduct.rubfac,
                )?.id || 0,
        };
    }
    return res;
};
