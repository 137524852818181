import React, { useCallback, useEffect, useState } from 'react';

import {
    SubmitHandler,
    useForm,
    SubmitErrorHandler,
    FieldErrors,
    FormState,
    UseFormReturn,
} from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { useSnackbar } from 'notistack';
import { Box, Button } from '@mui/material';

import {
    Fournisseur,
    Dossier,
    RubriqueFacturationApiObject,
    Commande,
    DOSSIERS_SLICE_NAME,
    DOSSIER_AUTOCOMPLETE_DATASOURCE_NAME,
    MediaObject,
    TauxTvaApiObject,
    FactureAchatNote,
    DossierFormalite,
    DOSSIERS_FORMALITES_AUTOCOMPLETE_DATASOURCE_NAME,
    FOURNISSEUR_SLICE_NAME,
    FOURNISSEUR_AUTOCOMPLETE_FACTURE_DATASOURCE_NAME,
    COMMANDES_SLICE_NAME,
} from '@europrocurement/l2d-domain';
import { FactureVente } from '@europrocurement/l2d-domain/reducers/dossiers/slices/factureVenteSlice';
import { FlexyForm, FlexyFormSubmitButtonRender, FormObject } from '@europrocurement/flexy-form';
import { UseKeycloakCheckRole } from '@europrocurement/l2d-keycloak';
import { DataSource, EuroprocApiResponseStatus } from '@europrocurement/l2d-redux-utils';
import { useModal } from '@europrocurement/flexy-components';

import {
    selectFactureVente,
    selectFournisseurAc,
    FactureFormSelector,
    RootStateType,
    AppDispatch,
    customizerSelector,
    selectCommande,
} from '@b2d/redux/RootStore';
import { ACTIONS } from '@b2d/redux/FactureFormReducer';

import {
    removeEmptyProduits,
    useSyncAchatProduitsWithVente,
    useUpdateFormWithVentesAndEvents,
} from '@b2d/pages/Achats/components/forms/functions/produitFunctions';
import { updateFormWithFournisseur } from '@b2d/pages/Achats/components/forms/functions/fournisseurFunctions';
import {
    resetDossierFormaliteFields,
    resetDossiersFields,
    useUpdateFormWithDossier,
    useUpdateFormWithDossierFormalite,
} from '@b2d/pages/Achats/components/forms/functions/dossierFonctions';
import {
    // useResetAllForm,
    useResetFactureAchatState,
} from '@b2d/pages/Achats/components/forms/functions/generalFunctions';
import { formalitesVentesToProduitsAchats } from '@b2d/pages/Achats/components/forms/functions/venteFunctions';
import {
    FactureAchatCreate,
    FactureFormObject,
    filterOptionsProps,
} from '@b2d/pages/Achats/components/forms/types';
import { navigateToNewFacture } from '@b2d/utils/navigationHelper';
import _ from 'lodash';
import useAddCustomContextActions from '@b2d/pages/Achats/hooks/contextActions/useAddCustomContextActions';
import useUpdateDiscountRate from '@b2d/pages/Achats/hooks/useUpdateDiscountRate';
import useUpdateTotals from '@b2d/pages/Achats/hooks/useUpdateTotals';
import useSwitchLockValues from '@b2d/pages/Achats/hooks/useSwitchLockValues';
import useShowMessage from '@b2d/hooks/useShowMessage';
import useDomain from '@b2d/hooks/useDomain';
import { jsonLdIdScraper } from '@europrocurement/l2d-utils';
import useLazyDispatch from '@b2d/hooks/useLazyDispatch';
import usePrevious from '@b2d/hooks/usePrevious';
import useActionOnExitForm from '@b2d/pages/Achats/hooks/useActionOnExitForm';
import {
    confirmationSaisieFactureModalMessages,
    removeProductsModalMessages,
    // saisieDossierCommandeModalMessages,
    saisieDossierFormaliteModalMessages,
} from '../../../constants/wording/modals';

import {
    useNavigateToMediaObjectsList,
    useNavigateToStatementRecap,
} from '../../../achatRouterHooks';
import ConfirmationModalContent from '../../modals/ConfirmationModalContent';
import type { Nullable } from '../../../constants/types';
import useTreatmentRefusal from '../../../hooks/contextActions/useTreatmentRefusal';
import useUpdateSociete from '../../../hooks/contextActions/useUpdateSociete';
import useDeleteMediaObject from '../../../hooks/contextActions/useDeleteMediaObject';
import useHandleSections from '../formElements/hooks/useHandleSections';
import formInvoicePurchaseToApiInvoicePurchase from '../functions/dataTransformers/formToApi/formInvoicePurchaseToApiInvoicePurchase';
import { convertToNumberAndFormatToString } from '../functions/calculsProduits';
import useAddLineToProducts from '../formElements/hooks/useAddLineToProducts';
import useHandleQuoteData from '../formElements/hooks/useHandleQuoteData';
import useFinderMVQuote from '../formElements/hooks/useFinderMVQuote';
import FormOverlay from '../../FormOverlay';

export type OnSubmitProps = {
    data: FactureAchatCreate;
    formContext: UseFormReturn<FormObject>;
    goToNext?: boolean;
    id_statement?: number;
};

export type OnCancelProps = {
    formContext: UseFormReturn<FormObject>;
};

export type RegisterSimpleInvoiceFormProps = {
    mediaObject: MediaObject;
    facture: FactureFormObject | Partial<FactureFormObject>;
    onSubmit: ({ data, formContext, goToNext, id_statement }: OnSubmitProps) => void;
    onCancel: ({ formContext }: OnCancelProps) => void;
};

const RegisterSimpleInvoiceForm: React.FunctionComponent<RegisterSimpleInvoiceFormProps> =
    function ({ mediaObject, facture, onSubmit, onCancel }) {
        const roleChecker = UseKeycloakCheckRole();
        const isInterne = roleChecker('realm:interne');

        const [validSubmit, setValidSubmit] = useState<boolean>(false);
        const [stateNotes, setStateNotes] = useState<FactureAchatNote[]>([]);
        const [lastVenteUpdate, setLastVenteUpdate] = useState<Nullable<number>>(null);
        const [showOverlay, setShowOverlay] = useState(false);

        const { mediaobjectid, statementid } = useParams();

        /** ********** */
        /* DataSources */
        /** ********** */
        const fournisseursDataSource: DataSource<Fournisseur> = useSelector(
            (s: RootStateType) => s.tiers.fournisseur.autocompleteFactures,
            _.isEqual,
        );

        const rubFactsDataSource: DataSource<RubriqueFacturationApiObject> = useSelector(
            (s: RootStateType) => s.dossiers.rubfac.main,
            _.isEqual,
        );

        const tauxTvaDataSource: DataSource<TauxTvaApiObject> = useSelector(
            (s: RootStateType) => s.dossiers.txtva.main,
            _.isEqual,
        );

        const ventesDataSource: DataSource<FactureVente> = useSelector(
            (s: RootStateType) => s.dossiers.facturevente.main,
            _.isEqual,
        );

        const commandeStatus: EuroprocApiResponseStatus = useSelector(
            (s: RootStateType) => s.dossiers.commande.main.selectedStatus,
            _.isEqual,
        );

        /** ********** */
        /* DataSources */
        /** ********** */

        /** ************* */
        /* Selected items */
        /** ************* */

        const commandeSelected: Commande | undefined = useSelector(
            (s: RootStateType) => s.dossiers.commande.main.selected,
            _.isEqual,
        );

        const venteSelected: FactureVente | undefined = useSelector(
            (s: RootStateType) => s.dossiers.facturevente.main.selected,
            _.isEqual,
        );

        const dossierSelected: Dossier | undefined = useSelector(
            (s: RootStateType) => s.dossiers.dos[DOSSIER_AUTOCOMPLETE_DATASOURCE_NAME].selected,
            _.isEqual,
        );

        const dossierFormaliteSelected: DossierFormalite | undefined = useSelector(
            (s: RootStateType) =>
                s.formalites.dosform[DOSSIERS_FORMALITES_AUTOCOMPLETE_DATASOURCE_NAME].selected,
            _.isEqual,
        );

        const publisherSelected: Fournisseur | undefined = useSelector(
            (s: RootStateType) => s.tiers.fournisseur.autocompleteFactures.selected,
            _.isEqual,
        );

        // Reducer instanciation (replace useStates)
        const dispatch = useDispatch<AppDispatch>();
        const state = useSelector(FactureFormSelector);

        // Initialisation du formulaire
        const formContext = useForm({
            mode: 'onTouched',
            defaultValues: facture,
        });

        const { enqueueSnackbar } = useSnackbar();
        const navigate = useNavigate();

        const { modalActions } = useModal();

        const switchLockValues = useSwitchLockValues();
        const { stateSwitchLockValues } = switchLockValues;
        const lockTotalsSectionValue = stateSwitchLockValues.totals.value;

        const { updateTotals } = useUpdateTotals({ formContext, stateSwitchLockValues });
        const updateDiscountRate = useUpdateDiscountRate({
            formContext,
            stateSwitchLockValues: switchLockValues.stateSwitchLockValues,
            fournisseur: publisherSelected,
            overwriteAfterUpdate: () => {
                updateTotals({
                    reasonToTriggerConfirmationModal: !lockTotalsSectionValue,
                });
            },
        });
        const { stateDiscountRate } = updateDiscountRate;

        const { xIdSociete } = useSelector(customizerSelector);

        const updateFormWithDossier = useUpdateFormWithDossier();
        const updateFormWithDossierFormalite = useUpdateFormWithDossierFormalite();
        const syncAchatProduitsWithVente = useSyncAchatProduitsWithVente();
        const updateFormWithVentesAndEvents = useUpdateFormWithVentesAndEvents();
        const showMessage = useShowMessage();
        const resetStates = useResetFactureAchatState();
        const lazyDispatch = useLazyDispatch({
            sliceName: DOSSIERS_SLICE_NAME,
            dataSourceName: DOSSIER_AUTOCOMPLETE_DATASOURCE_NAME,
        });
        const navigateToList = useNavigateToMediaObjectsList();
        const navigateToStatementRecap = useNavigateToStatementRecap();
        const { isRsf, defineDefaultProducts, actionOnExitForm } = useActionOnExitForm();

        useEffect(() => {
            setShowOverlay(false);
        }, []);

        useEffect(() => {
            lazyDispatch({
                target: 'Search',
                action: 'reset',
            });
        }, [lazyDispatch]);

        useDomain();

        const updateNotes = useCallback(
            (notes: FactureAchatNote[]) => setStateNotes([...notes]),
            [],
        );

        const handleSections = useHandleSections({
            formContext,
            state,
            stateNotes: {
                get: stateNotes,
                set: updateNotes,
            },
        });

        const stateHeaderSectionStructure = handleSections.states.header;

        useEffect(() => {
            if (!isInterne || !formContext.getValues('facture_formalite')) {
                if (fournisseursDataSource.filters.ddmId) {
                    dispatch({
                        type: `${FOURNISSEUR_SLICE_NAME}/delete${FOURNISSEUR_AUTOCOMPLETE_FACTURE_DATASOURCE_NAME}Filter`,
                        payload: {
                            field: 'ddmId',
                        },
                    });
                }
            } else if (
                !fournisseursDataSource.filters.ddmId ||
                fournisseursDataSource.filters.ddmId !== (xIdSociete === 12 ? state.defDomain : 0)
            ) {
                dispatch({
                    type: `${FOURNISSEUR_SLICE_NAME}/set${FOURNISSEUR_AUTOCOMPLETE_FACTURE_DATASOURCE_NAME}Filter`,
                    payload: {
                        key: 'ddmId',
                        value: xIdSociete === 12 ? state.defDomain : 0,
                    },
                });
            }
        }, [
            dispatch,
            formContext,
            fournisseursDataSource.filters.ddmId,
            isInterne,
            state.defDomain,
            xIdSociete,
        ]);

        /**
         * DEB - useEffects
         */

        /**
         * Actions à l'ouverture de la page de saisie de facture simple
         */
        useEffect(() => {
            const filterOptions: filterOptionsProps = {
                sliceName: DOSSIERS_SLICE_NAME,
                dataSourceName: 'main',
            };

            dispatch({
                type: ACTIONS.RESET_DATASOURCE_FILTERS,
                payload: filterOptions,
            });
        }, [dispatch]);

        /**
         * Infos facture de vente par N° de dossier / d'annonce
         *
         * Si le N° de dossier / d'annonce change et que l'utilisateur est interne :
         * Enregistrer le fournisseur correspondant.
         *
         * @see dossierSelected?.facture
         * @see dispatch
         * @see isInterne
         */
        useEffect(() => {
            updateFormWithVentesAndEvents(
                formContext,
                dossierSelected || null,
                dossierFormaliteSelected || null,
                publisherSelected || null,
                venteSelected || null,
                ventesDataSource.selectedStatus,
            );
        }, [
            formContext,
            updateFormWithVentesAndEvents,
            dossierSelected,
            dossierFormaliteSelected,
            publisherSelected,
            venteSelected,
            ventesDataSource.selectedStatus,
        ]);

        const { isQuoteFound } = useFinderMVQuote();
        useHandleQuoteData({ formContext, isQuoteFound });

        const previousDefDomain = usePrevious(state.defDomain);

        /**
         * Apply default products if :
         *   - The domain is different than earlier ;
         *   - And we have no additional details to overwrite the products.
         */
        useEffect(() => {
            const defDomainHaveChanged = previousDefDomain !== state.defDomain;
            const noAdditionalDetails = !isQuoteFound && !venteSelected;

            if (defDomainHaveChanged && noAdditionalDetails) {
                defineDefaultProducts({ formContext });
            }
        }, [
            defineDefaultProducts,
            formContext,
            isQuoteFound,
            previousDefDomain,
            state.defDomain,
            venteSelected,
        ]);

        const checkIfEmptyProducts = useCallback(() => {
            if (isRsf && formContext.getValues('produits')?.length === 1) {
                return true;
            }

            if (formContext.getValues('produits')?.length === 0) {
                return true;
            }

            return false;
        }, [formContext, isRsf]);

        /**
         * S'il n'y plus de dossier ou de fournisseur sélectionné,
         * alors on remplace le tableau de produits par la valeur par défaut.
         */
        useEffect(() => {
            const noFolioNorPublisher: boolean = !dossierSelected && !publisherSelected;
            const emptyProductsDetails =
                (state && !state.productsDetails) || state.productsDetails.length === 0;
            const productsNeedUpdate = checkIfEmptyProducts();

            if (noFolioNorPublisher || (emptyProductsDetails && productsNeedUpdate)) {
                defineDefaultProducts({ formContext });
            }
        }, [
            checkIfEmptyProducts,
            defineDefaultProducts,
            dossierSelected,
            formContext,
            publisherSelected,
            state,
        ]);

        /**
         * Infos dossier par N° de dossier / d'annonce
         *
         * Si un dossier est sélectionné, met à jour les informations.
         */
        useEffect(() => {
            if (dossierFormaliteSelected) return;

            if (
                dossierSelected &&
                dossierSelected.numero &&
                `${dossierSelected.numero}` !== state.defaultNumeroDossier
            ) {
                updateFormWithDossier(formContext, dossierSelected);
            } else if (!dossierSelected) {
                resetDossiersFields(formContext);
                defineDefaultProducts({ formContext });
                if (formContext.getFieldState('dossier_formalite').isTouched) {
                    resetStates({ formContext });
                }
            }
        }, [
            defineDefaultProducts,
            dossierFormaliteSelected,
            dossierSelected,
            formContext,
            resetStates,
            state.defaultNumeroDossier,
            updateFormWithDossier,
        ]);

        /**
         * Infos dossier par N° de dossier de formalité
         *
         * Si un dossier de formalité est sélectionné, met à jour les informations.
         */
        useEffect(() => {
            if (dossierSelected) return;

            if (
                dossierFormaliteSelected &&
                dossierFormaliteSelected.numero &&
                dossierFormaliteSelected.numero !== state.defaultNumeroDossierFormalite
            ) {
                updateFormWithDossierFormalite(formContext, dossierFormaliteSelected);
            } else if (!dossierFormaliteSelected) {
                resetDossierFormaliteFields(formContext);
                defineDefaultProducts({ formContext });
                if (formContext.getFieldState('dossier').isTouched) {
                    resetStates({ formContext });
                }
            }
        }, [
            defineDefaultProducts,
            dossierFormaliteSelected,
            dossierSelected,
            formContext,
            resetStates,
            state.defaultNumeroDossierFormalite,
            updateFormWithDossierFormalite,
        ]);

        /**
         * Infos fournisseur par N° de dossier / d'annonce
         *
         * Si le N° de dossier / d'annonce change et que l'utilisateur est interne :
         * Enregistrer le fournisseur correspondant.
         *
         * @see dossierSelected?.prestations
         * @see dispatch
         * @see isInterne
         */
        useEffect(() => {
            if (
                dossierSelected &&
                dossierSelected.prestations &&
                dossierSelected.prestations[0] &&
                !dossierSelected.prestations[0].idSocieteFacture
            ) {
                enqueueSnackbar(
                    "Cette parution n'est liée à aucunes société, il est impossible d'enregistrer la facture.",
                    {
                        autoHideDuration: 10000,
                        variant: 'error',
                    },
                );
            }

            const presta =
                dossierSelected?.prestations &&
                dossierSelected?.prestations.length > 0 &&
                dossierSelected?.prestations[0];

            if (presta) {
                dispatch({ type: ACTIONS.SET_NUM_PRESTA, payload: presta.numero });
                formContext.trigger('numero_annonce');
            }
            const idFacturante = presta ? presta.idEntiteFacturante : null;
            if (idFacturante && !stateHeaderSectionStructure.supplierFromStatement.value) {
                dispatch(selectFournisseurAc({ id: idFacturante }));
            }
        }, [
            dossierSelected?.prestations,
            dispatch,
            formContext,
            dossierSelected,
            enqueueSnackbar,
            stateHeaderSectionStructure.supplierFromStatement.value,
        ]);

        /**
         * Infos dossier par N° de dossier de formalité
         *
         * Si un dossier de formalité est sélectionné, met à jour les informations.
         */
        useEffect(() => {
            if (!dossierSelected) return;

            if (dossierSelected.commande) {
                const commandeDossierId = jsonLdIdScraper(dossierSelected.commande);

                if (
                    commandeStatus !== 'loading' &&
                    (!commandeSelected || commandeSelected.id !== commandeDossierId)
                ) {
                    dispatch(
                        selectCommande({
                            id: commandeDossierId,
                        }),
                    );
                }
            }
        }, [commandeSelected, commandeStatus, dispatch, dossierSelected]);

        useEffect(() => {
            if (!dossierSelected) return;

            if (!dossierSelected.commande && commandeSelected) {
                dispatch({
                    type: `${COMMANDES_SLICE_NAME}/deletemainSelected`,
                });
            }
        }, [commandeSelected, commandeStatus, dispatch, dossierSelected]);

        /**
         * Ajout des RubFacts généraux
         *
         * @see dispatch
         * @see rubFactsDataSource.data
         */
        useEffect(() => {
            const rubFactsToAdd: RubriqueFacturationApiObject[] = rubFactsDataSource.data;

            dispatch({ type: ACTIONS.ADD_RUB_FACTS, payload: rubFactsToAdd });
        }, [dispatch, rubFactsDataSource.data]);

        /**
         * Filtre sur les rubriques de facturation
         *
         * Conserver les rubriques de facturation liées au domaine (i.e : Publication, Adjudication, ...)
         *
         * @see dispatch
         * @see rubFactsDataSource.data
         * @see state.defDomain
         */
        useEffect(() => {
            dispatch({ type: ACTIONS.SET_RUB_FACT, payload: rubFactsDataSource.data });

            if (state.defDomain !== 0) {
                dispatch({
                    type: ACTIONS.FILTER_RUB_FACTS,
                    payload: {
                        domaine: state.defDomain,
                        rubfacts: rubFactsDataSource.data,
                    },
                });
            }
        }, [dispatch, rubFactsDataSource.data, state.defDomain]);

        /**
         * Alimente la liste des taux de TVA dans le state
         *
         * @see tauxTvaDataSource.data
         * @see state.txTva
         */
        useEffect(() => {
            if (state.txTva.length < 1) {
                dispatch({ type: ACTIONS.SET_TAUX_TVA, payload: tauxTvaDataSource.data });
            }
        }, [dispatch, state.txTva.length, tauxTvaDataSource.data]);

        /**
         * Select la facture de vente de la commande,
         * si le dossier n'a pas lui-même de facture de vente.
         *
         * On cherche dans la commande le dossier qui correspond au dossier sélectionné
         * pour s'assurer que la facture soit la bonne.
         *
         * E.g. :
         *     - Dossier sans facture : 1272353
         *     - Dossier avec facture : 802308
         */
        useEffect(() => {
            const commandWithVenteAndDossierWithout: boolean = !!(
                commandeSelected?.idFacture &&
                dossierSelected &&
                dossierSelected.id &&
                !dossierSelected.facture
            );

            if (commandWithVenteAndDossierWithout) {
                const matchedDossier = commandeSelected?.dossiers?.find((dossier) => {
                    if (!dossierSelected?.id) return false;
                    return dossier.includes(dossierSelected?.id.toString());
                });

                const newIdVenteFromCommandeAndDifferentThanVenteSelected: boolean = !!(
                    venteSelected &&
                    commandeSelected &&
                    commandeSelected?.idFacture !== venteSelected.id
                );

                if (
                    matchedDossier &&
                    ventesDataSource.selectedStatus !== 'loading' &&
                    (!venteSelected || newIdVenteFromCommandeAndDifferentThanVenteSelected)
                ) {
                    dispatch(selectFactureVente({ id: commandeSelected?.idFacture as number }));
                }
            }
        }, [
            commandeSelected,
            dossierSelected,
            ventesDataSource.selectedStatus,
            dispatch,
            venteSelected,
        ]);

        /**
         * Ajout des lignes d'achat correspondant à la vente pour un dossier classique
         *
         * RG : https://legal2digital.atlassian.net/browse/PA-54
         *
         * Si une facture de vente est trouvée :
         * Ajouter les lignes de vente à l'achat si elles ne sont pas LIB ou
         * la propriété 'affiche' est à false.
         *
         * @see venteSelected
         * @see formContext
         * @see addProduit
         * @see state.rubFacts
         */
        useEffect(() => {
            // Instanciation d'un formulaire d'édition de facture, on ignore le useEffect

            if (venteSelected && venteSelected.id && venteSelected.id !== lastVenteUpdate) {
                setLastVenteUpdate(venteSelected.id);
                syncAchatProduitsWithVente(
                    venteSelected?.lignes || undefined,
                    formContext,
                    state.rubFacts,
                    dossierSelected,
                );
            }
        }, [
            lastVenteUpdate,
            dossierSelected,
            formContext,
            state.rubFacts,
            syncAchatProduitsWithVente,
            venteSelected,
        ]);

        /**
         * Ajout des lignes d'achat correspondant à la vente pour un dossier de formalites
         *
         * RG : https://legal2digital.atlassian.net/browse/PA-54
         *
         * Si une facture de vente est trouvée :
         * Ajouter les lignes de vente à l'achat si elles ne sont pas LIB ou
         * la propriété 'affiche' est à false.
         */
        useEffect(() => {
            if (dossierSelected) return;

            if (
                dossierFormaliteSelected &&
                dossierFormaliteSelected.id &&
                dossierFormaliteSelected.id !== lastVenteUpdate
            ) {
                setLastVenteUpdate(dossierFormaliteSelected.id);
                formalitesVentesToProduitsAchats(`${dossierFormaliteSelected.id}`, xIdSociete)
                    .then((lignes) => {
                        if (lignes && lignes.length > 0) {
                            formContext.setValue('produits', []);
                            syncAchatProduitsWithVente(
                                lignes,
                                formContext,
                                state.rubFacts,
                                dossierSelected,
                                true,
                                true,
                            );
                        }
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            }
        }, [
            dossierFormaliteSelected,
            dossierSelected,
            formContext,
            lastVenteUpdate,
            state.rubFacts,
            syncAchatProduitsWithVente,
            xIdSociete,
        ]);

        const { addDiscountLine } = useAddLineToProducts({ formContext });

        /**
         * Afficher infos fournisseur
         *
         * RG : https://legal2digital.atlassian.net/browse/PA-53
         *
         * Si le N° de dossier / annonce change :
         * Remplir les champs Siren, Raison sociale, Type remise
         * Ajouter une ligne de produit : Remise
         *
         * @see publisherSelected
         * @see updateRSFFournisseur
         */
        useEffect(() => {
            const currentPublisher = formContext.getValues('fournisseur');

            if (publisherSelected !== undefined || publisherSelected !== currentPublisher) {
                formContext.setValue('fournisseur', publisherSelected);
                formContext.setValue('siren_fournisseur', publisherSelected);
                formContext.trigger('siren_fournisseur');
            }
            updateFormWithFournisseur(formContext, publisherSelected, {
                typeRemise: () => {
                    addDiscountLine();
                },
                siren: (siren: string) => {
                    if (siren !== state.defaultSiren && state.defDomain !== 3) {
                        dispatch({ type: ACTIONS.SET_SIREN, payload: siren });
                    }
                },
            });
        }, [
            addDiscountLine,
            dispatch,
            formContext,
            publisherSelected,
            state.defDomain,
            state.defaultSiren,
        ]);

        /**
         * Si le dossier sélectionné possède une commande hors Annonce Légale (domaine 1) et que l'utilisateur a le rôle mada,
         * alors empêcher la saisie de la facture avec ce dossier.
         *
         * Depuis la 1.9.2, MADA doit pouvoir saisir ce genre de dossiers.
         */
        // useEffect(() => {
        //     const isDossierOfCommande: boolean = !!(
        //         dossierSelected &&
        //         commandeSelected &&
        //         dossierSelected?.commande !== null &&
        //         dossierSelected?.commande !== undefined &&
        //         commandeSelected?.idDomaine !== 1
        //     );

        //     if (isDossierOfCommande && !isInterne) {
        //         modalActions.call(
        //             <ConfirmationModalContent
        //                 messages={saisieDossierCommandeModalMessages}
        //                 actionOnValidation={() => {
        //                     navigateToNewFacture(
        //                         showMessage,
        //                         dispatch,
        //                         navigate,
        //                         navigateToList,
        //                         mediaobjectid,
        //                     );
        //                 }}
        //                 actionOnCancellation={() => {
        //                     window.location.reload();
        //                 }}
        //             />,
        //         );
        //     }
        // }, [
        //     commandeSelected,
        //     dispatch,
        //     dossierSelected,
        //     isInterne,
        //     mediaobjectid,
        //     modalActions,
        //     navigate,
        //     navigateToList,
        //     showMessage,
        // ]);

        /**
         * Si le dossier sélectionné provient d'une formalité (domaine 3 = formalité) et que l'utilisateur a le rôle mada,
         * alors empêcher la saisie de la facture avec ce dossier.
         */
        useEffect(() => {
            const isDossierFormalite: boolean = !!(
                dossierFormaliteSelected && state.defDomain === 3
            );

            if (isDossierFormalite && !isInterne) {
                modalActions.call(
                    <ConfirmationModalContent
                        messages={saisieDossierFormaliteModalMessages}
                        actionOnValidation={() => {
                            navigateToNewFacture({
                                showMessage,
                                dispatch,
                                navigate,
                                navigateToList,
                                idCurrentMediaObject: mediaobjectid,
                            });
                        }}
                        actionOnCancellation={() => {
                            window.location.reload();
                        }}
                    />,
                );
            }
        }, [
            dispatch,
            dossierFormaliteSelected,
            isInterne,
            mediaobjectid,
            modalActions,
            navigate,
            navigateToList,
            showMessage,
            state.defDomain,
        ]);

        /**
         * FIN - useEffects
         */

        /**
         * Actions lors de l'envoi du formulaire
         *
         * Si le formulaire est rempli correctement et le bouton d'envoi est appuyé :
         * Formaliser les données du formulaire de façon à les rendre compatible avec l'API achats
         *
         * @param data
         * @see onSubmit
         *
         */
        const handleSubmit: SubmitHandler<FactureFormObject> = useCallback(
            async (data: FactureFormObject) => {
                const apiInvoicePurchase = formInvoicePurchaseToApiInvoicePurchase(
                    data,
                    mediaObject,
                );

                if (stateDiscountRate.hasSetRsf.value) {
                    apiInvoicePurchase.hasSetRsf = true;
                }

                if (stateHeaderSectionStructure.temporaryStatementId.value) {
                    apiInvoicePurchase.idReleveAchat =
                        stateHeaderSectionStructure.temporaryStatementId.value;
                }

                if (stateNotes) {
                    // Création des notes à lier à la FactureAchat qui va être créée
                    const iriNotes: string[] = stateNotes
                        .filter((stateNote) => stateNote['@id'] !== undefined)
                        .map((stateNote) => stateNote['@id']) as string[];

                    apiInvoicePurchase.notes = iriNotes;
                }

                // Si les totaux ne sont pas bloqués on set les totaux manuellement
                if (!lockTotalsSectionValue) {
                    apiInvoicePurchase.ht = convertToNumberAndFormatToString(data.total_ht);
                    apiInvoicePurchase.tva = convertToNumberAndFormatToString(data.total_tva);
                    apiInvoicePurchase.ttc = convertToNumberAndFormatToString(data.total_ttc);
                }

                setShowOverlay(true);

                if (data.goToNext) {
                    onSubmit({
                        data: apiInvoicePurchase,
                        formContext,
                        goToNext: true,
                    });
                } else {
                    onSubmit({ data: apiInvoicePurchase, formContext, goToNext: false });
                }
            },
            [
                formContext,
                lockTotalsSectionValue,
                mediaObject,
                onSubmit,
                stateDiscountRate.hasSetRsf.value,
                stateHeaderSectionStructure.temporaryStatementId.value,
                stateNotes,
            ],
        );

        const handleInvalid: SubmitErrorHandler<FactureFormObject> = useCallback(
            (errors: FieldErrors<FactureFormObject>) => {
                // Formalisme des données du formulaire de création de facture d'achat
                if (Object.keys(errors).length === 1 && errors.produits) {
                    let oneProduitOk = false;
                    if (errors.produits.length) {
                        for (let index = 0; index < errors.produits.length; index++) {
                            if (errors.produits[index] === undefined) {
                                oneProduitOk = true;
                            }
                        }

                        if (oneProduitOk) {
                            modalActions.call(
                                <ConfirmationModalContent
                                    messages={removeProductsModalMessages}
                                    actionOnValidation={() => {
                                        removeEmptyProduits(formContext);
                                        formContext.handleSubmit(
                                            (d) => {
                                                handleSubmit(d as FactureFormObject);
                                            },
                                            (e) => console.log(e),
                                        )();
                                    }}
                                    actionOnAlternative={() => {
                                        removeEmptyProduits(formContext);
                                        formContext.handleSubmit(
                                            (factureToSubmit) => {
                                                const cloneFactureToSubmit = { ...factureToSubmit };
                                                cloneFactureToSubmit.goToNext = true;
                                                handleSubmit(
                                                    cloneFactureToSubmit as FactureFormObject,
                                                );
                                            },
                                            (e) => console.log(e),
                                        )();
                                    }}
                                />,
                            );
                        }
                    }
                }
            },
            [formContext, handleSubmit, modalActions],
        );

        const renderedActionButtons: FlexyFormSubmitButtonRender | undefined = useCallback(
            (formstate: FormState<FormObject>) => {
                const { isSubmitSuccessful, isSubmitting, isLoading, isValidating } = formstate;

                const reasonToLock: boolean =
                    (isSubmitSuccessful || isSubmitting || isLoading || isValidating) &&
                    validSubmit;

                return (
                    <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                    >
                        <Button
                            color="error"
                            disabled={reasonToLock}
                            variant="contained"
                            onClick={() => {
                                actionOnExitForm({ formContext });
                                onCancel({ formContext });
                            }}
                            style={{ marginTop: '16px', marginBottom: '16px' }}
                        >
                            Annuler la saisie
                        </Button>
                        <Button
                            type="submit"
                            disabled={reasonToLock}
                            variant="contained"
                            style={{ marginTop: '16px', marginBottom: '16px' }}
                        >
                            Terminer la saisie
                        </Button>
                        <Button
                            disabled={reasonToLock}
                            variant="contained"
                            onClick={formContext.handleSubmit((factureToSubmit) => {
                                const cloneFactureToSubmit = { ...factureToSubmit };
                                cloneFactureToSubmit.goToNext = true;
                                handleSubmit(cloneFactureToSubmit as FactureFormObject);
                            }, handleInvalid)}
                            style={{ marginTop: '16px', marginBottom: '16px' }}
                        >
                            Facture Suivante
                        </Button>
                    </Box>
                );
            },
            [formContext, handleInvalid, handleSubmit, onCancel, actionOnExitForm, validSubmit],
        );

        const renderedActionButtonsStatement: FlexyFormSubmitButtonRender | undefined = useCallback(
            (formstate: FormState<FormObject>) => {
                const { isSubmitSuccessful, isSubmitting, isLoading, isValidating } = formstate;

                const reasonToLock: boolean =
                    (isSubmitSuccessful || isSubmitting || isLoading || isValidating) &&
                    validSubmit;

                return (
                    <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                    >
                        <Button
                            color="error"
                            disabled={reasonToLock}
                            variant="contained"
                            onClick={() => {
                                if (statementid) {
                                    navigateToStatementRecap({ statementId: +statementid });
                                    actionOnExitForm({ formContext });
                                } else {
                                    onCancel({ formContext });
                                }
                            }}
                            style={{ marginTop: '16px', marginBottom: '16px' }}
                        >
                            Annule -{' '}
                            {statementid ? 'Retour Récapitulatif' : 'Retour Liste À Traiter'}
                        </Button>
                        <Button
                            type="submit"
                            disabled={reasonToLock}
                            variant="contained"
                            style={{ marginTop: '16px', marginBottom: '16px' }}
                        >
                            Enregistre - Retour Récapitulatif
                        </Button>
                        <Button
                            disabled={reasonToLock}
                            variant="contained"
                            onClick={formContext.handleSubmit((factureToSubmit) => {
                                const cloneFactureToSubmit = { ...factureToSubmit };
                                cloneFactureToSubmit.goToNext = true;

                                handleSubmit(cloneFactureToSubmit as FactureFormObject);
                            }, handleInvalid)}
                            style={{ marginTop: '16px', marginBottom: '16px' }}
                        >
                            Enregistre - Saisie Suivante
                        </Button>
                    </Box>
                );
            },
            [
                formContext,
                handleInvalid,
                handleSubmit,
                navigateToStatementRecap,
                onCancel,
                actionOnExitForm,
                statementid,
                validSubmit,
            ],
        );

        const deleteMediaObject = useDeleteMediaObject({
            mediaObject,
        });

        const updateSociete = useUpdateSociete({
            mediaObject,
        });

        const treatmentRefusal = useTreatmentRefusal({
            mediaObject,
        });

        useAddCustomContextActions([
            treatmentRefusal,
            ...(isInterne ? [deleteMediaObject, updateSociete] : []),
        ]);

        return (
            <>
                <FlexyForm
                    formObject={facture}
                    formStructure={handleSections.factureFormStructure}
                    onSubmit={() => {
                        modalActions.call(
                            <ConfirmationModalContent
                                key={`${formContext}-${confirmationSaisieFactureModalMessages}`}
                                messages={confirmationSaisieFactureModalMessages}
                                actionOnValidation={() => {
                                    formContext.handleSubmit(
                                        (d) => {
                                            setValidSubmit(true);
                                            handleSubmit(d as FactureFormObject);
                                        },
                                        () => handleInvalid,
                                    )();
                                }}
                                actionOnCancellation={() => {
                                    setValidSubmit(false);
                                }}
                            />,
                        );
                    }}
                    onInvalid={handleInvalid}
                    formContext={formContext}
                    submitButton={{
                        render: (formstate, innerHandleSubmit) => {
                            if (stateHeaderSectionStructure.temporaryStatementId.value) {
                                return renderedActionButtonsStatement(formstate, innerHandleSubmit);
                            }
                            return renderedActionButtons(formstate, innerHandleSubmit);
                        },
                    }}
                />
                {showOverlay && <FormOverlay />}
            </>
        );
    };

export default RegisterSimpleInvoiceForm;
