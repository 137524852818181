import { AppDispatch, getSpecifiedDataSourceByViewName } from '@b2d/redux/RootStore';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { DataSource, DataSourcesThunksType } from '@europrocurement/l2d-redux-utils';
import { filterOptionsProps } from '@b2d/pages/Achats/components/forms/types';
import useHandleError from '@b2d/hooks/useHandleError';
import useAsyncStatus from '@b2d/hooks/useAsyncStatus';
import { MediaObject } from '@europrocurement/l2d-domain';
import { ViewName } from './types';
import useApplyCriteria from './useApplyCriteria';

type UseFetchDataByViewNameProps<T> = {
    dataSourcesThunks: DataSourcesThunksType<T>;
    dataSource: DataSource<T>;
    filterOptions: filterOptionsProps;
    viewName: ViewName;
};

/**
 * useFetchDataByViewName
 *
 * Usage:
 * This hook should be used within components that correspond to specific views in the MediaObject context,
 * where each view has distinctly defined data fetching requirements.
 *
 * @param {UseFetchDataByViewNameProps<T>} {
 *     dataSourcesThunks,    // Thunks for data fetching operations, specific to the data type T
 *     dataSource,           // Current data source configuration and state
 *     filterOptions,        // Filter options used to refine the data fetch criteria
 *     viewName              // Name of the view, which corresponds to specific data fetch logic
 * }
 * @returns {object} {
 *     fetchFilteredData,   // Function to initiate the data fetching process
 *     status               // Object containing the current status of the fetching operation
 * }
 */
const useFetchDataByViewName = <T extends MediaObject>({
    dataSourcesThunks,
    dataSource,
    filterOptions,
    viewName,
}: UseFetchDataByViewNameProps<T>) => {
    const dispatch = useDispatch<AppDispatch>();
    const { handleFailure } = useHandleError();
    const { status, startLoading, setSuccess, setError } = useAsyncStatus();
    const { setCriteria } = useApplyCriteria({ dataSource, filterOptions, viewName });

    /**
     * Dispatch the action to fetch filtered data
     */
    const fetchByViewName = useCallback(() => {
        dispatch(getSpecifiedDataSourceByViewName(dataSourcesThunks, viewName)({}))
            .then(() => {
                setSuccess();
            })
            .catch((error: Error) => {
                setError(error.message);
            });
    }, [dataSourcesThunks, dispatch, setError, setSuccess, viewName]);

    /**
     * Fetch data based on criteria where criteria depends on view mounted.
     */
    const fetchFilteredData = useCallback(() => {
        startLoading();

        try {
            setCriteria();
            setTimeout(() => {
                fetchByViewName();
            }, 125);
        } catch (error) {
            handleFailure({ error, withToaster: true, setError });
        }
    }, [fetchByViewName, handleFailure, setCriteria, setError, startLoading]);

    return {
        fetchFilteredData,
        status,
    };
};

export default useFetchDataByViewName;
