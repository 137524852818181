import * as React from 'react';
import { Box, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { FlexyHeaderForm } from '@europrocurement/flexy-components';

interface FrequencyItem {
    label: string;
    value: number;
}

type NotificationsFrequenciesProps = {
    isUpdating: boolean;
    frequency: FrequencyItem['value'];
    updateFrequency: (newValue: number) => void;
};

const NotificationsFrequencies: React.FunctionComponent<NotificationsFrequenciesProps> = function ({
    isUpdating,
    frequency,
    updateFrequency,
}) {
    const frequenciesTypes: Array<FrequencyItem> = [
        {
            label: 'Désactivé',
            value: 0,
        },
        {
            label: 'Quotidien',
            value: 1,
        },
        {
            label: 'Hebdomadaire',
            value: 2,
        },
        {
            label: 'Mensuel',
            value: 3,
        },
    ];

    return (
        <Box>
            <FlexyHeaderForm
                sx={{ marginTop: '20px' }}
                label="Fréquences des notifications"
                outlined
            />
            <RadioGroup
                sx={{ flexGrow: '1' }}
                aria-labelledby="frequency-radio-buttons-group-label"
                name="frequency-buttons-group"
                value={frequency}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const newValue = parseInt(event.target.value, 10) as 0 | 1 | 2 | 3;
                    updateFrequency(newValue);
                }}
            >
                <Box
                    display="flex"
                    justifyContent="space-between"
                    marginTop="30px"
                >
                    {frequenciesTypes.map(({ value, label }) => (
                        <FormControlLabel
                            value={value}
                            control={<Radio />}
                            label={label}
                            disabled={isUpdating}
                        />
                    ))}
                </Box>
            </RadioGroup>
        </Box>
    );
};

export default NotificationsFrequencies;
