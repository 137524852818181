import type { FactureFormObject, LigneWrite } from '@b2d/pages/Achats/components/forms/types';
import formProductLineToApiProductLine from './formProductLineToApiProductLine';

/**
 * Convert all product lines from form to api format.
 *
 * @param formInvoicePurchase
 * @param invoiceIri - Needed only in case the line must be associated to existing invoice.
 * @returns
 */
export default (formInvoicePurchase: FactureFormObject, invoiceIri?: string): LigneWrite[] =>
    formInvoicePurchase.produits.map((product) =>
        formProductLineToApiProductLine({
            formProductLine: product,
            folioPublication: formInvoicePurchase.dossier,
            folioFormality: formInvoicePurchase.dossier_formalite,
            invoiceIri,
        }),
    );
