import { updateToken } from '@b2d/keycloak';
import {
    APIENV,
    FormulaireConfigurationParameters,
    getCustomiserState,
    FormulairesConfiguration,
    FormulaireFormaliteApi,
    createFormulairesFormaliteSlice,
    FORMULAIRE_FORMALITE_SLICE_NAME,
} from '@europrocurement/l2d-domain';
import { combineReducers } from '@reduxjs/toolkit';

/**
 * Configuation
 */
const configFormulaire: FormulaireConfigurationParameters = {
    accessToken: updateToken,
    basePath: APIENV.apis.formulaire[getCustomiserState().api.formulaire],
};

export const formulairesConfiguration = new FormulairesConfiguration(configFormulaire);

export const formulaireApi = new FormulaireFormaliteApi(formulairesConfiguration);

export const { formulaireFormaliteSlice, formulaireFormaliteDataSourcesThunks } =
    createFormulairesFormaliteSlice(configFormulaire);

export const combinedFormulaireReducer = combineReducers({
    [FORMULAIRE_FORMALITE_SLICE_NAME]: formulaireFormaliteSlice.reducer,
});

export const getFormulaireFormalites = formulaireFormaliteDataSourcesThunks.main.getData;
export const selectFormulaireFormality = formulaireFormaliteDataSourcesThunks.main.getSelected;
