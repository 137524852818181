import React, { FunctionComponent, useEffect } from 'react';

import { useFakeProgressBar } from '@europrocurement/flexy-components/hooks';
import DisplayFile, { currentUploadStatus } from '../../atoms/DisplayFile';

export type UploadedFileProps = {
    file: File; // nom, poid , mimetype ( changer l'icone en fonction du mimetype )

    status?: currentUploadStatus;

    onDelete?: () => void;
    onEdit?: (file: File) => void;
};

/**
 * - [X] rend une card
 * - [X] Affiche le nom du fichier
 * - [X] Affiche le poid
 * - [X] Si mimetype connu ( jpg - png - pdf - doc ) => affiche l'icone correspondante - Sinon FaFile
 * - [X] Si on delete => affiche la poubelle
 * - [ ] Si on edit => affiche le bouton + upload nouveau fichier (UploadedFile)
 * - [X] status:
 *      'idle'
 *          couleur gris
 *      'pending'
 *          couleur gris
 *          bare de progression ( fake la valeur de progression avec https://github.com/piercus/fake-progress )
 *      'done'
 *          couleur success
 *          icone faCheck
 *          icone download
 *      'error'
 *          couleur error
 *          icone faTimes
 *
 *
 */

const UploadedFile: FunctionComponent<UploadedFileProps> = function ({
    file,
    status = 'done',
    onDelete,
    onEdit,
}) {
    const { startProgress, endProgress, started } = useFakeProgressBar();

    useEffect(() => {
        if (status === 'pending' && !started) {
            startProgress();
        }
    }, [status, startProgress, started]);

    useEffect(() => {
        if (status !== 'pending' && started) {
            endProgress();
        }
    }, [status, endProgress, started]);

    return (
        <DisplayFile
            nom={file.name}
            size={file.size}
            mimetype={file.type}
            status={status}
            onDelete={onDelete}
            onEdit={onEdit}
        />
    );
};

export default UploadedFile;
