import { ConfirmationModal, useModal } from '@europrocurement/flexy-components';
import { FaOptionIcon, deleteIcon } from '@europrocurement/l2d-icons';
import { Button, useTheme } from '@mui/material';
import React from 'react';

type TableRowActionsProps = {
    itemId: string;
    removeItem?: () => void;
};

const TableRowActions: React.FunctionComponent<TableRowActionsProps> = function (props) {
    const { itemId, removeItem = null } = props;
    const actions = [];
    const { modalActions } = useModal();
    const theme = useTheme();

    if (removeItem) {
        const onRemove = (e: React.MouseEvent<HTMLElement>) => {
            e.preventDefault();
            e.stopPropagation();
            modalActions.call(
                <ConfirmationModal
                    messages={{
                        question: 'Confirmation ?',
                        detail: {
                            message: 'Êtes vous sur de vouloir supprimer cet élément ?',
                            severity: 'info',
                        },
                    }}
                    actionOnValidation={() => {
                        removeItem();
                        modalActions.close();
                    }}
                    actionOnCancellation={() => modalActions.close()}
                    color={theme.palette.primary}
                />,
            );
        };

        actions.push(
            <Button
                key={itemId}
                onClick={onRemove}
            >
                <FaOptionIcon {...deleteIcon.props} />
            </Button>,
        );
    }

    return actions;
};

export default TableRowActions;
