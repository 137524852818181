import React, { FunctionComponent, useState } from 'react';

import achatsViewPathResolver from '@b2d/pages/Achats/achatsViewPathResolver';
import { mediaObjectApi } from '@b2d/redux/RootStore';
import { DataCard } from '@europrocurement/flexy-components';
import { FaOptionIcon } from '@europrocurement/l2d-icons';
import { faFileInvoice } from '@fortawesome/pro-duotone-svg-icons';
import { faPenToSquare } from '@fortawesome/pro-solid-svg-icons';
import { Button, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router';

// All

const FacturesATraiter: FunctionComponent = function () {
    const navigate = useNavigate();

    const [countOfUnregisteredInvoicesToTreat, setCountOfUnregisteredInvoicesToTreat] =
        useState<string>('...');

    mediaObjectApi
        .listMediaObjectMediaObjectCollection({
            page: 1,
            itemsPerPage: 0,
            // createdBy: '',
            forceInterne: false,
            dateDebutTraitementBefore: new Date(new Date().getTime() - 2 * 60 * 1000).toISOString(),
            available: true,
        })
        .then((res) => {
            if (
                res.data['hydra:totalItems'] !== null ||
                res.data['hydra:totalItems'] !== undefined
            ) {
                setCountOfUnregisteredInvoicesToTreat(`${res.data['hydra:totalItems']}`);
            }
        });

    return (
        <Grid
            sx={{
                flex: 1,
            }}
            item
            lg={1}
            md={1}
            xs={12}
        >
            <DataCard
                title="Factures à traiter"
                color="success"
                iconBgColor="transparent"
                iconColor="transparent"
                icon={
                    <FaOptionIcon
                        icon={faFileInvoice}
                        option={faPenToSquare}
                        size="2x"
                        color="success.main"
                        optionColor="success.dark"
                        iconProps={{
                            radius: 100,
                        }}
                    />
                }
                tools={
                    <Button
                        variant="outlined"
                        color="success"
                        onClick={() => {
                            navigate(achatsViewPathResolver('listmediaobject'));
                        }}
                    >
                        Traiter
                    </Button>
                }
            >
                <Typography
                    sx={{
                        fontSize: '18px',
                        textAlign: 'center',
                    }}
                >
                    Factures à traiter :
                </Typography>
                <Typography
                    component="h2"
                    color="success.main"
                    sx={{
                        fontSize: '32px',
                        textAlign: 'center',
                    }}
                >
                    <b>{countOfUnregisteredInvoicesToTreat}</b>
                </Typography>
            </DataCard>
        </Grid>
    );
};

export default FacturesATraiter;
