export class KeycloakRoleMalFormedError extends Error {
    constructor(inputMessage?: string) {
        /**
         * Possibilités des droits :
         *      undefined : je verif juste que l'utilisateur est connecté
         *      'chaineSimple' : je verif que j'ai le droit sur le client courant sur lequel je suis
         *          ( si on est sur backToDigital ca correspond a backToDigital:chaineSimple )
         *
         *      'monApi:monRole' : je verif que l'utilisateur a le droit sur un autre client
         *          exemple 'api-tiers:tiers-read'
         *
         *      'realm:monRoleGeneral' : je verif que l'utilisateur a le droit monRoleGeneral sur le realm
         *          exemple 'realm:interne'
         *
         *      on peu aussi composer :
         *          [ 'api-tiers:tiers-read', 'realm:interne' ] => un utilisateur interne qui a les droit de lister les tiers
         */
        let message =
            'Role malformed, role should be undefined to only check auth, "myrole" to check client role or "myressource:myrole"|"realm:myrole" to check role on ressource|realm or an array of roles ex: ["myrole","myressource:myrole","realm:myrole"]';
        if (inputMessage) {
            message = inputMessage;
        }
        super(message);
        Object.setPrototypeOf(this, KeycloakRoleMalFormedError.prototype);
    }
}

export default KeycloakRoleMalFormedError;
