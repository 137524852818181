import * as React from 'react';

import {
    SidePanelCard,
    TopbarHeight,
    breadcrumbHeight,
    footerHeight,
} from '@europrocurement/flexy-components';
import FlexyDatatableFilters from '../FlexyDatatableFilters/FlexyDatatableFilters';
import FlexyDatatableTable, {
    FlexyDatatableProps,
} from '../FlexyDatatableTable/FlexyDatatableTable';

export const FlexyDatatableMaxHeight = `calc(100vh - ${footerHeight}px - ${TopbarHeight}px - ${breadcrumbHeight}px -48px  )`;

export const FlexyDatatable = function <T extends Record<string, unknown>>({
    sx,
    columnLabelVariant = 'h5',
    textCellVariant = 'h5',
    ...props
}: FlexyDatatableProps<T>) {
    return props.searchOpts && props.searchOpts.filtersOpts ? (
        <SidePanelCard
            data-testid="FlexyDatatable"
            rightMenu={<FlexyDatatableFilters filtersOpts={props.searchOpts.filtersOpts} />}
            sx={{
                width: '100%',
                height: FlexyDatatableMaxHeight,
                ...sx,
            }}
        >
            <FlexyDatatableTable
                columnLabelVariant={columnLabelVariant}
                textCellVariant={textCellVariant}
                {...props}
            />
        </SidePanelCard>
    ) : (
        <FlexyDatatableTable
            columnLabelVariant={columnLabelVariant}
            textCellVariant={textCellVariant}
            sx={{
                width: '100%',
                height: FlexyDatatableMaxHeight,
                ...sx,
            }}
            {...props}
        />
    );
};

export default FlexyDatatable;
