/* eslint-disable react/no-unstable-nested-components */
import * as React from 'react';

import logoB2D from '@europrocurement/l2d-icons/assets/img/Symbole-L2D-RVB-V1-64.png';
import { Box, Chip, IconButton, Tooltip, Typography } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

const OriginHeader: React.FunctionComponent = function () {
    const logoSize = 100;

    const FakeLogo = function () {
        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width={`${logoSize}px`}
                margin="30px 10px 40px 0"
            >
                <img
                    src={logoB2D}
                    style={{ maxHeight: `${logoSize}px`, color: 'red' }}
                    alt="Legal2digital 2023"
                />
            </Box>
        );
    };

    return (
        <Box>
            <Box
                display="flex"
                justifyContent="space-between"
            >
                <Box display="flex">
                    <FakeLogo />
                    <Typography sx={{ fontWeight: 'bold' }}> Origin name</Typography>
                </Box>

                <Tooltip title="Aperçu pour cette origine">
                    <Box>
                        <IconButton sx={{ padding: 0 }}>
                            <RemoveRedEyeIcon />
                        </IconButton>
                    </Box>
                </Tooltip>
            </Box>
            <Box
                display="flex"
                justifyContent="start"
                alignItems="center"
                marginBottom="30px"
            >
                <LanguageIcon sx={{ marginRight: '15px' }} />{' '}
                <Typography> https://www.originurl.dns</Typography>
            </Box>
            <Box
                display="flex"
                justifyContent="start"
                alignItems="center"
                sx={{ minHeight: '25px' }}
            >
                {Math.random() > 0.5 && (
                    <Chip
                        sx={{ marginRight: '10px' }}
                        label="Custom"
                        variant="outlined"
                    />
                )}
                {Math.random() > 0.5 && (
                    <Chip
                        sx={{ marginRight: '10px' }}
                        label="Formalités"
                        variant="outlined"
                    />
                )}
            </Box>
        </Box>
    );
};

export default OriginHeader;
