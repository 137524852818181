import React from 'react';
import { SignataireType } from '@europrocurement/l2d-domain';
import { TypographyProps } from '@mui/material';
import {
    FaOptionIcon,
    NamedIconsType,
    fileSignedIcon,
    signInProgressIcon,
    signPendingIcon,
    signRefusedIcon,
    type FaOptionIconProps,
} from '@europrocurement/l2d-icons';
// import { fileSignedIcon, signatureIcon } from '@europrocurement/l2d-icons';

export const signataireToIcon = (
    signataire: Pick<SignataireType, 'refus' | 'signature'>,
): NamedIconsType => {
    if (signataire.signature === true && !signataire.refus) {
        return fileSignedIcon;
    }
    if (signataire.signature === true && signataire.refus === true) {
        return signRefusedIcon;
    }
    return signPendingIcon;
};

export const signatairesToIcon = (
    signataires: Array<Pick<SignataireType, 'refus' | 'signature'>>,
): NamedIconsType => {
    const allStatus = signataires.map(signataireToIcon);

    if (allStatus.every((status) => status === fileSignedIcon)) {
        return fileSignedIcon;
    }
    if (allStatus.every((status) => status === signPendingIcon)) {
        return signPendingIcon;
    }

    if (allStatus.indexOf(signPendingIcon) !== -1) {
        return signInProgressIcon;
    }

    return signRefusedIcon;
};

export const signataireToColor = (
    signataire: Pick<SignataireType, 'refus' | 'signature'>,
): TypographyProps['color'] => {
    if (signataire.signature === true && !signataire.refus) {
        return 'success.main';
    }
    if (signataire.signature === true && signataire.refus === true) {
        return 'error.main';
    }
    return 'blueGrey.main';
};

export const signatairesToColor = (
    signataires: Array<Pick<SignataireType, 'refus' | 'signature'>>,
): TypographyProps['color'] => {
    const allStatus = signataires.map(signataireToColor);

    if (allStatus.every((status) => status === 'success.main')) {
        return 'success.main';
    }
    if (allStatus.every((status) => status === 'blueGrey.main')) {
        return 'blueGrey.main';
    }

    if (allStatus.indexOf('blueGrey.main') !== -1) {
        return 'warning.main';
    }

    return 'error.main';
};

export type SignataireStatusProps = {
    signataires: Array<Pick<SignataireType, 'refus' | 'signature'>>;
    tooltip?: boolean;
    iconOverides?: Partial<FaOptionIconProps>;
};

const SignatureStatus: React.FunctionComponent<SignataireStatusProps> = function ({
    signataires,
    iconOverides = {},
    tooltip,
}) {
    return (
        <FaOptionIcon
            {...signatairesToIcon(signataires).props}
            tooltip={tooltip ? signatairesToIcon(signataires).displayName : undefined}
            {...iconOverides}
        />
    );
};

export default SignatureStatus;
