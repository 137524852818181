import React, { FunctionComponent, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '@b2d/redux/RootStore';
import { getDossierSignature, MainDossierSignatureSelector } from '@b2d/redux/subReducers';
import {
    ColumnDatatable,
    FiltersDatatableList,
    StoreDatatable,
} from '@europrocurement/flexy-datatable';
import { DossierSignatureApiObject } from '@europrocurement/l2d-domain';
import {
    CenterCircularProgress,
    ContextActionType,
    LazyBreadcrumb,
    Legend,
    useDumbActionsContext,
} from '@europrocurement/flexy-components';
import { FaOptionIcon, newSignIcon, signNamedIconsListe } from '@europrocurement/l2d-icons';
import { Typography } from '@mui/material';
import { signatureListeFullPath, signatureNewFullPath } from '../../constants/paths';
import SignatureStatus from '../../components/SignatureStatus';
import DossierSignatureListeActions from './DossierSignatureListeActions';

const isoTolocalDate = (maDateIso: string) => new Date(Date.parse(maDateIso)).toLocaleDateString();

const DossierSignatureListe: FunctionComponent = function () {
    const dataSource = useAppSelector(MainDossierSignatureSelector);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(getDossierSignature({}));
    }, [dispatch]);

    const contextActions: ContextActionType[] = [
        {
            name: 'Nouveau dossier de signature',
            icon: <FaOptionIcon {...newSignIcon.props} />,
            action: () => navigate(signatureNewFullPath),
        },
    ];

    useDumbActionsContext(contextActions);

    const dossierSignatureColumns: ColumnDatatable<DossierSignatureApiObject>[] = [
        {
            isDisplayed: true,
            label: 'Sujet',
            render: (item: DossierSignatureApiObject) => (
                <>
                    <Typography
                        display="inline"
                        sx={{
                            fontSize: '20px',
                        }}
                    >
                        <SignatureStatus
                            signataires={item.signataires}
                            tooltip
                        />
                    </Typography>
                    <Typography
                        display="inline"
                        sx={{ marginLeft: '15px' }}
                    >
                        {item.sujet}
                    </Typography>
                </>
            ),
            field: 'sujet',
            sortable: true,
        },
        {
            isDisplayed: false,
            label: 'Référence externe',
            render: 'referenceExterne',
        },
        {
            isDisplayed: false,
            label: 'Date de création',
            render: (item: DossierSignatureApiObject) => {
                if (item.createdAt) {
                    return isoTolocalDate(item?.createdAt);
                }
                return '';
            },
            sortable: true,
            field: 'createdAt',
        },
        {
            isDisplayed: false,
            label: 'Date de modification',
            render: (item: DossierSignatureApiObject) => {
                if (item.updatedAt) {
                    return isoTolocalDate(item?.updatedAt);
                }
                return '';
            },
            sortable: true,
            field: 'updatedAt',
        },
        {
            isDisplayed: true,
            label: 'Créateur',
            render: (item: DossierSignatureApiObject) => `${item.prescripteur}`,
            sortable: true,
            field: 'prescripteur',
        },
        {
            isDisplayed: true,
            label: 'Email du créateur',
            render: (item: DossierSignatureApiObject) => `${item.prescripteurEmail}`,
            sortable: true,
            field: 'prescripteurEmail',
        },
        {
            isDisplayed: true,
            label: 'Référentiel',
            render: (item: DossierSignatureApiObject) => `${item.demandeur?.nom}`,
            sortable: true,
            field: 'demandeur',
        },
        {
            label: 'Actions',
            displayLabel: false,
            render: (item) =>
                item.id && (
                    <DossierSignatureListeActions
                        signataires={item.signataires}
                        itemId={item.id}
                    />
                ),
            isDisplayed: true,
        },
    ];

    const dossierSignatureFilters: FiltersDatatableList = [
        {
            label: 'Dossiers terminé',
            field: 'termine',
            type: 'boolean',
        },
        {
            label: 'Dossiers refusé',
            field: 'refuse',
            type: 'boolean',
        },
        {
            label: 'Sujet',
            field: 'sujet',
            type: 'text',
        },
        {
            label: 'Référence externe',
            field: 'referenceExterne',
            type: 'text',
        },
        {
            label: 'Créateur',
            field: 'prescripteur',
            type: 'text',
        },
        {
            label: 'Email du créateur',
            field: 'prescripteurEmail',
            type: 'text',
        },
        {
            label: 'Demandeur',
            field: 'demandeurNom',
            type: 'text',
        },
    ];

    return (
        <>
            <LazyBreadcrumb
                path={[
                    { path: 'Signature électronique', link: signatureListeFullPath },
                    'Liste des dossiers',
                ]}
            />
            {dataSource ? (
                <StoreDatatable
                    filters={dossierSignatureFilters}
                    columns={dossierSignatureColumns}
                    dataSource={dataSource}
                    fetchData={getDossierSignature}
                    localStorageKey="dossierSignatureListe"
                    // hideColumnOptions={true}
                    onClickRow={(e, item: DossierSignatureApiObject) => navigate(`${item.id}`)}
                />
            ) : (
                <CenterCircularProgress sx={{ height: '500px' }} />
            )}

            <Legend icons={signNamedIconsListe} />
        </>
    );
};

export default DossierSignatureListe;
