import {
    APIENV,
    DOSSIER_SIGNATURE_SLICE_NAME,
    DossierSignatureConfiguration,
    DossierSignatureConfigurationParameters,
    createSignatureReducer,
    DemandeurSignatureApi,
    DEMANDEUR_SIGNATURE_SLICE_NAME,
    getCustomiserState,
} from '@europrocurement/l2d-domain';
import { combineReducers } from '@reduxjs/toolkit';
import { DossierApi } from '@europrocurement/l2d-domain/openApi/ApiSign';
import { updateToken } from '../../../keycloak';

/**
 * Configuation
 */
const configDossierSignature: DossierSignatureConfigurationParameters = {
    accessToken: updateToken,
    basePath: APIENV.apis.sign[getCustomiserState().api.sign],
};

const apiSignConfig = new DossierSignatureConfiguration(configDossierSignature);

export const dossierSignatureApi = new DossierApi(apiSignConfig);

export const demandeurSignatureApi = new DemandeurSignatureApi(apiSignConfig);

export const {
    dossierSignatureDataSourcesThunks,
    dossierSignatureSlice,
    demandeurSignatureDataSourcesThunks,
    demandeurSignatureSlice,
} = createSignatureReducer(configDossierSignature);

export const combinedDossierSignatureReducer = combineReducers({
    [DOSSIER_SIGNATURE_SLICE_NAME]: dossierSignatureSlice.reducer,
    [DEMANDEUR_SIGNATURE_SLICE_NAME]: demandeurSignatureSlice.reducer,
});
export const getDossierSignature = dossierSignatureDataSourcesThunks.main.getData;
export const selectDossierSignature = dossierSignatureDataSourcesThunks.main.getSelected;

export const getDemandeurSignature = demandeurSignatureDataSourcesThunks.main.getData;
export const selectDemandeurSignature = demandeurSignatureDataSourcesThunks.main.getSelected;
