import React, { FunctionComponent } from 'react';

import { ErrorPage } from '../ErrorPage';

export type NotFoundProps = {
    message?: string;
};

export const NotFound: FunctionComponent<NotFoundProps> = function ({
    message = 'Page non trouvée',
}) {
    return (
        <ErrorPage
            statusCode={404}
            message={message}
        />
    );
};
export default NotFound;
