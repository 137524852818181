import * as React from 'react';
import { CenterCircularProgress } from '@europrocurement/flexy-components';
import { KeycloakRoute } from '@europrocurement/l2d-keycloak';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router';
import { DataSource } from '@europrocurement/l2d-redux-utils';
import { CspOptions, Pays, WalletOptions } from '@europrocurement/l2d-domain';
import {
    CivilitiesSelector,
    CspOptionsSelector,
    PaysSelector,
    WalletOptionsSelector,
    getCivilities,
    getCspOptions,
    getPays,
    getWalletOptions,
    useAppDispatch,
} from '../../../redux/RootStore';
import { Civilite } from '../forms/utils';

const PrescipteurRoot = function () {
    const paysDatasource: DataSource<Pays> = useSelector(PaysSelector).main;
    const walletOptionsDatasource: DataSource<WalletOptions> =
        useSelector(WalletOptionsSelector).main;
    const cspOptionsDatasource: DataSource<CspOptions> = useSelector(CspOptionsSelector).main;
    const civilitiesDatasource: DataSource<Civilite> = useSelector(CivilitiesSelector).main;

    const optionsToLoadAtStartUp = React.useMemo(
        () => [
            { dataSource: paysDatasource, action: getPays },
            { dataSource: walletOptionsDatasource, action: getWalletOptions },
            { dataSource: cspOptionsDatasource, action: getCspOptions },
            { dataSource: civilitiesDatasource, action: getCivilities },
        ],
        [civilitiesDatasource, cspOptionsDatasource, paysDatasource, walletOptionsDatasource],
    );

    const dispatch = useAppDispatch();

    const loadInitialOptions = React.useCallback(() => {
        optionsToLoadAtStartUp.forEach(({ dataSource, action }) => {
            const { status } = dataSource;
            if (status === 'idle') {
                dispatch(action({}));
            }
        });
    }, [dispatch, optionsToLoadAtStartUp]);

    const optionsLoadingStatus = optionsToLoadAtStartUp.map(({ dataSource }) => {
        const { status } = dataSource;

        return status;
    });

    const allOptionsAreLoaded = optionsLoadingStatus.every((status) => status === 'succeeded');

    React.useEffect(() => {
        loadInitialOptions();
    }, [loadInitialOptions]);

    const isApplicationReady = () => allOptionsAreLoaded;

    return isApplicationReady() ? (
        <KeycloakRoute roles="prescripteur">
            <Outlet />
        </KeycloakRoute>
    ) : (
        <CenterCircularProgress />
    );
};

export default PrescipteurRoot;
