import React from 'react';

import { ColumnDatatable } from '@europrocurement/flexy-datatable';
import { MediaObject } from '@europrocurement/l2d-domain';
import { Typography } from '@mui/material';

const columnItemsTsBlame: Array<ColumnDatatable<MediaObject>> = [
    {
        label: 'Date de dépôt',
        render: (mediaObject: MediaObject) => {
            let moCreationDate = null;

            if (mediaObject.createdAt) {
                moCreationDate = new Date(Date.parse(mediaObject.createdAt)).toLocaleDateString();
            }

            return <Typography>{moCreationDate}</Typography>;
        },
        field: 'createdAt',
        sortable: true,
        isDisplayed: true,
    },
    {
        label: 'Déposé par',
        render: 'createdBy',
        isDisplayed: true,
    },
];

export default columnItemsTsBlame;
