import React, { useContext } from 'react';

import { Box, Button } from '@mui/material';

import { ModalContext } from '@europrocurement/flexy-components';

import NoteModalForm from '../informationWidget/NoteModalForm';

type NotesButtonProps = {
    idMediaObject: number;
    idFacture: number;
    idDossier: number;
};

const NotesButton: React.FC<NotesButtonProps> = function ({ idMediaObject, idFacture, idDossier }) {
    const { modalActions } = useContext(ModalContext);

    return (
        <Box>
            <Button
                onClick={() => {
                    modalActions.reset();
                    modalActions.call(
                        <NoteModalForm
                            idMediaObject={idMediaObject}
                            idFacture={idFacture}
                            idDossier={idDossier}
                        />,
                    );
                }}
                variant="outlined"
                size="small"
                color="secondary"
                sx={{
                    padding: '5px 15px',
                }}
            >
                Notes
            </Button>
        </Box>
    );
};

export default NotesButton;
