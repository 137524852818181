import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@b2d/redux/RootStore';
import { DataSource } from '@europrocurement/l2d-redux-utils';
import { Formalite } from '@europrocurement/l2d-domain/reducers/formalites/slices/formalite';
import { formalitiesSelector } from '@b2d/redux/subReducers/FormalityReducer/Selectors';
import { selectFormality } from '@b2d/redux/subReducers/FormalityReducer';

type UseFormaliteProps = {
    formalityId: string | undefined;
};

const useFormality = (props: UseFormaliteProps) => {
    const { formalityId } = props;
    const formaliteDataSource: DataSource<Formalite> = useSelector(formalitiesSelector);
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        if (formalityId) {
            dispatch(selectFormality({ id: formalityId }));
        }
    }, [dispatch, formalityId]);

    const formality = useMemo(() => formaliteDataSource.selected, [formaliteDataSource.selected]);

    return { formality, requestState: formaliteDataSource.selectedStatus };
};

export default useFormality;
