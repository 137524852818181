import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@mui/material';
import * as React from 'react';

type TooltipedIconProps = {
    tooltip: string | (React.JSX.Element | null)[];
    icon: IconDefinition;
};

const TooltipedIcon: React.FC<TooltipedIconProps> = function ({ tooltip, icon }) {
    return (
        <Tooltip title={tooltip}>
            <FontAwesomeIcon icon={icon} />
        </Tooltip>
    );
};

export default TooltipedIcon;
