import React from 'react';
import { FormaliteDocument } from '@europrocurement/l2d-domain';
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    List,
    ListItem,
    Typography,
    useTheme,
} from '@mui/material';
import { ActionsBar, ConfirmationModal, useModal } from '@europrocurement/flexy-components';
import { deleteIcon, FaOptionIcon } from '@europrocurement/l2d-icons';
import NoResultPreview from '../../components/NoResultPreview';

export type DocumentsCardProps = {
    title: string;
    documents: Array<FormaliteDocument>;
    type: number;
    onOpenModal: (type: number) => void;
    onRemoveItem: (documentId: number) => void;
};

const DocumentsCard: React.FunctionComponent<DocumentsCardProps> = function (props) {
    const { modalActions } = useModal();
    const theme = useTheme();
    const { title, documents, type, onOpenModal, onRemoveItem } = props;
    const rows = documents.sort((a: FormaliteDocument, b: FormaliteDocument) => {
        const aLabel = a.libelle as unknown as string;
        const bLabel = b.libelle as unknown as string;

        if (aLabel.toLowerCase() < bLabel.toLowerCase()) {
            return -1;
        }

        if (aLabel.toLowerCase() > bLabel.toLowerCase()) {
            return 1;
        }

        return 0;
    });

    const onRemove = (documentId: number) => {
        modalActions.call(
            <ConfirmationModal
                messages={{
                    question: 'Confirmation ?',
                    detail: {
                        message: 'Êtes vous sur de vouloir supprimer cet élément ?',
                        severity: 'info',
                    },
                }}
                actionOnValidation={() => {
                    onRemoveItem(documentId);
                    modalActions.close();
                }}
                actionOnCancellation={() => {
                    modalActions.close();
                }}
                color={theme.palette.primary}
            />,
        );
    };
    const editAction = () => onOpenModal(type);
    const content = rows.length ? (
        <List>
            {rows.map((item) => (
                <ListItem
                    key={item.id}
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'baseline',
                    }}
                >
                    {item.libelle as string}
                    <Button onClick={() => onRemove(item.id as number)}>
                        <FaOptionIcon {...deleteIcon.props} />
                    </Button>
                </ListItem>
            ))}
        </List>
    ) : (
        <NoResultPreview message="Aucun type de document" />
    );

    return (
        <Card sx={{ maxHeight: '100%', overflow: 'auto' }}>
            <CardHeader
                title={<Typography fontWeight="bold">{title}</Typography>}
                action={<ActionsBar editAction={editAction} />}
            />
            <CardContent>{content}</CardContent>
        </Card>
    );
};

export default DocumentsCard;
