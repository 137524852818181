import React from 'react';

import { Grid, Typography } from '@mui/material';

import ImportToast, { FileUploadItemProps } from './ImportToast';

type ImportToasterProps = {
    filesUploading: Array<FileUploadItemProps>;
};

const ImportToaster = function ({ filesUploading }: ImportToasterProps) {
    return (
        <>
            {filesUploading.length === 0 && (
                <Grid
                    item
                    height="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    xs={12}
                >
                    <Typography
                        color="GrayText"
                        sx={{
                            opacity: 0.5,
                        }}
                    >
                        Pas de fichiers importés pour le moment.
                    </Typography>
                </Grid>
            )}
            <Grid
                container
                spacing={2}
            >
                {filesUploading.map((fileUploading) => (
                    <ImportToast
                        key={`${fileUploading.file.name}-${fileUploading.file.size}-${fileUploading.status}`}
                        fileUploading={fileUploading}
                    />
                ))}
            </Grid>
        </>
    );
};

export default ImportToaster;
