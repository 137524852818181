import {
    addDays,
    startOfWeek,
    endOfWeek,
    addWeeks,
    startOfMonth,
    endOfMonth,
    addMonths,
    startOfYear,
    endOfYear,
    addYears,
} from 'date-fns';

import { fr } from 'date-fns/locale';
import { DefinedRange } from './types';

// function mapLocalLabel translate the label in french
const mapLocalLabel = (label: string, locale: Locale): string => {
    if (locale.code === 'en') {
        return label;
    }
    if (locale.code === 'fr') {
        switch (label) {
            case 'Today':
                return "Aujourd'hui";
            case 'Yesterday':
                return 'Hier';
            case 'This Week':
                return 'Cette semaine';
            case 'Last Week':
                return 'La semaine dernière';
            case 'Last 7 Days':
                return 'Les 7 derniers jours';
            case 'This Month':
                return 'Ce mois-ci';
            case 'Last Month':
                return 'Le mois dernier';
            case 'This Year':
                return 'Cette année';
            case 'Last Year':
                return "L'année dernière";
            default:
                return label;
        }
    }
    return label;
};

export const getDefaultRanges = (date: Date, locale: Locale = fr): DefinedRange[] => [
    {
        label: mapLocalLabel('Today', locale),
        startDate: date,
        endDate: date,
    },
    {
        label: mapLocalLabel('Yesterday', locale),
        startDate: addDays(date, -1),
        endDate: addDays(date, -1),
    },
    {
        label: mapLocalLabel('This Week', locale),
        startDate: startOfWeek(date, { locale }),
        endDate: endOfWeek(date, { locale }),
    },
    {
        label: mapLocalLabel('Last Week', locale),
        startDate: startOfWeek(addWeeks(date, -1), { locale }),
        endDate: endOfWeek(addWeeks(date, -1), { locale }),
    },
    {
        label: mapLocalLabel('Last 7 Days', locale),
        startDate: addWeeks(date, -1),
        endDate: date,
    },
    {
        label: mapLocalLabel('This Month', locale),
        startDate: startOfMonth(date),
        endDate: endOfMonth(date),
    },
    {
        label: mapLocalLabel('Last Month', locale),
        startDate: startOfMonth(addMonths(date, -1)),
        endDate: endOfMonth(addMonths(date, -1)),
    },
    {
        label: mapLocalLabel('This Year', locale),
        startDate: startOfYear(date),
        endDate: endOfYear(date),
    },
    {
        label: mapLocalLabel('Last Year', locale),
        startDate: startOfYear(addYears(date, -1)),
        endDate: endOfYear(addYears(date, -1)),
    },
];
