import axios from 'axios';
// import keycloak from '@root/.storybook/keycloak';

const AxiosClient = axios.create({
    // baseURL: 'http://localhost:8080/tiers',
    baseURL: 'https://api.qual.legal2digital.net/tiers',
    headers: {
        'Content-type': 'application/json',
        'X-Id-Societe': '5',
    },
});

export default AxiosClient;
