import React, { useCallback } from 'react';
import { Uploader, CenterCircularProgress } from '@europrocurement/flexy-components';
import { Card, Grid, Box, Typography } from '@mui/material';

import { DossierJsonldWrite } from '@europrocurement/l2d-domain/openApi/ApiSign';
import { useNavigate } from 'react-router';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { signatureListeFullPath } from '../../constants/paths';
import DossierSignatureForm from '../../forms/DossierSignatureForm';
import { useCreateDossierSignature } from '../../functions/createFunctions';

export type DossierSignatureFormWrapperType = {
    defaultValues?: Partial<DossierJsonldWrite>;
};

const DossierSignatureFormWrapper: React.FunctionComponent<DossierSignatureFormWrapperType> =
    function ({ defaultValues = {} }) {
        const [innerDefaultValues, setInnerDefaultValues] = React.useState(defaultValues);
        const [loading, setloading] = React.useState(false);
        const { enqueueSnackbar } = useSnackbar();

        const navigate = useNavigate();

        const createDossierSignature = useCreateDossierSignature();

        const createDossier = useCallback(
            async (formData: Partial<DossierJsonldWrite>) => {
                setInnerDefaultValues(formData);
                setloading(true);
                try {
                    await createDossierSignature(formData, (id) => {
                        setloading(false);
                        navigate(`${signatureListeFullPath}/${id}`);
                    });
                } catch (error) {
                    if (
                        error instanceof AxiosError &&
                        error.response &&
                        error.response.data.exception &&
                        error.response.data.exception.message
                    ) {
                        enqueueSnackbar(
                            <Typography>{error.response.data.exception.message}</Typography>,
                            {
                                variant: 'error',
                            },
                        );
                    } else if (error instanceof Error) {
                        enqueueSnackbar(<Typography>{error.message}</Typography>, {
                            variant: 'error',
                        });
                        setloading(false);
                    }
                }
            },
            [createDossierSignature, enqueueSnackbar, navigate],
        );

        return loading ? (
            <CenterCircularProgress sx={{ height: '500px' }} />
        ) : (
            <Grid container>
                <Grid
                    item
                    md={6}
                >
                    <Box>
                        <Uploader maxMbSize={10} />
                    </Box>
                </Grid>
                <Grid
                    item
                    md={6}
                >
                    <Card>
                        <DossierSignatureForm
                            afterSubmit={createDossier}
                            defaultValues={innerDefaultValues}
                        />
                    </Card>
                </Grid>
            </Grid>
        );
    };

export default DossierSignatureFormWrapper;
