import { library } from '@fortawesome/fontawesome-svg-core';

import { fad } from '@fortawesome/pro-duotone-svg-icons';
import { fasl } from '@fortawesome/sharp-light-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';

export * from './constants';
export * from './components';
export * from './types';

library.add(fas);
library.add(fad);
library.add(fasl);
