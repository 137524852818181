import { RootStateType } from '@b2d/redux/RootStore';
import { ARTICLES_SLICE_NAME, OFFRE_REDUCER_NAME } from '@europrocurement/l2d-domain';
import { ArticlesArticleUpdate } from '@europrocurement/l2d-domain/openApi/ApiOffre';
import { articleIcon } from '@europrocurement/l2d-icons';
import { articlesApi } from '@b2d/redux/subReducers/Offers';
import { articlesDataSourcesThunks } from '@b2d/redux/subReducers/Offers/reducers';
import { ModelConfiguration, OfferModelItem, ModelMethods } from './types';

const modelKey = 'article';
const modelName = 'Article';
const sliceName = ARTICLES_SLICE_NAME;
const api = articlesApi;
const baseSelector = (state: RootStateType) => state[OFFRE_REDUCER_NAME][sliceName];
const datasourcesThunks = articlesDataSourcesThunks;

type ModelStructure = ArticlesArticleUpdate;

const configuration: ModelConfiguration = {
    key: modelKey,
    name: modelName,
    type: 'Articles',
    selector: baseSelector,
    sliceName,
    api,
    datasourcesThunks,
    icon: articleIcon,
};

const methods: ModelMethods<ModelStructure> = {
    getItemDefaultLabel: (model: ModelStructure) => `${model.libelle}`,
    getItemSecondaryLabel: (model: ModelStructure) => `${model.libelle} (${model.prixUnitaire} €)`,
};

const endpoints = {
    list: (...args: Parameters<typeof api.apiArticlesGetCollection>) =>
        api.apiArticlesGetCollection(...args),
    delete: (...args: Parameters<typeof api.apiArticlesIdDelete>) =>
        api.apiArticlesIdDelete(...args),
    dependencies: (...args: Parameters<typeof api.apiArticlesIdDependenciesGet>) =>
        api.apiArticlesIdDependenciesGet(...args),
    read: (...args: Parameters<typeof api.apiArticlesIdGet>) => api.apiArticlesIdGet(...args),
    update: (...args: Parameters<typeof api.apiArticlesIdPut>) => api.apiArticlesIdPut(...args),
    create: (...args: Parameters<typeof api.apiArticlesPost>) => api.apiArticlesPost(...args),
};

type ModelType = OfferModelItem<ModelStructure> & typeof endpoints;

const model: ModelType = {
    ...configuration,
    ...methods,
    ...endpoints,
};

export type ArticleModel = ModelType;
export default model;
