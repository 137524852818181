import { FormaliteFormaliteRead } from '@europrocurement/l2d-domain/openApi/ApiOffre';
import { Checkbox, FormControlLabel } from '@mui/material';
import { Box } from '@mui/system';
import React, { FC } from 'react';

type Props = {
    activeIndex: number;
    index: number;
    formalities: FormaliteFormaliteRead[];
    toggleId: (id: number) => void;
    formalityIds: number[];
};

const FormalityFilterTab: FC<Props> = function (props) {
    const { activeIndex, formalities, index, toggleId, formalityIds } = props;

    const groupBySize = (groupSize: number) => {
        let groupCount = 0;
        const grouped = [];
        let subGroup = [];

        for (let i = 0; i < formalities.length; i++) {
            const formality = formalities[i];
            subGroup.push(formality);
            groupCount++;
            if (groupCount >= groupSize || i === formalities.length - 1) {
                grouped.push(subGroup);
                subGroup = [];
                groupCount = 0;
            }
        }

        return grouped;
    };

    const formalitiesGroupedByTwo = groupBySize(2);

    return (
        <div
            role="tabpanel"
            hidden={activeIndex !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
        >
            {activeIndex === index && (
                <Box
                    sx={{ p: 3, overflowX: 'hidden' }}
                    maxHeight="180px"
                    minHeight="180px"
                    width="100%"
                    overflow="scroll"
                >
                    {formalitiesGroupedByTwo.map((formalityGroups: FormaliteFormaliteRead[]) => (
                        <Box display="flex">
                            {formalityGroups.map((formality: FormaliteFormaliteRead) => {
                                // Todo remove this useless step by correctly typing formality ids on API SIDE / regenerate API.ts formalité
                                if (!formality.id) {
                                    return null;
                                }
                                return (
                                    <Box
                                        width="50%"
                                        minWidth="50%"
                                        maxWidth="100px"
                                    >
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={formalityIds.includes(formality.id)}
                                                    onChange={() => {
                                                        if (formality.id) {
                                                            toggleId(formality.id);
                                                        }
                                                    }}
                                                />
                                            }
                                            label={formality.libelle}
                                        />
                                    </Box>
                                );
                            })}
                        </Box>
                    ))}
                </Box>
            )}
        </div>
    );
};

export default FormalityFilterTab;
