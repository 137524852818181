import React, { FunctionComponent } from 'react';
import { Box, Skeleton } from '@mui/material';

interface SkeletonArrayProps {
    columns: number;
    rows: number;
    withHeader?: boolean;
}

const SkeletonArray: FunctionComponent<SkeletonArrayProps> = function ({
    columns,
    rows,
    withHeader = true,
}) {
    return (
        <Box sx={{ width: '100%', padding: '16px' }}>
            {Array.from({ length: rows }).map((_, rowIndex) => {
                const rowKey = `row-${rowIndex}`;
                return (
                    <Box
                        key={rowKey}
                        sx={{
                            display: 'flex',
                            gap: '16px',
                            marginBottom: '16px',
                        }}
                    >
                        {Array.from({ length: columns }).map((__, colIndex) => {
                            const colKey = `row-${rowIndex}-col-${colIndex}`;
                            return (
                                <Skeleton
                                    key={colKey}
                                    variant="rounded"
                                    width="100%"
                                    height={rowIndex === 0 && withHeader ? 56 : 32}
                                    sx={{
                                        flex: 1,
                                    }}
                                />
                            );
                        })}
                    </Box>
                );
            })}
        </Box>
    );
};

export default SkeletonArray;
