import { FlexyInput } from '@europrocurement/flexy-components';
import { Box } from '@mui/system';
import React from 'react';
// eslint-disable-next-line import/no-cycle
import { BooleanField } from './FormalitiesSettings';

type SwitchGroupProps = {
    booleanFields: BooleanField[];
    isProcessing: boolean;
    updateFormField: (field: string, value: boolean | string | number) => void;
    keyPrefix: string;
};

const SwitchGroup = function (props: SwitchGroupProps) {
    const { booleanFields, isProcessing, updateFormField, keyPrefix } = props;
    const switchWrapperStyle = {
        flex: `1 0 60%`,
        marginRight: '15px',
    };

    return (
        <Box
            display="flex"
            flexWrap="wrap"
            sx={{ marginTop: '20px' }}
        >
            {booleanFields.map((booleanField: BooleanField, index: number) => {
                const { label, value, apiFieldName } = booleanField;

                return (
                    <Box
                        sx={switchWrapperStyle}
                        // eslint-disable-next-line react/no-array-index-key
                        key={`${keyPrefix}_${index}`}
                    >
                        <FlexyInput
                            type="boolean"
                            inputlabel={label}
                            name={`${label}_switch`}
                            value={value}
                            disabled={isProcessing}
                            onChange={() => {
                                updateFormField(apiFieldName, !value);
                            }}
                        />
                    </Box>
                );
            })}
        </Box>
    );
};

export default SwitchGroup;
