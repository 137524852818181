import React, { useEffect, useState } from 'react';
import { CircularProgress, Grid, List, ListItem } from '@mui/material';
import { Formalite } from '@europrocurement/l2d-domain';
import useApiRequest from '@b2d/hooks/useApiRequest';
import model from '../models/formality';
import CheckboxListItem from './CheckboxListItem';

type FormalitySelectorProps = {
    categoryId: number | null;
    selectedItems: number[];
    onCheckItem: (selectedCategoryId?: number) => void;
    onUncheckItem: (selectedCategoryId?: number) => void;
    excludeItems: number[];
};

const FormalitySelector: React.FunctionComponent<FormalitySelectorProps> = function (props) {
    const { categoryId, selectedItems, onCheckItem, onUncheckItem, excludeItems } = props;
    const { request, requestState } = useApiRequest();
    const [formalities, setFormalities] = useState([]);
    const onChange = (id: number, checked: boolean) => {
        if (checked) {
            onCheckItem(id);
        } else {
            onUncheckItem(id);
        }
    };

    const refreshList = () => {
        const query = {
            page: 1,
            itemsPerPage: 1000,
            categorieId: categoryId as number,
            deleted: false,
        };
        request(model.list(query), {
            successCallback: (response) => {
                setFormalities(
                    response.data['hydra:member'].filter(
                        (item: Formalite) =>
                            !excludeItems.length ||
                            (item.id && excludeItems.indexOf(item.id) === -1),
                    ),
                );
            },
            withToaster: false,
        });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(refreshList, [categoryId]);

    if (requestState.isProcessing) {
        return (
            <Grid
                container
                justifyContent="center"
            >
                <CircularProgress sx={{ marginTop: '25px' }} />
            </Grid>
        );
    }

    const listItems = formalities.map(({ id, libelle, informationsSupplementaires }: Formalite) => {
        if (!id) {
            return null;
        }

        return (
            <ListItem
                sx={{ display: 'block' }}
                key={id}
            >
                <CheckboxListItem
                    label={libelle ?? ''}
                    description={informationsSupplementaires ?? ''}
                    onUpdate={(isChecked) => {
                        onChange(id, isChecked);
                    }}
                    checked={selectedItems.includes(id)}
                />
            </ListItem>
        );
    });

    return <List>{listItems}</List>;
};

export default FormalitySelector;
