import * as React from 'react';
import { Grid, Typography, Card, SxProps } from '@mui/material';

const GridIndicator: React.FunctionComponent<{ sx: SxProps }> = function ({ sx = {} }) {
    return (
        <Grid
            container
            sx={sx}
        >
            <Grid
                item
                xs={12}
                display={{
                    xs: 'none',
                    sm: 'none',
                    md: 'none',
                    lg: 'block',
                }}
            >
                <Card>
                    <Typography>Breakpoint LG</Typography>
                </Card>
            </Grid>
            <Grid
                item
                xs={12}
                display={{
                    xs: 'none',
                    sm: 'none',
                    md: 'block',
                    lg: 'none',
                }}
            >
                <Card>
                    <Typography>Breakpoint MD</Typography>
                </Card>
            </Grid>
            <Grid
                item
                xs={12}
                display={{
                    xs: 'none',
                    sm: 'block',
                    md: 'none',
                    lg: 'none',
                }}
            >
                <Card>
                    <Typography>Breakpoint SM</Typography>
                </Card>
            </Grid>
            <Grid
                item
                xs={12}
                display={{
                    xs: 'block',
                    sm: 'none',
                    md: 'none',
                    lg: 'none',
                }}
            >
                <Card>
                    <Typography>Breakpoint XS</Typography>
                </Card>
            </Grid>
        </Grid>
    );
};

export default GridIndicator;
