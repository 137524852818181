import React, { FC } from 'react';

import LineActionButton from '@b2d/pages/Offres/Package/Components/LineActionButton';
import { FormContextType } from '@b2d/pages/Offres/Package/Components/types';

import { Typography } from '@mui/material';
import { Box } from '@mui/system';

import { faMinus, faPlus } from '@fortawesome/pro-duotone-svg-icons';

type ComponentProps = {
    value: number | null;
    inputName: string;
    formContext: FormContextType;
    limit?: number;
    originalValue: number;
};

const QuantityField: FC<ComponentProps> = function ({
    limit = 99,
    value,
    inputName,
    formContext,
    originalValue,
}) {
    const { setValue } = formContext;
    const quantityValue = !value ? originalValue : value;

    const setQuantity = (newValue: number) => {
        setValue(inputName, newValue);
    };
    const incrementQuantity = () => {
        if (quantityValue + 1 <= limit) {
            setQuantity(quantityValue + 1);
        }
    };
    const decrementQuantity = () => {
        setQuantity(quantityValue - 1);
    };

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
        >
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                <Typography
                    sx={{ opacity: 0.5 }}
                    fontSize={15}
                    fontStyle="italic"
                    marginX={2}
                    display="flex"
                >
                    quantité : {value ?? originalValue}
                </Typography>
            </Box>

            <LineActionButton
                actionName="decrement quantity"
                icon={faMinus}
                onClick={decrementQuantity}
                disabled={quantityValue <= 1}
                bordered
                sx={{ marginRight: '15px' }}
            />
            <LineActionButton
                actionName="increment quantity"
                icon={faPlus}
                onClick={incrementQuantity}
                disabled={quantityValue >= limit}
                bordered
                sx={{ marginRight: '15px' }}
            />
        </Box>
    );
};

export default QuantityField;
