import { NamedIconsType } from '@europrocurement/l2d-icons/types';
import {
    faAlbumCollection,
    faBoxAlt,
    faBoxesStacked,
    faCircleLocationArrow,
    faFile,
    faHexagonCheck,
    faInputPipe,
    faLayerGroup,
    faList12,
    faObjectSubtract,
    faSitemap,
} from '@fortawesome/pro-duotone-svg-icons';

export const additionalFieldIcon: NamedIconsType = {
    props: { icon: faInputPipe },
    name: 'additionalField',
    displayName: 'Champ complémentaire',
};

export const articleIcon: NamedIconsType = {
    props: { icon: faObjectSubtract },
    name: 'article',
    displayName: 'Article',
};

export const catalogIcon: NamedIconsType = {
    props: { icon: faAlbumCollection },
    name: 'catalog',
    displayName: 'Catalogue',
};

export const documentIcon: NamedIconsType = {
    props: { icon: faFile },
    name: 'document',
    displayName: 'Document',
};

export const offerIcon: NamedIconsType = {
    props: { icon: faBoxAlt },
    name: 'offer',
    displayName: 'Offre',
};

export const offerGroupIcon: NamedIconsType = {
    props: { icon: faBoxesStacked },
    name: 'group',
    displayName: "Groupement d'offres",
};

export const offerOptionIcon: NamedIconsType = {
    props: { icon: faList12 },
    name: 'option',
    displayName: "Option  d'offres",
};

export const offerOptionValueIcon: NamedIconsType = {
    props: { icon: faHexagonCheck },
    name: 'optionValue',
    displayName: '',
};

export const offerPackageIcon: NamedIconsType = {
    props: { icon: faSitemap },
    name: 'offerPackage',
    displayName: 'Package',
};

export const domainIcon: NamedIconsType = {
    props: { icon: faLayerGroup },
    name: 'domain',
    displayName: 'Domaine',
};

export const actionIcon: NamedIconsType = {
    props: { icon: faCircleLocationArrow },
    name: 'action',
    displayName: 'Action',
};

export const modelIcons: NamedIconsType[] = [
    additionalFieldIcon,
    articleIcon,
    catalogIcon,
    documentIcon,
    offerIcon,
    offerGroupIcon,
    offerOptionIcon,
    offerOptionValueIcon,
    offerPackageIcon,
    domainIcon,
    actionIcon,
];
