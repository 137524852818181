import React, { useEffect } from 'react';

import { useFullLayout } from '@europrocurement/flexy-components/components/templates/FullLayout/FullLayoutContext';

import { Card } from '@mui/material';
import { FlexyForm, jsonFormToFormStructure } from '@europrocurement/flexy-form';
import { useForm } from 'react-hook-form';
import formjson from './form.json';

const PanelFormulaires = function () {
    console.warn('formjson', formjson);
    const { setSidebarOpen } = useFullLayout();

    useEffect(() => {
        setSidebarOpen(false);
        return () => {
            setSidebarOpen(true);
        };
    }, [setSidebarOpen]);

    const defaultValues = {};

    // Initialisation du formulaire
    const formContext = useForm({
        mode: 'onTouched',
        defaultValues,
    });

    const structure = jsonFormToFormStructure(formjson as Record<string, unknown>);

    return (
        <Card>
            <FlexyForm
                formContext={formContext}
                formStructure={structure}
                formObject={defaultValues}
                onSubmit={console.warn}
            />
            {/* <ExampleForm /> */}
        </Card>
    );
};

export default PanelFormulaires;
