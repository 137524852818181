import { ChampComplMentaireJsonldChampcomplementaireReadHtmlFieldTypeEnum } from '@europrocurement/l2d-domain/openApi/ApiOffre';
import { inputIcons, NamedIconsType } from '@europrocurement/l2d-icons';

type OptionValueHtmlTypes = 'select' | 'radio' | 'checkbox';
type AdditionalFieldHtmlTypes = ChampComplMentaireJsonldChampcomplementaireReadHtmlFieldTypeEnum;

export type HTMLFieldsPreviewType = OptionValueHtmlTypes | AdditionalFieldHtmlTypes | undefined;

export const getHtmlFieldTypeNamedIcon = (
    htmlFieldType: HTMLFieldsPreviewType,
): NamedIconsType | undefined => {
    if (!htmlFieldType) {
        return undefined;
    }
    return inputIcons.find((inputIcon) => inputIcon.name === htmlFieldType);
};

export const getHtmlFieldTypeLabel = (htmlFieldType?: HTMLFieldsPreviewType) => {
    switch (htmlFieldType) {
        case 'select':
            return "Liste d'options";
        case 'checkbox':
            return 'Case à cocher';
        case 'text':
            return 'Champ texte';
        case 'textarea':
            return 'Zone de texte';
        case 'number':
            return 'Nombre';
        case 'date':
            return 'Champ date';
        case 'radio':
            return 'Bouton radio';
        case 'email':
            return 'email';
        default:
            return '';
    }
};
