import React, { FunctionComponent, useState } from 'react';

import {
    AppDispatch,
    CategorieStatsSelector,
    customizerSelector,
    factureAchatApi,
    getFactureAchatStats,
} from '@b2d/redux/RootStore';
import { DataCard } from '@europrocurement/flexy-components';
import {
    CategorieStats,
    FACTURES_ACHATS_SLICE_NAME,
    FACTURES_ACHATS_STATS_DATASOURCE_NAME,
} from '@europrocurement/l2d-domain';
import { FaOptionIcon } from '@europrocurement/l2d-icons';
import { DataSource } from '@europrocurement/l2d-redux-utils';
import { faFileInvoice } from '@fortawesome/pro-duotone-svg-icons';
import { faPause } from '@fortawesome/pro-solid-svg-icons';
import { Button, Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

// interne

const FacturesEnERDO5: FunctionComponent = function () {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();

    const { xIdSociete } = useSelector(customizerSelector);

    const categoriesDataSource: DataSource<CategorieStats> =
        useSelector(CategorieStatsSelector).main;

    const [countOfInvoicesAsERDO5, setCountOfInvoicesAsERDO5] = useState<string>('...');

    factureAchatApi
        .getFacturesAchatsFactureAchatCollection({
            xIdSociete,
            page: 1,
            itemsPerPage: 0,
            cloture: true,
            deleted: false,
            codesRejetsCodeRejetCode: 'ERDO5',
            codesRejetsEtat: 'todo',
        })
        .then((res) => {
            if (
                res.data['hydra:totalItems'] !== null ||
                res.data['hydra:totalItems'] !== undefined
            ) {
                setCountOfInvoicesAsERDO5(`${res.data['hydra:totalItems']}`);
            }
        });

    return (
        <Grid
            sx={{
                flex: 1,
            }}
            item
            lg={1}
            md={1}
            xs={12}
        >
            <DataCard
                title="Factures en ERDO5"
                color="ERDO"
                iconBgColor="transparent"
                iconColor="transparent"
                icon={
                    <FaOptionIcon
                        icon={faFileInvoice}
                        option={faPause}
                        size="2x"
                        color="ERDO.main"
                        optionColor="ERDO.dark"
                    />
                }
                tools={
                    <Button
                        color="ERDO"
                        variant="outlined"
                        onClick={() => {
                            dispatch({
                                type: `${categoriesDataSource.slicename}/set${categoriesDataSource.name}Filter`,
                                payload: {
                                    key: 'codesRejets',
                                    value: ['ERDO5'],
                                },
                            });
                            dispatch({
                                type: `${FACTURES_ACHATS_SLICE_NAME}/set${FACTURES_ACHATS_STATS_DATASOURCE_NAME}Filter`,
                                payload: {
                                    key: 'codesRejetsEtat',
                                    value: 'todo',
                                },
                            });

                            dispatch(getFactureAchatStats({}));

                            navigate('/achats/stats?tab=detail');
                        }}
                    >
                        Consulter
                    </Button>
                }
            >
                <Typography
                    sx={{
                        fontSize: '18px',
                        textAlign: 'center',
                    }}
                >
                    Factures en ERDO5 :
                </Typography>
                <Typography
                    color="ERDO.main"
                    component="h2"
                    sx={{
                        fontSize: '32px',
                        textAlign: 'center',
                    }}
                >
                    <b>{countOfInvoicesAsERDO5}</b>
                </Typography>
            </DataCard>
        </Grid>
    );
};

export default FacturesEnERDO5;
