import React from 'react';

import {
    BDD_SWITCH_SOCIETES_FULLNAME_FROM_ID,
    BDD_SWITCH_SOCIETES_IDS_TYPE,
    MediaObject,
} from '@europrocurement/l2d-domain';
import { ColumnDatatable } from '@europrocurement/flexy-datatable';
import { Typography } from '@mui/material';

const columnItemAssignedCompany: ColumnDatatable<MediaObject> = {
    label: 'Société',
    render: (mediaObject: MediaObject) => {
        let moSociete = null;

        if (mediaObject.idSociete) {
            moSociete =
                BDD_SWITCH_SOCIETES_FULLNAME_FROM_ID[
                    mediaObject.idSociete as BDD_SWITCH_SOCIETES_IDS_TYPE
                ];
        }

        return <Typography>{moSociete}</Typography>;
    },
    isDisplayed: true,
};

export default columnItemAssignedCompany;
