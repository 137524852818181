import { Button, InputAdornment, Typography } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { Fournisseur } from '@europrocurement/l2d-domain';
import { UseSwitchLockValuesProps } from '@b2d/pages/Achats/hooks/useSwitchLockValues';
import { useActionOnChangeTauxRemise } from '../../functions/produitFunctions';

export type UseHandleAdornmentProps = {
    formContext: UseFormReturn<FieldValues, unknown>;
    stateSwitchLockValues: UseSwitchLockValuesProps['stateSwitchLockValues'];
    modalDiscountRate: () => void;
    fournisseur?: Fournisseur;
};

const useHandleAdornment = ({
    formContext,
    stateSwitchLockValues,
    modalDiscountRate,
    fournisseur,
}: UseHandleAdornmentProps) => {
    const actionOnChangeTauxRemise = useActionOnChangeTauxRemise();
    const { enqueueSnackbar } = useSnackbar();

    const handleOnClickPublisherAdornment = useCallback<
        React.MouseEventHandler<HTMLButtonElement>
    >(() => {
        actionOnChangeTauxRemise({
            formContext,
            stateSwitchLockValues,
            produitConcernedByRsfExist: () => {
                modalDiscountRate();
            },
            produitConcernedByRsfDoNotExist: (message) => {
                enqueueSnackbar(<Typography>{message}</Typography>, {
                    variant: 'error',
                });
            },
        });
    }, [
        actionOnChangeTauxRemise,
        enqueueSnackbar,
        formContext,
        modalDiscountRate,
        stateSwitchLockValues,
    ]);

    const prevPublisherCodeRef = useRef(fournisseur?.typeRemise?.code);

    useEffect(() => {
        prevPublisherCodeRef.current = fournisseur?.typeRemise?.code;
    }, [fournisseur?.typeRemise?.code]);

    const publisherAdornment = useMemo(() => {
        if (fournisseur?.typeRemise?.code === 'RSF') {
            return (
                <InputAdornment position="end">
                    <Button
                        onClick={handleOnClickPublisherAdornment}
                        disabled={false}
                        variant="text"
                        size="small"
                        style={{ marginTop: '16px', marginBottom: '16px' }}
                    >
                        Remise intégrée
                    </Button>
                </InputAdornment>
            );
        }
        return null;
    }, [fournisseur, handleOnClickPublisherAdornment]);

    return { publisherAdornment };
};

export default useHandleAdornment;
