import * as React from 'react';
import { Box } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

export type ImageSpecificationsType = {
    height: string;
    width: string;
    maxSize: string;
    allowedTypes: Array<string>;
};

type ImageSpecificationsProps = {
    isActive: boolean;
    imageSpecifications: ImageSpecificationsType;
};
const ImageSpecifications: React.FunctionComponent<ImageSpecificationsProps> = function (props) {
    const { isActive, imageSpecifications } = props;
    const { height, width, maxSize, allowedTypes } = imageSpecifications;

    return (
        <Box
            border="1px solid #d3d3d3"
            borderRadius="15px"
            padding="12px"
            fontSize="15px"
            lineHeight="16px"
            bgcolor="#f9f9f9"
            display="flex"
            sx={{ opacity: isActive ? '1' : '0.2' }}
        >
            <InfoIcon sx={{ margin: '15px 30px 15px 15px', opacity: '0.2', fontSize: '35px' }} />
            <Box marginRight="25px">
                <p>
                    Hauteur et largeur maximale de l&apos;image: {height} x {width} px
                </p>
                <p>
                    Taille maximale de l&apos;image : <span>{maxSize} Mo</span>
                </p>
                <p>
                    Types de fichiers autorisés :{' '}
                    {allowedTypes.map((type: string, index: number) => (
                        <span>{index === 0 ? `${type} ` : `/ ${type}`}</span>
                    ))}
                </p>
            </Box>
        </Box>
    );
};

export default ImageSpecifications;
