import React, { FunctionComponent } from 'react';

import { Box, SxProps, Typography } from '@mui/material';
import {
    FlexyInput,
    FlexyInputProps,
    RenderFieldInputType,
} from '@europrocurement/flexy-components';
import { FilterDatatable, FilterOptionValue, FiltersOpts } from '../DatatableHeader';

export type FlexyDatatableFiltersProps = {
    filtersOpts: FiltersOpts;
    mode?: 'classic' | 'raw';
    sx?: SxProps;
};

type FlexyInputCommonProps = FlexyInputProps<RenderFieldInputType>;

const FlexyDatatableFilters: FunctionComponent<FlexyDatatableFiltersProps> = function ({
    filtersOpts,
    mode = 'classic',
    sx = {},
}) {
    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexWrap: 'wrap',
                flexDirection: mode === 'classic' ? 'column' : 'row',
                gap: '10px',
                ...sx,
            }}
        >
            {mode === 'classic' ? <Typography variant="h2">Filtres de recherche</Typography> : null}

            {filtersOpts?.filters.map((filter: FilterDatatable, i) => {
                const value =
                    (filter.type !== 'boolean' && filtersOpts?.activeFilters?.[filter.field]) || '';
                const checked =
                    (filter.type === 'boolean' && filtersOpts?.activeFilters?.[filter.field]) ||
                    false;

                const commonProps: FlexyInputCommonProps = {
                    sx: {
                        width: mode === 'raw' ? '100%' : undefined,
                    },
                    variant: 'outlined',
                    size: 'small',
                    name: filter.label,
                    placeholder: filter.label,
                    inputlabel: filter.label.replace(/^\w/, (c) => c.toUpperCase()),
                };

                return (
                    <Box
                        key={String(filter.label + filter.field + i)}
                        sx={{ width: mode === 'raw' ? '24%' : undefined }}
                    >
                        {filter.type === 'staticSelect' ? (
                            <FlexyInput
                                key={`${filter.field}`}
                                data-testid="SearchFilters"
                                type="select"
                                value={filter.options.filter((option) => option.value === value)}
                                onChange={(selected: FilterOptionValue) => {
                                    filtersOpts?.onFilterChange(filter.field, selected.value);
                                }}
                                options={filter.options}
                                {...commonProps}
                            />
                        ) : (
                            <FlexyInput
                                key={`${filter.field}`}
                                data-testid="SearchFilters"
                                value={value}
                                checked={checked}
                                type={filter.type}
                                onChange={(
                                    e: React.BaseSyntheticEvent | Date,
                                    isChecked: boolean,
                                ) => {
                                    let newValue;
                                    if (filter.type === 'boolean') {
                                        newValue = isChecked;
                                    } else if (filter.type === 'date') {
                                        if (e === null) return;
                                        newValue = new Date(e as Date);
                                    } else {
                                        newValue = (e as React.BaseSyntheticEvent).target.value;
                                    }

                                    if (filter.valueMap) newValue = filter.valueMap(newValue);
                                    filtersOpts?.onFilterChange(filter.field, newValue);
                                }}
                                {...commonProps}
                            />
                        )}
                    </Box>
                );
            })}
        </Box>
    );
};

export default FlexyDatatableFilters;
