import { ConfigurationParameters as OffreConfigurationParameters } from '../../openApi/ApiOffre';
import { createArticlesSlice } from './slices/articleSlice';
import { createCatalogsSlice } from './slices/catalogSlice';
import { createDocumentsSlice } from './slices/documentSlice';
import { createDomainsSlice } from './slices/domainsSlice';
import { createOfferAdditionalFieldsSlice } from './slices/offerAdditionalFieldSlice';
import { createOfferOptionsSlice } from './slices/offerOptionSlice';
import { createOfferOptionValuesSlice } from './slices/offerOptionValueSlice';
import { createOffersSlice } from './slices/offerSlice';
import { createOfferGroupSlice } from './slices/offerGroupSlice';
import { createPackagesSlice } from './slices/packageSlice';
import { createVatsSlice } from './slices/vatsSlice';
import { createActionsSlice } from './slices/actionSlice';
import { createInvoicingCategorySlice } from './slices/invoicingCategorySlice';

export { type OffreConfigurationParameters };

export function createOfferReducer(configuration: OffreConfigurationParameters) {
    return {
        ...createOffersSlice(configuration),
        ...createCatalogsSlice(configuration),
        ...createPackagesSlice(configuration),
        ...createArticlesSlice(configuration),
        ...createActionsSlice(configuration),
        ...createOfferOptionsSlice(configuration),
        ...createOfferOptionValuesSlice(configuration),
        ...createOfferAdditionalFieldsSlice(configuration),
        ...createDocumentsSlice(configuration),
        ...createDomainsSlice(configuration),
        ...createVatsSlice(configuration),
        ...createOfferGroupSlice(configuration),
        ...createActionsSlice(configuration),
        ...createInvoicingCategorySlice(configuration),
    };
}
