import {
    DEMANDEUR_SIGNATURE_SLICE_NAME,
    DOSSIER_SIGNATURE_SLICE_NAME,
    SIGNATURE_REDUCER_NAME,
} from '@europrocurement/l2d-domain';
import { RootStateType } from '../../RootStore';

export const MainDossierSignatureSelector = (s: RootStateType) =>
    s[SIGNATURE_REDUCER_NAME][DOSSIER_SIGNATURE_SLICE_NAME].main;

export const MainDemandeurSignatureSelector = (s: RootStateType) =>
    s[SIGNATURE_REDUCER_NAME][DEMANDEUR_SIGNATURE_SLICE_NAME].main;
