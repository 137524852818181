import React from 'react';

import { ColumnDatatable } from '@europrocurement/flexy-datatable';
import { MediaObject } from '@europrocurement/l2d-domain';
import { Chip } from '@mui/material';

const columnItemsStatus: ColumnDatatable<MediaObject> = {
    label: 'Statut',
    isDisplayed: false,
    render: (mediaObject: MediaObject) => {
        const debug = false;
        if (debug) {
            console.log(mediaObject);
        }
        // Nouveau champs du MediaObject : Statut
        // Qui détermine dans quel état est le traitement du MediaObject :
        // À traiter - Tout est normal - vert
        // Refusé - Doit être résolu par un interne - rouge
        // Annoté - Note fournie par interne pour traiter la facture - orange
        return (
            <Chip
                color="success"
                label="À traiter"
            />
        );
    },
};

export default columnItemsStatus;
