import React, { useState } from 'react';
import { Box, Grid } from '@mui/material';
import { Contact, Prescripteur } from '@europrocurement/l2d-domain';
import { FlexyForm, FormObject, FormStructure } from '@europrocurement/flexy-form';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FlexyHeaderForm, ModalContext } from '@europrocurement/flexy-components';
import { usePrescriberContacts } from '../hooks/usePrescriberContacts';
import PasswordField from './PasswordField';

export type CreateUserProps = {
    prescriber: Prescripteur;
    contact: Contact;
    randomPassword: string;
    afterSubmit: () => void;
};

type CreateUserFormData = {
    password: string | null;
    passwordConfirmation: string | null;
};

const CreateUser: React.FunctionComponent<CreateUserProps> = function (props) {
    const { prescriber, contact, randomPassword, afterSubmit } = props;
    const { createUser } = usePrescriberContacts();
    const [isSubmitting, setSubmitting] = useState<boolean>(false);
    const { modalActions } = React.useContext(ModalContext);

    const defaultFormValues = {
        password: randomPassword,
        passwordConfirmation: randomPassword,
    };

    const formContext = useForm({
        mode: 'onTouched',
        defaultValues: defaultFormValues,
    });

    const handleSubmit: SubmitHandler<FormObject> = async (formData: CreateUserFormData) => {
        if (formData.password !== formData.passwordConfirmation) {
            formContext.setError('password', {
                type: 'custom',
                message: 'Les mots de passe ne correspondent pas',
            });

            return;
        }

        setSubmitting(true);

        const payload = {
            username: contact.emailsContact[0]?.email,
            enabled: true,
            emailVerified: true,
            firstName: contact.nomContact,
            lastName: contact.prenomContact,
            email: contact.emailsContact[0]?.email,
            password: formData?.password,
            idPrescripteur: prescriber?.id,
        };

        createUser(payload).then(
            () => {
                modalActions.close();
                afterSubmit();
            },
            () => {
                setSubmitting(false);
            },
        );
    };

    const formStructure: FormStructure[] = [
        {
            type: 'customItem',
            name: 'password',
            renderField: (currentFormContext) => (
                <PasswordField
                    label="Mot de passe"
                    required
                    value={currentFormContext.getValue('password')}
                />
            ),
            inputlabel: 'Mot de passe',
            rules: {
                required: 'Ce champs est obligatoire',
            },
        },
        {
            type: 'customItem',
            name: 'passwordConfirmation',
            renderField: (currentFormContext) => (
                <PasswordField
                    label="Mot de passe"
                    required
                    value={currentFormContext.getValue('passwordConfirmation')}
                />
            ),
            inputlabel: 'Confirmation du mot de passe',
            rules: {
                required: 'Ce champs est obligatoire',
            },
        },
    ];

    return (
        <Box>
            <Grid
                container
                spacing={3}
                sx={{ marginTop: 0 }}
            >
                <Grid>
                    <FlexyHeaderForm
                        label={
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                }}
                            >
                                <Box>Créer un accès B2B</Box>
                            </Box>
                        }
                        sx={{ marginTop: '0px' }}
                        outlined
                    />
                    <FlexyForm
                        formContext={formContext}
                        formObject={defaultFormValues}
                        formStructure={formStructure}
                        onSubmit={handleSubmit}
                        isSubmitting={isSubmitting}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export default CreateUser;
