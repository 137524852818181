/**
 * Preview card component :
 * ------------------------
 * Used in Listing with preview component
 */

import * as React from 'react';
import AdditionalFieldPreview from '@b2d/pages/Offres/AdditionalField/PreviewCard';
import ArticlePreview from '@b2d/pages/Offres/Article/PreviewCard';
import OfferPreview from '@b2d/pages/Offres/Offer/PreviewCard';
import OfferOptionPreview from '@b2d/pages/Offres/Option/PreviewCard';
import DocumentPreview from '@b2d/pages/Offres/Document/PreviewCard';
import PackagePreview from '@b2d/pages/Offres/Package/PreviewCard';
import CatalogPreview from '@b2d/pages/Offres/Catalog/PreviewCard';
import GroupPreview from '@b2d/pages/Offres/Group/PreviewCard';
import models from '@b2d/pages/Offres/models';
import { AbstractJsonLdData } from '@b2d/pages/Offres/forms/types';
import { Card } from '@mui/material';
import EmptyCardPreview from './EmptCardPreview';

type PreviewCardProps = {
    isPreviewLoading: boolean;
    isPreviewEmpty: boolean;
    selectedEntity: AbstractJsonLdData;
    onEdit: () => void;
};

const PreviewCard: React.FC<PreviewCardProps> = function (props) {
    const { isPreviewLoading, isPreviewEmpty, selectedEntity, onEdit } = props;

    const previewCardStyle = {
        margin: '0 0 0 15px',
    };

    const entityType = selectedEntity?.['@type'];

    if (isPreviewEmpty || isPreviewLoading) {
        return (
            <Card sx={previewCardStyle}>
                <EmptyCardPreview loading={isPreviewLoading} />
            </Card>
        );
    }

    const getContentFromEntityType = (entityTypeString: string) => {
        switch (entityTypeString) {
            case models.additionalField.type:
                return (
                    <AdditionalFieldPreview
                        entity={selectedEntity}
                        onEdit={onEdit}
                    />
                );
            case models.offerOption.type:
                return (
                    <OfferOptionPreview
                        entity={selectedEntity}
                        onEdit={onEdit}
                    />
                );

            case models.offer.type:
                return (
                    <OfferPreview
                        entity={selectedEntity}
                        onEdit={onEdit}
                    />
                );
            case models.article.type:
                return (
                    <ArticlePreview
                        entity={selectedEntity}
                        onEdit={onEdit}
                    />
                );
            case models.document.type:
                return (
                    <DocumentPreview
                        entity={selectedEntity}
                        onEdit={onEdit}
                    />
                );
            case models.offerGroup.type:
                return (
                    <GroupPreview
                        entity={selectedEntity}
                        onEdit={onEdit}
                    />
                );
            case models.offerPackage.type:
                return (
                    <PackagePreview
                        entity={selectedEntity}
                        onEdit={onEdit}
                    />
                );
            case models.catalog.type:
                return (
                    <CatalogPreview
                        entity={selectedEntity}
                        onEdit={onEdit}
                    />
                );

            default:
                return (
                    <p>
                        (aucune carte d&apos;aperçu n&apos;est défini pour : `${entityTypeString}`)
                    </p>
                );
        }
    };

    return <Card sx={previewCardStyle}>{getContentFromEntityType(entityType)}</Card>;
};

export default PreviewCard;
