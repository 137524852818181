import React, { useEffect } from 'react';
import {
    ContextActionType,
    FlexyTabs,
    useDumbActionsContext,
} from '@europrocurement/flexy-components';
import { Box, CircularProgress, Typography } from '@mui/material';
import useLoadStore, { LoadableCollection } from '@b2d/hooks/useLoadStore';
import OfferPanel from '@b2d/pages/Offres/Offer/Panel';
import CatalogPanel from '@b2d/pages/Offres/Catalog/Panel';
import ArticlePanel from '@b2d/pages/Offres/Article/Panel';
import PackagePanel from '@b2d/pages/Offres/Package/Panel';
import OptionPanel from '@b2d/pages/Offres/Option/Panel';
import OfferAdditionalFieldPanel from '@b2d/pages/Offres/AdditionalField/Panel';
import DocumentPanel from '@b2d/pages/Offres/Document/Panel';
import GroupPanel from '@b2d/pages/Offres/Group/Panel';
import useLoadLibArticle from '@b2d/hooks/useLoadLibArticle';
import { useDispatch } from 'react-redux';
import { setXIdSociete } from '@europrocurement/l2d-domain';
import useOfferCache from './cache/useOfferCache';

const OfferDashboard: React.FunctionComponent = function () {
    const offerDashboardContextActions: ContextActionType[] = [
        // Offer dashboard context actions
    ];
    const dispatch = useDispatch();
    useOfferCache();

    const L2D = 12;

    useEffect(() => {
        dispatch(setXIdSociete(L2D));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useDumbActionsContext(offerDashboardContextActions);
    const collectionsToFetch: LoadableCollection[] = [
        'vats',
        'domains',
        'invoicingCategories',
        'formalityCategories',
    ];
    const { getLoadingStatus } = useLoadStore(collectionsToFetch);
    useLoadLibArticle();

    if (getLoadingStatus() !== 'succeeded') {
        return <CircularProgress />;
    }

    return (
        <Box
            sx={{
                overflow: 'hidden',
            }}
        >
            <Box display="flex">
                <Typography variant="h1">Gestion des offres marketing</Typography>
            </Box>

            <FlexyTabs
                sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '15px' }}
                tabs={[
                    {
                        tabTitle: 'Offres',
                        tabPanel: <OfferPanel />,
                        disabled: false,
                        tabName: 'offre',
                    },
                    {
                        tabTitle: 'Catalogues',
                        tabPanel: <CatalogPanel />,
                        disabled: false,
                        tabName: 'catalogue',
                    },
                    {
                        tabTitle: 'Packages',
                        tabPanel: <PackagePanel />,
                        disabled: false,
                        tabName: 'package',
                    },
                    {
                        tabTitle: 'Articles',
                        tabPanel: <ArticlePanel />,
                        disabled: false,
                        tabName: 'article',
                    },
                    {
                        tabTitle: 'Options',
                        tabPanel: <OptionPanel />,
                        disabled: false,
                        tabName: 'option',
                    },
                    {
                        tabTitle: 'Champs complémentaire',
                        tabPanel: <OfferAdditionalFieldPanel />,
                        disabled: false,
                        tabName: 'champComplementaire',
                    },
                    {
                        tabTitle: 'Documents',
                        tabPanel: <DocumentPanel />,
                        disabled: false,
                        tabName: 'document',
                    },
                    {
                        tabTitle: 'Groupements',
                        tabPanel: <GroupPanel />,
                        disabled: false,
                        tabName: 'groupement',
                    },
                ]}
            />
        </Box>
    );
};

export default OfferDashboard;
