import { RootStateType } from '@b2d/redux/RootStore';
import { OffresOffreRead } from '@europrocurement/l2d-domain/openApi/ApiOffre';
import { OFFERS_SLICE_NAME, OFFRE_REDUCER_NAME } from '@europrocurement/l2d-domain';
import { offerIcon } from '@europrocurement/l2d-icons';
import { offersApi } from '@b2d/redux/subReducers/Offers';
import { offersDataSourcesThunks } from '@b2d/redux/subReducers/Offers/reducers';
import { ModelConfiguration, OfferModelItem, ModelMethods } from './types';

const modelKey = 'offer';
const modelName = 'Offer';
const sliceName = OFFERS_SLICE_NAME;
const baseSelector = (state: RootStateType) => state[OFFRE_REDUCER_NAME][sliceName];
const datasourcesThunks = offersDataSourcesThunks;
const api = offersApi;

type ModelStructure = OffresOffreRead;

const configuration: ModelConfiguration = {
    key: modelKey,
    name: modelName,
    type: 'Offres',
    selector: baseSelector,
    sliceName,
    api,
    datasourcesThunks,
    icon: offerIcon,
};

const methods: ModelMethods<ModelStructure> = {
    getItemDefaultLabel: (model: ModelStructure) => `${model.libelle}`,
};

const endpoints = {
    list: (...args: Parameters<typeof api.apiOffresGetCollection>) =>
        api.apiOffresGetCollection(...args),
    delete: (...args: Parameters<typeof api.apiOffresIdDelete>) => api.apiOffresIdDelete(...args),
    dependencies: (...args: Parameters<typeof api.apiOffresIdDependenciesGet>) =>
        api.apiOffresIdDependenciesGet(...args),
    read: (...args: Parameters<typeof api.apiOffresIdGet>) => api.apiOffresIdGet(...args),
    update: (...args: Parameters<typeof api.apiOffresIdPut>) => api.apiOffresIdPut(...args),
    create: (...args: Parameters<typeof api.apiOffresPost>) => api.apiOffresPost(...args),
};

type ModelType = OfferModelItem<ModelStructure> & typeof endpoints;

const model: ModelType = {
    ...configuration,
    ...methods,
    ...endpoints,
};

export type OfferModel = ModelType;
export default model;
