import React from 'react';
import { LazyBreadcrumb } from '@europrocurement/flexy-components';
import { UseKeycloakService } from '@europrocurement/l2d-keycloak';

import DossierSignatureFormWrapper from './DossierSignatureFormWrapper';
import { signatureNewFullPath } from '../../constants/paths';

const DossierSignatureAdd: React.FunctionComponent = function () {
    const kc = UseKeycloakService();

    return (
        <>
            <LazyBreadcrumb
                path={[
                    { path: 'Signature électronique', link: signatureNewFullPath },
                    'Nouveau dossier',
                ]}
            />

            <DossierSignatureFormWrapper
                defaultValues={{
                    prescripteur: kc.getName(),
                    prescripteurEmail: kc.getEmail(),
                }}
            />
        </>
    );
};

export default DossierSignatureAdd;
