import * as React from 'react';
import { Modalizer } from '@europrocurement/flexy-components';
import ExistingPrescribersList from './ExistingPrescribersList';

export type ModalStatus = { isOpen: boolean };

type ExistingPrescribersModalProps = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    existingPrescribers: any;
    modalStatus: ModalStatus;
    onModalClose: () => void;
};

const ExistingPrescribersModal: React.FunctionComponent<ExistingPrescribersModalProps> = function (
    props,
) {
    const { existingPrescribers, modalStatus, onModalClose } = props;
    const { isOpen } = modalStatus;
    return (
        <Modalizer
            open={isOpen}
            onClose={onModalClose}
            maxWidth="lg"
        >
            <ExistingPrescribersList
                existingPrescribers={existingPrescribers}
                sx={{ minHeight: '75vh' }}
            />
        </Modalizer>
    );
};

export default ExistingPrescribersModal;
