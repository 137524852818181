import React, { useCallback, useContext, useEffect, useRef } from 'react';

import { Alert, Box, Button, Typography } from '@mui/material';

import { ModalContext } from '@europrocurement/flexy-components';
import { MessagesTypes } from './ConfirmationModal';

export type ConfirmationModalContentProps = {
    messages?: MessagesTypes;
    actionOnValidation?: () => void;
    actionOnCancellation?: () => void;
    actionOnAlternative?: () => void;
};

const ConfirmationModalContent: React.FunctionComponent<ConfirmationModalContentProps> = function ({
    messages,
    actionOnValidation,
    actionOnCancellation,
    actionOnAlternative,
}) {
    const buttonRef = useRef<HTMLButtonElement | null>(null);

    const { modalStatus, modalActions } = useContext(ModalContext);

    useEffect(() => {
        const keyDownHandler = (ev: KeyboardEvent) => {
            if (ev.key === 'Enter' && buttonRef.current) {
                ev.preventDefault();

                buttonRef.current.click();
            }
        };

        if (modalStatus.isOpen) {
            document.addEventListener('keydown', keyDownHandler);
        } else {
            document.removeEventListener('keydown', keyDownHandler);
        }

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [modalStatus.isOpen]);

    const handleOnClickCancellation = useCallback(() => {
        if (actionOnCancellation) {
            actionOnCancellation();
        }
        modalActions.close();
    }, [actionOnCancellation, modalActions]);

    const handleOnClickValidation = useCallback(() => {
        if (actionOnValidation) {
            actionOnValidation();
        }
        modalActions.close();
    }, [actionOnValidation, modalActions]);

    const handleOnClickAlternative = useCallback(() => {
        if (actionOnAlternative) {
            actionOnAlternative();
        }
        modalActions.close();
    }, [actionOnAlternative, modalActions]);

    return (
        <Box>
            <Typography style={{ marginBottom: '8px' }}>
                {messages?.question || 'Êtes-vous sûr de vouloir valider cette action ?'}
            </Typography>
            {messages?.detail ? (
                <Alert severity={messages.detail.severity}>{messages.detail.message}</Alert>
            ) : null}
            <Box
                display="flex"
                flexDirection="row"
                justifyContent="center"
                gap={2}
            >
                {actionOnCancellation ||
                (!actionOnCancellation && !actionOnAlternative) ||
                messages?.annulation ? (
                    <Button
                        color="error"
                        variant="contained"
                        onClick={handleOnClickCancellation}
                        style={{ marginTop: '8px', marginBottom: '8px' }}
                    >
                        {messages?.annulation || 'Annuler'}
                    </Button>
                ) : null}
                <Button
                    ref={buttonRef}
                    color="primary"
                    variant="contained"
                    onClick={handleOnClickValidation}
                    style={{ marginTop: '8px', marginBottom: '8px' }}
                >
                    {messages?.validation || 'Valider'}
                </Button>
                {actionOnAlternative && messages?.alternative ? (
                    <Button
                        ref={buttonRef}
                        color="primary"
                        variant="outlined"
                        onClick={handleOnClickAlternative}
                        style={{ marginTop: '8px', marginBottom: '8px' }}
                    >
                        {messages.alternative}
                    </Button>
                ) : null}
            </Box>
        </Box>
    );
};

export default ConfirmationModalContent;
