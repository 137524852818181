import React from 'react';

import { faList } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HeaderStructure from '@b2d/pages/Achats/components/fragments/HeaderStructure';
import productsArrayStructure from '@b2d/pages/Achats/components/forms/formElements/fragments/productsSection/productsArrayStructure';

import type { FormStructure } from '@europrocurement/flexy-form';
import type { ProductsStructureProps } from '@b2d/pages/Achats/components/forms/formElements/types';

export default ({
    formContext,
    rubFacts,
    txTva,
    mode,
    headerSwitches = [],
    headerChildren = [],
    hasBlured,
    openModal,
    handleTotalsLock,
    stateSwitchLockValues,
}: ProductsStructureProps): Array<FormStructure> => [
    {
        type: 'header',
        name: 'products_header',
        label: (
            <HeaderStructure
                icon={<FontAwesomeIcon icon={faList} />}
                title="Rubriques"
                controls={headerChildren}
                switches={headerSwitches}
            />
        ),
    },
    ...productsArrayStructure({
        formContext,
        rubFacts,
        txTva,
        mode,
        hasBlured,
        openModal,
        handleTotalsLock,
        stateSwitchLockValues,
    }),
];
