import React from 'react';
import {
    useDumbActionsContext,
    FlexyTabs,
    ContextActionType,
    ModalContext,
} from '@europrocurement/flexy-components';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import AddHomeWorkIcon from '@mui/icons-material/AddHomeWork';
import ContactsIcon from '@mui/icons-material/Contacts';
import { useNavigate } from 'react-router';
import { usePrescriberUtils } from '@b2d/hooks/prescriberHooks';
import ActivityAndTracking from './tabs/activityAndTracking/ActivityAndTracking';
import MainInformations from './tabs/mainInformations/MainInformations';
import LegalAnnouncments from './tabs/LegalAnnouncments';
import Formalities from './tabs/formalities/Formalities';
import Accounting from './tabs/billing/Accounting';

import ContactForm from '../../forms/ContactForm';
import AddressForm from '../../forms/AddressForm';
import PrescriberCustomOffers from './tabs/offers/PrescriberCustomOffers';
import { PRESCRIBER_NEW } from '../../constants/paths';

const PrescripteurInformationsCard: React.FunctionComponent = function () {
    const {
        prescriber,
        methods: { reloadPrescriber },
    } = usePrescriberUtils();

    const navigate = useNavigate();

    const { modalActions } = React.useContext(ModalContext);

    // Component specific context actions

    const addFormProps = {
        isCreate: true,
        prescriber,
        afterSubmit: reloadPrescriber,
    };

    const addContact = () => modalActions.call(<ContactForm {...addFormProps} />);
    const addAddress = () => modalActions.call(<AddressForm {...addFormProps} />);

    const contextActions: ContextActionType[] = [
        {
            name: 'Ajouter prescripteur',
            icon: <PersonAddIcon />,
            action: () => navigate(PRESCRIBER_NEW),
        },
        {
            name: 'Ajouter contact',
            icon: <ContactsIcon />,
            action: addContact,
        },
        {
            name: 'Ajouter adresse',
            icon: <AddHomeWorkIcon />,
            action: addAddress,
        },
    ];

    useDumbActionsContext(contextActions);

    if (!prescriber.id) {
        return null;
    }

    const prescriberTabs = [
        {
            tabName: 'activite',
            tabTitle: 'Activité & Suivi',
            tabPanel: (
                <ActivityAndTracking
                // prescripteur={prescriber}
                // idSociete={idSociete}
                // reloadPrescripteur={reloadPrescripteur}
                />
            ),
        },
        {
            tabName: 'info',
            tabTitle: 'Informations Générales',
            tabPanel: (
                <MainInformations
                // {...mainInformationsProps}
                />
            ),
        },
        {
            tabName: 'annonces-legales',
            tabTitle: 'Annonces Légales',
            tabPanel: <LegalAnnouncments />,
            disabled: true,
        },
        {
            tabName: 'formalites',
            tabTitle: 'Formalités',
            tabPanel: <Formalities />,
        },
        {
            tabName: 'comptabilite',
            tabTitle: 'Comptabilité',
            tabPanel: <Accounting />,
        },
        {
            tabName: 'offre',
            tabTitle: 'Offres',
            tabPanel: <PrescriberCustomOffers />,
        },
    ];

    return (
        <FlexyTabs
            routerParameterName="prescripteurTab"
            sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '15px' }}
            tabs={prescriberTabs}
        />
    );
};

export default PrescripteurInformationsCard;
