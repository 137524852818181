import {
    articlesDataSourcesThunks,
    catalogsDataSourcesThunks,
    offerAdditionalFieldsDataSourcesThunks,
    offerOptionsDataSourcesThunks,
    offersDataSourcesThunks,
    packagesDataSourcesThunks,
    vatsDataSourcesThunks,
} from './reducers';

// [OFFRE] Actions - Fetch data
export const getOffers = offersDataSourcesThunks.main.getData;
export const getCatalogs = catalogsDataSourcesThunks.main.getData;
export const getPackages = packagesDataSourcesThunks.main.getData;
export const getArticles = articlesDataSourcesThunks.main.getData;
export const getOfferOptions = offerOptionsDataSourcesThunks.main.getData;
export const getOfferAdditionalFields = offerAdditionalFieldsDataSourcesThunks.main.getData;

export const getVats = vatsDataSourcesThunks.main.getData;

// [OFFRE] Actions - select item
export const selectOffer = offersDataSourcesThunks.main.getSelected;
export const selectCatalog = catalogsDataSourcesThunks.main.getSelected;
export const selectPackage = packagesDataSourcesThunks.main.getSelected;
export const selectArticle = articlesDataSourcesThunks.main.getSelected;
export const selectOfferOption = offerOptionsDataSourcesThunks.main.getSelected;
export const selectOfferAdditionalField = offerAdditionalFieldsDataSourcesThunks.main.getSelected;
