import * as React from 'react';
import { FlexyTabs } from '@europrocurement/flexy-components';
import { SUB_TAB_ROUTER_PARAMETER } from '@b2d/pages/Prescripteurs/constants/paths';
import OffersSettings from './OffersSettings';
import OffersPreviewTool from './OffersPreviewTool';
import OffersEdition from './OffersEdition';

const PrescriberCustomOffers: React.FunctionComponent = function () {
    const TABS = [
        {
            tabName: 'parametrage',
            tabTitle: 'Paramètrage',
            tabPanel: <OffersSettings />,
        },
        {
            tabName: 'visualisation',
            tabTitle: 'Visualisation',
            tabPanel: <OffersPreviewTool />,
        },
        {
            tabName: 'edition',
            tabTitle: 'Édition / Surcharge',
            tabPanel: <OffersEdition />,
        },
    ];

    return (
        <FlexyTabs
            orientation="vertical"
            tabs={TABS}
            inCard
            routerParameterName={SUB_TAB_ROUTER_PARAMETER}
        />
    );
};

export default PrescriberCustomOffers;
