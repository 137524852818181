import additionalField from './additionalField';
import article from './article';
import catalog from './catalog';
import document from './document';
import offer from './offer';
import offerGroup from './offerGroup';
import offerOption from './offerOption';
import offerOptionValue from './offerOptionValue';
import offerPackage from './offerPackage';

const models = {
    additionalField,
    article,
    catalog,
    document,
    offer,
    offerGroup,
    offerOption,
    offerOptionValue,
    offerPackage,
};

export default models;
