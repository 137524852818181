import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalculator } from '@fortawesome/pro-duotone-svg-icons';
import { FormStructure } from '@europrocurement/flexy-form';
import HeaderStructure from '@b2d/pages/Achats/components/fragments/HeaderStructure';
import { TotalsStructureProps } from '@b2d/pages/Achats/components/forms/formElements/types';
import { updateFormTotalsTtc } from '../../functions/calculsProduits';

export const totalsStructureFields = ({
    mode,
    isMulti,
}: Pick<TotalsStructureProps, 'mode' | 'isMulti'>): Array<FormStructure> => {
    const calculatorFieldOverwrite: Partial<FormStructure & { type: 'number' }> = {
        onChangeInput: (event, input, formContext) => {
            updateFormTotalsTtc({ formContext });
        },
    };

    const autoCalcFieldOverwrite: Partial<FormStructure & { type: 'number' }> = {
        disabled: true,
    };

    return [
        {
            type: 'number',
            name: 'total_ht',
            inputlabel: isMulti ? 'Sous-total HT' : 'Total HT',
            disabled: mode.lock,
            xs: 12,
            sm: 12,
            md: 4,
            lg: 4,
            ...calculatorFieldOverwrite,
        },
        {
            type: 'number',
            name: 'total_tva',
            inputlabel: isMulti ? 'Sous-total TVA' : 'Total TVA',
            disabled: mode.lock,
            xs: 12,
            sm: 12,
            md: 4,
            lg: 4,
            ...calculatorFieldOverwrite,
        },
        {
            type: 'number',
            name: 'total_ttc',
            inputlabel: isMulti ? 'Sous-total TTC' : 'Total TTC',
            disabled: mode.lock,
            xs: 12,
            sm: 12,
            md: 4,
            lg: 4,
            ...autoCalcFieldOverwrite,
        },
    ];
};

const totalsStructure = ({
    mode = { type: 'register' },
    isMulti = false,
    headerSwitches = [],
    headerChildren = [],
}: TotalsStructureProps): Array<FormStructure> => {
    const header: FormStructure = {
        type: 'header',
        name: 'totals_header',
        label: (
            <HeaderStructure
                icon={<FontAwesomeIcon icon={faCalculator} />}
                title={isMulti ? 'Sous-totaux' : 'Totaux'}
                controls={headerChildren}
            />
        ),
    };

    if (headerSwitches) {
        header.switches = [...headerSwitches];
    }

    return [header, ...totalsStructureFields({ mode, isMulti })];
};

export { totalsStructure };
