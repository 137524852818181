import * as React from 'react';

import OfferDashboard from './OfferDashboard';

const OfferRouter = {
    path: 'admin/offres',
    element: <OfferDashboard />,
};

export default OfferRouter;
