import { ListActionButton } from '@europrocurement/flexy-components';
import { MediaObject } from '@europrocurement/l2d-domain';
import { downloadIcon, FaOptionIcon } from '@europrocurement/l2d-icons';
import React, { FunctionComponent } from 'react';

type DownloadButtonProps = {
    mediaObject: MediaObject;
};

const DownloadButton: FunctionComponent<DownloadButtonProps> = function ({
    mediaObject,
}: DownloadButtonProps) {
    /**
     * Action lors du clique du bouton de téléchargement du PDF.
     */
    const onDownload = (mediaObjectToDownload: MediaObject) => {
        if (mediaObjectToDownload.contentUrl && mediaObjectToDownload.name) {
            const link = document.createElement('a');
            link.href = mediaObjectToDownload.contentUrl;
            link.target = '_blank';
            link.type = 'application/octet-stream';
            link.download = `${mediaObjectToDownload.name}.pdf`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    return (
        <ListActionButton
            tooltip="Télécharger"
            onClick={() => {
                onDownload(mediaObject);
            }}
            iconComponent={<FaOptionIcon {...downloadIcon.props} />}
        />
    );
};

export default DownloadButton;
