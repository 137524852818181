import { useContext } from 'react';
import ActionsContext from './ActionsContext';

const useContextActions = () => {
    const { defineContextActions, resetContextActions, contextActions } =
        useContext(ActionsContext);

    return {
        defineContextActions,
        resetContextActions,
        contextActions,
    };
};

export default useContextActions;
