/**
 * Centralize common wordings
 */

const checkConsole = 'Veuillez consulter la console pour obtenir plus de détails.';

const refreshPage = 'Veuillez rafraîchir la page.';

const retryLater = 'Veuillez réessayer plus tard.';

const contactSupport = 'Si le problème persiste, veuillez contacter le support.';

const waitOrSupport = `${retryLater} ${contactSupport}`;

export { checkConsole, refreshPage, retryLater, contactSupport, waitOrSupport };
