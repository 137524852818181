import React from 'react';
import { Card, Collapse, Grid, SxProps, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export type SpcContextProps = {
    toggleRightMenu: () => void;
    toggleLeftMenu: () => void;
};

export const SpcContext = React.createContext<SpcContextProps>({
    toggleRightMenu: () => null,
    toggleLeftMenu: () => null,
});

export type SidePanelCardProps = {
    children: React.ReactNode;
    leftMenu?: React.ReactNode;
    rightMenu?: React.ReactNode;
    sx?: SxProps;
};

/**
 * Pour ouvrir le volet Gauche ou droite :
 * Il est necessaire que le Children puisse le faire en appelant 'toggleLeftMenu' ou 'toggleRightMenu'
 * Ces deux fonctions peuvent être récuperer depuis React.useContext<SpcContextProps>(SpcContext)
 */
const SidePanelCard: React.FunctionComponent<SidePanelCardProps> = function ({
    leftMenu,
    rightMenu,
    children,
    sx,
}) {
    const [isRightOpen, setIsRightOpen] = React.useState<boolean>(false);
    const [isLeftOpen, setIsLeftOpen] = React.useState<boolean>(false);

    // eslint-disable-next-line react/jsx-no-constructed-context-values
    const initialValue = {
        toggleRightMenu: () => setIsRightOpen((v) => !v),
        toggleLeftMenu: () => setIsLeftOpen((v) => !v),
    };

    return (
        <SpcContext.Provider value={initialValue}>
            <Card
                data-testid="SidePanelCard"
                sx={{
                    margin: 0,
                    padding: 0,
                    position: 'relative',
                    ...sx,
                }}
            >
                {leftMenu ? (
                    <Collapse
                        in={isLeftOpen}
                        orientation="horizontal"
                        sx={{
                            boxShadow: '0px 7px 30px rgba(90, 114, 123, 0.11)',
                            height: 'auto',
                            padding: '10px 40px',
                            zIndex: 100,
                        }}
                    >
                        <Box
                            onClick={() => setIsLeftOpen(false)}
                            sx={{
                                position: 'absolute',
                                top: 10,
                                right: 10,
                                cursor: 'pointer',
                            }}
                        >
                            <CloseIcon />
                        </Box>
                        <Box sx={{ height: 'auto', marginTop: '35px' }}>{leftMenu}</Box>
                    </Collapse>
                ) : null}

                <Grid
                    container
                    height="100%"
                    hidden={isLeftOpen}
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    flexWrap="nowrap"
                    width="100%"
                >
                    {children}
                </Grid>

                {rightMenu ? (
                    <Collapse
                        className="right-menu collapse"
                        in={isRightOpen}
                        orientation="horizontal"
                        sx={{
                            boxShadow: '0px -7px 30px rgba(90, 114, 123, 0.11)',
                            height: '100%',
                            overflowY: 'scroll',
                            padding: '10px 40px',
                            position: 'absolute',
                            top: 0,
                            zIndex: 100,
                            right: 0,
                            backgroundColor: 'background.default',
                        }}
                    >
                        <Box
                            onClick={() => setIsRightOpen(false)}
                            sx={{ position: 'absolute', top: 10, right: 10, cursor: 'pointer' }}
                        >
                            <CloseIcon />
                        </Box>
                        <Box sx={{ height: 'auto', paddingY: '35px' }}>{rightMenu}</Box>
                    </Collapse>
                ) : null}
            </Card>
        </SpcContext.Provider>
    );
};

export default SidePanelCard;
