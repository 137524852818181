import { useSnackbar } from 'notistack';
// import { utils, writeFileXLSX } from 'xlsx';
import * as XLSX from 'xlsx';

/**
 * Exporte et convertit un tableau de données au format JSON au format XLSX.
 *
 * Signal un échec ou une réussite avec un toast.
 * En cas d'échec, le détails se trouve en console.
 *
 * @returns void
 */
export const useExport = () => {
    const { enqueueSnackbar } = useSnackbar();

    const exportToXlsx = (
        formatedDatas: (string | number | Date)[][],
        fileName: string,
        sheetName?: string,
    ): void => {
        try {
            const worksheet = XLSX.utils.json_to_sheet(formatedDatas);
            const workbook = XLSX.utils.book_new();

            XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);

            try {
                XLSX.writeFileXLSX(workbook, `${fileName}.xlsx`);
            } catch (error) {
                console.error(`${error}`);
            }

            enqueueSnackbar('Export réussi !', {
                autoHideDuration: 2000,
            });
        } catch (error) {
            console.error(`${error}`);
            enqueueSnackbar('Export échoué !', {
                variant: 'error',
                autoHideDuration: 2000,
            });
        }
    };

    return { exportToXlsx };
};

export default useExport;
