import { Box, Typography, useTheme } from '@mui/material';
import * as React from 'react';
import { hexToRgba } from '@europrocurement/l2d-utils';

type ComponentProps = {
    label: string;
    action?: () => void;
    disabled?: boolean;
    isSelected?: boolean;
    size?: number;
};

const UndoFilterButton: React.FC<ComponentProps> = function (props) {
    // const SvgShape =
    const { label, action, isSelected = false } = props;
    const theme = useTheme();

    const [onHover, setHover] = React.useState<boolean>(false);

    const borderColor = onHover || isSelected ? theme.palette.primary.main : 'lightGray';
    const borderSize = () => {
        if (onHover) {
            return '2px';
        }
        if (isSelected) {
            return '3px';
        }
        return '1px';
    };
    const buttonPadding = () => {
        // Compensate border
        if (onHover) {
            return '0px';
        }
        if (isSelected) {
            return '-1px';
        }
        return '1px';
    };

    const height = 30;
    const width = 150;
    const buttonStyle: React.CSSProperties = {
        display: 'flex',
        justifyContent: ' center',
        alignItems: ' center',
        maxWidth: width,
        maxHeight: height,
        minWidth: width,
        minHeight: height,
        border: `solid ${borderSize()} ${borderColor}`,
        borderRadius: '20px',
        cursor: onHover ? 'pointer' : '',
        margin: '5px',
        transition: 'padding border 0.3s',
        padding: buttonPadding(),
        backgroundColor: isSelected ? hexToRgba(theme.palette.primary.light, 0.4) : '',
    };

    const hoverProps = {
        onMouseEnter: () => setHover(true),
        onMouseLeave: () => setHover(false),
    };

    return (
        <Box
            sx={buttonStyle}
            {...hoverProps}
            onClick={() => (action ? action() : () => {})}
        >
            <Typography fontSize={12}>{label}</Typography>
        </Box>
    );
};
export default UndoFilterButton;
