import * as React from 'react';
import { CardContent, CardHeader, Chip, Typography } from '@mui/material';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import PhoneIcon from '@mui/icons-material/Phone';
import Card from '@mui/material/Card';
import MailIcon from '@mui/icons-material/MailOutline';
import { Box, SxProps } from '@mui/system';

import ActionsBar, { AdditionalActionsProps } from '../../molecules/ActionsBar';
import CustomAvatar from '../../molecules/CustomAvatar';
import FlexyHeaderForm from '../../molecules/FlexyHeaderForm';
import { BorderedBox } from '../../molecules';
import EmailLink from '../../atoms/EmailLink';

type contactCollectionType = {
    defaut?: boolean;
    id?: number;
    telephone?: string;
    email?: string;
    mobile?: string;
};

export type ContactCardContactType = {
    nomContact: string;
    prenomContact: string;
    fonction: string;
    principal?: boolean;
    comptabilite?: boolean;
    administrateur?: boolean;
    note?: string;
    telephonesContact: Array<contactCollectionType>;
    mobilesContact: Array<contactCollectionType>;
    emailsContact: Array<contactCollectionType>;
};

export type ContactCardProps = {
    contact: ContactCardContactType;
    editAction?: () => void;
    deleteAction?: () => void;
    additionalActions?: AdditionalActionsProps[];
    isDeletable?: boolean;
    isBordered?: boolean;
    sx?: SxProps;
};

const ContactCard: React.FunctionComponent<ContactCardProps> = function (props) {
    // Types

    type ContactRowConfig = {
        icon: React.ElementType;
        collection: Array<contactCollectionType>;
        labelKey: 'email' | 'telephone' | 'mobile';
    };

    type ContactRowConfigsType = {
        emails: ContactRowConfig;
        phones: ContactRowConfig;
        mobiles: ContactRowConfig;
    };

    const {
        contact,
        editAction,
        deleteAction,
        additionalActions,
        isDeletable = true,
        isBordered = false,
        sx,
    } = props;

    const {
        nomContact: lastName,
        prenomContact: firstName,
        telephonesContact: phones,
        mobilesContact: mobiles,
        emailsContact: emails,
        fonction: role,
        principal: isMainContact,
        administrateur: isAdmin,
        comptabilite: isAccountingContact,
        note: noteRawContent,
    } = contact;

    const contactHasEmails = emails.length > 0;
    const contactHasPhones = phones.length > 0;
    const contactHasMobiles = mobiles.length > 0;
    const hasContactInfo = contactHasEmails || contactHasPhones || contactHasMobiles;

    const [noteHidden, setNoteHidden] = React.useState<boolean>(true);

    // Functions

    const getCardTitle = () => {
        if (isMainContact && isAccountingContact) return 'Contact Principal et de Comptabilité';
        if (isMainContact && !isAccountingContact) return 'Contact Principal';
        if (!isMainContact && isAccountingContact) return 'Contact de Comptabilité';

        return 'Contact';
    };

    const formatPhoneNumber = (phoneNumber: string) =>
        phoneNumber.replace(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1 $2 $3 $4 $5');

    const formatLastName = (string: string) => string.toUpperCase();

    const capitalizeFirstLetter = (string: string) =>
        string
            .split(/\s/)
            .map((word) => {
                if (word && word !== '')
                    return word[0].toUpperCase() + word.substring(1).toLowerCase();
                return '';
            })
            .join(' ');

    const splitStringWithAuthorPattern = (rawNote: string) => {
        // Author pattern (date dash 2 words space colon)
        const regex = /(\d{2}\/\d{2}\/\d{4} - [a-zA-Z&-9]* [a-zA-Z&-9]*\s*:*)/g;
        const splitSymbol = '/cutNotesHere/';

        const formattedNotes = rawNote.trim().replace(regex, `${splitSymbol} $1 ${splitSymbol}`);

        const splittedNotes = formattedNotes.split(splitSymbol);
        if (splittedNotes[0] === '') {
            // Normal case, Unshift first split
            splittedNotes.shift();
        } else {
            // If note start without author pattern, add blank author
            splittedNotes.unshift('');
        }

        return splittedNotes;
    };

    const groupNotesByAuthor = (splittedNotes: Array<string>) => {
        const authors: Array<string> = [];
        const notes: Array<string> = [];

        splittedNotes.forEach((element: string, index: number) => {
            // Limit note length in contact card context
            if (index > 5) {
                return;
            }

            const limitStringLength = (string: string, maxLength: number) =>
                string.length > maxLength
                    ? string.substring(0, maxLength - 3).concat('...')
                    : string;

            const item = element.trim();

            if (index % 2 === 0) {
                authors.push(limitStringLength(item, 50));
            } else {
                notes.push(limitStringLength(item, 200));
            }
        });

        const groupedNotes = authors.map((author: string, index: number) => ({
            author,
            note: notes[index] ?? '',
        }));

        return groupedNotes;
    };

    const formatRawNote = (rawNote: string) => {
        if (!rawNote) {
            return [];
        }

        const splittedString = splitStringWithAuthorPattern(rawNote);
        const notesGroupedByAuthor = groupNotesByAuthor(splittedString);
        return notesGroupedByAuthor;
    };

    // Styles

    const cardStyle = {
        minHeight: 'fit-content',
        height: '90%',
        display: 'flex',
        flexDirection: 'column',
        color: 'text.primary',
    };

    const contactRowsWrapperStyle = {
        display: 'flex',
        flexDirection: 'column',
        gap: '25px',
    };

    const columnDisplayStyle = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    };

    const renderRowStyle = {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '10px',
    };

    const renderIconStyle = { margin: '0px 10px 0px 0px' };

    const noteStyle = {
        paddingTop: '15px',
        maxHeight: 'fit-content',
    };

    const hiddenNoteStyle = {
        maxHeight: '60px',
        overflow: 'hidden',
        paddingTop: '15px',
        width: '100%',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    };

    // Render config & functions

    const rowsConfig: ContactRowConfigsType = {
        emails: {
            icon: MailIcon,
            collection: emails,
            labelKey: 'email',
        },
        phones: {
            icon: PhoneIcon,
            collection: phones,
            labelKey: 'telephone',
        },
        mobiles: {
            icon: PhoneAndroidIcon,
            collection: mobiles,
            labelKey: 'mobile',
        },
    };

    const renderRow = (type: 'emails' | 'phones' | 'mobiles') => {
        const rowConfig = rowsConfig[type];

        const { icon: Icon, collection, labelKey } = rowConfig;

        const isPhoneNumber = ['phones', 'mobiles'].includes(type);
        const isEmail = type === 'emails';

        type ItemType = { defaut: boolean } & { [key in 'email' | 'telephone' | 'mobile']: string };

        return (
            <Box sx={renderRowStyle}>
                <Icon
                    fontSize="small"
                    sx={renderIconStyle}
                />

                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    {collection.map((item: ItemType) => {
                        const { defaut: isDefault } = item;
                        return (
                            <Typography
                                key={item.email || item.telephone || item.mobile}
                                fontWeight={isDefault ? 'bold' : 'normal'}
                            >
                                {isPhoneNumber && formatPhoneNumber(item[labelKey])}
                                {isEmail && (
                                    <EmailLink
                                        email={item.email}
                                        tooltip="Envoyer un mail a ce contact"
                                    />
                                )}
                            </Typography>
                        );
                    })}
                </Box>
            </Box>
        );
    };

    const renderContactNameAndAvatar = () => (
        <Box
            sx={{ display: 'flex', gap: '12px' }}
            alignItems="start"
        >
            <CustomAvatar
                firstName={firstName}
                lastName={lastName}
                sx={{ width: '60px', height: '60px', fontSize: '1.8rem' }}
                data-testid="CustomAvatar"
            />

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '0px',
                    alignItems: 'start',
                }}
            >
                <Typography variant="h2">{`${capitalizeFirstLetter(firstName)} ${formatLastName(
                    lastName,
                )}`}</Typography>

                <Box sx={{ display: 'flex', gap: '15px' }}>
                    {role && <Typography>{role}</Typography>}
                    {isAdmin && (
                        <Chip
                            label="Admin"
                            size="small"
                            color="secondary"
                        />
                    )}
                </Box>
            </Box>
        </Box>
    );

    const renderNotes = () => {
        const groupedNotes = formatRawNote(noteRawContent ?? '');

        type NoteType = { author: string; note: string };

        return (
            <>
                <FlexyHeaderForm
                    label="Notes"
                    outlined
                    sx={{ paddingBottom: 0 }}
                />
                <span
                    style={noteHidden ? hiddenNoteStyle : noteStyle}
                    onClick={() => setNoteHidden(!noteHidden)}
                    aria-hidden="true"
                >
                    {groupedNotes &&
                        groupedNotes.map((note: NoteType) => (
                            <React.Fragment key={`${note.author}-${note.note}`}>
                                <Typography
                                    variant="h5"
                                    fontWeight="bold"
                                >
                                    {note.author}
                                </Typography>
                                <span>{note.note}</span>
                            </React.Fragment>
                        ))}
                </span>
            </>
        );
    };

    const content = (
        <Box sx={columnDisplayStyle}>
            {renderContactNameAndAvatar()}

            {hasContactInfo && (
                <>
                    <FlexyHeaderForm
                        label="Coordonnées"
                        outlined
                        sx={{ paddingBottom: 0 }}
                    />
                    <Box sx={contactRowsWrapperStyle}>
                        <Box>
                            {contactHasEmails ? renderRow('emails') : ''}
                            {contactHasPhones ? renderRow('phones') : ''}
                            {contactHasMobiles ? renderRow('mobiles') : ''}
                        </Box>
                    </Box>
                </>
            )}

            {renderNotes()}
        </Box>
    );

    if (isBordered) {
        return (
            <BorderedBox
                boxTitle={getCardTitle()}
                actionBarProps={{
                    editAction,
                    deleteAction: isDeletable ? deleteAction : undefined,
                    additionalActions: additionalActions || [],
                }}
                sx={{ minHeight: 'fit-content', height: '95%', ...sx }}
                borderWidth={3}
            >
                {content}
            </BorderedBox>
        );
    }

    return (
        <Card
            role="article"
            aria-label="ContactCard"
            sx={cardStyle}
        >
            <CardHeader
                title={<Typography fontWeight="bold">{getCardTitle()}</Typography>}
                action={
                    <ActionsBar
                        deleteAction={isDeletable ? deleteAction : undefined}
                        editAction={editAction}
                    />
                }
            />

            <CardContent>{content}</CardContent>
        </Card>
    );
};

export default ContactCard;
