import * as React from 'react';

export type keyboardAction = {
    key: string;
    action: () => void;
};

const useKeyboard = (keyboardActions: keyboardAction[]) => {
    const keyboardAction = React.useCallback(
        (event: KeyboardEvent) => {
            const curentKeyboardAction = keyboardActions.find(({ key }) => key === event.key);

            if (curentKeyboardAction) {
                curentKeyboardAction.action();
            }
        },
        [keyboardActions],
    );

    React.useEffect(() => {
        document.addEventListener('keydown', keyboardAction, false);

        return () => {
            document.removeEventListener('keydown', keyboardAction, false);
        };
    }, [keyboardAction]);

    return {};
};
export default useKeyboard;
