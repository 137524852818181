import {
    CenterCircularProgress,
    ContextActionType,
    CustomDivider,
    FlexyTabs,
    Information,
    LazyBreadcrumb,
    Legend,
    LogTimeline,
    NotFound,
    PdfViewer,
    UrlFile,
    useContextActions,
} from '@europrocurement/flexy-components';
import SendIcon from '@mui/icons-material/Send';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Box } from '@mui/system';
import React, { useEffect, useMemo } from 'react';
import { Card, Divider, Grid, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from '@b2d/redux/RootStore';
import { MainDossierSignatureSelector, selectDossierSignature } from '@b2d/redux/subReducers';
import { DataSource, EuroprocApiResponseStatus } from '@europrocurement/l2d-redux-utils';
import { DossierSignature, SignataireType, DocumentType } from '@europrocurement/l2d-domain';

import { signNamedIconsDossier } from '@europrocurement/l2d-icons';
import { signatureDuplicateFullPath, signatureListeFullPath } from '../../constants/paths';
import SignatureStatus from '../../components/SignatureStatus';
import SignataireCard from '../../components/SignataireCard';

import { useRelanceDossierSignature } from '../../functions/raiseFunctions';
import { eventToColor, eventToIcone, eventToTitle } from '../../functions/signTimelineUtils';

import DemandeurCard from '../../components/DemandeurCard';

/**
 *
    - visualisation d'un dossier de signature
        - lister les signataire
            - compo 1 signataire
                - statut
                - email
                - tel
                - nom
                - prenom
                - commentaire
        - statut général
        - logs ( timeline )
        - possibilité d'annuler
        - possibilité de relancer
        - possibilité de dupliquer

 */

const isoTolocalDate = (maDateIso: string) => new Date(Date.parse(maDateIso)).toLocaleDateString();

export const relanceAviable = (
    signataires: Array<Pick<SignataireType, 'refus' | 'signature'>>,
): boolean => {
    if (signataires.every((signataire) => signataire.signature === true)) {
        return false;
    }

    return true;
};

type CurrentDocumentType = {
    nom: string;
    url: string;
};

const DOC_SIGNED_NAME = 'document signé';

const DossierSignatureFiche: React.FunctionComponent = function () {
    const [currentDocument, setCurrentDocument] = React.useState<CurrentDocumentType | null>(null);
    const [isError404, setIsError404] = React.useState<boolean>(false);

    const { idDossierSignature } = useParams();

    if (!idDossierSignature) {
        throw new Error('idDossierSignature is required');
    }

    const dispatch = useAppDispatch();

    const dataSource: DataSource<DossierSignature> = useAppSelector(MainDossierSignatureSelector);

    const currentDossierSignature = dataSource.selected;
    const selectedEntityResponseStatus: EuroprocApiResponseStatus = dataSource.selectedStatus;

    useEffect(() => {
        if (
            currentDossierSignature &&
            `${currentDossierSignature.id}` === idDossierSignature &&
            currentDocument === null
        ) {
            if (currentDossierSignature.documents.length === 0) {
                return;
            }
            const firstDoc = currentDossierSignature.documents[0];

            setCurrentDocument({
                nom: firstDoc.signedContentUrl ? DOC_SIGNED_NAME : firstDoc.nom,
                url: firstDoc.signedContentUrl || firstDoc.contentUrl || '',
            });
        }
    }, [currentDocument, currentDossierSignature, idDossierSignature]);

    const relanceDossier = useRelanceDossierSignature();

    const actionContext = useContextActions();

    useEffect(() => {
        if (!dataSource.selected || `${dataSource.selected.id}` !== idDossierSignature) {
            if (dataSource.status !== 'loading') {
                dispatch(selectDossierSignature({ id: idDossierSignature }));
            }
        }
        // console.log(dataSource.selectedStatus);
        if (selectedEntityResponseStatus === 'failed') {
            setIsError404(true);
        }
    }, [
        dataSource.selected,
        dataSource.status,
        dispatch,
        idDossierSignature,
        selectedEntityResponseStatus,
    ]);

    const navigate = useNavigate();
    const contextActions: ContextActionType[] = useMemo(
        () => [
            {
                name: 'Dupliquer le dossier',
                icon: <ContentCopyIcon />,
                action: () => {
                    if (currentDossierSignature?.id !== undefined) {
                        navigate(
                            signatureDuplicateFullPath.replace(
                                ':idDossierSignature',
                                `${currentDossierSignature?.id}`,
                            ),
                        );
                        // navigate(`${signatureDuplicateFullPath}/${currentDossierSignature?.id}`);
                    }
                },
            },
            ...(currentDossierSignature?.id &&
            currentDossierSignature.id !== undefined &&
            relanceAviable(currentDossierSignature.signataires)
                ? [
                      {
                          name: 'Relancer signature',
                          icon: <SendIcon />,
                          action: () => {
                              if (currentDossierSignature?.id !== undefined) {
                                  relanceDossier(currentDossierSignature?.id);
                              }
                          },
                      },
                  ]
                : []),
        ],
        [
            currentDossierSignature?.id,
            currentDossierSignature?.signataires,
            navigate,
            relanceDossier,
        ],
    );

    useEffect(() => {
        if (currentDossierSignature?.id && currentDossierSignature.id !== undefined) {
            actionContext.defineContextActions(contextActions);
        }

        return () => {
            actionContext.resetContextActions();
        };
    }, [actionContext, contextActions, currentDossierSignature?.id]);

    // useDumbActionsContext(contextActions);

    if (isError404) {
        return <NotFound message="Dossier de signature non trouvé" />;
    }
    return currentDossierSignature && `${currentDossierSignature?.id}` === idDossierSignature ? (
        <Box>
            <LazyBreadcrumb
                path={[
                    { path: 'Signature électronique', link: signatureListeFullPath },
                    { path: 'Liste des dossiers', link: signatureListeFullPath },
                    'Détails du dossier',
                ]}
            />
            <Box
                display="flex"
                flexDirection="row"
                gap="15px"
            >
                <Typography
                    display="inline"
                    sx={{
                        fontSize: '36px',
                    }}
                >
                    <SignatureStatus
                        tooltip
                        signataires={currentDossierSignature.signataires}
                    />
                </Typography>
                <Typography variant="h1">{currentDossierSignature.sujet}</Typography>
            </Box>
            <Grid container>
                <Grid
                    item
                    xs={12}
                    md={6}
                >
                    <Card>
                        <Typography
                            variant="h4"
                            sx={{
                                marginBottom: '10px',
                            }}
                        >
                            {' '}
                            Informations du dossier{' '}
                        </Typography>
                        {currentDossierSignature.createdAt ? (
                            <Information
                                label="Création le"
                                value={isoTolocalDate(currentDossierSignature.createdAt)}
                            />
                        ) : null}
                        {currentDossierSignature.referenceExterne ? (
                            <Information
                                label="Reference externe"
                                value={currentDossierSignature.referenceExterne}
                            />
                        ) : null}
                        <CustomDivider />

                        <Information
                            label="Créateur"
                            value={`${currentDossierSignature.prescripteur} (${currentDossierSignature.prescripteurEmail})`}
                        />
                    </Card>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={6}
                >
                    <DemandeurCard
                        callbackUrl={currentDossierSignature.demandeur?.callbackUrl}
                        key={currentDossierSignature.demandeur?.id}
                        logo={currentDossierSignature.demandeur?.logo}
                        nom={currentDossierSignature.demandeur?.nom}
                        id={currentDossierSignature.demandeur?.id}
                        actif={currentDossierSignature.demandeur?.actif}
                        userIdentifier={currentDossierSignature.demandeur?.userIdentifier}
                        titre="Informations du référentiel"
                    />
                </Grid>
            </Grid>
            <FlexyTabs
                scrollbar={false}
                tabs={[
                    {
                        tabName: 'signataires',
                        tabPanel: (
                            <Grid container>
                                <Grid
                                    item
                                    xs={12}
                                    md={6}
                                >
                                    {currentDossierSignature.signataires &&
                                        currentDossierSignature.signataires.map(
                                            (signataire: SignataireType) => (
                                                <SignataireCard
                                                    key={`signataire-${signataire.id}-${signataire.email}`}
                                                    {...signataire}
                                                />
                                            ),
                                        )}
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    md={6}
                                >
                                    <Card>
                                        <Typography variant="h4"> Derniers évènements </Typography>
                                        {currentDossierSignature.logs ? (
                                            <LogTimeline
                                                logs={currentDossierSignature.logs}
                                                eventToColor={eventToColor}
                                                eventToIcone={eventToIcone}
                                                eventToTitle={eventToTitle}
                                            />
                                        ) : (
                                            <Typography variant="body1">
                                                {' '}
                                                Impossible d&apos;afficher les évènements{' '}
                                            </Typography>
                                        )}
                                    </Card>
                                </Grid>
                                {/* <Grid
                                    item
                                    xs={12}
                                    md={12}
                                >
                                    <Card>
                                        <JsonView data={currentDossierSignature} />
                                    </Card>
                                </Grid> */}
                            </Grid>
                        ),
                        tabTitle: 'Signataires et derniers évènements',
                    },
                    {
                        tabName: 'documents',
                        tabPanel: (
                            <Grid container>
                                <Grid
                                    item
                                    md={4}
                                >
                                    {currentDossierSignature.documents[0] &&
                                    currentDossierSignature.documents[0].signedContentUrl ? (
                                        <Typography variant="h4">Documents signés</Typography>
                                    ) : null}
                                    {currentDossierSignature.documents[0] &&
                                    currentDossierSignature.documents[0].signedContentUrl &&
                                    currentDossierSignature.documents
                                        ? currentDossierSignature.documents.map(
                                              (document: DocumentType) => (
                                                  <UrlFile
                                                      key={`${document.nom}-${document.size}-${document.id}-docsigne`}
                                                      url={document.signedContentUrl}
                                                      nom={`signe_${document.nom}`}
                                                      size={document.size}
                                                      mimetype={document?.mimeType || undefined}
                                                      handleClick={setCurrentDocument}
                                                  />
                                              ),
                                          )
                                        : null}
                                    {currentDossierSignature.documents[0] &&
                                    currentDossierSignature.documents[0].signedContentUrl ? (
                                        <>
                                            <Divider />{' '}
                                            <Typography variant="h4">
                                                Documents originaux
                                            </Typography>
                                        </>
                                    ) : null}
                                    {currentDossierSignature.documents
                                        ? currentDossierSignature.documents.map(
                                              (document: DocumentType) => (
                                                  <UrlFile
                                                      key={`${document.nom}-${document.size}-${document.id}`}
                                                      url={document.contentUrl}
                                                      nom={document.nom}
                                                      size={document.size}
                                                      mimetype={document?.mimeType || undefined}
                                                      handleClick={setCurrentDocument}
                                                  />
                                              ),
                                          )
                                        : null}
                                </Grid>
                                <Grid
                                    item
                                    md={8}
                                >
                                    <Card>
                                        {currentDocument ? (
                                            <PdfViewer
                                                title={currentDocument.nom}
                                                pdfUrl={currentDocument.url}
                                                markerDisable
                                            />
                                        ) : null}
                                    </Card>
                                    {/* <DocumentCard
                                        key={`document-${currentDossierSignature.documents[0].nom}`}
                                        {...currentDossierSignature.documents[0]}
                                    /> */}
                                </Grid>
                            </Grid>
                        ),
                        tabTitle: 'Documents',
                    },
                ]}
                routerParameterName="signatureTab"
            />
            <Legend icons={signNamedIconsDossier} />
        </Box>
    ) : (
        <CenterCircularProgress />
    );
};

export default DossierSignatureFiche;
