import * as React from 'react';
import { SpeedDial, SpeedDialAction, Tooltip } from '@mui/material';

// Icons
import SettingsIcon from '@mui/icons-material/Settings';
import BrushIcon from '@mui/icons-material/Brush';
import { ContextActionType, useContextActions } from '../../../providers/Actions';
import { useFullLayout } from '../../templates/FullLayout/FullLayoutContext';

type ContextActionsMenuProps = {
    mainIcon?: React.ReactNode;
};

const ContextActionsMenu: React.FunctionComponent<ContextActionsMenuProps> = function (props) {
    const { mainIcon = <SettingsIcon /> } = props;

    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(!open);
    };

    const { toggleCustomizerDrawer } = useFullLayout();

    const defaultActions: ContextActionType[] = [
        {
            name: 'Thème',
            icon: <BrushIcon />,
            action: toggleCustomizerDrawer,
        },
    ];

    const { contextActions } = useContextActions();

    const actions = [...contextActions, ...defaultActions];

    return (
        <SpeedDial
            data-testid="contextactionmenu"
            onOpen={() => null}
            onClick={handleOpen}
            open={open}
            ariaLabel="SpeedDial basic example"
            sx={{
                position: 'absolute',
                bottom: 16,
                right: 16,
                zIndex: 1600,
                // Children theme override / custom style
                '& .MuiButtonBase-root': {
                    backgroundColor: 'white',
                },
                '& .MuiButtonBase-root:hover': {
                    backgroundColor: '#e0e0e0',
                },
            }}
            icon={
                <Tooltip title={`${actions.length} action(s) disponibles`}>
                    <div>{mainIcon}</div>
                </Tooltip>
            }
        >
            {actions.map((actionItem: ContextActionType) => {
                const { action, name, icon } = actionItem;
                return (
                    <SpeedDialAction
                        key={name}
                        icon={icon}
                        tooltipTitle={name}
                        onClick={action}
                    />
                );
            })}
        </SpeedDial>
    );
};
export default ContextActionsMenu;
