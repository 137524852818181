/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { isEqual } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/pro-duotone-svg-icons';

import {
    CategorieStats,
    CodeRejetStats,
    FactureAchat,
    FACTURES_ACHATS_STATS_DATASOURCE_NAME,
} from '@europrocurement/l2d-domain';
import { DataSource } from '@europrocurement/l2d-redux-utils';
import {
    LazyBreadcrumb,
    ContextActionType,
    useContextActions,
} from '@europrocurement/flexy-components';
import { AppDispatch } from '@europrocurement/flexy-components/redux/storeConfig/store';
import { filtersHasher } from '@europrocurement/l2d-utils';
import { envBaseUrl } from '@b2d/utils/getBaseUrl';
import { useExport } from '@b2d/hooks/useExport';

import useToaster from '@b2d/hooks/useToaster';

import { FactureAchatApiExportCodesRejetsDashboardFactureAchatCollectionRequest } from '@europrocurement/l2d-domain/openApi/ApiAchats';
import {
    CategorieStatsSelector,
    CodeRejetStatsSelector,
    customizerSelector,
    factureAchatApi,
    FactureachatSelector,
    getCategorieStats,
    getCodeRejetStats,
    getFactureAchatStats,
} from '../../../../redux/RootStore';
import StatsTabs from '../../components/StatsTabs';
import { exportDataDashboard } from '../../constants/wording/toasts';

function getDateString() {
    const date = new Date();
    const year = date.getFullYear();
    const month = `${date.getMonth() + 1}`.padStart(2, '0');
    const day = `${date.getDate()}`.padStart(2, '0');
    const time = `${date.getHours()}${date.getMinutes()}${date.getSeconds()}`;

    return `${year}${month}${day}${time}`;
}

const StatsView = function () {
    const categories: DataSource<CategorieStats> = useSelector(CategorieStatsSelector).main;
    const codesRejets: DataSource<CodeRejetStats> = useSelector(CodeRejetStatsSelector).main;
    const facturesAchats: DataSource<FactureAchat> =
        useSelector(FactureachatSelector)[FACTURES_ACHATS_STATS_DATASOURCE_NAME];

    const dispatch = useDispatch<AppDispatch>();
    const { exportToXlsx } = useExport();
    const { handleApiResponse } = useToaster();
    const { xIdSociete, api } = useSelector(customizerSelector);

    type ExportCodesRejetsDashboardFactureAchatCollectionFilters = {
        xIdSociete?: number;
        idSociete?: number;
        dateFacture?: Array<string>;
        dateFactureBefore?: string;
        dateFactureStrictlyBefore?: string;
        dateFactureAfter?: string;
        dateFactureStrictlyAfter?: string;
        createdAt?: Array<string>;
        createdAtBefore?: string;
        createdAtStrictlyBefore?: string;
        createdAtAfter?: string;
        createdAtStrictlyAfter?: string;
        idEntiteFacturante?: number;
        idEntiteFacturante2?: Array<number>;
        lignesIdDossier?: number;
        lignesIdDossier2?: Array<number>;
        idJal?: number;
        idJal2?: Array<number>;
        categories?: string;
        codesRejets?: string;
        cloture?: boolean;
        deleted?: boolean;
    };

    const [filters, setFilters] = useState<ExportCodesRejetsDashboardFactureAchatCollectionFilters>(
        {},
    );

    const appliedFilters = useMemo(() => facturesAchats.filters, [facturesAchats.filters]);

    const updatedFilters = useMemo(() => {
        const newFilters = Object.keys(appliedFilters).reduce(
            (acc, key: keyof ExportCodesRejetsDashboardFactureAchatCollectionFilters) => {
                const value = appliedFilters[key];

                if (Array.isArray(value) && (key === 'createdAt' || key === 'dateFacture')) {
                    // eslint-disable-next-line prefer-destructuring
                    acc[`${key}Before`] = value[0];
                    // eslint-disable-next-line prefer-destructuring
                    acc[`${key}After`] = value[1];
                } else if (Array.isArray(value)) {
                    acc[key] = JSON.stringify(value) as any;
                } else {
                    acc[key] = value as any;
                }

                return acc;
            },
            { ...appliedFilters },
        );

        return newFilters;
    }, [appliedFilters]);

    useEffect(() => {
        setFilters((oldFilters) => {
            if (isEqual(oldFilters, updatedFilters)) return oldFilters;

            return updatedFilters;
        });
    }, [updatedFilters]);

    const actionDownload = useCallback(
        (response: any) => {
            const filename: string = `export-dashboard-${getDateString()}`;

            const formattedData: (string | number | Date)[][] = response.data.map((item: any) => {
                let formattedDateFacture;
                let formattedDateSaisie;
                let formattedLien;

                if (item.dateFacture && item.dateFacture.date) {
                    formattedDateFacture = new Date(item.dateFacture.date).toLocaleDateString(
                        'FR-fr',
                    );
                }

                if (item.dateSaisie && item.dateSaisie.date) {
                    formattedDateSaisie = new Date(item.dateSaisie.date).toLocaleDateString(
                        'FR-fr',
                    );
                }

                if (item.lien) {
                    formattedLien = `${envBaseUrl(api.achats)}${item.lien}`;
                }

                return {
                    ...item,
                    dateFacture: formattedDateFacture || item.dateFacture,
                    dateSaisie: formattedDateSaisie || item.dateSaisie,
                    lien: formattedLien || item.lien,
                };
            });

            exportToXlsx(formattedData, filename, filename);
        },
        [api.achats, exportToXlsx],
    );

    const actionCallApi = useCallback(async () => {
        const requestParameters: FactureAchatApiExportCodesRejetsDashboardFactureAchatCollectionRequest =
            {
                xIdSociete,
                idSociete: filters.idSociete,
                dateFactureBefore: filters.dateFactureBefore,
                dateFactureStrictlyBefore: filters.dateFactureStrictlyBefore,
                dateFactureAfter: filters.dateFactureAfter,
                dateFactureStrictlyAfter: filters.dateFactureStrictlyAfter,
                createdAtBefore: filters.createdAtBefore,
                createdAtStrictlyBefore: filters.createdAtStrictlyBefore,
                createdAtAfter: filters.createdAtAfter,
                createdAtStrictlyAfter: filters.createdAtStrictlyAfter,
                idEntiteFacturante: filters.idEntiteFacturante,
                idEntiteFacturante2: filters.idEntiteFacturante2,
                lignesIdDossier: filters.lignesIdDossier,
                lignesIdDossier2: filters.lignesIdDossier2,
                idJal: filters.idJal,
                idJal2: filters.idJal2,
                categories: filters.categories,
                codesRejets: filters.codesRejets,
                cloture: filters.cloture,
                deleted: filters.deleted,
                etats: '["todo","except"]',
            };

        handleApiResponse(
            factureAchatApi.exportCodesRejetsDashboardFactureAchatCollection(requestParameters),
            undefined,
            exportDataDashboard,
        ).then(
            (response) => {
                actionDownload(response);
            },
            (reason) => {
                console.error(reason);
            },
        );
    }, [actionDownload, filters, handleApiResponse, xIdSociete]);

    const contextActions: ContextActionType[] = useMemo(
        () => [
            {
                name: `Exporter les données `,
                icon: (
                    <FontAwesomeIcon
                        icon={faDownload}
                        size="xl"
                    />
                ),
                action: () => {
                    actionCallApi();
                },
                resetHash: filtersHasher(filters),
            },
        ],
        [actionCallApi, filters],
    );

    const { defineContextActions, resetContextActions } = useContextActions();

    useEffect(() => {
        defineContextActions(contextActions);
    }, [contextActions, defineContextActions]);

    useEffect(() => () => resetContextActions(), [resetContextActions]);

    const initCategorieStats = useCallback(() => {
        if (categories.status === 'idle') {
            dispatch(getCategorieStats({}));
        }
    }, [categories.status, dispatch]);

    const initCodeRejetStats = useCallback(() => {
        if (codesRejets.status === 'idle') {
            dispatch(getCodeRejetStats({}));
        }
    }, [codesRejets.status, dispatch]);

    const initFactureAchatStats = useCallback(() => {
        if (facturesAchats.status === 'idle') {
            dispatch(getFactureAchatStats({}));
        }
    }, [facturesAchats.status, dispatch]);

    useEffect(() => {
        initCategorieStats();
        initCodeRejetStats();
        initFactureAchatStats();
    }, [initCategorieStats, initCodeRejetStats, initFactureAchatStats]);

    return (
        <>
            <LazyBreadcrumb
                path={[
                    { path: 'Portail achats', link: '/achats/stats' },
                    "Nombre de factures d'achat en rejet",
                ]}
            />
            <StatsTabs />
        </>
    );
};

export default StatsView;
