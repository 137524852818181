import React, { useCallback, useEffect, useMemo, FunctionComponent } from 'react';

import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Chip, Typography } from '@mui/material';

import {
    ColumnDatatable,
    FiltersDatatableList,
    StoreDatatable,
} from '@europrocurement/flexy-datatable';
import { FactureAchatApiObject, MediaObject } from '@europrocurement/l2d-domain';
import { ListActionButton } from '@europrocurement/flexy-components';
import { DataSource } from '@europrocurement/l2d-redux-utils';
import { ACTIONS } from '@b2d/redux/FactureFormReducer';
import generateB2DPath from '@b2d/utils/generateB2DPath';

import { AppDispatch, InvoiceStatementSelector, getInvoiceStatement } from '@b2d/redux/RootStore';
import { faCheck, faFileCirclePlus } from '@fortawesome/pro-duotone-svg-icons';

import { ReleveAchat } from '@europrocurement/l2d-domain/reducers/achats/slices/invoiceStatementSlice';
import { useSociete } from '@b2d/hooks/societeHooks';
import models from '../../models';

export type UnclosedInvoiceStatementsListProps = {
    additionalFilters?: FiltersDatatableList;
};

const UnclosedInvoiceStatementsList: FunctionComponent<UnclosedInvoiceStatementsListProps> =
    function ({ additionalFilters }: UnclosedInvoiceStatementsListProps) {
        const xIdSociete = useSociete();

        const statementDataSource: DataSource<ReleveAchat> =
            useSelector(InvoiceStatementSelector).main;

        const dispatch = useDispatch<AppDispatch>();
        useEffect(() => {
            dispatch(getInvoiceStatement({}));
        }, [dispatch]);

        const navigate = useNavigate();
        const { enqueueSnackbar } = useSnackbar();

        const resetStates = useCallback(() => {
            // clean du state du formulaire
            dispatch({
                type: ACTIONS.RESET,
            });
        }, [dispatch]);

        type NavigateToReleveAchatProps = {
            statementId: number;
            openNewTab?: boolean;
        };

        /**
         * Génère le chemin d'accès vers le formulaire de saisie d'une facture,
         * puis s'y dirige.
         *
         * @param statement ReleveAchat
         * @returns void
         */
        const navigateToReleveAchat = useCallback(
            ({ statementId, openNewTab = false }: NavigateToReleveAchatProps) => {
                if (statementDataSource.selected) {
                    resetStates();
                }

                const response = generateB2DPath('unclosedStatementView', { statementId });

                if (response.status === 'STAY') {
                    return;
                }

                if (response.status === 'KO') {
                    enqueueSnackbar(<Typography>{response.message}</Typography>, {
                        variant: 'warning',
                    });
                    return;
                }

                if (openNewTab) {
                    window.open(response.path, '_blank', 'rel=noopener noreferrer');
                    return;
                }

                navigate(response.path);
            },
            [enqueueSnackbar, statementDataSource.selected, navigate, resetStates],
        );

        const onValidate = useCallback(
            async (statement: ReleveAchat) => {
                models.invoiceStatement
                    .patch({
                        id: `${statement.id}`,
                        xIdSociete,
                        releveAchatReleveAchatWrite: {
                            cloture: true,
                        },
                    })
                    .then(() => {
                        dispatch(getInvoiceStatement({}));
                    });
            },
            [dispatch, xIdSociete],
        );

        const columns: ColumnDatatable<MediaObject>[] = useMemo(
            () => [
                {
                    label: 'Id',
                    render: 'id',
                    isDisplayed: true,
                },
                {
                    label: 'Date de dépôt',
                    render: (mediaObject: MediaObject) => {
                        let moCreationDate = null;

                        if (mediaObject.createdAt) {
                            moCreationDate = new Date(
                                Date.parse(mediaObject.createdAt),
                            ).toLocaleDateString();
                        }

                        return <Typography>{moCreationDate}</Typography>;
                    },
                    field: 'createdAt',
                    sortable: true,
                    isDisplayed: true,
                },
                {
                    label: 'Déposé par',
                    render: 'createdBy',
                    isDisplayed: true,
                },
                {
                    label: 'Facture liée',
                    onClickCell: () => {},
                    render: (mediaObject: MediaObject) =>
                        !mediaObject.facturesAchat || mediaObject.facturesAchat.length === 0
                            ? null
                            : mediaObject.facturesAchat.map((facture: FactureAchatApiObject) => (
                                  <Chip
                                      color="warning"
                                      label={`${facture.numeroFacture}`}
                                      size="small"
                                      key={`${facture.numeroFacture}-${facture.id}-${mediaObject.id}`}
                                      onClick={() => {
                                          navigate(`modifier/${facture.id}/${mediaObject.id}`);
                                      }}
                                      sx={{
                                          color: '#3e3e3e', // Nécessaire pour l'accessibilité
                                      }}
                                  />
                              )),
                    isDisplayed: false,
                },
                {
                    label: 'Actions',
                    onClickCell: () => {},
                    render: (statement: ReleveAchat) =>
                        statement.id ? (
                            <>
                                <ListActionButton
                                    tooltip="Saisir"
                                    onClick={() => {
                                        navigateToReleveAchat({
                                            statementId: statement.id as number,
                                        });
                                    }}
                                    icon={faFileCirclePlus}
                                />
                                <ListActionButton
                                    tooltip="Supprimer"
                                    onClick={() => {
                                        onValidate(statement);
                                    }}
                                    icon={faCheck}
                                />
                            </>
                        ) : null,
                    isDisplayed: true,
                },
            ],
            [navigate, navigateToReleveAchat, onValidate],
        );

        const filters: FiltersDatatableList = [
            {
                field: 'processingFacture',
                label: 'Pdf en traitement',
                type: 'boolean',
            },
        ];

        return statementDataSource ? (
            <StoreDatatable
                dataSource={statementDataSource}
                columns={columns}
                fetchData={getInvoiceStatement}
                filters={[...(additionalFilters || []), ...filters]}
                localStorageKey="FlexyStoreDatatableInvoiceStatement"
                onClickRow={(event, item) => {
                    if (item && item.id) {
                        navigateToReleveAchat({ statementId: item.id as number });
                    }
                }}
                onWheelClickRow={(event, item) => {
                    if (item && item.id) {
                        navigateToReleveAchat({ statementId: item.id as number, openNewTab: true });
                    }
                }}
            />
        ) : null;
    };

export default UnclosedInvoiceStatementsList;
