import { categoriesFormalitySelector } from '@b2d/redux/subReducers/FormalityReducer/Selectors';
import { FlexyForm, FormStructure } from '@europrocurement/flexy-form';
import { FaOptionIcon, editIcon } from '@europrocurement/l2d-icons';
import { IconName, findIconDefinition } from '@fortawesome/fontawesome-svg-core';
import { Box, Button } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import useFormulaire from '../../hooks/useFormulaire';

type FormalityFormProps = {
    onSubmit: (formData: { [key: string]: string | number | null }) => void;
    onCancel: () => void;
    isFormProcessing: boolean;
};

const FormalityForm: React.FunctionComponent<FormalityFormProps> = function (props) {
    const { onSubmit, onCancel, isFormProcessing } = props;
    const categoriesDatasource = useSelector(categoriesFormalitySelector);
    const { listFormulaires } = useFormulaire();
    const formulairesOptions = listFormulaires();

    const formStructure: FormStructure[] = [
        {
            type: 'header',
            label: (
                <>
                    <FaOptionIcon
                        {...editIcon.props}
                        iconProps={{ style: { marginRight: '5px' } }}
                    />
                    Informations principales
                </>
            ),
            name: 'main_informations',
        },
        {
            type: 'text',
            name: 'libelle',
            inputlabel: 'Libellé',
            rules: {
                required: 'Vous devez renseigner un libellé',
            },
            placeholder: 'Le libellé de ma formalité',
        },
        {
            type: 'text',
            name: 'informationsSupplementaires',
            inputlabel: 'Informations supplémentaires',
            placeholder: 'Informations supplémentaires',
        },
        {
            type: 'select',
            name: 'categorie',
            inputlabel: 'Catégorie',
            options: categoriesDatasource.data,
            getOptionLabel: ({ libelle }: { libelle: string }) => libelle,
            getOptionValue: ({ id }: { id: string }) => id,
            placeholder: 'Veuillez sélectionner une catégorie',
            rules: {
                required: 'Vous devez sélectionner une catégorie',
            },
        },
        {
            type: 'text',
            name: 'iconeName',
            inputlabel: 'Icône (https://fontawesome.com/search)',
            placeholder: 'Icône',
            rules: {
                validate: {
                    isAnIcon: (value) => {
                        if (!value || value === '') {
                            return true;
                        }
                        const icon = findIconDefinition({ iconName: value });
                        console.log(icon);
                        if (icon) {
                            return true;
                        }
                        return "L'icône est invalide";
                    },
                },
            },
            xs: 12,
            sm: 12,
            md: 9,
            lg: 9,
        },
        {
            type: 'customItem',
            name: 'renderIcone',
            xs: 12,
            sm: 12,
            md: 3,
            lg: 3,
            renderField: (formContext) => {
                let iconeRender: string | null = formContext.getValues('iconeName');
                if (!iconeRender || !findIconDefinition({ iconName: iconeRender })) {
                    iconeRender = 'image-slash';
                }
                console.log(iconeRender);
                return (
                    <Box
                        sx={{
                            fontSize: '40px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: '15px',
                        }}
                    >
                        <FaOptionIcon icon={['fad', iconeRender as IconName]} />
                    </Box>
                );
            },
        },
        {
            type: 'select',
            name: 'formulaire',
            inputlabel: 'Formulaire',
            options: formulairesOptions,
            getOptionLabel: ({ libelle }: { libelle: string }) => libelle,
            getOptionValue: ({ id }: { id: string }) => id,
            placeholder: 'Veuillez sélectionner un formulaire',
        },
    ];

    const formContext = useForm({
        mode: 'onTouched',
    });

    const formProps = {
        formContext,
        formObject: {},
        onSubmit,
        submitButton: {
            displayed: false,
        },
    };

    formContext.watch(['iconeName']);

    return (
        <>
            <FlexyForm
                formStructure={formStructure}
                {...formProps}
            />
            <Box
                display="flex"
                justifyContent="end"
            >
                <Box
                    display="flex"
                    sx={{ marginTop: '25px' }}
                >
                    <Button
                        variant="outlined"
                        onClick={onCancel}
                        sx={{ marginRight: '15px' }}
                    >
                        Annuler
                    </Button>
                    <Button
                        variant="contained"
                        onClick={formContext.handleSubmit(onSubmit)}
                        disabled={isFormProcessing}
                    >
                        Ajouter une formalité
                    </Button>
                </Box>
            </Box>
        </>
    );
};

export default FormalityForm;
