import { NamedIconsType } from '@europrocurement/l2d-icons/types';
import {
    faCalendar,
    faEnvelope,
    faInputNumeric,
    faInputText,
    faList,
    faListRadio,
    faText,
} from '@fortawesome/pro-duotone-svg-icons';

export const textInput: NamedIconsType = {
    props: { icon: faInputText },
    name: 'text',
    displayName: 'Champ texte',
};

export const textAreaInput: NamedIconsType = {
    props: { icon: faText },
    name: 'textarea',
    displayName: 'Champ zone de texte',
};

export const numberInput: NamedIconsType = {
    props: { icon: faInputNumeric },
    name: 'number',
    displayName: 'Champ nombre',
};

export const selectInput: NamedIconsType = {
    props: { icon: faList },
    name: 'select',
    displayName: "Selecteur d'options",
};
export const checkboxInput: NamedIconsType = {
    props: { icon: faList },
    name: 'checkbox',
    displayName: 'Case à cocher',
};
export const dateInput: NamedIconsType = {
    props: { icon: faCalendar },
    name: 'date',
    displayName: 'Champ date',
};
export const radioInput: NamedIconsType = {
    props: { icon: faListRadio },
    name: 'radio',
    displayName: 'Champ bouton radio',
};
export const emailInput: NamedIconsType = {
    props: { icon: faEnvelope },
    name: 'email',
    displayName: 'Champ email',
};

export const inputIcons: NamedIconsType[] = [
    textInput,
    textAreaInput,
    numberInput,
    selectInput,
    checkboxInput,
    dateInput,
    radioInput,
    emailInput,
];
