import React, { useEffect, useRef } from 'react';

import { Alert, Box, Button, Typography } from '@mui/material';

import { Modalizer } from '@europrocurement/flexy-components';

export type MessagesTypes = {
    question?: string;
    detail?: {
        message: string;
        severity: 'error' | 'warning' | 'info' | 'success';
    };
    validation?: string;
    annulation?: string;
    alternative?: string;
};

export type ConfirmationModalProps = {
    isModalOpen: boolean;
    isDraggable?: boolean;
    closeModal?: () => void;
    messages?: MessagesTypes;
    actionOnValidation?: () => void;
    actionOnCancellation?: () => void;
    actionOnAlternative?: () => void;
};

const ConfirmationModal: React.FunctionComponent<ConfirmationModalProps> = function ({
    isModalOpen,
    isDraggable,
    closeModal,
    messages,
    actionOnValidation,
    actionOnCancellation,
    actionOnAlternative,
}) {
    const buttonRef = useRef<HTMLButtonElement | null>(null);

    useEffect(() => {
        const keyDownHandler = (ev: KeyboardEvent) => {
            if (ev.key === 'Enter' && buttonRef.current) {
                ev.preventDefault();

                // 👇️ your logic here
                buttonRef.current.click();
            }
        };
        if (isModalOpen) {
            document.addEventListener('keydown', keyDownHandler);
        } else {
            document.removeEventListener('keydown', keyDownHandler);
        }

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [isModalOpen]);

    return (
        <Modalizer
            open={isModalOpen}
            onClose={() => {
                if (closeModal) closeModal();
            }}
            isDraggable={isDraggable}
        >
            <Box>
                <Typography style={{ marginBottom: '8px' }}>
                    {messages?.question || 'Êtes-vous sûr de vouloir valider cette action ?'}
                </Typography>
                {messages?.detail ? (
                    <Alert severity={messages.detail.severity}>{messages.detail.message}</Alert>
                ) : null}
                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    gap={2}
                >
                    {actionOnCancellation ||
                    (!actionOnCancellation && !actionOnAlternative) ||
                    messages?.annulation ? (
                        <Button
                            color="primary"
                            variant="outlined"
                            onClick={actionOnCancellation}
                            style={{ marginTop: '8px', marginBottom: '8px' }}
                        >
                            {messages?.annulation || 'Annuler'}
                        </Button>
                    ) : null}
                    <Button
                        ref={buttonRef}
                        color="primary"
                        variant="contained"
                        onClick={actionOnValidation}
                        style={{ marginTop: '8px', marginBottom: '8px' }}
                    >
                        {messages?.validation || 'Valider'}
                    </Button>
                    {actionOnAlternative && messages?.alternative ? (
                        <Button
                            ref={buttonRef}
                            color="primary"
                            variant="outlined"
                            onClick={actionOnAlternative}
                            style={{ marginTop: '8px', marginBottom: '8px' }}
                        >
                            {messages.alternative}
                        </Button>
                    ) : null}
                </Box>
            </Box>
        </Modalizer>
    );
};

export default ConfirmationModal;
