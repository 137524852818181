import {
    SliceFactory,
    FetchCollectionData,
    FetchItemDataType,
    ApiCollectionResponse,
} from '@europrocurement/l2d-redux-utils';
import {
    ConfigurationParameters as OffreConfigurationParameters,
    Configuration,
    CatalogueApi,
    CatalogueCatalogueRead,
} from '../../../openApi/ApiOffre';
import { OFFRE_REDUCER_NAME } from '../constants';

export type CatalogApiObject = CatalogueCatalogueRead & Record<string, unknown>;

export type Catalogs = CatalogApiObject;

export const CATALOGS_SLICE_NAME = 'catalogs';

export const mapperCatalogs = function (catalogs: CatalogApiObject) {
    return {
        ...catalogs,
    } as Catalogs;
};

export function createCatalogsSlice(configuration: OffreConfigurationParameters) {
    const conf = new Configuration(configuration);
    const api = new CatalogueApi(conf);

    // apiPrescripteursCataloguesGetCollection
    const fetchCatalogsCollection: FetchCollectionData<CatalogApiObject> = ({
        pagination: { page, itemsPerPage },
        xIdSociete,
    }) =>
        api
            .apiCataloguesGetCollection({
                xIdSociete,
                page: page + 1,
                itemsPerPage,
            })
            .then((res) => res.data)
            .catch((error) => {
                console.error(error);
            }) as Promise<ApiCollectionResponse<CatalogApiObject>>;

    const fetchCatalogsItem: FetchItemDataType<CatalogApiObject> = ({ idItem, xIdSociete }) =>
        api
            .apiCataloguesIdGet({ id: String(idItem), xIdSociete })
            .then((res) => res.data) as Promise<CatalogApiObject>;

    const { slice: catalogsSlice, dataSourcesThunks: catalogsDataSourcesThunks } =
        SliceFactory.createSlice<CatalogApiObject, Catalogs>(
            CATALOGS_SLICE_NAME,
            OFFRE_REDUCER_NAME,
            [],
            fetchCatalogsCollection,
            fetchCatalogsItem,
            mapperCatalogs,
        );

    return { catalogsSlice, catalogsDataSourcesThunks };
}
