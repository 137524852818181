// Phone number must start with 0, followed by 1 digit
// Then 4 groups of 2 digits
// Allow optionnal space between group of numbers

// import { ValidationRule } from 'react-hook-form';

export const PHONE_NUMBER_VALIDATION_REGEX: RegExp = /^0\d\s?([\d]{2}[\s]?){4}$/;
export const EMAIL_VALIDATION_REGEX: RegExp = /^[\w\-.]+@([\w-]+.)+[\w-]{2,4}$/;
export const POST_CODE_VALIDATION_REGEX: RegExp = /^\d{5}$/;
export const LANDLINE_PHONE_NUMBER_REGEX: RegExp = /^(0|\+33)[1-5;8-9]([\s]?[0-9]{2}){4}$/;
export const MOBILE_PHONE_NUMBER_REGEX: RegExp = /^(0|\+33)[6-7]([\s]?[0-9]{2}){4}$/;
export const FILE_NAME: RegExp = /^[\w-]+$/; // Any digit / letters / underscore / dash
export const PRICE_REGEX = /^[0-9]+[.,]{1}[0-9]{1,4}$|^[0-9]*$/; // Money regex (number / decimal max 4 digits / coma or dot as separator)
export const URL_LOGO: RegExp = /^(http(s?):)([/|.|\w|\s|-])*\.(?:jpg|png|svg)$/;
export const URL_CALLBACK: RegExp = /^(http(s?):)([/|.|\w|\s|-])*$/;

// export const RULE_URL_LOGO: ValidationRule = {
//     value: URL_LOGO,
//     message: 'Merci de renseigner une URL de logo correcte (au format jpg, png ou svg)',
// };
