import React, { PropsWithChildren } from 'react';

import { Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { UploaderContext, Base64File, FilesBase64Cache } from './UploaderContext';

const toBase64 = (file: File) =>
    new Promise<Base64File | 'error'>((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () =>
            resolve({
                nom: file.name,
                base64: reader.result,
            } as Base64File);
        reader.onerror = () => {
            resolve('error');
        };
    });

const fileExist = (file: File, files: File[]) =>
    files.some(
        (f) => f.name === file.name && f.size === file.size && f.lastModified === file.lastModified,
    );

export const UploaderProvider: React.FunctionComponent<PropsWithChildren> = function ({
    children,
}) {
    const [files, setFiles] = React.useState<File[]>([]);
    const [filesBase64, setFilesBase64] = React.useState<FilesBase64Cache[]>([]);
    const [base64Ready, setBase64Ready] = React.useState<boolean>(true);

    // Fonction de traitement pour chaque element de filesBase64

    // filesBase64 = [] car state reinitialisé > useCallback useMemo ??

    const syncBase64 = React.useCallback(
        async (newFilesBase64: FilesBase64Cache[], currentFiles: File[]) => {
            const promiseArray = newFilesBase64.map((item, index) => {
                if (item) {
                    return Promise.resolve(item);
                }
                return toBase64(currentFiles[index]);
            });

            setFilesBase64(await Promise.all(promiseArray));

            setBase64Ready(true);
        },
        [],
    );

    const { enqueueSnackbar } = useSnackbar();

    const setFileWrapper = React.useCallback(
        (currentFiles: File[], currentBase64: FilesBase64Cache[]) => {
            setFiles(currentFiles);
            setBase64Ready(false);
            syncBase64(currentBase64, currentFiles);
        },
        [syncBase64],
    );

    const errorProcessing = React.useCallback(
        (error: string) => {
            enqueueSnackbar(<Typography>{error}</Typography>, {
                variant: 'error',
            });
            throw new Error(error);
        },
        [enqueueSnackbar],
    );

    const addFile: (file: File) => void = React.useCallback(
        (file) => {
            if (fileExist(file, files)) {
                errorProcessing('Ce fichier a déjà été ajouté');
            }

            setFileWrapper([...files, file], [...filesBase64, false]);
        },
        [files, filesBase64, setFileWrapper, errorProcessing],
    );

    const addFiles: (toAddfiles: File[] | FileList) => void = React.useCallback(
        (fileList) => {
            const toAddfiles = Array.from(fileList);

            toAddfiles.forEach((file) => {
                if (fileExist(file, files)) {
                    errorProcessing('Ce fichier a déjà été ajouté');
                }
            });

            setFileWrapper(
                [...files, ...toAddfiles],
                [...filesBase64, ...toAddfiles.map(() => false as Base64File | 'error' | false)],
            );
        },
        [files, filesBase64, setFileWrapper, errorProcessing],
    );

    const removeFile: (index: number) => void = React.useCallback(
        (index) => {
            setFileWrapper([...files].toSpliced(index, 1), [...filesBase64].toSpliced(index, 1));
        },
        [files, filesBase64, setFileWrapper],
    );

    const replaceFile: (index: number, file: File) => void = React.useCallback(
        (index, file) => {
            setFileWrapper(
                [...files].toSpliced(index, 1, file),
                [...filesBase64].toSpliced(index, 1, false),
            );
        },
        [files, filesBase64, setFileWrapper],
    );

    const clearFiles: () => void = React.useCallback(() => {
        setFileWrapper([], []);
        setBase64Ready(true);
    }, [setFileWrapper]);

    const resetFiles: (toAddfiles: File[] | FileList) => void = React.useCallback(
        (fileList) => {
            const toAddfiles = Array.from(fileList);

            setFileWrapper(
                [...toAddfiles],
                [...toAddfiles.map(() => false as Base64File | 'error' | false)],
            );
        },
        [setFileWrapper],
    );

    const contextValue = React.useMemo(
        () => ({
            files,
            filesBase64,
            base64Ready,
            addFile,
            addFiles,
            removeFile,
            replaceFile,
            clearFiles,
            resetFiles,
            getFilesBase64: () =>
                // console.log("j'appel le getter");
                filesBase64,
        }),
        [
            files,
            filesBase64,
            base64Ready,
            addFile,
            addFiles,
            removeFile,
            replaceFile,
            clearFiles,
            resetFiles,
        ],
    );

    return <UploaderContext.Provider value={contextValue}>{children}</UploaderContext.Provider>;
};
