import { BaseTextFieldProps, FormHelperText, lighten, darken, useTheme } from '@mui/material';
import React from 'react';
import Select, { ControlProps, GroupBase, defaultTheme, Theme } from 'react-select';

export type FlexySelectProps = React.ComponentProps<typeof Select> & {
    error?: BaseTextFieldProps['error'];
    helperText?: BaseTextFieldProps['helperText'];
};
/**
 * FlexySelect permet de sélectionner une valeur pré-défini dans une liste déroulante.
 */
const FlexySelect: React.FunctionComponent<FlexySelectProps> = function ({
    ...props
}: FlexySelectProps) {
    const theme = useTheme();

    const getBorderColor = () => {
        const errorColor = theme.palette.error?.main;
        const colorFromPalette = theme.palette.divider; // Todo use theme variable instead
        return `${props.error ? errorColor : colorFromPalette}`;
    };

    const overidesStyle = function (state: ControlProps<unknown, boolean, GroupBase<unknown>>) {
        const base = {
            borderWidth: '1px',
            borderRadius: theme.shape.borderRadius,
            background: 'none',
            color: theme.palette.text?.primary || 'inherit',
            borderColor: getBorderColor(),
        };
        if (state.isFocused) {
            return {
                ...base,
                borderWidth: '1px',
            };
        }
        if (state.isDisabled) {
            return {
                ...base,
                color: '#767e89',
                opacity: '1',
                backgroundColor: `${
                    theme.palette.mode === 'dark' ? 'rgba(0, 0, 0, 0.12) ' : '#f8f9fb '
                }`,
            };
        }
        return base;
    };

    const getTheme = function (): Theme {
        const themised = {
            ...defaultTheme,
        };

        if (theme.palette.primary) {
            themised.colors = {
                ...defaultTheme.colors,
                primary: theme.palette.primary.main,
                primary75: theme.palette.primary.light,
                primary50: lighten(theme.palette.primary.main, 0.5),
                primary25: lighten(theme.palette.primary.main, 0.25),
            };
        }
        if (theme.palette.warning) {
            themised.colors.danger = theme.palette.warning.main;
            themised.colors.dangerLight = theme.palette.warning.light;
        }

        return themised;
    };

    return (
        <>
            <Select
                styles={{
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        ...overidesStyle(state),
                    }),
                    menu: (base) => ({
                        ...base,
                        backgroundColor: theme.palette.background.paper || base.backgroundColor,
                    }),

                    singleValue: (base) => ({
                        ...base,
                        color: theme.palette.text.primary || base.color,
                    }),

                    input: (base) => ({
                        ...base,
                    }),

                    placeholder: (base) => ({
                        ...base,
                        color: theme.palette.text?.disabled,
                    }),

                    // Fix Z-index issue
                    menuPortal: (base) => {
                        const newBase = {
                            ...base,
                            zIndex: 9999,
                        };
                        return newBase;
                    },

                    menuList: (base) => ({
                        ...base,

                        '::-webkit-scrollbar': {
                            width: '8px',
                            height: '0px',
                        },
                        '::-webkit-scrollbar-track': {
                            background: darken(theme.palette.background.paper, 0.1),
                        },
                        '::-webkit-scrollbar-thumb': {
                            borderRadius: '4px',
                            background: theme.palette.secondary.main || base.background,
                        },
                        '::-webkit-scrollbar-thumb:hover': {
                            background:
                                lighten(theme.palette.secondary.main, 0.3) || base.background,
                        },
                    }),

                    option: (base, state) => ({
                        ...base,
                        backgroundColor: state.isSelected
                            ? theme.palette.primary.main || base.backgroundColor
                            : theme.palette.background.paper,
                    }),
                }}
                theme={getTheme()}
                {...props}
                menuPosition="fixed"
            />
            <FormHelperText
                sx={{
                    margin: '4px 14px 0px 14px',
                }}
                error={props.error}
            >
                {props.helperText}
            </FormHelperText>
        </>
    );
};

// const FlexySelect = withTheme(FlexySelectRaw);

export default FlexySelect;
