import React, { useEffect } from 'react';

import { useFullLayout } from '@europrocurement/flexy-components/components/templates/FullLayout/FullLayoutContext';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';

const PanelTests = function () {
    const { setSidebarOpen } = useFullLayout();

    useEffect(() => {
        setSidebarOpen(false);
        return () => {
            setSidebarOpen(true);
        };
    }, [setSidebarOpen]);

    return (
        <Box>
            <Typography variant="h4">comming soon</Typography>
            {/* <iframe
                title="last-test-report"
                src="https://react-frontend-europrocurement.s3.eu-west-3.amazonaws.com/test-reports/develop/coverage/index.html"
            /> */}
        </Box>
    );
};

export default PanelTests;
