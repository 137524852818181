import React, { FunctionComponent, useState } from 'react';

import achatsViewPathResolver from '@b2d/pages/Achats/achatsViewPathResolver';
import { mediaObjectApi } from '@b2d/redux/RootStore';
import { DataCard } from '@europrocurement/flexy-components';
import { FaOptionIcon } from '@europrocurement/l2d-icons';
import { faFileInvoice } from '@fortawesome/pro-duotone-svg-icons';
import { faUser } from '@fortawesome/pro-solid-svg-icons';
import { Button, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import { UseKeycloakService } from '@europrocurement/l2d-keycloak';

// interne

const MesFacturesATraiter: FunctionComponent = function () {
    const navigate = useNavigate();
    const kc = UseKeycloakService();

    const [countOfMyUnregisteredInvoices, setCountOfMyUnregisteredInvoices] =
        useState<string>('...');

    mediaObjectApi
        .listMediaObjectMediaObjectCollection({
            page: 1,
            itemsPerPage: 0,
            createdBy: kc.getEmail(),
            dateDebutTraitementBefore: new Date(new Date().getTime() - 2 * 60 * 1000).toISOString(),
            available: true,
            forceInterne: true,
        })
        .then((res) => {
            if (
                res.data['hydra:totalItems'] !== null ||
                res.data['hydra:totalItems'] !== undefined
            ) {
                setCountOfMyUnregisteredInvoices(`${res.data['hydra:totalItems']}`);
            }
        });

    return (
        <Grid
            sx={{
                flex: 1,
            }}
            item
            lg={1}
            md={1}
            xs={12}
        >
            <DataCard
                title="Mes factures à traiter"
                color="blueGrey"
                iconBgColor="transparent"
                iconColor="transparent"
                icon={
                    <FaOptionIcon
                        icon={faFileInvoice}
                        option={faUser}
                        size="2x"
                        color="blueGrey.main"
                        optionColor="blueGrey.dark"
                    />
                }
                tools={
                    <Button
                        color="blueGrey"
                        variant="outlined"
                        onClick={() => {
                            navigate(achatsViewPathResolver('listmediaobjectinternally'));
                        }}
                    >
                        Traiter
                    </Button>
                }
            >
                <Typography
                    sx={{
                        fontSize: '18px',
                        textAlign: 'center',
                    }}
                >
                    Mes factures à traiter :
                </Typography>
                <Typography
                    color="blueGrey.main"
                    component="h2"
                    sx={{
                        fontSize: '32px',
                        textAlign: 'center',
                    }}
                >
                    <b>{countOfMyUnregisteredInvoices}</b>
                </Typography>
            </DataCard>
        </Grid>
    );
};

export default MesFacturesATraiter;
