/* eslint-disable react/no-unstable-nested-components */

import * as React from 'react';
import { Avatar, Box, Button, useMediaQuery, Link } from '@mui/material';
import {
    BorderedBox,
    CustomDivider,
    Information,
    ModalContext,
} from '@europrocurement/flexy-components';

import ApartmentIcon from '@mui/icons-material/Apartment';
import ExistingPrescribersInformation from '@b2d/pages/Prescripteurs/component/ExistingPrescribersInformation';
import { usePrescriberUtils } from '@b2d/hooks/prescriberHooks';
import { Theme, useTheme } from '@mui/system';
import MainInformationForm from '../../forms/MainInformationForm';

type CompanyInformationsCardProps = {
    afterSubmit: () => void;
};

const CompanyInformationsCard: React.FunctionComponent<CompanyInformationsCardProps> = function (
    props,
) {
    const { afterSubmit } = props;

    const { modalActions } = React.useContext(ModalContext);

    const { prescriber } = usePrescriberUtils();

    const handleEditMainInformation = () => {
        if (prescriber.id) {
            modalActions.call(
                <MainInformationForm
                    prescriber={prescriber}
                    afterSubmit={afterSubmit}
                />,
            );
        }
    };

    const { siret, siren, urlSocieteDotCom, tvaIntracommunautaire, regimeTva } = prescriber;

    const theme: Theme = useTheme();

    const lgBreakpoint = useMediaQuery(theme.breakpoints.up('lg'));

    const informations = [
        {
            label: 'SIRET',

            value: siret,
            additionalRender: () => siret && siret !== '' && <ExistingPrescribersInformation />,
        },
        {
            label: 'SIREN',
            value: siren,
        },
        {
            label: 'TVA Intracommunautaire',
            value: tvaIntracommunautaire,
        },
        {
            label: 'Régime TVA',
            value: regimeTva,
        },
    ];

    const avatarSize = 100;

    return (
        <BorderedBox
            boxTitle="Informations société"
            actionBarProps={{ editAction: handleEditMainInformation }}
            borderWidth={3}
        >
            <Box display="flex">
                <Box width={`${lgBreakpoint ? '66%' : '100%'}`}>
                    <CustomDivider />
                    {informations.map(({ label, value, additionalRender }) => (
                        <React.Fragment key={label}>
                            <Information
                                label={label}
                                value={value}
                                additionalRender={additionalRender}
                                sx={{ marginBottom: '10px' }}
                            />
                            <CustomDivider />
                        </React.Fragment>
                    ))}
                </Box>
                <Box
                    width="30%"
                    display={`${lgBreakpoint ? 'flex' : 'none'}`}
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    sx={{ marginLeft: '22px' }}
                >
                    <Avatar
                        sx={{
                            bgcolor: 'primary.main',
                            width: avatarSize,
                            height: avatarSize,
                            marginBottom: '15px',
                        }}
                    >
                        <ApartmentIcon fontSize="large" />
                    </Avatar>
                    {urlSocieteDotCom && (
                        <Box
                            display="flex"
                            marginTop="15px"
                        >
                            <Link
                                href={urlSocieteDotCom}
                                target="_blank"
                            >
                                <Button variant="outlined">Fiche société.com</Button>
                            </Link>
                        </Box>
                    )}
                </Box>
            </Box>
            {!lgBreakpoint && urlSocieteDotCom && (
                <Box
                    display="flex"
                    marginTop="15px"
                >
                    <Link
                        href={urlSocieteDotCom}
                        target="_blank"
                    >
                        <Button variant="outlined">Fiche société.com</Button>
                    </Link>
                </Box>
            )}
        </BorderedBox>
    );
};

export default CompanyInformationsCard;
