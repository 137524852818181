import * as React from 'react';
import { Card, CardContent, CardHeader, Typography, useTheme } from '@mui/material';
import { Box, SxProps } from '@mui/system';
import HomeIcon from '@mui/icons-material/Home';
import ActionsBar from '../../molecules/ActionsBar';
import { BorderedBox } from '../../molecules';

export type Address = {
    id?: number;
    adresse1: string;
    adresse2?: string;
    adresse3?: string;
    adresse4?: string;
    codePostal: string;
    ville: string;
    defaut?: boolean;
    facturation?: boolean;
};

export type AddressCardProps = {
    address: Address;
    onEdit?: () => void;
    onDelete?: () => void;
    sx?: SxProps;
    isDeletable?: boolean;
    isBordered?: boolean;
};

const AddressCard: React.FunctionComponent<AddressCardProps> = function (props: AddressCardProps) {
    const { address, onEdit, onDelete, isDeletable, sx, isBordered } = props;
    const { palette } = useTheme();

    const { adresse1, adresse2, adresse3, adresse4, codePostal, ville } = address || {};

    const getCardTitle = () => {
        const { defaut: isDefaultAddress, facturation: isBillingAddress } = address || {};

        if (isDefaultAddress && isBillingAddress) return 'Adresse Principale et de Facturation';
        if (isDefaultAddress && !isBillingAddress) return 'Adresse Principale';
        if (!isDefaultAddress && isBillingAddress) return 'Adresse de Facturation';
        return 'Adresse';
    };

    const content = (
        <Box
            gap="15px"
            display="flex"
            alignItems="start"
        >
            <Box
                width={60}
                height={60}
                bgcolor={palette.secondary.main}
                borderRadius={100}
                alignItems="center"
                display="flex"
                color={palette.secondary.contrastText}
            >
                <HomeIcon
                    fontSize="large"
                    sx={{
                        margin: 'auto',
                    }}
                />
            </Box>
            <Box>
                {adresse1 ? <Typography variant="body1">{adresse1}</Typography> : null}
                {adresse2 ? <Typography variant="body1">{adresse2} </Typography> : null}
                {adresse3 ? <Typography variant="body1">{adresse3} </Typography> : null}
                {adresse4 ? <Typography variant="body1">{adresse4} </Typography> : null}
                {codePostal && ville ? (
                    <Typography variant="body1">{`${codePostal} ${ville}`}</Typography>
                ) : null}
            </Box>
        </Box>
    );

    if (isBordered) {
        return (
            <BorderedBox
                boxTitle={getCardTitle()}
                actionBarProps={{
                    editAction: onEdit,
                    deleteAction: isDeletable ? onDelete : undefined,
                    localisationAction: () => null,
                }}
                sx={{ minHeight: 'fit-content', height: '95%', ...sx }}
                borderWidth={3}
            >
                {content}
            </BorderedBox>
        );
    }

    return (
        <Card
            role="article"
            aria-label="AddressCard"
            sx={{ minHeight: 'fit-content', height: '90%', ...sx }}
        >
            <CardHeader
                title={<Typography fontWeight="bold">{getCardTitle()}</Typography>}
                action={
                    <ActionsBar
                        editAction={onEdit}
                        deleteAction={isDeletable ? onDelete : undefined}
                        localisationAction={() => null}
                    />
                }
            />

            <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                {content}
            </CardContent>
        </Card>
    );
};
export default AddressCard;
