import { ConfigurationParameters as FormaliteConfigurationParameters } from '../../openApi/ApiFormalite';
import { createDossierFormaliteSlice } from './slices/dossierFormaliteSlice';
import { createFormaliteSlice } from './slices/formalite';
import { createCategoriesFormaliteSlice } from './slices/categoriesFormalite';
import { createLegalStatusSlice } from './slices/legalStatusSlice';
import { createFormaliteDocumentSlice } from './slices/documentSlice';

export { type FormaliteConfigurationParameters };

export function createDossierFormaliteReducer(configuration: FormaliteConfigurationParameters) {
    const { dossierFormaliteSlice, dossierFormaliteDataSourcesThunks } =
        createDossierFormaliteSlice(configuration);
    return { dossierFormaliteSlice, dossierFormaliteDataSourcesThunks };
}

export function createFormaliteReducer(configuration: FormaliteConfigurationParameters) {
    const { formaliteSlice, formaliteDataSourcesThunks } = createFormaliteSlice(configuration);
    return { formaliteSlice, formaliteDataSourcesThunks };
}

export function createCategoriesFormaliteReducer(configuration: FormaliteConfigurationParameters) {
    const { categoryFormaliteSlice, categoryFormaliteDataSourcesThunks } =
        createCategoriesFormaliteSlice(configuration);
    return { categoryFormaliteSlice, categoryFormaliteDataSourcesThunks };
}

export function createLegalStatusReducer(configuration: FormaliteConfigurationParameters) {
    const { legalStatusSlice, legalStatusDataSourcesThunks } =
        createLegalStatusSlice(configuration);
    return { legalStatusSlice, legalStatusDataSourcesThunks };
}

export function createFormaliteDocumentReducer(configuration: FormaliteConfigurationParameters) {
    const { formaliteDocumentSlice, formaliteDocumentDataSourcesThunks } =
        createFormaliteDocumentSlice(configuration);
    return { formaliteDocumentSlice, formaliteDocumentDataSourcesThunks };
}
