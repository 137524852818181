import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';

import { FactureAchat, Fournisseur } from '@europrocurement/l2d-domain';
import { FaOptionIcon, publisherIcon } from '@europrocurement/l2d-icons';
import { FlexyHeaderForm } from '@europrocurement/flexy-components';
import { FlexyForm, FormObject } from '@europrocurement/flexy-form';
import { RootStateType, getFournisseurAc } from '@b2d/redux/RootStore';
import { SubmitHandler } from 'react-hook-form';
import { FactureAchatLigneJsonldFactureAchatRead } from '@europrocurement/l2d-domain/openApi/ApiAchats';

import { DataSource } from '@europrocurement/l2d-redux-utils';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import useLoadingStatus from '@b2d/hooks/useLoading';
import useSectionDataManager from '@b2d/hooks/useSectionDataManager';
import { ModeProps } from '../../formElements/types';
import { FactureFormObject, ToggableSection } from '../../types';
import { publisherSectionStructure } from '../../formElements/fragments/publisherSection';
import ViewPublisherSection from '../../formElements/fragments/ViewPublisherSection';
import HeaderStructure from '../../../fragments/HeaderStructure';
import SkeletonLoader from '../../../SkeletonLoader';

export type UpdatePublisherSectionFormProps = {
    invoice: FactureAchat;
    lines: FactureAchatLigneJsonldFactureAchatRead[];
    publisher: Fournisseur;
} & ToggableSection;

const UpdatePublisherSectionForm: FunctionComponent<UpdatePublisherSectionFormProps> = function ({
    invoice,
    lines,
    publisher,
    switchLockValues,
}) {
    const { getSwitchLockValues, stateSwitchLockValues } = switchLockValues;

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const publisherMode = useMemo<ModeProps>(
        () => ({ type: 'update', lock: stateSwitchLockValues.publisher.value }),
        [stateSwitchLockValues.publisher.value],
    );

    const publishersDataSource: DataSource<Fournisseur> = useSelector(
        (s: RootStateType) => s.tiers.fournisseur.autocompleteFactures,
        _.isEqual,
    );

    const publisherStructure = useMemo(
        () =>
            publisherSectionStructure({
                mode: publisherMode,
                autocompleteProps: {
                    publisher: {
                        dataSource: publishersDataSource,
                        fetchData: getFournisseurAc,
                        defaultValue: publisher.siren || undefined,
                    },
                },
                hideHeader: true,
                onSelectOption: (
                    event,
                    newPublisher: Fournisseur,
                    reason,
                    details,
                    formContext,
                ) => {
                    formContext.setValue(
                        'raison_sociale_fournisseur',
                        newPublisher.libelle || 'Pas de raison sociale',
                    );

                    formContext.setValue('siren_fournisseur', newPublisher.siren);

                    if (newPublisher.typeRemise) {
                        let codeTypeRemise = newPublisher.typeRemise.code;

                        if (newPublisher.rsfInclus) {
                            codeTypeRemise = 'RSF Inclus';
                        }

                        formContext.setValue(
                            'type_remise_fournisseur',
                            `${codeTypeRemise} (${newPublisher.typeRemise.libelle})`,
                        );
                    } else {
                        formContext.setValue('type_remise_fournisseur', `Pas de remise`);
                    }
                },
            }),
        [publisher.siren, publisherMode, publishersDataSource],
    );

    if (!invoice.id) {
        throw new Error(
            "La facture n'as pas d'id, si vous voyez ce message, Sylvain vous autorise a demander a Kevin de faire du piano. ",
        );
    }

    if (lines.length === 0) {
        throw new Error("La facture n'as pas de ligne. Aucun lien avec un dossier");
    }

    const { publisher: publisherSection } = useSectionDataManager();
    const { updatePublisher } = publisherSection();

    const handleSubmit = useCallback<SubmitHandler<FormObject>>(
        async (
            values: Pick<
                FactureFormObject,
                | 'fournisseur'
                | 'siren_fournisseur'
                | 'raison_sociale_fournisseur'
                | 'type_remise_fournisseur'
            >,
        ) => {
            setIsLoading(true);

            updatePublisher({
                invoice,
                newPublisher: values,
            }).finally(() => {
                stateSwitchLockValues.publisher.set(true);

                setIsLoading(false);
            });
        },
        [invoice, stateSwitchLockValues.publisher, updatePublisher],
    );

    const formObject = useMemo<Partial<FactureFormObject>>(() => {
        if (publisher.id !== invoice.idEntiteFacturante)
            throw new Error(
                'The selected publisher is different than the publisher associate to the invoice.',
            );

        const formObjectData: Partial<FactureFormObject> = {};

        if (publisher.siren) {
            formObjectData.siren_fournisseur = publisher.siren;
        } else {
            formObjectData.siren_fournisseur = 'Pas de siren';
        }
        formObjectData.raison_sociale_fournisseur = publisher.libelle;
        if (publisher.typeRemise) {
            formObjectData.type_remise_fournisseur = `${publisher.typeRemise.code} (${publisher.typeRemise.libelle})`;
        } else {
            formObjectData.type_remise_fournisseur = `Pas de remise`;
        }

        return formObjectData;
    }, [
        invoice.idEntiteFacturante,
        publisher.id,
        publisher.libelle,
        publisher.siren,
        publisher.typeRemise,
    ]);

    const readyToDisplay = useMemo<boolean>(
        () => !!(!isLoading && invoice.id),
        [invoice.id, isLoading],
    );

    const { loading } = useLoadingStatus({
        checkReady: () => readyToDisplay,
    });

    return (
        <SkeletonLoader
            isLoading={loading}
            type="FormSection"
        >
            <>
                <FlexyHeaderForm
                    label={
                        <HeaderStructure
                            icon={
                                <FaOptionIcon
                                    {...publisherIcon.props}
                                    size="sm"
                                />
                            }
                            title={publisherIcon.displayName}
                        />
                    }
                    switches={[
                        ...(!invoice?.syncCegid
                            ? [getSwitchLockValues({ sectionToBlock: 'publisher' })]
                            : []),
                    ]}
                    outlined
                />
                {stateSwitchLockValues.publisher.value ? (
                    <ViewPublisherSection
                        siren={publisher.siren || 'Pas de siren'}
                        companyName={publisher.libelle || 'Pas de raison sociale'}
                        discountType={
                            publisher.typeRemise
                                ? `${publisher.typeRemise.code} (${publisher.typeRemise.libelle})`
                                : `Pas de remise`
                        }
                    />
                ) : (
                    <FlexyForm
                        onSubmit={handleSubmit}
                        formObject={formObject}
                        formStructure={publisherStructure}
                    />
                )}
            </>
        </SkeletonLoader>
    );
};

export default UpdatePublisherSectionForm;
