import * as React from 'react';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import { FlexyHeaderForm } from '@europrocurement/flexy-components';
import document from '../../models/document';
import additionalField from '../../models/additionalField';
import useStepForm from '../../components/form/MultiStepForm/useStepForm';
import { MultiStepContextProps } from '../../components/form/MultiStepForm/types';
import AutocompleteField from '../../components/form/AutocompleteField';
import DocumentsList from '../../components/DocumentsList/DocumentsList';
import { ReadOnlyDocuments } from '../../components/DocumentsList/types';

/** Form lats step : Relations (actions / additional fields / documents) */
const RelationStep: React.FC<MultiStepContextProps> = function (props) {
    const { multiStepForm } = props;
    const { setCurrentFormContext, formValues } = multiStepForm;

    /**  Zod validation schema */
    const configurationStepSchema = z.object({});

    // Form configuration
    const formContext = useForm({
        mode: 'onBlur',
        defaultValues: formValues,
        resolver: zodResolver(configurationStepSchema),
    });
    useStepForm(formContext, multiStepForm);
    // On mount
    React.useEffect(() => {
        setCurrentFormContext(formContext);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { control } = formContext;

    const additionalFieldList = formValues.additionalFields;
    const documentList = formValues.documents;
    const readOnlyDocuments = formValues.readOnlyDocuments as ReadOnlyDocuments;

    return (
        <form noValidate>
            <FlexyHeaderForm
                label="Champs complémentaires"
                outlined
            />
            <hr />
            <Controller
                name="additionalFields"
                control={control}
                render={({ field }) => (
                    <AutocompleteField
                        {...field}
                        formKey={field.name}
                        defaultList={additionalFieldList}
                        model={additionalField}
                        formContext={formContext}
                        label="Champ(s) complémentaire(s)"
                        inputLabel=""
                        placeholder="Sélectionnez un ou des champs(s) complementaires"
                    />
                )}
            />

            <FlexyHeaderForm
                label="Documents additionels"
                outlined
            />
            <Controller
                name="documents"
                control={control}
                render={({ field }) => (
                    <AutocompleteField
                        {...field}
                        formKey={field.name}
                        defaultList={documentList}
                        model={document}
                        formContext={formContext}
                        label="Documents"
                        inputLabel=""
                        placeholder="Sélectionnez un ou des document(s)"
                    />
                )}
            />

            <DocumentsList documents={readOnlyDocuments} />
        </form>
    );
};

export default RelationStep;
