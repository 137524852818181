import * as React from 'react';
import { Box, Container, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';
// import { B2D404Icon } from '@europrocurement/flexy-components/components';
import B2D404Icon from '../../../components/atoms/B2D404Icon/B2D404Icon';
import { PageContainer } from '../PageContainer';

/**
 *
 * [X] ErrorPage : ajouter title et description en parametre
 * [X] NotFound : ajouter message en parametre
 * [ ] DossierSignatureFiche :
 *      [X] ajouter un state isError404
 *      [X] a la place de navigateTo404 faire un setIsError404(true)
 *      [X] avant le return ligne 185 :
 *          si isError404 return un composant <NotFound>
 *
 * si ca marche pas :
 *      reprendre toute la box de ErrorPage dans un nouveau compo error
 *
 */

export type ErrorPageType = {
    statusCode: number;
    message: string;
    children?: React.ReactNode;
    title?: string;
    description?: string;
};

export const ErrorPage = function ({
    statusCode,
    message,
    children,
    title = 'Error',
    description = 'this is Error page',
}: ErrorPageType) {
    return (
        <PageContainer
            title={title}
            description={description}
        >
            <Box
                display="flex"
                flexDirection="column"
                height="100vh"
                textAlign="center"
                justifyContent="center"
                data-testid="test-id-ErrorPage"
            >
                <Container maxWidth="md">
                    <B2D404Icon />
                    <Typography
                        align="center"
                        variant="h1"
                        sx={{
                            pt: 2,
                        }}
                    >
                        {statusCode}
                    </Typography>
                    <Typography
                        align="center"
                        variant="h4"
                        sx={{
                            pt: 1,
                            pb: 3,
                        }}
                    >
                        {message}
                    </Typography>
                    <Button
                        color="primary"
                        variant="contained"
                        component={Link}
                        to="/"
                        disableElevation
                    >
                        Retour à l&apos;accueil
                    </Button>
                    {children}
                </Container>
            </Box>
        </PageContainer>
    );
};

export default ErrorPage;
