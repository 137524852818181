/**
 *
 * Notre composant affiche une timeline comme
 *
 * https://gitlab.europroc.net/devl2d/librairies/flexy/-/blob/master/package/main/src/views/timeline/CustomTimeline.js
 *
 * prends en parametre :
 *
 * une liste de LogType
 *
 * une objet de mapping d'icone    event => reactNode
 *
 * exemple : {
 *      "DOSSIER_CREATE" => <PlusIcon>,
 *      "SIGNATAIRE_SIGN" => <SignIcon>,
 * }
 *
 * une objet de mapping de couleur event => TypographyProps['color']
 *
 * exemple : {
 *      "DOSSIER_CREATE" => 'blueGrey.main',
 *      "SIGNATAIRE_SIGN" => 'success.main',
 * }
 *
 * position="alternate" https://mui.com/material-ui/react-timeline/
 *
 */
import React from 'react';
import { Typography, Box, TypographyProps } from '@mui/material';

import {
    TimelineItem,
    TimelineSeparator,
    TimelineConnector,
    TimelineContent,
    TimelineOppositeContent,
    TimelineDot,
} from '@mui/lab';

import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { FaOptionIcon } from '@europrocurement/l2d-icons';

const isoTolocalDate = (maDateIso: string) => new Date(Date.parse(maDateIso)).toLocaleDateString();

export type TimelineLogType = {
    date?: string;
    message?: string;
    event?: string;
};

export type TimelineItemProps = {
    log: TimelineLogType;
    eventToColor: (log: TimelineLogType) => TypographyProps['bgcolor'];
    eventToIcone: (log: TimelineLogType) => FontAwesomeIconProps['icon'];
    eventToTitle: (log: TimelineLogType) => string;
};

const LogTimelineItem: React.FC<TimelineItemProps> = function (props) {
    const { log, eventToColor, eventToIcone, eventToTitle } = props;
    return (
        <TimelineItem>
            <TimelineOppositeContent
                style={{ maxWidth: '100px', paddingLeft: '0px', paddingRight: '25px' }}
                sx={{ m: 'auto 0' }}
                align="left"
                variant="body2"
                color="text.secondary"
            >
                {log.date ? `${isoTolocalDate(log.date)}` : ''}
            </TimelineOppositeContent>
            <TimelineSeparator>
                <TimelineDot
                    color="info"
                    sx={{
                        width: '48px',
                        height: '48px',
                        textAlign: 'center',
                        p: 1,
                        bgcolor: eventToColor(log),
                    }}
                >
                    <FaOptionIcon
                        iconProps={{
                            style: {
                                width: '28px',
                                height: '28px',
                                margin: 'auto',
                            },
                        }}
                        icon={eventToIcone(log)}
                        color="white"
                    />
                </TimelineDot>
                <TimelineConnector color="secondary" />
            </TimelineSeparator>
            <TimelineContent>
                <Box sx={{ bgcolor: 'primary.light', p: 2, borderRadius: 3 }}>
                    <Typography
                        variant="h4"
                        component="span"
                    >
                        {eventToTitle(log)}
                    </Typography>
                    <Typography>{log.message}</Typography>
                </Box>
            </TimelineContent>
        </TimelineItem>
    );
};

export default LogTimelineItem;
