/* eslint-disable @typescript-eslint/no-explicit-any */

import type { LigneProduit } from '@b2d/pages/Achats/components/forms/types';

/**
 * Compares two product objects to determine if any relevant fields have changed.
 *
 * This function checks if there are any changes between the original and new product objects.
 * It treats numbers and strings as equal if their values are equivalent. It also considers
 * `undefined` values as unchanged fields, allowing for partial updates without triggering
 * unnecessary updates for untouched fields.
 *
 * @param {LigneProduit} originalProduct - The original product object before changes.
 * @param {LigneProduit} newProduct - The new product object with potential changes.
 * @returns {boolean} - Returns `true` if any relevant field has changed, otherwise `false`.
 */
export default (originalProduct: LigneProduit, newProduct: LigneProduit) => {
    const toString = (value: any) =>
        value !== undefined && value !== null ? value.toString() : '';

    const txtvaEqual = (original: any, updated: any) => {
        if (!original && !updated) return true;
        if (original && !updated) return original.value === 20;
        if (!original && updated) return updated.value === 20;
        return toString(original.value) === toString(updated.value);
    };

    const fieldsEqual = (originalValue: any, newValue: any) => {
        if (originalValue === undefined || newValue === undefined) return true;
        return toString(originalValue) === toString(newValue);
    };

    return (
        !fieldsEqual(originalProduct.rubriqueFacturation?.id, newProduct.rubriqueFacturation?.id) ||
        !fieldsEqual(originalProduct.ht, newProduct.ht) ||
        !txtvaEqual(originalProduct.txtva, newProduct.txtva)
    );
};
