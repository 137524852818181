import React, { ReactNode } from 'react';
import { Formalite } from '@europrocurement/l2d-domain/reducers/formalites/slices/formalite';
import { TableCell as MaterialTableCel, TableRow as MaterialTableRow } from '@mui/material';
import { useTheme } from '@mui/system';
import TableCel from './TableCel';
import DisableTableCel from './DisableTableCel';

export type TableRowProps = {
    formality: Formalite;
    formalities: Formalite[];
    categoryTableCel?: ReactNode;
    onChange: (formality: Formalite, incompatibility: Formalite) => void;
};

const TableRow: React.FunctionComponent<TableRowProps> = function (props) {
    const { formality, formalities, categoryTableCel, onChange } = props;
    const theme = useTheme();
    const borderColor = theme.palette.grey[300];
    const headingCelStyle = {
        width: '250px',
        height: '80px',
        maxWidth: '25%',
        borderLeft: `solid 1px ${borderColor}`,
        borderBottom: `solid 1px ${borderColor}`,
        backgroundColor: theme.palette.background.default,
    };

    const cels = formalities.map((otherFormality: Formalite) => {
        if (otherFormality.id === formality.id) {
            return <DisableTableCel key={`table-cel-${formality.id}-${otherFormality.id}`} />;
        }

        return (
            <TableCel
                key={`table-cel-${formality.id}-${otherFormality.id}`}
                formality={formality}
                incompatibility={otherFormality}
                incompatible={
                    formality.incompatibilities?.indexOf(otherFormality.id as unknown as string) ===
                    -1
                }
                onChange={onChange}
            />
        );
    });

    return (
        <MaterialTableRow>
            {categoryTableCel}
            <MaterialTableCel
                sx={headingCelStyle}
                key={`table-cel-${formality.id}-0`}
            >
                {formality.libelle}
            </MaterialTableCel>
            {cels}
        </MaterialTableRow>
    );
};

export default TableRow;
