import React from 'react';
import { CardActions, Tooltip, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';

type ImageActionsProps = {
    isActive: boolean;
    base64StringLogo: string;
    triggerFileInput: () => void;
    removeLogo: () => void;
};

const ImageActions = function (props: ImageActionsProps) {
    const { isActive, base64StringLogo, triggerFileInput, removeLogo } = props;
    return (
        <CardActions sx={{ marginTop: '20px' }}>
            {isActive && base64StringLogo ? (
                <>
                    <Tooltip title="Modifier logo">
                        <Button
                            variant="contained"
                            onClick={triggerFileInput}
                            aria-label="edit logo"
                        >
                            <EditIcon />
                        </Button>
                    </Tooltip>

                    <Tooltip title="Supprimer logo">
                        <Button
                            variant="contained"
                            onClick={removeLogo}
                            aria-label="delete logo"
                        >
                            <DeleteIcon />
                        </Button>
                    </Tooltip>
                </>
            ) : (
                <Tooltip title="Ajouter un logo">
                    <Button
                        variant="contained"
                        onClick={triggerFileInput}
                        aria-label="add logo"
                        disabled={isActive === false}
                    >
                        <AddIcon />
                    </Button>
                </Tooltip>
            )}
        </CardActions>
    );
};

export default ImageActions;
