import { ApiCollectionResponse } from '@europrocurement/l2d-redux-utils';
import * as React from 'react';

export interface UseFetchCollection<T> {
    result: T;
    fetch: () => Promise<void>;
    hasError: boolean;
    isLoaded: boolean;
    isFetching: boolean;
}

type FetchState = {
    hasError: boolean;
    isLoaded: boolean;
    isFetching: boolean;
};

function useFetchCollection<T>(apiPromise: () => Promise<unknown>): UseFetchCollection<T> {
    const fetchInitialState = {
        hasError: false,
        isLoaded: false,
        isFetching: false,
    };

    const [fetchState, setFetchState] = React.useState<FetchState>(fetchInitialState);
    const [result, setResult] = React.useState<unknown>([]);
    const fetch = async () => {
        setFetchState({
            ...fetchState,
            isFetching: true,
        });
        await apiPromise()
            .then((promiseResult: { data: ApiCollectionResponse<T> }) => {
                const data = promiseResult.data['hydra:member'] ?? promiseResult.data;
                setResult(data);
            })
            .catch((error) => {
                setFetchState({
                    ...fetchState,
                    hasError: true,
                });
                throw new Error('Encountered error while fetching data :', error);
            });
        setFetchState({
            ...fetchState,
            isFetching: false,
            isLoaded: true,
        });
    };

    return {
        ...fetchState,
        result: result as T,
        fetch,
    };
}
export default useFetchCollection;
