import {
    SliceFactory,
    FetchCollectionData,
    FetchItemDataType,
    ApiCollectionResponse,
} from '@europrocurement/l2d-redux-utils';
import {
    ConfigurationParameters as OffreConfigurationParameters,
    Configuration,
    TypeTvaApi,
    TypeTvaTvaRead,
} from '../../../openApi/ApiOffre';
import { OFFRE_REDUCER_NAME } from '../constants';

export type VatApiObject = TypeTvaTvaRead & Record<string, unknown>;

export type Vats = VatApiObject;

export const VATS_SLICE_NAME = 'vats';

const mapperVats = function (vats: VatApiObject) {
    return {
        ...vats,
    } as Vats;
};

export function createVatsSlice(configuration: OffreConfigurationParameters) {
    const conf = new Configuration(configuration);
    const api = new TypeTvaApi(conf);

    const fetchVatsCollection: FetchCollectionData<VatApiObject> = ({ xIdSociete }) =>
        api
            .apiTvaGetCollection({ xIdSociete })
            .then((res) => res.data)
            .catch((error) => {
                console.error(error);
            }) as Promise<ApiCollectionResponse<VatApiObject>>;

    const fetchVatItem: FetchItemDataType<VatApiObject> = ({
        idItem,
        // pagination,
        xIdSociete,
    }) =>
        api
            .apiTvaIdGet({
                id: String(idItem),
                xIdSociete,
            })
            .then((res) => res.data) as Promise<VatApiObject>;

    const { slice: vatsSlice, dataSourcesThunks: vatsDataSourcesThunks } = SliceFactory.createSlice<
        VatApiObject,
        Vats
    >(VATS_SLICE_NAME, OFFRE_REDUCER_NAME, [], fetchVatsCollection, fetchVatItem, mapperVats);

    return { vatsSlice, vatsDataSourcesThunks };
}
