import React, { useCallback } from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { useModal } from '@europrocurement/flexy-components';
import { updateTotauxUnblockedModalMessages } from '../constants/wording/modals';
import ConfirmationModalContent from '../components/modals/ConfirmationModalContent';
import { updateFormTotaux } from '../components/forms/functions/calculsProduits';
import { UseSwitchLockValuesProps } from './useSwitchLockValues';

export type UseUpdateTotalsProps = {
    formContext: UseFormReturn<FieldValues, unknown>;
    stateSwitchLockValues: UseSwitchLockValuesProps['stateSwitchLockValues'];
};

export type UpdateTotalsProps = {
    reasonToTriggerConfirmationModal?: boolean;
};

/**
 * useUpdateTotals Hook
 *
 * This custom hook is used to update the totals in a form.
 * It integrates a modal confirmation mechanism that triggers based on a condition provided by the consumer.
 *
 * @param {UseFormReturn<FieldValues, unknown>} formContext - The react-hook-form context used for form operations.
 *
 * @returns {Object} An object containing the updateTotals function.
 */
const useUpdateTotals = ({ formContext, stateSwitchLockValues }: UseUpdateTotalsProps) => {
    const { modalActions } = useModal();

    /**
     * Updates the totals.
     *
     * If the provided condition is false, it shows a confirmation modal.
     * Otherwise, it directly updates the form totals.
     *
     * @param {boolean} reasonToTriggerConfirmationModal - A flag indicating whether to show the confirmation modal.
     */
    const updateTotals = useCallback(
        ({ reasonToTriggerConfirmationModal = true }: UpdateTotalsProps) => {
            if (reasonToTriggerConfirmationModal) {
                modalActions.call(
                    <ConfirmationModalContent
                        messages={updateTotauxUnblockedModalMessages}
                        actionOnCancellation={() => {
                            stateSwitchLockValues.totals.set(true);
                            updateFormTotaux({
                                formContext,
                                reasonToTriggerUpdate: stateSwitchLockValues.totals.value,
                            });
                        }}
                        actionOnValidation={() => {
                            stateSwitchLockValues.totals.set(false);
                        }}
                    />,
                );
                return;
            }

            updateFormTotaux({
                formContext,
                reasonToTriggerUpdate: stateSwitchLockValues.totals.value,
            });
        },
        [formContext, stateSwitchLockValues, modalActions],
    );

    return {
        updateTotals,
    };
};

export default useUpdateTotals;
