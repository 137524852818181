import {
    notAllocatedIcon,
    refusedIcon,
    toBeCheckedIcon,
    validatedIcon,
} from '@europrocurement/l2d-icons';

export const documentStatusOptions = [
    {
        value: 9,
        label: 'Validé',
        icon: validatedIcon,
    },
    {
        value: 7,
        label: 'A vérifier',
        icon: toBeCheckedIcon,
    },

    {
        value: 10,
        label: 'Refusé',
        icon: refusedIcon,
    },
    {
        value: 43,
        label: 'Non attribué',
        icon: notAllocatedIcon,
    },
] as const;

/** Options Libelles : Demat'facile */

export const DFLabelOptions = [
    { value: 'statuts_constitutifs', label: 'Statuts constitutifs' },
    {
        value: 'declaration_affectation_patrimoine',
        label: "Declaration d'affectation du patrimoine",
    },
    { value: 'acte_modification', label: 'Acte de modification' },
    { value: 'liste_sieges_sociaux_anterieurs', label: 'Liste des sièges sociaux antérieurs' },
    {
        value: 'nomination_dirigeant',
        label: "Nomination d'un dirigeant, si celui ci n'est pas statutaire",
    },
    {
        value: 'autre_document_affectation_patrimoine',
        label: "Autre document d'affectation du patrimoine",
    },
    { value: 'comptes_cloture', label: 'Comptes de clôture' },
    { value: 'autre_acte_nomination_gestion', label: 'Autre acte nomination gestion' },
    { value: 'contrat_constitutif_groupement', label: 'Contrat constitutif du groupement' },
    { value: 'copie_statuts', label: 'Copie des statuts' },
    {
        value: 'decision_assemblee_comptes_cloture',
        label: 'Decision assemblée des comptes de clôture',
    },
    {
        value: 'attestation_depot_fonds_liste_souscripteurs',
        label: 'Attestation de dépot des fonds et liste des souscripteurs',
    },
    { value: 'contrat_appui', label: "Contrat d'appui" },
    { value: 'acte_complementaire', label: 'Le cas échéant, acte complémentaire' },
    {
        value: 'attestation_depot_fonds_depositaire',
        label: 'Attestation de dépôt des fonds, si nom et adresse du dépositaire des fonds pas précisés dans statuts',
    },
    {
        value: 'attestation_delivrance_information_conjoint',
        label: "Attestation de délivrance de l'information donnée au conjoint commun en biens",
    },
    {
        value: 'attestation_parution_annonce_legale',
        label: "Attestation de parution de l'annonce légale",
    },
    {
        value: 'copie_recepisse_depot',
        label: "Copie du récépissé de dépôt d'acte(s), en cas de dépôt au greffe avant la demande de modification",
    },
    {
        value: 'attestation_non_condamnation',
        label: "Attestation sur l'honneur relative à l'absence de condamnation, sanction civile ou administrative",
    },
    {
        value: 'copie_piece_identite',
        label: "Copie de la carte nationale d'identité, du passeport ou du titre de séjour en cours de validité",
    },
    {
        value: 'extrait_kbis',
        label: "Extrait de l'immatriculation au RCS datant de moins de trois mois ou titre justifiant l'existence",
    },
    {
        value: 'copie_decision_representant_permanent',
        label: 'Copie de la décision conférant la qualité de représentant permanent',
    },
    {
        value: 'lettre_acceptation_mission',
        label: "Lettre d'acceptation de la mission ou attestation d'inscription sur la liste",
    },
    { value: 'justificatif_mariage', label: "Justificatif d'identité faisant état du mariage" },
    {
        value: 'copie_autorisation_ou_diplome',
        label: "Copie de l'autorisation provisoire ou définitive, du diplôme ou du titre",
    },
    {
        value: 'justificatif_jouissance_locaux',
        label: "Justificatif de la jouissance des locaux ou justificatif de l'adresse de l'entreprise",
    },
    {
        value: 'copie_contrat_domiciliation',
        label: "Copie du contrat de domiciliation indiquant les références de l'agrément préfectoral",
    },
    {
        value: 'extrait_rcs_domiciliataire',
        label: 'Extrait RCS du domiciliataire ou extrait du répertoire des métiers, le cas échéant',
    },
    { value: 'copie_acte_achat', label: "Copie de l'acte d'achat" },
    {
        value: 'copie_acte_partage_fonds',
        label: "Copie de l'acte constatant l'attribution du fonds par voie de partage",
    },
    {
        value: 'copie_acte_licitation_fonds',
        label: "Copie de l'acte constatant l'attribution du fonds par voie de licitation",
    },
    { value: 'copie_acte_apport', label: "Copie de l'acte d'apport" },
    { value: 'copie_acte_donation', label: "Copie de l'acte de donation" },
    { value: 'copie_contrat_location_gerance', label: 'Copie du contrat de location-gérance' },
    { value: 'copie_contrat_gerance_mandat', label: 'Copie du contrat de gérance-mandat' },
    {
        value: 'copie_jugement_plan_cession',
        label: 'Copie du jugement ordonnant un plan de cession',
    },
    {
        value: 'extrait_rcs_societes_participant_fusion',
        label: "Extrait du RCS des sociétés qui ont participé à l'opération de fusion ou scission",
    },
    {
        value: 'attestation_notariee_insaisissabilite',
        label: "Attestation notariée relative à l'insaisissabilité",
    },
    {
        value: 'piece_modifications_situation',
        label: 'Pièce(s) établissant les modifications de situation',
    },
    {
        value: 'extrait_rcs',
        label: "Extrait de l'immatriculation principale datant de moins de trois mois",
    },
    {
        value: 'extrait_rcs_precedent_etablissement',
        label: "Extrait d'immatriculation du précédent établissement datant de moins de trois mois",
    },
    { value: 'procuration_client', label: 'Procuration de votre client' },
    { value: 'justificatif_identite_mandataire', label: "Justificatif d'identité du mandataire" },
    { value: 'procuration_mgjp', label: 'Procuration MGJP' },
    {
        value: 'autre_piece_justificative_complementaire',
        label: 'Autre piece justificative complémentaire',
    },
    {
        value: 'attestation_parution_annonce_legale_ancien_siege',
        label: "Attestation de parution du transfert de siège social dans un JAL du dpt de l'ancien siège",
    },
    {
        value: 'acte_sous_seing_prive',
        label: 'Acte sous seing privé (modification relative aux parts sociales)',
    },
    { value: 'acte_notarie', label: 'Acte notarié (modification relative aux parts sociales)' },
    { value: 'attestation_apport_nature', label: 'Attestation (apport en nature)' },
    { value: 'declaration_conformite', label: 'Déclaration de conformité' },
    { value: 'decision_apport_nature', label: 'Décision(s) (apport en nature)' },
    { value: 'ordonnance', label: 'Ordonnance' },
    { value: 'projet_apport_partiel_actif', label: "Projet d'apport partiel d'actif" },
    { value: 'projet_traite_fusion', label: 'Projet de traité de fusion' },
    { value: 'avenant_projet_traite_fusion', label: 'Avenant au projet de traité de fusion' },
    { value: 'projet_traite_scission', label: 'Projet de traité de scission' },
    { value: 'avenant_projet_traite_scission', label: 'Avenant au projet de traité de scission' },
    { value: 'proces_verbal_assemblee_generale', label: "Procès-verbal d'assemblée générale" },
    { value: 'rapport_commissaire_apports', label: 'Rapport du commissaire aux apports' },
    { value: 'rapport_commissaire_comptes', label: 'Rapport du commissaire aux comptes' },
    {
        value: 'rapport_commissaire_transformation',
        label: 'Rapport du commissaire à la transformation',
    },
    { value: 'rapport_liquidateur', label: 'Rapport du liquidateur' },
    { value: 'statuts_mis_a_jour', label: 'Statuts mis à jour' },
    {
        value: 'bilan_compte_resultat_annexes',
        label: 'Bilan (actif, passif), compte de résultat et annexes',
    },
    { value: 'bilan_compte_resultat', label: 'Bilan (actif, passif) et compte de résultat' },
    { value: 'rapport_gestion', label: 'Rapport de gestion' },
    { value: 'rapport_conseil_surveillance', label: 'Rapport du Conseil de Surveillance' },
    { value: 'rapport_commissaire_comptes', label: 'Rapport des Commissaires aux Comptes' },
    { value: 'inventaire', label: 'Inventaire' },
    {
        value: 'pv_assemblee',
        label: "PV d'Assemblée (proposition et résolution d'affectation votée)",
    },
    {
        value: 'rapport_president_conseil_administration',
        label: "Rapport du président du conseil d'administration prévu à l'article L.225-37 du code de commerce",
    },
    {
        value: 'rapport_president_conseil_surveillance',
        label: "Rapport du président du conseil de surveillance prévu à l'article L.225-68 du code de commerce",
    },
    {
        value: 'documents_comptables_etranger',
        label: "Documents comptables établis, contrôlés et publiés dans l'Etat où est situé le siège",
    },
    { value: 'rapport_gestion_groupe', label: 'Rapport de gestion du groupe' },
    {
        value: 'rapport_commissaire_comptes_consolides',
        label: 'Rapport des commissaires aux comptes sur les comptes consolidés',
    },
    {
        value: 'attestation_conformite_documents_comptables',
        label: 'Attestation de conformité des documents comptables',
    },
    {
        value: 'declaration_confidentialite_comptes',
        label: 'Déclaration de confidentialité des comptes annuels',
    },
    { value: 'bilan_et_annexes', label: 'Bilan (actif, passif) et annexes' },
    { value: 'bilan', label: 'Bilan (actif, passif)' },
    { value: 'compte_resultat', label: 'Compte de résultat' },
    {
        value: 'declaration_conformite_compte_resultat',
        label: 'Déclaration de confidentialité du compte de résultat',
    },
    { value: 'declaration_publication_simplifiee', label: 'Déclaration de publication simplifiée' },
    {
        value: 'bilan_et_annexes_presentation_simplifiee',
        label: 'Bilan (actif, passif) et annexe en présentation simplifiée',
    },
    {
        value: 'bilan_et_annexes_presentation_normale',
        label: 'Bilan (actif, passif) et annexe en présentation normale',
    },
    {
        value: 'petite_entreprise_declaration_documents_publics',
        label: 'Petite entreprise ayant un commissaire aux comptes - Déclaration accompagnant les doc rendus publics',
    },
    {
        value: 'moyenne_entreprise_declaration_documents_publics',
        label: 'Moyenne entreprise - Déclaration accompagnant les documents rendus publics',
    },
    { value: 'autre_piece_greffe', label: "Autre pièce jointe en provenance d'un greffe" },
    { value: 'avis_sirene_greffe', label: 'Avis Sirène' },
    { value: 'certificat_depot_greffe', label: 'Certificat de dépôt' },
    { value: 'facture_greffe', label: 'Facture émise par un greffe' },
    {
        value: 'invalidation_dossier_greffe',
        label: "Invalidation d'un dossier émise par un greffe",
    },
    { value: 'extrait_kbis_greffe', label: 'Extrait KBIS émis par un greffe' },
    {
        value: 'liste_pieces_manquantes_greffe',
        label: 'Liste des pièces manquantes émise par un greffe',
    },
    {
        value: 'copie_integrale_rbe_greffe',
        label: 'Copie intégrale des informations déclarées au Registre des Bénéficiaires Effectifs',
    },
    {
        value: 'extrait_rbe_greffe',
        label: 'Extrait des informations publiques déclarées au Registre des Bénéficiaires Effectifs',
    },
    { value: 'lettre_rejet_greffe', label: 'Lettre de rejet' },
    {
        value: 'relance_pieces_manquantes_greffe',
        label: 'Relance de pièce manquante émise par un greffe',
    },
    { value: 'cerfa_partie_1', label: 'Formalité RCS : Feuillet 1' },
    { value: 'cerfa_partie_2', label: 'Formalité RCS : Feuillet 2' },
    { value: 'formulaire_jqpa', label: 'Formulaire JQPA' },
    { value: 'formulaire_be', label: 'Formulaire BE' },
    { value: 'accord_greffe_entreprise', label: 'Accord Greffe-Entreprise' },
    { value: 'depot_comptes_annuels', label: 'Dépôt des comptes annuels' },
    { value: 'depot_comptes_complet', label: 'Dépôt des comptes annuels - Version complète' },
    { value: 'depot_comptes_simplifie', label: 'Dépôt des comptes annuels - Version simplifiée' },
    {
        value: 'depot_comptes_sans_compte_resultat',
        label: 'Dépôt des comptes annuels (sans compte de résultat)',
    },
] as const;
