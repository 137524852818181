import * as React from 'react';
import { FlexyAccordeon } from '@europrocurement/flexy-components';
import { Typography } from '@mui/material';

const LegalAnnouncments: React.FunctionComponent = function () {
    return (
        <FlexyAccordeon
            variante="section"
            accordionSummary={<Typography variant="h5">Annonces légales</Typography>}
            accordionDetails={<Typography>Aucune informations à afficher.</Typography>}
        />
    );
};

export default LegalAnnouncments;
