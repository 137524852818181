import React, { ReactNode } from 'react';

import { useForm } from 'react-hook-form';
import { Button, SxProps, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';

import BorderedBox from '../BorderedBox/BorderedBox';

export type NoteProps = {
    noteRawContent: string | undefined;
    title?: ReactNode;
    sx?: SxProps;
    onEdit?: (noteContent: string) => void;
    onRemove?: () => void;
};

const Note: React.FunctionComponent<NoteProps> = function (props) {
    const { title, noteRawContent, sx, onEdit, onRemove } = props;

    const formContext = useForm({
        mode: 'onTouched',
        defaultValues: { note: noteRawContent },
    });

    const noteField = formContext.register('note');

    const [editionMode, setEditionMode] = React.useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

    const toggleEditionMode = () => {
        setEditionMode(!editionMode);
    };

    const updateNote = () => {
        if (onEdit) {
            setIsSubmitting(true);
            const noteContent = formContext.getValues('note');
            onEdit(noteContent ?? '');
            setIsSubmitting(false);
            setEditionMode(false);
        }
    };

    const removeNote = () => {
        if (onRemove) {
            onRemove();
        }
    };

    return (
        <BorderedBox
            boxTitle={title}
            actionBarProps={
                onEdit || onRemove
                    ? {
                          editAction: toggleEditionMode,
                          deleteAction: removeNote,
                      }
                    : undefined
            }
            borderWidth={3}
            sx={sx}
        >
            <Typography
                component="div"
                variant="body1"
                style={{ whiteSpace: 'break-spaces' }}
                data-testid="note"
            >
                {editionMode ? (
                    <>
                        <TextField
                            id="note_prescripteur_textarea"
                            sx={{ marginTop: 2 }}
                            variant="outlined"
                            fullWidth
                            multiline
                            minRows={5}
                            placeholder="Note"
                            {...noteField}
                        />

                        <Box
                            display="flex"
                            justifyContent="flex-end"
                            alignItems="flex-end"
                        >
                            <Button
                                type="submit"
                                onClick={updateNote}
                                disabled={isSubmitting}
                                variant="contained"
                                style={{ marginTop: '2rem', marginBottom: '16px' }}
                            >
                                Mettre à jour la note
                            </Button>
                        </Box>
                    </>
                ) : (
                    noteRawContent
                )}
            </Typography>
        </BorderedBox>
    );
};

export default Note;
