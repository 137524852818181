import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, SxProps, Tooltip } from '@mui/material';
import * as React from 'react';

type TooltipedButtonProps = {
    tooltip: string | null | (React.JSX.Element | null)[];
    icon: IconDefinition;
    onClick: (event?: React.MouseEvent<HTMLButtonElement>) => void;
    size?: number;
    sx?: SxProps;
};

const TooltipedButton: React.FC<TooltipedButtonProps> = function ({
    size = 40,
    tooltip,
    onClick,
    icon,
    sx: sxProps,
}) {
    const sizeInPx = (value: number): string => `${value}px`;
    const sizeRules = {
        maxWidth: sizeInPx(size),
        minWidth: sizeInPx(size),
        maxHeight: sizeInPx(size),
        minHeight: sizeInPx(size),
    };

    const marginRules = {
        marginY: '15px',
        marginLeft: '15px',
    };

    return tooltip ? (
        <Tooltip title={tooltip}>
            <Button
                variant="outlined"
                sx={{
                    ...sizeRules,
                    ...marginRules,
                    ...sxProps,
                }}
                type="button"
                onClick={onClick}
            >
                <FontAwesomeIcon icon={icon} />
            </Button>
        </Tooltip>
    ) : (
        <Button
            variant="outlined"
            sx={{
                ...sizeRules,
                ...marginRules,
            }}
            type="button"
            onClick={onClick}
        >
            <FontAwesomeIcon icon={icon} />
        </Button>
    );
};

export default TooltipedButton;
