import { NamedIconsType } from '@europrocurement/l2d-icons/types';
import {
    faBan,
    faCheck,
    faFile,
    faMagnifyingGlass,
    faQuestion,
    faSignature,
    faXmark,
} from '@fortawesome/pro-solid-svg-icons';

export const validatedIcon: NamedIconsType = {
    props: { icon: faCheck },
    name: 'validated',
    displayName: 'Validé',
};

export const refusedIcon: NamedIconsType = {
    props: { icon: faXmark },
    name: 'refused',
    displayName: 'Refusé',
};

export const abortedIcon: NamedIconsType = {
    props: { icon: faBan },
    name: 'aborted',
    displayName: 'Annulé / Abandonné',
};

export const notAllocatedIcon: NamedIconsType = {
    props: { icon: faQuestion },
    name: 'notAllocated',
    displayName: 'Non alloué',
};

export const toBeCheckedIcon: NamedIconsType = {
    props: { icon: faMagnifyingGlass },
    name: 'toBeChecked',
    displayName: 'À véridier',
};

export const emptyDocument: NamedIconsType = {
    props: { icon: faFile },
    name: 'emptyDocument',
    displayName: 'Document vide',
};

export const signableDocument: NamedIconsType = {
    props: { icon: faSignature },
    name: 'signableDocument',
    displayName: 'Document signable',
};

export const statusIcons: NamedIconsType[] = [
    validatedIcon,
    refusedIcon,
    abortedIcon,
    notAllocatedIcon,
    toBeCheckedIcon,
];
