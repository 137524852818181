import { useCallback, useEffect, useMemo } from 'react';

import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { UseKeycloakCheckRole } from '@europrocurement/l2d-keycloak';
import { Commande, FactureVente, Fournisseur } from '@europrocurement/l2d-domain';
import { ACTIONS, FactureFormReducerType } from '@b2d/redux/FactureFormReducer';
import { AppDispatch, RootStateType } from '@b2d/redux/RootStore';

const useDomain = () => {
    const roleChecker = UseKeycloakCheckRole();
    const isInterne = roleChecker('realm:interne');

    const dispatch = useDispatch<AppDispatch>();

    const formState: FactureFormReducerType | undefined = useSelector(
        (s: RootStateType) => s.factureForm,
        _.isEqual,
    );
    const commandSelected: Commande | undefined = useSelector(
        (s: RootStateType) => s.dossiers.commande.main.selected,
        _.isEqual,
    );
    const billSelected: FactureVente | undefined = useSelector(
        (s: RootStateType) => s.dossiers.facturevente.main.selected,
        _.isEqual,
    );
    const publisherSelected: Fournisseur | undefined = useSelector(
        (s: RootStateType) => s.tiers.fournisseur.main.selected,
        _.isEqual,
    );

    const domainSelected = formState?.defDomain;
    const domainForcedSelected = formState?.defDomainForced;

    /**
     * List of domains settable in Portail Achats.
     */
    const authorizedDomains = useMemo(() => [0, 1, 2, 3, 6, 8], []);

    type isFalsyReturnProps<T> = {
        isFalsy: boolean;
        value: T | undefined;
    };

    /**
     * Treat 0 as truthy, return false for it.
     * For all other cases, use the standard falsy check.
     *
     * @param value - Any value to test.
     * @returns { isFalsy: boolean, value: T | undefined }
     */
    const isFalsy = useCallback(<T>(value: T): isFalsyReturnProps<T> => {
        if (value === 0) return { isFalsy: false, value };
        return { isFalsy: !value, value: value || undefined };
    }, []);

    /**
     * Extract domain id from selected item if the domain is in the domains allowed list.
     *
     * @param selectedItem - The item to check (publisher, bill, etc.).
     * @param domainKey - The key to retrieve the domain (ddmId, idDomaine, etc.).
     * @returns The authorized domain or undefined.
     */
    const getAuthorizedDomain = useCallback(
        <T>(selectedItem: T | undefined, domainKey: keyof T): number | undefined => {
            if (
                !selectedItem ||
                !(selectedItem[domainKey] as unknown as number) ||
                !authorizedDomains.includes(selectedItem[domainKey] as unknown as number)
            ) {
                return undefined;
            }

            return selectedItem[domainKey] as unknown as number;
        },
        [authorizedDomains],
    );

    const thereIsPublisherWithAuthorizedDomain = useMemo(
        (): number | undefined => getAuthorizedDomain(publisherSelected, 'ddmId'),
        [publisherSelected, getAuthorizedDomain],
    );

    const thereIsCommandWithAuthorizedDomain = useMemo(
        (): number | undefined => getAuthorizedDomain(commandSelected, 'idDomaine'),
        [commandSelected, getAuthorizedDomain],
    );

    const thereIsBillWithAuthorizedDomain = useMemo(
        (): number | undefined => getAuthorizedDomain(billSelected, 'idDdm'),
        [billSelected, getAuthorizedDomain],
    );

    // Checks if a domain is selected (including 0 as a valid selection)
    const thereIsDomainSelected = useMemo(
        () => !!(domainSelected !== undefined && domainSelected !== null),
        [domainSelected],
    );

    // Checks if a forced domain is selected and is greater than 0 (otherwise the domain would be forced every time)
    const thereIsDomainForced = useMemo(
        () =>
            !!(
                domainForcedSelected !== undefined &&
                domainForcedSelected !== null &&
                domainForcedSelected > 0
            ),
        [domainForcedSelected],
    );

    /**
     * Get the highest priority domain according to the rules:
     * Forced Domain > Command Domain > Bill Domain > Publisher Domain.
     *
     * @returns The highest priority domain or undefined.
     */
    const getHighestPriorityDomain = useCallback((): number | undefined => {
        if (thereIsDomainForced) {
            return domainForcedSelected;
        }

        if (!isFalsy(thereIsCommandWithAuthorizedDomain).isFalsy) {
            return thereIsCommandWithAuthorizedDomain;
        }

        if (!isFalsy(thereIsBillWithAuthorizedDomain).isFalsy) {
            return thereIsBillWithAuthorizedDomain;
        }

        if (!isFalsy(thereIsPublisherWithAuthorizedDomain).isFalsy) {
            return thereIsPublisherWithAuthorizedDomain;
        }

        return undefined;
    }, [
        domainForcedSelected,
        isFalsy,
        thereIsBillWithAuthorizedDomain,
        thereIsCommandWithAuthorizedDomain,
        thereIsDomainForced,
        thereIsPublisherWithAuthorizedDomain,
    ]);

    const highestPriorityDomain = getHighestPriorityDomain();

    const shouldSkipProcess = useMemo(
        () => thereIsDomainSelected && domainSelected === highestPriorityDomain,
        [thereIsDomainSelected, domainSelected, highestPriorityDomain],
    );

    /**
     * Set the selected domain in the state.
     *
     * @param domain - The domain to set.
     */
    const setDomain = useCallback(
        (domain: number) => {
            dispatch({
                type: ACTIONS.SET_DEF_DOMAIN,
                payload: domain,
            });
        },
        [dispatch],
    );

    /**
     * Set the forced domain in the state.
     *
     * @param domain - The forced domain to set.
     */
    const setDomainForced = useCallback(
        (domain: number) => {
            dispatch({
                type: ACTIONS.SET_DEF_DOMAIN_FORCED,
                payload: domain,
            });
        },
        [dispatch],
    );

    /**
     * Setup domain to 0 currently means no Invoice Categories filtering.
     */
    const resetDomain = useCallback(() => {
        if (isInterne) {
            setDomain(0);
        } else {
            setDomain(1);
        }
    }, [isInterne, setDomain]);

    /**
     * Applies the appropriate domain based on the available data for the selected folio,
     * following a strict priority order.
     *
     * Priority order for selecting the domain:
     * 1. Forced Domain: If a forced domain is available, it takes the highest priority.
     * 2. Command Domain: If no forced domain is set, the domain from the command is considered next.
     * 3. Bill Domain: If neither the forced domain nor the command domain is available, the domain from the bill is considered.
     * 4. Publisher Domain: If none of the above domains are available, the domain from the publisher is considered.
     *
     * If there is no domain currently selected, the function will apply the appropriate domain based on this priority.
     * If a domain is already selected and matches the highest priority available, the process is skipped.
     *
     * Fallback Logic:
     * - If no domain is found through the priority order, and the user has an "interne" role, the domain is reset.
     * - If the user is not "interne", the domain is defaulted to 1.
     */
    useEffect(() => {
        if (shouldSkipProcess) return;

        if (thereIsDomainForced) {
            setDomain(domainForcedSelected as number);
        } else if (!isFalsy(thereIsCommandWithAuthorizedDomain).isFalsy) {
            setDomain(thereIsCommandWithAuthorizedDomain as number);
        } else if (!isFalsy(thereIsBillWithAuthorizedDomain).isFalsy) {
            setDomain(thereIsBillWithAuthorizedDomain as number);
        } else if (!isFalsy(thereIsPublisherWithAuthorizedDomain).isFalsy) {
            setDomain(thereIsPublisherWithAuthorizedDomain as number);
        } else {
            resetDomain();
        }
    }, [
        domainForcedSelected,
        isFalsy,
        resetDomain,
        setDomain,
        shouldSkipProcess,
        thereIsBillWithAuthorizedDomain,
        thereIsCommandWithAuthorizedDomain,
        thereIsDomainForced,
        thereIsPublisherWithAuthorizedDomain,
    ]);

    return {
        setDomain,
        setDomainForced,
        resetDomain,
    };
};

export default useDomain;
