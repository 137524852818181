import { RootStateType } from '@b2d/redux/RootStore';
import { GroupementGroupementRead } from '@europrocurement/l2d-domain/openApi/ApiOffre';
import { OFFRE_REDUCER_NAME } from '@europrocurement/l2d-domain';
import { OFFER_GROUP_SLICE_NAME } from '@europrocurement/l2d-domain/reducers/offre/slices/offerGroupSlice';
import { offerGroupIcon } from '@europrocurement/l2d-icons';
import { groupementApi } from '@b2d/redux/subReducers/Offers';
import { offerGroupsDataSourcesThunks } from '@b2d/redux/subReducers/Offers/reducers';
import { ModelConfiguration, OfferModelItem, ModelMethods } from './types';

const modelKey = 'offer_group';
const modelName = 'OfferGroup';
const sliceName = OFFER_GROUP_SLICE_NAME;
const baseSelector = (state: RootStateType) => state[OFFRE_REDUCER_NAME][sliceName];
const datasourcesThunks = offerGroupsDataSourcesThunks;
const api = groupementApi;

type ModelStructure = GroupementGroupementRead;

const configuration: ModelConfiguration = {
    key: modelKey,
    name: modelName,
    type: 'Groupement',
    selector: baseSelector,
    sliceName,
    api,
    datasourcesThunks,
    icon: offerGroupIcon,
};

const methods: ModelMethods<ModelStructure> = {
    getItemDefaultLabel: (model: ModelStructure) => `${model.libelle}`,
};

const endpoints = {
    list: (...args: Parameters<typeof api.apiGroupementsGetCollection>) =>
        api.apiGroupementsGetCollection(...args),
    delete: (...args: Parameters<typeof api.apiGroupementsIdDelete>) =>
        api.apiGroupementsIdDelete(...args),
    read: (...args: Parameters<typeof api.apiGroupementsIdGet>) => api.apiGroupementsIdGet(...args),
    update: (...args: Parameters<typeof api.apiGroupementsIdPut>) =>
        api.apiGroupementsIdPut(...args),
    create: (...args: Parameters<typeof api.apiGroupementsPost>) => api.apiGroupementsPost(...args),
};

type ModelType = OfferModelItem<ModelStructure> & typeof endpoints;

const model: ModelType = {
    ...configuration,
    ...methods,
    ...endpoints,
};

export type OfferGroupModel = ModelType;
export default model;
