import React, { FunctionComponent, ReactElement, ReactNode, useMemo } from 'react';

import { Box, Skeleton } from '@mui/material';
import InvoiceListSkeleton from '../views/lists/InvoiceListSkeleton';
import SplitViewSkeleton from '../views/forms/SplitViewSkeleton';
import StatsCardsSkeleton from '../views/stats/StatsCardsSkeleton';
import InvoiceFormViewSkeleton from '../views/forms/simple/InvoiceFormViewSkeleton';
import DataCardSkeleton from './fragments/InvoiceDataCard/DataCardSkeleton';
import InvoiceFormFragmentSkeleton from '../views/forms/simple/InvoiceFormFragmentSkeleton';
import TabsSkeleton from './fragments/TabsSkeleton';

interface SkeletonLoaderProps {
    isLoading: boolean;
    children: ReactElement;
    type?: 'List' | 'Form' | 'FormSection' | 'Mosaic' | 'SplitView' | 'DataList' | 'Tabs';
    typeProps?: Record<string, unknown>;
}

const getSkeletonForComponent = ({
    type,
    typeProps,
}: Pick<SkeletonLoaderProps, 'type' | 'typeProps'>): ReactNode => {
    if (!type) return null;

    switch (type) {
        case 'List':
            return (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        height: '100%',
                    }}
                >
                    <InvoiceListSkeleton />
                </Box>
            );
        case 'SplitView':
            return (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        height: '100vh',
                    }}
                >
                    <SplitViewSkeleton />
                </Box>
            );
        case 'Form':
            return (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'start',
                        justifyContent: 'start',
                        width: '100%',
                        height: '100vh',
                    }}
                >
                    <TabsSkeleton />
                    <InvoiceFormViewSkeleton />
                </Box>
            );
        case 'FormSection':
            return (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        height: `${100 / 5}vh`,
                    }}
                >
                    <InvoiceFormFragmentSkeleton sectionsCount={5} />
                </Box>
            );
        case 'Mosaic':
            return (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        height: '100%',
                    }}
                >
                    <StatsCardsSkeleton />
                </Box>
            );
        case 'DataList':
            return (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        height: '100%',
                    }}
                >
                    <DataCardSkeleton {...typeProps} />
                </Box>
            );
        case 'Tabs':
            return <TabsSkeleton />;
        default:
            return (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        height: '100vh',
                    }}
                >
                    <Skeleton
                        variant="rounded"
                        width="100%"
                        height="100%"
                    />
                </Box>
            );
    }
};

const SkeletonLoader: FunctionComponent<SkeletonLoaderProps> = function ({
    isLoading,
    type,
    typeProps,
    children,
}) {
    const getSkeleton = useMemo(
        () => getSkeletonForComponent({ type, typeProps }),
        [type, typeProps],
    );

    return isLoading ? getSkeleton : children;
};

export default SkeletonLoader;
