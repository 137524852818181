import { RubriqueFacturationRubriqueFactureRead } from '@europrocurement/l2d-domain/openApi/ApiDossiers';
import { Box, Checkbox, Menu, MenuItem, Typography } from '@mui/material';
import * as React from 'react';
import { faFilter } from '@fortawesome/pro-solid-svg-icons';
import TooltipedButton from '../../components/TooltipedButton';

type ComponentProps = {
    invoicingCategories: RubriqueFacturationRubriqueFactureRead[];
    setFilters: (codes: string[]) => void;
};

const InvoicingCategoriesFilters: React.FC<ComponentProps> = function ({
    invoicingCategories,
    setFilters,
}) {
    const allCodes = invoicingCategories.map(({ code }) => code);

    const [selectedCodes, setSelectedCodes] = React.useState<string[]>(allCodes as string[]);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    React.useEffect(() => {
        setFilters(selectedCodes);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCodes]);

    const toggleFilter = (code: string | null | undefined) => {
        if (!code) {
            return;
        }

        if (selectedCodes.includes(code)) {
            setSelectedCodes([
                ...selectedCodes.filter((invoicingCategroyCode) => invoicingCategroyCode !== code),
            ]);
        } else {
            setSelectedCodes([...selectedCodes, code]);
        }
    };

    return (
        <div>
            <TooltipedButton
                tooltip="Filtrer les rubriques de facturation"
                onClick={handleClick}
                icon={faFilter}
            />

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Box sx={{ padding: '30px 30px 10px 30px' }}>
                    <Typography fontWeight="bold">Rubriques de facturations :</Typography>
                </Box>
                {invoicingCategories.map(({ code, libelle }) => {
                    if (!code || code === 'LIB') {
                        return null;
                    }

                    return (
                        <MenuItem
                            key={`package_invoicing_filter_${code}`}
                            sx={{ padding: '0 0 0 20' }}
                            onClick={() => toggleFilter(code)}
                        >
                            <Checkbox
                                sx={{ maxHeight: '25px' }}
                                checked={selectedCodes.includes(code)}
                            />
                            <Typography fontSize={14}>
                                {libelle} ({code})
                            </Typography>
                        </MenuItem>
                    );
                })}
            </Menu>
        </div>
    );
};

export default InvoicingCategoriesFilters;
