import * as React from 'react';

import { Controller, FieldValues, SubmitHandler, useForm } from 'react-hook-form';

import {
    ArticlesApiApiArticlesIdPutRequest,
    ArticlesApiApiArticlesPostRequest,
    ArticlesArticleRead,
    ArticlesJsonldArticleCreate,
    ArticlesJsonldArticleUpdate,
    RubriqueFacturationRubriqueFacturationRead,
    TblRubFacDomaineVentesRubriqueFacturationReadDomaine,
} from '@europrocurement/l2d-domain/openApi/ApiOffre';
import { FlexyFormLabel, ModalContext } from '@europrocurement/flexy-components';
import { Box } from '@mui/system';
import { Alert, Button, Typography } from '@mui/material';
import { zodResolver } from '@hookform/resolvers/zod';
import { useSelector } from 'react-redux';
import { RootStateType, domainSelector } from '@b2d/redux/RootStore';
import useApiRequest from '@b2d/hooks/useApiRequest';
import models from '@b2d/pages/Offres/models';
import { formatOptionsByKeys } from '@b2d/pages/Offres/forms/options';
import TextField from '@b2d/pages/Offres/components/form/TextField';
import SelectField from '@b2d/pages/Offres/components/form/SelectField';
import AutocompleteField from '@b2d/pages/Offres/components/form/AutocompleteField';
import { INVOICING_CATEGORIES_SLICE_NAME, OFFRE_REDUCER_NAME } from '@europrocurement/l2d-domain';
import { ArticleSchema, articleSchema } from './schema';
import {
    articleUnitsOptions,
    exemptedVatOption,
    groupInvoicingCategoriesOptions,
    priceTypesOptions,
    vatCodesOptions,
} from './options';
import { ADVERT_CATEGORY_CODE, EXEMPTED_VAT_CATEGORY_CODES } from './rules';
import { input, output } from './transformApiEntity';
import { AbstractFormType, SelectInputOption } from '../forms/types';

type ArticleFormProps = AbstractFormType & {
    entity?: ArticlesArticleRead;
};

type PriceStatus = 'not-defined' | 'free' | 'defined';
/** Article Form */
const Form: React.FC<ArticleFormProps> = function (props) {
    const L2D_COMPANY_ID = 12;
    const companyId = L2D_COMPANY_ID;

    /** Generic requirements */
    const { afterSubmit, entity, isCreate = false } = props;
    const { modalActions } = React.useContext(ModalContext ?? null);
    const { request } = useApiRequest();
    const { article: articleModel, document: documentModel } = models;

    /** Api fetched options for select inputs */
    const invoicingCategoriesSelector = (state: RootStateType) =>
        state[OFFRE_REDUCER_NAME][INVOICING_CATEGORIES_SLICE_NAME];
    const invoicingCategoriesOptions = useSelector(invoicingCategoriesSelector).main
        .data as RubriqueFacturationRubriqueFacturationRead[];
    const fetchedDomainsOptions = useSelector(domainSelector).main.data;
    const groupedInvoicingCategoriesOptions = groupInvoicingCategoriesOptions(
        invoicingCategoriesOptions,
    );
    const domainsOptions = formatOptionsByKeys(fetchedDomainsOptions, 'id', 'libelle');

    /** React hook form */

    // Format entity input > Translate fields > Format
    const defaultValues = input(isCreate ? undefined : entity) as FieldValues;
    // Form configuration
    const formContext = useForm({
        mode: 'onBlur',
        defaultValues, // Pass abstract type when using a resolver
        resolver: zodResolver(articleSchema),
    });

    const {
        control,
        handleSubmit,
        formState: { errors, isSubmitting },
        getValues,
        setValue,
        watch,
    } = formContext;

    const { invoicingCategory, unit, vatCode, domainId } = getValues();

    /** Specific validation rules */

    /**  Price  */
    const getInitialPriceType = () => {
        const { price } = getValues();
        if (price === 0) {
            return 'free';
        }
        if (price > 0) {
            return 'defined';
        }
        return 'not-defined';
    };

    const [priceType, setPriceType] = React.useState<PriceStatus>(getInitialPriceType());

    const isPriceDefined = priceType === 'defined';

    /** Rules : price update */
    React.useEffect(() => {
        setValue('price', priceType === 'free' ? '0' : null);
    }, [priceType, setValue]);

    /**  Invoicing category  */
    const isCustomCategory = invoicingCategory === 'DIV';
    const isAdvertCategory = ADVERT_CATEGORY_CODE.includes(invoicingCategory);
    const isVatExempted = EXEMPTED_VAT_CATEGORY_CODES.includes(invoicingCategory);

    const getInvoicingCategoryDomainIds = (
        invoicingCategoryValue: RubriqueFacturationRubriqueFacturationRead,
    ) => {
        if (!invoicingCategoryValue.domainesVentes) {
            return [];
        }
        return invoicingCategoryValue.domainesVentes.map(
            ({
                domaine,
            }: {
                domaine: TblRubFacDomaineVentesRubriqueFacturationReadDomaine | null;
            }) => domaine?.id,
        );
    };

    const invoicingCategoryBelongToDomainId = (invoicingCategoryCode: string): boolean => {
        const category = invoicingCategoriesOptions.find(
            ({ code }) => code === invoicingCategoryCode,
        );

        // domainId can be 0
        if (!category || typeof domainId !== 'number') {
            return false;
        }

        const invoicingCategoryDomainIds = getInvoicingCategoryDomainIds(category);

        return invoicingCategoryDomainIds.includes(domainId);
    };
    const optionDoesntBelongToSelectedDomain = (currentOption: SelectInputOption) => {
        if (!currentOption) {
            return false;
        }
        const categoryCode = currentOption.value as string;

        return !invoicingCategoryBelongToDomainId(categoryCode);
    };

    /** Rules : invoicing category update */
    React.useEffect(() => {
        // Force null for price in advert category case
        if (isAdvertCategory) {
            setValue('price', null);
            setValue('vatCode', 'TN');
            setPriceType('not-defined');
        } else if (unit === null && !isAdvertCategory) {
            // Fallback value
            setValue('unit', 'F');
        }
        // Force vat code for exempted categories
        if (isVatExempted) {
            setValue('vatCode', 'NS');
        } else if (vatCode === 'NS') {
            // Fallback value
            setValue('vatCode', 'TN');
        }
        /** Rules : reset invoicing category value if it does'nt belong to new selected domain */
        if (invoicingCategory !== null && !invoicingCategoryBelongToDomainId(invoicingCategory)) {
            setValue('invoicingCategory', null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoicingCategory, isAdvertCategory, isVatExempted, setValue, unit, vatCode, domainId]);

    const CEGIDbillingInputs = [
        {
            name: 'id',
            inputlabel: 'identifiant compte CEGID',
        },
        {
            name: 'sp1',
            inputlabel: 'SP1',
        },
        {
            name: 'sp2',
            inputlabel: 'SP2',
        },
        {
            name: 'tl1',
            inputlabel: 'TL1',
        },
        {
            name: 'tl2',
            inputlabel: 'TL2',
        },
        {
            name: 'tl3',
            inputlabel: 'TL3',
        },
    ] as const;

    const cegidStyle = {
        visibility: 'visible',
        opacity: '1',
    };

    /** Submit logic */

    const successCallback = () => {
        modalActions.reset();
        afterSubmit();
    };

    const createModel: SubmitHandler<ArticleSchema> = async (formValues) => {
        // Api platform type issue | iri and plain identifier must return string | number type
        const formData = output(formValues) as unknown as ArticlesJsonldArticleCreate;

        const createRequest = articleModel.create({
            articlesJsonldArticleCreate: {
                ...formData,
                societe: companyId,
            },
            xIdSociete: companyId,
        } as unknown as ArticlesApiApiArticlesPostRequest);
        await request(createRequest, { successCallback });
    };

    const updateModel: SubmitHandler<ArticleSchema> = async (formValues) => {
        const id = entity?.id ?? null;
        if (id) {
            // Api platform type issue | iri and plain identifier must return string | number type
            const formData = output(formValues) as unknown as ArticlesJsonldArticleUpdate;

            const updateRequest = articleModel.update({
                id: id.toString(),
                articlesJsonldArticleUpdate: {
                    ...formData,
                    societe: companyId,
                },
                xIdSociete: companyId,
            } as unknown as ArticlesApiApiArticlesIdPutRequest);
            await request(updateRequest, { successCallback });
        }
    };

    const onSubmit = (formData: ArticleSchema) =>
        isCreate ? createModel(formData) : updateModel(formData);

    const onError = (formData: unknown) => console.log('ON ERROR', formData);

    const documentList = isCreate ? [] : defaultValues.documents;

    return (
        <form
            onSubmit={handleSubmit(onSubmit, onError)}
            noValidate
        >
            <hr />
            <Box sx={{ minWidth: '800px' }}>
                <Box display="flex">
                    <Box sx={{ marginRight: '10px', width: '50%' }}>
                        <Controller
                            name="label"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    sx={{ width: '100%' }}
                                    {...field}
                                    name={field.name}
                                    label="Libellé"
                                    errors={errors}
                                    required
                                />
                            )}
                        />
                    </Box>

                    <Box sx={{ marginLeft: '10px', width: '50%' }}>
                        <Controller
                            name="code"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    sx={{ width: '100%' }}
                                    {...field}
                                    name={field.name}
                                    placeholder="Exemple  : ART0123"
                                    label="Code article"
                                    errors={errors}
                                    required={false}
                                />
                            )}
                        />
                    </Box>
                </Box>

                <Controller
                    name="price"
                    control={control}
                    render={({ field }) => (
                        <Box
                            display="flex"
                            sx={{ width: '100%' }}
                        >
                            <Box
                                sx={{
                                    marginRight: '10px',
                                    width: '50%',
                                }}
                            >
                                <SelectField
                                    name="priceType"
                                    label="Type de prix"
                                    options={priceTypesOptions}
                                    value={priceType}
                                    isDisabled={invoicingCategory && isAdvertCategory}
                                    onChange={setPriceType}
                                />
                            </Box>
                            <Box sx={{ marginLeft: '10px', width: '50%' }}>
                                {priceType === 'not-defined' ? (
                                    <Alert
                                        sx={{ width: '100%', marginTop: '38px' }}
                                        severity="info"
                                    >
                                        (Le tarif sera à définir ultérieurement)
                                    </Alert>
                                ) : (
                                    <TextField
                                        sx={{ width: '100%' }}
                                        {...field}
                                        name={field.name}
                                        label="Prix Unitaire"
                                        errors={errors}
                                        required
                                        disabled={!isPriceDefined}
                                    />
                                )}
                            </Box>
                        </Box>
                    )}
                />

                <Box display="flex">
                    <Box sx={{ marginRight: '10px', width: '50%' }}>
                        <Controller
                            name="domainId"
                            control={control}
                            render={({ field }) => (
                                <SelectField
                                    {...field}
                                    value={watch(field.name)}
                                    name={field.name}
                                    label="Domaine"
                                    options={domainsOptions}
                                    errors={errors}
                                />
                            )}
                        />
                    </Box>

                    <Box>
                        <Box
                            sx={{
                                marginRight: '10px',
                                width: '50%',
                                opacity: !isAdvertCategory ? 1 : 0.5,
                            }}
                        >
                            <FlexyFormLabel>
                                <Typography component="span">Unité</Typography>
                            </FlexyFormLabel>
                        </Box>
                        <Box display="flex">
                            <Controller
                                name="unit"
                                control={control}
                                render={() => (
                                    <Box
                                        display="flex"
                                        justifyContent="space-between"
                                    >
                                        {articleUnitsOptions.map(({ label, value }) => {
                                            const isOptionSelected = isAdvertCategory
                                                ? false
                                                : getValues().unit === value;
                                            return (
                                                <Button
                                                    key={`unit_${value}`}
                                                    sx={{
                                                        marginX: '8px',
                                                        opacity: isOptionSelected ? 1 : 0.7,
                                                    }}
                                                    variant={
                                                        isOptionSelected && !isAdvertCategory
                                                            ? 'contained'
                                                            : 'outlined'
                                                    }
                                                    disabled={isAdvertCategory}
                                                    onClick={() => setValue('unit', value)}
                                                >
                                                    {label}
                                                </Button>
                                            );
                                        })}
                                    </Box>
                                )}
                            />
                        </Box>
                    </Box>
                </Box>
                <Controller
                    name="invoicingCategory"
                    control={control}
                    render={({ field }) => (
                        <SelectField
                            {...field}
                            value={watch(field.name)}
                            placeholder="Sélectionnez une rubrique"
                            name={field.name}
                            label="Rubrique de facturation"
                            options={groupedInvoicingCategoriesOptions}
                            isOptionDisabled={optionDoesntBelongToSelectedDomain}
                            errors={errors}
                        />
                    )}
                />

                <Box
                    sx={{
                        visibility: 'hidden',
                        opacity: '0',
                        borderRadius: '10px',
                        transition: 'opacity 600ms',
                        ...(isCustomCategory && cegidStyle),
                    }}
                >
                    {isCustomCategory && (
                        <>
                            <FlexyFormLabel>
                                <Typography component="span">
                                    Paramétrage comptable (CEGID)
                                </Typography>
                                <hr />
                            </FlexyFormLabel>
                            <Box display="flex">
                                {CEGIDbillingInputs.map(({ inputlabel, name }) => {
                                    const isCegidIdField = name === 'id';

                                    return (
                                        <Box
                                            key={`cegid_${name}`}
                                            sx={{ marginRight: '10px', width: '50%' }}
                                        >
                                            <Controller
                                                name={`cegidConfiguration.${name}`}
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        sx={{
                                                            width: isCegidIdField
                                                                ? '300px'
                                                                : '100px',
                                                        }}
                                                        {...field}
                                                        name={field.name}
                                                        label={inputlabel}
                                                        errors={errors}
                                                        required={isCegidIdField}
                                                    />
                                                )}
                                            />
                                        </Box>
                                    );
                                })}
                            </Box>
                        </>
                    )}
                </Box>

                <Controller
                    name="vatCode"
                    control={control}
                    render={({ field }) => (
                        <SelectField
                            {...field}
                            value={watch(field.name)}
                            sx={{ opacity: isVatExempted ? 0.5 : 1 }}
                            name={field.name}
                            label="TVA"
                            isDisabled={isVatExempted || isAdvertCategory}
                            options={isVatExempted ? exemptedVatOption : vatCodesOptions}
                            required
                            errors={errors}
                        />
                    )}
                />

                <Controller
                    name="documentIds"
                    control={control}
                    render={({ field }) => (
                        <AutocompleteField
                            {...field}
                            formKey={field.name}
                            defaultList={documentList}
                            model={documentModel}
                            formContext={formContext}
                            label="Documents"
                            inputLabel=""
                            placeholder="Sélectionnez le ou les document(s) liés à cette valeur"
                        />
                    )}
                />

                <Box
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                >
                    <Button
                        type="submit"
                        sx={{ marginRight: '10px' }}
                        variant="outlined"
                        style={{ marginTop: '2rem', marginBottom: '16px' }}
                        onClick={modalActions.reset}
                    >
                        Annuler
                    </Button>
                    <Button
                        type="submit"
                        disabled={isSubmitting}
                        variant="contained"
                        style={{ marginTop: '2rem', marginBottom: '16px' }}
                    >
                        Valider
                    </Button>
                </Box>
            </Box>
        </form>
    );
};

export default Form;
