import * as React from 'react';
import { Button, PaletteColor, useTheme } from '@mui/material';
import { FaOptionIcon, FaOptionIconProps } from '@europrocurement/l2d-icons';
import './L2DFSidebarAction.css';

export type L2DFSidebarActionProps = {
    label: string;
    icon: FaOptionIconProps;
    folded?: boolean;
    color?: PaletteColor;
    onClick: () => void;
};

const L2DFSidebarAction: React.FunctionComponent<L2DFSidebarActionProps> = function (props) {
    const theme = useTheme();
    const { label, icon, folded = false, color, onClick } = props;
    const mainColor = color || theme.palette.primary;
    const textColor = theme.palette.background.paper;
    const classNames = ['l2df-sidebar-action'];

    if (folded) {
        classNames.push('l2df-sidebar-action-folded');
    }

    return (
        <Button
            className={classNames.join(' ')}
            data-testid="l2df-sidebar-action"
            onClick={onClick}
            sx={{
                backgroundColor: mainColor.dark,
                color: textColor,
                ':hover': { backgroundColor: mainColor.main },
            }}
        >
            <FaOptionIcon {...icon} />
            {folded ? '' : label}
        </Button>
    );
};

export default L2DFSidebarAction;
