import { FieldValues } from 'react-hook-form';

/** Format output handling */
export type FormatSchema = {
    [key: string]: (value: unknown) => unknown;
};

const applyFormatSchema = (formatSchema: FormatSchema, key: string, value: unknown) => {
    const formatFunction = formatSchema[key] ?? null;
    if (formatFunction) {
        return formatFunction(value);
    }
    return value;
};

/** Apply a format schema and return the formated object. */
export const formatPayload = (object: FieldValues, formatSchema: FormatSchema) =>
    Object.entries(object).reduce(
        (acc, [key, value]) =>
            Object.assign(acc, {
                [key]: applyFormatSchema(formatSchema, key, value),
            }),
        {},
    );
