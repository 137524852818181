import { useDispatch } from 'react-redux';
import { UseFormReturn } from 'react-hook-form';
import { AxiosResponse } from 'axios';

import {
    COMMANDES_SLICE_NAME,
    DOSSIERS_FORMALITES_AUTOCOMPLETE_DATASOURCE_NAME,
    DOSSIERS_FORMALITES_SLICE_NAME,
    DOSSIERS_SLICE_NAME,
    DOSSIER_AUTOCOMPLETE_DATASOURCE_NAME,
    FACTURE_VENTE_SLICE_NAME,
    FOURNISSEUR_AUTOCOMPLETE_FACTURE_DATASOURCE_NAME,
    FOURNISSEUR_SLICE_NAME,
} from '@europrocurement/l2d-domain';
import { FormObject } from '@europrocurement/flexy-form';
import {
    FactureAchatNoteApiCreateFactureAchatNoteFactureAchatNoteCollectionRequest,
    FactureAchatNoteJsonldFactureAchatRead,
} from '@europrocurement/l2d-domain/openApi/ApiAchats';
import { factureAchatNoteApi } from '@b2d/redux/RootStore';
import { ACTIONS } from '@b2d/redux/FactureFormReducer';

import { NoteFactureForm } from '../types';
import { resetDossierFormaliteFields, resetDossiersFields } from './dossierFonctions';
import { resetFournisseursFields } from './fournisseurFunctions';
import formNoteToApiInvoicePurchaseNoteArrayPost from './dataTransformers/formToApi/formNoteToApiInvoicePurchaseNoteArrayPost';

/**
 *
 * @param formContext
 */
export const resetDossierAndFournisseurs = function (formContext: UseFormReturn<FormObject>) {
    resetDossiersFields(formContext);
    resetFournisseursFields(formContext);
};

export const resetDossierFormalite = function (formContext: UseFormReturn<FormObject>) {
    resetDossierFormaliteFields(formContext);
};

export type ResetReduxProps = {
    selectedData?: boolean;
    searchResults?: boolean;
    defaultValues?: boolean;
};

/**
 * Vide les DataSources selected
 *
 * @returns void
 */
export const useResetRedux = function () {
    const dispatch = useDispatch();

    return ({
        selectedData = true,
        searchResults = true,
        defaultValues = true,
    }: ResetReduxProps) => {
        // Remove selected data
        if (selectedData) {
            dispatch({
                type: `${FOURNISSEUR_SLICE_NAME}/delete${FOURNISSEUR_AUTOCOMPLETE_FACTURE_DATASOURCE_NAME}Selected`,
            });
            dispatch({
                type: `${FACTURE_VENTE_SLICE_NAME}/deletemainSelected`,
            });
            dispatch({
                type: `${DOSSIERS_FORMALITES_SLICE_NAME}/delete${DOSSIERS_FORMALITES_AUTOCOMPLETE_DATASOURCE_NAME}Selected`,
            });
            dispatch({
                type: `${DOSSIERS_SLICE_NAME}/delete${DOSSIER_AUTOCOMPLETE_DATASOURCE_NAME}Selected`,
            });
            dispatch({
                type: `${COMMANDES_SLICE_NAME}/deletemainSelected`,
            });
        }

        // Remove search results
        if (searchResults) {
            dispatch({
                type: `${FOURNISSEUR_SLICE_NAME}/reset${FOURNISSEUR_AUTOCOMPLETE_FACTURE_DATASOURCE_NAME}Search`,
            });
            dispatch({
                type: `${FACTURE_VENTE_SLICE_NAME}/resetmainSearch`,
            });
            dispatch({
                type: `${DOSSIERS_FORMALITES_SLICE_NAME}/reset${DOSSIERS_FORMALITES_AUTOCOMPLETE_DATASOURCE_NAME}Search`,
            });
            dispatch({
                type: `${DOSSIERS_SLICE_NAME}/reset${DOSSIER_AUTOCOMPLETE_DATASOURCE_NAME}Search`,
            });
            dispatch({
                type: `${COMMANDES_SLICE_NAME}/resetmainSearch`,
            });
        }

        if (defaultValues) {
            // Remove default values from form state
            dispatch({
                type: ACTIONS.SET_NUM_DOSSIER_FORMALITE,
                payload: '',
            });
            dispatch({
                type: ACTIONS.SET_NUM_DOSSIER,
                payload: '',
            });
            dispatch({
                type: ACTIONS.SET_NUM_PRESTA,
                payload: '',
            });
            dispatch({
                type: ACTIONS.SET_SIREN,
                payload: '',
            });
        }
    };
};

export type ResetFormValuesProps = {
    formContext: UseFormReturn<FormObject>;
};

export const useResetFormValues = function () {
    return ({ formContext }: ResetFormValuesProps) => {
        formContext.setValue('fournisseur', {});
        formContext.setValue('numero_dossier_formalite', null);
        formContext.setValue('numero_dossier', null);
        formContext.setValue('numero_annonce', null);
    };
};

export type ResetFactureAchatStateProps = ResetReduxProps & {
    formContext: UseFormReturn<FormObject>;
    formValues?: boolean;
};

/**
 * Vide les champs du formulaire et les DataSources selected
 *
 * @returns void
 */
export const useResetFactureAchatState = function () {
    const resetRedux = useResetRedux();
    const resetFormValues = useResetFormValues();

    return ({
        formContext,
        formValues = true,
        ...resetReduxProps
    }: ResetFactureAchatStateProps) => {
        // resetDossierAndFournisseurs(formContext);
        resetDossierFormalite(formContext);

        if (formValues) {
            resetFormValues({ formContext });
        }

        resetRedux({ ...resetReduxProps });
    };
};

export type ResetAllFormProps = ResetFactureAchatStateProps;

/**
 *
 * @returns
 */
export const useResetAllForm = function () {
    const dispatch = useDispatch();
    const resetStates = useResetFactureAchatState();

    return ({ ...resetStatesProps }: ResetAllFormProps) => {
        resetStates(resetStatesProps);
        dispatch({
            type: ACTIONS.RESET,
        });
    };
};

/**
 * Création des notes.
 *
 * @param factureForm
 * @returns Promise<any>;
 */
export const createNotes = async (factureAchatIri: string, notes: NoteFactureForm[]) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const promises: Promise<AxiosResponse<FactureAchatNoteJsonldFactureAchatRead, any>>[] = [];
    const formatedFactureAchatNotes = formNoteToApiInvoicePurchaseNoteArrayPost(
        notes,
        factureAchatIri,
    );

    formatedFactureAchatNotes.map((note) => {
        const requestParameters: FactureAchatNoteApiCreateFactureAchatNoteFactureAchatNoteCollectionRequest =
            {
                factureAchatNoteJsonldFactureAchatNoteCreate: note,
            };

        return promises.push(
            factureAchatNoteApi.createFactureAchatNoteFactureAchatNoteCollection(requestParameters),
        );
    });

    return await Promise.all(promises);
};
