import { MenuEntry } from '@europrocurement/flexy-components';

import { PrescripteurMenuEntries } from '../pages/Prescripteurs/PrescripteurRouter';
import { SignatureMenuEntries } from '../pages/Signature/SignatureRouter';
import { AchatsMenuEntries } from '../pages/Achats/AchatsRouter';
import { FormalityMenuEntries } from '../pages/Formalities/FormalityRouter';

const menuEntries: MenuEntry[] = [
    {
        title: 'Dashboard',
        icon: 'house',
        href: '/accueil',
    },

    // Prescripteur
    ...PrescripteurMenuEntries,

    // Comptabilité
    ...AchatsMenuEntries,

    // Import signature menu entries
    ...SignatureMenuEntries,

    // Administration
    {
        title: 'Administration',
        icon: 'gear',
        role: 'realm:interne',
        children: [
            {
                title: 'Gestion des offres marketing',
                icon: 'tag',
                href: '/admin/offres',
                role: 'realm:interne',
            },
            ...FormalityMenuEntries,
        ],
    },
];

export default menuEntries;
