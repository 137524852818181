import React, { useMemo, useState } from 'react';
import type { ReactNode } from 'react';
import { DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import type { Active, UniqueIdentifier } from '@dnd-kit/core';
import { SortableContext, arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable';

import './SortableList.css';

import { DragHandle, SortableItem } from './index';
import SortableOverlay from './SortableOverlay/SortableOverlay';

interface BaseItem {
    id: UniqueIdentifier;
}

type Props<T extends BaseItem> = {
    items: T[];
    onChange(items: T[]): void;
    renderItem(item: T, index?: number): ReactNode;
};

const SortableList = function <T extends BaseItem>(props: Props<T>) {
    const { items, onChange, renderItem } = props;
    const [active, setActive] = useState<Active | null>(null);
    const activeItem = useMemo(() => items.find((item) => item.id === active?.id), [active, items]);
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
    );

    return (
        <DndContext
            sensors={sensors}
            onDragStart={({ active: activeValue }) => {
                setActive(activeValue);
            }}
            onDragEnd={({ active: activeValue, over }) => {
                if (over && activeValue.id !== over?.id) {
                    const activeIndex = items.findIndex(({ id }) => id === activeValue.id);
                    const overIndex = items.findIndex(({ id }) => id === over.id);

                    onChange(arrayMove(items, activeIndex, overIndex));
                }
                setActive(null);
            }}
            onDragCancel={() => {
                setActive(null);
            }}
        >
            <SortableContext items={items}>
                <ul
                    className="SortableList"
                    role="application"
                >
                    {items.map((item, index) => (
                        <React.Fragment key={`sortable_${item.id}`}>
                            {renderItem(item, index)}
                        </React.Fragment>
                    ))}
                </ul>
            </SortableContext>
            <SortableOverlay>{activeItem ? renderItem(activeItem) : null}</SortableOverlay>
        </DndContext>
    );
};

SortableList.Item = SortableItem;
SortableList.DragHandle = DragHandle;

export default SortableList;
