import * as React from 'react';
import { Box, Card, CardHeader, Chip, Divider, Grid, Typography } from '@mui/material';
import { ActionsBar, Information, ModalContext } from '@europrocurement/flexy-components';
import { faImageSlash } from '@fortawesome/pro-duotone-svg-icons';
import { faCheck, faXmark } from '@fortawesome/pro-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FaOptionIcon } from '@europrocurement/l2d-icons';
import DemandeurForm from '../forms/DemandeurForm';
import { DemandeurCardProps } from '../constants/types';

const DemandeurCard: React.FC<DemandeurCardProps> = function (demandeur) {
    const { logo, nom, id, actif, callbackUrl, userIdentifier, afterSubmit, editable, titre } =
        demandeur;

    const imageSize = '80px';
    const imageStyle = {
        margin: '20px',
        minHeight: imageSize,
        minWidth: imageSize,
        maxHeight: imageSize,
        maxWidth: imageSize,
    };

    const { modalActions } = React.useContext(ModalContext);

    const handleEditDemandeur = () => {
        // TODO type params
        if (id) {
            modalActions.call(
                <DemandeurForm
                    demandeur={demandeur}
                    afterSubmit={() => {
                        modalActions.close();
                        if (afterSubmit) {
                            afterSubmit();
                        }
                    }}
                />,
            );
        }
    };

    return (
        <Card>
            <CardHeader
                title={
                    titre ? (
                        <Typography
                            variant="h4"
                            sx={{
                                marginBottom: '10px',
                            }}
                        >
                            {titre}
                        </Typography>
                    ) : (
                        ''
                    )
                }
                action={<ActionsBar editAction={editable ? handleEditDemandeur : undefined} />}
                sx={{
                    marginBottom: '15px',
                }}
            />

            <Grid container>
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={2}
                    // sx={{
                    //     textAlign: 'center',
                    //     verticalAlign: 'center',
                    // }}
                >
                    {logo ? (
                        <img
                            style={{
                                ...imageStyle,
                            }}
                            src={logo}
                            alt={nom}
                        />
                    ) : (
                        <Box
                            sx={{
                                margin: '20px',
                            }}
                        >
                            <FaOptionIcon
                                icon={faImageSlash}
                                color="primary.main"
                                size="2x"
                            />
                        </Box>
                    )}
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={10}
                    // sx={{
                    //     textAlign: 'center',
                    //     verticalAlign: 'center',
                    // }}
                >
                    <Box>
                        <Typography fontWeight="bold">
                            {nom}
                            {actif ? (
                                <Chip
                                    color="primary"
                                    label={
                                        <Typography
                                            display="inline-block"
                                            color="primary.main"
                                        >
                                            <FontAwesomeIcon
                                                scale={1.5}
                                                icon={faCheck}
                                                style={{
                                                    marginRight: '3px',
                                                }}
                                            />
                                            Actif
                                        </Typography>
                                    }
                                    size="small"
                                    variant="outlined"
                                    sx={{
                                        margin: '0px 5px 0px 15px',
                                        color: '#3e3e3e', // Nécessaire pour l'accessibilité
                                    }}
                                />
                            ) : (
                                <Chip
                                    color="danger"
                                    label={
                                        <Typography
                                            display="inline-block"
                                            color="danger.main"
                                        >
                                            <FontAwesomeIcon
                                                scale={1.5}
                                                icon={faXmark}
                                                style={{
                                                    marginRight: '3px',
                                                }}
                                            />
                                            Inactif
                                        </Typography>
                                    }
                                    size="small"
                                    variant="outlined"
                                    sx={{
                                        margin: '0px 5px 0px 15px',
                                        color: '#3e3e3e', // Nécessaire pour l'accessibilité
                                    }}
                                />
                            )}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            marginTop: '30px',
                        }}
                    >
                        {userIdentifier ? (
                            <Information
                                label="Identifiant"
                                value={userIdentifier}
                            />
                        ) : null}

                        {callbackUrl ? (
                            <>
                                {userIdentifier ? (
                                    <Divider
                                        sx={{
                                            marginTop: '5px',
                                            marginBottom: '5px',
                                        }}
                                    />
                                ) : null}
                                <Information
                                    label="Url de Callback json automatisé"
                                    value={callbackUrl}
                                />
                            </>
                        ) : null}
                    </Box>
                </Grid>
            </Grid>
        </Card>
    );
};

export default DemandeurCard;
