// Request data structure

import { Civilite } from '@b2d/pages/Prescripteurs/forms/utils';

const defaultParameters = {
    clientSuivi: true,
    venteIndirecte: false,
    creerLogin: true,
    delaiPaiement: 1,
    devisDetaille: false,
};

const DEFAULT_CIVILITY_ID = 0;

type cspType = { id: number; code: string; libelle: string };
type portefeuilleType = { id: number };
type codePaysType = {
    codeIso2?: string;
    codeIso3?: string;
    codeNumerique?: string;
    codePays?: string;
    codePaysRattachement?: string;
    codeActualite?: number;
    libelle?: string;
    libelleOfficiel?: string;
};
type prescriberFormType = {
    raisonSociale: string;
    personnePhysique: boolean;
    demo: boolean;
    siret?: number;
    comptabilite?: boolean;
    siren?: string;
    tvaIntracommunautaire?: string;
    portefeuille?: portefeuilleType;
    csp?: cspType;
    adresse1?: string;
    adresse2?: string;
    adresse3?: string;
    adresse4?: string;
    codePostal: number;
    ville: string;
    codePays: codePaysType;
    civilite: Civilite;
    nomContact: string;
    prenomContact: string;
    telephone?: string;
    mobile?: string;
    email?: string;
};

export const formatPayload = (formData: prescriberFormType, xIdSociete: number) => {
    const {
        raisonSociale,
        siret,
        siren,
        tvaIntracommunautaire,
        adresse1,
        adresse2,
        adresse3,
        adresse4,
        codePostal,
        ville,
        codePays,
        civilite,
        nomContact,
        prenomContact,
        telephone,
        portefeuille,
        csp,
        mobile,
        email,
        personnePhysique,
        demo,
    } = formData;

    const { clientSuivi, venteIndirecte, creerLogin, delaiPaiement, devisDetaille } =
        defaultParameters;

    const address = {
        adresse1,
        adresse2,
        adresse3,
        adresse4,
        codePostal,
        ville,
        codePays: codePays.codeIso2,
    };

    const contact = {
        civilite: civilite?.id ?? DEFAULT_CIVILITY_ID,
        nomContact,
        prenomContact,
        telephonesContact: telephone ? [{ telephone }] : [],
        mobilesContact: mobile ? [{ mobile }] : [],
        faxsContact: [],
        emailsContact: [
            {
                email,
            },
        ],
    };

    const companyRelations = {
        societe: {
            id: xIdSociete,
        },
        delaiPaiement,
        ...(portefeuille?.id && { portefeuille: { id: portefeuille.id } }),
        ...(csp?.id && { categorieSocioProfessionnelle: { id: csp.id } }),
    };
    const payload = {
        raisonSociale,
        siret,
        siren,
        tvaIntracommunautaire,
        personnePhysique,
        demo,
        clientSuivi,
        venteIndirecte,
        creerLogin,
        adresses: [address],
        contacts: [contact],
        relationsSocietes: [companyRelations],
        devisDetaille,
    };

    return payload;
};
