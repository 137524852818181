import {
    CenterCircularProgress,
    ContextActionType,
    LazyBreadcrumb,
    NavigateNotFound,
    useDumbActionsContext,
    useModal,
} from '@europrocurement/flexy-components';
import React from 'react';
import { useParams } from 'react-router';
import {
    FaOptionIcon,
    formalityActiveIcon,
    formalityInactiveIcon,
} from '@europrocurement/l2d-icons';
import ModalConfirm from '@europrocurement/flexy-components/components/organisms/ModalConfirm';
import { useDispatch } from 'react-redux';
import { selectFormality } from '@b2d/redux/subReducers/FormalityReducer';
import FormalityShowLayout from './FormalityShowLayout';
import useFormality from '../../hooks/useFormality';
import { FORMALITY_LIST } from '../../constants/paths';
import useFormalityFormHandler from '../../hooks/useFormalityFormHandler';

const FormalityShow: React.FunctionComponent = function () {
    const { formalityId } = useParams();
    const { formality, requestState } = useFormality({ formalityId });
    const toggleStatusIcon = formality?.deleted ? formalityActiveIcon : formalityInactiveIcon;
    const toggleStatusLabel = formality?.deleted
        ? 'Activer cette formalité'
        : 'Désactiver cette formalité';
    const { modalActions } = useModal();
    const { updateFormality } = useFormalityFormHandler();
    const dispatch = useDispatch();
    const onToggleFormalityStatus = () => {
        updateFormality(formality, { deleted: !formality?.deleted }).then(() => {
            dispatch(selectFormality({ id: formality.id }));
        });
    };

    const contextActions: ContextActionType[] = [
        {
            name: toggleStatusLabel,
            icon: <FaOptionIcon {...toggleStatusIcon.props} />,
            action: () => {
                modalActions.call(
                    <ModalConfirm
                        onConfirm={onToggleFormalityStatus}
                        message={
                            formality?.deleted
                                ? 'Êtes vous sur de vouloir activer cette formalité ?'
                                : 'Êtes vous sur de vouloir désactiver cette formalité ?'
                        }
                    />,
                );
            },
        },
    ];

    useDumbActionsContext(contextActions);

    if (requestState === 'idle' || requestState === 'loading') {
        return <CenterCircularProgress sx={{ height: '70vh' }} />;
    }

    if (requestState === 'failed') {
        NavigateNotFound();

        return null;
    }

    return (
        <>
            <LazyBreadcrumb
                path={[
                    { path: 'Formalités', link: FORMALITY_LIST },
                    "Details d'une formalité",
                    formality?.libelle as string,
                ]}
            />
            <FormalityShowLayout formality={formality} />
        </>
    );
};

export default FormalityShow;
