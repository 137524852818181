import { RelationSocieteTiersJsonldTiersRead } from '@europrocurement/l2d-domain/openApi/ApiTiers';

/**
 * Payload formats for Api requests
 */

export const getPrescripteurCompanyRelationPayload = (
    companyRelation: RelationSocieteTiersJsonldTiersRead,
) => {
    const {
        clientSuivi,
        clientXl,
        tiersNonFiable,
        dafc,
        modePaiement,
        delaiPaiement,
        emailSousclientObligatoire,
        partenaireFormalite,
        logoFormalite,
        portefeuille,
        categorieSocioProfessionnelle,
        devisDetaille,
    } = companyRelation;

    if (!portefeuille) {
        throw new Error('Missing portefeuille');
    }

    return {
        clientSuivi,
        clientXl,
        tiersNonFiable,
        dafc,
        delaiPaiement,
        modePaiement: {
            id: modePaiement?.id,
        },
        emailSousclientObligatoire,
        partenaireFormalite,
        logoFormalite: logoFormalite ?? '',
        portefeuille: {
            id: portefeuille.id,
        },
        categorieSocioProfessionnelle: {
            id: categorieSocioProfessionnelle?.id,
        },
        devisDetaille,
    };
};

export const getPrescriberRightsPayload = (
    companyRelation: RelationSocieteTiersJsonldTiersRead,
) => {
    const { plateformeB2BAutorise } = companyRelation;

    const fields = [plateformeB2BAutorise];

    const isFormValid = fields.every((field) => typeof field === 'boolean');

    if (isFormValid) {
        return {
            accesPlateformeB2B: plateformeB2BAutorise,
        };
    }
    throw new Error('Form Invalid');
};
