import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { Card, Grid, Typography } from '@mui/material';

import {
    CustomizerReducerType,
    FOURNISSEUR_SLICE_NAME,
    FactureAchat,
    Fournisseur,
    MediaObject,
    setXIdSociete,
} from '@europrocurement/l2d-domain';
import {
    ContextActionType,
    footerHeight,
    TopbarHeight,
    useContextActions,
    ModalContext,
} from '@europrocurement/flexy-components';

import { FaOptionIcon } from '@europrocurement/l2d-icons';

import { DataSource, EuroprocApiResponseStatus } from '@europrocurement/l2d-redux-utils';
import {
    FactureAchatApiDeleteFactureAchatFactureAchatItemRequest,
    FactureAchatApiSyncG3FactureAchatFactureAchatItemRequest,
    MediaObjectApiPatchMediaObjectItemRequest,
} from '@europrocurement/l2d-domain/openApi/ApiAchats';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AxiosError } from 'axios';
import _ from 'lodash';
import { faFileInvoice, faServer, faTrashCan } from '@fortawesome/pro-duotone-svg-icons';
import { faRotate, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { filtersHasher } from '@europrocurement/l2d-utils';
import generateB2DPath, { GenerateB2DPathResponseType } from '@b2d/utils/generateB2DPath';
import useApiRequest from '@b2d/hooks/useApiRequest';
import { UseKeycloakCheckRole } from '@europrocurement/l2d-keycloak';
import { EnteteFacture } from '@b2d/pages/Achats/components/forms/multi/EnteteFacture';
import SkeletonLoader from '@b2d/pages/Achats/components/SkeletonLoader';
import useLoadingStatus from '@b2d/hooks/useLoading';
import { contactSupport, refreshPage } from '@b2d/utils/wording';
import { saisirEditDossierMultiFacturePath } from '@b2d/pages/Achats/constants/paths';
import { MediaObjectReader } from '../../../components/widgets/MediaObjectReader';
import {
    MediaObjectSelector,
    selectMediaObject,
    mediaObjectApi,
    AppDispatch,
    factureAchatApi,
    selectFactureAchat,
    RootStateType,
    customizerSelector,
    selectFournisseur,
} from '../../../../../redux/RootStore';
import ConfirmationModalContent from '../../../components/modals/ConfirmationModalContent';
import { removeFactureModalMessages } from '../../../constants/wording/modals';
import WorkflowMailSenderModal from '../../../components/modals/WorkflowMailSenderModal';

export type MultiAnnonceContextType = { mediaObject: MediaObject };

export function useMultiAnnonceContext() {
    return useOutletContext<MultiAnnonceContextType>();
}

const RegisterMultiInvoiceView: React.FunctionComponent = function () {
    const { mediaobjectid, factureachatid, dossiernum } = useParams();

    const { enqueueSnackbar } = useSnackbar();
    const { modalActions } = useContext(ModalContext);
    const { defineContextActions, resetContextActions } = useContextActions();
    const { xIdSociete } = useSelector(customizerSelector);

    const dispatch = useDispatch<AppDispatch>();
    const location = useLocation();
    const { request } = useApiRequest();
    const navigate = useNavigate();

    const roleChecker = UseKeycloakCheckRole();
    const isInterne = roleChecker('realm:interne');

    const mediaObjectDataSource: DataSource<MediaObject> = useSelector(MediaObjectSelector).main;

    const invoiceSelected: FactureAchat = useSelector(
        (s: RootStateType) => s.achats.factureachat.main.selected,
        _.isEqual,
    );
    const invoiceSelectedStatus: EuroprocApiResponseStatus = useSelector(
        (s: RootStateType) => s.achats.factureachat.main.selectedStatus,
        _.isEqual,
    );
    const publisherSelected: Fournisseur | undefined = useSelector(
        (s: RootStateType) => s.tiers.fournisseur.main.selected,
        _.isEqual,
    );
    const [moStatus, setMoStatus] = useState<EuroprocApiResponseStatus>('idle');

    useEffect(() => {
        if (factureachatid) {
            dispatch(selectFactureAchat({ id: +factureachatid }));
        }
    }, [dispatch, factureachatid]);

    /**
     * Si aucun fournisseur n'est sélectionné lorsque l'on a une facture d'achat,
     * alors on sélectionne le fournisseur lié à la facture d'achat.
     */
    useEffect(() => {
        if (
            invoiceSelected &&
            invoiceSelected.idEntiteFacturante &&
            (!publisherSelected || invoiceSelected.idEntiteFacturante !== publisherSelected.id)
        ) {
            dispatch(selectFournisseur({ id: +invoiceSelected.idEntiteFacturante }));
        }
    }, [dispatch, invoiceSelected, publisherSelected]);

    useEffect(() => {
        if (!mediaobjectid) {
            enqueueSnackbar(<Typography>Il n&apos;y a pas de PDF correspondant ! 💥</Typography>);
        } else if (moStatus !== 'succeeded') {
            const requestParameters: MediaObjectApiPatchMediaObjectItemRequest = {
                id: mediaobjectid,
                mediaObject: {
                    dateDebutTraitement: new Date().toISOString(),
                },
            };

            mediaObjectApi.patchMediaObjectItem(requestParameters);
            setMoStatus('succeeded');
        }
    }, [enqueueSnackbar, mediaobjectid, moStatus]);

    /**
     * Select MediaObject corresponding url param mediaobjectid.
     *
     * Should select MediaObject on mount.
     */
    useEffect(() => {
        if (mediaobjectid) {
            dispatch(selectMediaObject({ id: +mediaobjectid }));
        } else {
            enqueueSnackbar(
                `Le PDF n'est peut-être pas le bon, car son identifiant n'a pas été trouvé dans l'url. ${refreshPage} ${contactSupport}`,
                {
                    variant: 'warning',
                },
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // const mediaObject = ;
    const mediaObject = useMemo(
        () => mediaObjectDataSource.selected,
        [mediaObjectDataSource.selected],
    );

    /**
     * Si le PDF a un idSociete,
     * alors le définir comme xIdSociete de l'utilisateur
     */
    useEffect(() => {
        if (mediaObject?.idSociete) {
            dispatch(setXIdSociete(mediaObject?.idSociete as CustomizerReducerType['xIdSociete']));
        }
    }, [dispatch, mediaObject?.idSociete]);

    useEffect(
        () => () => {
            dispatch({
                type: `${FOURNISSEUR_SLICE_NAME}/deletemainSelected`,
            });
        },
        [dispatch],
    );

    const synchroG3Facture = useCallback(
        async (factureAchat: FactureAchat) => {
            if (!factureAchat.id) {
                enqueueSnackbar(
                    <Typography>
                        Erreur technique la facture n&apos;a pas d&apos;identifiant
                    </Typography>,
                    { variant: 'warning' },
                );
                return;
            }
            try {
                const requestParameters: FactureAchatApiSyncG3FactureAchatFactureAchatItemRequest =
                    {
                        xIdSociete,
                        id: `${factureAchat.id}`,
                        factureAchatJsonldFactureAchatSyncG3: { syncG3: true, forced: true },
                    };

                const res =
                    await factureAchatApi.syncG3FactureAchatFactureAchatItem(requestParameters);

                if (res.status >= 200 && res.status < 300) {
                    enqueueSnackbar(<Typography>Synchronisation effectuée</Typography>, {
                        variant: 'success',
                    });
                } else {
                    throw new Error(`Erreur ${res.status}`);
                }
            } catch (error) {
                if (
                    error instanceof AxiosError &&
                    error.response &&
                    error.response.data.exception &&
                    error.response.data.exception.message
                ) {
                    enqueueSnackbar(
                        <Typography>{error.response.data.exception.message}</Typography>,
                        {
                            variant: 'error',
                        },
                    );
                } else if (error instanceof Error) {
                    enqueueSnackbar(<Typography>{error.message}</Typography>, {
                        variant: 'error',
                    });
                }
            }
        },
        [enqueueSnackbar, xIdSociete],
    );

    const exitFormAfterDelete = useCallback(async () => {
        let response: GenerateB2DPathResponseType = {
            status: 'STAY',
        };

        if (
            location.pathname.match(/\/achats\/liste\/modifier/) ||
            location.pathname.match(/\/achats\/liste\/recap/)
        )
            response = generateB2DPath('listfacture');

        if (response.status === 'OK') {
            navigate(response.path);
        }
    }, [location.pathname, navigate]);

    const onDelete = useCallback(
        async (factureAchatToDelete: FactureAchat, isMediaObjectDelete?: boolean) => {
            if (!factureAchatToDelete.id) return;

            let mediaObjectId: string | null = null;

            if (factureAchatToDelete.pdfFacture && factureAchatToDelete.pdfFacture.id) {
                mediaObjectId = String(factureAchatToDelete.pdfFacture.id);
            }

            const successCallback = () => {
                // Pour l'instant ne s'applique qu'à la liste des factures en pause.
                // Plus tard, si on ajoute d'autres listes ce dispatch devra peut-être changer.

                exitFormAfterDelete();

                if (isMediaObjectDelete && mediaObjectId) {
                    const requestParameters: MediaObjectApiPatchMediaObjectItemRequest = {
                        id: mediaObjectId,
                        mediaObject: {
                            deleted: true,
                        },
                    };

                    request(mediaObjectApi.patchMediaObjectItem(requestParameters), {
                        withToaster: true,
                    });
                }
            };

            const requestParameters: FactureAchatApiDeleteFactureAchatFactureAchatItemRequest = {
                id: String(factureAchatToDelete.id),
                xIdSociete,
            };

            request(factureAchatApi.deleteFactureAchatFactureAchatItem(requestParameters), {
                withToaster: true,
                successCallback,
            });
        },
        [exitFormAfterDelete, request, xIdSociete],
    );

    const sendMailModalCallback = useCallback(() => {
        if (mediaObject) {
            const paramsToSend = {
                invoiceId: String(mediaObject.id),
                invoiceUrl: window.location.href,
            };

            modalActions.call(
                <WorkflowMailSenderModal
                    paramsToSend={paramsToSend}
                    mediaObject={mediaObject}
                />,
            );
        } else {
            enqueueSnackbar('PDF non trouvé', {
                variant: 'error',
            });
        }
    }, [enqueueSnackbar, mediaObject, modalActions]);

    const contextActions: ContextActionType[] = useMemo(
        () => [
            {
                name: 'Refus de traitement',
                icon: (
                    <FaOptionIcon
                        icon={faFileInvoice}
                        option={faXmark}
                        size="1x"
                        color="blueGrey.main"
                        optionColor="blueGrey.dark"
                        iconProps={{
                            radius: 100,
                        }}
                    />
                ),
                action: () => {
                    sendMailModalCallback();
                },
            },
            ...(isInterne
                ? [
                      {
                          name: 'Synchro G3',
                          icon: (
                              <FaOptionIcon
                                  icon={faServer}
                                  option={faRotate}
                                  size="1x"
                                  color="blueGrey.main"
                                  optionColor="blueGrey.dark"
                                  iconProps={{
                                      radius: 100,
                                  }}
                              />
                          ),
                          action: () => {
                              synchroG3Facture(invoiceSelected);
                          },
                      },
                      {
                          name: 'Supprimer la facture',
                          icon: (
                              <FontAwesomeIcon
                                  icon={faTrashCan}
                                  size="xl"
                              />
                          ),
                          action: () => {
                              modalActions.call(
                                  <ConfirmationModalContent
                                      key={`${invoiceSelected?.['@id']}-${removeFactureModalMessages}`}
                                      messages={removeFactureModalMessages}
                                      actionOnValidation={() => {
                                          onDelete(invoiceSelected, true);
                                      }}
                                      actionOnAlternative={() => {
                                          onDelete(invoiceSelected, false);
                                      }}
                                  />,
                              );
                          },
                          resetHash: filtersHasher(invoiceSelected),
                      },
                  ]
                : []),
        ],
        [
            invoiceSelected,
            isInterne,
            modalActions,
            onDelete,
            sendMailModalCallback,
            synchroG3Facture,
        ],
    );

    /**
     * Utility function to replace params from paths constants from paths.ts
     */
    const buildPath = useCallback(
        (
            pathTemplate: string,
            params: {
                [key: string]: string | undefined;
            },
        ) => pathTemplate.replace(/:([a-zA-Z]+)/g, (__, key) => params[key] || ''),
        [],
    );

    /**
     * ! This is a temporary use case !
     *
     * This condition is needed for now, until the AchatsRouter organization is correctly organized.
     * (In multiple folio use case, register and update children are shuffled.)
     */
    const shouldDisplaySeparatedHeader = useMemo(() => {
        const fullPath = buildPath(saisirEditDossierMultiFacturePath, {
            factureachatid,
            dossiernum,
        });

        return location.pathname.includes(fullPath);
    }, [buildPath, dossiernum, factureachatid, location.pathname]);

    useEffect(() => {
        defineContextActions(contextActions);
    }, [contextActions, defineContextActions]);

    useEffect(() => () => resetContextActions(), [resetContextActions]);

    const readyToDisplayEverything = useMemo<boolean>(
        () =>
            !!(
                mediaobjectid &&
                !!(mediaObject && mediaObjectDataSource.selectedStatus !== 'loading')
            ),
        [mediaobjectid, mediaObject, mediaObjectDataSource.selectedStatus],
    );

    const readyToDisplayForm = useMemo<boolean>(() => {
        if (!factureachatid) return true;

        if (invoiceSelectedStatus !== 'loading' && invoiceSelectedStatus !== 'succeeded') {
            // console.log('not ready loading status', invoiceSelectedStatus);
            return false;
        }

        if (!invoiceSelected) {
            // console.log('not ready no facture', invoiceSelected);
            return false;
        }

        return true;
    }, [factureachatid, invoiceSelected, invoiceSelectedStatus]);

    const readyToDisplayDataCard = useMemo<boolean>(() => {
        if (!factureachatid) return true;

        return !!invoiceSelected;
    }, [factureachatid, invoiceSelected]);

    const { loading: globalLoading } = useLoadingStatus({
        checkReady: () => readyToDisplayEverything,
    });
    const { loading: dataCardLoading } = useLoadingStatus({
        checkReady: () => readyToDisplayDataCard,
    });
    const { loading: formLoading } = useLoadingStatus({ checkReady: () => readyToDisplayForm });

    return (
        <SkeletonLoader
            isLoading={globalLoading}
            type="SplitView"
        >
            <Grid container>
                <Grid
                    item
                    lg={6}
                    sm={12}
                >
                    <MediaObjectReader
                        mediaObject={mediaObject}
                        sx={{
                            height: `calc(100vh -${footerHeight} - ${TopbarHeight}  )`,
                        }}
                    />
                </Grid>
                <Grid
                    item
                    lg={6}
                    sm={12}
                >
                    {shouldDisplaySeparatedHeader ? (
                        <Card
                            sx={{
                                gap: '15px',
                                padding: '15px',
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <SkeletonLoader
                                isLoading={dataCardLoading}
                                type="DataList"
                            >
                                <EnteteFacture
                                    invoice={invoiceSelected}
                                    publisher={publisherSelected}
                                />
                            </SkeletonLoader>
                        </Card>
                    ) : null}
                    <Card>
                        <SkeletonLoader
                            isLoading={formLoading}
                            type="Form"
                        >
                            <Outlet context={{ mediaObject }} />
                        </SkeletonLoader>
                    </Card>
                </Grid>
            </Grid>
        </SkeletonLoader>
    );
};

export default RegisterMultiInvoiceView;
