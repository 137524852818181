/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { AxiosResponse } from 'axios';
import { useDispatch, useSelector } from 'react-redux';

import {
    MediaObjectApiPostMediaObjectCollectionRequest,
    MediaObjectJsonldMediaObjectReadTimestampableBlameambleGroupTaggableGroup,
} from '@europrocurement/l2d-domain/openApi/ApiAchats';

import { UseKeycloakCheckRole } from '@europrocurement/l2d-keycloak';
import {
    mediaObjectApi,
    getMediaObject,
    AppDispatch,
    customizerSelector,
} from '../../redux/RootStore';
import { FileUploadItemProps } from './ImportToast';
import { FileUpload, FileUploadProps } from './components/FileUpload';

type UploaderProps = {
    onPending: (filesUploading: FileUploadItemProps) => void;
    onUploaded: (filesUploading: FileUploadItemProps) => void;
};

const Uploader = function ({ onPending, onUploaded }: UploaderProps) {
    const dispatch = useDispatch<AppDispatch>();
    const { xIdSociete } = useSelector(customizerSelector);

    const roleChecker = UseKeycloakCheckRole();
    const isInterne = roleChecker('realm:interne');

    const postMediaObject = async (file: File) => {
        let res: AxiosResponse<
            MediaObjectJsonldMediaObjectReadTimestampableBlameambleGroupTaggableGroup,
            any
        > | null = null;

        try {
            const requestParameters: MediaObjectApiPostMediaObjectCollectionRequest = {
                idSociete: xIdSociete,
                forceInterne: isInterne,
                file,
            };

            res = await mediaObjectApi.postMediaObjectCollection(requestParameters);

            if (res.status >= 200 && res.status < 300) {
                // enqueueSnackbar(<Typography>Envoi du fichier avec succès ! 🚀</Typography>);
                dispatch(getMediaObject({}));
            } else {
                throw new Error(`Erreur de communication api, statuscode : ${res.status}`);
            }
        } catch (error) {
            console.error(error);
            // let message: string = '';
            // if (typeof error === 'string') {
            //     message = error;
            // } else if (error instanceof Error) {
            //     message = error.message;
            // }
            // enqueueSnackbar(
            //     <Typography>
            //         Envoi du fichier raté ! 🌠
            //         <br />
            //         {message}
            //     </Typography>,
            //     {
            //         variant: 'error',
            //     },
            // );
        }

        return res;
    };

    const fileProcessing = (file: File) =>
        new Promise((resolve, reject) => {
            const currentFileUploading: FileUploadItemProps = {
                file,
                status: 'pending',
            };

            onPending(currentFileUploading);

            postMediaObject(file).then(
                (response) => {
                    if (!response) return;

                    currentFileUploading.status = 'success';
                    currentFileUploading.mediaObjectId = response.data.id;

                    onUploaded(currentFileUploading);
                    resolve('ok');
                },
                (reason) => {
                    if (!reason) return;

                    currentFileUploading.status = 'error';

                    onUploaded(currentFileUploading);
                    reject();
                },
            );
        });

    const handlePosts = (files: FileList) => {
        // const tempFilesUploading: Array<FileUploadItemProps> = [];

        const promises = [];

        Object.values(files).forEach((file) => promises.push(fileProcessing(file)));
    };

    const fileUploadProp: FileUploadProps = {
        accept: '.pdf',
        onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.target.files !== null && event.target.files?.length > 0) {
                handlePosts(event.target.files);
            }
        },
        onDrop: (event: React.DragEvent<HTMLElement>) => {
            handlePosts(event.dataTransfer.files);
        },
    };

    return <FileUpload {...fileUploadProp} />;
};

export default Uploader;
