/* istanbul ignore file */
/**
 * Icon configuration file :
 *  - Pick FontAwesomePro duotone variant in priority for design consistency
 *  - Centralization : this file must be the single source for application icons
 */
import {
    faAdd,
    faBracketsCurly,
    faCalculator,
    faCircle1,
    faCircle2,
    faCircle3,
    faEdit,
    faEye,
    faTrash,
    faEuro,
    faFileInvoice,
    faFolderOpen,
    faFilePdf,
    faGauge,
    faMailForward,
    faReceipt,
    faFileInvoiceDollar,
    faFileLines,
} from '@fortawesome/pro-duotone-svg-icons';
import { faCircleInfo, faTag } from '@fortawesome/pro-regular-svg-icons';

import { faFileInvoice as faFileInvoiceSolid, faMailbox } from '@fortawesome/pro-solid-svg-icons';

const ICONS = {
    /** Application general actions icons */
    actions: {
        help: faCircleInfo, // Utilisé dans le portail Achat

        create: faAdd,
        update: faEdit,
        delete: faTrash,
        preview: faEye,
    },
    /** Model related icons */
    models: {
        purchase: faFileInvoice,
        folio: faFolderOpen,
        statement: faFolderOpen,
        media_object: faFilePdf,
        stats: faGauge,
        mail: faMailForward,
        sell: faFileInvoiceDollar,
        quote: faFileLines,
        quote_line: faReceipt,
    },
    /** Optional icons */
    options: {
        statement: faFileInvoiceSolid,
        mail: faMailbox,
    },

    /** Various icons */
    miscellaneous: {
        cegidConfiguration: faCalculator,
        one: faCircle1,
        two: faCircle2,
        three: faCircle3,
        json: faBracketsCurly,
        label: faTag,
        euro: faEuro,
    },
} as const;

export default ICONS;
