/* eslint-disable @typescript-eslint/no-unused-vars */

import {
    SliceFactory,
    FetchCollectionData,
    FetchItemDataType,
    ApiCollectionResponse,
} from '@europrocurement/l2d-redux-utils';
import {
    ConfigurationParameters as CommandesConfigurationParameters,
    Configuration,
    CommandeCommandeRead,
    CommandeApi,
    CommandeApiGetCommandeCollectionRequest,
} from '../../../openApi/ApiDossiers';
import { DOSSIERS_REDUCER_NAME } from '../constants';

export type CommandeApiObject = CommandeCommandeRead & Record<string, unknown>;

export type Commande = CommandeApiObject;

export const COMMANDES_SLICE_NAME = 'commande';

const mapperCommande = function (commande: CommandeApiObject) {
    return {
        ...commande,
    } as Commande;
};

export function createCommandeSlice(configuration: CommandesConfigurationParameters) {
    const conf = new Configuration(configuration);
    const api = new CommandeApi(conf);

    const fetchCommandesCollection: FetchCollectionData<CommandeApiObject> = ({
        search,
        pagination,
        filters,
        orders,
        xIdSociete,
        rootstate,
    }) => {
        const requestParameters: CommandeApiGetCommandeCollectionRequest = {
            xIdSociete,
            page: pagination.page + 1,
        };

        return api.getCommandeCollection(requestParameters).then((res) => res.data) as Promise<
            ApiCollectionResponse<CommandeApiObject>
        >;
    };

    const fetchCommandesItem: FetchItemDataType<CommandeApiObject> = ({
        idItem,
        search,
        pagination,
        filters,
        orders,
        xIdSociete,
        rootstate,
    }) =>
        api
            .getCommandeItem({ id: String(idItem), xIdSociete })
            .then((res) => res.data) as Promise<CommandeApiObject>;

    const { slice: commandeSlice, dataSourcesThunks: commandeDataSourcesThunks } =
        SliceFactory.createSlice<CommandeApiObject, Commande>(
            COMMANDES_SLICE_NAME,
            DOSSIERS_REDUCER_NAME,
            [],
            fetchCommandesCollection,
            fetchCommandesItem,
            mapperCommande,
        );

    return { commandeSlice, commandeDataSourcesThunks };
}
