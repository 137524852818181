import { FaOptionIcon } from '@europrocurement/l2d-icons';
import { faCheck } from '@fortawesome/pro-duotone-svg-icons';
import { Typography } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import React, { CSSProperties, FunctionComponent, useCallback, useState } from 'react';

export type TreeItemProps = {
    label: string;
    value: string | number;
    isChecked?: boolean;
    isDisabled?: boolean;
    onChange?: (value: TreeItemProps['value'], checked: boolean) => void;
};

const TreeItem: FunctionComponent<TreeItemProps> = function (props) {
    const { label, value, isChecked = false, isDisabled = false, onChange = () => {} } = props;
    const theme = useTheme();

    const [checked, setChecked] = useState(isChecked);
    const changecheck = useCallback(() => {
        setChecked(!checked);
        onChange(value, !checked);
    }, [checked, onChange, value]);

    let cssChecked: CSSProperties = {};
    const cssCheckedBox: CSSProperties = {
        position: 'absolute',
        top: '5px',
        right: '5px',
    };
    let cssDisabled: CSSProperties = {};

    if (checked) {
        cssChecked = {
            color: theme.palette.primary.dark,
        };
    }
    if (isDisabled) {
        cssDisabled = {
            color: theme.palette.grey[300],
            cursor: 'not-allowed',
        };
        if (checked) {
            cssChecked = {
                color: theme.palette.primary.dark,
            };
        }
    }

    return (
        <Box
            style={{
                width: '100%',
                display: 'flex',
                gap: 1,
                cursor: 'pointer',
                position: 'relative',
                alignItems: 'center',
                // ...flexDirection,
                ...cssChecked,
                ...cssDisabled,
            }}
            data-testid="test-id-treeItem-cursor"
            onClick={() => {
                if (!isDisabled) {
                    changecheck();
                }
            }}
        >
            <Typography component="span">{label}</Typography>
            {checked ? (
                <Box
                    style={{
                        ...cssCheckedBox,
                    }}
                    data-testid="test-id-treeItem-checked"
                >
                    <FaOptionIcon icon={faCheck} />
                </Box>
            ) : null}
        </Box>
    );
};

export default TreeItem;
