import { useState, useCallback } from 'react';

export interface AsyncStatus {
    loading: boolean;
    error: string | null;
    success: boolean;
}

const useAsyncStatus = () => {
    const [status, setStatus] = useState<AsyncStatus>({
        loading: false,
        error: null,
        success: false,
    });

    const startLoading = useCallback(() => {
        setStatus({ loading: true, error: null, success: false });
    }, []);

    const setSuccess = useCallback(() => {
        setStatus({ loading: false, error: null, success: true });
    }, []);

    const setError = useCallback((error: string) => {
        setStatus({ loading: false, error, success: false });
    }, []);

    return {
        status,
        startLoading,
        setSuccess,
        setError,
    };
};

export default useAsyncStatus;
