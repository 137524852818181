import { RootStateType } from '@b2d/redux/RootStore';
import { CatalogueCatalogueRead } from '@europrocurement/l2d-domain/openApi/ApiOffre';
import { CATALOGS_SLICE_NAME, OFFRE_REDUCER_NAME } from '@europrocurement/l2d-domain';
import { catalogIcon } from '@europrocurement/l2d-icons';
import { catalogsApi } from '@b2d/redux/subReducers/Offers';
import { catalogsDataSourcesThunks } from '@b2d/redux/subReducers/Offers/reducers';
import { ModelConfiguration, OfferModelItem, ModelMethods } from './types';

const modelKey = 'catalog';
const modelName = 'Catalog';
const sliceName = CATALOGS_SLICE_NAME;
const api = catalogsApi;
const baseSelector = (state: RootStateType) => state[OFFRE_REDUCER_NAME][sliceName];
const datasourcesThunks = catalogsDataSourcesThunks;

type ModelStructure = CatalogueCatalogueRead;

const configuration: ModelConfiguration = {
    key: modelKey,
    name: modelName,
    type: 'Catalogue',
    selector: baseSelector,
    sliceName,
    api,
    datasourcesThunks,
    icon: catalogIcon,
};

const methods: ModelMethods<ModelStructure> = {
    getItemDefaultLabel: (model: ModelStructure) => `${model.nomCommercial}`,
};

const endpoints = {
    list: (...args: Parameters<typeof api.apiCataloguesGetCollection>) =>
        api.apiCataloguesGetCollection(...args),
    listWaitingAction: (
        ...args: Parameters<typeof api.apiCataloguesIdActionsAEffectuerGetCollection>
    ) => api.apiCataloguesIdActionsAEffectuerGetCollection(...args),
    delete: (...args: Parameters<typeof api.apiCataloguesIdDelete>) =>
        api.apiCataloguesIdDelete(...args),
    dependencies: (...args: Parameters<typeof api.apiCataloguesIdDependenciesGet>) =>
        api.apiCataloguesIdDependenciesGet(...args),
    listRequiredDocuments: (
        ...args: Parameters<typeof api.apiCataloguesIdDocumentsAFournirGetCollection>
    ) => api.apiCataloguesIdDocumentsAFournirGetCollection(...args),
    listByPrescripteur: (...args: Parameters<typeof api.apiPrescripteursCataloguesGetCollection>) =>
        api.apiPrescripteursCataloguesGetCollection(...args),
    read: (...args: Parameters<typeof api.apiCataloguesIdGet>) => api.apiCataloguesIdGet(...args),
    update: (...args: Parameters<typeof api.apiCataloguesIdPut>) => api.apiCataloguesIdPut(...args),
    create: (...args: Parameters<typeof api.apiCataloguesPost>) => api.apiCataloguesPost(...args),
};

type ModelType = OfferModelItem<ModelStructure> & typeof endpoints;

const model: ModelType = {
    ...configuration,
    ...methods,
    ...endpoints,
};

export type CatalogModel = ModelType;
export default model;
