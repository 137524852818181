import { ConfigurationParameters as FactureFormaliteConfigurationParameters } from '../../openApi/ApiFormalite';
import { createDevisLigneSlice } from './slices/devisLigneSlice';
import { createDevisSlice } from './slices/devisSlice';
import { createFactureFormaliteSlice } from './slices/factureFormaliteSlice';

export function createFactureFormaliteReducer(
    configuration: FactureFormaliteConfigurationParameters,
) {
    const { factureFormaliteDataSourcesThunks, factureFormaliteSlice } =
        createFactureFormaliteSlice(configuration);
    return { factureFormaliteDataSourcesThunks, factureFormaliteSlice };
}

export function createDevisReducer(configuration: FactureFormaliteConfigurationParameters) {
    const { devisDataSourcesThunks, devisSlice } = createDevisSlice(configuration);
    return { devisDataSourcesThunks, devisSlice };
}

export function createDevisLigneReducer(configuration: FactureFormaliteConfigurationParameters) {
    const { devisLigneDataSourcesThunks, devisLigneSlice } = createDevisLigneSlice(configuration);
    return { devisLigneDataSourcesThunks, devisLigneSlice };
}
