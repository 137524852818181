import React, { useEffect, useState } from 'react';
import { Box, Card, CircularProgress, Grid } from '@mui/material';
import { Formalite } from '@europrocurement/l2d-domain';
import {
    DatatablePagination,
    FlexyDatatable,
    FlexyDatatableProps,
} from '@europrocurement/flexy-datatable';
import { useNavigate } from 'react-router';
import useApiRequest from '@b2d/hooks/useApiRequest';
import NoResultPreview from '../../components/NoResultPreview';
import model from '../../models/formality';
import TableRowActions from '../../components/TableRowActions';

type FormalityIncompatibilitiesCardProps = {
    formalityId: number;
    totalItemsCount: number;
    onRemoveIncompatibility: (incompatibilityId: string | number) => void;
};

const FormalityIncompatibilitiesCard: React.FunctionComponent<FormalityIncompatibilitiesCardProps> =
    function (props) {
        const { formalityId, onRemoveIncompatibility } = props;
        const navigate = useNavigate();
        const { request, requestState } = useApiRequest();
        const [incompatibilities, setIncompatibilities] = useState([]);
        const [page, setCurrentPage] = useState(0);
        const [itemsPerPage, setItemsPerPage] = useState(10);
        const [totalItemsCount, setTotalItemsCount] = useState(0);
        const pagination: DatatablePagination = {
            page,
            itemsPerPage,
            total: totalItemsCount,
            onPageChange: (newValue) => {
                setCurrentPage(newValue);
            },
            onItemsPerPageChange: (newValue) => {
                setCurrentPage(0);
                setItemsPerPage(newValue);
            },
        };

        const refreshList = () => {
            const query = {
                page: page + 1,
                itemsPerPage,
                incompatibleWith: formalityId,
                deleted: false,
            };
            request(model.list(query), {
                successCallback: (response) => {
                    setIncompatibilities(response.data['hydra:member']);
                    setTotalItemsCount(response.data['hydra:totalItems'] as number);
                },
                withToaster: false,
            });
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
        useEffect(refreshList, [formalityId, page, itemsPerPage]);

        if (requestState.isProcessing) {
            return (
                <Grid
                    container
                    justifyContent="center"
                    sx={{ marginTop: '25px' }}
                >
                    <CircularProgress />
                </Grid>
            );
        }

        if (!incompatibilities.length) {
            return (
                <Card>
                    <NoResultPreview message="Cette formalité ne comporte aucune incompatibilité" />
                </Card>
            );
        }

        const celStyle = {
            display: 'flex',
            alignItems: 'center',
            minHeight: '40px',
        };
        const columns = [
            {
                label: 'Nom',
                field: 'libelle',
                isDisplayed: true,
                render: (item: Formalite) => <Box style={celStyle}>{item.libelle}</Box>,
            },
            {
                label: 'Catégorie',
                field: 'categorie',
                isDisplayed: true,
                render: (item: Formalite) => <Box style={celStyle}>{item?.categorie?.libelle}</Box>,
            },
            {
                label: 'Actions',
                displayLabel: false,
                isDisplayed: true,
                render: (item: Formalite) => (
                    <TableRowActions
                        itemId={item.id as unknown as string}
                        removeItem={() => {
                            onRemoveIncompatibility(item?.id as unknown as string);
                        }}
                    />
                ),
            },
        ];

        const datatableProps: FlexyDatatableProps = {
            status: 'succeeded',
            data: incompatibilities,
            hideColumnOptions: true,
            pagination,
            columns,
            onClickRow: (_: React.MouseEvent<HTMLElement>, { id }: Formalite) =>
                navigate(`/formalites/liste/${id}`),
        };

        return (
            <Card>
                <FlexyDatatable {...datatableProps} />
            </Card>
        );
    };

export default FormalityIncompatibilitiesCard;
