import { AutocompleteStructureReadonlyProps } from '@b2d/pages/Achats/components/forms/formElements/types';
import { FormStructure } from '@europrocurement/flexy-form';

/**
 * Generates a read-only text input structure for an autocomplete field in a form.
 *
 * This function creates a form structure object suitable for displaying a read-only
 * autocomplete field, typically used to show selected or predefined values without
 * allowing user input.
 *
 * @param overwriteProps - Partial properties to override the default form structure.
 *
 * @returns A FormStructure object representing a read-only text input configuration.
 */
const autocompleteStructureReadonly = ({
    name,
    inputlabel,
    overwriteProps,
}: AutocompleteStructureReadonlyProps): FormStructure => ({
    type: 'text',
    name,
    inputlabel,
    disabled: true,
    xs: 12,
    sm: 12,
    md: 12,
    lg: 6,
    ...overwriteProps,
});

export { autocompleteStructureReadonly };
