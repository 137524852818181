import useApiRequest from '@b2d/hooks/useApiRequest';
import { useEffect, useState } from 'react';
import {
    Catalogs,
    mapperCatalogs,
} from '@europrocurement/l2d-domain/reducers/offre/slices/catalogSlice';
import model from '../../Offres/models/catalog';

const FORMALITY_DOMAIN_ID = 3;
// const PRESCRIPTEUR_ID = 1234;
const ORIGINE_ID = 13;
export type CataloguePrescripteurType = {
    prescripteur: number;
    nomCommercial?: string;
    origine?: number;
    domaine?: number;
};

const useCataloguePrescripteur = ({
    prescripteur,
    nomCommercial,
    origine = ORIGINE_ID,
    domaine = FORMALITY_DOMAIN_ID,
}: CataloguePrescripteurType) => {
    console.log(
        'prescripteur ',
        prescripteur,
        'origine ',
        origine,
        'domaine ',
        domaine,
        'nomCommercial ',
        nomCommercial,
    );
    const { request, requestState } = useApiRequest();
    const [catalogs, setCatalogs] = useState<Catalogs[]>([]);

    const [domaineCatalogue, setDomaineCatalogue] = useState(domaine);
    const [prescripteurCatalogue, setPrescripteurCatalogue] = useState(prescripteur);
    const [origineCatalogue, setOrigineCatalogue] = useState(origine);
    const [nomCommercialCatalogue, setNomCommercialCatalogue] = useState(nomCommercial);

    // fetchByPrescripteur fetchCatalogsCollectionByPrescripteur
    const loadCatalogs = () => {
        const query = {
            page: 1,
            itemsPerPage: 1000,
            domaine: domaineCatalogue,
            prescripteur: prescripteurCatalogue,
            origine: origineCatalogue,
            nomCommercial: nomCommercialCatalogue,
        };

        request(model.listByPrescripteur(query), {
            successCallback: (response) => {
                const items = response.data['hydra:member'].map(mapperCatalogs);
                // console.log('items');
                setCatalogs(items);
            },
            withToaster: false,
        });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(loadCatalogs, [
        domaineCatalogue,
        prescripteurCatalogue,
        origineCatalogue,
        nomCommercialCatalogue,
    ]);

    return {
        catalogs,
        setDomaineCatalogue,
        setPrescripteurCatalogue,
        setOrigineCatalogue,
        setNomCommercialCatalogue,
        requestState,
    };
};

export default useCataloguePrescripteur;
