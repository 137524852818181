import * as React from 'react';
import { CircularProgress } from '@mui/material';
import { usePrescriberUtils } from '@b2d/hooks/prescriberHooks';
import useExistingPrescribers from '../hooks/useExistingsPrescribers';
import ExistingPrescriberBadge from './ExistingPrescriberBadge';
import ExistingPrescribersModal from './ExistingPrescribersModal';

const ExistingPrescribersInformation: React.FunctionComponent = function () {
    // Hooks

    const { prescriber } = usePrescriberUtils();

    const { prescribersRelatedToSiret, getExistingPrescribers, prescribersLoading } =
        useExistingPrescribers(prescriber.siret);

    // modal / popin / logic / state
    const modalInitialState = {
        isOpen: false,
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [modalStatus, setModalStatus] = React.useState<any>(modalInitialState);
    const onModalClose = () => setModalStatus({ isOpen: false });

    const getPrescribersRelatedToSiret = () =>
        prescribersRelatedToSiret?.filter(({ id }) => id !== prescriber.id);

    const prescriberList = getPrescribersRelatedToSiret();

    React.useEffect(() => {
        getExistingPrescribers();
    }, [getExistingPrescribers]);

    const canRender = prescriberList && prescriberList?.length > 0;

    if (!canRender) {
        return null;
    }

    if (prescribersLoading) {
        return <CircularProgress />;
    }

    return (
        <>
            <ExistingPrescriberBadge
                onClick={() => setModalStatus({ isOpen: true })}
                numberOfPrescribers={prescriberList.length}
            />
            <ExistingPrescribersModal
                existingPrescribers={prescriberList}
                modalStatus={modalStatus}
                onModalClose={onModalClose}
            />
        </>
    );
};

export default ExistingPrescribersInformation;
