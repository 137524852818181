import React, { useCallback } from 'react';

import { faFileInvoice } from '@fortawesome/pro-duotone-svg-icons';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { ContextActionType, useModal } from '@europrocurement/flexy-components';
import { FaOptionIcon } from '@europrocurement/l2d-icons';
import { MediaObject } from '@europrocurement/l2d-domain';
import WorkflowMailSenderModal from '@b2d/pages/Achats/components/modals/WorkflowMailSenderModal';

export type TreatmentRefusalProps = {
    mediaObject: MediaObject;
};

const useTreatmentRefusal = ({ mediaObject }: TreatmentRefusalProps): ContextActionType => {
    const { modalActions } = useModal();

    const sendMailModalCallback = useCallback(() => {
        const paramsToSend = {
            invoiceId: String(mediaObject.id),
            invoiceUrl: window.location.href,
        };

        modalActions.call(
            <WorkflowMailSenderModal
                paramsToSend={paramsToSend}
                mediaObject={mediaObject}
            />,
        );
    }, [mediaObject, modalActions]);

    return {
        name: 'Refus de traitement',
        icon: (
            <FaOptionIcon
                icon={faFileInvoice}
                option={faXmark}
                size="1x"
                color="blueGrey.main"
                optionColor="blueGrey.dark"
                iconProps={{
                    radius: 100,
                }}
            />
        ),
        action: () => {
            sendMailModalCallback();
        },
    };
};

export default useTreatmentRefusal;
