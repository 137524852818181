import { faSquare, faCircle } from '@fortawesome/pro-solid-svg-icons';
import {
    faSquare as faSquareLight,
    faSquareCheck,
    faSquareMinus,
    faCircle as faCircleLight,
    faCircleDot,
} from '@fortawesome/pro-regular-svg-icons';
import { NamedIconsType } from '../../types/types';

export const checkboxCheckedIcon: NamedIconsType = {
    props: {
        icon: faSquare,
        option: faSquareCheck,
        optionProps: {
            style: {
                scale: '2',
                translate: '-43% -50%',
            },
        },
    },
    name: 'checkbox_checked',
    displayName: 'Checkbox cochée',
};

export const checkboxUncheckedIcon: NamedIconsType = {
    props: {
        icon: faSquare,
        option: faSquareLight,
        optionProps: {
            style: {
                scale: '2',
                translate: '-43% -50%',
            },
        },
    },
    name: 'checkbox_unchecked',
    displayName: 'Checkbox décochée',
};

export const checkboxIndeterminateIcon: NamedIconsType = {
    props: {
        icon: faSquare,
        option: faSquareMinus,
        optionProps: {
            style: {
                scale: '2',
                translate: '-43% -50%',
            },
        },
    },
    name: 'checkbox_indeterminate',
    displayName: 'Checkbox indéterminée',
};

export const radioCheckedIcon: NamedIconsType = {
    props: {
        icon: faCircle,
        option: faCircleDot,
        optionProps: {
            style: {
                scale: '2',
                translate: '-38% -50%',
            },
        },
    },
    name: 'radio_checked',
    displayName: 'Bouton radio coché',
};

export const radioUncheckedIcon: NamedIconsType = {
    props: {
        icon: faCircle,
        option: faCircleLight,
        optionProps: {
            style: {
                scale: '2',
                translate: '-38% -50%',
            },
        },
    },
    name: 'radio_unchecked',
    displayName: 'Bouton radio décoché',
};

export const formNamedIcons: NamedIconsType[] = [
    checkboxCheckedIcon,
    checkboxUncheckedIcon,
    checkboxIndeterminateIcon,
    radioCheckedIcon,
    radioUncheckedIcon,
];
