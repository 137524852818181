import React, { useMemo, useState } from 'react';
import { FlexyForm, FormStructure } from '@europrocurement/flexy-form';
import { IconName } from '@fortawesome/pro-duotone-svg-icons';
import { Container } from '@mui/system';
import { CenterCircularProgress, SelectItemBaseProps } from '@europrocurement/flexy-components';
import { useForm } from 'react-hook-form';

import useCataloguePrescripteur, {
    CataloguePrescripteurType,
} from '../../hooks/useCataloguePrescripteur';

const SelectionFormalityShow: React.FunctionComponent = function () {
    const [nomCommercialRecherche, setNomCommercialRecherche] = useState('');

    const [groupementsChecked, setGroupementsChecked] = useState();
    console.log(groupementsChecked);

    const rechercheCatalogue: CataloguePrescripteurType = useMemo(
        () => ({
            prescripteur: 1234,
            origine: 13,
            domaine: 3,
            nomCommercial: nomCommercialRecherche,
        }),
        [nomCommercialRecherche],
    );

    // const test = useMemo(() => {
    //     const { catalogs } = useCataloguePrescripteur(rechercheCatalogue);
    //     return catalogs;
    // }, [rechercheCatalogue]);

    const { catalogs, requestState } = useCataloguePrescripteur(rechercheCatalogue);

    const groups = catalogs
        .map((item) => item.groupement)
        .filter(
            (value, index, self) =>
                self.findIndex((obj) => JSON.stringify(obj) === JSON.stringify(value)) === index,
        );

    const groupItems = groups.reduce((res: SelectItemBaseProps[], group) => {
        if (
            group?.libelle !== undefined &&
            group?.id !== undefined &&
            group?.libelle !== null &&
            group?.id !== null
        ) {
            res.push({
                label: group.libelle,
                value: group.id,
                icone: group.iconeName as IconName,
                isChecked: false,
                isDisabled: false,
            });
            return res;
        }
        return [];
    }, []);

    const catalogItems = catalogs
        .filter((item) => groupementsChecked?.includes(item.groupement?.id))
        .reduce((res: SelectItemBaseProps[], catalog) => {
            if (
                catalog.nomCommercial !== undefined &&
                catalog.id !== undefined &&
                catalog.nomCommercial !== null &&
                catalog.id !== null
            ) {
                res.push({
                    label: catalog.nomCommercial,
                    value: catalog.id,
                    icone: catalog.iconeName as IconName,
                    isChecked: false,
                    isDisabled: false,
                    title: catalog.groupement?.libelle,
                });
                return res;
            }
            return [];
        }, []);

    const formContext = useForm({
        mode: 'onTouched',
    });
    formContext.watch(['recherche']);
    console.log('rechercheCatalogue', rechercheCatalogue);

    const formStructure: FormStructure[] = useMemo(
        () => [
            {
                type: 'text',
                name: 'recherche',
                placeholder: 'Rechercher une formalité',
                onChangeInput: (event: React.FormEvent<HTMLInputElement>) => {
                    const { value } = event.currentTarget;

                    setNomCommercialRecherche(value);
                },
                xs: 12,
                sm: 12,
                md: 12,
                lg: 3,
            },
            {
                type: 'treeItems',
                name: 'prestationrecherche',
                items: catalogItems,
                rules: {
                    required: 'vous devez selectionner une formalité',
                },
            },
            {
                type: 'selectItems',
                label: 'de formalités',
                name: 'groupementform',
                items: groupItems,
                showMore: true,
                rules: {
                    required: 'vous devez selectionner un groupement',
                },
                onChange: (newValue: Array<number>, actionMeta) => {
                    setGroupementsChecked(newValue);
                    // ici tu peux faire un setstate sur tes éléments,
                    // attention faut gerrer une liste de groupement, donc un tableau, donc faut adapter ton filter
                    // a mon avis tu va devoir passer des trucs en useMemo (  catalogItems et groupementschecked )

                    console.log('change selectItems', newValue, actionMeta);
                },
            },
            {
                type: 'selectItems',
                name: 'prestationform',
                items: catalogItems,
                mode: 'inline',
                showMore: false,
                withTitle: true,
                rules: {
                    required: 'vous devez selectionner une formalité',
                },
            },
        ],
        [catalogItems, groupItems],
    );

    return !groupItems || requestState.isProcessing ? (
        <CenterCircularProgress sx={{ height: '500px' }} />
    ) : (
        <Container maxWidth="md">
            <FlexyForm
                formContext={formContext}
                formObject={{}}
                formStructure={formStructure}
                onSubmit={(value) => console.warn(value)}
            />
        </Container>
    );
};

export default SelectionFormalityShow;
