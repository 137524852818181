import {
    APIENV,
    DEVIS_LIGNE_SLICE_NAME,
    DEVIS_SLICE_NAME,
    FACTURE_FORMALITE_SLICE_NAME,
    FactureFormaliteConfiguration,
    FactureFormaliteConfigurationParameters,
    createDevisLigneReducer,
    createDevisReducer,
    createFactureFormaliteReducer,
    getCustomiserState,
} from '@europrocurement/l2d-domain';
import { combineReducers } from '@reduxjs/toolkit';
import { FactureApi } from '@europrocurement/l2d-domain/openApi/ApiFacturation';
import { updateToken } from '../../../keycloak';

/**
 * Configuration
 */
const configFactureFormalites: FactureFormaliteConfigurationParameters = {
    accessToken: updateToken,
    basePath: APIENV.apis.formalite[getCustomiserState().api.formalite],
};

export const factureFormaliteApi = new FactureApi(
    new FactureFormaliteConfiguration(configFactureFormalites),
);

export const { factureFormaliteDataSourcesThunks, factureFormaliteSlice } =
    createFactureFormaliteReducer(configFactureFormalites);

export const { devisDataSourcesThunks, devisSlice } = createDevisReducer(configFactureFormalites);

export const { devisLigneDataSourcesThunks, devisLigneSlice } =
    createDevisLigneReducer(configFactureFormalites);

export const combinedFactureFormaliteReducer = combineReducers({
    [FACTURE_FORMALITE_SLICE_NAME]: factureFormaliteSlice.reducer,
    [DEVIS_SLICE_NAME]: devisSlice.reducer,
    [DEVIS_LIGNE_SLICE_NAME]: devisLigneSlice.reducer,
});

export const getFactureFormalite = factureFormaliteDataSourcesThunks.main.getData;
export const selectFactureFormalite = factureFormaliteDataSourcesThunks.main.getSelected;

export const getDevis = devisDataSourcesThunks.main.getData;
export const selectDevis = devisDataSourcesThunks.main.getSelected;

export const getDevisLigne = devisLigneDataSourcesThunks.main.getData;
export const selectDevisLigne = devisLigneDataSourcesThunks.main.getSelected;
