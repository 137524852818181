import React, { FunctionComponent, useState } from 'react';

import achatsViewPathResolver from '@b2d/pages/Achats/achatsViewPathResolver';
import { AppDispatch, customizerSelector, factureAchatApi } from '@b2d/redux/RootStore';
import { DataCard } from '@europrocurement/flexy-components';
import { FACTURES_ACHATS_SLICE_NAME } from '@europrocurement/l2d-domain';
import { FaOptionIcon } from '@europrocurement/l2d-icons';
import { faFileInvoice } from '@fortawesome/pro-duotone-svg-icons';
import { faSync } from '@fortawesome/pro-solid-svg-icons';
import { Button, Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

// interne

const FacturesASynchroniser: FunctionComponent = function () {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();

    const { xIdSociete } = useSelector(customizerSelector);

    const [countOfInvoicesToSynchronise, setCountOfInvoicesToSynchronise] = useState<string>('...');

    factureAchatApi
        .getFacturesAchatsFactureAchatCollection({
            xIdSociete,
            page: 1,
            itemsPerPage: 0,
            cloture: true,
            syncG3: false,
            deleted: false,
        })
        .then((res) => {
            if (
                res.data['hydra:totalItems'] !== null ||
                res.data['hydra:totalItems'] !== undefined
            ) {
                setCountOfInvoicesToSynchronise(`${res.data['hydra:totalItems']}`);
            }
        });

    return (
        <Grid
            sx={{
                flex: 1,
            }}
            item
            lg={1}
            md={1}
            xs={12}
        >
            <DataCard
                title="Factures à synchroniser"
                color="primary"
                iconBgColor="transparent"
                iconColor="transparent"
                icon={
                    <FaOptionIcon
                        icon={faFileInvoice}
                        option={faSync}
                        size="2x"
                        color="primary.main"
                        optionColor="primary.dark"
                    />
                }
                tools={
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => {
                            dispatch({
                                type: `${FACTURES_ACHATS_SLICE_NAME}/resetmainFilter`,
                            });

                            dispatch({
                                type: `${FACTURES_ACHATS_SLICE_NAME}/setmainFilter`,
                                payload: {
                                    key: 'syncG3',
                                    value: false,
                                },
                            });

                            navigate(achatsViewPathResolver('listfacture'));
                        }}
                    >
                        Consulter
                    </Button>
                }
            >
                <Typography
                    sx={{
                        fontSize: '18px',
                        textAlign: 'center',
                    }}
                >
                    Factures à synchroniser avec G3 :
                </Typography>
                <Typography
                    color="primary"
                    component="h2"
                    sx={{
                        fontSize: '32px',
                        textAlign: 'center',
                    }}
                >
                    <b>{countOfInvoicesToSynchronise}</b>
                </Typography>
            </DataCard>
        </Grid>
    );
};

export default FacturesASynchroniser;
