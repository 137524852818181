import React from 'react';
import { Button } from '@mui/material';
import { FaOptionIcon, NamedIconsType } from '@europrocurement/l2d-icons';

type ActionButtonProps = {
    label: string;
    onClick: () => void;
    icon: NamedIconsType;
};

const ActionButton: React.FunctionComponent<ActionButtonProps> = function (props) {
    const { label, onClick, icon } = props;
    const buttonStyle = {
        borderRadius: '15px',
        margin: '15px 15px 15px 0',
        height: '70px',
        width: 'calc(100% - 15px)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    };

    return (
        <Button
            variant="contained"
            sx={buttonStyle}
            onClick={onClick}
        >
            <FaOptionIcon
                {...icon.props}
                iconProps={{ style: { marginRight: '15px', fontSize: 35 } }}
            />
            {label}
        </Button>
    );
};

export default ActionButton;
