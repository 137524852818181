import { createContext } from 'react';

export type Base64File = { nom: string; base64: string };

export type FilesBase64Cache = Base64File | false | 'error';

export type UplaoderContextType = {
    files: File[];
    filesBase64: FilesBase64Cache[];
    base64Ready: boolean;
    addFile: (file: File) => void;
    addFiles: (file: File[] | FileList) => void;
    removeFile: (index: number) => void;
    replaceFile: (index: number, file: File) => void;
    clearFiles: () => void;
    resetFiles: (file: File[] | FileList) => void;
    getFilesBase64: () => FilesBase64Cache[];
};

export const UploaderContext = createContext<UplaoderContextType>({
    files: [],
    filesBase64: [],
    base64Ready: false,
    addFile: () => {},
    resetFiles: () => {},
    addFiles: () => {
        // console.log('Add files | UplaoderContext');
    },
    removeFile: () => {
        // console.log('removeFile | UplaoderContext');
    },
    replaceFile: () => {
        // console.log('replaceFile | UplaoderContext');
    },
    clearFiles: () => {},
    getFilesBase64: () => [],
});
