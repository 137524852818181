import { RootStateType } from '@b2d/redux/RootStore';
import {
    DOCUMENT_SLICE_NAME,
    documentTypes,
    OFFRE_REDUCER_NAME,
} from '@europrocurement/l2d-domain';
import { TypeDeDocumentTypedocumentUpdate } from '@europrocurement/l2d-domain/openApi/ApiOffre';
import { documentIcon } from '@europrocurement/l2d-icons';
import { documentsApi } from '@b2d/redux/subReducers/Offers';
import { documentsDataSourcesThunks } from '@b2d/redux/subReducers/Offers/reducers';
import { ModelConfiguration, OfferModelItem, ModelMethods } from './types';

const modelKey = 'document';
const modelName = 'Document';
const sliceName = DOCUMENT_SLICE_NAME;
const api = documentsApi;
const baseSelector = (state: RootStateType) => state[OFFRE_REDUCER_NAME][sliceName];
const datasourcesThunks = documentsDataSourcesThunks;

type ModelStructure = TypeDeDocumentTypedocumentUpdate;

const configuration: ModelConfiguration = {
    key: modelKey,
    name: modelName,
    type: 'Type de document',
    selector: baseSelector,
    sliceName,
    api,
    datasourcesThunks,
    icon: documentIcon,
};

const methods: ModelMethods<ModelStructure> = {
    getItemDefaultLabel: (model: ModelStructure) => `${model.libelle}`,
    getItemSecondaryLabel: (model: ModelStructure) =>
        `${documentTypes.find((option) => option.value === model.type)?.label}`,
};

const endpoints = {
    list: (...args: Parameters<typeof api.apiTypesDocumentsGetCollection>) =>
        api.apiTypesDocumentsGetCollection(...args),
    delete: (...args: Parameters<typeof api.apiTypesDocumentsIdDelete>) =>
        api.apiTypesDocumentsIdDelete(...args),
    get: (...args: Parameters<typeof api.apiTypesDocumentsIdGet>) =>
        api.apiTypesDocumentsIdGet(...args),
    update: (...args: Parameters<typeof api.apiTypesDocumentsIdPut>) =>
        api.apiTypesDocumentsIdPut(...args),
    create: (...args: Parameters<typeof api.apiTypesDocumentsPost>) =>
        api.apiTypesDocumentsPost(...args),
};

type ModelType = OfferModelItem<ModelStructure> & typeof endpoints;

const model: ModelType = {
    ...configuration,
    ...methods,
    ...endpoints,
};

export type DocumentModel = ModelType;
export default model;
