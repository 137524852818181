/* eslint-disable @typescript-eslint/no-unused-vars */

import {
    SliceFactory,
    FetchCollectionData,
    FetchItemDataType,
    ApiCollectionResponse,
} from '@europrocurement/l2d-redux-utils';
import {
    ConfigurationParameters as AchatsConfigurationParameters,
    Configuration,
    ReleveAchatApi,
    ReleveAchatApiGetRelevesAchatsReleveAchatCollectionRequest,
    ReleveAchatJsonldReleveAchatReadReleveAchatReadCalculatedItemsTimestampableBlameambleGroup,
} from '../../../openApi/ApiAchats';
import { ACHATS_REDUCER_NAME } from '../constants';
import { Order } from '../../../types/apiGenericTypes';

export type ReleveAchatApiObject =
    ReleveAchatJsonldReleveAchatReadReleveAchatReadCalculatedItemsTimestampableBlameambleGroup &
        Record<string, unknown>;

export type ReleveAchat = ReleveAchatApiObject;

export const INVOICE_STATEMENT_SLICE_NAME = 'statement';

export const INVOICE_STATEMENTS_UNCLOSED_DATASOURCE_NAME = 'unclosedStatements';

export const additionalInvoiceStatementsDataSources: Array<string> = [
    INVOICE_STATEMENTS_UNCLOSED_DATASOURCE_NAME,
];

const mapperReleveAchat = function (statement: ReleveAchatApiObject) {
    return {
        ...statement,
    } as ReleveAchat;
};

export function createReleveAchatSlice(configuration: AchatsConfigurationParameters) {
    const conf = new Configuration(configuration);
    const api = new ReleveAchatApi(conf);

    const fetchReleveAchatsCollection: FetchCollectionData<ReleveAchatApiObject> = ({
        search,
        pagination,
        filters,
        orders,
        xIdSociete,
        rootstate,
    }) => {
        let createdAtBefore: string | undefined;
        let createdAtAfter: string | undefined;
        let updatedBy: string | undefined;
        let cloture: boolean | undefined;
        let deleted: boolean | undefined;
        let orderId: Order;
        let orderUpdatedAt: Order;

        if (filters.createdAt) {
            [createdAtBefore, createdAtAfter] = filters.createdAt as string[];
        }

        if (filters.updatedBy) {
            updatedBy = filters.updatedBy as string;
        }

        if (filters.cloture || filters.cloture === false) {
            cloture = filters.cloture as boolean;
        }

        if (filters.deleted === true) {
            deleted = true;
        } else if (filters.deleted === false) {
            deleted = false;
        }

        orders.forEach((item) => {
            if (item.field === 'id') {
                orderId = item.value;
            }
            if (item.field === 'updatedAt') {
                orderUpdatedAt = item.value;
            }
        });

        const requestParameters: ReleveAchatApiGetRelevesAchatsReleveAchatCollectionRequest = {
            xIdSociete,
            page: pagination.page + 1,
            itemsPerPage: pagination.itemsPerPage,
            cloture,
            deleted,
            createdAtBefore,
            createdAtAfter,
            updatedBy,
            orderId,
            orderUpdatedAt,
        };

        return api
            .getRelevesAchatsReleveAchatCollection(requestParameters)
            .then((res) => res.data) as Promise<ApiCollectionResponse<ReleveAchatApiObject>>;
    };

    const fetchReleveAchatsItem: FetchItemDataType<ReleveAchatApiObject> = ({
        idItem,
        search,
        pagination,
        filters,
        orders,
        xIdSociete,
        rootstate,
    }) =>
        api
            .getReleveAchatReleveAchatItem({
                id: String(idItem),
                xIdSociete,
            })
            .then((res) => res.data) as Promise<ReleveAchatApiObject>;

    const { slice: invoiceStatementSlice, dataSourcesThunks: invoiceStatementDataSourcesThunks } =
        SliceFactory.createSlice<ReleveAchatApiObject, ReleveAchat>(
            INVOICE_STATEMENT_SLICE_NAME,
            ACHATS_REDUCER_NAME,
            additionalInvoiceStatementsDataSources,
            fetchReleveAchatsCollection,
            fetchReleveAchatsItem,
            mapperReleveAchat,
            {
                deleted: false,
            },
            undefined,
            [
                {
                    field: 'updatedAt',
                    value: 'desc',
                },
            ],
        );

    return { invoiceStatementSlice, invoiceStatementDataSourcesThunks };
}
