import { faEye, faEyeSlash } from '@fortawesome/pro-duotone-svg-icons';
import { FlexyFormLabel, FlexyTextField } from '@europrocurement/flexy-components';
import { FaOptionIcon } from '@europrocurement/l2d-icons';
import { IconButton, InputAdornment, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react';

export type PasswordFieldProps = {
    show?: boolean;
    label: string;
    required: boolean;
    value?: string;
};

const PasswordField: React.FunctionComponent<PasswordFieldProps> = function (props) {
    const { show = false, value = '', label, required } = props;
    const [showPassword, setShowPassword] = useState(show);
    const [inputValue, setInputValue] = useState(value);
    const toggleVisibility = () => setShowPassword((v) => !v);

    const handleChange = (e) => {
        setInputValue(e.target.value);
    };

    return (
        <>
            <FlexyFormLabel>
                <Typography component="span">
                    {label}
                    {required && (
                        <Tooltip title="Le champ est requis">
                            <Typography
                                component="span"
                                color="danger.main"
                            >
                                &nbsp;&nbsp;*
                            </Typography>
                        </Tooltip>
                    )}
                </Typography>
            </FlexyFormLabel>
            <FlexyTextField
                fullWidth
                size="small"
                variant="outlined"
                type={showPassword ? 'text' : 'password'}
                value={inputValue}
                onChange={handleChange}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={toggleVisibility}
                            >
                                <FaOptionIcon
                                    size="xs"
                                    icon={showPassword ? faEyeSlash : faEye}
                                />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
        </>
    );
};

export default PasswordField;
