import * as React from 'react';
import { FlexyForm, FormStructure } from '@europrocurement/flexy-form';
import Person from '@mui/icons-material/Person';
import { DossierApiPostDossierCollectionRequest } from '@europrocurement/l2d-domain/openApi/ApiSign';
import { CustomDivider } from '@europrocurement/flexy-components';
import { Box, Button } from '@mui/material';
import { FaOptionIcon, signatureIcon } from '@europrocurement/l2d-icons';
import { MOBILE_PHONE_NUMBER_REGEX } from '../../../validation/regex';

export type DossierSignatureFormProps = {
    afterSubmit?: (
        partialDossier: Partial<DossierApiPostDossierCollectionRequest['dossierJsonldWrite']>,
    ) => void;
    defaultValues?: Partial<DossierApiPostDossierCollectionRequest['dossierJsonldWrite']>;
};

const DossierSignatureForm: React.FunctionComponent<DossierSignatureFormProps> = function (props) {
    const { afterSubmit, defaultValues = {} } = props;

    const createDossier = (
        formData: Partial<DossierApiPostDossierCollectionRequest['dossierJsonldWrite']>,
    ) => {
        if (afterSubmit) {
            afterSubmit(formData);
        }
    };

    const DossierSignatureFormStructure: FormStructure[] = [
        {
            type: 'header',
            label: (
                <>
                    <FaOptionIcon {...signatureIcon.props} />
                    Nouveau dossier de signature
                </>
            ),
            sx: {
                marginTop: '0px',
            },
            name: 'info',
        },
        {
            type: 'text',
            name: 'sujet',
            inputlabel: 'Sujet',
            xs: 12,
            rules: {
                required: 'Vous devez renseigner un sujet',
            },
            placeholder: 'Signature pouvoir cabinet Dupond',
        },
        {
            type: 'text',
            name: 'referenceExterne',
            inputlabel: 'Référence Externe ( permet de retrouver plus facilement votre dossier )',
            xs: 12,
            placeholder: 'dossier-1234_signature_pouvoir',
        },
        {
            type: 'text',
            name: 'prescripteur',
            inputlabel: 'Créateur',
            xs: 12,
            rules: {
                required: 'Vous devez renseigner un créateur',
            },
            placeholder: 'Maître Hélène Dupond',
        },
        {
            type: 'email',
            name: 'prescripteurEmail',
            inputlabel: 'Email du créateur',
            xs: 12,
            rules: {
                required: 'Vous devez renseigner un email',
            },
            placeholder: 'h.dupond@legal2digital.fr',
        },
        {
            type: 'header',
            label: (
                <>
                    <Person sx={{ marginRight: '5px' }} />
                    Signataires
                </>
            ),
            sx: {
                marginTop: '0px',
            },
            name: 'info',
        },
        {
            type: 'subformarray',
            minLength: 1,
            maxLength: 99,
            defaultLength: 1,
            name: 'signataires',
            // eslint-disable-next-line react/no-unstable-nested-components
            subFormHeader: (input, object, index) =>
                index > 0 ? <CustomDivider style={{ marginTop: '5px', width: '80%' }} /> : null,
            structure: [
                {
                    type: 'text',
                    name: 'nom',
                    inputlabel: 'Nom',
                    xs: 12,
                    sm: 6,
                    md: 6,
                    lg: 6,
                    rules: {
                        required: 'Vous devez renseigner un nom',
                    },
                    placeholder: 'Durand',
                },
                {
                    type: 'text',
                    name: 'prenom',
                    inputlabel: 'Prénom',
                    xs: 12,
                    sm: 6,
                    md: 6,
                    lg: 6,
                    rules: {
                        required: 'Vous devez renseigner un prénom',
                    },
                    placeholder: 'Jean',
                },
                {
                    type: 'email',
                    name: 'email',
                    inputlabel: 'Email',
                    xs: 12,
                    sm: 8,
                    md: 8,
                    lg: 8,
                    rules: {
                        required: 'Vous devez renseigner un email',
                    },
                    placeholder: 'j.durand@legal2digital.fr',
                },
                {
                    type: 'text',
                    name: 'mobile',
                    inputlabel: 'Mobile',
                    xs: 12,
                    sm: 4,
                    md: 4,
                    lg: 4,
                    rules: {
                        required: 'Vous devez renseigner un mobile',
                        pattern: {
                            value: MOBILE_PHONE_NUMBER_REGEX,
                            message: 'Le numero de mobile est invalide',
                        },
                    },
                    placeholder: '0612345789',
                },
            ],
            addBtnLabel: 'Ajouter un signataire',
            addBtnTooltip: 'Ajouter un signataire',
        },
    ];

    const submitButton = {
        render: () => (
            <Box
                display="flex"
                justifyContent="center"
                width="100%"
            >
                <Button
                    sx={{ marginTop: '15px' }}
                    type="submit"
                    // onClick={(event) => {
                    //     event.preventDefault();
                    //     handleSubmit();
                    // }}
                    variant="contained"
                >
                    Envoyer le dossier de signature
                </Button>
            </Box>
        ),
    };
    return (
        <FlexyForm
            formObject={{ signataires: [{}], ...defaultValues }}
            formStructure={DossierSignatureFormStructure}
            onSubmit={createDossier}
            submitButton={submitButton}
        />
    );
};

export default DossierSignatureForm;
