import React, { useCallback } from 'react';
import {
    CalendarPicker,
    DatePicker,
    DatePickerProps,
    DateTimePicker,
    DateTimePickerProps,
    LocalizationProvider,
    MobileDatePicker,
    MobileDatePickerProps,
    MobileDateTimePicker,
    MobileDateTimePickerProps,
    MobileTimePicker,
    MobileTimePickerProps,
    PickersDay,
    TimePicker,
    TimePickerProps,
} from '@mui/x-date-pickers';
import { BaseTextFieldProps, Box, Typography } from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/pro-duotone-svg-icons';

import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime } from 'luxon';

import FlexyTextField, { FlexyTextFieldProps } from '../FlexyTextField';

export const typeDateList: string[] = [
    'date',
    'datetime',
    'time',
    'mobiledate',
    'mobiledatetime',
    'mobiletime',
];
export const isDateType = (type: string): boolean => typeDateList.includes(type);

export type FlexyDateProps = Omit<
    DatePickerProps<Date, Date>,
    'renderInput' | 'value' | 'onChange' | 'onError' | 'label'
> & {
    type?: string;
    value?: Date | null;
    onChange?: (date: unknown, keyboardInputValue?: string | undefined) => void;
    inputRef?: React.ForwardedRef<HTMLInputElement>;
    error?: BaseTextFieldProps['error'];
    helperText?: BaseTextFieldProps['helperText'];
    size?: 'small' | 'medium';
    colorInterval?: (date: Date) => boolean;
};

export const isValidDate = (date: unknown): boolean => {
    if (typeof date !== 'object') {
        return false;
    }
    if (Object.prototype.toString.call(date) === '[object Date]') {
        if (!date) {
            return false;
        }
        if (Number.isNaN(date)) {
            return false;
        }
        return true;
    }
    return false;
};

const FaCalendar = function () {
    return (
        <Typography color="secondary">
            <FontAwesomeIcon
                size="xl"
                icon={faCalendar}
            />
        </Typography>
    );
};

const FlexyDate: React.FunctionComponent<FlexyDateProps> = function ({
    type = 'date',
    value = null,
    ...props
}: FlexyDateProps) {
    const renderInput = useCallback(
        ({ ...inputProps }: FlexyTextFieldProps) => {
            if (value === null || value === undefined) {
                // inputProps.value = '';
            }
            // delete inputProps.inputProps;
            return (
                <FlexyTextField
                    fullWidth
                    // variant="outlined"
                    size={props.size}
                    {...inputProps}
                    error={props.error}
                    helperText={props.helperText}
                />
            );
        },
        [props.error, props.helperText, props.size, value],
    );

    const RenderPicker = useCallback(() => {
        const handleChangeDateTime = (newValue: DateTime) => {
            if (props.onChange && newValue === null) {
                props.onChange(null);
                return;
            }

            if (props.onChange && newValue !== null) {
                props.onChange(newValue.toJSDate());
            }
        };
        const handleChangeDate = (newValue: Date) => {
            if (props.onChange && newValue !== null) {
                props.onChange(newValue);
            }
        };

        const { ...propsChildren } = {
            ...props,
            value,
            renderInput,
            components: {
                OpenPickerIcon: FaCalendar,
            },
        };

        if (!isValidDate(propsChildren.value)) {
            propsChildren.value = null;
        }

        switch (type) {
            case 'Datetime':
                return (
                    <DateTimePicker
                        {...(propsChildren as DateTimePickerProps<Date, DateTime>)}
                        onChange={handleChangeDateTime}
                    />
                );
            case 'time':
                return (
                    <TimePicker
                        {...(propsChildren as TimePickerProps<Date, DateTime>)}
                        onChange={handleChangeDateTime}
                    />
                );
            case 'mobiledate':
                return (
                    <MobileDatePicker
                        {...(propsChildren as MobileDatePickerProps<Date, Date>)}
                        onChange={handleChangeDate}
                    />
                );
            case 'mobiledatetime':
                return (
                    <MobileDateTimePicker
                        {...(propsChildren as MobileDateTimePickerProps<Date, Date>)}
                        onChange={handleChangeDate}
                    />
                );
            case 'mobiletime':
                return (
                    <MobileTimePicker
                        {...(propsChildren as MobileTimePickerProps<Date, Date>)}
                        onChange={handleChangeDate}
                    />
                );
            case 'calendar':
                return (
                    <CalendarPicker
                        shouldDisableDate={props.shouldDisableDate}
                        minDate={props.minDate}
                        date={value}
                        onChange={handleChangeDate}
                        renderDay={(date, selectedDates, pickersDayProps) => {
                            const luxonDate = date as unknown as { ts: number };
                            const luxonValue = value as unknown as { ts: number };
                            // Inputs are Luxon date with timestamp attribute
                            // Todo : Correctly type dates by exposing Luxon types
                            const dateInInterval =
                                props.colorInterval && props.colorInterval(date as Date);
                            const selectedDate =
                                value && luxonDate && luxonDate.ts === luxonValue.ts;
                            return (
                                <PickersDay
                                    {...pickersDayProps}
                                    key={pickersDayProps.key}
                                    selected={selectedDate || dateInInterval}
                                    sx={{ opacity: dateInInterval && !selectedDate ? 0.5 : 1 }}
                                />
                            );
                        }}
                    />
                );
            case 'date':
            default:
                return (
                    <DatePicker
                        {...(propsChildren as DatePickerProps<Date, DateTime>)}
                        onChange={handleChangeDateTime}
                    />
                );
        }
    }, [props, value, renderInput, type]);

    return (
        <LocalizationProvider
            adapterLocale="fr-FR"
            dateAdapter={AdapterLuxon}
        >
            <Box data-testid="FlexyDate">{RenderPicker()}</Box>
        </LocalizationProvider>
    );
};

export default FlexyDate;
