import * as React from 'react';
import {
    AdditionalActionsProps,
    ContactCard,
    ModalContext,
} from '@europrocurement/flexy-components';
import { Contact, Prescripteur } from '@europrocurement/l2d-domain';
import { Grid } from '@mui/material';
import ContactForm from '@b2d/pages/Prescripteurs/forms/ContactForm';
import { customizerSelector, prescribersApi } from '@b2d/redux/RootStore';
import ActionModal from '@b2d/pages/Prescripteurs/views/detail/forms/ActionModal';
import { useSelector } from 'react-redux';
import { usePrescriberContacts } from '@b2d/pages/Prescripteurs/hooks/usePrescriberContacts';
import CreateUser from '@b2d/pages/Prescripteurs/forms/CreateUser';

type PrescriberContactsProps = {
    prescriber: Prescripteur;
    afterSubmit: () => void;
};

const PrescriberContacts: React.FunctionComponent<PrescriberContactsProps> = function (props) {
    const { prescriber, afterSubmit } = props;

    const { xIdSociete } = useSelector(customizerSelector);
    const { users, contactHasUser, getUsers, hasB2bAccess } = usePrescriberContacts();

    React.useEffect(() => {
        getUsers(prescriber?.id as number, xIdSociete);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prescriber?.id, xIdSociete]);

    const { modalActions } = React.useContext(ModalContext);
    const { contacts } = prescriber;

    // Compare function to determine default order of contacts
    const defaultContactSort = (elementA: Contact, elementB: Contact) => {
        const aMain = elementA.principal;
        const bMain = elementB.principal;
        const aBilling = elementA.comptabilite;

        if (aMain) {
            return -1;
        }

        if (aBilling && !bMain) {
            return -1;
        }

        return 1;
    };

    const sortedContacts = [...contacts].sort(defaultContactSort);

    const getAdditionalActions = (contact: Contact): AdditionalActionsProps[] => {
        if (!hasB2bAccess(prescriber, xIdSociete)) {
            return [];
        }

        const b2bEmail = contactHasUser(contact, users);

        if (b2bEmail) {
            return [
                {
                    icon: 'user',
                    tooltip: `Ce contact a un accès B2B, login : ${b2bEmail}`,
                },
            ];
        }

        return [
            {
                icon: 'user-plus',
                tooltip: `Créer un accès B2B`,
                onClick: () => {
                    modalActions.call(
                        <CreateUser
                            contact={contact}
                            prescriber={prescriber}
                            randomPassword={(Math.random() + 1).toString(36).substring(2)}
                            afterSubmit={() => {
                                getUsers(prescriber?.id as number, xIdSociete);
                            }}
                        />,
                    );
                },
            },
        ];
    };

    const handleEditContact = (contact: Contact) => {
        if (prescriber.id) {
            modalActions.call(
                <ContactForm
                    entity={contact}
                    prescriber={prescriber}
                    afterSubmit={afterSubmit}
                />,
            );
        }
    };

    const deleteContact = async (contactId: number | undefined) => {
        if (!contactId) {
            return;
        }

        const prescriberContacts = prescriber.contacts.filter(
            (prescriberContact) => prescriberContact.id !== contactId,
        );
        if (prescriber && prescriber.id) {
            await prescribersApi.updateContactsPrescripteurTiersItem({
                xIdSociete,
                id: prescriber.id.toString(),
                tiersPrescripteurJsonldPrescripteurWriteContacts: {
                    contacts: prescriberContacts,
                },
            });
            afterSubmit();
        }
    };

    const handleDeleteContact = (contact: Contact) => {
        if (prescriber.id) {
            modalActions.call(
                <ActionModal
                    onClose={modalActions.close}
                    asyncAction={() => deleteContact(contact.id)}
                    title="Suppression d'un contact"
                    actionText="Voulez-vous supprimer ce contact ?"
                    actionLabel="Confirmer suppression"
                />,
            );
        }
    };

    return (
        <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
            {sortedContacts.map((contact, _, array) => (
                <Grid
                    key={`contact_card_${contact.id}`}
                    item
                    xs={12}
                    md={6}
                    lg={array.length > 2 ? 4 : 6}
                >
                    <ContactCard
                        isBordered
                        contact={contact}
                        editAction={() => handleEditContact(contact)}
                        deleteAction={() => handleDeleteContact(contact)}
                        isDeletable={contact.principal === false && contact.comptabilite === false}
                        additionalActions={getAdditionalActions(contact)}
                    />
                </Grid>
            ))}
        </Grid>
    );
};

export default PrescriberContacts;
