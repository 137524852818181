import React from 'react';
import { Box, Button, PaletteColor } from '@mui/material';
import './ConfirmationModal.css';
import {
    FaOptionIcon,
    alertDefaultIcon,
    alertInfoIcon,
    alertSuccessIcon,
    alertWarningIcon,
    alertErrorIcon,
} from '@europrocurement/l2d-icons';
import { useTheme } from '@mui/system';

export type MessagesTypes = {
    question?: string;
    detail?: {
        message: string;
        severity: 'error' | 'warning' | 'info' | 'success';
    };
    validation?: string;
    annulation?: string;
    alternative?: string;
};

export type ConfirmationModalProps = {
    messages?: MessagesTypes;
    actionOnValidation?: () => void;
    actionOnCancellation?: () => void;
    actionOnAlternative?: () => void;
    color: PaletteColor;
};

const getIconProps = (type: string | undefined, color: PaletteColor) => {
    const colorProp = color.main;

    if (type === 'info') {
        return { ...alertInfoIcon.props, color: colorProp };
    }

    if (type === 'success') {
        return { ...alertSuccessIcon.props, color: colorProp };
    }

    if (type === 'warning') {
        return { ...alertWarningIcon.props, color: colorProp };
    }

    if (type === 'error') {
        return { ...alertErrorIcon.props, color: colorProp };
    }

    return { ...alertDefaultIcon.props, color: colorProp };
};

const ConfirmationModal: React.FunctionComponent<ConfirmationModalProps> = function ({
    messages,
    actionOnValidation,
    actionOnCancellation,
    actionOnAlternative,
    color,
}) {
    const theme = useTheme();
    const mainColor = color || theme.palette.primary;
    const iconProps = getIconProps(messages?.detail?.severity || undefined, mainColor);

    return (
        <Box
            className="confirmation-modal"
            sx={{
                backgroundColor: theme.palette.background.dark,
            }}
        >
            <Box className="body">
                <Box
                    className="icon"
                    sx={{ backgroundColor: mainColor.light }}
                >
                    <FaOptionIcon {...iconProps} />
                </Box>
                <Box
                    className="question"
                    sx={{
                        color: theme.palette.text.primary,
                    }}
                >
                    {messages?.question || 'Êtes-vous sûr de vouloir valider cette action ?'}
                </Box>
                {messages?.detail ? (
                    <Box
                        className="details"
                        sx={{
                            color: theme.palette.text.primary,
                        }}
                    >
                        {messages.detail.message}
                    </Box>
                ) : null}
            </Box>
            <Box className="actions">
                <Button
                    sx={{
                        color: theme.palette.common.white,
                        backgroundColor: mainColor.main,
                        '&:hover': {
                            backgroundColor: mainColor.dark,
                        },
                    }}
                    variant="contained"
                    onClick={actionOnValidation}
                    data-testid="validation-button"
                >
                    {messages?.validation || 'Valider'}
                </Button>
                {actionOnCancellation ||
                (!actionOnCancellation && !actionOnAlternative) ||
                messages?.annulation ? (
                    <Button
                        className="cancel-button"
                        variant="outlined"
                        onClick={actionOnCancellation}
                        data-testid="cancel-button"
                        sx={{
                            color: theme.palette.text.secondary,
                            borderColor: theme.palette.grey[500],
                            '&:hover': {
                                borderColor: theme.palette.grey[500],
                            },
                        }}
                    >
                        {messages?.annulation || 'Annuler'}
                    </Button>
                ) : null}
                {actionOnAlternative && messages?.alternative ? (
                    <Button
                        className="alternate-button"
                        variant="outlined"
                        onClick={actionOnAlternative}
                        data-testid="alternate-button"
                        sx={{
                            color: theme.palette.text.secondary,
                            borderColor: theme.palette.grey[500],
                            '&:hover': {
                                borderColor: theme.palette.grey[500],
                            },
                        }}
                    >
                        {messages.alternative}
                    </Button>
                ) : null}
            </Box>
        </Box>
    );
};

export default ConfirmationModal;
