import { ConfigurationParameters as AchatsConfigurationParameters } from '../../openApi/ApiAchats';
import { createMediaObjectSlice } from './slices/mediaObjectsSlice';
import { createFactureAchatSlice } from './slices/factureAchatSlice';
import { createCategorieStatsSlice } from './slices/categorieStatsSlice';
import { createCodeRejetStatsSlice } from './slices/codeRejetStatsSlice';
import { createReleveAchatSlice } from './slices/invoiceStatementSlice';

export { type AchatsConfigurationParameters };

export function createMediaobjectReducer(configuration: AchatsConfigurationParameters) {
    const { mediaobjectSlice, mediaobjectDataSourcesThunks } =
        createMediaObjectSlice(configuration);

    return { mediaobjectSlice, mediaobjectDataSourcesThunks };
}

export function createReleveAchatReducer(configuration: AchatsConfigurationParameters) {
    const { invoiceStatementSlice, invoiceStatementDataSourcesThunks } =
        createReleveAchatSlice(configuration);

    return { invoiceStatementSlice, invoiceStatementDataSourcesThunks };
}

export function createFactureAchatReducer(configuration: AchatsConfigurationParameters) {
    const { factureAchatSlice, factureAchatDataSourcesThunks } =
        createFactureAchatSlice(configuration);

    return { factureAchatSlice, factureAchatDataSourcesThunks };
}

export function createCategorieStatsReducer(configuration: AchatsConfigurationParameters) {
    const { categorieStatsSlice, categorieStatsDataSourcesThunks } =
        createCategorieStatsSlice(configuration);

    return { categorieStatsSlice, categorieStatsDataSourcesThunks };
}

export function createCodeRejetStatsReducer(configuration: AchatsConfigurationParameters) {
    const { codeRejetStatsSlice, codeRejetStatsDataSourcesThunks } =
        createCodeRejetStatsSlice(configuration);

    return { codeRejetStatsSlice, codeRejetStatsDataSourcesThunks };
}
