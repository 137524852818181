import {
    SliceFactory,
    FetchCollectionData,
    FetchItemDataType,
    ApiCollectionResponse,
} from '@europrocurement/l2d-redux-utils';
import {
    ConfigurationParameters as OffreConfigurationParameters,
    Configuration,
    DomainesApi,
    DomainesDomaineRead,
} from '../../../openApi/ApiOffre';
import { OFFRE_REDUCER_NAME } from '../constants';

export type DomainApiObject = DomainesDomaineRead & Record<string, unknown>;

export type Domains = DomainApiObject;

export const DOMAINS_SLICE_NAME = 'domains';

const mapperDomains = function (domains: DomainApiObject) {
    return {
        ...domains,
    } as Domains;
};

export function createDomainsSlice(configuration: OffreConfigurationParameters) {
    const conf = new Configuration(configuration);
    const api = new DomainesApi(conf);

    const fetchDomainsCollection: FetchCollectionData<DomainApiObject> = ({ xIdSociete }) =>
        api
            .apiDomainesGetCollection({ xIdSociete })
            .then((res) => res.data)
            .catch((error) => {
                console.error(error);
            }) as Promise<ApiCollectionResponse<DomainApiObject>>;

    const fetchDomainItem: FetchItemDataType<DomainApiObject> = ({ idItem, xIdSociete }) =>
        api
            .apiDomainesIdGet({ id: String(idItem), xIdSociete })
            .then((res) => res.data) as Promise<DomainApiObject>;

    const { slice: domainsSlice, dataSourcesThunks: domainsDataSourcesThunks } =
        SliceFactory.createSlice<DomainApiObject, Domains>(
            DOMAINS_SLICE_NAME,
            OFFRE_REDUCER_NAME,
            [],
            fetchDomainsCollection,
            fetchDomainItem,
            mapperDomains,
        );

    return { domainsSlice, domainsDataSourcesThunks };
}
