export const signatureBasePath = '/signature';
export const signatureListePath = 'liste';
export const signatureFichePath = ':idDossierSignature';
export const signatureNewPath = 'new';
export const signatureDuplicatePath = 'duplicate/:idDossierSignature';

export const signatureListeDemandeurPath = 'referentiel';

export const signatureListeFullPath = `${signatureBasePath}/${signatureListePath}`;
export const signatureFicheFullPath = `${signatureListeFullPath}/${signatureFichePath}`;
export const signatureNewFullPath = `${signatureBasePath}/${signatureNewPath}`;
export const signatureDuplicateFullPath = `${signatureBasePath}/${signatureDuplicatePath}`;

export const signatureListeDemandeurFullPath = `${signatureBasePath}/${signatureListeDemandeurPath}`;
