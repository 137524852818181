import * as React from 'react';
import { MenuEntry, UploaderProvider } from '@europrocurement/flexy-components';

import { RouteObject } from 'react-router';
import {
    signatureBasePath,
    signatureFichePath,
    signatureListeFullPath,
    signatureListePath,
    signatureNewFullPath,
    signatureListeDemandeurFullPath,
    signatureNewPath,
    signatureListeDemandeurPath,
    signatureDuplicatePath,
} from './constants/paths';

import SignatureRoot from './views/SignatureRoot';

import DossierSignatureListe from './views/DossierSignatureListe/DossierSignatureListe';

import DossierSignatureFiche from './views/DossierSignatureFiche/DossierSignatureFiche';

import DossierSignatureAdd from './views/DossierSignatureAdd/DossierSignatureAdd';

import DossierSignatureListeDemandeur from './views/DemandeurSignatureListe/DemandeurSignatureListe';
import DossierSignatureDucplicate from './views/DossierSignatureAdd/DossierSignatureDucplicate';

export const SignatureRouter: RouteObject = {
    path: signatureBasePath,
    element: <SignatureRoot />,
    children: [
        {
            path: signatureListePath,
            children: [
                {
                    path: '',
                    index: true,
                    element: <DossierSignatureListe />,
                },
                {
                    path: signatureFichePath,
                    element: <DossierSignatureFiche />,
                },
            ],
        },
        {
            path: signatureNewPath,
            element: (
                <UploaderProvider>
                    <DossierSignatureAdd />
                </UploaderProvider>
            ),
        },
        {
            path: signatureDuplicatePath,
            element: (
                <UploaderProvider>
                    <DossierSignatureDucplicate />
                </UploaderProvider>
            ),
        },
        {
            path: signatureListeDemandeurPath,
            element: <DossierSignatureListeDemandeur />,
        },
    ],
};

export const SignatureMenuEntries: MenuEntry[] = [
    {
        title: 'Signature électronique',
        href: signatureListeFullPath,
        icon: 'signature',
        role: 'api-sign:dossier-read',
        children: [
            {
                title: 'Liste des dossiers',
                icon: 'list',
                href: signatureListeFullPath,
                role: 'api-sign:dossier-read',
            },
            {
                title: 'Nouveau dossier',
                icon: 'folder-plus',
                href: signatureNewFullPath,
                role: 'api-sign:dossier-write',
            },
            {
                title: 'Référentiels',
                icon: 'gears',
                href: signatureListeDemandeurFullPath,
                role: 'api-sign:admin',
            },
        ],
    },
];

export type ModuleSpec = {
    route: RouteObject;
    menu: MenuEntry[];
};

export const SignatureModuleSpec: ModuleSpec = {
    route: SignatureRouter,
    menu: SignatureMenuEntries,
};

export default SignatureRouter;
