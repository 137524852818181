import { ListItemButton, Typography } from '@mui/material';
import * as React from 'react';
import { Box } from '@mui/system';
import { createIcon, FaOptionIcon } from '@europrocurement/l2d-icons';

type AddButtonProps = {
    onClick: () => void;
};

const AddOptionButton: React.FC<AddButtonProps> = function (props) {
    const { onClick } = props;

    const [onHover, setHover] = React.useState<boolean>(false);

    const opacityFilter = onHover ? 0.05 : 0.01;

    return (
        <ListItemButton
            onClick={onClick}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            style={{
                background: `rgba(0,0,0,${opacityFilter})`,
                transition: 'background 0.3s',
                margin: '5px',
                width: '100%',
                fontSize: '15px',
                height: '65px',
            }}
            component="button"
        >
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="100%"
            >
                <FaOptionIcon
                    {...createIcon.props}
                    size="lg"
                    sx={{ marginRight: '18px' }}
                />
                <Typography
                    fontSize="18px"
                    style={{ opacity: '0.4' }}
                >
                    Ajouter valeur
                </Typography>
            </Box>
        </ListItemButton>
    );
};

export default AddOptionButton;
