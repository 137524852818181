import {
    CategorieCategorieRead,
    FormaliteFormaliteRead,
} from '@europrocurement/l2d-domain/openApi/ApiFormalite';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { FlexyHeaderForm } from '@europrocurement/flexy-components';
import { Box } from '@mui/system';
import { Controller, FieldValues, UseFormReturn } from 'react-hook-form';
import { categoriesFormalitySelector } from '@b2d/redux/subReducers/FormalityReducer/Selectors';
import model from '@b2d/pages/Formalities/models/formality';
import { ApiFormalitesGetCollection200Response } from '@europrocurement/l2d-domain/openApi/ApiOffre';
import { formatOptionsByKeys } from '../../forms/options';
import { GroupedSelectOptions, SelectInputOptions } from '../../forms/types';
import SelectField from '../../components/form/SelectField';
import UndoFilterButton from './UndoFilterButton';
import InlineCategoryButton from '../../components/CategoryButton/InlineCategory';
import useFetchCollection from './useFetchCollection';

type ComponentProps = {
    formContext: UseFormReturn<FieldValues, unknown>;
};

// Ids des categories de formalite
const OTHERS_ID = 3; // Autres

type FormalityCategory = {
    label: string | null | undefined;
    options: SelectInputOptions;
    id: number | undefined;
    iconeName: string | null | undefined;
};

const FormalitySelect: React.FC<ComponentProps> = function ({ formContext }) {
    const [selectedCategory, setSelectedCategory] = React.useState<FormalityCategory | null>(null);
    const {
        control,
        formState: { errors },
    } = formContext;

    const formalitiesPromise = () =>
        model.list({
            page: 1,
            itemsPerPage: 1000,
            deleted: false,
        });

    const { fetch: fetchFormalities, result: formalities } =
        useFetchCollection<ApiFormalitesGetCollection200Response['hydra:member']>(
            formalitiesPromise,
        );

    const fetchedCategoriesFormalityOptions = useSelector(categoriesFormalitySelector)
        .data as CategorieCategorieRead[];

    React.useEffect(() => {
        fetchFormalities();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const groupedFormalitiesOptions = fetchedCategoriesFormalityOptions.map(
        ({ libelle: label, id, iconeName }) => {
            const categoryOptions = formalities.filter(
                (formality: FormaliteFormaliteRead) => formality.categorie?.id === id,
            );
            return {
                label: label ?? '',
                options: formatOptionsByKeys(
                    categoryOptions as FormaliteFormaliteRead[],
                    'id',
                    'libelle',
                ),
                id,
                iconeName,
            };
        },
    );

    const categoryHasItems = (categoryId: number) => {
        const options = groupedFormalitiesOptions.find(
            (category) => category.id === categoryId,
        )?.options;
        return options && options.length > 0;
    };

    const otherCategory = groupedFormalitiesOptions.find((category) => category.id === OTHERS_ID);

    const formalitiesOptions = (): GroupedSelectOptions => {
        // Unfiltered

        if (!selectedCategory) {
            return groupedFormalitiesOptions;
        }
        // Prefiltered
        return groupedFormalitiesOptions.filter(
            (optionGroup) => optionGroup.id === selectedCategory?.id,
        );
    };

    const categorySelection = (
        <Box
            display="flex"
            flexDirection="column"
        >
            {groupedFormalitiesOptions.map((category) => {
                if (!category.id) {
                    return null;
                }
                if (category.id !== OTHERS_ID && categoryHasItems(category.id)) {
                    return (
                        <InlineCategoryButton
                            key={`${category.id}`}
                            isSelected={selectedCategory?.id === category.id}
                            label={category.label ?? ''}
                            icon={category.iconeName ?? 'question'}
                            action={() => setSelectedCategory(category)}
                            size={150}
                        />
                    );
                }
                return null;
            })}
            {otherCategory && (
                <InlineCategoryButton
                    key={`${otherCategory.id}`}
                    isSelected={selectedCategory?.id === otherCategory.id}
                    label={otherCategory.label ?? ''}
                    icon={otherCategory.iconeName ?? 'question'}
                    action={() => setSelectedCategory(otherCategory)}
                    size={150}
                />
            )}
        </Box>
    );

    return (
        <Box
            display="flex"
            sx={{ minWidth: '100%' }}
        >
            <Box
                display="flex"
                flexDirection="column"
                sx={{ marginRight: '40px' }}
            >
                <Box
                    display="flex"
                    alignItems="center"
                    marginY="15px"
                >
                    <FlexyHeaderForm
                        sx={{ marginTop: 0 }}
                        label="Catégorie"
                    />
                    <UndoFilterButton
                        label="Toutes les catégories"
                        action={() => setSelectedCategory(null)}
                        isSelected={selectedCategory === null}
                    />
                </Box>
                {categorySelection}
            </Box>

            <Box
                display="flex"
                flexDirection="column"
                width="100%"
                maxWidth="100%"
            >
                <FlexyHeaderForm
                    sx={{ marginTop: 0 }}
                    label="Type de formalité"
                />
                <Box>
                    <Controller
                        name="formalityId"
                        control={control}
                        render={({ field }) => (
                            <SelectField
                                {...field}
                                required={false}
                                placeholder="Sélectionnez une formalité"
                                name={field.name}
                                blurInputOnSelect
                                options={formalitiesOptions()}
                                errors={errors}
                            />
                        )}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default FormalitySelect;
