import { RootStateType } from '@b2d/redux/RootStore';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

/**
 * Hooks that regroup prescriber actions / methods / accessors
 */

export const SocieteSelector = (s: RootStateType) => s.customizer.xIdSociete;

export const useSociete = () => {
    const societe = useSelector(SocieteSelector);

    const [xIdSociete, setXIdSociete] = useState(societe);

    useEffect(() => {
        if (societe !== xIdSociete) {
            setXIdSociete(societe);
        }
    }, [societe, xIdSociete]);

    return xIdSociete;
};
