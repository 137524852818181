/* tslint:disable */
/* eslint-disable */
/**
 * Europrocurement Api Facturation
 * API de gestion des facturation
 *
 * The version of the OpenAPI document: 1.2.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ApiDevisGetDevisByIdItem404Response
 */
export interface ApiDevisGetDevisByIdItem404Response {
    /**
     * 
     * @type {string}
     * @memberof ApiDevisGetDevisByIdItem404Response
     */
    'error'?: string;
    /**
     * 
     * @type {ApiDevisGetDevisByIdItem404ResponseException}
     * @memberof ApiDevisGetDevisByIdItem404Response
     */
    'exception'?: ApiDevisGetDevisByIdItem404ResponseException;
}
/**
 * 
 * @export
 * @interface ApiDevisGetDevisByIdItem404ResponseException
 */
export interface ApiDevisGetDevisByIdItem404ResponseException {
    /**
     * 
     * @type {string}
     * @memberof ApiDevisGetDevisByIdItem404ResponseException
     */
    'message'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiDevisGetDevisByIdItem404ResponseException
     */
    'code'?: number;
}
/**
 * 
 * @export
 * @interface ApiDevisGetDevisCollection200Response
 */
export interface ApiDevisGetDevisCollection200Response {
    /**
     * 
     * @type {Array<DevisJsonldDevisRead>}
     * @memberof ApiDevisGetDevisCollection200Response
     */
    'hydra:member': Array<DevisJsonldDevisRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiDevisGetDevisCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiDevisGetDevisCollection200ResponseHydraView}
     * @memberof ApiDevisGetDevisCollection200Response
     */
    'hydra:view'?: ApiDevisGetDevisCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiDevisGetDevisCollection200ResponseHydraSearch}
     * @memberof ApiDevisGetDevisCollection200Response
     */
    'hydra:search'?: ApiDevisGetDevisCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiDevisGetDevisCollection200ResponseHydraSearch
 */
export interface ApiDevisGetDevisCollection200ResponseHydraSearch {
    /**
     * 
     * @type {string}
     * @memberof ApiDevisGetDevisCollection200ResponseHydraSearch
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiDevisGetDevisCollection200ResponseHydraSearch
     */
    'hydra:template'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiDevisGetDevisCollection200ResponseHydraSearch
     */
    'hydra:variableRepresentation'?: string;
    /**
     * 
     * @type {Array<ApiDevisGetDevisCollection200ResponseHydraSearchHydraMappingInner>}
     * @memberof ApiDevisGetDevisCollection200ResponseHydraSearch
     */
    'hydra:mapping'?: Array<ApiDevisGetDevisCollection200ResponseHydraSearchHydraMappingInner>;
}
/**
 * 
 * @export
 * @interface ApiDevisGetDevisCollection200ResponseHydraSearchHydraMappingInner
 */
export interface ApiDevisGetDevisCollection200ResponseHydraSearchHydraMappingInner {
    /**
     * 
     * @type {string}
     * @memberof ApiDevisGetDevisCollection200ResponseHydraSearchHydraMappingInner
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiDevisGetDevisCollection200ResponseHydraSearchHydraMappingInner
     */
    'variable'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiDevisGetDevisCollection200ResponseHydraSearchHydraMappingInner
     */
    'property'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApiDevisGetDevisCollection200ResponseHydraSearchHydraMappingInner
     */
    'required'?: boolean;
}
/**
 * 
 * @export
 * @interface ApiDevisGetDevisCollection200ResponseHydraView
 */
export interface ApiDevisGetDevisCollection200ResponseHydraView {
    /**
     * 
     * @type {string}
     * @memberof ApiDevisGetDevisCollection200ResponseHydraView
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiDevisGetDevisCollection200ResponseHydraView
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiDevisGetDevisCollection200ResponseHydraView
     */
    'hydra:first'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiDevisGetDevisCollection200ResponseHydraView
     */
    'hydra:last'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiDevisGetDevisCollection200ResponseHydraView
     */
    'hydra:previous'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiDevisGetDevisCollection200ResponseHydraView
     */
    'hydra:next'?: string;
}
/**
 * 
 * @export
 * @interface ApiDevisGetDevisCollection401Response
 */
export interface ApiDevisGetDevisCollection401Response {
    /**
     * 
     * @type {string}
     * @memberof ApiDevisGetDevisCollection401Response
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ApiDevisGetDevisCollection403Response
 */
export interface ApiDevisGetDevisCollection403Response {
    /**
     * 
     * @type {string}
     * @memberof ApiDevisGetDevisCollection403Response
     */
    'error'?: string;
    /**
     * 
     * @type {ApiDevisGetDevisCollection403ResponseException}
     * @memberof ApiDevisGetDevisCollection403Response
     */
    'exception'?: ApiDevisGetDevisCollection403ResponseException;
}
/**
 * 
 * @export
 * @interface ApiDevisGetDevisCollection403ResponseException
 */
export interface ApiDevisGetDevisCollection403ResponseException {
    /**
     * 
     * @type {string}
     * @memberof ApiDevisGetDevisCollection403ResponseException
     */
    'message'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiDevisGetDevisCollection403ResponseException
     */
    'code'?: number;
}
/**
 * 
 * @export
 * @interface ApiDevisLignesGetDevisCollection200Response
 */
export interface ApiDevisLignesGetDevisCollection200Response {
    /**
     * 
     * @type {Array<DevisLignesJsonldDevisLignesRead>}
     * @memberof ApiDevisLignesGetDevisCollection200Response
     */
    'hydra:member': Array<DevisLignesJsonldDevisLignesRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiDevisLignesGetDevisCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiDevisGetDevisCollection200ResponseHydraView}
     * @memberof ApiDevisLignesGetDevisCollection200Response
     */
    'hydra:view'?: ApiDevisGetDevisCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiDevisGetDevisCollection200ResponseHydraSearch}
     * @memberof ApiDevisLignesGetDevisCollection200Response
     */
    'hydra:search'?: ApiDevisGetDevisCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiDossiersDossierFacturesGetCollection200Response
 */
export interface ApiDossiersDossierFacturesGetCollection200Response {
    /**
     * 
     * @type {Array<FactureJsonldFactureRead>}
     * @memberof ApiDossiersDossierFacturesGetCollection200Response
     */
    'hydra:member': Array<FactureJsonldFactureRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiDossiersDossierFacturesGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiDevisGetDevisCollection200ResponseHydraView}
     * @memberof ApiDossiersDossierFacturesGetCollection200Response
     */
    'hydra:view'?: ApiDevisGetDevisCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiDevisGetDevisCollection200ResponseHydraSearch}
     * @memberof ApiDossiersDossierFacturesGetCollection200Response
     */
    'hydra:search'?: ApiDevisGetDevisCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiFacturesIdPut400Response
 */
export interface ApiFacturesIdPut400Response {
    /**
     * 
     * @type {string}
     * @memberof ApiFacturesIdPut400Response
     */
    'error': string;
    /**
     * 
     * @type {ApiFacturesIdPut400ResponseException}
     * @memberof ApiFacturesIdPut400Response
     */
    'exception': ApiFacturesIdPut400ResponseException;
}
/**
 * 
 * @export
 * @interface ApiFacturesIdPut400ResponseException
 */
export interface ApiFacturesIdPut400ResponseException {
    /**
     * 
     * @type {string}
     * @memberof ApiFacturesIdPut400ResponseException
     */
    'message': string;
    /**
     * 
     * @type {number}
     * @memberof ApiFacturesIdPut400ResponseException
     */
    'code': number;
    /**
     * liste des propiétés invalides
     * @type {Array<ApiFacturesIdPut400ResponseExceptionViolationsInner>}
     * @memberof ApiFacturesIdPut400ResponseException
     */
    'violations'?: Array<ApiFacturesIdPut400ResponseExceptionViolationsInner>;
}
/**
 * 
 * @export
 * @interface ApiFacturesIdPut400ResponseExceptionViolationsInner
 */
export interface ApiFacturesIdPut400ResponseExceptionViolationsInner {
    /**
     * nom de la propriété invalide
     * @type {string}
     * @memberof ApiFacturesIdPut400ResponseExceptionViolationsInner
     */
    'property'?: string;
    /**
     * message descriptif de la violation
     * @type {string}
     * @memberof ApiFacturesIdPut400ResponseExceptionViolationsInner
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ApiFacturesMultiplesPost400Response
 */
export interface ApiFacturesMultiplesPost400Response {
    /**
     * 
     * @type {string}
     * @memberof ApiFacturesMultiplesPost400Response
     */
    'error': string;
    /**
     * 
     * @type {ApiFacturesMultiplesPost400ResponseException}
     * @memberof ApiFacturesMultiplesPost400Response
     */
    'exception': ApiFacturesMultiplesPost400ResponseException;
}
/**
 * 
 * @export
 * @interface ApiFacturesMultiplesPost400ResponseException
 */
export interface ApiFacturesMultiplesPost400ResponseException {
    /**
     * 
     * @type {string}
     * @memberof ApiFacturesMultiplesPost400ResponseException
     */
    'message': string;
    /**
     * 
     * @type {number}
     * @memberof ApiFacturesMultiplesPost400ResponseException
     */
    'code': number;
    /**
     * liste des propiétés invalides
     * @type {Array<ApiFacturesMultiplesPost400ResponseExceptionViolationsInner>}
     * @memberof ApiFacturesMultiplesPost400ResponseException
     */
    'violations'?: Array<ApiFacturesMultiplesPost400ResponseExceptionViolationsInner>;
}
/**
 * 
 * @export
 * @interface ApiFacturesMultiplesPost400ResponseExceptionViolationsInner
 */
export interface ApiFacturesMultiplesPost400ResponseExceptionViolationsInner {
    /**
     * nom de la propriété invalide
     * @type {string}
     * @memberof ApiFacturesMultiplesPost400ResponseExceptionViolationsInner
     */
    'property'?: string;
    /**
     * message descriptif de la violation
     * @type {string}
     * @memberof ApiFacturesMultiplesPost400ResponseExceptionViolationsInner
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ApiFacturesPost400Response
 */
export interface ApiFacturesPost400Response {
    /**
     * 
     * @type {string}
     * @memberof ApiFacturesPost400Response
     */
    'error': string;
    /**
     * 
     * @type {ApiFacturesPost400ResponseException}
     * @memberof ApiFacturesPost400Response
     */
    'exception': ApiFacturesPost400ResponseException;
}
/**
 * 
 * @export
 * @interface ApiFacturesPost400ResponseException
 */
export interface ApiFacturesPost400ResponseException {
    /**
     * 
     * @type {string}
     * @memberof ApiFacturesPost400ResponseException
     */
    'message': string;
    /**
     * 
     * @type {number}
     * @memberof ApiFacturesPost400ResponseException
     */
    'code': number;
    /**
     * liste des propiétés invalides
     * @type {Array<ApiFacturesPost400ResponseExceptionViolationsInner>}
     * @memberof ApiFacturesPost400ResponseException
     */
    'violations'?: Array<ApiFacturesPost400ResponseExceptionViolationsInner>;
}
/**
 * 
 * @export
 * @interface ApiFacturesPost400ResponseExceptionViolationsInner
 */
export interface ApiFacturesPost400ResponseExceptionViolationsInner {
    /**
     * nom de la propriété invalide
     * @type {string}
     * @memberof ApiFacturesPost400ResponseExceptionViolationsInner
     */
    'property'?: string;
    /**
     * message descriptif de la violation
     * @type {string}
     * @memberof ApiFacturesPost400ResponseExceptionViolationsInner
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface DevisDevisRead
 */
export interface DevisDevisRead {
    /**
     * 
     * @type {number}
     * @memberof DevisDevisRead
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof DevisDevisRead
     */
    'dossierAl'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DevisDevisRead
     */
    'dossier'?: string | null;
    /**
     * 
     * @type {DevisDevisReadSociete}
     * @memberof DevisDevisRead
     */
    'societe'?: DevisDevisReadSociete | null;
    /**
     * 
     * @type {string}
     * @memberof DevisDevisRead
     */
    'tiers'?: string;
    /**
     * 
     * @type {string}
     * @memberof DevisDevisRead
     */
    'numero'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisDevisRead
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof DevisDevisRead
     */
    'auteur'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisDevisRead
     */
    'totalHt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisDevisRead
     */
    'totalTva'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisDevisRead
     */
    'totalTtc'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisDevisRead
     */
    'file'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DevisDevisRead
     */
    'actif'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof DevisDevisRead
     */
    'demo'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof DevisDevisRead
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DevisDevisRead
     */
    'lignes'?: Array<string>;
}
/**
 * 
 * @export
 * @interface DevisDevisReadSociete
 */
export interface DevisDevisReadSociete {
}
/**
 * 
 * @export
 * @interface DevisJsonldDevisRead
 */
export interface DevisJsonldDevisRead {
    /**
     * 
     * @type {string}
     * @memberof DevisJsonldDevisRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DevisJsonldDevisRead
     */
    '@type'?: string;
    /**
     * 
     * @type {DevisJsonldDevisReadContext}
     * @memberof DevisJsonldDevisRead
     */
    '@context'?: DevisJsonldDevisReadContext;
    /**
     * 
     * @type {number}
     * @memberof DevisJsonldDevisRead
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof DevisJsonldDevisRead
     */
    'dossierAl'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DevisJsonldDevisRead
     */
    'dossier'?: string | null;
    /**
     * 
     * @type {DevisJsonldDevisReadSociete}
     * @memberof DevisJsonldDevisRead
     */
    'societe'?: DevisJsonldDevisReadSociete | null;
    /**
     * 
     * @type {string}
     * @memberof DevisJsonldDevisRead
     */
    'tiers'?: string;
    /**
     * 
     * @type {string}
     * @memberof DevisJsonldDevisRead
     */
    'numero'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisJsonldDevisRead
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof DevisJsonldDevisRead
     */
    'auteur'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisJsonldDevisRead
     */
    'totalHt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisJsonldDevisRead
     */
    'totalTva'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisJsonldDevisRead
     */
    'totalTtc'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisJsonldDevisRead
     */
    'file'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DevisJsonldDevisRead
     */
    'actif'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof DevisJsonldDevisRead
     */
    'demo'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof DevisJsonldDevisRead
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DevisJsonldDevisRead
     */
    'lignes'?: Array<string>;
}
/**
 * @type DevisJsonldDevisReadContext
 * @export
 */
export type DevisJsonldDevisReadContext = DevisJsonldDevisReadContextOneOf | string;

/**
 * 
 * @export
 * @interface DevisJsonldDevisReadContextOneOf
 */
export interface DevisJsonldDevisReadContextOneOf {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof DevisJsonldDevisReadContextOneOf
     */
    '@vocab': string;
    /**
     * 
     * @type {string}
     * @memberof DevisJsonldDevisReadContextOneOf
     */
    'hydra': DevisJsonldDevisReadContextOneOfHydraEnum;
}

export const DevisJsonldDevisReadContextOneOfHydraEnum = {
    HttpWwwW3OrgNsHydraCore: 'http://www.w3.org/ns/hydra/core#'
} as const;

export type DevisJsonldDevisReadContextOneOfHydraEnum = typeof DevisJsonldDevisReadContextOneOfHydraEnum[keyof typeof DevisJsonldDevisReadContextOneOfHydraEnum];

/**
 * 
 * @export
 * @interface DevisJsonldDevisReadSociete
 */
export interface DevisJsonldDevisReadSociete {
    /**
     * 
     * @type {DevisJsonldDevisReadContext}
     * @memberof DevisJsonldDevisReadSociete
     */
    '@context'?: DevisJsonldDevisReadContext;
    /**
     * 
     * @type {string}
     * @memberof DevisJsonldDevisReadSociete
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DevisJsonldDevisReadSociete
     */
    '@type'?: string;
}
/**
 * 
 * @export
 * @interface DevisLignes
 */
export interface DevisLignes {
    /**
     * 
     * @type {number}
     * @memberof DevisLignes
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof DevisLignes
     */
    'numero'?: number;
    /**
     * 
     * @type {string}
     * @memberof DevisLignes
     */
    'rubfac'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignes
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignes
     */
    'unite'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DevisLignes
     */
    'quantite'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignes
     */
    'prixBase'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DevisLignes
     */
    'numeroTva'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DevisLignes
     */
    'tauxTva'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignes
     */
    'ht'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignes
     */
    'tva'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DevisLignes
     */
    'affiche'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignes
     */
    'compte'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignes
     */
    'cegidCompte'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignes
     */
    'cegidSp1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignes
     */
    'cegidSp2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignes
     */
    'cegidTl1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignes
     */
    'cegidTl2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignes
     */
    'cegidTl3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignes
     */
    'dep'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignes
     */
    'jal'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DevisLignes
     */
    'idAnnonce'?: number | null;
    /**
     * 
     * @type {DevisLignesDevis}
     * @memberof DevisLignes
     */
    'devis'?: DevisLignesDevis | null;
}
/**
 * 
 * @export
 * @interface DevisLignesDevis
 */
export interface DevisLignesDevis {
    /**
     * 
     * @type {number}
     * @memberof DevisLignesDevis
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof DevisLignesDevis
     */
    'dossierAl'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesDevis
     */
    'dossier'?: string | null;
    /**
     * 
     * @type {TblDevisSociete}
     * @memberof DevisLignesDevis
     */
    'societe'?: TblDevisSociete | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesDevis
     */
    'tiers'?: string;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesDevis
     */
    'numero'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesDevis
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesDevis
     */
    'auteur'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesDevis
     */
    'totalHt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesDevis
     */
    'totalTva'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesDevis
     */
    'totalTtc'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesDevis
     */
    'file'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DevisLignesDevis
     */
    'actif'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof DevisLignesDevis
     */
    'demo'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof DevisLignesDevis
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DevisLignesDevis
     */
    'lignes'?: Array<string>;
    /**
     * 
     * @type {any}
     * @memberof DevisLignesDevis
     */
    'pdfSimple'?: any;
    /**
     * 
     * @type {any}
     * @memberof DevisLignesDevis
     */
    'pdfDetail'?: any;
    /**
     * 
     * @type {any}
     * @memberof DevisLignesDevis
     */
    'expires'?: any;
}
/**
 * 
 * @export
 * @interface DevisLignesDevisLignesRead
 */
export interface DevisLignesDevisLignesRead {
    /**
     * 
     * @type {number}
     * @memberof DevisLignesDevisLignesRead
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof DevisLignesDevisLignesRead
     */
    'numero'?: number;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesDevisLignesRead
     */
    'rubfac'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesDevisLignesRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesDevisLignesRead
     */
    'unite'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DevisLignesDevisLignesRead
     */
    'quantite'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesDevisLignesRead
     */
    'prixBase'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DevisLignesDevisLignesRead
     */
    'numeroTva'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DevisLignesDevisLignesRead
     */
    'tauxTva'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesDevisLignesRead
     */
    'ht'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesDevisLignesRead
     */
    'tva'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DevisLignesDevisLignesRead
     */
    'affiche'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesDevisLignesRead
     */
    'compte'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesDevisLignesRead
     */
    'cegidCompte'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesDevisLignesRead
     */
    'cegidSp1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesDevisLignesRead
     */
    'cegidSp2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesDevisLignesRead
     */
    'cegidTl1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesDevisLignesRead
     */
    'cegidTl2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesDevisLignesRead
     */
    'cegidTl3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesDevisLignesRead
     */
    'dep'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesDevisLignesRead
     */
    'jal'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DevisLignesDevisLignesRead
     */
    'idAnnonce'?: number | null;
    /**
     * 
     * @type {DevisLignesDevisLignesReadDevis}
     * @memberof DevisLignesDevisLignesRead
     */
    'devis'?: DevisLignesDevisLignesReadDevis | null;
}
/**
 * 
 * @export
 * @interface DevisLignesDevisLignesReadDevis
 */
export interface DevisLignesDevisLignesReadDevis {
    /**
     * 
     * @type {number}
     * @memberof DevisLignesDevisLignesReadDevis
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof DevisLignesDevisLignesReadDevis
     */
    'dossierAl'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesDevisLignesReadDevis
     */
    'dossier'?: string | null;
    /**
     * 
     * @type {TblDevisDevisLignesReadSociete}
     * @memberof DevisLignesDevisLignesReadDevis
     */
    'societe'?: TblDevisDevisLignesReadSociete | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesDevisLignesReadDevis
     */
    'tiers'?: string;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesDevisLignesReadDevis
     */
    'numero'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesDevisLignesReadDevis
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesDevisLignesReadDevis
     */
    'auteur'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesDevisLignesReadDevis
     */
    'totalHt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesDevisLignesReadDevis
     */
    'totalTva'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesDevisLignesReadDevis
     */
    'totalTtc'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesDevisLignesReadDevis
     */
    'file'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DevisLignesDevisLignesReadDevis
     */
    'actif'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof DevisLignesDevisLignesReadDevis
     */
    'demo'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof DevisLignesDevisLignesReadDevis
     */
    'deleted'?: boolean | null;
}
/**
 * 
 * @export
 * @interface DevisLignesJsonld
 */
export interface DevisLignesJsonld {
    /**
     * 
     * @type {DevisJsonldDevisReadContext}
     * @memberof DevisLignesJsonld
     */
    '@context'?: DevisJsonldDevisReadContext;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesJsonld
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesJsonld
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof DevisLignesJsonld
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof DevisLignesJsonld
     */
    'numero'?: number;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesJsonld
     */
    'rubfac'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesJsonld
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesJsonld
     */
    'unite'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DevisLignesJsonld
     */
    'quantite'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesJsonld
     */
    'prixBase'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DevisLignesJsonld
     */
    'numeroTva'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DevisLignesJsonld
     */
    'tauxTva'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesJsonld
     */
    'ht'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesJsonld
     */
    'tva'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DevisLignesJsonld
     */
    'affiche'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesJsonld
     */
    'compte'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesJsonld
     */
    'cegidCompte'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesJsonld
     */
    'cegidSp1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesJsonld
     */
    'cegidSp2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesJsonld
     */
    'cegidTl1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesJsonld
     */
    'cegidTl2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesJsonld
     */
    'cegidTl3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesJsonld
     */
    'dep'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesJsonld
     */
    'jal'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DevisLignesJsonld
     */
    'idAnnonce'?: number | null;
    /**
     * 
     * @type {DevisLignesJsonldDevis}
     * @memberof DevisLignesJsonld
     */
    'devis'?: DevisLignesJsonldDevis | null;
}
/**
 * 
 * @export
 * @interface DevisLignesJsonldDevis
 */
export interface DevisLignesJsonldDevis {
    /**
     * 
     * @type {DevisJsonldDevisReadContext}
     * @memberof DevisLignesJsonldDevis
     */
    '@context'?: DevisJsonldDevisReadContext;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesJsonldDevis
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesJsonldDevis
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof DevisLignesJsonldDevis
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof DevisLignesJsonldDevis
     */
    'dossierAl'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesJsonldDevis
     */
    'dossier'?: string | null;
    /**
     * 
     * @type {TblDevisJsonldSociete}
     * @memberof DevisLignesJsonldDevis
     */
    'societe'?: TblDevisJsonldSociete | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesJsonldDevis
     */
    'tiers'?: string;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesJsonldDevis
     */
    'numero'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesJsonldDevis
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesJsonldDevis
     */
    'auteur'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesJsonldDevis
     */
    'totalHt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesJsonldDevis
     */
    'totalTva'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesJsonldDevis
     */
    'totalTtc'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesJsonldDevis
     */
    'file'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DevisLignesJsonldDevis
     */
    'actif'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof DevisLignesJsonldDevis
     */
    'demo'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof DevisLignesJsonldDevis
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DevisLignesJsonldDevis
     */
    'lignes'?: Array<string>;
    /**
     * 
     * @type {any}
     * @memberof DevisLignesJsonldDevis
     */
    'pdfSimple'?: any;
    /**
     * 
     * @type {any}
     * @memberof DevisLignesJsonldDevis
     */
    'pdfDetail'?: any;
    /**
     * 
     * @type {any}
     * @memberof DevisLignesJsonldDevis
     */
    'expires'?: any;
}
/**
 * 
 * @export
 * @interface DevisLignesJsonldDevisLignesRead
 */
export interface DevisLignesJsonldDevisLignesRead {
    /**
     * 
     * @type {DevisJsonldDevisReadContext}
     * @memberof DevisLignesJsonldDevisLignesRead
     */
    '@context'?: DevisJsonldDevisReadContext;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesJsonldDevisLignesRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesJsonldDevisLignesRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof DevisLignesJsonldDevisLignesRead
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof DevisLignesJsonldDevisLignesRead
     */
    'numero'?: number;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesJsonldDevisLignesRead
     */
    'rubfac'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesJsonldDevisLignesRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesJsonldDevisLignesRead
     */
    'unite'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DevisLignesJsonldDevisLignesRead
     */
    'quantite'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesJsonldDevisLignesRead
     */
    'prixBase'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DevisLignesJsonldDevisLignesRead
     */
    'numeroTva'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DevisLignesJsonldDevisLignesRead
     */
    'tauxTva'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesJsonldDevisLignesRead
     */
    'ht'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesJsonldDevisLignesRead
     */
    'tva'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DevisLignesJsonldDevisLignesRead
     */
    'affiche'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesJsonldDevisLignesRead
     */
    'compte'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesJsonldDevisLignesRead
     */
    'cegidCompte'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesJsonldDevisLignesRead
     */
    'cegidSp1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesJsonldDevisLignesRead
     */
    'cegidSp2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesJsonldDevisLignesRead
     */
    'cegidTl1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesJsonldDevisLignesRead
     */
    'cegidTl2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesJsonldDevisLignesRead
     */
    'cegidTl3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesJsonldDevisLignesRead
     */
    'dep'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesJsonldDevisLignesRead
     */
    'jal'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DevisLignesJsonldDevisLignesRead
     */
    'idAnnonce'?: number | null;
    /**
     * 
     * @type {DevisLignesJsonldDevisLignesReadDevis}
     * @memberof DevisLignesJsonldDevisLignesRead
     */
    'devis'?: DevisLignesJsonldDevisLignesReadDevis | null;
}
/**
 * 
 * @export
 * @interface DevisLignesJsonldDevisLignesReadDevis
 */
export interface DevisLignesJsonldDevisLignesReadDevis {
    /**
     * 
     * @type {DevisJsonldDevisReadContext}
     * @memberof DevisLignesJsonldDevisLignesReadDevis
     */
    '@context'?: DevisJsonldDevisReadContext;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesJsonldDevisLignesReadDevis
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesJsonldDevisLignesReadDevis
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof DevisLignesJsonldDevisLignesReadDevis
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof DevisLignesJsonldDevisLignesReadDevis
     */
    'dossierAl'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesJsonldDevisLignesReadDevis
     */
    'dossier'?: string | null;
    /**
     * 
     * @type {TblDevisJsonldDevisLignesReadSociete}
     * @memberof DevisLignesJsonldDevisLignesReadDevis
     */
    'societe'?: TblDevisJsonldDevisLignesReadSociete | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesJsonldDevisLignesReadDevis
     */
    'tiers'?: string;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesJsonldDevisLignesReadDevis
     */
    'numero'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesJsonldDevisLignesReadDevis
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesJsonldDevisLignesReadDevis
     */
    'auteur'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesJsonldDevisLignesReadDevis
     */
    'totalHt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesJsonldDevisLignesReadDevis
     */
    'totalTva'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesJsonldDevisLignesReadDevis
     */
    'totalTtc'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisLignesJsonldDevisLignesReadDevis
     */
    'file'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DevisLignesJsonldDevisLignesReadDevis
     */
    'actif'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof DevisLignesJsonldDevisLignesReadDevis
     */
    'demo'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof DevisLignesJsonldDevisLignesReadDevis
     */
    'deleted'?: boolean | null;
}
/**
 * 
 * @export
 * @interface FactureFactureCreate
 */
export interface FactureFactureCreate {
    /**
     * 
     * @type {string}
     * @memberof FactureFactureCreate
     */
    'numero'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureFactureCreate
     */
    'userCreation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureCreate
     */
    'dossier'?: string | null;
    /**
     * 
     * @type {Array<TblFligneFactureCreate>}
     * @memberof FactureFactureCreate
     */
    'lignes'?: Array<TblFligneFactureCreate>;
}
/**
 * 
 * @export
 * @interface FactureFactureRead
 */
export interface FactureFactureRead {
    /**
     * identifiant de la facture
     * @type {number}
     * @memberof FactureFactureRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'numero'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureFactureRead
     */
    'numeroDossier'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'dateFacture'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureFactureRead
     */
    'prescripteur'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureFactureRead
     */
    'client'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'raisonSociale'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'adresse1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'adresse2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'adresse3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'adresse4'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'codePostal'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'ville'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureFactureRead
     */
    'tiersCorrespondance'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'raisonSocialeC'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'adresse1C'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'adresse2C'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'adresse3C'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'adresse4C'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'codePostalC'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'villeC'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'reference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'cliCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'totalHt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'totalTva'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'totalTtc'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureFactureRead
     */
    'paye'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'dateExport'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureFactureRead
     */
    'societe'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureFactureRead
     */
    'contact'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureFactureRead
     */
    'gratuit'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'remise'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureFactureRead
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FactureFactureRead
     */
    'delaiPaiement'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureFactureRead
     */
    'userCreation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureFactureRead
     */
    'contactClient'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'tvaBase1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'tvaBase2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'tvaBase3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'tvaBase4'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureFactureRead
     */
    'tvaTx1'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureFactureRead
     */
    'tvaTx2'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureFactureRead
     */
    'tvaTx3'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureFactureRead
     */
    'tvaTx4'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'tvaMontant1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'tvaMontant2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'tvaMontant3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'tvaMontant4'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'montantPaiementRecu'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureFactureRead
     */
    'userMaj'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'dateMaj'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureFactureRead
     */
    'compta'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'contactFacture'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'contactCorrespondance'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureFactureRead
     */
    'rrrPaye'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'datePaiementRrr'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureFactureRead
     */
    'rrrRefuse'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureFactureRead
     */
    'formalite'?: boolean | null;
    /**
     * 
     * @type {FactureFactureReadDossierFormalite}
     * @memberof FactureFactureRead
     */
    'dossierFormalite'?: FactureFactureReadDossierFormalite | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureFactureRead
     */
    'factureEnPreparation'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FactureFactureRead
     */
    'societeFacture'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureFactureRead
     */
    'facturationDiverse'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FactureFactureRead
     */
    'idDossierFactureDiverse'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureFactureRead
     */
    'comptaCegid'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'dateExportCegid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'cegidTvaEncaissement'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'cegidRegimeTva'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'cegidLibelle'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureFactureRead
     */
    'statFacture'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureFactureRead
     */
    'factureAvoir'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureFactureRead
     */
    'factureAvoirRemise'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FactureFactureRead
     */
    'cegidRejetExportCompta'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'cegidRejetDateExport'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureFactureRead
     */
    'formalite2'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'dossier'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'adjNomTgi'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'adjDateVente'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'adjAffaire'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'adjDetailVente'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'dateEcheanceFacture'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureFactureRead
     */
    'factureAdju'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'adjDossier'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureFactureRead
     */
    'presentationAdju'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'dateLettrageReglement'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureFactureRead
     */
    'editionReleve'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureFactureRead
     */
    'nonRelancable'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FactureFactureRead
     */
    'idUserNonRelancable'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'dateNonRelancable'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureFactureRead
     */
    'rbtFraisAchats'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FactureFactureRead
     */
    'idFactureRefAvoir'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureFactureRead
     */
    'idFactureReleveAvoir'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureFactureRead
     */
    'venteIndirecte'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureFactureRead
     */
    'horsParrainage'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FactureFactureRead
     */
    'tiersParrain'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureFactureRead
     */
    'tauxParrainG'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureFactureRead
     */
    'tauxParrainHg'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureFactureRead
     */
    'traAchat'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'dateTraAchat'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureFactureRead
     */
    'factureMultiple'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'comNonRelancable'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureFactureRead
     */
    'exportG3'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'referenceInterne'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureFactureRead
     */
    'parrainageEdition'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureFactureRead
     */
    'retourNpai'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FactureFactureRead
     */
    'nImpNeotouch'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureFactureRead
     */
    'brouillonMultiple'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureFactureRead
     */
    'commande'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureFactureRead
     */
    'idDdm'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof FactureFactureRead
     */
    'intercoTraitee'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'dateTraitementInterco'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'numeroFactureInterco'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureFactureRead
     */
    'coef'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureFactureRead
     */
    'comRegie'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'dateComRegie'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureFactureRead
     */
    'fournisseurComRegie'?: number;
    /**
     * 
     * @type {number}
     * @memberof FactureFactureRead
     */
    'idTcf'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'releveRrrConfrereFile'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureFactureRead
     */
    'fnpExclus'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'cegidFile'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureFactureRead
     */
    'justifsDetailsInterdit'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FactureFactureRead
     */
    'nJustifsTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof FactureFactureRead
     */
    'nJustifsPayants'?: number;
    /**
     * 
     * @type {number}
     * @memberof FactureFactureRead
     */
    'nJustifsGratuits'?: number;
    /**
     * 
     * @type {number}
     * @memberof FactureFactureRead
     */
    'idPfj'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'numEngagement'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureFactureRead
     */
    'kdoTraite'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'dateTraitementKdo'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureFactureRead
     */
    'idUserKdoTraite'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof FactureFactureRead
     */
    'dnidTraite'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'dateTraitementDnid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'numAchatRrr'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'dateAchatRrr'?: string | null;
    /**
     * 
     * @type {Array<TblFligneFactureRead>}
     * @memberof FactureFactureRead
     */
    'lignes'?: Array<TblFligneFactureRead>;
}
/**
 * 
 * @export
 * @interface FactureFactureReadDossierFormalite
 */
export interface FactureFactureReadDossierFormalite {
}
/**
 * 
 * @export
 * @interface FactureFactureReadPdf
 */
export interface FactureFactureReadPdf {
    /**
     * Lien vers le pdf de la facture
     * @type {string}
     * @memberof FactureFactureReadPdf
     */
    'pdf'?: string;
    /**
     * Date d\'expiration des liens vers les pdf de la facture
     * @type {string}
     * @memberof FactureFactureReadPdf
     */
    'expires'?: string;
}
/**
 * 
 * @export
 * @interface FactureFactureUpdate
 */
export interface FactureFactureUpdate {
    /**
     * 
     * @type {string}
     * @memberof FactureFactureUpdate
     */
    'numero'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureUpdate
     */
    'dossier'?: string | null;
    /**
     * 
     * @type {Array<TblFligneFactureUpdate>}
     * @memberof FactureFactureUpdate
     */
    'lignes'?: Array<TblFligneFactureUpdate>;
}
/**
 * 
 * @export
 * @interface FactureJsonld
 */
export interface FactureJsonld {
    /**
     * 
     * @type {DevisJsonldDevisReadContext}
     * @memberof FactureJsonld
     */
    '@context'?: DevisJsonldDevisReadContext;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonld
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonld
     */
    '@type'?: string;
}
/**
 * 
 * @export
 * @interface FactureJsonldFactureCreate
 */
export interface FactureJsonldFactureCreate {
    /**
     * 
     * @type {DevisJsonldDevisReadContext}
     * @memberof FactureJsonldFactureCreate
     */
    '@context'?: DevisJsonldDevisReadContext;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureCreate
     */
    'numero'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureJsonldFactureCreate
     */
    'userCreation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureCreate
     */
    'dossier'?: string | null;
    /**
     * 
     * @type {Array<TblFligneJsonldFactureCreate>}
     * @memberof FactureJsonldFactureCreate
     */
    'lignes'?: Array<TblFligneJsonldFactureCreate>;
}
/**
 * 
 * @export
 * @interface FactureJsonldFactureRead
 */
export interface FactureJsonldFactureRead {
    /**
     * 
     * @type {DevisJsonldDevisReadContext}
     * @memberof FactureJsonldFactureRead
     */
    '@context'?: DevisJsonldDevisReadContext;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    '@type'?: string;
    /**
     * identifiant de la facture
     * @type {number}
     * @memberof FactureJsonldFactureRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'numero'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureJsonldFactureRead
     */
    'numeroDossier'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'dateFacture'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureJsonldFactureRead
     */
    'prescripteur'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureJsonldFactureRead
     */
    'client'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'raisonSociale'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'adresse1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'adresse2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'adresse3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'adresse4'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'codePostal'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'ville'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureJsonldFactureRead
     */
    'tiersCorrespondance'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'raisonSocialeC'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'adresse1C'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'adresse2C'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'adresse3C'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'adresse4C'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'codePostalC'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'villeC'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'reference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'cliCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'totalHt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'totalTva'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'totalTtc'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureJsonldFactureRead
     */
    'paye'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'dateExport'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureJsonldFactureRead
     */
    'societe'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureJsonldFactureRead
     */
    'contact'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureJsonldFactureRead
     */
    'gratuit'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'remise'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureJsonldFactureRead
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FactureJsonldFactureRead
     */
    'delaiPaiement'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureJsonldFactureRead
     */
    'userCreation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureJsonldFactureRead
     */
    'contactClient'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'tvaBase1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'tvaBase2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'tvaBase3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'tvaBase4'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureJsonldFactureRead
     */
    'tvaTx1'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureJsonldFactureRead
     */
    'tvaTx2'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureJsonldFactureRead
     */
    'tvaTx3'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureJsonldFactureRead
     */
    'tvaTx4'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'tvaMontant1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'tvaMontant2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'tvaMontant3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'tvaMontant4'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'montantPaiementRecu'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureJsonldFactureRead
     */
    'userMaj'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'dateMaj'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureJsonldFactureRead
     */
    'compta'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'contactFacture'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'contactCorrespondance'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureJsonldFactureRead
     */
    'rrrPaye'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'datePaiementRrr'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureJsonldFactureRead
     */
    'rrrRefuse'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureJsonldFactureRead
     */
    'formalite'?: boolean | null;
    /**
     * 
     * @type {FactureJsonldFactureReadDossierFormalite}
     * @memberof FactureJsonldFactureRead
     */
    'dossierFormalite'?: FactureJsonldFactureReadDossierFormalite | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureJsonldFactureRead
     */
    'factureEnPreparation'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FactureJsonldFactureRead
     */
    'societeFacture'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureJsonldFactureRead
     */
    'facturationDiverse'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FactureJsonldFactureRead
     */
    'idDossierFactureDiverse'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureJsonldFactureRead
     */
    'comptaCegid'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'dateExportCegid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'cegidTvaEncaissement'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'cegidRegimeTva'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'cegidLibelle'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureJsonldFactureRead
     */
    'statFacture'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureJsonldFactureRead
     */
    'factureAvoir'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureJsonldFactureRead
     */
    'factureAvoirRemise'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FactureJsonldFactureRead
     */
    'cegidRejetExportCompta'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'cegidRejetDateExport'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureJsonldFactureRead
     */
    'formalite2'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'dossier'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'adjNomTgi'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'adjDateVente'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'adjAffaire'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'adjDetailVente'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'dateEcheanceFacture'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureJsonldFactureRead
     */
    'factureAdju'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'adjDossier'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureJsonldFactureRead
     */
    'presentationAdju'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'dateLettrageReglement'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureJsonldFactureRead
     */
    'editionReleve'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureJsonldFactureRead
     */
    'nonRelancable'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FactureJsonldFactureRead
     */
    'idUserNonRelancable'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'dateNonRelancable'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureJsonldFactureRead
     */
    'rbtFraisAchats'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FactureJsonldFactureRead
     */
    'idFactureRefAvoir'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureJsonldFactureRead
     */
    'idFactureReleveAvoir'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureJsonldFactureRead
     */
    'venteIndirecte'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureJsonldFactureRead
     */
    'horsParrainage'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FactureJsonldFactureRead
     */
    'tiersParrain'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureJsonldFactureRead
     */
    'tauxParrainG'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureJsonldFactureRead
     */
    'tauxParrainHg'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureJsonldFactureRead
     */
    'traAchat'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'dateTraAchat'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureJsonldFactureRead
     */
    'factureMultiple'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'comNonRelancable'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureJsonldFactureRead
     */
    'exportG3'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'referenceInterne'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureJsonldFactureRead
     */
    'parrainageEdition'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureJsonldFactureRead
     */
    'retourNpai'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FactureJsonldFactureRead
     */
    'nImpNeotouch'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureJsonldFactureRead
     */
    'brouillonMultiple'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureJsonldFactureRead
     */
    'commande'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureJsonldFactureRead
     */
    'idDdm'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof FactureJsonldFactureRead
     */
    'intercoTraitee'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'dateTraitementInterco'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'numeroFactureInterco'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureJsonldFactureRead
     */
    'coef'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureJsonldFactureRead
     */
    'comRegie'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'dateComRegie'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureJsonldFactureRead
     */
    'fournisseurComRegie'?: number;
    /**
     * 
     * @type {number}
     * @memberof FactureJsonldFactureRead
     */
    'idTcf'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'releveRrrConfrereFile'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureJsonldFactureRead
     */
    'fnpExclus'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'cegidFile'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureJsonldFactureRead
     */
    'justifsDetailsInterdit'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FactureJsonldFactureRead
     */
    'nJustifsTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof FactureJsonldFactureRead
     */
    'nJustifsPayants'?: number;
    /**
     * 
     * @type {number}
     * @memberof FactureJsonldFactureRead
     */
    'nJustifsGratuits'?: number;
    /**
     * 
     * @type {number}
     * @memberof FactureJsonldFactureRead
     */
    'idPfj'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'numEngagement'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureJsonldFactureRead
     */
    'kdoTraite'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'dateTraitementKdo'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureJsonldFactureRead
     */
    'idUserKdoTraite'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof FactureJsonldFactureRead
     */
    'dnidTraite'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'dateTraitementDnid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'numAchatRrr'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'dateAchatRrr'?: string | null;
    /**
     * 
     * @type {Array<TblFligneJsonldFactureRead>}
     * @memberof FactureJsonldFactureRead
     */
    'lignes'?: Array<TblFligneJsonldFactureRead>;
}
/**
 * 
 * @export
 * @interface FactureJsonldFactureReadDossierFormalite
 */
export interface FactureJsonldFactureReadDossierFormalite {
    /**
     * 
     * @type {DevisJsonldDevisReadContext}
     * @memberof FactureJsonldFactureReadDossierFormalite
     */
    '@context'?: DevisJsonldDevisReadContext;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureReadDossierFormalite
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureReadDossierFormalite
     */
    '@type'?: string;
}
/**
 * 
 * @export
 * @interface FactureJsonldFactureReadPdf
 */
export interface FactureJsonldFactureReadPdf {
    /**
     * 
     * @type {DevisJsonldDevisReadContext}
     * @memberof FactureJsonldFactureReadPdf
     */
    '@context'?: DevisJsonldDevisReadContext;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureReadPdf
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureReadPdf
     */
    '@type'?: string;
    /**
     * Lien vers le pdf de la facture
     * @type {string}
     * @memberof FactureJsonldFactureReadPdf
     */
    'pdf'?: string;
    /**
     * Date d\'expiration des liens vers les pdf de la facture
     * @type {string}
     * @memberof FactureJsonldFactureReadPdf
     */
    'expires'?: string;
}
/**
 * 
 * @export
 * @interface FactureJsonldFactureUpdate
 */
export interface FactureJsonldFactureUpdate {
    /**
     * 
     * @type {DevisJsonldDevisReadContext}
     * @memberof FactureJsonldFactureUpdate
     */
    '@context'?: DevisJsonldDevisReadContext;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureUpdate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureUpdate
     */
    'numero'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureUpdate
     */
    'dossier'?: string | null;
    /**
     * 
     * @type {Array<TblFligneJsonldFactureUpdate>}
     * @memberof FactureJsonldFactureUpdate
     */
    'lignes'?: Array<TblFligneJsonldFactureUpdate>;
}
/**
 * 
 * @export
 * @interface FacturesMultiplesFactureMultipleCreate
 */
export interface FacturesMultiplesFactureMultipleCreate {
    /**
     * Liste des dossier à facturer
     * @type {Array<string>}
     * @memberof FacturesMultiplesFactureMultipleCreate
     */
    'dossiers'?: Array<string>;
    /**
     * 
     * @type {Array<TblFligneFactureMultipleCreate>}
     * @memberof FacturesMultiplesFactureMultipleCreate
     */
    'lignes'?: Array<TblFligneFactureMultipleCreate>;
}
/**
 * 
 * @export
 * @interface FacturesMultiplesFactureRead
 */
export interface FacturesMultiplesFactureRead {
    /**
     * 
     * @type {FacturesMultiplesFactureReadFacture}
     * @memberof FacturesMultiplesFactureRead
     */
    'facture'?: FacturesMultiplesFactureReadFacture | null;
}
/**
 * 
 * @export
 * @interface FacturesMultiplesFactureReadFacture
 */
export interface FacturesMultiplesFactureReadFacture {
    /**
     * identifiant de la facture
     * @type {number}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'numero'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'numeroDossier'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'dateFacture'?: string;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'prescripteur'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'client'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'raisonSociale'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'adresse1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'adresse2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'adresse3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'adresse4'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'codePostal'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'ville'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'tiersCorrespondance'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'raisonSocialeC'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'adresse1C'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'adresse2C'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'adresse3C'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'adresse4C'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'codePostalC'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'villeC'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'reference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'cliCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'totalHt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'totalTva'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'totalTtc'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'paye'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'dateExport'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'societe'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'contact'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'gratuit'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'remise'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'delaiPaiement'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'userCreation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'contactClient'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'tvaBase1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'tvaBase2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'tvaBase3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'tvaBase4'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'tvaTx1'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'tvaTx2'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'tvaTx3'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'tvaTx4'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'tvaMontant1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'tvaMontant2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'tvaMontant3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'tvaMontant4'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'montantPaiementRecu'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'userMaj'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'dateMaj'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'compta'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'contactFacture'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'contactCorrespondance'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'rrrPaye'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'datePaiementRrr'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'rrrRefuse'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'formalite'?: boolean | null;
    /**
     * 
     * @type {FactureFactureReadDossierFormalite}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'dossierFormalite'?: FactureFactureReadDossierFormalite | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'factureEnPreparation'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'societeFacture'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'facturationDiverse'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'idDossierFactureDiverse'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'comptaCegid'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'dateExportCegid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'cegidTvaEncaissement'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'cegidRegimeTva'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'cegidLibelle'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'statFacture'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'factureAvoir'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'factureAvoirRemise'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'cegidRejetExportCompta'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'cegidRejetDateExport'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'formalite2'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'dossier'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'adjNomTgi'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'adjDateVente'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'adjAffaire'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'adjDetailVente'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'dateEcheanceFacture'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'factureAdju'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'adjDossier'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'presentationAdju'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'dateLettrageReglement'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'editionReleve'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'nonRelancable'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'idUserNonRelancable'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'dateNonRelancable'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'rbtFraisAchats'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'idFactureRefAvoir'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'idFactureReleveAvoir'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'venteIndirecte'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'horsParrainage'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'tiersParrain'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'tauxParrainG'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'tauxParrainHg'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'traAchat'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'dateTraAchat'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'factureMultiple'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'comNonRelancable'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'exportG3'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'referenceInterne'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'parrainageEdition'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'retourNpai'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'nImpNeotouch'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'brouillonMultiple'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'commande'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'idDdm'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'intercoTraitee'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'dateTraitementInterco'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'numeroFactureInterco'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'coef'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'comRegie'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'dateComRegie'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'fournisseurComRegie'?: number;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'idTcf'?: number;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'releveRrrConfrereFile'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'fnpExclus'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'cegidFile'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'justifsDetailsInterdit'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'nJustifsTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'nJustifsPayants'?: number;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'nJustifsGratuits'?: number;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'idPfj'?: number;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'numEngagement'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'kdoTraite'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'dateTraitementKdo'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'idUserKdoTraite'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'dnidTraite'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'dateTraitementDnid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'numAchatRrr'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'dateAchatRrr'?: string | null;
    /**
     * 
     * @type {Array<TblFligneFactureRead>}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'lignes'?: Array<TblFligneFactureRead>;
}
/**
 * 
 * @export
 * @interface FacturesMultiplesJsonldFactureMultipleCreate
 */
export interface FacturesMultiplesJsonldFactureMultipleCreate {
    /**
     * 
     * @type {DevisJsonldDevisReadContext}
     * @memberof FacturesMultiplesJsonldFactureMultipleCreate
     */
    '@context'?: DevisJsonldDevisReadContext;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureMultipleCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureMultipleCreate
     */
    '@type'?: string;
    /**
     * Liste des dossier à facturer
     * @type {Array<string>}
     * @memberof FacturesMultiplesJsonldFactureMultipleCreate
     */
    'dossiers'?: Array<string>;
    /**
     * 
     * @type {Array<TblFligneJsonldFactureMultipleCreate>}
     * @memberof FacturesMultiplesJsonldFactureMultipleCreate
     */
    'lignes'?: Array<TblFligneJsonldFactureMultipleCreate>;
}
/**
 * 
 * @export
 * @interface FacturesMultiplesJsonldFactureRead
 */
export interface FacturesMultiplesJsonldFactureRead {
    /**
     * 
     * @type {DevisJsonldDevisReadContext}
     * @memberof FacturesMultiplesJsonldFactureRead
     */
    '@context'?: DevisJsonldDevisReadContext;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureRead
     */
    '@type'?: string;
    /**
     * 
     * @type {FacturesMultiplesJsonldFactureReadFacture}
     * @memberof FacturesMultiplesJsonldFactureRead
     */
    'facture'?: FacturesMultiplesJsonldFactureReadFacture | null;
}
/**
 * 
 * @export
 * @interface FacturesMultiplesJsonldFactureReadFacture
 */
export interface FacturesMultiplesJsonldFactureReadFacture {
    /**
     * 
     * @type {DevisJsonldDevisReadContext}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    '@context'?: DevisJsonldDevisReadContext;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    '@type'?: string;
    /**
     * identifiant de la facture
     * @type {number}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'numero'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'numeroDossier'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'dateFacture'?: string;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'prescripteur'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'client'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'raisonSociale'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'adresse1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'adresse2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'adresse3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'adresse4'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'codePostal'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'ville'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'tiersCorrespondance'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'raisonSocialeC'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'adresse1C'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'adresse2C'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'adresse3C'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'adresse4C'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'codePostalC'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'villeC'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'reference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'cliCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'totalHt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'totalTva'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'totalTtc'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'paye'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'dateExport'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'societe'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'contact'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'gratuit'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'remise'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'delaiPaiement'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'userCreation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'contactClient'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'tvaBase1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'tvaBase2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'tvaBase3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'tvaBase4'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'tvaTx1'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'tvaTx2'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'tvaTx3'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'tvaTx4'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'tvaMontant1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'tvaMontant2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'tvaMontant3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'tvaMontant4'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'montantPaiementRecu'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'userMaj'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'dateMaj'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'compta'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'contactFacture'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'contactCorrespondance'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'rrrPaye'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'datePaiementRrr'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'rrrRefuse'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'formalite'?: boolean | null;
    /**
     * 
     * @type {FactureJsonldFactureReadDossierFormalite}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'dossierFormalite'?: FactureJsonldFactureReadDossierFormalite | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'factureEnPreparation'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'societeFacture'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'facturationDiverse'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'idDossierFactureDiverse'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'comptaCegid'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'dateExportCegid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'cegidTvaEncaissement'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'cegidRegimeTva'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'cegidLibelle'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'statFacture'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'factureAvoir'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'factureAvoirRemise'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'cegidRejetExportCompta'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'cegidRejetDateExport'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'formalite2'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'dossier'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'adjNomTgi'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'adjDateVente'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'adjAffaire'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'adjDetailVente'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'dateEcheanceFacture'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'factureAdju'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'adjDossier'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'presentationAdju'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'dateLettrageReglement'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'editionReleve'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'nonRelancable'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'idUserNonRelancable'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'dateNonRelancable'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'rbtFraisAchats'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'idFactureRefAvoir'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'idFactureReleveAvoir'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'venteIndirecte'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'horsParrainage'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'tiersParrain'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'tauxParrainG'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'tauxParrainHg'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'traAchat'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'dateTraAchat'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'factureMultiple'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'comNonRelancable'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'exportG3'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'referenceInterne'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'parrainageEdition'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'retourNpai'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'nImpNeotouch'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'brouillonMultiple'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'commande'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'idDdm'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'intercoTraitee'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'dateTraitementInterco'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'numeroFactureInterco'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'coef'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'comRegie'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'dateComRegie'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'fournisseurComRegie'?: number;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'idTcf'?: number;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'releveRrrConfrereFile'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'fnpExclus'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'cegidFile'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'justifsDetailsInterdit'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'nJustifsTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'nJustifsPayants'?: number;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'nJustifsGratuits'?: number;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'idPfj'?: number;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'numEngagement'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'kdoTraite'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'dateTraitementKdo'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'idUserKdoTraite'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'dnidTraite'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'dateTraitementDnid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'numAchatRrr'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'dateAchatRrr'?: string | null;
    /**
     * 
     * @type {Array<TblFligneJsonldFactureRead>}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'lignes'?: Array<TblFligneJsonldFactureRead>;
}
/**
 * 
 * @export
 * @interface TblDevis
 */
export interface TblDevis {
    /**
     * 
     * @type {number}
     * @memberof TblDevis
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblDevis
     */
    'dossierAl'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblDevis
     */
    'dossier'?: string | null;
    /**
     * 
     * @type {TblDevisSociete}
     * @memberof TblDevis
     */
    'societe'?: TblDevisSociete | null;
    /**
     * 
     * @type {string}
     * @memberof TblDevis
     */
    'tiers'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDevis
     */
    'numero'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblDevis
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDevis
     */
    'auteur'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblDevis
     */
    'totalHt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblDevis
     */
    'totalTva'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblDevis
     */
    'totalTtc'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblDevis
     */
    'file'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblDevis
     */
    'actif'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblDevis
     */
    'demo'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblDevis
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof TblDevis
     */
    'lignes'?: Array<string>;
    /**
     * 
     * @type {any}
     * @memberof TblDevis
     */
    'pdfSimple'?: any;
    /**
     * 
     * @type {any}
     * @memberof TblDevis
     */
    'pdfDetail'?: any;
    /**
     * 
     * @type {any}
     * @memberof TblDevis
     */
    'expires'?: any;
}
/**
 * 
 * @export
 * @interface TblDevisDevisLignesRead
 */
export interface TblDevisDevisLignesRead {
    /**
     * 
     * @type {number}
     * @memberof TblDevisDevisLignesRead
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblDevisDevisLignesRead
     */
    'dossierAl'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblDevisDevisLignesRead
     */
    'dossier'?: string | null;
    /**
     * 
     * @type {TblDevisDevisLignesReadSociete}
     * @memberof TblDevisDevisLignesRead
     */
    'societe'?: TblDevisDevisLignesReadSociete | null;
    /**
     * 
     * @type {string}
     * @memberof TblDevisDevisLignesRead
     */
    'tiers'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDevisDevisLignesRead
     */
    'numero'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblDevisDevisLignesRead
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDevisDevisLignesRead
     */
    'auteur'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblDevisDevisLignesRead
     */
    'totalHt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblDevisDevisLignesRead
     */
    'totalTva'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblDevisDevisLignesRead
     */
    'totalTtc'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblDevisDevisLignesRead
     */
    'file'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblDevisDevisLignesRead
     */
    'actif'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblDevisDevisLignesRead
     */
    'demo'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblDevisDevisLignesRead
     */
    'deleted'?: boolean | null;
}
/**
 * 
 * @export
 * @interface TblDevisDevisLignesReadSociete
 */
export interface TblDevisDevisLignesReadSociete {
}
/**
 * 
 * @export
 * @interface TblDevisJsonld
 */
export interface TblDevisJsonld {
    /**
     * 
     * @type {DevisJsonldDevisReadContext}
     * @memberof TblDevisJsonld
     */
    '@context'?: DevisJsonldDevisReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblDevisJsonld
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDevisJsonld
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblDevisJsonld
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblDevisJsonld
     */
    'dossierAl'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblDevisJsonld
     */
    'dossier'?: string | null;
    /**
     * 
     * @type {TblDevisJsonldSociete}
     * @memberof TblDevisJsonld
     */
    'societe'?: TblDevisJsonldSociete | null;
    /**
     * 
     * @type {string}
     * @memberof TblDevisJsonld
     */
    'tiers'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDevisJsonld
     */
    'numero'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblDevisJsonld
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDevisJsonld
     */
    'auteur'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblDevisJsonld
     */
    'totalHt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblDevisJsonld
     */
    'totalTva'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblDevisJsonld
     */
    'totalTtc'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblDevisJsonld
     */
    'file'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblDevisJsonld
     */
    'actif'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblDevisJsonld
     */
    'demo'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblDevisJsonld
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof TblDevisJsonld
     */
    'lignes'?: Array<string>;
    /**
     * 
     * @type {any}
     * @memberof TblDevisJsonld
     */
    'pdfSimple'?: any;
    /**
     * 
     * @type {any}
     * @memberof TblDevisJsonld
     */
    'pdfDetail'?: any;
    /**
     * 
     * @type {any}
     * @memberof TblDevisJsonld
     */
    'expires'?: any;
}
/**
 * 
 * @export
 * @interface TblDevisJsonldDevisLignesRead
 */
export interface TblDevisJsonldDevisLignesRead {
    /**
     * 
     * @type {DevisJsonldDevisReadContext}
     * @memberof TblDevisJsonldDevisLignesRead
     */
    '@context'?: DevisJsonldDevisReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblDevisJsonldDevisLignesRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDevisJsonldDevisLignesRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblDevisJsonldDevisLignesRead
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblDevisJsonldDevisLignesRead
     */
    'dossierAl'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblDevisJsonldDevisLignesRead
     */
    'dossier'?: string | null;
    /**
     * 
     * @type {TblDevisJsonldDevisLignesReadSociete}
     * @memberof TblDevisJsonldDevisLignesRead
     */
    'societe'?: TblDevisJsonldDevisLignesReadSociete | null;
    /**
     * 
     * @type {string}
     * @memberof TblDevisJsonldDevisLignesRead
     */
    'tiers'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDevisJsonldDevisLignesRead
     */
    'numero'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblDevisJsonldDevisLignesRead
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDevisJsonldDevisLignesRead
     */
    'auteur'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblDevisJsonldDevisLignesRead
     */
    'totalHt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblDevisJsonldDevisLignesRead
     */
    'totalTva'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblDevisJsonldDevisLignesRead
     */
    'totalTtc'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblDevisJsonldDevisLignesRead
     */
    'file'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblDevisJsonldDevisLignesRead
     */
    'actif'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblDevisJsonldDevisLignesRead
     */
    'demo'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblDevisJsonldDevisLignesRead
     */
    'deleted'?: boolean | null;
}
/**
 * 
 * @export
 * @interface TblDevisJsonldDevisLignesReadSociete
 */
export interface TblDevisJsonldDevisLignesReadSociete {
    /**
     * 
     * @type {DevisJsonldDevisReadContext}
     * @memberof TblDevisJsonldDevisLignesReadSociete
     */
    '@context'?: DevisJsonldDevisReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblDevisJsonldDevisLignesReadSociete
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDevisJsonldDevisLignesReadSociete
     */
    '@type'?: string;
}
/**
 * 
 * @export
 * @interface TblDevisJsonldSociete
 */
export interface TblDevisJsonldSociete {
    /**
     * 
     * @type {DevisJsonldDevisReadContext}
     * @memberof TblDevisJsonldSociete
     */
    '@context'?: DevisJsonldDevisReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblDevisJsonldSociete
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDevisJsonldSociete
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblDevisJsonldSociete
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblDevisJsonldSociete
     */
    'siren'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDevisJsonldSociete
     */
    'raisonSociale'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDevisJsonldSociete
     */
    'nomCommercial'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDevisJsonldSociete
     */
    'adresse1'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDevisJsonldSociete
     */
    'adresse2'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDevisJsonldSociete
     */
    'adresse3'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDevisJsonldSociete
     */
    'adresse4'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDevisJsonldSociete
     */
    'codePostal'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDevisJsonldSociete
     */
    'ville'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDevisJsonldSociete
     */
    'pays'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDevisJsonldSociete
     */
    'libPays'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDevisJsonldSociete
     */
    'effectif'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDevisJsonldSociete
     */
    'sigle'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDevisJsonldSociete
     */
    'codeNaf'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDevisJsonldSociete
     */
    'codeApe'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TblDevisJsonldSociete
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TblDevisJsonldSociete
     */
    'dateCreation'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDevisJsonldSociete
     */
    'userCreation'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblDevisJsonldSociete
     */
    'userMAJ'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblDevisJsonldSociete
     */
    'dateMAJ'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblDevisJsonldSociete
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDevisJsonldSociete
     */
    'siteWeb'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDevisJsonldSociete
     */
    'telephone'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDevisJsonldSociete
     */
    'fax'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDevisJsonldSociete
     */
    'piedDePage'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDevisJsonldSociete
     */
    'compteBanque'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDevisJsonldSociete
     */
    'numeroRcs'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDevisJsonldSociete
     */
    'numeroTvaIntracommunautaire'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDevisJsonldSociete
     */
    'abreviationRaisonSociale'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblDevisJsonldSociete
     */
    'tvaSurEncaissement'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblDevisJsonldSociete
     */
    'regimeTvaCegid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblDevisJsonldSociete
     */
    'telephoneCompta'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblDevisJsonldSociete
     */
    'facturanteUtilise'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TblDevisJsonldSociete
     */
    'codeBic'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblDevisJsonldSociete
     */
    'headerFacture'?: string | null;
}
/**
 * 
 * @export
 * @interface TblDevisSociete
 */
export interface TblDevisSociete {
    /**
     * 
     * @type {number}
     * @memberof TblDevisSociete
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblDevisSociete
     */
    'siren'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDevisSociete
     */
    'raisonSociale'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDevisSociete
     */
    'nomCommercial'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDevisSociete
     */
    'adresse1'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDevisSociete
     */
    'adresse2'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDevisSociete
     */
    'adresse3'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDevisSociete
     */
    'adresse4'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDevisSociete
     */
    'codePostal'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDevisSociete
     */
    'ville'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDevisSociete
     */
    'pays'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDevisSociete
     */
    'libPays'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDevisSociete
     */
    'effectif'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDevisSociete
     */
    'sigle'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDevisSociete
     */
    'codeNaf'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDevisSociete
     */
    'codeApe'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TblDevisSociete
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TblDevisSociete
     */
    'dateCreation'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDevisSociete
     */
    'userCreation'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblDevisSociete
     */
    'userMAJ'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblDevisSociete
     */
    'dateMAJ'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblDevisSociete
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDevisSociete
     */
    'siteWeb'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDevisSociete
     */
    'telephone'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDevisSociete
     */
    'fax'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDevisSociete
     */
    'piedDePage'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDevisSociete
     */
    'compteBanque'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDevisSociete
     */
    'numeroRcs'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDevisSociete
     */
    'numeroTvaIntracommunautaire'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDevisSociete
     */
    'abreviationRaisonSociale'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblDevisSociete
     */
    'tvaSurEncaissement'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblDevisSociete
     */
    'regimeTvaCegid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblDevisSociete
     */
    'telephoneCompta'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblDevisSociete
     */
    'facturanteUtilise'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TblDevisSociete
     */
    'codeBic'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblDevisSociete
     */
    'headerFacture'?: string | null;
}
/**
 * 
 * @export
 * @interface TblDossierFormaliteJsonldFactureRead
 */
export interface TblDossierFormaliteJsonldFactureRead {
    /**
     * 
     * @type {DevisJsonldDevisReadContext}
     * @memberof TblDossierFormaliteJsonldFactureRead
     */
    '@context'?: DevisJsonldDevisReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblDossierFormaliteJsonldFactureRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDossierFormaliteJsonldFactureRead
     */
    '@type'?: string;
}
/**
 * 
 * @export
 * @interface TblFacture2FactureRead
 */
export interface TblFacture2FactureRead {
    /**
     * identifiant de la facture
     * @type {number}
     * @memberof TblFacture2FactureRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'numero'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2FactureRead
     */
    'numeroDossier'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'dateFacture'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2FactureRead
     */
    'prescripteur'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2FactureRead
     */
    'client'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'raisonSociale'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'adresse1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'adresse2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'adresse3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'adresse4'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'codePostal'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'ville'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2FactureRead
     */
    'tiersCorrespondance'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'raisonSocialeC'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'adresse1C'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'adresse2C'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'adresse3C'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'adresse4C'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'codePostalC'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'villeC'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'reference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'cliCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'totalHt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'totalTva'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'totalTtc'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2FactureRead
     */
    'paye'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'dateExport'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2FactureRead
     */
    'societe'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2FactureRead
     */
    'contact'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2FactureRead
     */
    'gratuit'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'remise'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2FactureRead
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2FactureRead
     */
    'delaiPaiement'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2FactureRead
     */
    'userCreation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2FactureRead
     */
    'contactClient'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'tvaBase1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'tvaBase2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'tvaBase3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'tvaBase4'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2FactureRead
     */
    'tvaTx1'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2FactureRead
     */
    'tvaTx2'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2FactureRead
     */
    'tvaTx3'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2FactureRead
     */
    'tvaTx4'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'tvaMontant1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'tvaMontant2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'tvaMontant3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'tvaMontant4'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'montantPaiementRecu'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2FactureRead
     */
    'userMaj'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'dateMaj'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2FactureRead
     */
    'compta'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'contactFacture'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'contactCorrespondance'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2FactureRead
     */
    'rrrPaye'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'datePaiementRrr'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2FactureRead
     */
    'rrrRefuse'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2FactureRead
     */
    'formalite'?: boolean | null;
    /**
     * 
     * @type {FactureFactureReadDossierFormalite}
     * @memberof TblFacture2FactureRead
     */
    'dossierFormalite'?: FactureFactureReadDossierFormalite | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2FactureRead
     */
    'factureEnPreparation'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2FactureRead
     */
    'societeFacture'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2FactureRead
     */
    'facturationDiverse'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2FactureRead
     */
    'idDossierFactureDiverse'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2FactureRead
     */
    'comptaCegid'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'dateExportCegid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'cegidTvaEncaissement'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'cegidRegimeTva'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'cegidLibelle'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2FactureRead
     */
    'statFacture'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2FactureRead
     */
    'factureAvoir'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2FactureRead
     */
    'factureAvoirRemise'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2FactureRead
     */
    'cegidRejetExportCompta'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'cegidRejetDateExport'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2FactureRead
     */
    'formalite2'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'dossier'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'adjNomTgi'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'adjDateVente'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'adjAffaire'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'adjDetailVente'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'dateEcheanceFacture'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2FactureRead
     */
    'factureAdju'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'adjDossier'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2FactureRead
     */
    'presentationAdju'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'dateLettrageReglement'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2FactureRead
     */
    'editionReleve'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2FactureRead
     */
    'nonRelancable'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2FactureRead
     */
    'idUserNonRelancable'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'dateNonRelancable'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2FactureRead
     */
    'rbtFraisAchats'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2FactureRead
     */
    'idFactureRefAvoir'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2FactureRead
     */
    'idFactureReleveAvoir'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2FactureRead
     */
    'venteIndirecte'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2FactureRead
     */
    'horsParrainage'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2FactureRead
     */
    'tiersParrain'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2FactureRead
     */
    'tauxParrainG'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2FactureRead
     */
    'tauxParrainHg'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2FactureRead
     */
    'traAchat'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'dateTraAchat'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2FactureRead
     */
    'factureMultiple'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'comNonRelancable'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2FactureRead
     */
    'exportG3'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'referenceInterne'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2FactureRead
     */
    'parrainageEdition'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2FactureRead
     */
    'retourNpai'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2FactureRead
     */
    'nImpNeotouch'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2FactureRead
     */
    'brouillonMultiple'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2FactureRead
     */
    'commande'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2FactureRead
     */
    'idDdm'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2FactureRead
     */
    'intercoTraitee'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'dateTraitementInterco'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'numeroFactureInterco'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2FactureRead
     */
    'coef'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2FactureRead
     */
    'comRegie'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'dateComRegie'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2FactureRead
     */
    'fournisseurComRegie'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2FactureRead
     */
    'idTcf'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'releveRrrConfrereFile'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2FactureRead
     */
    'fnpExclus'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'cegidFile'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2FactureRead
     */
    'justifsDetailsInterdit'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2FactureRead
     */
    'nJustifsTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2FactureRead
     */
    'nJustifsPayants'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2FactureRead
     */
    'nJustifsGratuits'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2FactureRead
     */
    'idPfj'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'numEngagement'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2FactureRead
     */
    'kdoTraite'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'dateTraitementKdo'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2FactureRead
     */
    'idUserKdoTraite'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2FactureRead
     */
    'dnidTraite'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'dateTraitementDnid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'numAchatRrr'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'dateAchatRrr'?: string | null;
    /**
     * 
     * @type {Array<TblFligneFactureRead>}
     * @memberof TblFacture2FactureRead
     */
    'lignes'?: Array<TblFligneFactureRead>;
}
/**
 * 
 * @export
 * @interface TblFacture2JsonldFactureRead
 */
export interface TblFacture2JsonldFactureRead {
    /**
     * 
     * @type {DevisJsonldDevisReadContext}
     * @memberof TblFacture2JsonldFactureRead
     */
    '@context'?: DevisJsonldDevisReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    '@type'?: string;
    /**
     * identifiant de la facture
     * @type {number}
     * @memberof TblFacture2JsonldFactureRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'numero'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2JsonldFactureRead
     */
    'numeroDossier'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'dateFacture'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2JsonldFactureRead
     */
    'prescripteur'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2JsonldFactureRead
     */
    'client'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'raisonSociale'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'adresse1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'adresse2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'adresse3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'adresse4'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'codePostal'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'ville'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2JsonldFactureRead
     */
    'tiersCorrespondance'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'raisonSocialeC'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'adresse1C'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'adresse2C'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'adresse3C'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'adresse4C'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'codePostalC'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'villeC'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'reference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'cliCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'totalHt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'totalTva'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'totalTtc'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2JsonldFactureRead
     */
    'paye'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'dateExport'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2JsonldFactureRead
     */
    'societe'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2JsonldFactureRead
     */
    'contact'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2JsonldFactureRead
     */
    'gratuit'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'remise'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2JsonldFactureRead
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2JsonldFactureRead
     */
    'delaiPaiement'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2JsonldFactureRead
     */
    'userCreation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2JsonldFactureRead
     */
    'contactClient'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'tvaBase1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'tvaBase2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'tvaBase3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'tvaBase4'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2JsonldFactureRead
     */
    'tvaTx1'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2JsonldFactureRead
     */
    'tvaTx2'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2JsonldFactureRead
     */
    'tvaTx3'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2JsonldFactureRead
     */
    'tvaTx4'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'tvaMontant1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'tvaMontant2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'tvaMontant3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'tvaMontant4'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'montantPaiementRecu'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2JsonldFactureRead
     */
    'userMaj'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'dateMaj'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2JsonldFactureRead
     */
    'compta'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'contactFacture'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'contactCorrespondance'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2JsonldFactureRead
     */
    'rrrPaye'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'datePaiementRrr'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2JsonldFactureRead
     */
    'rrrRefuse'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2JsonldFactureRead
     */
    'formalite'?: boolean | null;
    /**
     * 
     * @type {FactureJsonldFactureReadDossierFormalite}
     * @memberof TblFacture2JsonldFactureRead
     */
    'dossierFormalite'?: FactureJsonldFactureReadDossierFormalite | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2JsonldFactureRead
     */
    'factureEnPreparation'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2JsonldFactureRead
     */
    'societeFacture'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2JsonldFactureRead
     */
    'facturationDiverse'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2JsonldFactureRead
     */
    'idDossierFactureDiverse'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2JsonldFactureRead
     */
    'comptaCegid'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'dateExportCegid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'cegidTvaEncaissement'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'cegidRegimeTva'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'cegidLibelle'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2JsonldFactureRead
     */
    'statFacture'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2JsonldFactureRead
     */
    'factureAvoir'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2JsonldFactureRead
     */
    'factureAvoirRemise'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2JsonldFactureRead
     */
    'cegidRejetExportCompta'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'cegidRejetDateExport'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2JsonldFactureRead
     */
    'formalite2'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'dossier'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'adjNomTgi'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'adjDateVente'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'adjAffaire'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'adjDetailVente'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'dateEcheanceFacture'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2JsonldFactureRead
     */
    'factureAdju'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'adjDossier'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2JsonldFactureRead
     */
    'presentationAdju'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'dateLettrageReglement'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2JsonldFactureRead
     */
    'editionReleve'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2JsonldFactureRead
     */
    'nonRelancable'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2JsonldFactureRead
     */
    'idUserNonRelancable'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'dateNonRelancable'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2JsonldFactureRead
     */
    'rbtFraisAchats'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2JsonldFactureRead
     */
    'idFactureRefAvoir'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2JsonldFactureRead
     */
    'idFactureReleveAvoir'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2JsonldFactureRead
     */
    'venteIndirecte'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2JsonldFactureRead
     */
    'horsParrainage'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2JsonldFactureRead
     */
    'tiersParrain'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2JsonldFactureRead
     */
    'tauxParrainG'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2JsonldFactureRead
     */
    'tauxParrainHg'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2JsonldFactureRead
     */
    'traAchat'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'dateTraAchat'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2JsonldFactureRead
     */
    'factureMultiple'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'comNonRelancable'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2JsonldFactureRead
     */
    'exportG3'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'referenceInterne'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2JsonldFactureRead
     */
    'parrainageEdition'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2JsonldFactureRead
     */
    'retourNpai'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2JsonldFactureRead
     */
    'nImpNeotouch'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2JsonldFactureRead
     */
    'brouillonMultiple'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2JsonldFactureRead
     */
    'commande'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2JsonldFactureRead
     */
    'idDdm'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2JsonldFactureRead
     */
    'intercoTraitee'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'dateTraitementInterco'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'numeroFactureInterco'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2JsonldFactureRead
     */
    'coef'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2JsonldFactureRead
     */
    'comRegie'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'dateComRegie'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2JsonldFactureRead
     */
    'fournisseurComRegie'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2JsonldFactureRead
     */
    'idTcf'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'releveRrrConfrereFile'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2JsonldFactureRead
     */
    'fnpExclus'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'cegidFile'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2JsonldFactureRead
     */
    'justifsDetailsInterdit'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2JsonldFactureRead
     */
    'nJustifsTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2JsonldFactureRead
     */
    'nJustifsPayants'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2JsonldFactureRead
     */
    'nJustifsGratuits'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2JsonldFactureRead
     */
    'idPfj'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'numEngagement'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2JsonldFactureRead
     */
    'kdoTraite'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'dateTraitementKdo'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2JsonldFactureRead
     */
    'idUserKdoTraite'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2JsonldFactureRead
     */
    'dnidTraite'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'dateTraitementDnid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'numAchatRrr'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'dateAchatRrr'?: string | null;
    /**
     * 
     * @type {Array<TblFligneJsonldFactureRead>}
     * @memberof TblFacture2JsonldFactureRead
     */
    'lignes'?: Array<TblFligneJsonldFactureRead>;
}
/**
 * 
 * @export
 * @interface TblFligneFactureCreate
 */
export interface TblFligneFactureCreate {
    /**
     * Numero de la ligne (permet de jouer sur l\'ordre d\'apparition dans le pdf)
     * @type {number}
     * @memberof TblFligneFactureCreate
     */
    'numeroLigne'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneFactureCreate
     */
    'rubriqueFacturation': string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneFactureCreate
     */
    'libelle': string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneFactureCreate
     */
    'unite': string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFligneFactureCreate
     */
    'quantite': number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneFactureCreate
     */
    'prixBase': string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFligneFactureCreate
     */
    'tauxTva': number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFligneFactureCreate
     */
    'idAnnonce'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneFactureCreate
     */
    'articleDossierKey'?: string | null;
}
/**
 * 
 * @export
 * @interface TblFligneFactureMultipleCreate
 */
export interface TblFligneFactureMultipleCreate {
    /**
     * Numero de la ligne (permet de jouer sur l\'ordre d\'apparition dans le pdf)
     * @type {number}
     * @memberof TblFligneFactureMultipleCreate
     */
    'numeroLigne'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneFactureMultipleCreate
     */
    'rubriqueFacturation': string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneFactureMultipleCreate
     */
    'libelle': string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneFactureMultipleCreate
     */
    'unite': string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFligneFactureMultipleCreate
     */
    'quantite': number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneFactureMultipleCreate
     */
    'prixBase': string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFligneFactureMultipleCreate
     */
    'tauxTva': number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFligneFactureMultipleCreate
     */
    'idAnnonce'?: number | null;
}
/**
 * 
 * @export
 * @interface TblFligneFactureRead
 */
export interface TblFligneFactureRead {
    /**
     * 
     * @type {number}
     * @memberof TblFligneFactureRead
     */
    'id'?: number;
    /**
     * Numero de la ligne (permet de jouer sur l\'ordre d\'apparition dans le pdf)
     * @type {number}
     * @memberof TblFligneFactureRead
     */
    'numeroLigne'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFligneFactureRead
     */
    'jal'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneFactureRead
     */
    'rubriqueFacturation': string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneFactureRead
     */
    'libelle': string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneFactureRead
     */
    'unite': string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFligneFactureRead
     */
    'quantite': number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneFactureRead
     */
    'prixBase': string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFligneFactureRead
     */
    'tauxTva': number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneFactureRead
     */
    'montant'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFligneFactureRead
     */
    'debours'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneFactureRead
     */
    'compte'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFligneFactureRead
     */
    'affiche'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneFactureRead
     */
    'flux'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFligneFactureRead
     */
    'formalite'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof TblFligneFactureRead
     */
    'idAnnonce'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFligneFactureRead
     */
    'factureDiverse'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneFactureRead
     */
    'cegidCompte'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneFactureRead
     */
    'cegidSp1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneFactureRead
     */
    'cegidSp2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneFactureRead
     */
    'cegidTl1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneFactureRead
     */
    'cegidTl2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneFactureRead
     */
    'cegidTl3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneFactureRead
     */
    'dossier'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFligneFactureRead
     */
    'dprTypeTarif'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblFligneFactureRead
     */
    'idTat'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblFligneFactureRead
     */
    'idArticle'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TblFligneFactureRead
     */
    'rrrAvoInterdit'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TblFligneFactureRead
     */
    'htReference'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblFligneFactureRead
     */
    'dnidVersionPresta'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblFligneFactureRead
     */
    'idDrt'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TblFligneFactureRead
     */
    'rrrPaye'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TblFligneFactureRead
     */
    'datePaiementRrr'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFligneFactureRead
     */
    'rrrRefuse'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TblFligneFactureRead
     */
    'rrrAvoTraite'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TblFligneFactureRead
     */
    'dateTraitementRrrAvo'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFligneFactureRead
     */
    'tarifLogoEnteteV3'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TblFligneFactureRead
     */
    'rrrAvoReferenceTheorique'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TblFligneFactureRead
     */
    'rrrAvoReferenceReel'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TblFligneFactureRead
     */
    'tauxRrrAvoReel'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblFligneFactureRead
     */
    'dateRrrAvoReel'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFligneFactureRead
     */
    'idUserRrrAvoReel'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TblFligneFactureRead
     */
    'cmdDrtIdDetails'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TblFligneFactureRead
     */
    'articleDossierKey'?: string | null;
}
/**
 * 
 * @export
 * @interface TblFligneFactureUpdate
 */
export interface TblFligneFactureUpdate {
    /**
     * Numero de la ligne (permet de jouer sur l\'ordre d\'apparition dans le pdf)
     * @type {number}
     * @memberof TblFligneFactureUpdate
     */
    'numeroLigne'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneFactureUpdate
     */
    'rubriqueFacturation': string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneFactureUpdate
     */
    'libelle': string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneFactureUpdate
     */
    'unite': string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFligneFactureUpdate
     */
    'quantite': number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneFactureUpdate
     */
    'prixBase': string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFligneFactureUpdate
     */
    'tauxTva': number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFligneFactureUpdate
     */
    'idAnnonce'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneFactureUpdate
     */
    'articleDossierKey'?: string | null;
}
/**
 * 
 * @export
 * @interface TblFligneJsonldFactureCreate
 */
export interface TblFligneJsonldFactureCreate {
    /**
     * 
     * @type {DevisJsonldDevisReadContext}
     * @memberof TblFligneJsonldFactureCreate
     */
    '@context'?: DevisJsonldDevisReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureCreate
     */
    '@type'?: string;
    /**
     * Numero de la ligne (permet de jouer sur l\'ordre d\'apparition dans le pdf)
     * @type {number}
     * @memberof TblFligneJsonldFactureCreate
     */
    'numeroLigne'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureCreate
     */
    'rubriqueFacturation': string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureCreate
     */
    'libelle': string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureCreate
     */
    'unite': string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFligneJsonldFactureCreate
     */
    'quantite': number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureCreate
     */
    'prixBase': string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFligneJsonldFactureCreate
     */
    'tauxTva': number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFligneJsonldFactureCreate
     */
    'idAnnonce'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureCreate
     */
    'articleDossierKey'?: string | null;
}
/**
 * 
 * @export
 * @interface TblFligneJsonldFactureMultipleCreate
 */
export interface TblFligneJsonldFactureMultipleCreate {
    /**
     * 
     * @type {DevisJsonldDevisReadContext}
     * @memberof TblFligneJsonldFactureMultipleCreate
     */
    '@context'?: DevisJsonldDevisReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureMultipleCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureMultipleCreate
     */
    '@type'?: string;
    /**
     * Numero de la ligne (permet de jouer sur l\'ordre d\'apparition dans le pdf)
     * @type {number}
     * @memberof TblFligneJsonldFactureMultipleCreate
     */
    'numeroLigne'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureMultipleCreate
     */
    'rubriqueFacturation': string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureMultipleCreate
     */
    'libelle': string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureMultipleCreate
     */
    'unite': string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFligneJsonldFactureMultipleCreate
     */
    'quantite': number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureMultipleCreate
     */
    'prixBase': string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFligneJsonldFactureMultipleCreate
     */
    'tauxTva': number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFligneJsonldFactureMultipleCreate
     */
    'idAnnonce'?: number | null;
}
/**
 * 
 * @export
 * @interface TblFligneJsonldFactureRead
 */
export interface TblFligneJsonldFactureRead {
    /**
     * 
     * @type {DevisJsonldDevisReadContext}
     * @memberof TblFligneJsonldFactureRead
     */
    '@context'?: DevisJsonldDevisReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblFligneJsonldFactureRead
     */
    'id'?: number;
    /**
     * Numero de la ligne (permet de jouer sur l\'ordre d\'apparition dans le pdf)
     * @type {number}
     * @memberof TblFligneJsonldFactureRead
     */
    'numeroLigne'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFligneJsonldFactureRead
     */
    'jal'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureRead
     */
    'rubriqueFacturation': string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureRead
     */
    'libelle': string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureRead
     */
    'unite': string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFligneJsonldFactureRead
     */
    'quantite': number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureRead
     */
    'prixBase': string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFligneJsonldFactureRead
     */
    'tauxTva': number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureRead
     */
    'montant'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFligneJsonldFactureRead
     */
    'debours'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureRead
     */
    'compte'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFligneJsonldFactureRead
     */
    'affiche'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureRead
     */
    'flux'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFligneJsonldFactureRead
     */
    'formalite'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof TblFligneJsonldFactureRead
     */
    'idAnnonce'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFligneJsonldFactureRead
     */
    'factureDiverse'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureRead
     */
    'cegidCompte'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureRead
     */
    'cegidSp1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureRead
     */
    'cegidSp2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureRead
     */
    'cegidTl1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureRead
     */
    'cegidTl2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureRead
     */
    'cegidTl3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureRead
     */
    'dossier'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFligneJsonldFactureRead
     */
    'dprTypeTarif'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblFligneJsonldFactureRead
     */
    'idTat'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblFligneJsonldFactureRead
     */
    'idArticle'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TblFligneJsonldFactureRead
     */
    'rrrAvoInterdit'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureRead
     */
    'htReference'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblFligneJsonldFactureRead
     */
    'dnidVersionPresta'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblFligneJsonldFactureRead
     */
    'idDrt'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TblFligneJsonldFactureRead
     */
    'rrrPaye'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureRead
     */
    'datePaiementRrr'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFligneJsonldFactureRead
     */
    'rrrRefuse'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TblFligneJsonldFactureRead
     */
    'rrrAvoTraite'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureRead
     */
    'dateTraitementRrrAvo'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFligneJsonldFactureRead
     */
    'tarifLogoEnteteV3'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TblFligneJsonldFactureRead
     */
    'rrrAvoReferenceTheorique'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TblFligneJsonldFactureRead
     */
    'rrrAvoReferenceReel'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureRead
     */
    'tauxRrrAvoReel'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureRead
     */
    'dateRrrAvoReel'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFligneJsonldFactureRead
     */
    'idUserRrrAvoReel'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TblFligneJsonldFactureRead
     */
    'cmdDrtIdDetails'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureRead
     */
    'articleDossierKey'?: string | null;
}
/**
 * 
 * @export
 * @interface TblFligneJsonldFactureUpdate
 */
export interface TblFligneJsonldFactureUpdate {
    /**
     * 
     * @type {DevisJsonldDevisReadContext}
     * @memberof TblFligneJsonldFactureUpdate
     */
    '@context'?: DevisJsonldDevisReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureUpdate
     */
    '@type'?: string;
    /**
     * Numero de la ligne (permet de jouer sur l\'ordre d\'apparition dans le pdf)
     * @type {number}
     * @memberof TblFligneJsonldFactureUpdate
     */
    'numeroLigne'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureUpdate
     */
    'rubriqueFacturation': string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureUpdate
     */
    'libelle': string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureUpdate
     */
    'unite': string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFligneJsonldFactureUpdate
     */
    'quantite': number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureUpdate
     */
    'prixBase': string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFligneJsonldFactureUpdate
     */
    'tauxTva': number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFligneJsonldFactureUpdate
     */
    'idAnnonce'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureUpdate
     */
    'articleDossierKey'?: string | null;
}
/**
 * 
 * @export
 * @interface TblSociete
 */
export interface TblSociete {
    /**
     * 
     * @type {number}
     * @memberof TblSociete
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblSociete
     */
    'siren'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSociete
     */
    'raisonSociale'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSociete
     */
    'nomCommercial'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSociete
     */
    'adresse1'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSociete
     */
    'adresse2'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSociete
     */
    'adresse3'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSociete
     */
    'adresse4'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSociete
     */
    'codePostal'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSociete
     */
    'ville'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSociete
     */
    'pays'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSociete
     */
    'libPays'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSociete
     */
    'effectif'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSociete
     */
    'sigle'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSociete
     */
    'codeNaf'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSociete
     */
    'codeApe'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TblSociete
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TblSociete
     */
    'dateCreation'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSociete
     */
    'userCreation'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblSociete
     */
    'userMAJ'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblSociete
     */
    'dateMAJ'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblSociete
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSociete
     */
    'siteWeb'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSociete
     */
    'telephone'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSociete
     */
    'fax'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSociete
     */
    'piedDePage'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSociete
     */
    'compteBanque'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSociete
     */
    'numeroRcs'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSociete
     */
    'numeroTvaIntracommunautaire'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSociete
     */
    'abreviationRaisonSociale'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblSociete
     */
    'tvaSurEncaissement'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblSociete
     */
    'regimeTvaCegid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblSociete
     */
    'telephoneCompta'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblSociete
     */
    'facturanteUtilise'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TblSociete
     */
    'codeBic'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblSociete
     */
    'headerFacture'?: string | null;
}
/**
 * 
 * @export
 * @interface TblSocieteJsonld
 */
export interface TblSocieteJsonld {
    /**
     * 
     * @type {DevisJsonldDevisReadContext}
     * @memberof TblSocieteJsonld
     */
    '@context'?: DevisJsonldDevisReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblSocieteJsonld
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSocieteJsonld
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblSocieteJsonld
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblSocieteJsonld
     */
    'siren'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSocieteJsonld
     */
    'raisonSociale'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSocieteJsonld
     */
    'nomCommercial'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSocieteJsonld
     */
    'adresse1'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSocieteJsonld
     */
    'adresse2'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSocieteJsonld
     */
    'adresse3'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSocieteJsonld
     */
    'adresse4'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSocieteJsonld
     */
    'codePostal'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSocieteJsonld
     */
    'ville'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSocieteJsonld
     */
    'pays'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSocieteJsonld
     */
    'libPays'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSocieteJsonld
     */
    'effectif'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSocieteJsonld
     */
    'sigle'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSocieteJsonld
     */
    'codeNaf'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSocieteJsonld
     */
    'codeApe'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TblSocieteJsonld
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TblSocieteJsonld
     */
    'dateCreation'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSocieteJsonld
     */
    'userCreation'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblSocieteJsonld
     */
    'userMAJ'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblSocieteJsonld
     */
    'dateMAJ'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblSocieteJsonld
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSocieteJsonld
     */
    'siteWeb'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSocieteJsonld
     */
    'telephone'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSocieteJsonld
     */
    'fax'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSocieteJsonld
     */
    'piedDePage'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSocieteJsonld
     */
    'compteBanque'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSocieteJsonld
     */
    'numeroRcs'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSocieteJsonld
     */
    'numeroTvaIntracommunautaire'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSocieteJsonld
     */
    'abreviationRaisonSociale'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblSocieteJsonld
     */
    'tvaSurEncaissement'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblSocieteJsonld
     */
    'regimeTvaCegid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblSocieteJsonld
     */
    'telephoneCompta'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblSocieteJsonld
     */
    'facturanteUtilise'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TblSocieteJsonld
     */
    'codeBic'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblSocieteJsonld
     */
    'headerFacture'?: string | null;
}
/**
 * 
 * @export
 * @interface TblSocieteJsonldDevisLignesRead
 */
export interface TblSocieteJsonldDevisLignesRead {
    /**
     * 
     * @type {DevisJsonldDevisReadContext}
     * @memberof TblSocieteJsonldDevisLignesRead
     */
    '@context'?: DevisJsonldDevisReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblSocieteJsonldDevisLignesRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSocieteJsonldDevisLignesRead
     */
    '@type'?: string;
}
/**
 * 
 * @export
 * @interface TblSocieteJsonldDevisRead
 */
export interface TblSocieteJsonldDevisRead {
    /**
     * 
     * @type {DevisJsonldDevisReadContext}
     * @memberof TblSocieteJsonldDevisRead
     */
    '@context'?: DevisJsonldDevisReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblSocieteJsonldDevisRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblSocieteJsonldDevisRead
     */
    '@type'?: string;
}

/**
 * DevisApi - axios parameter creator
 * @export
 */
export const DevisApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Récupérer les informations d\'un devis
         * @summary Récupérer les informations d\'un devis
         * @param {string} id TblDevis identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDevisGetDevisByIdItem: async (id: string, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiDevisGetDevisByIdItem', 'id', id)
            const localVarPath = `/facturation/devis/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtenir la liste des devis
         * @summary Obtenir la liste des devis
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [dossierId] 
         * @param {Array<number>} [dossierId2] 
         * @param {number} [lignesIdAnnonce] 
         * @param {Array<number>} [lignesIdAnnonce2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDevisGetDevisCollection: async (xIdSociete?: number, page?: number, dossierId?: number, dossierId2?: Array<number>, lignesIdAnnonce?: number, lignesIdAnnonce2?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/facturation/devis`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (dossierId !== undefined) {
                localVarQueryParameter['dossier.id'] = dossierId;
            }

            if (dossierId2) {
                localVarQueryParameter['dossier.id[]'] = dossierId2;
            }

            if (lignesIdAnnonce !== undefined) {
                localVarQueryParameter['lignes.idAnnonce'] = lignesIdAnnonce;
            }

            if (lignesIdAnnonce2) {
                localVarQueryParameter['lignes.idAnnonce[]'] = lignesIdAnnonce2;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DevisApi - functional programming interface
 * @export
 */
export const DevisApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DevisApiAxiosParamCreator(configuration)
    return {
        /**
         * Récupérer les informations d\'un devis
         * @summary Récupérer les informations d\'un devis
         * @param {string} id TblDevis identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDevisGetDevisByIdItem(id: string, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DevisJsonldDevisRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDevisGetDevisByIdItem(id, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtenir la liste des devis
         * @summary Obtenir la liste des devis
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [dossierId] 
         * @param {Array<number>} [dossierId2] 
         * @param {number} [lignesIdAnnonce] 
         * @param {Array<number>} [lignesIdAnnonce2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDevisGetDevisCollection(xIdSociete?: number, page?: number, dossierId?: number, dossierId2?: Array<number>, lignesIdAnnonce?: number, lignesIdAnnonce2?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiDevisGetDevisCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDevisGetDevisCollection(xIdSociete, page, dossierId, dossierId2, lignesIdAnnonce, lignesIdAnnonce2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DevisApi - factory interface
 * @export
 */
export const DevisApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DevisApiFp(configuration)
    return {
        /**
         * Récupérer les informations d\'un devis
         * @summary Récupérer les informations d\'un devis
         * @param {DevisApiApiDevisGetDevisByIdItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDevisGetDevisByIdItem(requestParameters: DevisApiApiDevisGetDevisByIdItemRequest, options?: AxiosRequestConfig): AxiosPromise<DevisJsonldDevisRead> {
            return localVarFp.apiDevisGetDevisByIdItem(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtenir la liste des devis
         * @summary Obtenir la liste des devis
         * @param {DevisApiApiDevisGetDevisCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDevisGetDevisCollection(requestParameters: DevisApiApiDevisGetDevisCollectionRequest = {}, options?: AxiosRequestConfig): AxiosPromise<ApiDevisGetDevisCollection200Response> {
            return localVarFp.apiDevisGetDevisCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.dossierId, requestParameters.dossierId2, requestParameters.lignesIdAnnonce, requestParameters.lignesIdAnnonce2, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiDevisGetDevisByIdItem operation in DevisApi.
 * @export
 * @interface DevisApiApiDevisGetDevisByIdItemRequest
 */
export interface DevisApiApiDevisGetDevisByIdItemRequest {
    /**
     * TblDevis identifier
     * @type {string}
     * @memberof DevisApiApiDevisGetDevisByIdItem
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof DevisApiApiDevisGetDevisByIdItem
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for apiDevisGetDevisCollection operation in DevisApi.
 * @export
 * @interface DevisApiApiDevisGetDevisCollectionRequest
 */
export interface DevisApiApiDevisGetDevisCollectionRequest {
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof DevisApiApiDevisGetDevisCollection
     */
    readonly xIdSociete?: number

    /**
     * The collection page number
     * @type {number}
     * @memberof DevisApiApiDevisGetDevisCollection
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof DevisApiApiDevisGetDevisCollection
     */
    readonly dossierId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof DevisApiApiDevisGetDevisCollection
     */
    readonly dossierId2?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof DevisApiApiDevisGetDevisCollection
     */
    readonly lignesIdAnnonce?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof DevisApiApiDevisGetDevisCollection
     */
    readonly lignesIdAnnonce2?: Array<number>
}

/**
 * DevisApi - object-oriented interface
 * @export
 * @class DevisApi
 * @extends {BaseAPI}
 */
export class DevisApi extends BaseAPI {
    /**
     * Récupérer les informations d\'un devis
     * @summary Récupérer les informations d\'un devis
     * @param {DevisApiApiDevisGetDevisByIdItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevisApi
     */
    public apiDevisGetDevisByIdItem(requestParameters: DevisApiApiDevisGetDevisByIdItemRequest, options?: AxiosRequestConfig) {
        return DevisApiFp(this.configuration).apiDevisGetDevisByIdItem(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtenir la liste des devis
     * @summary Obtenir la liste des devis
     * @param {DevisApiApiDevisGetDevisCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevisApi
     */
    public apiDevisGetDevisCollection(requestParameters: DevisApiApiDevisGetDevisCollectionRequest = {}, options?: AxiosRequestConfig) {
        return DevisApiFp(this.configuration).apiDevisGetDevisCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.dossierId, requestParameters.dossierId2, requestParameters.lignesIdAnnonce, requestParameters.lignesIdAnnonce2, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DevisLignesApi - axios parameter creator
 * @export
 */
export const DevisLignesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Removes the DevisLignes resource.
         * @summary Removes the DevisLignes resource.
         * @param {string} id TblDevisLignes identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDevisLignesDeleteItem: async (id: string, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiDevisLignesDeleteItem', 'id', id)
            const localVarPath = `/facturation/devis_lignes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtenir la liste des lignes de devis
         * @summary Obtenir la liste des lignes de devis
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {string} [rubfac] 
         * @param {Array<string>} [rubfac2] 
         * @param {number} [devisId] 
         * @param {Array<number>} [devisId2] 
         * @param {number} [idAnnonce] 
         * @param {Array<number>} [idAnnonce2] 
         * @param {number} [devisDossierId] 
         * @param {Array<number>} [devisDossierId2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDevisLignesGetDevisCollection: async (xIdSociete?: number, page?: number, rubfac?: string, rubfac2?: Array<string>, devisId?: number, devisId2?: Array<number>, idAnnonce?: number, idAnnonce2?: Array<number>, devisDossierId?: number, devisDossierId2?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/facturation/devis_lignes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (rubfac !== undefined) {
                localVarQueryParameter['rubfac'] = rubfac;
            }

            if (rubfac2) {
                localVarQueryParameter['rubfac[]'] = rubfac2;
            }

            if (devisId !== undefined) {
                localVarQueryParameter['devis.id'] = devisId;
            }

            if (devisId2) {
                localVarQueryParameter['devis.id[]'] = devisId2;
            }

            if (idAnnonce !== undefined) {
                localVarQueryParameter['idAnnonce'] = idAnnonce;
            }

            if (idAnnonce2) {
                localVarQueryParameter['idAnnonce[]'] = idAnnonce2;
            }

            if (devisDossierId !== undefined) {
                localVarQueryParameter['devis.dossier.id'] = devisDossierId;
            }

            if (devisDossierId2) {
                localVarQueryParameter['devis.dossier.id[]'] = devisDossierId2;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a DevisLignes resource.
         * @summary Retrieves a DevisLignes resource.
         * @param {string} id TblDevisLignes identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDevisLignesGetItem: async (id: string, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiDevisLignesGetItem', 'id', id)
            const localVarPath = `/facturation/devis_lignes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the DevisLignes resource.
         * @summary Updates the DevisLignes resource.
         * @param {string} id TblDevisLignes identifier
         * @param {DevisLignes} devisLignes The updated DevisLignes resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDevisLignesPatchItem: async (id: string, devisLignes: DevisLignes, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiDevisLignesPatchItem', 'id', id)
            // verify required parameter 'devisLignes' is not null or undefined
            assertParamExists('apiDevisLignesPatchItem', 'devisLignes', devisLignes)
            const localVarPath = `/facturation/devis_lignes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(devisLignes, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces the DevisLignes resource.
         * @summary Replaces the DevisLignes resource.
         * @param {string} id TblDevisLignes identifier
         * @param {DevisLignesJsonld} devisLignesJsonld The updated DevisLignes resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDevisLignesPutItem: async (id: string, devisLignesJsonld: DevisLignesJsonld, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiDevisLignesPutItem', 'id', id)
            // verify required parameter 'devisLignesJsonld' is not null or undefined
            assertParamExists('apiDevisLignesPutItem', 'devisLignesJsonld', devisLignesJsonld)
            const localVarPath = `/facturation/devis_lignes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(devisLignesJsonld, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DevisLignesApi - functional programming interface
 * @export
 */
export const DevisLignesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DevisLignesApiAxiosParamCreator(configuration)
    return {
        /**
         * Removes the DevisLignes resource.
         * @summary Removes the DevisLignes resource.
         * @param {string} id TblDevisLignes identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDevisLignesDeleteItem(id: string, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDevisLignesDeleteItem(id, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtenir la liste des lignes de devis
         * @summary Obtenir la liste des lignes de devis
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {string} [rubfac] 
         * @param {Array<string>} [rubfac2] 
         * @param {number} [devisId] 
         * @param {Array<number>} [devisId2] 
         * @param {number} [idAnnonce] 
         * @param {Array<number>} [idAnnonce2] 
         * @param {number} [devisDossierId] 
         * @param {Array<number>} [devisDossierId2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDevisLignesGetDevisCollection(xIdSociete?: number, page?: number, rubfac?: string, rubfac2?: Array<string>, devisId?: number, devisId2?: Array<number>, idAnnonce?: number, idAnnonce2?: Array<number>, devisDossierId?: number, devisDossierId2?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiDevisLignesGetDevisCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDevisLignesGetDevisCollection(xIdSociete, page, rubfac, rubfac2, devisId, devisId2, idAnnonce, idAnnonce2, devisDossierId, devisDossierId2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a DevisLignes resource.
         * @summary Retrieves a DevisLignes resource.
         * @param {string} id TblDevisLignes identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDevisLignesGetItem(id: string, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DevisLignesJsonldDevisLignesRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDevisLignesGetItem(id, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the DevisLignes resource.
         * @summary Updates the DevisLignes resource.
         * @param {string} id TblDevisLignes identifier
         * @param {DevisLignes} devisLignes The updated DevisLignes resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDevisLignesPatchItem(id: string, devisLignes: DevisLignes, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DevisLignesJsonldDevisLignesRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDevisLignesPatchItem(id, devisLignes, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces the DevisLignes resource.
         * @summary Replaces the DevisLignes resource.
         * @param {string} id TblDevisLignes identifier
         * @param {DevisLignesJsonld} devisLignesJsonld The updated DevisLignes resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDevisLignesPutItem(id: string, devisLignesJsonld: DevisLignesJsonld, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DevisLignesJsonldDevisLignesRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDevisLignesPutItem(id, devisLignesJsonld, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DevisLignesApi - factory interface
 * @export
 */
export const DevisLignesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DevisLignesApiFp(configuration)
    return {
        /**
         * Removes the DevisLignes resource.
         * @summary Removes the DevisLignes resource.
         * @param {DevisLignesApiApiDevisLignesDeleteItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDevisLignesDeleteItem(requestParameters: DevisLignesApiApiDevisLignesDeleteItemRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiDevisLignesDeleteItem(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtenir la liste des lignes de devis
         * @summary Obtenir la liste des lignes de devis
         * @param {DevisLignesApiApiDevisLignesGetDevisCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDevisLignesGetDevisCollection(requestParameters: DevisLignesApiApiDevisLignesGetDevisCollectionRequest = {}, options?: AxiosRequestConfig): AxiosPromise<ApiDevisLignesGetDevisCollection200Response> {
            return localVarFp.apiDevisLignesGetDevisCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.rubfac, requestParameters.rubfac2, requestParameters.devisId, requestParameters.devisId2, requestParameters.idAnnonce, requestParameters.idAnnonce2, requestParameters.devisDossierId, requestParameters.devisDossierId2, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a DevisLignes resource.
         * @summary Retrieves a DevisLignes resource.
         * @param {DevisLignesApiApiDevisLignesGetItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDevisLignesGetItem(requestParameters: DevisLignesApiApiDevisLignesGetItemRequest, options?: AxiosRequestConfig): AxiosPromise<DevisLignesJsonldDevisLignesRead> {
            return localVarFp.apiDevisLignesGetItem(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the DevisLignes resource.
         * @summary Updates the DevisLignes resource.
         * @param {DevisLignesApiApiDevisLignesPatchItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDevisLignesPatchItem(requestParameters: DevisLignesApiApiDevisLignesPatchItemRequest, options?: AxiosRequestConfig): AxiosPromise<DevisLignesJsonldDevisLignesRead> {
            return localVarFp.apiDevisLignesPatchItem(requestParameters.id, requestParameters.devisLignes, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces the DevisLignes resource.
         * @summary Replaces the DevisLignes resource.
         * @param {DevisLignesApiApiDevisLignesPutItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDevisLignesPutItem(requestParameters: DevisLignesApiApiDevisLignesPutItemRequest, options?: AxiosRequestConfig): AxiosPromise<DevisLignesJsonldDevisLignesRead> {
            return localVarFp.apiDevisLignesPutItem(requestParameters.id, requestParameters.devisLignesJsonld, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiDevisLignesDeleteItem operation in DevisLignesApi.
 * @export
 * @interface DevisLignesApiApiDevisLignesDeleteItemRequest
 */
export interface DevisLignesApiApiDevisLignesDeleteItemRequest {
    /**
     * TblDevisLignes identifier
     * @type {string}
     * @memberof DevisLignesApiApiDevisLignesDeleteItem
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof DevisLignesApiApiDevisLignesDeleteItem
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for apiDevisLignesGetDevisCollection operation in DevisLignesApi.
 * @export
 * @interface DevisLignesApiApiDevisLignesGetDevisCollectionRequest
 */
export interface DevisLignesApiApiDevisLignesGetDevisCollectionRequest {
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof DevisLignesApiApiDevisLignesGetDevisCollection
     */
    readonly xIdSociete?: number

    /**
     * The collection page number
     * @type {number}
     * @memberof DevisLignesApiApiDevisLignesGetDevisCollection
     */
    readonly page?: number

    /**
     * 
     * @type {string}
     * @memberof DevisLignesApiApiDevisLignesGetDevisCollection
     */
    readonly rubfac?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof DevisLignesApiApiDevisLignesGetDevisCollection
     */
    readonly rubfac2?: Array<string>

    /**
     * 
     * @type {number}
     * @memberof DevisLignesApiApiDevisLignesGetDevisCollection
     */
    readonly devisId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof DevisLignesApiApiDevisLignesGetDevisCollection
     */
    readonly devisId2?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof DevisLignesApiApiDevisLignesGetDevisCollection
     */
    readonly idAnnonce?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof DevisLignesApiApiDevisLignesGetDevisCollection
     */
    readonly idAnnonce2?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof DevisLignesApiApiDevisLignesGetDevisCollection
     */
    readonly devisDossierId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof DevisLignesApiApiDevisLignesGetDevisCollection
     */
    readonly devisDossierId2?: Array<number>
}

/**
 * Request parameters for apiDevisLignesGetItem operation in DevisLignesApi.
 * @export
 * @interface DevisLignesApiApiDevisLignesGetItemRequest
 */
export interface DevisLignesApiApiDevisLignesGetItemRequest {
    /**
     * TblDevisLignes identifier
     * @type {string}
     * @memberof DevisLignesApiApiDevisLignesGetItem
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof DevisLignesApiApiDevisLignesGetItem
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for apiDevisLignesPatchItem operation in DevisLignesApi.
 * @export
 * @interface DevisLignesApiApiDevisLignesPatchItemRequest
 */
export interface DevisLignesApiApiDevisLignesPatchItemRequest {
    /**
     * TblDevisLignes identifier
     * @type {string}
     * @memberof DevisLignesApiApiDevisLignesPatchItem
     */
    readonly id: string

    /**
     * The updated DevisLignes resource
     * @type {DevisLignes}
     * @memberof DevisLignesApiApiDevisLignesPatchItem
     */
    readonly devisLignes: DevisLignes

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof DevisLignesApiApiDevisLignesPatchItem
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for apiDevisLignesPutItem operation in DevisLignesApi.
 * @export
 * @interface DevisLignesApiApiDevisLignesPutItemRequest
 */
export interface DevisLignesApiApiDevisLignesPutItemRequest {
    /**
     * TblDevisLignes identifier
     * @type {string}
     * @memberof DevisLignesApiApiDevisLignesPutItem
     */
    readonly id: string

    /**
     * The updated DevisLignes resource
     * @type {DevisLignesJsonld}
     * @memberof DevisLignesApiApiDevisLignesPutItem
     */
    readonly devisLignesJsonld: DevisLignesJsonld

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof DevisLignesApiApiDevisLignesPutItem
     */
    readonly xIdSociete?: number
}

/**
 * DevisLignesApi - object-oriented interface
 * @export
 * @class DevisLignesApi
 * @extends {BaseAPI}
 */
export class DevisLignesApi extends BaseAPI {
    /**
     * Removes the DevisLignes resource.
     * @summary Removes the DevisLignes resource.
     * @param {DevisLignesApiApiDevisLignesDeleteItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevisLignesApi
     */
    public apiDevisLignesDeleteItem(requestParameters: DevisLignesApiApiDevisLignesDeleteItemRequest, options?: AxiosRequestConfig) {
        return DevisLignesApiFp(this.configuration).apiDevisLignesDeleteItem(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtenir la liste des lignes de devis
     * @summary Obtenir la liste des lignes de devis
     * @param {DevisLignesApiApiDevisLignesGetDevisCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevisLignesApi
     */
    public apiDevisLignesGetDevisCollection(requestParameters: DevisLignesApiApiDevisLignesGetDevisCollectionRequest = {}, options?: AxiosRequestConfig) {
        return DevisLignesApiFp(this.configuration).apiDevisLignesGetDevisCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.rubfac, requestParameters.rubfac2, requestParameters.devisId, requestParameters.devisId2, requestParameters.idAnnonce, requestParameters.idAnnonce2, requestParameters.devisDossierId, requestParameters.devisDossierId2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a DevisLignes resource.
     * @summary Retrieves a DevisLignes resource.
     * @param {DevisLignesApiApiDevisLignesGetItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevisLignesApi
     */
    public apiDevisLignesGetItem(requestParameters: DevisLignesApiApiDevisLignesGetItemRequest, options?: AxiosRequestConfig) {
        return DevisLignesApiFp(this.configuration).apiDevisLignesGetItem(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the DevisLignes resource.
     * @summary Updates the DevisLignes resource.
     * @param {DevisLignesApiApiDevisLignesPatchItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevisLignesApi
     */
    public apiDevisLignesPatchItem(requestParameters: DevisLignesApiApiDevisLignesPatchItemRequest, options?: AxiosRequestConfig) {
        return DevisLignesApiFp(this.configuration).apiDevisLignesPatchItem(requestParameters.id, requestParameters.devisLignes, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces the DevisLignes resource.
     * @summary Replaces the DevisLignes resource.
     * @param {DevisLignesApiApiDevisLignesPutItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevisLignesApi
     */
    public apiDevisLignesPutItem(requestParameters: DevisLignesApiApiDevisLignesPutItemRequest, options?: AxiosRequestConfig) {
        return DevisLignesApiFp(this.configuration).apiDevisLignesPutItem(requestParameters.id, requestParameters.devisLignesJsonld, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FactureApi - axios parameter creator
 * @export
 */
export const FactureApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Obtenir la liste des factures d\'un dossier
         * @summary Obtenir la liste des factures d\'un dossier
         * @param {string} dossier FproDossier identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {string} [dossierNumero] 
         * @param {number} [dossierId] 
         * @param {Array<number>} [dossierId2] 
         * @param {number} [dossierPrescripteurId] 
         * @param {Array<number>} [dossierPrescripteurId2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersDossierFacturesGetCollection: async (dossier: string, xIdSociete?: number, page?: number, dossierNumero?: string, dossierId?: number, dossierId2?: Array<number>, dossierPrescripteurId?: number, dossierPrescripteurId2?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dossier' is not null or undefined
            assertParamExists('apiDossiersDossierFacturesGetCollection', 'dossier', dossier)
            const localVarPath = `/facturation/dossiers/{dossier}/factures`
                .replace(`{${"dossier"}}`, encodeURIComponent(String(dossier)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (dossierNumero !== undefined) {
                localVarQueryParameter['dossier.numero'] = dossierNumero;
            }

            if (dossierId !== undefined) {
                localVarQueryParameter['dossier.id'] = dossierId;
            }

            if (dossierId2) {
                localVarQueryParameter['dossier.id[]'] = dossierId2;
            }

            if (dossierPrescripteurId !== undefined) {
                localVarQueryParameter['dossier.prescripteur.id'] = dossierPrescripteurId;
            }

            if (dossierPrescripteurId2) {
                localVarQueryParameter['dossier.prescripteur.id[]'] = dossierPrescripteurId2;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtenir la liste des factures
         * @summary Obtenir la liste des factures
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {string} [dossierNumero] 
         * @param {number} [dossierId] 
         * @param {Array<number>} [dossierId2] 
         * @param {number} [dossierPrescripteurId] 
         * @param {Array<number>} [dossierPrescripteurId2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFacturesGetCollection: async (xIdSociete?: number, page?: number, dossierNumero?: string, dossierId?: number, dossierId2?: Array<number>, dossierPrescripteurId?: number, dossierPrescripteurId2?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/facturation/factures`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (dossierNumero !== undefined) {
                localVarQueryParameter['dossier.numero'] = dossierNumero;
            }

            if (dossierId !== undefined) {
                localVarQueryParameter['dossier.id'] = dossierId;
            }

            if (dossierId2) {
                localVarQueryParameter['dossier.id[]'] = dossierId2;
            }

            if (dossierPrescripteurId !== undefined) {
                localVarQueryParameter['dossier.prescripteur.id'] = dossierPrescripteurId;
            }

            if (dossierPrescripteurId2) {
                localVarQueryParameter['dossier.prescripteur.id[]'] = dossierPrescripteurId2;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Créer un avoir pour la facture
         * @summary Créer un avoir pour la facture
         * @param {string} id TblFacture2 identifier
         * @param {FactureJsonld} factureJsonld The updated Facture resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFacturesIdAvoirPut: async (id: string, factureJsonld: FactureJsonld, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiFacturesIdAvoirPut', 'id', id)
            // verify required parameter 'factureJsonld' is not null or undefined
            assertParamExists('apiFacturesIdAvoirPut', 'factureJsonld', factureJsonld)
            const localVarPath = `/facturation/factures/{id}/avoir`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(factureJsonld, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Supprimer une facture
         * @summary Supprimer une facture
         * @param {string} id TblFacture2 identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFacturesIdDelete: async (id: string, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiFacturesIdDelete', 'id', id)
            const localVarPath = `/facturation/factures/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'une facture
         * @summary Récupérer les informations d\'une facture
         * @param {string} id TblFacture2 identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFacturesIdGet: async (id: string, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiFacturesIdGet', 'id', id)
            const localVarPath = `/facturation/factures/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer le pdf d\'une facture
         * @summary Récupérer le pdf d\'une facture
         * @param {string} id TblFacture2 identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFacturesIdPdfGet: async (id: string, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiFacturesIdPdfGet', 'id', id)
            const localVarPath = `/facturation/factures/{id}/pdf`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Mettre à jour une facture
         * @summary Mettre à jour une facture
         * @param {string} id TblFacture2 identifier
         * @param {FactureJsonldFactureUpdate} factureJsonldFactureUpdate The updated Facture resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFacturesIdPut: async (id: string, factureJsonldFactureUpdate: FactureJsonldFactureUpdate, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiFacturesIdPut', 'id', id)
            // verify required parameter 'factureJsonldFactureUpdate' is not null or undefined
            assertParamExists('apiFacturesIdPut', 'factureJsonldFactureUpdate', factureJsonldFactureUpdate)
            const localVarPath = `/facturation/factures/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(factureJsonldFactureUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Valider une facture
         * @summary Valider une facture
         * @param {string} id TblFacture2 identifier
         * @param {FactureJsonld} factureJsonld The updated Facture resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFacturesIdValidatePut: async (id: string, factureJsonld: FactureJsonld, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiFacturesIdValidatePut', 'id', id)
            // verify required parameter 'factureJsonld' is not null or undefined
            assertParamExists('apiFacturesIdValidatePut', 'factureJsonld', factureJsonld)
            const localVarPath = `/facturation/factures/{id}/validate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(factureJsonld, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Créer une facture
         * @summary Créer une facture
         * @param {FactureJsonldFactureCreate} factureJsonldFactureCreate The new Facture resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFacturesPost: async (factureJsonldFactureCreate: FactureJsonldFactureCreate, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'factureJsonldFactureCreate' is not null or undefined
            assertParamExists('apiFacturesPost', 'factureJsonldFactureCreate', factureJsonldFactureCreate)
            const localVarPath = `/facturation/factures`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(factureJsonldFactureCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FactureApi - functional programming interface
 * @export
 */
export const FactureApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FactureApiAxiosParamCreator(configuration)
    return {
        /**
         * Obtenir la liste des factures d\'un dossier
         * @summary Obtenir la liste des factures d\'un dossier
         * @param {string} dossier FproDossier identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {string} [dossierNumero] 
         * @param {number} [dossierId] 
         * @param {Array<number>} [dossierId2] 
         * @param {number} [dossierPrescripteurId] 
         * @param {Array<number>} [dossierPrescripteurId2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDossiersDossierFacturesGetCollection(dossier: string, xIdSociete?: number, page?: number, dossierNumero?: string, dossierId?: number, dossierId2?: Array<number>, dossierPrescripteurId?: number, dossierPrescripteurId2?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiDossiersDossierFacturesGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDossiersDossierFacturesGetCollection(dossier, xIdSociete, page, dossierNumero, dossierId, dossierId2, dossierPrescripteurId, dossierPrescripteurId2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtenir la liste des factures
         * @summary Obtenir la liste des factures
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {string} [dossierNumero] 
         * @param {number} [dossierId] 
         * @param {Array<number>} [dossierId2] 
         * @param {number} [dossierPrescripteurId] 
         * @param {Array<number>} [dossierPrescripteurId2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFacturesGetCollection(xIdSociete?: number, page?: number, dossierNumero?: string, dossierId?: number, dossierId2?: Array<number>, dossierPrescripteurId?: number, dossierPrescripteurId2?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiDossiersDossierFacturesGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFacturesGetCollection(xIdSociete, page, dossierNumero, dossierId, dossierId2, dossierPrescripteurId, dossierPrescripteurId2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Créer un avoir pour la facture
         * @summary Créer un avoir pour la facture
         * @param {string} id TblFacture2 identifier
         * @param {FactureJsonld} factureJsonld The updated Facture resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFacturesIdAvoirPut(id: string, factureJsonld: FactureJsonld, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FactureJsonldFactureRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFacturesIdAvoirPut(id, factureJsonld, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Supprimer une facture
         * @summary Supprimer une facture
         * @param {string} id TblFacture2 identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFacturesIdDelete(id: string, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFacturesIdDelete(id, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Récupérer les informations d\'une facture
         * @summary Récupérer les informations d\'une facture
         * @param {string} id TblFacture2 identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFacturesIdGet(id: string, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FactureJsonldFactureRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFacturesIdGet(id, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Récupérer le pdf d\'une facture
         * @summary Récupérer le pdf d\'une facture
         * @param {string} id TblFacture2 identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFacturesIdPdfGet(id: string, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FactureJsonldFactureReadPdf>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFacturesIdPdfGet(id, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Mettre à jour une facture
         * @summary Mettre à jour une facture
         * @param {string} id TblFacture2 identifier
         * @param {FactureJsonldFactureUpdate} factureJsonldFactureUpdate The updated Facture resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFacturesIdPut(id: string, factureJsonldFactureUpdate: FactureJsonldFactureUpdate, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FactureJsonldFactureRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFacturesIdPut(id, factureJsonldFactureUpdate, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Valider une facture
         * @summary Valider une facture
         * @param {string} id TblFacture2 identifier
         * @param {FactureJsonld} factureJsonld The updated Facture resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFacturesIdValidatePut(id: string, factureJsonld: FactureJsonld, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FactureJsonldFactureRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFacturesIdValidatePut(id, factureJsonld, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Créer une facture
         * @summary Créer une facture
         * @param {FactureJsonldFactureCreate} factureJsonldFactureCreate The new Facture resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFacturesPost(factureJsonldFactureCreate: FactureJsonldFactureCreate, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FactureJsonldFactureRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFacturesPost(factureJsonldFactureCreate, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FactureApi - factory interface
 * @export
 */
export const FactureApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FactureApiFp(configuration)
    return {
        /**
         * Obtenir la liste des factures d\'un dossier
         * @summary Obtenir la liste des factures d\'un dossier
         * @param {FactureApiApiDossiersDossierFacturesGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersDossierFacturesGetCollection(requestParameters: FactureApiApiDossiersDossierFacturesGetCollectionRequest, options?: AxiosRequestConfig): AxiosPromise<ApiDossiersDossierFacturesGetCollection200Response> {
            return localVarFp.apiDossiersDossierFacturesGetCollection(requestParameters.dossier, requestParameters.xIdSociete, requestParameters.page, requestParameters.dossierNumero, requestParameters.dossierId, requestParameters.dossierId2, requestParameters.dossierPrescripteurId, requestParameters.dossierPrescripteurId2, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtenir la liste des factures
         * @summary Obtenir la liste des factures
         * @param {FactureApiApiFacturesGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFacturesGetCollection(requestParameters: FactureApiApiFacturesGetCollectionRequest = {}, options?: AxiosRequestConfig): AxiosPromise<ApiDossiersDossierFacturesGetCollection200Response> {
            return localVarFp.apiFacturesGetCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.dossierNumero, requestParameters.dossierId, requestParameters.dossierId2, requestParameters.dossierPrescripteurId, requestParameters.dossierPrescripteurId2, options).then((request) => request(axios, basePath));
        },
        /**
         * Créer un avoir pour la facture
         * @summary Créer un avoir pour la facture
         * @param {FactureApiApiFacturesIdAvoirPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFacturesIdAvoirPut(requestParameters: FactureApiApiFacturesIdAvoirPutRequest, options?: AxiosRequestConfig): AxiosPromise<FactureJsonldFactureRead> {
            return localVarFp.apiFacturesIdAvoirPut(requestParameters.id, requestParameters.factureJsonld, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Supprimer une facture
         * @summary Supprimer une facture
         * @param {FactureApiApiFacturesIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFacturesIdDelete(requestParameters: FactureApiApiFacturesIdDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiFacturesIdDelete(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'une facture
         * @summary Récupérer les informations d\'une facture
         * @param {FactureApiApiFacturesIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFacturesIdGet(requestParameters: FactureApiApiFacturesIdGetRequest, options?: AxiosRequestConfig): AxiosPromise<FactureJsonldFactureRead> {
            return localVarFp.apiFacturesIdGet(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer le pdf d\'une facture
         * @summary Récupérer le pdf d\'une facture
         * @param {FactureApiApiFacturesIdPdfGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFacturesIdPdfGet(requestParameters: FactureApiApiFacturesIdPdfGetRequest, options?: AxiosRequestConfig): AxiosPromise<FactureJsonldFactureReadPdf> {
            return localVarFp.apiFacturesIdPdfGet(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Mettre à jour une facture
         * @summary Mettre à jour une facture
         * @param {FactureApiApiFacturesIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFacturesIdPut(requestParameters: FactureApiApiFacturesIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<FactureJsonldFactureRead> {
            return localVarFp.apiFacturesIdPut(requestParameters.id, requestParameters.factureJsonldFactureUpdate, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Valider une facture
         * @summary Valider une facture
         * @param {FactureApiApiFacturesIdValidatePutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFacturesIdValidatePut(requestParameters: FactureApiApiFacturesIdValidatePutRequest, options?: AxiosRequestConfig): AxiosPromise<FactureJsonldFactureRead> {
            return localVarFp.apiFacturesIdValidatePut(requestParameters.id, requestParameters.factureJsonld, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Créer une facture
         * @summary Créer une facture
         * @param {FactureApiApiFacturesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFacturesPost(requestParameters: FactureApiApiFacturesPostRequest, options?: AxiosRequestConfig): AxiosPromise<FactureJsonldFactureRead> {
            return localVarFp.apiFacturesPost(requestParameters.factureJsonldFactureCreate, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiDossiersDossierFacturesGetCollection operation in FactureApi.
 * @export
 * @interface FactureApiApiDossiersDossierFacturesGetCollectionRequest
 */
export interface FactureApiApiDossiersDossierFacturesGetCollectionRequest {
    /**
     * FproDossier identifier
     * @type {string}
     * @memberof FactureApiApiDossiersDossierFacturesGetCollection
     */
    readonly dossier: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof FactureApiApiDossiersDossierFacturesGetCollection
     */
    readonly xIdSociete?: number

    /**
     * The collection page number
     * @type {number}
     * @memberof FactureApiApiDossiersDossierFacturesGetCollection
     */
    readonly page?: number

    /**
     * 
     * @type {string}
     * @memberof FactureApiApiDossiersDossierFacturesGetCollection
     */
    readonly dossierNumero?: string

    /**
     * 
     * @type {number}
     * @memberof FactureApiApiDossiersDossierFacturesGetCollection
     */
    readonly dossierId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof FactureApiApiDossiersDossierFacturesGetCollection
     */
    readonly dossierId2?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof FactureApiApiDossiersDossierFacturesGetCollection
     */
    readonly dossierPrescripteurId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof FactureApiApiDossiersDossierFacturesGetCollection
     */
    readonly dossierPrescripteurId2?: Array<number>
}

/**
 * Request parameters for apiFacturesGetCollection operation in FactureApi.
 * @export
 * @interface FactureApiApiFacturesGetCollectionRequest
 */
export interface FactureApiApiFacturesGetCollectionRequest {
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof FactureApiApiFacturesGetCollection
     */
    readonly xIdSociete?: number

    /**
     * The collection page number
     * @type {number}
     * @memberof FactureApiApiFacturesGetCollection
     */
    readonly page?: number

    /**
     * 
     * @type {string}
     * @memberof FactureApiApiFacturesGetCollection
     */
    readonly dossierNumero?: string

    /**
     * 
     * @type {number}
     * @memberof FactureApiApiFacturesGetCollection
     */
    readonly dossierId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof FactureApiApiFacturesGetCollection
     */
    readonly dossierId2?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof FactureApiApiFacturesGetCollection
     */
    readonly dossierPrescripteurId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof FactureApiApiFacturesGetCollection
     */
    readonly dossierPrescripteurId2?: Array<number>
}

/**
 * Request parameters for apiFacturesIdAvoirPut operation in FactureApi.
 * @export
 * @interface FactureApiApiFacturesIdAvoirPutRequest
 */
export interface FactureApiApiFacturesIdAvoirPutRequest {
    /**
     * TblFacture2 identifier
     * @type {string}
     * @memberof FactureApiApiFacturesIdAvoirPut
     */
    readonly id: string

    /**
     * The updated Facture resource
     * @type {FactureJsonld}
     * @memberof FactureApiApiFacturesIdAvoirPut
     */
    readonly factureJsonld: FactureJsonld

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof FactureApiApiFacturesIdAvoirPut
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for apiFacturesIdDelete operation in FactureApi.
 * @export
 * @interface FactureApiApiFacturesIdDeleteRequest
 */
export interface FactureApiApiFacturesIdDeleteRequest {
    /**
     * TblFacture2 identifier
     * @type {string}
     * @memberof FactureApiApiFacturesIdDelete
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof FactureApiApiFacturesIdDelete
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for apiFacturesIdGet operation in FactureApi.
 * @export
 * @interface FactureApiApiFacturesIdGetRequest
 */
export interface FactureApiApiFacturesIdGetRequest {
    /**
     * TblFacture2 identifier
     * @type {string}
     * @memberof FactureApiApiFacturesIdGet
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof FactureApiApiFacturesIdGet
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for apiFacturesIdPdfGet operation in FactureApi.
 * @export
 * @interface FactureApiApiFacturesIdPdfGetRequest
 */
export interface FactureApiApiFacturesIdPdfGetRequest {
    /**
     * TblFacture2 identifier
     * @type {string}
     * @memberof FactureApiApiFacturesIdPdfGet
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof FactureApiApiFacturesIdPdfGet
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for apiFacturesIdPut operation in FactureApi.
 * @export
 * @interface FactureApiApiFacturesIdPutRequest
 */
export interface FactureApiApiFacturesIdPutRequest {
    /**
     * TblFacture2 identifier
     * @type {string}
     * @memberof FactureApiApiFacturesIdPut
     */
    readonly id: string

    /**
     * The updated Facture resource
     * @type {FactureJsonldFactureUpdate}
     * @memberof FactureApiApiFacturesIdPut
     */
    readonly factureJsonldFactureUpdate: FactureJsonldFactureUpdate

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof FactureApiApiFacturesIdPut
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for apiFacturesIdValidatePut operation in FactureApi.
 * @export
 * @interface FactureApiApiFacturesIdValidatePutRequest
 */
export interface FactureApiApiFacturesIdValidatePutRequest {
    /**
     * TblFacture2 identifier
     * @type {string}
     * @memberof FactureApiApiFacturesIdValidatePut
     */
    readonly id: string

    /**
     * The updated Facture resource
     * @type {FactureJsonld}
     * @memberof FactureApiApiFacturesIdValidatePut
     */
    readonly factureJsonld: FactureJsonld

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof FactureApiApiFacturesIdValidatePut
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for apiFacturesPost operation in FactureApi.
 * @export
 * @interface FactureApiApiFacturesPostRequest
 */
export interface FactureApiApiFacturesPostRequest {
    /**
     * The new Facture resource
     * @type {FactureJsonldFactureCreate}
     * @memberof FactureApiApiFacturesPost
     */
    readonly factureJsonldFactureCreate: FactureJsonldFactureCreate

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof FactureApiApiFacturesPost
     */
    readonly xIdSociete?: number
}

/**
 * FactureApi - object-oriented interface
 * @export
 * @class FactureApi
 * @extends {BaseAPI}
 */
export class FactureApi extends BaseAPI {
    /**
     * Obtenir la liste des factures d\'un dossier
     * @summary Obtenir la liste des factures d\'un dossier
     * @param {FactureApiApiDossiersDossierFacturesGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FactureApi
     */
    public apiDossiersDossierFacturesGetCollection(requestParameters: FactureApiApiDossiersDossierFacturesGetCollectionRequest, options?: AxiosRequestConfig) {
        return FactureApiFp(this.configuration).apiDossiersDossierFacturesGetCollection(requestParameters.dossier, requestParameters.xIdSociete, requestParameters.page, requestParameters.dossierNumero, requestParameters.dossierId, requestParameters.dossierId2, requestParameters.dossierPrescripteurId, requestParameters.dossierPrescripteurId2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtenir la liste des factures
     * @summary Obtenir la liste des factures
     * @param {FactureApiApiFacturesGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FactureApi
     */
    public apiFacturesGetCollection(requestParameters: FactureApiApiFacturesGetCollectionRequest = {}, options?: AxiosRequestConfig) {
        return FactureApiFp(this.configuration).apiFacturesGetCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.dossierNumero, requestParameters.dossierId, requestParameters.dossierId2, requestParameters.dossierPrescripteurId, requestParameters.dossierPrescripteurId2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Créer un avoir pour la facture
     * @summary Créer un avoir pour la facture
     * @param {FactureApiApiFacturesIdAvoirPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FactureApi
     */
    public apiFacturesIdAvoirPut(requestParameters: FactureApiApiFacturesIdAvoirPutRequest, options?: AxiosRequestConfig) {
        return FactureApiFp(this.configuration).apiFacturesIdAvoirPut(requestParameters.id, requestParameters.factureJsonld, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Supprimer une facture
     * @summary Supprimer une facture
     * @param {FactureApiApiFacturesIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FactureApi
     */
    public apiFacturesIdDelete(requestParameters: FactureApiApiFacturesIdDeleteRequest, options?: AxiosRequestConfig) {
        return FactureApiFp(this.configuration).apiFacturesIdDelete(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'une facture
     * @summary Récupérer les informations d\'une facture
     * @param {FactureApiApiFacturesIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FactureApi
     */
    public apiFacturesIdGet(requestParameters: FactureApiApiFacturesIdGetRequest, options?: AxiosRequestConfig) {
        return FactureApiFp(this.configuration).apiFacturesIdGet(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer le pdf d\'une facture
     * @summary Récupérer le pdf d\'une facture
     * @param {FactureApiApiFacturesIdPdfGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FactureApi
     */
    public apiFacturesIdPdfGet(requestParameters: FactureApiApiFacturesIdPdfGetRequest, options?: AxiosRequestConfig) {
        return FactureApiFp(this.configuration).apiFacturesIdPdfGet(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Mettre à jour une facture
     * @summary Mettre à jour une facture
     * @param {FactureApiApiFacturesIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FactureApi
     */
    public apiFacturesIdPut(requestParameters: FactureApiApiFacturesIdPutRequest, options?: AxiosRequestConfig) {
        return FactureApiFp(this.configuration).apiFacturesIdPut(requestParameters.id, requestParameters.factureJsonldFactureUpdate, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Valider une facture
     * @summary Valider une facture
     * @param {FactureApiApiFacturesIdValidatePutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FactureApi
     */
    public apiFacturesIdValidatePut(requestParameters: FactureApiApiFacturesIdValidatePutRequest, options?: AxiosRequestConfig) {
        return FactureApiFp(this.configuration).apiFacturesIdValidatePut(requestParameters.id, requestParameters.factureJsonld, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Créer une facture
     * @summary Créer une facture
     * @param {FactureApiApiFacturesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FactureApi
     */
    public apiFacturesPost(requestParameters: FactureApiApiFacturesPostRequest, options?: AxiosRequestConfig) {
        return FactureApiFp(this.configuration).apiFacturesPost(requestParameters.factureJsonldFactureCreate, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FacturesMultiplesApi - axios parameter creator
 * @export
 */
export const FacturesMultiplesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Créer une facture multiple
         * @summary Créer une facture multiple
         * @param {FacturesMultiplesJsonldFactureMultipleCreate} facturesMultiplesJsonldFactureMultipleCreate The new Factures Multiples resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFacturesMultiplesPost: async (facturesMultiplesJsonldFactureMultipleCreate: FacturesMultiplesJsonldFactureMultipleCreate, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'facturesMultiplesJsonldFactureMultipleCreate' is not null or undefined
            assertParamExists('apiFacturesMultiplesPost', 'facturesMultiplesJsonldFactureMultipleCreate', facturesMultiplesJsonldFactureMultipleCreate)
            const localVarPath = `/facturation/factures-multiples`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(facturesMultiplesJsonldFactureMultipleCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FacturesMultiplesApi - functional programming interface
 * @export
 */
export const FacturesMultiplesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FacturesMultiplesApiAxiosParamCreator(configuration)
    return {
        /**
         * Créer une facture multiple
         * @summary Créer une facture multiple
         * @param {FacturesMultiplesJsonldFactureMultipleCreate} facturesMultiplesJsonldFactureMultipleCreate The new Factures Multiples resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFacturesMultiplesPost(facturesMultiplesJsonldFactureMultipleCreate: FacturesMultiplesJsonldFactureMultipleCreate, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FacturesMultiplesJsonldFactureRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFacturesMultiplesPost(facturesMultiplesJsonldFactureMultipleCreate, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FacturesMultiplesApi - factory interface
 * @export
 */
export const FacturesMultiplesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FacturesMultiplesApiFp(configuration)
    return {
        /**
         * Créer une facture multiple
         * @summary Créer une facture multiple
         * @param {FacturesMultiplesApiApiFacturesMultiplesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFacturesMultiplesPost(requestParameters: FacturesMultiplesApiApiFacturesMultiplesPostRequest, options?: AxiosRequestConfig): AxiosPromise<FacturesMultiplesJsonldFactureRead> {
            return localVarFp.apiFacturesMultiplesPost(requestParameters.facturesMultiplesJsonldFactureMultipleCreate, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiFacturesMultiplesPost operation in FacturesMultiplesApi.
 * @export
 * @interface FacturesMultiplesApiApiFacturesMultiplesPostRequest
 */
export interface FacturesMultiplesApiApiFacturesMultiplesPostRequest {
    /**
     * The new Factures Multiples resource
     * @type {FacturesMultiplesJsonldFactureMultipleCreate}
     * @memberof FacturesMultiplesApiApiFacturesMultiplesPost
     */
    readonly facturesMultiplesJsonldFactureMultipleCreate: FacturesMultiplesJsonldFactureMultipleCreate

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof FacturesMultiplesApiApiFacturesMultiplesPost
     */
    readonly xIdSociete?: number
}

/**
 * FacturesMultiplesApi - object-oriented interface
 * @export
 * @class FacturesMultiplesApi
 * @extends {BaseAPI}
 */
export class FacturesMultiplesApi extends BaseAPI {
    /**
     * Créer une facture multiple
     * @summary Créer une facture multiple
     * @param {FacturesMultiplesApiApiFacturesMultiplesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacturesMultiplesApi
     */
    public apiFacturesMultiplesPost(requestParameters: FacturesMultiplesApiApiFacturesMultiplesPostRequest, options?: AxiosRequestConfig) {
        return FacturesMultiplesApiFp(this.configuration).apiFacturesMultiplesPost(requestParameters.facturesMultiplesJsonldFactureMultipleCreate, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }
}


