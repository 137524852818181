// Article form - Select inputs options :

import { formatOptionList } from '../forms/options';
import { GroupedSelectOptions, SelectInputOptions } from '../forms/types';

/** 'Unité' options */
export const articleUnitsOptions: SelectInputOptions = [
    {
        value: 'F',
        label: 'Forfait',
    },
    {
        value: 'C',
        label: 'Caractère',
    },
    {
        value: 'U',
        label: 'Unité',
    },
    {
        value: 'L',
        label: 'Ligne',
    },
];

/** 'Nature Tva' options */
export const vatCodesOptions: SelectInputOptions = [
    {
        value: 'TN',
        label: 'Taux Normal',
    },
    {
        value: 'TI',
        label: 'Taux Intermédiaire',
    },
    {
        value: 'TR',
        label: 'Taux Réduit',
    },
    {
        value: 'TP',
        label: 'Taux Particulier',
    },
];

/** Specific 'Nature Tva' options */
export const exemptedVatOption: SelectInputOptions = [{ value: 'NS', label: 'Taux exonéré' }];

type InvoicingOptionList = { code?: string | null | undefined }[];

/**
 *  Hardcoded . Annonces Stype (actives) : Todo fetch those from API
 *  ----------------------------------------------------------------
 *
 *  - Groupées par catégorie
 *
 *  SELECT tc.Nom , tc.Description ,type, IDSType type_libelle, ta.IDCategorie FROM TBL_AnnonceSType ta
 *  INNER JOIN TBL_Categorie tc ON  ta.IDCategorie = tc.IDCategorie
 *  WHERE f_actif = 1
 *  ORDER by tc.IDCategorie asc;
 */

export const sTypeOptions: GroupedSelectOptions = [
    {
        label: '(1)	- Constitution - Création de societe',
        options: [
            { value: 45, label: 'Création SA	Création de société anonyme (SA)' },
            { value: 46, label: 'Création SAS	Création de société par actions simplifiée (SAS)' },
            {
                value: 47,
                label: 'Création SASU	Création de société par actions simplifiée unipersonnelle (SASU)',
            },
            { value: 48, label: 'Création SNC	Création de société en nom collectif (SNC)' },
            { value: 49, label: 'Création SARL - SELARL	Création SARL - SELARL' },
            { value: 50, label: 'Création EURL	Création EURL' },
            { value: 51, label: 'Création Société Civile	Création S.Civ' },
            { value: 52, label: 'Création SCI - SCCV	Création SCI - SCCV' },
            { value: 75, label: 'Création autre hors forfait	Constitution Autre' },
            { value: 89, label: 'Création GAEC	Création GAEC' },
        ],
    },
    {
        label: '(2) - Transformation',
        options: [
            { value: 88, label: 'Transformation Autre	Transformation Autre' },
            { value: 78, label: 'Transformation SARL en SCI	Transformation SARL / EURL en SCI' },
            { value: 30, label: 'Transformation SAS en SARL	Transformation SAS en SARL' },
            { value: 31, label: 'Transformation SARL en SAS	Transformation SARL / EURL en SAS' },
        ],
    },

    {
        label: '(5) - Modification',
        options: [
            { value: 87, label: 'Modification Autre	Modification Autre' },
            {
                value: 96,
                label: "Mouvement d'associés - Cessions de parts sociales	Mouvement d'associés - Cessions de parts sociales",
            },
            { value: 5, label: 'Changement de gérant	Changement de dirigeant' },
            { value: 6, label: 'Changement de dénomination	Changement de dénomination' },
            { value: 7, label: "Changement d'objet social	Changement d'objet social de la société" },
            {
                value: 13,
                label: "Continuation d'activité malgré pertes	Continuation d'activités malgré pertes",
            },
            { value: 17, label: 'Changement de durée	Changement de durée de la société' },
            {
                value: 21,
                label: "Modification du capital	Modification du capital social de l'entreprise",
            },
            {
                value: 22,
                label: 'Transfert de siège hors département	Transfert de siège social hors département',
            },
            { value: 23, label: 'Modifications multiples	Modifications multiples' },
            {
                value: 24,
                label: 'Transfert de siège dans le département	Transfert de siège social dans le département',
            },
            { value: 26, label: "Changement d'administrateur	Changement d'administrateur" },
            { value: 29, label: 'Nomination du CAC	Nomination du commissaire aux comptes' },
        ],
    },
    {
        label: '(6) - Dissolution',
        options: [
            {
                value: 28,
                label: 'TUP - Dissolution sans liquidation	TUP - Dissolution sans liquidation',
            },
            {
                value: 11,
                label: 'Ouverture Dissolution Anticipée	Dissolution entreprise et société',
            },
        ],
    },

    {
        label: '(7) - Clôture de liquidation',
        options: [{ value: 12, label: 'Clôture de liquidation	Liquidation entreprise et société' }],
    },

    {
        label: '(8) - Avis',
        options: [
            { value: 93, label: "Reprise d'entreprise 1/2 Tarif	Reprise d'entreprise 1/2 Tarif" },
            { value: 92, label: 'Créances salariales 1/2 Tarif	Créances salariales 1/2 Tarif' },
            { value: 79, label: 'Créance salariale	Créance salariale' },
            { value: 77, label: 'Avis Autre	Avis Autre' },
        ],
    },
    {
        label: '(9)	Vente - Opposition, location gérance',
        options: [
            { value: 10, label: 'Location gérance	Location gérance' },
            { value: 18, label: 'Fin de location gérance	Fin de location gérance' },
            { value: 19, label: 'Cession de fonds de commerce	Cession de fonds de commerce' },
            { value: 20, label: 'Cession de droit au bail	Cession de droit au bail' },
        ],
    },
    {
        label: '(10) Personne physique - Changements de nom ou de régime matrimonial',
        options: [
            { value: 25, label: 'Changement de nom patronymique	Changement de nom' },
            { value: 27, label: "Déclaration d'insaisissabilité	Déclaration d'insaisissabilité" },
            {
                value: 33,
                label: 'Changement de régime matrimonial	Changement de régime matrimonial',
            },
            { value: 90, label: 'Personne physique autre	Personne physique autre' },
            {
                value: 95,
                label: "Acceptation de la succession à concurrence de l'actif net	Acceptation de la succession à concurrence de l'actif net",
            },
        ],
    },
    {
        label: '13	Autre	Ne correspondant pas à une catégorie connue	',
        options: [{ value: 15, label: 'Autre	Autre annonce légale' }],
    },
    {
        label: '14	Rectif et additif	Annonce pour rectificatif ou additif',
        options: [
            { value: 91, label: 'Annonce additive	Additif' },
            { value: 32, label: 'Annonce rectificative	Rectificatif' },
        ],
    },
    {
        label: '21	PCCOL	Procédures collectives',
        options: [
            { value: 83, label: 'PCCOL Jugement de Clôture	Jugement de Clôture' },
            { value: 81, label: 'PCCOL Jugement d’ouverture	Jugement d’ouverture' },
            { value: 85, label: 'PCCOL Autre Jugement	Autre Jugement' },
        ],
    },
    {
        label: '22	PCCOL-NI	Procédures collectives - notif inter greffe',
        options: [
            { value: 86, label: 'PCCOL-NI Autre Jugement	Autre Jugement' },
            { value: 82, label: 'PCCOL-NI Jugement d’ouverture	Jugement d’ouverture' },
            { value: 84, label: 'PCCOL-NI Jugement de Clôture	Jugement de Clôture' },
        ],
    },
    {
        label: '(27) - Succession en déshérence',
        options: [{ value: 41, label: 'Succession en déshérence	Succession en déshérence' }],
    },
    {
        label: '(28) - Succession vacante',
        options: [
            { value: 34, label: 'Succession vacante - Nomination	Succession vacante - Nomination' },
            { value: 35, label: 'Succession vacante - Inventaire	Succession vacante - Inventaire' },
            {
                value: 36,
                label: 'Succession vacante - Projet rgl passif	Succession vacante - Projet rgl passif',
            },
            { value: 37, label: 'Succession vacante - Clôture	Succession vacante - Clôture' },
            {
                value: 38,
                label: 'Succession vacante - Inv./Rgl Pass./Clô.	Succession vacante - Inv./Rgl Pass./Clô.',
            },
            {
                value: 39,
                label: 'Succession vacante - Inv./P. Rgl Passif	Succession vacante - Inv./P. Rgl Passif',
            },
            {
                value: 40,
                label: 'Succession vacante - Inv./Clôture	Succession vacante - Inv./Clôture',
            },
        ],
    },
    {
        label: '(30) Succession testamentaire',
        options: [
            { value: 42, label: 'Succession testamentaire	Succession testamentaire' },
            { value: 43, label: 'Envoi en possession	Envoi en possession' },
        ],
    },
    {
        label: '(31) Avis National - Avis pour parution nationale qui couvre tout le territoire',
        options: [{ value: 44, label: 'Avis National	Avis National' }],
    },
];

export const priceTypesOptions = [
    { label: 'Prix non défini', value: 'not-defined' },
    { label: 'Article gratuit', value: 'free' },
    { label: 'Prix défini', value: 'defined' },
];

/** 2 - FUNCTIONS */

/** Group and format the invoicing categories fetched from API as an option list  */
export const groupInvoicingCategoriesOptions: (
    optionList: InvoicingOptionList,
) => GroupedSelectOptions = (optionList) => {
    // Group discrimination
    const ADVERTS_CODES = ['PUB', 'PUBNS'];
    const REGISTRATION_COSTS_CODES = ['FRS', 'FRN'];
    const isAdvertCode = (code: string) => ADVERTS_CODES.includes(code);
    const isRegistrationCode = (code: string) => REGISTRATION_COSTS_CODES.includes(code);

    const advertOptions = optionList.filter(({ code }: { code: string }) => isAdvertCode(code));
    const registrationOption = optionList.filter(({ code }: { code: string }) =>
        isRegistrationCode(code),
    );
    const otherOptions = optionList.filter(
        ({ code }: { code: string }) => !isAdvertCode(code) && !isRegistrationCode(code),
    );

    // Option format
    const setOptionValue = (option: { code: string }) => option.code;
    const setOptionLabel = (option: { libelle: string; code: string }) =>
        `${option.libelle} (${option.code})`;

    const formatOption = (unformattedOptions: object[]) =>
        formatOptionList(unformattedOptions, {
            value: setOptionValue,
            label: setOptionLabel,
        });

    return [
        {
            label: 'Publicité',
            options: formatOption(advertOptions),
        },
        {
            label: 'Frais de registre',
            options: formatOption(registrationOption),
        },
        {
            label: 'Autres',

            options: formatOption(otherOptions),
        },
    ];
};
