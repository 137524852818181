import React, { FunctionComponent } from 'react';
import { FlexyTabs, LazyBreadcrumb, SkeletonArray } from '@europrocurement/flexy-components';

// Lazy load child components
const UserUnregisteredInvoicesView = React.lazy(
    () => import('./UnregisteredInvoicesViews/UserUnregisteredInvoicesView'),
);
const UnassignedUnregisteredInvoicesView = React.lazy(
    () => import('./UnregisteredInvoicesViews/UnassignedUnregisteredInvoicesView'),
);

const InternUnergisteredInvoicesView: FunctionComponent = function () {
    return (
        <>
            <LazyBreadcrumb
                path={[
                    { path: 'Portail achats', link: '/achats/liste' },
                    'Factures à traiter en interne',
                ]}
            />
            <FlexyTabs
                status={undefined}
                sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '15px' }}
                tabs={[
                    {
                        tabName: 'Mes factures à traiter',
                        tabTitle: `Mes factures à traiter`,
                        tabPanel: (
                            <React.Suspense
                                fallback={
                                    <SkeletonArray
                                        columns={4}
                                        rows={8}
                                    />
                                }
                            >
                                <UserUnregisteredInvoicesView />
                            </React.Suspense>
                        ),
                    },
                    {
                        tabName: 'Factures non attribuées',
                        tabTitle: `Factures non attribuées`,
                        tabPanel: (
                            <React.Suspense
                                fallback={
                                    <SkeletonArray
                                        columns={4}
                                        rows={8}
                                    />
                                }
                            >
                                <UnassignedUnregisteredInvoicesView />
                            </React.Suspense>
                        ),
                    },
                ]}
            />
        </>
    );
};

export default InternUnergisteredInvoicesView;
