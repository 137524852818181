import React, { useEffect } from 'react';

import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import {
    AppDispatch,
    RootStateType,
    selectDossier,
    selectFactureAchat,
    selectMediaObject,
} from '@b2d/redux/RootStore';
import {
    DOSSIERS_SLICE_NAME,
    Dossier,
    FACTURES_ACHATS_SLICE_NAME,
    FactureAchat,
    MEDIAOBJECT_SLICE_NAME,
    MediaObject,
} from '@europrocurement/l2d-domain';
import {
    CenterCircularProgress,
    EmptyBox,
    FlexyHeaderForm,
} from '@europrocurement/flexy-components';

import { Box, Typography } from '@mui/material';
import NoteObject from '../notes/NoteObject';
import { NotesFacture } from '../widgets/NotesFacture';
import { NotesMediaObject } from '../widgets/NotesMediaObject';

export type NoteModalFormType = {
    idMediaObject: number;
    idFacture: number;
    idDossier: number;
};

const NoteModalForm: React.FunctionComponent<NoteModalFormType> = function ({
    idMediaObject,
    idFacture,
    idDossier,
}) {
    const dossierSelected: Dossier | undefined = useSelector(
        (s: RootStateType) => s.dossiers.dos.main.selected,
        shallowEqual,
    );

    const factureSelected: FactureAchat | undefined = useSelector(
        (s: RootStateType) => s.achats.factureachat.main.selected,
        shallowEqual,
    );

    const mediaObjectSelected: MediaObject | undefined = useSelector(
        (s: RootStateType) => s.achats.mediaobject.main.selected,
        shallowEqual,
    );

    const dispatch = useDispatch<AppDispatch>();

    /**
     * Met à jour le mediaObject sélectionné
     */
    useEffect(() => {
        if (mediaObjectSelected && mediaObjectSelected.id !== idMediaObject) {
            dispatch({
                type: `${MEDIAOBJECT_SLICE_NAME}/deletemainSelected`,
            });
        }

        if (!mediaObjectSelected || mediaObjectSelected.id !== idMediaObject) {
            dispatch(
                selectMediaObject({
                    id: idMediaObject,
                }),
            );
        }
    }, [dispatch, mediaObjectSelected, idMediaObject]);

    /**
     * Met à jour la facture sélectionnée
     */
    useEffect(() => {
        if (factureSelected && factureSelected.id !== idFacture) {
            dispatch({
                type: `${FACTURES_ACHATS_SLICE_NAME}/deletemainSelected`,
            });
        }

        if (!factureSelected || factureSelected.id !== idFacture) {
            dispatch(
                selectFactureAchat({
                    id: idFacture,
                }),
            );
        }
    }, [dispatch, factureSelected, idFacture]);

    /**
     * Met à jour le dossier sélectionné
     */
    useEffect(() => {
        if (dossierSelected && dossierSelected.id !== idDossier) {
            dispatch({
                type: `${DOSSIERS_SLICE_NAME}/deletemainSelected`,
            });
        }

        if (!dossierSelected || dossierSelected.id !== idDossier) {
            dispatch(
                selectDossier({
                    id: idDossier,
                }),
            );
        }
    }, [dispatch, dossierSelected, idDossier]);

    return mediaObjectSelected &&
        mediaObjectSelected.id === idMediaObject &&
        factureSelected &&
        factureSelected.id === idFacture &&
        dossierSelected &&
        dossierSelected.id === idDossier ? (
        <Box sx={{ minWidth: '40vw' }}>
            {/* Notes du dossier */}
            <FlexyHeaderForm
                label={
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '100%',
                        }}
                    >
                        <Typography
                            variant="h2"
                            color="secondary"
                        >
                            Note du dossier
                        </Typography>
                    </Box>
                }
            />
            {dossierSelected.notes && factureSelected['@id'] ? (
                <NoteObject
                    note={{
                        '@id': factureSelected['@id'],
                        note: dossierSelected.notes,
                    }}
                    isEditable={false}
                />
            ) : (
                <EmptyBox
                    text="Pas de notes"
                    sx={{
                        marginTop: '10px',
                    }}
                />
            )}
            {/* Notes de traitement du PDF */}
            <NotesMediaObject title="Notes de traitement du PDF" />
            {/* Notes libres */}
            <NotesFacture
                facture={factureSelected}
                displayNoteAllDossiers={false}
                idDossier={idDossier}
                title="Notes libres"
            />
        </Box>
    ) : (
        <CenterCircularProgress
            sx={{
                minHeight: '100px',
                minWidth: '40vw',
            }}
        />
    );
};

export default NoteModalForm;
