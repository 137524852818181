import React, { FunctionComponent, MouseEvent, useContext } from 'react';

import { useSelector } from 'react-redux';
import { Box } from '@mui/material';

import {
    ColumnDatatable,
    FiltersDatatableList,
    FlexyDatatableMaxHeight,
    StoreDatatable,
} from '@europrocurement/flexy-datatable';
import { Dossier } from '@europrocurement/l2d-domain';

import { ModalContext } from '@europrocurement/flexy-components';
import { DossierSelector, getDossier } from '../../../../redux/RootStore';
import { Nullable } from '../../constants/types';

export type SearchDossierModalContentProps = {
    onCloseModal?: (dossier: Nullable<Dossier>) => void;
};

const SearchDossierModalContent: FunctionComponent<SearchDossierModalContentProps> = function ({
    onCloseModal,
}) {
    const dossiersDatasource = useSelector(DossierSelector).main;

    const { modalActions } = useContext(ModalContext);

    const columns: ColumnDatatable<Dossier>[] = [
        {
            label: 'Numéro Dossier',
            render: 'numero',
            isDisplayed: true,
        },
        {
            label: 'Numéro Annonce',
            render: (dos: Dossier) => {
                if (dos.prestations && dos.prestations[0] && dos.prestations[0].numero) {
                    return dos.prestations[0].numero;
                }
                return '';
            },
            isDisplayed: true,
        },
        {
            label: 'Journal',
            render: (dos: Dossier) => {
                if (dos.prestations && dos.prestations[0] && dos.prestations[0].jal?.nom) {
                    return dos.prestations[0].jal?.nom;
                }
                return '';
            },
            isDisplayed: true,
        },
        {
            label: 'Annonceur',
            render: (dos: Dossier) => {
                if (dos.prestations && dos.prestations[0] && dos.prestations[0].annonceur) {
                    return dos.prestations[0].annonceur;
                }
                return '';
            },
            isDisplayed: true,
        },
        {
            label: 'Date de parution',
            render: (dos: Dossier) => {
                if (
                    dos.prestations &&
                    dos.prestations[0] &&
                    dos.prestations[0].dateParutionDemandee
                ) {
                    return new Date(dos.prestations[0].dateParutionDemandee).toLocaleDateString();
                }
                return '';
            },
            isDisplayed: true,
        },
        {
            label: 'Actions',
            onClickCell: () => {},
            render: () => null,
            isDisplayed: true,
        },
    ];

    const filters: FiltersDatatableList = [
        {
            field: 'numero',
            label: 'Numéro de dossier',
            type: 'number',
        },
        {
            field: 'prestationsNumero',
            label: "Numéro d'annonce",
            type: 'text',
        },
        {
            field: 'prestationsJalNom',
            label: 'Journal',
            type: 'text',
        },
        {
            field: 'prestationsAnnonceur',
            label: 'Annonceur',
            type: 'text',
        },
        {
            field: 'prestationsDateParution',
            label: 'Date de parution',
            type: 'date',
        },
        {
            field: 'prestationsDateParutionDemandeeAfter',
            label: 'Début plage de parution',
            type: 'date',
        },
        {
            field: 'prestationsDateParutionDemandeeBefore',
            label: 'Fin plage de parution',
            type: 'date',
        },
    ];

    return (
        <Box
            sx={{
                height: FlexyDatatableMaxHeight,
            }}
        >
            <StoreDatatable
                dataSource={dossiersDatasource}
                columns={columns}
                fetchData={getDossier}
                filters={filters}
                isSearch={false}
                mode="raw"
                fetchWithoutFilter={false}
                onClickRow={(e: MouseEvent, dossier: Dossier) => {
                    if (onCloseModal)
                        modalActions.close(() => {
                            onCloseModal(dossier);
                        });
                }}
            />
        </Box>
    );
};

export default SearchDossierModalContent;
