import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { workflowMailApi } from '@b2d/redux/RootStore';
import {
    ListTypeMailWorkflowMailCollection200ResponseHydraMemberInner,
    MediaObject,
    WorkflowMailApiListTypeMailWorkflowMailCollectionRequest,
} from '@europrocurement/l2d-domain/openApi/ApiAchats';
import { Box, Typography } from '@mui/material';
import { MailApiPostMailMailCollectionRequest } from '@europrocurement/l2d-domain/openApi/ApiMail';
import { CenterCircularProgress } from '@europrocurement/flexy-components';
import { useSociete } from '@b2d/hooks/societeHooks';
import { MailStructureMapType } from '@europrocurement/l2d-modules/MailForm/MailFormItems';
import { MailDetailType, MailForm } from '@europrocurement/l2d-modules';

type TypeMailListRequestInstance = WorkflowMailApiListTypeMailWorkflowMailCollectionRequest;
export type TypeMailList = Exclude<TypeMailListRequestInstance['typeMail'], undefined>;

export const TYPE_MAIL_LIST: readonly TypeMailList[] = [
    'InvalidDocument',
    'MissingInformation',
    'NoncompliantCompany',
    'NotFoundSupplier',
    'UnreadableDocument',
] as const;

export const workFlowMailOptions = TYPE_MAIL_LIST.filter((key) => key !== undefined).map(
    (value, index) => ({
        id: index,
        label: value,
    }),
);

export type OptionType = (typeof workFlowMailOptions)[0];

export type WorkflowMailFormProps = {
    refusalReason: TypeMailList;
    callbackOverride?: (request: MailApiPostMailMailCollectionRequest) => void;
    mediaObject?: MediaObject;
};

const WorkflowMailForm: FunctionComponent<WorkflowMailFormProps> = function ({
    refusalReason,
    callbackOverride,
    mediaObject,
}: WorkflowMailFormProps) {
    const [typeMailInfo, setTypeMailInfo] = useState<
        Array<ListTypeMailWorkflowMailCollection200ResponseHydraMemberInner> | undefined
    >(undefined);
    const [error, setError] = useState<Error | undefined>();
    const [override, setOverride] = useState<Partial<MailStructureMapType>>();
    const [defaultValues, setDefaultValues] = useState<Partial<MailDetailType>>();
    const [files, setFiles] = useState<Array<File>>();

    const xIdSociete = useSociete();

    const getTypeMailInfos = useCallback(
        async (refusalReasonToFetch: TypeMailList) => {
            try {
                const requestParameters: WorkflowMailApiListTypeMailWorkflowMailCollectionRequest =
                    {
                        xIdSociete,
                        typeMail: refusalReasonToFetch,
                    };

                const response =
                    await workflowMailApi.listTypeMailWorkflowMailCollection(requestParameters);

                return response.data['hydra:member'];
            } catch (fetchTypeMailError) {
                console.error('Error fetching type mail information:', fetchTypeMailError);
                throw error;
            }
        },
        [error, xIdSociete],
    );

    useEffect(() => {
        const fetchData = async () => {
            setTypeMailInfo(undefined);

            try {
                const data = await getTypeMailInfos(refusalReason);
                setTypeMailInfo(data);

                if (!data || data.length < 1) {
                    throw new Error('No mail type found');
                } else if (data.length > 1) {
                    throw new Error('Too much mail types retrieved.');
                } else if (data.length === 1) {
                    const { ...newData } = data[0];
                    delete newData.body;
                    const updatedDefaultValues: Partial<MailDetailType> = {
                        ...newData,
                    };

                    setDefaultValues(updatedDefaultValues);
                    setOverride({
                        ccs: {
                            disabled: true,
                            isDisplayable: false,
                            name: 'ccs',
                            type: 'text',
                        },
                        tos: {
                            disabled: true,
                            name: 'tos',
                            type: 'text',
                        },
                        bccs: {
                            disabled: true,
                            isDisplayable: false,
                            name: 'bccs',
                            type: 'text',
                        },
                        tags: {
                            disabled: true,
                            isDisplayable: false,
                            name: 'tags',
                            type: 'text',
                        },
                        subject: {
                            disabled: true,
                            name: 'subject',
                            type: 'text',
                        },
                        body: {
                            required: false,
                            // disabled: true,
                            inputlabel: 'Corps du mail (facultatif)',
                            name: 'body',
                            type: 'textarea',
                        },
                    });
                } else {
                    throw new Error('Unknown error about mail type data retrieval');
                }
            } catch (err: unknown) {
                setError(err as Error);
            }
        };

        fetchData();
    }, [getTypeMailInfos, refusalReason]);

    const getFileFromUrl = async (url: string, fileName: string): Promise<File> => {
        // Fetch the contents of the URL
        const response = await fetch(url);

        // Get the response body as a blob
        const blob = await response.blob();

        // Create a File object from the blob
        // You can specify the file name and MIME type if needed
        const file = new File([blob], fileName, {
            type: blob.type,
        });

        return file;
    };

    useEffect(() => {
        const fetchFiles = async () => {
            const attachments: Array<File> = [];

            if (mediaObject) {
                if (mediaObject.file) {
                    attachments.push(mediaObject.file);
                } else if (mediaObject.contentUrl) {
                    const file = await getFileFromUrl(
                        mediaObject.contentUrl,
                        mediaObject.originalName || '',
                    );

                    attachments.push(file);
                }
            }

            setFiles(attachments);
        };

        fetchFiles();
    }, [mediaObject]);

    if (error) {
        return (
            <Box>
                <Typography variant="h4">{error.message}</Typography>
            </Box>
        );
    }

    if (!typeMailInfo) {
        return <CenterCircularProgress />;
    }

    return (
        <MailForm
            sendMailCallback={callbackOverride || (() => {})}
            defaultValues={defaultValues}
            override={override}
            attachments={files}
            // hideSubmitButton
        />
    );
};

export default WorkflowMailForm;
