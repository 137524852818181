import { RootStateType } from '@b2d/redux/RootStore';
import { OFFER_OPTIONS_SLICE_NAME, OFFRE_REDUCER_NAME } from '@europrocurement/l2d-domain';
import { OptionsOptionRead } from '@europrocurement/l2d-domain/openApi/ApiOffre';
import { offerOptionIcon } from '@europrocurement/l2d-icons';
import { offerOptionsApi } from '@b2d/redux/subReducers/Offers';
import { offerOptionsDataSourcesThunks } from '@b2d/redux/subReducers/Offers/reducers';
import { ModelConfiguration, OfferModelItem, ModelMethods } from './types';

const modelKey = 'offer_option';
const modelName = 'OfferOption';
const sliceName = OFFER_OPTIONS_SLICE_NAME;
const baseSelector = (state: RootStateType) => state[OFFRE_REDUCER_NAME][sliceName];
const datasourcesThunks = offerOptionsDataSourcesThunks;
const api = offerOptionsApi;

type ModelStructure = OptionsOptionRead;

const configuration: ModelConfiguration = {
    key: modelKey,
    name: modelName,
    type: 'Options',
    selector: baseSelector,
    sliceName,
    api,
    datasourcesThunks,
    icon: offerOptionIcon,
};

const methods: ModelMethods<ModelStructure> = {
    getItemDefaultLabel: (model: ModelStructure) => `${model.libelle}`,
    getItemSecondaryLabel: (model: ModelStructure) => `${model.description}`,
};

const endpoints = {
    list: (...args: Parameters<typeof api.apiOptionsGetCollection>) =>
        api.apiOptionsGetCollection(...args),
    dependencies: (...args: Parameters<typeof api.apiOptionsIdDependenciesGet>) =>
        api.apiOptionsIdDependenciesGet(...args),
    read: (...args: Parameters<typeof api.apiOptionsIdGet>) => api.apiOptionsIdGet(...args),
    update: (...args: Parameters<typeof api.apiOptionsIdPut>) => api.apiOptionsIdPut(...args),
    create: (...args: Parameters<typeof api.apiOptionsPost>) => api.apiOptionsPost(...args),
};

type ModelType = OfferModelItem<ModelStructure> & typeof endpoints;

const model: ModelType = {
    ...configuration,
    ...methods,
    ...endpoints,
};

export type OfferOptionModel = ModelType;
export default model;
