import {
    ColumnDatatable,
    DatatablePagination,
    FlexyDatatable,
    FlexyDatatableProps,
} from '@europrocurement/flexy-datatable';
import React, { useState } from 'react';

type StaticDatatableProps = {
    data: Record<string, unknown>[];
    columns: Array<ColumnDatatable>;
    onPaginationChange?: (pagination: { page: number; itemsPerPage: number }) => void;
    totalItemsCount: number;
};

const StaticDatatable: React.FunctionComponent<StaticDatatableProps> = function (props) {
    const { data, columns, onPaginationChange, totalItemsCount } = props;
    const [page, setCurrentPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const offset = page > 0 ? page * itemsPerPage : 0;

    const pagination: DatatablePagination = {
        page,
        itemsPerPage,
        total: totalItemsCount,
        onPageChange: (newValue) => {
            setCurrentPage(newValue);

            if (onPaginationChange) {
                onPaginationChange({ page: newValue, itemsPerPage });
            }
        },
        onItemsPerPageChange: (newValue) => {
            setCurrentPage(0);
            setItemsPerPage(newValue);

            if (onPaginationChange) {
                onPaginationChange({ page, itemsPerPage: newValue });
            }
        },
    };
    const datatableProps: FlexyDatatableProps = {
        status: 'succeeded',
        data: data.slice(offset, offset + itemsPerPage),
        hideColumnOptions: true,
        pagination,
        columns,
    };

    return <FlexyDatatable {...datatableProps} />;
};

export default StaticDatatable;
