import React from 'react';

import { Box } from '@mui/system';
import { FlexyAccordeon } from '@europrocurement/flexy-components';
import { Button, Typography } from '@mui/material';
import { downloadFromUrl } from '@europrocurement/l2d-utils';

// import { JsonView } from 'react-json-view-lite';

export type AttachmentType = {
    id: number;
    contentUrl: string;
    urlExpires?: Date;
    size: number;
    originalName: string;
    mimeType: string;
    deleted?: boolean;
    createdAt?: Date;
    updatedAt?: Date;
    createdBy?: string;
    updatedBy?: string;
};

export type AttachmentsWidgetType = {
    attachments?: AttachmentType[];
};

export const AttachmentsWidget: React.FunctionComponent<AttachmentsWidgetType> = function ({
    attachments,
}) {
    // const getFileContent = async (url: string) => {
    //     const response = await fetch(url);

    //     console.log(response);
    //     const jsonRes = await response.json();
    //     console.log(jsonRes);
    //     return jsonRes;
    // };

    return attachments && attachments.length > 0 ? (
        attachments.map((attachment: AttachmentType) => (
            <Box key={attachment.id}>
                <FlexyAccordeon
                    variante="section"
                    accordionSummary={
                        <Box
                            sx={{
                                width: '100%',
                            }}
                        >
                            {attachment.originalName}
                            <Button
                                sx={{
                                    right: '0px',
                                }}
                                onClick={() => {
                                    downloadFromUrl(attachment.contentUrl, attachment.originalName);
                                }}
                            >
                                Ouvrir
                            </Button>
                        </Box>
                    }
                    accordionDetails={
                        <Box>
                            <Typography>Type de fichier non pris en charge en appercu</Typography>
                        </Box>
                    }
                />
            </Box>
        ))
    ) : (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '5%',
            }}
        >
            <Typography variant="body1">Pas de fichiers joints</Typography>
        </Box>
    );
};

export default AttachmentsWidget;
