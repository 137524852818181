import React, { FunctionComponent } from 'react';

import { Box, Grid, Typography, Card, TypographyProps, LinearProgress } from '@mui/material';

import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';

import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import {
    FaOptionIcon,
    deleteIcon,
    fileIcon,
    fileJpgIcon,
    filePdfIcon,
    filePngIcon,
    openIcon,
    todoIcon,
} from '@europrocurement/l2d-icons';
import { useFakeProgressBar } from '../../../hooks';

type handleClickProps = {
    url: string;
    nom: string;
};

export type currentUploadStatus = 'idle' | 'pending' | 'done' | 'error';

export type DisplayFileProps = {
    url?: string; // nom, poid , mimetype ( changer l'icone en fonction du mimetype )
    nom: string;
    status?: currentUploadStatus;
    color?: TypographyProps['color'];
    backgroundColor?: TypographyProps['color'];
    size?: number;
    mimetype?: string;
    onDelete?: () => void;
    onEdit?: (file: File) => void;
    handleClick?: (props: handleClickProps) => void;
};

export const mimetypeToIcone = (mimetype: string): FontAwesomeIconProps['icon'] => {
    switch (mimetype) {
        case 'application/pdf':
            return filePdfIcon.props.icon;
        case 'image/jpg':
        case 'image/jpeg':
            return fileJpgIcon.props.icon;
        case 'image/png':
            return filePngIcon.props.icon;
        default:
            return fileIcon.props.icon;
    }
};

export const statusToColor = (status: currentUploadStatus): TypographyProps['color'] => {
    switch (status) {
        case 'done':
            return 'success.main';
        case 'error':
            return 'error.main';
        default:
            return 'blueGrey.main';
    }
};

export const statusToPalette = (status: currentUploadStatus): TypographyProps['color'] => {
    switch (status) {
        case 'done':
            return 'success.light';
        case 'error':
            return 'error.light';
        default:
            return 'grey.200';
    }
};

export const sizeInMB = (size: number): string => (size / (1024 * 1024)).toFixed(2);

/**
 *
 * url :
 *      tester que l'url est la bonne
 *
 * icone :
 *      tester que le changement d'icone marche
 *
 * couleur :
 *      tester que le changement de couleur marche
 *
 */

const DisplayFile: FunctionComponent<DisplayFileProps> = function ({
    url,
    size,
    nom,
    mimetype = '',
    status = 'done',
    onDelete,
    onEdit,
    handleClick,
    color,
    backgroundColor,
}) {
    const { progress, progressBuffer, started } = useFakeProgressBar();

    // refaire le compo en 3 box :2 grid 1 de 2 box une autre d'une
    // 1 box logo
    // 1 box flex vertical textes + taille + barre ( voir ce qu'on fait si on a bare + taille )
    // 1 box 2 icones

    return (
        <Card>
            <Grid
                container
                spacing={2}
            >
                <Grid
                    item
                    md={10}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Box
                        display="flex"
                        flexDirection="row"
                        gap="10px"
                    >
                        <Box
                            data-testid="test-id-status-color-box"
                            sx={{
                                backgroundColor: backgroundColor || statusToPalette(status),
                                padding: '10px',
                                borderRadius: '10px',
                                fontSize: '25px',
                                maxHeight: '55px',
                            }}
                        >
                            <FaOptionIcon
                                data-testid="test-id-mimetype-icone"
                                icon={mimetypeToIcone(mimetype)}
                                color={color || statusToColor(status)}
                            />
                        </Box>
                        <Box
                            display="flex"
                            flexDirection="column"
                            gap="5px"
                        >
                            <Box>
                                <Typography variant="h4">
                                    {nom}

                                    {handleClick ? (
                                        <Box
                                            data-testid="test-id-handleClick-button"
                                            display="inline-block"
                                            role="button"
                                            onClick={() => {
                                                if (handleClick && url) {
                                                    handleClick({ nom, url });
                                                }
                                            }}
                                            sx={{
                                                fontSize: '1rem',
                                                marginLeft: '10px',
                                                cursor: 'pointer',
                                            }}
                                        >
                                            <FaOptionIcon {...openIcon.props} />
                                        </Box>
                                    ) : null}
                                </Typography>
                            </Box>

                            <Box>
                                {size && status !== 'pending' ? (
                                    <Box data-testid="test-id-sizeFile">
                                        <>
                                            {sizeInMB(size)} Mb
                                            <FaOptionIcon
                                                icon={faCheckCircle}
                                                iconProps={{
                                                    style: {
                                                        marginLeft: '10px',
                                                    },
                                                }}
                                            />
                                        </>
                                    </Box>
                                ) : null}
                                {status === 'pending' || started ? (
                                    <LinearProgress
                                        data-testid="test-id-progressbar"
                                        variant="buffer"
                                        color={progress < 100 ? 'primary' : 'success'}
                                        value={progress}
                                        valueBuffer={progressBuffer}
                                    />
                                ) : null}
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                <Grid
                    item
                    md={2}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    {onDelete ? (
                        <Box
                            data-testid="test-id-onDelete-button"
                            role="button"
                            onClick={() => {
                                onDelete();
                            }}
                            sx={{
                                // marginRight: '10px',
                                // height: '100%',
                                // display: 'flex',
                                // alignItems: 'center',
                                // flexDirection: 'row-reverse',
                                cursor: 'pointer',
                            }}
                        >
                            <FaOptionIcon {...deleteIcon.props} />
                        </Box>
                    ) : null}
                    {onEdit ? (
                        <Box
                            data-testid="test-id-onEdit-button"
                            role="button"
                            onClick={() => {
                                // onEdit();
                            }}
                            sx={{
                                // marginRight: '10px',
                                // height: '100%',
                                cursor: 'pointer',
                            }}
                        >
                            <FaOptionIcon {...todoIcon.props} />
                        </Box>
                    ) : null}
                </Grid>
            </Grid>
        </Card>
    );
};

export default DisplayFile;
