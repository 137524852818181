import { RootStateType } from '@b2d/redux/RootStore';
import { Dossier, DOSSIER_AUTOCOMPLETE_DATASOURCE_NAME } from '@europrocurement/l2d-domain';
import _ from 'lodash';
import { useSelector } from 'react-redux';

/**
 * The purpose of this hook is to stop having trouble to find correct selected data source for multiple data sources Redux objects.
 */
const useAbstractFolioDataSourceSelected = () => {
    const folioMainSelected: Dossier | undefined = useSelector(
        (s: RootStateType) => s.dossiers.dos.main.selected,
        _.isEqual,
    );

    const folioAutocompleteSelected: Dossier | undefined = useSelector(
        (s: RootStateType) => s.dossiers.dos[DOSSIER_AUTOCOMPLETE_DATASOURCE_NAME].selected,
        _.isEqual,
    );

    const folioSelected: Dossier | undefined = folioAutocompleteSelected || folioMainSelected;

    return {
        folioSelected,
    };
};

export default useAbstractFolioDataSourceSelected;
