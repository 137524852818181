import FakeProgress from '@white-matrix/fake-progress';

import { useState } from 'react';

export const useFakeProgressBar = (autoStart: boolean = false, timeConstant: number = 10000) => {
    const [started, setStarted] = useState<boolean>(false);
    const [currentProgress, setCurrentProgress] = useState<number>(0);
    const [currentProgressBuffer, setCurrentProgressBuffer] = useState<number>(0);

    const progress = new FakeProgress({
        timeConstant,
        autoStart,
    });

    let intervale: NodeJS.Timeout;

    const startProgress = function () {
        setStarted(true);
        intervale = setInterval(() => {
            const newProgress = progress.progress * 90;
            setCurrentProgress(newProgress);

            setCurrentProgressBuffer(
                newProgress + (100 - newProgress) * 0.1 + (100 - newProgress) * 0.2 * Math.random(),
            );
        }, 300);
        progress.start();
    };

    const setProgress = function (newProgres: number) {
        progress.setProgress(newProgres);
    };

    const endProgress = function () {
        clearInterval(intervale);
        setProgress(1);
        setCurrentProgress(100);
        setCurrentProgressBuffer(100);
        setTimeout(() => {
            progress.end();
            setStarted(false);
        }, 1000);
    };

    const pauseProgress = function () {
        progress.stop();
    };

    const getProgress = function () {
        return currentProgress;
    };

    return {
        started,
        progress: currentProgress,
        progressBuffer: currentProgressBuffer,
        endProgress,
        startProgress,
        setProgress,
        pauseProgress,
        getProgress,
    };
};

export default useFakeProgressBar;
