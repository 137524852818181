import React, { Fragment, ReactNode, useMemo } from 'react';

import { Box, FormControlLabel, Stack, SxProps, Typography } from '@mui/material';
import { HeaderSwitch, Switch } from '@europrocurement/flexy-components';
import {
    SectionHeadControlObjectProps,
    SectionHeadControlProps,
} from '../forms/formElements/types';

export type HeaderStructureProps = {
    title: string;
    icon: ReactNode;
    controls?: Array<SectionHeadControlProps> | SectionHeadControlProps;
    switches?: Array<HeaderSwitch>;
    sx?: SxProps;
};

const HeaderStructure: React.FC<HeaderStructureProps> = function (props) {
    const { title, icon, controls, switches, sx } = props;

    const { controlsRight, controlsLeft } = useMemo<{
        controlsRight: Array<ReactNode>;
        controlsLeft: Array<ReactNode>;
    }>(() => {
        const controlsMovingOnTheRight: Array<ReactNode> = [];
        const controlsMovingOnTheLeft: Array<ReactNode> = [];

        const addControl = (control: SectionHeadControlProps) => {
            if (React.isValidElement(control)) {
                controlsMovingOnTheRight.push(control);
            } else if (typeof control === 'object' && control !== null) {
                if ((control as SectionHeadControlObjectProps).side === 'left') {
                    controlsMovingOnTheLeft.push(
                        (control as SectionHeadControlObjectProps).component,
                    );
                } else {
                    controlsMovingOnTheRight.push(
                        (control as SectionHeadControlObjectProps).component,
                    );
                }
            }
        };

        if (Array.isArray(controls)) {
            controls.forEach(addControl);
        } else if (controls) {
            addControl(controls);
        }

        return { controlsRight: controlsMovingOnTheRight, controlsLeft: controlsMovingOnTheLeft };
    }, [controls]);

    return (
        <Box
            data-testid="HeaderStructure"
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                ...sx,
            }}
        >
            <Stack
                direction="row"
                alignItems="center"
                gap={1}
            >
                {icon}
                <Typography
                    variant="h2"
                    sx={{ fontSize: '18px' }}
                >
                    {title}
                </Typography>
                {controlsLeft ? (
                    <Stack
                        direction="row"
                        alignItems="center"
                        gap={1}
                    >
                        {controlsLeft.map((control) => (
                            <Fragment key={`control-left-${control}`}>
                                {/* Render left control */}
                                {control}
                            </Fragment>
                        ))}
                    </Stack>
                ) : null}
            </Stack>
            {controlsRight ? (
                <Stack
                    direction="row"
                    alignItems="center"
                    gap={1}
                    sx={{ marginLeft: 'auto' }}
                >
                    {controlsRight.map((control, index) => {
                        const key = `control-right-${index}`;

                        return (
                            <Fragment key={key}>
                                {/* Render right control */}
                                {control}
                            </Fragment>
                        );
                    })}
                </Stack>
            ) : null}
            {switches ? (
                <Stack
                    direction="row"
                    alignItems="center"
                    gap={1}
                >
                    {switches.map(({ label: switchLabel, ...switchProps }: HeaderSwitch) => (
                        <FormControlLabel
                            sx={{
                                marginLeft: 'auto',
                            }}
                            label={switchLabel}
                            key={`formHeaderBtn-${switchLabel}`}
                            control={
                                <Switch
                                    size="small"
                                    aria-label="switch"
                                    {...switchProps}
                                />
                            }
                        />
                    ))}
                </Stack>
            ) : null}
        </Box>
    );
};

export default HeaderStructure;
