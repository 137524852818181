import React, { useState } from 'react';
import {
    CenterCircularProgress,
    LazyBreadcrumb,
    NavigateNotFound,
    SidePanelCard,
    useModal,
} from '@europrocurement/flexy-components';
import { Box, Grid } from '@mui/material';
import FlexyDatatableFilters from '@europrocurement/flexy-datatable/components/FlexyDatatableFilters/FlexyDatatableFilters';
import { Formalite } from '@europrocurement/l2d-domain';
import ModalConfirm from '@europrocurement/flexy-components/components/organisms/ModalConfirm';
import { FORMALITY_LIST } from '../../constants/paths';
import IncompatibilitiesTable from './IncompatibilitiesTable';
import IncompatibilitiesSearch from './IncompatibilitiesSearch';
import useIncompatibility from '../../hooks/useIncompatibility';

const IncompatibilitiesShow: React.FunctionComponent = function () {
    const [editionMode, setEditionMode] = useState(false);
    const { modalActions } = useModal();
    const { requestState, formalities, searchOpts, getFilteredFormalities, toggleIncompatibility } =
        useIncompatibility();

    if (requestState.isProcessing) {
        return <CenterCircularProgress sx={{ height: '70vh' }} />;
    }

    if (requestState.error) {
        NavigateNotFound();

        return null;
    }

    const onToggleIncompatibility = (formality: Formalite, incompatibility: Formalite) => {
        if (editionMode) {
            toggleIncompatibility(formality, incompatibility);

            return;
        }

        modalActions.call(
            <ModalConfirm
                onConfirm={() => {
                    setEditionMode(true);
                    toggleIncompatibility(formality, incompatibility);
                }}
                message="Êtes vous sur de vouloir modifier les informations de ce tableau ?"
            />,
        );
    };

    return (
        <>
            <LazyBreadcrumb
                path={[{ path: 'Formalités', link: FORMALITY_LIST }, 'Liste des incompatibilités']}
            />
            <Box>
                <SidePanelCard
                    rightMenu={<FlexyDatatableFilters filtersOpts={searchOpts.filtersOpts} />}
                    sx={{
                        width: '100%',
                        maxHeight: '90vh',
                    }}
                >
                    <Grid
                        container
                        flexDirection="column"
                        sx={{ padding: '25px', boxSizing: 'border-box' }}
                    >
                        <Grid
                            item
                            sx={{ maxWidth: '100%' }}
                        >
                            <IncompatibilitiesSearch searchOpts={searchOpts} />
                        </Grid>
                        <Grid
                            item
                            sx={{
                                maxWidth: '100%',
                                overflowX: 'scroll',
                            }}
                        >
                            <IncompatibilitiesTable
                                formalities={formalities}
                                filteredFormalities={getFilteredFormalities()}
                                onToggleIncompatibility={onToggleIncompatibility}
                            />
                        </Grid>
                    </Grid>
                </SidePanelCard>
            </Box>
        </>
    );
};

export default IncompatibilitiesShow;
