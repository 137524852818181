import { getMediaObject, getMediaObjectByViewName } from '@b2d/redux/RootStore';
import useUrlParameters from '@europrocurement/flexy-components/hooks/useUrlParameters';
import { useCallback } from 'react';
import { ViewName } from './types';

const useHandleListParam = () => {
    type AllowedListName = ViewName;

    // Type guard function to check if a string is an AllowedListName
    const isAllowedListName = useCallback(
        (value: string): value is AllowedListName =>
            value === 'unregistered' ||
            value === 'user_unregistered' ||
            value === 'unassigned_unregistered',
        [],
    );

    // Gestion du router
    const { urlParameters, generateParams } = useUrlParameters();

    /**
     * Emmet
     */
    const applyListUrlParameter = useCallback(
        (listName: AllowedListName): URLSearchParams | undefined =>
            generateParams({
                from: listName,
            }),
        [generateParams],
    );

    /**
     * Receive
     */
    const retrieveListUrlParameter = useCallback(() => {
        const listName = urlParameters.from;

        if (isAllowedListName(listName)) {
            return getMediaObjectByViewName(listName);
        }

        return getMediaObject({});
    }, [isAllowedListName, urlParameters.from]);

    return {
        applyListUrlParameter,
        retrieveListUrlParameter,
    };
};

export default useHandleListParam;
