import React from 'react';
import { TableCell as MaterialTableCel } from '@mui/material';
import { useTheme } from '@mui/system';

const DisableTableCel: React.FunctionComponent = function () {
    const theme = useTheme();
    const celStyle = {
        backgroundColor:
            theme.palette.mode === 'dark'
                ? theme.palette.grey[200]
                : theme.palette.background.default,
        borderBottomColor: theme.palette.grey[300],
    };

    return <MaterialTableCel sx={celStyle} />;
};

export default DisableTableCel;
