import { useEffect, useMemo, useRef } from 'react';

import { FactureFormReducerType } from '@b2d/redux/FactureFormReducer';
import { DossierFormalite } from '@europrocurement/l2d-domain';
import { DataSource, getDataThunkType } from '@europrocurement/l2d-redux-utils';
import { HeaderSwitch } from '@europrocurement/flexy-components';
import { FormStructure } from '@europrocurement/flexy-form';
import { ModeProps, SectionHeadControlProps } from '../types';
import { folioFormalitySectionWithAdvancedResearchStructure } from '../fragments/folioFormalitySection';

export type UseFolioFormalitySectionStructureProps = {
    dataSource: DataSource<DossierFormalite>;
    fetchData: getDataThunkType<DossierFormalite>;
    mode: ModeProps;
    headerSwitches?: Array<HeaderSwitch>;
    headerChildren?: Array<SectionHeadControlProps> | SectionHeadControlProps;
    state: FactureFormReducerType;
    onClickModal: () => void;
};

const useFolioFormalitySectionStructure = ({
    dataSource,
    fetchData,
    mode,
    headerSwitches,
    headerChildren,
    state,
    onClickModal,
}: UseFolioFormalitySectionStructureProps) => {
    const prevDataSourceRef = useRef(dataSource);

    const folioFormalitySectionStructure = useMemo<Array<FormStructure>>(
        () =>
            folioFormalitySectionWithAdvancedResearchStructure({
                onClickModal,
                autocompleteProps: {
                    formality: {
                        dataSource,
                        fetchData,
                        defaultValue: state.defaultNumeroDossierFormalite,
                    },
                },
                headerSwitches,
                headerChildren,
                mode,
            }),
        [
            fetchData,
            dataSource,
            onClickModal,
            headerChildren,
            headerSwitches,
            mode,
            state.defaultNumeroDossierFormalite,
        ],
    );

    useEffect(() => {
        prevDataSourceRef.current = dataSource;
    }, [dataSource]);

    return {
        folioFormalitySectionStructure,
    };
};

export default useFolioFormalitySectionStructure;
