import { specificsArticleLabels } from '@b2d/pages/Offres/constants';
import { RootStateType } from '@b2d/redux/RootStore';
import {
    INVOICING_CATEGORIES_SLICE_NAME,
    OFFRE_REDUCER_NAME,
    REDUX_LIB_ARTICLE,
} from '@europrocurement/l2d-domain';

export const InvoicingCategoriesSelector = (state: RootStateType) =>
    state[OFFRE_REDUCER_NAME][INVOICING_CATEGORIES_SLICE_NAME];

export const sectionTitleArticleId = (state: RootStateType) =>
    state[OFFRE_REDUCER_NAME][REDUX_LIB_ARTICLE].result?.find(
        (result) => result.libelle === specificsArticleLabels.section,
    )?.id;

export const lineBreakArticleId = (state: RootStateType) =>
    state[OFFRE_REDUCER_NAME][REDUX_LIB_ARTICLE].result?.find(
        (result) => result.libelle === specificsArticleLabels.lineBreak,
    )?.id;
