import React from 'react';

export const useRank = () => {
    const [currentRank, setRank] = React.useState<number>(0);
    const incrementStep = 10;

    /** Increment rank by step and return current rank */
    const increment: () => number = () => {
        setRank(currentRank + incrementStep);
        return currentRank;
    };

    /** Return rank value based on array index value */
    const getRankByArrayIndex: (index: number) => number = (index) => index * incrementStep;

    return { currentRank, increment, getRankByArrayIndex };
};

export default useRank;
