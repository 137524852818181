import { createContext } from 'react';

export type VerbosityLevel = 0 | 1 | 2 | 3;

export const verbosityColors: Record<VerbosityLevel, string> = {
    0: 'red',
    1: 'orange',
    2: 'yellow',
    3: 'cyan',
};

export const verbosityNames: Record<VerbosityLevel, string> = {
    0: 'CRITIC',
    1: 'WARNING',
    2: 'INFO',
    3: 'DEBUG',
};

export type VerboseContextProps = {
    enable?: boolean;
    level?: VerbosityLevel;
    setLevel: (verboseLevel: VerbosityLevel) => void;
    log: (...data: unknown[]) => void;
};

const VerboseContext = createContext<VerboseContextProps>({
    enable: false,
    level: 3,
    setLevel: (verboseLevel: VerbosityLevel) => verboseLevel,
    log: (...data: unknown[]) => {
        // eslint-disable-next-line no-console
        console.log(data);
    },
});

export default VerboseContext;
