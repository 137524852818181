import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import FormalitySelector from './FormalitySelector';
import FormalitySelectCategories from './FormalitySelectCategories';

type FormalitySelectProps = {
    selectedFormalityIds: number[];
    excludeItems: number[];
    onUpdate: (items: number[]) => void;
};

const FormalitySelect: React.FunctionComponent<FormalitySelectProps> = function (props) {
    const { selectedFormalityIds, excludeItems, onUpdate } = props;
    const [selectedItems, setSelectedItems] = useState(selectedFormalityIds);
    const [selectedCategoryId, setSelectedCategoryId] = useState(null);

    const onUpdateCategory = (categoryId: number) => {
        setSelectedCategoryId(categoryId);
    };

    const onCheckItem = (formalityId: number) => {
        if (selectedItems.indexOf(formalityId) === -1) {
            const tmp = [...selectedItems];
            tmp.push(formalityId);
            setSelectedItems(tmp);
        }
    };

    const onUncheckItem = (formalityId: number) => {
        if (selectedItems.indexOf(formalityId) !== -1) {
            const tmp = [...selectedItems];
            tmp.splice(tmp.indexOf(formalityId), 1);
            setSelectedItems(tmp);
        }
    };

    useEffect(() => {
        onUpdate(selectedItems);
    }, [selectedItems, onUpdate]);

    return (
        <Grid
            container
            flexWrap="nowrap"
        >
            <Grid
                item
                sx={{ width: '20%' }}
            >
                <FormalitySelectCategories
                    onSelectCategory={onUpdateCategory}
                    selectedItemId={selectedCategoryId}
                />
            </Grid>
            <Grid
                item
                flexGrow="1"
            >
                <FormalitySelector
                    categoryId={selectedCategoryId}
                    selectedItems={selectedItems}
                    onCheckItem={onCheckItem}
                    onUncheckItem={onUncheckItem}
                    excludeItems={excludeItems}
                />
            </Grid>
        </Grid>
    );
};

export default FormalitySelect;
