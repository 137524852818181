import React, { useState } from 'react';
import { Box } from '@mui/material';
import { Formalite } from '@europrocurement/l2d-domain/reducers/formalites/slices/formalite';
import FormalityCard from './FormalityCard';
import FormalityEditModal from './FormalityEditModal';
import FormalityDocuments from './FormalityDocuments';

type FormalityMainTabProps = {
    formality: Formalite;
};

const FormalityMainTab: React.FunctionComponent<FormalityMainTabProps> = function (props) {
    const { formality } = props;
    const [editModalOpen, setEditModalOpen] = useState(false);
    const onOpenMainInformationsModal = () => {
        setEditModalOpen(true);
    };

    return (
        <>
            <Box>
                <FormalityCard
                    formality={formality}
                    handleEditMainInformation={onOpenMainInformationsModal}
                />
            </Box>
            <Box>
                <FormalityDocuments formality={formality} />
            </Box>
            <FormalityEditModal
                formality={formality}
                isOpen={editModalOpen}
                onClose={() => {
                    setEditModalOpen(false);
                }}
            />
        </>
    );
};

export default FormalityMainTab;
