import * as React from 'react';
import { UseKeycloakService } from '../../KeycloakProvider';

export type KeycloakHasRoleProps = {
    children: React.ReactNode;
    elseChildren?: React.ReactNode;
    kcRole: undefined | string | string[];
};

/**
 * Agit comme un provider.
 * Il permet de vérifier auprès du service keycloak que l'utilisateur dispose d'un rôle.
 *
 * ## Utilisation
 *
 * ### props
 * role: [string] le rôle à controller
 * children: Composant enfant à afficher SI l'utilisateur a le rôle.
 * elseChildren: Composant enfant à afficher si l'utilisateur n'a PAS le rôle.
 */
export const KeycloakHasRole = function ({ children, elseChildren, kcRole }: KeycloakHasRoleProps) {
    const KeycloakService = UseKeycloakService();

    const show = function (roleRoCheck: undefined | string | string[]): boolean {
        return KeycloakService.checkRole(roleRoCheck);
    };

    if (show(kcRole)) {
        return children;
    }
    if (elseChildren === undefined) {
        return null;
    }
    return elseChildren;
};

export default KeycloakHasRole;
