/* eslint-disable camelcase */

import type { RubriqueFacturation } from '@europrocurement/l2d-domain';
import type { TblFligneFactureVenteRead } from '@europrocurement/l2d-domain/openApi/ApiDossiers';
import type { LigneProduit } from '@b2d/pages/Achats/components/forms/types';

import {
    convertToNumberAndFormatToString,
    getTTC_HT_TxTVA,
    getTVA_HT_TxTVA,
} from '@b2d/pages/Achats/components/forms/functions/calculsProduits';

export default (produitVente: TblFligneFactureVenteRead, rubFacts: RubriqueFacturation[]) => {
    const res: LigneProduit = {
        idLigneVente: produitVente.id,
        libelle: produitVente.libelle ? produitVente.libelle : undefined,
        ht: produitVente.montant ? produitVente.montant : undefined,
        tva:
            produitVente.montant && produitVente.tauxTva
                ? convertToNumberAndFormatToString(
                      getTVA_HT_TxTVA(produitVente.montant, produitVente.tauxTva),
                  )
                : undefined,
        ttc:
            produitVente.montant && produitVente.tauxTva
                ? convertToNumberAndFormatToString(
                      getTTC_HT_TxTVA(produitVente.montant, produitVente.tauxTva),
                  )
                : undefined,
    };

    if (produitVente.rubriqueFacturation && produitVente.rubriqueFacturation !== '') {
        res.rubriqueFacturation = {
            code: produitVente.rubriqueFacturation,
            id:
                rubFacts.find(
                    (rubFact: RubriqueFacturation) =>
                        rubFact.code === produitVente.rubriqueFacturation,
                )?.id || 0,
        };
    }
    return res;
};
