import React from 'react';
import { Formalite } from '@europrocurement/l2d-domain/reducers/formalites/slices/formalite';
import { FlexyTabs } from '@europrocurement/flexy-components';
import FormalityMainTab from './FormalityMainTab';
import FormalityLegalStatusesTab from './FormalityLegalStatusesTab';
import FormalityIncompatibilitiesTab from './FormalityIncompatibilitiesTab';

type FormalityShowLayoutProps = {
    formality: Formalite;
};

const FormalityShowLayout: React.FunctionComponent<FormalityShowLayoutProps> = function (props) {
    const { formality } = props;

    return (
        <FlexyTabs
            scrollbar={false}
            tabs={[
                {
                    tabName: 'main',
                    tabPanel: <FormalityMainTab formality={formality} />,
                    tabTitle: 'Informations générales',
                },
                {
                    tabName: 'legalStatuses',
                    tabPanel: <FormalityLegalStatusesTab formality={formality} />,
                    tabTitle: 'Formes juridiques',
                },
                {
                    tabName: 'incompatibilities',
                    tabPanel: <FormalityIncompatibilitiesTab formality={formality} />,
                    tabTitle: 'Incompatibilités',
                },
            ]}
            routerParameterName="formalityTab"
        />
    );
};

export default FormalityShowLayout;
