import { DARK_THEME, SET_ID_SOCIETE, THEME_COLOR, THEME_LIST } from '../../constants';
import { type CustomizerReducerType } from './CustomizerReducer';

export const setTheme = (payload: THEME_LIST) => ({
    type: THEME_COLOR,
    payload,
});

export const setDarkMode = (payload: CustomizerReducerType['activeMode']) => ({
    type: DARK_THEME,
    payload,
});

export const setXIdSociete = (payload: CustomizerReducerType['xIdSociete']) => ({
    type: SET_ID_SOCIETE,
    payload,
});
