/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, type FunctionComponent } from 'react';
import { ActiveFilterBadge, isValidDate } from '@europrocurement/flexy-components';
import { Typography } from '@mui/material';
import { FiltersOpts } from '../DatatableHeader';

export type FieldAndValueFilter = {
    label: string;
    field: string;
    value: string;
};

// Formate un interval de dates
const dateRangeToString = (dateRange: string[]): string => {
    const beforeDate = new Date(dateRange[0]).toLocaleDateString('FR-fr');
    const afterDate = new Date(dateRange[1]).toLocaleDateString('FR-fr');
    return beforeDate === afterDate ? afterDate : `${afterDate} au ${beforeDate}`;
};

export type FlexyFiltersListProps = {
    filtersOpts?: FiltersOpts;
};

const FlexyFiltersList: FunctionComponent<FlexyFiltersListProps> = function ({
    filtersOpts,
}: FlexyFiltersListProps) {
    const [availableActiveFilters, setAvailableActiveFilters] = useState<
        Record<string, unknown> | undefined
    >(undefined);

    useEffect(() => {
        setAvailableActiveFilters(filtersOpts?.activeFilters);
    }, [filtersOpts?.activeFilters, setAvailableActiveFilters]);

    let filtersList = null;

    if (availableActiveFilters) {
        const arrayOfKeys: Array<string> = Object.getOwnPropertyNames(
            availableActiveFilters,
        ).filter((filterName) => filtersOpts?.filters.some((item) => item.field === filterName));

        const arrayOfObject: Array<FieldAndValueFilter> = arrayOfKeys.map((key) => {
            const foundFilter = filtersOpts?.filters.find((filter) => filter.field === key);

            return {
                label: foundFilter?.label.replace(/^\w/, (c) => c.toUpperCase()) || '',
                field: key,
                value: String(
                    foundFilter?.renderFilterList
                        ? foundFilter.renderFilterList(availableActiveFilters[key])
                        : availableActiveFilters[key] === true
                          ? 'Oui'
                          : availableActiveFilters[key] === false
                            ? 'Non'
                            : isValidDate(availableActiveFilters[key])
                              ? (availableActiveFilters[key] as Date).toLocaleDateString()
                              : key === 'dateFacture' || key === 'createdAt'
                                ? dateRangeToString(availableActiveFilters[key] as string[])
                                : availableActiveFilters[key],
                ),
            };
        });

        filtersList = (
            <>
                {arrayOfObject.length > 0 ? (
                    <Typography
                        padding={2}
                        component="div"
                    >
                        Filtres actifs :
                        {arrayOfObject.map(({ field, value, label }) => (
                            <ActiveFilterBadge
                                sx={{
                                    marginLeft: '10px',
                                }}
                                key={field + value}
                                field={label}
                                value={value}
                                onDelete={() => filtersOpts?.onFilterDelete(field)}
                            />
                        ))}
                    </Typography>
                ) : null}
            </>
        );
    }
    return availableActiveFilters ? filtersList : null;
};

export default FlexyFiltersList;
