import { LigneProduit } from '@b2d/pages/Achats/components/forms/types';
import React, { FunctionComponent, useMemo } from 'react';
import AdditionalDataLine from './AdditionalDataLine';

type AdditionalQuoteLineProps = {
    lineProductQuote: LigneProduit;
};

const AdditionalQuoteLine: FunctionComponent<AdditionalQuoteLineProps> = function ({
    lineProductQuote,
}: AdditionalQuoteLineProps) {
    const data: Array<string> = useMemo(
        () => [lineProductQuote.rubriqueFacturation?.code || '?', lineProductQuote.libelle || '?'],
        [lineProductQuote.libelle, lineProductQuote.rubriqueFacturation?.code],
    );

    return (
        <AdditionalDataLine
            libelle="Devis"
            data={data}
        />
    );
};

export default AdditionalQuoteLine;
