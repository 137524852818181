import { ConfigurationParameters as DossierConfigurationParameters } from '../../openApi/ApiDossiers';
import { createCommandeSlice } from './slices/commandeSlice';
import { createDossierSlice } from './slices/dossierSlice';
import { createFactureVenteSlice } from './slices/factureVenteSlice';
import { createJournalSlice } from './slices/journalSlice';
import { createRubriqueFacturationSlice } from './slices/rubriqueFactSlice';
import { createTauxTvaSlice } from './slices/tauxTvaSlice';

export { type DossierConfigurationParameters };

export function createCommandeReducer(configuration: DossierConfigurationParameters) {
    const { commandeSlice, commandeDataSourcesThunks } = createCommandeSlice(configuration);
    return { commandeSlice, commandeDataSourcesThunks };
}

export function createDossierReducer(configuration: DossierConfigurationParameters) {
    const { dossierSlice, dossierDataSourcesThunks } = createDossierSlice(configuration);
    return { dossierSlice, dossierDataSourcesThunks };
}

export function createFactureVenteReducer(configuration: DossierConfigurationParameters) {
    const { factureVenteSlice, factureVenteDataSourcesThunks } =
        createFactureVenteSlice(configuration);
    return { factureVenteSlice, factureVenteDataSourcesThunks };
}

export function createJournalReducer(configuration: DossierConfigurationParameters) {
    const { journalSlice, journalDataSourcesThunks } = createJournalSlice(configuration);
    return { journalSlice, journalDataSourcesThunks };
}

export function createRubriqueFacturationReducer(configuration: DossierConfigurationParameters) {
    const { rubriqueFacturationSlice, rubriqueFacturationDataSourcesThunks } =
        createRubriqueFacturationSlice(configuration);
    return { rubriqueFacturationSlice, rubriqueFacturationDataSourcesThunks };
}

export function createTauxTvaReducer(configuration: DossierConfigurationParameters) {
    const { tauxTvaSlice, tauxTvaDataSourcesThunks } = createTauxTvaSlice(configuration);
    return { tauxTvaSlice, tauxTvaDataSourcesThunks };
}
