/* eslint-disable @typescript-eslint/no-unused-vars */

import {
    SliceFactory,
    FetchCollectionData,
    FetchItemDataType,
    ApiCollectionResponse,
} from '@europrocurement/l2d-redux-utils';
import { Order } from '@europrocurement/l2d-domain/types/apiGenericTypes';
import {
    DemandeurApi as DemandeurSignatureApi,
    DemandeurApiGetDemandeurCollectionRequest,
} from '../../../openApi/ApiSign';

import {
    DemandeurSignatureApiObject,
    DemandeurSignature,
    DossierSignatureConfigurationParameters,
    DossierSignatureConfiguration,
} from '../types';

import { SIGNATURE_REDUCER_NAME } from '../constants';

export const DEMANDEUR_SIGNATURE_SLICE_NAME = 'demandeurSignature';

export { DemandeurSignatureApi };

const mapperDemandeur = function (demandeurSignature: DemandeurSignatureApiObject) {
    return {
        ...demandeurSignature,
    } as DemandeurSignature;
};

export function createDemandeurSignatureSlice(
    configuration: DossierSignatureConfigurationParameters,
) {
    const conf = new DossierSignatureConfiguration(configuration);
    const api = new DemandeurSignatureApi(conf);

    const fetchDemandeursCollection: FetchCollectionData<DemandeurSignature> = ({
        search,
        pagination,
        filters,
        orders,
        xIdSociete,
        rootstate,
    }) => {
        const page: number = pagination.page + 1;
        let signatairesSignature: boolean | undefined;

        const requestParameters: DemandeurApiGetDemandeurCollectionRequest = {
            page: pagination.page + 1,
            itemsPerPage: pagination.itemsPerPage,
        };

        /**
         *
         * Filtre multiple serach
         *
         * pagination complete
         *
         * isoler les vrais filtre chaine des recheche dans un champ
         *
         * order
         *
         */

        return api.getDemandeurCollection(requestParameters).then((res) => res.data) as Promise<
            ApiCollectionResponse<DemandeurSignatureApiObject>
        >;
    };

    const fetchDemandeursItem: FetchItemDataType<DemandeurSignatureApiObject> = ({
        idItem,
        search,
        pagination,
        filters,
        orders,
        xIdSociete,
        rootstate,
    }) =>
        api
            .getDemandeurItem({ id: String(idItem) })
            .then((res) => res.data) as Promise<DemandeurSignatureApiObject>;

    const {
        slice: demandeurSignatureSlice,
        dataSourcesThunks: demandeurSignatureDataSourcesThunks,
    } = SliceFactory.createSlice<DemandeurSignatureApiObject, DemandeurSignature>(
        DEMANDEUR_SIGNATURE_SLICE_NAME,
        SIGNATURE_REDUCER_NAME,
        [],
        fetchDemandeursCollection,
        fetchDemandeursItem,
        mapperDemandeur,
    );

    return { demandeurSignatureSlice, demandeurSignatureDataSourcesThunks };
}
