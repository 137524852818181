import * as React from 'react';
import { KeycloakRoute } from '@europrocurement/l2d-keycloak';
import { Outlet } from 'react-router';

const SignatureRoot = function () {
    return (
        <KeycloakRoute
            roles="api-sign:dossier-read"
            data-testid="signature-root"
        >
            <Outlet />
        </KeycloakRoute>
    );
};

export default SignatureRoot;
