import type {
    FactureAchatLigneDetailFactureAchatRead,
    FactureAchatLigneJsonldFactureAchatRead,
} from '@europrocurement/l2d-domain/openApi/ApiAchats';
import type { LigneProduit, TableauProduits } from '@b2d/pages/Achats/components/forms/types';
import { RubriqueFacturation } from '@europrocurement/l2d-domain';
import { convertToNumberAndFormatToString } from '../../calculsProduits';
import { findRubFactById } from '../../produitFunctions';

export default (
    lignes: FactureAchatLigneJsonldFactureAchatRead[],
    invoiceCategories?: Array<RubriqueFacturation>,
) => {
    const products = lignes.reduce((prev: TableauProduits, current) => {
        if (!current.details) {
            return prev;
        }
        const res: TableauProduits = [
            ...prev,
            ...current.details.map((ligneDetail: FactureAchatLigneDetailFactureAchatRead) => {
                const resMap: LigneProduit = {};

                if (ligneDetail.ht) resMap.ht = +ligneDetail.ht;
                if (ligneDetail.tva)
                    resMap.tva = convertToNumberAndFormatToString(+ligneDetail.tva);
                if (ligneDetail.ht && ligneDetail.tva)
                    resMap.ttc = convertToNumberAndFormatToString(
                        +ligneDetail.tva + +ligneDetail.ht,
                    );
                if (ligneDetail.tauxTva || ligneDetail.tauxTva === 0)
                    resMap.txtva = {
                        label: ligneDetail.tauxTva !== 0 ? +ligneDetail.tauxTva / 100 : 0,
                        value: ligneDetail.tauxTva !== 0 ? +ligneDetail.tauxTva / 100 : 0,
                    };
                if (ligneDetail.idRubriqueFacturation) {
                    const foundInvoiceCategory = invoiceCategories
                        ? findRubFactById(invoiceCategories, ligneDetail.idRubriqueFacturation)
                        : undefined;

                    if (
                        foundInvoiceCategory &&
                        foundInvoiceCategory.id &&
                        foundInvoiceCategory.code
                    ) {
                        resMap.rubriqueFacturation = {
                            id: foundInvoiceCategory.id,
                            code: foundInvoiceCategory.code,
                        };
                    } else {
                        resMap.rubriqueFacturation = {
                            id: ligneDetail.idRubriqueFacturation,
                        };
                    }
                }
                if (current.idFLigneVente) resMap.idLigneVente = current.idFLigneVente;

                return resMap;
            }),
        ];
        return res;
    }, []);

    return products;
};
