import { FieldErrors, FieldValues } from 'react-hook-form';

/** Retreive error based on input name (flat or nested with dot notation) */
export const getError = (name: string, errors: FieldErrors<FieldValues>) => {
    if (!name || name === '') {
        return null;
    }
    if (name in errors) {
        return errors[name];
    }

    let current = errors;

    const path = name.split('.');

    for (let i = 0; i < path.length; ++i) {
        if (current[path[i]] === undefined) {
            return undefined;
        }
        current = current[path[i]] as FieldErrors<FieldValues>;
    }

    return current;
};

/** Format error message based on input name (flat or nested with dot notation) */
export const getErrorMessage = (name: string, errors: FieldErrors<FieldValues>) => {
    const error = getError(name, errors);
    if (!error) {
        return '';
    }

    const { message, type } = error;
    if (type === 'invalid_type' && message === 'Required') {
        return 'Le champs est requis';
    }
    if (message) {
        return message.toString();
    }

    return '';
};

/** Retreive error status based on input name (flat or nested with dot notation) */
export const getErrorStatus = (name: string, errors: FieldErrors<FieldValues>) => {
    const error = getError(name, errors);

    if (error) {
        return true;
    }
    // const path = name.split('.')

    return false;
};
