import {
    compareProduits,
    productLineCalculation,
} from '@b2d/pages/Achats/components/forms/functions/calculsProduits';

import type { ExtandedFormContext } from '@europrocurement/flexy-form';
import type { LigneProduitRegistered } from '@b2d/pages/Achats/components/forms/types';

export type ConsistentLinesBetweenFormAndContextProps = {
    existingTemporaryLinesFilled: Array<LigneProduitRegistered>;
    arrayToFill: Array<LigneProduitRegistered>;
};

/**
 * Ensures consistency between form context and current lines in the temporary state.
 * This function prevents missing data in temporary lines when the user adds a new line.
 *
 * @param existingTemporaryLinesFilled - Array of temporary lines containing the values we want to keep through the "add new line" action.
 * @param arrayToFill - Array of temporary lines which might lose their data during the "add new line" action.
 * @returns A formatted array of ProductLines with consistent data.
 */
export const consistentLinesBetweenFormAndContext = ({
    existingTemporaryLinesFilled,
    arrayToFill,
}: ConsistentLinesBetweenFormAndContextProps) => {
    const consistentTemporaryLinesArray = arrayToFill;

    consistentTemporaryLinesArray.map((lineToConsist, index) => {
        const overwriteLineToConsist = lineToConsist;
        const lineFromForm = existingTemporaryLinesFilled[index];

        if (
            !!overwriteLineToConsist &&
            overwriteLineToConsist.id &&
            !!existingTemporaryLinesFilled[index] &&
            existingTemporaryLinesFilled[index].idLigne === ''
        ) {
            overwriteLineToConsist.ordre = lineFromForm.ordre;
            overwriteLineToConsist.rubriqueFacturation = lineFromForm.rubriqueFacturation;
            overwriteLineToConsist.ht = lineFromForm.ht;
            overwriteLineToConsist.txtva = lineFromForm.txtva;
            overwriteLineToConsist.tva = lineFromForm.tva;
            overwriteLineToConsist.ttc = lineFromForm.ttc;
        }

        return overwriteLineToConsist;
    });

    return consistentTemporaryLinesArray;
};

/**
 * Filters and sorts registered lines by their `idLigne`.
 *
 * @param linesWithoutCurrentItem - Array of product lines to be filtered and sorted.
 * @returns Array of filtered and sorted product lines, keeping only those with `idLigne`.
 */
export const filterAndSortRegisteredLines = (
    linesWithoutCurrentItem: Array<LigneProduitRegistered>,
) => linesWithoutCurrentItem.filter((line) => line.idLigne).sort(compareProduits);

/**
 * Filters out registered lines and appends the current item to the list.
 *
 * @param linesWithoutCurrentItem - Array of product lines to filter.
 * @param item - The new product line item to be appended.
 * @returns Array of unregistered product lines with the current item appended.
 */
export const filterUnregisteredLines = (
    linesWithoutCurrentItem: Array<LigneProduitRegistered>,
    item: LigneProduitRegistered,
) => [...linesWithoutCurrentItem.filter((line) => !line.idLigne), item];

/**
 * Completes and sorts temporary lines using data from form context and unregistered lines.
 *
 * @param newProducts - Array of new product lines from the form context.
 * @param linesUnregistered - Array of unregistered product lines.
 * @returns Array of product lines that are completed with correct values, sorted, and calculated.
 */
export const completeAndSortTemporaryLines = (
    newProducts: Array<LigneProduitRegistered>,
    linesUnregistered: Array<LigneProduitRegistered>,
) => {
    const temporaryLinesWithCorrectValues = consistentLinesBetweenFormAndContext({
        existingTemporaryLinesFilled: newProducts,
        arrayToFill: linesUnregistered,
    });

    return temporaryLinesWithCorrectValues.sort(compareProduits).map((formProduct) => ({
        ...formProduct,
        ...productLineCalculation(formProduct),
    }));
};

/**
 * Combines registered and processed new product lines, and updates the form context with the new array.
 *
 * @param formContextOnAppend - The form context object used to update the form state.
 * @param linesRegistered - Array of registered product lines.
 * @param processedNewProducts - Array of processed new product lines.
 */
export const combineAndUpdateProducts = (
    formContextOnAppend: ExtandedFormContext,
    linesRegistered: Array<LigneProduitRegistered>,
    processedNewProducts: Array<LigneProduitRegistered>,
) => {
    const updatedProducts = [...linesRegistered, ...processedNewProducts];
    formContextOnAppend.setValue('produits', updatedProducts);
};
