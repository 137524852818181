import React from 'react';

import { Box, Typography } from '@mui/material';
import { DossierApiObject } from '@europrocurement/l2d-domain';
import { EmptyBox, FlexyHeaderForm, Note } from '@europrocurement/flexy-components';

export type NoteDossierProps = {
    dossierSelected?: DossierApiObject;
};

const NoteDossier: React.FC<NoteDossierProps> = function ({ dossierSelected }) {
    return (
        <Box>
            <FlexyHeaderForm
                label={
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '100%',
                        }}
                    >
                        <Typography
                            variant="h2"
                            color="secondary"
                        >
                            Note du dossier
                        </Typography>
                    </Box>
                }
            />
            {dossierSelected && dossierSelected.notes ? (
                <Note
                    key={dossierSelected.id}
                    noteRawContent={dossierSelected.notes}
                />
            ) : (
                <EmptyBox
                    text="Pas de note"
                    sx={{
                        marginTop: '10px',
                    }}
                />
            )}
        </Box>
    );
};

export default NoteDossier;
