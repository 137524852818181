import React, { FunctionComponent } from 'react';

import { Skeleton } from '@mui/material';
import { Box } from '@mui/system';

const SplitViewSkeleton: FunctionComponent = function () {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
                width: '100%',
                height: '90%',
            }}
        >
            {['left', 'right'].map((panel) => (
                <Skeleton
                    key={panel}
                    variant="rounded"
                    width={`${90 / 2}%`}
                    height="100%"
                />
            ))}
        </Box>
    );
};

export default SplitViewSkeleton;
