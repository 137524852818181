import * as React from 'react';
import { FlexyTabs } from '@europrocurement/flexy-components';
import { SUB_TAB_ROUTER_PARAMETER } from '@b2d/pages/Prescripteurs/constants/paths';
import AccountingSettings from './AccountingSettings';

const Accounting: React.FunctionComponent = function () {
    const TABS = [
        {
            tabTitle: 'Paramétrage',
            tabName: 'settings',
            tabPanel: <AccountingSettings />,
        },
    ];

    return (
        <FlexyTabs
            orientation="vertical"
            tabs={TABS}
            inCard
            routerParameterName={SUB_TAB_ROUTER_PARAMETER}
        />
    );
};

export default Accounting;
