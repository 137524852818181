import React, { FC } from 'react';

import { Box, SxProps } from '@mui/material';
import { Controller } from 'react-hook-form';
import { FormContextType } from '@b2d/pages/Offres/Package/Components/types';
import TextField from '@b2d/pages/Offres/components/form/TextField';

type ComponentProps = {
    value?: string | null;
    inputName: string;
    context: FormContextType;
    placeholder?: string;
    sx?: SxProps;
};

/** Specific input for overridable field :
 * - Display overrided value as a place holder
 * - Clear input when there is no value
 */
const OverrideField: FC<ComponentProps> = function ({
    inputName,
    context,
    value,
    placeholder,
    sx: sxProps,
}) {
    const inputReference = React.useRef<HTMLInputElement>(null);
    const {
        formState: { errors },
        control,
    } = context;

    // Clear field when there is no value
    React.useEffect(() => {
        if (!value && inputReference && inputReference.current) {
            inputReference.current.value = '';
            context.trigger();
        }
    }, [context, value]);

    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={sxProps}
        >
            <Controller
                name={inputName}
                control={control}
                render={({ field }) => (
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <TextField
                            sx={{ width: '100%', margin: 0 }}
                            {...field}
                            inputRef={inputReference}
                            placeholder={placeholder}
                            errors={errors}
                            required={false}
                        />
                    </Box>
                )}
            />
        </Box>
    );
};

export default OverrideField;
