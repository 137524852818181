import { FactureFormSelector, RootStateType } from '@b2d/redux/RootStore';
import { FactureVente } from '@europrocurement/l2d-domain';
import { UseKeycloakCheckRole } from '@europrocurement/l2d-keycloak';
import { Box } from '@mui/material';
import _ from 'lodash';
import React, { FunctionComponent, ReactNode, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { CustomItemRenderFieldProps, ExtandedFormContext } from '@europrocurement/flexy-form';
import AdditionalSellLine from '@b2d/hooks/additionalProductsData/AdditionalSellLine';
import { LigneProduit, TableauProduits } from '../../../types';
import apiInvoiceSellProductToFormInvoicePurchaseProduct from '../../../functions/dataTransformers/apiToForm/apiInvoiceSellProductToFormInvoicePurchaseProduct';

type SellDetailProps = {
    formContext: ExtandedFormContext;
    props: CustomItemRenderFieldProps;
};

const SellDetail: FunctionComponent<SellDetailProps> = function ({
    formContext,
    props,
}: SellDetailProps) {
    const venteSelected: FactureVente | undefined = useSelector(
        (s: RootStateType) => s.dossiers.facturevente.main.selected,
        _.isEqual,
    );

    const invoiceCategoriesDataSource = useSelector(
        (s: RootStateType) => s.dossiers.rubfac.main,
        _.isEqual,
    );

    const roleChecker = UseKeycloakCheckRole();
    const isInterne = roleChecker('realm:interne');

    const state = useSelector(FactureFormSelector);
    const shouldDisplay = useMemo(
        () => (isInterne ? state.displayDetails : false),
        [isInterne, state.displayDetails],
    );

    /**
     * Find and format sells lines from selected sell.
     */
    const foundSells = useMemo<TableauProduits>(() => {
        if (!venteSelected || !venteSelected.lignes || venteSelected.lignes?.length === 0)
            return [];

        const formattedSellLines = venteSelected.lignes.map((sellLine) =>
            apiInvoiceSellProductToFormInvoicePurchaseProduct(
                sellLine,
                invoiceCategoriesDataSource.data,
            ),
        );

        return formattedSellLines;
    }, [invoiceCategoriesDataSource.data, venteSelected]);

    const res: ReactNode = useMemo(() => {
        if (!shouldDisplay) return null;

        const subForm = formContext.getValue(props.objectPath);

        if (!subForm || !foundSells) {
            return null;
        }

        const sellLineFound = foundSells.find(
            (produitVente: LigneProduit) => produitVente.idLigneVente === subForm.idLigneVente,
        );
        return (
            <Box sx={{ height: '20px' }}>
                {sellLineFound ? <AdditionalSellLine lineProductSell={sellLineFound} /> : null}
            </Box>
        );
    }, [formContext, foundSells, props.objectPath, shouldDisplay]);

    return res;
};

export default SellDetail;
