import { faPaperPlane, faPlus, faQuestion, faSignature } from '@fortawesome/pro-solid-svg-icons';
import { LogType } from '@europrocurement/l2d-domain';
import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';

import { TypographyProps } from '@mui/material';

export const eventToColor = (log: Pick<LogType, 'event'>): TypographyProps['bgcolor'] => {
    switch (log.event) {
        case 'SIGNATAIRE_SIGN':
        case 'SIGNATAIRE_UPDATE':
            return 'success.main';
        case 'RAISE':
            return 'warning.main';
        default:
            return 'blueGrey.main';
    }
};
export const eventToIcone = (log: Pick<LogType, 'event'>): FontAwesomeIconProps['icon'] => {
    switch (log.event) {
        case 'SIGNATAIRE_SIGN':
        case 'SIGNATAIRE_UPDATE':
            return faSignature;
        case 'DOSSIER_CREATE':
            return faPlus;
        case 'RAISE':
            return faPaperPlane;
        default:
            return faQuestion;
    }
};
export const eventToTitle = (log: Pick<LogType, 'event'>): string => {
    switch (log.event) {
        case 'SIGNATAIRE_SIGN':
        case 'SIGNATAIRE_UPDATE':
            return 'Signature';
        case 'DOSSIER_CREATE':
            return 'Création dossier';
        case 'RAISE':
            return 'Relance';
        default:
            return '';
    }
};
