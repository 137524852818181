export type ToastMessages = {
    success: string;
    error: string;
};

export const defaultMessages: ToastMessages = {
    success: 'Modification effectuée avec succès',
    error: 'Une erreur est survenue',
};

export const removeNoteMessages: ToastMessages = {
    success: 'Note supprimée avec succès !',
    error: 'Échec de la suppression de la note !',
};

export const addNoteMessages: ToastMessages = {
    success: 'Note crée avec succès !',
    error: 'Échec de la création de la note !',
};

export const editNoteMessages: ToastMessages = {
    success: 'Note éditée avec succès !',
    error: "Échec de l'édition de la note !",
};

export const exportDataDashboard: ToastMessages = {
    success: 'Données à exporter récupérée avec succès !',
    error: 'Échec de la récupération des données à exporter !',
};
