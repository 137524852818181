import * as React from 'react';
import ActionsContext, { ContextActionType } from './ActionsContext';

const UseDumbActionsContext = (componentSpecificActions: ContextActionType[]) => {
    const { defineContextActions, resetContextActions } = React.useContext(ActionsContext);

    React.useEffect(() => {
        // On mount define component specific context actions
        defineContextActions(componentSpecificActions);
        // On component unmount, reset context actions to default
    }, [componentSpecificActions, defineContextActions]);

    React.useEffect(() => () => resetContextActions(), [resetContextActions]);
};

export default UseDumbActionsContext;
