export const isLuhnValid = (input: string) => {
    const inputContainsOnlyDigits = /^\d+/.test(input);
    if (!inputContainsOnlyDigits) {
        return false;
    }
    const splittedNumber = input.split('');
    let sum = 0;
    const numberLength = splittedNumber.length;
    const parity = numberLength % 2;
    splittedNumber.forEach((value, index) => {
        const digit = parseInt(value, 10);
        if (index % 2 !== parity) {
            sum += digit;
        } else if (digit > 4) {
            sum = sum + 2 * digit - 9;
        } else {
            sum += 2 * digit;
        }
    });

    return sum % 10 === 0;
};

export const isJsonValid = (string: string) => {
    try {
        JSON.parse(string);
    } catch {
        return false;
    }
    return true;
};
