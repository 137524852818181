import * as React from 'react';
import { IconButton } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';

export type SnackbarCloseButtonProps = {
    snackbarKey: string | number;
};

const SnackbarCloseButton: React.FunctionComponent<SnackbarCloseButtonProps> = function ({
    snackbarKey,
}: SnackbarCloseButtonProps) {
    const { closeSnackbar } = useSnackbar();

    return (
        <IconButton
            onClick={() => closeSnackbar(snackbarKey)}
            sx={{
                color: 'white',
            }}
        >
            <FontAwesomeIcon icon={faXmark} />
        </IconButton>
    );
};

export default SnackbarCloseButton;
