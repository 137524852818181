import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { FactureAchat } from '@europrocurement/l2d-domain';
import { LazyBreadcrumb } from '@europrocurement/flexy-components';
import { DataSource } from '@europrocurement/l2d-redux-utils';
import { FilterDatatable } from '@europrocurement/flexy-datatable';

import { AppDispatch, FactureachatSelector, getFactureAchat } from '../../../../redux/RootStore';

import FactureAchatList from '../../components/lists/FactureAchatList';

const RegisteredInvoicesView: React.FunctionComponent = function () {
    const facturesAchatsDataSource: DataSource<FactureAchat> =
        useSelector(FactureachatSelector).main;

    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        dispatch({
            type: `${facturesAchatsDataSource.slicename}/set${facturesAchatsDataSource.name}Filter`,
            payload: { key: 'deleted', value: false },
        });
    }, [dispatch, facturesAchatsDataSource.name, facturesAchatsDataSource.slicename]);

    useEffect(() => {
        if (facturesAchatsDataSource.filters.deleted === false) {
            dispatch(getFactureAchat({}));
        }
    }, [dispatch, facturesAchatsDataSource.filters.deleted]);

    return (
        <>
            <LazyBreadcrumb
                path={[
                    { path: 'Portail achats', link: '/achats/liste' },
                    { path: 'Factures à traiter', link: '/achats/liste' },
                    'Factures traitées',
                ]}
            />
            {facturesAchatsDataSource.filters.deleted === false ? (
                <FactureAchatList
                    filtersControl
                    dataSource={facturesAchatsDataSource}
                    fetchData={getFactureAchat}
                    filterFilters={(filtre: FilterDatatable) => filtre.field !== 'deleted'}
                />
            ) : null}
        </>
    );
};

export default RegisteredInvoicesView;
