import { ThemeOptions } from '@mui/material';
import { useSelector } from 'react-redux';
import { BuildTheme } from '../assets/global/Theme-variable';
import { FlexyLayoutState } from '../redux/storeConfig/store';

export const ThemeSettings = (override?: Partial<ThemeOptions>) => {
    const activeTheme = useSelector((state: FlexyLayoutState) => state.customizer.activeTheme);

    const theme = BuildTheme(
        {
            theme: activeTheme,
        },
        override,
    );

    return theme;
};

export default ThemeSettings;
