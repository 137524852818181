import * as React from 'react';
import { Box, useTheme } from '@mui/system';
import { Button, Typography } from '@mui/material';
import { FaOptionIcon } from '@europrocurement/l2d-icons';
import { IconName } from '@fortawesome/pro-duotone-svg-icons';
import { TreeItem, TreeItemProps } from '../../atoms';

type title = { title?: string };
export type TreeItemBaseProps = Omit<TreeItemProps, 'design' | ' onChange'> & title;

export type TreeGroupItemsProps = {
    items: TreeItemBaseProps[];
    onChange: (values: Array<TreeItemProps['value']>) => void;
    value?: Array<TreeItemProps['value']>;
    label: string;
};

function onlyUnique<T>(value: T, index: number, array: Array<T>) {
    return array.indexOf(value) === index;
}

const TreeGroupItems: React.FunctionComponent<TreeGroupItemsProps> = function ({
    items = [],
    onChange,
    value,
    label,
}: TreeGroupItemsProps) {
    let innerItems: TreeGroupItemsProps['items'];

    if (value) {
        innerItems = items.map((item) => {
            if (value.indexOf(item.value) !== -1) {
                return { ...item, isChecked: true };
            }
            return { ...item, isChecked: false };
        });
    } else {
        innerItems = items;
    }

    const allValues = innerItems.map((i) => i.value).filter(onlyUnique);
    if (allValues.length !== innerItems.length) {
        console.error('Two items or more with the same value found.');
    }

    const [hide, setHide] = React.useState(true);
    const [values, setValues] = React.useState(
        innerItems.filter((i) => i.isChecked).map((i) => i.value),
    );

    const onChangeWrapper: TreeItemProps['onChange'] = React.useCallback(
        (newValue: TreeItemProps['value'], checked: boolean) => {
            if (checked) {
                setValues([...values, newValue]);
            } else {
                setValues([...values.filter((v) => v !== newValue)]);
            }
        },
        [values, setValues],
    );

    React.useEffect(() => {
        if (onChange) {
            onChange(values);
        }
    }, [onChange, values]);

    let cssHide: React.CSSProperties = {};
    let iconeName: string = 'chevron-up';
    if (hide) {
        cssHide = { display: 'none' };
        iconeName = 'chevron-down';
    }
    const theme = useTheme();
    return (
        <Box>
            {' '}
            <Typography
                component="h2"
                sx={{
                    fontSize: '18px',
                }}
                style={{
                    position: 'relative',
                }}
            >
                {label}
                <Button
                    style={{
                        position: 'absolute',
                        right: 0,
                    }}
                    onClick={() => {
                        setHide(!hide);
                    }}
                >
                    <FaOptionIcon
                        icon={['fasl', iconeName as IconName]}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            color: theme.palette.grey[600],
                        }}
                    />
                </Button>
            </Typography>
            <Box
                style={{ padding: '0px 15px', ...cssHide }}
                data-testid="test-id-treeItem-box"
            >
                {innerItems.map((item) => (
                    <TreeItem
                        label={item.label}
                        value={item.value}
                        onChange={onChangeWrapper}
                        isChecked={item.isChecked}
                        isDisabled={item.isDisabled}
                    />
                ))}
            </Box>
        </Box>
    );
};

export default TreeGroupItems;
