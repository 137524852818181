import { useCallback } from 'react';

import { useSnackbar } from 'notistack';

const useShowMessage = () => {
    const { enqueueSnackbar } = useSnackbar();

    const showMessage = useCallback(
        (message: string, variant: 'info' | 'warning' | 'error') => {
            enqueueSnackbar(message, { variant });
        },
        [enqueueSnackbar],
    );

    return showMessage;
};

export default useShowMessage;
