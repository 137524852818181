import { PRICE_REGEX } from '@b2d/validation/regex';
import { z } from 'zod';
import { isCustomCategory } from './rules';

/** Zod schema used for form validation and error feedbacks */

const maxLenghtMessage = (max: number) => `Ce champs est limité à ${max} caractères`;
const invalidMessage = 'La valeur de ce champs est invalide';
const requiredMessage = 'Le champs est obligatoire';
const wrongPriceFormatMessage = 'Le format du prix est incorrect';

/** Default error feedback */
const invalidDefault = {
    invalid_type_error: invalidMessage,
    required_error: requiredMessage,
    message: requiredMessage,
};

export const articleSchema = z
    .object({
        label: z.string().trim().min(1, requiredMessage).max(50, maxLenghtMessage(50)),
        code: z.string().max(10, maxLenghtMessage(10)).optional(),
        price: z.string().regex(PRICE_REGEX, wrongPriceFormatMessage).nullable(),
        companyId: z.number(),
        unit: z.enum(['F', 'C', 'U', 'L']).nullable(),
        domainId: z.number(),
        invoicingCategory: z.string(invalidDefault),
        vatCode: z.enum(['TN', 'TI', 'TR', 'TP', 'NS']),
        documentIds: z.array(z.number()).optional(),
        cegidConfiguration: z
            .object({
                id: z.string().optional(),
                sp1: z.string().optional(),
                sp2: z.string().optional(),
                tl1: z.string().optional(),
                tl2: z.string().optional(),
                tl3: z.string().optional(),
            })
            .optional(),
    })
    .superRefine(({ invoicingCategory, cegidConfiguration }, context) => {
        if (
            isCustomCategory(invoicingCategory) &&
            (!cegidConfiguration || !cegidConfiguration.id)
        ) {
            return context.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'Identifiant CEGID obligatoire',
                path: ['cegidConfiguration', 'id'],
            });
        }

        return null;
    });

export type ArticleSchema = z.infer<typeof articleSchema>;
