import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { customizerSelector, selectFactureAchat } from '@b2d/redux/RootStore'; // Adjust the import as per your project structure
import { AppDispatch } from '@europrocurement/flexy-components/redux/storeConfig/store';
import { FactureFormObject } from '@b2d/pages/Achats/components/forms/types';
import { FactureAchatFactureAchatWrite } from '@europrocurement/l2d-domain/openApi/ApiAchats';
import models from '@b2d/pages/Achats/models';
import { useSynchroG3Facture } from '@b2d/pages/Achats/components/forms/functions/dataHooks';

export type UpdatePublisherProps = {
    invoice: { id?: number | string };
    newPublisher: Pick<
        FactureFormObject,
        | 'fournisseur'
        | 'siren_fournisseur'
        | 'raison_sociale_fournisseur'
        | 'type_remise_fournisseur'
    >;
};

/**
 * Custom hook to calculate and update invoice publisher.
 */
const useSectionPublisher = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { enqueueSnackbar } = useSnackbar();
    const { xIdSociete } = useSelector(customizerSelector);
    const synchroG3Facture = useSynchroG3Facture();

    /**
     * Updates the publisher on the server.
     * @param newPublisher - The publisher to update.
     */
    const updatePublisher = useCallback(
        async ({ invoice, newPublisher }: UpdatePublisherProps) => {
            const payload: Pick<
                FactureAchatFactureAchatWrite,
                'idEntiteFacturante' | 'rcsEntiteFacturante'
            > = {};

            if (typeof newPublisher.siren_fournisseur !== 'string') {
                payload.idEntiteFacturante = newPublisher.siren_fournisseur?.id;
                payload.rcsEntiteFacturante = newPublisher.siren_fournisseur?.siren;
            } else {
                console.error(
                    'Publisher autocomplete value is a string, try to get id by another way, but certainly fail',
                );
                payload.idEntiteFacturante = newPublisher.fournisseur?.id;
                payload.rcsEntiteFacturante = newPublisher.siren_fournisseur;
            }

            const invoiceRequest = () =>
                models.invoicePurchase.patch({
                    id: invoice.id?.toString() || '-1',
                    factureAchatFactureAchatWrite: {
                        idEntiteFacturante: payload.idEntiteFacturante,
                        rcsEntiteFacturante: payload.rcsEntiteFacturante,
                        libelleFacturante: newPublisher.raison_sociale_fournisseur,
                        hasSetRsf: newPublisher.type_remise_fournisseur !== 'Pas de remise',
                    },
                    xIdSociete,
                });

            const syncRequest = () => synchroG3Facture(invoice.id?.toString() || '-1');

            const requestPromiseList: Array<typeof invoiceRequest> = [];

            requestPromiseList.push(invoiceRequest);

            try {
                await Promise.all(requestPromiseList.map((req) => req()));

                syncRequest();

                if (invoice.id) {
                    dispatch(selectFactureAchat({ id: invoice.id }));
                }

                enqueueSnackbar(`Le fournisseur de la facture à été mis à jour avec succès`);
            } catch (error) {
                console.error('Error updating publisher fields:', error);

                enqueueSnackbar(
                    `Une erreur est survenue lors de la mise à jour du fournisseur de la facture`,
                    {
                        variant: 'error',
                    },
                );
            }
        },
        [dispatch, enqueueSnackbar, synchroG3Facture, xIdSociete],
    );

    return {
        updatePublisher,
    };
};

export default useSectionPublisher;
