import {
    faBug,
    faCheck,
    faPause,
    faPenToSquare,
    faSync,
    faUser,
    faClock,
    faTimes,
    faLeftRight,
    faPlus,
    faEllipsisVertical,
    faCircleExclamation,
    faBan,
    faPlusCircle,
    faChevronUp,
    faChevronDown,
} from '@fortawesome/pro-solid-svg-icons';
import {
    faBanParking,
    faBracketCurly,
    faBuilding,
    faFileCirclePlus,
    faHeadset,
    faNote,
    faTags,
    faTrashCan,
    faArrowUpRightFromSquare,
    faRightFromBracket,
    faEnvelope,
    faPhone,
    faSlidersUp,
    faCode,
    faUsers,
    faEye,
    faCircleInfo,
    faCalculator,
    faList,
    faEdit,
    faFilter,
} from '@fortawesome/pro-duotone-svg-icons';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { NamedIconsType } from '../../types/types';

export const pauseIcon: NamedIconsType = {
    props: { icon: faPause },
    name: 'pause',
    displayName: 'Pause',
};

export const waitIcon: NamedIconsType = {
    props: { icon: faClock },
    name: 'wait',
    displayName: 'En attente',
};
export const checkIcon: NamedIconsType = {
    props: { icon: faCheck },
    name: 'check',
    displayName: 'Coché / Validé',
};

export const errorIcon: NamedIconsType = {
    props: { icon: faTimes },
    name: 'error',
    displayName: 'Erreur / Refusé',
};

export const deleteIcon: NamedIconsType = {
    props: { icon: faTrashCan },
    name: 'delete',
    displayName: 'Supprimer',
};

export const companyIcon: NamedIconsType = {
    props: { icon: faBuilding },
    name: 'company',
    displayName: 'Société',
};

export const tagIcon: NamedIconsType = {
    props: { icon: faTags },
    name: 'tag',
    displayName: 'Tag / Etiquette',
};

export const noteIcon: NamedIconsType = {
    props: { icon: faNote },
    name: 'note',
    displayName: 'Note',
};

export const addNoteIcon: NamedIconsType = {
    props: { icon: faNote, option: faPlus },
    name: 'addNote',
    displayName: 'Ajouter une note',
};

export const exitIcon: NamedIconsType = {
    props: { icon: faXmark },
    name: 'exit',
    displayName: 'Retour vers',
};

export const editIcon: NamedIconsType = {
    props: { icon: faEdit },
    name: 'edit',
    displayName: 'Modifier',
};

export const userIcon: NamedIconsType = {
    props: { icon: faUser },
    name: 'user',
    displayName: 'Utilisateur',
};

export const addFileIcon: NamedIconsType = {
    props: { icon: faFileCirclePlus },
    name: 'addFile',
    displayName: 'Ajout fichier',
};

export const todoIcon: NamedIconsType = {
    props: { icon: faPenToSquare },
    name: 'todo',
    displayName: 'A traiter',
};

export const syncIcon: NamedIconsType = {
    props: { icon: faSync },
    name: 'sync',
    displayName: 'Synchronisation',
};

export const unpublishedIcon: NamedIconsType = {
    props: { icon: faBanParking },
    name: 'unpublished',
    displayName: 'Non publié',
};

export const bugIcon: NamedIconsType = {
    props: { icon: faBug },
    name: 'bug',
    displayName: 'Bug',
};

export const supportIcon: NamedIconsType = {
    props: { icon: faHeadset },
    name: 'support',
    displayName: 'Support technique',
};

export const permuteIcon: NamedIconsType = {
    props: { icon: faLeftRight },
    name: 'permute',
    displayName: 'Echange / permutation',
};

export const devIcon: NamedIconsType = {
    props: { icon: faBracketCurly },
    name: 'dev',
    displayName: 'Dev / Version',
};

export const changeCompanyIcon: NamedIconsType = {
    props: {
        icon: companyIcon.props.icon,
        option: permuteIcon.props.icon,
    },
    name: 'changeCompanyIcon',
    displayName: 'Change Company Invoice',
};

export const openIcon: NamedIconsType = {
    props: { icon: faArrowUpRightFromSquare },
    name: 'open',
    displayName: 'Ouvrir',
};

export const plusIcon: NamedIconsType = {
    props: { icon: faPlus },
    name: 'plus',
    displayName: 'Plus / Ajouter',
};

export const listIcon: NamedIconsType = {
    props: { icon: faList },
    name: 'list',
    displayName: 'Liste',
};

export const moreIcon: NamedIconsType = {
    props: { icon: faEllipsisVertical },
    name: 'more',
    displayName: 'Afficher plus',
};

export const warningIcon: NamedIconsType = {
    props: { icon: faCircleExclamation },
    name: 'warning',
    displayName: 'Avertissement',
};

export const forbiddenIcon: NamedIconsType = {
    props: { icon: faBan },
    name: 'forbidden',
    displayName: 'Interdit',
};
export const quitIcon: NamedIconsType = {
    props: { icon: faRightFromBracket },
    name: 'open',
    displayName: 'Quitter',
};

export const mailIcon: NamedIconsType = {
    props: { icon: faEnvelope },
    name: 'open',
    displayName: 'Mail',
};

export const phoneIcon: NamedIconsType = {
    props: { icon: faPhone },
    name: 'open',
    displayName: 'Téléphone',
};

export const preferencesIcon: NamedIconsType = {
    props: { icon: faSlidersUp },
    name: 'open',
    displayName: 'Préférences',
};
export const codeIcon: NamedIconsType = {
    props: { icon: faCode },
    name: 'open',
    displayName: 'Développement',
};

export const usersIcon: NamedIconsType = {
    props: { icon: faUsers },
    name: 'open',
    displayName: 'Membres / utilisateurs',
};

export const addItem: NamedIconsType = {
    props: { icon: faPlusCircle },
    name: 'addItem',
    displayName: 'Ajouter',
};

export const preview: NamedIconsType = {
    props: { icon: faEye },
    name: 'preview',
    displayName: 'Aperçu',
};

export const help: NamedIconsType = {
    props: { icon: faCircleInfo },
    name: 'help',
    displayName: 'Aide',
};

export const chevronUpIcon: NamedIconsType = {
    props: {
        icon: faChevronUp,
    },
    name: 'toggle-up',
    displayName: 'Replier',
};

export const chevronDownIcon: NamedIconsType = {
    props: {
        icon: faChevronDown,
    },
    name: 'toggle-down',
    displayName: 'Déplier',
};

export const calculationIcon: NamedIconsType = {
    props: {
        icon: faCalculator,
    },
    name: 'calculation',
    displayName: 'Calculer',
};

export const totalsIcon: NamedIconsType = {
    props: {
        icon: calculationIcon.props.icon,
    },
    name: 'totals',
    displayName: 'Totaux',
};

export const productsIcon: NamedIconsType = {
    props: {
        icon: faList,
    },
    name: 'products',
    displayName: 'Rubriques',
};

export const filtersIcon: NamedIconsType = {
    props: {
        icon: faFilter,
    },
    name: 'filters',
    displayName: 'Filtres',
};

export const generalNamedIcons: NamedIconsType[] = [
    pauseIcon,
    waitIcon,
    checkIcon,
    errorIcon,
    deleteIcon,
    companyIcon,
    tagIcon,
    noteIcon,
    addNoteIcon,
    exitIcon,
    editIcon,
    userIcon,
    addFileIcon,
    todoIcon,
    syncIcon,
    unpublishedIcon,
    bugIcon,
    supportIcon,
    permuteIcon,
    devIcon,
    changeCompanyIcon,
    openIcon,
    plusIcon,
    listIcon,
    moreIcon,
    warningIcon,
    forbiddenIcon,
    quitIcon,
    mailIcon,
    phoneIcon,
    preferencesIcon,
    codeIcon,
    usersIcon,
    addItem,
    preview,
    help,
    chevronUpIcon,
    chevronDownIcon,
    totalsIcon,
    productsIcon,
    filtersIcon,
];
