import React, { Fragment } from 'react';

import { CardContent, Grid } from '@mui/material';

import { DataCard, FlexyHeaderForm } from '@europrocurement/flexy-components';
import {
    FaOptionIcon,
    namedIconsGroups,
    NamedIconsGroupType,
    NamedIconsType,
} from '@europrocurement/l2d-icons';
import { Box } from '@mui/system';

const PanelIcons = function () {
    return namedIconsGroups.map((namedIconsGroup: NamedIconsGroupType) => (
        <Fragment key={namedIconsGroup.displayName}>
            <FlexyHeaderForm
                outlined
                label={namedIconsGroup.displayName}
            />

            <Grid
                container
                spacing={0}
                columnSpacing={0}
                columns={namedIconsGroup.icons.length > 8 ? 8 : namedIconsGroup.icons.length}
            >
                {namedIconsGroup.icons.map((namedIcon: NamedIconsType) => (
                    <Grid
                        lg={1}
                        item
                        key={namedIcon.name}
                    >
                        <DataCard
                            typographyVariant="body2"
                            sx={{ margin: 1, padding: 1 }}
                            title={namedIcon.displayName}
                        >
                            <CardContent
                                sx={
                                    {
                                        // display: 'flex',
                                        // justifyContent: 'center',
                                        // alignItems: 'center',
                                    }
                                }
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: 2,
                                        // alignItems: 'center',
                                        // justifyContent: 'center',
                                    }}
                                >
                                    <FaOptionIcon {...namedIcon.props} />
                                    <FaOptionIcon
                                        {...namedIcon.props}
                                        size="2x"
                                    />
                                    <FaOptionIcon
                                        {...namedIcon.props}
                                        size="4x"
                                    />
                                </Box>
                            </CardContent>
                        </DataCard>
                    </Grid>
                ))}
            </Grid>
        </Fragment>
    ));
};

export default PanelIcons;
