import { Box, Skeleton, Stack } from '@mui/material';
import React, { FunctionComponent } from 'react';

export type DataCardSkeletonProps = {
    header?: boolean;
    controllers?: boolean;
    numberOfLines?: number;
    numberOfStatus?: number;
    numberOfActions?: number;
};

const DataCardSkeleton: FunctionComponent<DataCardSkeletonProps> = function ({
    header = false,
    controllers = false,
    numberOfLines = 4,
    numberOfStatus = 3,
    numberOfActions = 2,
}) {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'start',
                justifyContent: 'space-between',
                width: '100%',
                height: header ? '200px' : '130px',
            }}
        >
            <Stack
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    gap: 0,
                }}
            >
                {header ? (
                    <Skeleton
                        variant="text"
                        width="40%"
                        height={60}
                    />
                ) : null}
                {Array(numberOfLines)
                    .fill(1)
                    .map((card, index) => {
                        const key = `data-card-line-${card}-${index}`;
                        return (
                            <Box
                                key={key}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'end',
                                    justifyContent: 'start',
                                    width: '100%',
                                    gap: 1,
                                }}
                            >
                                <Skeleton
                                    variant="text"
                                    width="20%"
                                    height={32}
                                />
                                <Skeleton
                                    variant="text"
                                    width="40%"
                                    height={28}
                                />
                            </Box>
                        );
                    })}
            </Stack>
            <Stack
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'end',
                    justifyContent: 'space-between',
                    width: '20%',
                    height: '95%',
                    gap: 1,
                }}
            >
                <Stack
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 1,
                    }}
                >
                    {Array(numberOfStatus)
                        .fill(1)
                        .map((card, index) => {
                            const key = `data-card-status-${card}-${index}`;
                            return (
                                <Skeleton
                                    key={key}
                                    variant="circular"
                                    height="30px"
                                    width="30px"
                                />
                            );
                        })}
                </Stack>
                {controllers ? (
                    <Stack
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: 1,
                        }}
                    >
                        {Array(numberOfActions)
                            .fill(1)
                            .map((card, index) => {
                                const key = `data-card-actions-${card}-${index}`;
                                return (
                                    <Skeleton
                                        key={key}
                                        variant="rounded"
                                        height="30px"
                                        width="100px"
                                    />
                                );
                            })}
                    </Stack>
                ) : null}
            </Stack>
        </Box>
    );
};

export default DataCardSkeleton;
