import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';

import { FactureAchat } from '@europrocurement/l2d-domain';
import { FaOptionIcon, totalsIcon } from '@europrocurement/l2d-icons';
import { FlexyHeaderForm, FlexyInput } from '@europrocurement/flexy-components';
import {
    FlexyForm,
    FormObject,
    defaultGridProps,
    inputDefaultProps,
} from '@europrocurement/flexy-form';
// import { AppDispatch, factureAchatApi, selectFactureAchat } from '@b2d/redux/RootStore';
import { SubmitHandler } from 'react-hook-form';
import { Grid } from '@mui/material';
// import { useDispatch } from 'react-redux';
// import { useSnackbar } from 'notistack';
import useLoadingStatus from '@b2d/hooks/useLoading';
import objectMap from '@b2d/utils/objectMap';
import useSectionTotals from '@b2d/hooks/useSectionTotals';
import { ModeProps } from '../../formElements/types';
import { totalsStructureFields } from '../../formElements/fragments/totalsSection';
import { FactureFormObject, ToggableSection } from '../../types';
import HeaderStructure from '../../../fragments/HeaderStructure';
import SkeletonLoader from '../../../SkeletonLoader';

export type UpdateTotalsSectionFormProps = {
    invoice: FactureAchat;
} & ToggableSection;

const UpdateTotalsSectionForm: FunctionComponent<UpdateTotalsSectionFormProps> = function ({
    invoice,
    switchLockValues,
}) {
    const { getSwitchLockValues, stateSwitchLockValues } = switchLockValues;
    // const { enqueueSnackbar } = useSnackbar();
    // const dispatch = useDispatch<AppDispatch>();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const totalsMode = useMemo<ModeProps>(
        () => ({ type: 'update', lock: stateSwitchLockValues.totals.value }),
        [stateSwitchLockValues.totals.value],
    );

    const totalsStructure = useMemo(
        () =>
            totalsStructureFields({ mode: totalsMode }).map((field) => ({
                ...field,
                lg: 12 / totalsStructureFields({ mode: totalsMode }).length,
                md: 12 / totalsStructureFields({ mode: totalsMode }).length,
            })),
        [totalsMode],
    );

    if (!invoice.id) {
        throw new Error(
            "La facture n'as pas d'id, si vous voyez ce message, Sylvain vous autorise a demander a Kevin de faire du piano. ",
        );
    }

    const { updateTotals } = useSectionTotals();

    const handleSubmit = useCallback<SubmitHandler<FormObject>>(
        async (values: Pick<FactureFormObject, 'total_ht' | 'total_tva' | 'total_ttc'>) => {
            setIsLoading(true);

            updateTotals({
                invoiceId: invoice.id,
                newTotals: values,
            }).finally(() => {
                stateSwitchLockValues.totals.set(true);

                setIsLoading(false);
            });

            // const invoiceRequest = () =>
            //     factureAchatApi.patchFactureAchatFactureAchatItem({
            //         id: invoice.id?.toString() || '-1',
            //         factureAchatFactureAchatWrite: {
            //             ht: values.total_ht.toString(),
            //             tva: values.total_tva.toString(),
            //             ttc: values.total_ttc.toString(),
            //         },
            //     });

            // const requestPromiseList: Array<typeof invoiceRequest> = [];

            // requestPromiseList.push(invoiceRequest);

            // try {
            //     await Promise.all(requestPromiseList.map((req) => req()));

            //     if (invoice.id) {
            //         dispatch(selectFactureAchat({ id: invoice.id }));
            //     }

            //     enqueueSnackbar(`Les totaux de la facture ont été mis à jour avec succès`);
            // } catch (error) {
            //     console.error('Error updating totals fields:', error);

            //     enqueueSnackbar(
            //         `Une erreur est survenue lors de la mise à jour des totaux de la facture`,
            //         {
            //             variant: 'error',
            //         },
            //     );
            // } finally {

            // }
        },
        [invoice, stateSwitchLockValues.totals, updateTotals],
    );

    const formObject = useMemo<Partial<FactureFormObject>>(() => {
        if (!invoice.ht || !invoice.tva || !invoice.ttc)
            throw new Error('Missing one of totals values');

        return {
            total_ht: invoice.ht,
            total_tva: invoice.tva,
            total_ttc: invoice.ttc,
        };
    }, [invoice.ht, invoice.ttc, invoice.tva]);

    const formattedTotals = useMemo(() => {
        const totalsValues = {
            ht: invoice.ht,
            tva: invoice.tva,
            ttc: invoice.ttc,
        };

        return objectMap(totalsValues, (totalValue) => {
            if (totalValue === undefined || totalValue === null) {
                return 'N/A';
            }

            if (invoice.avoir) {
                return `-${totalValue}`;
            }

            return totalValue;
        });
    }, [invoice.avoir, invoice.ht, invoice.ttc, invoice.tva]);

    const readyToDisplay = useMemo<boolean>(
        () => !!(!isLoading && invoice.id),
        [invoice.id, isLoading],
    );

    const { loading } = useLoadingStatus({ checkReady: () => readyToDisplay });

    return (
        <SkeletonLoader
            isLoading={loading}
            type="FormSection"
        >
            <>
                <FlexyHeaderForm
                    label={
                        <HeaderStructure
                            icon={
                                <FaOptionIcon
                                    {...totalsIcon.props}
                                    size="sm"
                                />
                            }
                            title={totalsIcon.displayName}
                        />
                    }
                    switches={[
                        ...(!invoice?.syncCegid
                            ? [getSwitchLockValues({ sectionToBlock: 'totals' })]
                            : []),
                    ]}
                    outlined
                />
                {stateSwitchLockValues.totals.value ? (
                    <Grid
                        container
                        {...defaultGridProps}
                    >
                        <Grid
                            item
                            xs={12}
                            md={12 / 3}
                        >
                            <FlexyInput
                                {...inputDefaultProps}
                                variant="outlined"
                                name="total_ht"
                                type="displayData"
                                inputlabel="Total HT"
                                value={formattedTotals.ht}
                                readOnly
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={12 / 3}
                        >
                            <FlexyInput
                                {...inputDefaultProps}
                                variant="outlined"
                                name="total_tva"
                                type="displayData"
                                inputlabel="Total TVA"
                                value={formattedTotals.tva}
                                readOnly
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={12 / 3}
                        >
                            <FlexyInput
                                {...inputDefaultProps}
                                variant="outlined"
                                name="total_ttc"
                                type="displayData"
                                inputlabel="Total TTC"
                                value={formattedTotals.ttc}
                                readOnly
                            />
                        </Grid>
                    </Grid>
                ) : (
                    <FlexyForm
                        onSubmit={handleSubmit}
                        formObject={formObject}
                        formStructure={totalsStructure}
                    />
                )}
            </>
        </SkeletonLoader>
    );
};

export default UpdateTotalsSectionForm;
