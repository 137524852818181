import { factureFormaliteApi } from '@b2d/redux/subReducers';
import { TblFligneFactureVenteRead } from '@europrocurement/l2d-domain/openApi/ApiDossiers';

export const formalitesVentesToProduitsAchats = function (dossier: string, xIdSociete: number) {
    return factureFormaliteApi
        .apiDossiersDossierFacturesGetCollection({
            dossier,
            xIdSociete,
        })
        .then((response) => {
            const lignes: TblFligneFactureVenteRead[] = [];

            response.data['hydra:member'].forEach((factureVenteFormalite) => {
                if (
                    factureVenteFormalite.idFactureRefAvoir ||
                    factureVenteFormalite.factureAvoir !== null ||
                    factureVenteFormalite.factureEnPreparation === true ||
                    factureVenteFormalite.totalTtc === null ||
                    factureVenteFormalite.totalTtc === undefined ||
                    +factureVenteFormalite.totalTtc <= 0 ||
                    factureVenteFormalite.lignes === undefined
                ) {
                    return;
                }

                factureVenteFormalite.lignes.forEach((ligne) => {
                    if (
                        ligne.rubriqueFacturation === 'LIB' ||
                        ligne.rubriqueFacturation === 'RED' ||
                        ligne.rubriqueFacturation === 'AVO' ||
                        ligne.rubriqueFacturation === 'RED2' ||
                        ligne.rubriqueFacturation === 'VAC' ||
                        ligne.dossier !== `/formalite/dossiers/${dossier}`
                    ) {
                        return;
                    }

                    lignes.push({
                        ...ligne,
                        prixBase: ligne.prixBase ? +ligne.prixBase : undefined,
                        montant: ligne.montant ? +ligne.montant : undefined,
                        htReference: ligne.htReference ? +ligne.htReference : undefined,
                    });
                });
            });

            return lignes;
        })
        .catch((err) => {
            console.error(err);
            throw new Error(err);
        });
};
