import { OffresJsonldOffreReadVisibiliteEnum } from '@europrocurement/l2d-domain/openApi/ApiOffre';
import { findIconDefinition } from '@fortawesome/fontawesome-svg-core';
import { z } from 'zod';

/** Zod schema used for form validation and error feedbacks */

const maxLenghtMessage = (max: number) => `Ce champs est limité à ${max} caractères`;
const requiredMessage = 'Le champs est obligatoire';

const offerVisibilityAvailableValues = OffresJsonldOffreReadVisibiliteEnum;

export const offerSchema = z.object({
    label: z.string().trim().min(1, requiredMessage).max(50, maxLenghtMessage(50)),
    visibility: z.enum([
        offerVisibilityAvailableValues.Full,
        offerVisibilityAvailableValues.Partial,
    ]),
    domainId: z.number(),
    iconeName: z
        .string()
        .trim()
        .refine((value) => value === '' || findIconDefinition({ iconName: value }), {
            message: 'Icône invalide',
        }),
});

export type OfferSchema = z.infer<typeof offerSchema>;
