/* eslint-disable @typescript-eslint/no-unused-vars */

import {
    SliceFactory,
    FetchCollectionData,
    FetchItemDataType,
    ApiCollectionResponse,
} from '@europrocurement/l2d-redux-utils';
import {
    ConfigurationParameters as DossiersConfigurationParameters,
    Configuration,
    TauxTvaApi,
    TauxTvaTvaFactureRead,
} from '../../../openApi/ApiDossiers';
import { DOSSIERS_REDUCER_NAME } from '../constants';

export type TauxTvaApiObject = TauxTvaTvaFactureRead & Record<string, unknown>;
export type FilteredTauxTvaApiObject = Omit<TauxTvaTvaFactureRead, 'idtva' | 'regimetva'> &
    Record<string, unknown>;

export type TauxTva = TauxTvaApiObject;
export type FilteredTauxTva = FilteredTauxTvaApiObject;

export const TXTVA_SLICE_NAME = 'txtva';

const mapperTauxTva = function (txtva: TauxTvaApiObject) {
    return {
        ...txtva,
    } as TauxTva;
};

export function createTauxTvaSlice(configuration: DossiersConfigurationParameters) {
    const conf = new Configuration(configuration);
    const api = new TauxTvaApi(conf);

    const fetchTauxTvaCollection: FetchCollectionData<TauxTvaApiObject> = ({
        search,
        pagination,
        filters,
        orders,
        xIdSociete,
        rootstate,
    }) =>
        api
            .getTauxTvaCollection({ xIdSociete, page: pagination.page + 1 })
            .then((res) => res.data) as Promise<ApiCollectionResponse<TauxTvaApiObject>>;

    const fetchTauxTvaItem: FetchItemDataType<TauxTvaApiObject> = ({
        idItem,
        search,
        pagination,
        filters,
        orders,
        xIdSociete,
        rootstate,
    }) =>
        api
            .getTauxTvaItem({ idtva: String(idItem), xIdSociete })
            .then((res) => res.data) as Promise<TauxTvaApiObject>;

    const { slice: tauxTvaSlice, dataSourcesThunks: tauxTvaDataSourcesThunks } =
        SliceFactory.createSlice<TauxTvaApiObject, TauxTva>(
            TXTVA_SLICE_NAME,
            DOSSIERS_REDUCER_NAME,
            [],
            fetchTauxTvaCollection,
            fetchTauxTvaItem,
            mapperTauxTva,
        );

    return { tauxTvaSlice, tauxTvaDataSourcesThunks };
}
