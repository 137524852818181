import React, { FC } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { Box } from '@mui/system';
import { FlexyHeaderForm } from '@europrocurement/flexy-components';
import { overrideStepSchema } from './validation';
import useStepForm from '../../components/form/MultiStepForm/useStepForm';
import { MultiStepContextProps } from '../../components/form/MultiStepForm/types';
import AsyncEditableList from '../../components/form/AsyncEditableList/AsyncEditableList';
import document from '../../models/document';
import additionalField from '../../models/additionalField';
import option from '../../models/offerOption';
import DocumentsList from '../../components/DocumentsList/DocumentsList';

/** Form fourth step (optional) : Catalog options
 * 1) Documents
 * 2) Additional fields
 */
const OptionsStep: FC<MultiStepContextProps> = function (props) {
    // 1a - Form definition / multi step behaviour

    const { multiStepForm } = props;
    const { setCurrentFormContext, formValues } = multiStepForm;

    const optionsStepForm = useForm({
        mode: 'onBlur',
        defaultValues: formValues,
        resolver: zodResolver(overrideStepSchema),
    });

    useStepForm(optionsStepForm, multiStepForm);

    React.useEffect(() => {
        setCurrentFormContext(optionsStepForm);
    }, [optionsStepForm, setCurrentFormContext]);

    const { additionalFields, documents, options, readOnlyDocuments } = optionsStepForm.getValues();

    return (
        <Box sx={{ marginTop: '15px' }}>
            <form noValidate>
                <Box sx={{ marginBottom: '10px' }}>
                    <FlexyHeaderForm
                        sx={{ marginTop: 0 }}
                        label="Champs complémentaires"
                        outlined
                    />
                </Box>
                <AsyncEditableList
                    inputLabel="Champ(s) complémentaire(s)"
                    formContext={optionsStepForm}
                    formKey="additionalFields"
                    defaultList={additionalFields}
                    placeholder="Rechercher un champ complémentaire"
                    model={additionalField}
                />

                <Box sx={{ marginBottom: '10px' }}>
                    <FlexyHeaderForm
                        sx={{ marginTop: 0 }}
                        label="Documents"
                        outlined
                    />
                </Box>

                <AsyncEditableList
                    inputLabel="Document(s)"
                    formContext={optionsStepForm}
                    formKey="documents"
                    defaultList={documents}
                    placeholder="Rechercher un document"
                    model={document}
                />

                <Box sx={{ marginBottom: '10px' }}>
                    <FlexyHeaderForm
                        sx={{ marginTop: 0 }}
                        label="Options"
                        outlined
                    />
                </Box>

                <AsyncEditableList
                    inputLabel="Option(s)"
                    formContext={optionsStepForm}
                    formKey="options"
                    defaultList={options}
                    placeholder="Rechercher une option"
                    model={option}
                />

                <DocumentsList documents={readOnlyDocuments} />
            </form>
        </Box>
    );
};

export default OptionsStep;
