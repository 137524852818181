import { useLocation, useNavigate } from 'react-router';
import { PaletteColor } from '@mui/material';
import { IconName } from '@fortawesome/pro-duotone-svg-icons';
import { MenuItemProps } from '../../molecules';
import { MenuEntry } from './MenuEntry';

const useSidebar = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const pathDirect = pathname;
    const pathWithoutLastPart = pathname.slice(0, pathname.lastIndexOf('/'));

    const entryToItem = (entry: MenuEntry, color: PaletteColor, folded: boolean): MenuItemProps => {
        const item: MenuItemProps = {
            identifier: entry.title,
            label: entry.title,
            icon: {
                icon: ['fasl', 'question'],
                sx: { display: 'flex', alignItems: 'center' },
            },
            onClick: (target: string | null) => {
                if (target) {
                    navigate(target);
                }
            },
            href: entry.href,
            color,
            childrenItems: [],
            folded,
        };

        if (entry?.children) {
            item.childrenItems = entry.children.map((subEntry) =>
                entryToItem(subEntry, color, folded),
            );
        }

        if (entry?.icon) {
            item.icon = {
                ...item.icon,
                icon: ['fasl', entry.icon as IconName],
            };
        }

        return item;
    };

    const findActiveElements = (itemsTree: MenuItemProps[]) => {
        const search = (items: MenuItemProps[], ancestors: string[]): string[] => {
            for (let i = 0; i < items.length; i++) {
                if (items[i]?.href === pathDirect || items[i]?.href === pathWithoutLastPart) {
                    return [...ancestors, items[i].identifier];
                }

                const children = items[i]?.childrenItems || [];

                if (children.length > 0) {
                    const activeTree = search(children, [...ancestors, items[i].identifier]);

                    if (activeTree.length > 0) {
                        return activeTree;
                    }
                }
            }

            return [];
        };

        return search(itemsTree, []);
    };

    const findOpenElements = (items: MenuItemProps[]) => {
        const activeTree = findActiveElements(items);

        return activeTree.slice(0, -1);
    };

    return { entryToItem, findActiveElements, findOpenElements };
};

export default useSidebar;
