import { useEffect, useMemo } from 'react';
import { MediaObject, MEDIAOBJECT_SLICE_NAME } from '@europrocurement/l2d-domain';
import { DataSource } from '@europrocurement/l2d-redux-utils';
import { mediaobjectDataSourcesThunks, MediaObjectSelector } from '@b2d/redux/RootStore';
import { useSelector } from 'react-redux';
import usePrevious from '@b2d/hooks/usePrevious';
import { filterOptionsProps } from '@b2d/pages/Achats/components/forms/types';
import { ViewFunctionMap, ViewName } from './types';
import useClear from './useClear';
import useFetchDataByViewName from './useFetchDataByViewName';

type UseFetchFilteredDataUnregisteredInvoicesViewProps = {
    viewName: ViewName;
};

/**
 * useFetchFilteredDataUnregisteredInvoicesView
 *
 * This hook provides a mechanism to handle data filtering for different invoice views
 * based on predefined business rules. Each view has its own specific filtering criteria
 * defined by business logic, which ensures that only relevant data is fetched based on
 * the view's requirements.
 *
 * The hook automatically handles:
 * - Clearing previous filter, search, and order states on unmount.
 * - Applying common filtering criteria (e.g., available invoices, default sorting).
 * - Applying view-specific business rules for filtering (e.g., invoices created by a specific user or internal invoices).
 * - Fetching filtered data and managing the request status (loading, success, error).
 * - Error handling through reusable error-handling logic.
 *
 * @param {ViewName} viewName - The view name, used to apply view-specific criteria.
 *
 * @returns {object} - An object containing the current status of the fetching process (loading, error, success).
 */
// Utility hook to track the previous value of a state or prop
const useFetchFilteredDataUnregisteredInvoicesView = ({
    viewName,
}: UseFetchFilteredDataUnregisteredInvoicesViewProps) => {
    const composedDataSourceName = useMemo(() => `${ViewFunctionMap[viewName]}`, [viewName]);

    const dataSource: DataSource<MediaObject> =
        useSelector(MediaObjectSelector)[composedDataSourceName];

    const filterOptions: filterOptionsProps = useMemo(
        () => ({
            sliceName: MEDIAOBJECT_SLICE_NAME,
            dataSourceName: composedDataSourceName,
        }),
        [composedDataSourceName],
    );

    const { clear } = useClear({ dataSource, filterOptions });
    const { fetchFilteredData, status } = useFetchDataByViewName({
        dataSourcesThunks: mediaobjectDataSourcesThunks,
        dataSource,
        filterOptions,
        viewName,
    });

    const prevDataSource = usePrevious(dataSource);

    const isDataSourceHasChanged: boolean = useMemo(
        () => JSON.stringify(prevDataSource) !== JSON.stringify(dataSource),
        [dataSource, prevDataSource],
    );

    const isDataSourceLoadingOrAlreadyOk: boolean = useMemo(
        () => status.loading || status.success,
        [status.loading, status.success],
    );

    /**
     * Core of this hook.
     *
     * On this hook instantiation, fetch corresponding data.
     * On unmount of the component where the hook is instantiated, clear applied criteria and data.
     */
    useEffect(() => {
        if (!isDataSourceHasChanged || isDataSourceLoadingOrAlreadyOk) return () => undefined;

        fetchFilteredData();

        return () => {
            clear();
        };
    }, [clear, fetchFilteredData, isDataSourceHasChanged, isDataSourceLoadingOrAlreadyOk]);

    return { status, dataSource };
};

export default useFetchFilteredDataUnregisteredInvoicesView;
