import React, { ReactNode, useCallback, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/material';

import { FactureAchat, FactureAchatNote } from '@europrocurement/l2d-domain';
import {
    FactureAchatNoteApiCreateFactureAchatNoteFactureAchatNoteCollectionRequest,
    FactureAchatNoteApiDeleteFactureAchatNoteFactureAchatNoteItemRequest,
    FactureAchatNoteApiUpdateFactureAchatNoteFactureAchatNoteItemRequest,
    FactureAchatNoteJsonldFactureAchatNoteCreate,
    FactureAchatNoteJsonldFactureAchatNoteWrite,
} from '@europrocurement/l2d-domain/openApi/ApiAchats';
import {
    AppDispatch,
    customizerSelector,
    factureAchatNoteApi,
    selectFactureAchat,
} from '@b2d/redux/RootStore';
import {
    addNoteMessages,
    editNoteMessages,
    removeNoteMessages,
} from '@b2d/pages/Achats/constants/wording/toasts';
import useToaster from '@b2d/hooks/useToaster';
import ListNotesObject from '@b2d/pages/Achats/components/notes/ListNotesObject';

export type NotesFactureType = {
    facture: FactureAchat;
    idDossier?: number;
    displayNoteFacture?: boolean;
    displayNoteAllDossiers?: boolean;
    title?: ReactNode;
};

export const NotesFacture = function ({
    facture,
    idDossier = undefined,
    displayNoteFacture = true,
    displayNoteAllDossiers = true, // refresh = undefined,
    title = 'Notes générales',
}: NotesFactureType) {
    const dispatch = useDispatch<AppDispatch>();
    const { xIdSociete } = useSelector(customizerSelector);
    const { handleApiResponse } = useToaster();
    const [addNoteDisabled, setAddNoteDisabled] = useState<boolean>(false);

    const selectNote = useCallback(() => {
        if (!facture.notes) {
            return [];
        }

        if (displayNoteFacture === false) {
            return facture.notes.filter((note) => note.idDossier);
        }

        if (displayNoteAllDossiers === false) {
            if (!idDossier) {
                return facture.notes.filter((note) => !note.idDossier);
            }
            return facture.notes.filter((note) => note.idDossier === idDossier);
        }

        return facture.notes;
    }, [displayNoteAllDossiers, displayNoteFacture, idDossier, facture.notes]);

    const handleEditItem = async (note: FactureAchatNote) => {
        const noteToSend = { ...note };
        if (noteToSend.note === undefined) {
            noteToSend.note = '';
        }

        const requestParameters: FactureAchatNoteApiUpdateFactureAchatNoteFactureAchatNoteItemRequest =
            {
                xIdSociete,
                id: `${noteToSend.id}`,
                factureAchatNoteJsonldFactureAchatNoteWrite:
                    note as FactureAchatNoteJsonldFactureAchatNoteWrite,
            };

        await handleApiResponse(
            factureAchatNoteApi.updateFactureAchatNoteFactureAchatNoteItem(requestParameters),
            undefined,
            editNoteMessages,
        );

        if (facture.id) dispatch(selectFactureAchat({ id: +facture.id }));
    };

    const handleRemoveItem = async (note: FactureAchatNote) => {
        const requestParameters: FactureAchatNoteApiDeleteFactureAchatNoteFactureAchatNoteItemRequest =
            {
                xIdSociete,
                id: `${note.id}`,
            };

        await handleApiResponse(
            factureAchatNoteApi.deleteFactureAchatNoteFactureAchatNoteItem(requestParameters),
            undefined,
            removeNoteMessages,
        );
        if (facture.id) dispatch(selectFactureAchat({ id: +facture.id }));
    };

    const handleAddItem = async () => {
        const noteToCreate: FactureAchatNoteJsonldFactureAchatNoteCreate = {
            note: ' ',
            facture: facture['@id'],
        };

        if (idDossier) {
            noteToCreate.idDossier = idDossier;
        }

        const requestParameters: FactureAchatNoteApiCreateFactureAchatNoteFactureAchatNoteCollectionRequest =
            {
                xIdSociete,
                factureAchatNoteJsonldFactureAchatNoteCreate: noteToCreate,
            };

        await handleApiResponse(
            factureAchatNoteApi.createFactureAchatNoteFactureAchatNoteCollection(requestParameters),
            undefined,
            addNoteMessages,
        );
        if (facture.id) dispatch(selectFactureAchat({ id: +facture.id }));
    };

    /**
     * Verrouille ou déverrouille l'ajout de note si une note est en édition.
     * @param editionMode
     */
    const handleAddNoteButtonState = (editionMode: boolean) => {
        setAddNoteDisabled(editionMode);
    };

    /**
     * Force à déverrouiller l'ajout de note s'il n'y a pas de notes
     */
    useEffect(() => {
        if (!addNoteDisabled) return;
        if (facture.notes && facture.notes.length === 0) {
            setAddNoteDisabled(false);
        }
    }, [addNoteDisabled, facture.notes]);

    return (
        <>
            <ListNotesObject
                title={title}
                notes={selectNote()}
                editItem={handleEditItem}
                removeItem={handleRemoveItem}
                onEditModeChange={handleAddNoteButtonState}
            />
            <Button
                onClick={handleAddItem}
                disabled={addNoteDisabled}
                variant="contained"
                style={{ marginTop: '16px', marginBottom: '16px' }}
            >
                Ajouter une note
            </Button>
        </>
    );
};

NotesFacture.defaultValues = {
    dossier: undefined,
    displayNoteFacture: true,
    displayNoteAllDossiers: true,
};

export default NotesFacture;
