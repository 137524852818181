import React, { useState } from 'react';
import { TableCell as MaterialTableCel } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Formalite } from '@europrocurement/l2d-domain';
import { useTheme } from '@mui/system';

export type TableCelProps = {
    formality: Formalite;
    incompatibility: Formalite;
    incompatible: boolean;
    onChange: (formality: Formalite, incompatibility: Formalite) => void;
};

const TableCel: React.FunctionComponent<TableCelProps> = function (props) {
    const { formality, incompatibility, incompatible, onChange } = props;
    const [hover, setHover] = useState(false);
    const theme = useTheme();
    let icon;
    const celStyle = {
        textAlign: 'center',
        verticalAlign: 'middle',
        backgroundColor: '#c8e6c9',
        height: '80px',
        maxHeight: '80px',
        cursor: 'pointer',
        maxWidth: '75%',
        borderBottomColor: theme.palette.grey[300],
    };
    const onToggle = () => {
        onChange(formality, incompatibility);
    };

    if (incompatible) {
        icon = <CheckIcon sx={{ color: theme.palette.success.dark }} />;
        celStyle.backgroundColor = hover
            ? theme.palette.lightGreen.light
            : theme.palette.success.light;
    } else {
        icon = <CloseIcon sx={{ color: theme.palette.error.dark }} />;
        celStyle.backgroundColor = hover ? theme.palette.danger.light : theme.palette.error.light;
    }

    return (
        <MaterialTableCel
            onClick={onToggle}
            onMouseEnter={() => {
                setHover(true);
            }}
            onMouseLeave={() => {
                setHover(false);
            }}
            sx={celStyle}
        >
            {icon}
        </MaterialTableCel>
    );
};

export default TableCel;
