import {
    SliceFactory,
    FetchCollectionData,
    FetchItemDataType,
    ApiCollectionResponse,
} from '@europrocurement/l2d-redux-utils';
import {
    ConfigurationParameters as OffreConfigurationParameters,
    Configuration,
    ArticlesApi,
    ArticlesArticleRead,
} from '../../../openApi/ApiOffre';
import { AUTOCOMPLETE_KEY, OFFRE_REDUCER_NAME } from '../constants';

export type ArticleApiObject = ArticlesArticleRead & Record<string, unknown>;

export type Articles = ArticleApiObject;

export const ARTICLES_SLICE_NAME = 'articles';

const mapperArticles = function (articles: ArticleApiObject) {
    return {
        ...articles,
    } as Articles;
};

export function createArticlesSlice(configuration: OffreConfigurationParameters) {
    const conf = new Configuration(configuration);
    const api = new ArticlesApi(conf);

    const fetchArticlesCollection: FetchCollectionData<ArticleApiObject> = ({
        search,
        pagination: { page, itemsPerPage },
        filters,
        xIdSociete,
    }) => {
        let libelle;
        let domaineId;
        let deleted;

        if (filters.domaineId) {
            domaineId = filters.domaineId as number;
        }

        if (filters.deleted) {
            deleted = filters.deleted as boolean;
        }

        if (search && search !== '') {
            libelle = search;
        }

        return api
            .apiArticlesGetCollection({
                xIdSociete,
                page: page + 1,
                itemsPerPage,
                libelle,
                domaineId,
                deleted,
            })
            .then((res) => res.data)
            .catch((error) => {
                console.error(error);
            }) as Promise<ApiCollectionResponse<ArticleApiObject>>;
    };

    const fetchArticlesItem: FetchItemDataType<ArticleApiObject> = ({ idItem, xIdSociete }) =>
        api
            .apiArticlesIdGet({
                id: String(idItem),
                xIdSociete,
            })
            .then((res) => res.data) as Promise<ArticleApiObject>;

    const defaultFilters = {
        deleted: false,
        domaineId: 3,
    };

    const { slice: articlesSlice, dataSourcesThunks: articlesDataSourcesThunks } =
        SliceFactory.createSlice<ArticleApiObject, Articles>(
            ARTICLES_SLICE_NAME,
            OFFRE_REDUCER_NAME,
            [AUTOCOMPLETE_KEY],
            fetchArticlesCollection,
            fetchArticlesItem,
            mapperArticles,
            defaultFilters,
        );

    return { articlesSlice, articlesDataSourcesThunks };
}
