import React from 'react';
import { FormStructure } from '@europrocurement/flexy-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoice } from '@fortawesome/pro-duotone-svg-icons';
import { Typography } from '@mui/material';
import { HeaderStructureProps } from '../types';
import HeaderStructure from '../../../fragments/HeaderStructure';

export const headerStructureFields = ({
    mode,
}: Pick<HeaderStructureProps, 'mode'>): Array<FormStructure> => [
    {
        type: 'text',
        name: 'numero_facture',
        inputlabel: 'Numéro',
        placeholder: 'F000000000',
        disabled: mode.type === 'update' && mode.lock,
        rules: {
            required: 'Vous devez renseigner le numero de facture',
        },
        xs: 12,
        sm: 12,
        md: 12,
        lg: 3,
    },
    {
        type: 'date',
        name: 'date_facture',
        inputlabel: 'Date',
        disabled: mode.type === 'update' && mode.lock,
        rules: {
            required: 'Vous devez renseigner la date de facturation',
            validate: {
                between: (value: Date) => {
                    if (value < new Date(2000, 0, 1)) {
                        return 'Vérifier la date (Année inférieure à 2000)';
                    }
                    const current = new Date();
                    if (
                        value >
                        new Date(current.getFullYear() + 1, current.getMonth(), current.getDate())
                    ) {
                        return `Vérifier la date (Impossibilité de saisir une facture antidatée de plus d'un an)`;
                    }
                    return true;
                },
            },
        },
        xs: 12,
        sm: 12,
        md: 12,
        lg: 3,
    },
];

const headerStructure = ({
    mode = { type: 'register' },
    onUpdateAvoir,
    onUpdateDomaine,
    title = undefined,
    isInterne = false,
    headerSwitches = [],
    headerChildren = [],
}: HeaderStructureProps): Array<FormStructure> => {
    const header: FormStructure = {
        type: 'header',
        label: (
            <HeaderStructure
                icon={<FontAwesomeIcon icon={faFileInvoice} />}
                title={title !== undefined ? title : 'Facture'}
                controls={headerChildren}
            />
        ),
        sx: {
            marginTop: '0px',
        },
        name: 'invoice_header',
    };

    if (headerSwitches) {
        header.switches = [...headerSwitches];
    }

    const headerStructureArray: Array<FormStructure> = [header, ...headerStructureFields({ mode })];

    if (isInterne && onUpdateDomaine) {
        headerStructureArray.push({
            type: 'boolean',
            name: 'facture_formalite',
            inputlabel: <Typography>Formalité</Typography>,
            disabled: mode.type === 'update',
            verticalSwitch: true,
            onChangeInput: (e, inputPath, formContextOnChangeInput) => {
                const switchValue = formContextOnChangeInput.getValue(inputPath.path);

                onUpdateDomaine(switchValue);
            },
            xs: 12,
            sm: 12,
            md: 12,
            lg: 3,
        });
    }

    if (onUpdateAvoir) {
        headerStructureArray.push({
            type: 'boolean',
            name: 'avoir',
            inputlabel: "Facture d'avoir",
            disabled: mode.type === 'update',
            verticalSwitch: true,
            onChangeInput: () => {
                onUpdateAvoir();
            },
            xs: 12,
            sm: 12,
            md: 12,
            lg: 3,
        });
    }

    return headerStructureArray;
};

export { headerStructure };
