/* eslint-disable import/no-extraneous-dependencies */
import React, { createContext, useContext, useMemo } from 'react';
import type { CSSProperties, PropsWithChildren } from 'react';
import type {
    DraggableAttributes,
    DraggableSyntheticListeners,
    UniqueIdentifier,
} from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import './SortableItem.css';
import { ListItemIcon } from '@mui/material';
import { faGrip } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = {
    id: UniqueIdentifier;
};

interface Context {
    attributes: DraggableAttributes;
    listeners: DraggableSyntheticListeners;
    ref(node: HTMLElement | null): void;
}

const SortableItemContext = createContext<Context>({
    attributes: {
        role: 'list',
        tabIndex: 0,
        'aria-disabled': false,
        'aria-pressed': undefined,
        'aria-roledescription': 'List of draggable items',
        'aria-describedby': '',
    },
    listeners: undefined,
    ref() {},
});

export const SortableItem = function ({ children, id }: PropsWithChildren<Props>) {
    const {
        attributes,
        isDragging,
        listeners,
        setNodeRef,
        setActivatorNodeRef,
        transform,
        transition,
    } = useSortable({ id });
    const context = useMemo(
        () => ({
            attributes,
            listeners,
            ref: setActivatorNodeRef,
        }),
        [attributes, listeners, setActivatorNodeRef],
    );
    const style: CSSProperties = {
        opacity: isDragging ? 0.4 : undefined,
        transform: CSS.Translate.toString(transform),
        transition,
    };

    return (
        <SortableItemContext.Provider value={context}>
            <li
                className="SortableItem"
                ref={setNodeRef}
                style={style}
            >
                {children}
            </li>
        </SortableItemContext.Provider>
    );
};

export const DragHandle = function () {
    const { attributes, listeners, ref } = useContext(SortableItemContext);

    return (
        <ListItemIcon
            {...attributes}
            {...listeners}
            ref={ref}
            className="DragHandle"
        >
            <FontAwesomeIcon icon={faGrip} />
        </ListItemIcon>
    );
};
