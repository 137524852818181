import * as React from 'react';

const useOnHover = () => {
    const [onHover, setHover] = React.useState(false);

    const onHoverProps = {
        onMouseEnter: () => setHover(true),
        onMouseLeave: () => setHover(false),
    };

    return {
        onHoverProps,
        onHover,
    };
};
export default useOnHover;
