import type { PropsWithChildren } from 'react';
import { DragOverlay, defaultDropAnimationSideEffects } from '@dnd-kit/core';
import type { DropAnimation } from '@dnd-kit/core';
import React from 'react';

const dropAnimationConfig: DropAnimation = {
    sideEffects: defaultDropAnimationSideEffects({
        styles: {
            active: {
                opacity: '0.4',
            },
        },
    }),
};

const SortableOverlay = function ({ children }: PropsWithChildren<null>) {
    return <DragOverlay dropAnimation={dropAnimationConfig}>{children}</DragOverlay>;
};

export default SortableOverlay;
