import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router';

import { FactureAchatFactureAchatReadDossierDossiersInner } from '@europrocurement/l2d-domain/openApi/ApiAchats';
import { FlexyMenu } from '@europrocurement/flexy-components';
import { FactureAchat } from '@europrocurement/l2d-domain';
import generateB2DPath from '@b2d/utils/generateB2DPath';
import useToaster from '@b2d/hooks/useToaster';

type DossiersListProps = {
    facture: FactureAchat;
    dossiers: FactureAchatFactureAchatReadDossierDossiersInner[];
};

const DossiersList: React.FC<DossiersListProps> = function (props) {
    const { facture, dossiers } = props;

    const { handleRouterResponse } = useToaster();
    const navigate = useNavigate();

    const [isFormalite, setIsFormalite] = useState<boolean>(false);

    useEffect(() => {
        if (dossiers && dossiers.length > 0 && dossiers[0].idDossierFpro !== null) {
            setIsFormalite(true);
        } else {
            setIsFormalite(false);
        }
    }, [dossiers]);

    return isFormalite ? (
        <FlexyMenu
            key="liste-fpro-dossiers-facture-achat"
            title="Liste des dossiers de formalité"
            items={dossiers.map(
                (item: FactureAchatFactureAchatReadDossierDossiersInner) =>
                    `${item.numeroDossier} - ${item.idDossierFpro}`,
            )}
            onClick={(clickedDossier: string) => {
                const res = generateB2DPath('nextEditFproDossierMultiFacturePath', {
                    factureAchat: facture,
                    dossiernum: clickedDossier.split('-')[1].trim(),
                });

                handleRouterResponse(res, () => {
                    if (res.status === 'OK') {
                        navigate(res.path);
                    }
                });
            }}
        />
    ) : (
        <FlexyMenu
            key="liste-dossiers-facture-achat"
            title="Liste des dossiers"
            items={dossiers.map(
                (item: FactureAchatFactureAchatReadDossierDossiersInner) =>
                    `${item.numeroDossier} - ${item.libelleAnnonceur}`,
            )}
            onClick={(clickedDossier: string) => {
                const res = generateB2DPath('nextEditDossierMultiFacturePath', {
                    factureAchat: facture,
                    dossiernum: clickedDossier.split('-')[0].trim(),
                });

                handleRouterResponse(res, () => {
                    if (res.status === 'OK') {
                        navigate(res.path);
                    }
                });
            }}
        />
    );
};

export default DossiersList;
