import { DossierSignatureConfigurationParameters } from './types';
import { createDemandeurSignatureSlice } from './slices/demandeurSlice';
import { createDossierSignatureSlice } from './slices/signatureSlice';

export function createSignatureReducer(configuration: DossierSignatureConfigurationParameters) {
    const { dossierSignatureDataSourcesThunks, dossierSignatureSlice } =
        createDossierSignatureSlice(configuration);
    const { demandeurSignatureDataSourcesThunks, demandeurSignatureSlice } =
        createDemandeurSignatureSlice(configuration);
    return {
        dossierSignatureDataSourcesThunks,
        dossierSignatureSlice,
        demandeurSignatureDataSourcesThunks,
        demandeurSignatureSlice,
    };
}
