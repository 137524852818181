import React, { FunctionComponent } from 'react';

import { Box, Skeleton } from '@mui/material';

interface SkeletonMosaicProps {
    columns: number;
    rows: number;
}

const SkeletonMosaic: FunctionComponent<SkeletonMosaicProps> = function ({ columns, rows }) {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'start',
                alignItems: 'start',
                gap: 3,
                width: '100%',
                height: '100%',
                padding: '16px',
            }}
        >
            {Array.from({ length: rows }).map((_, rowIndex) => {
                const rowKey = `row-${rowIndex}`;
                return (
                    <Box
                        key={rowKey}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-around',
                            alignItems: 'start',
                            gap: 3,
                            width: '100%',
                            height: '100%',
                        }}
                    >
                        {Array.from({ length: columns }).map((__, colIndex) => {
                            const colKey = `row-${rowIndex}-col-${colIndex}`;
                            return (
                                <Skeleton
                                    key={colKey}
                                    variant="rounded"
                                    width={`${Math.round(80 / columns)}%`}
                                    height="200px"
                                    sx={{
                                        flex: 1,
                                    }}
                                />
                            );
                        })}
                    </Box>
                );
            })}
        </Box>
    );
};

export default SkeletonMosaic;
