import React, {
    FunctionComponent,
    ReactNode,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';
import { Box, Button } from '@mui/material';
import {
    CustomizerReducerType,
    DOSSIERS_FORMALITES_AUTOCOMPLETE_DATASOURCE_NAME,
    DOSSIERS_SLICE_NAME,
    Dossier,
    DossierFormalite,
    FACTURES_ACHATS_SLICE_NAME,
    FactureAchat,
    MediaObject,
    setXIdSociete,
} from '@europrocurement/l2d-domain';

import type {
    FactureAchatFactureAchatReadDossierDossiersInner,
    FactureAchatLigneJsonldFactureAchatRead,
} from '@europrocurement/l2d-domain/openApi/ApiAchats';
import {
    FactureVente,
    FACTURE_VENTE_SLICE_NAME,
} from '@europrocurement/l2d-domain/reducers/dossiers/slices/factureVenteSlice';
import { DataSource, EuroprocApiResponseStatus } from '@europrocurement/l2d-redux-utils';
import { UseKeycloakCheckRole } from '@europrocurement/l2d-keycloak';
import useAddCustomContextActions from '@b2d/pages/Achats/hooks/contextActions/useAddCustomContextActions';
import useDeleteInvoice from '@b2d/pages/Achats/hooks/contextActions/useDeleteInvoice';
import useSyncG3 from '@b2d/pages/Achats/hooks/contextActions/useSyncG3';
import {
    selectFactureAchat,
    selectFactureVente,
    AppDispatch,
    RootStateType,
    selectDossier,
    FactureFormSelector,
} from '@b2d/redux/RootStore';
import {
    useGetDossiersFromAchat,
    useGetLignesFpro,
} from '@b2d/pages/Achats/components/forms/functions/dataHooks';
import useSwitchLockValues from '@b2d/pages/Achats/hooks/useSwitchLockValues';
import { selectDossierFormalite } from '@b2d/redux/subReducers/FormalityReducer';
import { jsonLdIdScraper } from '@europrocurement/l2d-utils';
import generateB2DPath, { AccessibleDirections } from '@b2d/utils/generateB2DPath';
import { ACTIONS } from '@b2d/redux/FactureFormReducer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faForward, faListCheck } from '@fortawesome/pro-solid-svg-icons';
import {
    useNavigateToFactureAchatRecap,
    useNavigateToFactureAchatRecapFormalite,
} from '@b2d/pages/Achats/achatRouterHooks';
import useDomain from '@b2d/hooks/useDomain';
import UpdateProductsSectionForm from './sections/UpdateProductsSectionForm';
import UpdateFolioFormalitySectionForm from './sections/UpdateFolioFormalitySectionForm';

/**
 * SET FOLIO AND INVOICE FROM URL
 * REMOVE USEEFFECTS AND CO. SETTING OR REMOVING FOLIO OR INVOICE.
 * SHOULD STOP RERENDER LOOP.
 */

const UpdateMultiFormalityForm: FunctionComponent = function () {
    const { mediaobjectid, factureachatid, dossiernum } = useParams();

    const { setDomainForced } = useDomain();

    /** ********** */
    /* DataSources */
    /** ********** */

    const foliosFormalityDatasource: DataSource<DossierFormalite> = useSelector(
        (s: RootStateType) => s.formalites.dosform.main,
        _.isEqual,
    );

    const sellsDatasource: DataSource<FactureVente> = useSelector(
        (s: RootStateType) => s.dossiers.facturevente.main,
        _.isEqual,
    );

    /** ********** */
    /* DataSources */
    /** ********** */

    /** ************* */
    /* Selected items */
    /** ************* */
    const mediaObjectSelected: MediaObject = useSelector(
        (s: RootStateType) => s.achats.mediaobject.main.selected,
        _.isEqual,
    );

    const invoiceSelected: FactureAchat = useSelector(
        (s: RootStateType) => s.achats.factureachat.main.selected,
        _.isEqual,
    );

    const invoiceSelectedStatus: EuroprocApiResponseStatus = useSelector(
        (s: RootStateType) => s.achats.factureachat.main.selectedStatus,
        _.isEqual,
    );

    const folioSelected: Dossier | undefined = useSelector(
        (s: RootStateType) => s.dossiers.dos.main.selected,
        _.isEqual,
    );

    const folioFormalityMainSelected: DossierFormalite | undefined = useSelector(
        (s: RootStateType) => s.formalites.dosform.main.selected,
        _.isEqual,
    );

    const folioFormalityAutocompleteSelected: DossierFormalite | undefined = useSelector(
        (s: RootStateType) =>
            s.formalites.dosform[DOSSIERS_FORMALITES_AUTOCOMPLETE_DATASOURCE_NAME].selected,
        _.isEqual,
    );

    const folioFormalitySelected: DossierFormalite | undefined = useMemo(
        () =>
            folioFormalityAutocompleteSelected !== undefined
                ? folioFormalityAutocompleteSelected
                : folioFormalityMainSelected,
        [folioFormalityAutocompleteSelected, folioFormalityMainSelected],
    );

    /** ************* */
    /* Selected items */
    /** ************* */

    const [lignes, setLignes] = useState<FactureAchatLigneJsonldFactureAchatRead[]>([]);

    const [lignesStatus, setLignesStatus] = useState<EuroprocApiResponseStatus>('idle');

    const dispatch = useDispatch<AppDispatch>();

    const roleChecker = UseKeycloakCheckRole();
    const isInterne = roleChecker('realm:interne');
    const getDossiers = useGetDossiersFromAchat();

    useEffect(() => {
        dispatch({
            type: `${DOSSIERS_SLICE_NAME}/deletemainSelected`,
        });
    }, [dispatch]);

    if (!mediaobjectid) {
        throw new Error('MediaObjectId is undefined !');
    }

    if (!factureachatid) {
        throw new Error('FactureAchatId is undefined !');
    }

    if (!dossiernum) {
        throw new Error('DossierNum is undefined !');
    }

    /** Si c'est la mauvaise facture, on la delete */
    useEffect(() => {
        if (invoiceSelected && factureachatid !== invoiceSelected?.id?.toString()) {
            dispatch({
                type: `${FACTURES_ACHATS_SLICE_NAME}/deletemainSelected`,
            });
        }
    }, [dispatch, invoiceSelected, factureachatid]);

    /** va chercher la facture */
    useEffect(() => {
        if (!invoiceSelected && invoiceSelectedStatus !== 'loading') {
            dispatch(selectFactureAchat({ id: +factureachatid }));
        }
    }, [dispatch, invoiceSelected, invoiceSelectedStatus, factureachatid]);

    const [isFormalite, setIsFormalite] = useState<boolean>(false);
    const [dossiersAchat, setDossiersAchat] = useState<
        FactureAchatFactureAchatReadDossierDossiersInner[]
    >([]);
    const [dossiersAchatStatus, setDossiersAchatStatus] =
        useState<EuroprocApiResponseStatus>('idle');

    /**
     * Si la facture a un idSociete,
     * alors le définir comme xIdSociete de l'utilisateur
     */
    useEffect(() => {
        if (invoiceSelected?.idSociete) {
            dispatch(
                setXIdSociete(invoiceSelected?.idSociete as CustomizerReducerType['xIdSociete']),
            );
        }
    }, [dispatch, invoiceSelected?.idSociete]);

    useEffect(() => {
        if (
            !factureachatid ||
            dossiersAchatStatus === 'loading' ||
            dossiersAchatStatus === 'succeeded'
        ) {
            return;
        }

        setDossiersAchatStatus('loading');
        getDossiers(+factureachatid).then((data) => {
            setDossiersAchatStatus('succeeded');
            setDossiersAchat(data);
        });
    }, [dossiersAchatStatus, factureachatid, getDossiers]);

    /**
     * Si des lignes sont liées au numéro de dossier en paramètre,
     * alors sélectionner le dossier en question.
     */
    useEffect(() => {
        if (dossiernum && dossiersAchat && dossiersAchat.length > 0) {
            if (isFormalite) {
                // En cas de dossier de formalité
                const idDossierFpro = dossiersAchat.find(
                    (doss) => doss.idDossierFpro === Number.parseInt(dossiernum, 10),
                )?.idDossierFpro;

                if (idDossierFpro) {
                    dispatch(selectDossierFormalite({ id: +idDossierFpro }));
                }
            }

            // En cas de dossier d'annonce
            const idDossier = dossiersAchat.find(
                (doss) => doss.numeroDossier === dossiernum,
            )?.idDossier;

            if (idDossier) {
                dispatch(selectDossier({ id: +idDossier }));
            }
        }
    }, [dispatch, dossiernum, dossiersAchat, isFormalite]);

    /**
     * Définir la facture de vente.
     * Dépends du domaine définit à la sélection du fournisseur.
     */
    useEffect(() => {
        if (isFormalite === undefined || isFormalite) return;

        let idFactureVente: number;

        if (folioSelected && folioSelected.facture) {
            idFactureVente = jsonLdIdScraper(folioSelected.facture);
            if (sellsDatasource.selectedStatus !== 'loading') {
                if (!sellsDatasource.selected || sellsDatasource.selected.id !== idFactureVente) {
                    dispatch(selectFactureVente({ id: idFactureVente }));
                }
            }
        } else {
            dispatch({
                type: `${FACTURE_VENTE_SLICE_NAME}/deletemainSelected`,
            });
        }
    }, [
        dispatch,
        folioSelected,
        isFormalite,
        sellsDatasource.selected,
        sellsDatasource.selectedStatus,
    ]);

    /**
     * Définir le dossier de formalité.
     * Dépends du domaine définit à la sélection du fournisseur.
     */
    useEffect(() => {
        if (isFormalite === undefined || !isFormalite) return;

        if (foliosFormalityDatasource.selectedStatus !== 'loading' && lignes && lignes[0]) {
            if (!folioFormalitySelected || folioFormalitySelected.id !== lignes[0].idDossierFpro) {
                if (lignes[0].idDossierFpro === null) {
                    setIsFormalite(false);
                }

                dispatch(
                    selectDossierFormalite({
                        id: `${lignes[0].idDossierFpro}`,
                    }),
                );
            }
        }
    }, [
        dispatch,
        folioFormalitySelected,
        foliosFormalityDatasource.selectedStatus,
        isFormalite,
        lignes,
    ]);

    const getLignes = useGetLignesFpro();

    const fetchLines = useCallback(() => {
        if (invoiceSelected?.id && folioFormalitySelected?.id && lignesStatus !== 'loading') {
            setLignesStatus('loading');
            getLignes(invoiceSelected.id, [folioFormalitySelected.id])
                .then((data) => {
                    setLignesStatus('succeeded');
                    setLignes(data);
                })
                .catch((error) => {
                    setLignesStatus('failed');
                    console.error(error);
                });
        }
    }, [folioFormalitySelected?.id, getLignes, invoiceSelected.id, lignesStatus]);

    useEffect(() => {
        if (invoiceSelected && lignesStatus !== 'succeeded') {
            fetchLines();
        }
    }, [invoiceSelected, lignesStatus, fetchLines]);

    const forceSyncG3 = useSyncG3({
        invoice: invoiceSelected,
        forced: true,
    });

    const deleteInvoice = useDeleteInvoice({
        invoice: invoiceSelected,
    });

    useAddCustomContextActions([...(isInterne ? [forceSyncG3, deleteInvoice] : [])]);

    const { getSwitchLockValues, stateSwitchLockValues } = useSwitchLockValues();

    const resetStates = useCallback(() => {
        // clean du state du formulaire
        dispatch({
            type: ACTIONS.RESET,
        });
    }, [dispatch]);

    const navigate = useNavigate();

    const reprendreFacture = useCallback(() => {
        if (!invoiceSelected) return;
        resetStates();

        const fallback: AccessibleDirections = 'formmultiadddossier';

        const response = generateB2DPath(fallback, { factureAchat: invoiceSelected });
        if (response.status === 'OK') {
            navigate(response.path);
        }
    }, [invoiceSelected, resetStates, navigate]);

    const state = useSelector(FactureFormSelector);
    const navigateToFactureAchatRecap = useNavigateToFactureAchatRecap();
    const navigateToFactureAchatRecapFormalite = useNavigateToFactureAchatRecapFormalite();

    useEffect(() => {
        setIsFormalite(true);
        if (state.defDomain === 3) return;
        setDomainForced(3);
    }, [setIsFormalite, setDomainForced, state.defDomain]);

    const renderedActionButtons: ReactNode = useMemo(
        () => (
            <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
            >
                <Button
                    color="primary"
                    variant="contained"
                    endIcon={<FontAwesomeIcon icon={faListCheck} />}
                    onClick={() => {
                        if (state.defDomain === 3) {
                            navigateToFactureAchatRecapFormalite(
                                invoiceSelected,
                                mediaObjectSelected,
                            );
                        } else {
                            navigateToFactureAchatRecap(invoiceSelected, mediaObjectSelected);
                        }
                    }}
                    style={{
                        marginTop: '16px',
                        marginBottom: '16px',
                    }}
                >
                    Consulter le récapitulatif
                </Button>
                <Button
                    color="primary"
                    variant="outlined"
                    endIcon={<FontAwesomeIcon icon={faForward} />}
                    onClick={() => {
                        reprendreFacture();
                    }}
                    style={{ marginTop: '16px', marginBottom: '16px' }}
                >
                    Saisir un nouveau dossier
                </Button>
            </Box>
        ),
        [
            invoiceSelected,
            mediaObjectSelected,
            navigateToFactureAchatRecap,
            navigateToFactureAchatRecapFormalite,
            reprendreFacture,
            state.defDomain,
        ],
    );

    return (
        <Box key={`publication-form-${mediaObjectSelected?.contentUrl}`}>
            <UpdateFolioFormalitySectionForm
                key={`publication-form-folio-${folioFormalitySelected?.id}`}
                folioFormality={folioFormalitySelected}
                invoice={invoiceSelected}
                lines={lignes}
                onFetchNewLines={fetchLines}
                switchLockValues={{
                    getSwitchLockValues,
                    stateSwitchLockValues,
                }}
            />
            <UpdateProductsSectionForm
                key={`publication-form-products-${invoiceSelected?.id}-${lignes && lignes[0] ? lignes[0].id : 'N/A'}-${lignes && lignes[0] ? lignes[0].idDossierFpro : 'N/A'}`}
                invoice={invoiceSelected}
                lines={lignes}
                onFetchNewLines={fetchLines}
                switchLockValues={{
                    getSwitchLockValues,
                    stateSwitchLockValues,
                }}
            />
            {renderedActionButtons}
        </Box>
    );
};

export default UpdateMultiFormalityForm;
