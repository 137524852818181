import React from 'react';
import {
    Box,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    SxProps,
    Typography,
    TypographyProps,
} from '@mui/material';

export type DataCardProps = {
    title: string;
    children: React.ReactNode;
    details?: React.ReactNode;
    icon?: React.ReactNode;
    color?: string;
    sx?: SxProps;
    tools?: React.ReactNode;
    iconBgColor?: string;
    iconColor?: string;
    typographyVariant?: TypographyProps['variant'];
};

const DataCard: React.FunctionComponent<DataCardProps> = function ({
    title,
    children,
    details,
    icon,
    color,
    sx,
    tools,
    iconBgColor,
    iconColor,
    typographyVariant,
    ...props
}) {
    const colorPrincipal = color ? `${color}.main` : 'background.paper';

    return (
        <Card
            role="contentinfo"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                backgroundColor: 'background.paper',
                color: 'text.primary',
                height: 'auto',
                borderWidth: '2px',
                borderStyle: 'solid',
                borderColor: colorPrincipal,
                ...sx,
            }}
            {...props}
        >
            <CardHeader
                title={
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="flex-start"
                    >
                        <Typography
                            variant={typographyVariant || 'h5'}
                            fontWeight="bold"
                        >
                            {title}
                        </Typography>
                        {icon ? (
                            <Typography
                                width="48px"
                                height="48px"
                                borderRadius="100%"
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                sx={{
                                    backgroundColor: iconBgColor || colorPrincipal,
                                    color: iconColor || 'background.paper',
                                }}
                            >
                                {icon}
                            </Typography>
                        ) : null}
                    </Box>
                }
            />

            <CardContent>
                {children}
                {/* <Typography variant="h3"></Typography> */}
                <Typography
                    variant="h6"
                    sx={{ opacity: '0.6' }}
                >
                    {details}
                </Typography>
            </CardContent>

            {tools ? (
                <CardActions
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    {tools}
                </CardActions>
            ) : null}
        </Card>
    );
};

export default DataCard;
