import { Dispatch } from 'react';

import { NavigateFunction } from 'react-router';
import {
    CustomizerReducerType,
    MediaObject,
    MediaObjectApiObject,
    Route,
} from '@europrocurement/l2d-domain';
import { ACTIONS } from '@b2d/redux/FactureFormReducer';

import { AnyAction, CombinedState, ThunkDispatch } from '@reduxjs/toolkit';

import { jsonLdIdScraper } from '@europrocurement/l2d-utils';
import { getMediaObject, getMediaObjectByViewName } from '@b2d/redux/RootStore';
import { ViewName } from '@b2d/pages/Achats/views/lists/UnregisteredInvoicesViews/hooks/types';
import generateB2DPath from './generateB2DPath';

/**
 * Build the path to navigate, to add the parameter "from",
 * which we use then to select the correct Thunk Action to retrieve the
 * expected MediaObject.
 */
const navigateConcatFromParam = (responsePath: string, navigate: NavigateFunction): void => {
    const urlParams = new URLSearchParams(window.location.search);
    const fromList = urlParams.get('from');

    const path = fromList ? `${responsePath}?from=${fromList}` : responsePath;
    navigate(path);
};

type DispatchProps = ThunkDispatch<
    CombinedState<{
        routing: CombinedState<{
            routing: Route[];
        }>;
        customizer: CustomizerReducerType;
    }>,
    undefined,
    AnyAction
> &
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Dispatch<any>;

/**
 * Navigation vers multi-annonces
 *
 * Permet d'accéder au formulaire de saisie multi-annonces en nettoyant à la fois le state et les DataSources.
 *
 * @param multiAnnonceNavigation
 * @param onError
 */
export const navigateToMultiAnnonces = (
    showMessage: (message: string, variant: 'info' | 'warning' | 'error') => void,
    navigate: NavigateFunction,
    idMediaObject?: number,
    idStatement?: number,
) => {
    const response = generateB2DPath(
        idStatement ? 'formmultimediaobjectstatement' : 'formmultimediaobject',
        {
            mediaObjectId: idMediaObject,
            statementId: idStatement,
        },
    );

    if (response.status === 'OK') {
        navigateConcatFromParam(response.path, navigate);
    } else if (response.status === 'KO') {
        showMessage(response.message, 'warning');
    }
};

const findNextMediaObject = (mediaObjectList: MediaObjectApiObject[], idSelected?: string) => {
    if (!idSelected) return mediaObjectList[0];

    if (Number.isNaN(Number(idSelected))) {
        const scrappedId = jsonLdIdScraper(idSelected);
        if (Number.isNaN(Number(scrappedId))) {
            return mediaObjectList[0];
        }
    }

    const idSelectedConverted: number = Number(idSelected);
    const untagsMediaObjects = mediaObjectList.filter(
        (mediaObject) => !mediaObject.tags || mediaObject.tags?.length === 0,
    );

    const currentIndex = untagsMediaObjects.findIndex(
        (mediaObjectItem) => mediaObjectItem.id === idSelectedConverted,
    );
    const nextIndex = currentIndex + 1;

    // If the current item is the last one or not found, return the first item
    return nextIndex >= untagsMediaObjects.length || currentIndex === -1
        ? untagsMediaObjects[0]
        : untagsMediaObjects[nextIndex];
};

type CommonProps = {
    showMessage: (message: string, variant: 'info' | 'warning' | 'error') => void;
    dispatch: DispatchProps;
    navigate: NavigateFunction;
    isInterne?: boolean;
};

type NextUnregisteredPurchaseNeedNewMediaObjectProps = CommonProps & {
    navigateToList: () => void;
    idCurrentMediaObject?: string;
};

type FromParamCheckerProps = {
    isInterne?: boolean;
};

export const fromParamChecker = ({ isInterne }: FromParamCheckerProps): ViewName | undefined => {
    const urlParams = new URLSearchParams(window.location.search);
    const fromParam = urlParams.get('from');
    let validFromParam: ViewName | undefined;
    // Mada authorized to access only media objects from unregistered list.
    const isMadaInBounds: boolean = !isInterne && fromParam === 'unregistered';
    const checkRolesAuthorizations: boolean = isInterne || isMadaInBounds;

    if (fromParam !== null && checkRolesAuthorizations) {
        validFromParam = fromParam as ViewName;
    }

    return validFromParam;
};

const nextUnregisteredPurchaseNeedNewMediaObject = ({
    showMessage,
    dispatch,
    navigate,
    navigateToList,
    isInterne,
    idCurrentMediaObject,
}: NextUnregisteredPurchaseNeedNewMediaObjectProps) => {
    setTimeout(async () => {
        const validFromParam = fromParamChecker({ isInterne });

        // Use the "from" param to make sure the correct MediaObject will be used.
        const mediaObjects: { payload: { 'hydra:member': MediaObject[] } } = (await dispatch(
            validFromParam ? getMediaObjectByViewName(validFromParam)({}) : getMediaObject({}),
        )) as unknown as { payload: { 'hydra:member': MediaObject[] } };

        const mediaObjectsContent = mediaObjects.payload['hydra:member'];
        const noItems = mediaObjectsContent.length === 0;
        const remainingItemIsSelectedOne =
            mediaObjectsContent.length === 1 &&
            mediaObjectsContent[0]['@id'] === idCurrentMediaObject;

        if (noItems || remainingItemIsSelectedOne) {
            navigateToList();
            showMessage('Toutes les factures sont traitées ou en traitement', 'info');
        } else {
            let response = null;
            const mediaObjectToUse = findNextMediaObject(mediaObjectsContent, idCurrentMediaObject);

            response = generateB2DPath('formmediaobject', {
                mediaObjectId: mediaObjectToUse.id,
            });

            if (response.status === 'OK') {
                dispatch({
                    type: ACTIONS.RESET,
                });
                navigateConcatFromParam(response.path, navigate);
            } else if (response.status === 'KO') {
                showMessage(response.message, 'warning');
            }
        }
    }, 500);
};

type NextUnregisteredPurchaseNeedSameMediaObjectProps = Omit<CommonProps, 'getMediaObject'> & {
    idCurrentMediaObject: string;
    idStatement: number;
};

const nextUnregisteredPurchaseNeedSameMediaObject = ({
    showMessage,
    dispatch,
    navigate,
    idCurrentMediaObject,
    idStatement,
}: NextUnregisteredPurchaseNeedSameMediaObjectProps) => {
    const response = generateB2DPath('statementRegister', {
        mediaObjectId: jsonLdIdScraper(idCurrentMediaObject),
        statementId: idStatement,
    });

    if (response.status === 'OK') {
        dispatch({
            type: ACTIONS.FULL_RESET,
        });
        navigateConcatFromParam(response.path, navigate);
    } else if (response.status === 'KO') {
        showMessage(response.message, 'warning');
    }
};

type NavigateToNewFactureProps = CommonProps & {
    navigateToList: () => void;
    idCurrentMediaObject?: string;
    idStatement?: number;
};

export const navigateToNewFacture = async ({
    showMessage,
    dispatch,
    navigate,
    navigateToList,
    isInterne,
    idCurrentMediaObject,
    idStatement,
}: NavigateToNewFactureProps) => {
    if (idCurrentMediaObject && idStatement) {
        nextUnregisteredPurchaseNeedSameMediaObject({
            showMessage,
            dispatch,
            navigate,
            isInterne,
            idCurrentMediaObject,
            idStatement,
        });
    } else {
        nextUnregisteredPurchaseNeedNewMediaObject({
            showMessage,
            dispatch,
            navigate,
            navigateToList,
            isInterne,
            idCurrentMediaObject,
        });
    }
};

export const navigateToRecapStatement = async (navigate: NavigateFunction, idStatement: number) => {
    const response = generateB2DPath('statementRecap', {
        statementId: idStatement,
    });

    if (response.status === 'OK') {
        navigate(response.path);
    }
};
