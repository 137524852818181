import { Box, Stack, Typography } from '@mui/material';
import React, { FunctionComponent, ReactNode } from 'react';
import { FactureAchatApiObject } from '@europrocurement/l2d-domain';
import FactureAchatEtats from '../../widgets/FactureAchatEtats';
import StatusList from '../StatusList';

type InvoiceDataCardMultiProps = {
    invoice?: FactureAchatApiObject;
    headerControllers?: ReactNode | Array<ReactNode>;
    details?: Array<ReactNode>;
};

const InvoiceDataCardMulti: FunctionComponent<InvoiceDataCardMultiProps> = function ({
    invoice,
    headerControllers,
    details,
}: InvoiceDataCardMultiProps) {
    return (
        <Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Stack
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        gap: 1,
                    }}
                >
                    <Typography
                        color="secondary"
                        typography="h1"
                    >
                        Facture N°{invoice?.numeroFacture || 'N/A'}{' '}
                        {invoice?.avoir === true ? "(Facture d'avoir)" : ''}
                    </Typography>
                    <StatusList itemToWatch={invoice} />
                </Stack>
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="flex-start"
                    spacing={0}
                >
                    {details}
                </Stack>
                {invoice && invoice.cloture ? <FactureAchatEtats factureAchats={invoice} /> : null}
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                }}
            >
                {headerControllers}
            </Box>
        </Box>
    );
};

export default InvoiceDataCardMulti;
