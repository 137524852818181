import * as React from 'react';
import { Box, Container } from '@mui/system';
import { Button, Grid, Typography } from '@mui/material';
import { FlexyFormLabel, SelectItem, SelectItemProps } from '../../atoms';

type title = { title?: string };
export type SelectItemBaseProps = Omit<SelectItemProps, 'design' | ' onChange'> & title;

export type SelectItemsProps = {
    mode?: 'block' | 'inline' | 'mixed';
    items: SelectItemBaseProps[];
    colBlock?: number;
    colInline?: number;
    mixedMaxLines?: number;
    onChange: (values: Array<SelectItemProps['value']>) => void;
    value?: Array<SelectItemProps['value']>;
    label?: string;
    showMore?: boolean;
    showMoreLabel?: string;
    withTitle?: boolean;
};

function onlyUnique<T>(value: T, index: number, array: Array<T>) {
    return array.indexOf(value) === index;
}

const SelectItems: React.FunctionComponent<SelectItemsProps> = function ({
    mode = 'mixed',
    items = [],
    colBlock = 3,
    colInline = 6,
    mixedMaxLines = 2,
    onChange,
    value,
    label,
    showMore = false,
    showMoreLabel = 'de propositions',
    withTitle = false,
}: SelectItemsProps) {
    let innerItems: SelectItemsProps['items'];

    if (value) {
        innerItems = items.map((item) => {
            if (value.indexOf(item.value) !== -1) {
                return { ...item, isChecked: true };
            }
            return { ...item, isChecked: false };
        });
    } else {
        innerItems = items;
    }

    const allValues = innerItems.map((i) => i.value).filter(onlyUnique);
    if (allValues.length !== innerItems.length) {
        console.error('Two items or more with the same value found.');
    }

    const [hide, setHide] = React.useState(showMore);
    const [values, setValues] = React.useState(
        innerItems.filter((i) => i.isChecked).map((i) => i.value),
    );

    const onChangeWrapper: SelectItemProps['onChange'] = React.useCallback(
        (newValue: SelectItemProps['value'], checked: boolean) => {
            if (checked) {
                setValues([...values, newValue]);
            } else {
                setValues([...values.filter((v) => v !== newValue)]);
            }
        },
        [values, setValues],
    );

    React.useEffect(() => {
        if (onChange) {
            onChange(values);
        }
    }, [onChange, values]);

    let nbItemBlock = 0;
    if (mode === 'mixed') {
        nbItemBlock = mixedMaxLines * (12 / colBlock);
    } else if (mode === 'block') {
        nbItemBlock = innerItems.length;
    }

    let cssHide: React.CSSProperties = {};
    if (hide && mode === 'mixed') {
        cssHide = { display: 'none' };
    }
    const gridblock = (
        <Box data-testid="test-id-block-box">
            <Grid
                container
                spacing={1}
            >
                {innerItems.slice(0, nbItemBlock).map((item, index, itemArray) => (
                    <>
                        {withTitle &&
                        item.title &&
                        (index === 0 || item.title !== itemArray[index - 1].title) ? (
                            <Grid
                                item
                                lg={12}
                            >
                                {' '}
                                <Typography
                                    component="h2"
                                    sx={{
                                        fontSize: '18px',
                                    }}
                                >
                                    Formalité de type {item.title}
                                </Typography>
                            </Grid>
                        ) : null}
                        <Grid
                            item
                            lg={colBlock}
                            key={item.value}
                        >
                            <SelectItem
                                label={item.label}
                                icone={item.icone}
                                value={item.value}
                                design="block"
                                isChecked={item.isChecked}
                                isDisabled={item.isDisabled}
                                onChange={onChangeWrapper}
                            />
                        </Grid>
                    </>
                ))}
            </Grid>
        </Box>
    );

    const gridinline = (
        <Box
            style={{ ...cssHide }}
            data-testid="test-id-inline-box"
        >
            <Grid
                container
                spacing={1}
            >
                {innerItems.slice(nbItemBlock).map((item, index, itemArray) => (
                    <>
                        {withTitle &&
                        item.title &&
                        (index === 0 || item.title !== itemArray[index - 1].title) ? (
                            <Grid
                                item
                                lg={12}
                            >
                                {' '}
                                <Typography
                                    component="h2"
                                    sx={{
                                        fontSize: '18px',
                                    }}
                                >
                                    Formalité de type {item.title}
                                </Typography>
                            </Grid>
                        ) : null}
                        <Grid
                            item
                            lg={colInline}
                            key={item.value}
                        >
                            <SelectItem
                                label={item.label}
                                icone={item.icone}
                                value={item.value}
                                onChange={onChangeWrapper}
                                isChecked={item.isChecked}
                                isDisabled={item.isDisabled}
                            />
                        </Grid>
                    </>
                ))}
            </Grid>
        </Box>
    );

    const showMoreOrLess = (
        <Box
            sx={{ textAlign: 'center' }}
            data-testid="test-id-showMoreOrLess-box"
        >
            <Button
                variant="text"
                color="primary"
                onClick={() => {
                    setHide(!hide);
                }}
            >
                {' '}
                Afficher {hide ? 'plus' : 'moins'} {showMoreLabel}
            </Button>
        </Box>
    );
    return (
        <Container maxWidth="md">
            {label ? (
                <FlexyFormLabel>
                    <Typography
                        component="h2"
                        sx={{
                            fontSize: '18px',
                        }}
                    >
                        {label}
                    </Typography>
                </FlexyFormLabel>
            ) : null}

            {mode !== 'inline' ? gridblock : null}
            {showMore ? showMoreOrLess : null}
            {mode !== 'block' ? gridinline : null}
        </Container>
    );
};

export default SelectItems;
