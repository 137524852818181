/* eslint-disable @typescript-eslint/no-explicit-any */
import { TypographyProps } from '@mui/material';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { FormStructure } from '../components';

const renderError: (message: string) => FormStructure = (message) => ({
    type: 'header',
    label: message,
    name: 'error_message',
});

type jsonHeader = {
    type: 'header';
    icon: string;
    label: string;
    name: string;
    variant?: TypographyProps['variant'];
};

type disabledType =
    | true
    | false
    | {
          isFalse?: string;
      };

type jsonTextInput = {
    type: 'text';
    name: string;
    inputlabel: string;
    // rules: a typer;
    placeholder: string;
    disabled: disabledType;
};

type jsonBooleanInput = {
    type: 'boolean';
    name: string;
    inputlabel: string;
    // rules: a typer;
    placeholder: string;
    disabled: disabledType;
};

const jsonHeaderToFormElement: (header: jsonHeader) => FormStructure = (header) => ({
    type: header.type,
    label: header.label,
    name: header.name,
    variant: header.variant || undefined,
});

// {
//     "type": "text",
//     "name": "denom",
//     "inputlabel": "Dénomination de la société",

//     "rules": { "required": true },
//     "placeholder": "Dénomination de la société",
//     "disabled": false
// },
const jsonTextInputToFormElement: (input: jsonTextInput) => FormStructure = (input) => {
    const baseField: FormStructure = {
        type: input.type,
        name: input.name,
        inputlabel: input.inputlabel,
        placeholder: input.placeholder,
        // label: header.label,
        // variant: header.variant || undefined,
    };

    // comportement de toute la partie disabled
    if (input.disabled) {
        if (input.disabled === true) {
            baseField.disabled = true;
        } else if (input.disabled.isFalse) {
            const fieldNameToWatch = input.disabled.isFalse;
            baseField.disabled = (formContext: UseFormReturn<FieldValues, any>) =>
                !formContext.watch(fieldNameToWatch as string);
        }
    }

    return baseField;
};

const jsonBooleanInputToFormElement: (input: jsonBooleanInput) => FormStructure = (input) => {
    const baseField: FormStructure = {
        type: input.type,
        name: input.name,
        inputlabel: input.inputlabel,
        placeholder: input.placeholder,
        // label: header.label,
        // variant: header.variant || undefined,
    };

    return baseField;
};

const jsonPageSectionToStructure: (section: Record<string, unknown>) => FormStructure = (
    section,
) => {
    if (!section.type) {
        console.warn('pas de type sur cette section');
        // passer en textinfo
        return renderError(
            `Impossible d'afficher l'élément, pas de type ${JSON.stringify(section)}`,
        );
    }
    if (!section.name) {
        console.warn('pas de name sur cette section');
        // passer en textinfo
        return renderError(
            `Impossible d'afficher l'élément, pas de name ${JSON.stringify(section)}`,
        );
    }

    switch (section.type) {
        case 'header':
            return jsonHeaderToFormElement(section as jsonHeader);
        case 'separator':
            return renderError(`To Implement`);
        case 'textinfo':
            return renderError(`To Implement`);

        case 'text':
            return jsonTextInputToFormElement(section as jsonTextInput);
        case 'date':
            return renderError(`To Implement`);
        case 'datetime':
            return renderError(`To Implement`);
        case 'boolean':
            return jsonBooleanInputToFormElement(section as jsonBooleanInput);
        case 'radio':
            return renderError(`To Implement`);
        case 'select':
            return renderError(`To Implement`);

        case 'subform':
            return renderError(`To Implement`);
        case 'custom':
            return renderError(`To Implement`);

        default:
            return renderError(`élément non pris en charge :  ${section.type}`);
    }
};

const jsonPageSectionsToStructure: (sections: Array<Record<string, unknown>>) => FormStructure[] = (
    sections,
) => sections.map((section) => jsonPageSectionToStructure(section));

const jsonFormToFormStructure: (json: Record<string, unknown>) => FormStructure[] = (json) => {
    console.warn(json);

    const structure: FormStructure[] = [];

    if (json.pages && Array.isArray(json.pages) && json.pages[0] && json.pages[0].sections) {
        return jsonPageSectionsToStructure(json.pages[0].sections);
    }

    // 'header'    => SectionHeader::class,
    // 'separator' => SectionSeparator::class,
    // 'textinfo'  => SectionTextInfo::class,

    // 'text'      => SectionText::class,
    // 'date'      => SectionDate::class,
    // 'datetime'  => SectionDateTime::class,
    // 'boolean'   => SectionBoolean::class,
    // 'radio'     => SectionRadio::class,
    // 'select'    => SectionSelect::class,

    // 'subform'   => SectionSubForm::class,
    // 'custom'    => SectionCustom::class,

    return structure;
};

export default jsonFormToFormStructure;
