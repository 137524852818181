/* eslint-disable @typescript-eslint/no-unused-vars */

import {
    SliceFactory,
    FetchCollectionData,
    FetchItemDataType,
    ApiCollectionResponse,
} from '@europrocurement/l2d-redux-utils';
import {
    ConfigurationParameters as FactureVentesConfigurationParameters,
    Configuration,
    FactureVenteApi,
    FactureVenteFactureVenteRead,
} from '../../../openApi/ApiDossiers';
import { DOSSIERS_REDUCER_NAME } from '../constants';

export type FactureVenteApiObject = FactureVenteFactureVenteRead & Record<string, unknown>;

export type FactureVente = FactureVenteApiObject;

export const FACTURE_VENTE_SLICE_NAME = 'facturevente';

const mapperFactureVente = function (factureVente: FactureVenteApiObject) {
    return {
        ...factureVente,
    } as FactureVente;
};

export function createFactureVenteSlice(configuration: FactureVentesConfigurationParameters) {
    const conf = new Configuration(configuration);
    const api = new FactureVenteApi(conf);

    const fetchFactureVentesCollection: FetchCollectionData<FactureVenteApiObject> = ({
        search,
        pagination,
        filters,
        orders,
        xIdSociete,
        rootstate,
    }) =>
        api
            .getFactureVenteCollection({ page: pagination.page + 1 })
            .then((res) => res.data) as Promise<ApiCollectionResponse<FactureVenteApiObject>>;

    const fetchFactureVentesItem: FetchItemDataType<FactureVenteApiObject> = ({
        idItem,
        search,
        pagination,
        filters,
        orders,
        xIdSociete,
        rootstate,
    }) =>
        api
            .getFactureVenteItem({ id: String(idItem), xIdSociete })
            .then((res) => res.data) as Promise<FactureVenteApiObject>;

    const { slice: factureVenteSlice, dataSourcesThunks: factureVenteDataSourcesThunks } =
        SliceFactory.createSlice<FactureVenteApiObject, FactureVente>(
            FACTURE_VENTE_SLICE_NAME,
            DOSSIERS_REDUCER_NAME,
            [],
            fetchFactureVentesCollection,
            fetchFactureVentesItem,
            mapperFactureVente,
        );

    return { factureVenteSlice, factureVenteDataSourcesThunks };
}
