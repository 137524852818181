/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { FlexyForm, FormObject, FormStructure } from '@europrocurement/flexy-form';
import { TiersPrescripteurJsonldPrescripteurUpdate } from '@europrocurement/l2d-domain/openApi/ApiTiers';
import useApiRequest from '@b2d/hooks/useApiRequest';
import { isLuhnValid } from '@b2d/validation/validation';
import { Badge, Box, Tooltip } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import ExistingPrescribersList from '@b2d/pages/Prescripteurs/component/ExistingPrescribersList';
import { Prescripteur } from '@europrocurement/l2d-domain';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import { useForm } from 'react-hook-form';
import { useSociete } from '@b2d/hooks/societeHooks';
import { useSiret } from './useSiret';
import { PrescriberFormProps } from '../../../forms/common';
import { prescribersApi } from '../../../../../redux/RootStore';

export type MainInformationFormProps = PrescriberFormProps;

type FormFields =
    | 'siren'
    | 'regimeTva'
    | 'tvaIntracommunautaire'
    | 'personnePhysique'
    | 'venteIndirecte';

const MainInformationForm: React.FunctionComponent<MainInformationFormProps> = function (props) {
    const { afterSubmit, prescriber } = props;

    const { request, requestState } = useApiRequest();

    const xIdSociete = useSociete();

    const { siret: initialSiret } = prescriber;

    const initialSiretStatusState = React.useMemo(
        () => ({
            isValid: isLuhnValid(initialSiret ?? ''),
            isEmpty: initialSiret?.length === 0,
            value: initialSiret ?? '',
        }),
        [initialSiret],
    );

    const initialFormValues = React.useMemo(
        () => ({
            siren: prescriber.siren,
            regimeTva: prescriber.regimeTva,
            tvaIntracommunautaire: prescriber.tvaIntracommunautaire,
            personnePhysique: prescriber.personnePhysique,
            venteIndirecte: prescriber.venteIndirecte,
            raisonSociale: prescriber.raisonSociale,
        }),
        [
            prescriber.personnePhysique,
            prescriber.raisonSociale,
            prescriber.regimeTva,
            prescriber.siren,
            prescriber.tvaIntracommunautaire,
            prescriber.venteIndirecte,
        ],
    );

    const formContext = useForm({
        mode: 'onTouched',
    });

    const { siretStatus, setSiretStatus, siretValidation, prescribersRelatedToSiret } = useSiret(
        formContext,
        initialSiretStatusState,
    );

    const setInitialFormValues = React.useCallback(() => {
        // eslint-disable-next-line no-restricted-syntax
        for (const [field, value] of Object.entries(initialFormValues)) {
            formContext.setValue(field as FormFields, value);
        }
    }, [formContext, initialFormValues]);

    React.useEffect(() => {
        setSiretStatus(initialSiretStatusState);
        setInitialFormValues();
    }, [initialSiretStatusState, setInitialFormValues, setSiretStatus]);

    const handleSiretInput = (event: any) => {
        const { value } = event.target;
        siretValidation(value);
    };

    const existingPrescribersExceptCurrentPrescriber = (prescribersRelatedToSiret || []).filter(
        (existingPrescriber: Prescripteur) => existingPrescriber.id !== prescriber.id,
    );

    const MainInformationFormStructure: FormStructure[] = [
        {
            type: 'header',
            label: (
                <>
                    <ToggleOnIcon sx={{ marginRight: '5px' }} />
                    Configuration
                </>
            ),
            sx: {
                marginTop: '0px',
            },
            name: 'info',
        },
        {
            type: 'boolean',
            name: 'personnePhysique',
            inputlabel: 'Personne physique',
            xs: 12,
        },
        {
            type: 'boolean',
            name: 'venteIndirecte',
            inputlabel: 'Vente Indirecte',
            xs: 12,
        },
        {
            type: 'header',
            label: (
                <>
                    <PersonIcon sx={{ marginRight: '5px' }} />
                    Informations société
                </>
            ),
            sx: {
                marginTop: '0px',
            },
            name: 'info',
        },
        {
            type: 'text',
            name: 'raisonSociale',
            inputlabel: 'Raison sociale',
            xs: 12,
        },
        {
            type: 'number',
            name: 'siret',
            inputlabel: 'SIRET',
            xs: 11,
            lg: 4,
            placeholder: 'SIRET',
            value: siretStatus.value,
            rules: {
                validate: {
                    siret: () => siretStatus.isValid || 'Siret invalide',
                },
                pattern: {
                    value: /\d{14}/,
                    message: 'Le Siret doit être constitué de 14 chiffres',
                },
                onChange: (siretValue) => handleSiretInput(siretValue),
            },
            defaultValue: initialSiret,
        },
        {
            type: 'customItem',
            name: 'comptabilite',
            xs: 1,
            renderField: (currentFormContext) => {
                const numberOfExistingsPrescribers =
                    existingPrescribersExceptCurrentPrescriber.length;
                const isBadgeActive = !siretStatus.isEmpty && siretStatus.isValid;

                const getExistingPrescribersLabel = () => {
                    const plural = numberOfExistingsPrescribers > 1;
                    const pluralSuffix = plural ? 's' : '';

                    if (numberOfExistingsPrescribers === 0) {
                        return "Il n'existe aucun autre prescripteur pour ce numéro de Siret";
                    }

                    return `Il existe ${numberOfExistingsPrescribers} autre${pluralSuffix} prescripteur${pluralSuffix} pour ce numéro de
            Siret :`;
                };

                const getSiretTooltipTitle = () => {
                    if (!isBadgeActive) {
                        return '';
                    }
                    return getExistingPrescribersLabel();
                };

                const formDisplaySiretError = currentFormContext.formState.errors.siret?.message;

                return (
                    <Tooltip title={getSiretTooltipTitle()}>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="end"
                            sx={{
                                height: '100%',
                                paddingBottom: formDisplaySiretError ? '24px' : '0px',
                            }}
                        >
                            <Badge
                                badgeContent={isBadgeActive ? numberOfExistingsPrescribers : null}
                                color="primary"
                            >
                                <PersonIcon
                                    fontSize="large"
                                    color="action"
                                />
                            </Badge>
                        </Box>
                    </Tooltip>
                );
            },
        },

        {
            type: 'text',
            name: 'siren',
            inputlabel: 'SIREN',
            xs: 12,
            lg: 3,
            placeholder: 'SIREN',
            disabled: true,
        },
        {
            type: 'text',
            name: 'tvaIntracommunautaire',
            inputlabel: 'TVA Intracommunautaire',
            xs: 12,
            lg: 4,
            placeholder: 'TVA',
        },
    ];

    const updatePrescriber = async (formData: FormObject) => {
        console.log('formData');
        console.log(formData);

        if (prescriber.id) {
            request(
                prescribersApi.updatePrescripteurTiersItem({
                    xIdSociete,
                    id: prescriber.id?.toString(),
                    tiersPrescripteurJsonldPrescripteurUpdate:
                        formData as unknown as TiersPrescripteurJsonldPrescripteurUpdate,
                }),
                {
                    callback: afterSubmit,
                },
            );
        }
    };

    return (
        <>
            <FlexyForm
                formContext={formContext}
                formObject={prescriber}
                formStructure={MainInformationFormStructure}
                isSubmitting={requestState.isProcessing}
                onSubmit={(formData) => updatePrescriber(formData)}
            />
            <Box sx={{ minHeight: '275px', maxHeight: '350px' }}>
                <ExistingPrescribersList
                    existingPrescribers={existingPrescribersExceptCurrentPrescriber}
                    hasRedirect={false}
                    maximumRows={3}
                />
            </Box>
        </>
    );
};

export default MainInformationForm;
