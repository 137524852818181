import React from 'react';
import { ModalContext } from './ModalContext';

const useModal = () => {
    const { modalStatus, modalActions } = React.useContext(ModalContext);

    return { modalStatus, modalActions };
};

export default useModal;
