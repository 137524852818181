import * as React from 'react';
import { Box, Link, List, ListItem, SxProps, Typography, useTheme } from '@mui/material';
import './LazyBreadcrumb.css';
import { FaOptionIcon, FaOptionIconProps } from '@europrocurement/l2d-icons';

type PathAndLink = {
    path?: string;
    link: string;
    icon?: FaOptionIconProps;
};

export type LazyBreadcrumbsProps = {
    path: Array<PathAndLink | string>;
    bypass?: boolean;
    sx?: SxProps;
    separator?: string;
};

const LazyBreadcrumb: React.FunctionComponent<LazyBreadcrumbsProps> = function (props) {
    const { path, bypass, sx, separator = ' > ' } = props;
    const theme = useTheme();
    const color = theme.palette.text.primary;
    const hoverColor = theme.palette.primary.main;

    return (
        <Box
            className="breadcrumb"
            sx={sx}
            role="navigation"
        >
            <List className="breadcrumb-items">
                {path?.map((uniquePath, index, arr) => {
                    if (index >= arr.length - 1) {
                        return '';
                    }

                    const label =
                        typeof uniquePath === 'object' ? uniquePath?.path || '' : uniquePath;
                    const icon =
                        typeof uniquePath === 'object' && uniquePath?.icon ? (
                            <FaOptionIcon {...uniquePath.icon} />
                        ) : (
                            ''
                        );

                    if (typeof uniquePath === 'object') {
                        return (
                            <ListItem
                                key={`breadcrumb-item-${label}`}
                                className="breadcrumb-item"
                            >
                                {index > 0 ? separator : ''}
                                <Link
                                    href={uniquePath.link}
                                    sx={{ color, ':hover': { color: hoverColor } }}
                                    key={uniquePath.path}
                                >
                                    {icon}
                                    {label}
                                </Link>
                            </ListItem>
                        );
                    }

                    return (
                        <ListItem
                            key={`breadcrumb-item-${label}`}
                            className="breadcrumb-item"
                        >
                            {index > 0 ? separator : ' '}
                            {icon}
                            {label}
                        </ListItem>
                    );
                })}
            </List>
            {!bypass && undefined !== path[path.length - 1] && (
                <Typography
                    variant="h1"
                    color="secondary"
                    className="page-name"
                >
                    {`${path[path.length - 1]}`}
                </Typography>
            )}
        </Box>
    );
};

export default LazyBreadcrumb;
