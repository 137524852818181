// Colors Theme Constant
export type THEME_LIST =
    | 'LIGHT_THEME'
    | 'DARK_THEME'
    | 'THEME_COLOR'
    | 'BLUE_THEME'
    | 'GREEN_THEME'
    | 'RED_THEME'
    | 'BLACK_THEME'
    | 'PURPLE_THEME'
    | 'INDIGO_THEME'
    | 'ORANGE_THEME'
    | 'L2D_THEME';

export const LIGHT_THEME: THEME_LIST = 'LIGHT_THEME';
export const DARK_THEME: THEME_LIST = 'DARK_THEME';
export const THEME_COLOR: THEME_LIST = 'THEME_COLOR';
export const BLUE_THEME: THEME_LIST = 'BLUE_THEME';
export const GREEN_THEME: THEME_LIST = 'GREEN_THEME';
export const RED_THEME: THEME_LIST = 'RED_THEME';
export const BLACK_THEME: THEME_LIST = 'BLACK_THEME';
export const PURPLE_THEME: THEME_LIST = 'PURPLE_THEME';
export const INDIGO_THEME: THEME_LIST = 'INDIGO_THEME';
export const ORANGE_THEME: THEME_LIST = 'ORANGE_THEME';
export const L2D_THEME: THEME_LIST = 'L2D_THEME';

export const ALL_THEMES: THEME_LIST[] = [
    LIGHT_THEME,
    DARK_THEME,
    THEME_COLOR,
    BLUE_THEME,
    GREEN_THEME,
    RED_THEME,
    BLACK_THEME,
    PURPLE_THEME,
    INDIGO_THEME,
    ORANGE_THEME,
    L2D_THEME,
];

// Config constants
export const SET_ID_SOCIETE = 'SET_ID_SOCIETE';
export const SET_ENV = 'SET_ENV';
export const SET_ENV_API = 'SET_ENV_API';
export const SET_ALL_ENVS = 'SET_ALL_ENVS';

// export * from './selectors';
export * from './document';
export * from './slicename';
export * from './env';
export { default as APIENV } from './env';
