import * as React from 'react';

import { SxProps } from '@mui/system';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PlaceIcon from '@mui/icons-material/Place';
import { FaOptionIcon } from '@europrocurement/l2d-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Box, Tooltip } from '@mui/material';

export type AdditionalActionsProps = {
    icon: IconProp;
    tooltip?: string;
    onClick?: () => void;
};

export type ActionsBarProps = {
    deleteAction?: React.MouseEventHandler<SVGSVGElement>;
    editAction?: React.MouseEventHandler<SVGSVGElement>;
    localisationAction?: React.MouseEventHandler<SVGSVGElement>;
    color?:
        | 'secondary'
        | 'disabled'
        | 'action'
        | 'inherit'
        | 'primary'
        | 'error'
        | 'info'
        | 'success'
        | 'warning'
        | undefined;
    sx?: SxProps;
    additionalActions?: AdditionalActionsProps[];
};

const ActionsBar: React.FunctionComponent<ActionsBarProps> = function ({
    deleteAction,
    editAction,
    localisationAction,
    additionalActions = [],
    color = 'secondary',
    sx,
}) {
    const iconSize = '40px';
    const actionStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        border: '1px solid lightgray',
        borderRadius: '25px',
        width: iconSize,
        height: iconSize,
        padding: '5px',
    };
    return (
        <Box
            role="listbox"
            sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '10px',
                ...sx,
            }}
        >
            {editAction ? (
                <DriveFileRenameOutlineIcon
                    color={color}
                    onClick={editAction}
                    sx={actionStyle}
                />
            ) : null}
            {deleteAction ? (
                <DeleteOutlineIcon
                    color={color}
                    onClick={deleteAction}
                    sx={actionStyle}
                />
            ) : null}
            {localisationAction ? (
                <PlaceIcon
                    color={color}
                    onClick={localisationAction}
                    sx={actionStyle}
                />
            ) : null}
            {additionalActions.map((action) => (
                <Tooltip
                    title={action?.tooltip}
                    key={action.icon as string}
                >
                    <Box
                        onClick={action.onClick ? action.onClick : () => {}}
                        sx={action.onClick ? actionStyle : { ...actionStyle, cursor: 'default' }}
                    >
                        <FaOptionIcon
                            icon={action.icon}
                            color={color}
                        />
                    </Box>
                </Tooltip>
            ))}
        </Box>
    );
};

export default ActionsBar;
