/* tslint:disable */
/* eslint-disable */
/**
 * Europrocurement Api Mail
 * API d\'envoi de mails
 *
 * The version of the OpenAPI document: 1.2.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Attachment
 */
export interface Attachment {
    /**
     * 
     * @type {number}
     * @memberof Attachment
     */
    'id'?: number | null;
    /**
     * 
     * @type {File}
     * @memberof Attachment
     */
    'file'?: File | null;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    'contentUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    'urlExpires'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Attachment
     */
    'size'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    'originalName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    'mimeType'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Attachment
     */
    'deleted'?: boolean;
}
/**
 * 
 * @export
 * @interface AttachmentAttachmentGroupTimestampableBlameambleGroup
 */
export interface AttachmentAttachmentGroupTimestampableBlameambleGroup {
    /**
     * 
     * @type {number}
     * @memberof AttachmentAttachmentGroupTimestampableBlameambleGroup
     */
    'id'?: number | null;
    /**
     * 
     * @type {File}
     * @memberof AttachmentAttachmentGroupTimestampableBlameambleGroup
     */
    'file'?: File | null;
    /**
     * 
     * @type {string}
     * @memberof AttachmentAttachmentGroupTimestampableBlameambleGroup
     */
    'contentUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AttachmentAttachmentGroupTimestampableBlameambleGroup
     */
    'urlExpires'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AttachmentAttachmentGroupTimestampableBlameambleGroup
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AttachmentAttachmentGroupTimestampableBlameambleGroup
     */
    'size'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AttachmentAttachmentGroupTimestampableBlameambleGroup
     */
    'originalName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AttachmentAttachmentGroupTimestampableBlameambleGroup
     */
    'mimeType'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AttachmentAttachmentGroupTimestampableBlameambleGroup
     */
    'deleted'?: boolean;
}
/**
 * 
 * @export
 * @interface AttachmentJsonld
 */
export interface AttachmentJsonld {
    /**
     * 
     * @type {AttachmentJsonldContext}
     * @memberof AttachmentJsonld
     */
    '@context'?: AttachmentJsonldContext;
    /**
     * 
     * @type {string}
     * @memberof AttachmentJsonld
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AttachmentJsonld
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof AttachmentJsonld
     */
    'id'?: number | null;
    /**
     * 
     * @type {File}
     * @memberof AttachmentJsonld
     */
    'file'?: File | null;
    /**
     * 
     * @type {string}
     * @memberof AttachmentJsonld
     */
    'contentUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AttachmentJsonld
     */
    'urlExpires'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AttachmentJsonld
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AttachmentJsonld
     */
    'size'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AttachmentJsonld
     */
    'originalName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AttachmentJsonld
     */
    'mimeType'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AttachmentJsonld
     */
    'deleted'?: boolean;
}
/**
 * 
 * @export
 * @interface AttachmentJsonldAttachmentGroupTimestampableBlameambleGroup
 */
export interface AttachmentJsonldAttachmentGroupTimestampableBlameambleGroup {
    /**
     * 
     * @type {AttachmentJsonldContext}
     * @memberof AttachmentJsonldAttachmentGroupTimestampableBlameambleGroup
     */
    '@context'?: AttachmentJsonldContext;
    /**
     * 
     * @type {string}
     * @memberof AttachmentJsonldAttachmentGroupTimestampableBlameambleGroup
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AttachmentJsonldAttachmentGroupTimestampableBlameambleGroup
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof AttachmentJsonldAttachmentGroupTimestampableBlameambleGroup
     */
    'id'?: number | null;
    /**
     * 
     * @type {File}
     * @memberof AttachmentJsonldAttachmentGroupTimestampableBlameambleGroup
     */
    'file'?: File | null;
    /**
     * 
     * @type {string}
     * @memberof AttachmentJsonldAttachmentGroupTimestampableBlameambleGroup
     */
    'contentUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AttachmentJsonldAttachmentGroupTimestampableBlameambleGroup
     */
    'urlExpires'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AttachmentJsonldAttachmentGroupTimestampableBlameambleGroup
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AttachmentJsonldAttachmentGroupTimestampableBlameambleGroup
     */
    'size'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AttachmentJsonldAttachmentGroupTimestampableBlameambleGroup
     */
    'originalName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AttachmentJsonldAttachmentGroupTimestampableBlameambleGroup
     */
    'mimeType'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AttachmentJsonldAttachmentGroupTimestampableBlameambleGroup
     */
    'deleted'?: boolean;
}
/**
 * @type AttachmentJsonldContext
 * @export
 */
export type AttachmentJsonldContext = AttachmentJsonldContextOneOf | string;

/**
 * 
 * @export
 * @interface AttachmentJsonldContextOneOf
 */
export interface AttachmentJsonldContextOneOf {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof AttachmentJsonldContextOneOf
     */
    '@vocab': string;
    /**
     * 
     * @type {string}
     * @memberof AttachmentJsonldContextOneOf
     */
    'hydra': AttachmentJsonldContextOneOfHydraEnum;
}

export const AttachmentJsonldContextOneOfHydraEnum = {
    HttpWwwW3OrgNsHydraCore: 'http://www.w3.org/ns/hydra/core#'
} as const;

export type AttachmentJsonldContextOneOfHydraEnum = typeof AttachmentJsonldContextOneOfHydraEnum[keyof typeof AttachmentJsonldContextOneOfHydraEnum];

/**
 * 
 * @export
 * @interface MailAttachmentJsonldMailRead
 */
export interface MailAttachmentJsonldMailRead {
    /**
     * 
     * @type {AttachmentJsonldContext}
     * @memberof MailAttachmentJsonldMailRead
     */
    '@context'?: AttachmentJsonldContext;
    /**
     * 
     * @type {string}
     * @memberof MailAttachmentJsonldMailRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MailAttachmentJsonldMailRead
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof MailAttachmentJsonldMailRead
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MailAttachmentJsonldMailRead
     */
    'contentUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MailAttachmentJsonldMailRead
     */
    'urlExpires'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MailAttachmentJsonldMailRead
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MailAttachmentJsonldMailRead
     */
    'size'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MailAttachmentJsonldMailRead
     */
    'originalName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MailAttachmentJsonldMailRead
     */
    'mimeType'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MailAttachmentJsonldMailRead
     */
    'deleted'?: boolean;
}
/**
 * 
 * @export
 * @interface MailAttachmentMailRead
 */
export interface MailAttachmentMailRead {
    /**
     * 
     * @type {string}
     * @memberof MailAttachmentMailRead
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MailAttachmentMailRead
     */
    'contentUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MailAttachmentMailRead
     */
    'urlExpires'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MailAttachmentMailRead
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MailAttachmentMailRead
     */
    'size'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MailAttachmentMailRead
     */
    'originalName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MailAttachmentMailRead
     */
    'mimeType'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MailAttachmentMailRead
     */
    'deleted'?: boolean;
}
/**
 * 
 * @export
 * @interface MailJsonldMailRead
 */
export interface MailJsonldMailRead {
    /**
     * 
     * @type {AttachmentJsonldContext}
     * @memberof MailJsonldMailRead
     */
    '@context'?: AttachmentJsonldContext;
    /**
     * 
     * @type {string}
     * @memberof MailJsonldMailRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MailJsonldMailRead
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof MailJsonldMailRead
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MailJsonldMailRead
     */
    'from': string;
    /**
     * 
     * @type {string}
     * @memberof MailJsonldMailRead
     */
    'fromName'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MailJsonldMailRead
     */
    'tos': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MailJsonldMailRead
     */
    'ccs': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MailJsonldMailRead
     */
    'bccs': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MailJsonldMailRead
     */
    'replies': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof MailJsonldMailRead
     */
    'subject': string | null;
    /**
     * 
     * @type {string}
     * @memberof MailJsonldMailRead
     */
    'body': string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MailJsonldMailRead
     */
    'tags': Array<string>;
    /**
     * 
     * @type {Array<MailAttachmentJsonldMailRead>}
     * @memberof MailJsonldMailRead
     */
    'attachments'?: Array<MailAttachmentJsonldMailRead>;
}
/**
 * 
 * @export
 * @interface MailJsonldMailSend
 */
export interface MailJsonldMailSend {
    /**
     * 
     * @type {AttachmentJsonldContext}
     * @memberof MailJsonldMailSend
     */
    '@context'?: AttachmentJsonldContext;
    /**
     * 
     * @type {string}
     * @memberof MailJsonldMailSend
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MailJsonldMailSend
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof MailJsonldMailSend
     */
    'from': string;
    /**
     * 
     * @type {string}
     * @memberof MailJsonldMailSend
     */
    'fromName'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MailJsonldMailSend
     */
    'tos': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MailJsonldMailSend
     */
    'ccs': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MailJsonldMailSend
     */
    'bccs': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MailJsonldMailSend
     */
    'replies': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof MailJsonldMailSend
     */
    'subject': string | null;
    /**
     * 
     * @type {string}
     * @memberof MailJsonldMailSend
     */
    'body': string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MailJsonldMailSend
     */
    'tags': Array<string>;
}
/**
 * 
 * @export
 * @interface MailMailRead
 */
export interface MailMailRead {
    /**
     * 
     * @type {string}
     * @memberof MailMailRead
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MailMailRead
     */
    'from': string;
    /**
     * 
     * @type {string}
     * @memberof MailMailRead
     */
    'fromName'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MailMailRead
     */
    'tos': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MailMailRead
     */
    'ccs': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MailMailRead
     */
    'bccs': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MailMailRead
     */
    'replies': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof MailMailRead
     */
    'subject': string | null;
    /**
     * 
     * @type {string}
     * @memberof MailMailRead
     */
    'body': string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MailMailRead
     */
    'tags': Array<string>;
    /**
     * 
     * @type {Array<MailAttachmentMailRead>}
     * @memberof MailMailRead
     */
    'attachments'?: Array<MailAttachmentMailRead>;
}
/**
 * 
 * @export
 * @interface MailMailSend
 */
export interface MailMailSend {
    /**
     * 
     * @type {string}
     * @memberof MailMailSend
     */
    'from': string;
    /**
     * 
     * @type {string}
     * @memberof MailMailSend
     */
    'fromName'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MailMailSend
     */
    'tos': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MailMailSend
     */
    'ccs': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MailMailSend
     */
    'bccs': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MailMailSend
     */
    'replies': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof MailMailSend
     */
    'subject': string | null;
    /**
     * 
     * @type {string}
     * @memberof MailMailSend
     */
    'body': string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MailMailSend
     */
    'tags': Array<string>;
}
/**
 * 
 * @export
 * @interface PostAttachmentCollection401Response
 */
export interface PostAttachmentCollection401Response {
    /**
     * 
     * @type {string}
     * @memberof PostAttachmentCollection401Response
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface PostAttachmentCollection403Response
 */
export interface PostAttachmentCollection403Response {
    /**
     * 
     * @type {string}
     * @memberof PostAttachmentCollection403Response
     */
    'error'?: string;
    /**
     * 
     * @type {PostAttachmentCollection403ResponseException}
     * @memberof PostAttachmentCollection403Response
     */
    'exception'?: PostAttachmentCollection403ResponseException;
}
/**
 * 
 * @export
 * @interface PostAttachmentCollection403ResponseException
 */
export interface PostAttachmentCollection403ResponseException {
    /**
     * 
     * @type {string}
     * @memberof PostAttachmentCollection403ResponseException
     */
    'message'?: string;
    /**
     * 
     * @type {number}
     * @memberof PostAttachmentCollection403ResponseException
     */
    'code'?: number;
}
/**
 * 
 * @export
 * @interface RenderTemplateMailCollection200Response
 */
export interface RenderTemplateMailCollection200Response {
    /**
     * 
     * @type {Array<MailJsonldMailRead>}
     * @memberof RenderTemplateMailCollection200Response
     */
    'hydra:member': Array<MailJsonldMailRead>;
    /**
     * 
     * @type {number}
     * @memberof RenderTemplateMailCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {RenderTemplateMailCollection200ResponseHydraView}
     * @memberof RenderTemplateMailCollection200Response
     */
    'hydra:view'?: RenderTemplateMailCollection200ResponseHydraView;
    /**
     * 
     * @type {RenderTemplateMailCollection200ResponseHydraSearch}
     * @memberof RenderTemplateMailCollection200Response
     */
    'hydra:search'?: RenderTemplateMailCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface RenderTemplateMailCollection200ResponseHydraSearch
 */
export interface RenderTemplateMailCollection200ResponseHydraSearch {
    /**
     * 
     * @type {string}
     * @memberof RenderTemplateMailCollection200ResponseHydraSearch
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof RenderTemplateMailCollection200ResponseHydraSearch
     */
    'hydra:template'?: string;
    /**
     * 
     * @type {string}
     * @memberof RenderTemplateMailCollection200ResponseHydraSearch
     */
    'hydra:variableRepresentation'?: string;
    /**
     * 
     * @type {Array<RenderTemplateMailCollection200ResponseHydraSearchHydraMappingInner>}
     * @memberof RenderTemplateMailCollection200ResponseHydraSearch
     */
    'hydra:mapping'?: Array<RenderTemplateMailCollection200ResponseHydraSearchHydraMappingInner>;
}
/**
 * 
 * @export
 * @interface RenderTemplateMailCollection200ResponseHydraSearchHydraMappingInner
 */
export interface RenderTemplateMailCollection200ResponseHydraSearchHydraMappingInner {
    /**
     * 
     * @type {string}
     * @memberof RenderTemplateMailCollection200ResponseHydraSearchHydraMappingInner
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof RenderTemplateMailCollection200ResponseHydraSearchHydraMappingInner
     */
    'variable'?: string;
    /**
     * 
     * @type {string}
     * @memberof RenderTemplateMailCollection200ResponseHydraSearchHydraMappingInner
     */
    'property'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RenderTemplateMailCollection200ResponseHydraSearchHydraMappingInner
     */
    'required'?: boolean;
}
/**
 * 
 * @export
 * @interface RenderTemplateMailCollection200ResponseHydraView
 */
export interface RenderTemplateMailCollection200ResponseHydraView {
    /**
     * 
     * @type {string}
     * @memberof RenderTemplateMailCollection200ResponseHydraView
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RenderTemplateMailCollection200ResponseHydraView
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof RenderTemplateMailCollection200ResponseHydraView
     */
    'hydra:first'?: string;
    /**
     * 
     * @type {string}
     * @memberof RenderTemplateMailCollection200ResponseHydraView
     */
    'hydra:last'?: string;
    /**
     * 
     * @type {string}
     * @memberof RenderTemplateMailCollection200ResponseHydraView
     */
    'hydra:previous'?: string;
    /**
     * 
     * @type {string}
     * @memberof RenderTemplateMailCollection200ResponseHydraView
     */
    'hydra:next'?: string;
}

/**
 * AttachmentApi - axios parameter creator
 * @export
 */
export const AttachmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Removes the Attachment resource.
         * @summary Removes the Attachment resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAttachmentItem: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteAttachmentItem', 'id', id)
            const localVarPath = `/mail/attachments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Attachment resource.
         * @summary Retrieves a Attachment resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAttachmentItem: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAttachmentItem', 'id', id)
            const localVarPath = `/mail/attachments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the Attachment resource.
         * @summary Updates the Attachment resource.
         * @param {string} id Resource identifier
         * @param {Attachment} attachment The updated Attachment resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchAttachmentItem: async (id: string, attachment: Attachment, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchAttachmentItem', 'id', id)
            // verify required parameter 'attachment' is not null or undefined
            assertParamExists('patchAttachmentItem', 'attachment', attachment)
            const localVarPath = `/mail/attachments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(attachment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Stocker une pièce jointe
         * @summary Stocker une pièce jointe
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAttachmentCollection: async (file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/mail/attachments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces the Attachment resource.
         * @summary Replaces the Attachment resource.
         * @param {string} id Resource identifier
         * @param {AttachmentJsonld} attachmentJsonld The updated Attachment resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAttachmentItem: async (id: string, attachmentJsonld: AttachmentJsonld, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putAttachmentItem', 'id', id)
            // verify required parameter 'attachmentJsonld' is not null or undefined
            assertParamExists('putAttachmentItem', 'attachmentJsonld', attachmentJsonld)
            const localVarPath = `/mail/attachments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(attachmentJsonld, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AttachmentApi - functional programming interface
 * @export
 */
export const AttachmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AttachmentApiAxiosParamCreator(configuration)
    return {
        /**
         * Removes the Attachment resource.
         * @summary Removes the Attachment resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAttachmentItem(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAttachmentItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Attachment resource.
         * @summary Retrieves a Attachment resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAttachmentItem(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AttachmentJsonldAttachmentGroupTimestampableBlameambleGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAttachmentItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the Attachment resource.
         * @summary Updates the Attachment resource.
         * @param {string} id Resource identifier
         * @param {Attachment} attachment The updated Attachment resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchAttachmentItem(id: string, attachment: Attachment, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AttachmentJsonldAttachmentGroupTimestampableBlameambleGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchAttachmentItem(id, attachment, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Stocker une pièce jointe
         * @summary Stocker une pièce jointe
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAttachmentCollection(file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AttachmentJsonldAttachmentGroupTimestampableBlameambleGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAttachmentCollection(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces the Attachment resource.
         * @summary Replaces the Attachment resource.
         * @param {string} id Resource identifier
         * @param {AttachmentJsonld} attachmentJsonld The updated Attachment resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putAttachmentItem(id: string, attachmentJsonld: AttachmentJsonld, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AttachmentJsonldAttachmentGroupTimestampableBlameambleGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putAttachmentItem(id, attachmentJsonld, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AttachmentApi - factory interface
 * @export
 */
export const AttachmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AttachmentApiFp(configuration)
    return {
        /**
         * Removes the Attachment resource.
         * @summary Removes the Attachment resource.
         * @param {AttachmentApiDeleteAttachmentItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAttachmentItem(requestParameters: AttachmentApiDeleteAttachmentItemRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteAttachmentItem(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Attachment resource.
         * @summary Retrieves a Attachment resource.
         * @param {AttachmentApiGetAttachmentItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAttachmentItem(requestParameters: AttachmentApiGetAttachmentItemRequest, options?: AxiosRequestConfig): AxiosPromise<AttachmentJsonldAttachmentGroupTimestampableBlameambleGroup> {
            return localVarFp.getAttachmentItem(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the Attachment resource.
         * @summary Updates the Attachment resource.
         * @param {AttachmentApiPatchAttachmentItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchAttachmentItem(requestParameters: AttachmentApiPatchAttachmentItemRequest, options?: AxiosRequestConfig): AxiosPromise<AttachmentJsonldAttachmentGroupTimestampableBlameambleGroup> {
            return localVarFp.patchAttachmentItem(requestParameters.id, requestParameters.attachment, options).then((request) => request(axios, basePath));
        },
        /**
         * Stocker une pièce jointe
         * @summary Stocker une pièce jointe
         * @param {AttachmentApiPostAttachmentCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAttachmentCollection(requestParameters: AttachmentApiPostAttachmentCollectionRequest = {}, options?: AxiosRequestConfig): AxiosPromise<AttachmentJsonldAttachmentGroupTimestampableBlameambleGroup> {
            return localVarFp.postAttachmentCollection(requestParameters.file, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces the Attachment resource.
         * @summary Replaces the Attachment resource.
         * @param {AttachmentApiPutAttachmentItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAttachmentItem(requestParameters: AttachmentApiPutAttachmentItemRequest, options?: AxiosRequestConfig): AxiosPromise<AttachmentJsonldAttachmentGroupTimestampableBlameambleGroup> {
            return localVarFp.putAttachmentItem(requestParameters.id, requestParameters.attachmentJsonld, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for deleteAttachmentItem operation in AttachmentApi.
 * @export
 * @interface AttachmentApiDeleteAttachmentItemRequest
 */
export interface AttachmentApiDeleteAttachmentItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof AttachmentApiDeleteAttachmentItem
     */
    readonly id: string
}

/**
 * Request parameters for getAttachmentItem operation in AttachmentApi.
 * @export
 * @interface AttachmentApiGetAttachmentItemRequest
 */
export interface AttachmentApiGetAttachmentItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof AttachmentApiGetAttachmentItem
     */
    readonly id: string
}

/**
 * Request parameters for patchAttachmentItem operation in AttachmentApi.
 * @export
 * @interface AttachmentApiPatchAttachmentItemRequest
 */
export interface AttachmentApiPatchAttachmentItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof AttachmentApiPatchAttachmentItem
     */
    readonly id: string

    /**
     * The updated Attachment resource
     * @type {Attachment}
     * @memberof AttachmentApiPatchAttachmentItem
     */
    readonly attachment: Attachment
}

/**
 * Request parameters for postAttachmentCollection operation in AttachmentApi.
 * @export
 * @interface AttachmentApiPostAttachmentCollectionRequest
 */
export interface AttachmentApiPostAttachmentCollectionRequest {
    /**
     * 
     * @type {File}
     * @memberof AttachmentApiPostAttachmentCollection
     */
    readonly file?: File
}

/**
 * Request parameters for putAttachmentItem operation in AttachmentApi.
 * @export
 * @interface AttachmentApiPutAttachmentItemRequest
 */
export interface AttachmentApiPutAttachmentItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof AttachmentApiPutAttachmentItem
     */
    readonly id: string

    /**
     * The updated Attachment resource
     * @type {AttachmentJsonld}
     * @memberof AttachmentApiPutAttachmentItem
     */
    readonly attachmentJsonld: AttachmentJsonld
}

/**
 * AttachmentApi - object-oriented interface
 * @export
 * @class AttachmentApi
 * @extends {BaseAPI}
 */
export class AttachmentApi extends BaseAPI {
    /**
     * Removes the Attachment resource.
     * @summary Removes the Attachment resource.
     * @param {AttachmentApiDeleteAttachmentItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttachmentApi
     */
    public deleteAttachmentItem(requestParameters: AttachmentApiDeleteAttachmentItemRequest, options?: AxiosRequestConfig) {
        return AttachmentApiFp(this.configuration).deleteAttachmentItem(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Attachment resource.
     * @summary Retrieves a Attachment resource.
     * @param {AttachmentApiGetAttachmentItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttachmentApi
     */
    public getAttachmentItem(requestParameters: AttachmentApiGetAttachmentItemRequest, options?: AxiosRequestConfig) {
        return AttachmentApiFp(this.configuration).getAttachmentItem(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the Attachment resource.
     * @summary Updates the Attachment resource.
     * @param {AttachmentApiPatchAttachmentItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttachmentApi
     */
    public patchAttachmentItem(requestParameters: AttachmentApiPatchAttachmentItemRequest, options?: AxiosRequestConfig) {
        return AttachmentApiFp(this.configuration).patchAttachmentItem(requestParameters.id, requestParameters.attachment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Stocker une pièce jointe
     * @summary Stocker une pièce jointe
     * @param {AttachmentApiPostAttachmentCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttachmentApi
     */
    public postAttachmentCollection(requestParameters: AttachmentApiPostAttachmentCollectionRequest = {}, options?: AxiosRequestConfig) {
        return AttachmentApiFp(this.configuration).postAttachmentCollection(requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces the Attachment resource.
     * @summary Replaces the Attachment resource.
     * @param {AttachmentApiPutAttachmentItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttachmentApi
     */
    public putAttachmentItem(requestParameters: AttachmentApiPutAttachmentItemRequest, options?: AxiosRequestConfig) {
        return AttachmentApiFp(this.configuration).putAttachmentItem(requestParameters.id, requestParameters.attachmentJsonld, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MailApi - axios parameter creator
 * @export
 */
export const MailApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Removes the Mail resource.
         * @summary Removes the Mail resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMailItem: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteMailItem', 'id', id)
            const localVarPath = `/mail/mails/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Mail resource.
         * @summary Retrieves a Mail resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMailItem: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getMailItem', 'id', id)
            const localVarPath = `/mail/mails/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the Mail resource.
         * @summary Updates the Mail resource.
         * @param {string} id Resource identifier
         * @param {MailMailSend} mailMailSend The updated Mail resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchMailItem: async (id: string, mailMailSend: MailMailSend, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchMailItem', 'id', id)
            // verify required parameter 'mailMailSend' is not null or undefined
            assertParamExists('patchMailItem', 'mailMailSend', mailMailSend)
            const localVarPath = `/mail/mails/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mailMailSend, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a Mail resource.
         * @summary Send a synchronous e-mail
         * @param {MailMailSend} [details] 
         * @param {Array<File>} [attachments] Liste des pièces jointes, il faut les nommer attachments0, attachments1, etc.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postMailMailCollection: async (details?: MailMailSend, attachments?: Array<File>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/mail/send-mail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


            if (details !== undefined) { 
                localVarFormParams.append('details', new Blob([JSON.stringify(details)], { type: "application/json", }));
            }
                if (attachments) {
                attachments.forEach((element) => {
                    localVarFormParams.append('attachments', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces the Mail resource.
         * @summary Replaces the Mail resource.
         * @param {string} id Resource identifier
         * @param {MailJsonldMailSend} mailJsonldMailSend The updated Mail resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putMailItem: async (id: string, mailJsonldMailSend: MailJsonldMailSend, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putMailItem', 'id', id)
            // verify required parameter 'mailJsonldMailSend' is not null or undefined
            assertParamExists('putMailItem', 'mailJsonldMailSend', mailJsonldMailSend)
            const localVarPath = `/mail/mails/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mailJsonldMailSend, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Click the link to view the rendered HTML template in a new browser window.
         * @summary Render a Twig template
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renderTemplateMailCollection: async (page?: number, itemsPerPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/mail/render-template`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MailApi - functional programming interface
 * @export
 */
export const MailApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MailApiAxiosParamCreator(configuration)
    return {
        /**
         * Removes the Mail resource.
         * @summary Removes the Mail resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMailItem(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMailItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Mail resource.
         * @summary Retrieves a Mail resource.
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMailItem(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MailJsonldMailRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMailItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the Mail resource.
         * @summary Updates the Mail resource.
         * @param {string} id Resource identifier
         * @param {MailMailSend} mailMailSend The updated Mail resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchMailItem(id: string, mailMailSend: MailMailSend, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MailJsonldMailRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchMailItem(id, mailMailSend, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a Mail resource.
         * @summary Send a synchronous e-mail
         * @param {MailMailSend} [details] 
         * @param {Array<File>} [attachments] Liste des pièces jointes, il faut les nommer attachments0, attachments1, etc.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postMailMailCollection(details?: MailMailSend, attachments?: Array<File>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MailJsonldMailRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postMailMailCollection(details, attachments, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces the Mail resource.
         * @summary Replaces the Mail resource.
         * @param {string} id Resource identifier
         * @param {MailJsonldMailSend} mailJsonldMailSend The updated Mail resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putMailItem(id: string, mailJsonldMailSend: MailJsonldMailSend, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MailJsonldMailRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putMailItem(id, mailJsonldMailSend, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Click the link to view the rendered HTML template in a new browser window.
         * @summary Render a Twig template
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async renderTemplateMailCollection(page?: number, itemsPerPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RenderTemplateMailCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.renderTemplateMailCollection(page, itemsPerPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MailApi - factory interface
 * @export
 */
export const MailApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MailApiFp(configuration)
    return {
        /**
         * Removes the Mail resource.
         * @summary Removes the Mail resource.
         * @param {MailApiDeleteMailItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMailItem(requestParameters: MailApiDeleteMailItemRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteMailItem(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Mail resource.
         * @summary Retrieves a Mail resource.
         * @param {MailApiGetMailItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMailItem(requestParameters: MailApiGetMailItemRequest, options?: AxiosRequestConfig): AxiosPromise<MailJsonldMailRead> {
            return localVarFp.getMailItem(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the Mail resource.
         * @summary Updates the Mail resource.
         * @param {MailApiPatchMailItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchMailItem(requestParameters: MailApiPatchMailItemRequest, options?: AxiosRequestConfig): AxiosPromise<MailJsonldMailRead> {
            return localVarFp.patchMailItem(requestParameters.id, requestParameters.mailMailSend, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a Mail resource.
         * @summary Send a synchronous e-mail
         * @param {MailApiPostMailMailCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postMailMailCollection(requestParameters: MailApiPostMailMailCollectionRequest = {}, options?: AxiosRequestConfig): AxiosPromise<MailJsonldMailRead> {
            return localVarFp.postMailMailCollection(requestParameters.details, requestParameters.attachments, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces the Mail resource.
         * @summary Replaces the Mail resource.
         * @param {MailApiPutMailItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putMailItem(requestParameters: MailApiPutMailItemRequest, options?: AxiosRequestConfig): AxiosPromise<MailJsonldMailRead> {
            return localVarFp.putMailItem(requestParameters.id, requestParameters.mailJsonldMailSend, options).then((request) => request(axios, basePath));
        },
        /**
         * Click the link to view the rendered HTML template in a new browser window.
         * @summary Render a Twig template
         * @param {MailApiRenderTemplateMailCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renderTemplateMailCollection(requestParameters: MailApiRenderTemplateMailCollectionRequest = {}, options?: AxiosRequestConfig): AxiosPromise<RenderTemplateMailCollection200Response> {
            return localVarFp.renderTemplateMailCollection(requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for deleteMailItem operation in MailApi.
 * @export
 * @interface MailApiDeleteMailItemRequest
 */
export interface MailApiDeleteMailItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof MailApiDeleteMailItem
     */
    readonly id: string
}

/**
 * Request parameters for getMailItem operation in MailApi.
 * @export
 * @interface MailApiGetMailItemRequest
 */
export interface MailApiGetMailItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof MailApiGetMailItem
     */
    readonly id: string
}

/**
 * Request parameters for patchMailItem operation in MailApi.
 * @export
 * @interface MailApiPatchMailItemRequest
 */
export interface MailApiPatchMailItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof MailApiPatchMailItem
     */
    readonly id: string

    /**
     * The updated Mail resource
     * @type {MailMailSend}
     * @memberof MailApiPatchMailItem
     */
    readonly mailMailSend: MailMailSend
}

/**
 * Request parameters for postMailMailCollection operation in MailApi.
 * @export
 * @interface MailApiPostMailMailCollectionRequest
 */
export interface MailApiPostMailMailCollectionRequest {
    /**
     * 
     * @type {MailMailSend}
     * @memberof MailApiPostMailMailCollection
     */
    readonly details?: MailMailSend

    /**
     * Liste des pièces jointes, il faut les nommer attachments0, attachments1, etc.
     * @type {Array<File>}
     * @memberof MailApiPostMailMailCollection
     */
    readonly attachments?: Array<File>
}

/**
 * Request parameters for putMailItem operation in MailApi.
 * @export
 * @interface MailApiPutMailItemRequest
 */
export interface MailApiPutMailItemRequest {
    /**
     * Resource identifier
     * @type {string}
     * @memberof MailApiPutMailItem
     */
    readonly id: string

    /**
     * The updated Mail resource
     * @type {MailJsonldMailSend}
     * @memberof MailApiPutMailItem
     */
    readonly mailJsonldMailSend: MailJsonldMailSend
}

/**
 * Request parameters for renderTemplateMailCollection operation in MailApi.
 * @export
 * @interface MailApiRenderTemplateMailCollectionRequest
 */
export interface MailApiRenderTemplateMailCollectionRequest {
    /**
     * The collection page number
     * @type {number}
     * @memberof MailApiRenderTemplateMailCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof MailApiRenderTemplateMailCollection
     */
    readonly itemsPerPage?: number
}

/**
 * MailApi - object-oriented interface
 * @export
 * @class MailApi
 * @extends {BaseAPI}
 */
export class MailApi extends BaseAPI {
    /**
     * Removes the Mail resource.
     * @summary Removes the Mail resource.
     * @param {MailApiDeleteMailItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApi
     */
    public deleteMailItem(requestParameters: MailApiDeleteMailItemRequest, options?: AxiosRequestConfig) {
        return MailApiFp(this.configuration).deleteMailItem(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Mail resource.
     * @summary Retrieves a Mail resource.
     * @param {MailApiGetMailItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApi
     */
    public getMailItem(requestParameters: MailApiGetMailItemRequest, options?: AxiosRequestConfig) {
        return MailApiFp(this.configuration).getMailItem(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the Mail resource.
     * @summary Updates the Mail resource.
     * @param {MailApiPatchMailItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApi
     */
    public patchMailItem(requestParameters: MailApiPatchMailItemRequest, options?: AxiosRequestConfig) {
        return MailApiFp(this.configuration).patchMailItem(requestParameters.id, requestParameters.mailMailSend, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a Mail resource.
     * @summary Send a synchronous e-mail
     * @param {MailApiPostMailMailCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApi
     */
    public postMailMailCollection(requestParameters: MailApiPostMailMailCollectionRequest = {}, options?: AxiosRequestConfig) {
        return MailApiFp(this.configuration).postMailMailCollection(requestParameters.details, requestParameters.attachments, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces the Mail resource.
     * @summary Replaces the Mail resource.
     * @param {MailApiPutMailItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApi
     */
    public putMailItem(requestParameters: MailApiPutMailItemRequest, options?: AxiosRequestConfig) {
        return MailApiFp(this.configuration).putMailItem(requestParameters.id, requestParameters.mailJsonldMailSend, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Click the link to view the rendered HTML template in a new browser window.
     * @summary Render a Twig template
     * @param {MailApiRenderTemplateMailCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApi
     */
    public renderTemplateMailCollection(requestParameters: MailApiRenderTemplateMailCollectionRequest = {}, options?: AxiosRequestConfig) {
        return MailApiFp(this.configuration).renderTemplateMailCollection(requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }
}


