import { InformationCard, InformationCardProps } from '@europrocurement/flexy-components';
import { ChampComplMentaireJsonldChampcomplementaireRead } from '@europrocurement/l2d-domain/openApi/ApiOffre';
import * as React from 'react';
import { JsonLdData } from '../forms/types';
import { getHtmlFieldTypeLabel } from '../forms/htmlFields';
import { EntityPreview } from '../components/types';

type EntityType = JsonLdData<ChampComplMentaireJsonldChampcomplementaireRead>;

const PreviewCard: React.FC<EntityPreview<EntityType>> = function (props) {
    const { entity, onEdit } = props;

    const cardTitle = entity.libelle;
    const cardItems: InformationCardProps['items'] = [
        {
            label: 'ID',
            value: entity.id?.toString() ?? null,
        },
        {
            label: 'Type de champ',
            value: getHtmlFieldTypeLabel(entity.htmlFieldType) ?? null,
        },
        {
            label: 'Description',
            value: entity.description ?? null,
        },
    ];

    return (
        <InformationCard
            title={cardTitle}
            items={cardItems}
            button={{
                label: 'Éditer',
                onClick: onEdit,
            }}
        />
    );
};

export default PreviewCard;
