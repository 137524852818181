import React from 'react';
import { SelectItems, SelectItemsProps } from '@europrocurement/flexy-components';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import { FormHelperText } from '@mui/material';

export type ControlledSelectItemsProps = {
    name: string;
    rules: RegisterOptions;
    label?: string;
} & Omit<SelectItemsProps, 'onChange'>;

const ControlledSelectItems: React.FunctionComponent<ControlledSelectItemsProps> = function ({
    name,
    rules,
    showMore,
    label,
    ...inputProps
}) {
    const { control } = useFormContext();

    return (
        <Controller
            control={control}
            name={name}
            rules={rules}
            render={({
                field: { onChange, value },
                fieldState: { error },
                // formState,
            }) => (
                <>
                    <SelectItems
                        onChange={onChange}
                        {...inputProps}
                        value={value}
                        showMore={showMore}
                        showMoreLabel={label}
                    />
                    {error ? (
                        <FormHelperText
                            sx={{
                                margin: '4px 14px 0px 14px',
                            }}
                            error
                        >
                            {error?.message}
                        </FormHelperText>
                    ) : null}
                </>
            )}
        />
    );
};

export default ControlledSelectItems;
