import * as React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FlexyHeaderForm } from '@europrocurement/flexy-components';

type ComponentProps = {
    title: string;
    component: React.ReactNode;
};

const AccordionCard: React.FC<ComponentProps> = function (props) {
    const { title, component } = props;

    const cloneCardStyle = {
        // Card style consistency,
        boxShadow: `0px 7px 30px 0px rgba(90, 114, 123, 0.11)`,
        padding: '10px',
        margin: '15px !important',
        borderRadius: '20px !important',
    };

    return (
        <Accordion
            sx={{
                ...cloneCardStyle,
                '&:before': {
                    backgroundColor: 'inherit',
                },
            }}
            defaultExpanded
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                sx={{
                    boxShadow: 'none',
                }}
            >
                <Box>
                    <FlexyHeaderForm
                        sx={{ margin: '0px' }}
                        label={title}
                        outlined
                    />
                </Box>
            </AccordionSummary>
            <AccordionDetails>{component}</AccordionDetails>
        </Accordion>
    );
};

export default AccordionCard;
