import { useEffect, useMemo, useRef } from 'react';

import { FactureFormReducerType } from '@b2d/redux/FactureFormReducer';
import { Dossier } from '@europrocurement/l2d-domain';
import { DataSource, getDataThunkType } from '@europrocurement/l2d-redux-utils';
import { HeaderSwitch } from '@europrocurement/flexy-components';
import { FormStructure } from '@europrocurement/flexy-form';
import { folioInvoiceSectionWithAdvancedResearchStructure } from '../fragments/folioInvoiceSection';
import { ModeProps, SectionHeadControlProps } from '../types';

export type UseFolioPublicationSectionStructureProps = {
    dossiersDataSource: DataSource<Dossier>;
    dossierFetchData: getDataThunkType<Dossier>;
    mode: ModeProps;
    headerSwitches?: Array<HeaderSwitch>;
    headerChildren?: Array<SectionHeadControlProps> | SectionHeadControlProps;
    searchByNumeroPresta: getDataThunkType<Dossier>;
    state: FactureFormReducerType;
    handleClickModalSearchDossierAnnonce: () => void;
};

const useFolioPublicationSectionStructure = ({
    dossiersDataSource,
    dossierFetchData,
    mode,
    headerSwitches,
    headerChildren,
    searchByNumeroPresta,
    state,
    handleClickModalSearchDossierAnnonce,
}: UseFolioPublicationSectionStructureProps) => {
    const prevDossiersDataSourceRef = useRef(dossiersDataSource);

    const folioPublicationStructure = useMemo<Array<FormStructure>>(
        () =>
            folioInvoiceSectionWithAdvancedResearchStructure({
                onClickModal: handleClickModalSearchDossierAnnonce,
                autocompleteProps: {
                    folio: {
                        dataSource: dossiersDataSource,
                        fetchData: dossierFetchData,
                        defaultValue: state.defaultNumeroDossier,
                    },
                    provision: {
                        dataSource: dossiersDataSource,
                        fetchData: searchByNumeroPresta,
                        defaultValue: state.defaultNumeroPresta,
                    },
                },
                headerSwitches,
                headerChildren,
                mode,
            }),
        [
            dossierFetchData,
            dossiersDataSource,
            handleClickModalSearchDossierAnnonce,
            headerChildren,
            headerSwitches,
            mode,
            searchByNumeroPresta,
            state.defaultNumeroDossier,
            state.defaultNumeroPresta,
        ],
    );

    useEffect(() => {
        prevDossiersDataSourceRef.current = dossiersDataSource;
    }, [dossiersDataSource]);

    return {
        folioPublicationStructure,
    };
};

export default useFolioPublicationSectionStructure;
