import { FaOptionIcon } from '@europrocurement/l2d-icons';
import { Button } from '@mui/material';
import { Box } from '@mui/system';
import React, { FC } from 'react';

const FontAwesomeDocLink: FC = function () {
    return (
        <Button
            sx={{ marginRight: '10px' }}
            variant="outlined"
            target="_blank"
            href="https://fontawesome.com/v6/search"
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Box sx={{ marginTop: '5px' }}>
                    <FaOptionIcon icon="circle-info" />
                </Box>

                <Box sx={{ marginTop: '5px' }}>
                    <FaOptionIcon icon="arrow-right" />
                </Box>
                <span>Liste des icônes disponibles / lien vers la documentation Font awesome</span>
            </Box>
        </Button>
    );
};

export default FontAwesomeDocLink;
