import * as React from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import ArticlesModule from '../Components/ArticlesModule';
import { articlesStepSchema } from './validation';
import useStepForm from '../../components/form/MultiStepForm/useStepForm';
import { MultiStepContextProps } from '../../components/form/MultiStepForm/types';

/** Form second step : Articles | Content of package */
const ArticleStep: React.FC<MultiStepContextProps> = function (props) {
    // Props
    const { multiStepForm } = props;
    const { setCurrentFormContext, formValues } = multiStepForm;

    // Form configuration
    const formContext = useForm({
        mode: 'onBlur',
        defaultValues: { ...formValues },
        resolver: zodResolver(articlesStepSchema),
    });

    const { isStepValidating } = useStepForm(formContext, multiStepForm);

    React.useEffect(() => {
        setCurrentFormContext(formContext);
    }, [formContext, setCurrentFormContext]);

    return (
        <form noValidate>
            <ArticlesModule
                formContext={formContext}
                isStepValidating={isStepValidating}
            />
        </form>
    );
};

export default ArticleStep;
