import React, { FunctionComponent, ReactNode, useMemo } from 'react';

import { Box, Skeleton } from '@mui/material';

export type InvoiceFormFragmentSkeletonProps = {
    fragmentOnlyRender?: boolean;
    name?: string;
    sectionsCount?: number;
};

const InvoiceFormFragmentSkeleton: FunctionComponent<InvoiceFormFragmentSkeletonProps> = function ({
    fragmentOnlyRender = true,
    name = 'section',
    sectionsCount = 1,
}: InvoiceFormFragmentSkeletonProps) {
    const content = useMemo<ReactNode>(
        () => (
            <>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Skeleton
                        variant="text"
                        width="20%"
                        height={48}
                    />
                    <Skeleton
                        variant="text"
                        width="10%"
                        height={32}
                    />
                </Box>
                <Skeleton
                    variant="rounded"
                    width="100%"
                    height={`${(name.length * 150) / sectionsCount}%`}
                />
            </>
        ),
        [name.length, sectionsCount],
    );

    if (fragmentOnlyRender) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'start',
                    width: '100%',
                    height: '100%',
                }}
            >
                {content}
            </Box>
        );
    }

    return content;
};

export default InvoiceFormFragmentSkeleton;
