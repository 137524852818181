import * as React from 'react';
import { AxiosError } from 'axios';
import { prescribersApi } from '@b2d/redux/RootStore';
import { ConfigurationCard, relSociete } from '@europrocurement/flexy-components';
import { Grid } from '@mui/material';
import { useSnackbar } from 'notistack';
import { usePrescriberUtils } from '@b2d/hooks/prescriberHooks';
import {
    getPrescriberRightsPayload,
    getPrescripteurCompanyRelationPayload,
} from '../../forms/payloads';

const ConfigurationRow: React.FunctionComponent = function () {
    const {
        methods: { reloadPrescriber },
        prescriber,
        companyRelation,
        companyId,
    } = usePrescriberUtils();

    const { enqueueSnackbar } = useSnackbar();

    const getFormField = (
        // Usage : updatePrescripteurRights
        fieldName: keyof { plateformeB2BAutorise: string },
    ): string | null => {
        const fieldsMapping = {
            plateformeB2BAutorise: 'accesPlateformeB2B',
        };

        return fieldsMapping[fieldName] ?? null;
    };

    const updatePrescripteurRights = async (field: 'plateformeB2BAutorise', newValue: boolean) => {
        if (!prescriber.id || !companyRelation) {
            return;
        }

        const baseFormData = getPrescriberRightsPayload(companyRelation);

        const fieldName = getFormField(field);

        if (!fieldName) {
            return;
        }

        const updatedForm = {
            ...baseFormData,
            [fieldName]: newValue,
        };

        await prescribersApi
            .updateDroitsPrescripteurTiersItem({
                id: prescriber.id.toString(),
                updateDroitsPrescripteurTiersItemRequest: updatedForm,
                xIdSociete: companyId,
            })
            .catch((error: AxiosError) => {
                if (error?.response?.status && error.response.status === 404) {
                    enqueueSnackbar('Utilisateur non trouvé', { variant: 'error' });
                }
            });

        reloadPrescriber();
    };

    const updatePrescripteurCompanyRelationField = async (field: string, newValue: boolean) => {
        if (!prescriber.id || !companyId || !companyRelation) {
            return;
        }

        const baseFormData = getPrescripteurCompanyRelationPayload(companyRelation);

        const updatedForm = {
            ...baseFormData,
            [field]: newValue,
        };
        if (!updatedForm.portefeuille) {
            throw new Error('Donnée protefeuil manquante');
        }
        if (!updatedForm.categorieSocioProfessionnelle) {
            throw new Error('Donnée categorie socioprofessionnelle manquante');
        }
        if (!updatedForm.categorieSocioProfessionnelle.id) {
            throw new Error('Donnée categorie socioprofessionnelle manquante');
        }
        console.log({
            tiers: prescriber.id,
            xIdSociete: companyId,
            relationSocieteTiersRelationSocieteTiersJsonldPrescripteurWriteRelationSociete:
                //  {
                //     categorieSocioProfessionnelle: {
                //         id: updatedForm.categorieSocioProfessionnelle.id,
                //     },
                // },
                updatedForm,
            societe: companyId.toString(),
        });
        await prescribersApi.updateRelationSocietePrescripteurRelationSocieteTiersItem({
            tiers: prescriber.id,
            xIdSociete: companyId,
            relationSocieteTiersRelationSocieteTiersJsonldPrescripteurWriteRelationSociete:
                //  {
                //     categorieSocioProfessionnelle: {
                //         id: updatedForm.categorieSocioProfessionnelle.id,
                //     },
                // },
                updatedForm,
            societe: companyId.toString(),
        });
        reloadPrescriber();

        /* ,
                relationSocieteTiersRelationSocieteTiersJsonldPrescripteurWriteRelationSociete:
                    payload, */
    };

    const changePrescripteurConfigurationAction = (field: keyof relSociete, newValue: boolean) => {
        switch (field) {
            case 'plateformeB2BAutorise':
                return updatePrescripteurRights(field, newValue);
            case 'clientSuivi':
            case 'clientXl':
            case 'devisDetaille':
                return updatePrescripteurCompanyRelationField(field, newValue);
            default:
                throw new Error(`error field to change not found ${field}`);
        }
    };

    return (
        <Grid
            item
            xs={12}
        >
            {companyRelation && (
                <ConfigurationCard
                    companyRelation={companyRelation}
                    changeConfiguration={changePrescripteurConfigurationAction}
                />
            )}
        </Grid>
    );
};

export default ConfigurationRow;
