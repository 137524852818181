import {
    SliceFactory,
    FetchCollectionData,
    FetchItemDataType,
    ApiCollectionResponse,
} from '@europrocurement/l2d-redux-utils';
import {
    ConfigurationParameters as OffreConfigurationParameters,
    Configuration,
    GroupementApi,
    GroupementGroupementRead,
} from '../../../openApi/ApiOffre';
import { OFFRE_REDUCER_NAME } from '../constants';

export type OfferGroupApiObject = GroupementGroupementRead & Record<string, unknown>;

export type OfferGroup = OfferGroupApiObject;

export const OFFER_GROUP_SLICE_NAME = 'offer_group';

export const mapperOfferGroups = function (offerGroup: OfferGroupApiObject) {
    return {
        ...offerGroup,
    } as OfferGroup;
};

export function createOfferGroupSlice(configuration: OffreConfigurationParameters) {
    const conf = new Configuration(configuration);
    const api = new GroupementApi(conf);

    const fetchOfferGroupsCollection: FetchCollectionData<OfferGroupApiObject> = ({
        pagination: { page, itemsPerPage },
        xIdSociete,
    }) =>
        api
            .apiGroupementsGetCollection({ page: page + 1, itemsPerPage, xIdSociete })
            .then((res) => res.data)
            .catch((error) => {
                console.error(error);
            }) as Promise<ApiCollectionResponse<OfferGroupApiObject>>;

    const fetchOfferGroupsItem: FetchItemDataType<OfferGroupApiObject> = ({
        idItem,
        // pagination,
        xIdSociete,
    }) =>
        api
            .apiGroupementsIdGet({ id: String(idItem), xIdSociete })
            .then((res) => res.data) as Promise<OfferGroupApiObject>;

    const { slice: offerGroupsSlice, dataSourcesThunks: offerGroupsDataSourcesThunks } =
        SliceFactory.createSlice<OfferGroupApiObject, OfferGroup>(
            OFFER_GROUP_SLICE_NAME,
            OFFRE_REDUCER_NAME,
            [],
            fetchOfferGroupsCollection,
            fetchOfferGroupsItem,
            mapperOfferGroups,
        );

    return { offerGroupsSlice, offerGroupsDataSourcesThunks };
}
