import React from 'react';

import { ColumnDatatable } from '@europrocurement/flexy-datatable';
import { MediaObject, MediaObjectApiObject } from '@europrocurement/l2d-domain';
import { getFromSpecifiedDataSourceThunks } from '@b2d/redux/RootStore';
import { DataSource } from '@europrocurement/l2d-redux-utils';
import RegisterButton from '../ActionButtons/RegisterButton';
import DownloadButton from '../ActionButtons/DownloadButton';
import DeletePdfButton from '../ActionButtons/DeletePdfButton';
import SwitchAssignedCompanyButton from '../ActionButtons/SwitchAssignedCompanyButton';

const columnItemActions = (
    getMediaObject: ReturnType<typeof getFromSpecifiedDataSourceThunks>,
    selectedDataSource?: MediaObjectApiObject,
    dataSource?: DataSource<MediaObjectApiObject>,
): ColumnDatatable<MediaObject> => ({
    label: 'Actions',
    onClickCell: () => {},
    render: (mediaObject: MediaObject) =>
        mediaObject.id ? (
            <>
                <RegisterButton
                    mediaObject={mediaObject}
                    getMediaObject={getMediaObject}
                    selectedDataSource={selectedDataSource}
                    dataSource={dataSource}
                />
                <DownloadButton mediaObject={mediaObject} />
                <DeletePdfButton
                    mediaObject={mediaObject}
                    getMediaObject={getMediaObject}
                />
                <SwitchAssignedCompanyButton
                    mediaObject={mediaObject}
                    getMediaObject={getMediaObject}
                />
            </>
        ) : null,
    isDisplayed: true,
});

export default columnItemActions;
