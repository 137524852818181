import { getApiConfiguration } from '@b2d/redux/utils';
import { APIS_ENUM } from '@europrocurement/l2d-domain';
import {
    ActionDetailApi,
    ArticlesApi,
    CatalogueApi,
    ChampComplmentaireApi,
    DomainesApi,
    GroupementApi,
    OffresApi,
    OptionsApi,
    OptionValueApi,
    OriginesApi,
    PackageApi,
    RubriqueFacturationApi,
    TypeDeDocumentApi,
    TypeTvaApi,
} from '@europrocurement/l2d-domain/openApi/ApiOffre';

export const apiConfiguration = getApiConfiguration(APIS_ENUM.OFFRE);

export const offersApi = new OffresApi(apiConfiguration);
export const actionsApi = new ActionDetailApi(apiConfiguration);
export const catalogsApi = new CatalogueApi(apiConfiguration);
export const articlesApi = new ArticlesApi(apiConfiguration);
export const domaineApi = new DomainesApi(apiConfiguration);
export const additionalFieldApi = new ChampComplmentaireApi(apiConfiguration);
export const actionDetailApi = new ActionDetailApi(apiConfiguration);
export const packageApi = new PackageApi(apiConfiguration);
export const originApi = new OriginesApi(apiConfiguration);
export const offerOptionsApi = new OptionsApi(apiConfiguration);
export const offerOptionValuesApi = new OptionValueApi(apiConfiguration);
export const groupementApi = new GroupementApi(apiConfiguration);
export const documentsApi = new TypeDeDocumentApi(apiConfiguration);
export const vatApi = new TypeTvaApi(apiConfiguration);
export const domainApi = new DomainesApi(apiConfiguration);
export const invoicingCategoryApi = new RubriqueFacturationApi(apiConfiguration);
