import { updateToken } from '@b2d/keycloak';
import {
    APIENV,
    DOSSIERS_FORMALITES_SLICE_NAME,
    CATEGORY_SLICE_NAME,
    FORMALITES_SLICE_NAME,
    FormaliteApi,
    FormaliteConfigurationParameters,
    FormalitesConfiguration,
    createCategoriesFormaliteReducer,
    createDossierFormaliteReducer,
    createFormaliteReducer,
    createLegalStatusReducer,
    LEGAL_STATUS_SLICE_NAME,
    getCustomiserState,
    createFormaliteDocumentReducer,
    FORMALITE_DOCUMENT_SLICE_NAME,
} from '@europrocurement/l2d-domain';
import { combineReducers } from '@reduxjs/toolkit';

/**
 * Configuation
 */
const configFormalites: FormaliteConfigurationParameters = {
    accessToken: updateToken,
    basePath: APIENV.apis.formalite[getCustomiserState().api.formalite],
};

export const formalitesConfiguration = new FormalitesConfiguration(configFormalites);

export const formaliteApi = new FormaliteApi(formalitesConfiguration);

export const { dossierFormaliteSlice, dossierFormaliteDataSourcesThunks } =
    createDossierFormaliteReducer(configFormalites);

export const { formaliteSlice, formaliteDataSourcesThunks } =
    createFormaliteReducer(configFormalites);

export const { categoryFormaliteSlice, categoryFormaliteDataSourcesThunks } =
    createCategoriesFormaliteReducer(configFormalites);

export const { legalStatusSlice, legalStatusDataSourcesThunks } =
    createLegalStatusReducer(configFormalites);

export const { formaliteDocumentSlice, formaliteDocumentDataSourcesThunks } =
    createFormaliteDocumentReducer(configFormalites);

export const combinedFormaliteReducer = combineReducers({
    [DOSSIERS_FORMALITES_SLICE_NAME]: dossierFormaliteSlice.reducer,
    [FORMALITES_SLICE_NAME]: formaliteSlice.reducer,
    [CATEGORY_SLICE_NAME]: categoryFormaliteSlice.reducer,
    [LEGAL_STATUS_SLICE_NAME]: legalStatusSlice.reducer,
    [FORMALITE_DOCUMENT_SLICE_NAME]: formaliteDocumentSlice.reducer,
});

export const getFormalites = formaliteDataSourcesThunks.main.getData;
export const selectFormality = formaliteDataSourcesThunks.main.getSelected;

export const getDossierFormalite = dossierFormaliteDataSourcesThunks.main.getData;
export const selectDossierFormalite = dossierFormaliteDataSourcesThunks.main.getSelected;
export const selectAutocompleteDossierFormalite =
    dossierFormaliteDataSourcesThunks.autocompleteDossiersFormalites.getSelected;
export const getAutocompleteDossierFormalite =
    dossierFormaliteDataSourcesThunks.autocompleteDossiersFormalites.getData;
