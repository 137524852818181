import { useCallback } from 'react';

import { AxiosResponse } from 'axios';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { customizerSelector, RootStateType } from '@b2d/redux/RootStore'; // Adjust the import as per your project structure
import {
    FactureFormObject,
    LigneProduit,
    TableauProduits,
} from '@b2d/pages/Achats/components/forms/types';
import { FactureAchatLigneJsonldFactureAchatRead } from '@europrocurement/l2d-domain/openApi/ApiAchats';
import models from '@b2d/pages/Achats/models';
import {
    useDeleteLigne,
    useSynchroG3Facture,
} from '@b2d/pages/Achats/components/forms/functions/dataHooks';
import apiLinesToFormProducts from '@b2d/pages/Achats/components/forms/functions/dataTransformers/apiToForm/apiLinesToFormProducts';
import hasProductChanged from '@b2d/pages/Achats/components/forms/functions/dataTransformers/formToApi/hasProductChanged';
import { Dossier, DossierFormalite, FactureAchatApiObject } from '@europrocurement/l2d-domain';
import formProductLineToApiProductLine from '@b2d/pages/Achats/components/forms/functions/dataTransformers/formToApi/formProductLineToApiProductLine';
import _ from 'lodash';
import { useVerbose } from '@europrocurement/flexy-components';
import useSectionTotals from './useSectionTotals';

export type UpdateProductsProps = {
    invoice: FactureAchatApiObject;
    lines: FactureAchatLigneJsonldFactureAchatRead[];
    newProducts: Pick<FactureFormObject, 'produits'>;
    onFetchNewLines: () => void;
};

type UpdateApiLinesArrayProps = {
    product: Partial<LigneProduit>;
    invoiceIri?: string;
};

/**
 * Custom hook to calculate and update invoice totals.
 */
const useSectionProducts = () => {
    const verbose = useVerbose();
    const { enqueueSnackbar } = useSnackbar();
    const { xIdSociete } = useSelector(customizerSelector);
    const { calculateLinesTotals } = useSectionTotals();
    const synchroG3Facture = useSynchroG3Facture();

    const folioSelected: Dossier | undefined = useSelector(
        (s: RootStateType) => s.dossiers.dos.main.selected,
        _.isEqual,
    );

    const folioFormalitySelected: DossierFormalite | undefined = useSelector(
        (s: RootStateType) => s.formalites.dosform.main.selected,
        _.isEqual,
    );

    const deleteLine = useDeleteLigne();

    const updateApiLinesArray = useCallback(
        ({ product, invoiceIri }: UpdateApiLinesArrayProps) => {
            const { idLigne, rubriqueFacturation, ht, tva, txtva } = product;

            if (idLigne) {
                return () =>
                    models.invoicePurchase.patchLine({
                        id: idLigne.toString(),
                        factureAchatLigneJsonldFactureAchatLigneWrite: {
                            details: [
                                {
                                    idRubriqueFacturation: rubriqueFacturation?.id,
                                    ht: ht?.toString(),
                                    tva: tva?.toString(),
                                    tauxTva:
                                        txtva?.value || txtva?.value === 0
                                            ? txtva.value * 100
                                            : undefined,
                                },
                            ],
                        },
                        xIdSociete,
                    });
            }

            if (invoiceIri) {
                return () =>
                    models.invoicePurchase.createLine({
                        factureAchatLigneJsonldFactureAchatLigneWriteFactureAchatLigneCreate:
                            formProductLineToApiProductLine({
                                formProductLine: product,
                                invoiceIri,
                                folioPublication: folioSelected,
                                folioFormality: folioFormalitySelected,
                            }),
                        xIdSociete,
                    });
            }

            verbose.log('Skipping incomplete product:', product);
            return undefined;
        },
        [xIdSociete, folioSelected, folioFormalitySelected, verbose],
    );

    type RemoveProductProps = {
        lines: Array<FactureAchatLigneJsonldFactureAchatRead>;
        productsArray: TableauProduits;
    };

    const removeProduct = useCallback(
        ({ lines, productsArray }: RemoveProductProps) => {
            const isProductRemove = lines.length > productsArray.length;

            if (!isProductRemove) return [];

            const linesIds = lines
                .map((line) => {
                    if (!line.id) return undefined;
                    return line.id.toString();
                })
                .filter((id) => id !== undefined);

            const productsIds = productsArray
                .map((product) => {
                    if (!product.idLigne) return undefined;
                    return product.idLigne.toString();
                })
                .filter((id) => id !== undefined);

            const removedIds = linesIds.filter((id) => !productsIds.includes(id));

            return removedIds.map(
                (id) => () =>
                    deleteLine({
                        idLigne: id,
                    }),
            );
        },
        [deleteLine],
    );

    type PatchProductProps = {
        invoice: FactureAchatApiObject;
        lines: Array<FactureAchatLigneJsonldFactureAchatRead>;
        productsArray: TableauProduits;
    };

    const patchProduct = useCallback(
        ({ invoice, lines, productsArray }: PatchProductProps) => {
            const originalProducts: TableauProduits = apiLinesToFormProducts(lines);

            return productsArray
                .map((product, index) => {
                    const originalProduct = originalProducts[index];

                    if (
                        (!originalProduct || hasProductChanged(originalProduct, product)) &&
                        product.tva !== undefined &&
                        product.ttc !== undefined
                    ) {
                        return updateApiLinesArray({
                            product,
                            invoiceIri: invoice['@id'],
                        });
                    }

                    verbose.log('No changes detected for product:', product);
                    return undefined;
                })
                .filter((productRequest) => productRequest !== undefined) as Array<
                () => Promise<AxiosResponse>
            >;
        },
        [updateApiLinesArray, verbose],
    );

    /**
     * Updates the products on the server.
     *
     * @param invoice - Invoice data, mainly for id and IRI.
     * @param lines - The product lines from database.
     * @param newProducts - The products to update.
     * @param onFetchNewLines - Action to trigger on successfully fetch requests for the array of products (not anymore only the addition of lines).
     */
    const updateProducts = useCallback(
        async ({ invoice, lines, newProducts, onFetchNewLines }: UpdateProductsProps) => {
            const productsArray: TableauProduits =
                newProducts.produits as unknown as TableauProduits;

            const productToRemoveRequestList: Array<() => ReturnType<typeof deleteLine>> =
                removeProduct({
                    lines,
                    productsArray,
                });
            const productToPatchRequestList = patchProduct({ invoice, lines, productsArray });
            const syncRequest = () => synchroG3Facture(invoice.id?.toString() || '-1');

            const requestPromiseList: Array<
                (typeof productToPatchRequestList)[0] | (typeof productToRemoveRequestList)[0]
            > = [];

            requestPromiseList.push(...productToRemoveRequestList);
            requestPromiseList.push(...productToPatchRequestList);

            try {
                await Promise.all(requestPromiseList.map((req) => req()));

                syncRequest();

                onFetchNewLines();

                enqueueSnackbar(`Les produits ont été mis à jour avec succès`);
            } catch (error) {
                console.error('Error updating product lines:', error);

                enqueueSnackbar(`Une erreur est survenue lors de la mise à jour des produits`, {
                    variant: 'error',
                });
            } finally {
                calculateLinesTotals({ invoiceId: invoice.id, newProducts });
            }
        },
        [calculateLinesTotals, enqueueSnackbar, patchProduct, removeProduct, synchroG3Facture],
    );

    return {
        updateProducts,
    };
};

export default useSectionProducts;
