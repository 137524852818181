/* eslint-disable */
/* istanbul ignore file */

import * as React from 'react';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, error };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        console.error(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            if (typeof this.state.error === 'object') {
                console.log(Object.keys(this.state));
                return (
                    <div>
                        <h1>Something went wrong.</h1>
                        <h2>{this.state.error.toString()}</h2>
                        <pre>{this.state.error.stack}</pre>
                    </div>
                );
            }
            // You can render any custom fallback UI
            return (
                <div>
                    <h1>Something went wrong.</h1>
                    <pre>{this.state.error}</pre>
                </div>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
