import { useEffect } from 'react';

import { ContextActionType, useContextActions } from '@europrocurement/flexy-components';

export type AddCustomContextProps = Array<ContextActionType>;

const useAddCustomContextActions = (
    contextActions: AddCustomContextProps,
): AddCustomContextProps => {
    const { defineContextActions, resetContextActions } = useContextActions();

    useEffect(() => {
        defineContextActions(contextActions);
    }, [contextActions, defineContextActions]);

    useEffect(() => () => resetContextActions(), [resetContextActions]);

    return contextActions;
};

export default useAddCustomContextActions;
