import {
    ARTICLES_SLICE_NAME,
    CATALOGS_SLICE_NAME,
    createOfferReducer,
    DOCUMENT_SLICE_NAME,
    INVOICING_CATEGORIES_SLICE_NAME,
    OFFER_ACTIONS_SLICE_NAME,
    OFFER_ADDITIONAL_FIELDS_SLICE_NAME,
    OFFER_OPTION_VALUES_SLICE_NAME,
    OFFER_OPTIONS_SLICE_NAME,
    OFFERS_SLICE_NAME,
    PACKAGES_SLICE_NAME,
} from '@europrocurement/l2d-domain';
import { combineReducers } from '@reduxjs/toolkit';
import { VATS_SLICE_NAME } from '@europrocurement/l2d-domain/reducers/offre/slices/vatsSlice';
import { DOMAINS_SLICE_NAME } from '@europrocurement/l2d-domain/reducers/offre/slices/domainsSlice';
import { OFFER_GROUP_SLICE_NAME } from '@europrocurement/l2d-domain/reducers/offre/slices/offerGroupSlice';
import articleLibSlice, {
    REDUX_LIB_ARTICLE,
} from '@europrocurement/l2d-domain/reducers/offre/slices/articleLibSlice';
import offerCacheSlice, {
    OFFER_CACHED_KEY,
} from '@europrocurement/l2d-domain/reducers/offre/slices/offerCacheSlice';
import { apiConfiguration } from './api';

export const {
    offersSlice,
    offersDataSourcesThunks,
    catalogsSlice,
    packagesSlice,
    articlesSlice,
    actionsSlice,
    offerOptionsSlice,
    offerOptionValuesSlice,
    offerGroupsSlice,
    offerAdditionalFieldsSlice,
    documentsSlice,
    vatsSlice,
    domainsSlice,
    invoicingCategorySlice,
    catalogsDataSourcesThunks,
    packagesDataSourcesThunks,
    actionsDataSourcesThunks,
    articlesDataSourcesThunks,
    offerOptionsDataSourcesThunks,
    offerAdditionalFieldsDataSourcesThunks,
    offerOptionValuesDataSourcesThunks,
    offerGroupsDataSourcesThunks,
    documentsDataSourcesThunks,
    vatsDataSourcesThunks,
    domainsDataSourcesThunks,
    invoicingCategoryDataSourcesThunks,
} = createOfferReducer(apiConfiguration);

export const combinedOfferReducers = combineReducers({
    [OFFERS_SLICE_NAME]: offersSlice.reducer,
    [CATALOGS_SLICE_NAME]: catalogsSlice.reducer,
    [PACKAGES_SLICE_NAME]: packagesSlice.reducer,
    [VATS_SLICE_NAME]: vatsSlice.reducer,
    [ARTICLES_SLICE_NAME]: articlesSlice.reducer,
    [DOMAINS_SLICE_NAME]: domainsSlice.reducer,
    [OFFER_OPTIONS_SLICE_NAME]: offerOptionsSlice.reducer,
    [OFFER_OPTION_VALUES_SLICE_NAME]: offerOptionsSlice.reducer,
    [OFFER_ACTIONS_SLICE_NAME]: actionsSlice.reducer,
    [OFFER_ADDITIONAL_FIELDS_SLICE_NAME]: offerAdditionalFieldsSlice.reducer,
    [OFFER_GROUP_SLICE_NAME]: offerGroupsSlice.reducer,
    [DOCUMENT_SLICE_NAME]: documentsSlice.reducer,
    [INVOICING_CATEGORIES_SLICE_NAME]: invoicingCategorySlice.reducer,
    [REDUX_LIB_ARTICLE]: articleLibSlice.reducer,
    [OFFER_CACHED_KEY]: offerCacheSlice.reducer,
});
