import React from 'react';

import { DossierApiRaiseDossierItemRequest } from '@europrocurement/l2d-domain/openApi/ApiSign';
import { Typography } from '@mui/material';
import { AxiosError } from 'axios';
import { dossierSignatureApi } from '@b2d/redux/subReducers';
import { useSnackbar } from 'notistack';

export const processSignError = (error: Error) => {
    if (
        error instanceof AxiosError &&
        error.response &&
        error.response.data.exception &&
        error.response.data.exception.message
    ) {
        return <Typography>{error.response.data.exception.message}</Typography>;
    }
    return <Typography>{error.message}</Typography>;
};

export const relanceDossierSignature = async (id: number | string) => {
    const requestParameters: DossierApiRaiseDossierItemRequest = {
        id: `${id}`,
    };

    const res = await dossierSignatureApi.raiseDossierItem(requestParameters);

    if (res.status >= 200 && res.status < 300) {
        return Promise.resolve(res);
    }
    return Promise.reject(res);
};

export const useRelanceDossierSignature = () => {
    const { enqueueSnackbar } = useSnackbar();

    return (id: number) => {
        relanceDossierSignature(id as number)
            .then(() => {
                enqueueSnackbar(<Typography>Relance effectuée</Typography>, {
                    variant: 'success',
                });
            })
            .catch((error) => {
                enqueueSnackbar(processSignError(error), {
                    variant: 'error',
                });
            });
    };
};
