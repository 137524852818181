import * as React from 'react';
import { MenuEntry } from '@europrocurement/flexy-components';

import PrescripteurFiche from './views/detail/PrescripteurDetail';
import PrescripteursListing from './views/listing/PrescripteursListing';
import PrescripteurAdd from './views/create/PrescripteurCreation';
import PrescipteurRoot from './views/PrescripteurRoot';
import { PRESCRIBER_LIST, prescripteursBasePath, PRESCRIBER_NEW } from './constants/paths';

export const PrescipteurRouter = {
    path: prescripteursBasePath,
    element: <PrescipteurRoot />,
    children: [
        {
            path: 'liste',
            children: [
                {
                    path: '',
                    index: true,
                    element: <PrescripteursListing />,
                },
                {
                    path: ':idPrescripteur',
                    element: <PrescripteurFiche />,
                },
            ],
        },
        {
            path: 'new',
            element: <PrescripteurAdd />,
        },
    ],
};

export const PrescripteurMenuEntries: MenuEntry[] = [
    {
        title: 'Prescripteurs',
        icon: 'users',
        href: PRESCRIBER_LIST,
        role: 'prescripteur',
        children: [
            {
                title: 'Nouveau prescripteur',
                icon: 'user-plus',
                href: PRESCRIBER_NEW,
                role: 'prescripteur',
            },
        ],
    },
];

export default PrescipteurRouter;
