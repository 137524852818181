import * as React from 'react';
import { Box } from '@mui/system';
import { Step, StepLabel, Stepper, Typography } from '@mui/material';
import { MultiStepContextProps } from './types';

const MultiStepFormHeader: React.FC<MultiStepContextProps> = function (props) {
    const { steps, multiStepForm } = props;
    const {
        currentStep: { index },
    } = multiStepForm;

    return (
        <Box sx={{ width: '100%', padding: '0px 50px', margin: '20px 0px' }}>
            <Stepper
                sx={{ marginTop: '10px' }}
                activeStep={index}
            >
                {steps.map(({ title }) => (
                    <Step key={title}>
                        <StepLabel>
                            <Typography fontSize={15}>{title}</Typography>
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Box>
    );
};

export default MultiStepFormHeader;
