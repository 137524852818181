import * as React from 'react';
import { FlexyTextField } from '@europrocurement/flexy-components';
import { Box, Grid, Typography } from '@mui/material';
import PackageCard from './PackageCard';

const PreviewTool: React.FunctionComponent = function () {
    /**
     * Outil de preview d'une offre.
     *
     * Charger le modele entier et construire une interface permettant de naviguer au travers des différents elements / imbrications (packages / articles)
     */

    return (
        <Grid
            container
            spacing={4}
            rowSpacing={1}
        >
            <Grid xs={12}>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    margin="30px 0 30px 30px "
                >
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        marginLeft="30px"
                    >
                        <Typography
                            fontWeight="bold"
                            fontSize="20px"
                        >
                            Offre :
                        </Typography>
                        <Typography fontSize="16px">Titre de l&apos;offre</Typography>
                    </Box>
                    <Box width="420px">
                        <FlexyTextField
                            variant="outlined"
                            placeholder="Filtrer packages"
                            sx={{ width: '100%' }}
                        />
                    </Box>
                </Box>
            </Grid>

            <Grid
                item
                lg={3}
            >
                <PackageCard />
            </Grid>
            <Grid
                item
                lg={3}
            >
                <PackageCard />
            </Grid>
            <Grid
                item
                lg={3}
            >
                <PackageCard />
            </Grid>
            <Grid
                item
                lg={3}
            >
                <PackageCard />
            </Grid>
        </Grid>
    );
};

export default PreviewTool;
