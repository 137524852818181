import * as React from 'react';
import { Box, ListItem, ListItemText, Typography } from '@mui/material';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FormArticle, PackageLineProps } from './types';
import SortableList from '../../components/SortableList/SortableList';
import LineActions from './LineActions';
import useListItemHoverEffect from './useHoverEffect';

const LineBreakLine: React.FC<PackageLineProps> = function (props) {
    const { article, index, context } = props;

    const { setValue, getValues } = context;

    const onRemove = () => {
        const updatedList = getValues().articles as FormArticle[];
        setValue(
            'articles',
            updatedList.filter((formArticle) => formArticle.id !== article.id),
        );
    };

    const listHoverProps = useListItemHoverEffect();

    const actions = [
        {
            icon: faTrash,
            onClick: onRemove,
            actionName: 'remove',
        },
    ];

    const line = (
        <Box
            sx={{
                opacity: '0.3',
                border: '1px solid',
                minWidth: '100%',
                maxHeight: '1px',
                marginX: '10px',
            }}
        />
    );

    const lineBreakContent = (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ marginTop: '5px' }}
        >
            {line}
            <Typography
                sx={{
                    minWidth: '100%',
                }}
                fontStyle="italic"
                textAlign="center"
            >
                Saut de ligne
            </Typography>
            {line}
        </Box>
    );
    return (
        <>
            <Box sx={{ marginTop: '17px', marginBottom: 'auto' }}>
                <SortableList.DragHandle />
            </Box>

            <Box width="100%">
                <ListItem {...listHoverProps}>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        width="100%"
                    >
                        <Box />
                        <Box
                            display="flex"
                            justifyContent="center"
                            sx={{ opacity: '0.3' }}
                        >
                            <ListItemText primary={lineBreakContent} />
                        </Box>
                        <LineActions
                            actions={actions}
                            lineIndex={index}
                        />
                    </Box>
                </ListItem>
            </Box>
        </>
    );
};

export default LineBreakLine;
