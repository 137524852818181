import { TypographyOptions } from '@mui/material/styles/createTypography';
import './fonts/fonts.css';

const typography: TypographyOptions = {
    fontFamily: "'Roboto', sans-serif",
    // fontFamily: 'Verdana, Geneva, sans-serif',
    // fontFamily: 'Inter, Geneva, sans-serif',
    // useNextVariants: true,
    h1: {
        fontWeight: 500,
        fontSize: '1.875rem', // 30px
        lineHeight: '1.5',
        fontFamily: 'Roboto',
    },
    h2: {
        fontWeight: 500,
        fontSize: '1.5rem', // 24px
        lineHeight: '1.5',
        fontFamily: 'Roboto',
    },
    h3: {
        fontWeight: 500,
        fontSize: '1.3125rem', // 21px
        lineHeight: '1.5',
        fontFamily: 'Roboto',
    },
    h4: {
        fontWeight: 500,
        fontSize: '1.125rem', // 18px
        lineHeight: '1.5',
        fontFamily: 'Roboto',
    },
    h5: {
        fontWeight: 500,
        fontSize: '1rem', // 16px
        lineHeight: '1.5',
        fontFamily: 'Roboto',
    },
    h6: {
        fontWeight: 500,
        fontSize: '0.875rem', // 14px
        lineHeight: '1.5',
        fontFamily: 'Roboto',
    },
    body1: {
        fontWeight: 400,
        fontSize: '1rem',
        lineHeight: 'auto',
    },
    body2: {
        fontWeight: 400,
        fontSize: '0.75rem',
        lineHeight: 'auto',
    },
    subtitle1: {
        fontSize: '1.2rem', // 24px
        fontWeight: 400,
        letterSpacing: '2px',
    },
    // subtitle2: {},
    button: {
        textTransform: 'none',
        fontWeight: 400,
    },
};

export default typography;
