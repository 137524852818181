import { FlexyHeaderForm, Modalizer } from '@europrocurement/flexy-components';
import { Box, Button, Grid, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { LegalStatus } from '@europrocurement/l2d-domain';
import { legalStatusSelector } from '@b2d/redux/subReducers/FormalityReducer';
import CheckboxListItem from '../../components/CheckboxListItem';

type LegalStatusModalProps = {
    isOpen: boolean;
    onModalClose: () => void;
    onSubmitModal: (selectedItems: Array<number>) => void;
    itemsSelected: Array<number>;
};

type LegalStatusItem = {
    id: number;
    label: string;
    description: string;
    checked: boolean;
};

const LegalStatusModal: React.FunctionComponent<LegalStatusModalProps> = function (props) {
    const { isOpen, onModalClose, onSubmitModal, itemsSelected } = props;
    const [selectedItems, setSelectedItems] = useState(itemsSelected);
    const [searchTerm, setSearchTerm] = useState('');
    const dataSource = useSelector(legalStatusSelector);

    const legalStatusesItems = dataSource.data
        .map((item: LegalStatus) =>
            item.id
                ? {
                      id: item.id,
                      label: item.abreviation,
                      description: item.libelle,
                      checked: selectedItems.indexOf(item.id) !== -1,
                  }
                : // undefined id case (shouldn't exist)
                  {},
        )
        .filter(
            (item: LegalStatusItem) =>
                !searchTerm ||
                item.label.toLowerCase().match(searchTerm.toLowerCase()) ||
                item.description.toLowerCase().match(searchTerm.toLowerCase()),
        )
        .sort((a: LegalStatusItem, b: LegalStatusItem) => {
            if (a.label.toLowerCase() < b.label.toLowerCase()) {
                return -1;
            }

            if (a.label.toLowerCase() > b.label.toLowerCase()) {
                return 1;
            }

            return 0;
        });
    const onCheckItem = (legalStatusId: number) => {
        if (selectedItems.indexOf(legalStatusId) === -1) {
            const tmpArray = [...selectedItems];
            tmpArray.push(legalStatusId);
            setSelectedItems(tmpArray);
        }
    };
    const onUncheckItem = (legalStatusId: number) => {
        if (selectedItems.indexOf(legalStatusId) !== -1) {
            const tmpArray = [...selectedItems];
            tmpArray.splice(tmpArray.indexOf(legalStatusId), 1);
            setSelectedItems(tmpArray);
        }
    };
    const cancelModal = () => {
        onModalClose();
    };
    const submitModal = () => {
        setSelectedItems(selectedItems);
        onSubmitModal(selectedItems);
    };

    useEffect(() => {
        setSelectedItems(itemsSelected);
    }, [isOpen, itemsSelected]);

    const items = legalStatusesItems.map((item: LegalStatusItem) => (
        <Grid
            key={item.id}
            item
            style={{ width: '100%' }}
        >
            <CheckboxListItem
                label={item.label}
                checked={item.checked}
                onUpdate={(isChecked: boolean) => {
                    if (isChecked) {
                        onCheckItem(item.id);
                    } else {
                        onUncheckItem(item.id);
                    }
                }}
                description={item.description}
            />
        </Grid>
    ));
    const submitBtn = (
        <Button
            key="legal-status-submit_button"
            color="primary"
            variant="contained"
            onClick={submitModal}
            style={{ marginTop: '8px', marginBottom: '8px' }}
        >
            Confirmer
        </Button>
    );
    const cancelBtn = (
        <Button
            key="legal-status-cancel_button"
            color="primary"
            variant="outlined"
            onClick={cancelModal}
            style={{ marginTop: '8px', marginBottom: '8px' }}
        >
            Annuler
        </Button>
    );

    return (
        <Modalizer
            open={isOpen}
            onClose={cancelModal}
            maxWidth="lg"
            fullWidth
            modalActions={[cancelBtn, submitBtn]}
        >
            <Box>
                <FlexyHeaderForm
                    outlined
                    label="Modifier les formes juridiques associées à cette formalité"
                />
                <TextField
                    fullWidth
                    placeholder="Filtrer la liste des formes juridiques"
                    sx={{ marginTop: '25px' }}
                    onChange={(e) => {
                        setSearchTerm(e.target.value);
                    }}
                />
                <Box sx={{ marginTop: '25px', maxHeight: '60vh', overflowY: 'scroll' }}>
                    <Grid
                        container
                        gap="10px 0"
                    >
                        {items}
                    </Grid>
                </Box>
            </Box>
        </Modalizer>
    );
};

export default LegalStatusModal;
