import * as React from 'react';
import { FlexyForm, FormObject, FormStructure } from '@europrocurement/flexy-form';
import { customizerSelector } from '@b2d/redux/RootStore';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { Box } from '@mui/material';
import { maximumLengthRule } from '@b2d/validation/rules/length';
import {
    ChampComplMentaireChampcomplementaireRead,
    ChampComplMentaireJsonldChampcomplementaireCreate,
    ChampComplMentaireJsonldChampcomplementaireUpdate,
} from '@europrocurement/l2d-domain/openApi/ApiOffre';
import { useSelector } from 'react-redux';
import useApiRequest from '@b2d/hooks/useApiRequest';
import { ModalContext } from '@europrocurement/flexy-components';
import { isStringJsonValidRule } from '@b2d/validation/rules/strings';
import model from '../models/additionalField';
import { ModelForm } from '../forms/types';
import { formatPayload } from '../forms/format';

const Form: React.FC<ModelForm<ChampComplMentaireChampcomplementaireRead>> = function (props) {
    const { afterSubmit, entity, isCreate = false } = props;
    const { modalActions } = React.useContext(ModalContext);

    const successCallback = () => {
        modalActions.reset();
        afterSubmit();
    };

    const htmlAdditionalFieldOptions = [
        { value: 'text', label: 'Champ texte' },
        { value: 'number', label: 'Nombre' },
        { value: 'textarea', label: 'Zone de texte' },
        { value: 'date', label: 'Champ date' },
        { value: 'email', label: 'Champ email' },
    ];

    const htmlTypeOption = htmlAdditionalFieldOptions.find(
        (option) => option.value === entity?.htmlFieldType,
    );

    const getDefaultValues = () => {
        const jsonConfiguration = isCreate ? '{}' : entity?.configuration;
        if (isCreate) {
            return { configuration: jsonConfiguration };
        }

        const defaultHtmlOption = htmlAdditionalFieldOptions.find(
            (option) => option.value === 'text',
        );

        return {
            ...entity,
            htmlFieldType: htmlTypeOption ?? defaultHtmlOption,
            configuration: jsonConfiguration,
        };
    };

    // FormContext
    const formContext = useForm({
        defaultValues: getDefaultValues() as FieldValues,
        mode: 'onTouched',
    });

    const { xIdSociete: companyId } = useSelector(customizerSelector);

    const { request } = useApiRequest();

    const formatOutputSchema = {
        htmlFieldType: (option: { value: string }) => option.value,
    };

    const { getValues, trigger } = formContext;

    const fromStructure: FormStructure[] = [
        {
            type: 'header',
            label: `Création d'un champs complémentaire`,
            sx: {
                marginTop: '0px',
            },
            name: 'info',
        },
        {
            type: 'text',
            name: 'libelle',
            inputlabel: 'Libellé du champ complémentaire',
            rules: {
                required: 'Vous devez renseigner un libellé',
                validate: {
                    ...maximumLengthRule(255),
                },
            },
            xs: 12,
            placeholder: 'Nom du champ complémentaire',
        },
        {
            type: 'textarea',
            name: 'description',
            inputlabel: 'Description du champ complémentaire',
            xs: 12,
            placeholder: 'Description du champ complémentaire ...',
            rules: {
                validate: {
                    ...maximumLengthRule(2000),
                },
            },
            multiline: true,
            rows: 4,
        },
        {
            type: 'select',
            name: 'htmlFieldType',
            inputlabel: 'Type de champ HTML',
            placeholder: 'Choisissez un type de champ HTML',
            xs: 11,
            options: htmlAdditionalFieldOptions,
            rules: {
                required: 'Vous devez renseigner le type de champ',
            },
        },
        {
            type: 'textarea',
            name: 'configuration',
            inputlabel: 'Configuration JSON',
            rules: {
                validate: {
                    ...isStringJsonValidRule(),
                },
            },
            xs: 12,
            placeholder: 'Configuration JSON',
            multiline: true,
            rows: 15,
        },
    ];

    const createModel: SubmitHandler<FormObject> = async (formValues) => {
        const formData = formatPayload(
            formValues,
            formatOutputSchema,
        ) as ChampComplMentaireJsonldChampcomplementaireCreate;
        const createRequest = model.create({
            champComplMentaireJsonldChampcomplementaireCreate: formData,
            xIdSociete: companyId,
        });
        await request(createRequest, { successCallback });
    };

    const updateModel: SubmitHandler<FormObject> = async (formValues: { id: string }) => {
        const modelId = formValues.id;
        const formData = formatPayload(
            formValues,
            formatOutputSchema,
        ) as ChampComplMentaireJsonldChampcomplementaireUpdate;
        if (!modelId) {
            return;
        }
        const updateRequest = model.update({
            id: modelId,
            champComplMentaireJsonldChampcomplementaireUpdate: formData,
            xIdSociete: companyId,
        });
        await request(updateRequest, { successCallback });
    };

    const handleSubmit = async () => {
        const validation = await trigger();

        if (!validation) {
            return;
        }

        if (isCreate) {
            createModel(getValues());
        } else {
            updateModel(getValues());
        }
    };

    return (
        <Box>
            <FlexyForm
                formContext={formContext}
                formObject={isCreate ? {} : (entity as FieldValues)}
                formStructure={fromStructure}
                onSubmit={handleSubmit}
                isDisabled={false}
            />
        </Box>
    );
};

export default Form;
