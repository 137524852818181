import useLazyDispatch from '@b2d/hooks/useLazyDispatch';
import { filterOptionsProps } from '@b2d/pages/Achats/components/forms/types';
import { DataSource, Filter, Order } from '@europrocurement/l2d-redux-utils';
import { useCallback, useMemo } from 'react';
import { UseKeycloakService } from '@europrocurement/l2d-keycloak';
import usePrevious from '@b2d/hooks/usePrevious';
import { ViewName } from './types';

type UseApplyCriteriaProps<T> = {
    dataSource: DataSource<T>;
    filterOptions: filterOptionsProps;
    viewName: ViewName;
};

/**
 * useApplyCriteria
 *
 * A specialized hook designed to apply filtering criteria based on the specific view.
 * It handles both common criteria applicable across multiple views and view-specific criteria that
 * depend on business rules associated with each view.
 *
 * @param {UseApplyCriteriaProps<T>} {
 *     dataSource,       // The current data source where filters are to be applied.
 *     filterOptions,    // Contains necessary identifiers to configure dispatch actions.
 *     viewName,         // The view name used to determine the specific set of criteria to apply.
 * }
 *
 * @returns {object} {
 *     setCriteria       // Function to execute the setting of the criteria.
 * }
 *
 * Example Usage:
 * ```
 * const { setCriteria } = useApplyCriteria({
 *     dataSource: currentDataSource,
 *     filterOptions: { sliceName, dataSourceName },
 *     viewName: 'unregistered'
 * });
 *
 * setCriteria(); // Apply all relevant filters to the data source.
 * ```
 */
const useApplyCriteria = <T>({ dataSource, filterOptions, viewName }: UseApplyCriteriaProps<T>) => {
    /**
     * Default order define to arrange dataSource data.
     */
    const defaultOrder: Order = useMemo(() => ({ field: 'createdAt', value: 'desc' }), []);

    const lazyDispatch = useLazyDispatch(filterOptions);
    const kc = UseKeycloakService();
    const prevViewName = usePrevious(viewName);

    /**
     * Applies common filtering criteria used across all views.
     *
     * RG-Achat-20
     *
     * Common Unregistered Invoices:
     * - Order: `createdAt = 'desc'`,
     * - Filter: `processingFacture = false`,
     * - Filter: `available = true`
     */
    const applyCommonCriteria = useCallback(() => {
        lazyDispatch({
            target: 'Order',
            action: 'set',
            payload: { ...defaultOrder },
        })();
        lazyDispatch({
            target: 'Filter',
            action: 'set',
            payload: { key: 'processingFacture', value: false },
        })();
        lazyDispatch({
            target: 'Filter',
            action: 'set',
            payload: { key: 'available', value: true },
        })();
    }, [defaultOrder, lazyDispatch]);

    /**
     * RG-Achat-30
     *
     * Unregistered Invoices:
     * - Filter: `forceInterne = false` (Invoices not exclusive to internal employees).
     */
    const applyUnregisteredInvoicesCriteria = useCallback(() => {
        const payload = { key: 'forceInterne', value: false };

        if (dataSource.filters === payload) return;

        lazyDispatch({
            target: 'Filter',
            action: 'set',
            payload,
        })();
    }, [dataSource.filters, lazyDispatch]);

    /**
     * RG-Achat-40
     *
     * User Unregistered Invoices:
     * - Filter: `createdBy = <current user email>` (Invoices created by the logged-in user).
     * - Filter: `forceInterne = true` (Exclusive to internal employees).
     */
    const applyUserUnregisteredInvoicesCriteria = useCallback(() => {
        const payloads = [
            { key: 'createdBy', value: kc.getEmail() },
            { key: 'forceInterne', value: true },
        ];

        payloads.forEach((payload) => {
            if (dataSource.filters === payload) return;

            lazyDispatch({
                target: 'Filter',
                action: 'set',
                payload,
            })();
        });
    }, [dataSource.filters, kc, lazyDispatch]);

    /**
     * RG-Achat-50
     *
     * Unassigned Unregistered Invoices:
     * - Filter: `createdBy = "service-account-api-formalite"`,
     * - Filter: `forceInterne = true` (Invoices assigned to an internal system account and exclusive to internal employees).
     */
    const applyUnassignedUnregisteredInvoicesCriteria = useCallback(() => {
        const payloads: Array<Filter> = [
            { key: 'createdBy', value: 'service-account-api-formalite' },
            { key: 'forceInterne', value: true },
        ];

        payloads.forEach((payload) => {
            if (dataSource.filters === payload) return;

            lazyDispatch({
                target: 'Filter',
                action: 'set',
                payload,
            })();
        });
    }, [dataSource.filters, lazyDispatch]);

    /**
     * Mapping view names to their respective criteria functions
     */
    const viewCriteriaFunctions: Record<ViewName, () => void> = useMemo(
        () => ({
            unregistered: applyUnregisteredInvoicesCriteria,
            user_unregistered: applyUserUnregisteredInvoicesCriteria,
            unassigned_unregistered: applyUnassignedUnregisteredInvoicesCriteria,
        }),
        [
            applyUnassignedUnregisteredInvoicesCriteria,
            applyUnregisteredInvoicesCriteria,
            applyUserUnregisteredInvoicesCriteria,
        ],
    );

    const specificCriteriaFunction = useCallback(() => {
        if (prevViewName === viewName) return null;
        return viewCriteriaFunctions[viewName];
    }, [prevViewName, viewCriteriaFunctions, viewName]);

    /**
     * Apply criteria
     *
     * First common ones, then specific ones if they exists.
     * Technically, their is always specific criteria.
     */
    const setCriteria = useCallback(() => {
        applyCommonCriteria();

        const criteriaFunction = specificCriteriaFunction();

        if (criteriaFunction) {
            criteriaFunction();
        }
    }, [applyCommonCriteria, specificCriteriaFunction]);

    return {
        setCriteria,
    };
};

export default useApplyCriteria;
