import * as React from 'react';
import { ModalContext } from '@europrocurement/flexy-components';

import { ColumnDatatable } from '@europrocurement/flexy-datatable';
import { useDispatch } from 'react-redux';
import ListingWithPreview from '@b2d/pages/Offres/components/ListingWithPreview';
import { Box } from '@mui/system';
import { CatalogueJsonldCatalogueRead } from '@europrocurement/l2d-domain/openApi/ApiOffre';
import { UnknownAction } from 'redux';
import { addItem } from '@europrocurement/l2d-icons';
import Form from './Form/Form';
import model from '../models/catalog';
import { getStringColumn } from '../listings';

const Panel: React.FC = function () {
    const { modalActions } = React.useContext(ModalContext);

    const dispatch = useDispatch();

    const reloadList = () =>
        dispatch(model.datasourcesThunks.main.getData({}) as unknown as UnknownAction);

    const listingButton = {
        label: 'Créer un nouveau catalogue',
        icon: addItem,
        action: () =>
            modalActions.call(
                <Form
                    isCreate
                    afterSubmit={reloadList}
                />,
            ),
    };

    const columns: ColumnDatatable<CatalogueJsonldCatalogueRead>[] = [
        getStringColumn('nomCommercial'),
        {
            label: 'Domaine',
            displayLabel: true,
            render: (item) => {
                const domain = item.domaine as { libelle?: string };
                return (
                    <Box sx={{ justifyContent: 'end' }}>
                        <p>{domain?.libelle ?? '(aucun domaine)'}</p>
                    </Box>
                );
            },
            isDisplayed: true,
            sortable: false,
            field: 'domaine',
        },
    ];

    return (
        <ListingWithPreview
            listingTitle="Liste des catalogues"
            listingButton={listingButton}
            listingColumns={columns}
            form={Form}
            model={model}
        />
    );
};

export default Panel;
