import * as React from 'react';
import ReactDOM from 'react-dom/client';
import { KeycloakProvider } from '@europrocurement/l2d-keycloak';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import App from './App';

import ErrorBoundary from './ErrorBoundary';
import { rootStore } from './redux/RootStore';

import { keycloak } from './keycloak';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <KeycloakProvider
        initOptions={{
            onLoad: 'login-required',
            checkLoginIframe: false,
        }}
        authClient={keycloak}
    >
        <Provider store={rootStore}>
            <React.StrictMode>
                <BrowserRouter>
                    <ErrorBoundary>
                        <App />
                    </ErrorBoundary>
                </BrowserRouter>
            </React.StrictMode>
        </Provider>
    </KeycloakProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
