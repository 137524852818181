/* eslint-disable @typescript-eslint/no-unused-vars */

import {
    SliceFactory,
    FetchCollectionData,
    FetchItemDataType,
    ApiCollectionResponse,
} from '@europrocurement/l2d-redux-utils';
import {
    ConfigurationParameters as FacturationConfigurationParameters,
    Configuration,
    FactureApi,
    DevisLignesJsonld,
    DevisLignesApi,
    DevisLignesApiApiDevisLignesGetDevisCollectionRequest,
} from '../../../openApi/ApiFacturation';
import { FACTURATION_REDUCER_NAME } from '../constants';

export type DevisLigneApiObject = DevisLignesJsonld & Record<string, unknown>;

export type DevisLigne = DevisLigneApiObject;

export const DEVIS_LIGNE_SLICE_NAME = 'devisLigne';

const mapperDevisLigne = function (devisLigne: DevisLigne) {
    return {
        ...devisLigne,
    } as DevisLigne;
};

export function createDevisLigneSlice(configuration: FacturationConfigurationParameters) {
    const conf = new Configuration(configuration);
    const api = new DevisLignesApi(conf);

    const fetchDevisLignesCollection: FetchCollectionData<DevisLigne> = ({
        search,
        pagination,
        filters,
        orders,
        xIdSociete,
        rootstate,
    }) => {
        let devisDossierId: number | undefined;
        let devisDossierId2: Array<number> | undefined;
        let devisId: number | undefined;
        let devisId2: Array<number> | undefined;
        let idAnnonce: number | undefined;
        let idAnnonce2: Array<number> | undefined;
        let rubfac: string | undefined;
        let rubfac2: Array<string> | undefined;

        if (filters.devisDossierId !== undefined) {
            devisDossierId = filters.devisDossierId as number;
        }

        if (
            filters.devisDossierId2 &&
            Array.isArray(filters.devisDossierId2) &&
            filters.devisDossierId2.length > 0
        ) {
            devisDossierId2 = filters.devisDossierId2;
        }

        if (filters.devisId !== undefined) {
            devisId = filters.devisId as number;
        }

        if (filters.devisId2 && Array.isArray(filters.devisId2) && filters.devisId2.length > 0) {
            devisId2 = filters.devisId2;
        }

        if (filters.idAnnonce !== undefined) {
            idAnnonce = filters.idAnnonce as number;
        }

        if (
            filters.idAnnonce2 &&
            Array.isArray(filters.idAnnonce2) &&
            filters.idAnnonce2.length > 0
        ) {
            idAnnonce2 = filters.idAnnonce2;
        }

        if (filters.rubfac !== undefined) {
            rubfac = filters.rubfac as string;
        }

        if (filters.rubfac2 && Array.isArray(filters.rubfac2) && filters.rubfac2.length > 0) {
            rubfac2 = filters.rubfac2;
        }

        const requestParameters: DevisLignesApiApiDevisLignesGetDevisCollectionRequest = {
            xIdSociete,
            page: pagination.page + 1,
            devisDossierId,
            devisDossierId2,
            devisId,
            devisId2,
            idAnnonce,
            idAnnonce2,
            rubfac,
            rubfac2,
        };

        return api
            .apiDevisLignesGetDevisCollection(requestParameters)
            .then((res) => res.data) as Promise<ApiCollectionResponse<DevisLigneApiObject>>;
    };

    const fetchDevisLignesItem: FetchItemDataType<DevisLigneApiObject> = ({
        idItem,
        search,
        pagination,
        filters,
        orders,
        xIdSociete,
        rootstate,
    }) =>
        api
            .apiDevisLignesGetItem({ id: String(idItem), xIdSociete })
            .then((res) => res.data) as Promise<DevisLigneApiObject>;

    const { slice: devisLigneSlice, dataSourcesThunks: devisLigneDataSourcesThunks } =
        SliceFactory.createSlice<DevisLigneApiObject, DevisLigne>(
            DEVIS_LIGNE_SLICE_NAME,
            FACTURATION_REDUCER_NAME,
            [],
            fetchDevisLignesCollection,
            fetchDevisLignesItem,
            mapperDevisLigne,
        );

    return { devisLigneSlice, devisLigneDataSourcesThunks };
}
