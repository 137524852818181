import * as React from 'react';
import { ModalContext } from '@europrocurement/flexy-components';
import {
    ColumnDatatable,
    FilterOptionValue,
    FiltersDatatableList,
} from '@europrocurement/flexy-datatable';
import { ArticlesJsonldArticleRead } from '@europrocurement/l2d-domain/openApi/ApiOffre';
import { useDispatch, useSelector } from 'react-redux';
import ListingWithPreview from '@b2d/pages/Offres/components/ListingWithPreview';
import ArticleForm from '@b2d/pages/Offres/Article/Form';
import { addItem } from '@europrocurement/l2d-icons';
import { domainSelector } from '@b2d/redux/RootStore';
import model from '../models/article';
import ModalFormTitle from '../components/form/ModalFormTitle';
import { getPriceColumn, getStringColumn } from '../listings';
import { formatOptionsByKeys } from '../forms/options';

const Panel: React.FC = function () {
    const { modalActions } = React.useContext(ModalContext);

    const fetchedDomainsOptions = useSelector(domainSelector).main.data;
    const domainsOptions = formatOptionsByKeys(fetchedDomainsOptions, 'id', 'libelle');

    const dispatch = useDispatch();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const reloadList = () => dispatch(model.datasourcesThunks.main.getData({}) as any);

    const listingButton = {
        label: 'Créer un nouvel article',
        icon: addItem,
        action: () =>
            modalActions.call(
                <>
                    <ModalFormTitle
                        icon={model.icon}
                        title="Création d'un article"
                    />
                    <ArticleForm
                        isCreate
                        afterSubmit={reloadList}
                    />
                </>,
            ),
    };

    const columns: ColumnDatatable<ArticlesJsonldArticleRead>[] = [
        getStringColumn('libelle', 'Libellé'),
        getStringColumn('codeArticle'),
        getStringColumn('rubArticle', 'Rubrique'),
        getPriceColumn('prixUnitaire'),
    ];

    // Force string value as defined in FilterOptionValue
    const domainsSelectInputOptions: FilterOptionValue[] = domainsOptions.map((option) => ({
        ...option,
        value: option.value?.toString() ?? '',
    }));

    const filters: FiltersDatatableList = [
        {
            label: 'Domaine',
            field: 'domaineId',
            type: 'staticSelect',
            options: domainsSelectInputOptions,
            renderFilterList: (value) => {
                const selectedOption = domainsOptions.find((option) => {
                    console.log(option.value);
                    console.log(value);
                    return option.value === value.toString();
                });
                return selectedOption ? selectedOption.label : 'Aucun';
            },
        },
        {
            label: 'Article supprimé',
            field: 'deleted',
            type: 'boolean',
        },
    ];

    return (
        <ListingWithPreview
            listingTitle="Liste des articles"
            listingButton={listingButton}
            listingColumns={columns}
            form={ArticleForm}
            model={model}
            filters={filters}
            filtersControl
        />
    );
};

export default Panel;
