import { RootStateType } from '@b2d/redux/RootStore';

import { OptionValueOptionValueRead } from '@europrocurement/l2d-domain/openApi/ApiOffre';
import { OFFER_OPTION_VALUES_SLICE_NAME, OFFRE_REDUCER_NAME } from '@europrocurement/l2d-domain';
import { offerOptionValueIcon } from '@europrocurement/l2d-icons';
import { offerOptionValuesApi } from '@b2d/redux/subReducers/Offers';
import { offerOptionValuesDataSourcesThunks } from '@b2d/redux/subReducers/Offers/reducers';
import { ModelConfiguration, OfferModelItem, ModelMethods } from './types';

const modelKey = 'offer_option_value';
const modelName = 'OfferOptionValue';
const sliceName = OFFER_OPTION_VALUES_SLICE_NAME;
const api = offerOptionValuesApi;
const baseSelector = (state: RootStateType) => state[OFFRE_REDUCER_NAME][sliceName];
const datasourcesThunks = offerOptionValuesDataSourcesThunks;

type ModelStructure = OptionValueOptionValueRead;

const configuration: ModelConfiguration = {
    key: modelKey,
    name: modelName,
    type: 'Option value',
    selector: baseSelector,
    sliceName,
    api,
    datasourcesThunks,
    icon: offerOptionValueIcon,
};

const methods: ModelMethods<ModelStructure> = {
    getItemDefaultLabel: (model: ModelStructure) => `${model.libelle}`,
};

const endpoints = {
    list: (...args: Parameters<typeof api.apiOptionValuesGetCollection>) =>
        api.apiOptionValuesGetCollection(...args),
    dependencies: (...args: Parameters<typeof api.apiOptionValuesIdDependenciesGet>) =>
        api.apiOptionValuesIdDependenciesGet(...args),
    read: (...args: Parameters<typeof api.apiOptionValuesIdGet>) =>
        api.apiOptionValuesIdGet(...args),
    update: (...args: Parameters<typeof api.apiOptionValuesIdPut>) =>
        api.apiOptionValuesIdPut(...args),
    create: (...args: Parameters<typeof api.apiOptionValuesPost>) =>
        api.apiOptionValuesPost(...args),
};

type ModelType = OfferModelItem<ModelStructure> & typeof endpoints;

const model: ModelType = {
    ...configuration,
    ...methods,
    ...endpoints,
};

export type OfferOptionValueModel = ModelType;
export default model;
