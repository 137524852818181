import { faSignature, faUsers } from '@fortawesome/pro-duotone-svg-icons';
import { NamedIconsType } from '../../types/types';
import { checkIcon, errorIcon, pauseIcon, todoIcon, waitIcon } from './general';

export const signatureIcon: NamedIconsType = {
    props: {
        icon: faSignature,
    },
    name: 'signature',
    displayName: 'Dossier de  signature',
};

export const signatureDemandeurIcon: NamedIconsType = {
    props: {
        icon: faUsers,
        option: signatureIcon.props.icon,
        optionProps: {
            style: {
                scale: '1.4',
                translate: '30% 0%',
            },
        },
    },
    name: 'demanderuSignature',
    displayName: 'Demandeurs signature',
};

export const signPendingIcon: NamedIconsType = {
    props: {
        icon: signatureIcon.props.icon,
        option: pauseIcon.props.icon,
        color: 'blueGrey.main',
        optionProps: {
            style: {
                translate: '30% 0%',
            },
        },
    },
    name: 'signPending',
    displayName: 'En attente de signature',
};

export const signInProgressIcon: NamedIconsType = {
    props: {
        icon: signatureIcon.props.icon,
        color: 'warning.main',
        option: waitIcon.props.icon,
        optionProps: {
            style: {
                translate: '30% 0%',
            },
        },
    },
    name: 'signInProgress',
    displayName: 'En cours de signature',
};

export const fileSignedIcon: NamedIconsType = {
    props: {
        icon: signatureIcon.props.icon,
        color: 'success.main',
        option: checkIcon.props.icon,
        optionProps: {
            style: {
                translate: '30% 0%',
            },
        },
    },
    name: 'fileSigned',
    displayName: 'Dossier signé',
};
export const signRefusedIcon: NamedIconsType = {
    props: {
        icon: signatureIcon.props.icon,
        option: errorIcon.props.icon,
        optionProps: {
            style: {
                translate: '30% 0%',
            },
        },
        color: 'error.main',
    },
    name: 'signRefused',
    displayName: 'Signature refusée',
};

export const newSignIcon: NamedIconsType = {
    props: {
        icon: signatureIcon.props.icon,
        option: todoIcon.props.icon,
        optionProps: {
            style: {
                translate: '30% 0%',
            },
        },
    },
    name: 'newSign',
    displayName: 'Nouvelle signature',
};

export const signNamedIcons: NamedIconsType[] = [
    signatureIcon,
    signPendingIcon,
    signInProgressIcon,
    fileSignedIcon,
    signRefusedIcon,
    newSignIcon,
    signatureDemandeurIcon,
];
export const signNamedIconsListe: NamedIconsType[] = [
    signPendingIcon,
    signInProgressIcon,
    fileSignedIcon,
    signRefusedIcon,
    newSignIcon,
];
export const signNamedIconsDossier: NamedIconsType[] = [
    signPendingIcon,
    signInProgressIcon,
    fileSignedIcon,
    signRefusedIcon,
];
