import _ from 'lodash';

import { CustomizerReducerType, INIT_STATE } from '../reducers';

export const getCustomiserState = () => {
    const customizerStateString = localStorage.getItem('customizerState');
    try {
        const customizerState: CustomizerReducerType = JSON.parse(customizerStateString || '');

        if (
            JSON.stringify(_.merge(_.clone(INIT_STATE), _.clone(customizerState))) !==
            customizerStateString
        ) {
            localStorage.setItem(
                'customizerState',
                JSON.stringify(_.merge(_.clone(INIT_STATE), _.clone(customizerState))),
            );
        }

        return _.merge(_.clone(INIT_STATE), _.clone(customizerState));
    } catch {
        return INIT_STATE;
    }
};

export default getCustomiserState;
