import useApiRequest from '@b2d/hooks/useApiRequest';
import { useEffect, useState } from 'react';
import {
    OfferGroup,
    mapperOfferGroups,
} from '@europrocurement/l2d-domain/reducers/offre/slices/offerGroupSlice';
import model from '../../Offres/models/offerGroup';

const FORMALITY_DOMAIN_ID = 3;

type orderRangType = 'asc' | 'desc';

const useGroup = (domaine: number = FORMALITY_DOMAIN_ID) => {
    const { request } = useApiRequest();
    const [groups, setGroups] = useState<OfferGroup[]>([]);

    const [domaineGroupe, setDomaineGroupe] = useState(domaine);

    const loadGroups = () => {
        const query = {
            page: 1,
            itemsPerPage: 1000,
            domaineId: domaineGroupe,
            orderRang: 'asc' as orderRangType,
        };
        request(model.list(query), {
            successCallback: (response) => {
                const items = response.data['hydra:member'].map(mapperOfferGroups);
                setGroups(items);
            },
            withToaster: false,
        });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => loadGroups(), [domaineGroupe]);

    return { groups, setDomaineGroupe };
};

export default useGroup;
