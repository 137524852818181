import * as React from 'react';

import { Button, ListItem, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import { hexToRgba } from '@europrocurement/l2d-utils';
import { deleteIcon, FaOptionIcon } from '@europrocurement/l2d-icons';
import { OfferModelItem } from '@b2d/pages/Offres/models/types';

export type EditableListItem = {
    id: number;
    ['@id']: string;
    entityType: string;
    content: unknown;
    label: string;
    model: OfferModelItem<unknown>;
};

type ComponentProps = {
    item: EditableListItem;
    removeItem: (item: EditableListItem) => void;
    model: OfferModelItem<unknown>;
};

const AsyncEditableListItem: React.FC<ComponentProps> = function (props) {
    const { item, removeItem, model } = props;

    const { icon, getItemDefaultLabel, getItemSecondaryLabel } = model;

    // Hover effect
    const [onHover, setHover] = React.useState(true);
    const opacityFilter = onHover ? 0.05 : 0.01;
    const getBackgroundItem = () => {
        const hexColor = '#000000';
        return hexToRgba(hexColor, opacityFilter);
    };

    if (!item) {
        return null;
    }

    const secondaryLabel = getItemSecondaryLabel ? getItemSecondaryLabel(item) : '';

    const hoverProps = {
        onMouseEnter: () => setHover(true),
        onMouseLeave: () => setHover(false),
    };

    return (
        <Box>
            <ListItem
                style={{
                    // cursor: onHover ? 'pointer' : 'inherit',
                    background: getBackgroundItem(),
                    transition: 'background 0.3s',
                    margin: '5px',
                }}
                {...hoverProps}
            >
                {icon && (
                    <ListItemIcon>
                        <FaOptionIcon
                            {...icon.props}
                            size="xl"
                        />
                    </ListItemIcon>
                )}

                <ListItemText
                    primary={getItemDefaultLabel(item)}
                    secondary={secondaryLabel}
                />

                <Tooltip title="Supprimer l'élément">
                    <Button
                        color="secondary"
                        aria-label="ajout"
                        size="small"
                        sx={{
                            height: 'fit-content',
                        }}
                        onClick={() => removeItem(item)}
                    >
                        <FaOptionIcon
                            {...deleteIcon.props}
                            size="lg"
                        />
                    </Button>
                </Tooltip>
            </ListItem>
        </Box>
    );
};

export default AsyncEditableListItem;
