import * as React from 'react';
import { Box, IconButton, List, ListItem, ListItemText, Tooltip } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import EmailIcon from '@mui/icons-material/Email';
import DeleteIcon from '@mui/icons-material/Delete';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import { FlexyInput } from '@europrocurement/flexy-components';
import {
    CoordonneesEmailCoordonneeUpdate,
    EmailPrescripteurReadNotificationsEmails,
    EmailPrescripteurWriteNotificationsEmails,
} from '@europrocurement/l2d-domain/openApi/ApiTiers';

type EmailListProps = {
    isUpdating: boolean;
    emails: EmailPrescripteurWriteNotificationsEmails[] | null;
    updateEmails: (updatedEmails: EmailPrescripteurWriteNotificationsEmails[]) => void;
    deleteEmail: (deletedEmail: CoordonneesEmailCoordonneeUpdate) => void;
};

const EmailList: React.FunctionComponent<EmailListProps> = function (props) {
    const { emails, updateEmails, deleteEmail, isUpdating } = props;

    if (!emails) {
        return null;
    }

    const hasUnsetReminders = emails.some((email) => email.rappel === true);
    const hasUnsetNotifications = emails.some((email) => email.notification === true);

    const toggleNotification = (emailValue: string) => {
        const updatedEmails = emails.map((email) =>
            email.email === emailValue
                ? {
                      ...email,
                      notification: !email.notification,
                  }
                : email,
        );
        updateEmails(updatedEmails);
    };

    const toggleReminder = (emailValue: string) => {
        const updatedEmails = emails.map((email) =>
            email.email === emailValue
                ? {
                      ...email,
                      rappel: !email.rappel,
                  }
                : email,
        );
        updateEmails(updatedEmails);
    };

    const toggleList = (field: 'rappel' | 'notification', newListValue: boolean) => {
        const updatedEmails = emails.map((email) => ({
            ...email,
            [field]: newListValue,
        }));

        updateEmails(updatedEmails);
    };

    const isListEmpty = emails && emails.length === 0;

    const deletionItemColumn = {
        width: '25px',
    };

    return (
        <>
            <Box
                display="flex"
                justifyContent="space-between"
                sx={{ margin: '15px 0', opacity: '0.8' }}
            >
                <Box
                    display="flex"
                    alignItems="center"
                >
                    <EmailIcon
                        fontSize="medium"
                        sx={{ marginLeft: '15px' }}
                    />
                </Box>
                <Box
                    display="flex"
                    alignItems="center"
                >
                    <Tooltip title="Notification">
                        <NotificationsIcon fontSize="medium" />
                    </Tooltip>
                    <FlexyInput
                        sx={{ marginRight: '45px' }}
                        type="boolean"
                        name="list_rappel_switch"
                        value={hasUnsetNotifications}
                        disabled={isListEmpty || isUpdating}
                        onChange={() => toggleList('notification', !hasUnsetNotifications)}
                    />
                    <Tooltip title="Rappel">
                        <AlarmOnIcon fontSize="medium" />
                    </Tooltip>
                    <FlexyInput
                        sx={{ marginRight: '45px' }}
                        type="boolean"
                        name="list_rappel_switch"
                        value={hasUnsetReminders}
                        disabled={isListEmpty || isUpdating}
                        onChange={() => toggleList('rappel', !hasUnsetReminders)}
                    />
                    <Box
                        display="flex"
                        width={deletionItemColumn.width}
                        alignItems="center"
                    />
                </Box>
            </Box>
            <hr />

            <List
                sx={{
                    maxHeight: '50vh',
                    overflowY: 'auto',
                }}
            >
                {isListEmpty && (
                    <Box
                        width="100%"
                        display="flex"
                        justifyContent="center"
                        sx={{ opacity: '0.5' }}
                    >
                        <p>(Aucun email dans cette liste)</p>
                    </Box>
                )}

                {emails.map(
                    (emailItem: EmailPrescripteurReadNotificationsEmails, index: number) => {
                        const {
                            id,
                            email,
                            notification: isFormalProNotification,
                            rappel: isFormalProReminder,
                            hasContact,
                        } = emailItem;

                        const isEmailDeletable =
                            !hasContact && !isFormalProNotification && !isFormalProReminder;

                        return (
                            <ListItem
                                key={`notifications-email_list_${id}`}
                                sx={{
                                    backgroundColor: `${index % 2 === 0 ? '' : '#f6f6f6'}`,
                                }}
                            >
                                <ListItemText primary={email} />
                                <Box display="flex">
                                    <FlexyInput
                                        sx={{ marginRight: '70px' }}
                                        type="boolean"
                                        name="notification_switch"
                                        disabled={isUpdating}
                                        value={isFormalProNotification}
                                        onChange={() => toggleNotification(email)}
                                    />
                                    <FlexyInput
                                        sx={{ marginRight: '28px' }}
                                        type="boolean"
                                        name="rappel_switch"
                                        value={isFormalProReminder}
                                        disabled={isUpdating}
                                        onChange={() => toggleReminder(email)}
                                    />
                                </Box>
                                <Box
                                    display="flex"
                                    width={deletionItemColumn.width}
                                    alignItems="center"
                                >
                                    {isEmailDeletable && (
                                        <IconButton
                                            aria-label="delete"
                                            onClick={() => deleteEmail(emailItem)}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    )}
                                </Box>
                            </ListItem>
                        );
                    },
                )}
            </List>
        </>
    );
};

export default EmailList;
