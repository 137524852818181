import { createContext } from 'react';

export type MenuContextType = {
    activeTree: string[];
    setActiveTree: (value: string[]) => void;
    openElements: string[];
    setOpenElements: (value: string[]) => void;
};

const MenuContext = createContext<MenuContextType>({
    activeTree: [],
    setActiveTree: (value: string[]) => value,
    openElements: [],
    setOpenElements: (value: string[]) => value,
});

export default MenuContext;
