import { updateToken } from '@b2d/keycloak';
import {
    APIENV,
    APIS_ENUM,
    Configuration,
    ConfigurationParameters,
    getCustomiserState,
} from '@europrocurement/l2d-domain';

/** Retreive api base path based on Api name */
const getBasePathFromApiName = (name: APIS_ENUM) =>
    APIENV.apis[name][getCustomiserState().api[name]];

/** Generate base api configuration parameters based on Api name */
const getApiConfigurationParameters: (name: APIS_ENUM) => ConfigurationParameters = (name) => ({
    accessToken: updateToken,
    basePath: getBasePathFromApiName(name),
});

/** Generate an api configuration bases on ApiName */
export const getApiConfiguration: (name: APIS_ENUM) => Configuration = (name) => {
    const parameters = getApiConfigurationParameters(name);
    return new Configuration(parameters);
};
