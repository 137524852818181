import React from 'react';

import { useSelector } from 'react-redux';
import { Box } from '@mui/material';

import { Modalizer } from '@europrocurement/flexy-components';
import {
    ColumnDatatable,
    FiltersDatatableList,
    FlexyDatatableMaxHeight,
    StoreDatatable,
} from '@europrocurement/flexy-datatable';
import { DossierFormalite } from '@europrocurement/l2d-domain';
import { getDossierFormalite } from '@b2d/redux/subReducers/FormalityReducer';
import { RootStateType } from '../../../../redux/RootStore';

export type SearchDossierFormaliteModalProps = {
    isModalOpen: boolean;
    isDraggable?: boolean;
    closeModal?: (dossier: DossierFormalite | null) => void;
};

const SearchDossierFormaliteModal: React.FunctionComponent<SearchDossierFormaliteModalProps> =
    function ({ isModalOpen, isDraggable, closeModal }) {
        const dossiersFormaliteDatasource = useSelector(
            (s: RootStateType) => s.formalites.dosform.main,
        );

        const columns: ColumnDatatable<DossierFormalite>[] = [
            {
                label: 'Numéro Dossier Formalite',
                render: 'numero',
                isDisplayed: true,
            },
            {
                label: 'Dénomination société',
                render: 'societeDenomination',
                isDisplayed: true,
            },
            {
                label: 'Numéro DematFacile',
                render: 'idDematfacile',
                isDisplayed: true,
            },
            {
                label: 'Actions',
                onClickCell: () => {},
                render: () => null,
                isDisplayed: true,
            },
        ];

        const filters: FiltersDatatableList = [
            {
                field: 'societeDenomination',
                label: 'Dénomination société',
                type: 'text',
            },
            {
                field: 'idDematfacile',
                label: 'Numéro DematFacile',
                type: 'number',
            },
        ];

        return (
            <Modalizer
                open={isModalOpen}
                onClose={() => {
                    if (closeModal) closeModal(null);
                }}
                maxWidth="lg"
                fullWidth
                gridSx={{
                    padding: '0px',
                }}
                isDraggable={isDraggable}
            >
                <Box
                    sx={{
                        height: FlexyDatatableMaxHeight,
                    }}
                >
                    <StoreDatatable
                        dataSource={dossiersFormaliteDatasource}
                        columns={columns}
                        fetchData={getDossierFormalite}
                        filters={filters}
                        isSearch={false}
                        mode="raw"
                        fetchWithoutFilter={false}
                        onClickRow={(e: React.MouseEvent, dossier: DossierFormalite) => {
                            if (closeModal) closeModal(dossier);
                        }}
                    />
                </Box>
            </Modalizer>
        );
    };

export default SearchDossierFormaliteModal;
