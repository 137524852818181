import React, { useCallback } from 'react';

import { useLocation, useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/pro-duotone-svg-icons';
import {
    FactureAchatApiDeleteFactureAchatFactureAchatItemRequest,
    MediaObjectApiPatchMediaObjectItemRequest,
} from '@europrocurement/l2d-domain/openApi/ApiAchats';
import { ContextActionType, useModal } from '@europrocurement/flexy-components';
import { FactureAchat } from '@europrocurement/l2d-domain';
import ConfirmationModalContent from '@b2d/pages/Achats/components/modals/ConfirmationModalContent';
import { customizerSelector, factureAchatApi, mediaObjectApi } from '@b2d/redux/RootStore';
import generateB2DPath, { GenerateB2DPathResponseType } from '@b2d/utils/generateB2DPath';
import { removeFactureModalMessages } from '@b2d/pages/Achats/constants/wording/modals';
import useApiRequest from '@b2d/hooks/useApiRequest';
import { filtersHasher } from '@europrocurement/l2d-utils';

export type DeleteInvoiceProps = {
    invoice: FactureAchat;
};

const useDeleteInvoice = ({ invoice }: DeleteInvoiceProps): ContextActionType => {
    const { xIdSociete } = useSelector(customizerSelector);
    const { modalActions } = useModal();
    const { request } = useApiRequest();
    const location = useLocation();
    const navigate = useNavigate();

    const exitFormAfterDelete = useCallback(async () => {
        let response: GenerateB2DPathResponseType = {
            status: 'STAY',
        };

        if (
            location.pathname.match(/\/achats\/liste\/modifier/) ||
            location.pathname.match(/\/achats\/liste\/recap/)
        )
            response = generateB2DPath('listfacture');

        if (response.status === 'OK') {
            navigate(response.path);
        }
    }, [location.pathname, navigate]);

    const onDelete = useCallback(
        async (factureAchatToDelete: FactureAchat, isMediaObjectDelete?: boolean) => {
            if (!factureAchatToDelete.id) return;

            let mediaObjectId: string | null = null;

            if (factureAchatToDelete.pdfFacture && factureAchatToDelete.pdfFacture.id) {
                mediaObjectId = factureAchatToDelete.pdfFacture.id?.toString();
            }

            const successCallback = () => {
                // Pour l'instant ne s'applique qu'à la liste des factures en pause.
                // Plus tard, si on ajoute d'autres listes ce dispatch devra peut-être changer.

                exitFormAfterDelete();

                if (isMediaObjectDelete && mediaObjectId) {
                    const requestParameters: MediaObjectApiPatchMediaObjectItemRequest = {
                        id: mediaObjectId,
                        mediaObject: {
                            deleted: true,
                        },
                    };

                    request(mediaObjectApi.patchMediaObjectItem(requestParameters), {
                        withToaster: true,
                    });
                }
            };

            const requestParameters: FactureAchatApiDeleteFactureAchatFactureAchatItemRequest = {
                id: factureAchatToDelete.id.toString(),
                xIdSociete,
            };

            request(factureAchatApi.deleteFactureAchatFactureAchatItem(requestParameters), {
                withToaster: true,
                successCallback,
            });
        },
        [exitFormAfterDelete, request, xIdSociete],
    );

    return {
        name: 'Supprimer la facture',
        icon: (
            <FontAwesomeIcon
                icon={faTrashCan}
                size="xl"
            />
        ),
        action: () => {
            modalActions.call(
                <ConfirmationModalContent
                    key={`${invoice?.['@id']}-${removeFactureModalMessages}`}
                    messages={removeFactureModalMessages}
                    actionOnValidation={() => {
                        onDelete(invoice, true);
                    }}
                    actionOnAlternative={() => {
                        onDelete(invoice, false);
                    }}
                />,
            );
        },
        resetHash: filtersHasher(invoice),
    };
};

export default useDeleteInvoice;
