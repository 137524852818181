import { LazyBreadcrumb } from '@europrocurement/flexy-components';
import React from 'react';
import { Box, Card, Grid } from '@mui/material';
import { useNavigate } from 'react-router';
import { FORMALITY_LIST } from '../../constants/paths';
import FormalityForm from './FormalityForm';
import useFormalityFormHandler from '../../hooks/useFormalityFormHandler';

const FormalityAdd: React.FunctionComponent = function () {
    const navigate = useNavigate();
    const { handleCreateForm, requestState } = useFormalityFormHandler();
    const onSubmit = (formData: { [key: string]: unknown }) => {
        handleCreateForm(formData).then((result: { data: { id: number } }) => {
            const formaliteId = result.data?.id;
            if (formaliteId) {
                navigate(`${FORMALITY_LIST}/${formaliteId}`);
            }
        });
    };
    const onCancel = () => {
        navigate(FORMALITY_LIST);
    };

    return (
        <>
            <LazyBreadcrumb
                path={[
                    { path: 'Formalités', link: FORMALITY_LIST },
                    'Nouvelle formalité',
                    "Création d'une formalité",
                ]}
            />
            <Box>
                <Grid
                    container
                    spacing={2}
                >
                    <Grid
                        item
                        xs={12}
                        lg={6}
                    >
                        <Card>
                            <FormalityForm
                                onSubmit={onSubmit}
                                onCancel={onCancel}
                                isFormProcessing={requestState.isProcessing}
                            />
                        </Card>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default React.memo(FormalityAdd);
