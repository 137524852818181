import axios, { AxiosError } from 'axios';

type HandleErrorProps = {
    error: unknown;
};

function handleError({ error }: HandleErrorProps): Error {
    if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;

        if (axiosError.response) {
            const { status, data } = axiosError.response;

            switch (status) {
                case 400:
                    return new Error(`Bad Request: ${data}`);
                case 401:
                    return new Error(`Unauthorized: ${data}`);
                case 404:
                    return new Error(`Not Found: ${data}`);
                case 500:
                    return new Error(`Internal Server Error: ${data}`);
                default:
                    return new Error(`Error: ${status} - ${data}`);
            }
        }

        return new Error(`Error: ${axiosError.message}`);
    }

    const genericError = error as Error;
    return new Error(`Error: ${genericError.message}`);
}

export default handleError;
