import React, { createContext, ReactNode } from 'react';

export type closeModalType = (onCloseEvent?: () => void) => void;
export type callModalType = (
    modalContentToCall: ReactNode,
    isModalToCallDraggable?: boolean,
    isModalToCallFullWidth?: boolean,
) => void;

export type openModalType = () => void;
export type resetModalType = () => void;
export type setContentType = React.Dispatch<React.SetStateAction<ReactNode>>;

export type modalActionsType = {
    close: closeModalType;
    call: callModalType;
    open: openModalType;
    reset: resetModalType;
    setContent: setContentType;
};

export type modalStatusType = {
    isOpen: boolean;
    content: ReactNode;
    isDraggable: boolean;
    isFullWidth: boolean;
};

export type ModalContextType = {
    modalStatus: modalStatusType;
    modalActions: modalActionsType;
};

export const ModalContext = createContext<ModalContextType>({
    modalStatus: {
        content: null,
        isDraggable: false,
        isFullWidth: false,
        isOpen: false,
    },
    modalActions: {
        close: () => {
            console.warn('closeModal to initialisate');
        },
        call: () => {
            console.warn('callModal to initialisate');
        },
        open: () => {
            console.warn('openModal to initialisate');
        },
        reset: () => {
            console.warn('resetModal to initialisate');
        },
        setContent: () => {
            console.warn('setContentModal to initialisate');
        },
    },
});
