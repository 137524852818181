import React from 'react';

import { dossierSignatureApi } from '@b2d/redux/subReducers';
import { Base64File, useUploader } from '@europrocurement/flexy-components';
import { DossierJsonldWrite } from '@europrocurement/l2d-domain/openApi/ApiSign';
import { Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { AxiosError } from 'axios';

export const useCreateDossierSignature = () => {
    const { enqueueSnackbar } = useSnackbar();

    const { base64Ready, filesBase64, files } = useUploader();

    return async function (formData: Partial<DossierJsonldWrite>, callback?: (id: number) => void) {
        console.log('start submit', base64Ready, filesBase64, files);

        if (!base64Ready || filesBase64.length === 0) {
            console.log(base64Ready && filesBase64.length);
            throw new Error('Aucun fichier');
        }
        try {
            const result = await dossierSignatureApi.postDossierCollection({
                dossierJsonldWrite: {
                    ...(formData as DossierJsonldWrite),
                    documents: [
                        ...filesBase64
                            .filter((item) => item !== false && item !== 'error')
                            .map((item: Base64File) => ({
                                nom: item.nom,
                                base64: item.base64.split(',')[1],
                            })),
                    ] as Base64File[],
                },
            });
            if (callback && result.data.id) {
                callback(result.data.id);
            }
        } catch (error) {
            if (
                error instanceof AxiosError &&
                error.response &&
                error.response.data.exception &&
                error.response.data.exception.message
            ) {
                enqueueSnackbar(<Typography>{error.response.data.exception.message}</Typography>, {
                    variant: 'error',
                });
            } else if (error instanceof Error) {
                enqueueSnackbar(<Typography>{error.message}</Typography>, {
                    variant: 'error',
                });
            }
        }
        console.log('end submit');
    };
};
