import { hexToRgba } from '@europrocurement/l2d-utils';
import useOnHover from './useOnHover';

const useListItemHoverEffect = () => {
    const { onHoverProps, onHover } = useOnHover();
    const opacityFilter = onHover ? 0.05 : 0.01;

    const getBackgroundColor = () => {
        const hexColor = '#000000';
        return hexToRgba(hexColor, opacityFilter);
    };

    const styleProps = {
        style: {
            background: getBackgroundColor(),
            transition: 'background 0.3s',
            margin: '5px',
        },
    };
    return {
        ...styleProps,
        ...onHoverProps,
    };
};
export default useListItemHoverEffect;
