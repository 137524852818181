import useDomain from '@b2d/hooks/useDomain';
import { useCallback, useMemo } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { FormObject } from '@europrocurement/flexy-form';
import { useSelector } from 'react-redux';
import { FactureFormSelector, RootStateType } from '@b2d/redux/RootStore';
import { Fournisseur } from '@europrocurement/l2d-domain';
import _ from 'lodash';
import { defaultProduits, replaceProduits } from '../components/forms/functions/produitFunctions';
import { useResetFactureAchatState } from '../components/forms/functions/generalFunctions';

const useActionOnExitForm = () => {
    const publisherSelected: Fournisseur | undefined = useSelector(
        (s: RootStateType) => s.tiers.fournisseur.autocompleteFactures.selected,
        _.isEqual,
    );

    const state = useSelector(FactureFormSelector);
    const { resetDomain } = useDomain();
    const resetStates = useResetFactureAchatState();

    const isRsf = useMemo<boolean>(() => {
        const discountTypeAuthorized = ['RSF', 'RSF Inclus'];
        const publisherDiscountType = publisherSelected
            ? publisherSelected?.typeRemise?.code
            : undefined;
        return !!publisherDiscountType && discountTypeAuthorized.includes(publisherDiscountType);
    }, [publisherSelected]);

    type DefineDefaultProductsProps = {
        formContext: UseFormReturn<FormObject>;
    };

    const defineDefaultProducts = useCallback(
        ({ formContext }: DefineDefaultProductsProps) => {
            replaceProduits(formContext, defaultProduits(state.defDomain, isRsf));
        },
        [isRsf, state.defDomain],
    );

    type ActionOnExitFormProps = {
        formContext: UseFormReturn<FormObject>;
    };

    const actionOnExitForm = useCallback(
        ({ formContext }: ActionOnExitFormProps) => {
            defineDefaultProducts({ formContext });
            resetStates({ formContext });
            resetDomain();
        },
        [defineDefaultProducts, resetDomain, resetStates],
    );

    return {
        isRsf,
        defineDefaultProducts,
        actionOnExitForm,
    };
};

export default useActionOnExitForm;
