import * as React from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { MultiStepHook } from './useMultiStep';

export type StepFormHook = { isStepValidating: boolean };

/** Custom hook that contains step form logic :
 * - Form step validity check
 * - Step submit status
 */
const useStepForm = (
    formContext: UseFormReturn<FieldValues, unknown>,
    useMultiStep: MultiStepHook,
): StepFormHook => {
    const {
        formState: { isValid: isCurrentStepValid },
    } = formContext;

    const {
        setIsStepValid,
        stepState: { isStepValid, isStepValidating },
    } = useMultiStep;

    React.useEffect(() => {
        if (isCurrentStepValid !== isStepValid) {
            setIsStepValid(formContext.formState.isValid);
        }
    }, [formContext, isCurrentStepValid, isStepValid, setIsStepValid, useMultiStep]);

    return { isStepValidating };
};
export default useStepForm;
