import {
    OffresJsonldOffreRead,
    OffresOffreCreate,
    OffresOffreUpdate,
} from '@europrocurement/l2d-domain/openApi/ApiOffre';
import { OfferSchema } from './schema';

/** API -> Form -> API Serialization */

// TODO Types

export const input: (entity?: OffresJsonldOffreRead) => OfferSchema = (entity = undefined) => {
    const defaultValues = {
        label: '',
        visibility: 'FULL' as const,
        domainId: null as unknown as number,
        iconeName: '',
    };

    if (!entity) {
        return defaultValues;
    }

    // Field renaming by aliasing destructured api object
    const { libelle: label, visibilite: visibility, domaine: domain, iconeName } = entity;

    return {
        label: label ?? defaultValues.label,
        visibility: visibility ?? defaultValues.visibility,
        domainId: domain?.id as number,
        iconeName: iconeName ?? defaultValues.iconeName,
    };
};

/** Format request payload to match Api expectations */
export const output = (formData: OfferSchema): OffresOffreCreate | OffresOffreUpdate => {
    const { label, visibility, domainId, iconeName } = formData;

    const createPayload: OffresOffreCreate = {
        libelle: label,
        visibilite: visibility,
        domaine: domainId as unknown as string, // API type ask string but expect number ......
        iconeName,
    };
    // const updatePayload: OffresOffreUpdate = {};

    return createPayload;
};
