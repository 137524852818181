import * as React from 'react';
import { Typography } from '@mui/material';
import { footerHeight } from '../../../assets';

export type FooterProps = {
    text?: string;
    children?: React.ReactNode;
};
/**
 * Permet de mettre en form le Footer en centrant son contenu et en appliquant un padding
 */
const Footer: React.FunctionComponent<FooterProps> = function ({ text, children }: FooterProps) {
    if (children) {
        return (
            <footer
                style={{
                    padding: 3,
                    textAlign: 'center',
                    maxHeight: `${footerHeight}`,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '5vh',
                }}
            >
                {children}
            </footer>
        );
    }
    return (
        <footer
            style={{
                padding: 3,
                textAlign: 'center',
                maxHeight: `${footerHeight}`,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '5vh',
            }}
        >
            <Typography>{text || 'Legal2digital 2023'}</Typography>
        </footer>
    );
};

export default Footer;
