import React from 'react';

import { ColumnDatatable } from '@europrocurement/flexy-datatable';
import { FactureAchatApiObject, MediaObject } from '@europrocurement/l2d-domain';
import { Chip } from '@mui/material';
import { NavigateFunction } from 'react-router';

const columnItemsAssociatedPurchaseInvoice = (
    navigate: NavigateFunction,
): ColumnDatatable<MediaObject> => ({
    label: 'Facture liée',
    onClickCell: () => {},
    render: (mediaObject: MediaObject) =>
        !mediaObject.facturesAchat || mediaObject.facturesAchat.length === 0
            ? null
            : mediaObject.facturesAchat.map((facture: FactureAchatApiObject) => (
                  <Chip
                      color="warning"
                      label={`${facture.numeroFacture}`}
                      size="small"
                      key={`${facture.numeroFacture}-${facture.id}-${mediaObject.id}`}
                      onClick={() => {
                          navigate(`modifier/${facture.id}/${mediaObject.id}`);
                      }}
                      sx={{
                          color: '#3e3e3e', // Nécessaire pour l'accessibilité
                      }}
                  />
              )),
    isDisplayed: false,
});

export default columnItemsAssociatedPurchaseInvoice;
