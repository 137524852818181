import React from 'react';
import { Card, Typography } from '@mui/material';

type CardTitleProps = {
    title: string;
};

const CardTitle: React.FunctionComponent<CardTitleProps> = function (props) {
    const { title } = props;

    return (
        <Card>
            <Typography fontSize="20px">{title}</Typography>
        </Card>
    );
};

export default CardTitle;
