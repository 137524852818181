import { ENV_API } from '@europrocurement/l2d-domain';

export function windowBaseUrl() {
    return `${window.location.protocol}//${window.location.host}`;
}

export function envBaseUrl(env: ENV_API) {
    if (env === 'dev' || env === 'local') {
        return windowBaseUrl();
    }

    return `https://back.${env}.legal2digital.net`;
}

export default { windowBaseUrl, envBaseUrl };
