import { useContext } from 'react';
import VerboseContext from './VerboseContext';

/**
 * Gives tools to define verbose messages and then display them or not.
 */
const useVerbose = () => {
    const { enable, level, setLevel, log } = useContext(VerboseContext);

    return {
        enable,
        level,
        setLevel,
        log,
    };
};

export default useVerbose;
