import { NamedIconsType } from '@europrocurement/l2d-icons/types';
import {
    faAdd,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faEdit,
    faEye,
    faSearch,
    faUser,
} from '@fortawesome/pro-duotone-svg-icons';

export const createIcon: NamedIconsType = {
    props: { icon: faAdd },
    name: 'create',
    displayName: 'create',
};

export const updateIcon: NamedIconsType = {
    props: { icon: faEdit },
    name: 'update',
    displayName: 'update',
};

export const searchIcon: NamedIconsType = {
    props: { icon: faSearch },
    name: 'search',
    displayName: 'search',
};

export const clearIcon: NamedIconsType = {
    props: { icon: faUser },
    name: 'clear',
    displayName: 'clear',
};

export const refreshIcon: NamedIconsType = {
    props: { icon: faUser },
    name: 'refresh',
    displayName: 'refresh',
};

export const previewIcon: NamedIconsType = {
    props: {
        icon: faEye,
    },
    name: 'preview',
    displayName: 'preview',
};

export const downIcon: NamedIconsType = {
    props: { icon: faChevronDown },
    name: 'down',
    displayName: 'down',
};

export const upIcon: NamedIconsType = {
    props: { icon: faChevronUp },
    name: 'up',
    displayName: 'up',
};

export const rightIcon: NamedIconsType = {
    props: { icon: faChevronRight },
    name: 'right',
    displayName: 'right',
};

export const leftIcon: NamedIconsType = {
    props: { icon: faChevronLeft },
    name: 'left',
    displayName: 'left',
};

export const userActionIcons: NamedIconsType[] = [
    createIcon,
    updateIcon,
    searchIcon,
    clearIcon,
    refreshIcon,
    previewIcon,
    downIcon,
    upIcon,
    rightIcon,
    leftIcon,
];
