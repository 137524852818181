import {
    faCloudArrowUp,
    faCloudArrowDown,
    faFile,
    faFileJpg,
    faFilePdf,
    faFilePng,
} from '@fortawesome/pro-duotone-svg-icons';
import { faBracketsCurly } from '@fortawesome/pro-solid-svg-icons';
import { NamedIconsType } from '../../types/types';

export const fileIcon: NamedIconsType = {
    props: { icon: faFile },
    name: 'fichier',
    displayName: 'Fichier',
};

export const filePdfIcon: NamedIconsType = {
    props: { icon: faFilePdf },
    name: 'fichierPDF',
    displayName: 'Fichier PDF',
};
export const fileJpgIcon: NamedIconsType = {
    props: { icon: faFileJpg },
    name: 'fichierJPG',
    displayName: 'Fichier JPG',
};
export const filePngIcon: NamedIconsType = {
    props: { icon: faFilePng },
    name: 'fichierPNG',
    displayName: 'Fichier PNG',
};

export const fileJsonIcon: NamedIconsType = {
    props: { icon: faBracketsCurly },
    name: 'fichierJson',
    displayName: 'Fichier Json',
};

export const downloadIcon: NamedIconsType = {
    props: { icon: faCloudArrowDown },
    name: 'download',
    displayName: 'Télécharger',
};

export const uploadIcon: NamedIconsType = {
    props: { icon: faCloudArrowUp },
    name: 'upload',
    displayName: 'Uploader',
};

export const fileNamedIcons: NamedIconsType[] = [
    fileIcon,
    filePdfIcon,
    fileJpgIcon,
    filePngIcon,
    uploadIcon,
    downloadIcon,
    fileJsonIcon,
];
