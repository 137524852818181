import React, { useCallback } from 'react';

import { useSelector } from 'react-redux';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { faServer } from '@fortawesome/pro-duotone-svg-icons';
import { faRotate } from '@fortawesome/pro-solid-svg-icons';
import { Typography } from '@mui/material';
import { FactureAchatApiSyncG3FactureAchatFactureAchatItemRequest } from '@europrocurement/l2d-domain/openApi/ApiAchats';
import { ContextActionType } from '@europrocurement/flexy-components';
import { FactureAchat } from '@europrocurement/l2d-domain';
import { FaOptionIcon } from '@europrocurement/l2d-icons';
import { customizerSelector, factureAchatApi } from '@b2d/redux/RootStore';

export type SyncG3Props = {
    invoice?: FactureAchat;
    forced?: boolean;
};

const useSyncG3 = ({ invoice, forced = true }: SyncG3Props): ContextActionType => {
    const { enqueueSnackbar } = useSnackbar();
    const { xIdSociete } = useSelector(customizerSelector);

    const synchroG3Facture = useCallback(
        async (factureAchatSelectedToSync: FactureAchat) => {
            if (!factureAchatSelectedToSync.id) {
                enqueueSnackbar(
                    <Typography>
                        Erreur technique la facture n&apos;a pas d&apos;identifiant
                    </Typography>,
                    { variant: 'warning' },
                );
                return;
            }
            try {
                const requestParameters: FactureAchatApiSyncG3FactureAchatFactureAchatItemRequest =
                    {
                        xIdSociete,
                        id: `${factureAchatSelectedToSync.id}`,
                        factureAchatJsonldFactureAchatSyncG3: { syncG3: true, forced },
                    };

                const res =
                    await factureAchatApi.syncG3FactureAchatFactureAchatItem(requestParameters);

                if (res.status >= 200 && res.status < 300) {
                    enqueueSnackbar(<Typography>Synchronisation effectuée</Typography>, {
                        variant: 'success',
                    });
                } else {
                    throw new Error(`Erreur ${res.status}`);
                }
            } catch (error) {
                if (
                    error instanceof AxiosError &&
                    error.response &&
                    error.response.data.exception &&
                    error.response.data.exception.message
                ) {
                    enqueueSnackbar(
                        <Typography>{error.response.data.exception.message}</Typography>,
                        {
                            variant: 'error',
                        },
                    );
                } else if (error instanceof Error) {
                    enqueueSnackbar(<Typography>{error.message}</Typography>, {
                        variant: 'error',
                    });
                }
            }
        },
        [enqueueSnackbar, xIdSociete, forced],
    );

    return {
        name: 'Synchro G3',
        icon: (
            <FaOptionIcon
                icon={faServer}
                option={faRotate}
                size="1x"
                color="blueGrey.main"
                optionColor="blueGrey.dark"
                iconProps={{
                    radius: 100,
                }}
            />
        ),
        action: () => {
            if (invoice) {
                synchroG3Facture(invoice);
            }
        },
    };
};

export default useSyncG3;
