import { Typography } from '@mui/material';
import { Box, SxProps } from '@mui/system';
import React, { FunctionComponent } from 'react';

export type InformationsProps = {
    /**
     * Label of the information
     */
    label: string;
    /**
     * Value of the information
     */
    value: string | undefined;
    /**
     * Additional render function, this will be rendered at the right of the value
     */
    additionalRender?: () => React.ReactNode;
    /**
     * Custom style for typography
     */
    sx?: SxProps;
};

const Information: FunctionComponent<InformationsProps> = function ({
    label,
    value,
    additionalRender,
    sx = {},
}) {
    const displayedValue = value || '- - -';
    return (
        <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{ paddingRight: '30px' }}
            data-testid="information-wrapper"
        >
            <Typography
                data-testid="information"
                sx={{ ...sx }}
            >
                <b>{label} : </b>
                {displayedValue}
            </Typography>
            {additionalRender && additionalRender()}
        </Box>
    );
};

export default Information;
