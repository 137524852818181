import * as React from 'react';
import { FlexyAccordeon, FlexySelect } from '@europrocurement/flexy-components';
import {
    CategorieSocioProfessionnelleJsonldTiersRead,
    PortefeuilleJsonldTiersRead,
    UtilisateurJsonldTiersRead,
} from '@europrocurement/l2d-domain/openApi/ApiTiers';
import { Box, CircularProgress, Grid, Skeleton, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootStateType, customizerSelector, prescribersApi } from '@b2d/redux/RootStore';
import useApiRequest from '@b2d/hooks/useApiRequest';
import { usePrescriberUtils } from '@b2d/hooks/prescriberHooks';
import {
    CSP_OPTIONS_SLICE_NAME,
    TIERS_REDUCER_NAME,
    WALLET_OPTIONS_SLICE_NAME,
} from '@europrocurement/l2d-domain';
import { useSociete } from '@b2d/hooks/societeHooks';
import { getPrescripteurCompanyRelationPayload } from '../../forms/payloads';

type UpdatableGroup = PortefeuilleJsonldTiersRead | CategorieSocioProfessionnelleJsonldTiersRead;

const AccordionsSideBar: React.FunctionComponent = function () {
    const {
        prescriber,
        companyRelation,
        methods: { reloadPrescriber },
    } = usePrescriberUtils();

    const companyId = useSociete();
    const { request, requestState } = useApiRequest();
    const { xIdSociete } = useSelector(customizerSelector);

    const cspOptions: CategorieSocioProfessionnelleJsonldTiersRead[] = useSelector(
        (store: RootStateType) => store[TIERS_REDUCER_NAME][CSP_OPTIONS_SLICE_NAME].main.data,
    );

    const walletOptions: PortefeuilleJsonldTiersRead[] = useSelector(
        (store: RootStateType) => store[TIERS_REDUCER_NAME][WALLET_OPTIONS_SLICE_NAME].main.data,
    );

    const CSP_NONE_ID = 0;
    const WALLET_NON_SELECTED_ID = 1;

    const defaultCspOption =
        cspOptions.find(({ id }: { id: number }) => id === CSP_NONE_ID) || cspOptions[0];

    const defaultWalletOption =
        walletOptions.find(({ id }: { id: number }) => id === WALLET_NON_SELECTED_ID) ||
        walletOptions[0];

    const [cspOptionValue, setCspOptionValue] = React.useState<UpdatableGroup>(defaultCspOption);
    const [walletOptionValue, setWalletOptionValue] =
        React.useState<UpdatableGroup>(defaultWalletOption);

    React.useEffect(() => {
        const walletId = companyRelation?.portefeuille?.id;
        const cspId = companyRelation?.categorieSocioProfessionnelle?.id;

        if (walletId && cspId) {
            const prescriberWalletOption = walletOptions.find(({ id }) => id === walletId);
            const prescriberCspOption = cspOptions.find(({ id }) => id === cspId);
            setWalletOptionValue(prescriberWalletOption as PortefeuilleJsonldTiersRead);
            setCspOptionValue(prescriberCspOption as CategorieSocioProfessionnelleJsonldTiersRead);
        }
    }, [companyRelation, cspOptions, walletOptions]);

    if (!companyRelation || !prescriber) {
        return <CircularProgress />;
    }

    const formatUserName = (utilisateur: UtilisateurJsonldTiersRead | undefined): string => {
        if (!utilisateur) {
            return '';
        }
        return `${utilisateur?.prenom} ${utilisateur?.nom}`;
    };

    const handleGroupUpdate = (group: UpdatableGroup) => {
        if (!group || !group['@type'] || !prescriber.id) {
            return;
        }

        const lowerCaseFirstLetter = (string: string) => {
            const firstLetter = string.charAt(0);
            return string.replace(firstLetter, firstLetter.toLowerCase());
        };

        const groupKey = lowerCaseFirstLetter(group['@type']);
        const basePayload = getPrescripteurCompanyRelationPayload(companyRelation);

        const payload = {
            ...basePayload,
            [groupKey]: { id: group.id },
        };
        if (
            !payload.categorieSocioProfessionnelle ||
            !payload.categorieSocioProfessionnelle.id ||
            payload.categorieSocioProfessionnelle.id === undefined
        ) {
            throw new Error('missing categorieSocioProfessionnelle');
            return;
        }
        // console.log(` xIdSociete ${xIdSociete}`);
        // console.log(` tiers ${prescriber.id}`);
        console.log(payload);
        const groupUpdateRequest =
            prescribersApi.updateRelationSocietePrescripteurRelationSocieteTiersItem({
                xIdSociete,
                tiers: prescriber.id,
                relationSocieteTiersRelationSocieteTiersJsonldPrescripteurWriteRelationSociete:
                    payload,
                societe: companyId.toString(),
            });
        console.log(groupUpdateRequest);

        /* dossierSignatureApi.postDossierCollection({
                    dossierJsonldWrite: {
                        ...(formData as DossierJsonldWrite),
                        documents: [
                            ...filesBase64
                                .filter((item) => item !== false && item !== 'error')
                                .map((item: Base64File) => ({
                                    nom: item.nom,
                                    base64: item.base64.split(',')[1],
                                })),
                        ] as Base64File[],
                    },
                }) */

        // Set temporary value / waiting Api response
        if (groupKey === 'portefeuille') {
            setWalletOptionValue(group);
        }

        if (groupKey === 'categorieSocioProfessionnelle') {
            setCspOptionValue(group);
        }

        request(groupUpdateRequest, {
            successCallback: () => reloadPrescriber(),
            // failureCallback(error) {
            //     console.log('errrrrror');
            // },
        });
    };

    const groupSelectProps = {
        getOptionLabel: ({ libelle }: { libelle: string }) => libelle,
        getOptionValue: ({ id }: { id: string }) => id,
        onChange: handleGroupUpdate,
        isDisabled: requestState.isProcessing,
    };
    const accordions = [
        {
            title: 'Informations générales',
            content: (
                <>
                    <Box marginBottom={2}>
                        <Typography
                            marginBottom={2}
                            fontWeight="bold"
                        >
                            Portefeuille :
                        </Typography>
                        <FlexySelect
                            options={walletOptions}
                            value={walletOptionValue}
                            {...groupSelectProps}
                        />
                    </Box>

                    <Box marginBottom={2}>
                        <Typography
                            marginBottom={2}
                            fontWeight="bold"
                        >
                            Categorie socioprofessionnelle :
                        </Typography>
                        <FlexySelect
                            options={cspOptions}
                            value={cspOptionValue}
                            {...groupSelectProps}
                        />
                    </Box>
                </>
            ),
            defaultExpanded: true,
        },
        {
            title: 'Annonces Légales',
            fakeContent: true,
            content: (
                <Typography>
                    <b>Commercial : </b> <br />
                </Typography>
            ),
        },
        {
            title: 'Formalités',
            content: (
                <>
                    <Typography marginBottom={2}>
                        <b>Commercial : </b> <br />
                    </Typography>
                    <Typography>
                        <b>Formaliste : </b> <br />
                        {formatUserName(prescriber.formaliste)}
                    </Typography>
                </>
            ),
        },
        {
            title: 'Commerce',
            fakeContent: true,
            content: (
                <Typography>
                    <b>Commercial : </b> <br />
                </Typography>
            ),
        },
        {
            title: 'Comptabilité',
            fakeContent: true,
            content: (
                <>
                    <Typography marginBottom={2}>
                        <b>encours : </b> <br /> #TODO
                    </Typography>
                    <Typography>
                        <b>
                            {' '}
                            délai de paiement : <br />{' '}
                        </b>{' '}
                        #TODO
                    </Typography>
                </>
            ),
        },
    ];

    return (
        <>
            {accordions.map(({ title, fakeContent, content, defaultExpanded }) => {
                const randomInt = (min: number, max: number) =>
                    Math.floor(Math.random() * (max - min) + min);

                const fakeUi = (
                    <Box>
                        <Skeleton
                            animation={false}
                            variant="rectangular"
                            width="100%"
                            sx={{ margin: '10px 0px' }}
                        />
                        <Skeleton
                            animation={false}
                            variant="rectangular"
                            width="100%"
                            sx={{ margin: '10px 0px' }}
                        />
                        <Skeleton
                            animation={false}
                            variant="rectangular"
                            width={`${randomInt(60, 100)}%`}
                            sx={{ margin: '10px 0px' }}
                        />
                    </Box>
                );

                const accordionContent = fakeContent ? fakeUi : content;
                return (
                    <Grid
                        key={`grid_item_${title}`}
                        item
                    >
                        <FlexyAccordeon
                            defaultExpanded={defaultExpanded ?? false}
                            accordionSummary={
                                <Typography
                                    fontSize="1.125rem"
                                    fontWeight="bold"
                                >
                                    {title}
                                </Typography>
                            }
                            accordionDetails={accordionContent}
                        />
                    </Grid>
                );
            })}
        </>
    );
};

export default AccordionsSideBar;
