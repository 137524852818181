import React from 'react';
import { Formalite } from '@europrocurement/l2d-domain/reducers/formalites/slices/formalite';
import {
    TableBody as MaterialTableBody,
    TableCell as MaterialTableCel,
    Typography,
} from '@mui/material';
import { Box, useTheme } from '@mui/system';
import { FaOptionIcon } from '@europrocurement/l2d-icons';
import TableRow from './TableRow';
import useIncompatibility from '../../hooks/useIncompatibility';

export type TableBodyProps = {
    formalities: Formalite[];
    incompatibilities: Formalite[];
    onChange: (formality: Formalite, incompatibility: Formalite) => void;
};

const TableBody: React.FunctionComponent<TableBodyProps> = function (props) {
    const { formalities, incompatibilities, onChange } = props;
    const { getDistinctCategoriesMap } = useIncompatibility();
    const distinctCategoriesMap = getDistinctCategoriesMap(formalities);
    const theme = useTheme();
    const borderColor = theme.palette.grey[300];
    let currentCategory = 0;

    const rows = formalities.map((formality: Formalite) => {
        let categoryCel = null;
        const categoryId = formality?.categorie?.id || 0;

        if (currentCategory !== categoryId) {
            currentCategory = categoryId;
            const mapItem = distinctCategoriesMap.get(currentCategory);

            categoryCel = (
                <MaterialTableCel
                    sx={{
                        padding: '0px',
                        width: '40px',
                        boxSizing: 'border-box',
                        writingMode: 'vertical-rl',
                        textAlign: 'center',
                        verticalAlign: 'middle',
                        borderLeft: `solid 1px ${borderColor}`,
                        borderBottom: `solid 1px ${borderColor}`,
                        lineHeight: '40px',
                    }}
                    rowSpan={mapItem.count}
                >
                    <Box
                        sx={{
                            display: 'flex',
                        }}
                    >
                        <div style={{ transform: 'rotate(180deg)' }}>{mapItem.label}</div>
                        {mapItem?.iconeName ? (
                            <Typography
                                sx={{
                                    fontSize: '18px',
                                    textAlign: 'center',
                                    marginTop: '10px',
                                }}
                                style={{ transform: 'rotate(-90deg)' }}
                            >
                                <FaOptionIcon icon={['fad', mapItem.iconeName]} />
                            </Typography>
                        ) : null}
                    </Box>
                </MaterialTableCel>
            );
        }

        return (
            <TableRow
                key={`table-row-${formality.id}`}
                formality={formality}
                formalities={incompatibilities}
                onChange={onChange}
                categoryTableCel={categoryCel}
            />
        );
    });

    return <MaterialTableBody>{rows}</MaterialTableBody>;
};

export default TableBody;
