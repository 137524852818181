/* eslint-disable @typescript-eslint/no-explicit-any */
import { useSnackbar } from 'notistack';
import { ToastMessages, defaultMessages } from '@b2d/pages/Achats/constants/wording/toasts';
import { useVerbose } from '@europrocurement/flexy-components';
import { GenerateB2DPathResponseType } from '../utils/generateB2DPath';

const useToaster = () => {
    const { enqueueSnackbar } = useSnackbar();
    const verbose = useVerbose();

    const handleApiResponse = async (
        asyncCall: any,
        callBack: () => void = () => {},
        toast: boolean | ToastMessages = true,
    ) =>
        asyncCall
            .then((res: any) => {
                callBack();
                if (toast) {
                    enqueueSnackbar(
                        typeof toast !== 'boolean' && toast.success
                            ? toast.success
                            : defaultMessages.success,
                    );
                }
                return res;
            })
            .catch((error: any) => {
                callBack();
                console.error(error);
                if (toast) {
                    enqueueSnackbar(
                        typeof toast !== 'boolean' && toast.error
                            ? toast.error
                            : defaultMessages.error,
                        { variant: 'error' },
                    );
                }
            });

    const handleRouterResponse = (
        routerResponse: GenerateB2DPathResponseType,
        callBack: () => void,
    ) => {
        if (routerResponse.status === 'OK') {
            callBack();
        } else if (routerResponse.status === 'KO') {
            verbose.log(`Page inaccessible car : ${routerResponse.message}`);
            enqueueSnackbar("Impossible d'accéder à cette page.", { variant: 'error' });
        }
    };

    return { handleApiResponse, handleRouterResponse } as const;
};

export default useToaster;
