import React, { FunctionComponent } from 'react';

import { Link } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { SignataireType } from '@europrocurement/l2d-domain';

import { useRelanceDossierSignature } from '../../functions/raiseFunctions';
import { signatureDuplicateFullPath } from '../../constants/paths';

export const relanceAviable = (
    signataires: Array<Pick<SignataireType, 'refus' | 'signature'>>,
): boolean => {
    if (signataires.every((signataire) => signataire.signature === true)) {
        return false;
    }

    return true;
};

export type SignatureListeActionsProps = {
    itemId: number | string;
    signataires: Array<Pick<SignataireType, 'refus' | 'signature'>>;
};

const DossierSignatureListeActions: FunctionComponent<SignatureListeActionsProps> = function ({
    itemId,
    signataires,
}) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const relanceDossier = useRelanceDossierSignature();

    return (
        <div
            onClick={(e) => {
                e.stopPropagation();
            }}
            onKeyDown={() => {}}
            role="button"
            tabIndex={0}
        >
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {relanceAviable(signataires) ? (
                    <MenuItem
                        onClick={() => {
                            relanceDossier(itemId as number);
                            handleClose();
                        }}
                    >
                        <Typography sx={{ color: 'text.primary' }}>Relancer le dossier</Typography>
                    </MenuItem>
                ) : (
                    ''
                )}
                <MenuItem onClick={handleClose}>
                    <Link
                        to={signatureDuplicateFullPath.replace(
                            ':idDossierSignature',
                            itemId as string,
                        )}
                        style={{ textDecoration: 'none' }}
                    >
                        <Typography sx={{ color: 'text.primary' }}>Dupliquer le dossier</Typography>
                    </Link>
                </MenuItem>

                <MenuItem onClick={handleClose}>
                    <Link
                        to={`${itemId}`}
                        target="_blank"
                        style={{ textDecoration: 'none' }}
                    >
                        <Typography sx={{ color: 'text.primary' }}>
                            Ouvrir dans un nouvel onglet
                        </Typography>
                    </Link>
                </MenuItem>
            </Menu>
        </div>
    );
};

export default DossierSignatureListeActions;
