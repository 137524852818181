import React, { ReactNode, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@mui/material';
import { FactureAchatNote } from '@europrocurement/l2d-domain';
import {
    FactureAchatNoteApiCreateFactureAchatNoteFactureAchatNoteCollectionRequest,
    FactureAchatNoteApiDeleteFactureAchatNoteFactureAchatNoteItemRequest,
    FactureAchatNoteApiUpdateFactureAchatNoteFactureAchatNoteItemRequest,
    FactureAchatNoteJsonldFactureAchatNoteWrite,
} from '@europrocurement/l2d-domain/openApi/ApiAchats';
import { customizerSelector, factureAchatNoteApi } from '@b2d/redux/RootStore';
import useToaster from '@b2d/hooks/useToaster';
import {
    addNoteMessages,
    editNoteMessages,
    removeNoteMessages,
} from '@b2d/pages/Achats/constants/wording/toasts';
import ListNotesObject from '@b2d/pages/Achats/components/notes/ListNotesObject';

export type NotesFacturePendingType = {
    notes: FactureAchatNote[];
    notesChange: (notes: FactureAchatNote[]) => void;
    title?: ReactNode;
};

export const NotesFacturePending = function ({
    notes,
    notesChange,
    title = 'Notes libres',
}: NotesFacturePendingType) {
    const { handleApiResponse } = useToaster();
    const { xIdSociete } = useSelector(customizerSelector);
    const [localNotes, setLocalNotes] = useState<FactureAchatNote[]>(notes);
    const [addNoteDisabled, setAddNoteDisabled] = useState<boolean>(false);

    useEffect(() => {
        setLocalNotes(notes);
    }, [notes]);

    const handleEditItem = async (note: FactureAchatNote) => {
        const requestParameters: FactureAchatNoteApiUpdateFactureAchatNoteFactureAchatNoteItemRequest =
            {
                xIdSociete,
                id: `${note.id}`,
                factureAchatNoteJsonldFactureAchatNoteWrite:
                    note as FactureAchatNoteJsonldFactureAchatNoteWrite,
            };

        const noteResponse = await handleApiResponse(
            factureAchatNoteApi.updateFactureAchatNoteFactureAchatNoteItem(requestParameters),
            undefined,
            editNoteMessages,
        );

        const noteIndex = localNotes.findIndex(
            (noteFromArray) => noteFromArray['@id'] === note['@id'],
        );
        const updatedNotes = [...localNotes];
        updatedNotes[noteIndex] = noteResponse.data;
        setLocalNotes(updatedNotes);
        notesChange(updatedNotes);
    };

    const handleRemoveItem = async (note: FactureAchatNote) => {
        const requestParameters: FactureAchatNoteApiDeleteFactureAchatNoteFactureAchatNoteItemRequest =
            {
                xIdSociete,
                id: `${note.id}`,
            };

        await handleApiResponse(
            factureAchatNoteApi.deleteFactureAchatNoteFactureAchatNoteItem(requestParameters),
            undefined,
            removeNoteMessages,
        );

        const updatedNotes = localNotes.filter(
            (noteFromArray) => noteFromArray['@id'] !== note['@id'],
        );
        setLocalNotes(updatedNotes);
        notesChange(updatedNotes);
    };

    const handleAddItem = async () => {
        const requestParameters: FactureAchatNoteApiCreateFactureAchatNoteFactureAchatNoteCollectionRequest =
            {
                factureAchatNoteJsonldFactureAchatNoteCreate: {
                    note: ' ',
                },
            };

        const noteResponse = await handleApiResponse(
            factureAchatNoteApi.createFactureAchatNoteFactureAchatNoteCollection(requestParameters),
            undefined,
            addNoteMessages,
        );

        const updatedNotes = [...localNotes, noteResponse.data];
        setLocalNotes(updatedNotes);
        notesChange(updatedNotes);
    };

    /**
     * Verrouille ou déverrouille l'ajout de note si une note est en édition.
     * @param editionMode
     */
    const handleAddNoteButtonState = (editionMode: boolean) => {
        setAddNoteDisabled(editionMode);
    };

    /**
     * Force à déverrouiller l'ajout de note s'il n'y a pas de notes
     */
    useEffect(() => {
        if (!addNoteDisabled) return;
        if (localNotes && localNotes.length === 0) {
            setAddNoteDisabled(false);
        }
    }, [addNoteDisabled, localNotes]);

    return (
        <>
            <ListNotesObject
                title={title}
                notes={localNotes}
                editItem={handleEditItem}
                removeItem={handleRemoveItem}
                onEditModeChange={handleAddNoteButtonState}
            />
            <Button
                onClick={handleAddItem}
                disabled={addNoteDisabled}
                variant="contained"
                style={{ marginTop: '16px', marginBottom: '16px' }}
            >
                Ajouter une note
            </Button>
        </>
    );
};

NotesFacturePending.defaultValues = {
    title: 'Notes libres',
};

export default NotesFacturePending;
