/* tslint:disable */
/* eslint-disable */
/**
 * Europrocurement Api Geoloc
 * API Geoloc
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AnnexeAnnexeRead
 */
export interface AnnexeAnnexeRead {
    /**
     * 
     * @type {number}
     * @memberof AnnexeAnnexeRead
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnnexeAnnexeRead
     */
    'numero'?: number;
    /**
     * 
     * @type {string}
     * @memberof AnnexeAnnexeRead
     */
    'libelle'?: string | null;
}
/**
 * 
 * @export
 * @interface AnnexeJsonldAnnexeRead
 */
export interface AnnexeJsonldAnnexeRead {
    /**
     * 
     * @type {string}
     * @memberof AnnexeJsonldAnnexeRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnexeJsonldAnnexeRead
     */
    '@type'?: string;
    /**
     * 
     * @type {AnnexeJsonldAnnexeReadContext}
     * @memberof AnnexeJsonldAnnexeRead
     */
    '@context'?: AnnexeJsonldAnnexeReadContext;
    /**
     * 
     * @type {number}
     * @memberof AnnexeJsonldAnnexeRead
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnnexeJsonldAnnexeRead
     */
    'numero'?: number;
    /**
     * 
     * @type {string}
     * @memberof AnnexeJsonldAnnexeRead
     */
    'libelle'?: string | null;
}
/**
 * @type AnnexeJsonldAnnexeReadContext
 * @export
 */
export type AnnexeJsonldAnnexeReadContext = AnnexeJsonldAnnexeReadContextOneOf | string;

/**
 * 
 * @export
 * @interface AnnexeJsonldAnnexeReadContextOneOf
 */
export interface AnnexeJsonldAnnexeReadContextOneOf {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof AnnexeJsonldAnnexeReadContextOneOf
     */
    '@vocab': string;
    /**
     * 
     * @type {string}
     * @memberof AnnexeJsonldAnnexeReadContextOneOf
     */
    'hydra': AnnexeJsonldAnnexeReadContextOneOfHydraEnum;
}

export const AnnexeJsonldAnnexeReadContextOneOfHydraEnum = {
    HttpWwwW3OrgNsHydraCore: 'http://www.w3.org/ns/hydra/core#'
} as const;

export type AnnexeJsonldAnnexeReadContextOneOfHydraEnum = typeof AnnexeJsonldAnnexeReadContextOneOfHydraEnum[keyof typeof AnnexeJsonldAnnexeReadContextOneOfHydraEnum];

/**
 * 
 * @export
 * @interface ApiAnnexesGetCollection200Response
 */
export interface ApiAnnexesGetCollection200Response {
    /**
     * 
     * @type {Array<AnnexeJsonldAnnexeRead>}
     * @memberof ApiAnnexesGetCollection200Response
     */
    'hydra:member': Array<AnnexeJsonldAnnexeRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiAnnexesGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiAnnexesGetCollection200ResponseHydraView}
     * @memberof ApiAnnexesGetCollection200Response
     */
    'hydra:view'?: ApiAnnexesGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiAnnexesGetCollection200ResponseHydraSearch}
     * @memberof ApiAnnexesGetCollection200Response
     */
    'hydra:search'?: ApiAnnexesGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiAnnexesGetCollection200ResponseHydraSearch
 */
export interface ApiAnnexesGetCollection200ResponseHydraSearch {
    /**
     * 
     * @type {string}
     * @memberof ApiAnnexesGetCollection200ResponseHydraSearch
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiAnnexesGetCollection200ResponseHydraSearch
     */
    'hydra:template'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiAnnexesGetCollection200ResponseHydraSearch
     */
    'hydra:variableRepresentation'?: string;
    /**
     * 
     * @type {Array<ApiAnnexesGetCollection200ResponseHydraSearchHydraMappingInner>}
     * @memberof ApiAnnexesGetCollection200ResponseHydraSearch
     */
    'hydra:mapping'?: Array<ApiAnnexesGetCollection200ResponseHydraSearchHydraMappingInner>;
}
/**
 * 
 * @export
 * @interface ApiAnnexesGetCollection200ResponseHydraSearchHydraMappingInner
 */
export interface ApiAnnexesGetCollection200ResponseHydraSearchHydraMappingInner {
    /**
     * 
     * @type {string}
     * @memberof ApiAnnexesGetCollection200ResponseHydraSearchHydraMappingInner
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiAnnexesGetCollection200ResponseHydraSearchHydraMappingInner
     */
    'variable'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiAnnexesGetCollection200ResponseHydraSearchHydraMappingInner
     */
    'property'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApiAnnexesGetCollection200ResponseHydraSearchHydraMappingInner
     */
    'required'?: boolean;
}
/**
 * 
 * @export
 * @interface ApiAnnexesGetCollection200ResponseHydraView
 */
export interface ApiAnnexesGetCollection200ResponseHydraView {
    /**
     * 
     * @type {string}
     * @memberof ApiAnnexesGetCollection200ResponseHydraView
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiAnnexesGetCollection200ResponseHydraView
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiAnnexesGetCollection200ResponseHydraView
     */
    'hydra:first'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiAnnexesGetCollection200ResponseHydraView
     */
    'hydra:last'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiAnnexesGetCollection200ResponseHydraView
     */
    'hydra:previous'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiAnnexesGetCollection200ResponseHydraView
     */
    'hydra:next'?: string;
}
/**
 * 
 * @export
 * @interface ApiAnnexesGetCollection400Response
 */
export interface ApiAnnexesGetCollection400Response {
    /**
     * 
     * @type {string}
     * @memberof ApiAnnexesGetCollection400Response
     */
    'error'?: string;
    /**
     * 
     * @type {ApiAnnexesGetCollection400ResponseException}
     * @memberof ApiAnnexesGetCollection400Response
     */
    'exception'?: ApiAnnexesGetCollection400ResponseException;
}
/**
 * 
 * @export
 * @interface ApiAnnexesGetCollection400ResponseException
 */
export interface ApiAnnexesGetCollection400ResponseException {
    /**
     * 
     * @type {string}
     * @memberof ApiAnnexesGetCollection400ResponseException
     */
    'message'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiAnnexesGetCollection400ResponseException
     */
    'code'?: number;
}
/**
 * 
 * @export
 * @interface ApiAnnexesGetCollection401Response
 */
export interface ApiAnnexesGetCollection401Response {
    /**
     * 
     * @type {string}
     * @memberof ApiAnnexesGetCollection401Response
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ApiAnnexesGetCollection403Response
 */
export interface ApiAnnexesGetCollection403Response {
    /**
     * 
     * @type {string}
     * @memberof ApiAnnexesGetCollection403Response
     */
    'error'?: string;
    /**
     * 
     * @type {ApiAnnexesGetCollection403ResponseException}
     * @memberof ApiAnnexesGetCollection403Response
     */
    'exception'?: ApiAnnexesGetCollection403ResponseException;
}
/**
 * 
 * @export
 * @interface ApiAnnexesGetCollection403ResponseException
 */
export interface ApiAnnexesGetCollection403ResponseException {
    /**
     * 
     * @type {string}
     * @memberof ApiAnnexesGetCollection403ResponseException
     */
    'message'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiAnnexesGetCollection403ResponseException
     */
    'code'?: number;
}
/**
 * 
 * @export
 * @interface ApiAnnexesIdGet404Response
 */
export interface ApiAnnexesIdGet404Response {
    /**
     * 
     * @type {string}
     * @memberof ApiAnnexesIdGet404Response
     */
    'error'?: string;
    /**
     * 
     * @type {ApiAnnexesIdGet404ResponseException}
     * @memberof ApiAnnexesIdGet404Response
     */
    'exception'?: ApiAnnexesIdGet404ResponseException;
}
/**
 * 
 * @export
 * @interface ApiAnnexesIdGet404ResponseException
 */
export interface ApiAnnexesIdGet404ResponseException {
    /**
     * 
     * @type {string}
     * @memberof ApiAnnexesIdGet404ResponseException
     */
    'message'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiAnnexesIdGet404ResponseException
     */
    'code'?: number;
}
/**
 * 
 * @export
 * @interface ApiArrondissementsGetCollection200Response
 */
export interface ApiArrondissementsGetCollection200Response {
    /**
     * 
     * @type {Array<ArrondissementJsonldArrondissementRead>}
     * @memberof ApiArrondissementsGetCollection200Response
     */
    'hydra:member': Array<ArrondissementJsonldArrondissementRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiArrondissementsGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiAnnexesGetCollection200ResponseHydraView}
     * @memberof ApiArrondissementsGetCollection200Response
     */
    'hydra:view'?: ApiAnnexesGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiAnnexesGetCollection200ResponseHydraSearch}
     * @memberof ApiArrondissementsGetCollection200Response
     */
    'hydra:search'?: ApiAnnexesGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiCodesPostauxGetCollection200Response
 */
export interface ApiCodesPostauxGetCollection200Response {
    /**
     * 
     * @type {Array<CodePostalJsonldCodepostalRead>}
     * @memberof ApiCodesPostauxGetCollection200Response
     */
    'hydra:member': Array<CodePostalJsonldCodepostalRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiCodesPostauxGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiAnnexesGetCollection200ResponseHydraView}
     * @memberof ApiCodesPostauxGetCollection200Response
     */
    'hydra:view'?: ApiAnnexesGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiAnnexesGetCollection200ResponseHydraSearch}
     * @memberof ApiCodesPostauxGetCollection200Response
     */
    'hydra:search'?: ApiAnnexesGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiCodesPostauxPost400Response
 */
export interface ApiCodesPostauxPost400Response {
    /**
     * 
     * @type {string}
     * @memberof ApiCodesPostauxPost400Response
     */
    'error': string;
    /**
     * 
     * @type {ApiCodesPostauxPost400ResponseException}
     * @memberof ApiCodesPostauxPost400Response
     */
    'exception': ApiCodesPostauxPost400ResponseException;
}
/**
 * 
 * @export
 * @interface ApiCodesPostauxPost400ResponseException
 */
export interface ApiCodesPostauxPost400ResponseException {
    /**
     * 
     * @type {string}
     * @memberof ApiCodesPostauxPost400ResponseException
     */
    'message': string;
    /**
     * 
     * @type {number}
     * @memberof ApiCodesPostauxPost400ResponseException
     */
    'code': number;
    /**
     * liste des propiétés invalides
     * @type {Array<ApiCodesPostauxPost400ResponseExceptionViolationsInner>}
     * @memberof ApiCodesPostauxPost400ResponseException
     */
    'violations'?: Array<ApiCodesPostauxPost400ResponseExceptionViolationsInner>;
}
/**
 * 
 * @export
 * @interface ApiCodesPostauxPost400ResponseExceptionViolationsInner
 */
export interface ApiCodesPostauxPost400ResponseExceptionViolationsInner {
    /**
     * nom de la propriété invalide
     * @type {string}
     * @memberof ApiCodesPostauxPost400ResponseExceptionViolationsInner
     */
    'property'?: string;
    /**
     * message descriptif de la violation
     * @type {string}
     * @memberof ApiCodesPostauxPost400ResponseExceptionViolationsInner
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ApiCommunesGetCollection200Response
 */
export interface ApiCommunesGetCollection200Response {
    /**
     * 
     * @type {Array<CommuneJsonldCommuneRead>}
     * @memberof ApiCommunesGetCollection200Response
     */
    'hydra:member': Array<CommuneJsonldCommuneRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiCommunesGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiAnnexesGetCollection200ResponseHydraView}
     * @memberof ApiCommunesGetCollection200Response
     */
    'hydra:view'?: ApiAnnexesGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiAnnexesGetCollection200ResponseHydraSearch}
     * @memberof ApiCommunesGetCollection200Response
     */
    'hydra:search'?: ApiAnnexesGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiDepartementsGetCollection200Response
 */
export interface ApiDepartementsGetCollection200Response {
    /**
     * 
     * @type {Array<DPartementJsonldDepartementRead>}
     * @memberof ApiDepartementsGetCollection200Response
     */
    'hydra:member': Array<DPartementJsonldDepartementRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiDepartementsGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiAnnexesGetCollection200ResponseHydraView}
     * @memberof ApiDepartementsGetCollection200Response
     */
    'hydra:view'?: ApiAnnexesGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiAnnexesGetCollection200ResponseHydraSearch}
     * @memberof ApiDepartementsGetCollection200Response
     */
    'hydra:search'?: ApiAnnexesGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiGreffesGetCollection200Response
 */
export interface ApiGreffesGetCollection200Response {
    /**
     * 
     * @type {Array<GreffeJsonldGreffeRead>}
     * @memberof ApiGreffesGetCollection200Response
     */
    'hydra:member': Array<GreffeJsonldGreffeRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiGreffesGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiAnnexesGetCollection200ResponseHydraView}
     * @memberof ApiGreffesGetCollection200Response
     */
    'hydra:view'?: ApiAnnexesGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiAnnexesGetCollection200ResponseHydraSearch}
     * @memberof ApiGreffesGetCollection200Response
     */
    'hydra:search'?: ApiAnnexesGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiPaysGetCollection200Response
 */
export interface ApiPaysGetCollection200Response {
    /**
     * 
     * @type {Array<PaysJsonldPaysRead>}
     * @memberof ApiPaysGetCollection200Response
     */
    'hydra:member': Array<PaysJsonldPaysRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiPaysGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiAnnexesGetCollection200ResponseHydraView}
     * @memberof ApiPaysGetCollection200Response
     */
    'hydra:view'?: ApiAnnexesGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiAnnexesGetCollection200ResponseHydraSearch}
     * @memberof ApiPaysGetCollection200Response
     */
    'hydra:search'?: ApiAnnexesGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiRegionsGetCollection200Response
 */
export interface ApiRegionsGetCollection200Response {
    /**
     * 
     * @type {Array<RegionJsonldRegionRead>}
     * @memberof ApiRegionsGetCollection200Response
     */
    'hydra:member': Array<RegionJsonldRegionRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiRegionsGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiAnnexesGetCollection200ResponseHydraView}
     * @memberof ApiRegionsGetCollection200Response
     */
    'hydra:view'?: ApiAnnexesGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiAnnexesGetCollection200ResponseHydraSearch}
     * @memberof ApiRegionsGetCollection200Response
     */
    'hydra:search'?: ApiAnnexesGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ArrondissementArrondissementRead
 */
export interface ArrondissementArrondissementRead {
    /**
     * 
     * @type {number}
     * @memberof ArrondissementArrondissementRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ArrondissementArrondissementRead
     */
    'arrondissement'?: string;
    /**
     * 
     * @type {string}
     * @memberof ArrondissementArrondissementRead
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof ArrondissementArrondissementRead
     */
    'dep'?: string | null;
}
/**
 * 
 * @export
 * @interface ArrondissementJsonldArrondissementRead
 */
export interface ArrondissementJsonldArrondissementRead {
    /**
     * 
     * @type {string}
     * @memberof ArrondissementJsonldArrondissementRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ArrondissementJsonldArrondissementRead
     */
    '@type'?: string;
    /**
     * 
     * @type {AnnexeJsonldAnnexeReadContext}
     * @memberof ArrondissementJsonldArrondissementRead
     */
    '@context'?: AnnexeJsonldAnnexeReadContext;
    /**
     * 
     * @type {number}
     * @memberof ArrondissementJsonldArrondissementRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ArrondissementJsonldArrondissementRead
     */
    'arrondissement'?: string;
    /**
     * 
     * @type {string}
     * @memberof ArrondissementJsonldArrondissementRead
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof ArrondissementJsonldArrondissementRead
     */
    'dep'?: string | null;
}
/**
 * 
 * @export
 * @interface CodePostalCodepostalCreate
 */
export interface CodePostalCodepostalCreate {
    /**
     * 
     * @type {number}
     * @memberof CodePostalCodepostalCreate
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CodePostalCodepostalCreate
     */
    'insee': string | null;
    /**
     * 
     * @type {string}
     * @memberof CodePostalCodepostalCreate
     */
    'cpostal': string;
}
/**
 * 
 * @export
 * @interface CodePostalCodepostalCreated
 */
export interface CodePostalCodepostalCreated {
    /**
     * 
     * @type {number}
     * @memberof CodePostalCodepostalCreated
     */
    'id'?: number;
    /**
     * 
     * @type {CodePostalCodepostalCreatedInsee}
     * @memberof CodePostalCodepostalCreated
     */
    'insee': CodePostalCodepostalCreatedInsee | null;
    /**
     * 
     * @type {string}
     * @memberof CodePostalCodepostalCreated
     */
    'cpostal': string;
}
/**
 * 
 * @export
 * @interface CodePostalCodepostalCreatedInsee
 */
export interface CodePostalCodepostalCreatedInsee {
    /**
     * 
     * @type {string}
     * @memberof CodePostalCodepostalCreatedInsee
     */
    'insee'?: string;
    /**
     * 
     * @type {string}
     * @memberof CodePostalCodepostalCreatedInsee
     */
    'libgeo'?: string;
    /**
     * 
     * @type {string}
     * @memberof CodePostalCodepostalCreatedInsee
     */
    'region'?: string | null;
    /**
     * 
     * @type {TblCommuneCodepostalCreatedDepartement}
     * @memberof CodePostalCodepostalCreatedInsee
     */
    'departement'?: TblCommuneCodepostalCreatedDepartement | null;
    /**
     * 
     * @type {string}
     * @memberof CodePostalCodepostalCreatedInsee
     */
    'arrondissement'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CodePostalCodepostalCreatedInsee
     */
    'article'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CodePostalCodepostalCreatedInsee
     */
    'ville'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CodePostalCodepostalCreatedInsee
     */
    'villeMaj'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CodePostalCodepostalCreatedInsee
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {TblCommuneCodepostalCreatedGreffe}
     * @memberof CodePostalCodepostalCreatedInsee
     */
    'greffe'?: TblCommuneCodepostalCreatedGreffe | null;
    /**
     * 
     * @type {boolean}
     * @memberof CodePostalCodepostalCreatedInsee
     */
    'deleted'?: boolean;
}
/**
 * 
 * @export
 * @interface CodePostalCodepostalRead
 */
export interface CodePostalCodepostalRead {
    /**
     * 
     * @type {number}
     * @memberof CodePostalCodepostalRead
     */
    'id'?: number;
    /**
     * 
     * @type {CodePostalCodepostalReadInsee}
     * @memberof CodePostalCodepostalRead
     */
    'insee'?: CodePostalCodepostalReadInsee | null;
    /**
     * 
     * @type {string}
     * @memberof CodePostalCodepostalRead
     */
    'cpostal'?: string;
}
/**
 * 
 * @export
 * @interface CodePostalCodepostalReadInsee
 */
export interface CodePostalCodepostalReadInsee {
    /**
     * 
     * @type {string}
     * @memberof CodePostalCodepostalReadInsee
     */
    'insee'?: string;
    /**
     * 
     * @type {string}
     * @memberof CodePostalCodepostalReadInsee
     */
    'libgeo'?: string;
    /**
     * 
     * @type {string}
     * @memberof CodePostalCodepostalReadInsee
     */
    'region'?: string | null;
    /**
     * 
     * @type {TblCommuneCodepostalReadDepartement}
     * @memberof CodePostalCodepostalReadInsee
     */
    'departement'?: TblCommuneCodepostalReadDepartement | null;
    /**
     * 
     * @type {string}
     * @memberof CodePostalCodepostalReadInsee
     */
    'arrondissement'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CodePostalCodepostalReadInsee
     */
    'article'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CodePostalCodepostalReadInsee
     */
    'ville'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CodePostalCodepostalReadInsee
     */
    'villeMaj'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CodePostalCodepostalReadInsee
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {TblCommuneCodepostalReadGreffe}
     * @memberof CodePostalCodepostalReadInsee
     */
    'greffe'?: TblCommuneCodepostalReadGreffe | null;
    /**
     * 
     * @type {boolean}
     * @memberof CodePostalCodepostalReadInsee
     */
    'deleted'?: boolean;
}
/**
 * 
 * @export
 * @interface CodePostalCodepostalUpdate
 */
export interface CodePostalCodepostalUpdate {
    /**
     * 
     * @type {number}
     * @memberof CodePostalCodepostalUpdate
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CodePostalCodepostalUpdate
     */
    'insee': string | null;
    /**
     * 
     * @type {string}
     * @memberof CodePostalCodepostalUpdate
     */
    'cpostal': string;
}
/**
 * 
 * @export
 * @interface CodePostalJsonldCodepostalCreate
 */
export interface CodePostalJsonldCodepostalCreate {
    /**
     * 
     * @type {AnnexeJsonldAnnexeReadContext}
     * @memberof CodePostalJsonldCodepostalCreate
     */
    '@context'?: AnnexeJsonldAnnexeReadContext;
    /**
     * 
     * @type {string}
     * @memberof CodePostalJsonldCodepostalCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CodePostalJsonldCodepostalCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof CodePostalJsonldCodepostalCreate
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CodePostalJsonldCodepostalCreate
     */
    'insee': string | null;
    /**
     * 
     * @type {string}
     * @memberof CodePostalJsonldCodepostalCreate
     */
    'cpostal': string;
}
/**
 * 
 * @export
 * @interface CodePostalJsonldCodepostalCreated
 */
export interface CodePostalJsonldCodepostalCreated {
    /**
     * 
     * @type {AnnexeJsonldAnnexeReadContext}
     * @memberof CodePostalJsonldCodepostalCreated
     */
    '@context'?: AnnexeJsonldAnnexeReadContext;
    /**
     * 
     * @type {string}
     * @memberof CodePostalJsonldCodepostalCreated
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CodePostalJsonldCodepostalCreated
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof CodePostalJsonldCodepostalCreated
     */
    'id'?: number;
    /**
     * 
     * @type {CodePostalJsonldCodepostalCreatedInsee}
     * @memberof CodePostalJsonldCodepostalCreated
     */
    'insee': CodePostalJsonldCodepostalCreatedInsee | null;
    /**
     * 
     * @type {string}
     * @memberof CodePostalJsonldCodepostalCreated
     */
    'cpostal': string;
}
/**
 * 
 * @export
 * @interface CodePostalJsonldCodepostalCreatedInsee
 */
export interface CodePostalJsonldCodepostalCreatedInsee {
    /**
     * 
     * @type {AnnexeJsonldAnnexeReadContext}
     * @memberof CodePostalJsonldCodepostalCreatedInsee
     */
    '@context'?: AnnexeJsonldAnnexeReadContext;
    /**
     * 
     * @type {string}
     * @memberof CodePostalJsonldCodepostalCreatedInsee
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CodePostalJsonldCodepostalCreatedInsee
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof CodePostalJsonldCodepostalCreatedInsee
     */
    'insee'?: string;
    /**
     * 
     * @type {string}
     * @memberof CodePostalJsonldCodepostalCreatedInsee
     */
    'libgeo'?: string;
    /**
     * 
     * @type {string}
     * @memberof CodePostalJsonldCodepostalCreatedInsee
     */
    'region'?: string | null;
    /**
     * 
     * @type {TblCommuneJsonldCodepostalCreatedDepartement}
     * @memberof CodePostalJsonldCodepostalCreatedInsee
     */
    'departement'?: TblCommuneJsonldCodepostalCreatedDepartement | null;
    /**
     * 
     * @type {string}
     * @memberof CodePostalJsonldCodepostalCreatedInsee
     */
    'arrondissement'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CodePostalJsonldCodepostalCreatedInsee
     */
    'article'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CodePostalJsonldCodepostalCreatedInsee
     */
    'ville'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CodePostalJsonldCodepostalCreatedInsee
     */
    'villeMaj'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CodePostalJsonldCodepostalCreatedInsee
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {TblCommuneJsonldCodepostalCreatedGreffe}
     * @memberof CodePostalJsonldCodepostalCreatedInsee
     */
    'greffe'?: TblCommuneJsonldCodepostalCreatedGreffe | null;
    /**
     * 
     * @type {boolean}
     * @memberof CodePostalJsonldCodepostalCreatedInsee
     */
    'deleted'?: boolean;
}
/**
 * 
 * @export
 * @interface CodePostalJsonldCodepostalRead
 */
export interface CodePostalJsonldCodepostalRead {
    /**
     * 
     * @type {AnnexeJsonldAnnexeReadContext}
     * @memberof CodePostalJsonldCodepostalRead
     */
    '@context'?: AnnexeJsonldAnnexeReadContext;
    /**
     * 
     * @type {string}
     * @memberof CodePostalJsonldCodepostalRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CodePostalJsonldCodepostalRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof CodePostalJsonldCodepostalRead
     */
    'id'?: number;
    /**
     * 
     * @type {CodePostalJsonldCodepostalReadInsee}
     * @memberof CodePostalJsonldCodepostalRead
     */
    'insee'?: CodePostalJsonldCodepostalReadInsee | null;
    /**
     * 
     * @type {string}
     * @memberof CodePostalJsonldCodepostalRead
     */
    'cpostal'?: string;
}
/**
 * 
 * @export
 * @interface CodePostalJsonldCodepostalReadInsee
 */
export interface CodePostalJsonldCodepostalReadInsee {
    /**
     * 
     * @type {AnnexeJsonldAnnexeReadContext}
     * @memberof CodePostalJsonldCodepostalReadInsee
     */
    '@context'?: AnnexeJsonldAnnexeReadContext;
    /**
     * 
     * @type {string}
     * @memberof CodePostalJsonldCodepostalReadInsee
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CodePostalJsonldCodepostalReadInsee
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof CodePostalJsonldCodepostalReadInsee
     */
    'insee'?: string;
    /**
     * 
     * @type {string}
     * @memberof CodePostalJsonldCodepostalReadInsee
     */
    'libgeo'?: string;
    /**
     * 
     * @type {string}
     * @memberof CodePostalJsonldCodepostalReadInsee
     */
    'region'?: string | null;
    /**
     * 
     * @type {TblCommuneJsonldCodepostalReadDepartement}
     * @memberof CodePostalJsonldCodepostalReadInsee
     */
    'departement'?: TblCommuneJsonldCodepostalReadDepartement | null;
    /**
     * 
     * @type {string}
     * @memberof CodePostalJsonldCodepostalReadInsee
     */
    'arrondissement'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CodePostalJsonldCodepostalReadInsee
     */
    'article'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CodePostalJsonldCodepostalReadInsee
     */
    'ville'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CodePostalJsonldCodepostalReadInsee
     */
    'villeMaj'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CodePostalJsonldCodepostalReadInsee
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {TblCommuneJsonldCodepostalReadGreffe}
     * @memberof CodePostalJsonldCodepostalReadInsee
     */
    'greffe'?: TblCommuneJsonldCodepostalReadGreffe | null;
    /**
     * 
     * @type {boolean}
     * @memberof CodePostalJsonldCodepostalReadInsee
     */
    'deleted'?: boolean;
}
/**
 * 
 * @export
 * @interface CodePostalJsonldCodepostalUpdate
 */
export interface CodePostalJsonldCodepostalUpdate {
    /**
     * 
     * @type {AnnexeJsonldAnnexeReadContext}
     * @memberof CodePostalJsonldCodepostalUpdate
     */
    '@context'?: AnnexeJsonldAnnexeReadContext;
    /**
     * 
     * @type {string}
     * @memberof CodePostalJsonldCodepostalUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CodePostalJsonldCodepostalUpdate
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof CodePostalJsonldCodepostalUpdate
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CodePostalJsonldCodepostalUpdate
     */
    'insee': string | null;
    /**
     * 
     * @type {string}
     * @memberof CodePostalJsonldCodepostalUpdate
     */
    'cpostal': string;
}
/**
 * 
 * @export
 * @interface CommuneCommuneCreate
 */
export interface CommuneCommuneCreate {
    /**
     * 
     * @type {string}
     * @memberof CommuneCommuneCreate
     */
    'insee'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommuneCommuneCreate
     */
    'region'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommuneCommuneCreate
     */
    'departement'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommuneCommuneCreate
     */
    'arrondissement'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommuneCommuneCreate
     */
    'article'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommuneCommuneCreate
     */
    'ville': string | null;
    /**
     * 
     * @type {string}
     * @memberof CommuneCommuneCreate
     */
    'greffe'?: string | null;
}
/**
 * 
 * @export
 * @interface CommuneCommuneRead
 */
export interface CommuneCommuneRead {
    /**
     * 
     * @type {string}
     * @memberof CommuneCommuneRead
     */
    'insee'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommuneCommuneRead
     */
    'libgeo'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommuneCommuneRead
     */
    'region'?: string | null;
    /**
     * 
     * @type {CommuneCommuneReadDepartement}
     * @memberof CommuneCommuneRead
     */
    'departement'?: CommuneCommuneReadDepartement | null;
    /**
     * 
     * @type {CommuneCommuneReadArrondissement}
     * @memberof CommuneCommuneRead
     */
    'arrondissement'?: CommuneCommuneReadArrondissement | null;
    /**
     * 
     * @type {string}
     * @memberof CommuneCommuneRead
     */
    'article'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommuneCommuneRead
     */
    'ville'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommuneCommuneRead
     */
    'villeMaj'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommuneCommuneRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {CommuneCommuneReadGreffe}
     * @memberof CommuneCommuneRead
     */
    'greffe'?: CommuneCommuneReadGreffe | null;
    /**
     * 
     * @type {boolean}
     * @memberof CommuneCommuneRead
     */
    'deleted'?: boolean;
}
/**
 * 
 * @export
 * @interface CommuneCommuneReadArrondissement
 */
export interface CommuneCommuneReadArrondissement {
    /**
     * 
     * @type {number}
     * @memberof CommuneCommuneReadArrondissement
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CommuneCommuneReadArrondissement
     */
    'arrondissement'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommuneCommuneReadArrondissement
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommuneCommuneReadArrondissement
     */
    'dep'?: string | null;
}
/**
 * 
 * @export
 * @interface CommuneCommuneReadDepartement
 */
export interface CommuneCommuneReadDepartement {
    /**
     * 
     * @type {string}
     * @memberof CommuneCommuneReadDepartement
     */
    'codgeo'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommuneCommuneReadDepartement
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommuneCommuneReadDepartement
     */
    'prefix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommuneCommuneReadDepartement
     */
    'region'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CommuneCommuneReadDepartement
     */
    'ordre'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommuneCommuneReadDepartement
     */
    'prefDnid'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CommuneCommuneReadDepartement
     */
    'annexTarif'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CommuneCommuneReadDepartement
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CommuneCommuneReadDepartement
     */
    'commentaire'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CommuneCommuneReadDepartement
     */
    'habilitationDepartementale'?: boolean;
}
/**
 * 
 * @export
 * @interface CommuneCommuneReadGreffe
 */
export interface CommuneCommuneReadGreffe {
    /**
     * 
     * @type {CommuneCommuneReadDepartement}
     * @memberof CommuneCommuneReadGreffe
     */
    'departement'?: CommuneCommuneReadDepartement | null;
}
/**
 * 
 * @export
 * @interface CommuneCommuneUpdate
 */
export interface CommuneCommuneUpdate {
    /**
     * 
     * @type {string}
     * @memberof CommuneCommuneUpdate
     */
    'region'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommuneCommuneUpdate
     */
    'departement'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommuneCommuneUpdate
     */
    'arrondissement'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommuneCommuneUpdate
     */
    'article'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommuneCommuneUpdate
     */
    'ville'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommuneCommuneUpdate
     */
    'greffe'?: string | null;
}
/**
 * 
 * @export
 * @interface CommuneJsonld
 */
export interface CommuneJsonld {
    /**
     * 
     * @type {AnnexeJsonldAnnexeReadContext}
     * @memberof CommuneJsonld
     */
    '@context'?: AnnexeJsonldAnnexeReadContext;
    /**
     * 
     * @type {string}
     * @memberof CommuneJsonld
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommuneJsonld
     */
    '@type'?: string;
}
/**
 * 
 * @export
 * @interface CommuneJsonldCommuneCreate
 */
export interface CommuneJsonldCommuneCreate {
    /**
     * 
     * @type {AnnexeJsonldAnnexeReadContext}
     * @memberof CommuneJsonldCommuneCreate
     */
    '@context'?: AnnexeJsonldAnnexeReadContext;
    /**
     * 
     * @type {string}
     * @memberof CommuneJsonldCommuneCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommuneJsonldCommuneCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommuneJsonldCommuneCreate
     */
    'insee'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommuneJsonldCommuneCreate
     */
    'region'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommuneJsonldCommuneCreate
     */
    'departement'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommuneJsonldCommuneCreate
     */
    'arrondissement'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommuneJsonldCommuneCreate
     */
    'article'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommuneJsonldCommuneCreate
     */
    'ville': string | null;
    /**
     * 
     * @type {string}
     * @memberof CommuneJsonldCommuneCreate
     */
    'greffe'?: string | null;
}
/**
 * 
 * @export
 * @interface CommuneJsonldCommuneRead
 */
export interface CommuneJsonldCommuneRead {
    /**
     * 
     * @type {AnnexeJsonldAnnexeReadContext}
     * @memberof CommuneJsonldCommuneRead
     */
    '@context'?: AnnexeJsonldAnnexeReadContext;
    /**
     * 
     * @type {string}
     * @memberof CommuneJsonldCommuneRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommuneJsonldCommuneRead
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommuneJsonldCommuneRead
     */
    'insee'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommuneJsonldCommuneRead
     */
    'libgeo'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommuneJsonldCommuneRead
     */
    'region'?: string | null;
    /**
     * 
     * @type {CommuneJsonldCommuneReadDepartement}
     * @memberof CommuneJsonldCommuneRead
     */
    'departement'?: CommuneJsonldCommuneReadDepartement | null;
    /**
     * 
     * @type {CommuneJsonldCommuneReadArrondissement}
     * @memberof CommuneJsonldCommuneRead
     */
    'arrondissement'?: CommuneJsonldCommuneReadArrondissement | null;
    /**
     * 
     * @type {string}
     * @memberof CommuneJsonldCommuneRead
     */
    'article'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommuneJsonldCommuneRead
     */
    'ville'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommuneJsonldCommuneRead
     */
    'villeMaj'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommuneJsonldCommuneRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {CommuneJsonldCommuneReadGreffe}
     * @memberof CommuneJsonldCommuneRead
     */
    'greffe'?: CommuneJsonldCommuneReadGreffe | null;
    /**
     * 
     * @type {boolean}
     * @memberof CommuneJsonldCommuneRead
     */
    'deleted'?: boolean;
}
/**
 * 
 * @export
 * @interface CommuneJsonldCommuneReadArrondissement
 */
export interface CommuneJsonldCommuneReadArrondissement {
    /**
     * 
     * @type {AnnexeJsonldAnnexeReadContext}
     * @memberof CommuneJsonldCommuneReadArrondissement
     */
    '@context'?: AnnexeJsonldAnnexeReadContext;
    /**
     * 
     * @type {string}
     * @memberof CommuneJsonldCommuneReadArrondissement
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommuneJsonldCommuneReadArrondissement
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof CommuneJsonldCommuneReadArrondissement
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CommuneJsonldCommuneReadArrondissement
     */
    'arrondissement'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommuneJsonldCommuneReadArrondissement
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommuneJsonldCommuneReadArrondissement
     */
    'dep'?: string | null;
}
/**
 * 
 * @export
 * @interface CommuneJsonldCommuneReadDepartement
 */
export interface CommuneJsonldCommuneReadDepartement {
    /**
     * 
     * @type {AnnexeJsonldAnnexeReadContext}
     * @memberof CommuneJsonldCommuneReadDepartement
     */
    '@context'?: AnnexeJsonldAnnexeReadContext;
    /**
     * 
     * @type {string}
     * @memberof CommuneJsonldCommuneReadDepartement
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommuneJsonldCommuneReadDepartement
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommuneJsonldCommuneReadDepartement
     */
    'codgeo'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommuneJsonldCommuneReadDepartement
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommuneJsonldCommuneReadDepartement
     */
    'prefix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommuneJsonldCommuneReadDepartement
     */
    'region'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CommuneJsonldCommuneReadDepartement
     */
    'ordre'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommuneJsonldCommuneReadDepartement
     */
    'prefDnid'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CommuneJsonldCommuneReadDepartement
     */
    'annexTarif'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CommuneJsonldCommuneReadDepartement
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CommuneJsonldCommuneReadDepartement
     */
    'commentaire'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CommuneJsonldCommuneReadDepartement
     */
    'habilitationDepartementale'?: boolean;
}
/**
 * 
 * @export
 * @interface CommuneJsonldCommuneReadGreffe
 */
export interface CommuneJsonldCommuneReadGreffe {
    /**
     * 
     * @type {AnnexeJsonldAnnexeReadContext}
     * @memberof CommuneJsonldCommuneReadGreffe
     */
    '@context'?: AnnexeJsonldAnnexeReadContext;
    /**
     * 
     * @type {string}
     * @memberof CommuneJsonldCommuneReadGreffe
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommuneJsonldCommuneReadGreffe
     */
    '@type'?: string;
    /**
     * 
     * @type {CommuneJsonldCommuneReadDepartement}
     * @memberof CommuneJsonldCommuneReadGreffe
     */
    'departement'?: CommuneJsonldCommuneReadDepartement | null;
}
/**
 * 
 * @export
 * @interface CommuneJsonldCommuneUpdate
 */
export interface CommuneJsonldCommuneUpdate {
    /**
     * 
     * @type {AnnexeJsonldAnnexeReadContext}
     * @memberof CommuneJsonldCommuneUpdate
     */
    '@context'?: AnnexeJsonldAnnexeReadContext;
    /**
     * 
     * @type {string}
     * @memberof CommuneJsonldCommuneUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommuneJsonldCommuneUpdate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommuneJsonldCommuneUpdate
     */
    'region'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommuneJsonldCommuneUpdate
     */
    'departement'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommuneJsonldCommuneUpdate
     */
    'arrondissement'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommuneJsonldCommuneUpdate
     */
    'article'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommuneJsonldCommuneUpdate
     */
    'ville'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommuneJsonldCommuneUpdate
     */
    'greffe'?: string | null;
}
/**
 * 
 * @export
 * @interface DPartementDepartementCreate
 */
export interface DPartementDepartementCreate {
    /**
     * 
     * @type {string}
     * @memberof DPartementDepartementCreate
     */
    'codgeo'?: string;
    /**
     * 
     * @type {string}
     * @memberof DPartementDepartementCreate
     */
    'libelle': string;
    /**
     * 
     * @type {string}
     * @memberof DPartementDepartementCreate
     */
    'prefix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DPartementDepartementCreate
     */
    'region'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DPartementDepartementCreate
     */
    'ordre': number | null;
    /**
     * 
     * @type {number}
     * @memberof DPartementDepartementCreate
     */
    'prefDnid': number | null;
    /**
     * 
     * @type {string}
     * @memberof DPartementDepartementCreate
     */
    'annexTarif'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DPartementDepartementCreate
     */
    'commentaire'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DPartementDepartementCreate
     */
    'habilitationDepartementale'?: boolean;
}
/**
 * 
 * @export
 * @interface DPartementDepartementCreated
 */
export interface DPartementDepartementCreated {
    /**
     * 
     * @type {string}
     * @memberof DPartementDepartementCreated
     */
    'codgeo'?: string;
    /**
     * 
     * @type {string}
     * @memberof DPartementDepartementCreated
     */
    'libelle': string;
    /**
     * 
     * @type {string}
     * @memberof DPartementDepartementCreated
     */
    'prefix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DPartementDepartementCreated
     */
    'region'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DPartementDepartementCreated
     */
    'ordre': number | null;
    /**
     * 
     * @type {number}
     * @memberof DPartementDepartementCreated
     */
    'prefDnid': number | null;
    /**
     * 
     * @type {string}
     * @memberof DPartementDepartementCreated
     */
    'annexTarif'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DPartementDepartementCreated
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DPartementDepartementCreated
     */
    'commentaire'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DPartementDepartementCreated
     */
    'habilitationDepartementale'?: boolean;
}
/**
 * 
 * @export
 * @interface DPartementDepartementRead
 */
export interface DPartementDepartementRead {
    /**
     * 
     * @type {string}
     * @memberof DPartementDepartementRead
     */
    'codgeo'?: string;
    /**
     * 
     * @type {string}
     * @memberof DPartementDepartementRead
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof DPartementDepartementRead
     */
    'prefix'?: string | null;
    /**
     * 
     * @type {DPartementDepartementReadRegion}
     * @memberof DPartementDepartementRead
     */
    'region'?: DPartementDepartementReadRegion | null;
    /**
     * 
     * @type {number}
     * @memberof DPartementDepartementRead
     */
    'ordre'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DPartementDepartementRead
     */
    'prefDnid'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DPartementDepartementRead
     */
    'annexTarif'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DPartementDepartementRead
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DPartementDepartementRead
     */
    'commentaire'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DPartementDepartementRead
     */
    'habilitationDepartementale'?: boolean;
}
/**
 * 
 * @export
 * @interface DPartementDepartementReadRegion
 */
export interface DPartementDepartementReadRegion {
    /**
     * 
     * @type {number}
     * @memberof DPartementDepartementReadRegion
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof DPartementDepartementReadRegion
     */
    'idregion'?: number;
    /**
     * 
     * @type {string}
     * @memberof DPartementDepartementReadRegion
     */
    'cheflieu'?: string;
    /**
     * 
     * @type {number}
     * @memberof DPartementDepartementReadRegion
     */
    'tncc'?: number;
    /**
     * 
     * @type {string}
     * @memberof DPartementDepartementReadRegion
     */
    'ncc'?: string;
    /**
     * 
     * @type {number}
     * @memberof DPartementDepartementReadRegion
     */
    'regioncommerciale'?: number;
}
/**
 * 
 * @export
 * @interface DPartementDepartementUpdate
 */
export interface DPartementDepartementUpdate {
    /**
     * 
     * @type {string}
     * @memberof DPartementDepartementUpdate
     */
    'codgeo'?: string;
    /**
     * 
     * @type {string}
     * @memberof DPartementDepartementUpdate
     */
    'libelle': string;
    /**
     * 
     * @type {string}
     * @memberof DPartementDepartementUpdate
     */
    'prefix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DPartementDepartementUpdate
     */
    'region'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DPartementDepartementUpdate
     */
    'ordre': number | null;
    /**
     * 
     * @type {number}
     * @memberof DPartementDepartementUpdate
     */
    'prefDnid': number | null;
    /**
     * 
     * @type {string}
     * @memberof DPartementDepartementUpdate
     */
    'annexTarif'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DPartementDepartementUpdate
     */
    'commentaire'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DPartementDepartementUpdate
     */
    'habilitationDepartementale'?: boolean;
}
/**
 * 
 * @export
 * @interface DPartementJsonld
 */
export interface DPartementJsonld {
    /**
     * 
     * @type {AnnexeJsonldAnnexeReadContext}
     * @memberof DPartementJsonld
     */
    '@context'?: AnnexeJsonldAnnexeReadContext;
    /**
     * 
     * @type {string}
     * @memberof DPartementJsonld
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DPartementJsonld
     */
    '@type'?: string;
}
/**
 * 
 * @export
 * @interface DPartementJsonldDepartementCreate
 */
export interface DPartementJsonldDepartementCreate {
    /**
     * 
     * @type {AnnexeJsonldAnnexeReadContext}
     * @memberof DPartementJsonldDepartementCreate
     */
    '@context'?: AnnexeJsonldAnnexeReadContext;
    /**
     * 
     * @type {string}
     * @memberof DPartementJsonldDepartementCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DPartementJsonldDepartementCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof DPartementJsonldDepartementCreate
     */
    'codgeo'?: string;
    /**
     * 
     * @type {string}
     * @memberof DPartementJsonldDepartementCreate
     */
    'libelle': string;
    /**
     * 
     * @type {string}
     * @memberof DPartementJsonldDepartementCreate
     */
    'prefix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DPartementJsonldDepartementCreate
     */
    'region'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DPartementJsonldDepartementCreate
     */
    'ordre': number | null;
    /**
     * 
     * @type {number}
     * @memberof DPartementJsonldDepartementCreate
     */
    'prefDnid': number | null;
    /**
     * 
     * @type {string}
     * @memberof DPartementJsonldDepartementCreate
     */
    'annexTarif'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DPartementJsonldDepartementCreate
     */
    'commentaire'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DPartementJsonldDepartementCreate
     */
    'habilitationDepartementale'?: boolean;
}
/**
 * 
 * @export
 * @interface DPartementJsonldDepartementCreated
 */
export interface DPartementJsonldDepartementCreated {
    /**
     * 
     * @type {AnnexeJsonldAnnexeReadContext}
     * @memberof DPartementJsonldDepartementCreated
     */
    '@context'?: AnnexeJsonldAnnexeReadContext;
    /**
     * 
     * @type {string}
     * @memberof DPartementJsonldDepartementCreated
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DPartementJsonldDepartementCreated
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof DPartementJsonldDepartementCreated
     */
    'codgeo'?: string;
    /**
     * 
     * @type {string}
     * @memberof DPartementJsonldDepartementCreated
     */
    'libelle': string;
    /**
     * 
     * @type {string}
     * @memberof DPartementJsonldDepartementCreated
     */
    'prefix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DPartementJsonldDepartementCreated
     */
    'region'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DPartementJsonldDepartementCreated
     */
    'ordre': number | null;
    /**
     * 
     * @type {number}
     * @memberof DPartementJsonldDepartementCreated
     */
    'prefDnid': number | null;
    /**
     * 
     * @type {string}
     * @memberof DPartementJsonldDepartementCreated
     */
    'annexTarif'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DPartementJsonldDepartementCreated
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DPartementJsonldDepartementCreated
     */
    'commentaire'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DPartementJsonldDepartementCreated
     */
    'habilitationDepartementale'?: boolean;
}
/**
 * 
 * @export
 * @interface DPartementJsonldDepartementRead
 */
export interface DPartementJsonldDepartementRead {
    /**
     * 
     * @type {AnnexeJsonldAnnexeReadContext}
     * @memberof DPartementJsonldDepartementRead
     */
    '@context'?: AnnexeJsonldAnnexeReadContext;
    /**
     * 
     * @type {string}
     * @memberof DPartementJsonldDepartementRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DPartementJsonldDepartementRead
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof DPartementJsonldDepartementRead
     */
    'codgeo'?: string;
    /**
     * 
     * @type {string}
     * @memberof DPartementJsonldDepartementRead
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof DPartementJsonldDepartementRead
     */
    'prefix'?: string | null;
    /**
     * 
     * @type {DPartementJsonldDepartementReadRegion}
     * @memberof DPartementJsonldDepartementRead
     */
    'region'?: DPartementJsonldDepartementReadRegion | null;
    /**
     * 
     * @type {number}
     * @memberof DPartementJsonldDepartementRead
     */
    'ordre'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DPartementJsonldDepartementRead
     */
    'prefDnid'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DPartementJsonldDepartementRead
     */
    'annexTarif'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DPartementJsonldDepartementRead
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DPartementJsonldDepartementRead
     */
    'commentaire'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DPartementJsonldDepartementRead
     */
    'habilitationDepartementale'?: boolean;
}
/**
 * 
 * @export
 * @interface DPartementJsonldDepartementReadRegion
 */
export interface DPartementJsonldDepartementReadRegion {
    /**
     * 
     * @type {AnnexeJsonldAnnexeReadContext}
     * @memberof DPartementJsonldDepartementReadRegion
     */
    '@context'?: AnnexeJsonldAnnexeReadContext;
    /**
     * 
     * @type {string}
     * @memberof DPartementJsonldDepartementReadRegion
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DPartementJsonldDepartementReadRegion
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof DPartementJsonldDepartementReadRegion
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof DPartementJsonldDepartementReadRegion
     */
    'idregion'?: number;
    /**
     * 
     * @type {string}
     * @memberof DPartementJsonldDepartementReadRegion
     */
    'cheflieu'?: string;
    /**
     * 
     * @type {number}
     * @memberof DPartementJsonldDepartementReadRegion
     */
    'tncc'?: number;
    /**
     * 
     * @type {string}
     * @memberof DPartementJsonldDepartementReadRegion
     */
    'ncc'?: string;
    /**
     * 
     * @type {number}
     * @memberof DPartementJsonldDepartementReadRegion
     */
    'regioncommerciale'?: number;
}
/**
 * 
 * @export
 * @interface DPartementJsonldDepartementUpdate
 */
export interface DPartementJsonldDepartementUpdate {
    /**
     * 
     * @type {AnnexeJsonldAnnexeReadContext}
     * @memberof DPartementJsonldDepartementUpdate
     */
    '@context'?: AnnexeJsonldAnnexeReadContext;
    /**
     * 
     * @type {string}
     * @memberof DPartementJsonldDepartementUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DPartementJsonldDepartementUpdate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof DPartementJsonldDepartementUpdate
     */
    'codgeo'?: string;
    /**
     * 
     * @type {string}
     * @memberof DPartementJsonldDepartementUpdate
     */
    'libelle': string;
    /**
     * 
     * @type {string}
     * @memberof DPartementJsonldDepartementUpdate
     */
    'prefix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DPartementJsonldDepartementUpdate
     */
    'region'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DPartementJsonldDepartementUpdate
     */
    'ordre': number | null;
    /**
     * 
     * @type {number}
     * @memberof DPartementJsonldDepartementUpdate
     */
    'prefDnid': number | null;
    /**
     * 
     * @type {string}
     * @memberof DPartementJsonldDepartementUpdate
     */
    'annexTarif'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DPartementJsonldDepartementUpdate
     */
    'commentaire'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DPartementJsonldDepartementUpdate
     */
    'habilitationDepartementale'?: boolean;
}
/**
 * 
 * @export
 * @interface GreffeGreffeRead
 */
export interface GreffeGreffeRead {
    /**
     * 
     * @type {number}
     * @memberof GreffeGreffeRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GreffeGreffeRead
     */
    'greffes'?: string;
    /**
     * 
     * @type {string}
     * @memberof GreffeGreffeRead
     */
    'article'?: string;
    /**
     * 
     * @type {GreffeGreffeReadDepartement}
     * @memberof GreffeGreffeRead
     */
    'departement'?: GreffeGreffeReadDepartement | null;
    /**
     * 
     * @type {string}
     * @memberof GreffeGreffeRead
     */
    'mailBodacc'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GreffeGreffeRead
     */
    'bodaccMailPossible'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof GreffeGreffeRead
     */
    'adresseComplete'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GreffeGreffeRead
     */
    'dateModif'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GreffeGreffeRead
     */
    'userModif'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GreffeGreffeRead
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GreffeGreffeRead
     */
    'userCreation'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GreffeGreffeRead
     */
    'prefJalCaractere'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GreffeGreffeRead
     */
    'prefSpelCaractere'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GreffeGreffeRead
     */
    'prefJalCaractereXl'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GreffeGreffeRead
     */
    'prefSpelCaractereXl'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GreffeGreffeRead
     */
    'prefJalForfait'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GreffeGreffeRead
     */
    'prefSpelForfait'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GreffeGreffeRead
     */
    'prefJalForfaitXl'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GreffeGreffeRead
     */
    'prefSpelForfaitXl'?: number | null;
}
/**
 * 
 * @export
 * @interface GreffeGreffeReadDepartement
 */
export interface GreffeGreffeReadDepartement {
    /**
     * 
     * @type {string}
     * @memberof GreffeGreffeReadDepartement
     */
    'codgeo'?: string;
    /**
     * 
     * @type {string}
     * @memberof GreffeGreffeReadDepartement
     */
    'libelle'?: string;
}
/**
 * 
 * @export
 * @interface GreffeJsonldGreffeRead
 */
export interface GreffeJsonldGreffeRead {
    /**
     * 
     * @type {string}
     * @memberof GreffeJsonldGreffeRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GreffeJsonldGreffeRead
     */
    '@type'?: string;
    /**
     * 
     * @type {AnnexeJsonldAnnexeReadContext}
     * @memberof GreffeJsonldGreffeRead
     */
    '@context'?: AnnexeJsonldAnnexeReadContext;
    /**
     * 
     * @type {number}
     * @memberof GreffeJsonldGreffeRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GreffeJsonldGreffeRead
     */
    'greffes'?: string;
    /**
     * 
     * @type {string}
     * @memberof GreffeJsonldGreffeRead
     */
    'article'?: string;
    /**
     * 
     * @type {GreffeJsonldGreffeReadDepartement}
     * @memberof GreffeJsonldGreffeRead
     */
    'departement'?: GreffeJsonldGreffeReadDepartement | null;
    /**
     * 
     * @type {string}
     * @memberof GreffeJsonldGreffeRead
     */
    'mailBodacc'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GreffeJsonldGreffeRead
     */
    'bodaccMailPossible'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof GreffeJsonldGreffeRead
     */
    'adresseComplete'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GreffeJsonldGreffeRead
     */
    'dateModif'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GreffeJsonldGreffeRead
     */
    'userModif'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GreffeJsonldGreffeRead
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GreffeJsonldGreffeRead
     */
    'userCreation'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GreffeJsonldGreffeRead
     */
    'prefJalCaractere'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GreffeJsonldGreffeRead
     */
    'prefSpelCaractere'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GreffeJsonldGreffeRead
     */
    'prefJalCaractereXl'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GreffeJsonldGreffeRead
     */
    'prefSpelCaractereXl'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GreffeJsonldGreffeRead
     */
    'prefJalForfait'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GreffeJsonldGreffeRead
     */
    'prefSpelForfait'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GreffeJsonldGreffeRead
     */
    'prefJalForfaitXl'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GreffeJsonldGreffeRead
     */
    'prefSpelForfaitXl'?: number | null;
}
/**
 * 
 * @export
 * @interface GreffeJsonldGreffeReadDepartement
 */
export interface GreffeJsonldGreffeReadDepartement {
    /**
     * 
     * @type {AnnexeJsonldAnnexeReadContext}
     * @memberof GreffeJsonldGreffeReadDepartement
     */
    '@context'?: AnnexeJsonldAnnexeReadContext;
    /**
     * 
     * @type {string}
     * @memberof GreffeJsonldGreffeReadDepartement
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GreffeJsonldGreffeReadDepartement
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof GreffeJsonldGreffeReadDepartement
     */
    'codgeo'?: string;
    /**
     * 
     * @type {string}
     * @memberof GreffeJsonldGreffeReadDepartement
     */
    'libelle'?: string;
}
/**
 * 
 * @export
 * @interface PaysJsonldPaysRead
 */
export interface PaysJsonldPaysRead {
    /**
     * 
     * @type {string}
     * @memberof PaysJsonldPaysRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaysJsonldPaysRead
     */
    '@type'?: string;
    /**
     * 
     * @type {AnnexeJsonldAnnexeReadContext}
     * @memberof PaysJsonldPaysRead
     */
    '@context'?: AnnexeJsonldAnnexeReadContext;
    /**
     * 
     * @type {string}
     * @memberof PaysJsonldPaysRead
     */
    'codeIso2'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaysJsonldPaysRead
     */
    'codeIso3'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaysJsonldPaysRead
     */
    'codeNumerique'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaysJsonldPaysRead
     */
    'codePays'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaysJsonldPaysRead
     */
    'codeRattachement'?: string;
    /**
     * 
     * @type {number}
     * @memberof PaysJsonldPaysRead
     */
    'codeActualite'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaysJsonldPaysRead
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaysJsonldPaysRead
     */
    'libelleOfficiel'?: string;
}
/**
 * 
 * @export
 * @interface PaysPaysRead
 */
export interface PaysPaysRead {
    /**
     * 
     * @type {string}
     * @memberof PaysPaysRead
     */
    'codeIso2'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaysPaysRead
     */
    'codeIso3'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaysPaysRead
     */
    'codeNumerique'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaysPaysRead
     */
    'codePays'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaysPaysRead
     */
    'codeRattachement'?: string;
    /**
     * 
     * @type {number}
     * @memberof PaysPaysRead
     */
    'codeActualite'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaysPaysRead
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaysPaysRead
     */
    'libelleOfficiel'?: string;
}
/**
 * 
 * @export
 * @interface RegionJsonldRegionRead
 */
export interface RegionJsonldRegionRead {
    /**
     * 
     * @type {string}
     * @memberof RegionJsonldRegionRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegionJsonldRegionRead
     */
    '@type'?: string;
    /**
     * 
     * @type {AnnexeJsonldAnnexeReadContext}
     * @memberof RegionJsonldRegionRead
     */
    '@context'?: AnnexeJsonldAnnexeReadContext;
    /**
     * 
     * @type {number}
     * @memberof RegionJsonldRegionRead
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof RegionJsonldRegionRead
     */
    'idregion'?: number;
    /**
     * 
     * @type {string}
     * @memberof RegionJsonldRegionRead
     */
    'cheflieu'?: string;
    /**
     * 
     * @type {number}
     * @memberof RegionJsonldRegionRead
     */
    'tncc'?: number;
    /**
     * 
     * @type {string}
     * @memberof RegionJsonldRegionRead
     */
    'ncc'?: string;
    /**
     * 
     * @type {number}
     * @memberof RegionJsonldRegionRead
     */
    'regioncommerciale'?: number;
}
/**
 * 
 * @export
 * @interface RegionRegionRead
 */
export interface RegionRegionRead {
    /**
     * 
     * @type {number}
     * @memberof RegionRegionRead
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof RegionRegionRead
     */
    'idregion'?: number;
    /**
     * 
     * @type {string}
     * @memberof RegionRegionRead
     */
    'cheflieu'?: string;
    /**
     * 
     * @type {number}
     * @memberof RegionRegionRead
     */
    'tncc'?: number;
    /**
     * 
     * @type {string}
     * @memberof RegionRegionRead
     */
    'ncc'?: string;
    /**
     * 
     * @type {number}
     * @memberof RegionRegionRead
     */
    'regioncommerciale'?: number;
}
/**
 * 
 * @export
 * @interface TblArrondissementCommuneRead
 */
export interface TblArrondissementCommuneRead {
    /**
     * 
     * @type {number}
     * @memberof TblArrondissementCommuneRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblArrondissementCommuneRead
     */
    'arrondissement'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblArrondissementCommuneRead
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblArrondissementCommuneRead
     */
    'dep'?: string | null;
}
/**
 * 
 * @export
 * @interface TblArrondissementJsonldCommuneRead
 */
export interface TblArrondissementJsonldCommuneRead {
    /**
     * 
     * @type {AnnexeJsonldAnnexeReadContext}
     * @memberof TblArrondissementJsonldCommuneRead
     */
    '@context'?: AnnexeJsonldAnnexeReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblArrondissementJsonldCommuneRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblArrondissementJsonldCommuneRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblArrondissementJsonldCommuneRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblArrondissementJsonldCommuneRead
     */
    'arrondissement'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblArrondissementJsonldCommuneRead
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblArrondissementJsonldCommuneRead
     */
    'dep'?: string | null;
}
/**
 * 
 * @export
 * @interface TblCommuneCodepostalCreated
 */
export interface TblCommuneCodepostalCreated {
    /**
     * 
     * @type {string}
     * @memberof TblCommuneCodepostalCreated
     */
    'insee'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneCodepostalCreated
     */
    'libgeo'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneCodepostalCreated
     */
    'region'?: string | null;
    /**
     * 
     * @type {TblCommuneCodepostalCreatedDepartement}
     * @memberof TblCommuneCodepostalCreated
     */
    'departement'?: TblCommuneCodepostalCreatedDepartement | null;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneCodepostalCreated
     */
    'arrondissement'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneCodepostalCreated
     */
    'article'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneCodepostalCreated
     */
    'ville'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneCodepostalCreated
     */
    'villeMaj'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneCodepostalCreated
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {TblCommuneCodepostalCreatedGreffe}
     * @memberof TblCommuneCodepostalCreated
     */
    'greffe'?: TblCommuneCodepostalCreatedGreffe | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblCommuneCodepostalCreated
     */
    'deleted'?: boolean;
}
/**
 * 
 * @export
 * @interface TblCommuneCodepostalCreatedDepartement
 */
export interface TblCommuneCodepostalCreatedDepartement {
    /**
     * 
     * @type {string}
     * @memberof TblCommuneCodepostalCreatedDepartement
     */
    'codgeo'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneCodepostalCreatedDepartement
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneCodepostalCreatedDepartement
     */
    'prefix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneCodepostalCreatedDepartement
     */
    'region'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblCommuneCodepostalCreatedDepartement
     */
    'ordre'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblCommuneCodepostalCreatedDepartement
     */
    'prefDnid'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneCodepostalCreatedDepartement
     */
    'annexTarif'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblCommuneCodepostalCreatedDepartement
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneCodepostalCreatedDepartement
     */
    'commentaire'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblCommuneCodepostalCreatedDepartement
     */
    'habilitationDepartementale'?: boolean;
}
/**
 * 
 * @export
 * @interface TblCommuneCodepostalCreatedGreffe
 */
export interface TblCommuneCodepostalCreatedGreffe {
    /**
     * 
     * @type {number}
     * @memberof TblCommuneCodepostalCreatedGreffe
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneCodepostalCreatedGreffe
     */
    'greffes'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneCodepostalCreatedGreffe
     */
    'article'?: string;
    /**
     * 
     * @type {TblCommuneCodepostalCreatedDepartement}
     * @memberof TblCommuneCodepostalCreatedGreffe
     */
    'departement'?: TblCommuneCodepostalCreatedDepartement | null;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneCodepostalCreatedGreffe
     */
    'mailBodacc'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblCommuneCodepostalCreatedGreffe
     */
    'bodaccMailPossible'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneCodepostalCreatedGreffe
     */
    'adresseComplete'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneCodepostalCreatedGreffe
     */
    'dateModif'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblCommuneCodepostalCreatedGreffe
     */
    'userModif'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneCodepostalCreatedGreffe
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblCommuneCodepostalCreatedGreffe
     */
    'userCreation'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblCommuneCodepostalCreatedGreffe
     */
    'prefJalCaractere'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblCommuneCodepostalCreatedGreffe
     */
    'prefSpelCaractere'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblCommuneCodepostalCreatedGreffe
     */
    'prefJalCaractereXl'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblCommuneCodepostalCreatedGreffe
     */
    'prefSpelCaractereXl'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblCommuneCodepostalCreatedGreffe
     */
    'prefJalForfait'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblCommuneCodepostalCreatedGreffe
     */
    'prefSpelForfait'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblCommuneCodepostalCreatedGreffe
     */
    'prefJalForfaitXl'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblCommuneCodepostalCreatedGreffe
     */
    'prefSpelForfaitXl'?: number | null;
}
/**
 * 
 * @export
 * @interface TblCommuneCodepostalRead
 */
export interface TblCommuneCodepostalRead {
    /**
     * 
     * @type {string}
     * @memberof TblCommuneCodepostalRead
     */
    'insee'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneCodepostalRead
     */
    'libgeo'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneCodepostalRead
     */
    'region'?: string | null;
    /**
     * 
     * @type {TblCommuneCodepostalReadDepartement}
     * @memberof TblCommuneCodepostalRead
     */
    'departement'?: TblCommuneCodepostalReadDepartement | null;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneCodepostalRead
     */
    'arrondissement'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneCodepostalRead
     */
    'article'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneCodepostalRead
     */
    'ville'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneCodepostalRead
     */
    'villeMaj'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneCodepostalRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {TblCommuneCodepostalReadGreffe}
     * @memberof TblCommuneCodepostalRead
     */
    'greffe'?: TblCommuneCodepostalReadGreffe | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblCommuneCodepostalRead
     */
    'deleted'?: boolean;
}
/**
 * 
 * @export
 * @interface TblCommuneCodepostalReadDepartement
 */
export interface TblCommuneCodepostalReadDepartement {
    /**
     * 
     * @type {string}
     * @memberof TblCommuneCodepostalReadDepartement
     */
    'codgeo'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneCodepostalReadDepartement
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneCodepostalReadDepartement
     */
    'prefix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneCodepostalReadDepartement
     */
    'region'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblCommuneCodepostalReadDepartement
     */
    'ordre'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblCommuneCodepostalReadDepartement
     */
    'prefDnid'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneCodepostalReadDepartement
     */
    'annexTarif'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblCommuneCodepostalReadDepartement
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneCodepostalReadDepartement
     */
    'commentaire'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblCommuneCodepostalReadDepartement
     */
    'habilitationDepartementale'?: boolean;
}
/**
 * 
 * @export
 * @interface TblCommuneCodepostalReadGreffe
 */
export interface TblCommuneCodepostalReadGreffe {
    /**
     * 
     * @type {number}
     * @memberof TblCommuneCodepostalReadGreffe
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneCodepostalReadGreffe
     */
    'greffes'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneCodepostalReadGreffe
     */
    'article'?: string;
    /**
     * 
     * @type {TblCommuneCodepostalReadDepartement}
     * @memberof TblCommuneCodepostalReadGreffe
     */
    'departement'?: TblCommuneCodepostalReadDepartement | null;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneCodepostalReadGreffe
     */
    'mailBodacc'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblCommuneCodepostalReadGreffe
     */
    'bodaccMailPossible'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneCodepostalReadGreffe
     */
    'adresseComplete'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneCodepostalReadGreffe
     */
    'dateModif'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblCommuneCodepostalReadGreffe
     */
    'userModif'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneCodepostalReadGreffe
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblCommuneCodepostalReadGreffe
     */
    'userCreation'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblCommuneCodepostalReadGreffe
     */
    'prefJalCaractere'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblCommuneCodepostalReadGreffe
     */
    'prefSpelCaractere'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblCommuneCodepostalReadGreffe
     */
    'prefJalCaractereXl'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblCommuneCodepostalReadGreffe
     */
    'prefSpelCaractereXl'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblCommuneCodepostalReadGreffe
     */
    'prefJalForfait'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblCommuneCodepostalReadGreffe
     */
    'prefSpelForfait'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblCommuneCodepostalReadGreffe
     */
    'prefJalForfaitXl'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblCommuneCodepostalReadGreffe
     */
    'prefSpelForfaitXl'?: number | null;
}
/**
 * 
 * @export
 * @interface TblCommuneJsonldCodepostalCreated
 */
export interface TblCommuneJsonldCodepostalCreated {
    /**
     * 
     * @type {AnnexeJsonldAnnexeReadContext}
     * @memberof TblCommuneJsonldCodepostalCreated
     */
    '@context'?: AnnexeJsonldAnnexeReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneJsonldCodepostalCreated
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneJsonldCodepostalCreated
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneJsonldCodepostalCreated
     */
    'insee'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneJsonldCodepostalCreated
     */
    'libgeo'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneJsonldCodepostalCreated
     */
    'region'?: string | null;
    /**
     * 
     * @type {TblCommuneJsonldCodepostalCreatedDepartement}
     * @memberof TblCommuneJsonldCodepostalCreated
     */
    'departement'?: TblCommuneJsonldCodepostalCreatedDepartement | null;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneJsonldCodepostalCreated
     */
    'arrondissement'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneJsonldCodepostalCreated
     */
    'article'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneJsonldCodepostalCreated
     */
    'ville'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneJsonldCodepostalCreated
     */
    'villeMaj'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneJsonldCodepostalCreated
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {TblCommuneJsonldCodepostalCreatedGreffe}
     * @memberof TblCommuneJsonldCodepostalCreated
     */
    'greffe'?: TblCommuneJsonldCodepostalCreatedGreffe | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblCommuneJsonldCodepostalCreated
     */
    'deleted'?: boolean;
}
/**
 * 
 * @export
 * @interface TblCommuneJsonldCodepostalCreatedDepartement
 */
export interface TblCommuneJsonldCodepostalCreatedDepartement {
    /**
     * 
     * @type {AnnexeJsonldAnnexeReadContext}
     * @memberof TblCommuneJsonldCodepostalCreatedDepartement
     */
    '@context'?: AnnexeJsonldAnnexeReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneJsonldCodepostalCreatedDepartement
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneJsonldCodepostalCreatedDepartement
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneJsonldCodepostalCreatedDepartement
     */
    'codgeo'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneJsonldCodepostalCreatedDepartement
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneJsonldCodepostalCreatedDepartement
     */
    'prefix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneJsonldCodepostalCreatedDepartement
     */
    'region'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblCommuneJsonldCodepostalCreatedDepartement
     */
    'ordre'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblCommuneJsonldCodepostalCreatedDepartement
     */
    'prefDnid'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneJsonldCodepostalCreatedDepartement
     */
    'annexTarif'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblCommuneJsonldCodepostalCreatedDepartement
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneJsonldCodepostalCreatedDepartement
     */
    'commentaire'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblCommuneJsonldCodepostalCreatedDepartement
     */
    'habilitationDepartementale'?: boolean;
}
/**
 * 
 * @export
 * @interface TblCommuneJsonldCodepostalCreatedGreffe
 */
export interface TblCommuneJsonldCodepostalCreatedGreffe {
    /**
     * 
     * @type {AnnexeJsonldAnnexeReadContext}
     * @memberof TblCommuneJsonldCodepostalCreatedGreffe
     */
    '@context'?: AnnexeJsonldAnnexeReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneJsonldCodepostalCreatedGreffe
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneJsonldCodepostalCreatedGreffe
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblCommuneJsonldCodepostalCreatedGreffe
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneJsonldCodepostalCreatedGreffe
     */
    'greffes'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneJsonldCodepostalCreatedGreffe
     */
    'article'?: string;
    /**
     * 
     * @type {TblCommuneJsonldCodepostalCreatedDepartement}
     * @memberof TblCommuneJsonldCodepostalCreatedGreffe
     */
    'departement'?: TblCommuneJsonldCodepostalCreatedDepartement | null;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneJsonldCodepostalCreatedGreffe
     */
    'mailBodacc'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblCommuneJsonldCodepostalCreatedGreffe
     */
    'bodaccMailPossible'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneJsonldCodepostalCreatedGreffe
     */
    'adresseComplete'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneJsonldCodepostalCreatedGreffe
     */
    'dateModif'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblCommuneJsonldCodepostalCreatedGreffe
     */
    'userModif'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneJsonldCodepostalCreatedGreffe
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblCommuneJsonldCodepostalCreatedGreffe
     */
    'userCreation'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblCommuneJsonldCodepostalCreatedGreffe
     */
    'prefJalCaractere'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblCommuneJsonldCodepostalCreatedGreffe
     */
    'prefSpelCaractere'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblCommuneJsonldCodepostalCreatedGreffe
     */
    'prefJalCaractereXl'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblCommuneJsonldCodepostalCreatedGreffe
     */
    'prefSpelCaractereXl'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblCommuneJsonldCodepostalCreatedGreffe
     */
    'prefJalForfait'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblCommuneJsonldCodepostalCreatedGreffe
     */
    'prefSpelForfait'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblCommuneJsonldCodepostalCreatedGreffe
     */
    'prefJalForfaitXl'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblCommuneJsonldCodepostalCreatedGreffe
     */
    'prefSpelForfaitXl'?: number | null;
}
/**
 * 
 * @export
 * @interface TblCommuneJsonldCodepostalRead
 */
export interface TblCommuneJsonldCodepostalRead {
    /**
     * 
     * @type {AnnexeJsonldAnnexeReadContext}
     * @memberof TblCommuneJsonldCodepostalRead
     */
    '@context'?: AnnexeJsonldAnnexeReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneJsonldCodepostalRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneJsonldCodepostalRead
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneJsonldCodepostalRead
     */
    'insee'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneJsonldCodepostalRead
     */
    'libgeo'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneJsonldCodepostalRead
     */
    'region'?: string | null;
    /**
     * 
     * @type {TblCommuneJsonldCodepostalReadDepartement}
     * @memberof TblCommuneJsonldCodepostalRead
     */
    'departement'?: TblCommuneJsonldCodepostalReadDepartement | null;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneJsonldCodepostalRead
     */
    'arrondissement'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneJsonldCodepostalRead
     */
    'article'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneJsonldCodepostalRead
     */
    'ville'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneJsonldCodepostalRead
     */
    'villeMaj'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneJsonldCodepostalRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {TblCommuneJsonldCodepostalReadGreffe}
     * @memberof TblCommuneJsonldCodepostalRead
     */
    'greffe'?: TblCommuneJsonldCodepostalReadGreffe | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblCommuneJsonldCodepostalRead
     */
    'deleted'?: boolean;
}
/**
 * 
 * @export
 * @interface TblCommuneJsonldCodepostalReadDepartement
 */
export interface TblCommuneJsonldCodepostalReadDepartement {
    /**
     * 
     * @type {AnnexeJsonldAnnexeReadContext}
     * @memberof TblCommuneJsonldCodepostalReadDepartement
     */
    '@context'?: AnnexeJsonldAnnexeReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneJsonldCodepostalReadDepartement
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneJsonldCodepostalReadDepartement
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneJsonldCodepostalReadDepartement
     */
    'codgeo'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneJsonldCodepostalReadDepartement
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneJsonldCodepostalReadDepartement
     */
    'prefix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneJsonldCodepostalReadDepartement
     */
    'region'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblCommuneJsonldCodepostalReadDepartement
     */
    'ordre'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblCommuneJsonldCodepostalReadDepartement
     */
    'prefDnid'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneJsonldCodepostalReadDepartement
     */
    'annexTarif'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblCommuneJsonldCodepostalReadDepartement
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneJsonldCodepostalReadDepartement
     */
    'commentaire'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblCommuneJsonldCodepostalReadDepartement
     */
    'habilitationDepartementale'?: boolean;
}
/**
 * 
 * @export
 * @interface TblCommuneJsonldCodepostalReadGreffe
 */
export interface TblCommuneJsonldCodepostalReadGreffe {
    /**
     * 
     * @type {AnnexeJsonldAnnexeReadContext}
     * @memberof TblCommuneJsonldCodepostalReadGreffe
     */
    '@context'?: AnnexeJsonldAnnexeReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneJsonldCodepostalReadGreffe
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneJsonldCodepostalReadGreffe
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblCommuneJsonldCodepostalReadGreffe
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneJsonldCodepostalReadGreffe
     */
    'greffes'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneJsonldCodepostalReadGreffe
     */
    'article'?: string;
    /**
     * 
     * @type {TblCommuneJsonldCodepostalReadDepartement}
     * @memberof TblCommuneJsonldCodepostalReadGreffe
     */
    'departement'?: TblCommuneJsonldCodepostalReadDepartement | null;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneJsonldCodepostalReadGreffe
     */
    'mailBodacc'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblCommuneJsonldCodepostalReadGreffe
     */
    'bodaccMailPossible'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneJsonldCodepostalReadGreffe
     */
    'adresseComplete'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneJsonldCodepostalReadGreffe
     */
    'dateModif'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblCommuneJsonldCodepostalReadGreffe
     */
    'userModif'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblCommuneJsonldCodepostalReadGreffe
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblCommuneJsonldCodepostalReadGreffe
     */
    'userCreation'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblCommuneJsonldCodepostalReadGreffe
     */
    'prefJalCaractere'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblCommuneJsonldCodepostalReadGreffe
     */
    'prefSpelCaractere'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblCommuneJsonldCodepostalReadGreffe
     */
    'prefJalCaractereXl'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblCommuneJsonldCodepostalReadGreffe
     */
    'prefSpelCaractereXl'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblCommuneJsonldCodepostalReadGreffe
     */
    'prefJalForfait'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblCommuneJsonldCodepostalReadGreffe
     */
    'prefSpelForfait'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblCommuneJsonldCodepostalReadGreffe
     */
    'prefJalForfaitXl'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblCommuneJsonldCodepostalReadGreffe
     */
    'prefSpelForfaitXl'?: number | null;
}
/**
 * 
 * @export
 * @interface TblDepCodepostalCreated
 */
export interface TblDepCodepostalCreated {
    /**
     * 
     * @type {string}
     * @memberof TblDepCodepostalCreated
     */
    'codgeo'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDepCodepostalCreated
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDepCodepostalCreated
     */
    'prefix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblDepCodepostalCreated
     */
    'region'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblDepCodepostalCreated
     */
    'ordre'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblDepCodepostalCreated
     */
    'prefDnid'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblDepCodepostalCreated
     */
    'annexTarif'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblDepCodepostalCreated
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TblDepCodepostalCreated
     */
    'commentaire'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblDepCodepostalCreated
     */
    'habilitationDepartementale'?: boolean;
}
/**
 * 
 * @export
 * @interface TblDepCodepostalRead
 */
export interface TblDepCodepostalRead {
    /**
     * 
     * @type {string}
     * @memberof TblDepCodepostalRead
     */
    'codgeo'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDepCodepostalRead
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDepCodepostalRead
     */
    'prefix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblDepCodepostalRead
     */
    'region'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblDepCodepostalRead
     */
    'ordre'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblDepCodepostalRead
     */
    'prefDnid'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblDepCodepostalRead
     */
    'annexTarif'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblDepCodepostalRead
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TblDepCodepostalRead
     */
    'commentaire'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblDepCodepostalRead
     */
    'habilitationDepartementale'?: boolean;
}
/**
 * 
 * @export
 * @interface TblDepCommuneRead
 */
export interface TblDepCommuneRead {
    /**
     * 
     * @type {string}
     * @memberof TblDepCommuneRead
     */
    'codgeo'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDepCommuneRead
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDepCommuneRead
     */
    'prefix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblDepCommuneRead
     */
    'region'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblDepCommuneRead
     */
    'ordre'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblDepCommuneRead
     */
    'prefDnid'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblDepCommuneRead
     */
    'annexTarif'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblDepCommuneRead
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TblDepCommuneRead
     */
    'commentaire'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblDepCommuneRead
     */
    'habilitationDepartementale'?: boolean;
}
/**
 * 
 * @export
 * @interface TblDepGreffeRead
 */
export interface TblDepGreffeRead {
    /**
     * 
     * @type {string}
     * @memberof TblDepGreffeRead
     */
    'codgeo'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDepGreffeRead
     */
    'libelle'?: string;
}
/**
 * 
 * @export
 * @interface TblDepJsonldCodepostalCreated
 */
export interface TblDepJsonldCodepostalCreated {
    /**
     * 
     * @type {AnnexeJsonldAnnexeReadContext}
     * @memberof TblDepJsonldCodepostalCreated
     */
    '@context'?: AnnexeJsonldAnnexeReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblDepJsonldCodepostalCreated
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDepJsonldCodepostalCreated
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDepJsonldCodepostalCreated
     */
    'codgeo'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDepJsonldCodepostalCreated
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDepJsonldCodepostalCreated
     */
    'prefix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblDepJsonldCodepostalCreated
     */
    'region'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblDepJsonldCodepostalCreated
     */
    'ordre'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblDepJsonldCodepostalCreated
     */
    'prefDnid'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblDepJsonldCodepostalCreated
     */
    'annexTarif'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblDepJsonldCodepostalCreated
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TblDepJsonldCodepostalCreated
     */
    'commentaire'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblDepJsonldCodepostalCreated
     */
    'habilitationDepartementale'?: boolean;
}
/**
 * 
 * @export
 * @interface TblDepJsonldCodepostalRead
 */
export interface TblDepJsonldCodepostalRead {
    /**
     * 
     * @type {AnnexeJsonldAnnexeReadContext}
     * @memberof TblDepJsonldCodepostalRead
     */
    '@context'?: AnnexeJsonldAnnexeReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblDepJsonldCodepostalRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDepJsonldCodepostalRead
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDepJsonldCodepostalRead
     */
    'codgeo'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDepJsonldCodepostalRead
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDepJsonldCodepostalRead
     */
    'prefix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblDepJsonldCodepostalRead
     */
    'region'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblDepJsonldCodepostalRead
     */
    'ordre'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblDepJsonldCodepostalRead
     */
    'prefDnid'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblDepJsonldCodepostalRead
     */
    'annexTarif'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblDepJsonldCodepostalRead
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TblDepJsonldCodepostalRead
     */
    'commentaire'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblDepJsonldCodepostalRead
     */
    'habilitationDepartementale'?: boolean;
}
/**
 * 
 * @export
 * @interface TblDepJsonldCommuneRead
 */
export interface TblDepJsonldCommuneRead {
    /**
     * 
     * @type {AnnexeJsonldAnnexeReadContext}
     * @memberof TblDepJsonldCommuneRead
     */
    '@context'?: AnnexeJsonldAnnexeReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblDepJsonldCommuneRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDepJsonldCommuneRead
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDepJsonldCommuneRead
     */
    'codgeo'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDepJsonldCommuneRead
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDepJsonldCommuneRead
     */
    'prefix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblDepJsonldCommuneRead
     */
    'region'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblDepJsonldCommuneRead
     */
    'ordre'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblDepJsonldCommuneRead
     */
    'prefDnid'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblDepJsonldCommuneRead
     */
    'annexTarif'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblDepJsonldCommuneRead
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TblDepJsonldCommuneRead
     */
    'commentaire'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblDepJsonldCommuneRead
     */
    'habilitationDepartementale'?: boolean;
}
/**
 * 
 * @export
 * @interface TblDepJsonldGreffeRead
 */
export interface TblDepJsonldGreffeRead {
    /**
     * 
     * @type {AnnexeJsonldAnnexeReadContext}
     * @memberof TblDepJsonldGreffeRead
     */
    '@context'?: AnnexeJsonldAnnexeReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblDepJsonldGreffeRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDepJsonldGreffeRead
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDepJsonldGreffeRead
     */
    'codgeo'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDepJsonldGreffeRead
     */
    'libelle'?: string;
}
/**
 * 
 * @export
 * @interface TblGreffesCodepostalCreated
 */
export interface TblGreffesCodepostalCreated {
    /**
     * 
     * @type {number}
     * @memberof TblGreffesCodepostalCreated
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblGreffesCodepostalCreated
     */
    'greffes'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblGreffesCodepostalCreated
     */
    'article'?: string;
    /**
     * 
     * @type {TblCommuneCodepostalCreatedDepartement}
     * @memberof TblGreffesCodepostalCreated
     */
    'departement'?: TblCommuneCodepostalCreatedDepartement | null;
    /**
     * 
     * @type {string}
     * @memberof TblGreffesCodepostalCreated
     */
    'mailBodacc'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblGreffesCodepostalCreated
     */
    'bodaccMailPossible'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblGreffesCodepostalCreated
     */
    'adresseComplete'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblGreffesCodepostalCreated
     */
    'dateModif'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblGreffesCodepostalCreated
     */
    'userModif'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblGreffesCodepostalCreated
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblGreffesCodepostalCreated
     */
    'userCreation'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblGreffesCodepostalCreated
     */
    'prefJalCaractere'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblGreffesCodepostalCreated
     */
    'prefSpelCaractere'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblGreffesCodepostalCreated
     */
    'prefJalCaractereXl'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblGreffesCodepostalCreated
     */
    'prefSpelCaractereXl'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblGreffesCodepostalCreated
     */
    'prefJalForfait'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblGreffesCodepostalCreated
     */
    'prefSpelForfait'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblGreffesCodepostalCreated
     */
    'prefJalForfaitXl'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblGreffesCodepostalCreated
     */
    'prefSpelForfaitXl'?: number | null;
}
/**
 * 
 * @export
 * @interface TblGreffesCodepostalRead
 */
export interface TblGreffesCodepostalRead {
    /**
     * 
     * @type {number}
     * @memberof TblGreffesCodepostalRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblGreffesCodepostalRead
     */
    'greffes'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblGreffesCodepostalRead
     */
    'article'?: string;
    /**
     * 
     * @type {TblCommuneCodepostalReadDepartement}
     * @memberof TblGreffesCodepostalRead
     */
    'departement'?: TblCommuneCodepostalReadDepartement | null;
    /**
     * 
     * @type {string}
     * @memberof TblGreffesCodepostalRead
     */
    'mailBodacc'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblGreffesCodepostalRead
     */
    'bodaccMailPossible'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblGreffesCodepostalRead
     */
    'adresseComplete'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblGreffesCodepostalRead
     */
    'dateModif'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblGreffesCodepostalRead
     */
    'userModif'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblGreffesCodepostalRead
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblGreffesCodepostalRead
     */
    'userCreation'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblGreffesCodepostalRead
     */
    'prefJalCaractere'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblGreffesCodepostalRead
     */
    'prefSpelCaractere'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblGreffesCodepostalRead
     */
    'prefJalCaractereXl'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblGreffesCodepostalRead
     */
    'prefSpelCaractereXl'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblGreffesCodepostalRead
     */
    'prefJalForfait'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblGreffesCodepostalRead
     */
    'prefSpelForfait'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblGreffesCodepostalRead
     */
    'prefJalForfaitXl'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblGreffesCodepostalRead
     */
    'prefSpelForfaitXl'?: number | null;
}
/**
 * 
 * @export
 * @interface TblGreffesCommuneRead
 */
export interface TblGreffesCommuneRead {
    /**
     * 
     * @type {CommuneCommuneReadDepartement}
     * @memberof TblGreffesCommuneRead
     */
    'departement'?: CommuneCommuneReadDepartement | null;
}
/**
 * 
 * @export
 * @interface TblGreffesJsonldCodepostalCreated
 */
export interface TblGreffesJsonldCodepostalCreated {
    /**
     * 
     * @type {AnnexeJsonldAnnexeReadContext}
     * @memberof TblGreffesJsonldCodepostalCreated
     */
    '@context'?: AnnexeJsonldAnnexeReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblGreffesJsonldCodepostalCreated
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblGreffesJsonldCodepostalCreated
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblGreffesJsonldCodepostalCreated
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblGreffesJsonldCodepostalCreated
     */
    'greffes'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblGreffesJsonldCodepostalCreated
     */
    'article'?: string;
    /**
     * 
     * @type {TblCommuneJsonldCodepostalCreatedDepartement}
     * @memberof TblGreffesJsonldCodepostalCreated
     */
    'departement'?: TblCommuneJsonldCodepostalCreatedDepartement | null;
    /**
     * 
     * @type {string}
     * @memberof TblGreffesJsonldCodepostalCreated
     */
    'mailBodacc'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblGreffesJsonldCodepostalCreated
     */
    'bodaccMailPossible'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblGreffesJsonldCodepostalCreated
     */
    'adresseComplete'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblGreffesJsonldCodepostalCreated
     */
    'dateModif'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblGreffesJsonldCodepostalCreated
     */
    'userModif'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblGreffesJsonldCodepostalCreated
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblGreffesJsonldCodepostalCreated
     */
    'userCreation'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblGreffesJsonldCodepostalCreated
     */
    'prefJalCaractere'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblGreffesJsonldCodepostalCreated
     */
    'prefSpelCaractere'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblGreffesJsonldCodepostalCreated
     */
    'prefJalCaractereXl'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblGreffesJsonldCodepostalCreated
     */
    'prefSpelCaractereXl'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblGreffesJsonldCodepostalCreated
     */
    'prefJalForfait'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblGreffesJsonldCodepostalCreated
     */
    'prefSpelForfait'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblGreffesJsonldCodepostalCreated
     */
    'prefJalForfaitXl'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblGreffesJsonldCodepostalCreated
     */
    'prefSpelForfaitXl'?: number | null;
}
/**
 * 
 * @export
 * @interface TblGreffesJsonldCodepostalRead
 */
export interface TblGreffesJsonldCodepostalRead {
    /**
     * 
     * @type {AnnexeJsonldAnnexeReadContext}
     * @memberof TblGreffesJsonldCodepostalRead
     */
    '@context'?: AnnexeJsonldAnnexeReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblGreffesJsonldCodepostalRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblGreffesJsonldCodepostalRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblGreffesJsonldCodepostalRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblGreffesJsonldCodepostalRead
     */
    'greffes'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblGreffesJsonldCodepostalRead
     */
    'article'?: string;
    /**
     * 
     * @type {TblCommuneJsonldCodepostalReadDepartement}
     * @memberof TblGreffesJsonldCodepostalRead
     */
    'departement'?: TblCommuneJsonldCodepostalReadDepartement | null;
    /**
     * 
     * @type {string}
     * @memberof TblGreffesJsonldCodepostalRead
     */
    'mailBodacc'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblGreffesJsonldCodepostalRead
     */
    'bodaccMailPossible'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblGreffesJsonldCodepostalRead
     */
    'adresseComplete'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblGreffesJsonldCodepostalRead
     */
    'dateModif'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblGreffesJsonldCodepostalRead
     */
    'userModif'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblGreffesJsonldCodepostalRead
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblGreffesJsonldCodepostalRead
     */
    'userCreation'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblGreffesJsonldCodepostalRead
     */
    'prefJalCaractere'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblGreffesJsonldCodepostalRead
     */
    'prefSpelCaractere'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblGreffesJsonldCodepostalRead
     */
    'prefJalCaractereXl'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblGreffesJsonldCodepostalRead
     */
    'prefSpelCaractereXl'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblGreffesJsonldCodepostalRead
     */
    'prefJalForfait'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblGreffesJsonldCodepostalRead
     */
    'prefSpelForfait'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblGreffesJsonldCodepostalRead
     */
    'prefJalForfaitXl'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblGreffesJsonldCodepostalRead
     */
    'prefSpelForfaitXl'?: number | null;
}
/**
 * 
 * @export
 * @interface TblGreffesJsonldCommuneRead
 */
export interface TblGreffesJsonldCommuneRead {
    /**
     * 
     * @type {AnnexeJsonldAnnexeReadContext}
     * @memberof TblGreffesJsonldCommuneRead
     */
    '@context'?: AnnexeJsonldAnnexeReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblGreffesJsonldCommuneRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblGreffesJsonldCommuneRead
     */
    '@type'?: string;
    /**
     * 
     * @type {CommuneJsonldCommuneReadDepartement}
     * @memberof TblGreffesJsonldCommuneRead
     */
    'departement'?: CommuneJsonldCommuneReadDepartement | null;
}
/**
 * 
 * @export
 * @interface TblRegionDepartementRead
 */
export interface TblRegionDepartementRead {
    /**
     * 
     * @type {number}
     * @memberof TblRegionDepartementRead
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblRegionDepartementRead
     */
    'idregion'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblRegionDepartementRead
     */
    'cheflieu'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblRegionDepartementRead
     */
    'tncc'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblRegionDepartementRead
     */
    'ncc'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblRegionDepartementRead
     */
    'regioncommerciale'?: number;
}
/**
 * 
 * @export
 * @interface TblRegionJsonldDepartementRead
 */
export interface TblRegionJsonldDepartementRead {
    /**
     * 
     * @type {AnnexeJsonldAnnexeReadContext}
     * @memberof TblRegionJsonldDepartementRead
     */
    '@context'?: AnnexeJsonldAnnexeReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblRegionJsonldDepartementRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblRegionJsonldDepartementRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblRegionJsonldDepartementRead
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblRegionJsonldDepartementRead
     */
    'idregion'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblRegionJsonldDepartementRead
     */
    'cheflieu'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblRegionJsonldDepartementRead
     */
    'tncc'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblRegionJsonldDepartementRead
     */
    'ncc'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblRegionJsonldDepartementRead
     */
    'regioncommerciale'?: number;
}

/**
 * AnnexeApi - axios parameter creator
 * @export
 */
export const AnnexeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Obtenir la liste des annexes
         * @summary Obtenir la liste des annexes
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnnexesGetCollection: async (xIdSociete?: number, page?: number, itemsPerPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/geoloc/annexes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'une annexe
         * @summary Récupérer les informations d\'une annexe
         * @param {string} id TblDefAnnexeTarif identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnnexesIdGet: async (id: string, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAnnexesIdGet', 'id', id)
            const localVarPath = `/geoloc/annexes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AnnexeApi - functional programming interface
 * @export
 */
export const AnnexeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AnnexeApiAxiosParamCreator(configuration)
    return {
        /**
         * Obtenir la liste des annexes
         * @summary Obtenir la liste des annexes
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAnnexesGetCollection(xIdSociete?: number, page?: number, itemsPerPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiAnnexesGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAnnexesGetCollection(xIdSociete, page, itemsPerPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Récupérer les informations d\'une annexe
         * @summary Récupérer les informations d\'une annexe
         * @param {string} id TblDefAnnexeTarif identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAnnexesIdGet(id: string, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnnexeJsonldAnnexeRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAnnexesIdGet(id, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AnnexeApi - factory interface
 * @export
 */
export const AnnexeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AnnexeApiFp(configuration)
    return {
        /**
         * Obtenir la liste des annexes
         * @summary Obtenir la liste des annexes
         * @param {AnnexeApiApiAnnexesGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnnexesGetCollection(requestParameters: AnnexeApiApiAnnexesGetCollectionRequest = {}, options?: AxiosRequestConfig): AxiosPromise<ApiAnnexesGetCollection200Response> {
            return localVarFp.apiAnnexesGetCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'une annexe
         * @summary Récupérer les informations d\'une annexe
         * @param {AnnexeApiApiAnnexesIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnnexesIdGet(requestParameters: AnnexeApiApiAnnexesIdGetRequest, options?: AxiosRequestConfig): AxiosPromise<AnnexeJsonldAnnexeRead> {
            return localVarFp.apiAnnexesIdGet(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiAnnexesGetCollection operation in AnnexeApi.
 * @export
 * @interface AnnexeApiApiAnnexesGetCollectionRequest
 */
export interface AnnexeApiApiAnnexesGetCollectionRequest {
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof AnnexeApiApiAnnexesGetCollection
     */
    readonly xIdSociete?: number

    /**
     * The collection page number
     * @type {number}
     * @memberof AnnexeApiApiAnnexesGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof AnnexeApiApiAnnexesGetCollection
     */
    readonly itemsPerPage?: number
}

/**
 * Request parameters for apiAnnexesIdGet operation in AnnexeApi.
 * @export
 * @interface AnnexeApiApiAnnexesIdGetRequest
 */
export interface AnnexeApiApiAnnexesIdGetRequest {
    /**
     * TblDefAnnexeTarif identifier
     * @type {string}
     * @memberof AnnexeApiApiAnnexesIdGet
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof AnnexeApiApiAnnexesIdGet
     */
    readonly xIdSociete?: number
}

/**
 * AnnexeApi - object-oriented interface
 * @export
 * @class AnnexeApi
 * @extends {BaseAPI}
 */
export class AnnexeApi extends BaseAPI {
    /**
     * Obtenir la liste des annexes
     * @summary Obtenir la liste des annexes
     * @param {AnnexeApiApiAnnexesGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnexeApi
     */
    public apiAnnexesGetCollection(requestParameters: AnnexeApiApiAnnexesGetCollectionRequest = {}, options?: AxiosRequestConfig) {
        return AnnexeApiFp(this.configuration).apiAnnexesGetCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'une annexe
     * @summary Récupérer les informations d\'une annexe
     * @param {AnnexeApiApiAnnexesIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnexeApi
     */
    public apiAnnexesIdGet(requestParameters: AnnexeApiApiAnnexesIdGetRequest, options?: AxiosRequestConfig) {
        return AnnexeApiFp(this.configuration).apiAnnexesIdGet(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ArrondissementApi - axios parameter creator
 * @export
 */
export const ArrondissementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Récupérer les informations d\'un arrondissement
         * @summary Récupérer les informations d\'un arrondissement
         * @param {string} arrondissement TblArrondissement identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiArrondissementsArrondissementGet: async (arrondissement: string, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'arrondissement' is not null or undefined
            assertParamExists('apiArrondissementsArrondissementGet', 'arrondissement', arrondissement)
            const localVarPath = `/geoloc/arrondissements/{arrondissement}`
                .replace(`{${"arrondissement"}}`, encodeURIComponent(String(arrondissement)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtenir la liste des arrondissements
         * @summary Obtenir la liste des arrondissements
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiArrondissementsGetCollection: async (xIdSociete?: number, page?: number, itemsPerPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/geoloc/arrondissements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ArrondissementApi - functional programming interface
 * @export
 */
export const ArrondissementApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ArrondissementApiAxiosParamCreator(configuration)
    return {
        /**
         * Récupérer les informations d\'un arrondissement
         * @summary Récupérer les informations d\'un arrondissement
         * @param {string} arrondissement TblArrondissement identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiArrondissementsArrondissementGet(arrondissement: string, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArrondissementJsonldArrondissementRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiArrondissementsArrondissementGet(arrondissement, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtenir la liste des arrondissements
         * @summary Obtenir la liste des arrondissements
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiArrondissementsGetCollection(xIdSociete?: number, page?: number, itemsPerPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiArrondissementsGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiArrondissementsGetCollection(xIdSociete, page, itemsPerPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ArrondissementApi - factory interface
 * @export
 */
export const ArrondissementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ArrondissementApiFp(configuration)
    return {
        /**
         * Récupérer les informations d\'un arrondissement
         * @summary Récupérer les informations d\'un arrondissement
         * @param {ArrondissementApiApiArrondissementsArrondissementGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiArrondissementsArrondissementGet(requestParameters: ArrondissementApiApiArrondissementsArrondissementGetRequest, options?: AxiosRequestConfig): AxiosPromise<ArrondissementJsonldArrondissementRead> {
            return localVarFp.apiArrondissementsArrondissementGet(requestParameters.arrondissement, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtenir la liste des arrondissements
         * @summary Obtenir la liste des arrondissements
         * @param {ArrondissementApiApiArrondissementsGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiArrondissementsGetCollection(requestParameters: ArrondissementApiApiArrondissementsGetCollectionRequest = {}, options?: AxiosRequestConfig): AxiosPromise<ApiArrondissementsGetCollection200Response> {
            return localVarFp.apiArrondissementsGetCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiArrondissementsArrondissementGet operation in ArrondissementApi.
 * @export
 * @interface ArrondissementApiApiArrondissementsArrondissementGetRequest
 */
export interface ArrondissementApiApiArrondissementsArrondissementGetRequest {
    /**
     * TblArrondissement identifier
     * @type {string}
     * @memberof ArrondissementApiApiArrondissementsArrondissementGet
     */
    readonly arrondissement: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof ArrondissementApiApiArrondissementsArrondissementGet
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for apiArrondissementsGetCollection operation in ArrondissementApi.
 * @export
 * @interface ArrondissementApiApiArrondissementsGetCollectionRequest
 */
export interface ArrondissementApiApiArrondissementsGetCollectionRequest {
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof ArrondissementApiApiArrondissementsGetCollection
     */
    readonly xIdSociete?: number

    /**
     * The collection page number
     * @type {number}
     * @memberof ArrondissementApiApiArrondissementsGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof ArrondissementApiApiArrondissementsGetCollection
     */
    readonly itemsPerPage?: number
}

/**
 * ArrondissementApi - object-oriented interface
 * @export
 * @class ArrondissementApi
 * @extends {BaseAPI}
 */
export class ArrondissementApi extends BaseAPI {
    /**
     * Récupérer les informations d\'un arrondissement
     * @summary Récupérer les informations d\'un arrondissement
     * @param {ArrondissementApiApiArrondissementsArrondissementGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArrondissementApi
     */
    public apiArrondissementsArrondissementGet(requestParameters: ArrondissementApiApiArrondissementsArrondissementGetRequest, options?: AxiosRequestConfig) {
        return ArrondissementApiFp(this.configuration).apiArrondissementsArrondissementGet(requestParameters.arrondissement, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtenir la liste des arrondissements
     * @summary Obtenir la liste des arrondissements
     * @param {ArrondissementApiApiArrondissementsGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArrondissementApi
     */
    public apiArrondissementsGetCollection(requestParameters: ArrondissementApiApiArrondissementsGetCollectionRequest = {}, options?: AxiosRequestConfig) {
        return ArrondissementApiFp(this.configuration).apiArrondissementsGetCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CodePostalApi - axios parameter creator
 * @export
 */
export const CodePostalApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Obtenir la liste des codes postaux
         * @summary Obtenir la liste des codes postaux
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [inseeLibgeo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodesPostauxGetCollection: async (xIdSociete?: number, page?: number, itemsPerPage?: number, inseeLibgeo?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/geoloc/codes_postaux`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (inseeLibgeo !== undefined) {
                localVarQueryParameter['insee.libgeo'] = inseeLibgeo;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Supprimer un code postal
         * @summary Supprimer un code postal
         * @param {string} id TblCp identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodesPostauxIdDelete: async (id: string, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCodesPostauxIdDelete', 'id', id)
            const localVarPath = `/geoloc/codes_postaux/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'un code postal
         * @summary Récupérer les informations d\'un code postal
         * @param {string} id TblCp identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodesPostauxIdGet: async (id: string, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCodesPostauxIdGet', 'id', id)
            const localVarPath = `/geoloc/codes_postaux/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces the Code postal resource.
         * @summary Modifier un code postal
         * @param {string} id TblCp identifier
         * @param {CodePostalJsonldCodepostalUpdate} codePostalJsonldCodepostalUpdate The updated Code postal resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodesPostauxIdPut: async (id: string, codePostalJsonldCodepostalUpdate: CodePostalJsonldCodepostalUpdate, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCodesPostauxIdPut', 'id', id)
            // verify required parameter 'codePostalJsonldCodepostalUpdate' is not null or undefined
            assertParamExists('apiCodesPostauxIdPut', 'codePostalJsonldCodepostalUpdate', codePostalJsonldCodepostalUpdate)
            const localVarPath = `/geoloc/codes_postaux/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(codePostalJsonldCodepostalUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Ajouter un code postal
         * @summary Ajouter un code postal
         * @param {CodePostalJsonldCodepostalCreate} codePostalJsonldCodepostalCreate The new Code postal resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodesPostauxPost: async (codePostalJsonldCodepostalCreate: CodePostalJsonldCodepostalCreate, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'codePostalJsonldCodepostalCreate' is not null or undefined
            assertParamExists('apiCodesPostauxPost', 'codePostalJsonldCodepostalCreate', codePostalJsonldCodepostalCreate)
            const localVarPath = `/geoloc/codes_postaux`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(codePostalJsonldCodepostalCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CodePostalApi - functional programming interface
 * @export
 */
export const CodePostalApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CodePostalApiAxiosParamCreator(configuration)
    return {
        /**
         * Obtenir la liste des codes postaux
         * @summary Obtenir la liste des codes postaux
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [inseeLibgeo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCodesPostauxGetCollection(xIdSociete?: number, page?: number, itemsPerPage?: number, inseeLibgeo?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiCodesPostauxGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCodesPostauxGetCollection(xIdSociete, page, itemsPerPage, inseeLibgeo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Supprimer un code postal
         * @summary Supprimer un code postal
         * @param {string} id TblCp identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCodesPostauxIdDelete(id: string, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCodesPostauxIdDelete(id, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Récupérer les informations d\'un code postal
         * @summary Récupérer les informations d\'un code postal
         * @param {string} id TblCp identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCodesPostauxIdGet(id: string, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CodePostalJsonldCodepostalRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCodesPostauxIdGet(id, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces the Code postal resource.
         * @summary Modifier un code postal
         * @param {string} id TblCp identifier
         * @param {CodePostalJsonldCodepostalUpdate} codePostalJsonldCodepostalUpdate The updated Code postal resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCodesPostauxIdPut(id: string, codePostalJsonldCodepostalUpdate: CodePostalJsonldCodepostalUpdate, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CodePostalJsonldCodepostalRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCodesPostauxIdPut(id, codePostalJsonldCodepostalUpdate, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Ajouter un code postal
         * @summary Ajouter un code postal
         * @param {CodePostalJsonldCodepostalCreate} codePostalJsonldCodepostalCreate The new Code postal resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCodesPostauxPost(codePostalJsonldCodepostalCreate: CodePostalJsonldCodepostalCreate, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CodePostalJsonldCodepostalCreated>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCodesPostauxPost(codePostalJsonldCodepostalCreate, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CodePostalApi - factory interface
 * @export
 */
export const CodePostalApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CodePostalApiFp(configuration)
    return {
        /**
         * Obtenir la liste des codes postaux
         * @summary Obtenir la liste des codes postaux
         * @param {CodePostalApiApiCodesPostauxGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodesPostauxGetCollection(requestParameters: CodePostalApiApiCodesPostauxGetCollectionRequest = {}, options?: AxiosRequestConfig): AxiosPromise<ApiCodesPostauxGetCollection200Response> {
            return localVarFp.apiCodesPostauxGetCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, requestParameters.inseeLibgeo, options).then((request) => request(axios, basePath));
        },
        /**
         * Supprimer un code postal
         * @summary Supprimer un code postal
         * @param {CodePostalApiApiCodesPostauxIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodesPostauxIdDelete(requestParameters: CodePostalApiApiCodesPostauxIdDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiCodesPostauxIdDelete(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'un code postal
         * @summary Récupérer les informations d\'un code postal
         * @param {CodePostalApiApiCodesPostauxIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodesPostauxIdGet(requestParameters: CodePostalApiApiCodesPostauxIdGetRequest, options?: AxiosRequestConfig): AxiosPromise<CodePostalJsonldCodepostalRead> {
            return localVarFp.apiCodesPostauxIdGet(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces the Code postal resource.
         * @summary Modifier un code postal
         * @param {CodePostalApiApiCodesPostauxIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodesPostauxIdPut(requestParameters: CodePostalApiApiCodesPostauxIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<CodePostalJsonldCodepostalRead> {
            return localVarFp.apiCodesPostauxIdPut(requestParameters.id, requestParameters.codePostalJsonldCodepostalUpdate, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Ajouter un code postal
         * @summary Ajouter un code postal
         * @param {CodePostalApiApiCodesPostauxPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodesPostauxPost(requestParameters: CodePostalApiApiCodesPostauxPostRequest, options?: AxiosRequestConfig): AxiosPromise<CodePostalJsonldCodepostalCreated> {
            return localVarFp.apiCodesPostauxPost(requestParameters.codePostalJsonldCodepostalCreate, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiCodesPostauxGetCollection operation in CodePostalApi.
 * @export
 * @interface CodePostalApiApiCodesPostauxGetCollectionRequest
 */
export interface CodePostalApiApiCodesPostauxGetCollectionRequest {
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof CodePostalApiApiCodesPostauxGetCollection
     */
    readonly xIdSociete?: number

    /**
     * The collection page number
     * @type {number}
     * @memberof CodePostalApiApiCodesPostauxGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof CodePostalApiApiCodesPostauxGetCollection
     */
    readonly itemsPerPage?: number

    /**
     * 
     * @type {string}
     * @memberof CodePostalApiApiCodesPostauxGetCollection
     */
    readonly inseeLibgeo?: string
}

/**
 * Request parameters for apiCodesPostauxIdDelete operation in CodePostalApi.
 * @export
 * @interface CodePostalApiApiCodesPostauxIdDeleteRequest
 */
export interface CodePostalApiApiCodesPostauxIdDeleteRequest {
    /**
     * TblCp identifier
     * @type {string}
     * @memberof CodePostalApiApiCodesPostauxIdDelete
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof CodePostalApiApiCodesPostauxIdDelete
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for apiCodesPostauxIdGet operation in CodePostalApi.
 * @export
 * @interface CodePostalApiApiCodesPostauxIdGetRequest
 */
export interface CodePostalApiApiCodesPostauxIdGetRequest {
    /**
     * TblCp identifier
     * @type {string}
     * @memberof CodePostalApiApiCodesPostauxIdGet
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof CodePostalApiApiCodesPostauxIdGet
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for apiCodesPostauxIdPut operation in CodePostalApi.
 * @export
 * @interface CodePostalApiApiCodesPostauxIdPutRequest
 */
export interface CodePostalApiApiCodesPostauxIdPutRequest {
    /**
     * TblCp identifier
     * @type {string}
     * @memberof CodePostalApiApiCodesPostauxIdPut
     */
    readonly id: string

    /**
     * The updated Code postal resource
     * @type {CodePostalJsonldCodepostalUpdate}
     * @memberof CodePostalApiApiCodesPostauxIdPut
     */
    readonly codePostalJsonldCodepostalUpdate: CodePostalJsonldCodepostalUpdate

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof CodePostalApiApiCodesPostauxIdPut
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for apiCodesPostauxPost operation in CodePostalApi.
 * @export
 * @interface CodePostalApiApiCodesPostauxPostRequest
 */
export interface CodePostalApiApiCodesPostauxPostRequest {
    /**
     * The new Code postal resource
     * @type {CodePostalJsonldCodepostalCreate}
     * @memberof CodePostalApiApiCodesPostauxPost
     */
    readonly codePostalJsonldCodepostalCreate: CodePostalJsonldCodepostalCreate

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof CodePostalApiApiCodesPostauxPost
     */
    readonly xIdSociete?: number
}

/**
 * CodePostalApi - object-oriented interface
 * @export
 * @class CodePostalApi
 * @extends {BaseAPI}
 */
export class CodePostalApi extends BaseAPI {
    /**
     * Obtenir la liste des codes postaux
     * @summary Obtenir la liste des codes postaux
     * @param {CodePostalApiApiCodesPostauxGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodePostalApi
     */
    public apiCodesPostauxGetCollection(requestParameters: CodePostalApiApiCodesPostauxGetCollectionRequest = {}, options?: AxiosRequestConfig) {
        return CodePostalApiFp(this.configuration).apiCodesPostauxGetCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, requestParameters.inseeLibgeo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Supprimer un code postal
     * @summary Supprimer un code postal
     * @param {CodePostalApiApiCodesPostauxIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodePostalApi
     */
    public apiCodesPostauxIdDelete(requestParameters: CodePostalApiApiCodesPostauxIdDeleteRequest, options?: AxiosRequestConfig) {
        return CodePostalApiFp(this.configuration).apiCodesPostauxIdDelete(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'un code postal
     * @summary Récupérer les informations d\'un code postal
     * @param {CodePostalApiApiCodesPostauxIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodePostalApi
     */
    public apiCodesPostauxIdGet(requestParameters: CodePostalApiApiCodesPostauxIdGetRequest, options?: AxiosRequestConfig) {
        return CodePostalApiFp(this.configuration).apiCodesPostauxIdGet(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces the Code postal resource.
     * @summary Modifier un code postal
     * @param {CodePostalApiApiCodesPostauxIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodePostalApi
     */
    public apiCodesPostauxIdPut(requestParameters: CodePostalApiApiCodesPostauxIdPutRequest, options?: AxiosRequestConfig) {
        return CodePostalApiFp(this.configuration).apiCodesPostauxIdPut(requestParameters.id, requestParameters.codePostalJsonldCodepostalUpdate, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Ajouter un code postal
     * @summary Ajouter un code postal
     * @param {CodePostalApiApiCodesPostauxPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodePostalApi
     */
    public apiCodesPostauxPost(requestParameters: CodePostalApiApiCodesPostauxPostRequest, options?: AxiosRequestConfig) {
        return CodePostalApiFp(this.configuration).apiCodesPostauxPost(requestParameters.codePostalJsonldCodepostalCreate, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CommuneApi - axios parameter creator
 * @export
 */
export const CommuneApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Obtenir la liste des communes
         * @summary Obtenir la liste des communes
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [libgeo] 
         * @param {boolean} [deleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCommunesGetCollection: async (xIdSociete?: number, page?: number, itemsPerPage?: number, libgeo?: string, deleted?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/geoloc/communes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (libgeo !== undefined) {
                localVarQueryParameter['libgeo'] = libgeo;
            }

            if (deleted !== undefined) {
                localVarQueryParameter['deleted'] = deleted;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Supprimer une commune
         * @summary Supprimer une commune
         * @param {string} insee TblCommune identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCommunesInseeDelete: async (insee: string, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'insee' is not null or undefined
            assertParamExists('apiCommunesInseeDelete', 'insee', insee)
            const localVarPath = `/geoloc/communes/{insee}`
                .replace(`{${"insee"}}`, encodeURIComponent(String(insee)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'une commune
         * @summary Récupérer les informations d\'une commune
         * @param {string} insee TblCommune identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCommunesInseeGet: async (insee: string, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'insee' is not null or undefined
            assertParamExists('apiCommunesInseeGet', 'insee', insee)
            const localVarPath = `/geoloc/communes/{insee}`
                .replace(`{${"insee"}}`, encodeURIComponent(String(insee)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces the Commune resource.
         * @summary Modifier une commune
         * @param {string} insee TblCommune identifier
         * @param {CommuneJsonldCommuneUpdate} communeJsonldCommuneUpdate The updated Commune resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCommunesInseePut: async (insee: string, communeJsonldCommuneUpdate: CommuneJsonldCommuneUpdate, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'insee' is not null or undefined
            assertParamExists('apiCommunesInseePut', 'insee', insee)
            // verify required parameter 'communeJsonldCommuneUpdate' is not null or undefined
            assertParamExists('apiCommunesInseePut', 'communeJsonldCommuneUpdate', communeJsonldCommuneUpdate)
            const localVarPath = `/geoloc/communes/{insee}`
                .replace(`{${"insee"}}`, encodeURIComponent(String(insee)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(communeJsonldCommuneUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Réactiver une commune
         * @summary Réactiver une commune
         * @param {string} insee TblCommune identifier
         * @param {CommuneJsonld} communeJsonld The updated Commune resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCommunesInseeReactiverPut: async (insee: string, communeJsonld: CommuneJsonld, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'insee' is not null or undefined
            assertParamExists('apiCommunesInseeReactiverPut', 'insee', insee)
            // verify required parameter 'communeJsonld' is not null or undefined
            assertParamExists('apiCommunesInseeReactiverPut', 'communeJsonld', communeJsonld)
            const localVarPath = `/geoloc/communes/{insee}/reactiver`
                .replace(`{${"insee"}}`, encodeURIComponent(String(insee)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(communeJsonld, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Ajouter une commune
         * @summary Ajouter une commune
         * @param {CommuneJsonldCommuneCreate} communeJsonldCommuneCreate The new Commune resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCommunesPost: async (communeJsonldCommuneCreate: CommuneJsonldCommuneCreate, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'communeJsonldCommuneCreate' is not null or undefined
            assertParamExists('apiCommunesPost', 'communeJsonldCommuneCreate', communeJsonldCommuneCreate)
            const localVarPath = `/geoloc/communes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(communeJsonldCommuneCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommuneApi - functional programming interface
 * @export
 */
export const CommuneApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CommuneApiAxiosParamCreator(configuration)
    return {
        /**
         * Obtenir la liste des communes
         * @summary Obtenir la liste des communes
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [libgeo] 
         * @param {boolean} [deleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCommunesGetCollection(xIdSociete?: number, page?: number, itemsPerPage?: number, libgeo?: string, deleted?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiCommunesGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCommunesGetCollection(xIdSociete, page, itemsPerPage, libgeo, deleted, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Supprimer une commune
         * @summary Supprimer une commune
         * @param {string} insee TblCommune identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCommunesInseeDelete(insee: string, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCommunesInseeDelete(insee, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Récupérer les informations d\'une commune
         * @summary Récupérer les informations d\'une commune
         * @param {string} insee TblCommune identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCommunesInseeGet(insee: string, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommuneJsonldCommuneRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCommunesInseeGet(insee, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces the Commune resource.
         * @summary Modifier une commune
         * @param {string} insee TblCommune identifier
         * @param {CommuneJsonldCommuneUpdate} communeJsonldCommuneUpdate The updated Commune resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCommunesInseePut(insee: string, communeJsonldCommuneUpdate: CommuneJsonldCommuneUpdate, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommuneJsonldCommuneRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCommunesInseePut(insee, communeJsonldCommuneUpdate, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Réactiver une commune
         * @summary Réactiver une commune
         * @param {string} insee TblCommune identifier
         * @param {CommuneJsonld} communeJsonld The updated Commune resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCommunesInseeReactiverPut(insee: string, communeJsonld: CommuneJsonld, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommuneJsonldCommuneRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCommunesInseeReactiverPut(insee, communeJsonld, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Ajouter une commune
         * @summary Ajouter une commune
         * @param {CommuneJsonldCommuneCreate} communeJsonldCommuneCreate The new Commune resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCommunesPost(communeJsonldCommuneCreate: CommuneJsonldCommuneCreate, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommuneJsonldCommuneRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCommunesPost(communeJsonldCommuneCreate, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CommuneApi - factory interface
 * @export
 */
export const CommuneApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CommuneApiFp(configuration)
    return {
        /**
         * Obtenir la liste des communes
         * @summary Obtenir la liste des communes
         * @param {CommuneApiApiCommunesGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCommunesGetCollection(requestParameters: CommuneApiApiCommunesGetCollectionRequest = {}, options?: AxiosRequestConfig): AxiosPromise<ApiCommunesGetCollection200Response> {
            return localVarFp.apiCommunesGetCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, requestParameters.libgeo, requestParameters.deleted, options).then((request) => request(axios, basePath));
        },
        /**
         * Supprimer une commune
         * @summary Supprimer une commune
         * @param {CommuneApiApiCommunesInseeDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCommunesInseeDelete(requestParameters: CommuneApiApiCommunesInseeDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiCommunesInseeDelete(requestParameters.insee, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'une commune
         * @summary Récupérer les informations d\'une commune
         * @param {CommuneApiApiCommunesInseeGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCommunesInseeGet(requestParameters: CommuneApiApiCommunesInseeGetRequest, options?: AxiosRequestConfig): AxiosPromise<CommuneJsonldCommuneRead> {
            return localVarFp.apiCommunesInseeGet(requestParameters.insee, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces the Commune resource.
         * @summary Modifier une commune
         * @param {CommuneApiApiCommunesInseePutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCommunesInseePut(requestParameters: CommuneApiApiCommunesInseePutRequest, options?: AxiosRequestConfig): AxiosPromise<CommuneJsonldCommuneRead> {
            return localVarFp.apiCommunesInseePut(requestParameters.insee, requestParameters.communeJsonldCommuneUpdate, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Réactiver une commune
         * @summary Réactiver une commune
         * @param {CommuneApiApiCommunesInseeReactiverPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCommunesInseeReactiverPut(requestParameters: CommuneApiApiCommunesInseeReactiverPutRequest, options?: AxiosRequestConfig): AxiosPromise<CommuneJsonldCommuneRead> {
            return localVarFp.apiCommunesInseeReactiverPut(requestParameters.insee, requestParameters.communeJsonld, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Ajouter une commune
         * @summary Ajouter une commune
         * @param {CommuneApiApiCommunesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCommunesPost(requestParameters: CommuneApiApiCommunesPostRequest, options?: AxiosRequestConfig): AxiosPromise<CommuneJsonldCommuneRead> {
            return localVarFp.apiCommunesPost(requestParameters.communeJsonldCommuneCreate, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiCommunesGetCollection operation in CommuneApi.
 * @export
 * @interface CommuneApiApiCommunesGetCollectionRequest
 */
export interface CommuneApiApiCommunesGetCollectionRequest {
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof CommuneApiApiCommunesGetCollection
     */
    readonly xIdSociete?: number

    /**
     * The collection page number
     * @type {number}
     * @memberof CommuneApiApiCommunesGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof CommuneApiApiCommunesGetCollection
     */
    readonly itemsPerPage?: number

    /**
     * 
     * @type {string}
     * @memberof CommuneApiApiCommunesGetCollection
     */
    readonly libgeo?: string

    /**
     * 
     * @type {boolean}
     * @memberof CommuneApiApiCommunesGetCollection
     */
    readonly deleted?: boolean
}

/**
 * Request parameters for apiCommunesInseeDelete operation in CommuneApi.
 * @export
 * @interface CommuneApiApiCommunesInseeDeleteRequest
 */
export interface CommuneApiApiCommunesInseeDeleteRequest {
    /**
     * TblCommune identifier
     * @type {string}
     * @memberof CommuneApiApiCommunesInseeDelete
     */
    readonly insee: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof CommuneApiApiCommunesInseeDelete
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for apiCommunesInseeGet operation in CommuneApi.
 * @export
 * @interface CommuneApiApiCommunesInseeGetRequest
 */
export interface CommuneApiApiCommunesInseeGetRequest {
    /**
     * TblCommune identifier
     * @type {string}
     * @memberof CommuneApiApiCommunesInseeGet
     */
    readonly insee: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof CommuneApiApiCommunesInseeGet
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for apiCommunesInseePut operation in CommuneApi.
 * @export
 * @interface CommuneApiApiCommunesInseePutRequest
 */
export interface CommuneApiApiCommunesInseePutRequest {
    /**
     * TblCommune identifier
     * @type {string}
     * @memberof CommuneApiApiCommunesInseePut
     */
    readonly insee: string

    /**
     * The updated Commune resource
     * @type {CommuneJsonldCommuneUpdate}
     * @memberof CommuneApiApiCommunesInseePut
     */
    readonly communeJsonldCommuneUpdate: CommuneJsonldCommuneUpdate

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof CommuneApiApiCommunesInseePut
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for apiCommunesInseeReactiverPut operation in CommuneApi.
 * @export
 * @interface CommuneApiApiCommunesInseeReactiverPutRequest
 */
export interface CommuneApiApiCommunesInseeReactiverPutRequest {
    /**
     * TblCommune identifier
     * @type {string}
     * @memberof CommuneApiApiCommunesInseeReactiverPut
     */
    readonly insee: string

    /**
     * The updated Commune resource
     * @type {CommuneJsonld}
     * @memberof CommuneApiApiCommunesInseeReactiverPut
     */
    readonly communeJsonld: CommuneJsonld

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof CommuneApiApiCommunesInseeReactiverPut
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for apiCommunesPost operation in CommuneApi.
 * @export
 * @interface CommuneApiApiCommunesPostRequest
 */
export interface CommuneApiApiCommunesPostRequest {
    /**
     * The new Commune resource
     * @type {CommuneJsonldCommuneCreate}
     * @memberof CommuneApiApiCommunesPost
     */
    readonly communeJsonldCommuneCreate: CommuneJsonldCommuneCreate

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof CommuneApiApiCommunesPost
     */
    readonly xIdSociete?: number
}

/**
 * CommuneApi - object-oriented interface
 * @export
 * @class CommuneApi
 * @extends {BaseAPI}
 */
export class CommuneApi extends BaseAPI {
    /**
     * Obtenir la liste des communes
     * @summary Obtenir la liste des communes
     * @param {CommuneApiApiCommunesGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommuneApi
     */
    public apiCommunesGetCollection(requestParameters: CommuneApiApiCommunesGetCollectionRequest = {}, options?: AxiosRequestConfig) {
        return CommuneApiFp(this.configuration).apiCommunesGetCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, requestParameters.libgeo, requestParameters.deleted, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Supprimer une commune
     * @summary Supprimer une commune
     * @param {CommuneApiApiCommunesInseeDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommuneApi
     */
    public apiCommunesInseeDelete(requestParameters: CommuneApiApiCommunesInseeDeleteRequest, options?: AxiosRequestConfig) {
        return CommuneApiFp(this.configuration).apiCommunesInseeDelete(requestParameters.insee, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'une commune
     * @summary Récupérer les informations d\'une commune
     * @param {CommuneApiApiCommunesInseeGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommuneApi
     */
    public apiCommunesInseeGet(requestParameters: CommuneApiApiCommunesInseeGetRequest, options?: AxiosRequestConfig) {
        return CommuneApiFp(this.configuration).apiCommunesInseeGet(requestParameters.insee, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces the Commune resource.
     * @summary Modifier une commune
     * @param {CommuneApiApiCommunesInseePutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommuneApi
     */
    public apiCommunesInseePut(requestParameters: CommuneApiApiCommunesInseePutRequest, options?: AxiosRequestConfig) {
        return CommuneApiFp(this.configuration).apiCommunesInseePut(requestParameters.insee, requestParameters.communeJsonldCommuneUpdate, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Réactiver une commune
     * @summary Réactiver une commune
     * @param {CommuneApiApiCommunesInseeReactiverPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommuneApi
     */
    public apiCommunesInseeReactiverPut(requestParameters: CommuneApiApiCommunesInseeReactiverPutRequest, options?: AxiosRequestConfig) {
        return CommuneApiFp(this.configuration).apiCommunesInseeReactiverPut(requestParameters.insee, requestParameters.communeJsonld, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Ajouter une commune
     * @summary Ajouter une commune
     * @param {CommuneApiApiCommunesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommuneApi
     */
    public apiCommunesPost(requestParameters: CommuneApiApiCommunesPostRequest, options?: AxiosRequestConfig) {
        return CommuneApiFp(this.configuration).apiCommunesPost(requestParameters.communeJsonldCommuneCreate, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DpartementApi - axios parameter creator
 * @export
 */
export const DpartementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Supprimer un département
         * @summary Supprimer un département
         * @param {string} codgeo TblDep identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDepartementsCodgeoDelete: async (codgeo: string, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'codgeo' is not null or undefined
            assertParamExists('apiDepartementsCodgeoDelete', 'codgeo', codgeo)
            const localVarPath = `/geoloc/departements/{codgeo}`
                .replace(`{${"codgeo"}}`, encodeURIComponent(String(codgeo)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'un département
         * @summary Récupérer les informations d\'un département
         * @param {string} codgeo TblDep identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDepartementsCodgeoGet: async (codgeo: string, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'codgeo' is not null or undefined
            assertParamExists('apiDepartementsCodgeoGet', 'codgeo', codgeo)
            const localVarPath = `/geoloc/departements/{codgeo}`
                .replace(`{${"codgeo"}}`, encodeURIComponent(String(codgeo)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces the Département resource.
         * @summary Modifier un département
         * @param {string} codgeo TblDep identifier
         * @param {DPartementJsonldDepartementUpdate} dPartementJsonldDepartementUpdate The updated Département resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDepartementsCodgeoPut: async (codgeo: string, dPartementJsonldDepartementUpdate: DPartementJsonldDepartementUpdate, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'codgeo' is not null or undefined
            assertParamExists('apiDepartementsCodgeoPut', 'codgeo', codgeo)
            // verify required parameter 'dPartementJsonldDepartementUpdate' is not null or undefined
            assertParamExists('apiDepartementsCodgeoPut', 'dPartementJsonldDepartementUpdate', dPartementJsonldDepartementUpdate)
            const localVarPath = `/geoloc/departements/{codgeo}`
                .replace(`{${"codgeo"}}`, encodeURIComponent(String(codgeo)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dPartementJsonldDepartementUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Réactiver un département
         * @summary Réactiver un département
         * @param {string} codgeo TblDep identifier
         * @param {DPartementJsonld} dPartementJsonld The updated Département resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDepartementsCodgeoReactiverPut: async (codgeo: string, dPartementJsonld: DPartementJsonld, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'codgeo' is not null or undefined
            assertParamExists('apiDepartementsCodgeoReactiverPut', 'codgeo', codgeo)
            // verify required parameter 'dPartementJsonld' is not null or undefined
            assertParamExists('apiDepartementsCodgeoReactiverPut', 'dPartementJsonld', dPartementJsonld)
            const localVarPath = `/geoloc/departements/{codgeo}/reactiver`
                .replace(`{${"codgeo"}}`, encodeURIComponent(String(codgeo)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dPartementJsonld, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtenir la liste des départements
         * @summary Obtenir la liste des départements
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [libelle] 
         * @param {boolean} [deleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDepartementsGetCollection: async (xIdSociete?: number, page?: number, itemsPerPage?: number, libelle?: string, deleted?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/geoloc/departements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (libelle !== undefined) {
                localVarQueryParameter['libelle'] = libelle;
            }

            if (deleted !== undefined) {
                localVarQueryParameter['deleted'] = deleted;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Ajouter un département
         * @summary Ajouter un département
         * @param {DPartementJsonldDepartementCreate} dPartementJsonldDepartementCreate The new Département resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDepartementsPost: async (dPartementJsonldDepartementCreate: DPartementJsonldDepartementCreate, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dPartementJsonldDepartementCreate' is not null or undefined
            assertParamExists('apiDepartementsPost', 'dPartementJsonldDepartementCreate', dPartementJsonldDepartementCreate)
            const localVarPath = `/geoloc/departements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dPartementJsonldDepartementCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DpartementApi - functional programming interface
 * @export
 */
export const DpartementApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DpartementApiAxiosParamCreator(configuration)
    return {
        /**
         * Supprimer un département
         * @summary Supprimer un département
         * @param {string} codgeo TblDep identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDepartementsCodgeoDelete(codgeo: string, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDepartementsCodgeoDelete(codgeo, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Récupérer les informations d\'un département
         * @summary Récupérer les informations d\'un département
         * @param {string} codgeo TblDep identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDepartementsCodgeoGet(codgeo: string, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DPartementJsonldDepartementRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDepartementsCodgeoGet(codgeo, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces the Département resource.
         * @summary Modifier un département
         * @param {string} codgeo TblDep identifier
         * @param {DPartementJsonldDepartementUpdate} dPartementJsonldDepartementUpdate The updated Département resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDepartementsCodgeoPut(codgeo: string, dPartementJsonldDepartementUpdate: DPartementJsonldDepartementUpdate, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DPartementJsonldDepartementRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDepartementsCodgeoPut(codgeo, dPartementJsonldDepartementUpdate, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Réactiver un département
         * @summary Réactiver un département
         * @param {string} codgeo TblDep identifier
         * @param {DPartementJsonld} dPartementJsonld The updated Département resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDepartementsCodgeoReactiverPut(codgeo: string, dPartementJsonld: DPartementJsonld, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DPartementJsonldDepartementRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDepartementsCodgeoReactiverPut(codgeo, dPartementJsonld, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtenir la liste des départements
         * @summary Obtenir la liste des départements
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [libelle] 
         * @param {boolean} [deleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDepartementsGetCollection(xIdSociete?: number, page?: number, itemsPerPage?: number, libelle?: string, deleted?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiDepartementsGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDepartementsGetCollection(xIdSociete, page, itemsPerPage, libelle, deleted, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Ajouter un département
         * @summary Ajouter un département
         * @param {DPartementJsonldDepartementCreate} dPartementJsonldDepartementCreate The new Département resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDepartementsPost(dPartementJsonldDepartementCreate: DPartementJsonldDepartementCreate, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DPartementJsonldDepartementCreated>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDepartementsPost(dPartementJsonldDepartementCreate, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DpartementApi - factory interface
 * @export
 */
export const DpartementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DpartementApiFp(configuration)
    return {
        /**
         * Supprimer un département
         * @summary Supprimer un département
         * @param {DpartementApiApiDepartementsCodgeoDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDepartementsCodgeoDelete(requestParameters: DpartementApiApiDepartementsCodgeoDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiDepartementsCodgeoDelete(requestParameters.codgeo, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'un département
         * @summary Récupérer les informations d\'un département
         * @param {DpartementApiApiDepartementsCodgeoGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDepartementsCodgeoGet(requestParameters: DpartementApiApiDepartementsCodgeoGetRequest, options?: AxiosRequestConfig): AxiosPromise<DPartementJsonldDepartementRead> {
            return localVarFp.apiDepartementsCodgeoGet(requestParameters.codgeo, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces the Département resource.
         * @summary Modifier un département
         * @param {DpartementApiApiDepartementsCodgeoPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDepartementsCodgeoPut(requestParameters: DpartementApiApiDepartementsCodgeoPutRequest, options?: AxiosRequestConfig): AxiosPromise<DPartementJsonldDepartementRead> {
            return localVarFp.apiDepartementsCodgeoPut(requestParameters.codgeo, requestParameters.dPartementJsonldDepartementUpdate, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Réactiver un département
         * @summary Réactiver un département
         * @param {DpartementApiApiDepartementsCodgeoReactiverPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDepartementsCodgeoReactiverPut(requestParameters: DpartementApiApiDepartementsCodgeoReactiverPutRequest, options?: AxiosRequestConfig): AxiosPromise<DPartementJsonldDepartementRead> {
            return localVarFp.apiDepartementsCodgeoReactiverPut(requestParameters.codgeo, requestParameters.dPartementJsonld, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtenir la liste des départements
         * @summary Obtenir la liste des départements
         * @param {DpartementApiApiDepartementsGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDepartementsGetCollection(requestParameters: DpartementApiApiDepartementsGetCollectionRequest = {}, options?: AxiosRequestConfig): AxiosPromise<ApiDepartementsGetCollection200Response> {
            return localVarFp.apiDepartementsGetCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, requestParameters.libelle, requestParameters.deleted, options).then((request) => request(axios, basePath));
        },
        /**
         * Ajouter un département
         * @summary Ajouter un département
         * @param {DpartementApiApiDepartementsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDepartementsPost(requestParameters: DpartementApiApiDepartementsPostRequest, options?: AxiosRequestConfig): AxiosPromise<DPartementJsonldDepartementCreated> {
            return localVarFp.apiDepartementsPost(requestParameters.dPartementJsonldDepartementCreate, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiDepartementsCodgeoDelete operation in DpartementApi.
 * @export
 * @interface DpartementApiApiDepartementsCodgeoDeleteRequest
 */
export interface DpartementApiApiDepartementsCodgeoDeleteRequest {
    /**
     * TblDep identifier
     * @type {string}
     * @memberof DpartementApiApiDepartementsCodgeoDelete
     */
    readonly codgeo: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof DpartementApiApiDepartementsCodgeoDelete
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for apiDepartementsCodgeoGet operation in DpartementApi.
 * @export
 * @interface DpartementApiApiDepartementsCodgeoGetRequest
 */
export interface DpartementApiApiDepartementsCodgeoGetRequest {
    /**
     * TblDep identifier
     * @type {string}
     * @memberof DpartementApiApiDepartementsCodgeoGet
     */
    readonly codgeo: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof DpartementApiApiDepartementsCodgeoGet
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for apiDepartementsCodgeoPut operation in DpartementApi.
 * @export
 * @interface DpartementApiApiDepartementsCodgeoPutRequest
 */
export interface DpartementApiApiDepartementsCodgeoPutRequest {
    /**
     * TblDep identifier
     * @type {string}
     * @memberof DpartementApiApiDepartementsCodgeoPut
     */
    readonly codgeo: string

    /**
     * The updated Département resource
     * @type {DPartementJsonldDepartementUpdate}
     * @memberof DpartementApiApiDepartementsCodgeoPut
     */
    readonly dPartementJsonldDepartementUpdate: DPartementJsonldDepartementUpdate

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof DpartementApiApiDepartementsCodgeoPut
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for apiDepartementsCodgeoReactiverPut operation in DpartementApi.
 * @export
 * @interface DpartementApiApiDepartementsCodgeoReactiverPutRequest
 */
export interface DpartementApiApiDepartementsCodgeoReactiverPutRequest {
    /**
     * TblDep identifier
     * @type {string}
     * @memberof DpartementApiApiDepartementsCodgeoReactiverPut
     */
    readonly codgeo: string

    /**
     * The updated Département resource
     * @type {DPartementJsonld}
     * @memberof DpartementApiApiDepartementsCodgeoReactiverPut
     */
    readonly dPartementJsonld: DPartementJsonld

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof DpartementApiApiDepartementsCodgeoReactiverPut
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for apiDepartementsGetCollection operation in DpartementApi.
 * @export
 * @interface DpartementApiApiDepartementsGetCollectionRequest
 */
export interface DpartementApiApiDepartementsGetCollectionRequest {
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof DpartementApiApiDepartementsGetCollection
     */
    readonly xIdSociete?: number

    /**
     * The collection page number
     * @type {number}
     * @memberof DpartementApiApiDepartementsGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof DpartementApiApiDepartementsGetCollection
     */
    readonly itemsPerPage?: number

    /**
     * 
     * @type {string}
     * @memberof DpartementApiApiDepartementsGetCollection
     */
    readonly libelle?: string

    /**
     * 
     * @type {boolean}
     * @memberof DpartementApiApiDepartementsGetCollection
     */
    readonly deleted?: boolean
}

/**
 * Request parameters for apiDepartementsPost operation in DpartementApi.
 * @export
 * @interface DpartementApiApiDepartementsPostRequest
 */
export interface DpartementApiApiDepartementsPostRequest {
    /**
     * The new Département resource
     * @type {DPartementJsonldDepartementCreate}
     * @memberof DpartementApiApiDepartementsPost
     */
    readonly dPartementJsonldDepartementCreate: DPartementJsonldDepartementCreate

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof DpartementApiApiDepartementsPost
     */
    readonly xIdSociete?: number
}

/**
 * DpartementApi - object-oriented interface
 * @export
 * @class DpartementApi
 * @extends {BaseAPI}
 */
export class DpartementApi extends BaseAPI {
    /**
     * Supprimer un département
     * @summary Supprimer un département
     * @param {DpartementApiApiDepartementsCodgeoDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DpartementApi
     */
    public apiDepartementsCodgeoDelete(requestParameters: DpartementApiApiDepartementsCodgeoDeleteRequest, options?: AxiosRequestConfig) {
        return DpartementApiFp(this.configuration).apiDepartementsCodgeoDelete(requestParameters.codgeo, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'un département
     * @summary Récupérer les informations d\'un département
     * @param {DpartementApiApiDepartementsCodgeoGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DpartementApi
     */
    public apiDepartementsCodgeoGet(requestParameters: DpartementApiApiDepartementsCodgeoGetRequest, options?: AxiosRequestConfig) {
        return DpartementApiFp(this.configuration).apiDepartementsCodgeoGet(requestParameters.codgeo, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces the Département resource.
     * @summary Modifier un département
     * @param {DpartementApiApiDepartementsCodgeoPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DpartementApi
     */
    public apiDepartementsCodgeoPut(requestParameters: DpartementApiApiDepartementsCodgeoPutRequest, options?: AxiosRequestConfig) {
        return DpartementApiFp(this.configuration).apiDepartementsCodgeoPut(requestParameters.codgeo, requestParameters.dPartementJsonldDepartementUpdate, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Réactiver un département
     * @summary Réactiver un département
     * @param {DpartementApiApiDepartementsCodgeoReactiverPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DpartementApi
     */
    public apiDepartementsCodgeoReactiverPut(requestParameters: DpartementApiApiDepartementsCodgeoReactiverPutRequest, options?: AxiosRequestConfig) {
        return DpartementApiFp(this.configuration).apiDepartementsCodgeoReactiverPut(requestParameters.codgeo, requestParameters.dPartementJsonld, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtenir la liste des départements
     * @summary Obtenir la liste des départements
     * @param {DpartementApiApiDepartementsGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DpartementApi
     */
    public apiDepartementsGetCollection(requestParameters: DpartementApiApiDepartementsGetCollectionRequest = {}, options?: AxiosRequestConfig) {
        return DpartementApiFp(this.configuration).apiDepartementsGetCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, requestParameters.libelle, requestParameters.deleted, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Ajouter un département
     * @summary Ajouter un département
     * @param {DpartementApiApiDepartementsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DpartementApi
     */
    public apiDepartementsPost(requestParameters: DpartementApiApiDepartementsPostRequest, options?: AxiosRequestConfig) {
        return DpartementApiFp(this.configuration).apiDepartementsPost(requestParameters.dPartementJsonldDepartementCreate, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GreffeApi - axios parameter creator
 * @export
 */
export const GreffeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Obtenir la liste des greffes
         * @summary Obtenir la liste des greffes
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [greffes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGreffesGetCollection: async (xIdSociete?: number, page?: number, itemsPerPage?: number, greffes?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/geoloc/greffes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (greffes !== undefined) {
                localVarQueryParameter['greffes'] = greffes;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'un greffe
         * @summary Récupérer les informations d\'un greffe
         * @param {string} id TblGreffes identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGreffesIdGet: async (id: string, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiGreffesIdGet', 'id', id)
            const localVarPath = `/geoloc/greffes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GreffeApi - functional programming interface
 * @export
 */
export const GreffeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GreffeApiAxiosParamCreator(configuration)
    return {
        /**
         * Obtenir la liste des greffes
         * @summary Obtenir la liste des greffes
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [greffes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGreffesGetCollection(xIdSociete?: number, page?: number, itemsPerPage?: number, greffes?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGreffesGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGreffesGetCollection(xIdSociete, page, itemsPerPage, greffes, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Récupérer les informations d\'un greffe
         * @summary Récupérer les informations d\'un greffe
         * @param {string} id TblGreffes identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGreffesIdGet(id: string, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GreffeJsonldGreffeRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGreffesIdGet(id, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GreffeApi - factory interface
 * @export
 */
export const GreffeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GreffeApiFp(configuration)
    return {
        /**
         * Obtenir la liste des greffes
         * @summary Obtenir la liste des greffes
         * @param {GreffeApiApiGreffesGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGreffesGetCollection(requestParameters: GreffeApiApiGreffesGetCollectionRequest = {}, options?: AxiosRequestConfig): AxiosPromise<ApiGreffesGetCollection200Response> {
            return localVarFp.apiGreffesGetCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, requestParameters.greffes, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'un greffe
         * @summary Récupérer les informations d\'un greffe
         * @param {GreffeApiApiGreffesIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGreffesIdGet(requestParameters: GreffeApiApiGreffesIdGetRequest, options?: AxiosRequestConfig): AxiosPromise<GreffeJsonldGreffeRead> {
            return localVarFp.apiGreffesIdGet(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiGreffesGetCollection operation in GreffeApi.
 * @export
 * @interface GreffeApiApiGreffesGetCollectionRequest
 */
export interface GreffeApiApiGreffesGetCollectionRequest {
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof GreffeApiApiGreffesGetCollection
     */
    readonly xIdSociete?: number

    /**
     * The collection page number
     * @type {number}
     * @memberof GreffeApiApiGreffesGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof GreffeApiApiGreffesGetCollection
     */
    readonly itemsPerPage?: number

    /**
     * 
     * @type {string}
     * @memberof GreffeApiApiGreffesGetCollection
     */
    readonly greffes?: string
}

/**
 * Request parameters for apiGreffesIdGet operation in GreffeApi.
 * @export
 * @interface GreffeApiApiGreffesIdGetRequest
 */
export interface GreffeApiApiGreffesIdGetRequest {
    /**
     * TblGreffes identifier
     * @type {string}
     * @memberof GreffeApiApiGreffesIdGet
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof GreffeApiApiGreffesIdGet
     */
    readonly xIdSociete?: number
}

/**
 * GreffeApi - object-oriented interface
 * @export
 * @class GreffeApi
 * @extends {BaseAPI}
 */
export class GreffeApi extends BaseAPI {
    /**
     * Obtenir la liste des greffes
     * @summary Obtenir la liste des greffes
     * @param {GreffeApiApiGreffesGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GreffeApi
     */
    public apiGreffesGetCollection(requestParameters: GreffeApiApiGreffesGetCollectionRequest = {}, options?: AxiosRequestConfig) {
        return GreffeApiFp(this.configuration).apiGreffesGetCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, requestParameters.greffes, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'un greffe
     * @summary Récupérer les informations d\'un greffe
     * @param {GreffeApiApiGreffesIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GreffeApi
     */
    public apiGreffesIdGet(requestParameters: GreffeApiApiGreffesIdGetRequest, options?: AxiosRequestConfig) {
        return GreffeApiFp(this.configuration).apiGreffesIdGet(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PaysApi - axios parameter creator
 * @export
 */
export const PaysApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Récupérer les informations d\'un pays
         * @summary Récupérer les informations d\'un pays
         * @param {string} codeIso2 TblPays identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaysCodeIso2Get: async (codeIso2: string, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'codeIso2' is not null or undefined
            assertParamExists('apiPaysCodeIso2Get', 'codeIso2', codeIso2)
            const localVarPath = `/geoloc/pays/{codeIso2}`
                .replace(`{${"codeIso2"}}`, encodeURIComponent(String(codeIso2)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtenir la liste des pays
         * @summary Obtenir la liste des pays
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [libelle] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaysGetCollection: async (xIdSociete?: number, page?: number, itemsPerPage?: number, libelle?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/geoloc/pays`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (libelle !== undefined) {
                localVarQueryParameter['libelle'] = libelle;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaysApi - functional programming interface
 * @export
 */
export const PaysApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PaysApiAxiosParamCreator(configuration)
    return {
        /**
         * Récupérer les informations d\'un pays
         * @summary Récupérer les informations d\'un pays
         * @param {string} codeIso2 TblPays identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPaysCodeIso2Get(codeIso2: string, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaysJsonldPaysRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPaysCodeIso2Get(codeIso2, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtenir la liste des pays
         * @summary Obtenir la liste des pays
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [libelle] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPaysGetCollection(xIdSociete?: number, page?: number, itemsPerPage?: number, libelle?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiPaysGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPaysGetCollection(xIdSociete, page, itemsPerPage, libelle, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PaysApi - factory interface
 * @export
 */
export const PaysApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaysApiFp(configuration)
    return {
        /**
         * Récupérer les informations d\'un pays
         * @summary Récupérer les informations d\'un pays
         * @param {PaysApiApiPaysCodeIso2GetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaysCodeIso2Get(requestParameters: PaysApiApiPaysCodeIso2GetRequest, options?: AxiosRequestConfig): AxiosPromise<PaysJsonldPaysRead> {
            return localVarFp.apiPaysCodeIso2Get(requestParameters.codeIso2, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtenir la liste des pays
         * @summary Obtenir la liste des pays
         * @param {PaysApiApiPaysGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaysGetCollection(requestParameters: PaysApiApiPaysGetCollectionRequest = {}, options?: AxiosRequestConfig): AxiosPromise<ApiPaysGetCollection200Response> {
            return localVarFp.apiPaysGetCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, requestParameters.libelle, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiPaysCodeIso2Get operation in PaysApi.
 * @export
 * @interface PaysApiApiPaysCodeIso2GetRequest
 */
export interface PaysApiApiPaysCodeIso2GetRequest {
    /**
     * TblPays identifier
     * @type {string}
     * @memberof PaysApiApiPaysCodeIso2Get
     */
    readonly codeIso2: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof PaysApiApiPaysCodeIso2Get
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for apiPaysGetCollection operation in PaysApi.
 * @export
 * @interface PaysApiApiPaysGetCollectionRequest
 */
export interface PaysApiApiPaysGetCollectionRequest {
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof PaysApiApiPaysGetCollection
     */
    readonly xIdSociete?: number

    /**
     * The collection page number
     * @type {number}
     * @memberof PaysApiApiPaysGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof PaysApiApiPaysGetCollection
     */
    readonly itemsPerPage?: number

    /**
     * 
     * @type {string}
     * @memberof PaysApiApiPaysGetCollection
     */
    readonly libelle?: string
}

/**
 * PaysApi - object-oriented interface
 * @export
 * @class PaysApi
 * @extends {BaseAPI}
 */
export class PaysApi extends BaseAPI {
    /**
     * Récupérer les informations d\'un pays
     * @summary Récupérer les informations d\'un pays
     * @param {PaysApiApiPaysCodeIso2GetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaysApi
     */
    public apiPaysCodeIso2Get(requestParameters: PaysApiApiPaysCodeIso2GetRequest, options?: AxiosRequestConfig) {
        return PaysApiFp(this.configuration).apiPaysCodeIso2Get(requestParameters.codeIso2, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtenir la liste des pays
     * @summary Obtenir la liste des pays
     * @param {PaysApiApiPaysGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaysApi
     */
    public apiPaysGetCollection(requestParameters: PaysApiApiPaysGetCollectionRequest = {}, options?: AxiosRequestConfig) {
        return PaysApiFp(this.configuration).apiPaysGetCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, requestParameters.libelle, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RegionApi - axios parameter creator
 * @export
 */
export const RegionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Obtenir la liste des regions
         * @summary Obtenir la liste des regions
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRegionsGetCollection: async (xIdSociete?: number, page?: number, itemsPerPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/geoloc/regions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'une region
         * @summary Récupérer les informations d\'une region
         * @param {string} idregion TblRegion identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRegionsIdregionGet: async (idregion: string, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idregion' is not null or undefined
            assertParamExists('apiRegionsIdregionGet', 'idregion', idregion)
            const localVarPath = `/geoloc/regions/{idregion}`
                .replace(`{${"idregion"}}`, encodeURIComponent(String(idregion)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RegionApi - functional programming interface
 * @export
 */
export const RegionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RegionApiAxiosParamCreator(configuration)
    return {
        /**
         * Obtenir la liste des regions
         * @summary Obtenir la liste des regions
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRegionsGetCollection(xIdSociete?: number, page?: number, itemsPerPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiRegionsGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRegionsGetCollection(xIdSociete, page, itemsPerPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Récupérer les informations d\'une region
         * @summary Récupérer les informations d\'une region
         * @param {string} idregion TblRegion identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRegionsIdregionGet(idregion: string, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegionJsonldRegionRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRegionsIdregionGet(idregion, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RegionApi - factory interface
 * @export
 */
export const RegionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RegionApiFp(configuration)
    return {
        /**
         * Obtenir la liste des regions
         * @summary Obtenir la liste des regions
         * @param {RegionApiApiRegionsGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRegionsGetCollection(requestParameters: RegionApiApiRegionsGetCollectionRequest = {}, options?: AxiosRequestConfig): AxiosPromise<ApiRegionsGetCollection200Response> {
            return localVarFp.apiRegionsGetCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'une region
         * @summary Récupérer les informations d\'une region
         * @param {RegionApiApiRegionsIdregionGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRegionsIdregionGet(requestParameters: RegionApiApiRegionsIdregionGetRequest, options?: AxiosRequestConfig): AxiosPromise<RegionJsonldRegionRead> {
            return localVarFp.apiRegionsIdregionGet(requestParameters.idregion, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiRegionsGetCollection operation in RegionApi.
 * @export
 * @interface RegionApiApiRegionsGetCollectionRequest
 */
export interface RegionApiApiRegionsGetCollectionRequest {
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof RegionApiApiRegionsGetCollection
     */
    readonly xIdSociete?: number

    /**
     * The collection page number
     * @type {number}
     * @memberof RegionApiApiRegionsGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof RegionApiApiRegionsGetCollection
     */
    readonly itemsPerPage?: number
}

/**
 * Request parameters for apiRegionsIdregionGet operation in RegionApi.
 * @export
 * @interface RegionApiApiRegionsIdregionGetRequest
 */
export interface RegionApiApiRegionsIdregionGetRequest {
    /**
     * TblRegion identifier
     * @type {string}
     * @memberof RegionApiApiRegionsIdregionGet
     */
    readonly idregion: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof RegionApiApiRegionsIdregionGet
     */
    readonly xIdSociete?: number
}

/**
 * RegionApi - object-oriented interface
 * @export
 * @class RegionApi
 * @extends {BaseAPI}
 */
export class RegionApi extends BaseAPI {
    /**
     * Obtenir la liste des regions
     * @summary Obtenir la liste des regions
     * @param {RegionApiApiRegionsGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegionApi
     */
    public apiRegionsGetCollection(requestParameters: RegionApiApiRegionsGetCollectionRequest = {}, options?: AxiosRequestConfig) {
        return RegionApiFp(this.configuration).apiRegionsGetCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'une region
     * @summary Récupérer les informations d\'une region
     * @param {RegionApiApiRegionsIdregionGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegionApi
     */
    public apiRegionsIdregionGet(requestParameters: RegionApiApiRegionsIdregionGetRequest, options?: AxiosRequestConfig) {
        return RegionApiFp(this.configuration).apiRegionsIdregionGet(requestParameters.idregion, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }
}


