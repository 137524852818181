import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { customizerSelector, selectDossier, selectFactureAchat } from '@b2d/redux/RootStore'; // Adjust the import as per your project structure
import { AppDispatch } from '@europrocurement/flexy-components/redux/storeConfig/store';
import { FactureFormObject } from '@b2d/pages/Achats/components/forms/types';
import models from '@b2d/pages/Achats/models';
import {
    Dossier,
    DossierFormalite,
    DOSSIERS_FORMALITES_SLICE_NAME,
    DOSSIERS_SLICE_NAME,
} from '@europrocurement/l2d-domain';
import { FactureAchatLigneJsonldFactureAchatRead } from '@europrocurement/l2d-domain/openApi/ApiAchats';
import { selectDossierFormalite } from '@b2d/redux/subReducers/FormalityReducer';
import { useNavigate } from 'react-router';
import { useSynchroG3Facture } from '@b2d/pages/Achats/components/forms/functions/dataHooks';

export type UpdateFolioPublicationProps = {
    invoice: { id?: number | string };
    lines: FactureAchatLigneJsonldFactureAchatRead[];
    newFolio: Pick<FactureFormObject, 'numero_annonce' | 'numero_dossier'>;
    onFetchNewLines: () => void;
    isMulti?: boolean;
};

export type UpdateFolioFormalityProps = {
    invoice: { id?: number | string };
    lines: FactureAchatLigneJsonldFactureAchatRead[];
    newFolio: Pick<FactureFormObject, 'numero_dossier_formalite'>;
    onFetchNewLines: () => void;
    isMulti?: boolean;
};

/**
 * Custom hook to calculate and update invoice folio.
 */
const useSectionFolio = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { enqueueSnackbar } = useSnackbar();
    const { xIdSociete } = useSelector(customizerSelector);
    const navigate = useNavigate();
    const synchroG3Facture = useSynchroG3Facture();

    /**
     * Updates the folio on the server.
     * @param newFolio - The folio to update.
     */
    const updateFolioPublication = useCallback(
        async ({
            invoice,
            lines,
            newFolio,
            onFetchNewLines,
            isMulti = false,
        }: UpdateFolioPublicationProps) => {
            let newDossier: Dossier;
            if (newFolio.numero_dossier) {
                newDossier = newFolio.numero_dossier as unknown as Dossier;
            } else if (newFolio.numero_annonce) {
                newDossier = newFolio.numero_annonce as unknown as Dossier;
            } else {
                throw new Error("Aucun dossier ou d'annonce");
            }
            if (!newDossier.annonceur) {
                throw new Error("Le dossier n'a pas d'annonceur");
            }
            if (!newDossier.numero) {
                throw new Error("Le dossier n'a pas de numero");
            }
            if (!newDossier.prestations || !newDossier.prestations[0]) {
                throw new Error('Aucune Prestation dans le dossier');
            }
            const prestation = newDossier.prestations[0];

            const invoiceRequest = () => {
                if (!prestation.jal || !prestation.jal.id) {
                    throw new Error("La prestation n'est pas liée a un journal");
                }

                return models.invoicePurchase.patch({
                    id: invoice.id?.toString() || '-1',
                    factureAchatFactureAchatWrite: {
                        libelleAnnonceur: newDossier.annonceur,
                        idJal: prestation.jal.id,
                    },
                    xIdSociete,
                });
            };

            const lineRequestList = [
                ...lines.map(
                    (line) => () =>
                        models.invoicePurchase.patchLine({
                            id: line.id?.toString() || '-1',
                            factureAchatLigneJsonldFactureAchatLigneWrite: {
                                ...line,
                                idDossier: newDossier.id,
                                idPrestation: prestation.id,
                                libelleAnnonceur: newDossier.annonceur,
                                numeroDossier: newDossier.numero?.toString(),
                                numeroPrestation: prestation.numero,
                            },
                            xIdSociete,
                        }),
                ),
            ];

            const syncRequest = () => synchroG3Facture(invoice.id?.toString() || '-1');

            const requestPromiseList: Array<typeof invoiceRequest | (typeof lineRequestList)[0]> =
                [];

            requestPromiseList.push(invoiceRequest);
            requestPromiseList.push(...lineRequestList);

            try {
                await Promise.all(
                    requestPromiseList.map(async (req) => {
                        await req();
                    }),
                );

                syncRequest();

                if (invoice.id) {
                    dispatch(selectFactureAchat({ id: invoice.id }));
                }

                onFetchNewLines();

                if (newDossier.id) {
                    dispatch({
                        type: `${DOSSIERS_SLICE_NAME}/deletemainSelected`,
                    });
                    dispatch(selectDossier({ id: newDossier.id })).then(() => {
                        if (isMulti) {
                            navigate(`../${newDossier.numero}`, {
                                relative: 'path',
                            });
                        }
                    });
                }

                enqueueSnackbar(`Le dossier de la facture à été mis à jour avec succès`);
            } catch (error) {
                console.error('Error updating folio fields:', error);

                enqueueSnackbar(
                    `Une erreur est survenue lors de la mise à jour du dossier de la facture`,
                    {
                        variant: 'error',
                    },
                );
            }
        },
        [dispatch, enqueueSnackbar, navigate, synchroG3Facture, xIdSociete],
    );

    /**
     * Updates the folio on the server.
     * @param newFolio - The folio to update.
     */
    const updateFolioFormality = useCallback(
        async ({
            invoice,
            lines,
            newFolio,
            onFetchNewLines,
            isMulti = false,
        }: UpdateFolioFormalityProps) => {
            let newFolioFormality: DossierFormalite;
            if (newFolio.numero_dossier_formalite) {
                newFolioFormality =
                    newFolio.numero_dossier_formalite as unknown as DossierFormalite;
            } else {
                throw new Error('Aucun dossier de formalité');
            }
            if (!newFolioFormality.societeDenomination) {
                throw new Error("La société du dossier n'a pas de dénomination");
            }

            const invoiceRequest = () =>
                models.invoicePurchase.patch({
                    id: invoice.id?.toString() || '-1',
                    factureAchatFactureAchatWrite: {
                        libelleAnnonceur: newFolioFormality.societeDenomination,
                    },
                    xIdSociete,
                });

            const lineRequestList = [
                ...lines.map(
                    (line) => () =>
                        models.invoicePurchase.patchLine({
                            id: line.id?.toString() || '-1',
                            factureAchatLigneJsonldFactureAchatLigneWrite: {
                                ...line,
                                idDossierFpro: newFolioFormality.id,
                                numeroDossier: newFolioFormality?.numero?.toString(),
                                idPrestation: null,
                                libelleAnnonceur: newFolioFormality.societeDenomination,
                            },
                            xIdSociete,
                        }),
                ),
            ];

            const syncRequest = () => synchroG3Facture(invoice.id?.toString() || '-1');

            const requestPromiseList: Array<typeof invoiceRequest | (typeof lineRequestList)[0]> =
                [];

            requestPromiseList.push(invoiceRequest);
            requestPromiseList.push(...lineRequestList);

            try {
                await Promise.all(requestPromiseList.map((req) => req()));

                syncRequest();

                if (invoice.id) {
                    dispatch(selectFactureAchat({ id: invoice.id }));
                }

                onFetchNewLines();

                if (newFolioFormality.id) {
                    dispatch({
                        type: `${DOSSIERS_FORMALITES_SLICE_NAME}/deletemainSelected`,
                    });
                    dispatch(selectDossierFormalite({ id: newFolioFormality.id })).then(() => {
                        if (isMulti) {
                            navigate(`../${newFolioFormality.numero}`, {
                                relative: 'path',
                            });
                        }
                    });
                }

                enqueueSnackbar(`Le dossier de la facture à été mis à jour avec succès`);
            } catch (error) {
                console.error('Error updating folio fields:', error);

                enqueueSnackbar(
                    `Une erreur est survenue lors de la mise à jour du dossier de la facture`,
                    {
                        variant: 'error',
                    },
                );
            }
        },
        [dispatch, enqueueSnackbar, navigate, synchroG3Facture, xIdSociete],
    );

    return {
        updateFolioPublication,
        updateFolioFormality,
    };
};

export default useSectionFolio;
