import React, { ReactNode, useMemo } from 'react';

import { FactureAchat, Fournisseur } from '@europrocurement/l2d-domain';
import { Box, Typography } from '@mui/material';

import useLoadingStatus from '@b2d/hooks/useLoading';
import NoteModalFormFournisseur from '../../informationWidget/NoteModalFormFournisseur';
import InvoiceDataCardMulti from '../../fragments/InvoiceDataCard/InvoiceDataCardMulti';
import SkeletonLoader from '../../SkeletonLoader';

export type EnteteFactureType = {
    invoice?: FactureAchat;
    publisher?: Fournisseur;
    headerControllers?: ReactNode;
};

export const EnteteFacture = function ({
    invoice,
    publisher,
    headerControllers,
}: EnteteFactureType) {
    const details: ReactNode[] = [];

    const baseStyle = {
        width: 'fit-content',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 1,
    };

    if (publisher) {
        if (invoice?.dateFacture) {
            details.push(
                <Box
                    key="details-facture-date"
                    typography="subtitle2"
                    sx={baseStyle}
                >
                    <Typography fontWeight={600}>Date de facturation :</Typography>
                    <Typography sx={baseStyle}>{invoice.dateFacture.split('T')[0]}</Typography>
                </Box>,
            );
        }
        if (invoice?.idReleveAchat) {
            details.push(
                <Box
                    key="details-facture-statement"
                    typography="subtitle2"
                    sx={baseStyle}
                >
                    <Typography fontWeight={600}>Relevé :</Typography>
                    <Typography sx={baseStyle}>{invoice.idReleveAchat}</Typography>
                </Box>,
            );
        }
        if (publisher.libelle) {
            details.push(
                <Box
                    key="details-libelle"
                    typography="subtitle2"
                    sx={baseStyle}
                >
                    <Typography fontWeight={600}>Fournisseur :</Typography>
                    <Typography sx={baseStyle}>
                        {publisher.libelle}
                        {publisher.notes ? (
                            <NoteModalFormFournisseur noteFournisseur={publisher.notes} />
                        ) : null}
                    </Typography>
                </Box>,
            );
        }
        if (publisher.typeRemise) {
            details.push(
                <Box
                    key="details-remise"
                    typography="subtitle2"
                    sx={baseStyle}
                >
                    <Typography fontWeight={600}>Type remise :</Typography>{' '}
                    <Typography sx={baseStyle}>{publisher.typeRemise.code}</Typography>
                </Box>,
            );
        }
    }

    if (invoice?.createdAt) {
        details.push(
            <Box
                key="details-saisie-date"
                typography="subtitle2"
                sx={baseStyle}
            >
                <Typography fontWeight={600}>Facture saisie le :</Typography>
                <Typography sx={baseStyle}>{`${new Date(
                    invoice.createdAt,
                ).toLocaleDateString()}`}</Typography>
            </Box>,
        );
    }

    if (invoice?.createdBy) {
        details.push(
            <Box
                key="details-saisie-by"
                typography="subtitle2"
                sx={baseStyle}
            >
                <Typography fontWeight={600}>Facture saisie par :</Typography>
                <Typography sx={baseStyle}>{`${invoice.createdBy}`}</Typography>
            </Box>,
        );
    }

    const readyToDisplayDataCard = useMemo<boolean>(() => !!invoice, [invoice]);

    const { loading } = useLoadingStatus({
        checkReady: () => readyToDisplayDataCard,
    });

    return (
        <SkeletonLoader
            isLoading={loading}
            type="DataList"
            typeProps={{
                header: true,
                controllers: true,
                numberOfLines: !publisher ? 2 : 4,
            }}
        >
            <InvoiceDataCardMulti
                invoice={invoice}
                headerControllers={headerControllers}
                details={details}
            />
        </SkeletonLoader>
    );
};
export default EnteteFacture;
