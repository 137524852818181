/* eslint-disable @typescript-eslint/no-unused-vars */

import {
    SliceFactory,
    FetchCollectionData,
    FetchItemDataType,
    ApiCollectionResponse,
} from '@europrocurement/l2d-redux-utils';
import {
    ConfigurationParameters as FactureFormaliteConfigurationParameters,
    Configuration as FactureFormaliteConfiguration,
    FactureApi,
    FactureJsonldFactureRead,
} from '../../../openApi/ApiFacturation';
import { FACTURATION_REDUCER_NAME } from '../constants';

export type FactureFormaliteApiObject = FactureJsonldFactureRead & Record<string, unknown>;

export type FactureFormalite = FactureFormaliteApiObject;

export const FACTURE_FORMALITE_SLICE_NAME = 'factureformalite';

const mapperFactureFormalite = function (factureformalite: FactureFormaliteApiObject) {
    return {
        ...factureformalite,
    } as FactureFormalite;
};

export function createFactureFormaliteSlice(
    configuration: FactureFormaliteConfigurationParameters,
) {
    const conf = new FactureFormaliteConfiguration(configuration);
    const api = new FactureApi(conf);

    const fetchFactureFormalitesCollection: FetchCollectionData<FactureFormalite> = ({
        search,
        pagination,
        filters,
        orders,
        xIdSociete,
        rootstate,
    }) =>
        api
            .apiDossiersDossierFacturesGetCollection({
                page: pagination.page + 1,
                dossier: filters.dossier as string,
                xIdSociete,
            })
            .then((res) => res.data) as Promise<ApiCollectionResponse<FactureFormaliteApiObject>>;

    const fetchFactureFormalitesItem: FetchItemDataType<FactureFormaliteApiObject> = ({
        idItem,
        search,
        pagination,
        filters,
        orders,
        xIdSociete,
        rootstate,
    }) =>
        api
            .apiFacturesIdGet({ id: String(idItem), xIdSociete })
            .then((res) => res.data) as Promise<FactureFormaliteApiObject>;

    const { slice: factureFormaliteSlice, dataSourcesThunks: factureFormaliteDataSourcesThunks } =
        SliceFactory.createSlice<FactureFormaliteApiObject, FactureFormalite>(
            FACTURE_FORMALITE_SLICE_NAME,
            FACTURATION_REDUCER_NAME,
            [],
            fetchFactureFormalitesCollection,
            fetchFactureFormalitesItem,
            mapperFactureFormalite,
        );

    return { factureFormaliteSlice, factureFormaliteDataSourcesThunks };
}
