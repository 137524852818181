import { usePrescriberUtils } from '@b2d/hooks/prescriberHooks';
import { prescribersApi } from '@b2d/redux/RootStore';
import { Notes } from '@europrocurement/flexy-components';
import { Grid } from '@mui/material';
import * as React from 'react';

const NoteRow: React.FunctionComponent = function () {
    const {
        prescriber,
        methods: { reloadPrescriber },
    } = usePrescriberUtils();

    const { note } = prescriber;
    const prescriberId = prescriber.id;

    const onEdit = async (noteContent: string) => {
        if (prescriberId) {
            await prescribersApi.updateNoteTiersTiersItem({
                id: prescriberId.toString(),
                tiersTiersJsonldTiersWriteNote: {
                    note: noteContent,
                },
            });
        }
        reloadPrescriber();
    };

    return (
        <Grid
            item
            xs={12}
        >
            <Notes
                title="Notes du prescripteur"
                noteRawContent={note}
                onEdit={onEdit}
            />
        </Grid>
    );
};

export default NoteRow;
