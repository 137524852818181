import * as React from 'react';

import { CircularProgress } from '@mui/material';
import { FlexyTabs, TabStructure } from '@europrocurement/flexy-components';
import { usePrescriberUtils } from '@b2d/hooks/prescriberHooks';

import { SUB_TAB_ROUTER_PARAMETER } from '@b2d/pages/Prescripteurs/constants/paths';
import FormalitiesSettings from './FormalitiesSettings';
import FormalitiesNotifications from './FormalitiesNotifications';

const Formalities: React.FunctionComponent = function () {
    const { prescriber } = usePrescriberUtils();

    if (!prescriber.id) {
        return <CircularProgress />;
    }

    const TABS: Array<TabStructure> = [
        {
            tabName: 'parametrage',
            tabTitle: 'Paramétrage',
            tabPanel: (
                // props a revoir / usePrescriber / useModal
                <FormalitiesSettings prescriberId={`${prescriber.id}`} />
            ),
        },
        {
            // index: NOTIFICATION_TAB_INDEX,
            tabName: 'notification',
            tabTitle: 'Notifications',
            tabPanel: <FormalitiesNotifications />,
        },
    ];

    return (
        <FlexyTabs
            orientation="vertical"
            tabs={TABS}
            inCard
            routerParameterName={SUB_TAB_ROUTER_PARAMETER}
        />
    );
};

export default Formalities;
