import { RootStateType } from '@b2d/redux/RootStore';
import { OFFER_ACTIONS_SLICE_NAME, OFFRE_REDUCER_NAME } from '@europrocurement/l2d-domain';
import { ActionDetailActionDetailRead } from '@europrocurement/l2d-domain/openApi/ApiOffre';
import { actionIcon } from '@europrocurement/l2d-icons';
import { actionsApi } from '@b2d/redux/subReducers/Offers';
import { actionsDataSourcesThunks } from '@b2d/redux/subReducers/Offers/reducers';
import { ModelConfiguration, OfferModelItem, ModelMethods } from './types';

const modelKey = 'action';
const modelName = 'Action';
const sliceName = OFFER_ACTIONS_SLICE_NAME;
const api = actionsApi;
const baseSelector = (state: RootStateType) => state[OFFRE_REDUCER_NAME][sliceName];
const datasourcesThunks = actionsDataSourcesThunks;

type ModelStructure = ActionDetailActionDetailRead;

const configuration: ModelConfiguration = {
    key: modelKey,
    name: modelName,
    type: 'Actions',
    selector: baseSelector,
    sliceName,
    api,
    datasourcesThunks,
    icon: actionIcon,
};

const methods: ModelMethods<ModelStructure> = {
    getItemDefaultLabel: (model: ModelStructure) => `${model.libelle}`,
};

const endpoints = {
    list: (...args: Parameters<typeof api.apiActionDetailsGetCollection>) =>
        api.apiActionDetailsGetCollection(...args),
    get: (...args: Parameters<typeof api.apiActionDetailsIdGet>) =>
        api.apiActionDetailsIdGet(...args),
};

type ModelType = OfferModelItem<ModelStructure> & typeof endpoints;

const model: ModelType = {
    ...configuration,
    ...methods,
    ...endpoints,
};

export type ActionModel = ModelType;
export default model;
