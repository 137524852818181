import * as React from 'react';
import { FlexyHeaderForm, FlexyTextField } from '@europrocurement/flexy-components';
import { Box, IconButton, SxProps, Tooltip, Typography, useTheme } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import SearchIcon from '@mui/icons-material/Search';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Prescripteur } from '@europrocurement/l2d-domain';
import { useNavigate } from 'react-router';
import { PRESCRIBER_LIST } from '../constants/paths';

type ExistingPrescribersListProps = {
    existingPrescribers: Prescripteur[];
    hasRedirect?: boolean;
    maximumRows?: number;
    sx?: SxProps;
};

const ExistingPrescribersList: React.FunctionComponent<ExistingPrescribersListProps> = function (
    props,
) {
    const { existingPrescribers, hasRedirect = true, maximumRows, sx: sxProps } = props;

    const navigate = useNavigate();
    const redirectToPrescriberDetail = (prescriberId: number) => {
        const prescriberDetailUrl = `/prescripteurs/liste/${prescriberId}`;
        navigate(prescriberDetailUrl);
    };

    const [prescriberFilter, setPrescriberFilter] = React.useState<string | undefined>(undefined);
    const numberOfExistingsPrescribers = existingPrescribers.length;

    const filterEnabled = numberOfExistingsPrescribers > 1;

    const theme = useTheme();

    const rowMargin = 12;
    const rowHeight = 53;

    const rowStyle = {};

    const scrollableContainerHeight = maximumRows && (rowMargin + rowHeight) * maximumRows + 40;

    const maximumRowsStyle = {
        overflowY: 'auto',
        maxHeight: `${scrollableContainerHeight}px`,
    };

    const getExistingPrescribersLabel = () => {
        const plural = numberOfExistingsPrescribers > 1;
        const pluralSuffix = plural ? 's' : '';

        if (numberOfExistingsPrescribers === 0) {
            return "Il n'existe aucun autre prescripteur pour ce numéro de Siret";
        }

        return `Il existe ${numberOfExistingsPrescribers} autre${pluralSuffix} prescripteur${pluralSuffix} pour ce numéro de
            Siret :`;
    };

    const getFilteredPrescribers = () => {
        if (!prescriberFilter) {
            return existingPrescribers;
        }

        return existingPrescribers.filter((prescriber) =>
            prescriber.raisonSociale.includes(prescriberFilter),
        );
    };
    const filteredExistingPrescribers = getFilteredPrescribers();

    const filterContainerStyle = {
        maxHeight: '10px',
        marginRight: '15px',
        marginTop: '25px',
        ...(!filterEnabled && { opacity: 0.2 }),
    };

    // eslint-disable-next-line react/no-unstable-nested-components
    const EmptyListRender = function () {
        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ minHeight: '200px' }}
            >
                <Typography sx={{ opacity: '0.2' }}>(Aucun résultat)</Typography>
            </Box>
        );
    };

    return (
        <Box sx={{ ...sxProps }}>
            <Box
                display="flex"
                justifyContent="space-between"
                sx={{ marginBottom: '30px' }}
            >
                <Box>
                    <FlexyHeaderForm
                        label={getExistingPrescribersLabel()}
                        outlined
                    />
                </Box>
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={filterContainerStyle}
                >
                    <SearchIcon
                        sx={{ marginRight: '15px' }}
                        fontSize="medium"
                    />
                    <FlexyTextField
                        variant="outlined"
                        disabled={!filterEnabled}
                        size="small"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setPrescriberFilter(event.target.value);
                        }}
                    />
                </Box>
            </Box>

            <Box
                sx={{
                    ...(maximumRows && maximumRows > 0 && maximumRowsStyle),
                }}
            >
                {filteredExistingPrescribers.length === 0 ? (
                    <EmptyListRender />
                ) : (
                    filteredExistingPrescribers.map((prescriber: Prescripteur) => (
                        <Box
                            key={`existing_row_${prescriber.id}`}
                            sx={rowStyle}
                        >
                            <a
                                href={`${PRESCRIBER_LIST}/${prescriber.id}`}
                                target="_blank"
                                rel="noreferrer"
                                style={{
                                    color: 'inherit',
                                    textDecoration: 'none',
                                }}
                            >
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    sx={{
                                        backgroundColor: '#f6f6f6',
                                        width: '100%',
                                        margin: `${rowMargin}px 0px`,
                                        borderRadius: '20px',
                                        maxHeight: `${rowHeight}px`,
                                        '&:hover': {
                                            backgroundColor: theme.palette.primary.light,
                                        },
                                        transition: 'background-color 400ms',
                                    }}
                                >
                                    <Box
                                        marginX={2}
                                        display="flex"
                                        alignItems="center"
                                    >
                                        <Box marginX={2}>
                                            <PersonIcon />
                                        </Box>

                                        <Box marginX={2}>
                                            <p>{prescriber.raisonSociale}</p>
                                        </Box>
                                    </Box>

                                    <Box display="flex">
                                        <Tooltip title="Consulter la fiche prescripteur">
                                            <Box
                                                marginX={2}
                                                justifySelf="end"
                                            >
                                                <IconButton>
                                                    <SearchIcon />
                                                </IconButton>
                                            </Box>
                                        </Tooltip>
                                        {hasRedirect && (
                                            <Tooltip title="Aller à la fiche prescripteur">
                                                <Box
                                                    marginX={2}
                                                    justifySelf="end"
                                                >
                                                    <IconButton
                                                        onClick={() =>
                                                            redirectToPrescriberDetail(
                                                                prescriber.id || -1,
                                                            )
                                                        }
                                                    >
                                                        <ArrowForwardIcon />
                                                    </IconButton>
                                                </Box>
                                            </Tooltip>
                                        )}
                                    </Box>
                                </Box>
                            </a>
                        </Box>
                    ))
                )}
            </Box>
        </Box>
    );
};

export default ExistingPrescribersList;
