import { Box, Checkbox, FormControlLabel, Grid, ListItemText } from '@mui/material';
import React, { useState } from 'react';

type CheckboxListItemProps = {
    label: string;
    description?: string;
    checked: boolean;
    onUpdate: (checked: boolean) => void;
};

const CheckboxListItem: React.FunctionComponent<CheckboxListItemProps> = function (props) {
    const { label, description, checked, onUpdate } = props;
    const [isHover, setHover] = useState(false);
    const checkbox = (
        <Checkbox
            onChange={(e) => {
                onUpdate(e.target.checked);
            }}
            checked={checked}
        />
    );
    const labelElt = (
        <ListItemText
            primary={
                <div
                    style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: '100%',
                    }}
                >
                    {label}
                </div>
            }
            secondary={description}
        />
    );
    const style = {
        display: 'block',
        backgroundColor: isHover ? 'rgba(0, 0, 0, 0.04)' : 'rgba(0, 0, 0, 0.01)',
        padding: '10px 20px',
        borderRadius: '5px',
    };

    return (
        <Box
            sx={style}
            onMouseEnter={() => {
                setHover(true);
            }}
            onMouseLeave={() => {
                setHover(false);
            }}
        >
            <Grid
                container
                alignItems="flex-start"
                flexWrap="nowrap"
            >
                <FormControlLabel
                    control={checkbox}
                    label={labelElt}
                />
            </Grid>
        </Box>
    );
};

export default CheckboxListItem;
