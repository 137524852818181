import React from 'react';

import FlexyDatatableFilters from '../FlexyDatatableFilters/FlexyDatatableFilters';
import FlexyDatatableTable, {
    FlexyDatatableProps,
} from '../FlexyDatatableTable/FlexyDatatableTable';

export const FlexyDatatableRaw = function <T extends Record<string, unknown>>({
    sx,
    ...props
}: FlexyDatatableProps<T>) {
    return (
        <>
            {props.searchOpts && props.searchOpts.filtersOpts ? (
                <FlexyDatatableFilters
                    mode="raw"
                    filtersOpts={props.searchOpts.filtersOpts}
                    sx={{ padding: '20px 30px 0px 30px' }}
                />
            ) : null}
            <FlexyDatatableTable
                sx={{
                    width: '100%',
                    ...sx,
                }}
                {...props}
            />
        </>
    );
};

FlexyDatatableRaw.defaultProps = {
    columnLabelVariant: 'h5',
    textCellVariant: 'h5',
    pagination: undefined,
    searchOpts: undefined,
    setColumns: undefined,
    handleExport: undefined,
};

export default FlexyDatatableRaw;
