import { useCallback } from 'react';
import { UseFormReturn } from 'react-hook-form';
import _ from 'lodash';
import { AppDispatch, FactureFormSelector, RootStateType } from '@b2d/redux/RootStore';
import { Fournisseur } from '@europrocurement/l2d-domain';
import { useDispatch, useSelector } from 'react-redux';
import { ACTIONS } from '@b2d/redux/FactureFormReducer';
import {
    findProduitByIdRubFact,
    organiserProduits,
    replaceProduits,
} from '../../functions/produitFunctions';
import { FactureFormObject, LigneProduit, RSFRubFac, TableauProduits } from '../../types';

export type UseAddLineToProductsProps = {
    formContext: UseFormReturn<Partial<FactureFormObject>, unknown>;
};

const useAddLineToProducts = ({ formContext }: UseAddLineToProductsProps) => {
    const mainPublisherSelected: Fournisseur | undefined = useSelector(
        (s: RootStateType) => s.tiers.fournisseur.main.selected,
        _.isEqual,
    );

    const autocompletePublisherSelected: Fournisseur | undefined = useSelector(
        (s: RootStateType) => s.tiers.fournisseur.autocompleteFactures.selected,
        _.isEqual,
    );

    const publisherSelected: Fournisseur | undefined =
        autocompletePublisherSelected || mainPublisherSelected;

    const dispatch = useDispatch<AppDispatch>();
    const state = useSelector(FactureFormSelector);

    type RemoveLineByInvoiceCategoryProps = {
        invoiceCategory: string;
        productsArray?: TableauProduits;
    };

    /**
     * Delete products matching the invoice category.
     */
    const filterByInvoiceCategory = useCallback(
        ({
            invoiceCategory,
            productsArray,
        }: RemoveLineByInvoiceCategoryProps): TableauProduits | undefined =>
            productsArray?.filter(
                (produitToFilter) =>
                    !(produitToFilter?.rubriqueFacturation?.code === invoiceCategory),
            ),
        [],
    );

    const removeDiscountLine = useCallback(() => {
        let newProduitsForm: TableauProduits = [];

        const productsArray = formContext.getValues('produits');

        const newProductsArray = filterByInvoiceCategory({
            invoiceCategory: 'RSF',
            productsArray,
        });

        if (newProductsArray) {
            newProduitsForm = [...newProductsArray];
        }

        newProduitsForm = organiserProduits(newProduitsForm);

        replaceProduits(formContext, newProduitsForm);
    }, [filterByInvoiceCategory, formContext]);

    type AddLineProps = {
        produit: Partial<LigneProduit>;
    };

    const addLine = useCallback(
        ({ produit }: AddLineProps) => {
            let newProduitsForm: TableauProduits = [];

            // Supprime les produits sans idLigneVente du tableau de produits (les lignes par défauts, sauf RSF)
            const produitsValues = formContext
                .getValues('produits')
                ?.filter((produitToFilter) => !!produitToFilter?.idLigneVente);

            if (produitsValues) {
                newProduitsForm = [...produitsValues];
            }

            newProduitsForm.push(produit);

            newProduitsForm = organiserProduits(newProduitsForm);

            // newProduitsForm.forEach((LigneProduit, index) => {
            //     if (LigneProduit.rubriqueFacturation === undefined) newProduitsForm.splice(index);
            // });

            replaceProduits(formContext, newProduitsForm);
        },
        [formContext],
    );

    /**
     * Add RSF Invoice Category if not already applied.
     */
    const addRsfInvoiceCategory = useCallback(() => {
        if (state.rubFacts.find((category) => category.id === 99)) return;

        dispatch({ type: ACTIONS.ADD_RUB_FACT, payload: RSFRubFac });
    }, [dispatch, state.rubFacts]);

    const addDiscountLine = useCallback(() => {
        const produitRsf = findProduitByIdRubFact(formContext, 99);

        const discountTypeAuthorized = ['RSF', 'RSF Inclus'];
        const publisherDiscountType = publisherSelected
            ? publisherSelected?.typeRemise?.code
            : undefined;

        if (publisherDiscountType && discountTypeAuthorized.includes(publisherDiscountType)) {
            addRsfInvoiceCategory();

            setTimeout(() => {
                if (!produitRsf) {
                    addLine({
                        produit: {
                            ordre: 2,
                            rubriqueFacturation: {
                                id: 99,
                                code: 'RSF',
                            },
                            ht: undefined,
                            tva: undefined,
                            txtva: {
                                label: 20,
                                value: 20,
                            },
                            ttc: undefined,
                        },
                    });
                }
            }, 300);
        } else if (
            (!publisherDiscountType || !discountTypeAuthorized.includes(publisherDiscountType)) &&
            produitRsf
        ) {
            removeDiscountLine();
        }
    }, [addLine, addRsfInvoiceCategory, formContext, publisherSelected, removeDiscountLine]);

    return {
        addLine,
        addDiscountLine,
    };
};

export default useAddLineToProducts;
