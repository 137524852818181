import * as React from 'react';
import { InformationCard, InformationCardProps } from '@europrocurement/flexy-components';

import { CatalogueCatalogueRead } from '@europrocurement/l2d-domain/openApi/ApiOffre';
import { JsonLdData } from '../forms/types';
import { EntityPreview } from '../components/types';

type Items = InformationCardProps['items'];

type EntityType = JsonLdData<CatalogueCatalogueRead>;

const PreviewCard: React.FC<EntityPreview<EntityType>> = function (props) {
    const { entity, onEdit } = props;

    const cardTitle = entity.nomCommercial;
    const cardItems: Items = [
        {
            label: 'ID',
            value: entity.id?.toString() ?? null,
        },
        {
            label: 'Domaine',
            value: entity.domaine?.libelle ?? null,
        },
        {
            label: 'Tarif',
            value: entity.tarif ?? null,
        },
        {
            label: 'Offre',
            value: entity.offre?.libelle ?? null,
        },
        {
            label: 'Package',
            value: entity.package?.libelle ?? null,
        },
        {
            label: 'Groupement',
            value: entity.groupement?.libelle ?? null,
        },

        {
            label: 'Origine',
            value: entity.origine?.origine ?? null,
        },
        {
            label: 'Date de début',
            value: entity.dateDebut ?? null,
        },
        {
            label: 'Date de de fin',
            value: entity.dateFin ?? null,
        },
    ];

    return (
        <InformationCard
            title={cardTitle}
            items={cardItems}
            button={{
                label: 'Éditer',
                onClick: onEdit,
            }}
        />
    );
};

export default PreviewCard;
