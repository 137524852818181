import { getFromSpecifiedDataSourceThunks } from '@b2d/redux/RootStore';
import { ListActionButton } from '@europrocurement/flexy-components';
import { MediaObject, MediaObjectApiObject } from '@europrocurement/l2d-domain';
import { addFileIcon, FaOptionIcon } from '@europrocurement/l2d-icons';
import React, { FunctionComponent } from 'react';
import { DataSource } from '@europrocurement/l2d-redux-utils';
import useCheckInTreatmentInvoice from '../../hooks/useCheckInTreatmentInvoice';

type RegisterButtonProps = {
    mediaObject: MediaObject;
    getMediaObject: ReturnType<typeof getFromSpecifiedDataSourceThunks>;
    selectedDataSource?: MediaObjectApiObject;
    dataSource?: DataSource<MediaObjectApiObject>;
};

const RegisterButton: FunctionComponent<RegisterButtonProps> = function ({
    mediaObject,
    getMediaObject,
    selectedDataSource,
    dataSource,
}: RegisterButtonProps) {
    const { debutTraitementCheckModal } = useCheckInTreatmentInvoice({
        getMediaObject,
        selectedDataSource,
    });

    return (
        <ListActionButton
            tooltip="Saisir"
            onClick={() => {
                debutTraitementCheckModal({
                    mediaObjectToRegister: mediaObject,
                    dataSourceName: dataSource?.name,
                });
            }}
            iconComponent={<FaOptionIcon {...addFileIcon.props} />}
        />
    );
};

export default RegisterButton;
