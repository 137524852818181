import React from 'react';
import { List, ListItem } from '@mui/material';
import { DataSource } from '@europrocurement/l2d-redux-utils';
import { CategoriesFormalite } from '@europrocurement/l2d-domain';
import { useSelector } from 'react-redux';
import { categoriesFormalitySelector } from '@b2d/redux/subReducers/FormalityReducer/Selectors';

type FormalitySelectCategoriesProps = {
    selectedItemId: number | null;
    onSelectCategory: (selectedCategoryId?: number) => void;
};

const FormalitySelectCategories: React.FunctionComponent<FormalitySelectCategoriesProps> =
    function (props) {
        const { selectedItemId, onSelectCategory } = props;
        const categories: DataSource<CategoriesFormalite> = useSelector(
            categoriesFormalitySelector,
        );
        const defaultStyle = { fontWeight: 'normal' };
        const selectedStyle = { fontWeight: 'bold' };
        const listItems = categories.data.map((category: CategoriesFormalite) => (
            <ListItem
                key={category.id}
                onClick={() => {
                    onSelectCategory(category.id);
                }}
                sx={selectedItemId === category?.id ? selectedStyle : defaultStyle}
            >
                {category.libelle}
            </ListItem>
        ));

        return (
            <List>
                <ListItem
                    onClick={() => {
                        onSelectCategory();
                    }}
                    sx={!selectedItemId ? selectedStyle : defaultStyle}
                >
                    Toutes les catégories
                </ListItem>
                {listItems}
            </List>
        );
    };

export default FormalitySelectCategories;
