import {
    faTriangleExclamation,
    faCircleExclamation,
    faCircleCheck,
    faXmark,
} from '@fortawesome/pro-regular-svg-icons';
import { NamedIconsType } from '../../types/types';

export const alertDefaultIcon: NamedIconsType = {
    props: {
        icon: faCircleExclamation,
        color: 'primary.main',
    },
    name: 'alert_default',
    displayName: 'Icône par défaut',
};

export const alertInfoIcon: NamedIconsType = {
    props: {
        icon: faCircleExclamation,
        color: 'info.main',
    },
    name: 'alert_info',
    displayName: 'Information',
};

export const alertSuccessIcon: NamedIconsType = {
    props: {
        icon: faCircleCheck,
        color: 'success.main',
    },
    name: 'alert_success',
    displayName: 'Succès',
};

export const alertWarningIcon: NamedIconsType = {
    props: {
        icon: faTriangleExclamation,
        color: 'warning.main',
    },
    name: 'alert_warning',
    displayName: 'Avertissement',
};

export const alertErrorIcon: NamedIconsType = {
    props: {
        icon: faTriangleExclamation,
        color: 'error.main',
    },
    name: 'alert_error',
    displayName: 'Erreur',
};

export const alertCloseIcon: NamedIconsType = {
    props: {
        icon: faXmark,
    },
    name: 'alert_close',
    displayName: 'Fermer',
};

export const alertNamedIcons: NamedIconsType[] = [
    alertDefaultIcon,
    alertInfoIcon,
    alertSuccessIcon,
    alertWarningIcon,
    alertErrorIcon,
    alertCloseIcon,
];
