import React from 'react';
import { FormStructure } from '@europrocurement/flexy-form';
import { Fournisseur } from '@europrocurement/l2d-domain';
import {
    isSocieteGroupe,
    resetFournisseursFields,
} from '@b2d/pages/Achats/components/forms/functions/fournisseurFunctions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandshake } from '@fortawesome/pro-duotone-svg-icons';
import {
    AutocompletePublisherStructureProps,
    GetPublisherAutocompleteStructureProps,
    PublisherSectionStructureProps,
} from '../types';
import HeaderStructure from '../../../fragments/HeaderStructure';
import { autocompleteStructureReadonly } from './common';

const autocompletePublisherStructure = ({
    dataSource,
    fetchData,
    defaultInputValue,
    onSelectOption,
    mode,
}: AutocompletePublisherStructureProps): FormStructure | null => {
    if (!dataSource || !fetchData || !onSelectOption) {
        console.error('Missing prop to render publisher autocomplete field !');

        return null;
    }

    return {
        type: 'autocompletestore',
        name: 'siren_fournisseur',
        inputlabel: 'Recherche (siren, nom)',
        dataSource,
        fetchData,
        renderField: 'siren',
        idProperty: 'id',
        defaultInputValue,
        debounce: 500,
        rules: {
            required: mode.type === 'register' ? 'Vous devez renseigner le siren' : undefined,
            validate: (data: Fournisseur) => {
                const res = isSocieteGroupe(data.societeReference)
                    ? 'Vous ne pouvez pas intégrer une facture du Groupe'
                    : true;
                return res;
            },
        },
        events: {
            onClear: (event, newValue, reason, details, formContext) => {
                resetFournisseursFields(formContext);
            },
            onSelectOption,
        },
        getOptionLabel: (item: Fournisseur) => `${item.siren || 'Pas de SIREN'}`,
        simpleRenderOption: (item: Fournisseur) =>
            `${item.siren || 'Pas de SIREN'} (${item.libelle || 'Pas de libelle'})`,
        searchability: (terms) =>
            terms.length >= 3 ? true : 'Veuillez saisir au moins 3 caractères',
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
    };
};

const getPublisherAutocompleteStructure = ({
    dataSource,
    fetchData,
    defaultInputValue,
    onSelectOption,
    mode,
}: GetPublisherAutocompleteStructureProps): FormStructure | null => {
    const booleanEditMode = mode.type === 'update' && mode.lock;

    if (booleanEditMode)
        return autocompleteStructureReadonly({
            name: 'siren_fournisseur',
            inputlabel: 'Siren',
            overwriteProps: {
                lg: 12,
            },
        });

    return autocompletePublisherStructure({
        mode,
        dataSource,
        fetchData,
        defaultInputValue,
        onSelectOption,
    });
};

const publisherSectionStructure = ({
    mode,
    adornment,
    autocompleteProps,
    headerSwitches = [],
    headerChildren = [],
    hideHeader = false,
    onSelectOption = () => {},
}: PublisherSectionStructureProps): Array<FormStructure> => {
    const publisherAutocompleteStructure = getPublisherAutocompleteStructure({
        dataSource: autocompleteProps?.publisher?.dataSource,
        fetchData: autocompleteProps?.publisher?.fetchData,
        defaultInputValue: autocompleteProps?.publisher?.defaultValue,
        onSelectOption,
        mode,
    });

    const autocompleteStructures: Array<FormStructure> = [];

    if (publisherAutocompleteStructure !== null) {
        autocompleteStructures.push(publisherAutocompleteStructure);
    }

    let headerStructure: Array<FormStructure> = [];

    if (!hideHeader) {
        headerStructure = [
            {
                type: 'header',
                label: (
                    <HeaderStructure
                        icon={<FontAwesomeIcon icon={faHandshake} />}
                        title="Fournisseur"
                        controls={headerChildren}
                        switches={headerSwitches}
                    />
                ),
                name: 'supplier_header',
            },
        ];
    }

    return [
        ...headerStructure,
        ...autocompleteStructures,
        {
            type: 'text',
            name: 'raison_sociale_fournisseur',
            inputlabel: 'Raison sociale',
            disabled: true,
            xs: 6,
            sm: 6,
            md: 6,
            lg: 6,
        },
        {
            type: 'text',
            name: 'type_remise_fournisseur',
            inputlabel: 'Type remise',
            disabled: true,
            InputProps: {
                endAdornment: adornment,
            },
            xs: 6,
            sm: 6,
            md: 6,
            lg: 6,
        },
    ];
};

export {
    autocompletePublisherStructure,
    getPublisherAutocompleteStructure,
    publisherSectionStructure,
};
