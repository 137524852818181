import * as React from 'react';
import { Box, Card, Typography } from '@mui/material';
import { FaOptionIcon, NamedIconsType } from '@europrocurement/l2d-icons';

type ListingTitleProps = {
    title: string;
    modelIcon: NamedIconsType;
};

const ListingTitle: React.FC<ListingTitleProps> = function (props) {
    const { title, modelIcon } = props;

    return (
        <Card sx={{ width: '84%', margin: '0 0 15px 0', maxHeight: '60px', padding: 2 }}>
            <Box
                display="flex"
                alignItems="center"
            >
                <Box paddingRight={2}>
                    <FaOptionIcon
                        {...modelIcon.props}
                        size="xl"
                        sx={{ marginX: '15px' }}
                    />
                </Box>
                <Typography fontSize="20px">{title}</Typography>
            </Box>
        </Card>
    );
};

export default ListingTitle;
