import React, { useCallback, useEffect, useMemo } from 'react';
import { KeycloakRoute } from '@europrocurement/l2d-keycloak';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router';
import { DataSource } from '@europrocurement/l2d-redux-utils';
import { RubriqueFacturation, TauxTva } from '@europrocurement/l2d-domain';
import { CenterCircularProgress } from '@europrocurement/flexy-components';
import { AppDispatch, getRubFac, getTauxTva, RootStateType } from '../../redux/RootStore';

const AchatsRoot: React.FunctionComponent = function () {
    const dispatch = useDispatch<AppDispatch>();

    const rubFactsDataSource: DataSource<RubriqueFacturation> = useSelector(
        (s: RootStateType) => s.dossiers.rubfac.main,
    );

    const tauxTvaDatasource: DataSource<TauxTva> = useSelector(
        (s: RootStateType) => s.dossiers.txtva.main,
    );

    const optionsToLoadAtStartUp = useMemo(
        () => [
            { dataSource: tauxTvaDatasource, action: getTauxTva },
            { dataSource: rubFactsDataSource, action: getRubFac },
        ],
        [rubFactsDataSource, tauxTvaDatasource],
    );

    const loadInitialOptions = useCallback(() => {
        optionsToLoadAtStartUp.forEach(({ dataSource, action }) => {
            const { status } = dataSource;
            if (status === 'idle') {
                dispatch(action({}));
            }
        });
    }, [dispatch, optionsToLoadAtStartUp]);

    const optionsLoadingStatus = optionsToLoadAtStartUp.map(({ dataSource }) => {
        const { status } = dataSource;

        return status;
    });

    const allOptionsAreLoaded = optionsLoadingStatus.every((status) => status === 'succeeded');

    useEffect(() => {
        loadInitialOptions();
    }, [loadInitialOptions]);

    const isApplicationReady = () => allOptionsAreLoaded;

    return isApplicationReady() ? (
        <KeycloakRoute roles="api-tiers:tiers-read">
            <Outlet />
        </KeycloakRoute>
    ) : (
        <CenterCircularProgress />
    );
};

export default AchatsRoot;
